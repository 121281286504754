<section class="dirt-section-list dirt-association-search">
  <header class="row">
    <div class="col-sm-4">
      <a class="dirt-btn-back" [routerLink]="['/association']">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </a>

      <h1>Search Associations</h1>
    </div>
  </header>

  <div class="row">
    <!-- Left -->
    <div class="col-md-9 search-result" [ngClass]="{ loading: isLoading }">
      <p class="dirt-no-results" *ngIf="!isLoading && !associations?.length">No Associations</p>

      <div class="card" *ngIf="associations?.length">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let asso of associations">
            <div class="card-body">
              <!-- Parent Name -->
              <h4 class="card-subtitle mb-2" *ngIf="asso.parent">
                <span class="text-muted">{{ asso.parent.name }}</span>
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </h4>

              <!-- Name -->
              <h3 class="card-title">
                <i class="fa fa-chevron-right" aria-hidden="true" [hidden]="!asso.parent"></i>
                <a
                  [routerLink]="['/association/detail', asso._id]"
                  [innerHTML]="asso.name | highlight : model.name"
                  rel="noopener"
                  target="_blank"
                ></a>
                <span [hidden]="!asso.originalName">{{ asso.originalName }}</span>
              </h3>

              <!-- Meta -->
              <div class="card-subtitle text-muted mb-2" [hidden]="asso.parent">
                <div class="float-right">
                  <span *ngIf="asso.address?.city">{{ asso.address?.city }}</span>
                  <span *ngIf="asso.address?.city && asso.address?.country">,</span>
                  <span *ngIf="asso.address?.country">{{ asso.address?.country }}</span>
                </div>

                <div>
                  <span>{{ asso.reach | capitalize }}</span> |
                  <span>{{ asso.type | capitalize }}</span>
                </div>
              </div>

              <!-- Websources -->
              <ul class="list-unstyled">
                <li *ngFor="let ws of asso.webSources">
                  <a
                    [href]="ws"
                    class="card-link"
                    [innerHTML]="ws | highlight : model.webSource"
                    rel="noopener"
                    target="_blank"
                  ></a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!--Right -->
    <div class="col-md-3">
      <form #frmSearch="ngForm" novalidate>
        <div class="card">
          <div class="card-header text-center"><i class="fa fa-filter" aria-hidden="true"></i> Filter</div>
          <div class="card-body">
            <div class="form-group">
              <label for="name">Name</label>
              <input
                type="text"
                id="name"
                class="form-control"
                name="name"
                [(ngModel)]="model.name"
                placeholder="Enter a name..."
              />
            </div>
            <div class="form-group">
              <label for="name">Alternative Name</label>
              <input
                type="text"
                id="alt-name"
                class="form-control"
                name="name"
                [(ngModel)]="model.originalName"
                placeholder="Enter an alternative name..."
              />
            </div>
            <div class="form-group">
              <label for="webSource">Websource</label>
              <input
                type="text"
                id="webSource"
                class="form-control"
                name="webSource"
                [(ngModel)]="model.webSource"
                placeholder="i.e: https://google.com"
              />
            </div>
            <div class="form-group">
              <label class="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="only-root-custom-control-input"
                  [checked]="model.rootOnly"
                  (change)="model.rootOnly = !model.rootOnly"
                />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Only root associations</span>
              </label>
            </div>
          </div>
          <div class="card-footer">
            <button
              type="submit"
              class="btn btn-success btn-sm float-right"
              (click)="onSearch()"
              [disabled]="isLoading"
            >
              <span [hidden]="isLoading">Apply</span>
              <span class="fa fa-circle-o-notch fa-spin fa-fw" [hidden]="!isLoading"></span>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
