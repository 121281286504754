import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';
import { AccountAPI } from '../../account-api.service';
import { Account } from '../../account';

@Component({
  selector: 'dirt-account-single-select',
  templateUrl: 'account-single-select.html',
  styleUrls: ['account-single-select.scss'],
})
export class AccountSingleSelectComponent {
  @Output()
  accountSelected: EventEmitter<Account> = new EventEmitter();
  @Input()
  wide: boolean = false;

  isSearching = false;
  searchTerm: string;

  searchAccounts = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) =>
        this.svcAccount.find(term as any).pipe(
          take(1),
          catchError(() => {
            return of([]);
          })
        )
      ),
      tap(() => (this.isSearching = false))
    );

  constructor(private readonly svcAccount: AccountAPI) {}

  onChangeAccount(account) {
    this.accountSelected.emit(account);
    setTimeout(() => (this.searchTerm = null));
  }
}
