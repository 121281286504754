import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { EventAPI } from '../../event/shared/api.service';
import { Contribution } from '../shared/contribution';
import { ContributionAPI } from '../shared/contribution-api.service';

@Component({
  selector: 'dirt-contribution-create',
  templateUrl: 'create.component.html',
  styleUrls: ['create.component.scss'],
})
export class ContributionCreateComponent implements OnInit, OnDestroy {
  eventId: string;
  contribution: Contribution = new Contribution();
  sub: any;
  initializedAt: Date;
  isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventSvc: EventAPI,
    private contributionSvc: ContributionAPI
  ) {}

  ngOnInit(): void {
    // record the time, when user starts filling the form
    this.initializedAt = new Date();

    this.sub = this.route.params.subscribe((params) => {
      this.eventId = params['eventId'];

      // find the parent event in order to based on country, detect
      // whether or not full name should be visible.
      this.getParent();
    });
  }

  getParent(): void {
    this.eventSvc.findById(this.eventId).subscribe((resp) => (this.contribution.event = resp));
  }

  onSubmit(contribution: Contribution): void {
    contribution.event = this.eventId;
    contribution['initializedAt'] = this.initializedAt;

    this.isSubmitting = true;
    this.contributionSvc.create(contribution).subscribe((response) => {
      this.isSubmitting = false;
      this.router.navigate(['/event/detail', this.eventId]);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
