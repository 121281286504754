import { Component, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, takeUntil, tap } from 'rxjs';
import { FormControl } from '@angular/forms';

import { Filter, FilterType } from '../../shared/components/filters/filters.component';
import { UserPool } from '../shared/user-pool';
import { UserPoolAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-user-pool-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class UserPoolListComponent implements OnInit, OnDestroy {
  pools: Array<UserPool> = [];

  isLoading = true;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  // Sorting / Filtering settings
  searchCtrl: FormControl = new FormControl('');
  filter: { [key: string]: any } = { disabled: false };
  filters: Filter[] = [
    {
      title: 'Disabled',
      key: 'disabled',
      type: FilterType.SINGLE_VALUE,
      values: [
        { title: 'true', displayValue: 'YES' },
        { title: 'false', displayValue: 'NO', selected: true },
      ],
    },
  ];

  private searchTerm: string;

  private destroy$: Subject<boolean> = new Subject();

  constructor(private svcUserPool: UserPoolAPI) {}

  ngOnInit(): void {
    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.searchTerm = term;
        this.doLoad();
      });

    this.doLoad();
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /** just avoid useless rendering if we can */
  trackById(_: number, pool: UserPool): string {
    return pool.id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getPools();
  }

  onFilter(filter: { [key: string]: any }): void {
    this.filter = filter;
    this.doLoad();
  }

  private doLoad(): void {
    this.resetPagination();
    this.getPools();
    this.getPoolsCount();
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getPools(): void {
    this.isLoading = true;

    this.svcUserPool
      .find(this.searchTerm, this.pagingLimit, this.pagingSkip, '', this.filter)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((pools) => {
        this.pools = pools;
      });
  }

  private getPoolsCount(): void {
    this.svcUserPool.count(this.searchTerm, this.filter).subscribe((count) => {
      this.total = count;
    });
  }
}
