import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { APIService } from '../../shared/services/api/api.service';
import { Contribution } from '../../contribution/shared/contribution';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Body, MediaType, POST, Produces } from '../../shared/services/rest/rest.service';

@Injectable()
export class ContributionParseAPI extends APIService {
  wndw: Window = window; // be able to override in tests

  public parseContributionFromText(text: string, existingContribution?: Contribution): Observable<Contribution> {
    // can't have automatic here - (similar to bulk res download)
    const mth = this.wndw.localStorage.getItem('capture_pref_mth') || '';
    return this.http
      .post(this.getBaseUrl() + 'contributions/parse' + (mth ? '?mth=' + encodeURIComponent(mth) : ''), text, {
        headers: { 'Content-type': 'text/plain', Accept: 'application/json' },
      })
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(
        map((contribution: Contribution) => {
          contribution.plainText = text;
          contribution.originalPlainText = (existingContribution || {}).originalPlainText || text;
          if (contribution.person && existingContribution && existingContribution.person) {
            contribution.person.position = contribution.person.position || existingContribution.person.position;
          }
          return contribution;
        })
      );
  }

  public pdfOcrCoords(
    uuid: string,
    x: number | string,
    y: number | string,
    w: number | string,
    h: number | string,
    page?: number | string,
    lang?: string
  ): Observable<{ text: string }> {
    return this.http
      .get(
        this.getBaseUrl() +
          'contributions/pdf/coords' +
          (lang ? '?lang=' + encodeURIComponent(lang) : '') /* avoid plus sign trouble */,
        {
          params: { uuid, x: '' + x, y: '' + y, w: '' + w, h: '' + h, ...(page ? { page: '' + page } : {}) },
          headers: { Accept: 'application/json' },
        }
      )
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  public pdfOcrFrames(
    uuid: string,
    page?: number | string,
    lang?: string
  ): Observable<[{ rect: any /*[x, y, w, h]*/; text: string }]> {
    return this.http
      .get(
        this.getBaseUrl() +
          'contributions/pdf/frames' +
          (lang ? '?lang=' + encodeURIComponent(lang) : '') /* avoid plus sign trouble */,
        { params: { uuid, ...(page ? { page: '' + page } : {}) }, headers: { Accept: 'application/json' } }
      )
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  public pdfOcrUrlToUuid(url: string): Observable<{ uuid: string; url: string; pages: number }> {
    // (also manual - wrong URL encode)
    return this.http
      .get(
        this.getBaseUrl() + 'contributions/pdf/urltouuid?url=' + encodeURIComponent(url) /* avoid plus sign trouble */,
        { headers: { Accept: 'application/json' } }
      )
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  public pdfOcrGetByUuid(
    uuid: string,
    type: 'pdf' | 'png' | 'framespng' | 'framesjson',
    page?: number | string
  ): Observable<string> {
    // data URL (also manual)
    return this.http
      .get(this.getBaseUrl() + 'contributions/pdf/getbyuuid', {
        params: { uuid, type: '' + type, ...(page ? { page: '' + page } : {}) },
        responseType: 'blob',
        /*observe: 'response',*/ headers: {
          Accept: 'pdf' === type ? 'application/pdf' : 'framesjson' === type ? 'application/json' : 'image/*',
        },
      })
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(
        map((res: Blob) => {
          return URL.createObjectURL(res);
        })
      );
  }

  public pdfOcrPostScreen(file: File, lang?: string): Observable<{ uuid: string; pages: number; text?: string }> {
    // (similar XLSX upload)
    const formData: FormData = new FormData();
    formData.set('file', file); // multipart name must match server
    return this.http
      .post(
        this.getBaseUrl() +
          'contributions/pdf/postscreen' +
          (lang ? '?lang=' + encodeURIComponent(lang) : '') /* avoid plus sign trouble */,
        formData,
        { headers: { Accept: 'application/json' } }
      )
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  handleError(resp: HttpErrorResponse): Observable<HttpResponse<any>> {
    // some errors might be blobs - make sure we get them right
    if (resp.error instanceof Blob) {
      return from((resp.error as Blob).text())
        .pipe(
          map((errorText) => {
            try {
              (resp as any).error = JSON.parse(errorText);
            } catch (_e) {
              /* we really did our best*/
            }
            throw resp;
          })
        )
        .pipe(catchError((err) => super.handleError(err)));
    } else {
      return super.handleError(resp);
    }
  }

  @POST('contributions/smart-lcase')
  @Produces(MediaType.JSON)
  public smartLcase(@Body contribs: Partial<Contribution>[]): Observable<Partial<Contribution>[]> {
    return;
  } // (repeat to keep things neat)
}
