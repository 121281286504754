import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GET,
  PUT,
  POST,
  DELETE,
  Path,
  Body,
  Query,
  MediaType,
  Produces,
  BaseUrl,
} from '../../shared/services/rest/rest.service';

import { APIService } from '../../shared/services/api/api.service';
import { Membership } from './membership';
import { environment } from '../../../environments/environment';

@Injectable()
export class MembershipAPI extends APIService {
  @GET('memberships/')
  @Produces(MediaType.JSON)
  public find(
    @Query('associationId') associationId?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('title') title?: string,
    @Query('searchType') searchType?: string,
    @Query('filter') filter?: any
  ): Observable<Membership[]> {
    return null;
  }

  @GET('memberships/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Membership> {
    return null;
  }

  @GET('memberships/count')
  @Produces(MediaType.JSON)
  public count(
    @Query('associationId') associationId?: string,
    @Query('searchTerm') searchTerm?: string,
    @Query('searchType') searchType?: string
  ): Observable<any> {
    return null;
  }

  @POST('memberships')
  @Produces(MediaType.JSON)
  public create(@Body membership: Membership): Observable<any> {
    return null;
  }

  @PUT('memberships')
  @Produces(MediaType.JSON)
  public upsert(@Body membership: Membership): Observable<any> {
    return null;
  }

  @DELETE('memberships/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return null;
  }

  @POST('memberships/bulk-update')
  @Produces(MediaType.JSON)
  public bulkUpdate(@Body data: { ids: string[]; values: { [key: string]: any } }): Observable<any> {
    return null;
  }

  @GET('memberships/duplicates')
  @Produces(MediaType.JSON)
  public getDuplicates(
    @Query('associationId') associationId?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number
  ): Observable<Membership[]> {
    return null;
  }

  @GET('memberships/duplicates/count')
  @Produces(MediaType.JSON)
  public getDuplicatesCount(@Query('associationId') associationId?: string): Observable<{ count: number }> {
    return null;
  }
}
