import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { PublicationAPI } from './shared/publication-api.service';

@NgModule({
  imports: [SharedModule],
  declarations: [],
  providers: [PublicationAPI],
})
export class PublicationModule {}
