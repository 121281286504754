import { Component, OnInit } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { ColGroupDef } from 'ag-grid';
import { ColDef } from 'ag-grid/main';
import { ValueFormatterParams } from 'ag-grid/dist/lib/entities/colDef';
import { tap } from 'rxjs/operators';

import { ProfileGridComponent } from '../../../shared/grid/grid.component';
import { ProfileVideoLinkService } from '../../../shared/modal/video-link/video-link.service';
import { ProfileVideoLinkOptions } from '../../../shared/modal/video-link/video-link.interface';
import { Video, Source } from '../../../../video/shared/video';
import { VideoAPI } from '../../../../video/shared/video-api.service';
import { ProfileVideoAPI } from '../shared/api.service';
import { ACL } from '../../../../shared/acl/acl.service';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { ProfileAPI } from '../../../../profile/shared/api.service';
import { QCCommentService } from '../../../shared/grid/qc-comment/qc-comment.service';

const SOURCE_SET = [Source.YOUTUBE, Source.ECANCER];
const SOURCE_URL_MAP = {
  [Source.YOUTUBE]: 'https://www.youtube.com/watch?v=',
  [Source.ECANCER]: 'https://ecancer.org/en/video/',
};

@Component({
  selector: 'dirt-profile-video-list',
  templateUrl: '../../../shared/grid/grid.component.html',
  styleUrls: ['../../../shared/grid/grid.component.scss'],
})
export class ProfileVideoListComponent extends ProfileGridComponent implements OnInit {
  FIELD_SOURCE = 'source';
  FIELD_VIDEO_ID = 'vendorId';
  FIELD_PUB_DATE = 'publicationDate';
  FIELD_TITLE = 'title';
  FIELD_THUMBNAIL_URL = 'thumbnailUrl';
  FIELD_DESCRIPTION = 'description';
  FIELD_LANGUAGE = 'originalLanguage';
  FIELD_MATCH_TIMESTAMP = 'matchTimestamp';
  FIELD_COMPILER_COMMENT = 'compilerComment';

  noVideos = false;
  constructor(
    protected svcProfileVideo: ProfileVideoAPI,
    private svcVideo: VideoAPI,
    private svcProfile: ProfileAPI,
    private svcVideoLink: ProfileVideoLinkService,
    protected readonly svcAcl: ACL,
    protected svcQCComment: QCCommentService
  ) {
    super('id', svcAcl, svcQCComment);
  }

  ngOnInit() {
    this.setNoVideosFlag();
    this.addFilter(this.FIELD_SOURCE, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_SOURCE, params)
    );
    this.addFilter(this.FIELD_MATCH_TIMESTAMP, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_MATCH_TIMESTAMP, params)
    );
    this.addFilter(this.FIELD_PUB_DATE, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_PUB_DATE, params)
    );
    super.ngOnInit();
  }

  getBaseColumns(): (ColDef | ColGroupDef)[] {
    return [
      {
        colId: 'checker',
        pinned: 'left',
        checkboxSelection: true,
        suppressMenu: true,
        suppressResize: true,
        maxWidth: 40,
        headerComponent: 'checkerRenderer',
        headerComponentParams: {
          onCheckChanged: (value: boolean) => {
            if (value) {
              this.agGridApi.getModel().forEachNode((node) => {
                node.setSelected(true);
              });
            } else {
              this.agGridApi.deselectAll();
            }
          },
        },
      },
    ];
  }
  protected defineDistinct(profileId: string, field: string, filter: any): Observable<string[]> {
    return this.svcProfileVideo.distinct(profileId, field, filter);
  }

  protected defineUpdate(
    profileId: string,
    ids: string[],
    value: { match: ProfileMatchManual; comment?: string }
  ): Observable<{ id; compiledAt? }[]> {
    return;
  }

  protected defineGetDataRows(...params): Observable<any> {
    return this.svcProfileVideo.find.apply(this.svcProfileVideo, params);
  }

  protected defineGetFocusArea(profileId: string): Observable<any> {
    return EMPTY;
  }

  protected defineGrid(): (ColDef | ColGroupDef)[] {
    const columnDefs: (ColDef | ColGroupDef)[] = [
      {
        headerName: '',
        maxWidth: 20,
        cellRenderer: this.rendererCommentMarker,
        cellStyle: { color: 'red' },
      },
      {
        headerName: 'Updated At',
        field: this.FIELD_MATCH_TIMESTAMP,
        valueFormatter: this.formatterISODate,
        filterParams: this.filters.get(this.FIELD_MATCH_TIMESTAMP),
        maxWidth: 120,
      },
      {
        headerName: 'Source',
        field: this.FIELD_SOURCE,
        filterParams: this.filters.get(this.FIELD_SOURCE),
        maxWidth: 100,
      },
      {
        headerName: 'Video ID',
        field: this.FIELD_VIDEO_ID,
        maxWidth: 120,
        filter: 'text',
      },
      {
        headerName: 'Publication Date',
        field: this.FIELD_PUB_DATE,
        valueFormatter: this.formatterISODate,
        maxWidth: 130,
        filterParams: this.filters.get(this.FIELD_PUB_DATE),
      },
      {
        headerName: 'Title',
        field: this.FIELD_TITLE,
        filter: 'text',
        maxWidth: 250,
      },
      {
        headerName: 'Link',
        cellRenderer: this.rendererLinks,
        field: this.FIELD_VIDEO_ID,
        suppressMenu: true,
        width: 50,
      },
      {
        headerName: 'Language',
        field: this.FIELD_LANGUAGE,
        suppressMenu: true,
        width: 30,
      },
      {
        headerName: 'Thumbnail URL',
        field: this.FIELD_THUMBNAIL_URL,
        maxWidth: 180,
        filter: 'text',
      },
      {
        headerName: 'Description',
        field: this.FIELD_DESCRIPTION,
        filter: 'text',
      },
    ];

    return columnDefs;
  }

  protected defineAutoSizableColumns(): string[] {
    return [this.FIELD_TITLE, this.FIELD_DESCRIPTION];
  }

  protected onDirectLink(): void {
    const options = <ProfileVideoLinkOptions>{
      title: 'Video',
      sourceSet: SOURCE_SET,
      validateUrl: (url) => this.svcVideo.validate({ url }),
      addVideo: (entity) => {
        if (!entity) {
          return;
        }
        return this.svcProfileVideo.link(this.profileId, entity).pipe(tap(() => this.reload()));
      },
      sourceUrlMap: SOURCE_URL_MAP,
    };

    this.svcVideoLink.open(options).catch((e) => {
      if (e) {
        console.error(e);
      } else {
        console.log('Form closed');
      }
    });
  }

  deleteVideo() {
    if (!window.confirm('Are you sure you want to delete?')) {
      return;
    }

    const selected = this.getSelection();
    const profileVideoIds = (selected || []).map((item) => item.id);
    return this.svcProfileVideo.unlink(this.profileId, profileVideoIds).subscribe(() => {
      this.deselectAll();
      this.reloadData();
    });
  }

  editVideo() {
    const selection = this.getSelection();
    const data = (selection || []).length === 1 && (selection[0] || {}).data;
    if (!data) {
      return;
    }
    const options = <ProfileVideoLinkOptions>{
      title: 'Video',
      sourceSet: SOURCE_SET,
      sourceUrlMap: SOURCE_URL_MAP,
      video: { ...data },
      addVideo: (entity) => {
        if (!entity) {
          return;
        }
        const { compilerComment, ...rest } = entity;
        const updateParam = {
          video: rest,
          compilerComment,
        };
        return this.svcProfileVideo.update(this.profileId, entity.id, updateParam).pipe(tap(() => this.reload()));
      },
    };

    this.svcVideoLink.open(options).catch((e) => {
      if (e) {
        console.error(e);
      } else {
        console.log('Form closed');
      }
    });
  }

  /**
   * Renderer function for Links columns.
   *
   * @param {ValueFormatterParams} param
   * @return {string}
   */
  rendererLinks(param: ValueFormatterParams): string {
    if (!param.data) {
      return '';
    }

    const vendorId = param.data.vendorId;
    const source = param.data.source;
    const url = SOURCE_URL_MAP[source];
    const link = `<a href='${url}${vendorId}' rel=noopener target='_blank'>Link</a>`;
    return `${link}`;
  }

  rendererCommentMarker(param: ValueFormatterParams): string {
    if (!param.data) {
      return '';
    }
    const compilerComment = param.data.compilerComment;
    if (!compilerComment) {
      return '';
    }
    const content = `&#9679;`;
    return `${content}`;
  }

  onNoVideoFoundToggleChange(noVideo: boolean) {
    this.svcProfile
      .toggleNoVideos(this.profileId, { value: noVideo })
      .subscribe((response) => (this.noVideos = response));
  }

  hasPermissionDirectLink() {
    return this.svcACL.hasCredential('profile.videoDirectLink');
  }

  setNoVideosFlag() {
    this.svcProfile.getNoVideos(this.profileId).subscribe((noVideosResp) => (this.noVideos = noVideosResp));
  }

  reload() {
    this.reloadData();
    this.setNoVideosFlag();
  }
}
