export const ContributionEventPositions = {
  AWARD_WINNER: 'AWARD WINNER',
  CHAIRPERSON: 'CHAIRPERSON',
  MODERATOR: 'MODERATOR',
  ORGANIZER: 'ORGANIZER',
  PARTICIPANT: 'PARTICIPANT',
  PANELIST: 'PANELIST',
  POSTER_PRESENTER: 'POSTER PRESENTER',
  SPEAKER: 'SPEAKER',
};
