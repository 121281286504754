import { FormGroup, NgForm } from '@angular/forms';
import { get, isEmpty, set } from 'lodash';

export class Utils {
  /**
   * Removes falsey values from the array
   *
   * @param {Array<T>} arr
   * @return {Array<T>}
   */
  static compact = <T>(arr: T[]) => arr.filter((value) => !!value);

  /**
   * Converts camelCase to upper case
   *
   * @param {String} value
   * @return {String}
   */
  static upperCase = (value: string) => value.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());

  /**
   * Searches key inside an object recursively
   *
   * @param {any} object
   * @param {any} key
   * @return {any}
   */
  static findVal(object: any, key: string): any {
    let value;
    Object.keys(object).some((k) => {
      if (k === key) {
        value = object[k];
        return true;
      }
      if (object[k] && typeof object[k] === 'object') {
        value = Utils.findVal(object[k], key);
        return value !== undefined;
      }
    });
    return value;
  }

  /**
   * Gets value by path from an object
   *
   * @param {any} obj
   * @param {Array<any>} path
   * @param {any} def
   */
  static getByPath(obj: any, path: any[], def?: any): any {
    let current = obj;
    for (let i = 0; i < path.length; i++) {
      if (!current[path[i]]) {
        return def;
      }
      current = current[path[i]];
    }
    return current;
  }

  /**
   * Removes an item in an array
   *
   * @param {Array<any>} arr
   * @param {any} item
   */
  static removeItem(arr: any[], item: any) {
    const index = arr.indexOf(item);
    if (index !== -1) {
      arr.splice(index, 1);
    }
  }

  /**
   * Brings nested object keys up to the top array and preserves the path
   *
   * @param {any} obj
   * @param {Array<any>} all
   * @param {Array<string>} path
   */
  static unwindObject(obj: any, all = [], path?: string[]) {
    Object.keys(obj).forEach((key) => {
      const itemPath = [...(path || [])];
      if (typeof obj[key] === 'object') {
        itemPath.push(key);
        Utils.unwindObject(obj[key], all, itemPath);
      } else {
        all.push({
          path: itemPath.concat([key]),
          value: obj[key],
        });
      }
    });
    return all;
  }

  static isLiteralObject = (val: any): boolean => Object.prototype.toString.call(val).slice(8, -1) === 'Object';

  static isEmptyObject = (obj) => !Object.keys(obj || {}).length;

  static isDate(value: string): boolean {
    return /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(value);
  }

  static isURL(value: string): boolean {
    const urlExp =
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
    return !!value.match(urlExp);
  }

  /**
   * Recursively removes all empty values in an object
   * @param object
   */
  static normalizeObject(object: any) {
    if (Utils.isLiteralObject(object)) {
      Object.keys(object).forEach((key) => {
        const val = object[key];
        if (!val) {
          delete object[key];
        } else if (Utils.isLiteralObject(val)) {
          Utils.normalizeObject(val);
        } else if (Array.isArray(val)) {
          object[key] = Utils.compact(val);
          if (!object[key].length) {
            // delete object[key];
          } else {
            Utils.normalizeObject(val);
          }
        }
      });
    } else if (Array.isArray(object)) {
      object.forEach((val) => {
        if (Utils.isLiteralObject(val) || Array.isArray(val)) {
          Utils.normalizeObject(val);
        }
      });
    }
  }

  static difference = (arrA: any[], arrB: any[]) => arrA.filter((x) => !arrB.includes(x));

  static union = (arrA: any[], arrB: any[]) => Array.from(new Set(arrA.concat(arrB)));

  static clone = (value) => JSON.parse(JSON.stringify(value));

  static enumToKeyValue = (input: object) => Object.keys(input).map((key) => ({ key: key, value: input[key] }));

  static capitalize = (text: string) => (text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()).replace(/_/g, ' ');

  static sortByCountry(countryCodes: string[], countries: { [key: string]: string }): string[] {
    return countryCodes
      .filter((code) => countries[code])
      .sort((a, b) => countries[a].localeCompare(countries[b], 'en'));
  }

  static longDash = (text: string) => (text && typeof text === 'string' ? text.replace(/\u2013|\u2014/g, '-') : '');

  static prettyErrorFields(form: NgForm | FormGroup): string[] {
    // introduce this now to make it a ton more intuitive
    const fields: string[] = Object.entries(form.controls || {})
      .filter((e) => !!e[1].errors)
      .map((e) => e[0]);
    const toFirstUpper = (str) => str.substring(0, 1).toUpperCase() + str.substring(1);
    const fieldsPretty = fields.map((f) => {
      let upTo = 0;
      let res = '';
      let curr = 0;
      do {
        const nextUpper = (f.substring(upTo + 1).match(/([A-Z])/) || [])[1];
        if (nextUpper) {
          const nextUpperInd = f.substring(upTo).indexOf(nextUpper);
          res += (res.length > 0 ? ' ' : '') + toFirstUpper(f.substring(upTo, upTo + nextUpperInd));
          upTo = upTo + nextUpperInd;
        } else {
          res += (res.length > 0 ? ' ' : '') + toFirstUpper(f.substring(upTo));
          break;
        }
        curr++;
        if (curr > 100) {
          console.warn('Too many iterations in prettyErrorFields');
          res = f;
          break; // emergency exit
        }
      } while (true);
      res = res.replace(/-\d+/g, '').trim();
      return res;
    });
    return fieldsPretty;
  }

  static isDomainNonCompliant(nonCompliantDomains: string[], domain: string, exceptDomains?: string[]): boolean {
    if (!Array.isArray(nonCompliantDomains) || nonCompliantDomains.length === 0 || !domain) {
      return false;
    }

    domain = domain
      .replace(/(^\w+:|^)\/\//, '') // remove protocols if any
      .replace(/^w{3}\./, ''); // remove "www." as it's a special case (www.example.com = example.com)

    let host: string;
    let rootDomain: string;
    try {
      host = new URL(`https://${domain}`).host;
      rootDomain = host.split('.').slice(-2).join('.'); // the last two parts should be the root
    } catch (error) {
      return true; // likely not a proper URL
    }

    if (exceptDomains && exceptDomains.find((ed) => host === ed || rootDomain === ed)) {
      return false;
    }

    return !!nonCompliantDomains.find((nonCompliantDomain) => {
      nonCompliantDomain = nonCompliantDomain
        .replace(/(^\w+:|^)\/\//, '') // remove protocols if any
        .replace(/^w{3}\./, ''); // remove "www." as it's a special case

      if (nonCompliantDomain === host) {
        // exclude exact domains and IPs (v4, v6)
        return true;
      }

      try {
        // (we only set https for URL to be happy), and deal with IDN
        const nonCompliantHost = new URL(`https://${nonCompliantDomain}`).host;
        return nonCompliantHost === rootDomain;
      } catch (error) {
        return false; // likely non-compliant-domain value is invalid, skip this one
      }
    });
  }

  static getNameErrorRates(report: { [key: string]: any }) {
    const nameFields = [
      'firstName',
      'middleName',
      'lastName',
      'suffix',
      'nicknames',
      'alternativeNames',
      'originalFirstName',
      'originalMiddleName',
      'originalLastName',
      'originalFullName',
    ];
    const matches = nameFields.filter((field) => report[field]?.isCorrect || report[field]?.markAsCorrect);
    return `${(((nameFields.length - matches.length) / nameFields.length) * 100).toFixed(2)}%`;
  }

  static getPersonOverallErrorRate(report: { [key: string]: any }) {
    const fields = Object.keys(report);
    const errorFields = fields.filter((key) => !report[key].isCorrect && !report[key].markAsCorrect);
    const positionReport = report.affiliations?.positions;
    if (positionReport && !positionReport.isCorrect && !positionReport.markAsCorrect) {
      errorFields.push('positions');
      fields.push('positions');
    }
    return `${((errorFields.length / fields.length) * 100).toFixed(2)}%`;
  }

  static getFieldLevelErrorRate(path: string, report: { [key: string]: any }) {
    const parts = (path || '').split('.');
    const fieldName = parts[0];
    const markAsCorrectPath = parts[parts.length - 1] === 'positionErrorRate' ? `${fieldName}.positions` : fieldName;
    if (!report) return 'n/a';
    return (get(report, `${markAsCorrectPath}.markAsCorrect`, false) && '0%') || `${get(report, `${path}`, 'n/a')}%`;
  }

  static getYouTubeId(url: string) {
    const arr = url.split(/(vi\/|v%3D|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    return undefined !== arr[2] ? arr[2].split(/[^\w-]/i)[0] : arr[0];
  }

  static getProductColor(product: string) {
    let stringUniqueHash = [...product].reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc);
    }, 0);
    return `hsl(${stringUniqueHash % 360}, 95%, 35%)`;
  }

  static removeEmptyAndDuplicatedElementsFromArray<T extends object>(model: T, fields: (keyof T)[]): T {
    if (!model || !fields.length) {
      return;
    }

    fields.forEach((field) => {
      const value = get(model, field);
      if (!Array.isArray(value)) {
        return;
      }

      // Remove all empty values and duplicates
      set(model, field, [...new Set(value.filter((e) => !isEmpty(e)))]);
    });

    return model;
  }

  static hash(str: string): number {
    // just a simple hash, same as java.lang.String.hashCode() - thx, https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
    if (str.length === 0) {
      return 0;
    }
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash; // Convert to 32bit integer
    }
    return hash;
  }
}
