<section class="dirt-section-create dirt-document-create">
  <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
    <span class="fa fa-arrow-left" aria-hidden="true"></span>
  </button>

  <h1 class="bounceInRight">Create Document</h1>

  <br />

  <dirt-document-form
    #frmDocument="frmDocument"
    [model]="document"
    (validityChange)="onFormValidityChange($event)"
  ></dirt-document-form>

  <div class="pull-right">
    <ng-template [diAcl]="'document.create'">
      <button
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="!isFormValid || isSubmitting"
        (click)="onSubmit(frmDocument.getValue())"
      >
        Submit
      </button>
    </ng-template>
  </div>
</section>
