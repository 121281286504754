<section class="dirt-person-qc-next">
  <div class="next-content">
    <!--Loading Indicator-->
    <p [hidden]="!!queue">
      <i class="fa fa-refresh fa-spin fa-fw"></i>
      Loading your statistics...
    </p>

    <ul class="list-group" *ngIf="queue && queue.length">
      <p>Here are the profiles waiting for your review:</p>
      <li class="list-group-item d-flex justify-content-between align-items-center row" *ngFor="let stat of queue">
        <span class="col-md-6" [ngClass]="{ 'text-danger': stat.underperformer === true }">
          {{ stat.name.split('_').join(' ') | capitalize }}
        </span>
        <span class="col-md-2">
          <h5>
            <span class="badge badge-secondary">{{ stat.remaining }}</span>
          </h5>
        </span>
        <span class="col-md-4">
          <h5>
            <span
              class="badge"
              [ngClass]="{
                'badge-secondary': stat.percent === 0,
                'badge-success': stat.percent >= stat.reviewCap,
                'badge-warning': stat.percent < stat.reviewCap && stat.percent > 0
              }"
              >{{ getstatusText(stat) }}</span
            >
          </h5>
        </span>
      </li>
    </ul>

    <p *ngIf="queue && !queue.length">
      <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>&nbsp; You have no pending item in your queue. Simply
      click the button to start working on a new one!
    </p>
  </div>

  <div class="next-action">
    <button type="button" class="btn btn-success" (click)="getNext()" [disabled]="isLoadingNext">
      <i class="fa fa-refresh fa-spin" [hidden]="!isLoadingNext"></i>
      Next Person
    </button>
  </div>
</section>
