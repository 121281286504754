import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { Directive, forwardRef } from '@angular/core';

import { TwitterAPI } from '../services/twitter/twitter.service';

@Directive({
  selector: '[twitterHandleValidator]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => TwitterHandleValidator), multi: true }],
})
export class TwitterHandleValidator implements AsyncValidator {
  private handlePattern = new RegExp(/^[A-Z0-9_]{1,15}$/i); // https://help.twitter.com/en/managing-your-account/twitter-username-rules - although 1 character handles are a thing

  constructor(private readonly svcTwitter: TwitterAPI) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of(null);
    }

    if (!this.handlePattern.test(control.value)) {
      return of({ invalid: true });
    }

    return this.svcTwitter.findProfileByHandle(control.value).pipe(
      map(() => null), // no error
      catchError((error) => {
        if (error.status === 429) {
          // special case not to be handled by the user, allow saving
          return of(null);
        }
        if (error.status === 404) {
          return of({ unknown: true });
        }
        return throwError(() => error);
      })
    );
  }
}
