import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[twitterHashtagValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: TwitterHashtagValidator, multi: true }],
})
export class TwitterHashtagValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    if (control.value) {
      return !control.value.includes('#') ? null : { twitter: true };
    }
  }
}
