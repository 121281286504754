import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isNil } from 'lodash';
import { ClinicalTrialCurationStatus } from '../../curation-status';
import { ClinicalTrialProfileValueDate } from '../../value-date';

@Component({
  selector: 'dirt-input-value-date',
  templateUrl: 'value-date-input.html',
  styleUrls: ['value-date-input.scss'],
})
export class ValueDateInputComponent {
  @Input()
  value: ClinicalTrialProfileValueDate;
  @Output()
  valueEdited: EventEmitter<Date> = new EventEmitter();
  @Input()
  name: string = 'ctrl-' + Math.round(Math.random() * 1_000_000);
  @Input()
  disabled: boolean = false;

  @Input()
  validateDate: (date: any) => boolean;

  inside: boolean = false;
  error: boolean = false;
  statusEnum = ClinicalTrialCurationStatus;
  isNil = isNil;

  asString(dt: Date | string, iso?): string {
    if (isNil(dt)) {
      return '';
    }
    if (typeof dt === 'string') {
      dt = new Date(dt + '2000-01-01T00:00:00.000Z'.substring(dt.length));
    }
    if (iso) {
      return dt.toISOString().substring(0, 10);
    } else {
      return dt.toLocaleDateString(undefined, { timeZone: 'UTC' });
    }
  }

  changeInput(evnt) {
    const valRaw = evnt.target.value;
    if (!valRaw?.trim()) {
      this.value.value = null;
      this.valueEdited.emit(null);
      this.error = false;
      return;
    }
    // validate
    if (!valRaw.match(/^\d{4}-\d{2}-\d{2}$/)) {
      this.error = true;
      return;
    }
    const val = new Date(valRaw + 'T00:00:00.000Z');
    this.error = false;
    this.value.value = val;

    this.validateInput(valRaw);

    this.valueEdited.emit(val);
  }

  validateInput(date: any): boolean {
    if (!this.validateDate) {
      return true; // If no validation function is provided, assume input is valid
    }
    return this.validateDate(date);
  }
}
