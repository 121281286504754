import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'range',
})
export class RangePipe implements PipeTransform {
  transform(end: number, start: number = 0) {
    return {
      [Symbol.iterator]: function* () {
        for (let i = start; i <= end; i++) {
          yield i;
        }
      },
    };
  }
}
