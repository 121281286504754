import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountFinancialInformationComponent } from './account-financial-information.component';
import { AccountFinancialInformationCreateComponent } from './create/create.component';
import { AccountFinancialInformationDetailComponent } from './detail/detail.component';
import { AccountFinancialInformationModule } from './account-financial-information.module';
import { AclGuard } from '../shared/acl/guard.service';
import { AuthGuard } from '../shared/services/guard/auth-guard.service';

export const accountFinancialInformationRoutes: Routes = [
  {
    path: 'account-financial-information',
    component: AccountFinancialInformationComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: 'create',
        component: AccountFinancialInformationCreateComponent,
      },
      {
        path: 'detail/:id',
        component: AccountFinancialInformationDetailComponent,
      },
    ],
  },
];

export const accountFinancialInformationRouting: ModuleWithProviders<AccountFinancialInformationModule> =
  RouterModule.forChild(accountFinancialInformationRoutes);
