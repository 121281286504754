import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { PersonAPI } from '../shared/api.service';
import { ACL } from '../../shared/acl/acl.service';

interface Stat {
  user_id: string;
  name: string;
  completed: number;
  total: number;
  needed: number;
  percent: number;
  remaining: number;
  underperformer: boolean;
  completedOut: number;
  completedReadyForCompilation?: number;
  completedDone?: number;
  completedDuplicate?: number;
}

@Component({
  selector: 'dirt-person-id-qc-next',
  templateUrl: './id-qc-next.component.html',
  styleUrls: ['./id-qc-next.component.scss'],
})
export class IdQcNextComponent implements OnInit {
  queue: Stat[] = null;
  idBdQueue: { personalDone; regionDone; regionAll } = null;
  isLoadingNext: boolean = false;
  exceedCap: boolean = false;

  isCompileIdent: boolean;

  constructor(private router: Router, private svcAcl: ACL, private svcPerson: PersonAPI) {}

  ngOnInit() {
    this.isCompileIdent = this.svcAcl.hasCredential('identifyqc.compileident');

    this.getQueue();
  }

  getStatusText(completed, of): string {
    return `${completed}/${of} ${Math.floor((100 * (completed || 0)) / (of || 1))}%`;
  }

  getQueue() {
    if (this.isCompileIdent) {
      this.svcPerson
        .idBdQcQueue()
        .pipe(take(1))
        .subscribe((q) => {
          this.idBdQueue = q;
        });
    } else {
      this.svcPerson
        .idQcQueue()
        .pipe(take(1))
        .subscribe((q) => {
          this.queue = q.sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
          });
        });
    }
  }

  getNext(): void {
    this.isLoadingNext = true;

    this.svcPerson[this.isCompileIdent ? 'findIDBDQCNext' : 'findIDQCNext'](this.exceedCap).subscribe(
      (personId) => {
        if (!personId) {
          return alert('No people to be reviewed at the moment! Go and get some rest ;)');
        }

        this.router.navigate(['/person/detail', personId]);
      },
      (err) => alert('Error!'),
      () => (this.isLoadingNext = false)
    );
  }
}
