import { Component } from '@angular/core';

@Component({
  selector: 'dirt-person',
  template: `
    <dirt-admin-header></dirt-admin-header>
    <div class="container-fluid">
      <section class="dirt-navbar-sub">
        <nav class="navbar navbar-expand-lg navbar-light">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item" *diAcl="'person.list'">
              <a class="nav-link" routerLink="/person" routerLinkActive="is-active">Base Data</a>
            </li>
            <li class="nav-item" *diAcl="'affiliation.list && (person.list || profile.list)'">
              <a class="nav-link" routerLink="/affiliation" routerLinkActive="is-active">Organizations</a>
            </li>
            <li class="nav-item" *diAcl="'organization.list'">
              <a class="nav-link" routerLink="/organization" routerLinkActive="is-active">Organizations new</a>
            </li>
            <li class="nav-item" *diAcl="'profile.list'">
              <a class="nav-link" routerLink="/profile" routerLinkActive="is-active">Profiles</a>
            </li>
          </ul>
        </nav>
        &nbsp;
        <router-outlet></router-outlet>
      </section>
    </div>
  `,
})
export class PersonComponent {}
