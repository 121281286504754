import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AccountAffiliationSuspectListComponent } from './list/list.component';
import { AccountAffiliationSuspectAPI } from './shared/account-affiliation-suspect-api.service';

@NgModule({
  imports: [SharedModule],
  declarations: [AccountAffiliationSuspectListComponent],
  exports: [AccountAffiliationSuspectListComponent],
  providers: [AccountAffiliationSuspectAPI],
})
export class AccountAffiliationSuspectModule {}
