import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { BulkUpdateStateModel } from './bulk-update-state.model';
import {
  AddEntityAction,
  CloseAction,
  InitAction,
  LoadEntitiesAction,
  NextStepAction,
  PrevalidateUpdateAction,
  PreviousStepAction,
  RefreshListAction,
  RemoveEntityAction,
  RunBulkUpdateAction,
  SetFieldValueAction,
  ToggleFieldValueCheckAction,
  UpdateDependentChecksAction,
} from './bulk-update.actions';
import { Mode } from './mode.enum';
import { Step } from './step.enum';
import { FieldType } from './field.config';
import { BulkUpdateOptionsProvider } from './bulk-update-options-provider';
import { BulkTrackingAPI } from '../bulk/shared/api.service';
import { BulkTracking } from '../bulk/shared/bulk-tracking';
import { Injectable } from '@angular/core';
import { BulkUpdateApi } from './bulk-update-api';

@State<BulkUpdateStateModel>({
  name: 'bulkUpdate',
  defaults: {
    loading: false,
    validating: false,
    mode: null,
    currentStep: null,
    search: '',
    filter: null,
    entities: [],
    hasEu: false,
    values: [],
    updating: false,
    numberRecords: 0,
    numberDone: 0,
    results: [],
  },
})
@Injectable()
export class BulkUpdateState {
  @Selector()
  static mode(state: BulkUpdateStateModel) {
    return state.mode;
  }

  @Selector()
  static loading(state: BulkUpdateStateModel) {
    return state.loading;
  }

  @Selector()
  static validating(state: BulkUpdateStateModel) {
    return state.validating;
  }

  @Selector()
  static values(state: BulkUpdateStateModel) {
    return state.values;
  }

  @Selector()
  static hasEu(state: BulkUpdateStateModel) {
    return state.hasEu;
  }

  @Selector()
  static isValuesSet(state: BulkUpdateStateModel) {
    return (
      !state.values.filter((value) => value.check && value.config.required && !value.value).length &&
      state.values.filter((value) => value.check).length > 0
    );
  }

  @Selector()
  static currentStep(state: BulkUpdateStateModel) {
    return state.currentStep;
  }

  @Selector()
  static count(state: BulkUpdateStateModel) {
    return state.entities.length;
  }

  @Selector()
  static updating(state: BulkUpdateStateModel) {
    return state.updating;
  }

  @Selector()
  static numberRecords(state: BulkUpdateStateModel) {
    return state.numberRecords;
  }

  @Selector()
  static numberDone(state: BulkUpdateStateModel) {
    return state.numberDone;
  }

  @Selector()
  static entities(state: BulkUpdateStateModel) {
    return state.entities;
  }

  @Selector()
  static validCount(state: BulkUpdateStateModel) {
    return state.entities.filter((o) => o.invalid === false).length;
  }

  @Selector()
  static invalidCount(state: BulkUpdateStateModel) {
    return state.entities.filter((o) => o.invalid === true).length;
  }

  @Selector()
  static updatedCount(state: BulkUpdateStateModel) {
    return state.results.filter((o) => o.success).length;
  }

  @Selector()
  static errorCount(state: BulkUpdateStateModel) {
    return state.results.filter((o) => !o.success).length;
  }

  @Selector()
  static errors(state: BulkUpdateStateModel) {
    return state.results
      .filter((o) => !o.success)
      .map((res) => ({
        entity: state.entities.find((entity) => entity.id === res.id),
        error: res.error,
      }))
      .filter((err) => err.entity);
  }

  @Selector()
  static search(state: BulkUpdateStateModel) {
    return state.search;
  }

  @Selector()
  static filter(state: BulkUpdateStateModel) {
    return state.filter;
  }

  constructor(
    public bulkUpdateOptionsProvider: BulkUpdateOptionsProvider,
    private readonly svcBulkTracking: BulkTrackingAPI
  ) {}

  @Action(InitAction)
  initAction(ctx: StateContext<BulkUpdateStateModel>, action: InitAction) {
    const {
      fields,
      srvApi: { simple },
    } = this.bulkUpdateOptionsProvider.getOptions();
    ctx.patchState({
      mode: action.mode,
      search: action.search,
      filter: action.filter,
      currentStep: simple && Mode.filter === action.mode ? Step.second : Step.first,
      entities: [],
      values: fields.map((field) => ({
        check: false,
        config: field,
        value: null,
      })),
      updating: false,
      results: [],
    });

    if (action.mode === Mode.filter) {
      ctx.dispatch(new LoadEntitiesAction());
    }
  }

  @Action(LoadEntitiesAction)
  loadEntitiesAction(ctx: StateContext<BulkUpdateStateModel>) {
    const { srvApi } = this.bulkUpdateOptionsProvider.getOptions();
    if (srvApi.simple) {
      return;
    } // else do load from backend
    ctx.patchState({
      loading: true,
    });
    const state = ctx.getState();
    return (srvApi as BulkUpdateApi<any>).find(state.search, 5000, 0, null, state.filter).pipe(
      tap((entities) => {
        ctx.patchState({
          loading: false,
          entities: entities,
          hasEu: entities.filter((ent) => ent.eu).length > 0,
        });
      })
    );
  }

  @Action(NextStepAction)
  nextStepAction(ctx: StateContext<BulkUpdateStateModel>) {
    const state = ctx.getState();
    if (state.currentStep === Step.first) {
      ctx.patchState({
        currentStep: Step.second,
      });
    } else if (state.currentStep === Step.second) {
      ctx.patchState({
        currentStep: Step.third,
      });
      ctx.dispatch(new RunBulkUpdateAction());
    }
  }

  @Action(PreviousStepAction)
  previousStepAction(ctx: StateContext<BulkUpdateStateModel>) {
    const state = ctx.getState();
    if (state.currentStep === Step.second) {
      ctx.patchState({
        currentStep: Step.first,
      });
    } else if (state.currentStep === Step.third && !state.updating) {
      ctx.patchState({
        currentStep: Step.second,
      });
    }
  }

  @Action(SetFieldValueAction)
  setFieldValueAction(ctx: StateContext<BulkUpdateStateModel>, action: SetFieldValueAction) {
    const state = ctx.getState();
    const valueIndex = state.values.findIndex((value) => value.config.field === action.field);
    const before = state.values.slice(0, valueIndex);
    const after = state.values.slice(valueIndex + 1);
    ctx.patchState({
      values: [
        ...before,
        {
          ...state.values[valueIndex],
          value: action.value,
        },
        ...after,
      ],
    });
    ctx.dispatch(new UpdateDependentChecksAction());
  }

  @Action(UpdateDependentChecksAction)
  updateDependentChecksAction(ctx: StateContext<BulkUpdateStateModel>) {
    const state = ctx.getState();
    ctx.patchState({
      values: state.values.map((value) => {
        if (!value.config.handleCheck) {
          return value;
        } else {
          const check = value.config.handleCheck(state.values);
          return {
            ...value,
            check,
            value: check ? value.value : null,
          };
        }
      }),
    });
  }

  @Action(ToggleFieldValueCheckAction)
  toggleFieldValueCheckAction(ctx: StateContext<BulkUpdateStateModel>, action: ToggleFieldValueCheckAction) {
    const state = ctx.getState();
    const valueIndex = state.values.findIndex((value) => value.config.field === action.field);
    const fieldValue = state.values[valueIndex];
    const before = state.values.slice(0, valueIndex);
    const after = state.values.slice(valueIndex + 1);
    ctx.patchState({
      values: [
        ...before,
        {
          ...fieldValue,
          value: !fieldValue.check ? fieldValue.value : null,
          check: !fieldValue.check,
        },
        ...after,
      ],
    });
    ctx.dispatch(new UpdateDependentChecksAction());
  }

  @Action(RunBulkUpdateAction)
  runBulkUpdateAction(ctx: StateContext<BulkUpdateStateModel>) {
    const { srvApi } = this.bulkUpdateOptionsProvider.getOptions();
    const state = ctx.getState();
    ctx.patchState({
      updating: true,
      numberRecords: 0,
      numberDone: 0,
      results: [],
    });
    const resultUpdates: BehaviorSubject<any> = new BehaviorSubject(0); // # done (does not matter outside it changes)
    let updateSelector: { ids; condition? } = { ids: [] };
    if (srvApi.simple) {
      if (Mode.manual === state.mode) {
        updateSelector = {
          ids: state.entities.map((entity) => entity.id),
        };
      } else if (Mode.filter === state.mode) {
        updateSelector = {
          ids: (state.search || '').split(/[\W, ]+/).filter((s) => !!s),
          condition: state.filter,
        };
      }
    } else {
      updateSelector = {
        ids: state.entities.map((entity) => entity.id),
      };
    }
    srvApi
      .bulkUpdate({
        ...updateSelector,
        values: state.values.reduce((data, value) => {
          if (value.check) {
            let fieldValue;
            if (value.config.type === FieldType.user) {
              fieldValue = value.value.user_id;
            } else if (value.config.type === FieldType.multiUser) {
              fieldValue = value.value.map((user) => user.user_id);
            } else {
              fieldValue = value.value;
            }
            data[value.config.field] = fieldValue;
          }
          return data;
        }, {}),
      })
      .toPromise() // only returns Promise<{ bulkTrackingId }>
      .then((res: { bulkTrackingId }) => {
        const CHECK_INTERVAL = 3000; // ms
        const doCheck = async () => {
          const track: BulkTracking = await this.svcBulkTracking.findById(res.bulkTrackingId).toPromise();
          const numberDone = (track.numberSuccess || 0) + (track.numberFailure || 0);
          ctx.patchState({
            updating: !track.complete,
            numberRecords: track.numberRecords || 0,
            numberDone,
            results: track.results || [],
          });
          resultUpdates.next(numberDone);
          if (track.complete) {
            resultUpdates.complete(); // overall behavior is the same as before
          } else {
            setTimeout(doCheck, CHECK_INTERVAL);
          }
        };
        doCheck(); // (no return on purpose, continue indep'ly)
      })
      .catch((e) => {
        resultUpdates.error(e);
      });
    return resultUpdates.asObservable(); // next() / closed() called above
  }

  @Action(AddEntityAction)
  addEntityAction(ctx: StateContext<BulkUpdateStateModel>, action: AddEntityAction) {
    const state = ctx.getState();
    const entities = (action.id || '').split(/[ ;,]/).map((id) => ({ id }));
    ctx.patchState({
      entities: [...state.entities, ...entities],
    });
  }

  @Action(RemoveEntityAction)
  removeEntityAction(ctx: StateContext<BulkUpdateStateModel>, action: RemoveEntityAction) {
    const state = ctx.getState();
    const entityIndex = state.entities.findIndex((entity) => entity.id === action.id);
    const before = state.entities.slice(0, entityIndex);
    const after = state.entities.slice(entityIndex + 1);
    ctx.patchState({
      entities: [...before, ...after],
    });
  }

  @Action(PrevalidateUpdateAction)
  prevalidateUpdateAction(ctx: StateContext<BulkUpdateStateModel>) {
    const { srvApi } = this.bulkUpdateOptionsProvider.getOptions();
    if (srvApi.simple) {
      return;
    }
    ctx.patchState({
      validating: true,
    });
    const state = ctx.getState();
    return (srvApi as BulkUpdateApi<any>).exist(state.entities.map((o) => o.id)).pipe(
      tap((results) => {
        ctx.patchState({
          validating: false,
          entities: state.entities.map((entity, index) => ({
            ...entity,
            ...results[index], // {invalid, eu?}
          })),
          hasEu: results.filter((r) => r.eu).length > 0,
        });
      })
    );
  }

  @Action(CloseAction)
  closeAction(ctx: StateContext<BulkUpdateStateModel>) {
    const state = ctx.getState();
    if (
      state.currentStep === Step.third &&
      /*state.mode === Mode.filter && - manual also changes what's displayed */ !state.updating
    ) {
      ctx.dispatch(new RefreshListAction());
    }
  }
}
