import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
  transform(val: string, separator?: string, joiner?: string): string {
    if (!val) {
      return;
    }

    if (separator) {
      const tokens = val.split(separator);
      const result = [];

      for (let i = 0; i < tokens.length; i++) {
        result.push(this.apply(tokens[i]));
      }

      return result.join(joiner ? joiner : ' ');
    } else {
      return this.apply(val);
    }
  }

  private apply(val: string): string {
    return (val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()).replace(/_/g, ' ');
  }
}
