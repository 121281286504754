import { Injectable } from '@angular/core';
import { firstValueFrom, from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JwtHelperService } from '@auth0/angular-jwt';

import { PUT, POST, Path, Body, MediaType, Produces, PATCH } from '../../../../shared/services/rest/rest.service';
import { SubentityApiService } from '../../common/subentity-api.service';
import { ProfileContribution } from '../../../shared/profile-contribution';
import { Utils } from '../../../../common/utils';
import { ACL } from '../../../../shared/acl/acl.service';
import { PersonAPI } from '../../../../person/shared/api.service';
import { filterStream, parseJSONStream, splitStream } from '../../stream.utils';
import { Profile } from '../../../shared/profile';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { Roles } from '../../../../shared/acl/roles';

type ProfileContributionRaw = {
  person_id: string;
  contribution_id: string;
  match_result: string;
  match_source?: string;
  fas?: string; // split by @
  fas_ids?: string; // split by @
  ml_exclude?: 't' | 'f' | true | false;
};

type ContributionBaseInfo = {
  contributionId: string;
  firstName: string;
  middleName: string;
  lastName: string;
  fullName: string;
  workplace: string;
  position: string;
  title: string;
  session: {
    name: string;
  };
  event: {
    name: string;
    startDate: Date;
    webSource: string;
    address: {
      city: string;
      country: string;
    };
  };
};

@Injectable()
export class ProfileContributionAPI extends SubentityApiService<
  ProfileContribution,
  { id: string; _profile: ProfileContribution },
  ProfileContribution[],
  any
> {
  constructor(
    http: HttpClient,
    svcModal: NgbModal,
    svcAcl: ACL,
    svcJwtHelper: JwtHelperService,
    private svcPerson: PersonAPI
  ) {
    super(http, svcModal, svcAcl, svcJwtHelper);
  }

  @POST('contributions/base-info')
  @Produces(MediaType.JSON)
  public getContributionBaseInfo(@Body pmids: string[]): Observable<ContributionBaseInfo[]> {
    return;
  }

  public findAll(profile: Profile): Observable<ProfileContribution[]> {
    return from(this.findAllAutomated(profile));
  }

  protected async enrichAll(
    profile: Profile,
    allList: ProfileContribution[]
  ): Promise<{ id: string; _profile: ProfileContribution }[]> {
    const curatedMatches = await this.findAllCurated(profile);
    const curatedMatchesById = new Map(curatedMatches.map((match) => [match.id, match]));
    const mappedCuratedMatches = new Set<string>(); // track curated matches that were mapped to an automated match for later

    // 1 - Augment auto matches with manual info
    allList.forEach((match) => {
      if (!curatedMatchesById.has(match.id)) {
        return;
      }

      const curatedMatch = curatedMatchesById.get(match.id);

      mappedCuratedMatches.add(match.id);

      match.mongoId = curatedMatch.mongoId;

      if (curatedMatch.match) {
        match.match.manual = curatedMatch.match.manual;
        match.match.pendingSubmission = curatedMatch.match.pendingSubmission;
      }

      match.compiledAt = curatedMatch.compiledAt;
      match.qc = curatedMatch.qc;
    });

    // 2 - Append fully manual matches
    const remainingCurated = curatedMatches.filter(
      (item) =>
        !mappedCuratedMatches.has(item.id) &&
        (ProfileMatchManual.MATCH === item.match?.manual || ProfileMatchManual.MISMATCH === item.match?.manual)
    );
    let completeAllList = [...allList, ...remainingCurated];

    // Given we don't have manual info in ensureLoaded before calling enrichAll, we need to filter what's already matched here for compilers.
    // Will need to do exactly the same thing for the other sources when progressively switching them to outbox.

    // for compilers outside training and (usual) view mode delta, we could speed up (ViewModeFilter will _always_ filter out the same ones) - load way less base-info etc around the globe:
    if (
      this.svcAcl.userRoles?.includes(Roles.ProfileCompiler) &&
      !profile.training &&
      profile.viewMode?.endsWith('_DELTA')
    ) {
      completeAllList = completeAllList.filter(
        (item) => item.match?.manual === 'NONE' || item.match?.pendingSubmission
      );
    }

    // 3 - Augment with source info and transform
    const contributionIds = [...new Set(completeAllList.map((match) => match.contributionId))]; // make sure we don't fetch the same thing multiple time
    const contributionsById = new Map<string, ContributionBaseInfo>();

    const PAGE_SIZE = 2500;
    for (let i = 0; i < contributionIds.length; i += PAGE_SIZE) {
      const contributions = await firstValueFrom(this.getContributionBaseInfo(contributionIds.slice(i, i + PAGE_SIZE)));
      contributions.forEach((contribution) => contributionsById.set(contribution.contributionId, contribution));
    }

    return completeAllList
      .filter((match) => contributionsById.has(match.contributionId)) // Those without data in source are not relevant
      .map((match) => {
        // augment with source and transform
        const contribution = contributionsById.get(match.contributionId);

        return {
          ...contribution, // (journal, abstract, etc.)
          id: match.id, // replace w/ mongo ID when curated
          mongoId: match.mongoId,
          contributionId: match.contributionId,
          _profile: {
            // (the match as such)
            ...match,
            match: {
              ...match.match,
              mlAnyway:
                Math.abs(
                  Utils.hash((match as any).person?.kolId + '' + (match.contributionId || 'null').toString()) % 100
                ) < 1,
            },
            focusAreas: match.focusAreas?.map((fa) => (typeof fa === 'string' ? fa : (fa as any).name)),
            // focusAreaIds: not needed (no asia filter)
          },
        };
      });
  }

  protected async loadAndEnrichOrigins(
    profileId: string,
    kolId: string
  ): Promise<{ id; _profile: ProfileContribution }[]> {
    const person = await firstValueFrom(this.svcPerson.findById(kolId));
    const ctrIds: string[] = (person as any)?.additionalDetails?.automatedProfileIds?.eventContributionIds || [];
    if (ctrIds.length < 1) {
      return [];
    }

    // load contributions
    const ctrsList = await firstValueFrom(this.getContributionBaseInfo(ctrIds));
    const ctrsMap = {};
    ctrsList.forEach((c) => (ctrsMap[c.contributionId] = c));

    // do transform into the format we used - from here fw, we should not need any change
    const transformed = ctrIds.map((ctrId) => ({
      // (pretty simple transform if you break it down)
      ...ctrsMap[ctrId], // (journal, abstract, etc.)
      id: null,
      contributionId: ctrId,
      _profile: {
        // (the match as such + first author from pub)
        match: { automate: 'PB' },
        focusAreas: [],
      },
    }));

    return transformed;
  }

  @PATCH('profiles/{id}/contributions')
  @Produces(MediaType.JSON)
  public updateImpl(@Path('id') id: string, @Body matches: ProfileContribution[]): Observable<ProfileContribution[]> {
    return;
  }

  @PUT('profiles/{id}/contributions/rel/{fk}')
  @Produces(MediaType.JSON)
  public linkImpl(@Path('id') id: string, @Path('fk') contributionId: string): Observable<ProfileContribution> {
    return;
  }

  private async findAllAutomated(profile: Profile): Promise<ProfileContribution[]> {
    const options: RequestInit = { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } };
    const response: Response = await fetch(
      this.getBaseUrl() + 'profiles/' + profile.id + '/contributions/automated',
      options
    ); // (we want a stream now)
    if (!response.ok) {
      throw new Error('Failed to download automated matches');
    }

    // For automated matches, we need to get the base profile matches, the clone will not have anything under its name.
    const identifier = profile.training ? profile.baseProfileKolId : profile.person.kolId;
    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(splitStream('\n'))
      .pipeThrough(filterStream((row: string) => row.includes(identifier))) // save us some JSON parsing
      .pipeThrough(parseJSONStream())
      .pipeThrough(filterStream((row: ProfileContributionRaw) => row.person_id === identifier)) // Catch the very few cases that we could leave out
      .pipeThrough(transformToMatch())
      .getReader();

    const matches: ProfileContribution[] = [];

    while (true) {
      const result = await reader.read(); // Leave timeout to Nginx

      if (!result) {
        // Nothing in the stream
        break;
      }

      if (result.done) {
        break; // Everything was read
      }

      matches.push(result.value);
    }

    // We're done
    reader.cancel();

    return matches;
  }

  private async findAllCurated(profile: Profile): Promise<ProfileContribution[]> {
    const options: RequestInit = { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } };
    const response: Response = await fetch(
      this.getBaseUrl() + 'profiles/' + profile.id + '/contributions/curated',
      options
    ); // (we want a stream now)
    if (!response.ok) {
      throw new Error('Failed to download curated matches');
    }

    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(splitStream('\n'))
      .pipeThrough(parseJSONStream())
      .getReader();

    const matches: ProfileContribution[] = [];

    while (true) {
      const result = await reader.read(); // Leave timeout to Nginx

      if (!result) {
        // Nothing in the stream
        break;
      }

      if (result.done) {
        break; // Everything was read
      }

      this.postProcessFromMongo(result.value);
      matches.push(result.value);
    }

    // We're done
    reader.cancel();

    return matches;
  }

  protected postProcessFromMongo(item: ProfileContribution) {
    // also what is coming back from updates
    item.mongoId = item.id;
    item.id = 't-' + Utils.hash(getUniqueIdent(item));
  }
}

function getUniqueIdent(item: ProfileContribution): string {
  return [
    // item.person?.kolId, // we already work with a single KP, so having KOL ID here is redundant and detrimental when we want to merge base profile auto matches with cloned profile manual matches.
    item.contributionId,
  ]
    .map((s) => s || '')
    .join('|'); // had the same previously in backend as buildFilter (same fields)
}

function mapRawToModel(chunk: ProfileContributionRaw): ProfileContribution {
  const fas = (chunk.fas || '').split('@').filter((s) => !!s);
  const faIds = (chunk.fas_ids || '').split('@').filter((s) => !!s);

  const match: ProfileContribution = {
    id: null, // (filled below)
    contributionId: chunk.contribution_id,
    person: { kolId: chunk.person_id },
    match: {
      automate: chunk.match_result,
      manual: ProfileMatchManual.NONE, // Will enrich via curated matches
      matchSource: chunk.match_source,
      mlExclude: 't' === chunk.ml_exclude || true === chunk.ml_exclude,
    },
    focusAreas: fas.map((fa, i) => ({ name: fa, id: faIds[i] })),

    // filled later
    firstName: null,
    middleName: null,
    lastName: null,
    fullName: null,
    workplace: null,
    position: null,
    title: null,
    event: null,
    session: null,
  };

  match.id = 't-' + Utils.hash(getUniqueIdent(match));
  return match;
}

function transformToMatch() {
  return new TransformStream({
    transform(chunk: ProfileContributionRaw, controller) {
      const match = mapRawToModel(chunk);
      controller.enqueue(match);
    },
  });
}
