export class Affiliation {
  id: string;
  name: string;
  opName?: string;
  department?: string;
  opDepartment?: string;
  type: string;
  originalName?: string;
  originalDepartment?: string;
  primary: boolean;
  lastKnown?: boolean;
  inactive: boolean;
  verified: boolean;
  source?: string;
  sourceLfka?: boolean;
  address?: {
    additionalInfo?: string;
    city?: string;
    opCity?: string;
    country?: string;
    opCountry?: string;
    countryCode?: string;
    state?: string;
    name?: string;
    street?: string;
    zip?: string;
    originalCity?: string;
    originalStreet?: string;
    originalAdditionalInfo?: string;
    frozenGeo?: boolean;
  };
  originalAddr?: {
    city?: string;
    name?: string;
    additionalInfo?: string;
  };

  departments?: Affiliation[];
  duplicateSuspect?: boolean;
  duplicateSuspectIds?: string[];
  createdAt: Date;
  opCreatedAt: Date;
  updatedAt: Date;
  _version: number;

  webSource: string;
  _meta: {
    assignee?: string;
    workflow?: string;
    claimedUntil?: Date;
    jobId?: string;
    status: string;
  };
  published?: boolean;

  mergedAffiliationIds: string[];

  countryCode?: string;

  readyForDelivery?: boolean; // falsy = using entities are on hold

  usageInfo?: {
    // when we submit a new unpolished affiliation, capture some more data for central curation (this is only saved in job)
    customerRequest?: boolean;
    comment?: string;
    link?: string;
    requestOwnerProduct?: string;
  };

  originalLanguage?: string;

  primaryCandidate?: boolean; // will replace the current primary when no longer unpolished

  constructor() {}
}
