import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AccountAffiliationListComponent } from './list/list.component';
import { AccountAffiliationAPI } from './shared/account-affiliation-api.service';
import { AccountAffiliationModalComponent } from './shared/modal/affiliation/affiliation.component';
import { AccountAffiliationModalService } from './shared/modal/affiliation/affiliation.service';

@NgModule({
  imports: [SharedModule],
  declarations: [AccountAffiliationListComponent, AccountAffiliationModalComponent],
  exports: [AccountAffiliationListComponent],
  providers: [AccountAffiliationModalService, AccountAffiliationAPI],
})
export class AccountAffiliationModule {}
