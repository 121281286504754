import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EventSeriesListComponent } from './list/list.component';
import { EventSeriesFormComponent } from './shared/form/form.component';
import { EventSeriesAPI } from './shared/api.service';
import { EventSeriesDetailComponent } from './detail/detail.component';
import { eventSeriesRouting } from './event-series.routes';
import { EventSeriesCreateComponent } from './create/create.component';
import { EventSeriesEventList } from './shared/event-list/event-list.component';
import { CommentModule } from '../comment/comment.module';
import { SharedModule } from '../shared/shared.module';
import { JobsModule } from '../jobs/jobs.module';

@NgModule({
  imports: [CommonModule, SharedModule, CommentModule, JobsModule, eventSeriesRouting],
  declarations: [
    EventSeriesListComponent,
    EventSeriesFormComponent,
    EventSeriesDetailComponent,
    EventSeriesCreateComponent,
    EventSeriesEventList,
  ],
  providers: [EventSeriesAPI],
})
export class EventSeriesModule {}
