import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { Comment } from '../shared/comment';
import { CommentAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-comment-create',
  templateUrl: 'create.component.html',
  styleUrls: ['create.component.scss'],
})
export class CommentCreateComponent implements OnInit {
  @Input()
  ownerId: string;

  @Input()
  showBtnSubmit = false;

  @Input()
  showBtnCancel = false;

  @Input()
  btnSubmitCaption: string;

  @Input()
  existingContent: string;

  @Input()
  commentId: string;

  @Output()
  onSubmitted = new EventEmitter<Comment>();

  @Output()
  onCancelled = new EventEmitter();

  @Output()
  onUpdated = new EventEmitter<Comment>();

  isSaving = false;
  content: string;

  constructor(private svcComment: CommentAPI) {}

  ngOnInit(): void {
    if (!this.ownerId) {
      throw new Error('ownerId is required');
    }

    if (this.existingContent) {
      this.content = this.existingContent;
    }
  }

  doCreate(): void {
    this.isSaving = true;

    const comment = <Comment>{
      content: this.content,
      owner: this.ownerId,
    };

    this.svcComment.create(comment).subscribe(
      (resp) => this.onSubmitted.emit(resp),
      () => (this.isSaving = false),
      () => {
        this.content = '';
        this.isSaving = false;
      }
    );
  }

  doUpdate(): void {
    const comment = <Comment>{
      content: this.content,
      owner: this.ownerId,
    };

    this.svcComment.update(this.commentId, comment).subscribe(
      (resp) => {
        this.content = resp.content;
        this.onUpdated.emit(resp);
      },
      () => (this.isSaving = false),
      () => {
        this.content = '';
        this.isSaving = false;
      }
    );
  }

  onKeyDown(e: KeyboardEvent): void {
    e.stopPropagation();
    // let the submit button handle the process!
    if (this.showBtnSubmit) {
      return;
    }

    // Only pass through if Enter pressed with a valid content
    if (e.keyCode !== 13 || e.shiftKey || this.content.trim() === '') {
      return;
    }

    e.preventDefault();
    this.commentId ? this.doUpdate() : this.doCreate();
  }

  onCancel(): void {
    this.onCancelled.emit();
  }
}
