import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Sponsor } from '../shared/sponsor';
import { SponsorAPI } from '../shared/api.service';
import { SponsorFormComponent } from '../shared/form/form.component';

@Component({
  selector: 'dirt-sponsor-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class SponsorCreateComponent {
  sponsor: Sponsor = new Sponsor();
  isSubmitting = false;

  @ViewChild(SponsorFormComponent)
  sponsorForm: SponsorFormComponent;

  constructor(private router: Router, private svcSponsor: SponsorAPI) {}

  onSubmit(sponsor: Sponsor): void {
    this.sponsorForm.originalNameExists().subscribe((alreadyExists) => {
      if (alreadyExists) {
        alert('Original Name already exists. Please try again!');
        return;
      }
      this.isSubmitting = true;
      this.svcSponsor.create(sponsor).subscribe(
        (resp) => {
          this.isSubmitting = false;
          alert('Sponsor created successfully');
          this.reloadPage();
        },
        (error) => {
          if (error.status === 412 && error.error?.potentialDuplicate) {
            alert('Normalized name already exists with a different companyId!');
          }
          this.isSubmitting = false;
        }
      );
    });
  }

  goBack(): void {
    this.router.navigate(['/sponsor']);
  }

  reloadPage(): void {
    window.location.reload();
  }
}
