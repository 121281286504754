import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute, PRIMARY_OUTLET } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, take } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ACL } from '../../shared/acl/acl.service';
import { Event } from '../shared/event';
import { EventAPI } from '../shared/api.service';
import { Value } from '../../shared/services/value/value';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { SortConfig } from '../../shared/directives/data-table/sort-cofig';
import { buildBulkUpdateConfig } from '../shared/config/bulk-update-config';
import { BulkUpdateOptions } from '../../common/bulk-update/bulk-update-options';
import { BulkTrackingAPI } from '../../common/bulk/shared/api.service';
import { BulkModalComponent, BULK_MODAL_OPTIONS } from '../../common/bulk/component/bulk-modal/bulk-modal.component';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { MoveContributionService } from '../../contribution/shared/move-contribution-modal/move-contribution.service';
import { Contribution } from '../../contribution/shared/contribution';
import { SponsorAPI } from '../../sponsors/shared/api.service';
import { Sponsor } from '../../sponsors/shared/sponsor';
import { EventAdvancedSearchDto } from '../shared/event-advanced-search-dto';
import { isNil } from 'lodash';
import { JobsAPI } from '../../jobs/shared/api.service';

const LS_ADVANCED_SEARCH_KEY = 'clean-events-advanced-search';

@Component({
  selector: 'dirt-event-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class EventListComponent implements OnInit {
  @Input()
  parentId: string;

  @Input()
  disableDragAndDrop: boolean;

  @Output()
  verifiedCountChanged: EventEmitter<number> = new EventEmitter(); // delta of verified

  events: Array<Event>;
  isLoading: boolean;
  isQueryParamsRestored = false;

  // pagination settings
  total: any;
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  sort = '-_id';
  sortConfig: SortConfig;
  filter: any;

  searchTerm: string;
  searchCtrl: FormControl = new FormControl('');

  countryValues: Value[] = [];

  sponsors: Sponsor[] = [];
  sponsorsMap: {};

  bulkUpdateConfig: BulkUpdateOptions<EventAPI, Event>;

  wndw: Window = window; // be able to override in tests

  bulkListDelegate: BulkDelegate = {
    title: 'Bulk Import',
    type: BulkType.LIST,
    specialHint: 'Update event attributes - for contributions see there',
    specificStartFct: this.handleBulkListUpload.bind(this),
    specificNotifyReloadFct: this.getEvents.bind(this),
  };

  bulkSearchDelegate: BulkDelegate = {
    title: 'Bulk Search',
    type: BulkType.SEARCH,
    specialHint: 'Perform a bulk Google research with spreadsheet data',
    specificStartFct: this.handleBulkSearchUpload.bind(this),
  };

  jobBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import Job',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListJobUpload.bind(this),
    specificNotifyReloadFct: this.getEvents.bind(this),
  };

  allReviewed = false;
  allVerified = false;

  advancedSearchSpec: EventAdvancedSearchDto = null;
  @ViewChild('advancedSearchDialog', { read: ElementRef }) advancedSearchDialogElement: ElementRef;

  @Output() onContributionMoveUpdate = new EventEmitter<any>();

  constructor(
    private router: Router,
    private svcEvent: EventAPI,
    private svcAcl: ACL,
    private svcValue: ValueAPI,
    private svcModal: NgbModal,
    private readonly activatedRoute: ActivatedRoute,
    private svcBulkUpdate: BulkTrackingAPI,
    private svcMoveContributionModal: MoveContributionService,
    private svcSponsor: SponsorAPI,
    private svcJob: JobsAPI
  ) {}

  ngOnInit() {
    this.bulkUpdateConfig = buildBulkUpdateConfig(this.svcEvent);
    if (this.parentId) {
      this.sortConfig = {
        resetSort: true,
        defaultSortField: '-_id',
      };
    }

    this.restoreFromQueryParams();

    this.searchCtrl.valueChanges.pipe(distinctUntilChanged(), debounceTime(400)).subscribe((val) => this.onSearch(val));

    this.svcValue
      .find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.countryValues = data;
      });

    this.svcSponsor
      .getSponsors()
      .toPromise()
      .then((data) => {
        this.sponsors = data;
        this.sponsorsMap = {};
        data.forEach((curr) => (this.sponsorsMap[curr.companyId] = curr.normalizedName)); // no ... - performance
      });
  }

  getCountryTitle(code: string): string {
    return (this.countryValues.find((o) => o.code === code) || { title: '' }).title;
  }

  getEvents() {
    this.isLoading = true;
    const observables = [
      this.svcEvent.find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.filter, this.parentId),
    ];

    if (this.filter && this.filter['_meta.assignee'] && this.filter['_meta.assignee'].length > 0) {
      const { '_meta.assignee': assignee, ...remainingFilters } = this.filter;
      observables.push(
        this.svcEvent.find(
          this.searchTerm,
          this.pagingLimit,
          this.pagingSkip,
          this.sort,
          {
            ...remainingFilters,
            '_meta.lastAssignee': assignee,
            '_meta.status': 'DONE',
          },
          this.parentId
        )
      );
    }

    forkJoin(observables).subscribe(
      ([result1, result2 = []]) => {
        this.events = [...result1, ...result2];
      },
      null,
      () => {
        this.isLoading = false;
        this.setAllReviewedFlag();
        this.setAllVerifiedFlag();
      }
    );
  }

  getCount() {
    const observables = [this.svcEvent.count(this.parentId, this.searchTerm, this.filter)];

    if (this.filter && this.filter['_meta.assignee'] && this.filter['_meta.assignee'].length > 0) {
      const { '_meta.assignee': assignee, ...remainingFilters } = this.filter;
      observables.push(
        this.svcEvent.count(this.parentId, this.searchTerm, {
          ...remainingFilters,
          '_meta.lastAssignee': assignee,
          '_meta.status': 'DONE',
        })
      );
    }

    forkJoin(observables).subscribe(([result1, result2 = { count: 0 }]) => {
      this.total = {
        count: result1.count + result2.count,
      };
    });
  }

  getPage(page: number) {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getEvents();
  }

  navigateTo(route, e) {
    if (e && /dirt-event-name|star-icon/.test(e.target.className)) {
      return;
    }

    if (this.parentId) {
      route.push({ parentId: this.parentId });
    }

    this.router.navigate(route);
  }

  resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  onSort(field: string): void {
    this.sort = field;
    this.storeAsQueryParams();
    this.resetPagination();
    this.getEvents();
  }

  onSearch(name: string): void {
    if (this.parentId && !this.isQueryParamsRestored) {
      return;
    }
    this.storeAsQueryParams();
    this.resetPagination();
    this.getCount();
    this.getEvents();
  }

  onDelete(eventId: string, $event) {
    $event.stopPropagation();

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.svcEvent.deleteById(eventId).subscribe(() => {
      this.getEvents();
      this.getCount();
    });
  }

  setFilter(filter: any): void {
    this.filter = filter;

    this.resetPagination();
    this.getEvents();
    this.getCount();
  }

  getSponsorsName(sponsors: string[] = []): string[] {
    if (this.sponsorsMap) {
      return sponsors.map((sp) => this.sponsorsMap[sp]);
    }
  }

  /**
   * Store applied search text as route query params
   */
  storeAsQueryParams(): void {
    if (this.searchTerm || this.sort) {
      const filters = {
        ...(this.searchTerm && { searchTerm: this.searchTerm }),
        ...(this.sort && { sortField: this.sort }),
      };
      this.router.navigate([], { queryParams: filters });
    }
  }

  onBulkUpdateRefresh() {
    this.getEvents();
  }

  /**
   * Restore filters saved as query params
   */
  restoreFromQueryParams(): void {
    const tree = this.router.parseUrl(this.router.url);
    const segmentGroup = tree.root.children[PRIMARY_OUTLET];
    if (
      segmentGroup?.segments?.map((it) => it.path).join('/') !== 'event/list' &&
      segmentGroup?.segments
        ?.slice(0, 2)
        ?.map((it) => it.path)
        .join('/') !== 'event/detail'
    ) {
      // do not needlessly restore filters in pages that don't need it
      return;
    }

    this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      if (params.searchTerm) {
        this.searchTerm = params.searchTerm;
      }
      if (params.sortField) {
        this.sort = params.sortField;
        this.sortConfig = {
          ...this.sortConfig,
          initialSortField: params.sortField,
        };
      }
      this.isQueryParamsRestored = true;
      this.onSearch(this.searchTerm);
    });
  }

  hasAclBulkUpdate() {
    return this.svcAcl.hasCredential('event.list.bulkUpdate');
  }
  hasAclBulkList() {
    return this.svcAcl.hasCredential('event.bulkList');
  }
  hasOnlyAclBulkList() {
    return !this.hasAclBulkUpdate() && this.hasAclBulkList();
  }

  canSetReviewedFlag(): boolean {
    return this.svcAcl.hasCredential('event.update.prop.reviewed');
  }

  canSetVerifiedFlag(): boolean {
    return this.svcAcl.hasCredential('event.update.prop.verified');
  }

  toggleAllReviewed($event: any) {
    $event.stopPropagation();
    const newReviewed = !!$event.target.checked;
    this.isLoading = true;
    this.bulkUpdate({
      reviewed: newReviewed,
    });
  }

  toggleAllVerified($event: any) {
    $event.stopPropagation();
    const newVerified = !!$event.target.checked;
    const changedCount = this.events.filter((ev) => !!ev.verified !== newVerified).length;
    this.isLoading = true;
    this.bulkUpdate({
      verified: newVerified,
    });
    this.verifiedCountChanged.emit(changedCount * (newVerified ? 1 : -1));
  }

  bulkUpdate(values: { [key: string]: any }): Promise<void> {
    this.isLoading = true;
    if (this.parentId) {
      return this.svcEvent
        .bulkUpdate({
          ids: this.events.map((mb) => mb.id),
          values,
        })
        .toPromise()
        .then((resp) => {
          this.bulkUpdateStatus(resp.bulkTrackingId).then(() => {
            this.getEvents();
            return;
          });
        });
    }
  }

  onSessionReviewedChange(event: Event, $event: any) {
    $event.stopPropagation();
    this.onEdit(
      {
        reviewed: !!$event.target.checked,
      } as Event,
      event.id
    ); // do a partial update only
  }

  onSessionVerifiedChange(event: Event, $event: any) {
    $event.stopPropagation();
    this.onEdit(
      {
        verified: !!$event.target.checked,
      } as Event,
      event.id
    ); // do a partial update only
    this.verifiedCountChanged.emit(event.verified ? 1 : -1);
  }

  isNeeded(event: Event): boolean {
    return !!event.qc?.needsVerify;
  }

  onEdit(event: Event, id?) {
    this.svcEvent.update(id || event.id, event).subscribe((resp) => {
      this.getEvents();
      this.setAllReviewedFlag();
      this.setAllVerifiedFlag();
    });
  }

  setAllReviewedFlag() {
    this.allReviewed = this.events.every((ev) => ev.parent && ev.reviewed === true);
  }

  setAllVerifiedFlag() {
    this.allVerified = this.events.every((ev) => ev.parent && ev.verified === true);
  }

  bulkUpdateStatus(trakingID: string, triesCountLeft = 5): Promise<void> {
    return new Promise((resolve) => {
      this.svcBulkUpdate
        .findById(trakingID)
        .toPromise()
        .then((resp) => {
          if (!resp.complete) {
            if (!triesCountLeft) return resolve();
            setTimeout(() => {
              resolve(this.bulkUpdateStatus(trakingID, triesCountLeft - 1));
            }, 3000);
          } else resolve();
        });
    });
  }

  // BULK MODAL LOGIC

  onOpenBulkListDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.bulkListDelegate;
  }

  onOpenBulkSearchDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.bulkSearchDelegate;
  }

  private handleBulkListUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcEvent.startBulkListUpload(file, secondEyes);
  }

  private handleBulkSearchUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcEvent.startBulkSearchUpload(file, secondEyes);
  }

  drop(event: CdkDragDrop<Array<Contribution> | Event>) {
    if (this.parentId) {
      let contributionToBeMoved = event.previousContainer.data as Array<Contribution>; //The type of previous container will be contribution as it is being picked up from contribution list
      if (contributionToBeMoved && contributionToBeMoved.filter((mb) => mb.markForMove).length > 0) {
        this.svcMoveContributionModal
          .open(
            contributionToBeMoved.filter((mb) => mb.markForMove) || [],
            this.parentId,
            true,
            this.parentId,
            false,
            event.container.data[event.currentIndex - 1]
          )
          .then((result) => {
            this.getEvents();
            this.getCount();
            this.onContributionMoveUpdate.emit();
          })
          .catch(() => {});
      }
    }
  }

  openAdvancedSearch() {
    this.advancedSearchSpec = JSON.parse(this.wndw.localStorage.getItem(LS_ADVANCED_SEARCH_KEY) || '{"filter": {}}');
    this.advancedSearchDialogElement.nativeElement.show();
  }

  clearAdvancedSearch() {
    this.advancedSearchDialogElement.nativeElement.close();
    this.advancedSearchSpec = null;
    this.wndw.localStorage.removeItem(LS_ADVANCED_SEARCH_KEY);
    this.sort = '-_id'; // clear sort when clearing search
    this.resetPagination();
    this.getCount();
    this.getEvents();
  }

  runAdvancedSearch() {
    if (!this.advancedSearchSpec) {
      return;
    }
    if (Object.values(this.advancedSearchSpec.filter || {}).filter((f) => !isNil(f)).length < 1) {
      return; // we don't have a single filter
    }

    this.advancedSearchDialogElement?.nativeElement?.close();

    // take over search & page & sort
    this.advancedSearchSpec.size = this.pagingLimit;
    this.advancedSearchSpec.page = this.pagingPage - 1;

    // persist to localstorage
    localStorage.setItem(LS_ADVANCED_SEARCH_KEY, JSON.stringify(this.advancedSearchSpec));

    // actually pull results
    this.isLoading = true;

    this.svcEvent
      .advancedSearch({
        ...this.advancedSearchSpec,
        filter: {
          ...(this.advancedSearchSpec?.filter || {}),
          // We don't want parentId to be stored in localStorage
          // otherwise we'd have it by default when looking at unrelated events.
          ...(this.parentId ? { parent: this.parentId } : {}),
        },
      })
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.events = res.items;
          this.total = { count: res.total };
        },
        () => (this.isLoading = false)
      );
  }

  private handleBulkListJobUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcJob.startBulkListEventJobUpload(file, secondEyes);
  }

  onOpenBulkListJobDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.jobBulkListDelegate;
  }
}
