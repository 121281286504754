export enum OrganizationAffiliationMappingCurationStatus {
  MATCH = 'MATCH',
  MISMATCH = 'MISMATCH',
  UNPOLISHED = 'UNPOLISHED',
}

export enum OrganizationAffiliationMappingDataScienceStatus {
  MATCH = 'MATCH',
  MISMATCH = 'MISMATCH',
  UNPOLISHED = 'UNPOLISHED',
}

export class OrganizationAffiliation {
  _id: string;
  organizationId: string;
  affiliationId: string;
  /**
   * The status as defined by DataScience upon import.
   * This is more of a hint.
   */
  statusAuto?: OrganizationAffiliationMappingDataScienceStatus;
  /**
   * The status as defined by curators and what actually matters later on.
   */
  statusCurated: OrganizationAffiliationMappingCurationStatus;
}
