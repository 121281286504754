import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET, MediaType, Produces, Path } from '../../shared/services/rest/rest.service';

import { APIService } from '../../shared/services/api/api.service';
import { Grant } from './grant';

@Injectable()
export class GrantAPI extends APIService {
  @GET('grants/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Grant> {
    return;
  }
}
