import { Injectable } from '@angular/core';
import { CanActivateChild, Router, ActivatedRouteSnapshot, RouterStateSnapshot, PRIMARY_OUTLET } from '@angular/router';
import { map } from 'rxjs/operators';

import { ACL } from './acl.service';
import { Auth } from '../services/auth/auth.service';
import { Roles } from './roles';

@Injectable()
export class AclGuard implements CanActivateChild {
  private landingPagesLeaders = [
    //'dashboard',
    'person',
    'profile',
    'event',
    'association',
    'guideline',
    'sentiment',
    'domain',
    'account',
    'committee',
    'document',
    'organization',
    'affiliation',
  ];
  private landingPages = [
    //'dashboard',
    'id-next',
    'idqc-next', // (those are more important than the lists)
    'account',
    'person',
    'profile',
    'event',
    'association',
    'guideline',
    'sentiment',
    'domain',
    'committee',
    'document',
    'organization',
    'next', // more important than the affiliation list (otherwise PersonReviewer gets redirected to affiliation list page)
    'affiliation',
  ];

  constructor(private router: Router, private auth: Auth, private acl: ACL) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const url = state.url.replace(/#/g, '');
    let nextRoute = 'forbidden';
    const credential = (route.data || {})['acl'];

    return this.auth.getProfile().pipe(
      map((profile) => {
        if (!credential || this.acl.hasCredential(credential)) {
          return true;
        }

        const urlTree = this.router.parseUrl(url);
        const urlSegments = urlTree.root.children[PRIMARY_OUTLET].segments || [];
        const isLeader = profile.app_metadata.roles.some(
          (r) => 'ADMIN' === r || r.endsWith('_MANAGER') || r.endsWith('_MODERATOR')
        );
        const myLandingPages = isLeader ? this.landingPagesLeaders : this.landingPages;
        const idx = myLandingPages.indexOf(urlSegments[0].path);

        // check the current url against landing pages
        if (myLandingPages[idx + 1]) {
          nextRoute = myLandingPages[idx + 1];
        }

        // bypass trying routes by priority as it conflicts with other roles that have access to affiliation list (i.e PersonReviewer)
        if (this.acl.hasRole(Roles.AffiliationManager)) {
          nextRoute = 'affiliation';
        }

        if (this.acl.hasRole(Roles.ReadyForJobs)) {
          nextRoute = 'next';
        }

        // Last attempt, always redirect compilers/reviewers/maintainers/merger to their default route
        const compilerRegex = new RegExp(/_COMPILER$/);
        const reviewerRegex = new RegExp(/_REVIEWER$/);
        const maintainerRegex = new RegExp(/_MAINTAINER$/);
        const mergerRegex = new RegExp(/_MERGER$/);
        if (
          profile.app_metadata.roles.some(
            (role) =>
              compilerRegex.test(role) ||
              reviewerRegex.test(role) ||
              maintainerRegex.test(role) ||
              mergerRegex.test(role)
          ) &&
          nextRoute === 'forbidden'
        ) {
          // default route should always be next for those
          nextRoute = 'next';
        }

        this.router.navigate([nextRoute]);
        return false;
      })
    );
  }
}
