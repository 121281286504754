<section *ngIf="jobs?.length" class="dirt-section-list dirt-job-list">
  <header class="row">
    <div class="col-sm-4">
      <h4>Your Next Job(s)</h4>
    </div>
  </header>

  <!-- Table -->
  <div *ngIf="jobs" [hidden]="isLoading">
    <table class="table table-sm table-hover" [hidden]="!jobs.length">
      <thead>
        <tr>
          <th>Job Type</th>
          <th>Entity ID</th>
          <th>Priority</th>
          <ng-container *ngIf="hasVideoJob()">
            <th>Channel ID</th>
            <th>Publication Date</th>
          </ng-container>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <ng-container class="dirt-job-row" *ngFor="let job of jobs">
          <tr class="dirt-user-row">
            <td>{{ job.type }}</td>
            <td>{{ job.extEntityId }}</td>
            <td>{{ job.priority }}</td>
            <td *ngIf="job?.additionalData?.channelId">{{ job.additionalData?.channelId }}</td>
            <td *ngIf="job?.additionalData?.pubDate">{{ job.additionalData?.pubDate | date : 'medium' }}</td>
            <td>
              <span class="work" *ngIf="hasRoute(job)" (click)="onWorkClick(job)"> Work </span>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>

<section *ngIf="completedJobs?.length" class="dirt-section-list dirt-job-list">
  <header class="row">
    <div class="col-sm-4">
      <h4>Your Previous {{ completedJobs.length }} Job(s)</h4>
    </div>
  </header>

  <!-- Table -->
  <div *ngIf="completedJobs" [hidden]="isLoadingCompletedJobs">
    <table class="table table-sm table-hover" [hidden]="!completedJobs.length">
      <thead>
        <tr>
          <th>Job Type</th>
          <th>Entity ID</th>
          <th>Priority</th>
          <ng-container *ngIf="hasVideoJobInCompletedJobs()">
            <th>Channel ID</th>
            <th>Publication Date</th>
            <th>Linked People</th>
          </ng-container>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <ng-container class="dirt-job-row" *ngFor="let job of completedJobs">
          <tr class="dirt-user-row">
            <td>{{ job.type }}</td>
            <td>{{ job.extEntityId }}</td>
            <td>{{ job.priority }}</td>
            <td *ngIf="job?.additionalData?.channelId">{{ job.additionalData?.channelId }}</td>
            <td *ngIf="job?.additionalData?.pubDate">{{ job.additionalData?.pubDate | date : 'medium' }}</td>
            <td *ngIf="'videos' === job.entityType">
              <dirt-linked-people [showLastNamesOnly]="true" [videoId]="getVideoId(job)"></dirt-linked-people>
            </td>
            <td>
              <span class="work" *ngIf="hasRoute(job)" (click)="onWorkClick(job)"> View </span>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoadingCompletedJobs">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
