import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { tap } from 'rxjs';

import { AccountAffiliationSuspect } from '../shared/account-affiliation-suspect';
import { AccountAffiliationSuspectAPI } from '../shared/account-affiliation-suspect-api.service';
import { ACL } from '../../shared/acl/acl.service';

@Component({
  selector: 'dirt-account-affiliation-suspects-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class AccountAffiliationSuspectListComponent implements OnInit {
  @Input()
  accountId: string;

  @Output()
  matchChanged: EventEmitter<AccountAffiliationSuspect> = new EventEmitter();

  accountAffiliationSuspects: AccountAffiliationSuspect[];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 30;
  pagingSkip = 0;

  canEditAffiliation = false;

  private sort = '';

  constructor(
    private readonly svcAccountAffiliationSuspect: AccountAffiliationSuspectAPI,
    private readonly svcAcl: ACL
  ) {}

  ngOnInit(): void {
    this.canEditAffiliation = this.svcAcl.hasCredential('account.affiliation-suspect.update');

    this.doLoad();
  }

  /** just avoid useless rendering if we can */
  trackById(index: number, account: AccountAffiliationSuspect): string {
    return account.id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getAccountAffiliationSuspects();
  }

  onSort(field: string): void {
    this.sort = field;

    this.getAccountAffiliationSuspects();
  }

  doLoad(): void {
    this.resetPagination();
    this.getAccountAffiliationSuspects();
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getAccountAffiliationSuspects(): void {
    this.isLoading = true;

    this.svcAccountAffiliationSuspect
      .find(this.pagingLimit, this.pagingSkip, this.sort, this.accountId)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((accountFinancialInformation) => {
        this.accountAffiliationSuspects = accountFinancialInformation;
        this.getAccountAffiliationSuspectsCount(); // let suspects to generate first
      });
  }

  private getAccountAffiliationSuspectsCount(): void {
    this.svcAccountAffiliationSuspect.count(this.accountId).subscribe((count) => {
      this.total = count;
    });
  }

  onAffiliationSuspectStatusChange(suspect: AccountAffiliationSuspect) {
    if (!this.canEditAffiliation) {
      return;
    }

    this.svcAccountAffiliationSuspect
      .setAffiliationSuspectMatchStatus(this.accountId, suspect.affiliation.id, { status: suspect.status })
      .subscribe({
        next: (updatedSuspect) => {
          this.matchChanged.emit(suspect);
          this.accountAffiliationSuspects.splice(
            this.accountAffiliationSuspects.findIndex((a) => a.id === updatedSuspect.id),
            updatedSuspect
          );
        },
        error: (error) => {
          try {
            if (error.status === 404) {
              // On 404s for suspects we assume some other account mapped to the affiliation and
              // the suspect is now invalid for this account, so we can just reload.
              this.getAccountAffiliationSuspects();
              this.getAccountAffiliationSuspectsCount();
            }
          } catch (e) {}
        },
      });
  }
}
