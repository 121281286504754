import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { UserComponent } from './user.component';
import { UserListComponent } from './list/list.component';
import { UserDetailComponent } from './detail/detail.component';
import { UserCreateComponent } from './create/create.component';
import { UserFormComponent } from './shared/form/form.component';
import { UserLoginComponent } from './login/login.component';

import { userRouting } from './user.routes';
import { UserAPI } from './shared/api.service';
import { UserGroupModule } from '../user-group/user-group.module';
import { BulkListModule } from '../common/bulk-list/bulk-list.module';
import { BulkModule } from '../common/bulk/bulk.module';

@NgModule({
  imports: [SharedModule, UserGroupModule, BulkListModule, userRouting, BulkModule],
  declarations: [
    UserComponent,
    UserDetailComponent,
    UserCreateComponent,
    UserFormComponent,
    UserListComponent,
    UserLoginComponent,
  ],
  providers: [UserAPI],
  exports: [],
})
export class UserModule {}
