import { ChangeDetectionStrategy, Component, NgZone } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'dirt-copy-button-renderer',
  templateUrl: './copy-button-renderer.component.html',
  styleUrls: ['./copy-button-renderer.component.scss'],
})
export class CopyButtonRendererComponent implements ICellRendererAngularComp {
  public params: any;
  public list: any | any[] = null;

  isCopied = false;

  nav: Navigator = navigator;
  wndw: Window = window;

  constructor() {}

  agInit(params: any): void {
    this.params = params;
    if (params.listToCopy) {
      this.list = params.listToCopy(params); // resolve now
    }
  }

  refresh(): boolean {
    return false;
  }

  async onClick() {
    if (!this.list) {
      return;
    }
    await this.nav.clipboard.writeText(
      Array.isArray(this.list) ? this.list.map((l) => '' + l).join('\n') : '' + this.list
    );
    this.isCopied = true;
    this.wndw.setTimeout(() => (this.isCopied = false), 800);
  }
}
