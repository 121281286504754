<section class="dirt-section-detail dirt-video-job" *ngIf="job">
  <div class="row">
    <div class="col-md-8">
      <div class="dirt-btn-ct">
        <div class="pull-left">
          <h1 class="bounceInRight">Video Detail</h1>
        </div>
      </div>
      <br />
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <div class="clearfix">
        <!-- Text -->
        <div class="row">
          <div class="col-sm-4 col-md-2">Video</div>
          <div class="col-sm-8 col-md-10">
            <div>
              <a [href]="job.extEntityId" target="_blank" rel="noopener"> {{ job.extEntityId }}</a>
            </div>
          </div>
          <div class="col-sm-4 col-md-2">Publication Date</div>
          <div class="col-sm-8 col-md-10">
            <div>{{ job.additionalData?.pubDate | date : 'medium' }}</div>
          </div>
          <div class="col-sm-4 col-md-2">Priority</div>
          <div class="col-sm-8 col-md-10">
            <div>{{ job.priority }}</div>
          </div>
        </div>
      </div>
      <div class="clearfix">
        <dirt-linked-people
          [showRemove]="true"
          (onRemove)="unlinkProfile($event)"
          [reload]="reloadLinkedKOLs"
          [videoId]="videoId"
        ></dirt-linked-people>
      </div>

      <!-- Buttons -->
      <div class="dirt-btn-ct" *ngIf="!job.completedAt">
        <div class="pull-right">
          <!-- Add button -->
          <button class="btn btn-success" (click)="linkProfile()">
            <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
            <span>Add Person</span>
          </button>

          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSaving }"
            [disabled]="isSaving"
            (click)="onSubmit()"
            *diAcl="'job.submit'"
          >
            <span>Complete Job</span>
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Information -->
        <li ngbNavItem>
          <a ngbNavLink>Information</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <dt class="col-sm-4">Channel ID</dt>
              <dd class="col-sm-8">{{ job.additionalData?.channelId }}</dd>

              <dt class="col-sm-4">Created</dt>
              <dd class="col-sm-8">{{ job.createdAt | date : 'medium' }}</dd>

              <dt class="col-sm-4 text-truncate">Updated</dt>
              <dd class="col-sm-8">{{ job.updatedAt | date : 'medium' }}&nbsp;</dd>

              <dt class="col-sm-4 text-truncate di-separator" *ngIf="job.completedAt">Completed at</dt>
              <dd class="col-sm-8 di-separator" *ngIf="job.completedAt">{{ job.completedAt | date : 'medium' }}</dd>
            </dl>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="dirt-section-meta"></div>
    </div>
  </div>
</section>
