import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET, MediaType, Produces, Path } from '../../shared/services/rest/rest.service';

import { APIService } from '../../shared/services/api/api.service';
import { ClinicalTrial } from './clinical-trial';

@Injectable()
export class ClinicalTrialAPI extends APIService {
  @GET('clinical-trials/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<ClinicalTrial> {
    return;
  }
}
