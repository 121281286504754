import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, distinctUntilChanged, take } from 'rxjs/operators';

import { ACL } from '../../shared/acl/acl.service';
import { ProjectTagValue } from '../shared/project-tag-value';
import { ProjectTagsAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-project-tags-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class ProjectTagsListComponent implements OnInit, OnDestroy {
  shownType: 'project' | 'person-project' = 'person-project';
  projects: Array<ProjectTagValue> = [];
  personProjects: Array<ProjectTagValue> = [];
  currentList: Array<ProjectTagValue> = [];
  isLoading: boolean;

  searchTerm: string;
  searchCtrl: FormControl = new FormControl('');
  searchSub = null;

  editId: string;
  newTag: ProjectTagValue = null;
  isSubmitting = false;

  constructor(private router: Router, private projectTagsSvc: ProjectTagsAPI, private svcAcl: ACL) {}

  ngOnInit() {
    this.loadLists();
    this.searchSub = this.searchCtrl.valueChanges.pipe(distinctUntilChanged(), debounceTime(200)).subscribe((val) => {
      if (typeof val !== 'undefined') {
        this.searchTerm = val;
        this.showCurrentList();
      }
    });
  }

  ngOnDestroy() {
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
  }

  private async loadLists() {
    this.isLoading = true;
    let todo = 2;
    this.projectTagsSvc
      .listAll('project')
      .pipe(take(1))
      .subscribe((_tags) => {
        this.projects = _tags;
        todo--;
        this.isLoading = todo > 0;
        this.showCurrentList();
      });
    this.projectTagsSvc
      .listAll('person-project')
      .pipe(take(1))
      .subscribe((_tags) => {
        this.personProjects = _tags;
        todo--;
        this.isLoading = todo > 0;
        this.showCurrentList();
      });
  }

  private showCurrentList() {
    if ('person-project' === this.shownType) {
      this.currentList = this.personProjects;
    } else if ('project' === this.shownType) {
      this.currentList = this.projects;
    } else {
      this.currentList = []; // (should not happen)
    }
    const srch = (this.searchTerm || '').trim().toLowerCase();
    if (srch) {
      this.currentList = this.currentList.filter(
        (t) => (t.title || '').toLowerCase().indexOf(srch) >= 0 || (t.code || '').toLowerCase().indexOf(srch) >= 0
      );
    }
    this.currentList.sort((t1, t2) => t1.code.localeCompare(t2.code));
  }

  onShowType(type) {
    this.newTag = null;
    this.editId = null;
    this.shownType = type;
    this.showCurrentList();
  }

  onDeleteClick(tagId: string, $event) {
    $event.stopPropagation();
    if (!window.confirm('Project tag will be deleted. Do you want to continue?')) {
      return;
    }
    this.isSubmitting = true;
    this.projectTagsSvc.deleteById(tagId).subscribe(
      (resp) => {
        this.isSubmitting = false;
        if (resp.ok) {
          this.loadLists();
        } else {
          alert(resp.reason || 'Could not delete the tag - pls. check');
        }
      },
      () => (this.isSubmitting = false)
    );
  }

  onCreateClicked($event) {
    $event.stopPropagation();
    this.editId = null;
    this.newTag = new ProjectTagValue(this.shownType);
    if ('person-project' === this.shownType) {
      this.newTag.technical = true; // default
    }
  }

  onEditClicked(tagId, $event) {
    $event.stopPropagation();
    this.editId = tagId;
    this.newTag = null;
  }

  onSubmit(tag: ProjectTagValue): void {
    if (
      !window.confirm(
        'New project tag will be created. Selected project tag code cannot be changed later. Do you want to continue?'
      )
    ) {
      return;
    }
    this.saveTag(tag); // same for both
  }

  onEdit(tag: ProjectTagValue) {
    if (!window.confirm('Project tag will be modified. Do you want to continue?')) {
      return;
    }
    this.saveTag(tag); // same for both
  }

  private saveTag(tag: ProjectTagValue) {
    this.isSubmitting = true;
    this.projectTagsSvc.save(tag).subscribe(
      (resp) => {
        this.isSubmitting = false;
        if (resp.ok) {
          if (resp.message) {
            alert(resp.message);
          }
          this.newTag = null;
          this.editId = null;
          this.loadLists();
        } else {
          alert(resp.reason || resp.message || 'Could not save the tag - pls. check');
        }
      },
      () => (this.isSubmitting = false)
    );
  }

  onCancelClick() {
    this.newTag = null;
  }

  onCancelEditClick() {
    this.editId = null;
    this.loadLists(); // get rid of poss. edits
  }

  canCreate() {
    if ('person-project' === this.shownType) {
      return this.svcAcl.hasCredential('projectTags.create.personProject');
    } else if ('project' === this.shownType) {
      return this.svcAcl.hasCredential('projectTags.create.project');
    } else {
      return false;
    }
  }
  canEdit() {
    if ('person-project' === this.shownType) {
      return this.svcAcl.hasCredential('projectTags.edit.personProject');
    } else if ('project' === this.shownType) {
      return this.svcAcl.hasCredential('projectTags.edit.project');
    } else {
      return false;
    }
  }
  canDelete() {
    if ('person-project' === this.shownType) {
      return this.svcAcl.hasCredential('projectTags.delete.personProject');
    } else if ('project' === this.shownType) {
      return this.svcAcl.hasCredential('projectTags.delete.project');
    } else {
      return false;
    }
  }
}
