<section class="dirt-section-detail dirt-event-detail" *ngIf="event">
  <div class="row">
    <div class="col-md-8">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <div class="dirt-btn-ct header">
        <h1 class="bounceInRight">{{ !event.parent ? 'Event Detail' : 'Session Detail' }}</h1>

        <div class="headers-controls">
          <ng-template [diAcl]="'event.create'" [diAclAnd]="!event.parent">
            <a
              [routerLink]="['/event/create']"
              [queryParams]="{ from: event.id }"
              class="btn btn-success btn-sm"
              role="button"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
              <span>Clone Event</span>
            </a>
          </ng-template>
        </div>
      </div>

      <br />

      <p *ngIf="event.parent">
        Belongs to <a [routerLink]="['/event/detail', event.parent.id]">{{ event.parent.name }}</a>
      </p>

      <div class="alert alert-warning text-center mt-4" *ngIf="!event.parent && event._meta?.maintenance" role="alert">
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        <strong>This event is on Maintenance.</strong>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-8">
      <br />

      <dirt-event-form
        [event]="event"
        [currentJobType]="getJobTypeForCurrentUser()"
        #frmEvent="frmEvent"
      ></dirt-event-form>

      <!-- Event: Buttons -->
      <div class="dirt-btn-ct" *ngIf="!event.parent">
        <div class="pull-left" *ngIf="hasJobForCurrentUser()">
          <!-- Unable to Process for job (Need to be displayed even when form is disabled so that job curators are not stuck!) -->
          <button type="button" class="btn btn-outline-danger" (click)="onJobUtc()">Unable to Compile!</button>
        </div>

        <!-- Right -->
        <div class="pull-right" *ngIf="!hasJobForCurrentUser()">
          <!-- Save -->
          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="(!frmEvent.isValid() && !isHashtagsOnly()) || isSubmitting"
            (click)="onSave(frmEvent.getValue(), 'SAVE_AND_BACK')"
            *diAcl="'event.update'"
          >
            <span>Save</span>
          </button>

          <!-- Submit (still needed by hashtag compilers until they move to jobs) -->
          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="(!frmEvent.isValid() && !isHashtagsOnly()) || isSubmitting"
            (click)="onSubmit(frmEvent.getValue())"
            *diAcl="'event.update.submit'"
          >
            <span>Submit</span>
          </button>
        </div>
        <div class="pull-right" *ngIf="hasJobForCurrentUser()">
          <!-- Submit -->
          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!(frmEvent.isValid() || canSubmitJobAnyway()) || isSubmitting"
            (click)="onSubmitJob(frmEvent.getValue())"
          >
            <span>{{ !frmEvent.isValid() && canSubmitJobAnyway() ? 'Submit job anyway' : 'Submit job' }}</span>
          </button>
        </div>
      </div>

      <!-- Session: Buttons -->
      <div class="dirt-btn-ct" *ngIf="event.parent">
        <!-- Right -->
        <div class="pull-right">
          <!-- Save -->
          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!frmEvent.isValid() || isSubmitting"
            (click)="onSave(frmEvent.getValue(), 'SAVE_AND_BACK')"
            *diAcl="'session.update'"
          >
            <span>Save</span>
          </button>

          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!frmEvent.isValid() || isSubmitting"
            (click)="onSave(frmEvent.getValue(), 'SAVE_AND_NEW')"
            *diAcl="'session.update'"
          >
            <span>Save & New</span>
          </button>
        </div>
      </div>
    </div>

    <div [diMarkerWrapper]="event" class="col-md-4" *ngIf="!event.parent">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Workflow -->
        <li ngbNavItem>
          <a ngbNavLink>Workflow</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <dt class="col-sm-4" [hidden]="!isClaimedByOtherUser()"></dt>
              <dd class="col-sm-8" [hidden]="!isClaimedByOtherUser()">
                <span title="s/o else started working recently" class="badge badge-info">Claimed</span>
              </dd>

              <dt class="col-sm-4 di-separator" *ngIf="hasJobForCurrentUser()">Job</dt>
              <dd
                class="col-sm-8 di-separator"
                *ngIf="hasJobForCurrentUser()"
                [innerText]="eventJob?.type + (eventJob?.hint ? ' - ' + eventJob.hint : '')"
              ></dd>

              <dt class="col-sm-4" style="white-space: nowrap">Req by <small>/ Owner prd</small></dt>
              <dd class="col-sm-8">{{ event.ownerProduct || '-' }}</dd>

              <dt class="col-sm-4">Verified</dt>
              <dd class="col-sm-8">
                <span *diAcl="'event.update.prop.verified'">
                  <ui-switch
                    [disabled]="
                      !allDirectContributionVerified ||
                      !atleastOneIndirectContributionVerified ||
                      hasJobForCurrentUser()
                    "
                    [checked]="!!event.verified"
                    (change)="onVerifiedChange($event)"
                    size="small"
                  ></ui-switch>
                  <i
                    class="fa fa-pulse fa-spinner di-va-super"
                    [hidden]="!isSavingVerified && !isUpdatingVerifiedControl"
                  ></i>
                </span>
                <span *diAcl="'!event.update.prop.verified'">
                  <i
                    class="fa"
                    [ngClass]="{ 'fa-check': event.verified, 'fa-times': !event.verified }"
                    aria-hidden="true"
                  ></i>
                </span>
              </dd>

              <dt class="col-sm-4">ID Verified</dt>
              <dd class="col-sm-8">
                <span *diAcl="'event.update.prop.idVerified'">
                  <ui-switch
                    [disabled]="hasJobForCurrentUser()"
                    [checked]="!!event.idVerified"
                    (change)="onIdVerifiedChange($event)"
                    size="small"
                  ></ui-switch>
                  <i class="fa fa-pulse fa-spinner di-va-super" [hidden]="!isSavingIdVerified"></i>
                </span>
                <span *diAcl="'!event.update.prop.idVerified'">
                  <i
                    class="fa"
                    [ngClass]="{ 'fa-check': event.idVerified, 'fa-times': !event.idVerified }"
                    aria-hidden="true"
                  ></i>
                </span>
              </dd>

              <dt class="col-sm-4">Delivery Ready</dt>
              <dd class="col-sm-8">
                <i
                  class="fa"
                  [ngClass]="{ 'fa-check': event.readyForDelivery, 'fa-times': !event.readyForDelivery }"
                  aria-hidden="true"
                ></i>
              </dd>

              <dt class="col-sm-4 di-separator" [hidden]="!event.createdAt">Created</dt>
              <dd class="col-sm-8 di-separator">{{ event.createdAt | date : 'medium' }}</dd>

              <dt class="col-sm-4 text-truncate" [hidden]="!event.updatedAt">Modified</dt>
              <dd class="col-sm-8">{{ event.updatedAt | date : 'medium' }}</dd>

              <dt class="col-sm-4 text-truncate" *ngIf="event.reviewedAt">Reviewed</dt>
              <dd class="col-sm-8" *ngIf="event.reviewedAt">{{ event.reviewedAt | date : 'medium' }}</dd>

              <dt class="col-sm-4 text-truncate" *ngIf="event.id && !event.parent">Status</dt>
              <dd
                class="col-sm-8"
                *ngIf="event.id && !event.parent && (!hasJobForCurrentUser() || hasStatusChangeJobForCurrentUser())"
              >
                <span *diAcl="'!event.update.prop.meta.status'">{{ event._meta?.status | capitalize }}</span>
                <ng-container *diAcl="'event.update.prop.meta.status'">
                  <dirt-status
                    [statuses]="statuses"
                    [assigneeStatuses]="assigneeStatuses"
                    [disabledStatuses]="disabledStatuses"
                    [keepAssignee]="hasJobForCurrentUser()"
                    [_meta]="event._meta"
                    (onStatusSelected)="onStatusChange($event)"
                    *diMarker="{ name: 'status', entity: 'event', area: 'ID' }"
                  >
                  </dirt-status>
                </ng-container>
                <i class="fa fa-circle-o-notch fa-spin" [hidden]="!isSavingStatus"></i>
              </dd>
              <dd
                class="col-sm-8"
                *ngIf="event.id && !event.parent && hasJobForCurrentUser() && !hasStatusChangeJobForCurrentUser()"
              >
                <span>{{ event._meta?.status | capitalize }}</span>
              </dd>

              <dt class="col-sm-4">Version</dt>
              <dd class="col-sm-8">{{ event._version || 0 }}</dd>
            </dl>
          </ng-template>
        </li>

        <!-- Comments -->
        <li ngbNavItem *diAcl="'event.comment.list'">
          <a ngbNavLink>Comments</a>
          <ng-template ngbNavContent>
            <dirt-comment-list
              [ownerId]="id"
              [enableCreate]="svcAcl.hasCredential('event.comment.create')"
            ></dirt-comment-list>
          </ng-template>
        </li>

        <!-- Audit Log -->
        <li ngbNavItem *diAcl="'event.audit.list'">
          <a ngbNavLink>Audit Log</a>
          <ng-template ngbNavContent>
            <dirt-audit-log
              [id]="id"
              [entityAPI]="svcEvent"
              [shallowCompKeys]="auditLogShallowCompKeys"
              [diffArrayMappers]="auditDiffArrayMappers"
            ></dirt-audit-log>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-event-meta"></div>
    </div>

    <div class="col-md-12">
      <dirt-ocr-bar [webSource]="event.webSource"></dirt-ocr-bar>
    </div>

    <div class="col-md-12" *ngIf="isQcJob()">
      <span *ngIf="0 === currentOpenVerificationCount">All necessary verifications are done</span>
      <span *ngIf="currentOpenVerificationCount > 0"
        >You have <span class="red-font">{{ currentOpenVerificationCount }} open verifications</span></span
      >
      <i style="cursor: pointer" class="fa fa-refresh" title="Refresh counts" (click)="onVerifiedRefresh()"></i>
    </div>

    <ng-template [diAcl]="'contribution.list'" [diAclAnd]="isRegionCompliant()">
      <div *ngIf="isEventWithContributions()" [diMarkerWrapper]="event" class="col-md-12">
        <dirt-contribution-list
          *diMarker="{ name: 'contribution', entity: event.parent ? 'session' : 'event', area: 'BD' }"
          (onVerifiedUpdate)="onVerifiedFlagUpdate($event)"
          (sessionUpdate)="onSessionUpdate()"
          [event]="event"
          [qcSessionId]="this.eventJob?.qcSessionId"
          [disableDragAndDrop]="isQcJob()"
          [isSearchType]="!event.parent"
          (verifiedCountChanged)="onVerifiedCountChange($event)"
          #contribution
        ></dirt-contribution-list>
      </div>
    </ng-template>

    <ng-template [diAcl]="'session.list'">
      <div *ngIf="event && !event.parent && isEventWithContributions()" [diMarkerWrapper]="event" class="col-md-12">
        <dirt-event-list
          *diMarker="{ name: 'session', entity: 'event', area: 'BD' }"
          [parentId]="id"
          [disableDragAndDrop]="isQcJob()"
          (onContributionMoveUpdate)="onContributionMove()"
          (verifiedCountChanged)="onVerifiedCountChange($event)"
          #session
        ></dirt-event-list>
      </div>
    </ng-template>
  </div>
</section>
