import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GET,
  Path,
  Query,
  MediaType,
  Produces,
  POST,
  Body,
  PATCH,
  DELETE,
} from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { Sponsor, UpdateNormalizedNamesData } from './sponsor';

@Injectable()
export class SponsorAPI extends APIService {
  @GET('sponsors/')
  @Produces(MediaType.JSON)
  public find(
    @Query('term') term?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any,
    @Query('grouped') grouped?: boolean
  ): Observable<Sponsor[]> {
    return null;
  }

  @GET('sponsors/count')
  @Produces(MediaType.JSON)
  public count(@Query('name') name?: string, @Query('filter') filter?: any): Observable<any> {
    return;
  }

  @GET('sponsors/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Sponsor> {
    return null;
  }

  @POST('sponsors')
  @Produces(MediaType.JSON)
  public create(@Body pool: Sponsor): Observable<any> {
    return;
  }

  @PATCH('sponsors/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body pool: Sponsor): Observable<any> {
    return;
  }

  @GET('sponsors/list-dropdown')
  @Produces(MediaType.JSON)
  public getSponsors(): Observable<Sponsor[]> {
    return;
  }

  @DELETE('sponsors/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<{ Sponsor }> {
    return;
  }

  @GET('sponsors/exist/{originalName}')
  @Produces(MediaType.JSON)
  public exist(@Path('originalName') originalName: string): Observable<boolean> {
    return null;
  }

  @GET('sponsors/next-id')
  @Produces(MediaType.JSON)
  public getNextCompanyId(): Observable<string> {
    return;
  }

  @PATCH('sponsors/normalized-name')
  @Produces(MediaType.JSON)
  public updateNormalizedNames(@Body data: UpdateNormalizedNamesData): Observable<any> {
    return;
  }
}
