<div class="d-projects-dropdown">
  <ng-select
    [items]="projectTags"
    bindValue="code"
    bindLabel="title"
    [clearable]="false"
    [clearOnBackspace]="false"
    [(ngModel)]="selected"
    [multiple]="true"
    [hideSelected]="true"
    [searchable]="true"
    [loading]="isLoading"
    (add)="onClick($event.code)"
    (remove)="onClick($event?.value?.code)"
    dropdownPosition="bottom"
    [disabled]="this.disabled"
  >
  </ng-select>
  <i class="fa fa-refresh fa-spin fa-fw" *ngIf="isSaving"></i>
</div>
