import { EventAPI } from '../api.service';
import { Event } from '../event';

import { BulkUpdateOptions } from '../../../common/bulk-update/bulk-update-options';
import { Roles } from '../../../shared/acl/roles';
import { FieldType } from '../../../common/bulk-update/field.config';
import { enumToDataObject, rangeOfNumbers } from '../utils';
import { EventStatus } from '../enum/status.enum';
import { FieldValue } from '../../../common/bulk-update/field-value';

export const eventStatusValues = enumToDataObject(EventStatus);

export function titleFormatter(entity: Event) {
  return (entity.name ? `${entity.name} ` : '') + (entity.originalName ? `${entity.originalName} ` : '');
}

export function handleAssigneeCheck(fieldsValues: FieldValue[]) {
  const status = fieldsValues.find((field) => field.config.field === '_meta.status');
  return status && status.value === EventStatus.COMPILATION_IN_PROGRESS;
}

export const priorityRange = rangeOfNumbers(5, 0);

export function getEventStatusValues() {
  return eventStatusValues;
}

export function getPriorityRangeValues() {
  return priorityRange;
}

export function buildBulkUpdateConfig(srvApi: EventAPI): BulkUpdateOptions<EventAPI, Event> {
  return {
    srvApi: srvApi,
    titleFormatter: titleFormatter,
    fields: [
      {
        field: '_meta.status',
        label: 'Status',
        required: true,
        type: FieldType.select,
        values: getEventStatusValues,
      },
      {
        field: 'assignees',
        label: 'Assignee',
        required: true,
        type: FieldType.multiUser,
        roles: [Roles.EventCompiler],
        hideCheck: true,
        handleCheck: handleAssigneeCheck,
      },
      {
        field: 'priority',
        label: 'Priority',
        type: FieldType.select,
        values: getPriorityRangeValues,
      },
    ],
    filterBarConfig: null,
  };
}
