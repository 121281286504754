<section class="dirt-section-detail dirt-sentiment" *ngIf="sentiment">
  <div class="row">
    <div class="col-md-8">
      <div class="dirt-btn-ct">
        <div class="pull-left">
          <h1 class="bounceInRight">Sentiment Detail</h1>
        </div>
      </div>
      <br />
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <dirt-sentiment-form [sentiment]="sentiment" #frmSentiment="frmSentiment"></dirt-sentiment-form>

      <!-- Buttons -->
      <div class="dirt-btn-ct">
        <div class="pull-right">
          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSaving }"
            [disabled]="!frmSentiment.isValid() || isSaving"
            (click)="onSubmit()"
            *diAcl="'sentiment.submit'"
          >
            <span>Submit</span>
          </button>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Information -->
        <li ngbNavItem>
          <a ngbNavLink>Information</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <dt class="col-sm-4">Tweet ID</dt>
              <dd class="col-sm-8">{{ sentiment.entityId }}</dd>

              <dt class="col-sm-4">Created</dt>
              <dd class="col-sm-8">{{ sentiment.createdAt | date : 'medium' }}</dd>

              <dt class="col-sm-4 text-truncate">Updated</dt>
              <dd class="col-sm-8">{{ sentiment.updatedAt | date : 'medium' }}&nbsp;</dd>

              <dt class="col-sm-4 text-truncate di-separator" *ngIf="sentiment.curatedAt">Curated at</dt>
              <dd class="col-sm-8 di-separator" *ngIf="sentiment.curatedAt">
                {{ sentiment.curatedAt | date : 'medium' }}
              </dd>

              <dt class="col-sm-4 text-truncate" *ngIf="sentiment.curatedBy">Curated by</dt>
              <dd class="col-sm-8 font-italic" *ngIf="sentiment.curatedBy">
                <dirt-username [user]="curateUser ? curateUser : sentiment.curatedBy"></dirt-username>
              </dd>
            </dl>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-sentiment-meta"></div>
    </div>
  </div>
</section>
