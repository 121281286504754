<section class="dirt-section-detail dirt-contribution-detail" *ngIf="contribution">
  <header>
    <button
      type="button"
      class="dirt-btn-back btn btn-primary-outline btn-sm"
      (click)="navigateTo(['/event/detail', contribution.event.id])"
    >
      <span class="fa fa-arrow-left" aria-hidden="true"></span>
    </button>

    <h1 class="bounceInRight">Contribution Detail</h1>

    <p>
      Belongs to <a [routerLink]="['/event/detail', contribution.event.id]">{{ contribution.event.name }}</a>
    </p>
  </header>

  <br />
  <dirt-contribution-form [contribution]="contribution" [isSubmitting]="isSubmitting" (onSubmitted)="onSubmit($event)">
  </dirt-contribution-form>
</section>
