import { Directive, Input, OnChanges, SimpleChange } from '@angular/core';
import { ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { ACL } from './acl.service';

const REGEX = /([\w\d\.-]{1,})/g;
const REPLACEMENT = 'this.acl.hasCredential("$1")';

@Directive({
  selector: '[diAcl]',
})
export class DirtAclDirective implements OnChanges {
  private hasView: boolean;

  @Input('diAcl') aclStatement: string;
  @Input('diAclOr') aclOr: boolean;
  @Input('diAclAnd') aclAnd: boolean;

  constructor(
    private el: ElementRef,
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<Object>,
    private acl: ACL
  ) {}

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.evaluate();
  }

  private evaluate() {
    if (!this.acl) {
      return;
    }

    const input = this.aclStatement.replace(REGEX, REPLACEMENT);
    const fn = new Function(`return ${input}`);
    let condition = fn.bind(this)();

    // Check OR
    if (typeof this.aclOr !== 'undefined') {
      condition = condition || this.aclOr;
    }

    // Check AND
    if (typeof this.aclAnd !== 'undefined') {
      condition = condition && this.aclAnd;
    }

    if (condition && !this.hasView) {
      this.hasView = true;
      this.viewContainer.createEmbeddedView(this.template);
    } else if (!condition && this.hasView) {
      this.hasView = false;
      this.viewContainer.clear();
    }
  }
}
