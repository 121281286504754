import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

import { ACL } from './../../../shared/acl/acl.service';
import { CommitteeConnection } from '../connection';
import { initPrimaryResearchDetails } from '../primaryResearch';
import { PrimaryResearchResults, PrimaryResearchMethods } from '../constant/primaryResearch.enum';
import { Utils } from './../../../common/utils';
import { Value } from '../../../shared/services/value/value';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';

@Component({
  selector: 'dirt-committee-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss'],
  exportAs: 'committeeConnectionsComponent',
})
export class CommitteeConnectionsComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input()
  connections: CommitteeConnection[] = [];

  @Input()
  disabled: boolean;

  @Input()
  searchFunction: (term: string) => Observable<CommitteeConnection[]>;

  @Input()
  connectionRequestHandler?: (connection?: CommitteeConnection) => Observable<CommitteeConnection>;

  @Output()
  validityChange: EventEmitter<'VALID' | 'INVALID'> = new EventEmitter();

  @ViewChild(NgbTypeahead)
  private searchAutocomplete: NgbTypeahead;

  @ViewChild('ngForm')
  private ngForm: NgForm;

  isSearching = false;

  searchTerm: string;

  isCollapsed: Map<string, boolean> = new Map();

  committeeConnectionProbabilities: Value[] = [];

  primaryResearchResults = PrimaryResearchResults;
  primaryResearchMethods = PrimaryResearchMethods;

  longDash = Utils.longDash;

  private destroy$: Subject<boolean> = new Subject();

  private wndw: Window = window; // allow for testing

  constructor(private svcAcl: ACL, private svcValue: ValueAPI) {
    this.onSearchConnection = this.onSearchConnection.bind(this);
  }

  ngOnInit(): void {
    this.svcValue.find(ValueType.CommitteeConnectionProbability, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.committeeConnectionProbabilities = data;
    });
  }

  ngAfterViewInit(): void {
    this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID'); // check validity on init

    this.ngForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
      this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  trackById(_: number, connection: CommitteeConnection): string {
    return connection.connectionId;
  }

  onSearchConnection(term$: Observable<string>): Observable<CommitteeConnection[]> {
    return term$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }

        return this.searchFunction(term).pipe(catchError(() => of([])));
      }),
      tap(() => (this.isSearching = false))
    );
  }

  onSelectConnection(event: any): void {
    event.preventDefault(); // prevent setting model to [object Object]

    this.searchTerm = '';
    this.searchAutocomplete.dismissPopup();

    if (this.connections.find((c) => c.connectionId === event.item.connectionId)) {
      // already added
      return;
    }

    this.connections.push(event.item);
    this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
  }

  onRemoveConnection(id: string): void {
    if (!this.wndw.confirm('Sure to remove the connection?')) {
      return;
    }

    const idx = this.connections.findIndex((c) => c.connectionId === id);
    if (idx < 0) {
      return;
    }

    this.connections.splice(idx, 1);
    this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
  }

  onToggleCollapse(id: string): void {
    this.isCollapsed.set(id, !this.isCollapsed.get(id));
  }

  onRequestConnection(connection?: CommitteeConnection): void {
    if (this.disabled || !this.connectionRequestHandler || (connection && !connection.readyForDelivery)) {
      return;
    }

    this.connectionRequestHandler(connection)
      .pipe(take(1))
      .subscribe((connection) => {
        if (this.connections.find((c) => c.connectionId === connection.connectionId)) {
          // already added
          return;
        }

        this.connections.push(connection);
        this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
      });
  }

  onPrimaryResearchSwitchChange(index: number, checked: boolean): void {
    this.connections[index].hasPrimaryResearch = checked;
    this.connections[index].primaryResearchInfo = {
      result: null,
      method: null,
      details: null,
    };
  }

  onPrimaryResearchMethodChange(index: number): void {
    const methodValue: string = this.connections[index].primaryResearchInfo.method;

    this.connections[index].primaryResearchInfo.details = initPrimaryResearchDetails(methodValue);
  }

  isFieldEditable(field: string): boolean {
    return this.svcAcl.hasCredential(`committee.update.prop.${field}`);
  }

  private isValid(): boolean {
    return this.ngForm.disabled || this.ngForm.valid;
  }
}
