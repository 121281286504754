import { Injectable } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { GET, POST, MediaType, Path, Produces, Query, Body, PATCH } from '../../shared/services/rest/rest.service';

import { APIService } from '../../shared/services/api/api.service';
import { Job } from './job';
import { map } from 'rxjs/operators';

@Injectable()
export class JobsAPI extends APIService {
  @GET('jobs/next')
  @Produces(MediaType.JSON)
  public findNext(@Query('draftId') draftId?: string): Observable<Job> {
    return;
  }

  @GET('jobs/next-list')
  @Produces(MediaType.JSON)
  public findNextList(): Observable<Job[]> {
    return;
  }

  @GET('jobs/completed-list')
  @Produces(MediaType.JSON)
  public findCompletedList(): Observable<Job[]> {
    return;
  }

  @GET('jobs/{id}')
  @Produces(MediaType.JSON)
  public findById(
    @Path('id') id: string,
    @Query('includeQcSession') includeQcSession?: boolean
  ): Observable<Job & { qcSession? }> {
    return;
  }

  @GET('jobs/drafts')
  @Produces(MediaType.JSON)
  public drafts(): Observable<{ limit: number; current: (Job & { abstract })[] }> {
    return;
  }

  @POST('jobs/set-draft')
  @Produces(MediaType.JSON)
  public setDraft(@Query('id') id: string): Observable<{ ok }> {
    return;
  }

  @POST('jobs/set-utc')
  @Produces(MediaType.JSON)
  public setUtc(@Query('id') id: string, @Query('comment') comment: string): Observable<{ ok }> {
    return;
  }

  @POST('jobs/set-skipped')
  @Produces(MediaType.JSON)
  public setSkipped(@Query('id') id: string): Observable<{ ok }> {
    return;
  }

  @POST('jobs')
  @Produces(MediaType.JSON)
  public create(@Body job: Job): Observable<{ ok: boolean }> {
    return;
  }

  @POST('jobs/finish')
  @Produces(MediaType.JSON)
  public finish(@Query('id') id: string): Observable<{ ok }> {
    return;
  }

  @GET('jobs/stats')
  @Produces(MediaType.JSON)
  public getUserStats(): Observable<any> {
    return;
  }

  public startBulkListPersonJobUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!) - move method to Job API, so we can create job from both Person and jobs page
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'people/bulk-list-job', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  public startBulkListClinicalTrialProfileJobUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!) - only via Jobs page
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'clinical-trial-profiles/bulk-list-job', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  public startBulkListCommitteeJobUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!) - move method to Job API, so we can create job from both Person and jobs page
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'committee/bulk-list-job', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  public startBulkListAccountJobUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!) - move method to Job API, so we can create job from both Person and jobs page
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'accounts/bulk-list-job', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  public startBulkListOrganizationJobUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!) - move method to Job API, so we can create job from both Person and jobs page
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'organizations/bulk-list-job', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  public startBulkListDocumentJobUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!) - move method to Job API, so we can create job from both Person and jobs page
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'document/bulk-list-job', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  public startBulkListEventJobUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!) - move method to Job API, so we can create job from both Person and jobs page
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'events/bulk-list-job', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  public startBulkListEventSeriesJobUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'events-series/bulk-list-job', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  public startBulkListMIJobUpload(file: File, secondEyes: string): Observable<string> {
    const formData: FormData = new FormData();
    formData.set('xlsx', file);
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'medical-insights-profile/bulk-list-job', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  @GET('jobs/')
  @Produces(MediaType.JSON)
  public find(
    @Query('name') name?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<Job[]> {
    return;
  }

  @GET('jobs/count')
  @Produces(MediaType.JSON)
  public count(@Query('filter') filter?: any, @Query('name') name?: string): Observable<any> {
    return;
  }

  @PATCH('jobs/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body job: Job): Observable<Job> {
    return;
  }

  @GET('jobs/types-list')
  @Produces(MediaType.JSON)
  public getTypes(): Observable<string[]> {
    return;
  }

  @PATCH('jobs/reset/{id}')
  @Produces(MediaType.JSON)
  public reset(@Path('id') id: string): Observable<Job> {
    return;
  }

  @POST('jobs/bulk-update')
  @Produces(MediaType.JSON)
  public bulkUpdate(
    @Body data: { ids?: string[]; condition?: any; values: { [key: string]: any } }
  ): Observable<{ bulkTrackingId }> {
    return;
  }
  simple: true = true;

  /**
   * Get the file as binary
   */
  public downloadJobsFile(filter?: any): Observable<any> {
    const filterUrl = filter ? 'filter=' + encodeURIComponent(JSON.stringify(filter)) : null;
    return this.http
      .get(this.getBaseUrl() + 'jobs/export' + (filterUrl ? '?' + filterUrl : ''), { responseType: 'blob' })
      .pipe(
        catchError((err) => {
          console.log(err);
          return this.handleError(err);
        })
      );
  }
}
