import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ProfileDirectLinkComponent } from './direct-link.component';
import { ProfileDirectLinkOptions } from './direct-link-options.interface';

@Injectable()
export class ProfileDirectLinkService {
  constructor(private svcModal: NgbModal) {}

  open(options: ProfileDirectLinkOptions): Promise<any> {
    const ref = this.svcModal.open(ProfileDirectLinkComponent);

    ref.componentInstance.options = options;

    return ref.result;
  }
}
