import { CdkPortalOutlet, ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Component, ComponentRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subject, takeUntil, timer } from 'rxjs';

import { SideBarService } from './side-bar.service';

@Component({
  selector: 'dirt-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit, OnDestroy {
  isClosed = true;

  @ViewChild(CdkPortalOutlet)
  private portalContainer: CdkPortalOutlet;

  private destroy$: Subject<boolean> = new Subject();

  constructor(@Inject(DOCUMENT) private document: Document, private sideBarService: SideBarService) {}

  ngOnInit(): void {
    this.sideBarService._componentRef = this;
  }

  ngOnDestroy(): void {
    this.document.body.style.overflow = 'initial'; // Make sure we don't trap scroll
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  open<T>(content: ComponentType<T>): ComponentRef<T> {
    this.document.body.style.overflow = 'hidden';
    this.isClosed = false;

    const portal = new ComponentPortal(content);

    return this.portalContainer.attach(portal);
  }

  close(): void {
    this.document.body.style.overflow = 'initial';
    this.isClosed = true;

    timer(300)
      .pipe(takeUntil(this.destroy$)) // Cancelation in case component is destroyed before closing - timer complete after one invocation so no need for take(1)
      .subscribe(() => {
        this.portalContainer.detach();
      });
  }
}
