import { ActivatedRoute, Router } from '@angular/router';
import { capitalize } from 'lodash';
import { forkJoin, Observable, Subject } from 'rxjs';
import { format } from 'date-fns';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnInit, Component, ViewChild, Input, OnDestroy } from '@angular/core';
import { take, debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

import { Account } from '../shared/account';
import { AccountAPI } from '../shared/account-api.service';
import { AccountStatus } from '../shared/constant/status.enum';
import { ACL } from '../../shared/acl/acl.service';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkModalComponent, BULK_MODAL_OPTIONS } from '../../common/bulk/component/bulk-modal/bulk-modal.component';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { Filter, FiltersComponent, FilterType } from '../../shared/components/filters/filters.component';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { JobsAPI } from '../../jobs/shared/api.service';
import { AccountMedicalInformationAPI } from '../../account-medical-information/shared/account-medical-information-api.service';

@Component({
  selector: 'dirt-account-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class AccountListComponent implements OnInit, OnDestroy {
  @Input()
  parentId: string;

  accounts: Account[];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 25;
  pagingSkip = 0;

  // Sorting / Filtering settings
  searchCtrl: FormControl = new FormControl('');
  filter: { [key: string]: any };
  filters: Filter[] = [];
  filterBarConfig = {
    'address.countryCode': {
      label: 'Country',
      format: (value: string) => {
        return this.countries.get(value) || value;
      },
    },
    '_meta.status': {
      label: 'Status',
      format(value: string) {
        return capitalize(value).replace(/_/g, ' ');
      },
    },
    createdAt: {
      label: 'Created at',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
    updatedAt: {
      label: 'Modified at',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
  };

  bulkListDelegate: BulkDelegate = {
    title: 'Bulk Import',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListUpload.bind(this),
    specificNotifyReloadFct: this.doLoad.bind(this),
  };

  medicalInformationBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import Medical Information',
    type: BulkType.LIST,
    specificStartFct: this.handleMedicalInformationBulkListUpload.bind(this),
    specificNotifyReloadFct: this.doLoad.bind(this),
  };

  jobBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import Job',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListJobUpload.bind(this),
    specificNotifyReloadFct: this.doLoad.bind(this),
  };

  private searchTerm: string;

  private sort = '-_id';

  @ViewChild(FiltersComponent, { static: false })
  private filterRef: FiltersComponent;

  private countries: Map<string, string> = new Map();

  private destroy$: Subject<boolean> = new Subject();

  isPriorityEditable: boolean;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly svcAccount: AccountAPI,
    private readonly svcAccountMedicalInformation: AccountMedicalInformationAPI,
    private readonly svcACL: ACL,
    private readonly svcValue: ValueAPI,
    private readonly svcJob: JobsAPI,
    private readonly svcModal: NgbModal
  ) {}

  ngOnInit(): void {
    this.filters.push(
      {
        title: 'Status',
        key: '_meta.status',
        type: FilterType.MULTI_VALUE,
        values: Object.keys(AccountStatus)
          .sort()
          .map((statusKey) => ({ title: AccountStatus[statusKey], displayValue: AccountStatus[statusKey] })),
      },
      {
        title: 'Created',
        key: 'createdAt',
        type: FilterType.DATE_RANGE,
      },
      {
        title: 'Modified',
        key: 'updatedAt',
        type: FilterType.DATE_RANGE,
      }
    );

    forkJoin({
      countries: this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title'),
      params: this.route.queryParams.pipe(take(1)),
    }).subscribe(({ countries, params }) => {
      const filter = {
        title: 'Countries',
        key: 'address.countryCode',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: countries.map((value) => ({ title: value.code as string, displayValue: value.title })),
      };

      this.filters = [...this.filters, filter]; // DO break reference when creating a filter from an Observable/Promise (otherwise change detection won't run)
      this.countries = new Map(countries.map((value) => [value.code as string, value.title]));

      if (params.searchTerm) {
        this.searchTerm = params.searchTerm;
        this.searchCtrl.setValue(this.searchTerm, { emitEvent: false }); // restore search term
      }
      if (params.sortField) {
        this.sort = params.sortField;
      }

      this.doLoad(); // We rely on countries to map code to country name
    });

    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.searchTerm = term;
        this.doLoad();
      });

    this.isPriorityEditable = !this.parentId && this.svcACL.hasCredential('account.update.prop.priority');
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /** just avoid useless rendering if we can */
  trackById(index: number, account: Account): string {
    return account.id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getAccounts();
  }

  onSort(field: string): void {
    this.sort = field;

    this.storeFiltersAsQueryParams();
    this.getAccounts();
  }

  onFilter(filter: { [key: string]: any }): void {
    this.filter = filter;
    this.doLoad();
  }

  onDelete(id: string): void {
    if (!this.svcACL.hasCredential('account.delete')) {
      return;
    }

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.svcAccount.deleteById(id).subscribe(() => {
      this.getAccounts();
      this.getAccountsCount();
    });
  }

  onFilterClear(): void {
    this.filterRef.doClear();
  }

  onFilterValueRemove(item: { key: string; value: any }): void {
    this.filterRef.removeValue(item.key, item.value);
  }

  onOpenBulkListDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.bulkListDelegate;
  }

  onOpenMedicalInformationBulkListDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.medicalInformationBulkListDelegate;
  }

  onOpenBulkListJobDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.jobBulkListDelegate;
  }

  navigateTo(id: string): void {
    if (!id) {
      return;
    }

    this.router.navigate(['/account/detail', id]);
  }

  onExport(): void {
    this.svcAccount.downloadAccountsFile(this.filter, this.searchTerm).subscribe((blob) => {
      const downloadLink = document.createElement('a');

      const dateTime = new Date().toLocaleString();
      downloadLink.setAttribute('download', `accounts_list_${dateTime}.xlsx`);
      downloadLink.setAttribute('href', URL.createObjectURL(blob));
      downloadLink.click();

      downloadLink.parentElement.removeChild(downloadLink);
    });
  }

  private doLoad(): void {
    this.storeFiltersAsQueryParams();
    this.resetPagination();
    this.getAccounts();
    this.getAccountsCount();
  }

  private storeFiltersAsQueryParams(): void {
    if (this.parentId) {
      return;
    }

    if (this.searchTerm || this.sort) {
      const filters = {
        ...(this.searchTerm && { searchTerm: this.searchTerm }),
        ...(this.sort && { sortField: this.sort }),
      };
      this.router.navigate([], { queryParams: filters });
    }
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getAccounts(): void {
    this.isLoading = true;

    if (this.sort === '-_id' && this.searchTerm) {
      // Remove sorting by id when term is not empty (we want list ordered by closest match)
      this.sort = '';
    } else if (!this.sort && !this.searchTerm) {
      // Revert sorting by id when term is empty
      this.sort = '-_id';
    }

    this.svcAccount
      .find(
        this.searchTerm,
        this.pagingLimit,
        this.pagingSkip,
        this.sort,
        { ...this.filter, parent: this.parentId },
        true
      )
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((accounts) => {
        this.accounts = accounts;
      });
  }

  private getAccountsCount(): void {
    this.svcAccount.count(this.searchTerm, { ...this.filter, parent: this.parentId }).subscribe((count) => {
      this.total = count;
    });
  }

  private handleBulkListUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcAccount.startBulkListUpload(file, secondEyes);
  }

  private handleMedicalInformationBulkListUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcAccountMedicalInformation.startBulkListUpload(file, secondEyes);
  }

  private handleBulkListJobUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcJob.startBulkListAccountJobUpload(file, secondEyes);
  }

  private getMergedAccount(localData: Account, remoteData: Account): Account {
    localData.updatedAt = remoteData.updatedAt;
    localData._version = remoteData._version;
    return localData;
  }

  setPriority(account: Account, priority: number): void {
    if (!priority || (account.priority && account.priority === priority)) {
      return;
    }

    this.svcAccount.update(account.id, { priority } as Account).subscribe((updatedAccount) => {
      this.accounts = this.accounts.map((acc) => {
        if (acc.id === updatedAccount.id) {
          return this.getMergedAccount(acc, updatedAccount);
        }
        return acc;
      });
    });
  }

  getAccountLevel(account: Account): string | null {
    // if root level
    if (account.level < 2) {
      return null;
    }

    switch (account.level) {
      case 2:
        return 'DIVISION';
      case 3:
        return 'HOSPITAL';
      case 4:
        return 'SUBDIVISION';
      case 5:
        return 'LEVEL5';
      default:
        console.warn('Illegal account level!');
        return 'ILLEGAL';
    }
  }
}
