<div class="tag-container" [ngClass]="{ 'justify-content-end': alignLeft }">
  <div ngbDropdown *ngIf="!readonly">
    <div class="dirt-add-tag-container" [ngClass]="{ disabled: disabled || tags?.length }" ngbDropdownToggle>
      <div class="dirt-add-tag">
        <i class="fa fa-plus" aria-hidden="true"></i>
        <div class="dirt-triangle"></div>
      </div>
    </div>
    <div ngbDropdownMenu class="dirt-tag-select" [ngClass]="{ disabled: disabled || tags?.length }">
      <ng-container *ngFor="let vm of ProfileTag | iterable">
        <button class="dropdown-item" [ngClass]="{ disabled: tags?.includes(vm.key) }" (click)="onClick(vm.key)">
          {{ vm.value }}
        </button>
      </ng-container>
    </div>
  </div>
  <div class="dirt-profile-tags">
    <span *ngFor="let tag of tags" class="badge dirt-profile-tag" [ngClass]="getTagClass(tag)">
      {{ tag }}
      <i *ngIf="!readonly && !disabled" class="fa fa-times" aria-hidden="true" (click)="onRemoveClick(tag)"></i>
    </span>
  </div>
</div>
