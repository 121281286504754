import { Component, Input } from '@angular/core';

import { ACL } from '../../../shared/acl/acl.service';
import { Profile } from '../profile';
import { ProfileAPI } from '../api.service';
import { ProfileViewMode } from '../constant/view-mode.enum';

@Component({
  selector: 'dirt-profile-view-mode',
  templateUrl: 'view-mode.component.html',
  styleUrls: ['view-mode.component.scss'],
})
export class ProfileViewModeComponent {
  @Input()
  profile: Profile;

  viewModes = ProfileViewMode;

  isSaving = false;

  constructor(private svcAcl: ACL, private svcProfile: ProfileAPI) {}

  setViewMode(profile: Profile, viewMode: ProfileViewMode) {
    if (viewMode === profile.viewMode) {
      return;
    }

    this.isSaving = true;

    this.svcProfile.update(profile.id, <Profile>{ viewMode }).subscribe(
      (result) => (profile.viewMode = result.viewMode),
      null,
      () => (this.isSaving = false)
    );
  }

  isEditable(): boolean {
    return this.svcAcl.hasCredential('profile.update');
  }
}
