import { Component, EventEmitter, OnDestroy, Output, Input, OnInit } from '@angular/core';

import { CustomFilterComponent } from '../../../../shared/components/filters/filters.component';

@Component({
  selector: 'dirt-year-filter',
  templateUrl: './year-filter.component.html',
  styleUrls: ['./year-filter.component.scss'],
})
export class YearFilterComponent implements OnInit, OnDestroy, CustomFilterComponent {
  @Input()
  value: { $gte?: Date; $lte?: Date } = {};

  @Output()
  valueChange = new EventEmitter<{ $gte?: Date; $lte?: Date }>();

  rawValue: { $gte?: number; $lte?: number } = {};

  ngOnInit(): void {
    this.rawValue.$gte = this.value?.$gte?.getFullYear();
    this.rawValue.$lte = this.value?.$lte?.getFullYear();
  }

  ngOnDestroy(): void {
    this.valueChange.complete();
  }

  doCancel(previousValue: { $gte?: Date; $lte?: Date }): void {
    this.rawValue.$gte = previousValue?.$gte?.getFullYear();
    this.rawValue.$lte = previousValue?.$lte?.getFullYear();
  }

  doClear(): void {
    this.rawValue = {};
  }

  onRawValueChange(): void {
    const value: { $gte?: Date; $lte?: Date } = {};

    if ((this.rawValue.$gte || 0) >= (this.rawValue.$lte || 0)) {
      this.rawValue.$lte = this.rawValue.$gte || 0;
    }

    if (!this.rawValue.$gte) {
      this.rawValue.$gte = this.rawValue.$lte;
    }

    if (this.rawValue.$gte) {
      const $gte = new Date(0, 0, 1); // First day of the year
      $gte.setFullYear(this.rawValue.$gte);
      value.$gte = $gte;
    }

    if (this.rawValue.$lte) {
      const $lte = new Date(0, 11, 31); // last day of the year
      $lte.setFullYear(this.rawValue.$lte);
      value.$lte = $lte;
    }

    this.valueChange.next(value);
  }
}
