<section class="dirt-section-detail dirt-document-detail">
  <div class="row" *ngIf="!isLoading; else loader">
    <div class="col-md-8">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <div class="dirt-title-ct">
        <h1 class="bounceInRight">Document Detail</h1>

        <button type="button" class="btn btn-outline-primary" (click)="onCopyID(document.id)">Copy ID</button>
      </div>

      <dirt-document-form
        #frmDocument="frmDocument"
        [model]="document"
        (validityChange)="onFormValidityChange($event)"
      ></dirt-document-form>

      <!-- Job controls -->
      <div *ngIf="hasJobForCurrentUser; else updateControls">
        <div class="pull-left">
          <!-- Unable to Compile -->
          <button class="btn btn-outline-danger" (click)="onJobUtc()">Unable to Compile!</button>
        </div>

        <div class="pull-right">
          <!-- Save as Draft -->
          <button
            class="btn btn-secondary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="isSubmitting"
            (click)="onJobDraft()"
          >
            Save as Draft
          </button>

          <!-- Submit -->
          <button
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="(!isFormValid && !canSaveAnyway) || isSubmitting"
            (click)="onSubmitJob(frmDocument.getValue())"
          >
            {{ !isFormValid && canSaveAnyway ? 'Submit Job Anyway' : 'Submit Job' }}
          </button>
        </div>
      </div>

      <!-- Standard controls -->
      <ng-template #updateControls>
        <div class="pull-right" *ngIf="canSubmitNonJob">
          <button
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="(!isFormValid && !canSaveAnyway) || isSubmitting"
            (click)="onSave(frmDocument.getValue())"
          >
            {{ !isFormValid && canSaveAnyway ? 'Save Anyway' : 'Save' }}
          </button>
        </div>
      </ng-template>
    </div>

    <div class="col-md-4">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Workflow -->
        <li ngbNavItem>
          <a ngbNavLink>Workflow</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <dt class="col-sm-4" [hidden]="!isClaimedByOtherUser()"></dt>
              <dd class="col-sm-8" [hidden]="!isClaimedByOtherUser()">
                <span title="s/o else started working recently" class="badge badge-info">Claimed</span>
              </dd>

              <dt class="col-sm-4" *ngIf="hasJobForCurrentUser">Job</dt>
              <dd
                class="col-sm-8"
                *ngIf="hasJobForCurrentUser"
                [innerText]="currentJob?.type + (currentJob?.hint ? ' - ' + currentJob.hint : '')"
              ></dd>

              <dt class="col-sm-4" [ngClass]="{ 'di-separator': hasJobForCurrentUser }">Created</dt>
              <dd class="col-sm-8" [ngClass]="{ 'di-separator': hasJobForCurrentUser }">
                {{ document.createdAt | date : 'medium' }}
              </dd>

              <dt class="col-sm-4 text-truncate">Modified</dt>
              <dd class="col-sm-8">{{ document.updatedAt | date : 'medium' }}</dd>

              <dt class="col-sm-4 text-truncate">Priority</dt>
              <dd class="col-sm-8">
                <ngb-rating
                  [rate]="document.priority"
                  (rateChange)="onSetPriority(document, $event)"
                  max="5"
                  [readonly]="!isPriorityEditable"
                >
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
                  </ng-template>
                </ngb-rating>
                <i class="fa fa-circle-o-notch fa-spin" [hidden]="!isSavingPriority"></i>
              </dd>

              <dt class="col-sm-4 text-truncate">Status</dt>
              <dd class="col-sm-8">
                <span *diAcl="'!document.update.prop.meta.status'">{{ document._meta?.status | capitalize }}</span>
                <ng-container *diAcl="'document.update.prop.meta.status'">
                  <dirt-status
                    [statuses]="statuses"
                    [assigneeStatuses]="assigneeStatuses"
                    [disabledStatuses]="disabledStatuses"
                    [_meta]="document._meta || {}"
                    (onStatusSelected)="onStatusChange($event)"
                  >
                  </dirt-status>
                </ng-container>
                <i class="fa fa-circle-o-notch fa-spin" [hidden]="!isSavingStatus"></i>
              </dd>

              <dt class="col-sm-4">Version</dt>
              <dd class="col-sm-8">{{ document._version || 0 }}</dd>

              <dt class="col-sm-4 di-separator">Connected KPs</dt>
              <dd class="col-sm-8 di-separator">{{ connectedKPsCount }}</dd>

              <ng-container *ngIf="hasUnpolishedAffiliations">
                <dt class="col-sm-4" title="Central organizations did not yet check all organizations">
                  Unpolished organizations
                </dt>
                <dd class="col-sm-8">YES</dd>
              </ng-container>

              <dt class="col-sm-4 di-separator">Delivery Ready</dt>
              <dd class="col-sm-8 di-separator">
                <i
                  class="fa"
                  [ngClass]="{ 'fa-check': document.readyForDelivery, 'fa-times': !document.readyForDelivery }"
                  aria-hidden="true"
                ></i>
              </dd>

              <ng-container *ngIf="document.identify?.at">
                <dt class="col-sm-4 text-truncate di-separator">Identified at</dt>
                <dd class="col-sm-8 di-separator">{{ document.identify.at | date : 'medium' }}</dd>

                <dt class="col-sm-4 text-truncate">Identified by</dt>
                <dd class="col-sm-8 font-italic">
                  <dirt-username [userId]="document.identify.by"></dirt-username>
                </dd>
              </ng-container>

              <ng-container *ngIf="document.mapping?.at">
                <dt class="col-sm-4 text-truncate">Mapped at</dt>
                <dd class="col-sm-8">{{ document.mapping.at | date : 'medium' }}</dd>

                <dt class="col-sm-4 text-truncate">Mapped by</dt>
                <dd class="col-sm-8 font-italic">
                  <dirt-username [userId]="document.mapping.by"></dirt-username>
                </dd>
              </ng-container>
            </dl>
          </ng-template>
        </li>

        <!-- Comments -->
        <ng-template [diAcl]="'document.comment.list'">
          <li ngbNavItem>
            <a ngbNavLink>Comments</a>
            <ng-template ngbNavContent>
              <dirt-comment-list [ownerId]="id" [enableCreate]="canCreateComments"></dirt-comment-list>
            </ng-template>
          </li>
        </ng-template>

        <!-- Audit Log -->
        <ng-template [diAcl]="'document.audit.list'">
          <li ngbNavItem>
            <a ngbNavLink>Audit Log</a>
            <ng-template ngbNavContent>
              <dirt-audit-log [id]="id" [entityAPI]="svcDocument"></dirt-audit-log>
            </ng-template>
          </li>
        </ng-template>

        <!-- Job History -->
        <ng-template [diAcl]="'document.job-history.list'">
          <li ngbNavItem>
            <a ngbNavLink>Job History</a>
            <ng-template ngbNavContent>
              <dirt-job-history [entityId]="id"></dirt-job-history>
            </ng-template>
          </li>
        </ng-template>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-document-meta"></div>
    </div>

    <div class="col-md-12" *diAcl="'document.person.list'">
      <dirt-document-person-list [document]="document"></dirt-document-person-list>
    </div>
  </div>

  <!-- Loading Message -->
  <ng-template #loader>
    <p class="dirt-loading">
      <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
      <span class="sr-only">Loading...</span>
    </p>
  </ng-template>
</section>
