import { Component, Input } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { tap, finalize, catchError } from 'rxjs/operators';

import { MedicalInsightsProfileAPI } from '../shared/medical-insights-profile-api.service';
import { ResultDialogService } from './../../shared/modals/result-dialog/result-dialog.service';

@Component({
  selector: 'dirt-mi-profile-copy',
  templateUrl: './mi-profile-copy.component.html',
})
export class MedicalInsightsProfileCopyComponent {
  @Input()
  noteId: string = ''; // aka. `mongo_id` of the note

  modalOption: NgbModalOptions = {};
  modalRef: NgbModalRef;

  numOfCopies: number = 0;
  isReplicating: boolean = false;

  constructor(
    protected modalService: NgbModal,
    private miService: MedicalInsightsProfileAPI,
    private resultDialogService: ResultDialogService
  ) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.numOfCopies = 0;
  }

  onOpenModal(dialog) {
    this.modalRef = this.modalService.open(dialog, this.modalOption);
  }

  async onSubmit() {
    if (!this.numOfCopies) {
      return;
    }

    this.isReplicating = true;
    this.modalRef.close();

    this.miService
      .replicate(this.noteId, { copies: this.numOfCopies })
      .pipe(
        tap(async (response) => {
          await this.resultDialogService.open({
            title: 'Medical Insight Profile got copied to the note ID(s):',
            results: response,
          });
        }),
        catchError((error) => {
          alert('Error while creating a copy of MI profile!');
          throw error;
        }),
        finalize(() => {
          this.isReplicating = false;
          this.numOfCopies = 0;
        })
      )
      .subscribe();
  }

  getNumbers(): number[] {
    // for the UI dropdown - limit of 20 copies
    return Array.from({ length: 20 }, (_, i) => i + 1);
  }
}
