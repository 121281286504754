import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountPayerInformationComponent } from './account-payer-information.component';
import { AccountPayerInformationCreateComponent } from './create/create.component';
import { AccountPayerInformationDetailComponent } from './detail/detail.component';
import { AccountPayerInformationModule } from './account-payer-information.module';
import { AclGuard } from '../shared/acl/guard.service';
import { AuthGuard } from '../shared/services/guard/auth-guard.service';

export const accountPayerInformationRoutes: Routes = [
  {
    path: 'account-payer-information',
    component: AccountPayerInformationComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: 'create',
        component: AccountPayerInformationCreateComponent,
      },
      {
        path: 'detail/:id',
        component: AccountPayerInformationDetailComponent,
      },
    ],
  },
];

export const accountPayerInformationRouting: ModuleWithProviders<AccountPayerInformationModule> =
  RouterModule.forChild(accountPayerInformationRoutes);
