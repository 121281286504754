<section class="dirt-section-list dirt-job-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>Jobs</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input type="search" class="form-control" [formControl]="searchCtrl" placeholder="Search by entity id..." />
    </div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- No bulk, job validation is entity specific, use the controls available in each modules -->

        <!-- Filter -->
        <dirt-filters [filters]="filters" (onFilter)="onFilter($event)"></dirt-filters>

        <dirt-button-dropdown [title]="'Bulk'" [icon]="'fa-pencil'" *diAcl="'job.update'">
          <dirt-bulk-update
            [filter]="filter"
            [filterCount]="total.count"
            [search]="searchTerm"
            (refresh)="doLoad()"
            [options]="bulkUpdateConfig"
          ></dirt-bulk-update>
          <!-- create jobs - same as manager start page, have the function where it's most expected, now that we have a list -->
          <dirt-jobs-next-create *diAcl="'job.create'" (notifyReload)="doLoad()"></dirt-jobs-next-create>
        </dirt-button-dropdown>
      </div>
    </div>
  </header>

  <div class="pull-right">
    <!-- Dashboards -->
    <dirt-button-dropdown [title]="'Dashboards'" [icon]="'fa-tachometer'">
      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/current-jobs/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Current Jobs
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/job-performance/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Job Performance
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/recent-job-by-origin/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Jobs by Origin
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/job-qc-general-stats/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Jobs QC (general stats)
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/job-qc-feedback/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Jobs QC feedback
      </a>
    </dirt-button-dropdown>

    <!-- Export -->
    <button class="btn btn-secondary btn-sm" (click)="onExportJobs()" *diAcl="'job.export'">
      <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Export List
    </button>
  </div>

  <br />

  <!-- Filter Bar -->
  <dirt-filters-bar
    [filter]="filter"
    [config]="filterBarConfig"
    (filterClear)="onFilterClear()"
    (filterValueRemove)="onFilterValueRemove($event)"
  ></dirt-filters-bar>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-job-list'"
    [pagingSize]="jobs?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="jobs?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-type" [diColSorter]="'type'">Type</th>
          <th class="col-entityId" [diColSorter]="'entityId'">Entity ID</th>
          <th class="col-pool" [diColSorter]="'pool'">Pool</th>
          <th class="col-category" [diColSorter]="'category'">Category</th>
          <th class="col-status" [diColSorter]="'_meta.status'">Status</th>
          <th class="col-created" [diColSorter]="'createdAt'">Created</th>
          <th class="col-completedBy" [diColSorter]="'completedBy'">Completed By</th>
          <th class="col-completedAt" [diColSorter]="'completedAt'">Completed At</th>
          <th class="col-priority" [diColSorter]="'priority'">Priority</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-job-row"
          *ngFor="
            let job of jobs
              | paginate
                : {
                    id: 'dirt-job-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <td class="col-type">
            <a (click)="openLinkInNewTab(job.id)">{{ job.type }}</a>
          </td>
          <td class="col-entityId">
            <!-- URL to the entity-->
            <dirt-job-entity-url [model]="job"></dirt-job-entity-url>
          </td>
          <td class="col-pool">{{ job.pool }}</td>
          <td class="col-category">{{ job.category || '-' }}</td>
          <td class="col-status" [title]="job._meta?.status | capitalize">{{ job._meta?.status | capitalize }}</td>
          <td class="col-created">{{ job.createdAt | date : 'dd.MM.y' }}</td>
          <td class="col-completedBy">
            <dirt-username *ngIf="job.completedBy" [userId]="job.completedBy"></dirt-username>
          </td>
          <td class="col-completedAt">{{ job.completedAt | date : 'dd.MM.y' }}</td>
          <td class="col-priority disabled">
            <ngb-rating [rate]="job.priority" [readonly]="true" max="5">
              <ng-template let-fill="fill" let-index="index">
                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
              </ng-template>
            </ngb-rating>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No Jobs</p>
    </ng-template>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-job-list'"
    [pagingSize]="jobs?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
