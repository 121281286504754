import { catchError, concatMap, map, tap } from 'rxjs/operators';
import { Component } from '@angular/core';
import { of, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { ACL } from '../../shared/acl/acl.service';
import { Document } from '../shared/document';
import { DocumentAPI } from '../shared/document-api.service';

@Component({
  selector: 'dirt-document-create',
  templateUrl: './create.component.html',
})
export class DocumentCreateComponent {
  document: Document = new Document();

  isSubmitting = false;

  isFormValid: boolean;

  constructor(private router: Router, private svcDocument: DocumentAPI, private svcACL: ACL) {}

  goBack(): void {
    const link = ['/document'];
    this.router.navigate(link);
  }

  onSubmit(document: Document): void {
    if (!this.svcACL.hasCredential('document.create') || !document || !this.isFormValid) {
      return;
    }

    document._meta = {} as any;

    this.isSubmitting = true;
    this.svcDocument
      .create(document)
      .pipe(
        map(() => true),
        catchError((error) => {
          if (error.status === 412 && error.error?.potentialDuplicate) {
            return of(confirm(`Found potential duplicate: ${error.error.potentialDuplicate}. Save anyway?`)).pipe(
              concatMap((confirmed) => {
                if (confirmed) {
                  return this.svcDocument.create(document, true);
                }
                return of(null);
              })
            );
          }

          return throwError(() => error);
        }),
        tap(() => (this.isSubmitting = false))
      )
      .subscribe((res) => {
        if (!res) {
          return;
        }

        this.goBack();
      });
  }

  onFormValidityChange(status: string): void {
    this.isFormValid = status === 'VALID';
  }
}
