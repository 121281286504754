<span>
  <a href="javascript:void(0)" (click)="onOpenModal(dialog)"> Find by ID </a>
</span>

<ng-template #dialog let-close="close">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Find by Person ID</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="inputValue">Person ID</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          [class]="'form-control ' + (inputValue ? 'ng-valid' : 'ng-invalid')"
          [value]="inputValue"
          (input)="inputValue = $event.target.value"
          id="inputValue"
        />
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="pull-right">
      <button type="button" class="btn btn-primary btn-sm" (click)="onSubmit()">Submit</button>
      <button type="button" class="btn btn-secondary btn-sm" (click)="close()">Cancel</button>
    </div>
  </div>
</ng-template>
