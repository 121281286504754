import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { JobsModalComponent, JobType } from './modal.component';

@Injectable()
export class JobsModalService {
  constructor(private svcModal: NgbModal) {}

  open(jobTypes: JobType[]): Promise<any> {
    const ref = this.svcModal.open(JobsModalComponent);
    ref.componentInstance.jobTypes = jobTypes;
    return ref.result;
  }
}
