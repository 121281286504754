import { Component, HostListener, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

import { ACL } from '../../acl/acl.service';
import { Roles } from '../../acl/roles';
import { PersonMarkerWrapperDirective } from '../../directives/marker-wrapper/wrapper.directive';
import { MarkerArea, MarkerEntity, STD_JOB_AREAS } from '../../directives/marker/marker.directive';
import { ValueType } from '../../enum/value-type.enum';
import { Value } from '../../services/value/value';
import { ValueAPI } from '../../services/value/value-api.service';

@Component({
  selector: 'di-marker-container',
  templateUrl: 'container.component.html',
  styleUrls: ['container.component.scss'],
})
export class EntityMarkerContainerComponent implements OnInit {
  @Input()
  template: TemplateRef<any>;

  @ViewChild(NgbPopover, { static: true })
  popover: NgbPopover;

  fieldName: string;
  area: MarkerArea = 'BD';
  entity: MarkerEntity = 'person';
  leftAlign: boolean = false;
  lfkaOnlyProject: boolean = false;
  defaultFixed: boolean = false;

  isShown = false;

  errorTypes = [];

  errors: { comment; type; fixed? }[] = [
    {
      comment: '',
      type: null,
    },
  ];

  checked = false;
  private prevChecked = false;

  private fieldsErrorTypes: Value[];

  constructor(
    private readonly svcAcl: ACL,
    private svcValue: ValueAPI,
    private readonly markerWrapper: PersonMarkerWrapperDirective
  ) {}

  ngOnInit(): void {
    // We cannot pull only when the marker is first opened due to CT relying on errors being defined for them in people.
    // Yet ideally we move the error pulling to a store so that all errors are pulled exactly once (instead of one time per marker instance)
    this.svcValue
      .find(ValueType.FieldErrorType, Number.MAX_SAFE_INTEGER, 0, '', { 'value.field': this.fieldName })
      .subscribe((errors) => {
        this.fieldsErrorTypes = errors;
        this.updateDisplayedErrors();
      });
  }

  public updateEntity(newEntity, newArea) {
    this.entity = newEntity;
    this.area = newArea;
    this.updateDisplayedErrors(); // Might receive area / entity later, so refresh the list
  }

  private async updateDisplayedErrors() {
    // Cannot skip pulling errors since we wouldn't show what's there to people not working QC
    // Yet we really should pull ONCE (e.g. in a store) instead of one time per instance.
    // Currently CT detail view is 3-4x slower than it should due to QC markers.
    // if (this.entity === 'noop') { // No QC possible - don't go an pull errors we won't use
    //   return;
    // }

    const value = this.markerWrapper.getValue(this.fieldName);

    this.errors = [
      {
        comment: '',
        type: null,
        ...(this.defaultFixed ? { fixed: true } : {}),
      },
    ];

    this.prevChecked = this.checked;
    this.checked = value?.checked || false;

    if (!Array.isArray(this.fieldsErrorTypes)) {
      return;
    }

    const isViewer =
      !this.svcAcl.hasCredential(this.entity + '.qc') ||
      this.svcAcl.hasCredential(this.entity + '.idqc') ||
      this.svcAcl.hasCredential(this.entity + '.idbdqc');
    const hasTypedComment = value && value.type;

    /**
     * Control error visibility on a field basis while giving us enough
     * room to handle new processes.
     */
    const validErrors = this.fieldsErrorTypes
      .filter((o) => {
        if (!o.value?.visibleForRoles) {
          // Manage new method while preserving compatibility with the old one
          return false;
        }

        const hasExcludedRole = o.value?.excludedRoles?.some((r) => this.svcAcl.hasRole(r)) || false;
        const hasValidRole = o.value?.visibleForRoles?.some((r) => this.svcAcl.hasRole(r)) || false;
        const hasMandatoryRoles = Array.isArray(o.value?.mandatoryRoles)
          ? o.value.mandatoryRoles.every((r) => this.svcAcl.hasRole(r))
          : true; // no mandatory roles = OK

        if (!hasExcludedRole && hasValidRole && hasMandatoryRoles) {
          return true;
        }

        return false;
      })
      .filter((o) => {
        // same as below - only show LFKA specific errors on LFKA resources
        if (!o.value?.mandatoryRoles?.includes(Roles.ViewLfka)) {
          return true;
        }

        return this.svcAcl.hasRole(Roles.PersonReviewer) && this.svcAcl.hasRole(Roles.ViewLfka) && this.lfkaOnlyProject;
      });

    this.errorTypes = (
      isViewer && hasTypedComment
        ? this.fieldsErrorTypes
        : this.fieldsErrorTypes
            .filter((o) => {
              // get the right area
              if (o.value?.visibleForRoles) {
                // Manage new method while preserving compatibility with the old one
                return false;
              }

              if (
                ('BD' === this.area || 'ALL' === this.area) &&
                (this.svcAcl.hasCredential(this.entity + '.qc') ||
                  this.svcAcl.hasCredential(this.entity + '.idbdqc')) &&
                (!o.value?.area || 'BD' === o.value?.area || 'ALL' === o.value?.area)
              ) {
                return true;
              }
              if (
                ('ID' === this.area || 'ALL' === this.area) &&
                (this.svcAcl.hasCredential(this.entity + '.idqc') ||
                  this.svcAcl.hasCredential(this.entity + '.idbdqc')) &&
                ('ID' === o.value?.area || 'ALL' === o.value?.area)
              ) {
                return true;
              }
              if (
                'IMAGE' === this.area &&
                this.svcAcl.hasCredential(this.entity + '.imageqc') &&
                'IMAGE' === o.value?.area
              ) {
                return true;
              }
              // (all else)
              return false;
            })
            .filter((o) => {
              // get the right entity
              if (
                'person' === this.entity && // entity defaults to person when nothing defined
                (!o.value?.entity || 'person' === o.value?.entity)
              ) {
                return true;
              }
              if (this.entity === o.value?.entity) {
                return true;
              }
              // (all else)
              return false;
            })
    )
      .filter((o) => {
        if (!o.value?.lfkaOnly) return true;
        return this.svcAcl.hasRole(Roles.PersonReviewer) && this.svcAcl.hasRole(Roles.ViewLfka) && this.lfkaOnlyProject;
      })
      .map((o) => o.value);

    if ('person' === this.entity && this.svcAcl.hasRole('CT_REVIEWER')) {
      // only explicit (b/c no base data team)
      this.errorTypes = Array.from(new Set(validErrors.map((o) => o.value)));
    } else if (STD_JOB_AREAS.includes(this.area)) {
      this.errorTypes = this.fieldsErrorTypes.filter((o) => o.value?.area === this.area).map((o) => o.value);
    } else {
      this.errorTypes = Array.from(new Set([...this.errorTypes, ...validErrors.map((o) => o.value)]));
    }

    if (value && value.comment && !hasTypedComment) {
      // Single object level comment
      this.errors = [
        {
          comment: value.comment,
          type: null,
          ...(value.fixed ? { fixed: true } : {}),
        },
      ];
    } else if (hasTypedComment) {
      this.errors = value.type.map((t) => ({
        comment: t.comment || value.comment,
        type: this.errorTypes.find((et) => et.error === t.error),
        ...(t.fixed ? { fixed: true } : {}),
      }));
    }
  }

  doDiscard() {
    this.popover.close();
  }

  doSave() {
    if (this.errors.length === 1 && !this.errors[0].comment && !this.errors[0].type) {
      this.markerWrapper.apply(this.fieldName, this.errors[0].comment, undefined, this.checked);
    } else {
      const type = this.errors.map((error) => {
        let res;
        if (!error.type) {
          res = {
            comment: error.comment,
          };
        } else {
          res = {
            error: error.type.error,
            level: error.type.level,
            subField: error.type.subField,
            comment: error.comment,
          };
        }
        if (!!error.fixed) {
          res.fixed = true;
        }
        return res;
      });
      this.markerWrapper.apply(this.fieldName, null, type, this.checked);
    }

    this.popover.close();
  }

  doRemove() {
    this.errors = [
      {
        comment: '',
        type: null,
        ...(this.defaultFixed ? { fixed: true } : {}),
      },
    ];

    this.prevChecked = false;
    this.checked = false;

    this.doSave();
  }

  hasPermission(): boolean {
    if (
      ('BD' === this.area || STD_JOB_AREAS.includes(this.area) || 'ALL' === this.area) &&
      (this.svcAcl.hasCredential(this.entity + '.qc') || this.svcAcl.hasCredential(this.entity + '.idbdqc'))
    ) {
      return true;
    }
    if (
      ('ID' === this.area || 'ALL' === this.area) &&
      (this.svcAcl.hasCredential(this.entity + '.idqc') || this.svcAcl.hasCredential(this.entity + '.idbdqc'))
    ) {
      return true;
    }
    if ('IMAGE' === this.area && this.svcAcl.hasCredential(this.entity + '.imageqc')) {
      return true;
    }
    // (all else)
    return false;
  }

  onPopoverClose() {
    this.isShown = false;
  }

  @HostListener('mouseenter')
  doShow(): void {
    if (!this.hasPermission() && !(this.errors[0].comment || this.errors[0].type)) {
      return;
    }
    if ((this.svcAcl.hasRole('CT_REVIEWER') || STD_JOB_AREAS.includes(this.area)) && !this.errorTypes?.length) {
      return; // limit to few that we define explicitly
    }
    if (this.markerWrapper.suppressMarker) {
      return; // just don't show _any_thing
    }

    this.isShown = true;
  }

  @HostListener('mouseleave')
  doHide(): void {
    this.isShown = false;
  }

  onErrorTypeClick(error: any, errorType: any) {
    if (error.type && error.type.error === errorType.error) {
      // Remove and item
      error.type = null;
    } else {
      // Add item
      error.type = errorType;
    }
  }

  onAddNewErrorClick() {
    this.errors.push({
      comment: '',
      type: null,
      ...(this.defaultFixed ? { fixed: true } : {}),
    });
  }

  onRemoveErrorClick(error) {
    this.errors = this.errors.filter((e) => e !== error);
  }

  onDropClick(evnt: Event, drop) {
    evnt.preventDefault();
    if (drop.isOpen()) {
      drop.close();
    } else {
      drop.open();
    }
  }

  isSubmitDisabled() {
    if (this.prevChecked !== this.checked) {
      return false;
    }

    if (this.errorTypes.length && this.errors.filter((e) => !e.type).length) {
      return true;
    }

    if (!this.errorTypes.length && !this.errors[0].comment) {
      return true;
    }
  }

  onCheckedChange(): void {
    this.prevChecked = !this.checked;
  }

  canSetChecked(): boolean {
    return this.svcAcl.hasCredential(`${this.entity}.qc.checked`);
  }
}
