<button
  class="btn btn-secondary btn-sm"
  (click)="onShow()"
  [ngbPopover]="filterPopoverContent"
  [autoClose]="false"
  placement="bottom-right"
  #filterPopover="ngbPopover"
>
  Filter
  <i class="fa fa-sort-down" aria-hidden="true"></i>
</button>

<ng-template #filterPopoverContent>
  <div class="dirt-association-filter-content">
    <!-- Filter List -->
    <div class="card dirt-filter-list" *ngIf="!visiblePanel">
      <div class="card-header text-center">Filters</div>
      <div class="card-body" diScroller>
        <ul class="list-group">
          <li class="list-group-item justify-content-between" (click)="setPanel('areas')">
            Areas <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('products')">
            Products <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('category')">
            Category <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('region')">
            Regions
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('location')">
            Locations <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('maintenance')">
            Maintenance <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('locality')">
            Reach <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('scope')">
            Scope <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('status')">
            Status <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('therapeuticAreas')">
            Therapeutic Areas <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('projects')">
            Projects
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('compilers')">
            Compilers
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('creators')">
            Created By
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('type')">
            Type <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('verified')">
            Verified <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('idVerified', true)">
            ID Verified <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('reviewDate')">
            Review Date <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('createdDate')">
            Created Date <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
        </ul>
      </div>
      <div class="card-footer" *ngIf="filter">
        <button class="btn btn-outline-primary btn-sm pull-right" (click)="doClear()">Clear Filters</button>
      </div>
    </div>

    <!-- Filter Panel -->
    <div class="card dirt-filter-panel" *ngIf="visiblePanel">
      <div class="card-header text-center">
        <i class="fa fa-chevron-left dirt-back" aria-hidden="true" (click)="setPanel()"></i>
        <span>Filter by {{ visiblePanel | capitalize }}</span>
      </div>

      <!-- Type -->
      <div class="card-body" [hidden]="visiblePanel !== 'type'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let type of typeList"
            [class.selected]="isTypeSelected(type.code)"
            (click)="toggleType(type.code)"
          >
            {{ type.title | capitalize }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Locality -->
      <div class="card-body" [hidden]="visiblePanel !== 'locality'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let locality of localityList | keys"
            [class.selected]="isLocalitySelected(locality)"
            (click)="toggleLocality(locality)"
          >
            {{ locality | capitalize }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Category -->
      <div class="card-body" [hidden]="visiblePanel !== 'category'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let category of categoryList"
            [class.selected]="isCategorySelected(category.code)"
            (click)="toggleCategory(category.code)"
          >
            {{ category.title | capitalize }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Status -->
      <div class="card-body" [hidden]="visiblePanel !== 'status'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let status of statusList | iterable"
            [class.selected]="isStatusSelected(status.key)"
            (click)="toggleStatus(status.key)"
          >
            {{ status.value | capitalize }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Therapeutic Areas -->
      <div class="card-body" [hidden]="visiblePanel !== 'therapeuticAreas'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let ta of therapeuticAreaList"
            [class.selected]="isTherapeuticAreasSelected(ta.code)"
            (click)="toggleTherapeuticAreas(ta.code)"
          >
            {{ ta.title | capitalize }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Areas -->
      <div class="card-body" [hidden]="visiblePanel !== 'areas'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let ta of areaList"
            [class.selected]="isAreasSelected(ta.code)"
            (click)="toggleAreas(ta.code)"
          >
            {{ ta.title | capitalize }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Products -->
      <div class="card-body" [hidden]="visiblePanel !== 'products'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let p of productList"
            [class.selected]="isProductSelected(p.value)"
            (click)="toggleProducts(p.value)"
          >
            {{ p.value }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Scope -->
      <div class="card-body" [hidden]="visiblePanel !== 'scope'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let scope of scopeList"
            [class.selected]="isScopeSelected(scope)"
            (click)="toggleScope(scope)"
          >
            {{ scope | capitalize }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Maintenance -->
      <div class="card-body" [hidden]="visiblePanel !== 'maintenance'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            [class.selected]="selectedMaintenance"
            (click)="toggleMaintenance()"
          >
            In Maintenance <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Regions -->
      <div class="card-body" [hidden]="visiblePanel !== 'region'">
        <div class="text-center" [hidden]="!isLoadingRegions">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let region of regionList"
            [class.selected]="isRegionSelected(region)"
            (click)="toggleRegion(region)"
          >
            {{ region }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Locations -->
      <div class="card-body" [hidden]="visiblePanel !== 'location'">
        <div class="text-center" [hidden]="!isLoadingLocations">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <ul class="list-group" [hidden]="isLoadingLocations">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let loc of locationList"
            [class.selected]="isLocationSelected(loc)"
            (click)="toggleLocation(loc)"
          >
            {{ getCountryTitle(loc) }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Projects -->
      <div class="card-body" [hidden]="visiblePanel !== 'projects'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let pr of projectsList"
            [class.selected]="isProjectSelected(pr.code)"
            (click)="toggleProjects(pr.code)"
          >
            {{ pr.title }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Reviewers -->
      <div class="card-body" [hidden]="visiblePanel !== 'compilers'">
        <div class="text-center" [hidden]="!isLoadingCompilers">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <ul class="list-group" [hidden]="isLoadingCompilers">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let reviewer of compilersList"
            [class.selected]="isReviewedBySelected(reviewer.user_id)"
            (click)="toggleReviewedBy(reviewer.user_id, $event)"
          >
            <dirt-username [user]="reviewer"></dirt-username> <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Verified -->
      <div class="card-body" [hidden]="visiblePanel !== 'verified'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let type of [true, null]"
            [class.selected]="isVerifiedSelected(type)"
            (click)="toggleVerified(type)"
          >
            <span *ngIf="type === true">Verified</span>
            <span *ngIf="type === null">Unverified</span>
            <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Created By -->
      <div class="card-body" [hidden]="visiblePanel !== 'creators'">
        <div class="text-center" [hidden]="!isLoadingCreators">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <ul class="list-group" [hidden]="isLoadingCreators">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let creator of creatorsList"
            [class.selected]="isCreatedBySelected(creator.user_id)"
            (click)="toggleCreatedBy(creator.user_id, $event)"
          >
            <dirt-username [user]="creator"></dirt-username> <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- ID Verified -->
      <div class="card-body" [hidden]="visiblePanel !== 'idVerified'" *ngIf="!visibleSubPanel">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let option of idVerifiedOptions"
            [class.selected]="isIdVerifiedValueSelected(option.value)"
            (click)="toggleIdVerifiedValue(option.value, option.key)"
          >
            {{ option.key }}
            <i
              class="fa"
              [ngClass]="{ 'fa-chevron-right': option.key === 'ID Verified', 'fa-minus': option.key !== 'ID Verified' }"
              aria-hidden="true"
            ></i>
          </li>
        </ul>
      </div>
      <div class="card-body" [hidden]="visibleSubPanel !== 'idVerified'">
        <div class="text-center">
          <i class="fa fa-chevron-left dirt-back" aria-hidden="true" (click)="setSubPanel('')"> </i>
          <span>Choose sub options or press apply to filter</span>
        </div>
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let subOption of idVerifiedSubOptions"
            [class.selected]="isIdVerifiedSubValueSelected(subOption.value)"
            (click)="toggleIdVerifiedSubValue(subOption.value)"
          >
            {{ subOption.key }}
            <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <div class="card-body" [hidden]="visiblePanel !== 'reviewDate'">
        <div class="p-1 overflow-hidden">
          <div class="form-group row justify-content-center">
            <label class="col-sm-3 col-form-label">Start</label>
            <div class="col-sm-9">
              <input
                type="date"
                class="form-control"
                name="reviewDateFrom"
                placeholder="From"
                [max]="selectedReviewDate.end | date : 'yyyy-MM-dd'"
                [ngModel]="selectedReviewDate.start | date : 'yyyy-MM-dd'"
                (change)="setReviewDate(reviewDateFrom.valueAsDate, reviewDateTo.valueAsDate)"
                #reviewDateFrom
              />
            </div>
          </div>
          <div class="form-group row justify-content-center">
            <label class="col-sm-3 col-form-label">End</label>
            <div class="col-sm-9">
              <input
                type="date"
                class="form-control"
                name="reviewDateTo"
                placeholder="To"
                [min]="selectedReviewDate.start | date : 'yyyy-MM-dd'"
                [ngModel]="selectedReviewDate.end | date : 'yyyy-MM-dd'"
                (change)="setReviewDate(reviewDateFrom.valueAsDate, reviewDateTo.valueAsDate)"
                #reviewDateTo
              />
            </div>
          </div>
        </div>
      </div>
      <div class="card-body" [hidden]="visiblePanel !== 'createdDate'">
        <div class="p-1 overflow-hidden">
          <div class="form-group row justify-content-center">
            <label class="col-sm-3 col-form-label">Start</label>
            <div class="col-sm-9">
              <input
                type="date"
                class="form-control"
                name="createdDateFrom"
                placeholder="From"
                [max]="selectedCreatedDate.end | date : 'yyyy-MM-dd'"
                [ngModel]="selectedCreatedDate.start | date : 'yyyy-MM-dd'"
                (change)="setCreatedDate(createdDateFrom.valueAsDate, createdDateTo.valueAsDate)"
                #createdDateFrom
              />
            </div>
          </div>
          <div class="form-group row justify-content-center">
            <label class="col-sm-3 col-form-label">End</label>
            <div class="col-sm-9">
              <input
                type="date"
                class="form-control"
                name="createdDateTo"
                placeholder="To"
                [min]="selectedCreatedDate.start | date : 'yyyy-MM-dd'"
                [ngModel]="selectedCreatedDate.end | date : 'yyyy-MM-dd'"
                (change)="setCreatedDate(createdDateFrom.valueAsDate, createdDateTo.valueAsDate)"
                #createdDateTo
              />
            </div>
          </div>
        </div>
      </div>

      <div class="card-footer">
        <button class="btn btn-outline-primary btn-sm pull-left" (click)="doApply()">Apply</button>
        <button class="btn btn-outline-secondary btn-sm pull-right" (click)="doCancel()">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>
