<div class="di-container">
  <input
    class="form-control ng-trim-ignore"
    id="selected-users"
    [disabled]="disabled"
    [required]="required"
    [ngModel]="selectedUser"
    (ngModelChange)="onSelectedUserChange($event)"
    [ngbTypeahead]="search"
    [resultTemplate]="customItemTemplate"
    [inputFormatter]="formatName"
    tabindex="-1"
  />
  <div class="di-spinner" [hidden]="!(isLoading$ | async)">
    <span class="fa fa-refresh fa-spin" aria-hidden="true"></span>
  </div>
</div>
<!-- Typeahead Item Template -->
<ng-template #customItemTemplate let-model="result" let-t="term">
  <span>{{ model.user_metadata?.firstName }}</span>
  <span>{{ model.user_metadata?.lastName }}</span>
  <span><{{ model.email }}></span>
</ng-template>
