import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { isArray, isEmpty, isEqual, xorWith, pick, intersection, max, intersectionWith } from 'lodash';
import { Component, Host, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';

import { ACL } from '../../acl/acl.service';
import { TrainingEvaluationMarkerWrapperDirective } from '../../directives/training-evaluation-marker/training-evaluation-marker-wrapper.directive';
import { Auth } from '../../services/auth/auth.service';
import { ValueAPI } from '../../services/value/value-api.service';
import { Value } from '../../../shared/services/value/value';
import { ValueType } from '../../../shared/enum/value-type.enum';

@Component({
  selector: 'dirt-training-marker',
  templateUrl: './training-marker.component.html',
  styleUrls: ['./training-marker.component.scss'],
})
export class TrainingMarkerComponent implements OnInit {
  @Input()
  template: TemplateRef<any>;

  @ViewChild('trainingPopover')
  popover: NgbPopover;

  fieldName: string;

  value: any;
  baseValue: any;

  report: any;
  specialtyList: Value[] = [];

  constructor(
    private readonly svcAcl: ACL,
    private svcValue: ValueAPI,
    private readonly svcAuth: Auth,
    private readonly trainingEvaluationMarkerWrapper: TrainingEvaluationMarkerWrapperDirective
  ) {}

  ngOnInit(): void {
    if (
      !this.trainingEvaluationMarkerWrapper.isTrainingPerson() ||
      !this.trainingEvaluationMarkerWrapper.isPersonInDoneState() ||
      !this.trainingEvaluationMarkerWrapper.hasValidBaseValue()
    ) {
      return;
    }
    if (this.hasTrainerRole()) {
      this.updateReport();
      this.setReport();
    } else {
      this.svcAuth
        .getProfile()
        .toPromise()
        .then((authUser) => {
          if (this.trainingEvaluationMarkerWrapper.getReportCompilerId() === authUser.user_id) {
            this.setReport();
          }
        });
    }

    this.svcValue
      .find(ValueType.Specialty, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.specialtyList = data;
      });
  }

  updateReport(): void {
    this.value = this.trainingEvaluationMarkerWrapper.getValue(this.fieldName);
    this.baseValue = this.trainingEvaluationMarkerWrapper.getBaseValue(this.fieldName);
    this.sanitizeEmptyArrays();
    this.trainingEvaluationMarkerWrapper.updateReport(
      this.fieldName,
      this.getIsCorrect(),
      undefined,
      this.getFieldLevelErrorRate()
    );
  }

  sanitizeEmptyArrays() {
    if (this.baseValue && isArray(this.baseValue)) {
      if (this.baseValue.length === 1 && this.baseValue[0] === '') {
        this.baseValue = [];
      }
      this.baseValue = this.baseValue.sort();
    }
    if (this.value && isArray(this.value)) {
      if (this.value.length === 1 && this.value[0] === '') {
        this.value = [];
      }
      this.value = this.value.sort();
    }
  }

  setReport() {
    this.report = this.trainingEvaluationMarkerWrapper.getReport(this.fieldName) || {};
  }

  getIsCorrect(): boolean {
    if (!isArray(this.baseValue)) {
      return isEqual(this.baseValue || undefined, this.value || undefined); // to consider empty fields equally
    }
    if (this.fieldName === 'affiliations') {
      const fieldsToMatch = ['id', 'primary', 'lastKnown', 'inactive'];
      const baseValue = this.baseValue.map((item) => pick(item, fieldsToMatch));
      const currentValue = this.value.map((item) => pick(item, fieldsToMatch));

      const positionBaseValue = this.baseValue.map((item) => pick(item, ['id', 'position', 'originalPosition']));
      const positionCurrentValue = this.value.map((item) => pick(item, ['id', 'position', 'originalPosition']));
      const isPositionCorrect = isEmpty(xorWith(positionBaseValue, positionCurrentValue, isEqual));
      this.trainingEvaluationMarkerWrapper.updateReport('affiliations.positions', isPositionCorrect, undefined);

      return isEmpty(xorWith(baseValue, currentValue, isEqual));
    }
    return isEmpty(xorWith(this.baseValue, this.value, isEqual));
  }

  getFieldLevelErrorRate(): { [key: string]: number } {
    if (!isArray(this.baseValue)) {
      return isEqual(this.baseValue, this.value) ? { errorRate: 0 } : { errorRate: 100 };
    }
    const totalItems = max([this.baseValue.length, this.value.length]);
    if (!totalItems) {
      return {
        errorRate: 0,
      };
    }
    if (this.fieldName === 'affiliations') {
      const fieldsToMatch = ['id', 'primary', 'lastKnown', 'inactive'];

      const baseValue = this.baseValue.map((item) => pick(item, fieldsToMatch));
      const currentValue = this.value.map((item) => pick(item, fieldsToMatch));
      const affiliationMatches = intersectionWith(baseValue, currentValue, isEqual).length;
      const affiliationErrorRate = this.getErrorRate(totalItems - affiliationMatches, totalItems);

      const positionBaseValue = this.baseValue.map((item) => pick(item, ['id', 'position', 'originalPosition']));
      const positionCurrentValue = this.value.map((item) => pick(item, ['id', 'position', 'originalPosition']));
      const positionMatches = intersectionWith(positionBaseValue, positionCurrentValue, isEqual).length;
      const positionErrorRate = this.getErrorRate(totalItems - positionMatches, totalItems);

      return {
        errorRate: affiliationErrorRate,
        positionErrorRate,
      };
    }
    const matches = intersection(this.baseValue, this.value).length;
    return {
      errorRate: this.getErrorRate(totalItems - matches, totalItems),
    };
  }

  markAsCorrect(path?: string) {
    this.trainingEvaluationMarkerWrapper.updateReport(path || this.fieldName, undefined, true);
    this.setReport();
    this.popover.close();
  }

  markAsError(path?: string) {
    this.trainingEvaluationMarkerWrapper.updateReport(path || this.fieldName, undefined, false);
    this.setReport();
    this.popover.close();
  }

  hasTrainerRole(): boolean {
    return this.svcAcl.hasCredential('profile.training');
  }

  openPopOver() {
    if (this.hasTrainerRole()) {
      this.popover.open();
    }
  }

  isArray(obj: any) {
    return Array.isArray(obj);
  }

  getErrorRate(error, total): number {
    return Number(((error / total) * 100).toFixed(2));
  }

  getValueTitle(values: Value[], code: string) {
    return (values.find((o) => o.code === code) || { title: '' }).title;
  }

  getDisplayValue(value: any) {
    const valueTransformersByFieldName = {
      specialties: this.getValueTitle.bind(null, this.specialtyList),
    };
    return (
      (valueTransformersByFieldName[this.fieldName] && valueTransformersByFieldName[this.fieldName](value)) || value
    );
  }

  toggleMarkAsCorrect(event, path?: string) {
    const correct = event.target.checked;
    if (correct) {
      this.markAsCorrect(path);
    } else {
      this.markAsError(path);
    }
  }

  markedAsCorrect() {
    if (!this.report) {
      return false;
    }
    if (this.fieldName === 'affiliations') {
      return (
        (this.report.isCorrect || this.report.markAsCorrect) &&
        (this.report.positions?.isCorrect || this.report.positions?.markAsCorrect)
      );
    }
    return this.report.markAsCorrect;
  }

  isCorrect() {
    if (!this.report) {
      return false;
    }
    if (this.fieldName === 'affiliations') {
      return this.report.isCorrect && this.report.positions?.isCorrect;
    }
    return this.report.isCorrect;
  }
}
