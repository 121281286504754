import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  GET,
  PUT,
  POST,
  DELETE,
  Path,
  Body,
  Query,
  MediaType,
  Produces,
} from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { Memo } from './memo';

@Injectable()
export class MemoAPI extends APIService {
  @GET('memos/')
  @Produces(MediaType.JSON)
  public find(
    @Query('user') user?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string
  ): Observable<Memo[]> {
    return;
  }

  @GET('memos/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Memo> {
    return;
  }

  @GET('memos/count')
  @Produces(MediaType.JSON)
  public count(@Query('user') user?: string): Observable<any> {
    return;
  }

  @POST('memos')
  @Produces(MediaType.JSON)
  public create(@Body memo: Memo): Observable<any> {
    return;
  }

  @PUT('memos/{id}')
  @Produces(MediaType.JSON)
  public upsert(@Path('id') id: string, @Body memo: Memo): Observable<any> {
    return;
  }

  @DELETE('memos/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }

  public startBulkListUpload(file: File, secondEyes: string): Observable<string> {
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);

    return this.http
      .post(this.getBaseUrl() + 'memos/bulk-list', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj: any) => obj.bulkTrackingId));
  }
}
