import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl } from '@angular/forms';

const DOMAIN_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => DomainValidator),
  multi: true,
};

@Directive({
  selector: '[ngModel][validateDomain]',
  providers: [DOMAIN_VALIDATOR],
})
export class DomainValidator implements Validator {
  allowedDomain: string = '';

  @Input()
  set validateDomain(domain: string) {
    this.allowedDomain = domain;
  }

  validate(control: AbstractControl): { [key: string]: any } {
    // return CustomValidators.url(c);
    if (this.isPresent(Validators.required(control))) {
      return;
    }

    const v: string = control.value;
    return this.validateUrlDomain(v);
  }

  public validateUrlDomain(v: string) {
    const parts = this.allowedDomain.split('.');
    const regExp = new RegExp(`^(?:https?:\\/\\/)?(?:[^.]+\\.)?${parts[0]}\\.${parts[1]}(\\/.*)?$`);
    const matchArray = v.match(regExp);
    return matchArray && matchArray.length ? null : { domain: true };
  }

  private isPresent(obj: any) {
    return obj !== undefined && obj !== null;
  }
}
