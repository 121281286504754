<div ngbDropdown class="d-inline-block">
  <button class="btn btn-outline-primary btn-sm" ngbDropdownToggle [disabled]="!isEditable()">
    {{ profile.viewMode | capitalize }}
  </button>

  <div ngbDropdownMenu>
    <button
      class="dropdown-item"
      [ngClass]="{ active: profile.viewMode == vm.key }"
      *ngFor="let vm of viewModes | iterable"
      (click)="setViewMode(profile, vm.key)"
    >
      {{ vm.value }}
    </button>
  </div>

  <i class="fa fa-refresh fa-spin fa-fw" *ngIf="isSaving"></i>
</div>
