import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { CommentModule } from '../comment/comment.module';
import { organizationRouting } from './organization.routes';
import { OrganizationComponent } from './organization.component';
import { OrganizationListComponent } from './list/list.component';
import { OrganizationAPI } from './shared/api.service';
import { OrganizationDetailFormComponent } from './shared/form/organization-detail-form.component';
import { OrganizationDetailComponent } from './detail/detail.component';
import { OrganizationTreePartComponent } from './shared/tree/tree-part.component';
import { OrganizationLookupComponent } from './shared/form/lookup/organization-lookup.component';
import { AddressLookupComponent } from './shared/form/lookup/address-lookup.component';
import { AddressTile } from './shared/tile/address-tile';
import { CreateRootDialogComponent } from './shared/create-root-dialog/create-root-dialog.component';
import { AffiliationModule } from '../affiliation/affiliation.module';
import { OrganizationAffiliationModule } from '../organization-affiliation/organization-affiliation.module';
import { OrganizationAdvancedSearchComponent } from './shared/advanced-search/advanced-search';
import { OrganizationMaintenanceJobComponent } from './shared/organization-maintenance-job/organization-maintenance-job.component';
import { OrganizationMaintenanceJobModalService } from './shared/organization-maintenance-job/organization-maintenance-job.service';

@NgModule({
  imports: [SharedModule, CommentModule, organizationRouting, AffiliationModule, OrganizationAffiliationModule],
  declarations: [
    OrganizationComponent,
    OrganizationListComponent,
    OrganizationDetailFormComponent,
    OrganizationTreePartComponent,
    OrganizationLookupComponent,
    AddressLookupComponent,
    AddressTile,
    OrganizationDetailComponent,
    CreateRootDialogComponent,
    OrganizationAdvancedSearchComponent,
    OrganizationMaintenanceJobComponent,
  ],
  providers: [OrganizationAPI, OrganizationMaintenanceJobModalService],
  exports: [],
})
export class OrganizationModule {}
