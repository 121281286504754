<section class="dirt-section-list dirt-user-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span *ngIf="total">{{ total.count | number }}</span> Users
      </h1>
    </div>
    <div class="col-sm-4">
      <input
        type="search"
        class="form-control"
        [formControl]="searchCtrl"
        [(ngModel)]="searchTerm"
        placeholder="Search..."
      />
    </div>
    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Filter -->
        <dirt-filters [filters]="filters" (onFilter)="onFilter($event)"></dirt-filters>

        <dirt-bulk-list-button *diAcl="'user.bulkList'" [delegate]="bulkListDelegate"></dirt-bulk-list-button>

        <!-- Create Button -->
        <button class="btn btn-success btn-sm" (click)="navigateTo(['/user/create'])" *diAcl="'user.create'">
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Create User
        </button>
      </div>
    </div>
  </header>

  <!-- Export (right next to pagination, just don't waste any space) -->
  <button class="btn btn-secondary btn-sm pull-right" (click)="exportUsers()" *diAcl="'user.list'">
    <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Export All Users
  </button>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-user-list'"
    [pagingSize]="users?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Table -->
  <div *ngIf="users" [hidden]="isLoading">
    <table class="table table-sm table-hover" [hidden]="!users.length">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Roles</th>
          <th>Login Count</th>
          <th>Created At</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="dirt-user-row"
          *ngFor="
            let user of users
              | paginate
                : { id: 'dirt-user-list', itemsPerPage: pagingLimit, currentPage: pagingPage, totalItems: total?.count }
          "
        >
          <td>
            <div class="media">
              <div class="media-left media-middle">
                <img class="media-object rounded-circle mr-2" [src]="user.picture" />
              </div>
              <div class="media-body">
                <a [routerLink]="['/user/detail', user.user_id]">
                  <dirt-username [user]="user" [reverse]="true"></dirt-username>
                </a>
                <span class="badge badge-danger ml-2" *ngIf="user.blocked">Blocked</span>
              </div>
            </div>
          </td>
          <td>{{ user.email }}</td>
          <td>{{ user.app_metadata?.roles || user.roles }}</td>
          <td>{{ user.logins_count }}</td>
          <td>{{ user.created_at | date : 'medium' }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <p class="dirt-no-results" *ngIf="!isLoading && !users.length">No Users</p>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-user-list'"
    [pagingSize]="users?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="isLoading || pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
