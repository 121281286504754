import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AclGuard } from '../shared/acl/guard.service';
import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { DomainComponent } from './domain.component';
import { DomainCreateComponent } from './create/create.component';
import { DomainDetailComponent } from './detail/detail.component';
import { DomainListComponent } from './list/list.component';
import { DomainModule } from './domain.module';
import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';

const domainRoutes: Routes = [
  {
    path: 'domain',
    component: DomainComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'create',
        component: DomainCreateComponent,
      },
      {
        path: 'detail/:id',
        component: DomainDetailComponent,
      },
      {
        path: 'list',
        component: DomainListComponent,
        data: { acl: 'domain.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
    data: { title: 'Domain' },
  },
];

export const domainRouting: ModuleWithProviders<DomainModule> = RouterModule.forChild(domainRoutes);
