import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET, Query, MediaType, Produces, BaseUrl } from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { Contributor } from './contributor';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ContributorAPI extends APIService {
  @GET('contributors/')
  @Produces(MediaType.JSON)
  public find(
    @Query('searchTerm') searchTerm?: string,
    @Query('searchField') searchField?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string
  ): Observable<Contributor[]> {
    return null;
  }

  @GET('contributors/count')
  @Produces(MediaType.JSON)
  public count(@Query('searchTerm') searchTerm?: string, @Query('searchField') searchField?: string): Observable<any> {
    return null;
  }

  /**
   * Upload the file and get a bulk upload ID
   *
   * @param file
   * @param secondEyes
   */
  public startBulkListUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'contributions/bulk-list', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }
}
