import { ColDef, ColGroupDef, RowNode } from 'ag-grid';
import { Component, OnInit } from '@angular/core';
import { concatMap, from, Observable, take } from 'rxjs';
import { ValueFormatterParams } from 'ag-grid/dist/lib/entities/colDef';

import { ACL } from '../../../../shared/acl/acl.service';
import { Podcast } from '../../../../podcast/shared/podcast';
import { PodcastAPI } from '../../../../podcast/shared/podcast-api.service';
import { ProfileDirectLinkOptions } from '../../../shared/modal/direct-link/direct-link-options.interface';
import { ProfileDirectLinkService } from '../../../shared/modal/direct-link/direct-link.service';
import { ProfileGridComponent } from '../../../shared/grid/grid.component';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { ProfilePodcastAPI } from '../shared/api.service';
import { QCCommentService } from '../../../shared/grid/qc-comment/qc-comment.service';
import { ProfilePodcast } from '../../../shared/profile-podcast';

@Component({
  selector: 'dirt-profile-podcast-list',
  templateUrl: '../../../shared/grid/grid.component.html',
  styleUrls: ['../../../shared/grid/grid.component.scss'],
})
export class ProfilePodcastListComponent extends ProfileGridComponent implements OnInit {
  FIELD_MENTION_FIRST_NAME = '_profile.mention.firstName';
  FIELD_MENTION_LAST_NAME = '_profile.mention.lastName';
  FIELD_MENTION_ROLE = '_profile.mention.role';
  FIELD_MENTION_AFFILIATION = '_profile.mention.affiliation';
  FIELD_PODCAST_TITLE = '_profile.podcastOriginalTitle';
  FIELD_PODCAST_DESCRIPTION = '_profile.podcastOriginalDescription';
  FIELD_EPISODE_TITLE = '_profile.episodeOriginalTitle';
  FIELD_EXTERNAL_SOURCE = '_profile.externalIds.source';
  FIELD_PUBLICATION_DATE = '_profile.publicationDate';

  constructor(
    protected readonly svcAcl: ACL,
    protected readonly svcQCComment: QCCommentService,
    private readonly svcProfilePodcast: ProfilePodcastAPI,
    private readonly svcPodcast: PodcastAPI,
    private readonly svcDirectLink: ProfileDirectLinkService
  ) {
    super('id', svcAcl, svcQCComment);
  }

  ngOnInit(): void {
    this.addFilter(this.FIELD_MENTION_FIRST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_MENTION_FIRST_NAME, params)
    );
    this.addFilter(this.FIELD_MENTION_LAST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_MENTION_LAST_NAME, params)
    );
    this.addFilter(this.FIELD_MENTION_ROLE, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_MENTION_ROLE, params)
    );
    this.addFilter(this.FIELD_PUBLICATION_DATE, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_PUBLICATION_DATE, params)
    );
    this.addFilter(this.FIELD_KEY_FA, true, 'keep', false, (params) =>
      this.defineGetFocusArea().subscribe((result) =>
        result && result[0] && result[0].focusArea
          ? params.success(result[0].focusArea?.map((val) => val.name))
          : params.success('')
      )
    );

    super.ngOnInit();
  }

  protected defineDistinct(profileId: string, field: string, filter: any): Observable<string[]> {
    return this.svcProfilePodcast.distinct(this.profile, field, filter);
  }

  protected defineUpdate(profileId: string, ids: string[], value: ProfilePodcast[]): Observable<{ id; compiledAt? }[]> {
    return this.svcProfilePodcast.update(this.profile, value);
  }

  protected defineGetDataRows(...params): Observable<any> {
    return this.svcProfilePodcast.find.apply(this.svcProfilePodcast, [this.profile, ...params.slice(1)]); // migrating profileId to profile
  }

  protected defineGetFocusArea(): Observable<any> {
    return this.svcProfilePodcast.distinctFocusArea(this.profile);
  }

  protected defineGrid(): (ColDef | ColGroupDef)[] {
    const columnDefs: (ColDef | ColGroupDef)[] = [
      {
        headerName: 'Mention',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'First Name',
            field: this.FIELD_MENTION_FIRST_NAME,
            filterParams: this.filters.get(this.FIELD_MENTION_FIRST_NAME),
          },
          {
            headerName: 'Last Name',
            field: this.FIELD_MENTION_LAST_NAME,
            filterParams: this.filters.get(this.FIELD_MENTION_LAST_NAME),
          },
          {
            headerName: 'Role',
            field: this.FIELD_MENTION_ROLE,
            filterParams: this.filters.get(this.FIELD_MENTION_ROLE),
          },
          {
            headerName: 'Affiliation',
            field: this.FIELD_MENTION_AFFILIATION,
            filterParams: this.filters.get(this.FIELD_MENTION_AFFILIATION),
          },
        ],
      },
      {
        headerName: 'Link',
        cellRenderer: this.rendererLinks,
        field: this.FIELD_EXTERNAL_SOURCE,
        suppressMenu: true,
        width: 50,
      },
      {
        headerName: 'Podcast',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'Title',
            field: this.FIELD_PODCAST_TITLE,
            filter: 'text',
            width: 550,
            cellRenderer: this.cellRendererTooltip,
          },
          {
            headerName: 'Description',
            field: this.FIELD_PODCAST_DESCRIPTION,
            filter: 'text',
            columnGroupShow: 'open',
          },
          {
            headerName: 'Episode Title',
            field: this.FIELD_EPISODE_TITLE,
            filter: 'text',
            width: 550,
            cellRenderer: this.cellRendererTooltip,
          },
          {
            headerName: 'Date',
            field: this.FIELD_PUBLICATION_DATE,
            filterParams: this.filters.get(this.FIELD_PUBLICATION_DATE),
            width: 50,
            columnGroupShow: 'open',
          },
        ],
      },
      {
        headerName: 'Focus Areas',
        field: this.FIELD_KEY_FA,
        filterParams: this.filters.get(this.FIELD_KEY_FA),
        width: 550,
      },
    ];

    return columnDefs;
  }

  protected defineAutoSizableColumns(): string[] {
    return [this.FIELD_MENTION_FIRST_NAME, this.FIELD_MENTION_LAST_NAME, this.FIELD_EXTERNAL_SOURCE];
  }

  protected onDirectLink(): void {
    let selectedRole: string;
    const options = <ProfileDirectLinkOptions>{
      title: 'Podcast',
      positionSet: ['Author', 'Host', 'Guest'], // FIXME: To be confirmed
      setPosition: (role) => (selectedRole = role),
      getEntity: (id) => this.svcPodcast.findById(id),
      getData: (p: Podcast) => ({ name: `${p.podcastOriginalTitle} - ${p.episodeOriginalTitle}` }),
    };

    from(this.svcDirectLink.open(options))
      .pipe(
        take(1),
        concatMap((entity: Podcast) =>
          this.svcProfilePodcast.link(this.profile, entity.externalIds.source, { role: selectedRole })
        )
      )
      .subscribe(() => {
        this.reloadData();
      });
  }

  rendererLinks(param: ValueFormatterParams): string {
    if (!param?.data) {
      return;
    }
    return `<a href='${param.data._profile.podcastUrl}' target='_blank' rel='noopener noreferrer'>P</a>`;
  }

  /** @override */
  updateCommentAndProfileMatch(comment: string, matched: boolean): void {
    const nodes = this.getSelection();
    const match = matched ? ProfileMatchManual.MATCH : ProfileMatchManual.MISMATCH;

    nodes.forEach((node: RowNode) => {
      const data = Object.assign({}, node.data); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables

      data._profile.match.manual = match;

      if (comment) {
        data._profile.qc = { comment };
      }

      node.setData(data);
    });

    this.update(
      nodes,
      nodes.map(
        (n) =>
          ({
            matchId: n.data._profile.matchId,
            episodeId: n.data._profile.episodeId,
            episodePersonId: n.data._profile.episodePersonId,
            mention: n.data._profile.mention,
            match: n.data._profile.match,
            person: { id: this.profile.person.id, kolId: this.profile.person.kolId },
            profileId: this.profileId,
            qc: n.data._profile.qc,
          } as Partial<ProfilePodcast>)
      )
    ); // took the liberty of adjusting this, only changed typing, not logic
  }
}
