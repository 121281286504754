export class AddressEntity {
  // don't call it address b/c we have such a class that is treated differently
  _id;

  name?: string;

  originalName?: string;

  houseNo?: string;

  additionalInfo?: string;

  originalAdditionalInfo?: string;

  city?: string;

  originalCity?: string;

  zip?: string;

  state?: string;

  country: string; // ideally: sset along with country code

  countryCode?: string;

  originalLanguage?: string;

  latitude: number;

  longitude: number;

  formatted: string;

  addressEntryMode?: string;

  websource?: string;

  transformed?: boolean;

  createdAt: Date;
  updatedAt: Date;
  _version: number;
}
