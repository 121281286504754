import { Account } from '../../account/shared/account';

export class AccountMedicalInformation {
  id: string;
  account: Account;
  year: number;
  targetPatientPopulationCount: number;
  affiliatedHospitalsCount: number;
  affiliatedOutpatientSitesCount: number;
  bedsCount: number;
  physiciansCount: number;
  patientsInCount: number;
  patientsOutCount: number;
  patientsTotalCount: number;
  websources: string[];
  employedPhysiciansCount?: number;
  employedPhysiciansCountProofLink?: string;
  contractedPhysiciansCount?: number;
  contractedPhysiciansCountProofLink?: string;
  departmentsCount?: number;
  createdAt: Date;
  updatedAt: Date;
}
