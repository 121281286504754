import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ACL } from '../../shared/acl/acl.service';
import { Association } from '../shared/association';
import { AssociationAPI } from '../shared/association-api.service';
import { AssociationStatus } from '../shared/constant/status.enum';
import { AssociationObsolete } from '../shared/constant/obsolete.enum';
import { ProjectSetChange } from '../../shared/components/project-name/set-change';
import { Roles } from '../../shared/acl/roles';
import { ValueType } from '../../shared/enum/value-type.enum';
import { Auth } from '../../shared/services/auth/auth.service';
import { MembershipAPI } from '../../membership/shared/api.service';
import { AssociationFormComponent } from '../shared/form/form.component';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { MembershipListComponent } from '../../membership/list/list.component';
import { checkRegionRestrict, RegionRestrict } from '../../shared/values/region-restrict';
import { Title } from '@angular/platform-browser';

type SubmitAction = 'SAVE' | 'SAVE_AND_BACK' | 'SAVE_AND_NEW';

@Component({
  selector: 'dirt-association-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.scss'],
})
export class AssociationDetailComponent implements OnInit, OnDestroy {
  id: string;
  association: Association;
  statuses = AssociationStatus;
  assigneeStatuses = [AssociationStatus.COMPILATION_IN_PROGRESS];

  sub: any;
  isObjectionCommentShown = false;
  objectionStatus: string;

  isLoading = false;
  isSubmitting = false;
  isSavingStatus: boolean;
  isSavingVerified: boolean;
  isSavingIdVerified: boolean;
  isSavingNoMaintenance: boolean;
  isUpdatingVerifiedControl: boolean;

  auditLogShallowCompKeys = ['therapeuticAreas', 'areas'];

  disabledStatuses = [];

  ValueType = ValueType;

  myUserId: string;
  allDirectMembershipVerified = false;
  atleastOneIndirectMembershipVerified = false;

  isLogoLoading = true; // used in view only

  @ViewChild('frmAssociation', { static: false })
  private frmAssociation: AssociationFormComponent;

  private regionRestrict: RegionRestrict[] = [];

  @ViewChild(MembershipListComponent)
  membershipList: MembershipListComponent;

  private inScopeCountries: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public svcAcl: ACL, // used in template
    private svcAuth: Auth,
    private svcAssociation: AssociationAPI,
    private svcMembership: MembershipAPI,
    private svcValue: ValueAPI,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      window.scrollTo(0, 0);

      this.id = params['id'];
      this.getAssociation();
      this.setVerifiedEditable();
    });

    if (!this.svcAcl.hasRole(Roles.AssociationManager)) {
      this.disabledStatuses = [AssociationStatus.OPT_OUT];
    }

    this.svcAuth.getProfile().subscribe((p) => {
      this.myUserId = p.user_id;
    });

    this.svcValue.find(ValueType.RegionalRestrict, Number.MAX_SAFE_INTEGER).subscribe((values) => {
      this.regionRestrict = values;
    });

    this.svcValue.find(ValueType.InScopeCountry, Number.MAX_SAFE_INTEGER).subscribe((values) => {
      this.inScopeCountries = values.map((v) => v.code as string);
    });
  }

  getAssociation() {
    this.isLoading = true;

    this.svcAssociation.findById(this.id).subscribe(
      (resp) => {
        this.association = resp;
        this.titleService.setTitle(`cLean | Association | ${this.association.name}`);
      },
      null,
      () => (this.isLoading = false)
    );
  }

  updateAssociation(asso: Association, action: SubmitAction): void {
    this.isSubmitting = true;
    if ((!asso.remoteLogoUrl && !asso.twitterHandle) || (!asso.remoteLogoUrl && !asso.useTwitterLogo)) {
      asso.localLogoUrl = null;
      asso.localLogoUrl_nocache = null;
    }

    this.svcAssociation.upsert(this.id, asso).subscribe(() => {
      this.isSubmitting = false;
      switch (action) {
        case 'SAVE': // save and do nothing
          break;
        case 'SAVE_AND_NEW': // save and reset form
          this.frmAssociation.resetForm();
          const path: any = ['/association/create'];
          if (this.association.parent) {
            path.push({ parentId: this.association.parent.id });
          }
          this.router.navigate(path);
          break;
        default: // save and go back to previous main view
          this.goBack();
          break;
      }
    });
  }

  async onSubmit(asso: Association): Promise<void> {
    if (!this.association.parent) {
      const hasDuplicates = await this.membershipList?.hasDuplicates();

      let problems: string[] = [];
      if (hasDuplicates) {
        problems.push('Some duplicates were found in memberships.');
      }

      if (!asso.parent && asso.address?.countryCode && !this.inScopeCountries.includes(asso.address.countryCode)) {
        problems.push('Selected country is out of scope.');
      }

      let message: string;
      if (problems.length > 0) {
        message = `The following issues were found: \n - ${problems.join('\n - ')}\n\n`;
      }

      message += 'Do you really want to submit this entry?';
      message += '\nNote: By submitting, you will not be having any access to it anymore!';

      // confirm the submit action
      if (!window.confirm(message)) {
        return;
      }

      asso.published = true;

      //  NB! TypeScript enums have numeric values
      asso._meta.status = AssociationStatus[AssociationStatus.DONE];
      asso._meta.claimedUntil = null;
    }

    this.updateAssociation(asso, 'SAVE_AND_BACK');
  }

  async onSave(asso: Association, action: SubmitAction): Promise<void> {
    const hasDuplicates = await this.membershipList?.hasDuplicates();

    let problems: string[] = [];
    if (hasDuplicates) {
      problems.push('Some duplicates were found in memberships.');
    }

    if (!asso.parent && asso.address?.countryCode && !this.inScopeCountries.includes(asso.address.countryCode)) {
      problems.push('Selected country is out of scope.');
    }

    let message: string;
    if (problems.length > 0) {
      message = `The following issues were found: \n - ${problems.join('\n - ')}\n\n`;
      message += 'Do you really want to submit this entry?';

      // confirm the submit action
      if (!window.confirm(message)) {
        return;
      }
    }

    asso.published = false;
    this.updateAssociation(asso, action);
  }

  onObjection(status: string): void {
    this.objectionStatus = status;
    this.isObjectionCommentShown = true;
  }

  onObjectionSubmit(): void {
    const val = <Association>{
      published: true,
      _meta: { status: this.objectionStatus },
    };

    this.updateAssociation(val, 'SAVE_AND_BACK');
  }

  onObjectionCancel(): void {
    this.objectionStatus = null;
    this.isObjectionCommentShown = false;
  }

  onStatusChange(_meta: any): void {
    this.isSavingStatus = true;

    const association = <Association>{ _meta };

    this.svcAssociation.upsert(this.association.id, association).subscribe(
      (resp: Association) => this.getMergedResponse(this.association, resp),
      null,
      () => (this.isSavingStatus = false)
    );
  }

  onVerifiedChange(verified: boolean): void {
    this.isSavingVerified = true;

    const asso = <Association>{ verified };

    this.svcAssociation.upsert(this.association.id, asso).subscribe(
      (resp: Association) => Object.assign(this.getMergedResponse(this.association, resp), { verified }),
      null,
      () => (this.isSavingVerified = false)
    );
  }

  onIdVerifiedChange(idVerified: boolean): void {
    this.isSavingIdVerified = true;

    this.svcAssociation
      .upsert(this.association.id, { idVerified } as unknown as Association)
      .subscribe((resp: Association) => {
        Object.assign(this.getMergedResponse(this.association, resp), { idVerified });
        this.isSavingIdVerified = false;
      });
  }

  onNoMaintenanceChange(checked: boolean): void {
    this.isSavingNoMaintenance = true;

    const _meta = { maintenanceDisabled: checked };
    const asso = <Association>{ _meta };

    this.svcAssociation.upsert(this.association.id, asso).subscribe(
      (resp: Association) => this.getMergedResponse(this.association, resp),
      null,
      () => (this.isSavingNoMaintenance = false)
    );
  }

  onProjectSelectionChanged(event: ProjectSetChange) {
    if (!this.svcAcl.hasCredential('association.update.prop.projectNames')) {
      return;
    }

    this.svcAssociation.update(this.association.id, { projectNames: event.activeSet } as Association).subscribe(
      (resp: Association) => this.getMergedResponse(this.association, resp),
      null,
      () => event.callback()
    );
  }

  getMergedResponse(localData: Association, remoteData: Association): Association {
    localData.updatedAt = remoteData.updatedAt;
    localData._version = remoteData._version;
    localData.readyForDelivery = remoteData.readyForDelivery;
    localData._meta.maintenanceDisabled = remoteData._meta.maintenanceDisabled;
    return localData;
  }

  isObsolete(): boolean {
    return AssociationObsolete.YES === this.association.obsolete;
  }

  goBack(): void {
    const link = this.association.parent ? ['/association/detail', this.association.parent.id] : ['/association'];

    this.router.navigate(link);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  isClaimedByOtherUser(): boolean {
    return (
      this.association._meta &&
      this.association._meta.claimedUntil &&
      new Date(this.association._meta.claimedUntil) > new Date() &&
      this.association._meta.assignee &&
      this.association._meta.assignee !== this.myUserId
    );
  }

  private async setVerifiedEditable() {
    // if user cannot update the verified state, there is no point determining if the switch should be enabled or not.
    if (!this.svcAcl.hasCredential('association.update.prop.verified')) {
      return;
    }

    this.isUpdatingVerifiedControl = true;
    const directNotVerified = await this.svcMembership
      .find(this.id, 1, undefined, '-_id', undefined, 'DIRECT', { verified: false })
      .toPromise();
    this.allDirectMembershipVerified = directNotVerified.length > 0 ? false : true;

    const indirectMemberships = await this.svcMembership
      .find(this.id, 1, undefined, '-_id', undefined, 'INDIRECT')
      .toPromise();
    if (!indirectMemberships.length) {
      this.atleastOneIndirectMembershipVerified = true;
      this.isUpdatingVerifiedControl = false;
      return;
    }

    const indirectVerified = await this.svcMembership
      .find(this.id, 1, undefined, '-_id', undefined, 'INDIRECT', { verified: true })
      .toPromise();
    this.atleastOneIndirectMembershipVerified = indirectVerified.length > 0 ? true : false;
    this.isUpdatingVerifiedControl = false;
  }

  onVerifiedFlagUpdate() {
    this.setVerifiedEditable();
  }

  skipVerification(): void {
    if (!window.confirm('Are you sure?')) {
      return;
    }

    const val = <Association>{
      //
      verificationSkipped: true,
    };

    this.svcAssociation.update(this.association.id, val).subscribe(() => this.router.navigate(['/']));
  }

  isRegionCompliant(): boolean {
    const countryCode = this.association.parent?.address?.countryCode || this.association.address?.countryCode || null;
    const hasWhitelistedRole =
      this.svcAcl.hasRole(Roles.Admin) ||
      this.svcAcl.hasRole(Roles.AssociationManager) ||
      this.svcAcl.hasRole(Roles.AssociationModerator);

    if (hasWhitelistedRole) {
      return true;
    }

    return checkRegionRestrict(this.svcAcl, this.regionRestrict, countryCode);
  }
}
