import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';

import { AssociationComponent } from './association.component';
import { AssociationListComponent } from './list/list.component';
import { AssociationDetailComponent } from './detail/detail.component';
import { AssociationCreateComponent } from './create/create.component';
import { AssociationSearchComponent } from './search/search.component';

import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';
import { AssociationModule } from './association.module';

const associationRoutes: Routes = [
  {
    path: 'association',
    component: AssociationComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'create',
        component: AssociationCreateComponent,
      },
      {
        path: 'detail/:id',
        component: AssociationDetailComponent,
        canActivate: [StickyFilterGuard],
      },
      {
        path: 'list',
        component: AssociationListComponent,
        data: { acl: 'association.list' },
        canActivate: [StickyFilterGuard],
      },
      {
        path: 'search',
        component: AssociationSearchComponent,
        data: { acl: 'association.search' },
      },
    ],
    data: { title: 'Association' },
  },
];

export const associationRouting: ModuleWithProviders<AssociationModule> = RouterModule.forChild(associationRoutes);
