<section class="dirt-section-create dirt-domain-create">
  <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
    <span class="fa fa-arrow-left" aria-hidden="true"></span>
  </button>

  <h1 class="bounceInRight">Create Domain</h1>

  <br />

  <dirt-domain-form
    [domain]="domain"
    #frmDomain="frmDomain"
    (validityChange)="onFormValidityChange($event)"
  ></dirt-domain-form>

  <div class="pull-right">
    <ng-template [diAcl]="'domain.create'">
      <button
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="!isFormValid || isSubmitting"
        (click)="onSubmit(frmDomain.getValue())"
      >
        Submit
      </button>
    </ng-template>
  </div>
</section>
