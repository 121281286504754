<dirt-admin-header></dirt-admin-header>
<div class="container-fluid">
  <section class="dirt-navbar-sub">
    <nav class="navbar navbar-expand-lg navbar-light">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item" *diAcl="'user.list'">
          <a class="nav-link" routerLink="/user" routerLinkActive="is-active">Users</a>
        </li>
        <li class="nav-item" *diAcl="'userPool.list'">
          <a class="nav-link" routerLink="/user-pool" routerLinkActive="is-active">User Pools</a>
        </li>
        <li class="nav-item" *diAcl="'projectTags.list'">
          <a class="nav-link" routerLink="/project-tags" routerLinkActive="is-active">Tags</a>
        </li>
        <li class="nav-item" *diAcl="'sponsor.list'">
          <a class="nav-link" routerLink="/sponsor" routerLinkActive="is-active">Sponsors</a>
        </li>
      </ul>
    </nav>
    &nbsp;
    <router-outlet></router-outlet>
  </section>
</div>
