import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, catchError, concatMap, forkJoin, of, takeUntil, tap, firstValueFrom, lastValueFrom } from 'rxjs';

import { Job } from './../../jobs/shared/job';
import { ACL } from './../../shared/acl/acl.service';
import { Auth } from './../../shared/services/auth/auth.service';
import { MedicalInsightsProfileAPI } from '../shared/medical-insights-profile-api.service';
import { IMultiSelectOption, IMultiSelectSettings } from '../../shared/components/multiselect-dropdown/types';
import { JobsAPI } from './../../jobs/shared/api.service';
import {
  CuratedFocusArea,
  MedicalInsightsProfile,
  TaxonomyCategory,
  ViewFocusArea,
} from '../shared/medical-insights-profile';
import { Utils } from './../../common/utils';
import { Value } from '../../shared/services/value/value';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { MedicalInsightsProfileStatus } from '../shared/constant/status.enum';
import { MedicalInsightsUATProfileAPI } from '../shared/medical-insights-profile-uat-api.service';
import { MedicalInsightsProfileJob } from '../shared/constant/job.enum';

@Component({
  selector: 'medical-insights-profile-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.scss'],
})
export class MedicalInsightsProfileDetailComponent implements OnInit, OnDestroy {
  id: string;

  note: MedicalInsightsProfile;
  noteText: string;

  taxonomyCategories: Value[] = [];
  taxonomySubCategories: Value[] = [];
  taxonomyConcepts: Value[] = [];
  taxonomyModifiers: Value[] = [];

  // FAs received from tagging team for this noteID
  private taggingFocusAreas: Array<{ id: number; name: string }> = [];

  // the ones that are already curated out - comes from model
  private curatedFocusAreas: Array<CuratedFocusArea & { visible: boolean; category: string; deleted?: boolean }> = [];

  viewFocusAreas: Array<CuratedFocusArea & { visible: boolean; category: string; deleted?: boolean }> = [];

  focusAreasSelected: Array<number> = [];
  focusAreasAll: (IMultiSelectOption & { nameLower: string; visible: boolean; category: string })[] = [];
  focusAreasFiltered: (IMultiSelectOption & { nameLower: string; visible: boolean; category: string })[] = [];
  focusAreasSettings: IMultiSelectSettings = {
    buttonClasses: 'btn btn-sm btn-secondary',
    enableSearch: true,
    searchRenderLimit: 100,
    isLazyLoad: true,
    isLazyLoadWithSelected: true,
    dynamicTitleMaxItems: 30,
    showUncheckAll: true,
    selectAddedValues: true,
    showVisibleFA: true,
    showCategoryFA: true,
  };

  hasPressedNoInsightsButton = false;
  isSubmitting = false;
  hasJobForCurrentUser: boolean;
  currentJob: Job;

  statuses = MedicalInsightsProfileStatus;
  assigneeStatuses = [];
  disabledStatuses = [];
  isSavingStatus = false;

  @ViewChild('focusAreaSelectionModal')
  private focusAreaSelectionModal: TemplateRef<any>;

  private focusAreaMapping: Map<number, ViewFocusArea> = new Map();

  private destroy$: Subject<boolean> = new Subject();
  currentUser: any;
  diseaseFocusAreaName: string;

  // for auto-correction on training on stage - the ones that are already curated out in the parent node
  private correctCuratedFAs: Array<CuratedFocusArea> = [];
  private correctCuratedTaxonomies: Array<TaxonomyCategory> = [];

  missingFAsOnTrainingCuration: Array<string> = [];
  missingTaxonomiesOnTrainingCuration: Array<string> = [];
  correctedTrainingTaxonomies: Array<{ title: string; text: string }> = [];
  correctedTrainingFAs: Array<{ title: string; text: string }> = [];

  constructor(
    private route: ActivatedRoute,
    public serviceMI: MedicalInsightsProfileAPI,
    public serviceUAT: MedicalInsightsUATProfileAPI,
    private svcAcl: ACL,
    private svcAuth: Auth,
    private svcModal: NgbModal,
    private svcJob: JobsAPI,
    private svcValue: ValueAPI,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        takeUntil(this.destroy$), // we want to react to url change to fetch child details
        tap(() => {
          // Make sure we cannot submit something we shouldn't under the pretense that we are working a job
          this.currentJob = null;
          this.hasJobForCurrentUser = false;
        }),
        concatMap((params) => {
          window.scrollTo(0, 0);

          this.id = params['id'];
          return this.loadProfile();
        }),
        concatMap(() => {
          if (this.currentUser) {
            // We already know current user, don't waste time fetching again
            return of(this.currentUser);
          }

          return this.svcAuth.getProfile().pipe(tap((user) => (this.currentUser = user)));
        }),
        concatMap(() => {
          // Only get job details if user is the one working it
          if (!(this.note._meta?.jobId && this.currentUser.user_id === this.note._meta?.assignee)) {
            return of(null);
          }

          return this.svcJob.findById(this.note._meta.jobId).pipe(
            tap((job) => {
              if (job) {
                this.currentJob = job;
                this.hasJobForCurrentUser =
                  this.svcAcl.hasCredential('job.submit') &&
                  this.note._meta.jobId &&
                  this.currentUser.user_id === this.note._meta.assignee;
              }
            })
          );
        })
      )
      .subscribe((_params) => {});
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  private get isUAT() {
    return this.router.url.includes('uat');
  }

  get service(): MedicalInsightsUATProfileAPI | MedicalInsightsProfileAPI {
    if (this.isUAT) {
      return this.serviceUAT;
    }
    return this.serviceMI;
  }

  private loadProfile() {
    return this.service.findById(this.id).pipe(
      tap(async (res) => {
        if (!res) {
          throw new Error(`Could not find profile with id ${this.id}`);
        }

        this.curatedFocusAreas = res?.selectedFocusAreas || [];
        this.taggingFocusAreas = res?.taggingFocusAreas || [];

        this.note = res;

        await this.loadAllFAs();

        // First consolidate the (automated) focus areas from tagging with the existing data
        let viewFocusAreas = this.taggingFocusAreas.map((tfa) => {
          const cfa = this.curatedFocusAreas.find((fa) => fa.id === tfa.id);
          return {
            id: tfa.id,
            name: tfa.name,
            highlighted: !!cfa?.highlighted,
            manuallyAdded: !!cfa?.manuallyAdded, // It was already here before
            curatedAt: cfa?.curatedAt,
            visible: this.focusAreaMapping.get(tfa.id)?.visible,
            category: this.focusAreaMapping.get(tfa.id)?.category,
          };
        });

        // Get the fully manual focus areas (including previously (highlighted) automated FAs that are no longer present in Tagging mapping)
        const manualFocusAreas = this.curatedFocusAreas
          .filter((fa) => !viewFocusAreas.find((vfa) => vfa.id === fa.id) && fa.highlighted)
          .map((fa) => {
            // to handle the case where tagging deleted some already curated FAs - KS-5205
            if (!fa.manuallyAdded) {
              fa.deleted = true;
            }
            fa.name = this.focusAreaMapping.get(fa.id)?.name || fa.name;
            fa.manuallyAdded = true;
            fa.highlighted = true; // automatically the case
            fa.visible = this.focusAreaMapping.get(fa.id)?.visible;
            fa.category = this.focusAreaMapping.get(fa.id)?.category;
            return fa;
          });

        // all FAs - manual + auto (highlighted and not)
        this.viewFocusAreas = viewFocusAreas.concat(manualFocusAreas).sort(this.sortHighlighted);

        if (!Array.isArray(this.note.selectedTaxonomyInsights) || this.note.selectedTaxonomyInsights.length === 0) {
          this.note.selectedTaxonomyInsights = [];
          this.onAddRow();
        }

        if (this.note.disease_focus_area_id) {
          const diseaseFAid = parseInt(this.note.disease_focus_area_id);
          this.diseaseFocusAreaName = this.focusAreaMapping.get(diseaseFAid)?.name;
        }

        this.loadNoteFromAutoloading();
        this.loadTaxonomies();

        if (
          this.note.training &&
          this.note._meta.status === MedicalInsightsProfileStatus.DONE &&
          this.svcAcl.hasRole('MED_INS_TRAINING_MANAGER') &&
          !this.isUAT
        ) {
          await this.setTrainingCorrections();
        }
      })
    );
  }

  private async setTrainingCorrections(): Promise<void> {
    // Training is not relevant for UAT
    if (this.isUAT) {
      return;
    }

    // load the correctSelectedFAs and the correctTaxonomies from the original curated note (from where training notes were copied)
    const correctValues = await lastValueFrom(this.serviceMI.getTrainingParentNodeInput(this.note.veeva_object_id));
    if (!correctValues) {
      return;
    }

    this.correctCuratedFAs = correctValues.correctSelectedFAs;
    this.correctCuratedTaxonomies = correctValues.correctSelectedTaxonomies;

    // find the FAs the training curator hasn't selected - display them as 'missing' on the UI
    const selectedFocusAreasIds = new Set(this.note.selectedFocusAreas?.map((i) => i.id));
    this.missingFAsOnTrainingCuration = this.correctCuratedFAs
      ?.filter((correctFA) => !selectedFocusAreasIds.has(correctFA.id))
      .map((missingFA) => missingFA.name);

    // find the Taxonomies (whole combo) the training curator hasn't selected - display them as 'missing' on the UI
    this.missingTaxonomiesOnTrainingCuration = this.correctCuratedTaxonomies
      ?.filter(
        (obj1) =>
          // Check if any object in the second list matches the object from the first list
          !this.note.selectedTaxonomyInsights?.some(
            (obj2) =>
              // Compare each property of the objects for equality
              // obj1.category === obj2.category &&
              // obj1.subcategory === obj2.subcategory &&
              // obj1.modifier === obj2.modifier &&
              obj1.concept === obj2.concept
          )
      )
      .map(
        (missingTaxonomy) =>
          //`${Utils.capitalize(missingTaxonomy.category || 'no category')} - \
          // ${Utils.capitalize(missingTaxonomy.subcategory || 'no subcategory')} - \
          `${Utils.capitalize(missingTaxonomy.concept || 'no concept')}`
        // - /${Utils.capitalize(missingTaxonomy.modifier || 'no modifier')}`
      );

    // load corrected Taxonomies to UI
    this.correctedTrainingTaxonomies = this.autoCorrectTrainingTaxonomy();

    // load corrected FAs to UI
    this.correctedTrainingFAs = this.autoCorrectTrainingFocusArea();
  }

  private async loadAllFAs() {
    try {
      const data = await firstValueFrom(this.service.getOurFocusAreas());

      data?.forEach((d) => {
        // load all FAs to focusAreaMapping
        this.focusAreaMapping.set(Number(d.id), {
          id: Number(d.id),
          name: d.name,
          visible: d.visible,
          category: d.category,
        });

        // disable for selection the FAs that are already provided by tagging
        const disabled = !!this.taggingFocusAreas.find((tfa) => tfa.id === Number(d.id));
        this.focusAreasAll.push({
          id: d.id,
          name: d.name,
          nameLower: d.name.toLowerCase(),
          visible: d.visible,
          category: d.category,
          disabled,
        });
      });
    } catch (error) {
      console.error('Error fetching FAs:', error);
    }
  }

  private loadTaxonomies() {
    // grab all the taxonomy data per dropdown
    forkJoin({
      taxonomyCategories: this.svcValue.find(ValueType.MedicalInsightTaxonomyCat, Number.MAX_SAFE_INTEGER, 0, '+title'),
      taxonomySubCategories: this.svcValue.find(
        ValueType.MedicalInsightTaxonomySubCat,
        Number.MAX_SAFE_INTEGER,
        0,
        '+title'
      ),
      taxonomyConcepts: this.svcValue.find(
        ValueType.MedicalInsightTaxonomyConcept,
        Number.MAX_SAFE_INTEGER,
        0,
        '+title'
      ),
      taxonomyModifiers: this.svcValue.find(
        ValueType.MedicalInsightTaxonomyModifier,
        Number.MAX_SAFE_INTEGER,
        0,
        '+title'
      ),
    }).subscribe((data) => {
      this.taxonomyCategories = data.taxonomyCategories || [];
      this.taxonomySubCategories = data.taxonomySubCategories || [];
      this.taxonomyConcepts = data.taxonomyConcepts || [];
      this.taxonomyModifiers = data.taxonomyModifiers || [];
    });
  }

  private async loadNoteFromAutoloading() {
    const autoLoadingNote = await this.service.getNote(this.note.veeva_object_id);

    this.noteText = autoLoadingNote?.text || '';
    this.note.disease_focus_area_id = autoLoadingNote?.disease_focus_area_id || '';
    this.note.company_id = autoLoadingNote?.company_id || '';
    this.note.provider_id = autoLoadingNote?.provider_object_id || '';
  }

  onAddRow() {
    this.note.selectedTaxonomyInsights?.push({
      category: null,
      subcategory: null,
      concept: null,
      modifier: null,
    });
  }

  onRemoveRow(index: number) {
    this.note.selectedTaxonomyInsights?.splice(index, 1);

    // in case we delete the only row - add a new empty row
    if (this.note.selectedTaxonomyInsights?.length === 0) {
      this.onAddRow();
    }
  }

  trackById(focusArea: CuratedFocusArea): number {
    return focusArea.id;
  }

  onOpenModal(): void {
    this.focusAreasSelected = this.viewFocusAreas.filter((fa) => fa.manuallyAdded).map((fa) => fa.id); // Only what's currently selected
    this.onFilterFocusAreas({ filter: '' });

    this.svcModal.open(this.focusAreaSelectionModal, { size: 'lg' });
  }

  onSelectFocusArea(focusAreaId: number): void {
    const hasFocusArea = this.viewFocusAreas.find((fa) => fa.id === focusAreaId);
    if (hasFocusArea) {
      // Can't add the same FA multiple time
      return;
    }

    const fa = this.focusAreaMapping.get(focusAreaId);
    if (!fa) {
      return;
    }

    this.viewFocusAreas.push({
      id: focusAreaId,
      name: fa.name,
      manuallyAdded: true,
      highlighted: true,
      curatedAt: null,
      visible: fa.visible,
      category: fa.category,
    });
  }

  onDeleteFocusArea(focusAreaId: number): void {
    const index = this.viewFocusAreas.findIndex((fa) => fa.id === focusAreaId);
    if (index >= 0) {
      this.viewFocusAreas.splice(index, 1);
    }
  }

  onFilterFocusAreas({ filter }) {
    const focusAreasSelected = this.focusAreasSelected.map((id) => ({
      id: id,
      name: this.focusAreaMapping.get(id)?.name,
      nameLower: this.focusAreaMapping.get(id)?.name.toLowerCase(),
      visible: this.focusAreaMapping.get(id)?.visible,
      category: this.focusAreaMapping.get(id)?.category,
    }));
    if (!(filter?.length >= 3)) {
      this.focusAreasFiltered = focusAreasSelected;
    } else {
      // populate as options the loaded FAs
      const filterLower = filter.toLowerCase();
      this.focusAreasFiltered = [
        ...focusAreasSelected,
        ...this.focusAreasAll.filter((s) => s.nameLower?.indexOf(filterLower) >= 0).slice(0, 100),
      ];
    }
  }

  private sortHighlighted(a: CuratedFocusArea, b: CuratedFocusArea): number {
    const manuallyAddedA = a.manuallyAdded || false;
    const manuallyAddedB = b.manuallyAdded || false;

    const highlightedA = a.highlighted ? 1 : 0;
    const highlightedB = b.highlighted ? 1 : 0;

    // Sort by "manuallyAdded" property (false first)
    if (manuallyAddedA !== manuallyAddedB) {
      return manuallyAddedA ? 1 : -1;
    }

    // If "manuallyAdded" is the same, sort by "highlighted" property (true first)
    return highlightedB - highlightedA;
  }

  get hasSelectedTaxonomies() {
    if (!this.note.selectedTaxonomyInsights) {
      return false;
    }

    return (
      this.note.selectedTaxonomyInsights.length > 0 &&
      this.note.selectedTaxonomyInsights.some((insight) => this.isInsightValid(insight))
    );
  }

  markNoInsights(): void {
    this.note.hasNoProperInsights = true;
    this.hasPressedNoInsightsButton = true;
  }

  isInsightValid(insight: TaxonomyCategory): boolean {
    return insight.concept?.length > 0;

    // Everything else is out for now but might come back in a few weeks
    // return (
    //   insight.category?.length > 0 && insight.subcategory?.length > 0 && insight.concept?.length > 0
    //   // modifier is optional by purpose!
    // );
  }

  saveQCIntoNote() {
    if (this.currentJob?.type === MedicalInsightsProfileJob.MEDICAL_INSIGHTS_PROFILE_CURATION_QC) {
      this.note.qc = {};

      const qcConcepts = this.note.selectedTaxonomyInsights
        .filter((c) => c.qc) // Filter out any entries without a qc
        .map((cc, i) => ({ [`CONCEPT${i}_${cc.concept}`]: cc.qc })) // Map to an array of key-value pairs with keys the name of the concept (not id)
        .reduce((acc, curr) => Object.assign(acc, curr), {});

      const qcFAs = this.note.selectedFocusAreas
        .filter((c) => c.qc) // Filter out any entries without a qc
        .map((cc, i) => ({ [`FA${i}_${cc.id}`]: cc.qc })) // Map to an array of key-value pairs with keys the name of the FA (not id)
        .reduce((acc, curr) => Object.assign(acc, curr), {});

      this.note.qc = { ...qcConcepts, ...qcFAs };
      this.note.qc[this.note.id] = {};
    }
  }

  onSaveNote() {
    this.isSubmitting = true;

    // Reset toggle since we have data now
    if (this.note.selectedTaxonomyInsights?.some((insight) => this.isInsightValid(insight))) {
      this.note.hasNoProperInsights = false;
    }

    this.note.selectedFocusAreas = this.viewFocusAreas;

    this.saveQCIntoNote();

    this.note.selectedTaxonomyInsights = this.prepareSelectedTaxonomyInsights();

    this.service
      .save(this.id, this.note)
      .pipe(
        tap(() => (this.isSubmitting = false)),
        catchError((err) => {
          this.isSubmitting = false;
          throw err;
        })
      )
      .subscribe(() => {});
  }

  onSubmitJob(): void {
    if (!this.hasJobForCurrentUser) {
      return;
    }

    this.isSubmitting = true;

    // Reset toggle since we have data now
    if (this.note.selectedTaxonomyInsights?.some((insight) => this.isInsightValid(insight))) {
      this.note.hasNoProperInsights = false;
    }

    this.note.selectedFocusAreas = this.viewFocusAreas;

    this.saveQCIntoNote();

    this.note.selectedTaxonomyInsights = this.prepareSelectedTaxonomyInsights();

    this.service
      .submitJob(this.note)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  async onJobDraft(): Promise<void> {
    if (!this.hasJobForCurrentUser) {
      return;
    }

    this.isSubmitting = true;

    // Reset toggle since we have data now
    if (this.note.selectedTaxonomyInsights?.some((insight) => this.isInsightValid(insight))) {
      this.note.hasNoProperInsights = false;
    }

    this.note.selectedFocusAreas = this.viewFocusAreas;

    this.saveQCIntoNote();
    this.note.selectedTaxonomyInsights = this.prepareSelectedTaxonomyInsights();

    this.service
      .save(this.id, this.note)
      .pipe(
        concatMap(() => this.svcJob.setDraft(this.currentJob._id)),
        tap(() => (this.isSubmitting = false))
      )
      .subscribe(() => {
        this.goBack();
      });
  }

  onJobUtc(): void {
    if (!this.hasJobForCurrentUser) {
      return;
    }

    let comment = '';
    while (comment.length < 3) {
      comment = prompt('Enter a comment for Unable to Compile...');
      if (null === comment) {
        return;
      }
    }

    this.isSubmitting = true;

    this.svcJob
      .setUtc(this.currentJob._id, comment)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  goBack(): void {
    this.router.navigate(['/next']);
  }

  trackByIndex(index: number): number {
    return index;
  }

  // getSubCategoriesForCategory(category?: string): Value[] {
  //   if (!category) {
  //     return [];
  //   }

  //   return this.taxonomySubCategories.filter((v) => v.showForType === category);
  // }

  // Leave commented out for now! It might just come back in a few weeks
  // getModifiersForConcept(concept?: string): Value[] {
  //   if (!concept) {
  //     return [];
  //   }

  //   const conceptValue = this.taxonomyConcepts.find((v) => v.code === concept);
  //   if (!conceptValue) {
  //     return [];
  //   }

  //   return this.taxonomyModifiers.filter((v) => conceptValue.modifiers.includes(v.code as string));
  // }

  private prepareSelectedTaxonomyInsights() {
    if (!this.note.selectedTaxonomyInsights) {
      return [];
    }

    return (
      this.note.selectedTaxonomyInsights
        // discard incomplete rows
        .filter((insight) => this.isInsightValid(insight))
        // Empty string since it would otherwise break Provisioning - only concept shall be set
        .map((insight) => ({
          category: '',
          subcategory: '',
          concept: insight.concept,
          modifier: '',
        }))
    );
  }

  autoCorrectTrainingFocusArea(): Array<{ title: string; text: string }> {
    return this.viewFocusAreas.map((viewFA) => {
      const correctItem = this.correctCuratedFAs?.find((val) => val.id === viewFA.id);

      if (!correctItem) {
        return { title: 'incorrect', text: 'This is incorrectly inserted based on previous curation.' };
      } else if (viewFA.manuallyAdded && correctItem.manuallyAdded) {
        return { title: 'correct', text: 'This is correctly inserted based on previous curation.' };
      } else if (!viewFA.manuallyAdded) {
        // came from tagging
        if (viewFA.highlighted && !correctItem.highlighted) {
          return { title: 'incorrect', text: 'This is incorrectly highlighted based on previous curation.' };
        } else if (!viewFA.highlighted && correctItem.highlighted) {
          return { title: 'missing', text: 'This should be highlighted based on previous curation.' };
        }
        return { title: 'correct', text: 'This is correctly highlighted based on previous curation.' };
      }
    });
  }

  autoCorrectTrainingTaxonomy(): Array<{ title: string; text: string }> {
    return this.note.selectedTaxonomyInsights?.map((taxonomy) => {
      const foundTaxonomy = this.correctCuratedTaxonomies?.some(
        (correctTaxonomy) =>
          // Compare each property of the objects for equality
          // taxonomy.category === correctTaxonomy.category &&
          // taxonomy.subcategory === correctTaxonomy.subcategory &&
          // taxonomy.modifier === correctTaxonomy.modifier &&
          taxonomy.concept === correctTaxonomy.concept
      );

      if (!foundTaxonomy) {
        return { title: 'incorrect', text: 'This is incorrect based on previous curation.' };
      }

      return { title: 'correct', text: 'This is correct based on previous curation.' };
    });
  }
}
