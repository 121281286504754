<form #form="ngForm" class="form-group">
  <!-- URL to the entity-->
  <dirt-job-entity-url [model]="model" [showLabel]="true"></dirt-job-entity-url>

  <!-- Type -->
  <div class="form-group row">
    <label class="form-control-label col-sm-4 col-md-2">Type</label>
    <div class="col-sm-8 col-md-10">
      <ng-container *ngIf="!model._id; else readOnly">
        <select
          class="form-control custom-select"
          name="jobType"
          [(ngModel)]="model.type"
          (ngModelChange)="onJobTypeChange()"
          required
          [disabled]="model._id"
        >
          <option *ngFor="let type of jobTypes" [value]="type.key">
            {{ type.value }}
          </option>
        </select>
      </ng-container>

      <ng-template #readOnly>
        {{ model.type }}
      </ng-template>
    </div>
  </div>

  <!-- Pool -->
  <div class="form-group row">
    <label class="form-control-label col-sm-4 col-md-2">Pool</label>
    <div class="input-group col-sm-8 col-md-10">
      <input
        type="text"
        class="form-control ng-trim-ignore"
        name="pool"
        placeholder="Search pools..."
        [(ngModel)]="model.poolId"
        [ngbTypeahead]="onSearchPool"
        [resultTemplate]="poolItemTemplate"
        [inputFormatter]="formatName"
        (selectItem)="onSelectPool($event)"
        [editable]="false"
        required
      />

      <div class="input-group-append">
        <div class="input-group-text">
          <i class="fa fa-search" aria-hidden="true" [hidden]="isLoadingPool"></i>
          <i class="fa fa-refresh fa-spin" [hidden]="!isLoadingPool"></i>
        </div>
      </div>
    </div>

    <div class="col-sm-8 col-md-10 offset-sm-4 offset-md-2 error" *ngIf="hasInvalidSelectedPoolError">
      Selected pool has no user able to work this kind of job
    </div>
  </div>

  <!-- Assignee -->
  <div class="form-group row">
    <label class="form-control-label col-sm-4 col-md-2">Assignee</label>
    <div class="col-sm-8 col-md-10">
      <select
        class="form-control custom-select"
        name="assignee"
        [(ngModel)]="model._meta.assignee"
        [disabled]="!model.type || !model.poolId || isLoadingPool"
      >
        <option [value]="undefined">None</option>
        <option *ngFor="let user of availableUsers" [value]="user.user_id">
          {{ user.user_metadata?.firstName }} {{ user.user_metadata?.lastName }}
        </option>
      </select>
    </div>
  </div>

  <!-- Priority -->
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Priority</label>
    <div class="col-sm-8 col-md-10">
      <ngb-rating name="priority" [(rate)]="model.priority" max="5">
        <ng-template let-fill="fill" let-index="index">
          <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
        </ng-template>
      </ngb-rating>
    </div>
  </div>

  <!-- Hint -->
  <div class="form-group row">
    <label class="form-control-label col-sm-4 col-md-2">Hint</label>
    <div class="col-sm-8 col-md-10">
      <input type="text" class="form-control" name="hint" [(ngModel)]="model.hint" />
    </div>
  </div>

  <!-- Review percentage (event QC only) -->
  <div class="form-group row" *ngIf="model.entityType === 'events' && model.type?.endsWith('_QC')">
    <label class="form-control-label col-sm-4 col-md-2">Review percentage</label>
    <div class="col-sm-2">
      <input
        type="number"
        min="1"
        max="100"
        class="form-control"
        name="reviewPercentage"
        [(ngModel)]="model.additionalData.reviewPercentage"
      />
    </div>
  </div>
</form>

<!-- Typeahead Pool Item Template -->
<ng-template #poolItemTemplate let-pool="result" let-t="term">
  <span>{{ pool.name }}</span>
</ng-template>
