import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BulkListButtonComponent } from './components/bulk-list-button/bulk-list-button.component';
import { BulkModule } from '../bulk/bulk.module';

@NgModule({
  declarations: [BulkListButtonComponent],
  imports: [CommonModule, BulkModule],
  exports: [BulkListButtonComponent],
})
export class BulkListModule {}
