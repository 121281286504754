import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, delay, take, concatMap, tap } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';

import { Affiliation } from '../shared/affiliation';
import { AffiliationAPI } from '../shared/api.service';
import { AffiliationMergeService } from '../shared/modal/merge/merge.service';
import { ACL } from '../../shared/acl/acl.service';
import { Auth } from '../../shared/services/auth/auth.service';
import { Roles } from '../../shared/acl/roles';
import { AFFILIATION_RECENT_MERGE_ID_KEY, AFFILIATION_CREATE_JOB_TYPE } from '../shared/affiliation.constants';
import { Job } from '../../jobs/shared/job';
import { JobsAPI } from '../../jobs/shared/api.service';
import { AffiliationMaintenanceJobModalService } from '../shared/modal/affiliation-maintenance-job/affiliation-maintenance-job.service';
import { AffiliationStatus } from '../shared/constant/status.enum';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'dirt-affiliation-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.scss'],
})
export class AffiliationDetailComponent implements OnInit {
  id: string;
  affiliation: Affiliation;
  affiliationJob: Job;

  oldSavedAddress: {
    state?: string;
    name?: string; // aka street main
    zip?: string;
  };

  isSaving: boolean;
  isUpdating: boolean;
  isDeleting: boolean;
  isSavingVerified: boolean;
  isSavingDuplicateSuspect = false;
  originalAddress: { originalCity?: string; originalStreet?: string; originalAdditionalInfo?: string };

  isClaimedByOtherUser: boolean;

  isSubmitting: boolean;

  isAffiliationMaintainerOrMerger: boolean;

  private myUserId: string;

  duplicateId: string | null = null;
  duplicateAffiliation: Affiliation | null = null;

  protected wndw: Window = window; // allow for testing

  statuses = AffiliationStatus;

  assigneeStatuses = [];

  disabledStatuses = [AffiliationStatus.PENDING_REMOVAL];

  isSavingStatus = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly svcAffiliation: AffiliationAPI,
    private svcAcl: ACL,
    private svcMergeModal: AffiliationMergeService,
    private svcAuth: Auth,
    private svcJob: JobsAPI,
    private svcMaintenanceModal: AffiliationMaintenanceJobModalService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.initAffiliation();

    this.isAffiliationMaintainerOrMerger =
      this.svcAcl.hasRole(Roles.AffiliationMaintainer) || this.svcAcl.hasRole(Roles.AffiliationMerger);
  }

  private initAffiliation() {
    this.route.params
      .pipe(
        take(1),
        map((params) => (this.id = params['id'])),
        switchMap((id) => this.svcAffiliation.findById(id))
      )
      .subscribe((affiliation) => {
        this.affiliation = affiliation;
        this.oldSavedAddress = affiliation?.address && JSON.parse(JSON.stringify(affiliation.address)); // deep copy so we can compare later
        this.titleService.setTitle(
          `cLean | Organization | ${[this.affiliation.name, this.affiliation.department]
            .filter((p) => !!p?.trim())
            .join(' - ')}`
        );
        this.affiliation = this.getOriginalAddress(this.affiliation);

        this.svcAuth.getProfile().subscribe((p) => {
          this.myUserId = p.user_id;

          this.isClaimedByOtherUser =
            this.affiliation._meta &&
            this.affiliation._meta.claimedUntil &&
            new Date(this.affiliation._meta.claimedUntil) > new Date() &&
            this.affiliation._meta.assignee &&
            this.affiliation._meta.assignee !== this.myUserId;

          // Only get job details if user is the one working it
          if (affiliation._meta?.jobId && affiliation._meta?.assignee === this.myUserId) {
            this.svcJob
              .findById(affiliation._meta.jobId)
              .pipe(take(1))
              .subscribe((j) => {
                this.affiliationJob = j;
              });
          }
        });
      });
  }

  onSave(affiliation: Affiliation): void {
    this.resetFrozenGeo(affiliation);
    this.isSaving = true;

    this.svcAffiliation
      .checkExactDuplicate(affiliation)
      .pipe(
        concatMap((res) => {
          this.duplicateId = res.duplicateId;
          if (this.duplicateId) {
            // We'll only display the duplicate
            return of(null);
          }
          this.duplicateAffiliation = null;

          return this.svcAffiliation.update(this.id, affiliation); // proceed with affiliation update
        }),
        tap(() => (this.isSaving = false))
      )
      .subscribe(() => {
        if (this.duplicateId) {
          this.svcAffiliation.findById(this.duplicateId).subscribe((res) => {
            this.duplicateAffiliation = res;
          });
          return;
        }

        this.goBack();
      });
  }

  async onMerge() {
    let title = this.affiliation.name;

    if (this.affiliation.department && this.affiliation.department.trim()) {
      title += ` - ${this.affiliation.department}`;
    }
    let dialogRes;
    try {
      dialogRes = await this.svcMergeModal.open({
        id: this.affiliation.id,
        title,
      });
    } catch (_e) {
      // cancel
      return;
    }
    const res = await this.svcAffiliation.merge(this.affiliation.id, dialogRes.id).pipe(take(1)).toPromise();
    const resPairs = (res.pairs || []).map((item) => ({
      existId: item.exist.id,
      existCaption: [item.exist.name, item.exist.department].filter((s) => !!s).join(', '),
      mergeId: item.merge.id,
      mergeCaption: [item.merge.name, item.merge.department].filter((s) => !!s).join(', '),
      merged: item.merged,
    }));
    if (!res.ok || res.msg || (res.pairs && res.pairs.length > 0)) {
      try {
        await this.svcMergeModal.openInfo({ success: res.ok, msg: res.msg, pairs: resPairs });
      } catch (_e) {} // cancel (we don't care which one it is)
    }
    if (res.ok) {
      // remember the ID of the winner - so when we have the winner open in parallel, we can get back to that tab and update
      // (if we open a window with res, we might even have several tabs where we return to - so we need to keep an array per day)
      AffiliationDetailComponent.rememberWinnerForOtherTabs(dialogRes.id);
      // (now we're good)
      const toQueue = this.hasJobForCurrentUser();
      this.goBack(toQueue);
    } // (& stay here)
  }

  public static rememberWinnerForOtherTabs(winnerId) {
    let existingIds: { date: string; ids: string[] } = JSON.parse(
      localStorage.getItem(AFFILIATION_RECENT_MERGE_ID_KEY) || '{}'
    );
    const todayStr = new Date().toISOString().substring(0, 10);
    if (!existingIds.date || existingIds.date < todayStr) {
      // reset (cound new)
      existingIds = { date: todayStr, ids: [] };
    }
    existingIds.ids.push(winnerId);
    localStorage.setItem(AFFILIATION_RECENT_MERGE_ID_KEY, JSON.stringify(existingIds));
  }

  async onDelete(): Promise<void> {
    if (this.isSubmitting || this.isUpdating || this.isDeleting) {
      return;
    }

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.isDeleting = true;

    const res = await firstValueFrom(this.svcAffiliation.deleteById(this.affiliation.id));
    if (res?.connectionCount > 0) {
      if (
        !window.confirm(
          `This entry is connected to ${res.connectionCount} resources. Delete requests will be sent for every connections. Are you sure you want to proceed?`
        )
      ) {
        this.isDeleting = false;
        return;
      }

      await firstValueFrom(this.svcAffiliation.deleteById(this.affiliation.id, true));
    }

    this.isDeleting = false;

    const toQueue = this.hasJobForCurrentUser();
    this.goBack(toQueue);
  }

  onJobUtc(): void {
    let comment = '';
    while (comment.length < 3) {
      comment = this.wndw.prompt('Enter a comment for Unable to Compile...');
      if (null === comment) {
        return;
      }
    }

    this.isSubmitting = true;

    this.svcJob.setUtc(this.affiliationJob._id, comment).subscribe({
      next: async () => {
        if (this.affiliationJob.type === 'AFFILIATION_CREATE') {
          await firstValueFrom(this.svcAffiliation.deleteById(this.affiliation.id, true));
        }

        this.isSubmitting = false; // We want that AFTER the deletion check

        this.goBack(true);
      },
      error: () => (this.isSubmitting = false),
    });
  }

  async onJobDraft(affiliation: Affiliation): Promise<void> {
    if (!this.hasJobForCurrentUser()) {
      return;
    }

    this.isSubmitting = true;

    this.svcAffiliation
      .checkExactDuplicate(affiliation)
      .pipe(
        concatMap((res) => {
          this.duplicateId = res.duplicateId;
          if (this.duplicateId) {
            // We'll only display the duplicate
            return of(null);
          }
          this.duplicateAffiliation = null;

          return this.svcAffiliation.update(this.id, affiliation); // proceed with affiliation update
        }),
        concatMap(() => {
          if (this.duplicateId) {
            this.svcAffiliation.findById(this.duplicateId).subscribe((res) => {
              this.duplicateAffiliation = res;
            });
            return of(null);
          }

          return this.svcJob.setDraft(this.affiliationJob._id);
        }),
        tap(() => (this.isSubmitting = false))
      )
      .subscribe(() => {
        if (this.duplicateId) {
          return;
        }

        this.goBack(true);
      });
  }

  canSubmitJob() {
    return this.svcAcl.hasCredential('job.submit');
  }
  hasJobForCurrentUser(): boolean {
    return this.affiliationJob && this.myUserId && this.affiliation?._meta?.assignee === this.myUserId;
  }
  getJobTypeForCurrentUser(): string | null {
    return this.hasJobForCurrentUser() ? this.affiliationJob.type : null;
  }
  isCreatingAffiliation(): boolean {
    return this.getJobTypeForCurrentUser() === AFFILIATION_CREATE_JOB_TYPE;
  }

  onCreateRequest() {
    this.svcMaintenanceModal.open(this.affiliation.id);
  }

  onSubmit(affiliation: Affiliation): void {
    // also submit jobs
    let message = 'Do you really want to submit this entry?';
    message += '\nNote: By submitting, you will not be having any access to it anymore!';

    // confirm the submit action
    if (!this.wndw.confirm(message)) {
      return;
    }

    this.resetFrozenGeo(affiliation);
    this.isSubmitting = true;

    this.svcAffiliation.checkExactDuplicate(affiliation).subscribe(
      (res) => {
        if (res.duplicateId) {
          this.isSubmitting = false;
          this.duplicateId = res.duplicateId;
          this.svcAffiliation.findById(this.duplicateId).subscribe((res) => {
            this.duplicateAffiliation = res;
          });
        } else {
          this.duplicateId = null;
          this.duplicateAffiliation = null;
          this.svcAffiliation
            .submit(this.id, affiliation)
            .pipe(delay(1000))
            .subscribe(
              () => {
                this.isSubmitting = false;
                this.goBack(true);
              },
              () => (this.isSubmitting = false)
            );
        }
      },
      () => (this.isSubmitting = false)
    );
  }

  private resetFrozenGeo(affiliation: Affiliation): void {
    if (!affiliation.address?.frozenGeo) {
      return; // nothing to do
    }
    if (
      affiliation.address.name?.toLowerCase() !== this.oldSavedAddress?.name?.toLowerCase() ||
      affiliation.address.zip?.toLowerCase() !== this.oldSavedAddress?.zip?.toLowerCase() ||
      affiliation.address.state?.toLowerCase() !== this.oldSavedAddress?.state?.toLowerCase()
    ) {
      affiliation.address.frozenGeo = false;
    }
  }

  goBack(toQueue?: boolean): void {
    this.router.navigate([toQueue ? '/next' : '/affiliation']);
  }

  onVerifiedChange(verified: boolean) {
    this.isSavingVerified = true;

    const affiliation = <Affiliation>{ verified };

    this.svcAffiliation.update(this.affiliation.id, affiliation).subscribe(
      (resp: Affiliation) =>
        Object.assign(this.getMergedResponse(this.affiliation, resp), {
          verified,
        }),
      null,
      () => (this.isSavingVerified = false)
    );
  }

  onStatusChange(_meta: any): void {
    if (!this.svcAcl.hasCredential('affiliation.update.prop.meta.status')) {
      return;
    }

    this.isSavingStatus = true;

    this.svcAffiliation
      .update(this.affiliation.id, { _meta } as any)
      .pipe(tap(() => (this.isSavingStatus = false)))
      .subscribe((affiliation) => {
        this.affiliation._meta.status = _meta.status;
        this.getMergedResponse(this.affiliation, affiliation);
      });
  }

  getMergedResponse(localData: Affiliation, remoteData: Affiliation): Affiliation {
    localData.updatedAt = remoteData.updatedAt;
    localData._version = remoteData._version;
    localData.readyForDelivery = remoteData.readyForDelivery;
    return localData;
  }

  private getOriginalAddress(affiliation: Affiliation): Affiliation {
    affiliation.originalAddr = {};
    affiliation.originalAddr.name = affiliation.address.originalStreet;
    affiliation.originalAddr.additionalInfo = affiliation.address.originalAdditionalInfo;
    affiliation.originalAddr.city = affiliation.address.originalCity;

    return affiliation;
  }

  hasPermissionInclLfka(perm: string) {
    if (this.affiliation.sourceLfka && !this.svcAcl.hasCredential('affiliation.lfka')) {
      return false;
    }
    return this.svcAcl.hasCredential(perm);
  }

  hasMergePermission() {
    if (this.hasPermissionInclLfka('affiliation.merge')) {
      return true;
    } else if (this.hasPermissionInclLfka('affiliation.mergeLimited')) {
      return !!(this.wndw.location.search?.indexOf('withMerge') >= 0 || this.duplicateId); // only when needed
    } else {
      return false;
    }
  }

  onCopyID(id?): void {
    navigator.clipboard.writeText(id || this.affiliation.id);
  }

  onDuplicateSuspectChange(duplicateSuspect: boolean) {
    this.isSavingDuplicateSuspect = true;

    const updates = <Affiliation>({ duplicateSuspect } as unknown);
    this.svcAffiliation.update(this.id, updates).subscribe(
      () => {},
      (error) => {
        alert('Error!');
      },
      () => {
        this.isSavingDuplicateSuspect = false;
      }
    );
  }

  @HostListener('document:visibilitychange')
  async onGlobalVisibility() {
    if (!this.wndw.document.hidden) {
      // coming back to that tab
      const recentMergeIds: { date: string; ids: string[] } = JSON.parse(
        this.wndw.localStorage.getItem(AFFILIATION_RECENT_MERGE_ID_KEY) || '{}'
      );

      if (recentMergeIds.ids && this.affiliation?.id && recentMergeIds.ids.indexOf(this.affiliation.id) >= 0) {
        // we did merge sth into this - so we should reload the mergedIds (and version for good measure)
        this.svcAffiliation.findById(this.affiliation.id).subscribe(
          (affilUpdate) => {
            this.affiliation.mergedAffiliationIds = affilUpdate.mergedAffiliationIds;
            this.affiliation.updatedAt = affilUpdate.updatedAt;
            this.affiliation._version = affilUpdate._version;
            recentMergeIds.ids = recentMergeIds.ids.filter((id) => id !== this.affiliation.id);
            this.wndw.localStorage.setItem(AFFILIATION_RECENT_MERGE_ID_KEY, JSON.stringify(recentMergeIds)); // don't have to do that twice
          },
          (err) => (this.isUpdating = false)
        );
      }
    }
  }

  hasDeletePermission(): boolean {
    return (
      this.hasPermissionInclLfka('affiliation.delete') &&
      !['AFFILIATION_CREATE', 'AFFILIATION_CHANGE'].includes(this.affiliationJob?.type)
    );
  }
}
