import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { delay, tap } from 'rxjs/operators';

import { Affiliation } from '../shared/affiliation';
import { AffiliationAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-affiliation-create',
  templateUrl: 'create.component.html',
  styleUrls: ['create.component.scss'],
})
export class AffiliationCreateComponent {
  affiliation = new Affiliation();

  isSubmitting = false;

  duplicates: { name; department }[] | null = null;

  constructor(private router: Router, private svcAffiliation: AffiliationAPI) {}

  onSubmit(affiliation: Affiliation, force?: boolean): void {
    this.isSubmitting = true;

    this.svcAffiliation
      .create(affiliation, !!force)
      .pipe(tap(() => (this.isSubmitting = false)))
      .pipe(delay(1000))
      .subscribe(
        (result: { res: Affiliation; potentialDups?: { name; department }[] }) => {
          if (result.potentialDups && result.potentialDups.length) {
            this.duplicates = result.potentialDups.filter((d) => !!d); // Elastic but not mongo might return null
          } else {
            this.duplicates = null; // (just for clarity - unmounting will also reset it)
            this.goBack();
          }
        },
        () => (this.isSubmitting = false)
      );
  }

  goBack(): void {
    this.router.navigate(['/affiliation']);
  }
}
