import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ProfileAPI, QCStat } from '../shared/api.service';

interface Stat {
  user_id: string;
  name: string;
  completed: number;
  total: number;
  percent: number;
  remaining: number;
  //reviewCap: number,
  underperformer: boolean;
}

@Component({
  selector: 'dirt-profile-qc-next',
  templateUrl: './qc-next.component.html',
  styleUrls: ['./qc-next.component.scss'],
})
export class ProfileQcNextComponent implements OnInit {
  queue: any;

  QCStats: { region: QCStat; personal: QCStat };

  isLoadingNext: boolean = false;

  isLoadingQCStats: boolean = true;

  constructor(private router: Router, private svcProfile: ProfileAPI) {}

  ngOnInit() {
    this.getQCStats();
  }

  getNext(): void {
    this.isLoadingNext = true;

    this.svcProfile.findQCNext().subscribe(
      (profileId) => {
        if (!profileId) {
          return alert('No profiles to be reviewed at the moment! Go and get some rest ;)');
        }

        this.router.navigate(['/profile/detail', profileId]);
      },
      (err) => alert('Error!'),
      () => (this.isLoadingNext = false)
    );
  }

  getStatusText(stat: Stat): string {
    return `${stat.completed}/${stat.total} ${stat.percent}%`;
  }

  private getQCStats(): void {
    this.svcProfile.QCStats().subscribe((stats) => {
      this.QCStats = stats;
      this.isLoadingQCStats = false;
    });
  }
}
