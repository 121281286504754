import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';
import { MedicalInsightsProfileDetailComponent } from './detail/detail.component';
import { MedicalInsightsProfileModule } from './medical-insights-profile.module';
import { MedicalInsightsProfileComponent } from './medical-insights-profile.component';

const medicalInsightsProfile: Routes = [
  {
    path: 'medical-insights-profile',
    component: MedicalInsightsProfileComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: 'detail/:id',
        component: MedicalInsightsProfileDetailComponent,
        data: { acl: 'medical-insights-profile.detail', title: 'Note' },
      },
    ],
  },
  {
    path: 'medical-insights-profile-uat',
    component: MedicalInsightsProfileComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: 'detail/:id',
        component: MedicalInsightsProfileDetailComponent,
        data: { acl: 'medical-insights-profile.detail', title: 'UAT Note' },
      },
    ],
  },
];

export const medicalInsightsProfileRouting: ModuleWithProviders<MedicalInsightsProfileModule> =
  RouterModule.forChild(medicalInsightsProfile);
