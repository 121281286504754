export enum PrimaryResearchResults {
  CONFIRMED = 'Confirmed',
  INCORRECT = 'InCorrect',
}

export enum PrimaryResearchMethods {
  PHONE = 'Phone',
  EMAIL = 'Email',
  DOCUMENT = 'Document',
}
