<button
  class="btn btn-secondary btn-sm"
  (click)="onShow()"
  [ngbPopover]="filterPopoverContent"
  [autoClose]="false"
  placement="bottom-right"
  #filterPopover="ngbPopover"
>
  <span [ngClass]="{ 'has-filter': isFilterSet() }">
    <i class="fa fa-filter" aria-hidden="true"></i>
    Filter
    <i class="fa fa-sort-down" aria-hidden="true"></i>
  </span>
</button>

<ng-template #filterPopoverContent>
  <div class="dirt-filter-content">
    <!-- Filter List -->
    <div class="card dirt-filter-list" *ngIf="!visiblePanel">
      <div class="card-header text-center">Filters</div>
      <div class="card-body" diScroller>
        <ul class="list-group">
          <li class="list-group-item justify-content-between" (click)="setPanel('status')">
            Status
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('region')">
            Regions
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('location')">
            Countries
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('projects')">
            Projects
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('products')">
            Products
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('compilers')">
            Compilers
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('tag')">
            Tags
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('qc')">
            QC <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('priority')">
            Priority <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('completeness', true)">
            Completeness <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('utcBy')">
            UTC by
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('created_at')">
            Created at
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('submitted_at')">
            Submitted at
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('discarded_at')">
            Discarded at
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
        </ul>
      </div>
      <div class="card-footer" *ngIf="filter">
        <button class="btn btn-outline-primary btn-sm pull-right" (click)="doClear()">Clear Filters</button>
      </div>
    </div>

    <!-- Filter Panel -->
    <div class="card dirt-filter-panel" [hidden]="!visiblePanel">
      <div class="card-header text-center">
        <i class="fa fa-chevron-left dirt-back" aria-hidden="true" (click)="setPanel()"></i>
        <span>Filter by {{ visiblePanel | capitalize }}</span>
      </div>

      <!-- Status -->
      <div class="card-body" [hidden]="visiblePanel !== 'status'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let status of statusList | iterable"
            [class.selected]="isStatusSelected(status.key)"
            (click)="toggleStatus(status.key)"
          >
            {{ status.value | capitalize }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Regions -->
      <div class="card-body" [hidden]="visiblePanel !== 'region'">
        <div class="text-center" [hidden]="!isLoadingRegions">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let region of regionList"
            [class.selected]="isRegionSelected(region)"
            (click)="toggleRegion(region)"
          >
            {{ region }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Locations -->
      <div class="card-body" [hidden]="visiblePanel !== 'location'">
        <div class="text-center" [hidden]="!isLoadingLocations">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <ul class="list-group" [hidden]="isLoadingLocations">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let loc of locationList"
            [class.selected]="isLocationSelected(loc)"
            (click)="toggleLocation(loc)"
          >
            {{ getCountryTitle(loc) }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Projects -->
      <div class="card-body" [hidden]="visiblePanel !== 'projects'" *ngIf="!visibleSubPanel">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let pr of projectTagList | iterable"
            [class.selected]="isProjectSelected(pr.code)"
            [class.disabled]="
              isProjectSelected('NON_AUTOMATED') ||
              isProjectSelected('AUTOMATED_PLUS_DELIVERY') ||
              isProjectSelected('AUTOMATED_NO_DELIVERY') ||
              isProjectSelected('LFKA_ONLY')
            "
            (click)="setSubPanel('projects', pr.value)"
          >
            {{ pr.value.charAt(0).toUpperCase() + pr.value.slice(1) }}
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>

          <li
            class="list-group-item justify-content-between"
            [class.selected]="isProjectSelected('NON_AUTOMATED')"
            (click)="toggleProjects('NON_AUTOMATED')"
          >
            Non Automated
          </li>

          <li
            class="list-group-item justify-content-between"
            [class.selected]="isProjectSelected('AUTOMATED_PLUS_DELIVERY')"
            (click)="toggleProjects('AUTOMATED_PLUS_DELIVERY')"
          >
            Automated + Delivery
          </li>

          <li
            class="list-group-item justify-content-between"
            [class.selected]="isProjectSelected('AUTOMATED_NO_DELIVERY')"
            (click)="toggleProjects('AUTOMATED_NO_DELIVERY')"
          >
            Automated no Delivery
          </li>

          <li
            class="list-group-item justify-content-between"
            [class.selected]="isProjectSelected('LFKA_ONLY')"
            (click)="toggleProjects('LFKA_ONLY')"
          >
            LFKA Only
          </li>
        </ul>
      </div>

      <div class="card-body" [hidden]="visibleSubPanel !== 'projects'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let pr of filterOnProjectType()"
            [class.selected]="isProjectSelected(pr.code)"
            (click)="toggleProjects(pr.code)"
          >
            {{ pr.title }} <i class="fa fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Products -->
      <div class="card-body" [hidden]="visiblePanel !== 'products'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let p of productList"
            [class.selected]="isProductSelected(p.value)"
            (click)="toggleProducts(p.value)"
          >
            {{ p.value }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Reviewers -->
      <div class="card-body" [hidden]="visiblePanel !== 'compilers'">
        <div class="text-center" [hidden]="!isLoadingCompilers">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <div>
          <input
            type="search"
            class="form-control"
            [formControl]="searchCtrl"
            [(ngModel)]="searchTerm"
            placeholder="Search..."
          />
        </div>
        <ul class="list-group" [hidden]="isLoadingCompilers">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let reviewer of compilersList"
            [class.selected]="isReviewedBySelected(reviewer.user_id)"
            (click)="toggleReviewedBy(reviewer.user_id, $event)"
          >
            <dirt-username [user]="reviewer"></dirt-username> <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Tags -->
      <div class="card-body" [hidden]="visiblePanel !== 'tag'">
        <!-- Empty Message -->
        <p class="text-center mb-0" *ngIf="!isLoadingTags && !tagList?.length">No Tags</p>
        <!-- Loading Indicator -->
        <div class="text-center" [hidden]="!isLoadingTags">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <ul class="list-group" [hidden]="isLoadingTags">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let tag of tagList"
            [class.selected]="isTagSelected(tag)"
            (click)="toggleTag(tag)"
          >
            {{ tag }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Verified -->
      <div class="card-body" [hidden]="visiblePanel !== 'qc'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let type of [true, null]"
            [class.selected]="isVerifiedSelected(type)"
            (click)="toggleVerified(type)"
          >
            <span *ngIf="type === true">Verified</span>
            <span *ngIf="type === null">Unverified</span>
            <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>
      <!-- Priority -->
      <div class="card-body" [hidden]="visiblePanel !== 'priority'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let priority of priorityList | iterable"
            [class.selected]="isPrioritySelected(priority.key)"
            (click)="togglePriority(priority.key)"
          >
            {{ priority.value }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>
      <!-- Completeness -->
      <div class="card-body" [hidden]="visiblePanel !== 'completeness'" *ngIf="!visibleSubPanel">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let compelete of completenessList | iterable"
            [class.selected]="isCompleteValueSelected(compelete.key)"
            (click)="setSubPanel('completeness', compelete.key)"
          >
            {{ compelete.value }}
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
        </ul>
      </div>
      <div class="card-body" [hidden]="visibleSubPanel !== 'completeness'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let compeleteSub of completenessSubList | iterable"
            [class.selected]="isCompleteSubValueSelected(compeleteSub.key)"
            (click)="toggleCompleteSubValue(compeleteSub.key)"
          >
            {{ compeleteSub.value }}
            <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- UTC by -->
      <div class="card-body" [hidden]="visiblePanel !== 'utcBy'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            [class.selected]="'compiler' === selectedUtcBy"
            (click)="selectedUtcBy = 'compiler' === selectedUtcBy ? undefined : 'compiler'"
          >
            Compiler
          </li>
          <li
            class="list-group-item justify-content-between"
            [class.selected]="'reviewer' === selectedUtcBy"
            (click)="selectedUtcBy = 'reviewer' === selectedUtcBy ? undefined : 'reviewer'"
          >
            Reviewer
          </li>
        </ul>
      </div>

      <!-- CreatedAt -->
      <div class="card-body" [hidden]="visiblePanel !== 'created_at'">
        <dirt-date-range
          [isOpen]="visiblePanel === 'created_at'"
          [value]="selectedCreatedAt"
          [hideNotSet]="true"
          (onChange)="setCreatedAt($event)"
          (onError)="onFilterError($event)"
        >
        </dirt-date-range>
      </div>

      <!-- SubmittedAt -->
      <div class="card-body" [hidden]="visiblePanel !== 'submitted_at'">
        <dirt-date-range
          [hideNotSet]="true"
          [isOpen]="visiblePanel === 'submitted_at'"
          [value]="selectedSubmittedAt"
          (onChange)="setSubmittedAt($event)"
          (onError)="onFilterError($event)"
        >
        </dirt-date-range>
      </div>

      <!-- DiscaredAt -->
      <div class="card-body" [hidden]="visiblePanel !== 'discarded_at'">
        <dirt-date-range
          [isOpen]="visiblePanel === 'discarded_at'"
          [hideNotSet]="true"
          [value]="selectedDiscardedAt"
          (onChange)="setDiscardedAt($event)"
          (onError)="onFilterError($event)"
        >
        </dirt-date-range>
      </div>

      <div class="card-footer" [hidden]="hasSubPanel === true">
        <button class="btn btn-outline-primary btn-sm pull-left" (click)="doApply()">Apply</button>
        <button class="btn btn-outline-secondary btn-sm pull-right" (click)="doCancel()">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>
