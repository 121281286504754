import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { OcrHelperAPI } from './shared/ocr-helper-api.service';
import { OcrBarComponent } from './shared/ocr-bar/ocr-bar.component';

@NgModule({
  imports: [SharedModule],
  declarations: [OcrBarComponent],
  exports: [OcrBarComponent],
  providers: [OcrHelperAPI],
})
export class OcrHelperModule {}
