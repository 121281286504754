<section class="di-person-affiliations-education" #sect>
  <div class="card">
    <div class="card-header" style="cursor: pointer" (click)="isMainExpanded = !isMainExpanded">
      <div class="d-inline-block">
        <i
          class="fa fa-lg"
          [ngClass]="{ 'fa-angle-right': !isMainExpanded, 'fa-angle-down': isMainExpanded }"
          aria-hidden="true"
        ></i>
        <span class="pl-1">Educational Background</span>
      </div>
    </div>

    <div class="card-body" [ngbCollapse]="!isMainExpanded">
      <div class="search-wrapper">
        <input
          class="di-search form-control ng-trim-ignore"
          type="search"
          [ngbTypeahead]="searchAffiliations"
          [resultTemplate]="searchResultTemplate"
          (selectItem)="onSelect($event)"
          [(ngModel)]="searchTerm"
          [required]="isRequired()"
          placeholder="Search Organizations"
          *ngIf="!disabled"
          aria-label="Search"
        />

        <i class="fa fa-spinner fa-pulse" *ngIf="isSearching"></i>
      </div>

      <span class="small text-muted pt-1 pl-2" [ngClass]="{ invisible: !searchedOnce || isSearching }" *ngIf="!disabled"
        >No results</span
      >

      <a
        href="javascript:void(0)"
        class="small pull-right pt-1 pr-2"
        (click)="showAffiliationModal()"
        *ngIf="!disabled"
      >
        {{ canCreateAffiliation ? 'Create Organization' : 'Request Organization' }}
      </a>

      <form #frmAffiliation="ngForm" class="no-validate" novalidate>
        <div *ngFor="let af of affiliations; trackBy: trackById; let idx = index">
          <div class="card mt-3" [diMarkerWrapper]="af">
            <div class="card-body di-aff-body" *diMarker="{ name: 'affiliationsEducation', area: currentQcArea }">
              <h5>{{ af.name }}</h5>
              <h6 class="dirt-aff-original-department" *ngIf="af.originalName">{{ af.originalName }}</h6>

              <!-- Name & Department -->
              <div class="d-flex justify-content-between align-items-baseline">
                <div class="card-title" title="Name">
                  <a
                    [routerLink]="['/affiliation/detail', af.id]"
                    rel="noopener"
                    target="_blank"
                    *diAcl="'affiliation.detail'"
                    >{{ af.department || af.name }}</a
                  >
                  <span *diAcl="'!affiliation.detail'">{{ af.department || af.name }}</span>

                  <!-- Unpolished Badge -->
                  <div class="d-inline-block ml-2" *ngIf="!af.readyForDelivery">
                    <div class="badge badge-warning" title="Not yet worked by central organizations team">
                      Unpolished
                    </div>
                  </div>

                  <!-- Remove Button -->
                  <div
                    class="d-inline-block ml-2 di-remove-btn"
                    style="cursor: pointer"
                    title="Remove"
                    *ngIf="!disabled"
                    (click)="removeAffiliation($event, idx)"
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </div>
                </div>

                <ng-template [diAcl]="'affiliation.create || affiliation.request'">
                  <a
                    href="javascript:void(0)"
                    class="small pull-right pt-1 pr-2"
                    (click)="maintenanceRequestHandler(af.id, 'LFTA')"
                    *ngIf="!!maintenanceRequestHandler && af.readyForDelivery"
                  >
                    Request Maintenance
                  </a>
                </ng-template>
              </div>

              <!-- Original Name & Department -->
              <div
                class="d-flex justify-content-between align-items-baseline ml-3 font-italic"
                *ngIf="af.originalDepartment"
              >
                <div class="card-subtitle" title="Original Department">{{ af.originalDepartment }}</div>
              </div>

              <div class="d-flex justify-content-between align-items-baseline ml-3">
                <!-- Address -->
                <div>
                  <address class="di-address" *ngIf="af.address?.countryCode">
                    <span *ngIf="af.address?.name || af.address?.street">
                      <span>{{ af.address.name || af.address.street }}</span>
                      <span>{{ af.address.additionalInfo }}</span>
                      <br />
                    </span>
                    <span *ngIf="af.address?.city">{{ af.address.city }},</span>
                    <span *ngIf="af.address?.state">{{ af.address.state }},</span>
                    <span>{{ getCountryTitle(af.address?.countryCode) }}</span>
                    <span *ngIf="af.address?.zip">{{ af.address.zip }}</span>
                  </address>
                </div>

                <!--Original Address -->
                <div
                  *ngIf="af.address?.originalStreet || af.address?.originalAdditionalInfo || af.address?.originalCity"
                >
                  <address class="di-address" *ngIf="af.address?.countryCode">
                    <span>
                      <span>{{ af.address.originalStreet }}</span>
                      <span>{{ af.address.originalAdditionalInfo }}</span>
                      <br />
                    </span>
                    <span *ngIf="af.address?.originalCity">{{ af.address.originalCity }}</span>
                  </address>
                </div>
              </div>

              <!-- study track -->
              <div class="form-group di-edu-form-group row" style="margin-top: 30px">
                <label [for]="'studytrack_' + idx" class="col-sm-4 col-md-2">Study track</label>
                <div class="col-sm-8 col-md-5">
                  <ng-select
                    [name]="'studytrack_' + idx"
                    [items]="studyTracks"
                    [virtualScroll]="true"
                    placeholder="Select Study Track"
                    [(ngModel)]="af.studyTrack"
                    [disabled]="disabled"
                    [required]="true"
                    bindLabel="label"
                    bindValue="value"
                  >
                  </ng-select>
                </div>
              </div>

              <!-- specialties -->
              <div class="form-group di-edu-form-group row">
                <label class="col-sm-4 col-md-2">Specialties</label>
                <div class="col-sm-8 col-md-8">
                  <dirt-multiselect-dropdown
                    [name]="'specialties_' + idx"
                    [ngClass]="{ disabled: disabled }"
                    [options]="specialties"
                    [settings]="specialtiesSettings"
                    [(ngModel)]="af.specialties"
                  >
                  </dirt-multiselect-dropdown>
                </div>
              </div>

              <!-- graduation year -->
              <div class="form-group di-edu-form-group row">
                <label [for]="'graduationYear_' + idx" class="col-sm-4 col-md-2">Graduated <small>(year)</small></label>
                <div class="col-sm-8 col-md-8">
                  <input
                    type="text"
                    size="10"
                    pattern="(19|20)\d\d"
                    class="form-control form-control-sm"
                    style="width: initial"
                    [id]="'graduationYear_' + idx"
                    [name]="'graduationYear_' + idx"
                    [(ngModel)]="af.graduationYear"
                    [disabled]="disabled"
                  />
                </div>
              </div>

              <!-- proof links - allow up to 5 proofLinks -->
              <div
                class="form-group di-edu-form-group row"
                *ngFor="let proofLink of af.proofLinks; let proofIndex = index; trackBy: trackByProofIndex"
              >
                <label class="col-sm-4 col-md-2">Link {{ proofIndex + 1 }}</label>
                <div class="col-sm-8 col-md-8">
                  <dirt-link
                    [(url)]="af.proofLinks[proofIndex]"
                    name="'proofLinks-{{ idx }}-{{ proofIndex }}'"
                    [form]="ngForm"
                    [small]="true"
                    [disabled]="disabled"
                  >
                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="pushItemToList(af.proofLinks)"
                      *ngIf="proofIndex === af.proofLinks.length - 1 && shouldAddMoreLink(af.proofLinks) && !disabled"
                    >
                      <i class="fa fa-plus-circle"></i>
                    </button>

                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="removeFromByIndex(af.proofLinks, proofIndex)"
                      *ngIf="proofIndex > 0 && !disabled"
                    >
                      <i class="fa fa-minus-circle"></i>
                    </button>
                  </dirt-link>
                </div>
              </div>

              <!-- Other trainings specialties -->
              <div class="form-group di-edu-form-group row">
                <label [for]="'otherTraining_' + idx" class="col-sm-4 col-md-2">Other training specialties</label>
                <div class="col-sm-8 col-md-8">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    [id]="'otherTraining_' + idx"
                    [name]="'otherTraining_' + idx"
                    [(ngModel)]="af.otherTraining"
                    [disabled]="disabled"
                    (focusout)="af.otherTraining = af.otherTraining?.trim()"
                    *diMarker="{ name: 'otherTraining', area: currentQcArea }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <p class="text-center mt-1" *ngIf="!affiliations.length">No Educational Background so far</p>
    </div>
  </div>
</section>

<!-- SearchAutoComplete Result Template -->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <ng-container>
    <div class="di-sac-verified pull-left">
      <dirt-verified-icon color="success" *ngIf="r?.verified"></dirt-verified-icon>
    </div>

    <div class="di-sac-affiliation pull-left">
      <div class="di-sac-name">{{ r?.name }}</div>
      <div class="di-sac-department">{{ r?.department || '&mdash;' }}</div>
      <div class="di-sac-address">{{ r?.address?.formatted || '&mdash;' }}</div>
    </div>

    <div class="pull-right row">
      <div
        [ngClass]="{ 'di-sac-lfka-status pull-left': r?.sourceLfka, 'di-sac-lfta-status pull-left': !r?.sourceLfka }"
      >
        {{ r?.sourceLfka ? 'LFKA' : ('LFTA' | productLabel) }}
      </div>

      <span class="action-btn pull-right" (click)="onCopyClick($event, r)">
        <i class="fa fa-copy" aria-hidden="true"></i>
      </span>
    </div>
  </ng-container>
</ng-template>
