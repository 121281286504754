<section class="dirt-section-list dirt-event-series-event-list clearfix">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ displayedEvents?.length | number }}&nbsp;</span>
        <span>Events</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        placeholder="Search..."
        [formControl]="searchCtrl"
      />
    </div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Create Button -->
        <a
          class="btn btn-success btn-sm"
          [routerLink]="['/event/create']"
          [queryParams]="{ seriesId, currentJobType }"
          *diAcl="'event.create'"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
          <span>Create Event</span>
        </a>
      </div>
    </div>
  </header>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-event-series-event-list'"
    [pagingSize]="events?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="events?.length || 0"
    [hidden]="pagingLimit >= events?.length"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="displayedEvents?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-name" [diColSorter]="'name'">Name</th>
          <th class="col-date" [diColSorter]="'startDate'">Date</th>
          <th class="col-status" [diColSorter]="'status'">Status</th>
          <th class="col-contributions-count">
            <span ngbTooltip="Contributions Count">
              <i class="fa fa-list" aria-hidden="true"></i>
            </span>
          </th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-event-row"
          *ngFor="
            let event of displayedEvents
              | paginate
                : {
                    id: 'dirt-event-series-event-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: events.length
                  }
          "
        >
          <td class="col-name">
            <a class="dirt-event-name" [title]="event.name" [routerLink]="['/event/detail', event._id]">{{
              event.name
            }}</a>
          </td>
          <td class="col-date">{{ event.startDate | date : 'dd.MM.y' }}</td>
          <td class="col-status">{{ event._meta.status | capitalize }}</td>
          <td class="col-contributions-count">{{ event.contributionsCount }}</td>
          <td class="col-actions">
            <!-- Clone -->
            <a
              title="Clone event"
              [routerLink]="['/event/create']"
              [queryParams]="{ from: event._id, seriesId, currentJobType }"
            >
              <i class="fa fa-plus-circle" aria-hidden="true"></i>
            </a>

            &nbsp;

            <!-- Detach -->
            <span
              class="action-btn action-delete"
              title="Detach Event"
              (click)="onDetachEvent(event._id)"
              *diAcl="'eventSeries.update.prop.event'"
            >
              <i class="fa fa-remove" aria-hidden="true"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No Events</p>
    </ng-template>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
