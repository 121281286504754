import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Document } from '../shared/document';
import { DocumentPerson } from '../shared/person';
import { DocumentPersonModalComponent } from './person-modal.component';

@Injectable()
export class DocumentPersonModalService {
  constructor(private svcModal: NgbModal) {}

  open(document: Document, mode: 'ADD' | 'VIEW' | 'EDIT', person?: DocumentPerson): Promise<void> {
    const ref = this.svcModal.open(DocumentPersonModalComponent);
    ref.componentInstance.document = document;
    ref.componentInstance.mode = mode;
    ref.componentInstance.selectedDocumentPerson = person;
    return ref.result;
  }
}
