<span style="position: relative"
  >{{ params.getValue() }}
  <i
    *ngIf="list"
    class="fa fa-copy"
    style="cursor: pointer; position: absolute; right: -20px; top: 4px"
    (click)="onClick()"
    title="Copy list"
  ></i>
  <small *ngIf="isCopied" style="position: absolute; right: -20px; bottom: -25px">copied</small>
</span>
