import { Directive, OnInit, Input, Output } from '@angular/core';
import { EventEmitter, ElementRef, HostListener, Renderer2 } from '@angular/core';
import { SortConfig } from './sort-cofig';

const CLS_NAME = 'di-col-sorter';
const CLS_PREFIX = 'sort';

@Directive({
  selector: 'th[diColSorter]',
})
export class DiColumnSort implements OnInit {
  private order: string;
  private sortConfig: SortConfig;

  @Input('diColSorter') fieldName: string;
  @Output() onSort = new EventEmitter<string>();

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.addClass(this.el.nativeElement, CLS_NAME);
  }

  @HostListener('click')
  toggleSort() {
    const order = this.getSortOrder();
    this.updateSort(order);
  }

  clearSort() {
    this.updateSort(null);
  }

  updateSort(order: string) {
    this.updateClassName(this.order, order);
    this.order = order;

    // NB! don't emit it back to parent (diSorter)
    if (order) {
      const sortField =
        this.order === 'reset' ? this.sortConfig && this.sortConfig.defaultSortField : this.order + this.fieldName;
      this.onSort.emit(sortField);
    }
  }

  updateClassName(orderFrom: string, orderTo: string) {
    if (orderFrom) {
      orderFrom = `${CLS_PREFIX}-` + (orderFrom === '+' ? 'asc' : 'desc');
      this.renderer.removeClass(this.el.nativeElement, orderFrom);
    }

    if (orderTo && orderTo !== 'reset') {
      orderTo = `${CLS_PREFIX}-` + (orderTo === '+' ? 'asc' : 'desc');
      this.renderer.addClass(this.el.nativeElement, orderTo);
    }
  }

  getSortOrder() {
    if (!this.sortConfig || !this.sortConfig.resetSort) {
      return this.order === '+' ? '-' : '+';
    }
    const order = this.order === '+' ? '-' : !this.order || this.order === 'reset' ? '+' : 'reset';
    return order;
  }

  setSortConfig(config: SortConfig) {
    this.sortConfig = config;
  }

  setSortOrder(order: string) {
    this.order = order;
    this.updateClassName('', order);
  }
}
