<section class="dirt-section-list dirt-ct-ext-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>Trials</span>
      </h1>
      <select [value]="extListType" (change)="onListTypeChange($event)">
        <option value="CT-Timing">CT-Timing</option>
        <option value="CT-People">CT-People</option>
        <option value="CT-Sites">CT-Sites</option>
      </select>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        placeholder="Search by NCT"
        [formControl]="searchCtrl"
      />
    </div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Filter -->
        <dirt-filters [filters]="filters" (onFilter)="onFilter($event)"></dirt-filters>
      </div>
    </div>
  </header>

  <!-- Filter Bar -->
  <dirt-filters-bar
    [filter]="filter"
    [config]="filterBarConfig"
    (filterClear)="onFilterClear()"
    (filterValueRemove)="onFilterValueRemove($event)"
  ></dirt-filters-bar>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-ct-ext-list'"
    [pagingSize]="rows?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="rows?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-nct">NCT</th>
          <th class="col-conditions">Disease/Conditions</th>
          <th class="col-priority">Priority</th>
          <th class="col-status">Status</th>
          <th class="col-curated" [diColSorter]="'curatedAt'">Curated</th>
          <th class="col-qc" [diColSorter]="'qcAt'">QC</th>
          <th class="col-category">Therapeutic area/Category</th>
          <th class="col-created" [diColSorter]="'createdAt'">Created</th>
          <th class="col-modified" [diColSorter]="'updatedAt'">Modified</th>
        </tr>
      </thead>

      <tbody>
        <tr
          *ngFor="
            let row of rows
              | paginate
                : {
                    id: 'dirt-ct-ext-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <td class="col-nct">
            <a class="dirt-nct" [title]="row.nct" [routerLink]="['/ct-profile/detail', row.id]">{{ row.nct }}</a>
          </td>
          <td [title]="row.ctrial?.conditions" class="col-conditions truncate">{{ row.ctrial?.conditions }}</td>
          <td class="col-priority">{{ row.priority }}</td>
          <td class="col-status" [title]="row.status | capitalize">{{ row.status | capitalize }}</td>
          <td class="col-created">
            {{ row.curatedAt | date : 'dd.MM.y' }} <small>{{ userNames[row.curatedBy] || '' }}</small>
          </td>
          <td class="col-created">
            {{ row.qcAt | date : 'dd.MM.y' }} <small>{{ userNames[row.qcBy] || '' }}</small>
          </td>
          <td class="col-category">{{ row.category }}</td>
          <td class="col-created">{{ row.createdAt | date : 'dd.MM.y' }}</td>
          <td class="col-modified">{{ row.updatedAt | date : 'dd.MM.y' }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No trial profiles</p>
    </ng-template>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-ct-ext-list'"
    [pagingSize]="rows?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
