import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { groupBy, toPairs, find } from 'lodash';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

import { FormList } from '../../interfaces/form-list.interface';
import { ACL } from '../../acl/acl.service';
import { AffiliationAPI } from '../../../affiliation/shared/api.service';
import { PersonAffiliationLfka } from '../../../person/shared/person-affiliation-lfka';
import { Value } from '../../services/value/value';
import { ValueType } from '../../enum/value-type.enum';
import { ValueAPI } from '../../services/value/value-api.service';
import { Roles } from '../../../shared/acl/roles';
import { Affiliation } from '../../../affiliation/shared/affiliation';
import { CapitalizePipe } from '../../pipes';

@Component({
  selector: 'dirt-affiliations-lfka',
  templateUrl: 'affiliations-lfka.component.html',
  styleUrls: ['affiliations-lfka.component.scss'],
})
export class PersonAffiliationsLfkaComponent implements OnInit, FormList {
  // similar to dirt-affiliations but not same: no child affiliations - and re-ordering is immediate to faithfully save what was dragged and dropped

  @Input()
  affiliations: Array<PersonAffiliationLfka> = [];

  @Input()
  mandatoryBecauseOfStatus: boolean = true;

  @Input()
  lfkaOnlyProject: boolean = false;

  @Input()
  disabled: boolean;

  @Input()
  reviewDisabled: boolean;

  @Input()
  currentJobType?: string;

  @Input()
  currentQcArea?: string;

  @Input()
  createRequestHandler: (
    sourceItem?: PersonAffiliationLfka,
    disableNameField?: boolean,
    requestOwnerProduct?: string
  ) => Observable<PersonAffiliationLfka> = null;

  @Input()
  maintenanceRequestHandler: (id, requestOwnerProduct?: string) => any = null;

  @ViewChild(NgbTypeahead, { static: false })
  searchAutocomplete: NgbTypeahead;

  expanded = new Set<string>();
  expandedDetail: { [_id: string]: boolean } = {};
  addedByCurrentUser = new Set<string>();
  searchTerm: string;
  isSearching = false;
  searchedOnce = false;
  searchTimeOutForPos: any;
  // Positions
  positions: Value[] = [];

  // D&D I
  draggedAffil: PersonAffiliationLfka = null;
  dragCurrAffilTargetIdx = -1;

  // D&D II
  draggedPosAffil: PersonAffiliationLfka = null;
  draggedPosIdx = -1;
  dragCurrPosTargetIdx = -1;

  countryValues: Value[] = [];

  groupedAffiliations: Array<{
    name: string;
    originalName?: string;
    isInactiveAfg?: boolean;
    affiliations?: Array<PersonAffiliationLfka>;
    inactiveAffiliations?: Array<{
      name: string;
      originalName?: string;
      isInactiveAfg?: boolean;
      affiliations?: Array<PersonAffiliationLfka>;
    }>;
  }> = [];

  canCreateAffiliation: boolean;

  private inScopeCountries: string[] = [];

  searchAffiliations = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) =>
        this.svcAffiliation.search(term, 100).pipe(
          // tap(() => this.searchFailed = false),
          catchError(() => {
            // this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.isSearching = false)),
      tap(() => (this.searchedOnce = true))
    );

  constructor(
    private readonly svcACL: ACL,
    private readonly svcAffiliation: AffiliationAPI,
    private svcValue: ValueAPI,
    private cdRef: ChangeDetectorRef,
    private capitalizePipe: CapitalizePipe
  ) {}

  ngOnInit() {
    this.canCreateAffiliation = this.svcACL.hasCredential('affiliation.create', this.currentJobType);

    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.countryValues = data;
    });

    this.svcValue.find(ValueType.InScopeCountry, Number.MAX_SAFE_INTEGER).subscribe((values) => {
      this.inScopeCountries = values.map((v) => v.code as string);
    });

    this.expanded.clear();
    this.groupAffiliations();
  }

  hasOnlyInactivePositions(af) {
    const pos = af.positions || [];
    return pos.length >= 1 && pos.filter((p) => p.inactive).length === pos.length;
  }

  hasAfgGroupOnlyInactivePositions(afg) {
    return afg.affiliations?.length && afg.affiliations.every((af) => this.hasOnlyInactivePositions(af));
  }

  hasAfgGroupUnpolished(afg): boolean {
    return !!afg.affiliations.find((a) => !a.readyForDelivery);
  }

  getPositionTitle(code: string): string {
    return (this.positions.find((o) => o.code === code) || { title: '' }).title;
  }
  getCountryTitle(code: string): string {
    return (this.countryValues.find((o) => o.code === code) || { title: '' }).title;
  }

  isValid(): boolean {
    let isValid = true;

    // Make sure that all affiliations are from in-scope countries
    if (!this.disabled) {
      isValid &&= (this.affiliations?.filter((a) => this.isOutOfScopeCountry(a))?.length || 0) === 0;
    }

    if (
      this.svcACL.hasCredential('person.update.mandatory.prop.affiliationsLfka', this.currentJobType) &&
      this.mandatoryBecauseOfStatus
    ) {
      isValid &&= this.affiliations.length > 0;
    }

    return isValid;
  }

  isFieldMandatory(field: string): boolean {
    return (
      this.svcACL.hasCredential(`person.update.mandatory.prop.${field}`, this.currentJobType) &&
      this.mandatoryBecauseOfStatus
    );
  }

  getListItems(): any[] {
    return this.affiliations; /* on the server (upsert): .map(af => ({
      id: af.id,
      countryCode: af.countryCode,
      positions: af.positions,
    }));*/
  }

  onSelect(event: any): void {
    event.preventDefault();

    if (!this.inScopeCountries.includes(event.item.address.countryCode)) {
      alert('People must never be actively affiliated with an out-of-scope country organization.');
      return;
    }

    if (
      !event.item.readyForDelivery &&
      !confirm(
        "You are adding an affiliation not polished yet by central organizations. The person will remain hidden from customers until central organizations' work is completed. Use anyway?"
      )
    ) {
      return;
    }

    this.addAffiliation(event.item);

    // clean up
    this.searchTerm = '';
    this.searchedOnce = false;
    this.searchAutocomplete.dismissPopup();
  }

  removeAffiliation(e: Event, id: string) {
    e.stopPropagation();

    const idx = this.affiliations.findIndex((af) => af.id === id);

    if (!confirm('Sure to delete the affiliation?')) {
      return;
    }

    this.addedByCurrentUser.delete(this.affiliations[idx].id);
    this.affiliations.splice(idx, 1);
    this.groupAffiliations();
  }

  toggleExpanded(name: string): void {
    const isExpanded = this.expanded.has(name);

    isExpanded ? this.expanded.delete(name) : this.expanded.add(name);
  }
  isExpanded(name: string): boolean {
    return this.expanded.has(name);
  }

  setPosInactive(event: Event, affiliation: PersonAffiliationLfka, pos: { inactive: boolean }) {
    event.stopPropagation();
    if (this.disabled) {
      return;
    }
    if (!this.canSetInactive()) {
      return;
    }

    if (pos.inactive) {
      delete pos.inactive;
    } else {
      pos.inactive = true;
    }
    // reorder below all active positions when deactivated, above all inactive when re-activated
    affiliation.positions = [
      ...affiliation.positions.filter((p) => !p.inactive && p !== pos),
      pos as any,
      ...affiliation.positions.filter((p) => p.inactive && p !== pos),
    ];
  }

  showAffiliationModal(sourceItem?: PersonAffiliationLfka) {
    this.createRequestHandler(
      { ...(sourceItem || ({} as any)), sourceLfka: true /* copy into new */ },
      undefined,
      'LFKA'
    ).subscribe((result) => {
      if (result.id) {
        this.addAffiliation(result, true);
      }
    });
  }

  onCopyClick(e: MouseEvent, sourceItem: PersonAffiliationLfka) {
    e.stopPropagation();
    this.searchAutocomplete.dismissPopup();
    this.searchAutocomplete.handleBlur();
    this.showAffiliationModal(sourceItem);
  }

  canRemoveItem(affiliation: PersonAffiliationLfka): boolean {
    if (!affiliation.readyForDelivery) {
      return (
        this.svcACL.hasCredential('person.update.prop.affiliations.remove', this.currentJobType) ||
        this.svcACL.hasCredential('person.update.prop.affiliations.removeRequest', this.currentJobType) ||
        this.addedByCurrentUser.has(affiliation.id)
      );
    }

    return (
      this.svcACL.hasCredential('person.update.prop.affiliations.remove', this.currentJobType) ||
      this.addedByCurrentUser.has(affiliation.id)
    );
  }

  canSetInactive(): boolean {
    return this.svcACL.hasCredential('person.update.prop.affiliations.inactive', this.currentJobType);
  }

  isFieldPositionDisabled(): boolean {
    if (this.disabled) {
      return true;
    }
    return !this.svcACL.hasCredential('person.update.prop.affiliations.position', this.currentJobType);
  }

  onPositionKeyUp(pos: { position: string; originalPosition: string }) {
    const delay = 300;

    if (!pos.originalPosition || pos.originalPosition.trim() === '') {
      pos.position = undefined;
      return;
    }

    if (this.searchTimeOutForPos) {
      clearTimeout(this.searchTimeOutForPos);
    }
    this.searchTimeOutForPos = setTimeout(() => {
      this.svcAffiliation
        .searchPos(pos.originalPosition)
        .toPromise()
        .then((data) => {
          pos.position = data.position;
        });
    }, delay);
  }

  public addPosition(affiliation: PersonAffiliationLfka) {
    if (this.disabled) {
      return;
    }
    const pos = JSON.parse(JSON.stringify(affiliation.positions || [])); // (deep copy b/c Angular tracking)
    // insert before there's only inactive ones
    let insertPos = pos.length;
    while (insertPos > 1 && pos[insertPos - 1].inactive) {
      insertPos--;
    }
    affiliation.positions = [
      ...pos.filter((p, i) => i < insertPos),
      {} as any, // the new one
      ...pos.filter((p, i) => i >= insertPos),
    ];
  }
  public removePosition(event: Event, affiliation: PersonAffiliationLfka, posIndex: number) {
    event.stopPropagation();
    if (this.disabled) {
      return;
    }

    if (!confirm('Sure to delete the position?')) {
      return;
    }

    affiliation.positions.splice(posIndex, 1);
  }

  private addAffiliation(affiliation: PersonAffiliationLfka, isNew?: boolean): void {
    // don't add the same affiliation multiple times
    if (this.affiliations.findIndex((item) => affiliation.id === item.id) < 0) {
      const newPos = {} as any;
      affiliation.positions = [newPos];
      affiliation.countryCode = affiliation.address.countryCode; // prepare later save (no server-side magic)
      if (isNew) {
        affiliation.newlyCreated = true;
      }
      this.affiliations.push(affiliation);
      this.addedByCurrentUser.add(affiliation.id);
    }
    this.groupAffiliations();
    this.expanded.add(affiliation.name);
  }

  highlightFields(additionalClass?: string) {
    additionalClass = additionalClass ? `form-control ${additionalClass || ''}` : `form-control`;

    return this.highlightFieldsForSearch() ? `form-focus ${additionalClass}` : additionalClass;
  }

  highlightFieldsForSearch() {
    return (
      this.svcACL.hasRole(Roles.ProfileModerator) ||
      this.svcACL.hasRole(Roles.ProfileModifier) ||
      this.svcACL.hasRole(Roles.ProfileCompiler)
    );
  }

  // D&D I - affiliation
  isAffilTargetVisible(affiliation: PersonAffiliationLfka) {
    return this.draggedAffil && affiliation !== this.draggedAffil && !affiliation;
  }
  isAffilDragged(affiliation: PersonAffiliationLfka) {
    return affiliation === this.draggedAffil;
  }
  isAffilOverTarget(idx: number) {
    return idx === this.dragCurrAffilTargetIdx;
  }
  dragOverAffil(event: Event, idx: number) {
    event.preventDefault();
    if (idx === this.dragCurrAffilTargetIdx) {
      // avoid bursts
      this.cdRef.detach();
      return;
    }
    this.dragCurrAffilTargetIdx = idx;
    this.cdRef.reattach();
  }
  dragLeaveAffil(event: Event, idx: number) {
    event.preventDefault();
    this.dragCurrAffilTargetIdx = -1;
    this.cdRef.reattach();
  }
  startDragAffil(event: Event, affiliation: PersonAffiliationLfka) {
    this.draggedAffil = affiliation;
    this.dragCurrAffilTargetIdx = -1;
  }
  stopDragAffil(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    this.draggedAffil = null;
    this.dragCurrAffilTargetIdx = -1;
    this.cdRef.reattach();
  }
  dropAffil(event: Event, newPos: number) {
    event.preventDefault();
    // reorder affiliations array
    const listPrev = this.affiliations.filter((p, i) => i < newPos).filter((p) => p !== this.draggedAffil);
    const listNext = this.affiliations.filter((p, i) => i >= newPos).filter((p) => p !== this.draggedAffil);
    const newList = [...listPrev, this.draggedAffil, ...listNext];
    // need to change in-place
    this.affiliations.splice(0, this.affiliations.length);
    newList.forEach((a) => this.affiliations.push(a));
    this.stopDragAffil();
  }

  // D&D II - position
  isPosTargetVisible(affiliation: PersonAffiliationLfka, pos: number) {
    return (
      this.draggedPosAffil &&
      affiliation === this.draggedPosAffil &&
      pos !== this.draggedPosIdx &&
      (!affiliation.positions || !affiliation.positions[pos])
    );
  }
  isPosDragged(affiliation: PersonAffiliationLfka, pos: number) {
    return affiliation === this.draggedPosAffil && pos === this.draggedPosIdx;
  }
  isPosOverTarget(affiliation: PersonAffiliationLfka, idx: number) {
    return affiliation === this.draggedPosAffil && idx === this.dragCurrPosTargetIdx;
  }
  dragOverPos(event: Event, idx: number) {
    event.preventDefault();
    if (idx === this.dragCurrPosTargetIdx) {
      // avoid bursts
      this.cdRef.detach();
      return;
    }
    this.dragCurrPosTargetIdx = idx;
    this.cdRef.reattach();
  }
  dragLeavePos(event: Event, idx: number) {
    event.preventDefault();
    this.dragCurrPosTargetIdx = -1;
    this.cdRef.reattach();
  }
  startDragPos(event: Event, affiliation: PersonAffiliationLfka, posIdx: number) {
    this.draggedPosAffil = affiliation;
    this.draggedPosIdx = posIdx;
    this.dragCurrPosTargetIdx = -1;
  }
  stopDragPos(event?: Event) {
    if (event) {
      event.preventDefault();
    }
    this.draggedPosAffil = null;
    this.draggedPosIdx = -1;
    this.dragCurrPosTargetIdx = -1;
    this.cdRef.reattach();
  }
  dropPos(event: Event, newPos: number) {
    event.preventDefault();
    // reorder pos array of the affiliation
    const posObj = this.draggedPosAffil.positions[this.draggedPosIdx];
    const listPrev = this.draggedPosAffil.positions.filter((p, i) => i < newPos).filter((p) => p !== posObj);
    const listNext = this.draggedPosAffil.positions.filter((p, i) => i >= newPos).filter((p) => p !== posObj);
    this.draggedPosAffil.positions = [...listPrev, posObj, ...listNext];
    this.stopDragPos();
  }

  reportDuplicate(affiliation?: Affiliation) {
    let origId = '';
    let origIdComplete = false;
    while (!origIdComplete) {
      origId = prompt('Please provide the id of the original (24-digits)', origId);
      if (null === origId) {
        return; // cancel
      }
      if (affiliation.id === origId) {
        continue; // the other one!
      }
      if (/[a-f0-9]{24}/.exec(origId)) {
        origIdComplete = true;
        break;
      }
    }
    this.svcAffiliation.duplicateSuspect(affiliation.id, { id: origId }).subscribe(
      (res) => {
        if (!res.ok) {
          alert(res.msg || 'Cannot mark as duplicate');
        } else {
          alert(res.msg || 'Thanks for letting us know');
        }
      },
      (error) => {
        alert('Error!');
      }
    );
  }

  onCopyAffiliationName(affiliation?: PersonAffiliationLfka): void {
    const value = affiliation.name + ` ${this.capitalizePipe.transform(affiliation.type)}`;
    navigator.clipboard.writeText(value);
  }

  groupAffiliations() {
    this.groupedAffiliations = [
      ...toPairs(
        // array of pairs where each pair contains a 'name' and an array of affiliations with that name
        groupBy(
          // group by active affiliations by `name` and make sure there's an `id` (KS-4919)
          this.affiliations.filter((a) => !a.inactive && a.id),
          'name'
        )
      )
        .map(([name, affiliations]) => ({
          // each pair to create an object containing the name, originalName (if available), and sorted list of affiliations
          name,
          originalName: (find(affiliations, ['name', name]) || { originalName: '' }).originalName,
          affiliations: affiliations.sort(
            (af1, af2) =>
              (!af1.department ? -100 : !af2.department ? 100 : 0) +
              Math.sign((af1.department || '').localeCompare(af2.department || ''))
          ),
        }))
        // sort alphabetically by name
        .sort((afg1, afg2) => Math.sign((afg1.name || '').localeCompare(afg2.name || ''))),
      ...(this.affiliations.filter((a) => a.inactive).length > 0
        ? [
            {
              name: 'Inactive affiliations',
              isInactiveAfg: true,
              inactiveAffiliations: [
                ...toPairs(
                  groupBy(
                    this.affiliations.filter((a) => a.inactive),
                    'name'
                  )
                )
                  // Maps over each pair to create an object containing the name, originalName (if available), and sorted list of inactive affiliations
                  .map(([name, affiliations]) => ({
                    name,
                    originalName: (find(affiliations, ['name', name]) || { originalName: '' }).originalName,
                    affiliations: affiliations.sort(
                      (af1, af2) =>
                        (!af1.department ? -100 : !af2.department ? 100 : 0) +
                        Math.sign((af1.department || '').localeCompare(af2.department || ''))
                    ),
                  })),
              ],
            },
          ]
        : []),
    ];
  }

  isOutOfScopeCountry(affiliation: PersonAffiliationLfka): boolean {
    return !this.inScopeCountries.includes(affiliation.address?.countryCode);
  }
}
