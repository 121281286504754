import { ProfileViewMode } from '../constant/view-mode.enum';
import { FiltersBarConfig } from '../../../shared/components/filters-bar/filters-bar-config';
import { capitalize } from '../utils';

export const filterBarConfig: FiltersBarConfig = {
  viewMode: {
    label: 'View mode',
    values: ProfileViewMode,
  },
  '_meta.status': {
    label: 'Status',
    format(value) {
      return capitalize(value);
    },
  },
  'person.projectNames': {
    label: 'Projects',
    hide: true,
  },
  'person.affiliation.countryCode': {
    label: 'Country',
    hide: true,
  },
  '_meta.assignee': {
    label: 'Polishers',
    hide: true,
  },
  priority: {
    label: 'Priority',
  },
  videoCompilerComment: {
    label: 'Video Compiler Comment',
  },
};
