import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { OrganizationAffiliationListComponent } from './list/list.component';
import { OrganizationAffiliationAPI } from './shared/organization-affiliation-api.service';

@NgModule({
  imports: [SharedModule],
  declarations: [OrganizationAffiliationListComponent],
  exports: [OrganizationAffiliationListComponent],
  providers: [OrganizationAffiliationAPI],
})
export class OrganizationAffiliationModule {}
