import { PersonStatus } from './../../../person/shared/constant/status.enum';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../user/shared/user';
import { UserAPI } from '../../../user/shared/api.service';

@Component({
  selector: 'dirt-status',
  templateUrl: 'status.component.html',
  styleUrls: ['status.component.scss'],
})
export class StatusComponent implements OnInit {
  @Input()
  statuses: any;

  @Input()
  showStatusIdOutReasons: boolean = false;

  @Input()
  assigneeStatuses: any;

  @Input()
  keepAssignee: boolean = false;

  @Input()
  disabledStatuses: string[] = [];

  @Input()
  commentStatuses: string[] = [];

  @Input()
  _meta: any = {};

  @Input()
  showClaimedWarning: boolean;

  @Input()
  requiresEu: boolean = false;

  @Output()
  onStatusSelected = new EventEmitter<any>();
  @Output()
  onCommentCreated = new EventEmitter<string>();
  @Output()
  statusIdOutReasonSelectedOn = new EventEmitter<string>();

  isLoading: boolean;
  searchFailed: boolean;
  user: User;
  selectedUser: User;
  statusComment: string;
  selectedIdOutReason: string;
  selectedStatus: string;
  isUserRequired: boolean;
  modalOption: NgbModalOptions = {};
  modalRef: NgbModalRef;

  statusIdOutReasons: Record<string, string> = {
    DECEASED: 'Deceased',
    OUT_OF_COUNTRY_SCOPE: 'Out of scope country',
    UNDER_AGE: 'Expert is under 18 years',
    NO_PROOF: 'No proof of life science affiliation or activities',
    NO_PROOF_5YEARS: 'No proof of primary affiliation within the last 5 years',
    IQVIA: 'Active affiliation is IQVIA',
    MIXED_PROFILE: 'Mixed profile',
    CANT_IDENTIFY: 'Unable to identify',
  };

  constructor(private modalService: NgbModal, private svcUser: UserAPI) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
    this.search = this.search.bind(this);
  }

  ngOnInit() {
    this.selectedStatus = this._meta.status;
    this.selectedIdOutReason = Object.keys(this.statusIdOutReasons).find(
      (key) => this.statusIdOutReasons[key] === this._meta.statusIdOutReason
    );

    if (this.hasAssignee() && this._meta.assignee) {
      this.svcUser.findById(this._meta.assignee).subscribe((resp) => (this.user = resp));
    }
  }

  onOpenModal(dialog) {
    this.selectedStatus = this._meta.status;
    this.selectedIdOutReason = Object.keys(this.statusIdOutReasons).find(
      (key) => this.statusIdOutReasons[key] === this._meta.statusIdOutReason
    );

    this.selectedUser = this.user;
    this.isUserRequired = !this.user;
    this.modalRef = this.modalService.open(dialog, this.modalOption);

    this.modalRef.result.then(() => {
      this.selectedUser = null;
      this.statusComment = null;
      this.selectedIdOutReason = null;
    });
  }

  search(text$: Observable<any>): Observable<any[]> {
    return text$.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      tap(() => (this.isLoading = true)),
      switchMap((term) => {
        this.isUserRequired = true;

        if (term.length > 2) {
          return this.svcUser
            .find(term, 10, 0, '', { blocked: false, ...(this.requiresEu ? { euCompliant: true } : {}) })
            .pipe(
              tap(() => (this.searchFailed = false)),
              catchError(() => {
                this.searchFailed = true;
                return of([]);
              })
            );
        } else {
          return of([]);
        }
      }),
      tap(() => (this.isLoading = false))
    );
  }

  onSave(): void {
    if (this.showClaimedWarning) {
      if (
        !confirm(`This person is currently assigned to user: ${this.formatName(this.user)}\n
                    Re assigning may result in loss of work.`)
      ) {
        return;
      }
    }

    if (this.hasAssignee() && this.selectedUser) {
      this._meta.assignee = this.selectedUser.user_id;
    } else if (!this.hasAssignee() && !this.keepAssignee) {
      if (this._meta.assignee && this.selectedStatus === 'DONE') {
        this._meta.lastAssignee = this._meta.assignee;
      }
      this._meta.assignee = null;
      this.selectedUser = null;
    }

    this.user = this.selectedUser;
    this._meta.status = this.selectedStatus;

    if (this.needsStatusIdOutReason()) {
      if (!this.selectedIdOutReason) {
        alert('Please, provide a reason for marking this ID OUT status.');
        return;
      }
      this._meta.statusIdOutReason = this.statusIdOutReasons[this.selectedIdOutReason];
    }

    const comment = (this.statusComment || '').trim();
    if (this.needsComment() && !comment) {
      alert('Please, provide a comment for marking this person.');
      return;
    }
    this.statusComment = null;

    if (comment) {
      if (this.selectedStatus === PersonStatus.ID_OUT) {
        this._meta.statusIdOutComment = comment;
      }
      this.onCommentCreated.emit(comment);
    }

    this.onStatusSelected.emit(this._meta);

    this.modalRef.close();
  }

  onSelectUser(): void {
    this.isUserRequired = false;
  }

  hasAssignee(): boolean {
    return this.assigneeStatuses && this.assigneeStatuses.includes(this.selectedStatus);
  }

  needsComment(): boolean {
    return this.commentStatuses && this.commentStatuses.includes(this.selectedStatus);
  }

  needsStatusIdOutReason(): boolean {
    return this.showStatusIdOutReasons && this.selectedStatus === PersonStatus.ID_OUT ? true : false;
  }

  formatName(user: User): string {
    let firstName;
    let lastName;

    if (user.user_metadata) {
      firstName = user.user_metadata.firstName;
      lastName = user.user_metadata.lastName;
    }

    const name = `${firstName} ${lastName} <${user.email}>`;
    return name.trim();
  }
}
