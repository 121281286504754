<div class="modal-header">
  <h5 class="modal-title">
    Search for a <b>{{ options.title }}</b>
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form-group" [ngClass]="{ 'has-danger': isInvalid }">
    <!-- Positions -->
    <div class="form-group" *ngIf="options.positionSet">
      <select class="form-control custom-select" name="positions" title="Positions" [(ngModel)]="position">
        <option [ngValue]="undefined" hidden>Select a position</option>
        <option *ngFor="let po of options.positionSet" [value]="po">{{ po }}</option>
      </select>
    </div>

    <!-- Search -->
    <div class="form-group">
      <div class="input-group">
        <input
          class="form-control form-control-danger"
          [ngClass]="{ 'form-control-warning': isInvalid }"
          name="entityId"
          [(ngModel)]="entityId"
          autocomplete="false"
          placeholder="Search by ID..."
        />
        <div class="input-group-append">
          <button
            class="btn btn-secondary"
            (click)="onSearch(entityId)"
            [disabled]="!entityId || (options.positionSet && !position)"
          >
            <i class="fa fa-search" aria-hidden="true" [hidden]="isLoading"></i>
            <i class="fa fa-circle-o-notch fa-spin fa-fw" [hidden]="!isLoading"></i>
          </button>
        </div>
      </div>
    </div>
  </form>

  <div class="mt-3" *ngIf="entity">
    <p>Would you like to add the following item to this profile?</p>
    <div>
      <!-- Name -->
      <a [routerLink]="dataItems.link" rel="noopener" target="_blank" *ngIf="dataItems.link">{{ dataItems.name }}</a>
      <strong *ngIf="!dataItems.link">{{ dataItems.name }}</strong>

      <!-- Position -->
      <span *ngIf="dataItems.position">({{ dataItems.position | capitalize }})</span>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-success" (click)="onClose()" [disabled]="!entity">Add to Profile</button>
</div>
