import { FieldValue } from './field-value';

export enum FieldType {
  select,
  multiSelect,
  string,
  number,
  user,
  multiUser,
}

export type ValuesFunc = (data: FieldValue[]) => { [key: string]: string };

export interface FieldConfig {
  field: string;
  label: string;
  tooltip?: string;
  type: FieldType;
  required?: boolean;
  values?: ValuesFunc | { [key: string]: string };
  format?: (value: any) => string;
  roles?: string[];
  hideCheck?: boolean;
  handleCheck?: (data: FieldValue[]) => boolean;
}
