import { catchError, debounceTime, distinctUntilChanged, firstValueFrom, Observable, of, switchMap, tap } from 'rxjs';
import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

import { Job } from '../job';
import { JobType } from '../modal/modal.component';
import { User } from '../../../user/shared/user';
import { UserAPI } from '../../../user/shared/api.service';
import { UserPool } from '../../../user-pool/shared/user-pool';
import { UserPoolAPI } from '../../../user-pool/shared/api.service';
import { ClinicalTrialProfileAPI } from '../../../clinical-trial-profile/shared/clinical-trial-profile-api.service';
import { PersonAPI } from '../../../person/shared/api.service';

@Component({
  selector: 'dirt-job-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class JobFormComponent implements OnChanges {
  @Input()
  model: Job;

  @Input()
  jobTypes: JobType[];

  @ViewChild(NgForm, { static: true })
  jobForm: NgForm;

  isLoadingPool: boolean;
  isLoadingUsers: boolean;

  availableUsers: User[] = [];

  hasInvalidSelectedPoolError: boolean;

  private selectedPoolUsers: User[];

  constructor(private svcUserPool: UserPoolAPI, private svcUser: UserAPI) {
    this.onSearchPool = this.onSearchPool.bind(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['model']) {
      this.onSelectPool({ item: this.model.poolId } as any);
      if (!this.model.additionalData) {
        this.model.additionalData = {};
      }
    }
  }

  formatName(pool: UserPool): string {
    return pool.name;
  }

  onSearchPool(term$: Observable<string>): Observable<UserPool[]> {
    return term$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => (this.isLoadingPool = true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }

        return this.svcUserPool.find(term, 10, 0, '', null).pipe(
          catchError(() => {
            return of([]);
          })
        ) as Observable<UserPool[]>;
      }),
      tap(() => (this.isLoadingPool = false))
    );
  }

  onJobTypeChange(): void {
    if (!this.selectedPoolUsers || !this.model.type) {
      return;
    }

    const jobType = this.jobTypes?.find((t) => t.key === this.model.type);
    if (!jobType) {
      this.availableUsers = this.selectedPoolUsers;
    } else {
      this.availableUsers = this.selectedPoolUsers.filter(
        (u) => u.app_metadata?.roles?.filter((r) => jobType.roles.includes(r as any))?.length > 0
      );
    }

    if (this.model._meta?.assignee && !this.availableUsers.find((u) => u.user_id === this.model._meta.assignee)) {
      // reset assignee
      this.model._meta.assignee = undefined;
    }

    this.hasInvalidSelectedPoolError = this.availableUsers.length === 0; // prevent submit if no one in the pool can work the job
  }

  async onSelectPool(event: NgbTypeaheadSelectItemEvent): Promise<void> {
    if (!(event.item.users?.length > 0) || !this.model.type) {
      this.availableUsers = [];
      return;
    }

    this.isLoadingUsers = true;
    let fullRes = [];
    let start = 0;
    let PAGE = 50;
    while (event.item.users.length > start) {
      const partRes = await firstValueFrom(
        this.svcUser.find(undefined, 5000, undefined, undefined, {
          userIds: event.item.users.slice(start, start + PAGE),
        })
      );
      fullRes = fullRes.concat(partRes);
      start += PAGE;
    }
    this.selectedPoolUsers = fullRes;
    this.isLoadingUsers = false;
    this.onJobTypeChange();
  }

  isValid(): boolean {
    return this.jobForm.valid && !this.hasInvalidSelectedPoolError;
  }
}
