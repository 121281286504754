<div class="clearfix">
  <form #ngForm="ngForm" novalidate>
    <!-- Year -->
    <div class="form-group row pb-4">
      <label class="col-form-label col-sm-4 col-md-2" for="year">Year</label>
      <div class="col-sm-4 col-md-5">
        <div class="input-group">
          <input
            type="number"
            id="year"
            name="year"
            class="form-control"
            [(ngModel)]="model.year"
            [disabled]="!isFieldEditable('year')"
            min="1900"
            autofocus
            integerValidator
            required
          />
        </div>
      </div>
    </div>

    <!-- Looping through payerShares -->
    <div class="pb-3" *ngFor="let payerShare of model.payerShares; let idx = index; trackBy: trackByIndex">
      <!-- Standardized Payer Name -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">Standardized Payer Name</label>
        <div class="col-sm-4 col-md-5">
          <select
            name="standardizedPayerName-{{ idx }}"
            class="form-control custom-select"
            (change)="clearCustomPayerNameIfDisabled(payerShare)"
            [(ngModel)]="payerShare.standardizedPayerName"
            [disabled]="!isFieldEditable('payerShare.standardizedPayerName')"
            required
          >
            <option [ngValue]="undefined">&mdash; Select &mdash;</option>
            <option *ngFor="let payerType of healthPayerTypes" [value]="payerType.code">{{ payerType.title }}</option>
          </select>
        </div>
      </div>

      <!-- Payer Name -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="payerName-{{ idx }}">Payer Name</label>
        <div class="col-sm-8 col-md-10">
          <input
            type="text"
            id="payerName-{{ idx }}"
            name="payerName-{{ idx }}"
            class="form-control"
            [(ngModel)]="payerShare.payerName"
            placeholder="Payer Name"
            [disabled]="
              !isFieldEditable('payerShare.payerName') ||
              !payerShare.standardizedPayerName ||
              !isCustomPayerNameEnabled(payerShare.standardizedPayerName)
            "
          />
        </div>
      </div>

      <!-- Share -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="share-{{ idx }}">Share (%)</label>
        <div class="col-sm-8 col-md-10">
          <input
            type="number"
            id="share-{{ idx }}"
            name="share-{{ idx }}"
            class="form-control"
            [(ngModel)]="payerShare.share"
            placeholder="Share"
            (ngModelChange)="onPayerSharesChanged()"
            min="1"
            max="100"
            required
          />
        </div>
      </div>

      <!-- Web sources -->
      <div
        class="form-group row"
        *ngFor="let websource of payerShare.websources; let wIdx = index; trackBy: trackByIndex"
      >
        <label class="col-form-label col-sm-4 col-md-2" for="websources-{{ idx }}-{{ wIdx }}">Websource</label>
        <div class="col-sm-8 col-md-10">
          <dirt-link
            [(url)]="payerShare.websources[wIdx]"
            name="websources-{{ idx }}-{{ wIdx }}"
            [form]="ngForm"
            [disabled]="!isFieldEditable('websources')"
          >
            <button
              type="button"
              class="btn btn-secondary"
              (click)="pushItemToList(payerShare.websources)"
              *ngIf="wIdx == payerShare.websources.length - 1 && isFieldEditable('websources')"
            >
              <i class="fa fa-plus-circle"></i>
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              (click)="removeFromByIndex(payerShare.websources, wIdx)"
              *ngIf="wIdx > 0 && isFieldEditable('websources')"
            >
              <i class="fa fa-minus-circle"></i>
            </button>
          </dirt-link>
        </div>
      </div>

      <!-- divider -->
      <hr class="hr hr-blurry" />
    </div>

    <div class="form-group row">
      <!-- Button to add a new payer share -->
      <div class="col-sm-2">
        <button class="btn btn-primary" (click)="addNewPayerShare()">Add New Share</button>
      </div>
      <!-- Button to delete last payer share -->
      <div class="col-sm-2">
        <button class="btn btn-danger" (click)="deleteLastShare()" *ngIf="model.payerShares.length > 1">
          Delete Last Share
        </button>
      </div>
    </div>
  </form>
</div>
