<section class="di-committee-connections">
  <div class="card">
    <div class="card-header">Connection</div>
    <div class="card-body">
      <!-- Searchbar -->
      <div class="di-search">
        <input
          type="search"
          class="form-control ng-trim-ignore"
          [ngClass]="{ loading: isSearching }"
          placeholder="Search..."
          aria-label="Search"
          [ngbTypeahead]="onSearchConnection"
          [resultTemplate]="searchResultTemplate"
          [(ngModel)]="searchTerm"
          name="searchConnection"
          (selectItem)="onSelectConnection($event)"
          *ngIf="!disabled"
        />

        <i class="fa fa-pulse fa-spinner di-va-super" [hidden]="!isSearching"></i>
      </div>

      <ng-container *ngIf="!disabled && !!connectionRequestHandler">
        <a href="javascript:void(0)" class="small pull-right pt-1 pr-2" (click)="onRequestConnection()">
          Request Connection
        </a>

        <br />
        <!-- This does the trick indeed - but maybe better to be fixed with css style vars -->
      </ng-container>

      <!-- Form -->
      <form #ngForm="ngForm" class="no-validate" novalidate>
        <div *ngFor="let connection of connections; let idx = index; trackBy: trackById">
          <div class="card mt-3 connection-wrapper">
            <div class="card-header" (click)="onToggleCollapse(connection.connectionId)">
              <div class="d-inline-block">
                <i
                  class="fa fa-lg"
                  [ngClass]="{
                    'fa-angle-right': isCollapsed.get(connection.connectionId),
                    'fa-angle-down': !isCollapsed.get(connection.connectionId)
                  }"
                  aria-hidden="true"
                >
                </i>

                <span class="font-weight-bold pl-1 pr-2">{{ connection.title }}</span>

                <!-- Unpolished Badge -->
                <div class="d-inline-block ml-2" *ngIf="!connection.readyForDelivery">
                  <div class="badge badge-warning" title="Not yet worked by team responsible of connection">
                    Unpolished
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body" [ngbCollapse]="isCollapsed.get(connection.connectionId)">
              <h5>
                <a
                  [routerLink]="'/' + connection.type.toLowerCase() + '/detail/' + connection.connectionId"
                  target="_blank"
                  rel="noopener"
                  >{{ connection.title }}</a
                >
              </h5>

              <ng-container *ngIf="!disabled && !!connectionRequestHandler && connection.readyForDelivery">
                <a
                  href="javascript:void(0)"
                  class="small pull-right pt-1 pr-2"
                  (click)="onRequestConnection(connection)"
                >
                  Request Maintenance
                </a>

                <br />
                <!-- This does the trick indeed - but maybe better to be fixed with css style vars -->
              </ng-container>

              <div class="row">
                <div class="col-md-10">
                  <!-- Probability -->
                  <select
                    class="form-control custom-select"
                    name="probability-{{ idx }}"
                    [(ngModel)]="connection.probability"
                    [disabled]="disabled"
                    required
                  >
                    <option [ngValue]="null" [selected]="!connection.probability" disabled>
                      &mdash; Probability &mdash;
                    </option>
                    <option *ngFor="let probability of committeeConnectionProbabilities" [value]="probability.code">
                      {{ probability.title }}
                    </option>
                  </select>
                </div>

                <!-- Actions -->
                <div class="col-md-2 connection-actions">
                  <!-- Remove -->
                  <span
                    class="action-btn action-remove"
                    title="Remove Connection"
                    (click)="onRemoveConnection(connection.connectionId)"
                    *ngIf="!disabled"
                  >
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </span>
                </div>
              </div>

              <!-- Primary Research -->
              <div class="primaryResearch-wrapper form-group">
                <label class="col-form-label title">Primary Research</label>
                <ui-switch
                  size="small"
                  color="#6d5cae"
                  id="toggle"
                  class="ui-switch"
                  [checked]="connection.hasPrimaryResearch === true"
                  (change)="onPrimaryResearchSwitchChange(idx, $event)"
                  [disabled]="!isFieldEditable('primaryResearch')"
                  required
                >
                </ui-switch>

                <!-- All Primary Research fields -->
                <ng-container *ngIf="connection.hasPrimaryResearch">
                  <!-- Result dropdown -->
                  <div class="form-group">
                    <label class="col-form-label">Result:</label>
                    <select
                      class="form-control custom-select"
                      name="primaryResearch-result-{{ idx }}"
                      [(ngModel)]="connection.primaryResearchInfo.result"
                      [disabled]="!isFieldEditable('primaryResearch')"
                      required
                    >
                      <option [ngValue]="null" disabled>Select the result</option>
                      <option *ngFor="let result of primaryResearchResults | keyvalue" [value]="result.value">
                        {{ result.value }}
                      </option>
                    </select>
                  </div>

                  <!-- Method dropdown -->
                  <div class="form-group">
                    <label class="col-form-label">Method to reach:</label>
                    <select
                      class="form-control custom-select"
                      name="primaryResearch-method-{{ idx }}"
                      [(ngModel)]="connection.primaryResearchInfo.method"
                      (change)="onPrimaryResearchMethodChange(idx)"
                      [disabled]="!isFieldEditable('primaryResearch')"
                      required
                    >
                      <option [ngValue]="null" disabled>Select the method</option>
                      <option *ngFor="let method of primaryResearchMethods | keyvalue" [value]="method.value">
                        {{ method.value }}
                      </option>
                    </select>
                  </div>

                  <!-- For Phone Method -->
                  <ng-container *ngIf="connection.primaryResearchInfo.method === 'Phone'">
                    <div class="form-group">
                      <dirt-input-phone
                        [model]="connection.primaryResearchInfo.details.phone"
                        [disabled]="!isFieldEditable('primaryResearch')"
                        [form]="ngForm"
                        [required]="true"
                      >
                      </dirt-input-phone>
                    </div>

                    <div class="form-group">
                      <label class="col-form-label">Phone-call Date</label>
                      <input
                        type="date"
                        id="phone-date-{{ idx }}"
                        class="form-control"
                        [(ngModel)]="connection.primaryResearchInfo.details.phoneCallDate"
                        [disabled]="!isFieldEditable('primaryResearch')"
                        name="phone-date-{{ idx }}"
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label class="col-form-label">Call Report</label>
                      <textarea
                        maxlength="1000"
                        class="form-control call-report"
                        name="call-report-{{ idx }}"
                        [(ngModel)]="connection.primaryResearchInfo.details.phoneCallReport"
                        placeholder="Please, provide your report from the call..."
                        [disabled]="!isFieldEditable('primaryResearch')"
                        required
                      ></textarea>
                    </div>
                  </ng-container>

                  <!-- For Email Method -->
                  <ng-container *ngIf="connection.primaryResearchInfo.method === 'Email'">
                    <div class="form-group">
                      <label class="col-form-label">Email Address</label>
                      <input
                        type="email"
                        class="form-control"
                        id="email-{{ idx }}"
                        name="email-{{ idx }}"
                        [(ngModel)]="connection.primaryResearchInfo.details.emailAddress"
                        [disabled]="!isFieldEditable('primaryResearch')"
                        placeholder="Please, enter the email address of the contact."
                        validateEmail
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label class="col-form-label">Date information received</label>
                      <input
                        type="date"
                        id="email-date-{{ idx }}"
                        class="form-control"
                        [(ngModel)]="connection.primaryResearchInfo.details.emailDate"
                        [disabled]="!isFieldEditable('primaryResearch')"
                        name="email-date-{{ idx }}"
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label class="col-form-label">Email Printout</label>
                      <dirt-link
                        [(url)]="connection.primaryResearchInfo.details.emailPrintout"
                        name="email-printout-{{ idx }}"
                        [form]="ngForm"
                        [disabled]="!isFieldEditable('primaryResearch')"
                        [required]="true"
                        [placeholder]="'Please, provide a GDrive or Egnyte URL of the Email Printout pdf file'"
                        [validateFormatOnly]="true"
                      >
                      </dirt-link>
                    </div>
                  </ng-container>

                  <!-- For Document Method -->
                  <ng-container *ngIf="connection.primaryResearchInfo.method === 'Document'">
                    <div class="form-group">
                      <label class="col-form-label">Document Title</label>
                      <input
                        type="text"
                        class="form-control"
                        name="doc-title-{{ idx }}"
                        [ngModel]="connection.primaryResearchInfo.details.documentTitle"
                        (ngModelChange)="connection.primaryResearchInfo.details.documentTitle = longDash($event)"
                        [disabled]="!isFieldEditable('primaryResearch')"
                        placeholder="Please, enter the title of the document."
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label class="col-form-label">Document Publish Date</label>
                      <input
                        type="date"
                        id="doc-date-{{ idx }}"
                        class="form-control"
                        [(ngModel)]="connection.primaryResearchInfo.details.documentPublishDate"
                        [disabled]="!isFieldEditable('primaryResearch')"
                        name="doc-date-{{ idx }}"
                        required
                      />
                    </div>

                    <div class="form-group">
                      <label class="col-form-label">Document host URL</label>
                      <dirt-link
                        [(url)]="connection.primaryResearchInfo.details.documentURL"
                        name="doc-url-{{ idx }}"
                        [form]="ngForm"
                        [disabled]="!isFieldEditable('primaryResearch')"
                        [required]="true"
                        [placeholder]="'Please, provide an account website or GDrive/Egnyte URL for the pdf file.'"
                        [validateFormatOnly]="true"
                      >
                      </dirt-link>
                    </div>
                  </ng-container>
                </ng-container>
              </div>

              <div style="margin-top: 24px" class="row" *ngIf="connection.linkedAccounts?.length > 0">
                <div class="col-sm-12">
                  Connected accounts:
                  <span *ngFor="let ca of connection.linkedAccounts; let idx = index"
                    >{{ idx > 0 ? ' - ' : ''
                    }}<a [routerLink]="'/account/detail/' + ca._id" target="_blank" rel="noopener">{{
                      ca.name
                    }}</a></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <p class="text-center mt-1" *ngIf="!connections.length">No Connections</p>
    </div>
  </div>
</section>

<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div class="di-sac-title">
    <span>{{ r.title }}</span>
  </div>
</ng-template>
