import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgOption } from '@ng-select/ng-select';
import { NgForm } from '@angular/forms';

import { ACL } from '../../../shared/acl/acl.service';
import { AffiliationAPI } from '../../../affiliation/shared/api.service';
import { FormList } from '../../../shared/interfaces/form-list.interface';
import { IMultiSelectOption, IMultiSelectSettings } from '../multiselect-dropdown/types';
import { PersonAffiliationEducation } from '../../../person/shared/person-affiliation-education';
import { PersonJob } from '../../../person/shared/constant/job.enum';
import { Utils } from './../../../common/utils';
import { Value } from '../../../shared/services/value/value';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { ValueAPI } from '../../../shared/services/value/value-api.service';

@Component({
  selector: 'dirt-affiliations-education',
  templateUrl: 'affiliations-education.component.html',
  styleUrls: ['affiliations-education.component.scss'],
})
export class PersonAffiliationsEducationComponent implements OnInit, OnChanges, FormList {
  @Input()
  affiliations: Array<PersonAffiliationEducation> = [];

  @Input()
  disabled: boolean;

  @Input()
  currentJobType?: string;

  @Input()
  currentQcArea?: string;

  @Input()
  createRequestHandler: (
    sourceItem?: PersonAffiliationEducation,
    disableNameField?: boolean,
    requestOwnerProduct?: string
  ) => Observable<PersonAffiliationEducation> = null;

  @Input()
  maintenanceRequestHandler: (id, requestOwnerProduct?: string) => any = null;

  @ViewChild(NgbTypeahead, { static: false })
  searchAutocomplete: NgbTypeahead;

  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  @ViewChild('sect')
  sectionElement: ElementRef;

  searchTerm: string;
  isSearching = false;
  searchedOnce = false;

  countryValues: Value[] = [];

  // Specialties
  specialties: IMultiSelectOption[] = [];
  specialtiesSettings: IMultiSelectSettings = {
    buttonClasses: 'btn btn-sm btn-secondary',
    checkedStyle: 'fontawesome',
    enableSearch: true,
    sortSelectedFirst: true,
    dynamicTitleMaxItems: 5,
  };

  studyTracks: NgOption[] = [];

  canCreateAffiliation: boolean;
  isMainExpanded: boolean;

  wndw: Window = window;

  searchAffiliations = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) =>
        this.svcAffiliation.search(term, 100).pipe(
          // tap(() => this.searchFailed = false),
          catchError(() => {
            // this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.isSearching = false)),
      tap(() => (this.searchedOnce = true))
    );

  constructor(
    private readonly svcACL: ACL,
    private readonly svcAffiliation: AffiliationAPI,
    private svcValue: ValueAPI
  ) {}

  ngOnInit() {
    this.canCreateAffiliation = this.svcACL.hasCredential('affiliation.create', this.currentJobType);
    this.checkExpanded();

    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.countryValues = data;
    });
    this.loadSpecialties();
    this.loadStudyTracks();

    this.affiliations.forEach((affiliation) => {
      if (!affiliation.proofLinks) {
        affiliation.proofLinks = ['']; // create empty array if not exists - after migration from `prooflink`
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentJobType']) {
      this.checkExpanded();
      // if we switch into "Educational background", _scroll_ there
      if (
        changes['currentJobType'].previousValue !== PersonJob.EDUCATIONAL_BACKGROUND &&
        changes['currentJobType'].currentValue === PersonJob.EDUCATIONAL_BACKGROUND
      ) {
        setTimeout(() => this.sectionElement.nativeElement.scrollIntoView({ behavior: 'smooth' }));
      }
    }
  }

  loadSpecialties() {
    this.svcValue.find(ValueType.Specialty, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.specialties = data.map((o) => ({
        id: o.code,
        name: o.title,
        disabled: o.disabled,
      }));
    });
  }

  loadStudyTracks() {
    this.svcValue.find(ValueType.StudyTrack, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((values) => {
      this.studyTracks = values.map((v) => ({
        label: v.title,
        value: v.code,
        disabled: v.disabled,
      }));
    });
  }

  private checkExpanded() {
    this.isMainExpanded =
      this.svcACL.hasCredential('person.create.prop.affiliationsEducation', this.currentJobType) ||
      this.svcACL.hasCredential('person.update.prop.affiliationsEducation', this.currentJobType);
  }

  getCountryTitle(code: string): string {
    return (this.countryValues.find((o) => o.code === code) || { title: '' }).title;
  }

  isValid(): boolean {
    return this.ngForm.valid;
  }

  isRequired(): boolean {
    // (just leave in case we need again)
    return false;
  }

  getListItems(): any[] {
    this.affiliations.map((affiliationEducation) => {
      Utils.removeEmptyAndDuplicatedElementsFromArray(affiliationEducation, ['proofLinks']);
    });

    return this.affiliations; /* on the server (upsert): .map(af => ({
      id: af.id,
      primary: af.primary,
      lastKnown: af.lastKnown,
      inactive: af.inactive,
      position: af.position,
      originalPosition: af.originalPosition
    })); */
  }

  onSelect(event: any): void {
    event.preventDefault();

    this.addAffiliation(event.item);

    // clean up
    this.searchTerm = '';
    this.searchedOnce = false;
    this.searchAutocomplete.dismissPopup();
  }

  removeAffiliation(e: Event, idx: number) {
    e.stopPropagation();

    if (!this.wndw.confirm('Sure to delete the educational background?')) {
      return;
    }

    this.affiliations.splice(idx, 1);
  }

  showAffiliationModal(sourceItem?: PersonAffiliationEducation, disableNameField?: boolean) {
    this.createRequestHandler(sourceItem, disableNameField, 'LFTA').subscribe((result) => {
      if (result.id) {
        this.addAffiliation(result, true);
      }
    });
  }

  onCopyClick(e: MouseEvent, sourceItem: PersonAffiliationEducation) {
    e.stopPropagation();
    this.searchAutocomplete.dismissPopup();
    this.searchAutocomplete.handleBlur();
    this.showAffiliationModal(sourceItem, true);
  }

  private addAffiliation(affiliation: PersonAffiliationEducation, isNew?: boolean): void {
    if (this.affiliations?.length >= 10) {
      this.wndw.alert('Already have 10 educational backgrounds - that is the max');
      return;
    }
    // for edu: we CAN add the same affiliation multiple times - we don't prevent and we need to deep-copy
    affiliation = JSON.parse(JSON.stringify(affiliation));
    affiliation.countryCode = affiliation.address?.countryCode; // prepare later save (no server-side magic)
    if (isNew) {
      affiliation.newlyCreated = true;
    }
    if (!affiliation.proofLinks) {
      affiliation.proofLinks = ['']; // due to migration from `prooflink`
    }

    this.affiliations.push(affiliation);
  }

  trackById(index: number, affiliation: PersonAffiliationEducation): string {
    return `${index}-${affiliation.id}`;
  }

  trackByProofIndex(index: number, proofLink: string): string {
    return `${index}-${proofLink}`;
  }

  shouldAddMoreLink(list: any[]): boolean {
    return list.length < 5;
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }
}
