import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

/**
 * Used to replace the `window.prompt` so we can show
 * results of arrays per line and not concatenated into one line with commas.
 */
@Component({
  selector: 'dirt-result-dialog',
  templateUrl: 'result-dialog.component.html',
  styleUrls: ['result-dialog.component.scss'],
})
export class ResultDialogComponent {
  title: string = '';
  results: any[];

  constructor(public activeModal: NgbActiveModal) {}
}
