import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl } from '@angular/forms';

const LIST_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ListValidator),
  multi: true,
};

@Directive({
  selector: '[validateList][formControlName],[validateList][formControl],[validateList][ngModel]',
  providers: [LIST_VALIDATOR],
})
export class ListValidator implements Validator {
  @Input() validateList: string[];

  validate(c: AbstractControl): { [key: string]: any } {
    if (!this.validateList.length) {
      return null;
    }

    if (!c.value || this.isPresent(Validators.required(c))) {
      return;
    }

    if (!this.validateList.includes(c.value)) {
      return { list: true };
    }
  }

  private isPresent(obj: any) {
    return obj !== undefined && obj !== null;
  }
}
