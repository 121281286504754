import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl } from '@angular/forms';
import { Utils } from '../../common/utils';

const URL_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => UrlValidator),
  multi: true,
};

@Directive({
  selector: '[ngModel][validateURL]',
  providers: [URL_VALIDATOR],
})
export class UrlValidator implements Validator {
  nonCompliantDomains: string[] = [];

  @Input()
  set validateURL(value: string[]) {
    this.nonCompliantDomains = value;
  }

  validate(control: AbstractControl): { [key: string]: any } {
    // return CustomValidators.url(c);
    if (this.isPresent(Validators.required(control))) {
      return;
    }

    const v: string = control.value;
    return this.validateUrl(v);
  }

  public validateUrl(v: string) {
    if (Utils.isDomainNonCompliant(this.nonCompliantDomains, v)) {
      return { url: true };
    }

    // We should probably not use a regex to validate URLs, yet it's there now, and it does the job...
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
      v
    )
      ? null
      : { url: true };
  }

  private isPresent(obj: any) {
    return obj !== undefined && obj !== null;
  }
}
