import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { concatMap, of, take, tap } from 'rxjs';

import { ACL } from '../../shared/acl/acl.service';
import { Auth } from '../../shared/services/auth/auth.service';
import { Domain } from '../shared/domain';
import { DomainAPI } from '../shared/domain-api.service';
import { Job } from '../../jobs/shared/job';
import { JobsAPI } from '../../jobs/shared/api.service';
import { Roles } from '../../shared/acl/roles';
import { User } from '../../user/shared/user';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'dirt-domain-detail',
  templateUrl: './detail.component.html',
})
export class DomainDetailComponent implements OnInit {
  id: string;

  domain: Domain;

  isLoading = true;

  isSaving = false;

  isFormValid: boolean;

  canSaveAnyway: boolean;

  canSubmitNonJob: boolean;

  canSubmitJob: boolean;

  hasJobForCurrentUser: boolean;

  currentJob: Job;

  canCreateComments: boolean;

  isDomainAdmin: boolean;

  private currentUser: User;

  private wndw: Window = window; // allow for testing

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcAcl: ACL,
    private svcAuth: Auth,
    private svcJob: JobsAPI,
    public svcDomain: DomainAPI, // used in template too
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.canSaveAnyway = this.svcAcl.hasCredential('domain.saveAnyway'); // allow saving despite remaining form validation issues
    this.canSubmitNonJob = this.svcAcl.hasCredential('domain.update');
    this.canSubmitJob = this.svcAcl.hasCredential('job.submit');
    this.canCreateComments = this.svcAcl.hasCredential('domain.comment.create');
    this.isDomainAdmin = this.svcAcl.hasRole(Roles.Admin) || this.svcAcl.hasRole(Roles.DomainAdmin);

    this.route.params
      .pipe(
        take(1),
        tap(() => {
          this.isLoading = true;

          // Make sure we cannot submit something we shouldn't under the pretense that we are working a job
          this.currentJob = null;
          this.hasJobForCurrentUser = false;
        }),
        concatMap((params) => {
          window.scrollTo(0, 0);

          this.id = params['id'];

          return this.svcDomain.findById(this.id).pipe(
            tap((domain) => {
              this.domain = domain;
              this.titleService.setTitle(`cLean | Domain | ${this.domain.hostname}`);
            })
          );
        }),
        concatMap(() => this.svcAuth.getProfile().pipe(tap((user) => (this.currentUser = user)))),
        concatMap(() => {
          if (!this.domain._meta?.jobId) {
            return of(null);
          }

          return this.svcJob.findById(this.domain._meta.jobId).pipe(
            tap((job) => {
              if (job) {
                this.currentJob = job;
                this.hasJobForCurrentUser =
                  this.svcAcl.hasCredential('job.submit') &&
                  this.domain._meta.jobId &&
                  this.currentUser.user_id === this.domain._meta.assignee;
              }
            })
          );
        }),
        tap(() => (this.isLoading = false))
      )
      .subscribe();
  }

  goBack(): void {
    const link = ['/domain'];
    this.router.navigate(link);
  }

  onJobUtc(): void {
    if (!this.hasJobForCurrentUser) {
      return;
    }

    let comment = '';
    while (comment.length < 3) {
      comment = this.wndw.prompt('Enter a comment for Unable to Compile...');
      if (null === comment) {
        return;
      }
    }

    this.isSaving = true;

    this.svcJob
      .setUtc(this.currentJob._id, comment)
      .pipe(tap(() => (this.isSaving = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  onJobDraft(domain: Domain): void {
    if (!this.hasJobForCurrentUser) {
      return;
    }

    this.isSaving = true;

    this.svcDomain
      .update(this.domain.id, domain)
      .pipe(
        concatMap(() => this.svcJob.setDraft(this.currentJob._id)),
        tap(() => (this.isSaving = false))
      )
      .subscribe(() => {
        this.goBack();
      });
  }

  onSubmitJob(domain: Domain): void {
    if (!this.hasJobForCurrentUser || !this.isFormValid) {
      return;
    }

    this.isSaving = true;

    this.svcDomain
      .submitJob(domain)
      .pipe(tap(() => (this.isSaving = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  onSave(domain: Domain): void {
    this.updateDomain(domain);
  }

  onFormValidityChange(status: string): void {
    setTimeout(() => (this.isFormValid = status === 'VALID'));
  }

  onRequiresChangeApprovalChange(requiresChangeApproval: boolean): void {
    if (!this.svcAcl.hasCredential('domain.update.prop.requiresChangeApproval')) {
      return;
    }

    this.isSaving = true;

    this.svcDomain
      .update(this.domain.id, { requiresChangeApproval })
      .pipe(tap(() => (this.isSaving = false)))
      .subscribe((domain) => {
        this.domain.status = domain.status;
        this.domain.updatedAt = domain.updatedAt;
        this.domain._version = domain._version;
      });
  }

  onOptOutChange(optOut: boolean): void {
    if (!this.svcAcl.hasCredential('domain.update.prop.optOut')) {
      return;
    }

    this.isSaving = true;

    this.svcDomain
      .update(this.domain.id, { optOut })
      .pipe(tap(() => (this.isSaving = false)))
      .subscribe((domain) => {
        this.domain.status = domain.status;
        this.domain.updatedAt = domain.updatedAt;
        this.domain._version = domain._version;
      });
  }

  isClaimedByOtherUser(): boolean {
    // actual valid use of call from template to handle expiration
    const hasValidClaim = this.domain._meta.claimedUntil && new Date(this.domain._meta.claimedUntil) > new Date();
    const isClaimedByUser = this.domain._meta.assignee && this.domain._meta.assignee !== this.currentUser.user_id;

    return hasValidClaim && isClaimedByUser;
  }

  private updateDomain(domain: Domain): void {
    if (!this.canSubmitNonJob || (!this.isFormValid && !this.canSaveAnyway)) {
      return;
    }

    if (this.isDomainAdmin && Object.keys(domain.pendingUpdates || {}).length > 0) {
      this.wndw.alert('There are pending changes that need to be checked.');
      return;
    }

    if (
      !this.isFormValid &&
      this.canSaveAnyway &&
      !this.wndw.confirm('There are validation errors left. Sure you want to submit?')
    ) {
      return;
    }

    this.isSaving = true;

    this.svcDomain
      .update(this.id, domain)
      .pipe(tap(() => (this.isSaving = false)))
      .subscribe(() => {
        this.goBack();
      });
  }
}
