<section class="dirt-section-list dirt-event-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span *ngIf="total">{{ total.count | number }}</span>
        <span [hidden]="parentId">Events</span>
        <span [hidden]="!parentId">Sessions</span>
      </h1>
    </div>
    <div class="col-sm-4" *ngIf="!advancedSearchSpec">
      <input
        type="search"
        class="form-control"
        [formControl]="searchCtrl"
        [(ngModel)]="searchTerm"
        placeholder="Search..."
      />
      <small><a class="alink" (click)="openAdvancedSearch()">Advanced search</a></small>
    </div>
    <div class="col-sm-4" *ngIf="advancedSearchSpec">
      <small
        ><a class="alink" (click)="openAdvancedSearch()">Change search</a>
        <a class="alink" (click)="clearAdvancedSearch()">Clear search</a></small
      >
    </div>
    <dialog class="advanced-search-dialog" #advancedSearchDialog (keyup.enter)="runAdvancedSearch()">
      <dirt-event-advanced-search
        *ngIf="advancedSearchSpec"
        [model]="advancedSearchSpec"
        [hideSessionFilters]="!!parentId"
      ></dirt-event-advanced-search>
      <hr />
      <div class="pull-right">
        <button class="btn" (click)="advancedSearchDialogElement.nativeElement.close()">Cancel</button>
        <button class="btn" (click)="clearAdvancedSearch()">Clear</button>
        <button class="btn btn-primary" (click)="runAdvancedSearch()">GO</button>
      </div>
    </dialog>
    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Filter -->
        <!-- TODO: replace that by the generic filter component when there is a change to make here -->
        <dirt-event-filter (onFilter)="setFilter($event)" *ngIf="!parentId"></dirt-event-filter>

        <dirt-button-dropdown
          [title]="'Bulk'"
          [icon]="'fa-pencil'"
          *diAcl="'event.list.bulkUpdate || event.bulkList || event.bulkSearch'"
        >
          <dirt-bulk-update
            *diAcl="'event.list.bulkUpdate'"
            [filter]="filter"
            [search]="searchTerm"
            [options]="bulkUpdateConfig"
            (refresh)="onBulkUpdateRefresh()"
          ></dirt-bulk-update>

          <button
            class="list-group-item list-group-item-action"
            [title]="bulkListDelegate.specialHint || ''"
            (click)="onOpenBulkListDialog()"
            *diAcl="'event.bulkList'"
          >
            <i class="fa fa-th-list" aria-hidden="true"></i>
            Bulk import
          </button>

          <button
            class="list-group-item list-group-item-action"
            [title]="bulkSearchDelegate.specialHint || ''"
            (click)="onOpenBulkSearchDialog()"
            *diAcl="'event.bulkSearch'"
          >
            <i class="fa fa-th-list" aria-hidden="true"></i>
            Bulk Search
          </button>
          <button
            class="list-group-item list-group-item-action"
            [title]="jobBulkListDelegate.specialHint || ''"
            (click)="onOpenBulkListJobDialog()"
          >
            <i class="fa fa-th-list" aria-hidden="true"></i>
            Bulk Import Job
          </button>
        </dirt-button-dropdown>

        <!-- Create Button -->
        <button
          class="btn btn-success btn-sm"
          (click)="navigateTo(['/event/create'])"
          *diAcl="'event.create'"
          [hidden]="true"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Create Event
        </button>
        <button
          class="btn btn-success btn-sm"
          (click)="navigateTo(['/event/create'])"
          *diAcl="'session.create'"
          [hidden]="!parentId"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Create Session
        </button>
      </div>
    </div>
  </header>

  <div class="pull-right">
    <!-- Dashboards -->
    <dirt-button-dropdown [title]="'Dashboards'" [icon]="'fa-tachometer'" *diAcl="'event.dashboards'">
      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/event-id-throughput/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Event ID Throughput
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/event-id-qc-throughput/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Event ID QC Throughput
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/event-id-qc-error-rate/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Event ID QC Error Rate
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/event-throughput/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Event Throughput
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/event-qc-throughput/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Event QC Throughput
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/event-qc-error-rate/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Event QC Error Rate
      </a>
    </dirt-button-dropdown>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-event-list'"
    [pagingSize]="events?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Table -->
  <div *ngIf="events" [hidden]="isLoading">
    <table
      class="table table-sm table-hover"
      [hidden]="!events.length"
      [(sortConfig)]="sortConfig"
      (diSort)="onSort($event)"
    >
      <thead>
        <tr>
          <!-- Events list -->
          <th class="col-state" *ngIf="!parentId">&nbsp;</th>

          <!-- Events list + Sessions -->
          <th class="col-name" [diColSorter]="'name'">Name</th>

          <!-- Events list -->
          <th class="col-country" [diColSorter]="'address.countryCode'" *ngIf="!parentId">Location</th>
          <th class="col-reach" [diColSorter]="'reach'" *ngIf="!parentId">Reach</th>
          <th class="col-category" [diColSorter]="'category'" *ngIf="!parentId">Cat.</th>
          <th class="col-created" [diColSorter]="'createdAt'" *ngIf="!parentId">Created</th>
          <th class="col-date" [diColSorter]="'startDate'" *ngIf="!parentId">Date</th>
          <th class="col-modified" [diColSorter]="'updatedAt'" *ngIf="!parentId">Modified</th>
          <th class="col-status" [diColSorter]="'_meta.status'" *ngIf="!parentId">Status</th>

          <!-- Sessions -->
          <th class="col-sponsors" *ngIf="parentId">Sponsors</th>
          <th class="col-session-date" *ngIf="parentId">Session Date</th>
          <th class="col-session-time" *ngIf="parentId">Session Time</th>
          <th class="col-contrib-count">
            <div ngbTooltip="Contributions Count">
              <i class="fa fa-list" aria-hidden="true"></i>
            </div>
          </th>
          <th class="col-reviewed" *ngIf="parentId">
            <input
              (change)="toggleAllReviewed($event)"
              type="checkbox"
              [checked]="allReviewed"
              [disabled]="!canSetReviewedFlag() || isLoading"
            />
            Reviewed
          </th>
          <th class="col-verified" *ngIf="parentId">
            <input
              (change)="toggleAllVerified($event)"
              type="checkbox"
              [checked]="allVerified"
              [disabled]="!canSetVerifiedFlag() || isLoading"
            />
            Verified
          </th>

          <!-- Events list + Sessions -->
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>
      <tbody
        cdkDropList
        id="sessionList"
        [cdkDropListData]="events"
        cdkDropListConnectedTo="contributionsList"
        (cdkDropListDropped)="drop($event)"
      >
        <tr
          class="dirt-event-row"
          *ngFor="
            let event of events
              | paginate
                : {
                    id: 'dirt-event-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  }
          "
          (click)="navigateTo(['/event/detail', event.id], $event)"
          cdkDrag
          [cdkDragDisabled]="disableDragAndDrop"
        >
          <!-- Events list -->
          <td class="col-state" *ngIf="!parentId">
            <span
              class="state state-stacked state-success"
              ngbTooltip="Verified"
              placement="right"
              *ngIf="event.verified"
            ></span>
            <span
              class="state state-stacked state-primary"
              ngbTooltip="Oncology"
              placement="right"
              *ngIf="event.scope"
            ></span>
            <span
              class="state state-stacked state-warning"
              ngbTooltip="Upcoming"
              placement="right"
              *ngIf="event._meta.maintenance"
            ></span>
          </td>

          <!-- Events list + Sessions -->
          <td class="col-name">
            <a class="dirt-event-name" [title]="event.name" [routerLink]="['/event/detail', event.id]">{{
              event.name
            }}</a>
          </td>

          <!-- Events list -->
          <td class="col-country" *ngIf="!parentId">{{ getCountryTitle(event.address?.countryCode) }}</td>
          <td class="col-reach" *ngIf="!parentId">{{ event.reach | capitalize }}</td>
          <td class="col-category" *ngIf="!parentId">{{ event.category }}</td>
          <td class="col-created" *ngIf="!parentId">{{ event.createdAt | date : 'dd.MM.y' }}</td>
          <td class="col-date" *ngIf="!parentId">{{ event.startDate | date : 'dd.MM.y' }}</td>
          <td class="col-modified" *ngIf="!parentId">{{ event.updatedAt | date : 'dd.MM.y' }}</td>
          <td class="col-status" *ngIf="!parentId">{{ event._meta?.status | capitalize }}</td>

          <!-- Sessions -->
          <td class="col-sponsors" *ngIf="parentId">{{ getSponsorsName(event.sponsors) }}</td>
          <td class="col-session-date" *ngIf="parentId">{{ event.sessionDate | date : 'dd.MM.y' : 'UTC' }}</td>
          <td class="col-session-time" *ngIf="parentId">
            {{
              event.sessionTime
                ? ((event.sessionTime.hour || 0) * 3600000 + (event.sessionTime.minute || 0) * 60000
                  | date : 'HH:mm' : 'UTC')
                : ''
            }}
          </td>
          <td class="col-contrib-count">
            <span>{{ event.contributionsCount | number }}</span>
          </td>
          <td class="col-ended" *ngIf="parentId" (click)="$event.stopPropagation()">
            <input
              type="checkbox"
              [disabled]="!canSetReviewedFlag()"
              [checked]="event.reviewed"
              (change)="onSessionReviewedChange(event, $event)"
            />
          </td>
          <td class="col-ended" *ngIf="parentId" (click)="$event.stopPropagation()">
            <span [ngClass]="{ 'di-red-border': isNeeded(event) }"
              ><input
                type="checkbox"
                [disabled]="!canSetVerifiedFlag()"
                [checked]="event.verified"
                (change)="onSessionVerifiedChange(event, $event)"
            /></span>
          </td>

          <!-- Events list + Sessions -->
          <td class="col-actions">
            <!-- Delete -->
            <span
              class="action-btn action-delete"
              (click)="onDelete(event.id, $event)"
              *diAcl="'event.delete || session.delete'"
            >
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <p class="dirt-no-results" *ngIf="!events.length">
      {{ parentId ? 'No Sessions' : 'No Events' }}
    </p>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-event-list'"
    [pagingSize]="events?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="isLoading || pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
