<button [disabled]="isReplicating" class="btn btn-sm btn-secondary" (click)="onOpenModal(dialog)">
  Create Medical Insights Profiles for training
</button>

<dirt-spinner [show]="isReplicating"></dirt-spinner>

<ng-template #dialog let-close="close">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Replicate Medical Insights Profile</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label class="col-form-label col-md-9" for="numOfCopies">Select number of copies:</label>
      <select class="'form-control'" [(ngModel)]="numOfCopies" id="numOfCopies">
        <option *ngFor="let num of getNumbers()" [value]="num">{{ num }}</option>
      </select>
    </div>
  </div>

  <div class="modal-footer">
    <div class="pull-right">
      <button type="button" class="btn btn-primary btn-sm" (click)="onSubmit()">Submit</button>
      <button type="button" class="btn btn-secondary btn-sm" (click)="close()">Cancel</button>
    </div>
  </div>
</ng-template>
