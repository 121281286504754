import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { SponsorComponent } from './sponsors.component';
import { sponsorRouting } from './sponsors.routes';
import { SponsorAPI } from './shared/api.service';
import { SponsorListComponent } from './list/list.component';
import { SponsorFormComponent } from './shared/form/form.component';
import { SponsorCreateComponent } from './create/create.component';
import { SponsorDetailComponent } from './detail/detail.component';
import { SponsorNormalizedNamesComponent } from './normalized-names/normalized-names.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [SharedModule, NgSelectModule, sponsorRouting],
  declarations: [
    SponsorComponent,
    SponsorListComponent,
    SponsorFormComponent,
    SponsorCreateComponent,
    SponsorDetailComponent,
    SponsorNormalizedNamesComponent,
  ],
  exports: [],
  providers: [SponsorAPI],
})
export class SponsorModule {}
