import { Component, OnInit, Input, ViewChild, OnDestroy, Output, EventEmitter, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { GuidelineAuthor } from '../guideline-author';
import { Utils } from '../../../common/utils';
import { ACL } from '../../../shared/acl/acl.service';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { Value } from '../../../shared/services/value/value';
import { GuidelineAuthorPosition } from '../constant/position.enum';
import { UserGroupAPI } from '../../../user-group/shared/api.service';
import { NameSplitService } from '../../../shared/services/name-split/name-split.service';
import { Auth } from '../../../shared/services/auth/auth.service';

@Component({
  selector: 'dirt-authorship-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  exportAs: 'frmAuthorship',
})
export class GuidelineAuthorFormComponent implements OnInit, OnDestroy {
  @Input()
  author: GuidelineAuthor = new GuidelineAuthor();

  @Output()
  validityChange: EventEmitter<boolean> = new EventEmitter();

  @Input()
  disabled = false;

  suffixes: Value[] = [];

  longDash = Utils.longDash;

  positions = GuidelineAuthorPosition;

  countries: Value[] = [];

  enableOriginalNamesSection = false;

  @ViewChild(NgForm, { static: true })
  private ngForm: NgForm;

  private APACCountries: Set<string>;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private svcAcl: ACL,
    private svcValue: ValueAPI,
    private svcUserGroup: UserGroupAPI,
    private svcNameSplit: NameSplitService,
    public auth: Auth
  ) {}

  ngOnInit(): void {
    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((values) => {
      this.countries = values;
    });

    this.svcValue.find(ValueType.PersonSuffix, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.suffixes = data;
    });

    this.svcUserGroup.find(Number.MAX_SAFE_INTEGER, '+name').subscribe((regions) => {
      const APACRegions = regions.filter((region) => region.name.startsWith('APAC '));
      this.APACCountries = new Set(APACRegions.map((region) => region.countries).flat());

      this.enableOriginalNamesSection = this.APACCountries.has(this.author.country);
    });

    this.ngForm.statusChanges.pipe(distinctUntilChanged(), takeUntil(this.destroy$)).subscribe(() => {
      this.validityChange.next(this.ngForm.valid);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  getValue(): GuidelineAuthor {
    if (this.APACCountries.has(this.author.country)) {
      // reset suffix
      this.author.suffix = null;
    } else {
      // reset original full name
      this.author.originalFullName = null;
    }

    return this.author;
  }

  isFieldEditable(field: string): boolean {
    const prefix = this.author._id ? 'update' : 'create';
    return this.svcAcl.hasCredential(`guideline.${prefix}.prop.author.${field}`) && !this.disabled;
  }

  onCountryChange(country: string): void {
    this.enableOriginalNamesSection = this.APACCountries.has(country);
  }

  @HostListener('document:keydown.control.x')
  @HostListener('document:keydown.meta.x')
  onClipboardPaste() {
    (navigator as any).clipboard
      .readText()
      .then((text: string) => {
        const name = this.svcNameSplit.splitFullName(text);
        this.author.firstName = name.first;
        this.author.middleName = name.middle;
        this.author.lastName = name.last;
      })
      .catch(() => window.alert('You have to grant access to read from your clipboard!'));
  }
}
