import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dirt-checker-renderer',
  templateUrl: './checker-renderer.component.html',
  styleUrls: ['./checker-renderer.component.scss'],
})
export class CheckerRendererComponent implements OnInit {
  params: any;
  disabled = false;
  value = false;

  constructor() {}

  ngOnInit(): void {}

  agInit(params: any): void {
    this.params = params;

    if ('PB' === this.params.data?._profile?.match?.automate) {
      this.disabled = true;
    }
  }

  onCheckClick() {
    if (this.disabled) {
      return;
    }

    this.value = !this.value;

    this.params.onCheckChanged(this.value);
  }
}
