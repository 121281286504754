import { MembershipPosition } from './constant/position.enum';

export class Membership {
  public id: string;
  public association: any;
  // public webSources: string[] = [];
  public position: MembershipPosition;
  public startYear: string;
  public endYear: string;
  public member: {
    firstName?: string;
    middleName?: string;
    lastName?: string;
    fullName?: string;
    nickname?: string;
    suffix?: string;
    workplace?: string;
  } = {};
  public ended = false;
  public reviewed = false;
  public verified = false;
  qc: any;
  constructor() {}
}
