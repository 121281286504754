<form #formGuidelineAuthor="ngForm" novalidate>
  <fieldset [diMarkerWrapper]="author" class="dirt-fieldset form-group">
    <div class="form-group row">
      <div class="col-12">
        <h3 *ngIf="!author._id; else editTitle">Create new authorship</h3>
        <ng-template #editTitle>
          <h3>Edit authorship</h3>
        </ng-template>
      </div>
    </div>

    <!-- Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">
        <span class="pr-2">Name</span>
        <a href="javascript:void(0)" class="di-timid" ngbTooltip="Paste and split" (click)="onClipboardPaste()">
          <i class="fa fa-clipboard" aria-hidden="true"></i>
        </a>
      </label>

      <div class="col-sm-8 col-md-10">
        <div class="row">
          <!-- First Name -->
          <div class="col-md-3">
            <label class="sr-only" for="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              class="form-control"
              name="firstName"
              placeholder="First Name"
              [ngModel]="author.firstName"
              (ngModelChange)="author.firstName = longDash($event)"
              [readonly]="!isFieldEditable('firstName')"
            />
          </div>

          <!-- Middle Name -->
          <div class="col-md-2">
            <label class="sr-only" for="middleName">Middle Name</label>
            <input
              type="text"
              id="middleName"
              class="form-control"
              name="middleName"
              placeholder="Middle Name"
              [ngModel]="author.middleName"
              (ngModelChange)="author.middleName = longDash($event)"
              [readonly]="!isFieldEditable('middleName')"
            />
          </div>

          <!-- Last Name -->
          <div class="col-md-3">
            <label class="sr-only" for="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              class="form-control"
              name="lastName"
              placeholder="Last Name"
              [ngModel]="author.lastName"
              (ngModelChange)="author.lastName = longDash($event)"
              [readonly]="!isFieldEditable('lastName')"
              [required]="!enableOriginalNamesSection || !author.originalFullName"
              *diMarker="{ name: 'lastName', entity: 'guideline', area: 'BD' }"
            />
          </div>

          <!-- Suffix -->
          <div class="col-md-2">
            <label class="sr-only" for="suffix">Suffix</label>
            <select
              id="suffix"
              class="form-control custom-select"
              name="suffix"
              [(ngModel)]="author.suffix"
              [disabled]="!isFieldEditable('suffix') || enableOriginalNamesSection"
            >
              <option [ngValue]="undefined">&mdash; Suffix &mdash;</option>
              <option *ngFor="let suffix of suffixes" [value]="suffix.code">{{ suffix.title }}</option>
            </select>
          </div>

          <!-- Nickname -->
          <div class="col-md-2">
            <label class="sr-only" for="nickname">Nickname</label>
            <input
              type="text"
              id="nickname"
              class="form-control"
              name="nickname"
              placeholder="Nickname"
              [ngModel]="author.nickname"
              (ngModelChange)="author.nickname = longDash($event)"
              [readonly]="!isFieldEditable('nickname')"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Original Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Original Name</label>
      <div class="col-sm-8 col-md-10">
        <div class="row">
          <!-- Original Full Name -->
          <div class="col-md-12">
            <label class="sr-only" for="originalFullName">Original Full Name</label>
            <input
              type="text"
              id="originalFullName"
              class="form-control"
              name="originalFullName"
              placeholder="Original Full Name"
              [ngModel]="author.originalFullName"
              (ngModelChange)="author.originalFullName = longDash($event)"
              [readonly]="!isFieldEditable('originalFullName') || !enableOriginalNamesSection"
              [required]="enableOriginalNamesSection && !author.lastName"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Position -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="position">Position</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="position"
          class="form-control custom-select"
          name="position"
          [(ngModel)]="author.position"
          [disabled]="!isFieldEditable('position')"
          required
          *diMarker="{ name: 'position', entity: 'guideline', area: 'BD' }"
        >
          <option *ngFor="let position of positions | keyvalue" [value]="position.key">
            {{ position.value | capitalize }}
          </option>
        </select>
      </div>
    </div>

    <!-- Institution -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="institution">Institution</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="institution"
          class="form-control"
          name="institution"
          [(ngModel)]="author.institution"
          [disabled]="!isFieldEditable('institution')"
          *diMarker="{ name: 'institution', entity: 'guideline', area: 'BD' }"
        />
      </div>
    </div>

    <!-- City -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="city">City</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="city"
          class="form-control"
          name="city"
          [(ngModel)]="author.city"
          [disabled]="!isFieldEditable('city')"
          *diMarker="{ name: 'city', entity: 'guideline', area: 'BD' }"
        />
      </div>
    </div>

    <!-- Country -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="country">Country</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="country"
          class="form-control custom-select"
          name="country"
          [(ngModel)]="author.country"
          (ngModelChange)="onCountryChange($event)"
          [disabled]="!isFieldEditable('country')"
          *diMarker="{ name: 'country', entity: 'guideline', area: 'BD' }"
        >
          <option *ngFor="let country of countries" [value]="country.code">
            {{ country.title }}
          </option>
        </select>
      </div>
    </div>
  </fieldset>
</form>
