import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, tap, concatMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { Guideline } from '../shared/guideline';
import { ACL } from '../../shared/acl/acl.service';
import { GuidelineAPI } from '../shared/guideline-api.service';
import { Auth } from '../../shared/services/auth/auth.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { ProjectSetChange } from '../../shared/components/project-name/set-change';
import { GuidelineStatus } from '../shared/constant/status.enum';
import { PublicationAPI } from '../../publication/shared/publication-api.service';
import { GuidelineAuthorListComponent } from '../../guideline-author/list/list.component';
import { Roles } from '../../shared/acl/roles';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { checkRegionRestrict, RegionRestrict } from '../../shared/values/region-restrict';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'dirt-guideline-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class GuidelineDetailComponent implements OnInit {
  id: string;

  guideline: Guideline;

  isLoading = true;

  isSubmitting = false;

  isSavingVerified = false;

  isSavingIdVerified = false;

  isSavingStatus = false;

  canCreateComments: boolean;

  isClaimedByOtherUser: boolean;

  ValueType = ValueType;

  statuses = GuidelineStatus;

  assigneeStatuses = [GuidelineStatus.COMPILATION_IN_PROGRESS];

  disabledStatuses = [];

  isPublicationGuideline = false;

  isObjectionCommentShown: boolean;

  objectionStatus: string;

  private myUserId: string;

  @ViewChild(GuidelineAuthorListComponent) authorList: GuidelineAuthorListComponent;

  private regionRestrict: RegionRestrict[] = [];

  private inScopeCountries: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcAcl: ACL,
    private svcAuth: Auth,
    public svcGuideline: GuidelineAPI, // used in template too
    private svcPublication: PublicationAPI,
    private svcValue: ValueAPI,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.isPublicationGuideline = this.router.routerState.snapshot.url.split('/')[1] === 'publication-guideline';

    this.route.params
      .pipe(
        take(1),
        concatMap((params) => {
          this.id = params['id'];
          let observable: Observable<Guideline>;
          if (!this.isPublicationGuideline) {
            observable = this.svcGuideline.findById(this.id);
          } else {
            observable = this.svcPublication
              .findById(this.id)
              .pipe(map((publication) => Object.assign(new Guideline(), publication)));
          }

          return observable.pipe(tap(() => (this.isLoading = false)));
        }),
        concatMap((guideline) => {
          let localDate: string | Date;

          if (typeof guideline.publicationDate === 'string') {
            const [year, month, day] = guideline.publicationDate.split('-').map(Number);
            localDate = new Date(year, month - 1, day);
          } else if (guideline.publicationDate instanceof Date) {
            localDate = guideline.publicationDate;
          }

          guideline.publicationDate = new Date(localDate);
          this.guideline = guideline;
          const pageTitle = this.isPublicationGuideline ? 'Publication Guideline' : 'Guideline';
          this.titleService.setTitle(`cLean | ${pageTitle} | ${this.guideline.title}`);
          return this.svcAuth.getProfile();
        })
      )
      .subscribe((profile) => {
        this.myUserId = profile.user_id;
        this.isClaimedByOtherUser = this.getIsClaimedByOtherUser(this.guideline);
      });

    this.canCreateComments = this.svcAcl.hasCredential('guideline.comment.create');

    this.svcValue.find(ValueType.RegionalRestrict, Number.MAX_SAFE_INTEGER).subscribe((values) => {
      this.regionRestrict = values;
    });

    this.svcValue.find(ValueType.InScopeCountry, Number.MAX_SAFE_INTEGER).subscribe((values) => {
      this.inScopeCountries = values.map((v) => v.code as string);
    });
  }

  goBack(): void {
    if (!this.isPublicationGuideline) {
      this.router.navigate(['/guideline']);
    } else {
      this.router.navigate(['/publication-guideline']);
    }
  }

  onSave(guideline: Guideline): void {
    const hasDuplicates = this.authorList?.hasDuplicates();

    let problems: string[] = [];
    if (hasDuplicates) {
      problems.push('Some duplicates were found in authors.');
    }

    if (guideline.journal?.country && !this.inScopeCountries.includes(guideline.journal.country)) {
      problems.push('Selected country is out of scope.');
    }

    let message: string;
    if (problems.length > 0) {
      message = `The following issues were found: \n - ${problems.join('\n - ')}\n\n`;
      message += 'Do you really want to submit this entry?';

      // confirm the submit action
      if (!window.confirm(message)) {
        return;
      }
    }

    guideline.published = false;
    this.updateGuideline(guideline);
  }

  onVerifiedChange(verified: boolean): void {
    if (!this.svcAcl.hasCredential('guideline.update.prop.verified') || this.isPublicationGuideline) {
      return;
    }

    this.isSavingVerified = true;

    this.svcGuideline
      .update(this.guideline._id, { verified } as unknown as Guideline)
      .subscribe((guideline: Guideline) => {
        this.guideline = this.getMergedGuideline(this.guideline, guideline);
        this.isSavingVerified = false;
      });
  }

  onIdVerifiedChange(idVerified: boolean): void {
    if (
      !this.svcAcl.hasCredential('guideline.update.prop.idVerified') ||
      this.isPublicationGuideline ||
      this.isSavingIdVerified
    ) {
      return;
    }

    this.isSavingIdVerified = true;

    this.svcGuideline
      .update(this.guideline._id, { idVerified } as unknown as Guideline)
      .subscribe((guideline: Guideline) => {
        this.guideline = this.getMergedGuideline(this.guideline, guideline);
        this.isSavingIdVerified = false;
      });
  }

  onStatusChange(_meta: any): void {
    if (!this.svcAcl.hasCredential('guideline.update.prop.meta.status') || this.isPublicationGuideline) {
      return;
    }

    this.isSavingStatus = true;

    this.svcGuideline.update(this.guideline._id, { _meta } as Guideline).subscribe((guideline: Guideline) => {
      this.guideline = this.getMergedGuideline(this.guideline, guideline);
      this.isSavingStatus = false;
    });
  }

  onProjectSelectionChanged(event: ProjectSetChange): void {
    if (!this.svcAcl.hasCredential('guideline.update.prop.projectNames') || this.isPublicationGuideline) {
      return;
    }

    this.svcGuideline
      .update(this.guideline._id, { projectNames: event.activeSet } as Guideline)
      .subscribe((guideline: Guideline) => {
        this.guideline = this.getMergedGuideline(this.guideline, guideline);
        event.callback();
      });
  }

  onUnableToCompile(): void {
    if (!this.svcAcl.hasCredential('guideline.update.unable')) {
      return;
    }

    this.svcGuideline
      .update(this.guideline._id, { '_meta.status': GuidelineStatus.UNABLE_TO_COMPILE } as unknown as Guideline)
      .subscribe(() => {
        this.goBack();
      });
  }

  onSubmit(guideline: Guideline): void {
    const hasDuplicates = this.authorList?.hasDuplicates();

    let problems: string[] = [];
    if (hasDuplicates) {
      problems.push('Some duplicates were found in authors.');
    }

    if (guideline.journal?.country && !this.inScopeCountries.includes(guideline.journal.country)) {
      problems.push('Selected country is out of scope.');
    }

    let message: string;
    if (problems.length > 0) {
      message = `The following issues were found: \n - ${problems.join('\n - ')}\n\n`;
    }

    message += 'Do you really want to submit this entry?';
    message += '\nNote: By submitting, you will not be having any access to it anymore!';

    // confirm the submit action
    if (!window.confirm(message)) {
      return;
    }

    guideline.published = true; // should not save, but still kept it to indicate a submission, removed in api

    guideline._meta.status = GuidelineStatus.DONE;
    guideline._meta.claimedUntil = null;
    this.updateGuideline(guideline);
  }

  private updateGuideline(guideline: Guideline): void {
    if (!this.svcAcl.hasCredential('guideline.update') || this.isPublicationGuideline) {
      return;
    }

    this.isSubmitting = true;

    delete guideline.authors; // prevent risk of overriding authors with an outdated version

    this.svcGuideline
      .update(this.id, guideline)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  private getIsClaimedByOtherUser(guideline: Guideline): boolean {
    return (
      guideline._meta?.claimedUntil &&
      new Date(guideline._meta.claimedUntil) > new Date() &&
      guideline._meta?.assignee !== this.myUserId
    );
  }

  private getMergedGuideline(localData: Guideline, remoteData: Guideline): Guideline {
    localData.updatedAt = remoteData.updatedAt;
    localData._version = remoteData._version;
    localData.readyForDelivery = remoteData.readyForDelivery;
    return localData;
  }

  onObjection(status: string): void {
    this.objectionStatus = status;
    this.isObjectionCommentShown = true;
  }

  onObjectionCancel(): void {
    this.objectionStatus = null;
    this.isObjectionCommentShown = false;
  }

  skipVerification(): void {
    if (!window.confirm('Are you sure?')) {
      return;
    }

    this.svcGuideline
      .update(this.guideline._id, { verificationSkipped: true } as unknown as Guideline)
      .subscribe(() => {
        this.goBack();
      });
  }

  isRegionCompliant(): boolean {
    const countryCode = this.guideline.journal?.country || null;
    const hasWhitelistedRole =
      this.svcAcl.hasRole(Roles.Admin) ||
      this.svcAcl.hasRole(Roles.GuidelineManager) ||
      this.svcAcl.hasRole(Roles.GuidelineModerator);

    if (hasWhitelistedRole) {
      return true;
    }

    if (!this.isPublicationGuideline) {
      // We don't have country information for publication guidelines
      return true;
    }

    return checkRegionRestrict(this.svcAcl, this.regionRestrict, countryCode);
  }
}
