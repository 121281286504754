import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Sponsor } from '../shared/sponsor';
import { SponsorAPI } from '../shared/api.service';
import { ACL } from '../../shared/acl/acl.service';

@Component({
  selector: 'dirt-sponsor-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.scss'],
})
export class SponsorDetailComponent implements OnInit, OnDestroy {
  id: string;
  sponsor: Sponsor;
  sub: any;
  isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcSponsor: SponsorAPI,
    private svcAcl: ACL
  ) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.getSponsor();
    });
  }

  getSponsor() {
    this.svcSponsor.findById(this.id).subscribe((resp) => (this.sponsor = resp));
  }

  onSave(sponsor: Sponsor): void {
    if (!this.svcAcl.hasCredential('sponsor.update')) {
      return;
    }
    this.isSubmitting = true;
    this.svcSponsor.update(sponsor._id, sponsor).subscribe(
      (sponsor: Sponsor) => {
        this.isSubmitting = false;
        if (sponsor) {
          this.router.navigate([`/sponsor/detail/${sponsor._id}`]);
          return;
        }
        alert('All original names are mapped!');
        this.goBack();
      },
      (error) => {
        if (error.status === 412 && error.error?.potentialDuplicate) {
          alert('Normalized name already exists with a different companyId!');
        }
        this.isSubmitting = false;
      }
    );
  }

  goBack(): void {
    this.router.navigate(['/sponsor']);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  canUpdate() {
    return this.svcAcl.hasCredential('sponsor.update');
  }
}
