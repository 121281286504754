import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';

@Directive({
  selector: '[sameValueValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => SameValueValidator), multi: true }],
})
export class SameValueValidator implements Validator {
  @Input()
  set sameValueValidator(control: FormControl) {
    this.comparisonControl = control;
  }

  private comparisonControl: FormControl;

  validate(control: AbstractControl): ValidationErrors {
    if (!control.value) {
      return null;
    }

    return control.value === this.comparisonControl.value ? { 'same-value': true } : null;
  }
}
