import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET, PATCH, MediaType, Produces, Path, Body, POST } from '../../shared/services/rest/rest.service';

import { APIService } from '../../shared/services/api/api.service';
import { Video } from './video';

@Injectable()
export class VideoAPI extends APIService {
  @GET('videos/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Video> {
    return;
  }

  @PATCH('videos/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body video: Video): Observable<Video> {
    return;
  }

  @POST('videos/validate')
  @Produces(MediaType.JSON)
  public validate(@Body vendorUrl: { url: string }): Observable<boolean> {
    return;
  }

  @POST('videos')
  @Produces(MediaType.JSON)
  public create(@Body video: any): Observable<Video> {
    return;
  }

  @GET('profiles/videos/{id}')
  @Produces(MediaType.JSON)
  public findProfilesByVideoId(@Path('id') id: string): Observable<any> {
    return;
  }
}
