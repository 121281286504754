import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Body, GET, MediaType, PATCH, Path, POST, Produces, Query } from '../../shared/services/rest/rest.service';
import { MedicalInsightsProfile } from './medical-insights-profile';
import { MedicalInsightsProfileAPI } from './medical-insights-profile-api.service';

@Injectable()
export class MedicalInsightsUATProfileAPI extends MedicalInsightsProfileAPI {
  get modulePath(): string {
    return 'medical-insight-profile-uat';
  }

  @GET('medical-insight-profile-uat/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<any> {
    return null;
  }

  @PATCH('medical-insight-profile-uat/{id}')
  @Produces(MediaType.JSON)
  public save(@Path('id') id: string, @Body params: Partial<MedicalInsightsProfile>): Observable<any> {
    return;
  }

  @POST('medical-insights-profile-uat/submit-job')
  submitJob(@Body params: Partial<MedicalInsightsProfile>): Observable<any> {
    return;
  }

  /**
   * Find all the audit logs of a given model id
   * @param id
   */
  @GET('medical-insights-profile-uat/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  /**
   * Find count of audit logs
   * @param id
   */
  @GET('medical-insights-profile-uat/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }
}
