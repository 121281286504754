import { Component, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, takeUntil, tap } from 'rxjs';
import { FormControl } from '@angular/forms';

import { Filter, FilterType } from '../../shared/components/filters/filters.component';
import { Sponsor } from '../shared/sponsor';
import { SponsorAPI } from '../shared/api.service';
import { ACL } from '../../shared/acl/acl.service';
import { Router } from '@angular/router';

@Component({
  selector: 'dirt-sponsor-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class SponsorListComponent implements OnInit, OnDestroy {
  sponsors: Array<Sponsor> = [];

  isLoading = true;

  private sort = '-_id';

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  // Sorting / Filtering settings
  searchCtrl: FormControl = new FormControl('');
  filter: { [key: string]: any } = {};
  filters: Filter[] = [
    {
      title: 'Company Id',
      key: 'companyId',
      type: FilterType.SINGLE_VALUE,
      values: [
        { title: '', displayValue: 'Is Empty' },
        { title: 'true', displayValue: 'Is not empty' },
        { title: 'FALSE (no company sponsor)', displayValue: 'FALSE (no company sponsor)' },
      ],
    },
    {
      title: 'Created',
      key: 'createdAt',
      type: FilterType.DATE_RANGE,
    },
    {
      title: 'Updated',
      key: 'updatedAt',
      type: FilterType.DATE_RANGE,
    },
  ];

  private searchTerm: string;

  private destroy$: Subject<boolean> = new Subject();

  constructor(private svcSponsor: SponsorAPI, private svcAcl: ACL, private readonly router: Router) {}

  ngOnInit(): void {
    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.searchTerm = term;
        this.doLoad();
      });

    this.doLoad();
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getSponsors();
  }

  onFilter(filter: { [key: string]: any }): void {
    this.filter = filter;
    this.doLoad();
  }

  private doLoad(): void {
    this.resetPagination();
    this.getSponsors();
    this.getSponsorsCount();
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getSponsors(): void {
    this.isLoading = true;
    this.svcSponsor
      .find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.filter)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((sponsors) => {
        this.sponsors = sponsors;
      });
  }

  private getSponsorsCount(): void {
    this.svcSponsor.count(this.searchTerm, this.filter).subscribe((count) => {
      this.total = count;
    });
  }

  private storeFiltersAsQueryParams(): void {
    if (this.sort) {
      const filters = { ...(this.sort && { sortField: this.sort }) };
      this.router.navigate([], { queryParams: filters });
    }
  }

  onDeleteClick(sponsorId: string, $event) {
    $event.stopPropagation();
    if (!this.svcAcl.hasCredential('sponsor.delete')) {
      return;
    }

    if (!window.confirm('Sponsor will be deleted. Do you want to continue?')) {
      return;
    }
    this.svcSponsor.deleteById(sponsorId).subscribe(() => {
      this.doLoad();
    });
  }

  onSort(field: string): void {
    this.sort = field;
    this.storeFiltersAsQueryParams();
    this.getSponsors();
  }

  navigateTo(id: string): void {
    if (!id) {
      return;
    }

    this.router.navigate(['/sponsor/detail', id]);
  }
}
