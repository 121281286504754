import { NgModule } from '@angular/core';

import { AffiliationModule } from '../affiliation/affiliation.module';
import { AssociationModule } from '../association/association.module';
import { ClinicalTrialSponsorAPI } from './shared/clinical-trial-sponsor-api.service';
import { ClinicalTrialSponsorComponent } from './clinical-trial-sponsor.component';
import { ClinicalTrialSponsorDetailComponent } from './detail/detail.component';
import { ClinicalTrialSponsorFormComponent } from './shared/form/form.component';
import { ClinicalTrialSponsorListComponent } from './list/list.component';
import { clinicalTrialSponsorRouting } from './clinical-trial-sponsor.routes';
import { CommentModule } from '../comment/comment.module';
import { PersonModule } from '../person/person.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    SharedModule,
    AssociationModule,
    AffiliationModule,
    PersonModule,
    clinicalTrialSponsorRouting,
    CommentModule,
  ],
  declarations: [
    ClinicalTrialSponsorComponent,
    ClinicalTrialSponsorListComponent,
    ClinicalTrialSponsorDetailComponent,
    ClinicalTrialSponsorFormComponent,
  ],
  exports: [],
  providers: [ClinicalTrialSponsorAPI],
})
export class ClinicalTrialSponsorModule {}
