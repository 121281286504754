import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { tap } from 'rxjs/operators';

import { Job } from '../../../jobs/shared/job';
import { JobsAPI } from '../../../jobs/shared/api.service';

@Component({
  selector: 'dirt-job-history',
  templateUrl: './job-history.component.html',
  styleUrls: ['./job-history.component.scss'],
})
export class JobHistoryComponent implements OnChanges {
  @Input()
  entityId: string;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  isLoading: boolean;

  jobs: Job[];

  constructor(private svcJobs: JobsAPI) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.entityId) {
      return;
    }

    this.doLoad();
  }

  /** just avoid useless rendering if we can */
  trackById(_: number, job: Job): string {
    return job.id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getJobs();
  }

  private doLoad(): void {
    this.resetPagination();
    this.getJobs();
    this.getJobsCount();
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getJobs(): void {
    this.isLoading = true;
    this.svcJobs
      .find(null, this.pagingLimit, this.pagingSkip, '-createdAt', { entityId: this.entityId })
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((jobs) => {
        this.jobs = jobs;
      });
  }

  private getJobsCount(): void {
    this.svcJobs.count({ entityId: this.entityId }).subscribe((count) => {
      this.total = count;
    });
  }
}
