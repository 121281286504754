<form #frmPerson="ngForm" [diMarkerWrapper]="model" novalidate>
  <!-- First Name -->
  <div class="form-group">
    <label class="col-form-label" for="firstName">First Name</label>
    <input
      type="text"
      id="firstName"
      name="firstName"
      class="form-control"
      [(ngModel)]="model.firstName"
      placeholder="First Name"
      dirtPasteNormalizer
      required
      validateNameWithoutDot
      [disabled]="isFieldDisabled('firstName')"
      *diMarker="{ name: 'firstName', entity, area }"
    />
  </div>

  <!-- Middle Name -->
  <div class="form-group">
    <label class="col-form-label" for="middleName">Middle Name</label>
    <input
      type="text"
      id="middleName"
      name="middleName"
      class="form-control"
      [(ngModel)]="model.middleName"
      placeholder="Middle Name"
      dirtPasteNormalizer
      validateNameWithoutDot
      [disabled]="isFieldDisabled('middleName')"
      *diMarker="{ name: 'middleName', entity, area }"
    />
  </div>

  <!-- Last Name -->
  <div class="form-group">
    <label class="col-form-label" for="lastName">Last Name</label>
    <input
      type="text"
      id="lastName"
      name="lastName"
      class="form-control"
      [(ngModel)]="model.lastName"
      placeholder="Last Name"
      dirtPasteNormalizer
      required
      validateName
      [disabled]="isFieldDisabled('lastName')"
      *diMarker="{ name: 'lastName', entity, area }"
    />
  </div>

  <!-- Suffix -->
  <div class="form-group">
    <label class="col-form-label" for="middleName">Suffix</label>
    <select
      id="suffix"
      name="suffix"
      class="form-control"
      [(ngModel)]="model.suffix"
      [disabled]="isFieldDisabled('suffix')"
    >
      <option [ngValue]="undefined">&mdash; Suffix &mdash;</option>
      <option *ngFor="let suffix of suffixes" [value]="suffix.code">
        {{ suffix.title }}
      </option>
    </select>
  </div>

  <!-- Country (workflow - if required) -->
  <div class="form-group" *ngIf="'MANDATORY' === displayCountry">
    <label class="col-form-label" for="middleName">Country (Workflow)</label>
    <select
      id="countryWorkflow"
      name="countryWorkflow"
      class="form-control"
      [(ngModel)]="model.countryWorkflow"
      [disabled]="isFieldDisabled('countryWorkflow')"
      [required]="true"
    >
      <option
        *ngFor="let country of countries"
        [value]="country.code"
        [disabled]="disableDisabledCountries && country.disabled"
      >
        {{ country.title }}
      </option>
    </select>
  </div>

  <!-- CV Link -->
  <div class="form-group">
    <label class="col-form-label">CV Link</label>
    <div class="input-group cvLinks" *ngFor="let cvLink of model.cvLinks; let idx = index; trackBy: trackByIndex">
      <dirt-link
        [(url)]="model.cvLinks[idx]"
        name="cvLinks-{{ idx }}"
        [form]="ngForm"
        [disabled]="isFieldDisabled('cvLinks')"
        [required]="true"
      >
        <button
          type="button"
          class="btn btn-secondary"
          (click)="pushItemToList(model.cvLinks)"
          *ngIf="idx == model.cvLinks.length - 1 && !disabled && idx < multiCvLink - 1 && !isFieldDisabled('cvLinks')"
        >
          <i class="fa fa-plus-circle"></i>
        </button>

        <button
          type="button"
          class="btn btn-secondary"
          (click)="removeFromByIndex(model.cvLinks, idx)"
          *ngIf="idx > 0 && !disabled && !isFieldDisabled('cvLinks')"
        >
          <i class="fa fa-minus-circle"></i>
        </button>
      </dirt-link>
    </div>
  </div>

  <!-- Source -->
  <div class="form-group">
    <label class="col-form-label">Source</label>
    <select
      class="form-control custom-select"
      name="source"
      [(ngModel)]="model.source"
      required
      [disabled]="isFieldDisabled('source')"
    >
      <option *ngFor="let source of sources" [value]="source.value" [disabled]="source.disabled">
        {{ source.value | capitalize }}
      </option>
    </select>
  </div>

  <!-- Delivery Projects -->
  <div class="form-group">
    <label class="col-form-label">Delivery Projects</label>
    <br />
    <dirt-multiselect-dropdown
      name="projectsDelivery"
      [options]="projectsDelivery"
      [settings]="projectsSettings"
      [required]="true"
      [disabled]="isFieldDisabled('projectsDelivery')"
      [ngModel]="projectNamesDelivery"
      (ngModelChange)="onProjectTagChangeForDelivery($event)"
    >
    </dirt-multiselect-dropdown>
  </div>

  <!-- Technical Projects -->
  <div class="form-group">
    <label class="col-form-label">Technical Projects</label>
    <br />
    <dirt-multiselect-dropdown
      name="projectsTechnical"
      [options]="projectsTechnical"
      [settings]="projectsSettings"
      [required]="true"
      [disabled]="isFieldDisabled('projectsTechnical')"
      [ngModel]="projectNamesTechnical"
      (ngModelChange)="onProjectTagChangeForTechnical($event)"
    >
    </dirt-multiselect-dropdown>
  </div>
</form>
