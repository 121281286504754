<div class="di-fields-values-step">
  <form>
    <div *ngFor="let field of values$ | async; trackBy: trackByItem" class="form-group row">
      <div class="di-label-wrapper col-sm-4">
        <div class="form-check">
          <input
            id="di-field-{{ field.config.field }}"
            class="form-check-input ng-trim-ignore"
            type="checkbox"
            value="option1"
            tabindex="-1"
            [hidden]="field.config.hideCheck"
            [ngModel]="field.check"
            (ngModelChange)="onCheckChange(field.config.field)"
            [ngModelOptions]="{ standalone: true }"
          />
          <label
            class="form-check-label"
            tabindex="-1"
            for="di-field-{{ field.config.field }}"
            [ngClass]="{ 'di-disable': !field.check }"
          >
            {{ field.config.label }}
            <span *ngIf="field.config.tooltip" [title]="field.config.tooltip" class="fa fa-info"></span>
          </label>
        </div>
      </div>
      <div class="col-sm-8" [ngSwitch]="field.config.type">
        <input
          type="text"
          class="form-control ng-trim-ignore"
          *ngSwitchCase="FieldType.string"
          [disabled]="!field.check"
          [ngModel]="field.value"
          (ngModelChange)="onFieldValueChange(field.config.field, $event)"
          [ngModelOptions]="{ standalone: true }"
          [required]="field.config.required && field.check"
          tabindex="-1"
        />
        <select
          class="form-control custom-select ng-trim-ignore"
          *ngSwitchCase="FieldType.select"
          [ngModel]="field.value"
          (ngModelChange)="onFieldValueChange(field.config.field, $event)"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="!field.check"
          [required]="field.config.required && field.check"
          tabindex="-1"
        >
          <option *ngIf="!field.check"></option>
          <option *ngFor="let value of getValues(field) | iterable" [value]="value.key">{{ value.value }}</option>
        </select>
        <ng-select
          *ngSwitchCase="FieldType.multiSelect"
          [disabled]="!field.check"
          [required]="field.config.required && field.check"
          [multiple]="true"
          [ngModel]="field.value"
          [ngModelOptions]="{ standalone: true }"
          (ngModelChange)="onFieldValueChange(field.config.field, $event)"
          tabindex="-1"
        >
          <ng-option *ngFor="let value of getValues(field) | iterable" [value]="value.key">{{ value.value }}</ng-option>
        </ng-select>
        <dirt-user-select
          [disabled]="!field.check"
          *ngSwitchCase="FieldType.user"
          [selectedUser]="field.value"
          [roles]="field.config.roles"
          [required]="field.config.required && field.check"
          [requiresEu]="hasEu"
          (selectedUserChange)="onFieldValueChange(field.config.field, $event)"
        >
        </dirt-user-select>
        <dirt-multi-user-select
          [disabled]="!field.check"
          *ngSwitchCase="FieldType.multiUser"
          [selectedUsers]="field.value"
          [roles]="field.config.roles"
          [required]="field.config.required && field.check"
          [requiresEu]="hasEu"
          (selectedUsersChange)="onFieldValueChange(field.config.field, $event)"
        >
        </dirt-multi-user-select>
        <span *ngSwitchDefault>Not supported</span>
      </div>
    </div>
  </form>
</div>
