export class AffiliationAdvancedSearchDto {
  filter: {
    name?: string;
    originalName?: string;
    department?: string;
    state?: string;
    city?: string;
    street?: string;
    zip?: string;
    countryCode?: string;
    type?: string;
  };
  sort?: string;
  sortDesc?: boolean;
  page?: number;
  size?: number;
}
