import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';
import { ProjectTagsComponent } from './project-tags.component';
import { ProjectTagsListComponent } from './list/list.component';
import { ProjectTagsModule } from './project-tags.module';

export const projectTagsRoutes: Routes = [
  {
    path: 'project-tags',
    component: ProjectTagsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        component: ProjectTagsListComponent,
        data: { acl: 'projectTags.list' },
      },
    ],
  },
];

export const projectTagsRouting: ModuleWithProviders<ProjectTagsModule> = RouterModule.forChild(projectTagsRoutes);
