import { DocumentConnection } from './connection';

export class Document {
  _id: string;
  title: string;
  type: string;
  category: string;
  country: string;
  format: string;
  publisherNotFound: boolean;
  publisher: string;
  publishedDate: Date;
  effectiveStartDate: Date;
  effectiveEndDate: Date;
  plannedReviewDate: Date;
  url: string;
  sourceUrl: string;
  documentVersion: string;
  previousVersion?: string;
  connectionType: string;
  connections: DocumentConnection[];
  priority: number;
  _meta: {
    status: string;
    assignee?: string;
    jobId?: string;
    claimedUntil?: Date;
  };
  createdAt: Date;
  updatedAt: Date;
  _version: number;
  identify?: { by: string; at: Date };
  mapping?: { by: string; at: Date };
  readyForDelivery?: boolean;

  peopleCount?: number; // only in list view

  status: string;
  autoFormat?: boolean;
}
