import { Component, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ValueFormatterParams } from 'ag-grid/dist/lib/entities/colDef';
import { ProfileMatchManual } from '../../constant/match-manual.enum';
import { QCCommentService } from '../qc-comment/qc-comment.service';
import { isEmpty } from 'lodash';

@Component({
  selector: 'di-match-manual-cell',
  templateUrl: 'match-manual-renderer.component.html',
  styleUrls: ['match-manual-renderer.component.scss'],
})
export class ProfileGridMatchManualRendererComponent implements ICellRendererAngularComp {
  @ViewChild('trainingPopover') popover: NgbPopover;
  public params: any;
  report: any;
  comment: any;

  agInit(params: any): void {
    this.params = params;

    // For matches coming from from outbox, id is computed client side, it is not the Mongo ID
    if (this.params?.getReport && (this.params?.data?.mongoId || this.params?.data?.id)) {
      this.report = this.params.getReport(this.params.data.mongoId || this.params.data.id);
    }

    if (
      this.params &&
      this.params.data &&
      this.params.data._profile &&
      this.params.data._profile.qc &&
      this.params.data._profile.qc.comment
    ) {
      if (isEmpty(this.params.data._profile.qc.comment)) {
        delete this.params.data._profile.qc; // `deleting` ithe comment field to make the QC bug icon disappear
      }
    }
  }

  constructor(private svcQCComment: QCCommentService) {}

  refresh(): boolean {
    return false;
  }

  /**
   * Formatter for manual match cell.
   *
   * @param {ValueFormatterParams} param
   * @return {string}
   */
  formatValue(param: ValueFormatterParams): string {
    if ('PB' === this.params.data?._profile?.match?.automate) {
      return '-';
    }
    switch (param.value) {
      case ProfileMatchManual.MATCH:
        return 'Yes';

      case ProfileMatchManual.MISMATCH:
        return 'No';

      default:
        return '';
    }
  }

  updateReport(val) {
    if (!this.params.updateReport) {
      return;
    }
    this.report = this.params.updateReport(this.report.id, val);
    this.popover.close();
  }

  openPopOver($event) {
    $event.stopPropagation();
    if (this.params && this.params.hasTrainingFeaturesEnabled()) {
      this.popover.open();
    }
  }

  openEditAlertBox() {
    if (this.params && this.params.updateQCComments) {
      this.svcQCComment
        .open(this.params.data?._profile?.qc?.comment, this.params.data?._profile?.qc?.errorType)
        .then((result) => {
          if (result) {
            this.params.updateQCComments(
              result.comment,
              this.params.value === 'MATCH' ? true : false,
              result.errorType
            );
          }
        })
        .catch(() => {});
    }
  }

  deleteQCComment() {
    let commentDelete = window.confirm('Are you sure want to delete?');
    if (!commentDelete) {
      return;
    }
    if (this.params && this.params.updateQCComments) {
      // this is to solve this bug reported bug: https://drive.google.com/file/d/1a2Bo5DQpRqAmieeP77QoiAEn-Mpuxqzc/view?usp=sharing
      const qcComment = this.params.data?._profile?.qc?.comment;
      let profileMatchedValue: boolean;
      if (
        qcComment &&
        qcComment.includes('->') &&
        (qcComment.split('->')[0].toLocaleLowerCase() === 'match' ||
          qcComment.split('->')[0].toLocaleLowerCase() === 'mismatch')
      ) {
        profileMatchedValue = this.params.value !== 'MATCH';
      } else {
        profileMatchedValue = this.params.value === 'MATCH';
      }

      this.params.updateQCComments(null, profileMatchedValue, null);
    }
  }
}
