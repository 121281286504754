<section class="di-affiliation-people" *ngIf="!isLoading || !!people.length || !!peopleLfka.length">
  <div class="card">
    <div class="card-body">
      <h6 class="card-title" *ngIf="!!people.length">Attached People</h6>

      <ol *ngIf="!!people.length">
        <li *ngFor="let person of people">
          <div class="pull-right">
            <span class="small">{{ person._meta.status | capitalize }}</span>
          </div>

          <!-- Name -->
          <a [routerLink]="['/person/detail', person.id]" rel="noopener" target="_blank"
            >{{ person.firstName }} {{ person.lastName }}</a
          >

          <!-- Full Name -->
          <span *ngIf="person.originalFullName">({{ person.originalFullName }})</span>

          <!-- Primary -->
          <div class="badge badge-primary" *ngIf="isPrimaryAffiliation(person.affiliations)">Primary</div>
        </li>
      </ol>

      <h6 class="card-title" *ngIf="!!peopleLfka.length">Attached People (LFKA)</h6>

      <ol *ngIf="!!peopleLfka.length">
        <li *ngFor="let person of peopleLfka">
          <div class="pull-right">
            <span class="small">{{ person._meta.status | capitalize }}</span>
          </div>

          <!-- Name -->
          <a [routerLink]="['/person/detail', person.id]" rel="noopener" target="_blank"
            >{{ person.firstName }} {{ person.lastName }}</a
          >

          <!-- Full Name -->
          <span *ngIf="person.originalFullName">({{ person.originalFullName }})</span>

          <!-- Primary -->
          <div class="badge badge-primary" *ngIf="isPrimaryAffiliation(person.affiliationsLfka)">Primary</div>
        </li>
      </ol>

      <div *ngIf="isLoadingStarted && !isLoading && !people.length && !peopleLfka.length">
        Not used in People so far
      </div>
    </div>
  </div>
</section>

<!-- Loading Message -->
<p class="dirt-loading" *ngIf="isLoading">
  <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
  <span class="sr-only">Loading...</span>
</p>
