import { Component } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

import { ACL } from '../../shared/acl/acl.service';
import { Committee } from '../shared/committee';
import { CommitteeAPI } from '../shared/committee-api.service';

@Component({
  selector: 'dirt-committee-create',
  templateUrl: './create.component.html',
})
export class CommitteeCreateComponent {
  committee: Committee = new Committee();

  isSubmitting = false;

  isFormValid: boolean;

  constructor(private router: Router, private svcCommittee: CommitteeAPI, private svcACL: ACL) {}

  goBack(): void {
    const link = ['/committee'];
    this.router.navigate(link);
  }

  async onSubmit(committee: Committee): Promise<void> {
    if (!this.svcACL.hasCredential('committee.create') || !committee || !this.isFormValid) {
      return;
    }

    await this.saveCommittee(committee);
    this.goBack();
  }

  onFormValidityChange(status: string): void {
    this.isFormValid = status === 'VALID';
  }

  private async saveCommittee(committee: Committee): Promise<any> {
    committee._meta = {} as any;

    this.isSubmitting = true;

    return await firstValueFrom(this.svcCommittee.create(committee).pipe(tap(() => (this.isSubmitting = false))));
  }
}
