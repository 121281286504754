<div class="modal-header">
  <h5 class="modal-title">
    Enter <b>{{ options.title }} Details</b>
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form #frmVideo="ngForm" class="form-group">
    <label for="source">Source</label>
    <div class="form-group" *ngIf="options.sourceSet">
      <select
        [disabled]="model.id"
        class="form-control custom-select"
        name="source"
        title="Source"
        [(ngModel)]="model.source"
        (ngModelChange)="onSourceChange()"
        required
      >
        <option [ngValue]="undefined" hidden>Select a source</option>
        <option *ngFor="let s of options.sourceSet" [value]="s">{{ s }}</option>
      </select>
    </div>

    <div class="form-group">
      <label for="vendorId">Video Id</label>
      <a
        href="javascript:void(0)"
        class="di-timid"
        ngbTooltip="Extract from Copied URL"
        (click)="onClipboardPaste($event)"
      >
        <i class="fa fa-clipboard" aria-hidden="true"></i>
      </a>
      <div class="input-group">
        <input
          class="form-control"
          name="vendorId"
          (focusout)="validateVideoId()"
          [ngModelOptions]="{ updateOn: 'blur' }"
          [(ngModel)]="model.vendorId"
          autocomplete="false"
          [disabled]="model.id || !model.source"
          required
        />
        <div class="input-group-append">
          <button class="btn btn-secondary">
            <i
              class="fa fa-check"
              aria-hidden="true"
              [hidden]="!model.vendorId || isValidatingVideoId || isVideoIdInvalid !== false"
            ></i>
            <i class="fa fa-circle-o-notch fa-spin fa-fw" [hidden]="!model.vendorId || !isValidatingVideoId"></i>
            <i
              class="fa fa-exclamation-circle text-danger"
              [hidden]="!model.vendorId || !isVideoIdInvalid || isValidatingVideoId"
            ></i>
          </button>
        </div>
      </div>
    </div>

    <label *ngIf="model.id" for="compilerComment">Comment</label>
    <div class="form-group" *ngIf="model.id">
      <select
        class="form-control custom-select"
        name="compilerComment"
        title="Comment"
        [(ngModel)]="model.compilerComment"
      >
        <option value="">empty</option>
        <option value="not_relevant">Not relevant</option>
        <option value="to_remove">To remove</option>
      </select>
    </div>

    <div class="form-group">
      <label for="title">Title</label>
      <input
        id="name"
        [required]="model.source === 'ECANCER'"
        class="form-control"
        name="title"
        [(ngModel)]="model.title"
      />
    </div>

    <div class="form-group">
      <label for="thumbnailUrl">Thumbnail URL</label>
      <div class="input-group">
        <input
          class="form-control"
          name="thumbnailUrl"
          (focusout)="validateThumbnailurl()"
          [(ngModel)]="model.thumbnailUrl"
          autocomplete="false"
          [ngModelOptions]="{ updateOn: 'blur' }"
        />
        <div class="input-group-append">
          <button class="btn btn-secondary">
            <i
              class="fa fa-check"
              aria-hidden="true"
              [hidden]="!model.thumbnailUrl || isValidatingThumbnailUrl || isThumbnailUrlInvalid !== false"
            ></i>
            <i
              class="fa fa-circle-o-notch fa-spin fa-fw"
              [hidden]="!model.thumbnailUrl || !isValidatingThumbnailUrl"
            ></i>
            <i
              class="fa fa-exclamation-circle text-danger"
              [hidden]="!model.thumbnailUrl || !isThumbnailUrlInvalid || isValidatingThumbnailUrl"
            ></i>
          </button>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label for="description">Description</label>
      <input
        id="name"
        [required]="model.source === 'ECANCER'"
        class="form-control"
        name="description"
        [(ngModel)]="model.description"
      />
    </div>

    <div class="form-group">
      <div class="row">
        <div class="col">
          <label for="publicationDate">Publication Date</label>
        </div>
        <div class="col">
          <ngb-datepicker
            [required]="model.source === 'ECANCER'"
            name="publicationDate"
            #dp
            [(ngModel)]="model.publicationDate"
            [startDate]="model.publicationDate"
            [minDate]="minDate"
            [maxDate]="maxDate"
          >
          </ngb-datepicker>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="submit" class="btn btn-sm btn-secondary" (click)="activeModal.dismiss()">Cancel</button>

  <button
    type="submit"
    class="btn btn-sm btn-success"
    [ngClass]="{ 'btn-loading': isSubmitting }"
    (click)="onSubmit(false)"
    [disabled]="
      !valid ||
      isValidatingVideoId ||
      isVideoIdInvalid ||
      isThumbnailUrlInvalid ||
      isValidatingThumbnailUrl ||
      isSubmitting
    "
  >
    Add
  </button>

  <button
    *ngIf="!model.id"
    type="submit"
    class="btn btn-sm btn-success"
    [ngClass]="{ 'btn-loading': isSubmitting }"
    (click)="onSubmit(true)"
    [disabled]="
      !valid ||
      isValidatingVideoId ||
      isVideoIdInvalid ||
      isThumbnailUrlInvalid ||
      isValidatingThumbnailUrl ||
      isSubmitting
    "
  >
    Add and new
  </button>
</div>
