import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { GuidelineComponent } from './guideline.component';
import { guidelineRouting } from './guideline.routes';
import { GuidelineListComponent } from './list/list.component';
import { GuidelineAPI } from './shared/guideline-api.service';
import { YearFilterComponent } from './shared/filters/year-filter/year-filter.component';
import { GuidelineCreateComponent } from './create/create.component';
import { GuidelineDetailComponent } from './detail/detail.component';
import { GuidelineFormComponent } from './shared/form/form.component';
import { CommentModule } from '../comment/comment.module';
import { GuidelineAuthorModule } from '../guideline-author/guideline-author.module';
import { GuidelineNextComponent } from './next/next.component';
import { OcrHelperModule } from '../ocr-helper/ocr-helper.module';
import { GuidelineQcNextComponent } from './qc-next/qc-next.component';
import { SubMenuFilterComponent } from './shared/filters/sub-menu-filter/sub-menu-filter.component';

@NgModule({
  imports: [SharedModule, CommentModule, GuidelineAuthorModule, guidelineRouting, OcrHelperModule],
  declarations: [
    GuidelineComponent,
    GuidelineListComponent,
    YearFilterComponent,
    GuidelineCreateComponent,
    GuidelineDetailComponent,
    GuidelineFormComponent,
    GuidelineNextComponent,
    GuidelineQcNextComponent,
    SubMenuFilterComponent,
  ],
  exports: [GuidelineNextComponent, GuidelineQcNextComponent],
  providers: [GuidelineAPI],
})
export class GuidelineModule {}
