import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, Subject, take } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { format } from 'date-fns';

import { Profile } from '../shared/profile';
import { ProfileAPI } from '../shared/api.service';
import { ProfileFilterComponent } from '../shared/filter/filter.component';
import { filterBarConfig } from '../shared/config/filter-bar-config';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { Value } from '../../shared/services/value/value';
import { buildBulkUpdateConfig } from '../shared/config/bulk-update-config';
import { BulkUpdateOptions } from '../../common/bulk-update/bulk-update-options';
import { DateRange } from '../../shared/components/date-range/date-range.component';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { BulkModalComponent, BULK_MODAL_OPTIONS } from '../../common/bulk/component/bulk-modal/bulk-modal.component';
import { Person } from '../../person/shared/person';

@Component({
  selector: 'dirt-profile-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class ProfileListComponent implements OnInit, OnDestroy {
  profiles: Profile[];

  isLoading = false;

  total: { count: number };
  pagingPage = 1;
  pagingLimit = 50;
  pagingSkip = 0;

  sort: string;
  filter: any;
  filterBarConfig = {
    ...filterBarConfig,
    polishedAt: {
      // don't interfere w/ shared config that can't take closures
      label: 'Polished at',
      format: (value: DateRange) => {
        if (!value.isNotSet) {
          return `${format(value.start, 'yyyy/MM/dd')} - ${format(value.end, 'yyyy/MM/dd')}`;
        }

        return `Never Polished`;
      },
    },
  };

  private autoTags: string[] = [];

  private deliveryProjectTags: Value[] = [];

  private technicalProjectTags: Value[] = [];

  @ViewChild(ProfileFilterComponent, { static: true }) filterRef: ProfileFilterComponent;

  searchTerm: string;
  searchCtrl: FormControl = new FormControl('');

  countryValues: Value[] = [];

  bulkUpdateConfig: BulkUpdateOptions<ProfileAPI, Profile>;

  bulkListDelegate: BulkDelegate = {
    title: 'Bulk Import',
    type: BulkType.LIST,
    specialHint: 'Bulk upload Profile Clinical Trials',
    specificStartFct: this.handleBulkListUpload.bind(this),
  };

  videoJobBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import',
    type: BulkType.LIST,
    specialHint: 'Bulk upload Profile Video Jobs',
    specificStartFct: this.handleProfileVideoJobsBulkListUpload.bind(this),
  };

  profileBulkListDelegate: BulkDelegate = {
    title: 'Profile Bulk Import',
    type: BulkType.LIST,
    specialHint: 'Bulk upload Profile',
    specificStartFct: this.handleProfileBulkListUpload.bind(this),
  };

  jobBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import Job',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListJobUpload.bind(this),
    specificNotifyReloadFct: this.getProfiles.bind(this),
  };

  wndw: Window = window;

  private destroy$: Subject<boolean> = new Subject();

  constructor(private svcModal: NgbModal, private svcProfile: ProfileAPI, private svcValue: ValueAPI) {}

  ngOnInit() {
    this.bulkUpdateConfig = buildBulkUpdateConfig(this.svcProfile);
    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), distinctUntilChanged(), debounceTime(400))
      .subscribe((val) => {
        // search field gets fired with `undefined` value after init
        if (typeof val !== 'undefined') {
          this.onSearch(val);
        }
      });

    forkJoin([
      this.svcProfile.polishers(),
      this.svcValue.find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER, 0, '+title'),
      this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title'),
    ]).subscribe(([polishersList, projects, countries]) => {
      this.filterBarConfig = Object.assign({}, this.filterBarConfig, {
        '_meta.assignee': {
          label: 'Polishers',
          format: (value) => {
            const user = polishersList.find((o) => o.user_id === value);
            if (user) {
              return `${user.user_metadata.firstName} ${user.user_metadata.lastName}`;
            } else {
              return value;
            }
          },
        },
        'person.projectNames': {
          label: 'Projects',
          format: (value) => {
            return (projects.find((o) => o.code === value) || { title: '' }).title;
          },
        },
        'person.affiliation.countryCode': {
          label: 'Country',
          format: (value) => {
            return (countries.find((o) => o.code === value) || { title: '' }).title;
          },
        },
      });

      this.countryValues = countries;
      this.autoTags = projects.filter((project) => !!project.autoTag).map((project) => project.code as string);
      this.deliveryProjectTags = projects.filter((project) => !!project.delivery);
      this.technicalProjectTags = projects.filter((project) => !!project.technical);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  getCountryTitle(code: string): string {
    return (this.countryValues.find((o) => o.code === code) || { title: '' }).title;
  }

  async getProfiles(): Promise<void> {
    this.isLoading = true;

    this.svcProfile.find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.filter).subscribe({
      next: (resp) => (this.profiles = resp),
      error: null,
      complete: () => (this.isLoading = false),
    });
  }

  getCount() {
    delete this.total;

    this.svcProfile
      .count(this.searchTerm, this.filter, true) // (optional=true: server excludes heavy count when restricted)
      .subscribe((res) => (this.total = res));
  }

  getPage(page: number) {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getProfiles();
  }

  resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  onSort(field: string): void {
    this.sort = field;
    this.resetPagination();
    this.getProfiles();
  }

  onSearch(name: string): void {
    this.resetPagination();
    this.getProfiles();
    this.getCount();
  }

  setFilter(filter: any): void {
    this.filter = filter;

    this.resetPagination();
    this.getProfiles();
    this.getCount();
  }

  onFilterClear() {
    this.filterRef.doClear();
  }

  onFilterValueRemove(item: { key: string; value: any }) {
    this.filterRef.removeValue(item.key, item.value);
  }

  onBulkUpdateRefresh() {
    this.getProfiles();
  }

  isAutoProject(profile: Profile) {
    if (!profile.person || !profile.person.projectNames) {
      return;
    }
    return profile.person.projectNames.some((item) => this.autoTags.indexOf(item) > -1);
  }

  onOpenBulkListDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.bulkListDelegate;
  }

  onOpenProfileBulkListDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.profileBulkListDelegate;
  }

  private handleBulkListUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcProfile.startClinicalTrialsBulkListUpload(file, secondEyes);
  }

  getDelList(person: Person) {
    return person.projectNames.reduce((projects, project) => {
      const title = this.deliveryProjectTags.find((t) => t.code === project)?.title;
      if (title) {
        projects.push(title);
      }
      return projects;
    }, []);
  }

  getTechList(person: Person) {
    return person.projectNames.reduce((projects, project) => {
      const title = this.technicalProjectTags.find((t) => t.code === project)?.title;
      if (title) {
        projects.push(title);
      }
      return projects;
    }, []);
  }

  onOpenProfileVideoJobsBulkListDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.videoJobBulkListDelegate;
  }

  public onExport(): void {
    this.svcProfile
      .downloadProfilesFile(this.filter, this.searchTerm)
      .pipe(take(1))
      .subscribe((profilesBlob) => {
        const downloadLink = document.createElement('a');

        const dateTime = new Date().toLocaleString();
        downloadLink.setAttribute('download', `profiles_list_${dateTime}.xlsx`);
        downloadLink.setAttribute('href', URL.createObjectURL(profilesBlob));
        downloadLink.click();

        downloadLink.parentElement.removeChild(downloadLink);
      });
  }

  private handleProfileVideoJobsBulkListUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcProfile.startProfileVideoJobsBulkListUpload(file, secondEyes);
  }

  private handleProfileBulkListUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcProfile.startProfileBulkListUpload(file, secondEyes);
  }

  onOpenBulkListJobDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.jobBulkListDelegate;
  }
  private handleBulkListJobUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcProfile.startBulkListJobUpload(file, secondEyes);
  }
}
