import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'dirt-yearpicker',
  templateUrl: 'yearpicker.component.html',
  styleUrls: ['yearpicker.component.scss'],
})
export class YearpickerComponent implements OnInit {
  @Input()
  year: string;

  @Input()
  minOffset = 20;

  @Input()
  maxOffset = 0;

  @Input()
  disabled: boolean;

  @Input()
  placeholder: string;

  currentYear: number = new Date().getFullYear();

  years: string[] = [];

  constructor() {}

  ngOnInit() {
    this.getYears();
  }

  getYears() {
    for (let i = this.currentYear - this.minOffset; i <= this.currentYear + this.maxOffset; i++) {
      this.years.push(i.toString());
    }
  }

  getYear(): string {
    return this.year;
  }

  setYear(year: string) {
    this.year = year;
  }
}
