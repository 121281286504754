<form novalidate>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchEventName" title="Event name">Event Name</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchEventName"
        name="searchEventName"
        class="form-control"
        [(ngModel)]="model.filter.name"
        placeholder="Event Name"
      />
    </div>
  </div>

  <div class="form-group row" *ngIf="!hideSessionFilters">
    <label class="col-form-label col-sm-4 col-md-2" for="searchOriginalName" title="Original Name">Original Name</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchOriginalName"
        name="searchOriginalName"
        class="form-control"
        [(ngModel)]="model.filter.originalName"
        placeholder="Original Name"
      />
    </div>
  </div>

  <div class="form-group row" *ngIf="!hideSessionFilters">
    <label class="col-form-label col-sm-4 col-md-2" for="organizer">Organizer</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="organizer"
        name="organizer"
        class="form-control"
        [(ngModel)]="model.filter.organizer"
        placeholder="Organizer"
      />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Start Date Min</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="date"
        id="startDate"
        class="form-control"
        [(ngModel)]="model.filter.startDate.start"
        name="startDateStart"
      />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Start Date Max</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="date"
        id="startDate"
        class="form-control"
        [(ngModel)]="model.filter.startDate.end"
        name="startDateEnd"
      />
    </div>
  </div>
</form>
