<div class="modal-header">
  <div>
    <h4 class="modal-title" id="modal-basic-title">{{ delegate.title }}</h4>

    <div *ngIf="delegate.specialHint">
      <small>{{ delegate.specialHint }}</small>
    </div>
  </div>
</div>

<div class="modal-body">
  <div class="form-group" #bulkListDialog>
    <form (submit)="(false)" novalidate>
      <input type="file" class="form-control" [disabled]="isWorking" #bulkListFile /><br />

      <div>
        <label for="secondEyes">I certify that above file was reviewed and approved by</label>

        <input
          id="secondEyes"
          class="form-control"
          [ngClass]="secondEyes ? 'ng-valid' : 'ng-invalid'"
          type="text"
          [value]="secondEyes || ''"
          [disabled]="isWorking"
          required
          (input)="secondEyes = $event.target.value"
        />
      </div>

      <div class="forminfo">{{ progress }}</div>

      <div class="forminfo" *ngIf="isComplete && !!filenameFeedback">
        <a class="alink" (click)="onFeedbackDownload()">Download feedback...</a>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-outline-dark" (click)="onClose()">Close</button>
  <button class="btn btn-outline-dark" [disabled]="isWorking" (click)="onSubmit()">Submit</button>
</div>
