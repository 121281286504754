import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AffiliationMergeComponent } from './merge.component';
import { AffiliationMergeInfoComponent } from './merge-info.component';
import { Affiliation } from '../../affiliation';
import { AffiliationMergeDirectionComponent } from './merge-direction.component';

@Injectable()
export class AffiliationMergeService {
  constructor(private svcModal: NgbModal) {}

  open(options: { id: string; title: string }): Promise<any> {
    const ref = this.svcModal.open(AffiliationMergeComponent);

    ref.componentInstance.options = options;

    return ref.result;
  }

  openInfo(options: {
    success?: boolean;
    msg?: string;
    pairs?: { existId: string; existCaption: string; mergeId: string; mergeCaption: string; merged: boolean }[];
  }): Promise<any> {
    const ref = this.svcModal.open(AffiliationMergeInfoComponent);

    ref.componentInstance.options = options;

    return ref.result;
  }

  openMergeDirection(left: Affiliation, right: Affiliation): Promise<any> {
    const ref = this.svcModal.open(AffiliationMergeDirectionComponent);

    ref.componentInstance.left = left;
    ref.componentInstance.right = right;

    return ref.result;
  }
}
