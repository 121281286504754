<span [ngClass]="{wide: wide}">
  <input
    class="di-search form-control form-control-sm ng-trim-ignore"
    type="search"
    [ngClass]="{ loading: isSearching }"
    [ngbTypeahead]="searchValues"
    [resultTemplate]="searchResultTemplate"
    (selectItem)="onSelect($event)"
    [(ngModel)]="searchTerm"
    [required]="required"
    [placeholder]="code || ''"
    aria-label="Search"
  />
</span>

<ng-template #searchResultTemplate let-r="result" let-t="term">
  <ng-container> {{r.value}} </ng-container>
</ng-template>
