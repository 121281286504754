import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Auth } from '../../shared/services/auth/auth.service';
import { Observable, Subscriber } from 'rxjs';

@Injectable()
export class UserLoginGuard implements CanActivate {
  constructor(private router: Router, private readonly svcAuth: Auth) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let url = '/';

    return Observable.create((o: Subscriber<boolean>) => {
      this.svcAuth.resolveToken().subscribe(() => {
        // authenticated user doesn't need to land on login page anymore!
        if (this.svcAuth.isAuthenticated()) {
          // redirect user back to the same page
          if (Auth.redirectUrl) {
            url = Auth.redirectUrl;
            Auth.redirectUrl = null;
          }

          // router.navigate will encode the provided URL breaking query params if any (resulting in 500) as it adds them multiple time.
          // Using navigateByUrl instead avoids that issue: https://github.com/angular/angular/issues/28917
          this.router.navigateByUrl(url, /* Removed unsupported properties by Angular migration: fragment. */ {});
          o.next(false);
        } else {
          o.next(true);
        }

        o.complete();
      });
    });
  }
}
