import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AccountPayerInformation } from './account-payer-infomation';
import { APIService } from '../../shared/services/api/api.service';
import {
  GET,
  MediaType,
  Produces,
  Query,
  POST,
  Body,
  Path,
  PATCH,
  DELETE,
} from '../../shared/services/rest/rest.service';

@Injectable()
export class AccountPayerInformationAPI extends APIService {
  @GET('account-payer-information/')
  @Produces(MediaType.JSON)
  public find(
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('accountId') accountId?: string
  ): Observable<AccountPayerInformation[]> {
    return;
  }

  @GET('account-payer-information/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<AccountPayerInformation> {
    return;
  }

  @GET('account-payer-information/count')
  @Produces(MediaType.JSON)
  public count(@Query('accountId') accountId?: string): Observable<any> {
    return;
  }

  @POST('account-payer-information')
  @Produces(MediaType.JSON)
  public create(@Body accountPayerInformation: AccountPayerInformation): Observable<any> {
    return;
  }

  @PATCH('account-payer-information/{id}')
  @Produces(MediaType.JSON)
  public update(
    @Path('id') id: string,
    @Body accountPayerInformation: Partial<AccountPayerInformation>
  ): Observable<any> {
    return;
  }

  @DELETE('account-payer-information/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }
}
