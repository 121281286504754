<button
  class="dirt-bulk-update-btn btn btn-secondary btn-sm"
  [ngbPopover]="popoverContent"
  [autoClose]="true"
  placement="bottom-right"
>
  <i class="fa {{ icon }}" aria-hidden="true" *ngIf="icon"></i>
  <span>{{ title }}</span>
  <i class="fa fa-sort-down" aria-hidden="true"></i>
</button>

<ng-template #popoverContent>
  <div class="list-group list-group-flush">
    <ng-content></ng-content>
  </div>
</ng-template>
