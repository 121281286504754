import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Directive, forwardRef, Input } from '@angular/core';
import { firstValueFrom, map, Observable, of } from 'rxjs';

import { ValueAPI } from '../services/value/value-api.service';
import { DomainAPI } from '../../domain/shared/domain-api.service';

interface DomainComplianceValidatorOptions {
  /** If provided, will only validate URL format and not URL compliance */
  validateFormatOnly?: boolean;

  /** If provided, will check for domains with matching type in the values collection */
  type?: string;
}

@Directive({
  selector: '[nonCompliantDomainValidator]',
  providers: [{ provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => DomainComplianceValidator), multi: true }],
})
export class DomainComplianceValidator implements AsyncValidator {
  @Input()
  set nonCompliantDomainValidator(options: DomainComplianceValidatorOptions | null) {
    this.options = options;
  }

  private options?: DomainComplianceValidatorOptions;

  private ongoingPromise: Promise<ValidationErrors>;

  private previousValue: string;

  private previousReturn?: ValidationErrors;

  private protocolRegexp = new RegExp(/(^\w+:|^)\/\//);

  private validProtocolRegexp = new RegExp(/^((http(s)?)|((s)?ftp)):\/\/.+$/);

  constructor(private readonly svcValue: ValueAPI, private readonly svcDomain: DomainAPI) {}

  async validate(control: AbstractControl): Promise<ValidationErrors | null> {
    const controlValue = control.value;
    if (this.ongoingPromise && controlValue === this.previousValue) {
      return this.ongoingPromise; // the system unsubscribes previous observables - without this part, we're stuck with illegal values not marked
    }

    if (controlValue === this.previousValue) {
      // don't waste time checking what was already checked - return previous result
      return this.previousReturn;
    }

    this.previousValue = controlValue;
    this.previousReturn = null;

    try {
      this.ongoingPromise = firstValueFrom(this.validateImpl(controlValue)); // returning an unfulfilled Promise doesn't triggers it again where returning an observable will re-subscribe to it
      this.ongoingPromise.then((value) => {
        this.ongoingPromise = null;
        this.previousReturn = value;
      });
    } catch (error) {
      // otherwise, we're stuck in error
      this.ongoingPromise = null;
      this.previousValue = null;
      this.previousReturn = null;

      throw error;
    }

    return this.ongoingPromise;
  }

  private validateImpl(value?: string): Observable<ValidationErrors | null> {
    if (!value) {
      return of(null);
    }

    // Make sure it's http or ftp and their secure variants. No protocol = http
    if (this.protocolRegexp.test(value) && !this.validProtocolRegexp.test(value)) {
      return of({ 'non-compliant': true });
    }

    const domain = value
      .replace(this.protocolRegexp, '') // remove protocols if any
      .replace(/^w{3}\./, ''); // remove "www." as it's a special case (www.example.com = example.com)

    try {
      new URL(`https://${domain}`);
    } catch (error) {
      return of({ 'non-compliant': true }); // likely not a proper URL - don't send garbage inputs
    }

    if (this.options?.validateFormatOnly) {
      // that's it, only check that it's a proper URL
      return of(null);
    }

    if (this.options?.type) {
      // Some have their own rules, like activities
      return this.svcValue
        .getURLCompliance(domain, this.options.type)
        .pipe(map((res) => (res.valid ? null : { 'non-compliant': true })));
    }

    return this.svcDomain.validate(domain).pipe(map((res) => (res.valid ? null : { 'non-compliant': true })));
  }
}
