import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router, PRIMARY_OUTLET } from '@angular/router';
import { first, tap, distinctUntilChanged } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

import { ProfileViewMode } from '../constant/view-mode.enum';
import { ProfileStatus } from '../constant/status.enum';
import { ProfileTag } from '../constant/profile-tag.enum';
import { UserGroupAPI } from '../../../user-group/shared/api.service';
import { ProfileAPI } from '../api.service';
import { User } from '../../../user/shared/user';
import { Utils } from '../../../common/utils';
import { Value } from '../../../shared/services/value/value';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { DateRange } from '../../../shared/components/date-range/date-range.component';
import { CompilerComment } from '../../../video/shared/video';

@Component({
  selector: 'dirt-profile-filter',
  templateUrl: 'filter.component.html',
  styleUrls: ['filter.component.scss'],
})
export class ProfileFilterComponent implements OnInit {
  visiblePanel: any;
  filter: any;

  @Output() onFilter = new EventEmitter<any>();

  @ViewChild('filterPopover', { static: true })
  filterPopover: NgbPopover;

  countryValues: Value[] = [];
  priorityList = [0, 1, 2, 3, 4, 5];
  videoCompilerComment = [
    {
      value: 'NOT_RELEVANT',
      key: 'not_relevant',
    },
    {
      value: 'TO_REMOVE',
      key: 'to_remove',
    },
  ];

  searchCtrl: FormControl = new FormControl('');

  errorMessage: string;
  visibleSubPanel: any;
  hasSubPanel: Boolean;
  panelVal: string;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private svcUserGroup: UserGroupAPI,
    private svcProfile: ProfileAPI,
    private svcValue: ValueAPI
  ) {}

  ngOnInit() {
    this.restoreFromQueryParams();

    this.svcValue
      .find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER, 0, '+title', { technical: false })
      .toPromise()
      .then((data) => {
        this.projectsDeliveryList = data;
      });
    this.svcValue
      .find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER, 0, '+title', { technical: true })
      .toPromise()
      .then((data) => {
        this.projectsTechnicalList = data;
      });

    this.svcValue
      .find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.countryValues = data;
      });

    this.searchCtrl.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => this.onSearch(val));
    this.searchCtrl.reset(this.completePolishersList);
  }

  getCountryTitle(code: string): string {
    return (this.countryValues.find((o) => o.code === code) || { title: '' }).title;
  }

  /**
   * Show the popup panel
   */
  onShow(): void {
    // reset panel visibility status
    this.visiblePanel = false;
  }

  /**
   * Set the active filter panel
   *
   * @param panel
   */
  setPanel(panel?: string, hasSubPanel?: boolean): void {
    this.visiblePanel = panel;
    this.errorMessage = undefined; // reset error message
    this.hasSubPanel = hasSubPanel ? true : false;
    this.visibleSubPanel = '';
    if (panel) {
      // uppercase first letter
      const name = panel.charAt(0).toUpperCase() + panel.slice(1).toLowerCase();
      const initMethod = `init${name}`;
      if (this[initMethod]) {
        this[initMethod]();
      }
    }
  }

  setSubPanel(subPanel?: string, panelVal?: string): void {
    this.visibleSubPanel = subPanel;
    this.panelVal = panelVal;
    this.hasSubPanel = false;
  }

  private addSetValues<T>(set: Set<T>, value: T | T[]) {
    if (!value) {
      return;
    }
    if (Array.isArray(value)) {
      value.forEach((v) => {
        set.add(v);
      });
    } else {
      set.add(value);
    }
  }

  /**
   * Apply the filter
   */
  doApply(): void {
    if (this.errorMessage) {
      return;
    }

    // add countries in a region as selected counties
    this.selectedRegionList.forEach((region) => {
      this.regionLocationMap.get(region).forEach((l) => this.selectedLocations.add(l));
    });

    this.filter = {
      viewMode: Array.from(this.selectedModes),
      '_meta.status': Array.from(this.selectedStatuses),
      'person.affiliation.countryCode': Array.from(this.selectedLocations),
      'person.projectNames': Array.from(this.selectedProjects),
      '_meta.assignee': Array.from(this.selectedPolishers),
      polishedAt: Object.keys(this.selectedPolishedAt).filter((k) => !!this.selectedPolishedAt[k]).length
        ? this.selectedPolishedAt
        : undefined,
      verified: this.selectedVerified,
      tags: Array.from(this.selectedTags),
      priority: Array.from(this.selectedPriorities),
      videoCompilerComment: Array.from(this.selectedVideosCompilerComment),
    };

    // remove empty values
    Object.keys(this.filter).forEach((key) => {
      const value = this.filter[key];

      if (typeof value === 'undefined' || (Array.isArray(value) && value.length === 0)) {
        delete this.filter[key];
      }
    });

    this.filterPopover.close();
    this.storeAsQueryParams();
    this.onFilter.emit(this.filter);
  }

  /**
   * Clear the active filter
   */
  doClear(): void {
    this.selectedLocations.clear();
    this.selectedModes.clear();
    this.selectedProjects.clear();
    this.selectedStatuses.clear();
    this.selectedPolishers.clear();
    this.selectedPolishedAt = {};
    this.selectedRegionList.clear();
    this.selectedPriorities.clear();

    delete this.selectedVerified;
    this.selectedTags.clear();

    delete this.filter;
    this.onFilter.emit();
    this.filterPopover.close();

    // Clear query params
    this.router.navigate([]);
  }

  /**
   * Hide the filter popup without applying the changes
   */
  doCancel(): void {
    this.errorMessage = undefined;
    this.filterPopover.close();
    this.setPanel();
  }

  isFilterSet(): boolean {
    return !!Object.keys(this.filter || {}).length;
  }

  /**
   * Store applied filters as route query params
   */
  storeAsQueryParams(): void {
    const filters = Object.keys(this.filter).reduce((filter, key) => {
      const value = this.filter[key];
      if (Utils.isLiteralObject(value)) {
        Object.keys(value).forEach((oKey) => {
          const oValue = value[oKey];
          if (oValue) {
            filter[oKey] = oValue instanceof Date ? oValue.toJSON() : oValue;
          }
        });
      } else {
        filter[key] = value;
      }
      return filter;
    }, {});

    this.router.navigate([], { queryParams: filters });
  }

  /**
   * Restore filters saved as query params
   */
  restoreFromQueryParams(): void {
    const tree = this.router.parseUrl(this.router.url);
    const segmentGroup = tree.root.children[PRIMARY_OUTLET];
    if (segmentGroup?.segments?.map((it) => it.path).join('/') !== 'profile/list') {
      // do not needlessly restore filters in pages that don't need it
      return;
    }

    this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      this.addSetValues(this.selectedModes, params.viewMode);
      this.addSetValues(this.selectedStatuses, params['_meta.status']);
      this.addSetValues(this.selectedLocations, params['person.affiliation.countryCode']);
      this.addSetValues(this.selectedProjects, params['person.projectNames']);
      this.addSetValues(this.selectedPolishers, params['_meta.assignee']);
      this.addSetValues(this.selectedPriorities, params['priority']);
      this.addSetValues(this.selectedTags, params['tags']);
      this.addSetValues(this.selectedVideosCompilerComment, params['videoCompilerComment']);
      this.selectedVerified = params.verified;

      if (params.start) {
        this.selectedPolishedAt = {
          start: new Date(params.start),
          end: new Date(params.end),
          isNotSet: false,
        };
      } else if (params.isNotSet) {
        // params.start have priority
        this.selectedPolishedAt = {
          start: undefined,
          end: undefined,
          isNotSet: true,
        };
      } else {
        this.selectedPolishedAt = {};
      }

      this.doApply();
    });
  }

  removeValue(key: string, value: any) {
    switch (key) {
      case 'viewMode':
        this.selectedModes.delete(value);
        break;
      case '_meta.status':
        this.selectedStatuses.delete(value);
        break;
      case 'person.affiliation.countryCode': {
        this.selectedLocations.delete(value);
        this.selectedRegionList.clear();
        break;
      }
      case 'person.projectNames':
        this.selectedProjects.delete(value);
        break;
      case '_meta.assignee':
        this.selectedPolishers.delete(value);
        break;
      case 'polishedAt':
        this.selectedPolishedAt = {};
        break;
      case 'tags':
        this.selectedTags.delete(value);
        break;
      case 'verified':
        delete this.selectedVerified;
        break;
      case 'priority':
        this.selectedPriorities.delete(value);
        break;
      case 'videoCompilerComment':
        this.selectedVideosCompilerComment.delete(value);
        break;
    }
    this.doApply();
  }

  // LOCATION
  locationList: string[];
  isLoadingLocations = false;
  private selectedLocations: Set<string> = new Set();

  initLocation(): void {
    if (this.locationList) {
      return;
    }

    this.isLoadingLocations = true;

    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe({
      next: (countries) => {
        this.locationList = countries.map((c) => c.code as string);
        this.isLoadingLocations = false;
      },
      error: () => (this.isLoadingLocations = false),
    });
  }

  toggleLocation(loc: string): void {
    if (this.selectedLocations.has(loc)) {
      this.selectedLocations.delete(loc);
    } else {
      this.selectedLocations.add(loc);
    }
  }

  isLocationSelected(loc: string): boolean {
    return this.selectedLocations.has(loc);
  }

  getSelectedLocations(): Array<string> {
    const locations: Set<string> = new Set(this.selectedLocations);
    this.selectedRegionList.forEach((region) => this.regionLocationMap.get(region).forEach((l) => locations.add(l)));
    return Array.from(locations);
  }

  // REGION
  regionList: string[];
  regionLocationMap: Map<string, string[]> = new Map();
  isLoadingRegions = false;
  private selectedRegionList: Set<string> = new Set();

  initRegion(): void {
    if (this.regionList) {
      return;
    }

    this.isLoadingRegions = true;

    this.svcUserGroup.find(Number.MAX_SAFE_INTEGER).subscribe(
      (resp) => {
        this.regionList = resp.map((u) => u.name).sort();
        resp.forEach((u) => this.regionLocationMap.set(u.name, u.countries));
      },
      null,
      () => (this.isLoadingRegions = false)
    );
  }

  toggleRegion(region: string): void {
    this.selectedLocations.clear();
    if (this.selectedRegionList.has(region)) {
      this.selectedRegionList.delete(region);
    } else {
      this.selectedRegionList.add(region);
    }
  }

  isRegionSelected(region: string): boolean {
    return this.selectedRegionList.has(region);
  }

  // VIEW MODE
  modeList = ProfileViewMode;
  private selectedModes: Set<string> = new Set();

  toggleMode(mode: string): void {
    if (this.selectedModes.has(mode)) {
      this.selectedModes.delete(mode);
    } else {
      this.selectedModes.add(mode);
    }
  }

  isModeSelected(mode: string): boolean {
    return this.selectedModes.has(mode);
  }

  // STATUS
  statusList = ProfileStatus;
  private selectedStatuses: Set<string> = new Set();

  toggleStatus(status: string): void {
    if (this.selectedStatuses.has(status)) {
      this.selectedStatuses.delete(status);
    } else {
      this.selectedStatuses.add(status);
    }
  }

  isStatusSelected(status: string): boolean {
    return this.selectedStatuses.has(status);
  }

  // PROJECTS
  projectsTechnicalList: Value[] = [];
  projectsDeliveryList: Value[] = [];
  projectTagList = ['delivery', 'technical'];

  private selectedProjects: Set<string> = new Set();

  filterOnProjectType(): any[] {
    if (this.panelVal === 'technical') {
      return this.projectsTechnicalList;
    } else if (this.panelVal === 'delivery') {
      return this.projectsDeliveryList;
    }
  }

  toggleProjects(status: string): void {
    if (['NON_AUTOMATED', 'AUTOMATED_PLUS_DELIVERY', 'AUTOMATED_NO_DELIVERY', 'LFKA_ONLY'].includes(status)) {
      const hasStatus = this.selectedProjects.has(status);

      this.selectedProjects.clear(); // We only want one of NON_AUTOMATED, AUTOMATED_PLUS_DELIVERY, AUTOMATED_NO_DELIVERY or LFKA_ONLY when either is selected
      if (!hasStatus) {
        // allow deselection using clear
        this.selectedProjects.add(status);
      }
    } else if (
      !this.selectedProjects.has('NON_AUTOMATED') &&
      !this.selectedProjects.has('AUTOMATED_PLUS_DELIVERY') &&
      !this.selectedProjects.has('AUTOMATED_NO_DELIVERY') &&
      !this.selectedProjects.has('LFKA_ONLY')
    ) {
      if (this.selectedProjects.has(status)) {
        this.selectedProjects.delete(status);
      } else {
        this.selectedProjects.add(status);
      }
    }
  }

  isProjectSelected(value: string): boolean {
    return this.selectedProjects.has(value);
  }

  maxPeriod = 0;

  // POLISHERS
  polishersList: User[];
  completePolishersList: User[];
  isLoadingPolishers = false;
  private selectedPolishers: Set<string> = new Set();

  initPolishers(): void {
    if (this.polishersList) {
      return;
    }

    this.isLoadingPolishers = true;
    this.svcProfile
      .polishers()
      .pipe(tap(() => (this.isLoadingPolishers = false)))
      .subscribe((resp) => {
        this.polishersList = resp;
        this.completePolishersList = [...this.polishersList];
      });
  }

  togglePolishedBy(reviewedBy: string, $event: MouseEvent): void {
    $event.preventDefault();

    if (this.selectedPolishers.has(reviewedBy)) {
      this.selectedPolishers.delete(reviewedBy);
    } else {
      this.selectedPolishers.add(reviewedBy);
    }
  }

  isPolishedBySelected(reviewedBy: string): boolean {
    return this.selectedPolishers.has(reviewedBy);
  }

  // PolishedAt
  selectedPolishedAt: DateRange = {};

  setPolishedAt(value: DateRange): void {
    this.selectedPolishedAt = value;
  }

  // Verified
  private selectedVerified: boolean;

  toggleVerified(verified: boolean): void {
    if (typeof this.selectedVerified === 'undefined') {
      this.selectedVerified = verified;
    } else if (this.selectedVerified !== verified) {
      this.selectedVerified = verified;
    }
  }

  isVerifiedSelected(verified: boolean): boolean {
    return this.selectedVerified === verified;
  }
  // TAG
  tagList: string[];
  isLoadingTags = false;
  private selectedTags: Set<string> = new Set();

  initTag(): void {
    this.tagList = Object.keys(ProfileTag);
  }

  toggleTag(loc: string): void {
    if (this.selectedTags.has(loc)) {
      this.selectedTags.delete(loc);
    } else {
      this.selectedTags.add(loc);
    }
  }

  isTagSelected(loc: string): boolean {
    return this.selectedTags.has(loc);
  }

  //PriorityList
  private selectedPriorities: Set<string> = new Set();

  isPrioritySelected(priority: string): boolean {
    return this.selectedPriorities.has(priority);
  }
  togglePriority(priority: string): void {
    if (this.selectedPriorities.has(priority)) {
      this.selectedPriorities.delete(priority);
    } else {
      this.selectedPriorities.add(priority);
    }
  }

  onSearch(polisher: string): void {
    if (polisher)
      this.polishersList = this.completePolishersList.filter((val) => {
        return (
          val.user_metadata &&
          (val.user_metadata.firstName.toLowerCase().includes(polisher.toLowerCase()) ||
            val.user_metadata.lastName.toLowerCase().includes(polisher.toLowerCase()) ||
            val.name.toLowerCase().includes(polisher.toLowerCase()))
        );
      });
    else this.polishersList = this.completePolishersList;
  }

  onFilterError(message: string): void {
    this.errorMessage = message;
  }

  //Video compiler comment
  private selectedVideosCompilerComment: Set<string> = new Set();

  isVideosCompilerCommmentSelected(comment: CompilerComment): boolean {
    return this.selectedVideosCompilerComment.has(comment);
  }
  toggleCompilerComment(comment: CompilerComment): void {
    if (this.selectedVideosCompilerComment.has(comment)) {
      this.selectedVideosCompilerComment.delete(comment);
    } else {
      this.selectedVideosCompilerComment.add(comment);
    }
  }
}
