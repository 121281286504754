<section class="dirt-section-list dirt-sentiment-list">
  <header class="row">
    <div class="col-sm-8">
      <h1>Sentiments</h1>
    </div>
    <div class="col-sm-4">
      <div class="pull-right">
        <dirt-bulk-list-button *diAcl="'sentiment.bulkList'" [delegate]="bulkListDelegate"></dirt-bulk-list-button>
      </div>
    </div>
  </header>

  <div class="pull-right">
    <!-- Dashboards -->
    <dirt-button-dropdown [title]="'Dashboards'" [icon]="'fa-tachometer'" *diAcl="'sentiment.dashboards'">
      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/sentiment-base/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Sentiment Base
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/sentiment-performance/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Sentiment Performance
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/sentiment-subject-curation/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Sentiment Subject Curation
      </a>
    </dirt-button-dropdown>
  </div>
</section>
