export class PersonAdvancedSearchDto {
  filter: {
    firstName?: string;
    includeNickname?: boolean;
    middleName?: string;
    lastName?: string;
    includeAlternativeName?: string;
    country?: string;
    projectName?: string;
    specialty?: string;
    degree?: string;
    metaStatus?: string;
  };
  sort?: string;
  sortDesc?: boolean;
  page?: number;
  size?: number;
}
