import { Component, Input, OnInit, ViewChild, Output, TemplateRef } from '@angular/core';
import { firstValueFrom, Observable, of, Subject } from 'rxjs';
import { groupBy, toPairs, find } from 'lodash';
import { catchError, debounceTime, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

import { FormList } from '../../../shared/interfaces/form-list.interface';
import { ACL } from '../../../shared/acl/acl.service';
import { AffiliationAPI } from '../../../affiliation/shared/api.service';
import { PersonAffiliation } from '../../../person/shared/person-affiliation';
import { Value } from '../../../shared/services/value/value';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { Roles } from '../../../shared/acl/roles';
import { CapitalizePipe } from '../../pipes';
import { PersonAffiliationTakeoverStrategy } from './takeover-strategy.enum';

@Component({
  selector: 'dirt-affiliations',
  templateUrl: 'affiliations.component.html',
  styleUrls: ['affiliations.component.scss'],
})
export class PersonAffiliationsComponent implements OnInit, FormList {
  @Input()
  affiliations: Array<PersonAffiliation> = [];

  @Input()
  mandatoryBecauseOfStatus: boolean = true;

  @Input()
  lfkaOnlyProject: boolean = false;

  @Input()
  disabled: boolean;

  @Input()
  currentJobType?: string;

  @Input()
  currentQcArea?: string;

  @Input()
  requestOwnerProduct?: string;

  @Input()
  createRequestHandler: (
    sourceItem?: PersonAffiliation,
    disableNameField?: boolean,
    requestOwnerProduct?: string
  ) => Observable<PersonAffiliation> = null;

  @Input()
  maintenanceRequestHandler: (id, requestOwnerProduct?: string) => any = null;

  @Output()
  primaryChanged: Subject<PersonAffiliation> = new Subject();

  @ViewChild(NgbTypeahead, { static: false })
  searchAutocomplete: NgbTypeahead;

  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  expanded = new Set<string>();
  expandedDetail: { [_id: string]: boolean } = {};
  addedByCurrentUser = new Set<string>();
  searchTerm: string;
  isSearching = false;
  searchedOnce = false;
  searchTimeOutForPos: any;
  // Positions
  positions: Value[] = [];

  countryValues: Value[] = [];

  groupedAffiliations: Array<{
    name: string;
    originalName?: string;
    isInactiveAfg?: boolean;
    affiliations?: Array<PersonAffiliation>;
    inactiveAffiliations?: Array<{
      name: string;
      originalName?: string;
      isInactiveAfg?: boolean;
      affiliations?: Array<PersonAffiliation>;
    }>;
  }> = [];

  isProfileCompiler: boolean;

  canCreateAffiliation: boolean;

  takeoverStrategy = PersonAffiliationTakeoverStrategy.REQUESTED_BECOMES_PRIMARY; // Majority of the cases
  affiliationTakeoverStrategy = PersonAffiliationTakeoverStrategy;

  private inScopeCountries: string[] = [];

  @ViewChild('takeoverStrategyModal')
  private takeoverStrategyModal: TemplateRef<any>;

  searchAffiliations = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) =>
        this.svcAffiliation.search(term, 100).pipe(
          // tap(() => this.searchFailed = false),
          catchError(() => {
            // this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.isSearching = false)),
      tap(() => (this.searchedOnce = true))
    );

  constructor(
    private readonly svcACL: ACL,
    private readonly svcAffiliation: AffiliationAPI,
    private svcValue: ValueAPI,
    private capitalizePipe: CapitalizePipe,
    private svcModal: NgbModal
  ) {}

  ngOnInit() {
    this.isProfileCompiler = this.svcACL.hasRole(Roles.ProfileCompiler);
    this.canCreateAffiliation = this.svcACL.hasCredential('affiliation.create', this.currentJobType);

    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.countryValues = data;
    });

    this.svcValue.find(ValueType.InScopeCountry, Number.MAX_SAFE_INTEGER).subscribe((values) => {
      this.inScopeCountries = values.map((v) => v.code as string);
    });

    this.groupAffiliations();
    this.groupedAffiliations
      .filter((afg) => this.hasPrimaryAffiliation(afg))
      .forEach((afg) => this.expanded.add(afg.name));
  }

  groupAffiliations() {
    // so far: no blend in of LFKA as readonly or sth (same goes vice versa)
    this.groupedAffiliations = [
      ...toPairs(
        groupBy(
          this.affiliations.filter((a) => !a.inactive),
          'name'
        )
      )
        .map(([name, affiliations]) => ({
          name,
          originalName: (find(affiliations, ['name', name]) || { originalName: '' }).originalName,
          affiliations: affiliations.sort(
            (af1, af2) =>
              (!af1.department ? -100 : !af2.department ? 100 : 0) +
              (af1.primary ? -10 : af2.primary ? 10 : 0) +
              Math.sign((af1.department || '').localeCompare(af2.department || ''))
          ),
        }))
        .sort(
          (afg1, afg2) =>
            (this.hasPrimaryAffiliation(afg1) ? -10 : this.hasPrimaryAffiliation(afg2) ? 10 : 0) +
            Math.sign((afg1.name || '').localeCompare(afg2.name || ''))
        ),
      ...(this.affiliations.filter((a) => a.inactive).length > 0
        ? [
            {
              name: 'Inactive affiliations',
              isInactiveAfg: true,
              inactiveAffiliations: [
                ...toPairs(
                  groupBy(
                    this.affiliations.filter((a) => a.inactive),
                    'name'
                  )
                ).map(([name, affiliations]) => ({
                  name,
                  originalName: (find(affiliations, ['name', name]) || { originalName: '' }).originalName,
                  affiliations: affiliations.sort(
                    (af1, af2) =>
                      (!af1.department ? -100 : !af2.department ? 100 : 0) +
                      (af1.primary ? -10 : af2.primary ? 10 : 0) +
                      Math.sign((af1.department || '').localeCompare(af2.department || ''))
                  ),
                })),
              ],
            },
          ]
        : []),
    ];
    //  console.log('this.groupedAffiliations', this.groupedAffiliations);
  }

  isPositionDetailOpen(afg, af) {
    // remember but initialize - similar to main open/collapse
    if (this.expandedDetail[af.id] === undefined) {
      // either primary or empty position
      this.expandedDetail[af.id] = af.primary; // was also open if primary - no good UX
    }
    return this.expandedDetail[af.id];
  }
  setPositionDetailOpen(af, open) {
    this.expandedDetail[af.id] = open;
  }

  hasPrimaryAffiliation(afg) {
    return afg.affiliations
      ? afg.affiliations.filter((a) => a.primary).length > 0
      : this.inactiveHasPrimaryAffiliation(afg);
  }

  inactiveHasPrimaryAffiliation(afg) {
    return afg.inactiveAffiliations.some((elm) => {
      return elm.affiliations.filter((a) => a.primary).length > 0;
    });
  }

  hasPrimaryCandidateAffiliation(afg): boolean {
    return (
      !!afg.affiliations?.find((a) => a.primaryCandidate) ||
      afg.inactiveAffiliations?.some((elm) => !!elm.affiliations.find((a) => a.primaryCandidate))
    );
  }

  hasUnpolishedAffiliation(afg): boolean {
    return (
      !!afg.affiliations?.find((a) => !a.readyForDelivery) ||
      afg.inactiveAffiliations?.some((elm) => !!elm.affiliations.find((a) => !a.readyForDelivery))
    );
  }

  getPositionTitle(code: string): string {
    return (this.positions.find((o) => o.code === code) || { title: '' }).title;
  }
  getCountryTitle(code: string): string {
    return (this.countryValues.find((o) => o.code === code) || { title: '' }).title;
  }

  isValid(): boolean {
    let isValid = true;

    // Make sure that all active affiliations are from in-scope countries
    if (!this.disabled && this.ngForm.status !== 'DISABLED') {
      isValid &&= (this.affiliations?.filter((a) => this.isOutOfScopeCountry(a))?.length || 0) === 0;
    }

    if (
      this.svcACL.hasCredential('person.update.mandatory.prop.affiliations', this.currentJobType) &&
      this.mandatoryBecauseOfStatus
    ) {
      // Form statuses are mutually exclusive, so a control cannot be invalid AND disabled (https://angular.io/api/forms/AbstractControl#status)
      isValid &&= this.affiliations.length > 0 && (this.ngForm.status === 'DISABLED' || this.ngForm.form.valid);
    }

    return isValid;
  }

  isRequired(): boolean {
    return (
      this.affiliations.length < 1 &&
      this.svcACL.hasCredential('person.update.mandatory.prop.affiliations', this.currentJobType) &&
      this.mandatoryBecauseOfStatus
    );
  }

  getListItems(): any[] {
    return this.affiliations; /* on the server (upsert): .map(af => ({
      id: af.id,
      primary: af.primary,
      lastKnown: af.lastKnown,
      inactive: af.inactive,
      position: af.position,
      originalPosition: af.originalPosition
    })); */
  }

  async onSelect(event: any): Promise<void> {
    event.preventDefault();

    if (!this.inScopeCountries.includes(event.item.address.countryCode)) {
      alert('People must never be actively affiliated with an out-of-scope country organization.');
      return;
    }

    if (
      !event.item.readyForDelivery &&
      !confirm(
        "You are adding an affiliation not polished yet by central organizations. The person will remain hidden from customers until central organizations' work is completed. Use anyway?"
      )
    ) {
      return;
    }

    await this.addAffiliation(event.item);

    // clean up
    this.searchTerm = '';
    this.searchedOnce = false;
    this.searchAutocomplete.dismissPopup();
  }

  removeAffiliation(e: Event, id: string) {
    e.stopPropagation();

    const idx = this.affiliations.findIndex((af) => af.id === id);

    if (this.affiliations[idx].primary) {
      alert('Unable to delete! Select a new primary address and retry');
      return;
    }

    if (!confirm('Sure to delete the affiliation?')) {
      return;
    }

    this.addedByCurrentUser.delete(this.affiliations[idx].id);
    this.affiliations.splice(idx, 1);

    this.syncPrimary();
    this.groupAffiliations();
  }

  toggleExpanded(name: string): void {
    const isExpanded = this.expanded.has(name);

    isExpanded ? this.expanded.delete(name) : this.expanded.add(name);
  }

  isExpanded(name: string): boolean {
    return this.expanded.has(name);
  }

  hasAnyLastKnown(): boolean {
    return this.affiliations.filter((_a) => _a.lastKnown).length > 0;
  }

  setPrimary(event: Event, id: string): void {
    event.stopPropagation();

    if (this.hasAnyLastKnown()) {
      return;
    }

    const newPrimary = this.affiliations.find((a) => a.id === id);
    if (!newPrimary) {
      return;
    }

    if (!newPrimary.readyForDelivery) {
      // Cannot mark it manually, see takeoverStrategy instead
      return;
    }

    if (!!this.affiliations.find((a) => !a.readyForDelivery)?.primaryCandidate) {
      alert('Cannot change primary affiliation while another affiliation is marked as primary candidate');
      return;
    }

    if (newPrimary.inactive) {
      delete newPrimary.inactive;
    }

    // sync primary state
    this.affiliations.forEach((a) => (a.primary = a.id === newPrimary.id));

    this.primaryChanged.next(newPrimary);
  }

  unsetPrimaryCandidate(id: string): void {
    const affiliation = this.affiliations.find((a) => a.id === id);
    if (!affiliation) {
      return;
    }

    if (
      !confirm(
        'By removing the primary candidate, affiliation will stay as secondary once polished by central ops team. Do you still want to remove the flag?'
      )
    ) {
      return;
    }

    affiliation.primaryCandidate = false;
    affiliation.lastKnownCandidate = false;
    affiliation.takeoverStrategy = PersonAffiliationTakeoverStrategy.REQUESTED_BECOMES_SECONDARY;
  }

  setLastKnown(event: Event, id: string): void {
    event.stopPropagation();

    const affiliation = this.affiliations.find((af) => af.id === id);
    if (!affiliation?.readyForDelivery) {
      // Just to be sure
      return;
    }

    if (!confirm('Sure to set this last known and all other affiliations inactive?')) {
      return;
    }

    this.affiliations.forEach((af) => {
      if (af.id === id) {
        af.primary = true;
        af.lastKnown = true;
        delete af.inactive;
      } else {
        delete af.primary;
        delete af.primaryCandidate;
        delete af.lastKnown;
        af.inactive = true;
      }
    });

    this.primaryChanged.next(this.affiliations.find((af) => af.primary));
  }

  setLastKnownCandidate(id: string): void {
    const affiliation = this.affiliations.find((af) => af.id === id);

    // Not possible to mark as last known when strategy is not for requested aff to become primary
    if (
      !affiliation?.readyForDelivery &&
      ![
        PersonAffiliationTakeoverStrategy.REQUESTED_BECOMES_PRIMARY,
        PersonAffiliationTakeoverStrategy.REQUESTED_BECOMES_PRIMARY_NO_DISABLE,
      ].includes(affiliation.takeoverStrategy as any)
    ) {
      return;
    }

    if (!confirm('Sure to set this last known candidate?')) {
      return;
    }

    this.affiliations.forEach((af) => {
      if (af.id === id) {
        af.primaryCandidate = true;
        af.lastKnownCandidate = true;
        delete af.inactive;
      } else {
        delete af.primaryCandidate;
        delete af.lastKnownCandidate;
        af.inactive = !af.primary; // We shouldn't disable the current primary just yet, we'll take care of that when swapping candidate with current primary depending on the selected strategy
      }
    });
  }

  unsetLastKnown(event: Event, id: string): void {
    // can always do it
    event.stopPropagation();

    this.affiliations.forEach((af) => {
      if (af.id === id) {
        delete af.lastKnown;
        delete af.lastKnownCandidate;
      }
    });
  }

  setInactive(event: Event, id: string) {
    event.stopPropagation();
    if (this.hasAnyLastKnown()) {
      return;
    }

    if (!this.canSetInactive()) {
      return;
    }

    const af = this.affiliations.find((o) => o.id === id);

    if (af) {
      af.inactive = !af.inactive;

      if (!af.inactive) {
        delete af.inactive;
      }
    }
  }

  trackById(idx, item: PersonAffiliation): string {
    return item.id;
  }

  showAffiliationModal(sourceItem?: PersonAffiliation, disableNameField?: boolean) {
    this.createRequestHandler(sourceItem, disableNameField, this.requestOwnerProduct).subscribe(async (result) => {
      if (result.id) {
        await this.addAffiliation(result, true);
      }
    });
  }

  onCopyClick(e: MouseEvent, sourceItem: PersonAffiliation) {
    e.stopPropagation();
    this.searchAutocomplete.dismissPopup();
    this.searchAutocomplete.handleBlur();
    this.showAffiliationModal(sourceItem, true);
  }

  canRemoveItem(affiliation: PersonAffiliation): boolean {
    if (affiliation.primary) {
      // can never remove primary
      return false;
    }

    if (!affiliation.readyForDelivery) {
      return (
        this.svcACL.hasCredential('person.update.prop.affiliations.remove', this.currentJobType) ||
        this.svcACL.hasCredential('person.update.prop.affiliations.removeRequest', this.currentJobType) ||
        this.addedByCurrentUser.has(affiliation.id)
      );
    }

    return (
      this.svcACL.hasCredential('person.update.prop.affiliations.remove', this.currentJobType) ||
      this.addedByCurrentUser.has(affiliation.id)
    );
  }

  canSetInactive(): boolean {
    return this.svcACL.hasCredential('person.update.prop.affiliations.inactive', this.currentJobType);
  }

  isFieldPositionDisabled(): boolean {
    if (this.disabled) {
      return true;
    }

    return !this.svcACL.hasCredential('person.update.prop.affiliations.position', this.currentJobType);
  }

  onOrgPositionKeyUp(af: PersonAffiliation) {
    const { originalPosition } = af;
    const delay = 300;
    // let value;

    if (!originalPosition || originalPosition.trim() === '') {
      af.position = undefined;
      return;
    }

    if (this.searchTimeOutForPos) {
      clearTimeout(this.searchTimeOutForPos);
    }
    this.searchTimeOutForPos = setTimeout(() => {
      this.svcAffiliation
        .searchPos(af.originalPosition)
        .toPromise()
        .then((data) => {
          af.position = data.position;
        });
    }, delay);
  }

  trimInput(af: PersonAffiliation) {
    const { originalPosition } = af;

    if (!originalPosition || originalPosition.trim() === '') {
      return;
    }
    af.originalPosition = originalPosition.trim();
  }

  private async addAffiliation(affiliation: PersonAffiliation, isNew?: boolean): Promise<void> {
    if (!affiliation.readyForDelivery) {
      const ref = this.svcModal.open(this.takeoverStrategyModal);
      await firstValueFrom(ref.closed);

      affiliation.takeoverStrategy = this.takeoverStrategy;
      affiliation.primaryCandidate = [
        PersonAffiliationTakeoverStrategy.REQUESTED_BECOMES_PRIMARY,
        PersonAffiliationTakeoverStrategy.REQUESTED_BECOMES_PRIMARY_NO_DISABLE,
      ].includes(affiliation.takeoverStrategy as any);
      this.takeoverStrategy = PersonAffiliationTakeoverStrategy.REQUESTED_BECOMES_PRIMARY; // Reset to default for next
    }

    // don't add the same affiliation multiple times
    if (this.affiliations.findIndex((item) => affiliation.id === item.id) < 0) {
      if (isNew) {
        affiliation.newlyCreated = true;
      }
      this.affiliations.push(affiliation);
      this.addedByCurrentUser.add(affiliation.id);
    }

    this.syncPrimary();
    this.groupAffiliations();
    this.expanded.add(affiliation.name);
  }

  private syncPrimary(): void {
    const primaryIdx = this.affiliations.findIndex((aff) => aff.primary);

    if (primaryIdx === -1 && this.affiliations.length > 0) {
      const firstItem = this.affiliations[0];
      if (firstItem.readyForDelivery) {
        firstItem.primary = true;
      } else {
        firstItem.primaryCandidate = true;
      }

      // always keep the new primary expanded
      this.expanded.add(firstItem.name);

      this.primaryChanged.next(firstItem);
    }
  }

  highlightFields(additionalClass: string) {
    additionalClass = additionalClass ? `form-control ${additionalClass || ''}` : `form-control`;

    return this.highlightFieldsForSearch() ? `form-focus ${additionalClass}` : additionalClass;
  }

  highlightFieldsForSearch() {
    return (
      this.svcACL.hasRole(Roles.ProfileModerator) ||
      this.svcACL.hasRole(Roles.ProfileModifier) ||
      this.svcACL.hasRole(Roles.ProfileCompiler)
    );
  }

  reportDuplicate(affiliation?: PersonAffiliation) {
    let origId = '';
    let origIdComplete = false;
    while (!origIdComplete) {
      origId = prompt('Please provide the id of the original (24-digits)', origId);
      if (null === origId) {
        return; // cancel
      }
      if (affiliation.id === origId) {
        continue; // the other one!
      }
      if (/[a-f0-9]{24}/.exec(origId)) {
        origIdComplete = true;
        break;
      }
    }
    this.svcAffiliation.duplicateSuspect(affiliation.id, { id: origId }).subscribe(
      (res) => {
        if (!res.ok) {
          alert(res.msg || 'Cannot mark as duplicate');
        } else {
          alert(res.msg || 'Thanks for letting us know');
        }
      },
      (error) => {
        alert('Error!');
      }
    );
  }

  onCopyAffiliationName(affiliation?: PersonAffiliation): void {
    const value =
      affiliation.name +
      (affiliation.department ? ` ${affiliation.department}` : '') +
      ` ${this.capitalizePipe.transform(affiliation.type)}` +
      (affiliation.primary ? ' primary' : '');
    navigator.clipboard.writeText(value);
  }

  isOutOfScopeCountry(affiliation: PersonAffiliation): boolean {
    return !affiliation.inactive && !this.inScopeCountries.includes(affiliation.address?.countryCode);
  }
}
