export enum ClinicalTrialProfileComparators {
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
  Lt = '<',
  LtE = '<=',
  Gt = '>',
  GtE = '>=',
  Eq = '=',
  Neq = '<>',
}
