<section class="dirt-profile-next">
  <div class="next-content">
    <!-- Loading Indicator -->
    <p [hidden]="!!queue">
      <i class="fa fa-refresh fa-spin fa-fw"></i>
      Loading your statistics...
    </p>

    <ul class="list-group" *ngIf="queue && queue.length">
      <p>Here's the number of Profiles in the queue waiting for you:</p>
      <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let stat of queue">
        {{ stat.status.split('_').join(' ') | capitalize }}
        <span class="badge badge-pill badge-secondary">{{ stat.count }}</span>
      </li>
    </ul>

    <p *ngIf="queue && !queue.length">
      <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>&nbsp; You have no pending Profile in your queue. Simply
      click the button to start working on a new one!
    </p>
  </div>

  <div class="next-action">
    <button type="button" class="btn btn-success" (click)="getNext()" [disabled]="isLoadingNext">
      <i class="fa fa-refresh fa-spin" [hidden]="!isLoadingNext"></i>
      Next Profile
    </button>
  </div>
</section>
