import { ACL } from '../acl/acl.service';

export type RegionRestrict = { value: { role: string; qualify: 'POS' | 'NEG'; countries: string[] } };

export function checkRegionRestrict(svcAcl: ACL, regionRestrict: RegionRestrict[], countryCode: string) {
  // does one of the restrictions apply
  for (let r of regionRestrict) {
    if ('POS' === r.value.qualify) {
      if (!svcAcl.hasRole(r.value.role) && r.value.countries.includes(countryCode)) {
        return false; // country is in the restriction list, but we don't have the role to pass
      } // (else we're OK for this one & can check next restriction)
    } else if ('NEG' === r.value.qualify) {
      if (svcAcl.hasRole(r.value.role) && r.value.countries.includes(countryCode)) {
        return false; // country is in the list for which this role has no access
      } // (else we're OK for this one & can check next restriction)
    }
  }
  // (else)
  return true;
}

export function checkRegionRestrictSimple(svcAcl: ACL, regionRestrict: RegionRestrict[]) {
  // could we (indep of country) fall under a restriction?
  for (let r of regionRestrict) {
    if ('POS' === r.value.qualify) {
      if (!svcAcl.hasRole(r.value.role)) {
        return false; // country might be in the restriction list, but we don't have the role to pass
      } // (else we're OK for this one & can check next restriction)
    } else if ('NEG' === r.value.qualify) {
      if (svcAcl.hasRole(r.value.role)) {
        return false; // country might be in the list for which this role has no access
      } // (else we're OK for this one & can check next restriction)
    }
  }
  // (else)
  return true;
}
