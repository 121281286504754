import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AclGuard } from '../shared/acl/guard.service';
import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { ClinicalTrialSponsorComponent } from './clinical-trial-sponsor.component';
import { ClinicalTrialSponsorDetailComponent } from './detail/detail.component';
import { ClinicalTrialSponsorListComponent } from './list/list.component';
import { ClinicalTrialSponsorModule } from './clinical-trial-sponsor.module';
import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';

const clinicalTrialSponsorRoutes: Routes = [
  {
    path: 'ct-sponsor',
    component: ClinicalTrialSponsorComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'detail/:id',
        component: ClinicalTrialSponsorDetailComponent,
      },
      {
        path: 'list',
        component: ClinicalTrialSponsorListComponent,
        data: { acl: 'clinicalTrialSponsor.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
  },
];

export const clinicalTrialSponsorRouting: ModuleWithProviders<ClinicalTrialSponsorModule> =
  RouterModule.forChild(clinicalTrialSponsorRoutes);
