import { Injectable } from '@angular/core';

import { BulkUpdateOptions } from './bulk-update-options';
import { BulkUpdateApi } from './bulk-update-api';
import { BulkUpdateModel } from './bulk-update.model';
import { BulkUpdateApiSimple } from './bulk-update-api-simple';

@Injectable()
export class BulkUpdateOptionsProvider {
  private _options: BulkUpdateOptions<BulkUpdateApi<BulkUpdateModel> | BulkUpdateApiSimple, BulkUpdateModel>;

  setOptions(options: BulkUpdateOptions<BulkUpdateApi<BulkUpdateModel> | BulkUpdateApiSimple, BulkUpdateModel>) {
    this._options = options;
  }

  getOptions(): BulkUpdateOptions<BulkUpdateApi<BulkUpdateModel> | BulkUpdateApiSimple, BulkUpdateModel> {
    return this._options;
  }
}
