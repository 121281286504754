import { Directive, EventEmitter, Output, Input } from '@angular/core';
import { AfterContentInit, ContentChildren, QueryList } from '@angular/core';
import { DiColumnSort } from './column-sorter.directive';
import { SortConfig } from './sort-cofig';

@Directive({
  selector: 'table[diSort]',
})
export class DiSort implements AfterContentInit {
  @Output() diSort = new EventEmitter<string>();
  @Input() sortConfig: SortConfig;

  @ContentChildren(DiColumnSort, {
    descendants:
      true /* If true include all descendants of the element. If false then only query direct children of the element. */,
  })
  private columns: QueryList<DiColumnSort>;

  ngAfterContentInit() {
    this.observeChanges();
    if (this.sortConfig && this.sortConfig.initialSortField) {
      this.setInitialSortOrder(this.sortConfig.initialSortField);
    }
  }

  doSort(column: DiColumnSort, field: string) {
    this.columns.forEach((col) => {
      // reset sort of other columns
      if (col !== column) {
        col.clearSort();
      }
    });
    this.diSort.emit(field);
  }

  observeChanges() {
    this.columns.forEach((column) => {
      if (this.sortConfig) {
        column.setSortConfig(this.sortConfig);
      }
      column.onSort.subscribe((field) => this.doSort.call(this, column, field));
    });
  }

  setInitialSortOrder(initialSortOrder: string) {
    const order = initialSortOrder.slice(0, 1);
    const columnName = initialSortOrder.slice(1);
    const column = this.columns.find((col) => col.fieldName === columnName);
    if (column) {
      column.setSortOrder(order);
    }
  }
}
