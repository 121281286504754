import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl } from '@angular/forms';

const ALLOW_EMPTY: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => AllowEmptyValidator),
  multi: true,
};

@Directive({
  selector: '[allowEmpty][formControlName],[allowEmpty][formControl],[allowEmpty][ngModel]',
  providers: [ALLOW_EMPTY],
})
export class AllowEmptyValidator implements Validator {
  validate(c: AbstractControl): { [key: string]: any } {
    return this.test(c);
  }

  public test(c: AbstractControl) {
    if (c.value === '') {
      return null;
    }
    return Validators.required(c);
  }
}
