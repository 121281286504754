import { Component, OnInit, Input, ViewChild, SimpleChanges, OnChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { NgbDateStruct, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { format } from 'date-fns';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map, delay, takeUntil } from 'rxjs/operators';

import { Guideline } from '../guideline';
import { Utils } from '../../../common/utils';
import { ACL } from '../../../shared/acl/acl.service';
import { Value } from '../../../shared/services/value/value';
import { GuidelineReach } from '../constant/reach.enum';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { AssociationAPI } from '../../../association/shared/association-api.service';
import { Association } from '../../../association/shared/association';
import { TranslateAPI } from '../../../shared/services/translate/translate.service';
import { GuidelineAPI } from '../guideline-api.service';
import { PublicationAPI } from '../../../publication/shared/publication-api.service';
import { IMultiSelectSettings } from '../../../shared/components/multiselect-dropdown/types';

const CLS_MULTISELECT_BTN = 'btn btn-sm btn-secondary';

@Component({
  selector: 'dirt-guideline-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  exportAs: 'frmGuideline',
})
export class GuidelineFormComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input('guideline')
  model: Guideline = new Guideline();

  @Input('disabled')
  disabled: boolean = false;

  longDash = Utils.longDash;

  projects: { id: string; name: string; disabled: boolean }[] = [];
  products: Value[];

  projectsSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    dynamicTitleMaxItems: 5,
  };

  categories: Value[] = [];

  reaches = GuidelineReach;

  countries: Value[] = [];

  fullTextLink: string;

  associations: Association[] = [];

  searchTerm: string;

  isLoadingAssociation: boolean;

  didSearch = false;

  minDate: NgbDateStruct;

  maxDate: NgbDateStruct;

  isPubMedIDInvalid: boolean;

  languages$: Observable<Value[]>;

  nonCompliantDomainType: string = ValueType.NonCompliantDomainsActivities;

  private countryMap: Map<string, string> = new Map();

  @ViewChild(NgForm, { static: true })
  private ngForm: NgForm;

  @ViewChild(NgbTypeahead, { static: false })
  private searchAutocomplete: NgbTypeahead;

  @ViewChild('pubMedIDInput', { static: false })
  private pubMedIDInput: NgModel;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private svcValue: ValueAPI,
    private svcAcl: ACL,
    private svcAssociation: AssociationAPI,
    private svcTranslation: TranslateAPI,
    private svcGuideline: GuidelineAPI,
    private svcPublication: PublicationAPI
  ) {
    this.onSearchAssociation = this.onSearchAssociation.bind(this);
  }

  ngOnInit(): void {
    const now = new Date();
    this.minDate = { year: now.getFullYear() - 20, month: 1, day: 1 };
    this.maxDate = { year: now.getFullYear() + 1, month: 12, day: 31 };

    if (this.model.associations?.length === 0) {
      this.model.associations.push('');
    }

    if (!Array.isArray(this.model.keywords)) {
      this.model.keywords = [];
    }
    if (this.model.keywords.length === 0) {
      this.model.keywords.push({
        keyword: '',
        majorTopic: null, // (so far: not used)
      });
    }

    if (!this.model.abstract) {
      this.model.abstract = {};
    }

    if (!this.model.journal) {
      this.model.journal = {};
    }

    forkJoin({
      projects: this.svcValue.find(ValueType.Project, Number.MAX_SAFE_INTEGER, 0, '+title'),
      categories: this.svcValue.find(ValueType.Category, Number.MAX_SAFE_INTEGER, 0, '+title'),
      countries: this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title'),
      products: this.svcValue.find(ValueType.Product, Number.MAX_SAFE_INTEGER, 0),
    }).subscribe((data) => {
      // TODO: This seriously needs to be addressed at one point, pulling fairly static values from the backend to always do the same transformation logic is a no go
      this.projects = data.projects?.map((p) => ({ id: p.code as string, name: p.title, disabled: false }));
      this.categories = data.categories;
      this.countries = data.countries;
      this.countryMap = new Map(data.countries?.map((value) => [value.code as string, value.title]));
      this.products = [
        ...data.products.filter((d) => 'LFTA' === d.value), // make sure it's first
        ...data.products.filter((d) => 'LFTA' !== d.value),
      ];
    });

    if (this.model.associationRefs.length > 0) {
      // don't send useless requests
      this.svcAssociation
        .find(null, this.model.associationRefs.length, 0, '+name', {
          id: this.model.associationRefs.map((ref) => ref.id),
        })
        .subscribe((associations) => {
          this.associations = this.getTransformedAssociations(associations);
        });
    }

    this.languages$ = this.svcValue.find(ValueType.Language, Number.MAX_SAFE_INTEGER, 0, '+title');
  }

  ngAfterViewInit(): void {
    this.pubMedIDInput.control.setValidators(() => {
      return this.isPubMedIDInvalid ? { id: true } : null;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['model']) {
      return;
    }

    this.fullTextLink = this.model.externalLinks?.find((link) => link.type === 'full text link')?.link;

    this.normalizeDates();
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  getValue(): Guideline {
    if (!this.isValid() || this.disabled) {
      return;
    }

    const model = Object.assign({}, this.model); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables

    delete model._version; // guideline isn't reloaded when an author is added, resulting in a conflict with the version

    model.publicationDate = this.ngbStructToDate(model.publicationDate as any);
    model.associationRefs = model.associationRefs.map((association) => ({
      id: association.id,
      name: association.name,
    })) as any;
    model.associations = model.associations.filter((association) => association !== '');
    model.externalLinks = [
      {
        link: this.fullTextLink,
        type: 'full text link',
      },
    ];
    // Compatibility with existing data
    model.types = ['Practice Guideline'];
    model.sources = ['Manual guideline extract'];

    this.associations = []; // Don't carry on associations from one guideline to the other when save & new

    return model;
  }

  isFieldEditable(field: string): boolean {
    const prefix = this.model.id ? 'update' : 'create';
    return this.svcAcl.hasCredential(`guideline.${prefix}.prop.${field}`) && !this.disabled;
  }

  isValid(): boolean {
    return this.ngForm.form.valid;
  }

  onSearchAssociation(term$: Observable<string>): Observable<Association[]> {
    return term$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => (this.isLoadingAssociation = true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }

        return this.svcAssociation.find(term, 100).pipe(
          map((associations: Association[]) => this.getTransformedAssociations(associations)),
          catchError(() => {
            return of([]);
          })
        );
      }),
      tap(() => (this.isLoadingAssociation = false)),
      tap(() => (this.didSearch = true))
    );
  }

  onSelectAssociation(event: any): void {
    event.preventDefault();

    if (this.disabled) {
      return;
    }

    // Don't add association multiple time
    if (this.model.associationRefs.find((association) => association.id === event.item.id)) {
      return;
    }

    this.model.associationRefs.push(event.item);
    this.associations.push(event.item); // association is already transformed at this point

    // clean up
    this.searchTerm = null;
    this.didSearch = false;
    this.searchAutocomplete.dismissPopup();
  }

  onAssociationDeleteClicked(id: string): void {
    if (this.disabled) {
      return;
    }

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.model.associationRefs = this.model.associationRefs.filter((association) => association.id !== id);
    this.associations = this.associations.filter((association) => association.id !== id);
  }

  onFullContentChange(checked: boolean): void {
    this.model.fullContent = checked;
  }

  onOriginalTitleLostFocus(): void {
    if (this.model.title || !this.model.originalTitle || !this.isFieldEditable('title')) {
      return;
    }

    of(true)
      .pipe(
        takeUntil(this.destroy$), // nuke delay when component is destroyed
        delay(1000),
        switchMap(() => {
          if (this.model.title || !this.model.originalTitle) {
            return of(null);
          }
          return this.svcTranslation.translate(this.model.originalTitle).pipe(distinctUntilChanged());
        })
      )
      .subscribe(({ value }) => {
        if (!value) {
          return;
        }

        this.model.title = value;
      });
  }

  onPubMedIDChange(value: string): void {
    if (!(value || '').trim()) {
      this.isPubMedIDInvalid = false;
      this.pubMedIDInput.control.updateValueAndValidity();
      return;
    }

    forkJoin({
      guidelines: this.svcGuideline
        .find(null, 2, 0, null, { 'externalIds.pmid': value })
        .pipe(map((guidelines) => guidelines.filter((guideline) => guideline.id !== this.model?._id).length > 0)), // not the same guideline
      publications: this.svcPublication
        .find(null, 1, 0, null, { 'externalIds.pubmed': value })
        .pipe(map((guidelines) => guidelines.length > 0)),
    }).subscribe((data) => {
      // we don't have any publication with the same Pubmed ID or we already have a guideline using this ID
      this.isPubMedIDInvalid = !data.publications || data.guidelines;
      this.pubMedIDInput.control.updateValueAndValidity();
    });
  }

  addAssociation(): void {
    this.model.associations.push('');
  }

  removeAssociation(idx: number): void {
    this.model.associations.splice(idx, 1);
  }

  trackByIndex(index: number): number {
    return index;
  }

  private normalizeDates(): void {
    this.model.publicationDate = this.dateToNgbStruct(this.model.publicationDate as Date) as any;
  }

  private dateToNgbStruct(date: Date): NgbDateStruct {
    if (!date) {
      return;
    }

    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    };
  }

  private ngbStructToDate(date: NgbDateStruct): string {
    if (!date) {
      return;
    }
    return format(new Date(date.year, date.month - 1, date.day), 'yyyy-MM-dd');
  }

  private getTransformedAssociations(associations: Association[]): Association[] {
    return associations.map((association) => {
      if (association.address?.countryCode) {
        association.address.countryCode =
          this.countryMap.get(association.address.countryCode) || association.address.countryCode;
      }
      return association;
    });
  }
}
