<div class="modal-header">
  <h5 class="modal-title">{{ options.success ? 'Successful Merge' : 'Problems during Merge' }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div>
    {{
      options.msg ||
        (options.success
          ? 'Merge performed successfully' + (hasConflicts() ? ' - yet there were below conflicts' : '')
          : 'Merge could not be performed')
    }}
  </div>
  <table *ngIf="options.pairs && options.pairs.length" border="1" class="dirt-merge-problems-table">
    <thead>
      <tr>
        <th>existing</th>
        <th>(to be) merged</th>
        <th>result</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of options.pairs">
        <td *ngIf="hasConflict(item)">
          <a [routerLink]="['/affiliation/detail', item.existId]" rel="noopener" target="_blank">{{
            item.existCaption || item.existId
          }}</a>
        </td>
        <td *ngIf="!hasConflict(item)">
          {{ item.existCaption || item.existId }}
        </td>
        <td>
          <a [routerLink]="['/affiliation/detail', item.mergeId]" rel="noopener" target="_blank">{{
            item.mergeCaption || item.mergeId
          }}</a>
        </td>
        <td [ngClass]="{ 'dirt-merge-problems-conflict': hasConflict(item) }">
          {{ hasConflict(item) ? 'conflict' : 'merged' }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
