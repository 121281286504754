import { Component } from '@angular/core';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { Observable } from 'rxjs';
import { SentimentAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-sentiment-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class SentimentListComponent {
  bulkListDelegate: BulkDelegate = {
    title: 'Bulk Import',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListUpload.bind(this),
    // when list comes in: specificNotifyReloadFct: ...
  };

  constructor(private svcSentiment: SentimentAPI) {}

  private handleBulkListUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcSentiment.startBulkListUpload(file, secondEyes);
  }
}
