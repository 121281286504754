<section class="dirt-person-qc-next">
  <div class="next-content" [ngClass]="{ 'table-responsive': !isCompileIdent, compact: isCompileIdent }">
    <!--Loading Indicator-->
    <p [hidden]="!!queue || !!idBdQueue">
      <i class="fa fa-refresh fa-spin fa-fw"></i>
      Loading your statistics...
    </p>

    <div class="card" *ngIf="queue && queue.length">
      <div class="card-header">Here are the profiles waiting for your ID review:</div>

      <div class="card-body">
        <table class="table table-sm">
          <thead>
            <tr>
              <th></th>
              <th>Remaining</th>
              <th>Total</th>

              <ng-container *ngIf="isCompileIdent; else IDStats">
                <th>Done</th>
                <th>Duplicate</th>
              </ng-container>

              <ng-template #IDStats>
                <th>Ready for Compilation</th>
              </ng-template>

              <th>ID-Out</th>
            </tr>
          </thead>

          <tbody>
            <tr *ngFor="let stat of queue">
              <td>
                <span [ngClass]="{ 'text-danger': stat.underperformer === true }">{{
                  stat.name.split('_').join(' ') | capitalize
                }}</span>
              </td>

              <!-- Remaining -->
              <td>
                <h5>
                  <span class="badge badge-secondary">{{ stat.remaining }}</span>
                </h5>
              </td>

              <!-- Total -->
              <td>
                <h5>
                  <span
                    class="badge"
                    [ngClass]="{
                      'badge-secondary': stat.percent === 0 && stat.needed >= 1,
                      'badge-success': stat.needed < 1,
                      'badge-warning': stat.needed >= 1 && stat.percent > 0
                    }"
                    >{{ stat.completed }}/{{ stat.total }} {{ stat.percent }}%</span
                  >
                </h5>
              </td>

              <ng-container *ngIf="isCompileIdent; else IDStats">
                <!-- Done -->
                <td>
                  <h5>
                    <span class="badge badge-secondary"
                      >{{ (stat.completedDone / (stat.total || 1)) * 100 | number : '1.0-0' }}%</span
                    >
                  </h5>
                </td>

                <!-- Duplicate -->
                <td>
                  <h5>
                    <span class="badge badge-secondary"
                      >{{ (stat.completedDuplicate / (stat.total || 1)) * 100 | number : '1.0-0' }}%</span
                    >
                  </h5>
                </td>
              </ng-container>

              <ng-template #IDStats>
                <!-- Ready for Compilation -->
                <td>
                  <h5>
                    <span class="badge badge-secondary"
                      >{{ (stat.completedReadyForCompilation / (stat.total || 1)) * 100 | number : '1.0-0' }}%</span
                    >
                  </h5>
                </td>
              </ng-template>

              <!-- ID-Out -->
              <td>
                <h5>
                  <span class="badge badge-secondary"
                    >{{ (stat.completedOut / (stat.total || 1)) * 100 | number : '1.0-0' }}%</span
                  >
                </h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ul class="list-group" *ngIf="idBdQueue">
      <p>Overall Statistics</p>

      <!-- Region -->
      <li class="list-group-item d-flex justify-content-between align-items-center row">
        <span class="col-md-8">Your region</span>
        <span class="col-md-4">
          <h5>
            <span class="badge badge-secondary">{{ getStatusText(idBdQueue.regionDone, idBdQueue.regionAll) }}</span>
          </h5>
        </span>
      </li>

      <!-- QC -->
      <li class="list-group-item d-flex justify-content-between align-items-center row">
        <span class="col-md-8">You individually</span>
        <span class="col-md-4">
          <h5>
            <span class="badge badge-secondary">{{ getStatusText(idBdQueue.personalDone, idBdQueue.regionDone) }}</span>
          </h5>
        </span>
      </li>
    </ul>

    <p *ngIf="queue && !queue.length">
      <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>&nbsp; You have no pending item in your queue. Simply
      click the button to start working on a new one!
    </p>
  </div>

  <div class="exceed-cap" *ngIf="!isCompileIdent">
    <label><input type="checkbox" [(ngModel)]="exceedCap" /> Exceed review cap</label>
  </div>

  <div class="next-action">
    <button type="button" class="btn btn-success" (click)="getNext()" [disabled]="isLoadingNext">
      <i class="fa fa-refresh fa-spin" [hidden]="!isLoadingNext"></i>
      Next Person
    </button>
  </div>
</section>
