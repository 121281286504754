import { Event } from '../../event/shared/event';

export class EventSeries {
  public createdAt: Date;
  public events: Event[];
  public id: string;
  public category: number;
  public name: string;
  public type: string;
  public projectNames: string[];
  public webSources: string[];
  public updatedAt: Date;
  public _version: number;
  public originalLanguage?: string;
  public notHappeningAnymore?: boolean;
  public noInfo?: boolean;
  public maintained?: { by: string; at: Date };
  public ownerProduct?: string;
  public country?: string;

  _meta: {
    assignee?: string;
    jobId?: string;
    claimedUntil?: Date;
    consecutiveNoInfoCount?: number;
  };
}
