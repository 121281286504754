import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { NgForm } from '@angular/forms';

import { Roles } from '../../../shared/acl/roles';
import { UserPool } from '../user-pool';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { take } from 'rxjs';
import { ACL } from '../../../shared/acl/acl.service';

@Component({
  selector: 'dirt-user-pool-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
  exportAs: 'frmPool',
  providers: [TitleCasePipe],
})
export class UserPoolFormComponent implements OnInit {
  @Input('pool')
  model: UserPool = new UserPool();

  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  searchRoleConfig = [Roles.ReadyForJobs];
  users: any = [];
  products: { value }[] = [];
  projectNames: { title; code }[] = [];

  constructor(private svcAcl: ACL, private svcValue: ValueAPI) {}

  ngOnInit() {
    this.svcValue
      .find(ValueType.Product, Number.MAX_SAFE_INTEGER, 0, '+value')
      .pipe(take(1))
      .subscribe((vals) => (this.products = vals));
    this.svcValue
      .find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER, 0, '+title')
      .pipe(take(1))
      .subscribe((vals) => (this.projectNames = vals));
  }

  isValid(): boolean {
    return this.ngForm.form.valid && this.sumReserved() <= 100;
  }

  /**
   * Detects whether the form is in create or edit mode based on id existence
   * @returns {boolean}
   */
  isNewRecord(): boolean {
    return !this.model.id;
  }

  getValue(): UserPool {
    return this.model;
  }

  onSelectedUsersChange(auth0Users: any[]): void {
    this.model.users = auth0Users.map((item) => item.user_id);
  }

  canEditField(): boolean {
    return !this.model.system || this.svcAcl.hasRole(Roles.Admin); // leave system pools alone other than assigning users
  }

  onHasRegionalChange(value: boolean) {
    this.model.regional = value;
  }

  onHasProjectWiseChange(value: boolean) {
    this.model.projectWise = value;
  }

  addReserved() {
    if (!this.model.capacities) {
      this.model.capacities = [];
    }
    this.model.capacities.push({} as any);
  }

  removeReserved(idx) {
    this.model.capacities.splice(idx, 1);
  }

  sumReserved(): number {
    return (this.model.capacities || []).reduce((sum, c) => (sum += c.percent || 0), 0);
  }
}
