import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Association } from '../../association';
import { AssociationModalComponent } from './association-modal.component';

@Injectable()
export class AssociationModalService {
  constructor(private readonly svcModal: NgbModal) {}

  open(): Promise<Association> {
    const ref = this.svcModal.open(AssociationModalComponent, { size: 'xl', backdrop: 'static' });
    return ref.result;
  }
}
