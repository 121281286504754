import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';
import { SponsorComponent } from './sponsors.component';
import { SponsorModule } from './sponsors.module';
import { SponsorListComponent } from './list/list.component';
import { SponsorCreateComponent } from './create/create.component';
import { SponsorDetailComponent } from './detail/detail.component';
import { SponsorNormalizedNamesComponent } from './normalized-names/normalized-names.component';

export const sponsorRoutes: Routes = [
  {
    path: 'sponsor',
    component: SponsorComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'create',
        component: SponsorCreateComponent,
        data: { acl: 'sponsor.create' },
      },
      {
        path: 'detail/:id',
        component: SponsorDetailComponent,
        data: { acl: 'sponsor.detail' },
      },
      {
        path: 'list',
        component: SponsorListComponent,
        data: { acl: 'sponsor.list' },
      },
      {
        path: 'normalized-names',
        component: SponsorNormalizedNamesComponent,
        data: { acl: 'sponsor.detail' },
      },
    ],
  },
];

export const sponsorRouting: ModuleWithProviders<SponsorModule> = RouterModule.forChild(sponsorRoutes);
