import { Component, Input, Output, OnInit, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { ACL } from '../../../shared/acl/acl.service';
import { Memo } from '../memo';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { subDays } from 'date-fns';

@Component({
  selector: 'dirt-memo-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
})
export class MemoFormComponent implements OnInit, OnChanges {
  @Input()
  user: string;

  @Input('isSubmitting')
  isSubmitting = false;

  @Input('isSaving')
  isSaving = false;

  submitted = false;
  isReadOnly = false;

  @Input('memo')
  model: Memo = new Memo();

  @Output()
  onSubmitted = new EventEmitter<Memo>();

  @Output()
  onSubmittedAndNew = new EventEmitter<Memo>();

  @Output()
  onCancel = new EventEmitter();

  get isEditMode() {
    return this.model && !!this.model.id;
  }

  get maxDate() {
    return this.dateToNgbStruct(subDays(new Date(), 1));
  }

  constructor(private svcAcl: ACL) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['model']) {
      this.normalizeDate();
    }
  }

  private normalizeDate() {
    this.model.date = this.dateToNgbStruct(this.model.date);
  }

  private dateToNgbStruct(date: any): NgbDateStruct {
    if (!date) {
      return;
    }

    if (!!date.year) {
      return date;
    }

    const dt = new Date(date);

    return {
      year: dt.getFullYear(),
      month: dt.getMonth() + 1,
      day: dt.getDate(),
    };
  }

  private ngbStructToDate(date: NgbDateStruct): string {
    if (!date) {
      return;
    }

    const dt = Date.UTC(date.year, date.month - 1, date.day);
    return new Date(dt).toISOString();
  }

  ngOnInit() {
    this.isReadOnly = this.model.id && !this.svcAcl.hasCredential('memo.update');
  }

  onSubmit(val): void {
    this.submitted = true;
    this.onSubmitted.emit({ ...this.model, date: this.ngbStructToDate(this.model.date) });
  }

  onSubmitAndNew() {
    this.onSubmittedAndNew.emit({ ...this.model, date: this.ngbStructToDate(this.model.date) });
  }

  onCancelClick() {
    this.model.date = this.ngbStructToDate(this.model.date);
    this.onCancel.emit();
  }
}
