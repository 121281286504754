import { Component, Input, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Profile } from '../profile';
import { ProfileAPI } from '../api.service';

@Component({
  selector: 'dirt-profile-summary',
  templateUrl: 'summary.component.html',
  styleUrls: ['summary.component.scss'],
})
export class ProfileSummaryComponent implements OnInit {
  @Input()
  personId: string;

  profile: Profile;

  isLoading = false;

  constructor(private svcProfile: ProfileAPI) {}

  ngOnInit() {
    this.loadProfile();
  }

  private loadProfile(): void {
    this.isLoading = true;

    this.svcProfile
      .findByPersonId(this.personId)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((resp = []) => (this.profile = resp[0]));
  }
}
