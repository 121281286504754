import { NgModule } from '@angular/core';

import { CommentModule } from '../comment/comment.module';
import { DocumentAPI } from './shared/document-api.service';
import { DocumentComponent } from './document.component';
import { DocumentConnectionsComponent } from './shared/connection/connections.component';
import { DocumentCreateComponent } from './create/create.component';
import { DocumentDetailComponent } from './detail/detail.component';
import { DocumentFormComponent } from './shared/form/form.component';
import { DocumentListComponent } from './list/list.component';
import { DocumentPersonAPI } from './shared/document-person-api.service';
import { DocumentPersonListComponent } from './person-list/person-list.component';
import { DocumentPersonModalComponent } from './person-modal/person-modal.component';
import { DocumentPersonModalService } from './person-modal/person-modal.service';
import { documentRouting } from './document.routes';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [SharedModule, CommentModule, documentRouting],
  declarations: [
    DocumentComponent,
    DocumentListComponent,
    DocumentCreateComponent,
    DocumentDetailComponent,
    DocumentFormComponent,
    DocumentConnectionsComponent,
    DocumentPersonListComponent,
    DocumentPersonModalComponent,
  ],
  exports: [],
  providers: [DocumentAPI, DocumentPersonAPI, DocumentPersonModalService],
})
export class DocumentModule {}
