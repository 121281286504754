<section class="dirt-section-list dirt-sponsor-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>Sponsors</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        placeholder="Search..."
        [formControl]="searchCtrl"
      />
    </div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Filter -->
        <dirt-filters [filters]="filters" (onFilter)="onFilter($event)"></dirt-filters>

        <button class="btn btn-secondary btn-sm" [routerLink]="['/sponsor/normalized-names']" *diAcl="'sponsor.detail'">
          <i class="fa fa-edit" aria-hidden="true"></i>&nbsp; Edit Normalized Names
        </button>

        <!-- Create Button -->
        <button class="btn btn-success btn-sm" [routerLink]="['/sponsor/create']" *diAcl="'sponsor.create'">
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Create Sponsor
        </button>
      </div>
    </div>
  </header>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-sponsor-list'"
    [pagingSize]="sponsors?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Table -->
  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="sponsors?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-original-name" [diColSorter]="'originalName'">Original Name</th>
          <th class="col-normalized-name" [diColSorter]="'normalizedName'">Normalized Name</th>
          <th class="col-company-id" [diColSorter]="'companyId'">Company Id</th>
          <th class="col-created-at" [diColSorter]="'createdAt'">Created At</th>
          <th class="col-updated-at" [diColSorter]="'updatedAt'">Updated At</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-sponsor-row"
          *ngFor="
            let sponsor of sponsors
              | paginate
                : {
                    id: 'dirt-sponsor-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
          (click)="navigateTo(sponsor.id, $event)"
        >
          <td class="col-original-name">{{ sponsor.originalName }}</td>
          <td class="col-normalized-name">{{ sponsor.normalizedName }}</td>
          <td class="col-company-id">{{ sponsor.companyId }}</td>
          <td class="col-company-id">{{ sponsor.createdAt | date : 'dd.MM.y' }}</td>
          <td class="col-company-id">{{ sponsor.updatedAt | date : 'dd.MM.y' }}</td>
          <td class="col-actions">
            <!-- Delete -->
            <span
              class="action-btn action-delete"
              (click)="onDeleteClick(sponsor._id, $event)"
              *diAcl="'sponsor.delete'"
            >
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No Sponsors</p>
    </ng-template>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-sponsor-list'"
    [pagingSize]="sponsors?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
