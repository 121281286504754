import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { combineLatest, distinctUntilChanged, fromEvent, map, startWith, Subject, takeUntil } from 'rxjs';
import { FormControl, NgForm, Validators } from '@angular/forms';

import { ImageAPI } from '../../services/image/image.service';
import { TwitterAPI } from '../../services/twitter/twitter.service';
import { PersonJob } from '../../../person/shared/constant/job.enum';

export interface ImageFormModel {
  isImageNotFound?: boolean;
  url?: string;
  websource?: string;
  isTwitterHandleNotFound?: boolean;
  twitterHandle?: string;
  /** If false, useTwitterHandle can only be false */
  isTwitterHandleApproved?: boolean;
  useTwitterHandle?: boolean;
  qc?: any;
}

export interface ImageFormValidationConfig {
  minSize: number;
  /** if less or equal to 0, no check will be performed */
  maxAspectRatio: number;
}

@Component({
  selector: 'dirt-image-form',
  templateUrl: './image-form.component.html',
  styleUrls: ['./image-form.component.scss'],
})
export class ImageFormComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input()
  model: ImageFormModel = {};

  @Input()
  readonly disableImageControls: boolean;

  @Input()
  readonly disableTwitterHandleControls: boolean;

  @Input()
  readonly hideTwitterFields: boolean;

  @Input()
  readonly reviewArea?: string = '';

  @Input()
  displayMode: 'round' | 'square' = 'round';

  @Input()
  readonly imageValidationConfig: ImageFormValidationConfig = {
    minSize: 200,
    maxAspectRatio: 0, // disabled by default
  };

  @Input()
  readonly jobType?: string;

  @Output()
  validityChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChild(NgForm, { static: false })
  ngForm: NgForm;

  @ViewChild('imagePreview', { static: false })
  imagePreview: ElementRef;

  imageURLCtrl: FormControl = new FormControl('', { updateOn: 'blur' });

  imageWebsourceCtrl: FormControl = new FormControl('', { updateOn: 'blur' });

  twitterHandleCtrl: FormControl = new FormControl('', { updateOn: 'blur' });

  hasImagePreviewError = false; // cannot load preview or invalid size

  hasTwitterHandleError = false; // handle doesn't exists or API issue

  hasURLError = false; // URL is invalid (not a URL) or

  isImagePreviewLoading = false;

  img = Image; // just a testing helper allowing us to mock Image in tests

  fileReader = FileReader; // just a testing helper allowing us to mock FileReader in tests

  readonly defaultImage = 'assets/blank-profile.png';

  private currentPreview: string;

  private destroy$: Subject<boolean> = new Subject();

  constructor(private svcTwitter: TwitterAPI, private svcImage: ImageAPI) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) {
      if (changes.model?.previousValue?.url !== changes.model.currentValue.url) {
        this.imageURLCtrl.setValue(this.model.url);
      }
      if (changes.model?.previousValue?.websource !== changes.model.currentValue.websource) {
        this.imageWebsourceCtrl.setValue(this.model.websource);
      }
      if (changes.model?.previousValue?.twitterHandle !== changes.model.currentValue.twitterHandle) {
        this.twitterHandleCtrl.setValue(this.model.twitterHandle);
      }
    }

    if (changes.disableImageControls) {
      if (this.disableImageControls) {
        this.imageURLCtrl.disable();
        this.imageWebsourceCtrl.disable();
      } else {
        this.imageURLCtrl.enable();
        this.imageWebsourceCtrl.enable();
      }
    }

    if (changes.disableTwitterFields) {
      if (this.disableTwitterHandleControls) {
        this.twitterHandleCtrl.disable();
      } else {
        this.twitterHandleCtrl.enable();
      }
    }
  }

  ngAfterViewInit(): void {
    this.imageURLCtrl.setValue(this.model.url);
    this.imageWebsourceCtrl.setValue(this.model.websource);
    this.twitterHandleCtrl.setValue(this.model.twitterHandle);

    // Get a bit more control over the field - only fetch the preview AFTER we ran the validators
    this.imageURLCtrl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      switch (status) {
        case 'DISABLED':
        case 'PENDING':
          this.hasURLError = false;
          return;
        case 'INVALID':
          this.hasURLError = true;
          break;
        case 'VALID':
          this.hasURLError = false;
          break;
      }

      this.model.url = this.imageURLCtrl.value;
      this.onImageURLChange(this.model.url);

      if (this.model.url && !this.disableImageControls && this.jobType !== PersonJob.PERSON_SO_QC) {
        this.imageWebsourceCtrl.addValidators(Validators.required);
      } else {
        this.imageWebsourceCtrl.removeValidators(Validators.required);
      }

      this.imageWebsourceCtrl.updateValueAndValidity();
    });

    this.twitterHandleCtrl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      switch (status) {
        case 'DISABLED':
        case 'PENDING':
          this.hasTwitterHandleError = false;
          return;
        case 'INVALID':
          this.hasTwitterHandleError = true;
          break;
        case 'VALID':
          this.hasTwitterHandleError = false;
          break;
      }

      this.model.twitterHandle = this.twitterHandleCtrl.value;
      this.onTwitterHandleChange(this.twitterHandleCtrl.value);
    });

    this.imageWebsourceCtrl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((status) => {
      if (['DISABLED', 'PENDING', 'INVALID'].includes(status)) {
        return;
      }

      this.model.websource = this.imageWebsourceCtrl.value;
    });

    combineLatest([
      this.imageURLCtrl.statusChanges.pipe(startWith(null)),
      this.twitterHandleCtrl.statusChanges.pipe(startWith(null)),
      this.imageWebsourceCtrl.statusChanges.pipe(startWith(null)),
      this.ngForm.statusChanges.pipe(startWith(null)),
      fromEvent(this.imagePreview.nativeElement, 'load').pipe(startWith(null)),
      fromEvent(this.imagePreview.nativeElement, 'error').pipe(startWith(null)),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(() => {
          return (
            ['VALID', 'DISABLED'].includes(this.imageURLCtrl.status) &&
            ['VALID', 'DISABLED'].includes(this.imageWebsourceCtrl.status) &&
            ['VALID', 'DISABLED'].includes(this.ngForm.status) &&
            // only validate twitter when fields are enabled
            (this.disableTwitterHandleControls
              ? true
              : ['VALID', 'DISABLED'].includes(this.twitterHandleCtrl.status)) &&
            (this.disableTwitterHandleControls ? true : !this.hasTwitterHandleError) &&
            !this.hasURLError &&
            !this.hasImagePreviewError
          );
        }),
        distinctUntilChanged()
      )
      .subscribe((isValid) => {
        this.validityChange.emit(isValid);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  onUseTwitterHandleChange(value: boolean): void {
    if (!value || this.disableImageControls || this.model.isTwitterHandleNotFound) {
      this.hasTwitterHandleError = false;
      this.onImagePreviewURLChange(this.model.url);
      return;
    }

    this.hasImagePreviewError = false;
    this.isImagePreviewLoading = false;

    if (!this.model.twitterHandle || !this.model.isTwitterHandleApproved) {
      return;
    }

    this.twitterHandleCtrl.updateValueAndValidity();
  }

  onIsTwitterHandleApprovedChange(): void {
    if (!this.model.useTwitterHandle || this.disableTwitterHandleControls || this.model.isTwitterHandleNotFound) {
      return;
    }

    if (!this.model.isTwitterHandleApproved) {
      // cannot use the twitter handle in this case
      this.model.useTwitterHandle = false;
    }
  }

  onImageNotFoundChange(): void {
    if (!this.model.isImageNotFound) {
      this.imageURLCtrl.enable();
      this.imageWebsourceCtrl.enable();
      return;
    }

    this.model.url = null;
    this.model.websource = null;

    this.imageURLCtrl.setValue(null);
    this.imageWebsourceCtrl.setValue(null);
    this.imageURLCtrl.disable();
    this.imageWebsourceCtrl.disable();
  }

  onTwitterNotFoundChange(): void {
    if (!this.model.isTwitterHandleNotFound) {
      this.twitterHandleCtrl.enable();
      return;
    }

    this.model.twitterHandle = null;
    this.model.isTwitterHandleApproved = null;
    this.model.useTwitterHandle = null;

    this.twitterHandleCtrl.setValue(null);
    this.twitterHandleCtrl.disable();
  }

  private onImagePreviewLoadFailure(): void {
    this.hasImagePreviewError = true;
    this.isImagePreviewLoading = false;
    this.imagePreview.nativeElement.src = this.defaultImage;
  }

  /**
   * Try to load image preview by fetching it from the backend
   * (to avoid CORS issues) as a base64 encoded string.
   *
   * Validate two things:
   *  1. Image can load (API didn't errored out and image loads)
   *  2. Image size is correct (at least one of the size is greater than
   *    minSize and aspect ratio is less than maxAspectRatio)
   *
   * In case any of the checks fails, reset image preview and set
   * image preview error flag.
   */
  private onImagePreviewURLChange(url: string): void {
    if (this.currentPreview === url) {
      // don't try to load preview multiple time
      return;
    }

    this.currentPreview = url;

    if (!url) {
      // reset image preview
      this.isImagePreviewLoading = false;
      this.hasImagePreviewError = false;
      this.imagePreview.nativeElement.src = this.defaultImage;
      return;
    }

    this.hasImagePreviewError = false;
    this.isImagePreviewLoading = true;

    this.svcImage.getPreview(url).subscribe({
      next: (data) => {
        const reader = new this.fileReader();
        reader.onloadend = () => {
          // Check image size
          const image = new this.img();
          image.onload = () => {
            const invalidSize =
              image.width < this.imageValidationConfig.minSize && image.height < this.imageValidationConfig.minSize;
            const invalidAspectRatio =
              this.imageValidationConfig.maxAspectRatio > 0 &&
              Math.max(image.width, image.height) / Math.min(image.width, image.height) >
                this.imageValidationConfig.maxAspectRatio;
            if (invalidSize || invalidAspectRatio) {
              this.onImagePreviewLoadFailure();
              return;
            }

            this.isImagePreviewLoading = false;
            this.hasImagePreviewError = false;
            this.imagePreview.nativeElement.src = reader.result as string;
          };

          image.onerror = () => {
            this.onImagePreviewLoadFailure();
          };

          image.src = reader.result as string;
        };

        reader.readAsDataURL(data);
      },
      error: () => {
        this.onImagePreviewLoadFailure();
      },
    });
  }

  /**
   * Retrieve Twitter profile from handle and request update of image preview.
   * If we have handle error (set by the validator) or if we don't have an
   * handle, try to fallback to the image URL instead.
   *
   * In case the twitter handle isn't set for use or approved, don't try to set
   * the preview at all.
   */
  private onTwitterHandleChange(handle: string): void {
    if (this.hasTwitterHandleError || !handle?.trim()) {
      this.model.useTwitterHandle = false;
      this.model.isTwitterHandleApproved = null;
    }

    if (!this.model.useTwitterHandle || !this.model.isTwitterHandleApproved || this.model.url) {
      if (this.imageURLCtrl.valid) {
        // try to fallback to url, if it fails we'll just fallback to default image
        this.onImagePreviewURLChange(this.model.url);
      }

      return;
    }

    this.svcTwitter.findProfileByHandle(handle).subscribe((profile) => {
      this.onImagePreviewURLChange(profile['profile_image_url'].replace('normal', '400x400'));
    });
  }

  /**
   * Request update of image preview or try to fallback to twitter handle if
   * URL isn't defined.
   */
  private onImageURLChange(url: string): void {
    if (!this.hasURLError || !url?.trim()) {
      // try to fallback to twitter handle, if it fails we'll just fallback to default image
      this.onTwitterHandleChange(this.model.twitterHandle);
      return;
    }

    this.onImagePreviewURLChange(url);
  }

  onVisitAllLinksClick(): void {
    const links = [this.model?.url, this.model?.websource];

    links.filter((link) => !!link).forEach((link) => window.open(link));
  }
}
