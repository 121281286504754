import { Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { interval, Subject } from 'rxjs';
import { switchMap, takeUntil, takeWhile } from 'rxjs/operators';

import { BulkDelegate } from '../../bulk.delegate';
import { BulkTracking } from '../../shared/bulk-tracking';
import { BulkTrackingAPI } from '../../shared/api.service';

export const BULK_MODAL_OPTIONS = {
  centered: true,
  size: 'lg',
  backdrop: false,
  windowClass: 'di-bulk-modal',
};

const CHECK_INTERVAL = 3000; // ms
const MAX_UPLOAD_SIZE = 5; // MB - set in nginx configuration

@Component({
  selector: 'dirt-bulk-modal',
  templateUrl: './bulk-modal.component.html',
  styleUrls: ['./bulk-modal.component.scss'],
})
export class BulkModalComponent implements OnDestroy {
  @Input() delegate: BulkDelegate;

  @ViewChild('bulkListDialog') bulkListDialog: ElementRef;
  @ViewChild('bulkListFile') bulkListFile: ElementRef;

  wndw: Window = window; // be able to override in tests

  secondEyes: string;

  isWorking: boolean = false;

  isComplete: boolean = false;

  progress: string = '';

  filenameFeedback: string;

  private bulkTrackingID: string;

  private destroy$: Subject<boolean> = new Subject();

  constructor(private svcBulkTracking: BulkTrackingAPI, private activeModal: NgbActiveModal) {}

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
    this.notifyReload();
  }

  /**
   * Automatically download feedback file on complete.
   */
  async onFeedbackDownload(): Promise<void> {
    const feedbackBlob = await this.svcBulkTracking
      .downloadFeedbackFile(this.bulkTrackingID, this.delegate.type)
      .toPromise();
    const dlDummyLink = this.bulkListDialog.nativeElement.appendChild(
      this.bulkListDialog.nativeElement.ownerDocument.createElement('a')
    );
    dlDummyLink.setAttribute('download', this.filenameFeedback);
    dlDummyLink.setAttribute('href', URL.createObjectURL(feedbackBlob));
    dlDummyLink.click();
    dlDummyLink.parentElement.removeChild(dlDummyLink);
  }

  async onSubmit(): Promise<void> {
    const file: File = (this.bulkListFile.nativeElement.files || [])[0];
    if (!(file && this.secondEyes)) {
      return;
    }

    if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.wndw.alert('Only .xlsx (Excel Sheet) files are supported.');
      return;
    }

    if (file.size >= MAX_UPLOAD_SIZE * Math.pow(10, 6)) {
      this.wndw.alert(`File total size is bigger than ${MAX_UPLOAD_SIZE}MB. Please split file in smaller files.`);
      return;
    }

    this.progress = 'Uploading...';
    this.isWorking = true;
    this.isComplete = false;

    this.delegate
      .specificStartFct(file, this.secondEyes, null)
      .pipe(takeUntil(this.destroy$))
      .subscribe((bulkTrackingID) => {
        this.bulkTrackingID = bulkTrackingID;
        this.filenameFeedback = undefined;

        this.doProgressCheckCheck();
      });
  }

  async onClose(): Promise<void> {
    this.activeModal.close();
  }

  private doProgressCheckCheck(): void {
    interval(CHECK_INTERVAL) // repeat every CHECK_INTERVAL until component gets destroyed or bulk complete
      .pipe(
        takeUntil(this.destroy$),
        takeWhile(() => !this.isComplete),
        switchMap(() => this.svcBulkTracking.findById(this.bulkTrackingID))
      )
      .subscribe((track: BulkTracking) => {
        this.progress =
          (track.numberSuccess || 0) + ' success + ' + (track.numberFailure || 0) + ' failed of ' + track.numberRecords;
        if (!track.complete) {
          return;
        }

        this.progress += ' - complete!';
        this.isComplete = true;
        this.filenameFeedback = track.filenameFeedback;
      });
  }

  private notifyReload(): void {
    if (this.bulkTrackingID /* has at least started */ && this.delegate.specificNotifyReloadFct) {
      this.delegate.specificNotifyReloadFct();
    }
  }
}
