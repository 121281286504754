<section class="dirt-section-list dirt-account-people-list">
  <header class="row justify-content-between">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>People</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        id="affiliations-searchbar"
        class="di-search form-control ng-trim-ignore"
        placeholder="Search..."
        [formControl]="searchCtrl"
      />
    </div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Add button -->
        <button class="btn btn-success btn-sm" (click)="onAddPersonRequested()" *diAcl="'account-person.create'">
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
          <span>Add Person</span>
        </button>
      </div>
    </div>
  </header>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-account-people-list'"
    [pagingSize]="accountPeople?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading" class="table-container">
    <table class="table table-sm table-hover" *ngIf="accountPeople?.length; else empty">
      <thead>
        <tr>
          <th class="col-badge">&nbsp;</th>
          <th class="col-kolId">kolId</th>
          <th class="col-firstName">First name</th>
          <th class="col-middleName">Middle name</th>
          <th class="col-lastName">Last name</th>
          <th class="col-jobTitleMatrix">Job title Matrix Cluster</th>
          <th class="col-jobTitle">Job title</th>
          <th class="col-status">Status</th>
          <th class="col-requestMaintenance">Requested maintenance</th>
          <th class="col-inactive">Inactive</th>
          <th class="col-isPersonAccountConnected">Person is connected to current account</th>
          <th class="col-createdAt">Created At</th>
          <th class="col-reviewed">
            <label>
              <span class="col-label">Reviewed</span>
              <div class="reviewed-wrapper">
                <input
                  (change)="toggleAllReviewed($event)"
                  type="checkbox"
                  [checked]="allReviewed"
                  [disabled]="!canReviewKP() || isLoading"
                  title="Mark all as reviewed"
                />
              </div>
            </label>
          </th>
          <th class="col-delete">
            <span class="action-btn action-delete">
              <i class="fa fa-trash-o"></i>
            </span>
          </th>
          <th col="col-personStatus">Person Status</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-person-row"
          *ngFor="
            let accountPerson of accountPeople
              | paginate
                : {
                    id: 'dirt-account-people-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <td class="col-state">
            <span class="badge badge-info" *ngIf="accountPerson.person.autoProfile">Auto</span>
          </td>
          <td class="col-kolId" [title]="accountPerson.kolId">
            <a [routerLink]="['/person/detail', accountPerson.person._id]" rel="noopener noreferrer" target="_blank">{{
              accountPerson.person.kolId
            }}</a>
          </td>
          <td class="col-firstName" [title]="accountPerson.firstName">{{ accountPerson.person.firstName }}</td>
          <td class="col-middleName" [title]="accountPerson.middleName">{{ accountPerson.person.middleName }}</td>
          <td class="col-lastName" [title]="accountPerson.lastName">{{ accountPerson.person.lastName }}</td>
          <td class="col-jobTitleMatrix" [title]="getTooltipContent(accountPerson, 'matrix')">
            <ng-container *ngFor="let jobDetail of accountPerson.jobDetails; let last = last">
              {{ jobDetail.matrix }}
              <span *ngIf="!last">,</span>
            </ng-container>
          </td>
          <td class="col-jobTitle" [title]="getTooltipContent(accountPerson, 'title')">
            <ng-container *ngFor="let jobDetail of accountPerson.jobDetails; let last = last">
              {{ jobDetail.title }}
              <span *ngIf="!last">,</span>
            </ng-container>
          </td>
          <td class="col-status">{{ accountPerson.status }}</td>
          <td class="col-requestMaintenance">{{ accountPerson.requestMaintenance }}</td>
          <td class="col-inactive">{{ accountPerson.inactive }}</td>
          <td class="col-isPersonAccountConnected">{{ accountPerson.isPersonAccountConnected }}</td>
          <td class="col-createdAt">{{ accountPerson.createdAt | date : 'dd.MM.y' }}</td>
          <td class="col-reviewed" [title]="accountPerson.reviewed">
            <input
              type="checkbox"
              [disabled]="!canReviewKP()"
              [checked]="accountPerson.reviewed"
              (change)="onKPReviewedChange(accountPerson, $event)"
            />
          </td>
          <td>
            <input
              type="checkbox"
              [disabled]="!canReviewKP()"
              (change)="onAccountPersonMarkForDelete(accountPerson, $event)"
              [checked]="accountPerson.markForDelete"
            />
          </td>
          <td class="col-personStatus" [title]="accountPerson.person._meta.status">
            {{ accountPerson.person._meta.status }}
          </td>
          <td class="col-actions">
            <!-- Audits -->
            <span
              class="action-btn action-audit"
              title="Open audit logs"
              (click)="onRequestAuditLogs([accountPerson._id])"
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </span>
            <!-- View -->
            <span
              class="action-btn action-edit"
              title="View connection"
              (click)="onViewPersonRequested(accountPerson)"
              *diAcl="'!account-person.update'"
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </span>

            <!-- Edit -->
            <span
              class="action-btn action-edit"
              title="Edit connection"
              (click)="onEditPersonRequested(accountPerson)"
              *diAcl="'account-person.update'"
            >
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </span>

            <!-- Delete -->
            <span
              class="action-btn action-delete"
              title="Delete connection"
              (click)="onDeletePersonRequested(accountPerson)"
              *diAcl="'account-person.delete'"
            >
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No People</p>
    </ng-template>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
