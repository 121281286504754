import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GET,
  POST,
  Path,
  Body,
  Query,
  MediaType,
  Produces,
  BaseUrl,
  PATCH,
} from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { User } from './user';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class UserAPI extends APIService {
  @GET('users/db/')
  @Produces(MediaType.JSON)
  public find(
    @Query('name') name?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: object,
    @Query('includeAdditionalInfo') includeAdditionalInfo?: boolean,
    @Query('restrictByRole') restrictByRole?: boolean
  ): Observable<User[]> {
    return;
  }

  @GET('users/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<User> {
    return;
  }

  @GET('users/name/{id}')
  @Produces(MediaType.JSON)
  public findNameById(
    @Path('id') id: string
  ): Observable<{ user_id: string | null; name?; user_metadata?: { firstName; lastName } }> {
    return;
  }

  @GET('users/me')
  @Produces(MediaType.JSON)
  public findMe(): Observable<User> {
    return;
  }

  @GET('users/db/count')
  @Produces(MediaType.JSON)
  public count(
    @Query('name') name?: string,
    @Query('filter') filter?: object,
    @Query('restrictByRole') restrictByRole?: boolean
  ): Observable<any> {
    return;
  }

  @POST('users')
  @Produces(MediaType.JSON)
  public create(@Body user: User): Observable<any> {
    return;
  }

  @PATCH('users/{id}')
  @Produces(MediaType.JSON)
  public upsert(@Path('id') id: string, @Body user: User): Observable<any> {
    return;
  }

  /**
   * Upload the file and get a bulk upload ID
   *
   * @param file
   * @param secondEyes
   */
  public startBulkListUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'users/bulk-list', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  /**
   * Get the file as binary
   */
  public downloadUsersFile(): Observable<any> {
    return this.http
      .get(this.getBaseUrl() + 'users/export', { responseType: 'blob' })
      .pipe(catchError((err) => this.handleError(err)));
  }
}
