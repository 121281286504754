import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIService } from '../../shared/services/api/api.service';
import { DocumentPerson } from './person';
import {
  GET,
  MediaType,
  Produces,
  Query,
  POST,
  Body,
  Path,
  PATCH,
  DELETE,
} from '../../shared/services/rest/rest.service';

@Injectable()
export class DocumentPersonAPI extends APIService {
  @GET('document-people/')
  @Produces(MediaType.JSON)
  public find(
    @Query('documentId') documentId?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<DocumentPerson[]> {
    return;
  }

  @GET('document-people/count')
  @Produces(MediaType.JSON)
  public count(@Query('documentId') documentId?: string): Observable<any> {
    return;
  }

  @GET('document-people/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<DocumentPerson> {
    return;
  }

  @POST('document-people')
  @Produces(MediaType.JSON)
  public create(@Body person: DocumentPerson): Observable<any> {
    return;
  }

  @PATCH('document-people/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body person: Partial<DocumentPerson>): Observable<any> {
    return;
  }

  @DELETE('document-people/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }
}
