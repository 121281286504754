import { Component, EventEmitter, Input, Output } from '@angular/core';
import { isNil } from 'lodash';
import { ClinicalTrialCurationStatus } from '../../curation-status';
import { ClinicalTrialProfileValueNumeric } from '../../value-numeric';

@Component({
  selector: 'dirt-input-value-numeric',
  templateUrl: 'value-numeric-input.html',
  styleUrls: ['value-numeric-input.scss'],
})
export class ValueNumericInput {
  @Input()
  value: ClinicalTrialProfileValueNumeric;
  @Output()
  valueEdited: EventEmitter<number> = new EventEmitter();
  @Input()
  name: string = 'ctrl-' + Math.round(Math.random() * 1_000_000);

  inside: boolean = false;
  error: boolean = false;
  statusEnum = ClinicalTrialCurationStatus;
  parseInt = parseInt;
  isNil = isNil;

  changeInput(evnt) {
    const valRaw = evnt.target.value;
    if (!valRaw?.trim()) {
      this.value.value = null;
      this.valueEdited.emit(null);
      this.error = false;
      return;
    }
    const val = parseInt(valRaw);
    if (isNaN(val)) {
      this.error = true;
      return;
    }
    this.error = false;
    this.value.value = val;
    this.valueEdited.emit(val);
  }
}
