import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'highlight' })
export class HighlightPipe implements PipeTransform {
  transform(text: string, pattern: string): string {
    if (!pattern) {
      return text;
    }

    try {
      const regex = new RegExp(pattern, 'gi');
      return text.replace(regex, (match) => `<span class="highlight">${match}</span>`);
    } catch (e) {
      return text;
    }
  }
}
