import { AwsRum, AwsRumConfig } from 'aws-rum-web';

let awsRum: AwsRum = null;

export function setupCloudWatchRUM(environment: any) {
  // A bit too noisy when developing and hide stack trace
  if (!environment.enableRUM) {
    return;
  }

  if (
    ['AWS_IDENTITY_POOL_ID', 'AWS_REGION', 'AWS_GUEST_ROLE_ARN', 'AWS_RUM_APP_ID'].some((k) => environment[k] == null)
  ) {
    console.warn(`Cannot setup AWS RUM, missing configs!`);
    return;
  }

  if (awsRum) {
    console.log(`AWS RUM client already setup!`);
    return;
  }

  try {
    const APPLICATION_REGION = environment.AWS_REGION;

    const config: AwsRumConfig = {
      sessionSampleRate: 1, // If less than 1, events may or may not be emitted for a given session
      endpoint: `https://dataplane.rum.${APPLICATION_REGION}.amazonaws.com`,
      guestRoleArn: environment.AWS_GUEST_ROLE_ARN,
      identityPoolId: environment.AWS_IDENTITY_POOL_ID,
      telemetries: [
        'performance',
        // https://github.com/aws-observability/aws-rum-web/blob/main/docs/configuration.md#errors
        // default stack trace length is 1000
        ['errors', { stackTraceLength: 5000 }],
        [
          'http',
          // Enabling this causes CORS to block the redirect to veeva-test.auth0
          // { addXRayTraceIdHeader: true },
        ],
      ],
      allowCookies: true,
      enableXRay: true,
      enableRumClient: true,
      sessionEventLimit: 0, // If 0, records unlimited num of events per session
      disableAutoPageView: true,
    };

    const APPLICATION_ID = environment.AWS_RUM_APP_ID;
    const APPLICATION_VERSION = '1.0.0';

    if (APPLICATION_ID) {
      awsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
      console.log(`AWS RUM initialization completed!`);
    }
  } catch (error) {
    console.error(`ERROR: failed to initiate RUM client, ${error}!`);
  }
}

export function recordErrorIntoRUM(errorToUpload: Error): void {
  if (!awsRum) {
    console.error(`ERROR: There's no RUM client setup!`);
    return;
  }

  try {
    awsRum.recordError(errorToUpload);
  } catch (error) {
    console.error(`ERROR: when recording error "${errorToUpload.name}", ${error}!`);
  }
}
