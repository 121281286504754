import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface DateRange {
  start?: Date;
  end?: Date;
  isNotSet?: boolean;
}

@Component({
  selector: 'dirt-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
})
export class DateRangeComponent implements OnInit {
  @Input() notSetLabel: string = 'No value';

  @Input() isOpen: boolean;

  @Input() hideNotSet: boolean = false;

  @Input() value: DateRange;

  @Output() onChange: EventEmitter<DateRange> = new EventEmitter();

  @Output() onError: EventEmitter<string> = new EventEmitter();

  private readonly errorMessage: string = 'Please provide a date range when using date range option.';

  ngOnInit(): void {
    if (this.hideNotSet) {
      this.value.isNotSet = false;
    }

    if (this.value.isNotSet || this.value.start || !this.isOpen) {
      return;
    }

    this.onError.emit(this.errorMessage);
  }

  onDateRangeChanged(start: Date, end: Date): void {
    if ((start && start.getFullYear() < 1900) || (end && end.getFullYear() < 1900)) {
      return; // incomplete input
    }
    if (start && !end) {
      end = start;
    }

    this.value = { start, end };
    this.onChange.emit(this.value);
    this.onError.emit(undefined);
  }

  onSelectedRangeOptionChanged(selectedOption: string): void {
    this.value = { ...this.value }; // break reference to not mutate object in parent
    switch (selectedOption) {
      case 'range':
        this.value.isNotSet = false;
        break;
      case 'date-not-set':
        this.value = {
          start: undefined,
          end: undefined,
          isNotSet: true,
        };
        break;
      default:
        return;
    }

    // Don't send the signal if there is no date, otherwise it crash the list
    if (this.value.isNotSet || this.value.start) {
      this.onChange.emit(this.value);
      this.onError.emit(undefined);
    } else {
      this.onError.emit(this.errorMessage);
    }
  }
}
