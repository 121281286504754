<section class="dirt-section-list dirt-account-list" [ngClass]="{ 'expandable-section': parentId }">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>{{ parentId ? 'Children' : 'Accounts' }}</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        placeholder="Search by Name, Acronym or ID"
        [formControl]="searchCtrl"
      />
    </div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Filter -->
        <dirt-filters [filters]="filters" (onFilter)="onFilter($event)" *ngIf="!parentId"></dirt-filters>

        <!-- Bulk -->
        <ng-container *ngIf="!parentId">
          <dirt-button-dropdown [title]="'Bulk'" [icon]="'fa-pencil'" *diAcl="'account.bulkList'">
            <button
              class="list-group-item list-group-item-action"
              [title]="bulkListDelegate.specialHint || ''"
              (click)="onOpenBulkListDialog()"
            >
              <i class="fa fa-th-list" aria-hidden="true"></i>
              Bulk import
            </button>

            <button
              class="list-group-item list-group-item-action"
              [title]="medicalInformationBulkListDelegate.specialHint || ''"
              (click)="onOpenMedicalInformationBulkListDialog()"
            >
              <i class="fa fa-th-list" aria-hidden="true"></i>
              Bulk Import Medical Information
            </button>

            <button
              class="list-group-item list-group-item-action"
              [title]="jobBulkListDelegate.specialHint || ''"
              (click)="onOpenBulkListJobDialog()"
            >
              <i class="fa fa-th-list" aria-hidden="true"></i>
              Bulk Import Job
            </button>
          </dirt-button-dropdown>
        </ng-container>

        <!-- Create Button -->
        <a
          class="btn btn-success btn-sm"
          [routerLink]="['/account/create']"
          [queryParams]="{ parentId: parentId }"
          *diAcl="'account.create || division.create'"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
          <span>Create {{ parentId ? 'Children' : 'Accounts' }}</span>
        </a>
      </div>
    </div>
  </header>

  <div class="pull-right">
    <!-- Export -->
    <button class="btn btn-secondary btn-sm" (click)="onExport()" *diAcl="'account.export'">
      <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Export List
    </button>
  </div>

  <!-- Filter Bar -->
  <dirt-filters-bar
    [filter]="filter"
    [config]="filterBarConfig"
    (filterClear)="onFilterClear()"
    (filterValueRemove)="onFilterValueRemove($event)"
  ></dirt-filters-bar>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-account-list'"
    [pagingSize]="accounts?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="accounts?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-type">&nbsp;</th>
          <th class="col-name" [diColSorter]="'name.keyword'">Name</th>
          <th class="col-acronym" [diColSorter]="'acronym.keyword'">Acronym</th>
          <th class="col-country" [diColSorter]="'address.countryCode'">Country</th>
          <th class="col-status" [diColSorter]="'_meta.status'">Status</th>
          <th class="col-created" [diColSorter]="'createdAt'">Created</th>
          <th class="col-modified" [diColSorter]="'updatedAt'">Modified</th>
          <th class="col-priority" [diColSorter]="'priority'">Priority</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <ng-container
          *ngFor="
            let account of accounts
              | paginate
                : {
                    id: 'dirt-account-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <tr class="dirt-account-row">
            <td class="col-name" colspan="9">{{ account.name }}</td>
          </tr>

          <!-- Row for self -->
          <tr class="dirt-division-row">
            <ng-template
              [ngTemplateOutlet]="accountRow"
              [ngTemplateOutletContext]="{ account: account, type: getAccountLevel(account) }"
            ></ng-template>
          </tr>

          <ng-container *ngFor="let level2 of account.divisions">
            <tr class="dirt-division-row">
              <ng-template
                [ngTemplateOutlet]="accountRow"
                [ngTemplateOutletContext]="{ account: level2, type: getAccountLevel(level2) }"
              ></ng-template>
            </tr>

            <ng-container *ngFor="let level3 of level2.divisions">
              <tr class="dirt-hospital-row">
                <ng-template
                  [ngTemplateOutlet]="accountRow"
                  [ngTemplateOutletContext]="{ account: level3, type: getAccountLevel(level3) }"
                ></ng-template>
              </tr>

              <ng-container *ngFor="let level4 of level3.divisions">
                <tr class="dirt-subdivision-row">
                  <ng-template
                    [ngTemplateOutlet]="accountRow"
                    [ngTemplateOutletContext]="{ account: level4, type: getAccountLevel(level4) }"
                  ></ng-template>
                </tr>

                <ng-container *ngFor="let level5 of level4.divisions">
                  <tr class="dirt-level5-row">
                    <ng-template
                      [ngTemplateOutlet]="accountRow"
                      [ngTemplateOutletContext]="{ account: level5, type: getAccountLevel(level5) }"
                    ></ng-template>
                  </tr>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No {{ parentId ? 'Children' : 'Accounts' }}</p>
    </ng-template>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-account-list'"
    [pagingSize]="accounts?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>

<!-- Row template -->
<ng-template #accountRow let-account="account" let-type="type">
  <td class="col-type">
    <ng-container [ngSwitch]="type">
      <span *ngSwitchCase="'DIVISION'" class="di-icon" ngbTooltip="L2" placement="top" aria-hidden="true">&#9401;</span>
      <span *ngSwitchCase="'HOSPITAL'" class="di-icon" ngbTooltip="L3" placement="top" aria-hidden="true">&#9405;</span>
      <span *ngSwitchCase="'SUBDIVISION'" class="di-icon" ngbTooltip="L4" placement="top" aria-hidden="true"
        >&#9416;</span
      >
      <span *ngSwitchCase="'LEVEL5'" class="di-icon" ngbTooltip="L5" placement="top" aria-hidden="true">&#9316;</span>
      <span *ngSwitchDefault></span>
    </ng-container>
  </td>
  <td class="col-name">
    <a class="dirt-account-name" [title]="account.name | dash" [routerLink]="['/account/detail', account.id]">{{
      account.name | dash
    }}</a>
  </td>
  <td class="col-acronym">{{ account.acronym }}</td>
  <td class="col-country">{{ account.address?.countryCode }}</td>
  <td class="col-status" [title]="account._meta?.status | capitalize">{{ account._meta?.status | capitalize }}</td>
  <td class="col-created">{{ account.createdAt | date : 'dd.MM.y' }}</td>
  <td class="col-modified">{{ account.updatedAt | date : 'dd.MM.y' }}</td>
  <td class="col-priority" [ngClass]="{ disabled: !isPriorityEditable }">
    <ngb-rating
      [rate]="account.priority"
      (rateChange)="setPriority(account, $event)"
      max="5"
      [readonly]="!isPriorityEditable"
    >
      <ng-template let-fill="fill" let-index="index">
        <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
      </ng-template>
    </ngb-rating>
  </td>
  <td class="col-actions">
    <ng-template [diAcl]="'account.delete'">
      <!-- Delete -->
      <span class="action-btn action-delete" title="Delete Account" (click)="onDelete(account.id)">
        <i class="fa fa-trash-o" aria-hidden="true"></i>
      </span>
    </ng-template>
  </td>
</ng-template>
