import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../../user/shared/user';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { UserAPI } from '../../../../user/shared/api.service';
import { Utils } from '../../../utils';

@Component({
  selector: 'dirt-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSelectComponent implements OnInit {
  @Input()
  selectedUser: User;

  @Input()
  roles: string[] = [];

  @Output()
  selectedUserChange = new EventEmitter<User>();

  @Input()
  disabled: boolean;

  @Input()
  required: boolean;

  @Input()
  requiresEu: boolean = false;

  isLoading$ = new BehaviorSubject(false);

  constructor(private svcUser: UserAPI) {}

  ngOnInit() {}

  search = (text$: Observable<any>) => {
    return text$.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      tap(() => this.isLoading$.next(true)),
      switchMap((term: string) => {
        if (term.length > 2) {
          return this.svcUser
            .find(term, 10, 0, '', {
              roles: this.roles,
              blocked: false,
              ...(this.requiresEu
                ? {
                    euCompliant:
                      true /*we'd go insane checking every region restriction - this is a convenience feature anyway*/,
                  }
                : {}),
            })
            .pipe(
              catchError(() => {
                return of([]);
              })
            );
        } else {
          return of([]);
        }
      }),
      tap(() => this.isLoading$.next(false))
    );
  };

  formatName(user: User): string {
    let firstName;
    let lastName;

    if (user.user_metadata) {
      firstName = user.user_metadata.firstName;
      lastName = user.user_metadata.lastName;
    }

    const name = `${firstName} ${lastName} <${user.email}>`;
    return name.trim();
  }

  onSelectedUserChange(user: User) {
    this.selectedUser = user;
    console.log(user);
    if (Utils.isLiteralObject(user)) {
      this.selectedUserChange.emit(user);
    } else if (!user) {
      this.selectedUserChange.emit(null);
    }
  }
}
