import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Affiliation } from '../../../affiliation/shared/affiliation';
import { Value } from '../../services/value/value';
import { ValueAPI } from '../../services/value/value-api.service';
import { ValueType } from '../../enum/value-type.enum';

enum AffiliationType {
  ASSOCIATION = 'Association',
  HOSPITAL = 'Hospital',
  PRIVATE_PRACTICE = 'Private practise', // TODO are we ok with this typo?
  INDUSTRY = 'Industry',
  UNIVERSITY_RESEARCH_INST = 'University research institution',
  HEALTH_PAYER = 'HEALTH_PAYER', // TODO shouldn't this be 'Health Payer' ?
  OTHER = 'Other',
}

@Component({
  selector: 'dirt-affiliation-suggestion-list',
  templateUrl: './affiliation-suggestion-list.component.html',
  styleUrls: ['./affiliation-suggestion-list.component.scss'],
})
export class AffiliationSuggestionListComponent implements OnInit {
  @Input()
  affiliations: Affiliation[];

  @Input()
  selectLabel: string = 'Select';

  @Output()
  onSelectedItem = new EventEmitter<Affiliation>();

  countryValues: Value[] = [];
  affiliationType = AffiliationType;

  constructor(private svcValue: ValueAPI) {}

  ngOnInit(): void {
    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.countryValues = data;
    });
  }

  onSelectItem(item: Affiliation) {
    this.onSelectedItem.emit(item);
  }

  getCountryTitle(code: string): string {
    return this.countryValues.find((o) => o.code === code)?.title || '';
  }

  getTypeColor(type: string) {
    switch (type) {
      case 'ASSOCIATION':
        return 'green';
      case 'HOSPITAL':
        return 'blue';
      case 'PRIVATE_PRACTICE':
        return 'orange';
      case 'INDUSTRY':
        return 'purple';
      case 'UNIVERSITY_RESEARCH_INST':
        return 'brown';
      case 'HEALTH_PAYER':
        return 'darkgreen';
      case 'OTHER':
        return 'turquoise';
      default:
        return 'black';
    }
  }
}
