import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TitleCasePipe } from '@angular/common';

import { User } from '../shared/user';
import { UserAPI } from '../shared/api.service';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { Filter, FilterType } from '../../shared/components/filters/filters.component';
import { Roles } from '../../shared/acl/roles';
import { Auth } from '../../shared/services/auth/auth.service';

@Component({
  selector: 'dirt-user-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class UserListComponent implements OnInit {
  users: Array<User> = [];
  isLoading = true;

  // pagination settings
  total: any;
  pagingPage = 1;
  pagingLimit = 50;
  pagingSkip = 0;

  sort = 'email:1';
  filter: { [key: string]: any } = { blocked: false };
  filters: Filter[] = [
    {
      title: 'Blocked',
      key: 'blocked',
      type: FilterType.SINGLE_VALUE,
      values: [
        { title: 'true', displayValue: 'YES' },
        { title: 'false', displayValue: 'NO', selected: true },
      ],
    },
  ];

  searchTerm: string;
  searchCtrl: FormControl = new FormControl('');

  bulkListDelegate: BulkDelegate = {
    title: 'Bulk Import',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListUpload.bind(this),
    specificNotifyReloadFct: this.getUsers.bind(this),
  };

  private roles: string[];

  constructor(
    private router: Router,
    private svcUser: UserAPI,
    private readonly svcAuth: Auth,
    private readonly titleCasePipe: TitleCasePipe
  ) {}

  ngOnInit() {
    this.searchCtrl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(1000))
      .subscribe((val) => this.onSearch(val));

    this.svcAuth.getProfile().subscribe((userProfile) => {
      this.roles = userProfile.app_metadata.roles;
      this.filters.push({
        title: 'Role',
        key: 'roles',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: this.getAllowedRolesForUser(),
      });
    });
  }

  getUsers() {
    this.isLoading = true;

    this.svcUser
      .find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.filter, false, true)
      .subscribe(
        (res) => (this.users = res),
        null,
        () => (this.isLoading = false)
      );
  }

  getCount() {
    this.svcUser.count(this.searchTerm, this.filter, true).subscribe((res) => (this.total = res));
  }

  getPage(page: number) {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getUsers();
  }

  onSearch(name: string): void {
    this.resetPagination();
    this.getCount();
    this.getUsers();
  }

  onFilter(filter: { [key: string]: any }): void {
    this.filter = filter;

    this.resetPagination();
    this.getUsers();
    this.getCount();
  }

  navigateTo(route, e: Event) {
    this.router.navigate(route);
  }

  resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private handleBulkListUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcUser.startBulkListUpload(file, secondEyes);
  }

  async exportUsers() {
    const usersBlob = await this.svcUser.downloadUsersFile().toPromise();
    var dlDummyLink = document.createElement('a');
    dlDummyLink.setAttribute('download', `users_list_${new Date().toLocaleString()}.xlsx`);
    dlDummyLink.setAttribute('href', URL.createObjectURL(usersBlob));
    dlDummyLink.click();
    dlDummyLink.parentElement.removeChild(dlDummyLink);
  }

  private getAllowedRolesForUser() {
    const specialRoles = [Roles.ViewAutomate, Roles.ViewLfka, Roles.ReadyForJobs];

    const hasSameModule = (item: string) => this.roles.some((ur) => item.split('_')[0] === ur.split('_')[0]);

    return Object.keys(Roles).reduce((acc, k) => {
      // In case the user is not admin (i.e manager), only show relevant users
      if (this.roles.includes(Roles.Admin) || hasSameModule(Roles[k]) || specialRoles.includes(Roles[k])) {
        acc.push({
          title: Roles[k],
          displayValue: this.titleCasePipe
            .transform(Roles[k].replace(/_/g, ' '))
            .replace('So', 'SO')
            .replace('Lfka', 'LFKA')
            .replace('Compiler Ident', 'Compiler-Identifier'),
        });
      }
      return acc;
    }, []);
  }
}
