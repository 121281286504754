import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIService } from '../../shared/services/api/api.service';
import { AccountPerson } from './account-person';
import {
  GET,
  MediaType,
  Produces,
  Query,
  POST,
  Body,
  Path,
  PATCH,
  DELETE,
  PUT,
} from '../../shared/services/rest/rest.service';

@Injectable()
export class AccountPersonAPI extends APIService {
  @GET('account-people/')
  @Produces(MediaType.JSON)
  public find(
    @Query('accountId') accountId?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any,
    @Query('term') term?: string
  ): Observable<AccountPerson[]> {
    return;
  }

  @GET('account-people/count')
  @Produces(MediaType.JSON)
  public count(@Query('accountId') accountId?: string): Observable<any> {
    return;
  }

  @GET('account-people/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<AccountPerson> {
    return;
  }

  @POST('account-people')
  @Produces(MediaType.JSON)
  public create(@Body person: Partial<AccountPerson>): Observable<any> {
    return;
  }

  @PATCH('account-people/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body person: Partial<AccountPerson>): Observable<any> {
    return;
  }

  @DELETE('account-people/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }

  @POST('account-people/audit')
  @Produces(MediaType.JSON)
  public audit(@Body ids: string[], @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  @POST('account-people/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Body ids: string[]): Observable<number> {
    return;
  }

  @POST('account-people/bulk-update-reviewed')
  @Produces(MediaType.JSON)
  public bulkUpdate(@Body data: { ids: string[]; reviewed: boolean }): Observable<any> {
    return null;
  }
}
