<form #formGuideline="ngForm" novalidate>
  <div [diMarkerWrapper]="model" class="clearfix">
    <!-- Title -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="title">Title</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="title"
          class="form-control"
          name="title"
          [ngModel]="model.title"
          (ngModelChange)="model.title = longDash($event)"
          [readonly]="!isFieldEditable('title')"
          autofocus
          required
          *diMarker="{ name: 'title', entity: 'guideline', area: 'ID' }"
        />
      </div>
    </div>

    <!-- Original Title -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="originalTitle">Original Title</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="originalTitle"
          class="form-control"
          name="originalTitle"
          [ngModel]="model.originalTitle"
          (ngModelChange)="model.originalTitle = longDash($event)"
          (blur)="onOriginalTitleLostFocus()"
          [readonly]="!isFieldEditable('originalTitle')"
          *diMarker="{ name: 'originalTitle', entity: 'guideline', area: 'ID' }"
        />
      </div>
    </div>

    <!-- Original Language -->
    <div class="form-group row" *ngIf="model.id && (model.originalLanguage || model.originalTitle)">
      <label class="col-form-label col-sm-4 col-md-2" for="originalLanguage">Original Language</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="originalLanguage"
          class="form-control custom-select"
          name="originalLanguage"
          [(ngModel)]="model.originalLanguage"
          [disabled]="!isFieldEditable('originalLanguage')"
        >
          <option *ngFor="let language of languages$ | async" [value]="language.code">{{ language.title }}</option>
        </select>
      </div>
    </div>

    <!-- PubMed ID -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="pubmedID">Pubmed ID</label>
      <div class="col-sm-8 col-md-10">
        <div class="input-group" *diMarker="{ name: 'pubmedID', entity: 'guideline', area: 'ID' }">
          <input
            type="text"
            id="pubmedID"
            class="form-control"
            name="pubmedID"
            [(ngModel)]="model.externalIds.pubmed ? model.externalIds.pubmed : model.externalIds.pmid"
            (blur)="onPubMedIDChange(model.externalIds.pubmed ? model.externalIds.pubmed : model.externalIds.pmid)"
            [readonly]="!isFieldEditable('externalIds.pmid')"
            dirtPasteNormalizer
            *diInputErrorMsg="{
              text: 'Duplicated or unknown PubMed ID',
              show: isPubMedIDInvalid
            }"
            #pubMedIDInput="ngModel"
          />

          <div class="input-group-append" *ngIf="!!model.externalIds.pmid || !!model.externalIds.pubmed">
            <a
              class="btn btn-secondary"
              href="https://pubmed.ncbi.nlm.nih.gov/{{
                model.externalIds.pmid ? model.externalIds.pmid : model.externalIds.pubmed
              }}"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i class="fa fa-external-link dirt-link-pointer" aria-hidden="true"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- DOI -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="doi">DOI</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="doi"
          class="form-control"
          name="doi"
          [(ngModel)]="model.externalIds.doi"
          [readonly]="!isFieldEditable('externalIds.doi')"
          dirtPasteNormalizer
          *diMarker="{ name: 'doi', entity: 'guideline', area: 'ID' }"
        />
      </div>
    </div>

    <!-- Journal Title -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="journalTitle">Journal Title</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="journalTitle"
          class="form-control"
          name="journalTitle"
          [(ngModel)]="model.journal.title"
          [readonly]="!isFieldEditable('journal.title')"
          dirtPasteNormalizer
          *diMarker="{ name: 'journalTitle', entity: 'guideline', area: 'ID' }"
        />
      </div>
    </div>

    <!-- Journal Issue -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="journalIssue">Journal Issue</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="journalIssue"
          class="form-control"
          name="journalIssue"
          [(ngModel)]="model.journal.issue"
          [readonly]="!isFieldEditable('journal.issue')"
          dirtPasteNormalizer
          *diMarker="{ name: 'journalIssue', entity: 'guideline', area: 'ID' }"
        />
      </div>
    </div>

    <!-- Journal Volume -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="journalVolume">Journal Volume</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="journalVolume"
          class="form-control"
          name="journalVolume"
          [(ngModel)]="model.journal.volume"
          [readonly]="!isFieldEditable('journal.volume')"
          dirtPasteNormalizer
          *diMarker="{ name: 'journalVolume', entity: 'guideline', area: 'ID' }"
        />
      </div>
    </div>

    <!-- Publication Date -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="publicationDate">Publication Date</label>
      <div class="col-sm-8 col-md-10">
        <div class="input-group" *diMarker="{ name: 'publicationDate', entity: 'guideline', area: 'ID' }">
          <input
            type="text"
            id="publicationDate"
            class="form-control"
            name="publicationDate"
            [(ngModel)]="model.publicationDate"
            ngbDatepicker
            #dp="ngbDatepicker"
            [minDate]="minDate"
            [maxDate]="maxDate"
            readonly
            required
          />

          <div class="input-group-append">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="dp.toggle()"
              [hidden]="!isFieldEditable('publicationDate')"
            >
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Abstract -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="abstract">Abstract</label>
      <div class="col-sm-8 col-md-10">
        <textarea
          id="abstract"
          class="form-control"
          name="abstract"
          [ngModel]="model.abstract?.text"
          (ngModelChange)="model.abstract.text = longDash($event)"
          [readonly]="!isFieldEditable('abstract')"
          required
          *diMarker="{ name: 'abstract', entity: 'guideline', area: 'ID' }"
        >
        </textarea>
      </div>
    </div>

    <!-- Full Content -->
    <div class="form-group row">
      <label class="col-sm-4 col-md-2">Full Content</label>
      <div class="col-sm-8 col-md-10 pt-1">
        <ui-switch
          size="small"
          [checked]="!!model.fullContent"
          [disabled]="!isFieldEditable('fullContent')"
          (change)="onFullContentChange($event)"
        ></ui-switch>
      </div>
    </div>

    <!-- Projects -->
    <div class="form-group row" *ngIf="!model._id">
      <label class="col-form-label col-sm-4 col-md-2" for="projectNames">Projects</label>
      <div class="col-md-2">
        <dirt-multiselect-dropdown
          id="projectNames"
          name="projectNames"
          [ngClass]="{ disabled: !isFieldEditable('projectNames') }"
          [options]="projects"
          [settings]="projectsSettings"
          [(ngModel)]="model.projectNames"
          required
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <!-- Category -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="category">Category</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="category"
          class="form-control custom-select"
          name="category"
          [(ngModel)]="model.category"
          [disabled]="!isFieldEditable('category')"
          required
        >
          <option *ngFor="let category of categories" [value]="category.code">
            {{ category.title | capitalize }}
          </option>
        </select>
      </div>
    </div>

    <!-- Reach -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="reach">Reach</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="reach"
          class="form-control custom-select"
          name="reach"
          [(ngModel)]="model.reach"
          [disabled]="!isFieldEditable('reach')"
          required
        >
          <option *ngFor="let reach of reaches | keys" [value]="reach">
            {{ reach | capitalize }}
          </option>
        </select>
      </div>
    </div>

    <!-- Country -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="country">Country</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="country"
          class="form-control custom-select"
          name="country"
          requiredOrNull
          [(ngModel)]="model.journal.country"
          [disabled]="!isFieldEditable('journal.country')"
          *diMarker="{ name: 'country', entity: 'guideline', area: 'ID' }"
        >
          <option [ngValue]="undefined">&mdash; Country &mdash;</option>
          <option [ngValue]="null">No country available</option>
          <option *ngFor="let country of countries" [value]="country.code">
            {{ country.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Full text link -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="fullTextLink">Full Text Link</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="fullTextLink"
          name="fullTextLink"
          [form]="formGuideline"
          [nonCompliantDomainType]="nonCompliantDomainType"
          [disabled]="!isFieldEditable('fullTextLink')"
          [required]="true"
          *diMarker="{ name: 'fullTextLink', entity: 'guideline', area: 'ID' }"
        >
        </dirt-link>
      </div>
    </div>

    <!-- Web Source -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="webSource">Web Source</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.webSource"
          name="webSource"
          [form]="formGuideline"
          [nonCompliantDomainType]="nonCompliantDomainType"
          [disabled]="!isFieldEditable('webSource')"
          [required]="true"
          *diMarker="{ name: 'webSource', entity: 'guideline', area: 'ID' }"
        >
        </dirt-link>
      </div>
    </div>

    <!-- Keywords -->
    <div *diMarker="{ name: 'keywords', entity: 'guideline', area: 'ALL' }">
      <div class="form-group row" *ngFor="let keyword of model.keywords; let idx = index; trackBy: trackByIndex">
        <label class="col-form-label col-sm-4 col-md-2" [for]="'keyword-' + idx"
          ><span *ngIf="0 === idx">Keywords</span></label
        >
        <div class="col-sm-6 col-md-6">
          <div class="input-group">
            <input
              type="text"
              [id]="'keyword-' + idx"
              class="form-control"
              [name]="'keyword-' + idx"
              [(ngModel)]="keyword.keyword"
              [readonly]="!isFieldEditable('keywords')"
            />

            <div class="input-group-append" *ngIf="!!model.keywords">
              <!-- Button: Add -->
              <button
                type="button"
                class="btn btn-secondary"
                (click)="model.keywords.push({ keyword: '', majorTopic: null })"
                *ngIf="idx === model.keywords.length - 1 && isFieldEditable('keywords')"
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <!-- Button: Remove -->
              <button
                type="button"
                class="btn btn-secondary"
                (click)="model.keywords.splice(idx, 1)"
                *ngIf="idx > 0 && isFieldEditable('keywords')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <!-- Association List -->
    <div class="form-group">
      <section class="guideline-associations">
        <div class="card">
          <div class="card-header">Associations</div>
          <div class="card-body">
            <input
              *ngIf="isFieldEditable('associations')"
              type="search"
              class="di-search form-control ng-trim-ignore"
              name="searchTerm"
              [ngClass]="{ loading: isLoadingAssociation }"
              [ngbTypeahead]="onSearchAssociation"
              [resultTemplate]="searchResultTemplate"
              (selectItem)="onSelectAssociation($event)"
              [(ngModel)]="searchTerm"
              placeholder="Search Associations"
              aria-label="Search"
            />

            <span
              class="small text-muted pt-1 pl-2"
              [ngClass]="{ invisible: !didSearch || isLoadingAssociation }"
              *ngIf="!disabled"
              >No results</span
            >

            <div [hidden]="isLoadingAssociation" *diMarker="{ name: 'associations', entity: 'guideline', area: 'ID' }">
              <table class="table table-sm table-hover" *ngIf="associations?.length; else empty">
                <thead>
                  <tr>
                    <th class="col-link"></th>
                    <th class="col-name">Name</th>
                    <th class="col-location">Location</th>
                    <th class="col-reach">Reach</th>
                    <th class="col-cat">Cat.</th>
                    <th class="col-actions">&nbsp;</th>
                  </tr>
                </thead>

                <tbody>
                  <tr class="dirt-association-row" *ngFor="let association of associations">
                    <td class="col-link">
                      <a [routerLink]="['/association/detail', association.id]" rel="noopener" target="_blank">
                        <i class="fa fa-external-link" aria-hidden="true"></i>
                      </a>
                    </td>
                    <td class="col-name">{{ association.name | dash }}</td>
                    <td class="col-location">{{ association.address?.countryCode }}</td>
                    <td class="col-reach">{{ association.reach | capitalize }}</td>
                    <td class="col-category">{{ association.category }}</td>
                    <td class="col-actions">
                      <!-- Delete -->
                      <span
                        class="action-btn action-delete"
                        *ngIf="isFieldEditable('associations')"
                        (click)="onAssociationDeleteClicked(association.id)"
                      >
                        <i class="fa fa-trash-o" aria-hidden="true"></i>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <!-- Empty Message -->
              <ng-template #empty>
                <p class="dirt-no-results">No Associations Attached</p>
              </ng-template>
            </div>

            <!-- Loading Message -->
            <p class="dirt-loading" *ngIf="isLoadingAssociation">
              <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
              <span class="sr-only">Loading...</span>
            </p>

            <!-- SearchAutoComplete Result Template -->
            <ng-template #searchResultTemplate let-r="result" let-t="term">
              <ng-container>
                <div class="di-sac-affiliation">
                  <div class="di-sac-name">{{ r?.name }}</div>
                  <div class="di-sac-location">{{ r?.address?.countryCode || '&mdash;' }}</div>
                  <div class="di-sac-reach">{{ r?.reach || '&mdash;' }}</div>
                </div>
              </ng-container>
            </ng-template>
          </div>
        </div>
      </section>
    </div>

    <!-- Association Name -->
    <div class="form-group row" *ngFor="let association of model.associations; let idx = index; trackBy: trackByIndex">
      <label class="col-form-label col-sm-4 col-md-2">Association Name</label>
      <div class="col-sm-8 col-md-10">
        <div class="input-group">
          <input
            type="text"
            class="form-control"
            name="association-{{ idx }}"
            [readonly]="!isFieldEditable('associations')"
            [(ngModel)]="model.associations[idx]"
          />

          <div class="input-group-append">
            <!-- Button: Add -->
            <button
              type="button"
              class="btn btn-secondary"
              (click)="addAssociation()"
              *ngIf="idx == model.associations.length - 1 && isFieldEditable('associations')"
            >
              <i class="fa fa-plus-circle"></i>
            </button>

            <!-- Button: Remove -->
            <button
              type="button"
              class="btn btn-secondary"
              (click)="removeAssociation(idx)"
              *ngIf="idx > 0 && isFieldEditable('associations')"
            >
              <i class="fa fa-minus-circle"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Comment -->
    <div class="form-group row" *ngIf="!model._id">
      <label class="col-form-label col-sm-4 col-md-2" for="comment">Comment</label>
      <div class="col-sm-8 col-md-10">
        <textarea
          id="comment"
          class="form-control"
          name="comment"
          [ngModel]="model.comment"
          (ngModelChange)="model.comment = longDash($event)"
        >
        </textarea>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="ownerProduct">Owner product</label>

      <div class="col-sm-8 col-md-10 input-group">
        <select
          id="ownerProduct"
          class="form-control custom-select"
          name="ownerProduct"
          [(ngModel)]="model.ownerProduct"
          [required]="!model.id"
          [disabled]="model.id && !isFieldEditable('ownerProduct')"
        >
          <option *ngFor="let product of products" [value]="product.value">
            {{ product.value }}
          </option>
        </select>
      </div>
    </div>
  </div>
</form>
