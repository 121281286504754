<section class="dirt-section-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>Committees</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        placeholder="Search..."
        [formControl]="searchCtrl"
      />
    </div>
  </header>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-committee-list'"
    [pagingSize]="committees?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="committees?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-name" [diColSorter]="'name'">Name</th>
          <th class="col-country" [diColSorter]="'country'">Country</th>
          <th class="col-topic" [diColSorter]="'topic'">Topic</th>
          <th class="col-status" [diColSorter]="'_meta.status'">Status</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-committee-row"
          *ngFor="
            let committee of committees
              | paginate
                : {
                    id: 'dirt-committee-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <td class="col-name">
            <a
              class="dirt-committee-name"
              [title]="committee.name | dash"
              [routerLink]="['/committee/detail', committee._id]"
              target="_blank"
              rel="noopener noreferrer"
              >{{ committee.name | dash }}</a
            >
          </td>
          <td class="col-country">{{ committee.country }}</td>
          <td class="col-topic">
            <span *ngIf="!!committee.therapeuticAreas?.length; else default" [ngbTooltip]="committee.therapeuticAreas">
              {{ committee.topic | capitalize }}
            </span>

            <ng-template #default>{{ committee.topic | capitalize }}</ng-template>
          </td>
          <td class="col-status" [title]="committee._meta?.status | capitalize">
            {{ committee._meta?.status | capitalize }}
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No Committees</p>
    </ng-template>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-committee-list'"
    [pagingSize]="committees?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
