export class User {
  public app_metadata: {
    groups?: string[];
    associationPools?: string[];
    eventPools?: string[];
    roles: string[];
    projectNames?: string[];
    profileProjectNames?: string[];
    profileCategories?: string[];
    personCompilers?: string[];
    eventCompilers?: string[];
    associationCompilers?: string[];
    profileQCReviewer?: string;
    personQCReviewer?: string;
    eventQCReviewer?: string;
    assoicationQCReviewer?: string;
    spokenLanguages?: string[];
    additionalInfo: {
      underperformer?: boolean;
      underperfValidTill?: any;
      personIdentifiers?: string[];
      personIDQCReviewer?: string;
      associationProjects?: string[];
      eventProjects?: string[];
      guidelineProjects?: string[];
      utcMaintainer?: boolean;
      draftMaintainer?: boolean;
      eventCreators?: string[];
      eventIdReviewer?: string;
      associationCreators?: string[];
      associationIdReviewer?: string;
      guidelineCreators?: string[];
      guidelineIdReviewer?: string;
      regionPreferences?: { groups: string[] }[];
    };
  };
  public blocked: boolean;
  public created_at: string;
  public email: string;
  public last_login: string;
  public logins_count: number;
  public name: string;
  public picture: string;
  public updated_at: string;
  public user_id: string;
  public user_metadata: {
    firstName: string;
    lastName: string;
  };
  public pools: {
    id: string;
    name: string;
  }[];

  constructor() {}
}
