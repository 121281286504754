import { NgModule } from '@angular/core';

import { UserGroupAPI } from './shared/api.service';

@NgModule({
  imports: [],
  declarations: [],
  providers: [UserGroupAPI],
  exports: [],
})
export class UserGroupModule {}
