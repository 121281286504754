import { Component, Input, OnInit } from '@angular/core';
import { PersonAdvancedSearchDto } from '../../person-advanced-search-dto';
import { ValueAPI } from '../../../../shared/services/value/value-api.service';
import { Value } from '../../../../shared/services/value/value';
import { ValueType } from '../../../../shared/enum/value-type.enum';
import { take } from 'rxjs';
import { PersonStatus } from '../../constant/status.enum';
import { IMultiSelectOption, IMultiSelectSettings } from '../../../../shared/components/multiselect-dropdown/types';

const CLS_MULTISELECT_BTN = 'btn btn-sm btn-secondary';

@Component({
  selector: 'dirt-person-advanced-search',
  templateUrl: 'advanced-search.html',
  styleUrls: ['advanced-search.scss'],
})
export class PersonAdvancedSearchComponent implements OnInit {
  @Input()
  model: PersonAdvancedSearchDto;

  countries: Value[] = [];
  deliveryProjects: Value[] = [];
  specialties: IMultiSelectOption[] = [];
  specialtiesSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    selectionLimit: 1, // really just select one
  };
  degrees: IMultiSelectOption[] = [];
  degreesSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    selectionLimit: 1, // really just select one
  };
  statuses = PersonStatus;

  constructor(private readonly svcValue: ValueAPI) {}

  ngOnInit() {
    this.svcValue
      .find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title')
      .pipe(take(1))
      .subscribe((_c) => (this.countries = _c));
    this.svcValue
      .find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER, 0, '+title')
      .pipe(take(1))
      .subscribe((_p) => (this.deliveryProjects = _p.filter((_pp) => (_pp as any).delivery && !(_pp as any).autoTag)));
    this.svcValue
      .find(ValueType.Specialty, Number.MAX_SAFE_INTEGER, 0, '+title')
      .pipe(take(1))
      .subscribe((_s) => (this.specialties = _s.map((_ss) => ({ id: _ss.code, name: _ss.title }))));
    this.svcValue
      .find(ValueType.Degree, Number.MAX_SAFE_INTEGER, 0, '+title')
      .pipe(take(1))
      .subscribe((_d) => (this.degrees = _d.map((_dd) => ({ id: _dd.code, name: _dd.title }))));
  }
}
