import { NgModule } from '@angular/core';

import { UserPoolAPI } from './shared/api.service';
import { SharedModule } from '../shared/shared.module';
import { userPoolRouting } from './user-pool.routes';
import { UserPoolComponent } from './user-pool.component';
import { UserPoolFormComponent } from './shared/form/form.component';
import { UserPoolCreateComponent } from './create/create.component';
import { UserPoolDetailComponent } from './detail/detail.component';
import { UserPoolListComponent } from './list/list.component';
import { ValueModule } from '../value/value.module';

@NgModule({
  imports: [SharedModule, userPoolRouting, ValueModule],
  declarations: [
    UserPoolComponent,
    UserPoolFormComponent,
    UserPoolCreateComponent,
    UserPoolDetailComponent,
    UserPoolListComponent,
  ],
  providers: [UserPoolAPI],
  exports: [],
})
export class UserPoolModule {}
