import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { MemoAPI } from '../shared/memo-api.service';
import { Memo } from '../shared/memo';
import { UserAPI } from '../../user/shared/api.service';
import { User } from '../../user/shared/user';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { BulkModalComponent, BULK_MODAL_OPTIONS } from '../../common/bulk/component/bulk-modal/bulk-modal.component';

@Component({
  selector: 'dirt-memo-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class MemoListComponent implements OnInit, OnDestroy {
  user: string;
  userData: User;

  memos: Array<Memo>;
  isLoading: boolean;
  sub: Subscription;

  total: any;
  pagingPage = 1;
  pagingLimit = 30;
  pagingSkip = 0;

  sort = '-_id';

  memoFormOpened = false;
  memoEditId: string;
  flashingId: string;
  flashingEditId: string;

  memo: Memo = new Memo();
  isSubmitting = false;
  isSaving = false;

  bulkListDelegate: BulkDelegate = {
    title: 'Bulk Import',
    type: BulkType.LIST,
    specialHint: 'Bulk upload memo notes',
    specificStartFct: this.handleBulkListUpload.bind(this),
    specificNotifyReloadFct: this.doLoad.bind(this),
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcUser: UserAPI,
    private svcMemo: MemoAPI,
    private svcModal: NgbModal
  ) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe((params) => {
      this.user = params['user'];
      this.doLoad();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  doLoad(): void {
    this.resetPagination();
    this.getCount();
    this.getMemos();
    this.getUser();
  }

  getUser() {
    this.svcUser.findById(this.user).subscribe((resp) => (this.userData = resp));
  }

  getMemos(): void {
    this.isLoading = true;

    this.svcMemo.find(this.user, this.pagingLimit, this.pagingSkip, this.sort).subscribe(
      (response) => (this.memos = response),
      null,
      () => (this.isLoading = false)
    );
  }

  getCount() {
    this.svcMemo.count(this.user).subscribe((res) => (this.total = res));
  }

  getPage(page: number) {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getMemos();
  }

  navigateTo(route, e?: any) {
    if (e && e.target.nodeName === 'A') {
      return;
    }

    this.router.navigate(route);
  }

  resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  onSort(field: string): void {
    this.sort = field;
    this.resetPagination();
    this.getMemos();
  }

  onDeleteClick(memoId: string, $event) {
    $event.stopPropagation();
    if (window.confirm('Do you want to remove this entry?')) {
      this.svcMemo.deleteById(memoId).subscribe(() => {
        this.getMemos();
        this.getCount();
      });
    }
  }

  getMemo(id: string) {
    this.svcMemo.findById(id).subscribe((response) => (this.memo = response));
  }

  onEditClicked(memoId) {
    this.memoEditId = memoId;
  }

  onSubmit(memo: Memo): void {
    memo.user = this.user;

    this.isSubmitting = true;
    this.svcMemo.create(memo).subscribe(
      (resp) => {
        this.isSubmitting = false;
        this.memoFormOpened = false;
        this.memos = [resp, ...(this.memos || [])];
        this.flashingId = resp.id;
        setTimeout(() => {
          this.flashingId = null;
        }, 3000);
        this.getCount();
      },
      () => (this.isSubmitting = false)
    );
  }

  onSave(memo: Memo) {
    memo.user = this.user;

    this.isSaving = true;
    this.svcMemo.create(memo).subscribe(
      (resp) => {
        this.isSaving = false;
        this.memos = [resp, ...(this.memos || [])];
        this.flashingId = resp.id;
        this.memo = new Memo();
        this.getCount();
        setTimeout(() => {
          this.flashingId = null;
        }, 3000);
      },
      () => (this.isSaving = false)
    );
  }

  onEdit(memo: Memo) {
    this.isSubmitting = true;
    this.svcMemo.upsert(memo.id, memo).subscribe(
      (resp) => {
        this.isSubmitting = false;
        this.flashingEditId = resp.id;
        const index = this.memos.findIndex((c) => c.id === memo.id);
        this.memos = [...this.memos.slice(0, index), resp, ...this.memos.slice(index + 1)];
        this.memoEditId = null;
        setTimeout(() => {
          this.flashingEditId = null;
        }, 3000);
      },
      () => (this.isSubmitting = false)
    );
  }

  onCreateMemoClick() {
    this.memo = new Memo();
    this.memoFormOpened = true;
  }

  onCancelClick() {
    this.memoFormOpened = false;
    this.memo = new Memo();
  }

  onCancelEditClick() {
    this.memoEditId = null;
  }

  onBulkImportClick(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.bulkListDelegate;
  }

  private handleBulkListUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcMemo.startBulkListUpload(file, secondEyes);
  }
}
