<form (ngSubmit)="onSubmit(frmMemo.value)" #frmMemo="ngForm" novalidate>
  <fieldset class="dirt-fieldset form-group">
    <div class="form-group row">
      <div class="col-12">
        <h3 *ngIf="!isEditMode">Create new memo</h3>
        <h3 *ngIf="isEditMode">Edit memo</h3>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-md-1" for="date">Date</label>
      <div class="col-md-2">
        <div class="input-group">
          <input
            id="date"
            type="text"
            class="form-control"
            name="date"
            [(ngModel)]="model.date"
            [required]="true"
            readonly
            autofocus
            ngbDatepicker
            [maxDate]="maxDate"
            #dp="ngbDatepicker"
          />
          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" (click)="dp.toggle()" [hidden]="isReadOnly">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
      <label class="col-form-label col-md-1" for="hours">Hours</label>
      <div class="col-md-2">
        <input
          id="hours"
          type="number"
          class="form-control"
          name="hours"
          [(ngModel)]="model.hours"
          [required]="true"
          [disabled]="isReadOnly"
          autofocus
        />
      </div>
      <label class="col-form-label col-md-1" for="task">Task</label>
      <div class="col-md-5">
        <input
          id="task"
          type="text"
          class="form-control"
          name="task"
          [(ngModel)]="model.task"
          [required]="true"
          [disabled]="isReadOnly"
          autofocus
        />
      </div>
    </div>

    <div class="dirt-actions col-md-12" *ngIf="!isReadOnly">
      <div class="dirt-btn-ct pull-right">
        <button
          class="btn btn-secondary"
          [disabled]="isSubmitting || isSubmitting || isSaving"
          (click)="onCancelClick()"
        >
          <span>Cancel</span>
        </button>
      </div>

      <div *ngIf="!isEditMode" class="dirt-btn-ct pull-right">
        <button
          class="btn btn-primary"
          [ngClass]="{ 'btn-loading': isSaving }"
          [disabled]="!frmMemo.form.valid || isSubmitting || isSaving"
          (click)="onSubmitAndNew(); $event.preventDefault()"
        >
          <span>Save & New</span>
        </button>
      </div>

      <div class="dirt-btn-ct pull-right">
        <button
          type="submit"
          class="btn btn-primary"
          [ngClass]="{ 'btn-loading': isSubmitting }"
          [disabled]="!frmMemo.form.valid || isSubmitting || isSaving"
        >
          <span>Save</span>
        </button>
      </div>
    </div>
  </fieldset>
</form>
