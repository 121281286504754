<div class="clearfix">
  <form #baseDataForm="ngForm" novalidate>
    <div class="row">
      <div class="col-md-10">
        <h3>Base Data</h3>
      </div>

      <!-- Avatar -->
      <div class="col-md-2 d-flex align-items-center justify-content-center" *ngIf="model.logo?.local_url">
        <dirt-avatar
          [displayMode]="'square'"
          [readonly]="true"
          [local_url]="model.logo?.local_url_nocache || model.logo?.local_url"
        >
        </dirt-avatar>
      </div>
    </div>

    <br />

    <!-- Full Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="name">Full Name</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="name"
          class="form-control"
          name="name"
          [(ngModel)]="model.name"
          (ngModelChange)="model.name = longDash($event)"
          [readonly]="!isFieldEditable('name')"
          autofocus
          required
        />
      </div>
    </div>

    <!-- Original Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Original Name</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          class="form-control"
          name="originalName"
          [(ngModel)]="model.originalName"
          (ngModelChange)="model.originalName = longDash($event)"
          [readonly]="!isFieldEditable('originalName')"
        />
      </div>
    </div>

    <!-- Alternative Names -->
    <div class="form-group row" *ngFor="let p of model.alternativeNames; let idx = index; trackBy: trackByIndex">
      <label class="col-form-label col-sm-4 col-md-2" for="alternativeNames-{{ idx }}">Alternative Name</label>
      <div class="col-sm-8 col-md-10">
        <div class="input-group">
          <input
            type="text"
            id="alternativeNames-{{ idx }}"
            class="form-control"
            name="alternativeNames-{{ idx }}"
            [(ngModel)]="model.alternativeNames[idx]"
            [readonly]="!isFieldEditable('alternativeNames')"
          />

          <div class="input-group-append">
            <!-- Button: Add -->
            <button
              type="button"
              class="btn btn-secondary"
              (click)="pushItemToList(model.alternativeNames)"
              *ngIf="idx == model.alternativeNames.length - 1 && isFieldEditable('alternativeNames')"
            >
              <i class="fa fa-plus-circle"></i>
            </button>

            <!-- Button: Remove -->
            <button
              type="button"
              class="btn btn-secondary"
              (click)="removeFromByIndex(model.alternativeNames, idx)"
              *ngIf="idx > 0 && isFieldEditable('alternativeNames')"
            >
              <i class="fa fa-minus-circle"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Acronym -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="acronym">Acronym</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="acronym"
          class="form-control"
          name="acronym"
          [(ngModel)]="model.acronym"
          (ngModelChange)="model.acronym = longDash($event)"
          [readonly]="!isFieldEditable('acronym')"
        />
      </div>
    </div>

    <!-- Website URL -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Website URL</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.website"
          name="website"
          [form]="baseDataForm"
          [disabled]="!isFieldEditable('website')"
          [required]="true"
        ></dirt-link>
      </div>
    </div>

    <!-- Phone -->
    <dirt-input-phone
      [model]="model.phone"
      [extensionInput]="true"
      [allowedCountries]="model.address?.countryCode ? [model.address?.countryCode] : []"
      [form]="baseDataForm"
      [disabled]="!isFieldEditable('phone.countryCallingCode')"
    >
    </dirt-input-phone>

    <!-- Address -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="address">Address</label>
      <div class="col-sm-8 col-md-10">
        <dirt-address
          [address]="model.address"
          [stateSelector]="true"
          [disabled]="!isFieldEditable('address')"
          [mandatoryCountry]="true"
          (onChange)="onAddressChange()"
        >
        </dirt-address>
      </div>
    </div>

    <!-- Type -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="type">Type</label>
      <div class="col-md-3">
        <select
          id="type"
          class="form-control custom-select"
          name="type"
          [(ngModel)]="model.type"
          (ngModelChange)="onAccountTypeChange()"
          [disabled]="!isFieldEditable('type')"
          required
        >
          <option *ngFor="let type of accountTypes" [value]="type.code">
            {{ type.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Subtype -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="subtype">Subtype</label>
      <div class="col-md-3">
        <select
          id="subtype"
          class="form-control custom-select"
          name="subtype"
          [(ngModel)]="model.subtype"
          [disabled]="!isFieldEditable('subtype') || !model.address?.countryCode || !model.type"
          required
        >
          <option [ngValue]="null" [selected]="!model.subtype" disabled>&mdash; Subtype &mdash;</option>
          <option *ngFor="let type of availableAccountSubtypes" [ngValue]="type.code">
            {{ type.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Teaching Status -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="teachingStatus">Teaching Status</label>
      <div class="col-md-3">
        <select
          id="teachingStatus"
          class="form-control custom-select"
          name="teachingStatus"
          [(ngModel)]="model.teachingStatus"
          [disabled]="!isFieldEditable('teachingStatus') || !model.address?.countryCode"
        >
          <option [ngValue]="null" [selected]="!model.teachingStatus" disabled>&mdash; Teaching Status &mdash;</option>
          <option *ngFor="let ts of availableAccountTeachingStatus" [ngValue]="ts.code">
            {{ ts.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Priority -->
    <!-- should only be visible durating creation -->
    <div *ngIf="!model.id" class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="priority">Priority</label>
      <div class="col-md-3" style="align-self: center">
        <ngb-rating
          id="priority"
          [rate]="model.priority"
          max="5"
          (rateChange)="onPriorityChange($event)"
          [readonly]="!isFieldEditable('priority')"
        >
          <ng-template let-fill="fill" let-index="index">
            <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
          </ng-template>
        </ngb-rating>
      </div>
    </div>

    <!-- Websources -->
    <div class="form-group row" *ngFor="let affLink of model.websources; let idx = index; trackBy: trackByIndex">
      <label class="col-form-label col-sm-4 col-md-2" for="websources-{{ idx }}">Websource</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.websources[idx]"
          name="websources-{{ idx }}"
          [form]="baseDataForm"
          [disabled]="!isFieldEditable('websources')"
        >
          <button
            type="button"
            class="btn btn-secondary"
            (click)="pushItemToList(model.websources)"
            *ngIf="idx == model.websources.length - 1 && isFieldEditable('websources')"
          >
            <i class="fa fa-plus-circle"></i>
          </button>

          <button
            type="button"
            class="btn btn-secondary"
            (click)="removeFromByIndex(model.websources, idx)"
            *ngIf="idx > 0 && isFieldEditable('websources')"
          >
            <i class="fa fa-minus-circle"></i>
          </button>
        </dirt-link>
      </div>
    </div>

    <dirt-image-form
      #imageForm
      [(model)]="accountLogoValue"
      [displayMode]="'square'"
      [disableImageControls]="!isFieldEditable('logo')"
      [disableTwitterHandleControls]="!isFieldEditable('social.twitter')"
      [imageValidationConfig]="imageValidationConfig"
      (validityChange)="onFormImageValidityChange($event)"
    >
    </dirt-image-form>
  </form>

  <div class="collapsed-sections" role="tablist" *diAcl="'account.profile'">
    <!-- Metrics Data -->
    <div class="card" [hidden]="!model.id">
      <div
        class="card-header"
        role="tab"
        (click)="onToggleMetricsDataSection()"
        [attr.aria-expanded]="showMetricsDataSection"
      >
        <button class="btn btn-link">Metrics Data</button>

        <div class="collapsed-sections__indicators">
          <span [ngbTooltip]="'Section contains invalid or missing fields'" *ngIf="!isMetricsDataFormValid">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          </span>

          <i *ngIf="!showMetricsDataSection" class="fa fa-sort-desc di-collapse-toggle" aria-hidden="true"></i>
          <i *ngIf="showMetricsDataSection" class="fa fa-sort-asc di-collapse-toggle" aria-hidden="true"></i>
        </div>
      </div>

      <div [ngbCollapse]="!showMetricsDataSection">
        <div class="card-body">
          <form #metricsDataForm="ngForm" novalidate>
            <!-- Summary -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2" for="summary">Summary</label>
              <div class="col-sm-8 col-md-10">
                <textarea
                  id="summary"
                  class="form-control"
                  name="summary"
                  [(ngModel)]="model.summary"
                  [readonly]="!isFieldEditable('summary')"
                  required
                >
                </textarea>
              </div>
            </div>

            <!-- Summary Proof Links -->
            <div cdkDropList (cdkDropListDropped)="onReorderLinks($event, model.summaryLinks)">
              <div
                class="form-group row"
                *ngFor="let link of model.summaryLinks; let idx = index; trackBy: trackByIndex"
              >
                <label class="col-form-label col-sm-4 col-md-2" for="summaryLinks-{{ idx }}">Summary Proof Links</label>
                <div class="col-sm-8 col-md-10" cdkDrag>
                  <dirt-link
                    [(url)]="model.summaryLinks[idx]"
                    name="summaryLinks-{{ idx }}"
                    [form]="metricsDataForm"
                    [disabled]="!isFieldEditable('summaryLinks')"
                  >
                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="pushItemToList(model.summaryLinks)"
                      *ngIf="idx == model.summaryLinks.length - 1 && isFieldEditable('summaryLinks')"
                    >
                      <i class="fa fa-plus-circle"></i>
                    </button>

                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="removeFromByIndex(model.summaryLinks, idx)"
                      *ngIf="idx > 0 && isFieldEditable('summaryLinks')"
                    >
                      <i class="fa fa-minus-circle"></i>
                    </button>

                    <button type="button" class="btn btn-outline-secondary" title="Move Link" cdkDragHandle>
                      <i class="fa fa-bars fa-rotate-90" aria-hidden="true"></i>
                    </button>
                  </dirt-link>
                </div>
              </div>
            </div>

            <!-- Development Notes -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2" for="developmentNotes">Development Notes</label>
              <div class="col-sm-8 col-md-10">
                <textarea
                  id="developmentNotes"
                  class="form-control"
                  name="developmentNotes"
                  [(ngModel)]="model.developmentNotes"
                  [readonly]="!isFieldEditable('developmentNotes')"
                >
                </textarea>
              </div>
            </div>

            <!-- Development Notes Proof Link -->
            <div cdkDropList (cdkDropListDropped)="onReorderLinks($event, model.developmentNotesLinks)">
              <div
                class="form-group row"
                *ngFor="let link of model.developmentNotesLinks; let idx = index; trackBy: trackByIndex"
              >
                <label class="col-form-label col-sm-4 col-md-2" for="developmentNotesLinks-{{ idx }}"
                  >Development Notes Proof Link</label
                >
                <div class="col-sm-8 col-md-10" cdkDrag>
                  <dirt-link
                    [(url)]="model.developmentNotesLinks[idx]"
                    name="developmentNotesLinks-{{ idx }}"
                    [form]="metricsDataForm"
                    [disabled]="!isFieldEditable('developmentNotesLinks')"
                  >
                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="pushItemToList(model.developmentNotesLinks)"
                      *ngIf="idx == model.developmentNotesLinks.length - 1 && isFieldEditable('developmentNotesLinks')"
                    >
                      <i class="fa fa-plus-circle"></i>
                    </button>

                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="removeFromByIndex(model.developmentNotesLinks, idx)"
                      *ngIf="idx > 0 && isFieldEditable('developmentNotesLinks')"
                    >
                      <i class="fa fa-minus-circle"></i>
                    </button>

                    <button type="button" class="btn btn-outline-secondary" title="Move Link" cdkDragHandle>
                      <i class="fa fa-bars fa-rotate-90" aria-hidden="true"></i>
                    </button>
                  </dirt-link>
                </div>
              </div>
            </div>

            <!-- Economic Status -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2" for="economicStatus">Economic Status</label>
              <div class="col-md-3">
                <select
                  id="economicStatus"
                  class="form-control custom-select"
                  name="economicStatus"
                  [(ngModel)]="model.economicStatus"
                  [disabled]="!isFieldEditable('economicStatus')"
                  required
                >
                  <option *ngFor="let status of economicStatuses" [value]="status.code">
                    {{ status.title }}
                  </option>
                </select>
              </div>
            </div>

            <!-- Ownership Status -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2" for="ownershipStatus">Ownership Status</label>
              <div class="col-md-3">
                <select
                  id="ownershipStatus"
                  class="form-control custom-select"
                  name="ownershipStatus"
                  [(ngModel)]="model.ownershipStatus"
                  [disabled]="!isFieldEditable('ownershipStatus')"
                  required
                >
                  <option *ngFor="let status of ownershipStatuses" [value]="status.code">
                    {{ status.title }}
                  </option>
                </select>
              </div>
            </div>

            <!-- International Operations -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2">International Operations</label>
              <div class="col-sm-8 col-md-10">
                <ui-switch
                  size="small"
                  name="internationalOperations"
                  [(ngModel)]="model.internationalOperations"
                  [disabled]="!isFieldEditable('internationalOperations')"
                ></ui-switch>
              </div>
            </div>

            <!-- Market Share -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2" for="marketShare">Market Share</label>
              <div class="col-md-3">
                <div class="input-group">
                  <input
                    type="number"
                    id="marketShare"
                    class="form-control"
                    name="marketShare"
                    [(ngModel)]="model.marketShare"
                    [readonly]="!isFieldEditable('marketShare')"
                  />

                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
              </div>
            </div>

            <!-- Member of GPO -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2">Member of GPO</label>
              <div class="col-sm-8 col-md-10">
                <ui-switch
                  size="small"
                  name="isGPOMember"
                  [(ngModel)]="model.isGPOMember"
                  [disabled]="!isFieldEditable('isGPOMember')"
                ></ui-switch>
              </div>
            </div>

            <!-- GPO Name -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2" for="GPOName">GPO Name</label>
              <div class="col-sm-8 col-md-10">
                <input
                  type="text"
                  id="GPOName"
                  class="form-control"
                  name="GPOName"
                  [(ngModel)]="model.GPOName"
                  [readonly]="!isFieldEditable('GPOName')"
                  [required]="!!model.isGPOMember"
                />
              </div>
            </div>

            <!-- GPO Proof Link -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2">GPO Proof Link</label>
              <div class="col-sm-8 col-md-10">
                <dirt-link
                  [(url)]="model.GPOLink"
                  name="GPOLink"
                  [form]="metricsDataForm"
                  [disabled]="!isFieldEditable('GPOLink')"
                ></dirt-link>
              </div>
            </div>

            <!-- NCCN Member -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2">NCCN Member</label>
              <div class="col-sm-8 col-md-10">
                <ui-switch
                  size="small"
                  name="isNCCNMember"
                  [(ngModel)]="model.isNCCNMember"
                  [disabled]="!isFieldEditable('isNCCNMember')"
                ></ui-switch>
              </div>
            </div>

            <!-- Payer Mix -->
            <div class="form-group row" *ngFor="let p of model.payerMix; let idx = index; trackBy: trackByIndex">
              <label class="col-form-label col-sm-4 col-md-2" for="payerMix-{{ idx }}">Payer Mix</label>
              <div class="col-sm-8 col-md-10">
                <div class="input-group">
                  <input
                    type="text"
                    id="payerMix-{{ idx }}"
                    class="form-control"
                    name="payerMix-{{ idx }}"
                    [(ngModel)]="model.payerMix[idx]"
                    [readonly]="!isFieldEditable('payerMix')"
                  />

                  <div class="input-group-append">
                    <!-- Button: Add -->
                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="pushItemToList(model.payerMix)"
                      *ngIf="idx == model.payerMix.length - 1 && isFieldEditable('payerMix')"
                    >
                      <i class="fa fa-plus-circle"></i>
                    </button>

                    <!-- Button: Remove -->
                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="removeFromByIndex(model.payerMix, idx)"
                      *ngIf="idx > 0 && isFieldEditable('payerMix')"
                    >
                      <i class="fa fa-minus-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Own Health Insurance Plan -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2">Own Health Insurance Plan</label>
              <div class="col-sm-8 col-md-10">
                <ui-switch
                  size="small"
                  name="ownHealthInsurance"
                  [(ngModel)]="model.ownHealthInsurance"
                  [disabled]="!isFieldEditable('ownHealthInsurance')"
                ></ui-switch>
              </div>
            </div>

            <!-- Health Plan Name -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2" for="healthPlanName">Health Insurance Plan Name</label>
              <div class="col-sm-8 col-md-10">
                <input
                  type="text"
                  id="healthPlanName"
                  class="form-control"
                  name="healthPlanName"
                  [(ngModel)]="model.healthPlanName"
                  [readonly]="!isFieldEditable('healthPlanName')"
                  [required]="!!model.ownHealthInsurance"
                />
              </div>
            </div>

            <!-- Health Plan Network Type -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2" for="healthPlanNetworkType"
                >Health Plan Network Type</label
              >
              <div class="col-md-3">
                <dirt-multiselect-dropdown
                  id="healthPlanNetworkType"
                  name="healthPlanNetworkType"
                  [ngClass]="{ disabled: !isFieldEditable('healthPlanNetworkType') }"
                  [options]="healthPlanNetworkTypes"
                  [settings]="healthPlanNetworkTypeSettings"
                  [(ngModel)]="model.healthPlanNetworkType"
                >
                </dirt-multiselect-dropdown>
              </div>
            </div>

            <!-- Health Plan Product Categories -->
            <div
              class="form-group row"
              *ngFor="let p of model.healthPlanProductCategories; let idx = index; trackBy: trackByIndex"
            >
              <label class="col-form-label col-sm-4 col-md-2" for="healthPlanProductCategories-{{ idx }}"
                >Health Plan Product Category</label
              >
              <div class="col-sm-8 col-md-10">
                <div class="input-group">
                  <input
                    type="text"
                    id="healthPlanProductCategories-{{ idx }}"
                    class="form-control"
                    name="healthPlanProductCategories-{{ idx }}"
                    [(ngModel)]="model.healthPlanProductCategories[idx]"
                    [readonly]="!isFieldEditable('healthPlanProductCategories')"
                  />

                  <div class="input-group-append">
                    <!-- Button: Add -->
                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="pushItemToList(model.healthPlanProductCategories)"
                      *ngIf="
                        idx == model.healthPlanProductCategories.length - 1 &&
                        isFieldEditable('healthPlanProductCategories')
                      "
                    >
                      <i class="fa fa-plus-circle"></i>
                    </button>

                    <!-- Button: Remove -->
                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="removeFromByIndex(model.healthPlanProductCategories, idx)"
                      *ngIf="idx > 0 && isFieldEditable('healthPlanProductCategories')"
                    >
                      <i class="fa fa-minus-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Health Plan Members Count -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2" for="healthPlanMembersCount"
                >Number of Health Plan Members</label
              >
              <div class="col-md-3">
                <input
                  type="number"
                  id="healthPlanMembersCount"
                  class="form-control"
                  name="healthPlanMembersCount"
                  [(ngModel)]="model.healthPlanMembersCount"
                  [readonly]="!isFieldEditable('healthPlanMembersCount')"
                  integerValidator
                />
              </div>
            </div>

            <!-- Associated PBM -->
            <div class="form-group row">
              <label class="col-form-label col-sm-4 col-md-2" for="pharmacyBenefitManager"
                >Associated Pharmacy Benefit Manager</label
              >
              <div class="col-sm-8 col-md-10">
                <input
                  type="text"
                  id="pharmacyBenefitManager"
                  class="form-control"
                  name="pharmacyBenefitManager"
                  [(ngModel)]="model.pharmacyBenefitManager"
                  [readonly]="!isFieldEditable('pharmacyBenefitManager')"
                />
              </div>
            </div>

            <!-- Metrics Data Proof Links -->
            <div
              class="form-group row"
              *ngFor="let affLink of model.metricsDataLinks; let idx = index; trackBy: trackByIndex"
            >
              <label class="col-form-label col-sm-4 col-md-2" for="metricsDataLinks-{{ idx }}">Proof Link</label>
              <div class="col-sm-8 col-md-10">
                <dirt-link
                  [(url)]="model.metricsDataLinks[idx]"
                  name="metricsDataLinks-{{ idx }}"
                  [form]="metricsDataForm"
                  [disabled]="!isFieldEditable('metricsDataLinks')"
                >
                  <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="pushItemToList(model.metricsDataLinks)"
                    *ngIf="idx == model.metricsDataLinks.length - 1 && isFieldEditable('metricsDataLinks')"
                  >
                    <i class="fa fa-plus-circle"></i>
                  </button>

                  <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="removeFromByIndex(model.metricsDataLinks, idx)"
                    *ngIf="idx > 0 && isFieldEditable('metricsDataLinks')"
                  >
                    <i class="fa fa-minus-circle"></i>
                  </button>
                </dirt-link>
              </div>
            </div>

            <!-- List of Departments -->
            <div>
              <div
                class="form-group row"
                *ngFor="let link of model.departmentNames; let idx = index; trackBy: trackByIndex"
              >
                <label class="col-form-label col-sm-4 col-md-2" for="departmentNames-{{ idx }}"
                  >List of Departments</label
                >
                <div class="col-sm-8 col-md-10">
                  <div class="input-group">
                    <input
                      type="text"
                      id="departmentNames-{{ idx }}"
                      class="form-control"
                      name="departmentNames-{{ idx }}"
                      [(ngModel)]="model.departmentNames[idx]"
                      [readonly]="!isFieldEditable('departmentNames')"
                    />

                    <div class="input-group-append">
                      <!-- Button: Add -->
                      <button
                        type="button"
                        class="btn btn-secondary"
                        (click)="pushItemToList(model.departmentNames)"
                        *ngIf="idx === model.departmentNames.length - 1 && isFieldEditable('departmentNames')"
                      >
                        <i class="fa fa-plus-circle"></i>
                      </button>

                      <!-- Button: Remove -->
                      <button
                        type="button"
                        class="btn btn-secondary"
                        (click)="removeFromByIndex(model.departmentNames, idx)"
                        *ngIf="idx > 0 && isFieldEditable('departmentNames')"
                      >
                        <i class="fa fa-minus-circle"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- List of Departments Proof Link -->
            <div cdkDropList>
              <div
                class="form-group row"
                *ngFor="let link of model.departmentNamesLinks; let idx = index; trackBy: trackByIndex"
              >
                <label class="col-form-label col-sm-4 col-md-2" for="departmentNamesLinks-{{ idx }}"
                  >List of Departments Proof Link</label
                >
                <div class="col-sm-8 col-md-10" cdkDrag>
                  <dirt-link
                    [(url)]="model.departmentNamesLinks[idx]"
                    name="departmentNamesLinks-{{ idx }}"
                    [form]="metricsDataForm"
                    [disabled]="!isFieldEditable('departmentNamesLinks')"
                    [required]="isListOfDepartmentsProofLinkRequired()"
                  >
                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="pushItemToList(model.departmentNamesLinks)"
                      *ngIf="idx === model.departmentNamesLinks.length - 1 && isFieldEditable('departmentNamesLinks')"
                    >
                      <i class="fa fa-plus-circle"></i>
                    </button>

                    <button
                      type="button"
                      class="btn btn-secondary"
                      (click)="removeFromByIndex(model.departmentNamesLinks, idx)"
                      *ngIf="idx > 0 && isFieldEditable('departmentNamesLinks')"
                    >
                      <i class="fa fa-minus-circle"></i>
                    </button>

                    <button type="button" class="btn btn-outline-secondary" title="Move Link" cdkDragHandle>
                      <i class="fa fa-bars fa-rotate-90" aria-hidden="true"></i>
                    </button>
                  </dirt-link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #searchResultTemplate let-r="result" let-t="term">
  <ng-container>
    <div class="di-sac-verified pull-left">
      <dirt-verified-icon color="success" *ngIf="r?.verified"></dirt-verified-icon>
    </div>

    <div class="di-sac-affiliation pull-left">
      <div class="di-sac-name">{{ r?.name }}</div>
      <div class="di-sac-department">{{ r?.department || '&mdash;' }}</div>
      <div class="di-sac-address">{{ r?.address?.formatted || '&mdash;' }}</div>
    </div>

    <div class="pull-right row">
      <div
        [ngClass]="{ 'di-sac-lfka-status pull-left': r?.sourceLfka, 'di-sac-lfta-status pull-left': !r?.sourceLfka }"
      >
        {{ r?.sourceLfka ? 'LFKA' : 'LFTA' }}
      </div>
    </div>
  </ng-container>
</ng-template>
