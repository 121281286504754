import { Component, OnChanges, OnDestroy, OnInit, HostListener, SimpleChanges, Input } from '@angular/core';
import { TimeTracking } from '../../shared/time-tracking';
import { TimeTrackingAPI } from '../../shared/api.service';
import { ValueAPI } from '../../../../shared/services/value/value-api.service';
import { obtainClientConfig } from '../../../client-config';

const LAST_RECORD = 'clean_timetrack_last_record';
const LAST_REPORT = 'clean_timetrack_last_report';
const CURRENT_REPORT_PATH = 'clean_timetrack_current_report_path';
const CURRENT_REPORT_OBJ = 'clean_timetrack_current_report_obj';
const CURRENT_REPORT_OBJ_CHG = 'clean_timetrack_current_report_obj_chg';
const CURRENT_REPORT_OBJ_IDS = 'clean_timetrack_current_report_obj_ids';
const WATCH_INTERVAL = 15000; // ms
let MAX_CONTINGENCY = 60000; // ms; client-config: TRACK_CONTINGENCY
const SUBMIT_AT = 15 * 60 * 1000; // ms
const PATH_FIELDS: (keyof TimeReportComponent)[] = ['major', 'id', 'minor', 'state'];

@Component({
  selector: 'dirt-time-report-component',
  template: '', // purely invisible -
})
export class TimeReportComponent implements OnInit, OnChanges, OnDestroy {
  constructor(private readonly svcTimeTracking: TimeTrackingAPI, private readonly svcValue: ValueAPI) {
    this.loadClientConfig();
  }

  private async loadClientConfig() {
    try {
      const cfg = await obtainClientConfig(this.svcValue);
      const cont = parseInt(cfg['TRACK_CONTINGENCY'], 10);
      if (cont && cont > 0) {
        MAX_CONTINGENCY = cont;
      }
    } catch (_e) {
      console.error(_e); // & that's it. NEVER hinder work
    }
  }

  @Input()
  major: string = null;
  @Input()
  id: string = null;
  @Input()
  minor: string = null;
  @Input()
  state: string = null;

  lastActionTs: number = 0;
  watcherHandle: number = null;

  protected wndw: Window = window; // allow for testing

  private getLastRecordedTs(): number {
    return parseInt(this.wndw.localStorage.getItem(LAST_RECORD) || (0 as any), 10);
  }
  private getLastReportedTs(): number {
    return parseInt(this.wndw.localStorage.getItem(LAST_REPORT) || (0 as any), 10);
  }
  private getCurrentReportPath(): string {
    return this.wndw.localStorage.getItem(CURRENT_REPORT_PATH);
  }
  private getCurrentReport(): { [path: string]: number /*ms*/ } {
    return JSON.parse(this.wndw.localStorage.getItem(CURRENT_REPORT_OBJ) || '{}');
  }
  private getCurrentReportChg(): { [path: string]: number /*count*/ } {
    return JSON.parse(this.wndw.localStorage.getItem(CURRENT_REPORT_OBJ_CHG) || '{}');
  }
  private getCurrentReportIds(): { [path: string]: string[] } {
    return JSON.parse(this.wndw.localStorage.getItem(CURRENT_REPORT_OBJ_IDS) || '{}');
  }

  private goingIn() {
    try {
      this.lastActionTs = new Date().getTime();
      this.recordUntil(this.lastActionTs);
      let pathComps = PATH_FIELDS.map((_f) => this[_f]); // 2nd is always an object ID in BE
      while (!pathComps[pathComps.length - 1]) {
        pathComps = pathComps.slice(0, pathComps.length - 1);
      }
      const path = pathComps.join('/');
      this.wndw.localStorage.setItem(CURRENT_REPORT_PATH, path);
      this.lastActionTs = new Date().getTime();
      this.recordUntil(this.lastActionTs); // again to save new
      //console.log('goingIn ' + path + ' - ' + JSON.stringify(this.getCurrentReport()));
      this.killWatcher();
      this.watcherHandle = this.wndw.setInterval(() => {
        this.recordUntil(this.lastActionTs);
      }, WATCH_INTERVAL);
    } catch (_e) {
      console.error(_e); // & that's it. NEVER hinder work
    }
  }
  private leaving() {
    try {
      this.killWatcher();
      this.recordUntil(this.lastActionTs);
      //console.log('leaving ' + JSON.stringify(this.getCurrentReport()));
    } catch (_e) {
      console.error(_e); // & that's it. NEVER hinder work
    }
  }
  ngOnInit(): void {
    this.goingIn();
  }
  ngOnDestroy(): void {
    this.leaving();
  }
  ngOnChanges(changes: SimpleChanges & keyof TimeReportComponent): void {
    const chg = PATH_FIELDS.filter(
      (f) => changes[f] && !changes[f].firstChange && changes[f].previousValue !== changes[f].currentValue
    );
    if (chg.length > 0) {
      this.leaving();
      this.goingIn();
    }
  }

  public trackSubmittedChange(changedIds?: string[]) {
    // called from outside via component ref
    try {
      const currPath = this.getCurrentReportPath();
      if (!currPath) {
        return;
      }
      const currRepChg = this.getCurrentReportChg();
      const nextRepChg = {
        ...currRepChg,
        [currPath]: (currRepChg[currPath] || 0) + 1,
      };
      const currRepIds = this.getCurrentReportIds();
      const nextRepIds = {
        ...currRepIds,
        [currPath]: [
          ...(currRepIds[currPath] || []),
          ...(changedIds || []).filter((_id) => (currRepIds[currPath] || []).indexOf(_id) < 0),
        ],
      };
      //console.log('Recording chg ' + JSON.stringify(nextRepChg));
      this.wndw.localStorage.setItem(CURRENT_REPORT_OBJ_CHG, JSON.stringify(nextRepChg));
      this.wndw.localStorage.setItem(CURRENT_REPORT_OBJ_IDS, JSON.stringify(nextRepIds));
    } catch (_e) {
      console.error(_e); // & that's it. NEVER hinder work
    }
  }

  private killWatcher(): void {
    if (this.watcherHandle) {
      this.wndw.clearInterval(this.watcherHandle);
      this.watcherHandle = null;
    }
  }

  private recordUntil(lastActionTakenTs: number) {
    const currPath = this.getCurrentReportPath();
    if (!currPath) {
      return;
    }
    const currRep = this.getCurrentReport();
    const prevActionTakenTs = this.getLastRecordedTs();
    const sinceLastAction = lastActionTakenTs - prevActionTakenTs;
    if (prevActionTakenTs > 0 && new Date(prevActionTakenTs).getDay() !== new Date(lastActionTakenTs).getDay()) {
      this.submitWhenFull(prevActionTakenTs, true);
    }
    if (sinceLastAction > 0 && sinceLastAction <= MAX_CONTINGENCY) {
      // it counts
      const nextRep = {
        ...currRep,
        [currPath]: (currRep[currPath] || 0) + sinceLastAction,
      };
      //console.log('Recording ' + JSON.stringify(nextRep));
      this.wndw.localStorage.setItem(CURRENT_REPORT_OBJ, JSON.stringify(nextRep));
    } // else drop it
    this.wndw.localStorage.setItem(LAST_RECORD, '' + lastActionTakenTs);
    this.submitWhenFull(lastActionTakenTs);
  }
  private async submitWhenFull(ts: number, force?: boolean) {
    const currRep = this.getCurrentReport();
    const currRepChg = this.getCurrentReportChg();
    const currRepIds = this.getCurrentReportIds();
    let sum = 0;
    Object.values(currRep).forEach((_t) => (sum += _t));
    if (force || sum >= SUBMIT_AT) {
      this.wndw.localStorage.setItem(CURRENT_REPORT_OBJ, '{}');
      this.wndw.localStorage.setItem(CURRENT_REPORT_OBJ_CHG, '{}');
      this.wndw.localStorage.setItem(CURRENT_REPORT_OBJ_IDS, '{}');
      const trackings: TimeTracking[] = [];
      Object.entries(currRep).map((e) => {
        const t = new TimeTracking();
        const p = e[0].split('/');
        t.major = p[0] || null;
        t.refId = p[1] || null;
        t.minor = p[2] || null;
        t.status = p[3] || null;
        t.timeSpentMs = e[1];
        t.changesSubmitted = currRepChg[e[0]] || 0;
        t.pointsChanged = (currRepIds[e[0]] || []).length;
        trackings.push(t);
      });
      await this.svcTimeTracking.postTrackings({ trackings, lastTs: new Date(ts) }).toPromise();
      this.wndw.localStorage.setItem(LAST_REPORT, '' + new Date().getTime());
    }
  }

  @HostListener('document:keyup')
  onGlobalKeyAction() {
    this.lastActionTs = new Date().getTime();
  }
  @HostListener('document:mousemove')
  onGlobalMouseAction() {
    this.lastActionTs = new Date().getTime();
  }

  @HostListener('document:visibilitychange')
  onGlobalVisibility() {
    if (this.wndw.document.hidden) {
      this.leaving();
    } else {
      this.goingIn();
    }
  }
}
