import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { Profile } from '../profile';
import { ProfileAPI } from '../api.service';
import { ProfileStatus } from '../constant/status.enum';
import { PersonAPI } from '../../../person/shared/api.service';
import { Person } from '../../../person/shared/person';

@Component({
  selector: 'dirt-profile-submit',
  templateUrl: 'submit.component.html',
  styleUrls: ['submit.component.scss'],
})
export class ProfileSubmitComponent {
  @Input()
  profile: Profile;

  @Input()
  isSaving: boolean;

  @Input()
  mandatoryUnpolishedDps: number = 0;

  isLoading = false;

  constructor(private router: Router, private svcProfile: ProfileAPI) {}

  onSubmit(): void {
    if (this.mandatoryUnpolishedDps > 0) {
      // introduce client-side validation for all that come via outboxes
      return alert(
        'The profile is not completely polished yet.\nThere are ' +
          this.mandatoryUnpolishedDps +
          ' mandatory data points left to polish. '
      );
    }

    // remove previous exceptions and reduce to what's actually needed

    this.isLoading = true;

    this.svcProfile.status(this.profile.id, this.profile.viewMode).subscribe({
      next: (resp) => this.validateStatus(resp),
      complete: () => (this.isLoading = false),
    });
  }

  private submit(): void {
    this.svcProfile
      .update(this.profile.id, <Profile>({
        published: true,
        _meta: { ...this.profile._meta, status: ProfileStatus.DONE, claimedUntil: null },
      } as unknown))
      .subscribe(() => this.goBack());
  }

  private validateStatus(resp: any): void {
    let showMessage = false;
    const msg = [
      'The profile is not completely polished yet.',
      'Please finalize the followings before submitting the profile:\n',
    ];

    Object.keys(resp).forEach((field) => {
      if (resp[field] > 0) {
        showMessage = true;
        msg.push(`${field}: ${resp[field]} items`);
      }
    });

    if (showMessage) {
      return alert(msg.join('\n'));
    }

    this.submit();
  }

  private goBack() {
    this.router.navigate(['/profile/list']);
  }
}
