import { firstValueFrom, from, map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Body, MediaType, PATCH, Path, POST, Produces, PUT } from '../../../../shared/services/rest/rest.service';
import { ProfilePodcast } from '../../../shared/profile-podcast';
import { SubentityApiService } from '../../common/subentity-api.service';
import { Profile } from '../../../shared/profile';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { filterStream, parseJSONStream, splitStream } from '../../stream.utils';

type ProfilePodcastRaw = {
  match_id: string; // uuidv4
  person_id: string;
  episode_id: string;
  episode_person_id: string;
  match_result: string;
  ml_exclude: boolean;
  first_name: string;
  last_name: string;
  role: string;
  affiliation?: string;
  focus_areas: { name: string; id: string }[];
  match_source?: string;
};

type PodcastBaseInfo = {
  episodeId: string;
  episodeOriginalTitle: string;
  podcastOriginalTitle: string;
  podcastOriginalDescription: string;
  url: string;
  publicationDate: string;
};

@Injectable()
export class ProfilePodcastAPI extends SubentityApiService<
  ProfilePodcast,
  { id; _profile: ProfilePodcast },
  ProfilePodcast[],
  { role }
> {
  @PATCH('profiles/{id}/podcasts')
  @Produces(MediaType.JSON)
  public updateImpl(@Path('id') id: string, @Body matches: ProfilePodcast[]): Observable<ProfilePodcast[]> {
    return;
  }

  @PUT('profiles/{id}/podcasts/rel/{fk}')
  @Produces(MediaType.JSON)
  public linkImpl(
    @Path('id') id: string,
    @Path('fk') episodeId: string,
    @Body data: { role: string }
  ): Observable<ProfilePodcast> {
    return;
  }

  @POST('podcasts/base-info')
  @Produces(MediaType.JSON)
  public getPodcastBaseInfo(@Body episodeIds: string[]): Observable<PodcastBaseInfo[]> {
    return;
  }

  public findAll(profile: Profile): Observable<ProfilePodcast[]> {
    return from(this.findAllAutomated(profile));
  }

  protected async enrichAll(
    profile: Profile,
    allList: ProfilePodcast[]
  ): Promise<{ id: any; _profile: ProfilePodcast }[]> {
    const curatedMatches = await firstValueFrom(this.findAllCurated(profile));
    const curatedMatchesById = new Map(curatedMatches.map((match) => [match.matchId, match]));

    // 1 - Augment auto matches with manual info
    allList.forEach((match) => {
      if (!curatedMatchesById.has(match.matchId)) {
        return;
      }

      const curatedMatch = curatedMatchesById.get(match.matchId);

      match.id = curatedMatch.id;
      match.match.manual = curatedMatch.match.manual;
      // TODO: check if we need pendingSubmission as well
      match.compiledAt = curatedMatch.compiledAt;
      match.qc = curatedMatch.qc;
    });

    // 2 - Append fully manual matches
    allList.push(...curatedMatches.filter((match) => !!match.match.direct));

    // 3 - Augment with source info and transform
    const episodeIds = [...new Set(allList.map((match) => match.episodeId))]; // make sure we don't fetch the same thing multiple time
    const podcastsByEpisodeId = new Map<string, PodcastBaseInfo>();

    const PAGE_SIZE = 2500;
    for (let i = 0; i < episodeIds.length; i += PAGE_SIZE) {
      const podcasts = await firstValueFrom(this.getPodcastBaseInfo(episodeIds.slice(i, i + PAGE_SIZE)));
      podcasts.forEach((podcast) => podcastsByEpisodeId.set(podcast.episodeId, podcast));
    }

    return allList
      .filter((match) => podcastsByEpisodeId.has(match.episodeId)) // Those without data in source are not relevant
      .map((match) => {
        // augment with source and transform
        const podcast = podcastsByEpisodeId.get(match.episodeId);

        match.episodeOriginalTitle = podcast.episodeOriginalTitle;
        match.podcastOriginalTitle = podcast.podcastOriginalTitle;
        match.podcastOriginalDescription = podcast.podcastOriginalDescription;
        match.podcastUrl = podcast.url;
        match.publicationDate = podcast.publicationDate;

        return {
          id: match.id,
          _profile: match,
        };
      });
  }

  private async findAllAutomated(profile: Profile): Promise<ProfilePodcast[]> {
    const options: RequestInit = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
      signal: (AbortSignal as any).timeout(10_000),
    };
    const response: Response = await fetch(
      this.getBaseUrl() + 'profiles/' + profile.id + '/podcasts/automated',
      options
    ); // (we want a stream now)
    if (!response.ok) {
      throw new Error('Failed to download automated matches');
    }

    // For automated matches, we need to get the base profile matches, the clone will not have anything under its name.
    const identifier = profile.training ? profile.baseProfileKolId : profile.person.kolId;
    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(splitStream('\n'))
      .pipeThrough(filterStream((row: string) => row.includes(identifier))) // save us some JSON parsing
      .pipeThrough(parseJSONStream())
      .pipeThrough(filterStream((row: ProfilePodcastRaw) => row.person_id === identifier)) // Catch the very few cases that we could leave out
      .pipeThrough(transformToMatch())
      .getReader();

    const matches: ProfilePodcast[] = [];

    while (true) {
      const result = await reader.read(); // Leave timeout to Nginx

      if (!result) {
        // Nothing in the stream
        break;
      }

      if (result.done) {
        break; // Everything was read
      }

      matches.push(result.value);
    }

    // We're done
    reader.cancel();

    return matches;
  }

  private findAllCurated(profile: Profile): Observable<ProfilePodcast[]> {
    return this.http.get(this.getBaseUrl() + 'profiles/' + profile.id + '/podcasts/curated').pipe(
      map<any, ProfilePodcast[]>((res: any[]) => {
        return res.map((row) => ({
          id: row.id,
          matchId: row.matchId,
          episodeId: row.episodeId,
          episodePersonId: row.episodePersonId,
          mention: row.mention,
          match: {
            ...row.match,
          },
          qc: row.qc,
          compiledAt: row.compiledAt,

          // Fill later from podcast data
          episodeOriginalTitle: null,
          podcastOriginalTitle: null,
          podcastOriginalDescription: null,
          podcastUrl: null,
          publicationDate: null,
        }));
      })
    );
  }
}

// (took the liberty of moving the other helper functions to a util ts)

function transformToMatch() {
  return new TransformStream({
    transform(chunk, controller) {
      const match: ProfilePodcast = {
        id: chunk.match_id, // Will override with Mongo ID from curated match if we have it
        matchId: chunk.match_id,
        episodeId: chunk.episode_id,
        episodePersonId: chunk.episode_person_id,
        mention: {
          firstName: chunk.first_name,
          lastName: chunk.last_name,
          role: chunk.role,
          affiliation: chunk.affiliation,
        },
        match: {
          automate: chunk.match_result,
          manual: ProfileMatchManual.NONE, // Will enrich via curated matches
          matchSource: chunk.match_source,
          mlExclude: chunk.ml_exclude,
        },
        focusAreas: chunk.focus_areas?.map((fa) => fa.name),

        // Fill later from podcast data
        episodeOriginalTitle: null,
        podcastOriginalTitle: null,
        podcastOriginalDescription: null,
        podcastUrl: null,
        publicationDate: null,
      };

      controller.enqueue(match);
    },
  });
}
