import { Pipe, PipeTransform } from '@angular/core';

/**
 * Converts enum values to array to be usable by *ngFor
 */
@Pipe({ name: 'keys' })
export class KeysPipe implements PipeTransform {
  transform(value: Object, args?: string[]): Object[] {
    const keys = [];

    for (const member in value) {
      const isProperty = parseInt(member, 10) >= 0;

      if (isProperty) {
        keys.push(value[member]);
      }
    }

    return keys;
  }
}
