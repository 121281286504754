<div class="clearfix">
  <form #ngForm="ngForm" novalidate>
    <!-- Year -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="year">Year</label>
      <div class="col-md-3">
        <input
          type="number"
          id="year"
          class="form-control"
          name="year"
          [(ngModel)]="model.year"
          [readonly]="!isFieldEditable('year')"
          min="1900"
          autofocus
          required
          integerValidator
        />
      </div>
    </div>

    <!-- Target Patient Population Size -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="targetPatientPopulationCount"
        >Target patient population count</label
      >
      <div class="col-md-3">
        <input
          type="number"
          id="targetPatientPopulationCount"
          class="form-control"
          name="targetPatientPopulationCount"
          [(ngModel)]="model.targetPatientPopulationCount"
          [readonly]="!isFieldEditable('targetPatientPopulationCount')"
          integerValidator
        />
      </div>
    </div>

    <!-- Affiliated Hospitals Count -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="affiliatedHospitalsCount">Affiliated Hospitals Count</label>
      <div class="col-md-3">
        <input
          type="number"
          id="affiliatedHospitalsCount"
          class="form-control"
          name="affiliatedHospitalsCount"
          [(ngModel)]="model.affiliatedHospitalsCount"
          [readonly]="!isFieldEditable('affiliatedHospitalsCount')"
          integerValidator
        />
      </div>
    </div>

    <!-- Affiliated Outpatient Sites Count -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="affiliatedOutpatientSitesCount"
        >Affiliated Outpatient Sites Count</label
      >
      <div class="col-md-3">
        <input
          type="number"
          id="affiliatedOutpatientSitesCount"
          class="form-control"
          name="affiliatedOutpatientSitesCount"
          [(ngModel)]="model.affiliatedOutpatientSitesCount"
          [readonly]="!isFieldEditable('affiliatedOutpatientSitesCount')"
          integerValidator
        />
      </div>
    </div>

    <!-- Beds Count -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="bedsCount">Beds Count</label>
      <div class="col-md-3">
        <input
          type="number"
          id="bedsCount"
          class="form-control"
          name="bedsCount"
          [(ngModel)]="model.bedsCount"
          [readonly]="!isFieldEditable('bedsCount')"
          integerValidator
        />
      </div>
    </div>

    <!-- Physicians Count -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="physiciansCount">Physicians Count</label>
      <div class="col-md-3">
        <input
          type="number"
          id="physiciansCount"
          class="form-control"
          name="physiciansCount"
          [(ngModel)]="model.physiciansCount"
          [readonly]="!isFieldEditable('physiciansCount')"
          integerValidator
        />
      </div>
    </div>

    <!-- Patients In Count -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="patientsInCount">Patients In Count</label>
      <div class="col-md-3">
        <input
          type="number"
          id="patientsInCount"
          class="form-control"
          name="patientsInCount"
          [(ngModel)]="model.patientsInCount"
          [readonly]="!isFieldEditable('patientsInCount')"
          integerValidator
        />
      </div>
    </div>

    <!-- Patients Out Count -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="patientsOutCount">Patients Out Count</label>
      <div class="col-md-3">
        <input
          type="number"
          id="patientsOutCount"
          class="form-control"
          name="patientsOutCount"
          [(ngModel)]="model.patientsOutCount"
          [readonly]="!isFieldEditable('patientsOutCount')"
          integerValidator
        />
      </div>
    </div>

    <!-- Patients Count Total -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="patientsTotalCount">Patients Count Total</label>
      <div class="col-md-3">
        <input
          type="number"
          id="patientsTotalCount"
          class="form-control"
          name="patientsTotalCount"
          [(ngModel)]="model.patientsTotalCount"
          [readonly]="!isFieldEditable('patientsTotalCount')"
          integerValidator
        />
      </div>
    </div>

    <!-- Websources -->
    <div class="form-group row" *ngFor="let affLink of model.websources; let idx = index; trackBy: trackByIndex">
      <label class="col-form-label col-sm-4 col-md-2" for="websources-{{ idx }}">Websource</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.websources[idx]"
          name="websources-{{ idx }}"
          [form]="ngForm"
          [disabled]="!isFieldEditable('websources')"
        >
          <button
            type="button"
            class="btn btn-secondary"
            (click)="pushItemToList(model.websources)"
            *ngIf="idx == model.websources.length - 1 && isFieldEditable('websources')"
          >
            <i class="fa fa-plus-circle"></i>
          </button>

          <button
            type="button"
            class="btn btn-secondary"
            (click)="removeFromByIndex(model.websources, idx)"
            *ngIf="idx > 0 && isFieldEditable('websources')"
          >
            <i class="fa fa-minus-circle"></i>
          </button>
        </dirt-link>
      </div>
    </div>

    <!-- Number of employed Physicians -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="employedPhysiciansCount"
        >Number of employed Physicians</label
      >
      <div class="col-md-3">
        <input
          type="number"
          id="employedPhysiciansCount"
          class="form-control"
          name="employedPhysiciansCount"
          [(ngModel)]="model.employedPhysiciansCount"
          [readonly]="!isFieldEditable('employedPhysiciansCount')"
        />
      </div>
    </div>

    <!-- Number of employed Physicians Prooflink -->
    <div class="form-group row" *ngIf="model.employedPhysiciansCount != null">
      <label class="col-form-label col-sm-4 col-md-2">Employed Physicians Proof Link</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.employedPhysiciansCountProofLink"
          name="employedPhysiciansCountProofLink"
          [form]="ngForm"
          [disabled]="!isFieldEditable('employedPhysiciansCountProofLink')"
          [required]="true"
        ></dirt-link>
      </div>
    </div>

    <!-- Number of contracted Physicians -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="contractedPhysiciansCount"
        >Number of contracted Physicians</label
      >
      <div class="col-md-3">
        <input
          type="number"
          id="contractedPhysiciansCount"
          class="form-control"
          name="contractedPhysiciansCount"
          [(ngModel)]="model.contractedPhysiciansCount"
          [readonly]="!isFieldEditable('contractedPhysiciansCount')"
        />
      </div>
    </div>

    <!-- Number of contracted Physicians Prooflink -->
    <div class="form-group row" *ngIf="model.contractedPhysiciansCount != null">
      <label class="col-form-label col-sm-4 col-md-2">Contracted Physicians Proof Link</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.contractedPhysiciansCountProofLink"
          name="contractedPhysiciansCountProofLink"
          [form]="ngForm"
          [disabled]="!isFieldEditable('contractedPhysiciansCountProofLink')"
          [required]="true"
        ></dirt-link>
      </div>
    </div>

    <!-- Number of departments -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="departmentsCount">Number of departments</label>
      <div class="col-md-3">
        <input
          type="number"
          id="departmentsCount"
          class="form-control"
          name="departmentsCount"
          [(ngModel)]="model.departmentsCount"
          [readonly]="!isFieldEditable('departmentsCount')"
        />
      </div>
    </div>
  </form>
</div>
