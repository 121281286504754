import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { tap, concatMap, take, catchError } from 'rxjs/operators';
import { AccountPayerInformation } from '../shared/account-payer-infomation';
import { AccountPayerInformationAPI } from '../shared/account-payer-information-api.service';

@Component({
  selector: 'dirt-account-payer-information-detail',
  templateUrl: './detail.component.html',
})
export class AccountPayerInformationDetailComponent implements OnInit {
  id: string;

  accountPayerInformation: AccountPayerInformation;

  isLoading = true;

  isSubmitting = false;

  isFormValid: boolean;

  private wndw: Window = window; // allow for testing

  isTotalShareValid: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public svcAccountPayerInformation: AccountPayerInformationAPI // used in template too
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        take(1),
        tap(() => (this.isLoading = true)),
        concatMap((params) => {
          this.wndw.scrollTo(0, 0);

          this.id = params['id'];

          return this.svcAccountPayerInformation
            .findById(this.id)
            .pipe(tap((accountPayerInformation) => (this.accountPayerInformation = accountPayerInformation)));
        }),
        tap(() => (this.isLoading = false))
      )
      .subscribe();
  }

  goBack(): void {
    this.router.navigate(['/account/detail', this.accountPayerInformation.account.id]);
  }

  onSave(accountPayerInformation: AccountPayerInformation): void {
    this.updateAccountPayerInformation(accountPayerInformation);
  }

  onFormValidityChange(status: string): void {
    setTimeout(() => (this.isFormValid = status === 'VALID'));
  }

  private updateAccountPayerInformation(accountPayerInformation: AccountPayerInformation): void {
    if (!this.isFormValid) {
      return;
    }

    if (!this.isTotalShareValid) {
      alert('Total health payer share should be 100');
      return;
    }

    this.isSubmitting = true;

    this.svcAccountPayerInformation
      .update(this.id, accountPayerInformation)
      .pipe(
        tap(() => (this.isSubmitting = false)),
        catchError((err) => {
          this.isSubmitting = false;
          throw err;
        })
      )
      .subscribe(() => {
        this.goBack();
      });
  }

  handleSharesValidityChange(isValid: boolean) {
    this.isTotalShareValid = isValid;
  }
}
