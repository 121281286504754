import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { concatMap, firstValueFrom, of, take, tap } from 'rxjs';

import { ACL } from '../../shared/acl/acl.service';
import { Auth } from '../../shared/services/auth/auth.service';
import { Event } from '../../event/shared/event';
import { EventSeries } from '../shared/event-series';
import { EventSeriesAPI } from '../shared/api.service';
import { Job } from '../../jobs/shared/job';
import { JobsAPI } from '../../jobs/shared/api.service';
import { User } from '../../user/shared/user';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'dirt-event-eventSeries-detail',
  templateUrl: './detail.component.html',
})
export class EventSeriesDetailComponent implements OnInit {
  id: string;

  eventSeries: EventSeries;

  isLoading = true;

  isSubmitting = false;

  isFormValid: boolean;

  canSubmitNonJob: boolean;

  canSubmitJob: boolean;

  canCreateComments: boolean;

  hasJobForCurrentUser: boolean;

  currentJob: Job;

  private currentUser: User;

  private wndw: Window = window; // allow for testing

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcAcl: ACL,
    private svcAuth: Auth,
    private svcJob: JobsAPI,
    public svcEventSeries: EventSeriesAPI, // used in template too
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.canSubmitNonJob = this.svcAcl.hasCredential('eventSeries.update');
    this.canSubmitJob = this.svcAcl.hasCredential('job.submit');
    this.canCreateComments = this.svcAcl.hasCredential('eventSeries.comment.create');

    this.route.params
      .pipe(
        take(1),
        tap(() => {
          this.isLoading = true;

          // Make sure we cannot submit something we shouldn't under the pretense that we are working a job
          this.currentJob = null;
          this.hasJobForCurrentUser = false;
        }),
        concatMap((params) => {
          window.scrollTo(0, 0);

          this.id = params['id'];

          return this.svcEventSeries.findById(this.id).pipe(
            tap((eventSeries) => {
              this.eventSeries = eventSeries;
              this.titleService.setTitle(`cLean | Event Series| ${this.eventSeries.name}`);
            })
          );
        }),
        concatMap(() => {
          if (this.currentUser) {
            // We already know current user, don't waste time fetching again
            return of(this.currentUser);
          }

          return this.svcAuth.getProfile().pipe(tap((user) => (this.currentUser = user)));
        }),
        concatMap(() => {
          // Only get job details if user is the one working it
          if (!(this.eventSeries._meta?.jobId && this.eventSeries._meta?.assignee === this.currentUser.user_id)) {
            return of(null);
          }

          return this.svcJob.findById(this.eventSeries._meta.jobId).pipe(
            tap((job) => {
              if (job) {
                this.currentJob = job;
                this.hasJobForCurrentUser =
                  this.svcAcl.hasCredential('job.submit') &&
                  this.eventSeries._meta.jobId &&
                  this.currentUser.user_id === this.eventSeries._meta.assignee;
              }
            })
          );
        }),
        tap(() => (this.isLoading = false))
      )
      .subscribe();
  }

  goBack(): void {
    this.router.navigate(this.hasJobForCurrentUser ? ['/next'] : ['/event-series']);
  }

  onSave(eventSeries: EventSeries): void {
    if (!this.canSubmitNonJob || !this.isFormValid || this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    this.svcEventSeries
      .update(this.id, eventSeries)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  onFormValidityChange(status: string): void {
    setTimeout(() => (this.isFormValid = status === 'VALID'));
  }

  onJobUtc(): void {
    if (!this.hasJobForCurrentUser || this.isSubmitting) {
      return;
    }

    let comment = '';
    while (comment.length < 3) {
      comment = this.wndw.prompt('Enter a comment for Unable to Compile...');
      if (null === comment) {
        return;
      }
    }

    this.isSubmitting = true;
    this.svcJob
      .setUtc(this.currentJob._id, comment)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  onJobNoInfo(): void {
    if (!this.hasJobForCurrentUser || this.isSubmitting) {
      return;
    }

    this.eventSeries.noInfo = true;
    this.eventSeries._meta.consecutiveNoInfoCount = (this.eventSeries._meta.consecutiveNoInfoCount || 0) + 1;

    this.isSubmitting = true;
    this.svcEventSeries
      .submitJob(this.eventSeries)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  async onJobDraft(): Promise<void> {
    if (!this.hasJobForCurrentUser || this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    this.svcEventSeries
      .update(this.id, this.eventSeries)
      .pipe(
        concatMap(() => this.svcJob.setDraft(this.currentJob._id)),
        tap(() => (this.isSubmitting = false))
      )
      .subscribe(() => {
        this.goBack();
      });
  }

  onSubmitJob(eventSeries: EventSeries): void {
    if (!this.hasJobForCurrentUser || !this.isFormValid || this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;
    this.svcEventSeries
      .submitJob(eventSeries)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  onEventsChange(events: Event[]): void {
    this.eventSeries.events = events;

    this.isLoading = true;
    this.svcEventSeries
      .update(this.eventSeries.id, this.eventSeries)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((eventSeries) => {
        this.eventSeries = eventSeries;
      });
  }

  onNoInfoChange(noInfo: boolean) {
    if (!this.svcAcl.hasCredential('eventSeries.update.prop.noInfo') || this.isSubmitting) {
      return;
    }

    this.eventSeries.noInfo = noInfo;
    if (!noInfo) {
      this.eventSeries._meta.consecutiveNoInfoCount = null;
    } else {
      this.eventSeries._meta.consecutiveNoInfoCount = (this.eventSeries._meta.consecutiveNoInfoCount || 0) + 1;
    }

    this.isSubmitting = true;
    this.svcEventSeries
      .update(this.id, { noInfo: this.eventSeries.noInfo, _meta: this.eventSeries._meta })
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe((eventSeries) => {
        this.eventSeries._version = eventSeries._version;
        this.eventSeries.updatedAt = eventSeries.updatedAt;
      });
  }

  isClaimedByOtherUser(): boolean {
    // actual valid use of call from template to handle expiration
    const hasValidClaim =
      this.eventSeries._meta?.claimedUntil && new Date(this.eventSeries._meta?.claimedUntil) > new Date();
    const isClaimedByUser =
      this.eventSeries._meta?.assignee && this.eventSeries._meta?.assignee !== this.currentUser.user_id;

    return hasValidClaim && isClaimedByUser;
  }
}
