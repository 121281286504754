import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dirt-save-training-report',
  templateUrl: './save-training-report.component.html',
  styleUrls: ['./save-training-report.component.scss'],
})
export class SaveTrainingReportComponent implements OnInit {
  modalOption: NgbModalOptions = {};
  modalRef: NgbModalRef;
  @Input()
  comment: string;
  summary = {
    totalDp: 0,
    totalError: 0,
    totalCorrect: 0,
    totalMarkedAsCorrect: 0,
  };
  @Input()
  report: any = {};

  @Input()
  saveReport: (comment: string) => void;

  constructor(protected modalService: NgbModal) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
  }

  ngOnInit(): void {
    if (!this.comment) {
      this.comment = '';
    }
  }

  onOpenModal(dialog) {
    this.createSummary();
    this.modalRef = this.modalService.open(dialog, this.modalOption);
  }

  onSubmit() {
    this.saveReport(this.comment);
    this.modalRef.close();
  }

  createSummary() {
    let totalDp = 0;
    let totalError = 0;
    let totalCorrect = 0;
    let totalMarkedAsCorrect = 0;

    Object.keys(this.report).forEach((key) => {
      if (Array.isArray(this.report[key])) {
        totalDp += this.report[key].length;
        this.report[key].forEach((item) => {
          if (item.isCorrect) {
            totalCorrect += 1;
          } else {
            totalError += 1;
          }
          if (item.markAsCorrect) {
            totalMarkedAsCorrect += 1;
          }
        });
      } else {
        const item = this.report[key];
        totalDp += 1;

        if (key === 'affiliations') {
          if (item.isCorrect && item.positions.isCorrect) {
            totalCorrect += 1;
          } else {
            totalError += 1;
          }
          if ((item.isCorrect || item.markAsCorrect) && (item.positions.isCorrect || item.positions.markAsCorrect)) {
            totalMarkedAsCorrect += 1;
          }
        } else {
          if (item.isCorrect) {
            totalCorrect += 1;
          } else {
            totalError += 1;
          }
          if (item.markAsCorrect) {
            totalMarkedAsCorrect += 1;
          }
        }
      }
    });

    this.summary = {
      totalDp,
      totalCorrect,
      totalError,
      totalMarkedAsCorrect,
    };
  }
}
