import { Injectable } from '@angular/core';
import { PERMISSIONS } from './permissions';
import { PERMISSIONS_JOBS } from './permissions-jobs';

@Injectable()
export class ACL {
  public userRoles: string[] = [];
  public userCredentials: string[] = [];
  private definitions: any = PERMISSIONS;

  constructor() {}

  grantRoles(roles: string[], flush?: boolean): void {
    if (flush) {
      this.flushRoles();
    }
    roles.map(this.grantRole.bind(this));
  }

  grantRole(role: string): void {
    if (!this.hasRole(role)) {
      this.userRoles.push(role);
    }

    // let `addCredential()` decide whether this permission should be added
    if (this.definitions[role]) {
      this.addCredential(this.definitions[role]);
    }
  }

  /**
   * Check whether or not a user is a member of a certain role
   *
   * @param {String} role - name of a role to check this user against
   * @return {Boolean} Whether the user is a member of the given role
   */
  hasRole(role: string): boolean {
    return this.userRoles.indexOf(role) !== -1;
  }

  revokeRoles(roles: string[]): void {
    let idx = roles.length;

    // NB! remove roles from end to start to not ruining indexes
    while (idx--) {
      this.revokeRole(roles[idx]);
    }
  }

  revokeRole(role: string): void {
    const idx = this.userRoles.indexOf(role);

    if (idx !== -1) {
      this.userRoles.splice(idx, 1);
      // clear all credentials...
      this.clearCredentials();
      // ...and add for remaining roles back again
      this.grantRoles(this.userRoles);
    }
  }

  flushRoles(): void {
    this.revokeRoles(this.userRoles);
  }

  addCredential(credential: string | string[]): void {
    if (Array.isArray(credential)) {
      credential.map(this.addCredential.bind(this));
      return;
    }

    if (!this.hasCredential(credential, undefined, undefined, true)) {
      this.userCredentials.push(credential);
    }
  }

  /**
   * Indicates whether or not this user has a credential
   *
   * @method hasCredential
   * @param {String} credential - credential data
   * @param {String} currentJobType - active job type. If provided, will be used
   *  to determine if user has access to the field.
   * @param jobNoFallback - when the job does not alllow it: do not allow it
   * @param explicit - do not regard wildcards - the permission needs to be given verbatim, no *
   * @returns {Boolean} Whether the user has the given credential
   */
  hasCredential(credential: string, currentJobType?: string, jobNoFallback?: boolean, explicit?: boolean): boolean {
    // If we have permissions defined for job, use them, otherwise, use user permissions to preserve compatibility with existing
    let credentialList;
    if (currentJobType && Array.isArray(PERMISSIONS_JOBS[currentJobType])) {
      // empty array = also an array. If we define sth for a job, then overwrite
      credentialList = PERMISSIONS_JOBS[currentJobType];
    } else if (currentJobType && jobNoFallback) {
      credentialList = [];
    } else {
      credentialList = this.userCredentials;
    }

    if (credentialList.length < 1) {
      return false;
    }

    if (credentialList.indexOf(credential) !== -1) {
      return true;
    }
    if (explicit) {
      return false; // either above does it - or not
    }

    // if no exact permission, check for wildcard matches
    const parts = credential.split('.');
    const len = parts.length;
    let lastIdx = parts.length - 1;

    if (len > 2 || (len === 2 && parts[lastIdx] !== '*')) {
      if (parts[lastIdx] === '*') {
        parts.splice(-1, 1);
        lastIdx--;
      }

      parts[lastIdx] = '*';
      return this.hasCredential(parts.join('.'), currentJobType);
    }

    return false;
  }

  clearCredentials(): void {
    this.userCredentials = [];
  }
}
