import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Roles } from '../../shared/acl/roles';
import { UserPool } from '../shared/user-pool';
import { UserPoolAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-user-pool-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.scss'],
})
export class UserPoolDetailComponent implements OnInit, OnDestroy {
  id: string;
  pool: UserPool;
  sub: any;
  isSubmitting = false;

  constructor(private route: ActivatedRoute, private router: Router, private svcUserPool: UserPoolAPI) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.getUserPool();
    });
  }

  getUserPool() {
    this.svcUserPool.findById(this.id).subscribe((resp) => (this.pool = resp));
  }

  onSave(pool: UserPool): void {
    this.isSubmitting = true;
    this.svcUserPool.update(pool.id, pool).subscribe(() => {
      this.isSubmitting = false;
      this.goBack();
    });
  }

  goBack(): void {
    this.router.navigate(['/user-pool']);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
