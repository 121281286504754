<section class="dirt-section-list dirt-people-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>Attached People</span>
      </h1>
    </div>

    <div class="col-sm-4"></div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Add button -->
        <button class="btn btn-success btn-sm" (click)="onAddPersonRequested()" *diAcl="'document.person.create'">
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
          <span>Add Person</span>
        </button>
      </div>
    </div>
  </header>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-people-list'"
    [pagingSize]="documentPeople?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="documentPeople?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-state">&nbsp;</th>
          <th class="col-firstname" [diColSorter]="'person.firstName'">First Name</th>
          <th class="col-middlename" [diColSorter]="'person.middleName'">Middle Name</th>
          <th class="col-lastname" [diColSorter]="'person.lastName'">Last Name</th>
          <th class="col-originalFullName" [diColSorter]="'person.originalFullName'">Original Full name</th>
          <th class="col-position" [diColSorter]="'position'">Position</th>
          <th class="col-created" [diColSorter]="'createdAt'">Created</th>
          <th class="col-modified" [diColSorter]="'updatedAt'">Modified</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-person-row"
          *ngFor="
            let documentPerson of documentPeople
              | paginate
                : {
                    id: 'dirt-people-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <td class="col-state">
            <span class="badge badge-info" *ngIf="documentPerson.person.autoProfile">Auto</span>
          </td>
          <td class="col-firstname">
            <a [routerLink]="['/person/detail', documentPerson.person._id]" rel="noopener noreferrer" target="_blank">{{
              documentPerson.person.firstName
            }}</a>
          </td>
          <td class="col-middlename">{{ documentPerson.person.middleName }}</td>
          <td class="col-lastname">{{ documentPerson.person.lastName }}</td>
          <td class="col-originalFullName">{{ documentPerson.person.originalFullName }}</td>
          <td class="col-position">{{ documentPerson.position }}</td>
          <td class="col-created">{{ documentPerson.createdAt | date : 'dd.MM.y' }}</td>
          <td class="col-modified">{{ documentPerson.updatedAt | date : 'dd.MM.y' }}</td>
          <td class="col-actions">
            <!-- View -->
            <span
              class="action-btn action-edit"
              title="View connection"
              (click)="onViewPersonRequested(documentPerson)"
              *diAcl="'!document.person.update'"
            >
              <i class="fa fa-eye" aria-hidden="true"></i>
            </span>

            <!-- Edit -->
            <span
              class="action-btn action-edit"
              title="Edit connection"
              (click)="onEditPersonRequested(documentPerson)"
              *diAcl="'document.person.update'"
            >
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </span>

            <!-- Delete -->
            <span
              class="action-btn action-delete"
              title="Delete connection"
              (click)="onDeletePersonRequested(documentPerson)"
              *diAcl="'document.person.delete'"
            >
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No Attached People</p>
    </ng-template>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-people-list'"
    [pagingSize]="documentPeople?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
