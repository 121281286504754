<section class="dirt-section-detail dirt-affiliation-detail-merge">
  <div class="row">
    <div class="col-md-12">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>
      <div class="dirt-btn-ct">
        <div class="pull-left">
          <h1 class="bounceInRight">Organization Detail</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="row dirt-affiliation-detail-merge__split-view" *ngIf="!!duplicateSuspect && !!duplicateMatch">
    <div class="col-lg-6 col-md-12">
      <div class="d-flex justify-content-between">
        <h2>Duplicate Suspect</h2>
        <span>ID {{ duplicateSuspect.id }}</span>
        <button type="button" class="btn btn-outline-primary" (click)="onCopyID(duplicateSuspect.id)">Copy ID</button>

        <!-- TODO: claimed badge -->
      </div>

      <br />

      <dirt-affiliation-form
        [affiliation]="duplicateSuspect"
        #frmAffiliationDuplicateSuspect="frmAffiliation"
        [disableDeptFieldUnconditionally]="'affiliation-parent' === finding?.type"
      ></dirt-affiliation-form>
      <br />
      <dirt-affiliation-people [affiliationId]="duplicateSuspect.id"></dirt-affiliation-people>

      <br />

      <div class="dirt-duplicate-list" *ngIf="!!duplicateId[duplicateSuspect.id]">
        <div class="dirt-duplicate-list-heading">A duplicate was found with same name or department:</div>
        <dirt-affiliation-suggestion-list
          *ngIf="duplicateSuspect"
          [affiliations]="[duplicateSuspect]"
          [selectLabel]="'Copy ID'"
          (onSelectedItem)="onCopyID(duplicateId[duplicateSuspect.id])"
        ></dirt-affiliation-suggestion-list>
      </div>

      <div class="dirt-btn-ct">
        <div class="pull-right">
          <!-- Save -->
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            title="Save ahead of submit (optional)"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!frmAffiliationDuplicateSuspect.isValid() || isSubmitting"
            (click)="onSave(frmAffiliationDuplicateSuspect.getValue(), duplicateSuspect)"
            *ngIf="hasPermissionInclLfka('affiliation.update')"
          >
            <span>Save</span>
          </button>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-12">
      <div class="d-flex justify-content-between">
        <h2>Duplicate Match</h2>
        <span>ID {{ duplicateMatch.id }}</span>
        <button type="button" class="btn btn-outline-primary" (click)="onCopyID(duplicateMatch.id)">Copy ID</button>
      </div>

      <br />

      <dirt-affiliation-form
        [affiliation]="duplicateMatch"
        #frmAffiliationDuplicateMatch="frmAffiliation"
        [disableDeptFieldUnconditionally]="'affiliation-parent' === finding?.type"
      ></dirt-affiliation-form>
      <br />
      <dirt-affiliation-people [affiliationId]="duplicateMatch.id"></dirt-affiliation-people>

      <br />

      <div class="dirt-duplicate-list" *ngIf="!!duplicateId[duplicateMatch.id]">
        A duplicate was found with same name(/department): #{{ duplicateId[duplicateMatch.id] }}
        <a class="alink" (click)="onCopyID(duplicateId[duplicateMatch.id])">Copy ID</a>
      </div>

      <div class="dirt-btn-ct">
        <div class="pull-right">
          <!-- Save -->
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            title="Save ahead of submit (optional)"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!frmAffiliationDuplicateMatch.isValid() || isSubmitting"
            (click)="onSave(frmAffiliationDuplicateMatch.getValue(), duplicateMatch)"
            *ngIf="hasPermissionInclLfka('affiliation.update')"
          >
            <span>Save</span>
          </button>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-md-12 card dirt-duplicate-decision-card">
      <div class="card-body">
        <h3>Decision</h3>
        <div class="row dirt-duplicate-decision">
          <div class="col-md-4 col-xs-12">
            <label
              ><input type="radio" name="merge-decision" [value]="LEFT_WINS" [(ngModel)]="mergeDecision" /> Left side
              (suspect) wins - merge right side into here</label
            >
          </div>
          <div class="col-md-4 col-xs-12">
            <label
              ><input type="radio" name="merge-decision" [value]="WHITELIST" [(ngModel)]="mergeDecision" /> Both sides
              stay - put this onto whitelist</label
            >
          </div>
          <div class="col-md-4 col-xs-12">
            <label
              ><input type="radio" name="merge-decision" [value]="RIGHT_WINS" [(ngModel)]="mergeDecision" /> Right side
              (match) wins - merge left side into here</label
            >
          </div>
        </div>
        <div class="dirt-btn-ct">
          <div class="pull-right">
            <!-- Submit -->
            <button
              type="submit"
              class="btn btn-primary"
              [ngClass]="{ 'btn-loading': isSubmitting }"
              [disabled]="!frmAffiliationDuplicateSuspect.isValid() || isSubmitting || !mergeDecision"
              (click)="onSubmit(frmAffiliationDuplicateSuspect.getValue(), frmAffiliationDuplicateMatch.getValue())"
              *ngIf="hasPermissionInclLfka('affiliation.submit-merge')"
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
