import { OrganizationJob } from '../../organizations/shared/constant/job.enum';
import { MarkerArea } from '../../shared/directives/marker/marker.directive';

export function rangeOfNumbers(end: number, start: number) {
  return new Array(end - start + 1)
    .fill(null)
    .map((_, index) => index)
    .reduce((all, value) => {
      all[value + start] = value + start;
      return all;
    }, {}) as { [key: string]: string };
}

export function enumToKeyValue(input: object) {
  return Object.keys(input).map((key) => ({ key: key, value: input[key] }));
}

export function capitalize(text) {
  return (text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()).replace(/_/g, ' ');
}

export function enumToDataObject(input: Object, keepKey?: boolean): { [key: string]: string } {
  return enumToKeyValue(input).reduce((all, item) => {
    const key = keepKey ? item.key : item.value;
    all[key] = capitalize(item.value);
    return all;
  }, {});
}

export function isOnlyLFKAProject(projects: string[], lfkaTags: string[]): boolean {
  if (!Array.isArray(projects) || projects.length === 0) {
    return false;
  }
  return projects.every((p) => lfkaTags.includes(p));
}
