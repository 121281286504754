<div class="modal-header">
  <h5 class="modal-title">Add Organization</h5>
</div>

<div class="modal-body">
  <input
    type="search"
    id="affiliations-searchbar"
    class="di-search form-control ng-trim-ignore"
    [ngClass]="{ loading: isSearching }"
    [ngbTypeahead]="onSearchAffiliation"
    [focusFirst]="false"
    [resultTemplate]="searchResultTemplate"
    placeholder="Search..."
    [(ngModel)]="searchTerm"
    (selectItem)="onSelect($event)"
  />

  <a href="javascript:void(0)" class="small pull-right pt-1 pr-2" (click)="showAffiliationModal()">
    Create Organization request
  </a>

  <br />

  <ng-container *ngIf="selectedAffiliation">
    <h6>Selected Organization:</h6>

    <div class="d-flex justify-content-between align-items-baseline">
      <a
        [routerLink]="['/affiliation/detail', selectedAffiliation.id]"
        rel="noopener"
        target="_blank"
        *diAcl="'affiliation.detail'"
      >
        {{ selectedAffiliation.department || selectedAffiliation.name }}
      </a>
      <span *diAcl="'!affiliation.detail'">
        {{ selectedAffiliation.department || selectedAffiliation.name }}
      </span>

      <!-- Original Name & Department -->
      <div
        class="d-flex justify-content-between align-items-baseline ml-3 font-italic"
        *ngIf="selectedAffiliation.originalDepartment"
      >
        <div class="card-subtitle" title="Original Department">{{ selectedAffiliation.originalDepartment }}</div>
      </div>

      <div class="d-flex justify-content-between align-items-baseline ml-3">
        <!-- Address -->
        <div>
          <address class="di-address" *ngIf="selectedAffiliation.address?.countryCode">
            <span *ngIf="selectedAffiliation.address?.name || selectedAffiliation.address?.street">
              <span>{{ selectedAffiliation.address.name || selectedAffiliation.address.street }}</span>
              <span>{{ selectedAffiliation.address.additionalInfo }}</span>
              <br />
            </span>
            <span *ngIf="selectedAffiliation.address?.city">{{ selectedAffiliation.address.city }},</span>
            <span *ngIf="selectedAffiliation.address?.state">{{ selectedAffiliation.address.state }},</span>
            <span>{{ selectedAffiliation.address?.countryCode }}</span>
            <!-- FIXME: Transform country code into country name -->
            <span *ngIf="selectedAffiliation.address?.zip">{{ selectedAffiliation.address.zip }}</span>
          </address>
        </div>

        <!-- Original Address -->
        <div
          *ngIf="
            selectedAffiliation.address?.originalStreet ||
            selectedAffiliation.address?.originalAdditionalInfo ||
            selectedAffiliation.address?.originalCity
          "
        >
          <address class="di-address" *ngIf="selectedAffiliation.address?.countryCode">
            <span>
              <span>{{ selectedAffiliation.address.originalStreet }}</span>
              <span>{{ selectedAffiliation.address.originalAdditionalInfo }}</span>
              <br />
            </span>
            <span *ngIf="selectedAffiliation.address?.originalCity">{{
              selectedAffiliation.address.originalCity
            }}</span>
          </address>
        </div>
      </div>
    </div>

    <!-- List already connected accounts for selected affiliation -->
    <div>Connected Accounts: {{ selectedAffiliationConnectedAccounts.length }}</div>
    <div>
      <a
        *ngFor="let account of selectedAffiliationConnectedAccounts"
        [routerLink]="['/account/detail', account.id]"
        target="_blank"
        >{{ account.name }}</a
      >
    </div>
  </ng-container>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-outline-dark" [disabled]="isSearching" (click)="activeModal.dismiss()">Close</button>
  <button
    class="btn btn-sm btn-secondary"
    [ngClass]="{ 'btn-loading': isSearching }"
    [hidden]="!selectedAffiliation"
    [disabled]="isSearching || addOrganizationButtonDisabled"
    (click)="onShortlist()"
    [title]="addOrganizationButtonDisabledTooltip"
  >
    Shortlist
  </button>
  <button
    class="btn btn-sm btn-success"
    [ngClass]="{ 'btn-loading': isSearching }"
    [hidden]="!selectedAffiliation"
    [disabled]="isSearching || addOrganizationButtonDisabled"
    (click)="onAdd()"
    [title]="addOrganizationButtonDisabledTooltip"
  >
    Add
  </button>
</div>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div class="di-sac-verified pull-left">
    <dirt-verified-icon color="success" *ngIf="r?.verified"></dirt-verified-icon>
    <div class="badge badge-primary" *ngIf="!r.department" title="Primary Affiliation">P</div>
  </div>

  <div class="di-sac-affiliation pull-left">
    <div class="di-sac-name">{{ r?.name }}</div>
    <div class="di-sac-department">{{ r?.department || '&mdash;' }}</div>
    <div class="di-sac-address">{{ r?.address?.state || '&mdash;' }} , {{ r?.address?.country || '&mdash;' }}</div>
    <div *ngIf="isAlreadyMatched(r.id)" class="di-matched">Matched to KA</div>
  </div>

  <div class="pull-right row">
    <div [ngClass]="{ 'di-sac-lfka-status pull-left': r?.sourceLfka, 'di-sac-lfta-status pull-left': !r?.sourceLfka }">
      {{ r?.sourceLfka ? 'LFKA' : 'LFTA' }}
    </div>
  </div>
</ng-template>
