import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

import { ACL } from '../../shared/acl/acl.service';
import { Auth } from '../../shared/services/auth/auth.service';
import { checkRegionRestrict, RegionRestrict } from '../../shared/values/region-restrict';
import { ContributionAPI } from '../../contribution/shared/contribution-api.service';
import { ContributionListComponent } from '../../contribution/list/list.component';
import { Event } from '../shared/event';
import { EventAPI } from '../shared/api.service';
import { EventFormComponent } from '../shared/form/form.component';
import { EventJob } from '../shared/enum/job.enum';
import { EventListComponent } from '../list/list.component';
import { EventStatus } from '../shared/enum/status.enum';
import { EventWorkflow } from '../shared/enum/workflow.enum';
import { Job } from '../../jobs/shared/job';
import { JobsAPI } from '../../jobs/shared/api.service';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { Roles } from '../../shared/acl/roles';

type SubmitAction = 'SAVE' | 'SAVE_AND_BACK' | 'SAVE_AND_NEW';

@Component({
  selector: 'dirt-event-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.scss'],
})
export class EventDetailComponent implements OnInit, OnDestroy {
  id: string;
  event: Event;
  eventJob: Job;
  currentOpenVerificationCount: number = 0;
  statuses = EventStatus;
  disabledStatuses = [EventStatus.UNABLE_TO_COMPILE];
  assigneeStatuses = [EventStatus.COMPILATION_IN_PROGRESS];

  isObjectionCommentShown: boolean;
  objectionStatus: string;

  isSubmitting: boolean;
  isSavingStatus: boolean;
  isSavingVerified: boolean;
  isSavingIdVerified: boolean;
  isUpdatingVerifiedControl: boolean;

  auditLogShallowCompKeys = ['areas', 'therapeuticAreas', 'organizers'];
  auditDiffArrayMappers = { organizers: (o) => o.affiliationId?.toString() || o.associationId?.toString() || '-' };

  ValueType = ValueType;

  myUserId: string;
  allDirectContributionVerified = false;
  atleastOneIndirectContributionVerified = false;

  @ViewChild('contribution') contributionList: ContributionListComponent;
  @ViewChild('session') session: EventListComponent;

  @ViewChild('frmEvent', { static: false })
  private frmEvent: EventFormComponent;

  wndw: Window = window; // be able to override in tests

  private destroy$: Subject<boolean> = new Subject();

  private regionRestrict: RegionRestrict[] = [];

  private inScopeCountries: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcAcl: ACL, // used in template
    private svcAuth: Auth,
    private svcEvent: EventAPI,
    private svcContribution: ContributionAPI,
    private svcValue: ValueAPI,
    private titleService: Title,
    private svcJob: JobsAPI
  ) {}

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params) => {
      this.id = params['id'];
      this.getEvent();
      this.setVerifiedEditable();
    });

    this.svcAuth.getProfile().subscribe((p) => {
      this.myUserId = p.user_id;
    });

    this.svcValue.find(ValueType.RegionalRestrict, Number.MAX_SAFE_INTEGER).subscribe((values) => {
      this.regionRestrict = values;
    });

    this.svcValue.find(ValueType.InScopeCountry, Number.MAX_SAFE_INTEGER).subscribe((values) => {
      this.inScopeCountries = values.map((v) => v.code as string);
    });

    if (this.isCreatorOnly()) {
      this.disabledStatuses.push(
        EventStatus.READY_FOR_COMPILATION,
        EventStatus.COMPILATION_IN_PROGRESS,
        EventStatus.UNABLE_TO_COMPILE
      );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  private async setVerifiedEditable() {
    // if user cannot update the verified state, there is no point determining if the switch should be enabled or not.
    if (!this.svcAcl.hasCredential('event.update.prop.verified')) {
      return;
    }

    this.isUpdatingVerifiedControl = true;
    const directNotVerified = await this.svcContribution
      .find(this.id, 1, undefined, '-_id', undefined, 'DIRECT', { verified: false })
      .toPromise();
    this.allDirectContributionVerified = directNotVerified.length > 0 ? false : true;

    const indirectContributions = await this.svcContribution
      .find(this.id, 1, undefined, '-_id', undefined, 'INDIRECT')
      .toPromise();
    if (!indirectContributions.length) {
      this.atleastOneIndirectContributionVerified = true; // there is no indirect contribution, we can somehow say that indirect contributions are verified
      this.isUpdatingVerifiedControl = false;
      return;
    }

    const indirectVerified = await this.svcContribution
      .find(this.id, 1, undefined, '-_id', undefined, 'INDIRECT', { verified: true })
      .toPromise();
    this.atleastOneIndirectContributionVerified = indirectVerified.length > 0 ? true : false;
    this.isUpdatingVerifiedControl = false;
  }

  getEvent() {
    this.svcEvent.findById(this.id).subscribe((resp) => {
      this.event = resp;
      this.titleService.setTitle(`cLean | Event | ${this.event.name}`);

      if (undefined === this.event.isVirtual || null === this.event.isVirtual) {
        this.event.isVirtual = false;
      }

      if (this.event._meta?.jobId && this.event?._meta?.assignee === this.myUserId) {
        this.reloadJobWithQc();
      }
    });
  }

  private reloadJobWithQc() {
    this.svcJob
      .findById(this.event._meta.jobId, true)
      .pipe(take(1))
      .subscribe((j) => {
        this.eventJob = j;
        // set needsVerify VS verified
        let needsVerifyCount = 0;
        let verifiedCount = 0;
        Object.values(j.qcSession?.entities || {}).forEach((qcValue: { needsVerify; verified }) => {
          if (qcValue?.needsVerify) {
            needsVerifyCount++;
          }
          if (qcValue.verified) {
            verifiedCount++;
          }
        });
        this.currentOpenVerificationCount = Math.max(needsVerifyCount - verifiedCount, 0);
      });
  }

  updateEvent(event: Event, action: SubmitAction): void {
    this.isSubmitting = true;

    this.svcEvent.update(this.event.id, event).subscribe({
      next: () => {
        this.isSubmitting = false;
        switch (action) {
          case 'SAVE': // save and do nothing
            break;
          case 'SAVE_AND_NEW': // save and reset form
            this.frmEvent.resetForm();
            const path: any = ['/event/create'];
            if (this.event.parent) {
              path.push({ parentId: this.event.parent.id });
            }
            this.router.navigate(path);
            break;
          default: // save and go back to previous main view
            this.goBack();
            break;
        }
      },
      error: () => (this.isSubmitting = false),
    });
  }

  async onSubmit(event: Event): Promise<void> {
    if (!this.event.parent) {
      let message = await this.createSubmitConfirmationMessage(event);

      // confirm the submit action
      if (!window.confirm(message)) {
        return;
      }

      if (this.event?._meta?.workflow !== EventWorkflow.Hashtag) {
        event.published = true;

        //  NB! TypeScript enums have numeric values
        event._meta.status = EventStatus[EventStatus.DONE];
      } else if (this.svcAcl.hasRole(Roles.EventHashtagCompiler)) {
        event._meta.status = event._meta.previousStatus;
      }

      event._meta.claimedUntil = null;
    }

    this.updateEvent(event, 'SAVE_AND_BACK');
  }

  private async createSubmitConfirmationMessage(event: Event) {
    const hasDuplicates = await this.contributionList?.hasDuplicates();

    let problems: string[] = [];
    if (hasDuplicates) {
      problems.push('Some duplicates were found in contributions.');
    }

    if (!event.parent && event.address?.countryCode && !this.inScopeCountries.includes(event.address.countryCode)) {
      problems.push('Selected country is out of scope.');
    }

    let message: string = '';
    if (problems.length > 0) {
      message = `The following issues were found: \n - ${problems.join('\n - ')}\n\n`;
    }

    message += 'Do you really want to submit this entry?';
    message += '\nNote: By submitting, you will not be having any access to it anymore!';
    return message;
  }

  async onSubmitJob(event: Event): Promise<void> {
    if (!(this.frmEvent.isValid() || this.canSubmitJobAnyway())) {
      return;
    }

    if (this.isQcJob() && this.currentOpenVerificationCount > 0) {
      if (!window.confirm('You have open verifications - submit?')) {
        return;
      }
    }

    if (!this.event.parent) {
      let message = await this.createSubmitConfirmationMessage(event);

      // confirm the submit action
      if (!window.confirm(message)) {
        return;
      }
    }

    this.isSubmitting = true;

    this.svcEvent.update(this.event.id, event, true).subscribe({
      next: () => {
        this.isSubmitting = false;
        this.router.navigate(['/next']);
      },
      error: () => (this.isSubmitting = false),
    });
  }

  onJobUtc(): void {
    let comment = '';
    while (comment.length < 3) {
      comment = window.prompt('Enter a comment for Unable to Compile...');
      if (null === comment) {
        return;
      }
    }

    this.isSubmitting = true;

    this.svcJob.setUtc(this.eventJob._id, comment).subscribe(
      () => {
        this.isSubmitting = false;
        this.router.navigate(['/next']);
      },
      () => (this.isSubmitting = false)
    );
  }

  async onSave(event: Event, action: SubmitAction): Promise<void> {
    const hasDuplicates = await this.contributionList?.hasDuplicates();

    let problems: string[] = [];
    if (hasDuplicates) {
      problems.push('Some duplicates were found in contributions.');
    }

    if (!event.parent && event.address?.countryCode && !this.inScopeCountries.includes(event.address.countryCode)) {
      problems.push('Selected country is out of scope.');
    }

    let message: string;
    if (problems.length > 0) {
      message = `The following issues were found: \n - ${problems.join('\n - ')}\n\n`;
      message += 'Do you really want to submit this entry?';

      // confirm the submit action
      if (!window.confirm(message)) {
        return;
      }
    }

    event.published = false;

    if (
      !event.parent &&
      !event.requiresCompilation &&
      [EventStatus.DRAFT, EventStatus.READY_FOR_COMPILATION, EventStatus.COMPILATION_IN_PROGRESS].includes(
        event._meta?.status as EventStatus
      )
    ) {
      event._meta.status = EventStatus.DONE;
    }

    this.updateEvent(event, action);
  }

  onStatusChange(_meta: any): void {
    this.isSavingStatus = true;

    const event = <Event>{ _meta };

    this.svcEvent.update(this.event.id, event).subscribe(
      (resp: Event) => this.getMergedResponse(this.event, resp),
      null,
      () => (this.isSavingStatus = false)
    );
  }

  onVerifiedChange(verified: boolean): void {
    this.isSavingVerified = true;

    const event = <Event>{ verified };

    this.svcEvent.update(this.event.id, event).subscribe(
      (resp: Event) => Object.assign(this.getMergedResponse(this.event, resp), { verified }),
      null,
      () => (this.isSavingVerified = false)
    );
  }

  onIdVerifiedChange(idVerified: boolean): void {
    this.isSavingIdVerified = true;

    this.svcEvent.update(this.event.id, { idVerified } as unknown as Event).subscribe((resp: Event) => {
      Object.assign(this.getMergedResponse(this.event, resp), { idVerified });
      this.isSavingIdVerified = false;
    });
  }

  getMergedResponse(localData: Event, remoteData: Event): Event {
    localData.updatedAt = remoteData.updatedAt;
    localData._version = remoteData._version;
    localData.readyForDelivery = remoteData.readyForDelivery;
    return localData;
  }

  goBack(): void {
    const link = this.event.parent ? ['/event/detail', this.event.parent.id] : ['/event'];

    this.router.navigate(link);
  }

  isClaimedByOtherUser(): boolean {
    return (
      this.event._meta &&
      this.event._meta.claimedUntil &&
      new Date(this.event._meta.claimedUntil) > new Date() &&
      this.event._meta.assignee &&
      this.event._meta.assignee !== this.myUserId
    );
  }

  onVerifiedFlagUpdate() {
    this.setVerifiedEditable();
  }
  onContributionMove() {
    this.contributionList.getContributions();
    this.contributionList.getCount();
  }
  onSessionUpdate() {
    this.session.getCount();
    this.session.getEvents();
  }

  skipVerification(): void {
    if (!window.confirm('Are you sure?')) {
      return;
    }

    const val = <Event>{
      //
      verificationSkipped: true,
    };

    this.svcEvent.update(this.event.id, val).subscribe(() => this.router.navigate(['/']));
  }

  isRegionCompliant(): boolean {
    const countryCode = this.event.parent?.address?.countryCode || this.event.address?.countryCode || null;
    const hasWhitelistedRole =
      this.svcAcl.hasRole(Roles.Admin) ||
      this.svcAcl.hasRole(Roles.EventManager) ||
      this.svcAcl.hasRole(Roles.EventModerator);

    if (hasWhitelistedRole) {
      return true;
    }

    return checkRegionRestrict(this.svcAcl, this.regionRestrict, countryCode);
  }

  isHashtagsOnly(): boolean {
    return this.svcAcl.hasRole(Roles.EventHashtagCompiler);
  }

  hasJobForCurrentUser(): boolean {
    return this.eventJob && this.myUserId && this.event?._meta?.assignee === this.myUserId;
  }
  hasStatusChangeJobForCurrentUser(): boolean {
    return this.hasJobForCurrentUser() && EventJob.AGENDA_IDENTIFICATION === this.eventJob.type;
  }
  getJobTypeForCurrentUser(): string | null {
    return this.hasJobForCurrentUser() ? this.eventJob.type : null;
  }
  canSubmitJobAnyway() {
    return this.svcAcl.hasCredential('event.updateAnyway'); // (same permission)
  }

  isQcJob(): boolean {
    return this.eventJob?.type.endsWith('_QC');
  }

  onVerifiedCountChange(delta: number) {
    this.currentOpenVerificationCount = Math.max(0, this.currentOpenVerificationCount - delta); // additional ones get verified: we get positive; verified taken away: we get negative
  }
  onVerifiedRefresh() {
    this.reloadJobWithQc();
  }

  isCreatorOnly(): boolean {
    return (
      !this.svcAcl.hasRole(Roles.Admin) &&
      !this.svcAcl.hasRole(Roles.EventManager) &&
      !this.svcAcl.hasRole(Roles.EventReviewer) &&
      !this.svcAcl.hasRole(Roles.EventIDReviewer)
    );
  }

  /* could expand to refresh every time we come back - but changing the tab is the exception: sessions are same tab, contribs are inline
  @HostListener('document:visibilitychange')
  onGlobalVisibility() {
    if (!this.wndw.document.hidden) { // coming back
      this.reloadJobWithQc();
    }
  }*/

  isEventWithContributions() {
    return this.event.requiresCompilation || this.event.parent /*was implicit in below*/;
    //const eventType = this.event.type;
    // exclude Medical Education as it might have contributions/sessions eventually
    //return !isEventTypeWithoutCurationNeeded(eventType) || eventType === 'MEDICAL_EDUCATION';
  }
}
