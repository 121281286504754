import { Directive, ElementRef, OnInit, Input } from '@angular/core';
import { NgModel, RequiredValidator } from '@angular/forms';
import { GoogleAddress } from './address';

declare var google: any;

@Directive({
  selector: '[ngModel][dirtGooglePlace]',
  host: {
    '(keydown)': 'onInput($event)',
    '(paste)': 'onInput($event)',
  },
  providers: [RequiredValidator],
})
export class DirtGooglePlaceDirective implements OnInit {
  private el: HTMLInputElement;
  private addressComponents: Object = {
    street_number: [{ key: 'short_name', alias: GoogleAddress.STREET_NO }],
    route: [{ key: 'long_name', alias: GoogleAddress.STREET }],
    neighborhood: [{ key: 'long_name', alias: GoogleAddress.NEIGHBORHOOD }],
    locality: [{ key: 'long_name', alias: GoogleAddress.CITY }],
    administrative_area_level_2: [{ key: 'long_name', alias: GoogleAddress.COUNTY }],
    administrative_area_level_1: [{ key: 'short_name', alias: GoogleAddress.STATE }],
    country: [
      { key: 'long_name', alias: GoogleAddress.COUNTRY },
      { key: 'short_name', alias: GoogleAddress.COUNTRY_CODE },
    ],
    postal_code: [{ key: 'short_name', alias: GoogleAddress.ZIP }],
    postal_code_suffix: [{ key: 'short_name', alias: GoogleAddress.ZIP_SUFFIX }],
  };

  options: any = {
    language: 'en',
  };

  /*
   * Instructs the Place Autocomplete service to return only geocoding
   * results with a precise address.
   */
  @Input()
  forceAddress = false;

  @Input()
  withHouseNo = false;

  constructor(el: ElementRef, private address: NgModel, private requiredValidator: RequiredValidator) {
    this.el = el.nativeElement;
  }

  ngOnInit() {
    if (this.forceAddress) {
      this.options.types = ['address'];
    }

    const ac = new google.maps.places.Autocomplete(this.el, this.options);

    google.maps.event.addListener(ac, 'place_changed', () => {
      this.onPlaceChange(ac.getPlace());
    });

    // try to have the formatted address shown in text field
    this.address.valueChanges.subscribe((v) => this.onValueChange(v));
  }

  onValueChange(update): void {
    let text = '';

    // don't interfere with typing
    if (typeof update === 'string') {
      // do not overwrite the model object with the text coming from view
      this.safeUpdateModel(<GoogleAddress>{});
      return;
    }

    // empty model
    if (this.address.model && Object.keys(this.address.model).length !== 0) {
      text = this.address.model.formatted || this.constructFormatted(this.address.model);
    }

    this.address.valueAccessor.writeValue(text);
  }

  /**
   * callback of a selection from Google Place Autocomplete.
   *
   * @param place PlaceResult from google autocomplete
   */
  onPlaceChange(place: any): void {
    const val = <GoogleAddress>{};

    val[GoogleAddress.NAME] = place.name;
    val[GoogleAddress.LATITUDE] = place.geometry.location.lat();
    val[GoogleAddress.LONGITUDE] = place.geometry.location.lng();
    val[GoogleAddress.FORMATTED] = place.formatted_address;
    val[GoogleAddress.VICINITY] = place.vicinity;
    val[GoogleAddress.OFFSET_UTC] = place['utc_offset_minutes']; // https://developers.google.com/maps/deprecations#places_fields_open_now_utc_offset_deprecated_on_november_20_2019

    place.address_components.forEach((component) => {
      const props = this.addressComponents[component.types[0]];

      if (props) {
        props.forEach((p) => (val[p.alias] = component[p.key]));
      }
    });
    // if we use the long name for states, fill those in - our country-states only fits short names for US
    if (val[GoogleAddress.STATE] && val[GoogleAddress.COUNTRY_CODE] !== 'US') {
      let longState = place.address_components
        .filter((component) => component.types[0] === 'administrative_area_level_1')
        .map((component) => component.long_name)[0];
      if (longState) {
        val[GoogleAddress.STATE] = longState;
      } else {
        console.error('Cannot find long state for ' + val[GoogleAddress.STATE]);
        delete val[GoogleAddress.STATE]; // we can't use it, anyway
      }
    }

    this.constructStreetName(val);
    this.safeUpdateModel(val);

    // monkey-patch to reflect the changes on model immediately
    this.el.focus();
    this.el.blur();
  }

  /**
   * Safely update model and preserve non Google fields
   *
   * @param ga GoogleAddress to apply on model
   */
  safeUpdateModel(ga: GoogleAddress): void {
    const model = this.address.model ? { ...this.address.model } : {};

    // first clean-up the model from GA fields...
    Object.keys(model).forEach((key) => {
      if (GoogleAddress[key]) {
        delete model[key];
      }
    });

    // ...then merge model with new GA fields
    Object.assign(model, ga);

    // update text field value
    this.address.viewToModelUpdate(model);
    this.doValidate(model);
  }

  doValidate(model: { [P in keyof typeof GoogleAddress]?: string }): void {
    let errors: { [p: string]: boolean } = {};
    const text = this.el.value.trim();
    const fields = Object.keys(model).filter((k) => GoogleAddress[k]);

    if (fields.length) {
      delete errors.invalid;
    } else if (text !== '') {
      errors.invalid = true;
    }

    // required validation
    if (this.requiredValidator.required && text === '') {
      errors.required = false;
    }

    // nullify error object to clear all errors
    if (!Object.keys(errors).length) {
      errors = null;
    }

    this.address.control.setErrors(errors);
  }

  onInput(e: KeyboardEvent) {
    // prevent the form to be submitted!
    const whiteListChars: RegExp = /[A-Za-z0-9#&() ]/g;
    const text = e.type === 'paste' ? e['clipboardData'].getData('text') : e.key;

    if (!whiteListChars.test(text)) {
      alert('The entered character is not valid!');
      return e.preventDefault();
    }

    if (e.keyCode === 13) {
      e.preventDefault();
    }
  }

  /**
   * Constructs the full street name field.
   *
   * If there's no `name` field coming from Google or the `name` field is
   * invalid, construct it by concatenating `street` and `streetNumber` fields.
   *
   * @param ad GoogleAddress - address
   */
  constructStreetName(ad: GoogleAddress) {
    if (!ad[GoogleAddress.STREET]) {
      delete ad[GoogleAddress.NAME];
      return;
    }

    const street = this.withHouseNo
      ? ad[GoogleAddress.STREET]
      : `${ad[GoogleAddress.STREET]} ${ad[GoogleAddress.STREET_NO]}`;

    if (!ad[GoogleAddress.NAME] || !ad[GoogleAddress.STREET].includes(ad[GoogleAddress.NAME])) {
      ad[GoogleAddress.NAME] = street;
      if (this.withHouseNo) {
        ad['houseNo'] = ad[GoogleAddress.STREET_NO];
      }
    }
  }

  constructFormatted(address: any) {
    const formatted = [];

    if (address.streetNumber || address.street) {
      formatted.push([address.streetNumber, address.street].join(' ').trim());
    } else if (address.name) {
      formatted.push(address.name);
    }

    if (address.city) {
      formatted.push(address.city);
    }

    if (address.country || address.countryCode) {
      formatted.push(address.country || address.countryCode);
    }

    return formatted.join(', ');
  }
}
