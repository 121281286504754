import { ActivatedRoute, Router } from '@angular/router';
import { capitalize } from 'lodash';
import { debounceTime, distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';
import { forkJoin, Observable, Subject } from 'rxjs';
import { format } from 'date-fns';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnInit, Component, OnDestroy, ViewChild } from '@angular/core';

import { ACL } from '../../shared/acl/acl.service';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkModalComponent, BULK_MODAL_OPTIONS } from '../../common/bulk/component/bulk-modal/bulk-modal.component';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { Document } from '../shared/document';
import { DocumentAPI } from '../shared/document-api.service';
import { DocumentMetaStatus } from '../shared/constant/meta-status.enum';
import { Filter, FiltersComponent, FilterType } from '../../shared/components/filters/filters.component';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { JobsAPI } from '../../jobs/shared/api.service';

@Component({
  selector: 'dirt-document-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class DocumentListComponent implements OnInit, OnDestroy {
  documents: Document[];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  // Sorting / Filtering settings
  searchCtrl: FormControl = new FormControl('');
  filter: { [key: string]: any };
  filters: Filter[] = [];
  filterBarConfig = {
    country: {
      label: 'Country',
      format: (value: string) => {
        return this.countries.get(value) || value;
      },
    },
    type: {
      label: 'Type',
      format: (value: string) => {
        return this.types.get(value) || value;
      },
    },
    category: {
      label: 'Category',
      format: (value: string) => {
        return this.categories.get(value) || value;
      },
    },
    '_meta.status': {
      label: 'Status',
      format(value: string) {
        return capitalize(value).replace(/_/g, ' ');
      },
    },
    createdAt: {
      label: 'Created at',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
    updatedAt: {
      label: 'Modified at',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
  };

  bulkListDelegate: BulkDelegate = {
    title: 'Bulk Import',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListUpload.bind(this),
    specificNotifyReloadFct: this.doLoad.bind(this),
  };

  jobBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import Job',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListJobUpload.bind(this),
    specificNotifyReloadFct: this.doLoad.bind(this),
  };

  isPriorityEditable: boolean;

  searchType = 'DOCUMENT';

  private searchTerm: string;

  private sort = '-_id';

  @ViewChild(FiltersComponent, { static: false })
  private filterRef: FiltersComponent;

  private countries: Map<string, string> = new Map();
  private types: Map<string, string> = new Map();
  private categories: Map<string, string> = new Map();

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly svcDocument: DocumentAPI,
    private readonly svcACL: ACL,
    private readonly svcValue: ValueAPI,
    private readonly svcModal: NgbModal,
    private readonly svcJob: JobsAPI
  ) {}

  ngOnInit(): void {
    this.filters.push(
      {
        title: 'Status',
        key: '_meta.status',
        type: FilterType.MULTI_VALUE,
        values: Object.keys(DocumentMetaStatus)
          .sort()
          .map((key) => ({ title: DocumentMetaStatus[key], displayValue: DocumentMetaStatus[key] })),
      },
      {
        title: 'Created',
        key: 'createdAt',
        type: FilterType.DATE_RANGE,
      },
      {
        title: 'Modified',
        key: 'updatedAt',
        type: FilterType.DATE_RANGE,
      }
    );

    forkJoin({
      countries: this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title'),
      types: this.svcValue.find(ValueType.DocumentType, Number.MAX_SAFE_INTEGER, 0, '+title'),
      categories: this.svcValue.find(ValueType.DocumentCategory, Number.MAX_SAFE_INTEGER, 0, '+title'),
      params: this.route.queryParams.pipe(take(1)),
    }).subscribe(({ countries, types, categories, params }) => {
      const countryFilter = {
        title: 'Countries',
        key: 'country',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: countries.map((value) => ({ title: value.code as string, displayValue: value.title })),
      };

      const typeFilter = {
        title: 'Types',
        key: 'type',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: types.map((value) => ({ title: value.code as string, displayValue: value.title })),
      };

      const categoryFilter = {
        title: 'Categories',
        key: 'category',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: categories.map((value) => ({ title: value.code as string, displayValue: value.title })),
      };

      this.filters = [...this.filters, countryFilter, typeFilter, categoryFilter]; // DO break reference when creating a filter from an Observable/Promise (otherwise change detection won't run)

      this.countries = new Map(countries.map((value) => [value.code as string, value.title]));
      this.types = new Map(types.map((value) => [value.code as string, value.title]));
      this.categories = new Map(categories.map((value) => [value.code as string, value.title]));

      if (params.searchTerm) {
        this.searchTerm = params.searchTerm;
        this.searchCtrl.setValue(this.searchTerm, { emitEvent: false }); // restore search term
      }
      if (params.sortField) {
        this.sort = params.sortField;
      }

      this.doLoad();
    });

    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.searchTerm = term;
        this.doLoad();
      });

    this.isPriorityEditable = this.svcACL.hasCredential('document.update.prop.priority');
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /** just avoid useless rendering if we can */
  trackById(_: number, document: Document): string {
    return document._id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getDocuments();
  }

  onSort(field: string): void {
    this.sort = field;

    this.storeFiltersAsQueryParams();
    this.getDocuments();
  }

  onFilter(filter: { [key: string]: any }): void {
    this.filter = filter;
    this.doLoad();
  }

  onDelete(id: string): void {
    if (!this.svcACL.hasCredential('document.delete')) {
      return;
    }

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.svcDocument.deleteById(id).subscribe(() => {
      this.getDocuments();
      this.getDocumentsCount();
    });
  }

  onFilterClear(): void {
    this.filterRef.doClear();
  }

  onFilterValueRemove(item: { key: string; value: any }): void {
    this.filterRef.removeValue(item.key, item.value);
  }

  onOpenBulkListDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.bulkListDelegate;
  }

  onSetPriority(document: Document, priority: number): void {
    if (!priority || (document.priority && document.priority === priority)) {
      return;
    }

    this.svcDocument.update(document._id, { priority } as Document).subscribe((updatedDocument) => {
      const document = this.documents.find((document) => document._id === updatedDocument._id);
      if (!document) {
        return;
      }

      Object.assign(document, updatedDocument);
    });
  }

  onExport(): void {
    this.svcDocument.downloadDocumentsFile(this.filter, this.searchTerm, this.searchType).subscribe((blob) => {
      const dlDummyLink = document.createElement('a');

      dlDummyLink.setAttribute('download', `document_list_${new Date().toLocaleString()}.xlsx`);
      dlDummyLink.setAttribute('href', URL.createObjectURL(blob));
      dlDummyLink.click();
      dlDummyLink.parentElement.removeChild(dlDummyLink);
    });
  }

  onOpenBulkListJobDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.jobBulkListDelegate;
  }

  onSearchTypeChange(type: string): void {
    this.searchType = type;
    if (this.searchTerm) {
      this.doLoad();
    }
  }

  navigateTo(id: string): void {
    if (!id) {
      return;
    }

    this.router.navigate(['/document/detail', id]);
  }

  private doLoad(): void {
    this.storeFiltersAsQueryParams();
    this.resetPagination();
    this.getDocuments();
    this.getDocumentsCount();
  }

  private storeFiltersAsQueryParams(): void {
    if (this.searchTerm || this.sort) {
      const filters = {
        ...(this.searchTerm && { searchTerm: this.searchTerm }),
        ...(this.sort && { sortField: this.sort }),
      };
      this.router.navigate([], { queryParams: filters });
    }
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getDocuments(): void {
    this.isLoading = true;

    this.svcDocument
      .find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.filter, this.searchType)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((documents) => {
        this.documents = documents;
      });
  }

  private getDocumentsCount(): void {
    this.svcDocument.count(this.searchTerm, this.filter, this.searchType).subscribe((count) => {
      this.total = count;
    });
  }

  private handleBulkListUpload(file: File, secondEyes: string): Observable<string> {
    return this.svcDocument.startBulkListUpload(file, secondEyes);
  }

  private handleBulkListJobUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcJob.startBulkListDocumentJobUpload(file, secondEyes);
  }

  compactString(str?: string) {
    if (!str) {
      return str;
    }
    const abbrevMatch = /([A-Z]{2,5})/.exec(str);
    if (abbrevMatch && !str.startsWith(abbrevMatch[1])) {
      str = abbrevMatch[1] + ' - ' + str;
    }
    str = str.replace('University', 'U.');
    str = str.replace('Academy', 'A.');
    return str;
  }

  nameString(array?: { name }[]) {
    return (array || []).map((e) => e.name).join(', ');
  }
}
