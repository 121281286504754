import { ChangeDetectorRef, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ClinicalTrialSite } from '../../site';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';
import { AffiliationAPI } from '../../../../affiliation/shared/api.service';
import { ClinicalTrialProfileAPI } from '../../clinical-trial-profile-api.service';
import { PersonAffiliationModalService } from '../../../../shared/services/modal.service';

@Component({
  selector: 'dirt-site-single-select',
  templateUrl: 'site-single-select.html',
  styleUrls: ['site-single-select.scss'],
})
export class SiteSingleSelectComponent {
  @Input()
  siteId: string;
  @Output() // (can [(siteId)]
  siteIdChange: EventEmitter<string> = new EventEmitter();
  @Input()
  wide: boolean = false;
  @Input()
  disabled: boolean = false;

  isSearching = false;
  searchTerm: string;

  @Input()
  existSites: { [_id: string]: ClinicalTrialSite } = {};
  @Input()
  doLoadAdditional: boolean = false; // so main component can itself load first
  // (plus we load on demand - but avoid 70 requests upfront with above)
  addSites: { [kolId: string]: ClinicalTrialSite } = {};

  private _affiliation: { id: string } = { id: null };

  @Input()
  matchedAffiliation: { id: string } = { id: null };

  searchAffiliations = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) =>
        this.svcAffiliation.search(term as any, 100).pipe(
          take(1),
          // tap(() => this.searchFailed = false),
          catchError(() => {
            // this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.isSearching = false))
    );

  constructor(
    private readonly svcAffiliation: AffiliationAPI,
    private readonly svcAffiliationModal: PersonAffiliationModalService,
    private readonly svcClinicalTrialProfile: ClinicalTrialProfileAPI,
    private svcChangeDetect: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.matchedAffiliation && changes.matchedAffiliation.currentValue) {
      this._affiliation.id = changes.matchedAffiliation.currentValue.affiliationId;
      this.onChangeSite(this._affiliation);
      this.svcChangeDetect.markForCheck();
    }
  }

  private loadAnotherSite(siteId: string) {
    if (!this.doLoadAdditional || !siteId || this.existSites[siteId]) {
      return;
    }
    if (undefined === this.addSites[siteId]) {
      this.addSites[siteId] = null; // (we're loading)
      this.svcClinicalTrialProfile
        .findSitesByIds([siteId])
        .pipe(take(1))
        .subscribe((stes) => {
          if (stes.length > 0) {
            stes.forEach((ste) => (this.addSites[ste._id] = ste));
            this.svcChangeDetect.markForCheck(); // reset placeholder
          }
        });
    } // else (incl. null: we're loading already or have already)
  }

  onChangeSite(affiliation) {
    this.svcClinicalTrialProfile
      .obtainSite(affiliation.id)
      .pipe(take(1))
      .subscribe((ste) => {
        this.addSites[ste._id] = ste;
        this.siteIdChange.emit(ste._id);
        this.searchTerm = null;
        this.svcChangeDetect.markForCheck(); // reset placeholder
      });
  }
  onClearSite() {
    this.siteIdChange.emit(null);
    setTimeout(() => (this.searchTerm = null)); // (next apply)
  }

  gotoAffiliation(evnt, affiliationId: string): void {
    evnt.preventDefault();
    evnt.stopPropagation();
    window.open('/affiliation/detail/' + affiliationId, '_blank', 'noopener');
  }

  determineSiteCaption(siteId: string) {
    if (!siteId) {
      return '';
    }
    const siteInfo = this.existSites[siteId] || this.addSites[siteId] || null;
    if (siteInfo) {
      return (
        siteInfo.affiliationName +
        ' ' +
        siteInfo.affiliationDepartment +
        (siteInfo.affiliationAddress ? ' (' + siteInfo.affiliationAddress + ')' : '')
      );
    } else {
      this.loadAnotherSite(siteId);
      return siteId;
    }
  }

  determineAffiliationId(siteId: string): string {
    return (this.existSites[siteId] || this.addSites[siteId])?.['affiliation'];
  }

  showAffiliationModal() {
    this.svcAffiliationModal
      .open()
      .then((affiliation) => {
        if (!affiliation) {
          return;
        }
        this.onChangeSite(affiliation);
      })
      .catch(() => {}); // (cancel)
  }
}
