import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ACL } from './acl.service';
import { DirtAclDirective } from './acl.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [DirtAclDirective],
  exports: [DirtAclDirective],
})
export class AclModule {
  static forRoot(): ModuleWithProviders<AclModule> {
    return {
      ngModule: AclModule,
      providers: [ACL],
    };
  }
}
