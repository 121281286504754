<div class="dirt-wrapper">
  <ng-container *ngFor="let step of steps; index as i; last as isLast">
    <div class="dirt-step-circle" [ngClass]="{ 'dirt-current-step': activeStep === step.step }">
      <span>{{ i + 1 }}</span>
    </div>
    <div class="dirt-step-label" [ngClass]="{ 'dirt-current-step': activeStep === step.step }">
      <span [attr.data-label]="step.label">{{ step.label }}</span>
    </div>
    <div *ngIf="!isLast" class="dirt-line"></div>
  </ng-container>
</div>
