<section class="dirt-section-detail dirt-account-detail">
  <div class="row" *ngIf="!isLoading; else loader">
    <div class="col-md-8">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>
      <h1 class="bounceInRight">{{ account.parent ? 'Children Detail' : 'Account Detail' }}</h1>

      <p *ngIf="account.parent">
        Belongs to <a [routerLink]="['/account/detail', account.parent.id]">{{ account.parent.name | dash }}</a>
      </p>

      <div class="headers-controls pull-right">
        <div>
          <button
            type="button"
            class="btn btn-sm btn-outline-primary"
            (click)="onMoveAccount()"
            *diAcl="'account.reparent'"
          >
            Move
          </button>

          <!-- Create Event Button -->
          <ng-container *ngIf="account.id">
            <a
              [routerLink]="['/event/create']"
              *diAcl="'event.create || session.create'"
              [queryParams]="{ type: 'account', id: account.id }"
              class="btn btn-success btn-sm"
              role="button"
              target="_blank"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
              <span>Create Event</span>
            </a>
          </ng-container>
        </div>

        <!-- Inactive status toggle -->
        <div class="inactive-toggle">
          <label class="col-form-label col-sm-4 col-md-2">Inactive</label>
          <div class="col-sm-8 col-md-10 pt-1">
            <ui-switch
              size="small"
              color="#6d5cae"
              [checked]="account.inactive === true"
              [disabled]="!!account?.parent && account?.parent.inactive === true"
              (change)="onInactiveChange($event)"
            ></ui-switch>
          </div>
        </div>
      </div>

      <br /><br /><br />

      <dirt-account-form
        #frmAccount="frmAccount"
        [account]="account"
        [currentJobType]="currentJob?.type"
        (validityChange)="onFormValidityChange($event)"
      ></dirt-account-form>

      <!-- Job controls -->
      <div *ngIf="hasJobForCurrentUser; else updateControls">
        <div class="pull-left">
          <!-- Unable to Compile -->
          <button class="btn btn-outline-danger" (click)="onJobUtc()">Unable to Compile!</button>
        </div>

        <div class="pull-right">
          <!-- Save as Draft -->
          <button
            class="btn btn-secondary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="isSubmitting"
            (click)="onJobDraft(frmAccount.getValue())"
          >
            Save as Draft
          </button>

          <!-- Save -->
          <button
            class="btn btn-secondary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="(!isFormValid && !canSaveAnyway) || isSubmitting"
            (click)="onSave(frmAccount.getValue())"
          >
            {{ !isFormValid && canSaveAnyway ? 'Save Anyway' : 'Save' }}
          </button>

          <!-- Submit -->
          <button
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="(!isFormValid && !canSaveAnyway) || isSubmitting"
            (click)="onSubmitJob(frmAccount.getValue())"
          >
            {{ !isFormValid && canSaveAnyway ? 'Submit Job Anyway' : 'Submit Job' }}
          </button>
        </div>
      </div>

      <!-- Standard controls -->
      <ng-template #updateControls>
        <div class="pull-right" *ngIf="canSubmitNonJob">
          <button
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="(!isFormValid && !canSaveAnyway) || isSubmitting"
            (click)="onSave(frmAccount.getValue(), true)"
          >
            {{ !isFormValid && canSaveAnyway ? 'Save Anyway' : 'Save' }}
          </button>
        </div>
      </ng-template>
    </div>

    <div class="col-md-4">
      <ul ngbNav #nav="ngbNav" class="nav-tabs" (activeIdChange)="onActiveIdChange($event)">
        <!-- Workflow -->
        <li ngbNavItem>
          <a ngbNavLink>Workflow</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <dt class="col-sm-4" [hidden]="!isClaimedByOtherUser()"></dt>
              <dd class="col-sm-8" [hidden]="!isClaimedByOtherUser()">
                <span title="s/o else started working recently" class="badge badge-info">Claimed</span>
              </dd>

              <dt class="col-sm-4" *ngIf="hasJobForCurrentUser">Job</dt>
              <dd class="col-sm-8" *ngIf="hasJobForCurrentUser">
                {{ currentJob?.type + (currentJob?.hint ? ' - ' + currentJob.hint : '') }}
              </dd>

              <dt class="col-sm-4" *ngIf="account.accountID">Account ID (Legacy)</dt>
              <dd class="col-sm-8" *ngIf="account.accountID">{{ account.accountID }}</dd>

              <dt class="col-sm-4" [ngClass]="{ 'di-separator': hasJobForCurrentUser }">Created</dt>
              <dd class="col-sm-8" [ngClass]="{ 'di-separator': hasJobForCurrentUser }">
                {{ account.createdAt | date : 'medium' }}
              </dd>

              <dt class="col-sm-4 text-truncate">Modified</dt>
              <dd class="col-sm-8">{{ account.updatedAt | date : 'medium' }}</dd>

              <dt class="col-sm-4 text-truncate">Priority</dt>
              <dd class="col-sm-8">
                <ngb-rating
                  [rate]="account.priority"
                  (rateChange)="setPriority(account, $event)"
                  max="5"
                  [readonly]="!isPriorityEditable"
                >
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
                  </ng-template>
                </ngb-rating>
                <i class="fa fa-circle-o-notch fa-spin" [hidden]="!isSavingPriority"></i>
              </dd>

              <dt class="col-sm-4 text-truncate">Status</dt>
              <dd class="col-sm-8">
                <span *diAcl="'!account.update.prop.meta.status'">{{ account._meta?.status | capitalize }}</span>
                <ng-container *diAcl="'account.update.prop.meta.status'">
                  <dirt-status
                    [statuses]="statuses"
                    [assigneeStatuses]="assigneeStatuses"
                    [disabledStatuses]="disabledStatuses"
                    [_meta]="account._meta || {}"
                    (onStatusSelected)="onStatusChange($event)"
                  >
                  </dirt-status>
                </ng-container>
                <i class="fa fa-circle-o-notch fa-spin" [hidden]="!isSavingStatus"></i>
              </dd>

              <dt class="col-sm-4">Version</dt>
              <dd class="col-sm-8">{{ account._version || 0 }}</dd>

              <ng-container *ngIf="hasUnpolishedAffiliations">
                <dt class="col-sm-4 di-separator" title="Central organizations did not yet check all organizations">
                  Unpolished organizations
                </dt>
                <dd class="col-sm-8 di-separator">YES</dd>
              </ng-container>

              <dt class="col-sm-4" [ngClass]="{ 'di-separator': !hasUnpolishedAffiliations }">Delivery Ready</dt>
              <dd class="col-sm-8" [ngClass]="{ 'di-separator': !hasUnpolishedAffiliations }">
                <i
                  class="fa"
                  [ngClass]="{ 'fa-check': account.readyForDelivery, 'fa-times': !account.readyForDelivery }"
                  aria-hidden="true"
                ></i>
              </dd>

              <ng-container *ngIf="account.identify?.at">
                <dt class="col-sm-4 text-truncate di-separator">Identified at</dt>
                <dd class="col-sm-8 di-separator">{{ account.identify.at | date : 'medium' }}</dd>

                <dt class="col-sm-4 text-truncate">Identified by</dt>
                <dd class="col-sm-8 font-italic">
                  <dirt-username [userId]="account.identify.by"></dirt-username>
                </dd>
              </ng-container>

              <ng-container *ngIf="account.mapping?.at">
                <dt class="col-sm-4 text-truncate">Mapped at</dt>
                <dd class="col-sm-8">{{ account.mapping.at | date : 'medium' }}</dd>

                <dt class="col-sm-4 text-truncate">Mapped by</dt>
                <dd class="col-sm-8 font-italic">
                  <dirt-username [userId]="account.mapping.by"></dirt-username>
                </dd>
              </ng-container>

              <ng-container *ngIf="account.profiling?.at">
                <dt class="col-sm-4 text-truncate">Profiled at</dt>
                <dd class="col-sm-8">{{ account.profiling.at | date : 'medium' }}</dd>

                <dt class="col-sm-4 text-truncate">Profiled by</dt>
                <dd class="col-sm-8 font-italic">
                  <dirt-username [userId]="account.profiling.by"></dirt-username>
                </dd>
              </ng-container>

              <ng-container *ngIf="account.review?.at">
                <dt class="col-sm-4 text-truncate">Reviewed at</dt>
                <dd class="col-sm-8">{{ account.review.at | date : 'medium' }}</dd>

                <dt class="col-sm-4 text-truncate">Reviewed by</dt>
                <dd class="col-sm-8 font-italic">
                  <dirt-username [userId]="account.review.by"></dirt-username>
                </dd>
              </ng-container>
            </dl>
          </ng-template>
        </li>

        <!-- Comments -->
        <ng-template [diAcl]="'account.comment.list'">
          <li ngbNavItem>
            <a ngbNavLink>Comments</a>
            <ng-template ngbNavContent>
              <dirt-comment-list [ownerId]="id" [enableCreate]="canCreateComments"></dirt-comment-list>
            </ng-template>
          </li>
        </ng-template>

        <!-- Audit Log -->
        <ng-template [diAcl]="'account.audit.list'">
          <li ngbNavItem>
            <a ngbNavLink>Audit Log</a>
            <ng-template ngbNavContent>
              <dirt-audit-log [id]="id" [entityAPI]="svcAccount"></dirt-audit-log>
            </ng-template>
          </li>
        </ng-template>

        <!-- Audit Log Account Affiliations -->
        <ng-template [diAcl]="'account.affiliation.audit.list'">
          <li ngbNavItem="account-affiliation-audits">
            <a ngbNavLink>Audit Log Affiliations</a>
            <ng-template ngbNavContent>
              <dirt-audit-log [id]="accountAffiliationIds" [entityAPI]="svcAccountAffiliation"></dirt-audit-log>
            </ng-template>
          </li>
        </ng-template>

        <!-- Job History -->
        <li ngbNavItem *diAcl="'account.job-history.list'">
          <a ngbNavLink>Job History</a>
          <ng-template ngbNavContent>
            <dirt-job-history [entityId]="id"></dirt-job-history>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-guideline-meta"></div>
    </div>

    <div class="col-md-12" *diAcl="'account-person.list'">
      <!-- collapse header -->
      <div class="card-header toggle-section" (click)="toggleCollapseSettings('person')">
        <div class="d-inline-block">
          <i
            class="fa fa-lg"
            [ngClass]="{ 'fa-angle-right': isCollapsed.get('person'), 'fa-angle-down': !isCollapsed.get('person') }"
            aria-hidden="true"
          ></i>
          <span class="font-weight-bold pl-1 pr-2">Key People</span>
        </div>
      </div>
      <div [ngbCollapse]="isCollapsed.get('person')">
        <dirt-account-person-list [account]="account"></dirt-account-person-list>
      </div>
    </div>

    <div class="col-md-12" *diAcl="'account-financial-information.list'">
      <!-- collapse header -->
      <div class="card-header toggle-section" (click)="toggleCollapseSettings('financial')">
        <div class="d-inline-block">
          <i
            class="fa fa-lg"
            [ngClass]="{
              'fa-angle-right': isCollapsed.get('financial'),
              'fa-angle-down': !isCollapsed.get('financial')
            }"
            aria-hidden="true"
          ></i>
          <span class="font-weight-bold pl-1 pr-2">Financial Information</span>
        </div>
      </div>
      <!-- collapsible section -->
      <div [ngbCollapse]="isCollapsed.get('financial')">
        <dirt-account-financial-information-list [accountId]="id"></dirt-account-financial-information-list>
      </div>
    </div>

    <ng-template [diAcl]="'account-medical-information.list'" [diAclAnd]="account?.type !== 'PAYER'">
      <div class="col-md-12">
        <!-- collapse header -->
        <div class="card-header toggle-section" (click)="toggleCollapseSettings('medical')">
          <div class="d-inline-block">
            <i
              class="fa fa-lg"
              [ngClass]="{ 'fa-angle-right': isCollapsed.get('medical'), 'fa-angle-down': !isCollapsed.get('medical') }"
              aria-hidden="true"
            ></i>
            <span class="font-weight-bold pl-1 pr-2">Medical Information</span>
          </div>
        </div>
        <!-- collapsible section -->
        <div [ngbCollapse]="isCollapsed.get('medical')">
          <dirt-account-medical-information-list [accountId]="id"></dirt-account-medical-information-list>
        </div>
      </div>
    </ng-template>

    <ng-template [diAcl]="'division.list'" [diAclAnd]="account && account.level < 5">
      <div class="col-md-12">
        <!-- collapse header -->
        <div class="card-header toggle-section" (click)="toggleCollapseSettings('division')">
          <div class="d-inline-block">
            <i
              class="fa fa-lg"
              [ngClass]="{
                'fa-angle-right': isCollapsed.get('division'),
                'fa-angle-down': !isCollapsed.get('division')
              }"
              aria-hidden="true"
            ></i>
            <span class="font-weight-bold pl-1 pr-2">Children</span>
          </div>
        </div>
        <div [ngbCollapse]="isCollapsed.get('division')">
          <dirt-account-simple-list [parentId]="id" class="expandable-section"></dirt-account-simple-list>
        </div>
      </div>
    </ng-template>

    <div class="col-md-12" *diAcl="'account.affiliation.list'">
      <!-- collapse header -->
      <div class="card-header toggle-section" (click)="toggleCollapseSettings('affiliation')">
        <div class="d-inline-block">
          <i
            class="fa fa-lg"
            [ngClass]="{
              'fa-angle-right': isCollapsed.get('affiliation'),
              'fa-angle-down': !isCollapsed.get('affiliation')
            }"
            aria-hidden="true"
          ></i>
          <span class="font-weight-bold pl-1 pr-2">Organizations</span>
        </div>
      </div>
      <div [ngbCollapse]="isCollapsed.get('affiliation')">
        <dirt-account-affiliation-list
          [accountId]="id"
          (affiliationRemoved)="onAffiliationRemove($event)"
          (selectHQ)="onSetHQ()"
        ></dirt-account-affiliation-list>
      </div>
    </div>

    <div class="col-md-12" *diAcl="'account.affiliation-suspect.list'">
      <!-- collapse header -->
      <div class="card-header toggle-section" (click)="toggleCollapseSettings('affiliationSuspects')">
        <div class="d-inline-block">
          <i
            class="fa fa-lg"
            [ngClass]="{
              'fa-angle-right': isCollapsed.get('affiliationSuspects'),
              'fa-angle-down': !isCollapsed.get('affiliationSuspects')
            }"
            aria-hidden="true"
          ></i>
          <span class="font-weight-bold pl-1 pr-2">Organization Suspects</span>
        </div>
      </div>
      <div [ngbCollapse]="isCollapsed.get('affiliationSuspects')">
        <dirt-account-affiliation-suspects-list
          [accountId]="id"
          (matchChanged)="onMatchChange($event)"
        ></dirt-account-affiliation-suspects-list>
      </div>
    </div>

    <div class="col-md-12" *diAcl="'account-payer-information.list'">
      <!-- collapse header -->
      <div class="card-header toggle-section" (click)="toggleCollapseSettings('payer')">
        <div class="d-inline-block">
          <i
            class="fa fa-lg"
            [ngClass]="{ 'fa-angle-right': isCollapsed.get('payer'), 'fa-angle-down': !isCollapsed.get('payer') }"
            aria-hidden="true"
          ></i>
          <span class="font-weight-bold pl-1 pr-2">Payer Information</span>
        </div>
      </div>
      <!-- collapsible section -->
      <div [ngbCollapse]="isCollapsed.get('payer')">
        <dirt-account-payer-information-list [accountId]="id"></dirt-account-payer-information-list>
      </div>
    </div>
  </div>

  <!-- Loading Message -->
  <ng-template #loader>
    <p class="dirt-loading">
      <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
      <span class="sr-only">Loading...</span>
    </p>
  </ng-template>
</section>
