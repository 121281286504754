<div class="di-update-results-step">
  <h5 *ngIf="!(updating$ | async)" class="text-success">{{ updatedCount$ | async }} records successfully updated</h5>
  <h5 *ngIf="!(updating$ | async) && (errorCount$ | async) > 0" class="text-danger">
    {{ errorCount$ | async }} records failed, check details below
  </h5>
  <div class="di-errors">
    <table class="table table-striped table-borderless" *ngIf="!(updating$ | async) && (errorCount$ | async) > 0">
      <thead>
        <tr>
          <th scope="col" class="bold">{{ (mode$ | async) === Mode.manual ? 'Id' : 'Name' }}</th>
          <th scope="col" class="bold">Error message</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let error of errors$ | async">
          <td>{{ (mode$ | async) === Mode.manual ? error.entity.id : titleFormatter(error.entity) }}</td>
          <td class="text-danger">{{ error.error }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <dirt-spinner [show]="updating$ | async" bgColor="transparent"></dirt-spinner>
  <div *ngIf="updating$ | async">{{ numberDone$ | async }} of {{ numberRecords$ | async }}</div>
</div>
