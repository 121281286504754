<span>
  <input
    class="form-control"
    id="selected-users"
    [(ngModel)]="selectedUser"
    [ngbTypeahead]="search"
    [resultTemplate]="customItemTemplate"
    [inputFormatter]="formatName"
    [disabled]="disabled"
    (selectItem)="onSelectUser($event)"
  />
</span>

<!-- Typeahead Item Template -->
<ng-template #customItemTemplate let-model="result" let-t="term">
  <span>{{ model.user_metadata?.firstName }}</span>
  <span>{{ model.user_metadata?.lastName }}</span>
  <span><{{ model.email }}></span>
</ng-template>
