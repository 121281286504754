import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import { Auth } from '../../shared/services/auth/auth.service';
import { User } from '../../user/shared/user';

@Component({
  selector: 'dirt-admin-next',
  templateUrl: 'next.component.html',
  styleUrls: ['next.component.scss'],
})
export class AdminNextComponent implements OnInit {
  profile: User & { nickname? };
  userFirstName: string;
  isProfileReviewer = false;
  isMultipleActivityReviewer = false;

  pools: string[] = [];

  constructor(private auth: Auth) {}

  ngOnInit() {
    this.auth
      .getProfile()
      .pipe(take(1))
      .subscribe((profile) => {
        if (!profile) {
          return;
        }
        this.profile = profile;
        const meta = this.profile.user_metadata || ({} as any);
        this.userFirstName = meta.firstName || this.profile.nickname;
        if (this.profile.app_metadata && (this.profile.app_metadata.roles || []).includes('PROFILE_REVIEWER')) {
          this.isProfileReviewer = true;
        }
        const workflowReviewerRoles = (this.profile.app_metadata?.roles || []).filter((role) =>
          ['ASSOCIATION_ID_REVIEWER', 'EVENT_ID_REVIEWER', 'GUIDELINE_ID_REVIEWER'].includes(role)
        );
        this.isMultipleActivityReviewer = workflowReviewerRoles.length > 1;

        this.pools = profile.pools.map((p) => p.name);
      });
  }
}
