<section class="dirt-section-detail dirt-event-series-detail">
  <div class="row" *ngIf="!isLoading; else loader">
    <div class="col-md-8">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <div class="dirt-title-ct">
        <h1 class="bounceInRight">Event Series Detail</h1>
      </div>

      <dirt-event-series-form
        #frmEventSeries="frmEventSeries"
        [model]="eventSeries"
        (validityChange)="onFormValidityChange($event)"
      ></dirt-event-series-form>

      <!-- Job controls -->
      <div *ngIf="hasJobForCurrentUser; else updateControls">
        <div class="pull-left">
          <!-- Unable to Compile -->
          <button class="btn btn-outline-danger" (click)="onJobUtc()">Unable to Compile!</button>

          <!-- No Info -->
          <button class="btn btn-outline-warning" (click)="onJobNoInfo()">No Info</button>
        </div>

        <div class="pull-right">
          <!-- Save as Draft -->
          <button
            class="btn btn-secondary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="isSubmitting"
            (click)="onJobDraft()"
          >
            Save as Draft
          </button>

          <!-- Submit -->
          <button
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!isFormValid || isSubmitting"
            (click)="onSubmitJob(frmEventSeries.getValue())"
          >
            Submit Job
          </button>
        </div>
      </div>

      <!-- Standard controls -->
      <ng-template #updateControls>
        <div class="pull-right" *ngIf="canSubmitNonJob">
          <button
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!isFormValid || isSubmitting"
            (click)="onSave(frmEventSeries.getValue())"
          >
            Save
          </button>
        </div>
      </ng-template>
    </div>

    <div class="col-md-4">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Workflow -->
        <li ngbNavItem>
          <a ngbNavLink>Workflow</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <dt class="col-sm-4" [hidden]="!isClaimedByOtherUser()"></dt>
              <dd class="col-sm-8" [hidden]="!isClaimedByOtherUser()">
                <span title="s/o else started working recently" class="badge badge-info">Claimed</span>
              </dd>

              <dt class="col-sm-4" *ngIf="hasJobForCurrentUser">Job</dt>
              <dd class="col-sm-8" *ngIf="hasJobForCurrentUser">
                {{ currentJob?.type + (currentJob?.hint ? ' - ' + currentJob.hint : '') }}
              </dd>

              <dt class="col-sm-4">No Info</dt>
              <dd class="col-sm-8">
                <span *diAcl="'eventSeries.update.prop.noInfo'">
                  <ui-switch
                    [disabled]="!!isSubmitting"
                    [checked]="!!eventSeries.noInfo"
                    (change)="onNoInfoChange($event)"
                    size="small"
                  ></ui-switch>
                  <i class="fa fa-pulse fa-spinner di-va-super" [hidden]="!isSubmitting"></i>
                </span>
                <span *diAcl="'!eventSeries.update.prop.noInfo'">
                  <i
                    class="fa"
                    [ngClass]="{ 'fa-check': eventSeries.noInfo, 'fa-times': !eventSeries.noInfo }"
                    aria-hidden="true"
                  ></i>
                </span>
              </dd>

              <dt class="col-sm-4" [ngClass]="{ 'di-separator': hasJobForCurrentUser }">Created</dt>
              <dd class="col-sm-8" [ngClass]="{ 'di-separator': hasJobForCurrentUser }">
                {{ eventSeries.createdAt | date : 'medium' }}
              </dd>

              <dt class="col-sm-4 text-truncate">Modified</dt>
              <dd class="col-sm-8">{{ eventSeries.updatedAt | date : 'medium' }}</dd>

              <dt class="col-sm-4">Version</dt>
              <dd class="col-sm-8">{{ eventSeries._version || 0 }}</dd>
            </dl>
          </ng-template>
        </li>

        <!-- Comments -->
        <ng-template [diAcl]="'eventSeries.comment.list'">
          <li ngbNavItem>
            <a ngbNavLink>Comments</a>
            <ng-template ngbNavContent>
              <dirt-comment-list [ownerId]="id" [enableCreate]="canCreateComments"></dirt-comment-list>
            </ng-template>
          </li>
        </ng-template>

        <!-- Audit Log -->
        <ng-template [diAcl]="'eventSeries.audit.list'">
          <li ngbNavItem>
            <a ngbNavLink>Audit Log</a>
            <ng-template ngbNavContent>
              <dirt-audit-log [id]="id" [entityAPI]="svcEventSeries"></dirt-audit-log>
            </ng-template>
          </li>
        </ng-template>

        <!-- Job History -->
        <li ngbNavItem *diAcl="'eventSeries.job-history.list'">
          <a ngbNavLink>Job History</a>
          <ng-template ngbNavContent>
            <dirt-job-history [entityId]="id"></dirt-job-history>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-event-series-meta"></div>
    </div>

    <div class="col-md-12">
      <dirt-event-series-event-list
        [seriesId]="eventSeries.id"
        [events]="eventSeries.events"
        [currentJobType]="currentJob?.type"
        (eventsChange)="onEventsChange($event)"
      ></dirt-event-series-event-list>
    </div>
  </div>

  <!-- Loading Message -->
  <ng-template #loader>
    <p class="dirt-loading">
      <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
      <span class="sr-only">Loading...</span>
    </p>
  </ng-template>
</section>
