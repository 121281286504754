import { Affiliation } from '../../affiliation/shared/affiliation';

export enum AffiliationSuspectStatus {
  MATCH = 'MATCH',
  MISMATCH = 'MISMATCH',
  SUSPECT = 'SUSPECT',
}

export class AccountAffiliationSuspect {
  id: string;
  accountId: string;
  affiliation: Affiliation;
  status?: AffiliationSuspectStatus;
}
