import { Component, ViewChild, AfterViewChecked, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { Video, Source } from '../../../../video/shared/video';
import { ProfileVideoLinkOptions } from './video-link.interface';
import { Utils } from '../../../../common/utils';
import { Value } from '../../../../shared/services/value/value';
import { ValueAPI } from '../../../../shared/services/value/value-api.service';
import { ValueType } from '../../../../shared/enum/value-type.enum';

@Component({
  selector: 'dirt-profile-video-link',
  templateUrl: 'video-link.component.html',
  styleUrls: ['video-link.component.scss'],
})
export class ProfileVideoLinkComponent implements OnInit, AfterViewChecked {
  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  model: Video = new Video();

  valid: boolean;

  options: ProfileVideoLinkOptions;

  isVideoIdInvalid = null;
  isValidatingVideoId = false;

  isThumbnailUrlInvalid = null;
  isValidatingThumbnailUrl = false;

  minDate = { year: 2001, month: 1, day: 1 };
  maxDate = { year: new Date().getFullYear(), month: 12, day: 31 };

  isSubmitting = false;

  languages$: Observable<Value[]>;

  constructor(public activeModal: NgbActiveModal, public svcValue: ValueAPI) {}

  ngOnInit(): void {
    this.languages$ = this.svcValue.find(ValueType.Language, Number.MAX_SAFE_INTEGER, 0, '+title');

    this.initValues();
  }

  initValues() {
    this.setInitialValues();
    this.setMinDate();
  }

  setInitialValues() {
    if (this.options?.video) {
      if (this.options.video.publicationDate) {
        this.options.video.publicationDate = this.ngbDateFromDate(this.options.video.publicationDate);
      }
      if (this.options.video.vendorId) {
        this.isVideoIdInvalid = false;
      }
      this.model = this.options.video;
    } else {
      this.model.source = Source.YOUTUBE;
    }
  }
  ngAfterViewChecked() {
    setTimeout(() => {
      this.valid = this.ngForm.form.valid;
    }, 1000);
  }

  async onSubmit(addAnother: boolean) {
    if (this.isSubmitting) {
      return;
    }

    let normalizedDate;
    if (this.model.publicationDate) {
      normalizedDate = this.ngbStructToDate(this.model.publicationDate as unknown as NgbDate);
    }

    const video = {
      id: this.model.id,
      vendorId: this.model.vendorId,
      source: this.model.source,
      publicationDate: normalizedDate,
      title: this.model.title,
      thumbnailUrl: this.model.thumbnailUrl,
      description: this.model.description,
      compilerComment: this.model.compilerComment,
    };

    if (!video.vendorId) {
      // very slow CPU and very fast clicks
      alert('Pls. add a video id first!');
      return;
    }

    this.isSubmitting = true;

    this.options.addVideo(video).subscribe(() => {
      this.isSubmitting = false;

      if (!addAnother) {
        return this.activeModal.close();
      }

      this.model = new Video();
      this.initValues();
    });
  }

  async validateVideoId() {
    if (this.isVideoIdInvalid) {
      this.ngForm.controls['vendorId'].setErrors({ required: true });
    }
    if (!this.model.source || !this.model.vendorId) {
      return;
    }
    if (this.model.source === Source.YOUTUBE) {
      return this.validateYoutubeId(this.model.vendorId);
    }
    this.isValidatingVideoId = true;

    this.validateUrl().subscribe(
      (valid) => {
        this.isVideoIdInvalid = !valid;
        this.isValidatingVideoId = false;
        const error = valid ? null : { required: true };
        this.ngForm.controls['vendorId'].setErrors(error);
      },
      (error) => {
        this.isVideoIdInvalid = true;
        this.isValidatingVideoId = false;
      }
    );
  }

  validateUrl() {
    if (!this.options.validateUrl || !this.options.sourceUrlMap) {
      return;
    }
    if (!this.model.source || !this.model.vendorId) {
      return;
    }
    const urlPrefix = this.options.sourceUrlMap[this.model.source];
    return this.options.validateUrl(urlPrefix + this.model.vendorId);
  }

  validateYoutubeId(id) {
    this.isValidatingVideoId = true;
    const img = new Image();
    img.src = 'http://img.youtube.com/vi/' + id + '/mqdefault.jpg';
    img.onload = () => {
      if (!img.width || img.width === 120) {
        this.isVideoIdInvalid = true;
      } else {
        this.isVideoIdInvalid = false;
      }

      this.isValidatingVideoId = false;
      const error = this.isVideoIdInvalid ? { required: true } : null;
      this.ngForm.controls['vendorId'].setErrors(error);
    };

    img.onerror = () => {
      this.isValidatingVideoId = false;
      this.isVideoIdInvalid = true;

      this.ngForm.controls['vendorId'].setErrors({ required: true });
    };
  }

  ngbDateFromDate(date: Date): NgbDate {
    const value = new Date(date);
    return NgbDate.from({
      day: value.getDate(),
      month: value.getMonth() + 1,
      year: value.getFullYear(),
    });
  }

  private ngbStructToDate(date: NgbDateStruct): string {
    if (!date) {
      return;
    }

    const dt = Date.UTC(date.year, date.month - 1, date.day);
    return new Date(dt).toISOString();
  }

  setMinDate() {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 20);
    this.minDate = {
      year: date.getFullYear(),
      month: date.getMonth(),
      day: date.getDate(),
    };
  }

  validateThumbnailurl() {
    if (!this.model.thumbnailUrl) {
      this.isThumbnailUrlInvalid = false;
      return;
    }
    this.isValidatingThumbnailUrl = true;
    const isEcancer = this.model.source === Source.ECANCER;
    if (isEcancer && this.isThumbnailUrlInvalid) {
      this.ngForm.controls['thumbnailUrl'].setErrors({ required: true });
    }
    const img = new Image();
    img.src = this.model.thumbnailUrl;
    img.onload = () => {
      this.isThumbnailUrlInvalid = !!img.width ? false : true;
      this.isValidatingThumbnailUrl = false;
      if (isEcancer) {
        const error = this.isThumbnailUrlInvalid ? { required: true } : null;
        this.ngForm.controls['thumbnailUrl'].setErrors(error);
      }
    };
    img.onerror = () => {
      this.isValidatingThumbnailUrl = false;
      this.isThumbnailUrlInvalid = true;
      if (isEcancer) {
        this.ngForm.controls['thumbnailUrl'].setErrors({ required: true });
      }
    };
  }

  onSourceChange() {
    this.validateVideoId();
  }

  getEcacnerVideoId(url: string) {
    return (url.split('https://ecancer.org/en/video/')[1] || '').split('#')[0];
  }

  onClipboardPaste(evnt: Event) {
    (navigator as any).clipboard
      .readText()
      .then((text) => {
        let videoId;
        if (this.model.source === Source.YOUTUBE) {
          videoId = Utils.getYouTubeId(text);
        } else if (this.model.source === Source.ECANCER) {
          videoId = this.getEcacnerVideoId(text);
        }
        if (videoId) {
          this.model.vendorId = videoId;
          this.validateVideoId();
        } else {
          window.alert('Unable to extract videoId from the copied text!');
        }
      })
      .catch(() => window.alert('You have to grant access to read from your clipboard!'));
  }
}
