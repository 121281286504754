import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { GuidelineAPI } from '../shared/guideline-api.service';
import { ACL } from '../../shared/acl/acl.service';

interface Stat {
  user_id: string;
  name: string;
  completed: number;
  total: number;
  needed: number;
  percent: number;
  remaining: number;
  completedReadyForCompilation?: number;
  completedDone?: number;
}

@Component({
  selector: 'dirt-guideline-qc-next',
  templateUrl: './qc-next.component.html',
  styleUrls: ['./qc-next.component.scss'],
})
export class GuidelineQcNextComponent implements OnInit {
  @Input('isMultipleActivityReviewer')
  isMultipleActivityReviewer = false;

  queue: any;
  isLoadingNext: boolean = false;
  exceedCap: boolean = false;
  expanded: boolean = true;
  all: Stat;

  isIdQC: boolean;

  constructor(private router: Router, private svcAcl: ACL, private svcGuideline: GuidelineAPI) {}

  ngOnInit() {
    this.isIdQC = this.svcAcl.hasCredential('guideline.idqc');
    this.getQueue();
  }

  getQueue() {
    this.svcGuideline[this.isIdQC ? 'idQcQueue' : 'qcQueue']().subscribe((q) => {
      this.queue = q.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      if (this.queue.length >= 0) {
        this.setAggregatedStats();
      }
    });
  }

  getNext(): void {
    this.isLoadingNext = true;
    this.svcGuideline[this.isIdQC ? 'findIDQCNext' : 'findQcNext'](this.exceedCap).subscribe(
      (guidelineId) => {
        if (!guidelineId) {
          return alert('No Guideline to be reviewed at the moment! Go and get some rest ;)');
        }

        this.router.navigate(['/guideline/detail', guidelineId]);
      },
      (err) => alert('Error!'),
      () => (this.isLoadingNext = false)
    );
  }

  toggleExpanded(name: string): void {
    this.expanded = !this.expanded;
  }

  isExpanded(): boolean {
    return this.expanded;
  }

  setAggregatedStats() {
    if (!this.queue.length) {
      return;
    }
    const aggregation = this.queue
      .map(({ completed = 0, total = 0, needed = 0, remaining = 0 }) => ({ completed, total, needed, remaining }))
      .reduce((prev, current) => {
        return {
          completed: prev.completed + current.completed,
          total: prev.total + current.total,
          needed: prev.needed + current.needed,
          remaining: prev.remaining + current.remaining,
        };
      });
    aggregation.percent = aggregation.total !== 0 ? Math.ceil((aggregation.completed * 100) / aggregation.total) : 0;
    this.all = aggregation;
  }
}
