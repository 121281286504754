import { Component, OnInit, Input } from '@angular/core';
import { Step } from '../../step.enum';
import { StepOptions } from './step-options';

@Component({
  selector: 'dirt-bulk-update-stepper',
  templateUrl: './bulk-update-stepper.component.html',
  styleUrls: ['./bulk-update-stepper.component.scss'],
})
export class BulkUpdateStepperComponent implements OnInit {
  @Input()
  steps: StepOptions[];

  @Input()
  activeStep: Step;

  constructor() {}

  ngOnInit() {}
}
