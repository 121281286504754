<section class="dirt-section-list dirt-profile-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span *ngIf="total">{{ total.count | number }}</span>
        <span *ngIf="!total">...</span>
        Profiles
      </h1>
    </div>
    <div class="col-sm-4">
      <input
        type="search"
        class="form-control"
        [formControl]="searchCtrl"
        [(ngModel)]="searchTerm"
        placeholder="Search..."
      />
    </div>
    <div class="col-sm-4">
      <div class="pull-right">
        <!-- TODO: replace that by the generic filter component when there is a change to make here -->
        <dirt-profile-filter (onFilter)="setFilter($event)"></dirt-profile-filter>

        <dirt-button-dropdown
          [title]="'Bulk'"
          [icon]="'fa-pencil'"
          *diAcl="'profile.list.bulkUpdate || profile.bulkList || profile-video.bulkList'"
        >
          <dirt-bulk-update
            *diAcl="'profile.list.bulkUpdate'"
            [filter]="filter"
            [search]="searchTerm"
            (refresh)="onBulkUpdateRefresh()"
            [options]="bulkUpdateConfig"
          ></dirt-bulk-update>

          <button
            *diAcl="'profile-clinical-trial.bulkList'"
            class="list-group-item list-group-item-action"
            [title]="bulkListDelegate.specialHint || ''"
            (click)="onOpenBulkListDialog()"
          >
            <i class="fa fa-th-list" aria-hidden="true"></i>
            Bulk Import Profile Clinical Trials
          </button>

          <button
            *diAcl="'profile.bulkList'"
            class="list-group-item list-group-item-action"
            [title]="profileBulkListDelegate.specialHint || ''"
            (click)="onOpenProfileBulkListDialog()"
          >
            <i class="fa fa-th-list" aria-hidden="true"></i>
            Bulk Import Profile
          </button>

          <button
            *diAcl="'profile-video.bulkList'"
            class="list-group-item list-group-item-action"
            [title]="videoJobBulkListDelegate.specialHint || ''"
            (click)="onOpenProfileVideoJobsBulkListDialog()"
          >
            <i class="fa fa-th-list" aria-hidden="true"></i>
            Bulk upload Profile Video Jobs
          </button>

          <button
            *diAcl="'profile-job.bulkList'"
            class="list-group-item list-group-item-action"
            [title]="jobBulkListDelegate.specialHint || ''"
            (click)="onOpenBulkListJobDialog()"
          >
            <i class="fa fa-th-list" aria-hidden="true"></i>
            Bulk Import Job
          </button>
        </dirt-button-dropdown>
      </div>
    </div>
  </header>

  <!-- Filter Bar -->
  <dirt-filters-bar
    [filter]="filter"
    [config]="filterBarConfig"
    (filterClear)="onFilterClear()"
    (filterValueRemove)="onFilterValueRemove($event)"
  ></dirt-filters-bar>

  <div class="pull-right">
    <!-- Dashboards -->
    <dirt-button-dropdown [title]="'Dashboards'" [icon]="'fa-tachometer'" *diAcl="'profile.dashboards'">
      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/profiling-throughput/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Profiling throughput
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/profiling-qc-performance/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Profiling QC
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/qc-feedback/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        QC Feedback
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/video-base/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Video Base
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/video-workflow-performance/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Video Workflow Performance
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/signoff-qc-performance/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        SignOff QC
      </a>
    </dirt-button-dropdown>

    <!-- Export -->
    <button class="btn btn-secondary btn-sm" (click)="onExport()" *diAcl="'profile.export'">
      <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Export List
    </button>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-profile-list'"
    [pagingSize]="profiles?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Table -->
  <div *ngIf="profiles" [hidden]="isLoading">
    <table class="table table-sm table-hover" [hidden]="!profiles.length" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-state"></th>
          <th class="col-name">Name</th>
          <th class="col-automated"></th>
          <th class="col-verified"></th>
          <th class="col-tags"></th>
          <th class="col-country">Country</th>
          <th class="col-ta">Delivery projects</th>
          <th class="col-ta">Technical projects</th>
          <th class="col-status" [diColSorter]="'_meta.status'">Status</th>
          <th class="col-mode" [diColSorter]="'viewMode'">Mode</th>
          <th class="col-unpolished-dps" [diColSorter]="'unpolishedDPs'">Unpolished DPs</th>
          <th class="col-db-update" [diColSorter]="'person.review.at'">Last BD Update</th>
          <th class="col-polished" [diColSorter]="'polishedAt'">Last Polished</th>
          <th class="col-priority" [diColSorter]="'priority'">Priority</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="dirt-profile-row"
          *ngFor="
            let profile of profiles
              | paginate
                : {
                    id: 'dirt-profile-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  }
          "
        >
          <td class="col-state"></td>
          <td class="col-name">
            <a [routerLink]="['/profile/detail', profile.id]">
              <span>{{ profile.person.firstName }}</span>
              <span [hidden]="!profile.person.middleName">{{ profile.person.middleName }}</span>
              <span>{{ profile.person.lastName }}</span>
            </a>
          </td>
          <td class="col-automated">
            <span *ngIf="isAutoProject(profile)" ngbTooltip="Automated" placement="right" aria-hidden="true"
              >&#9398;</span
            >
          </td>
          <td class="col-verified">
            <dirt-verified-icon
              *ngIf="profile.verified"
              color="success"
              ngbTooltip="Verified"
              placement="top"
              aria-hidden="true"
            >
            </dirt-verified-icon>
          </td>
          <td class="col-tags"><dirt-tags [readonly]="true" [tags]="profile.tags"></dirt-tags></td>
          <td class="col-country">{{ getCountryTitle(profile.person.affiliation?.countryCode) }}</td>
          <td class="col-ta">
            <span [ngbTooltip]="getDelList(profile.person)">{{ getDelList(profile.person) }}</span>
          </td>
          <td class="col-ta">
            <span [ngbTooltip]="getTechList(profile.person)">{{ getTechList(profile.person) }}</span>
          </td>
          <td class="col-status">{{ profile._meta?.status | capitalize }}</td>
          <td class="col-mode"><dirt-profile-view-mode [profile]="profile"></dirt-profile-view-mode></td>
          <td class="col-unpolished-dps">{{ profile.unpolishedDPs }}</td>
          <td class="col-db-update">{{ profile?.person?.review?.at | date : 'dd.MM.y' }}</td>
          <td class="col-polished">{{ profile.polishedAt | date : 'dd.MM.y' }}</td>
          <td class="col-priority"><dirt-profile-priority [profile]="profile"></dirt-profile-priority></td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <p class="dirt-no-results" *ngIf="!profiles.length">No Profiles</p>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-profile-list'"
    [pagingSize]="profiles?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="isLoading || pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
