import { Component, OnInit, Input, OnChanges, SimpleChange, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

import { Contribution } from '../shared/contribution';
import { ContributionAPI } from '../shared/contribution-api.service';
import { SearchType } from '../shared/constant/contribution-search.enum';
import { EventAPI } from '../../event/shared/api.service';
import { Event } from '../../event/shared/event';
import { CaptureBarComponent } from '../../contribution-capture/shared/capture-bar/capture-bar.component';
import { ContributionEventPositions } from '../shared/constant/event-positions';
import { ACL } from '../../shared/acl/acl.service';
import { Roles } from '../../shared/acl/roles';
import { MoveContributionService } from '../shared/move-contribution-modal/move-contribution.service';

@Component({
  selector: 'dirt-contribution-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class ContributionListComponent implements OnInit, OnChanges {
  @Input()
  event: Event;

  @Input()
  isSearchType: boolean;

  @Input()
  disableDragAndDrop: boolean;

  @Input()
  qcSessionId?: string;

  @Output()
  verifiedCountChanged: EventEmitter<number> = new EventEmitter(); // delta of verified

  @ViewChild('captureBarComponent')
  captureBarComponent: CaptureBarComponent;

  contributions: Array<Contribution>;
  isLoading: boolean;
  sub: Subscription;
  searchTypes = SearchType;
  selectedSearchType = SearchType.Direct;

  total: any;
  pagingPage = 1;
  pagingLimit = 30;
  pagingSkip = 0;

  sort = '-_id';

  searchTerm: string;
  searchCtrl: FormControl = new FormControl('');

  contributionFormOpened = false;
  contributionEditId: string;
  flashingId: string;
  flashingEditId: string;

  initializedAt: Date;
  contribution: Contribution = new Contribution();
  prevSaved: Contribution = null;
  isFromCapture = false;
  wasVerified = false;
  isSubmitting = false;
  isSaving = false;

  expandedIds = [];
  allReviewed = false;
  allVerified = false;
  allContributionOnMove = false;

  contributionsToBeDragged: number;

  tableExpandView: boolean = false;
  onlyNeedsVerify: boolean = false;

  @Output() onVerifiedUpdate = new EventEmitter<any>();

  @Output() sessionUpdate = new EventEmitter<any>();
  shouldDisplayVerifiedControls: boolean;

  showDuplicates = false;

  constructor(
    private router: Router,
    private eventSvc: EventAPI,
    private service: ContributionAPI,
    private svcAcl: ACL,
    private svcMoveContributionModal: MoveContributionService
  ) {}

  ngOnInit() {
    this.attachSearchHandler();
    this.getParent();

    this.shouldDisplayVerifiedControls =
      !this.svcAcl.hasRole(Roles.EventCompiler) && !this.svcAcl.hasRole(Roles.AssociationCompiler);
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    const event = changes['event'];
    if (event) {
      this.event = event.currentValue;
      this.allContributionOnMove = false;
      this.doLoad();
    }
  }

  doLoad(): void {
    this.resetPagination();
    this.getCount();
    this.getContributions();
  }

  getContributions(): void {
    this.isLoading = true;

    let observable = this.service.find(
      this.event.id,
      this.pagingLimit,
      this.pagingSkip,
      this.sort,
      this.searchTerm,
      this.selectedSearchType,
      undefined,
      this.onlyNeedsVerify ? this.qcSessionId || undefined : undefined
    );
    if (this.showDuplicates) {
      observable = this.service.getDuplicates(this.event.id, this.pagingLimit, this.pagingSkip);
    }

    observable.pipe(tap(() => (this.isLoading = false))).subscribe((contributions) => {
      this.contributions = contributions;

      if (!this.showDuplicates) {
        this.setAllReviewedFlag();
        this.setAllVerifiedFlag();
      }
    });
  }

  getCount() {
    let observable = this.service.count(this.event.id, this.searchTerm, this.selectedSearchType);
    if (this.showDuplicates) {
      observable = this.service.getDuplicatesCount(this.event.id);
    }

    observable.subscribe((res) => (this.total = res));
  }

  getPage(page: number) {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getContributions();
  }

  navigateTo(route, e) {
    if (e && e.target.nodeName === 'A') {
      return;
    }

    this.router.navigate(route);
  }

  resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  onSort(field: string): void {
    if (this.showDuplicates) {
      return;
    }

    this.sort = field;
    this.resetPagination();
    this.getContributions();
  }

  onDeleteClick(contributionId: string, $event) {
    $event.stopPropagation();
    if (window.confirm('Do you want to remove this entry?')) {
      this.service.deleteById(contributionId).subscribe(() => {
        this.getContributions();
        this.getCount();
      });
    }
  }

  onSearchTypeChange(val: SearchType) {
    if (this.showDuplicates) {
      return;
    }

    this.selectedSearchType = val;
    this.doLoad();
  }

  attachSearchHandler(): void {
    this.searchCtrl.valueChanges.pipe(distinctUntilChanged(), debounceTime(400)).subscribe((val) => {
      if (typeof val !== 'undefined') {
        this.doLoad();
      }
    });
  }

  getContribution(id: string) {
    this.service.findById(id).subscribe((response) => (this.contribution = response));
  }

  onEditClicked(contributionId, $event) {
    $event.stopPropagation();
    this.wasVerified = !!this.contributions.find((c) => c.id === contributionId)?.verified;
    this.contributionEditId = contributionId;
    this.isFromCapture = false;
    // this.contribution = JSON.parse(JSON.stringify(this.contributions.find((c) => c.id === contributionId)));
    // this.contributionFormOpened = true;
  }

  onSubmit(contribution: Contribution): void {
    contribution.event = this.event.id;
    delete (contribution as any).title_removed;
    contribution['initializedAt'] = this.initializedAt;

    this.isSubmitting = true;
    this.service.create(contribution).subscribe(
      (resp) => {
        this.isSubmitting = false;
        this.prevSaved = resp;
        this.contributionFormOpened = false;
        this.contributions = [resp, ...(this.contributions || [])];
        this.flashingId = resp.id;
        setTimeout(() => {
          this.flashingId = null;
        }, 3000);
        this.getCount();
        this.captureBarComponent.currentContribComplete();
      },
      () => (this.isSubmitting = false)
    );
  }

  onSave(contribution: Contribution) {
    const newContribution = { ...contribution };
    newContribution.event = this.event.id;
    delete (newContribution as any).title_removed;
    newContribution['initializedAt'] = this.initializedAt;

    this.isSaving = true;
    this.service.create(newContribution).subscribe(
      (resp) => {
        this.isSaving = false;
        this.contributions = [{ ...resp, event: this.event }, ...(this.contributions || [])];
        this.prevSaved = resp;
        this.flashingId = resp.id;
        // contribution.title
        this.contribution = {
          title: newContribution.title,
          person: {
            position: newContribution.person.position,
          },
          event: this.event,
        } as any;
        if (this.event.startDate && !this.event.sessionDate) {
          this.contribution.person.contributionDate = new Date(
            Date.UTC(this.event.startDate.year, this.event.startDate.month - 1, this.event.startDate.day)
          );
        } else {
          if (this.event.sessionDate) {
            this.contribution.person.contributionDate = new Date(this.event.sessionDate);
          }
          if (this.event.sessionTime) {
            this.contribution.person.contributionTime = {
              hour: this.event.sessionTime.hour,
              minute: this.event.sessionTime.minute,
            };
          }
        }
        this.getCount();
        setTimeout(() => {
          this.flashingId = null;
        }, 3000);
        const wasCapture = this.captureBarComponent.currentContribComplete();
        // when we're coming from capture, we can check if there's more (small timeout to show there's a sequence)
        if (wasCapture) {
          setTimeout(() => {
            this.captureBarComponent.checkAnother();
          }, 500);
        }
      },
      () => (this.isSaving = false)
    );
  }

  onEdit(contribution: Contribution, emitVerifiedChanged?: boolean, emitVerifiedCountChanged: boolean = true) {
    const newVerified = !!contribution.verified;
    if (emitVerifiedCountChanged && newVerified !== this.wasVerified) {
      this.verifiedCountChanged.emit(newVerified ? 1 : -1);
    }

    this.isSubmitting = true;
    this.service.upsert(contribution).subscribe(
      (resp) => {
        this.isSubmitting = false;
        this.flashingEditId = resp.id;
        const index = this.contributions.findIndex((c) => c.id === contribution.id);
        this.contributions = [
          ...this.contributions.slice(0, index),
          { ...resp, event: this.contributions[index].event },
          ...this.contributions.slice(index + 1),
        ];
        this.contributionEditId = null;
        this.setAllReviewedFlag();
        if (emitVerifiedChanged) {
          this.setAllVerifiedFlag();
          this.onVerifiedUpdate.emit();
        }
        setTimeout(() => {
          this.flashingEditId = null;
        }, 3000);
      },
      () => (this.isSubmitting = false)
    );
  }

  getParent(): void {
    this.eventSvc.findById(this.event.id).subscribe((resp) => (this.contribution.event = resp));
  }

  onContributionFromCapture(contrib: Contribution) {
    this.initializedAt = new Date();
    this.contribution = contrib;
    (contrib as any)._fromCapture = true;
    if (
      this.prevSaved &&
      this.prevSaved.person &&
      this.prevSaved.person.position &&
      contrib.person &&
      !contrib.person.position
    ) {
      contrib.person.position = this.prevSaved.person.position;
    }
    this.onPrevTitle(contrib); // same as clicking
    // independently of algo or not, if we don't have a title, ditch it
    if (
      contrib.person &&
      contrib.person.position &&
      [
        ContributionEventPositions.PARTICIPANT,
        ContributionEventPositions.CHAIRPERSON,
        ContributionEventPositions.ORGANIZER,
      ].indexOf(contrib.person.position) >= 0
    ) {
      if (contrib.title) {
        (contrib as any).title_removed = contrib.title;
      }
      delete contrib.title;
    }
    this.isFromCapture = true;
    this.getParent();
    this.contributionFormOpened = true;
  }
  onPrevTitle(contrib: Contribution, force: boolean = false) {
    if (
      this.prevSaved &&
      (this.prevSaved.title || '').trim().length > 0 &&
      (force || (contrib.title || '').trim().length < 1)
    ) {
      contrib.title = this.prevSaved.title;
    }
  }
  onPrevWp(contrib: Contribution, force: boolean = false) {
    if (
      this.prevSaved &&
      ((this.prevSaved.person || {}).workplace || '').trim().length > 0 &&
      (force || ((contrib.person || {}).workplace || '').trim().length < 1)
    ) {
      if (!contrib.person) {
        contrib.person = {};
      }
      contrib.person.workplace = this.prevSaved.person.workplace;
    }
  }

  onCreateContributionClick() {
    this.initializedAt = new Date();
    this.contribution = new Contribution();
    this.isFromCapture = false;
    this.getParent();
    // when creating a new contribution outsite a session, we set the contributionDate to the event startDate
    if (this.event.startDate && !this.event.sessionDate) {
      this.contribution.person.contributionDate = new Date(
        Date.UTC(this.event.startDate.year, this.event.startDate.month - 1, this.event.startDate.day)
      );
    } else {
      if (this.event.sessionDate) {
        this.contribution.person.contributionDate = new Date(this.event.sessionDate);
      }
      if (this.event.sessionTime) {
        this.contribution.person.contributionTime = {
          hour: this.event.sessionTime.hour,
          minute: this.event.sessionTime.minute,
        };
      }
    }
    this.contributionFormOpened = true;
  }

  onCancelClick() {
    this.contributionFormOpened = false;
    this.contribution = new Contribution();
  }

  onCancelEditClick() {
    this.contributionEditId = null;
    this.doLoad();
  }

  onShowAllText(id: string) {
    if (this.expandedIds.includes(id)) {
      this.expandedIds = this.expandedIds.filter((eId) => eId !== id);
    } else {
      this.expandedIds.push(id);
    }
  }

  onContributionReviewedChange(contribution: Contribution, $event: any) {
    $event.stopPropagation();
    contribution.reviewed = !!$event.target.checked;
    this.onEdit(contribution);
  }

  onContributionVerifiedChange(contribution: Contribution, $event: any) {
    $event.stopPropagation();
    contribution.verified = !!$event.target.checked;
    this.onEdit(contribution, true, false);
    this.verifiedCountChanged.emit(contribution.verified ? 1 : -1);
  }

  canEditContribution() {
    return this.svcAcl.hasCredential('contribution.update');
  }

  toggleAllReviewed($event: any) {
    if (this.showDuplicates) {
      return;
    }

    $event.stopPropagation();
    const newReviewed = !!$event.target.checked;
    this.isLoading = true;
    this.bulkUpdate({
      reviewed: newReviewed,
    });
  }

  toggleAllVerified($event: any) {
    if (this.showDuplicates) {
      return;
    }

    $event.stopPropagation();
    const newVerified = !!$event.target.checked;
    const changedCount = this.contributions.filter((ctr) => !!ctr.verified !== newVerified).length;
    this.isLoading = true;
    this.bulkUpdate({
      verified: newVerified,
    }).then(() => {
      this.onVerifiedUpdate.emit();
    });
    this.verifiedCountChanged.emit(changedCount * (newVerified ? 1 : -1));
  }

  bulkUpdate(values: { [key: string]: any }): Promise<void> {
    this.isLoading = true;
    return this.service
      .bulkUpdate({
        ids: this.contributions.map((mb) => mb.id),
        values,
      })
      .toPromise()
      .then((resp) => {
        this.getContributions();
        return;
      });
  }

  private setAllReviewedFlag() {
    this.allReviewed = this.contributions.every((cn) => cn.reviewed === true);
  }

  private setAllVerifiedFlag() {
    this.allVerified = this.contributions.every((cn) => cn.verified === true);
  }

  hasQc(contrib: Contribution): boolean {
    return Object.keys(contrib.qc || {}).filter((key) => !['needsVerify', 'verified'].includes(key)).length > 0;
  }
  isNeeded(contrib: Contribution): boolean {
    return !!contrib.qc?.needsVerify;
  }

  onContributionDelete(contribution: Contribution, $event: any) {
    $event.stopPropagation();
    contribution.markForDelete = $event.target.checked;
  }

  bulkDelete(): Promise<void> {
    if (this.contributions.some((mb) => mb.markForDelete)) {
      if (!window.confirm('Do you want to remove this entry?')) {
        return;
      }
      this.isLoading = true;
      return this.service
        .bulkDelete((this.contributions.filter((mb) => mb.markForDelete) || []).map((mb) => mb.id))
        .toPromise()
        .then((resp) => {
          this.getCount();
          this.getContributions();
        });
    }
  }

  onContributionMoveSelect(contribution: Contribution, $event: any) {
    $event.stopPropagation();
    contribution.markForMove = $event.target.checked;
    this.contributionsToBeDragged = (this.contributions.filter((mb) => mb.markForMove) || []).length;
    this.allContributionOnMove = this.contributions.every((cn) => cn.markForMove === true);
  }

  toggleAllMoveContribution($event: any) {
    $event.stopPropagation();
    this.contributions.forEach((cn) => (cn.markForMove = !this.allContributionOnMove));
    this.allContributionOnMove = !this.allContributionOnMove;
    this.contributionsToBeDragged = this.allContributionOnMove ? this.contributions.length : 0;
  }

  onContributionMoveAction() {
    let parentID = this.event.parent ? this.event.parent._id : this.event.id;
    const contributionToBeMoved = this.contributions.filter((mb) => mb.markForMove) || [];
    this.svcMoveContributionModal
      .open(
        contributionToBeMoved,
        parentID,
        this.event.parent ? false : true,
        this.event.id,
        this.allContributionOnMove
      )
      .then((result) => {
        this.getCount();
        this.getContributions();
        this.allContributionOnMove = false;
        this.sessionUpdate.emit();
      })
      .catch(() => {});
  }

  onCopyTitle(title: string): void {
    navigator.clipboard.writeText(title);
  }

  onToggleShowDuplicate(): void {
    this.showDuplicates = !this.showDuplicates;
    this.doLoad();
  }

  async hasDuplicates(): Promise<boolean> {
    const res = await firstValueFrom(this.service.getDuplicatesCount(this.event.id));
    return res.count > 0;
  }

  onChangeOnlyNeedsVerify(newVal: boolean) {
    this.onlyNeedsVerify = newVal;
    this.doLoad();
  }
}
