<div class="form-group row">
  <label class="col-form-label col-sm-4 col-md-2">Phone</label>
  <div class="col-sm-8 col-md-10">
    <div class="form-group row" style="margin-bottom: 0">
      <div class="col-md-3">
        <label class="sr-only" for="phone-calling-code">Country Calling Code</label>
        <select
          #callingCodeInput="ngModel"
          id="phone-calling-code"
          class="form-control custom-select"
          name="phone-calling-code"
          [(ngModel)]="model.countryCallingCode"
          (ngModelChange)="onCountryCallingCodeChange()"
          [disabled]="disabled"
        >
          <option [ngValue]="undefined">&mdash; Country Calling Code &mdash;</option>
          <option *ngFor="let callingCode of countryCallingCodes" [value]="callingCode.code">
            {{ callingCode.title }}
          </option>
        </select>
      </div>

      <div class="col-md-6">
        <label class="sr-only" for="phone-number">Phone Number</label>
        <input
          #numberInput="ngModel"
          type="text"
          id="phone-number"
          class="form-control"
          name="phone-number"
          [(ngModel)]="model.number"
          (ngModelChange)="onPhoneNumberChange()"
          placeholder="Phone Number"
          [readonly]="disabled"
          [required]="required"
        />
      </div>

      <div class="col-md-3">
        <label class="sr-only" for="extension">Extension</label>
        <input
          *ngIf="extensionInput"
          #extensionInputRef="ngModel"
          type="text"
          id="extension"
          class="form-control"
          name="extension"
          [(ngModel)]="model.extension"
          (ngModelChange)="onPhoneExtensionChange()"
          placeholder="Extension"
          [readonly]="disabled"
        />
      </div>
    </div>
  </div>

  <div class="col-sm-8 col-md-10 offset-sm-4 offset-md-2 error" *ngIf="hasPhoneNumberError">
    Invalid Phone number. Phone number must only contain digits (no spaces, dashes, etc) and be 12 characters at most
  </div>

  <div class="col-sm-8 col-md-10 offset-sm-4 offset-md-2 error" *ngIf="hasPhoneNumberExtensionError">
    Invalid Phone number extension. Phone number extension must only contain digits (no spaces, dashes, etc) and be 10
    characters at most
  </div>

  <div class="col-sm-8 col-md-10 offset-sm-4 offset-md-2 error" *ngIf="hasCallingCodeError">
    Invalid Country calling code. Please select a country calling coding matching account address
  </div>
</div>
