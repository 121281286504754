import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { APIService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class TwitterAPI extends APIService {
  public findProfileByHandle(handle: string): Observable<any> {
    return of({ profile_image_url: '' }); // Disable since Twitter quota is shared per user now and the Twitter loader eats the whole cake

    return this.http.get(this.getBaseUrl() + `twitter/profile/${handle}`); // bypass APIService interceptor
  }
}
