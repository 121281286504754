<div class="di-select-step">
  <div class="di-table-wrapper">
    <div class="di-search" *ngIf="search$ | async">
      <span class="di-label">Search:</span>
      <span class="di-value">{{ search$ | async }}</span>
    </div>
    <dirt-filters-bar *ngIf="filterBarConfig" [filter]="filter$ | async" [config]="filterBarConfig" [readonly]="true">
    </dirt-filters-bar>
    <table class="table table-striped table-borderless" *ngIf="!(loading$ | async)">
      <thead>
        <tr>
          <th scope="col">{{ (mode$ | async) === Mode.manual ? 'Id' : 'Name' }}</th>
          <th scope="col" class="di-remove-action"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let entity of entities$ | async" [ngClass]="{ 'di-invalid': entity.invalid }">
          <td>{{ (mode$ | async) === Mode.manual ? entity.id : titleFormatter(entity) }}</td>
          <td>
            <span class="action-btn action-delete" (click)="onDelete(entity.id)" *diAcl="'person.delete'">
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </span>
          </td>
        </tr>
        <tr *ngIf="(mode$ | async) === Mode.manual">
          <input
            type="email"
            [(ngModel)]="enteredId"
            (keydown.enter)="onEnterEntityId()"
            class="form-control di-id-input"
            placeholder="Copy & Paste IDs and press Enter"
          />
        </tr>
      </tbody>
    </table>
  </div>
  <div class="di-records">
    <h5 class="di-error-records-label text-success" *ngIf="validCount$ | async">
      {{ validCount$ | async }} valid records
    </h5>
    <h5 class="di-error-records-label text-danger" *ngIf="invalidCount$ | async">
      {{ invalidCount$ | async }} invalid records
    </h5>
    <h5 class="di-selected-records-label" *ngIf="!(loading$ | async)">{{ count$ | async }} records selected</h5>
  </div>
  <dirt-spinner [show]="(loading$ | async) || (validating$ | async)" bgColor="transparent"></dirt-spinner>
</div>
