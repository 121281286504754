<div class="modal-header">
  <h5 class="modal-title">Add Person</h5>
</div>

<div class="modal-body">
  <input
    *ngIf="!showPersonForm"
    type="search"
    id="affiliations-searchbar"
    class="di-search form-control ng-trim-ignore"
    [ngClass]="{ loading: isSearching }"
    [ngbTypeahead]="onSearchPerson"
    [focusFirst]="false"
    [resultTemplate]="searchResultTemplate"
    placeholder="Search..."
    [(ngModel)]="searchTerm"
    (selectItem)="onSelect($event)"
  />

  <a href="javascript:void(0)" class="small pull-right pt-1 pr-2" (click)="togglePersonCreationForm()">
    <span *ngIf="!showPersonForm">Create Person</span> <span *ngIf="showPersonForm">Search Profile</span>
  </a>

  <br />

  <ng-container *ngIf="selectedPerson">
    <h6>Selected Person:</h6>

    <div class="row">
      <div class="col-md-6 di-twitter-avatar-wrapper">
        <dirt-avatar
          [readonly]="true"
          [local_url]="
            selectedPerson?.image?.local_url_nocache || selectedPerson?.image?.local_url || 'assets/blank-profile.png'
          "
        >
        </dirt-avatar>
      </div>
      <div class="col-md-6 di-info-wrapper">
        <!-- Header -->
        <div class="row di-info-header">
          <div class="col-auto">
            <h4 class="d-inline-block">
              <a
                class="di-header-name dirt-link-pointer"
                (click)="onPersonNameClick(selectedPerson.id)"
                rel="noopener"
                target="_blank"
              >
                <span title="First name" *ngIf="selectedPerson.firstName">{{ selectedPerson.firstName }}</span>
                <span title="Middle name" *ngIf="selectedPerson.middleName">{{ selectedPerson.middleName }}</span>
                <span title="Last name" *ngIf="selectedPerson.lastName">{{ selectedPerson.lastName }}</span>
              </a>
            </h4>
            <!-- FullName-->
            <div class="d-inline-block ml-2 mb-2 text-muted">{{ selectedPerson.originalFullName }}</div>
            <div>{{ selectedPerson.affiliation?.department || selectedPerson.affiliation?.name }}</div>
            <!-- Specialty-->
            <div class="col-md-3 di-header-specialty">
              <div>
                <span
                  class="badge badge-outline-info mr-1"
                  *ngFor="let sp of selectedPerson.specialties"
                  ngbTooltip="Specialty"
                  [placement]="'bottom'"
                >
                  {{ getValueTitle(specialtyList, sp) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <span class="di-label text-left">CV Links:</span>
        <span *ngFor="let cvLink of selectedPerson.cvLinks; last as isLast">
          <a [href]="cvLink" rel="noopener" target="_blank">{{ cvLink | domain }}</a>
          <span [hidden]="isLast">|</span>
        </span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showPersonForm">
    <dirt-person-form-inline
      [model]="newPerson"
      [disabledFields]="disabledFields"
      [displayCountry]="'MANDATORY'"
      (validityChange)="onFormValidityChange($event)"
    ></dirt-person-form-inline>
  </ng-container>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-outline-dark" [disabled]="isSearching" (click)="activeModal.dismiss()">Close</button>
  <button
    class="btn btn-sm btn-success"
    [ngClass]="{ 'btn-loading': isSearching }"
    [hidden]="!selectedPerson && !(newPerson && showPersonForm)"
    [disabled]="isSearching || (showPersonForm && !isFormValid)"
    (click)="onAdd()"
  >
    Add
  </button>
</div>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div class="di-sac-kol-id pull-right">{{ r.kolId }}</div>
  <div class="di-sac-profile pull-left">
    <span (click)="onResClick($event, r.id)"
      >{{ r.firstName }} {{ r.middleName }} {{ r.lastName }} {{ r.originalFullName }}</span
    >
  </div>
</ng-template>
