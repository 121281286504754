import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { ValueAPI } from '../../services/value/value-api.service';
import { ValueType } from '../../enum/value-type.enum';

@Component({
  selector: 'dirt-value-select-typeahead',
  templateUrl: 'value-select-typeahead.html',
  styleUrls: ['value-select-typeahead.scss'],
})
export class ValueSelectTypeahead {
  @Input()
  type: ValueType;
  @Input()
  required: boolean = false;
  @Input()
  wide: boolean = false;

  @Input()
  code: string;
  @Output() // can bind [(code)]
  codeChange: EventEmitter<string> = new EventEmitter();

  @ViewChild(NgbTypeahead, { static: false })
  searchAutocomplete: NgbTypeahead;

  searchTerm: string;
  isSearching = false;

  searchValues = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) =>
        this.svcValue.search(this.type, term as string).pipe(
          // tap(() => this.searchFailed = false),
          catchError(() => {
            // this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => (this.isSearching = false))
    );

  constructor(private readonly svcValue: ValueAPI) {}

  onSelect(event: any): void {
    event.preventDefault();

    this.codeChange.emit(event.item.code);

    // clean up
    this.searchTerm = '';
    this.searchAutocomplete.dismissPopup();
  }
}
