import { Routes, RouterModule } from '@angular/router';

import { AclGuard } from '../shared/acl/guard.service';
import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { CommitteeComponent } from './committee.component';
import { CommitteeCreateComponent } from './create/create.component';
import { CommitteeDetailComponent } from './detail/detail.component';
import { CommitteeListComponent } from './list/list.component';
import { CommitteeModule } from './committee.module';
import { ModuleWithProviders } from '@angular/core';
import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';

const committeeRoutes: Routes = [
  {
    path: 'committee',
    component: CommitteeComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'create',
        component: CommitteeCreateComponent,
      },
      {
        path: 'detail/:id',
        component: CommitteeDetailComponent,
      },
      {
        path: 'list',
        component: CommitteeListComponent,
        data: { acl: 'committee.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
    data: { title: 'Committee' },
  },
];

export const committeeRouting: ModuleWithProviders<CommitteeModule> = RouterModule.forChild(committeeRoutes);
