export class Sponsor {
  _id: string;
  originalName: string;
  normalizedName: string;
  companyId: string;
  createdAt: string;
  updatedAt: string;
  disabled: boolean;
}

export interface UpdateNormalizedNamesData {
  oldName: string;
  newName: string;
}
