import { Component, OnChanges, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dirt-merge-problems',
  templateUrl: './merge-info.component.html',
  styleUrls: ['./merge-info.component.scss'],
})
export class AffiliationMergeInfoComponent implements OnChanges {
  options: {
    success?: boolean;
    msg?: string;
    pairs?: { existId: string; existCaption: string; mergeId: string; mergeCaption: string; merged: boolean }[];
  };

  private _hasConflicts = null;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnChanges(changes: SimpleChanges) {
    this._hasConflicts = null;
  }

  hasConflicts(): boolean {
    if (null === this._hasConflicts) {
      this._hasConflicts = (this.options.pairs || []).filter((item) => this.hasConflict(item)).length > 0;
    }
    return this._hasConflicts;
  }
  hasConflict(item: { merged: boolean }): boolean {
    return !item.merged;
  }
}
