import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GET,
  POST,
  DELETE,
  Path,
  Body,
  Query,
  MediaType,
  Produces,
  BaseUrl,
  PATCH,
} from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { Event } from './event';
import { environment } from '../../../environments/environment';
import { User } from '../../user/shared/user';
import { catchError, map } from 'rxjs/operators';
import { EventAdvancedSearchDto } from './event-advanced-search-dto';

@Injectable()
export class EventAPI extends APIService {
  /**
   * Find all instances of the model matched by filter from the data source
   *
   * @method find
   * @param {String} parentId
   * @param {String} name
   * @param {Number} limit
   * @param {Number} skip
   * @param {String} sort
   * @param {String} filter
   * @returns {Observable} observable of array of models
   */
  @GET('events/')
  @Produces(MediaType.JSON)
  public find(
    @Query('name') name?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any,
    @Query('parentId') parentId?: string
  ): Observable<Event[]> {
    return;
  }

  /**
   * Find an instance of the model matched by filter
   *
   * @method findById
   * @param {String} id
   * @returns {Observable} observable model
   */
  @GET('events/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Event> {
    return;
  }

  @POST('events/exist')
  @Produces(MediaType.JSON)
  public exist(@Body ids: string[]): Observable<{ invalid: boolean }[]> {
    return;
  }

  @GET('events/count')
  @Produces(MediaType.JSON)
  public count(
    @Query('parentId') parentId?: string,
    @Query('name') name?: string,
    @Query('filter') filter?: string
  ): Observable<any> {
    return null;
  }

  @POST('events')
  @Produces(MediaType.JSON)
  public create(@Body event: Event): Observable<any> {
    return;
  }

  @POST('events/bulk-update')
  @Produces(MediaType.JSON)
  public bulkUpdate(@Body data: { ids: string[]; values: { [key: string]: any } }): Observable<{ bulkTrackingId }> {
    return;
  }

  @PATCH('events/{id}')
  @Produces(MediaType.JSON)
  public update(
    @Path('id') id: string,
    @Body event: Partial<Event>,
    @Query('submitJob') submitJob?: boolean
  ): Observable<any> {
    return;
  }

  @DELETE('events/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }

  @GET('events/next')
  @Produces(MediaType.JSON)
  public findNext(): Observable<Event> {
    return;
  }

  @GET('events/next-hashtag')
  @Produces(MediaType.JSON)
  public findNextHashtag(): Observable<Event> {
    return;
  }

  @GET('events/distinct')
  @Produces(MediaType.JSON)
  public distinct(@Query('field') field: string): Observable<string[]> {
    return;
  }

  @GET('events/statistic')
  @Produces(MediaType.JSON)
  public statistic(): Observable<any> {
    return;
  }

  @GET('events/pipeline')
  @Produces(MediaType.JSON)
  public pipeline(@Query('from') from: string, @Query('to') to: string): Observable<any> {
    return;
  }

  /**
   * Find models which are part of the given user's workflow
   *
   * @method queue
   * @param {String} userId
   * @returns {Observable}
   */
  @GET('events/queue')
  @Produces(MediaType.JSON)
  public queue(@Query('userId') userId?: string): Observable<any> {
    return;
  }

  /**
   * Find all the audit logs of a given model id
   * @param id
   */
  @GET('events/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  /**
   * Find count of audit logs
   * @param id
   */
  @GET('events/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }

  /**
   * Find all the distinct values of the `_meta.assignee` field
   */
  @GET('events/reviewers')
  @Produces(MediaType.JSON)
  public reviewers(): Observable<User[]> {
    return;
  }

  /**
   * Find all the distinct values of the `lastHashtagCheck.by` field
   */
  @GET('events/hashtag-compilers')
  @Produces(MediaType.JSON)
  public hashtagCompilers(): Observable<User[]> {
    return;
  }

  public startBulkListUpload(file: File, secondEyes: string): Observable<string> {
    return this.doBulkUpload(file, secondEyes, 'events/bulk-list');
  }

  public startBulkSearchUpload(file: File, secondEyes: string): Observable<string> {
    return this.doBulkUpload(file, secondEyes, 'events/bulk-search');
  }

  /**
   * Upload the file and get a bulk upload ID
   *
   * @param file
   * @param secondEyes
   */
  private doBulkUpload(file: File, secondEyes: string, endpoint: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);

    return this.http
      .post(this.getBaseUrl() + endpoint, formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj: any) => obj.bulkTrackingId));
  }

  @GET('event/id-qc-queue')
  @Produces(MediaType.JSON)
  public idQcQueue(): Observable<any> {
    return;
  }

  @GET('event/id-qc-next')
  @Produces(MediaType.JSON)
  public findIDQCNext(@Query('exceedCap') exceedCap?: boolean): Observable<string | undefined> {
    return;
  }

  @GET('events/creators')
  @Produces(MediaType.JSON)
  public creators(): Observable<User[]> {
    return;
  }

  @GET('events/qc-queue')
  @Produces(MediaType.JSON)
  public qcQueue(): Observable<any> {
    return;
  }

  @GET('events/qc-next')
  @Produces(MediaType.JSON)
  public findQcNext(@Query('exceedCap') exceedCap?: boolean): Observable<string | undefined> {
    return;
  }

  @GET('events/qc-pool-stats')
  @Produces(MediaType.JSON)
  public qcPoolStats(): Observable<User[]> {
    return;
  }

  @POST('events/advanced-search')
  @Produces(MediaType.JSON)
  public advancedSearch(@Body searchDto: EventAdvancedSearchDto): Observable<{ items: Event[]; total: number }> {
    return;
  }
}
