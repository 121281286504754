import { catchError, map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { APIService } from '../../shared/services/api/api.service';
import { Document } from './document';
import {
  GET,
  MediaType,
  Produces,
  Query,
  Path,
  POST,
  Body,
  PATCH,
  DELETE,
} from '../../shared/services/rest/rest.service';

@Injectable()
export class DocumentAPI extends APIService {
  @GET('documents/')
  @Produces(MediaType.JSON)
  public find(
    @Query('name') name?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any,
    @Query('searchType') searchType?: string
  ): Observable<Document[]> {
    return;
  }

  @GET('documents/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Document> {
    return;
  }

  @GET('documents/count')
  @Produces(MediaType.JSON)
  public count(
    @Query('name') name?: string,
    @Query('filter') filter?: any,
    @Query('searchType') searchType?: string
  ): Observable<any> {
    return;
  }

  @POST('documents')
  @Produces(MediaType.JSON)
  public create(@Body document: Document, @Query('confirmed') confirmed?: boolean): Observable<any> {
    return;
  }

  @PATCH('documents/{id}')
  @Produces(MediaType.JSON)
  public update(
    @Path('id') id: string,
    @Body document: Partial<Document>,
    @Query('confirmed') confirmed?: boolean
  ): Observable<any> {
    return;
  }

  @DELETE('documents/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }

  @GET('documents/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  @GET('documents/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }

  @POST('documents/submit-job')
  @Produces(MediaType.JSON)
  public submitJob(@Body document: Document, @Query('confirmed') confirmed?: boolean): Observable<Document> {
    return;
  }

  @GET('documents/format')
  @Produces(MediaType.JSON)
  public getDocumentFormat(@Query('url') url: string): Observable<{ format: string | null }> {
    return;
  }

  /**
   * Upload the file and get a bulk upload ID
   *
   * @param file
   * @param secondEyes
   */
  public startBulkListUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'documents/bulk-list', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  /**
   * Get the file as binary
   */
  public downloadDocumentsFile(filter?: any, name?: string, searchType?: string): Observable<any> {
    let filterUrl = filter ? 'filter=' + encodeURIComponent(JSON.stringify(filter)) : null;
    if (name) {
      filterUrl = (filterUrl ? filterUrl + '&' : '') + 'name=' + encodeURIComponent(name);
    }
    if (searchType) {
      filterUrl = (filterUrl ? filterUrl + '&' : '') + 'searchType=' + encodeURIComponent(searchType);
    }
    return this.http
      .get(this.getBaseUrl() + 'documents/export' + (filterUrl ? '?' + filterUrl : ''), { responseType: 'blob' })
      .pipe(
        catchError((err) => {
          console.log(err);
          return this.handleError(err);
        })
      );
  }
}
