import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';

import { EventAPI } from '../api.service';
import { EventStatus } from '../enum/status.enum';
import { UserGroupAPI } from '../../../user-group/shared/api.service';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { Value } from '../../../shared/services/value/value';
import { Utils } from '../../../common/utils';
import { User } from '../../../user/shared/user';
import { UserAPI } from '../../../user/shared/api.service';
import { Roles } from '../../../shared/acl/roles';

@Component({
  selector: 'dirt-event-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class EventFilterComponent implements OnInit, OnDestroy {
  visiblePanel: any;
  visibleSubPanel: any;
  filter: any;

  @Output() onFilter = new EventEmitter<any>();

  @ViewChild('filterPopover', { static: true })
  filterPopover: NgbPopover;

  countryValues: Value[] = [];

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private svcEvent: EventAPI,
    private svcUserGroup: UserGroupAPI,
    private svcValue: ValueAPI,
    private svcUser: UserAPI
  ) {}

  ngOnInit() {
    this.svcValue.find(ValueType.Project, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.projectsList = data;
    });

    this.svcValue.find(ValueType.Area, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.areaList = data;
    });

    this.svcValue.find(ValueType.Product, Number.MAX_SAFE_INTEGER, 0).subscribe((data) => {
      this.productList = data;
    });

    this.svcValue.find(ValueType.TherapeuticArea, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.therapeuticAreaList = data;
    });

    this.svcValue.find(ValueType.Category, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.categoryList = data;
    });

    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.countryValues = data;
    });

    this.svcValue.find(ValueType.EventType, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.typeList = data;
    });

    this.initHashtagCompilers();
    this.initEventCreators();
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  getCountryTitle(code: string): string {
    return (this.countryValues.find((o) => o.code === code) || { title: '' }).title;
  }

  onShow(): void {
    // reset panel visibility status
    this.visiblePanel = false;
  }

  setPanel(panel?: string, hasSubPanel?: boolean): void {
    this.visiblePanel = panel;
    this.visibleSubPanel = '';

    if (panel) {
      // uppercase first letter
      const name = panel.charAt(0).toUpperCase() + panel.slice(1).toLowerCase();
      const initMethod = `init${name}`;
      if (this[initMethod]) {
        this[initMethod]();
      }
    }
  }

  doApply(): void {
    this.filter = {
      type: Array.from(this.selectedTypes),
      reach: Array.from(this.selectedLocalities),
      category: Array.from(this.selectedCategories),
      therapeuticAreas: Array.from(this.selectedTherapeuticAreas),
      areas: Array.from(this.selectedAreas),
      products: Array.from(this.selectedProducts),
      scope: Array.from(this.selectedScopes),
      '_meta.status': [...Array.from(this.selectedStatuses), ...Array.from(this.selectedMaintainerStatus)],
      '_meta.maintenance': this.selectedMaintenance ? [true] : [],
      '_meta.assignee': [...Array.from(this.selectedCompilers), ...Array.from(this.selectedMaintainers)],
      'identify.by': Array.from(this.selectedCreators),
      'address.countryCode': this.getSelectedLocations(),
      projectNames: Array.from(this.selectedProjects),
      verified: this.selectedVerified,
      idVerified: Object.keys(this.selectedIdVerified).length ? JSON.stringify(this.selectedIdVerified) : undefined,
      startDate: Object.keys(this.selectedStartDate).length ? this.selectedStartDate : undefined,
      createdAt: Object.keys(this.selectedCreateDate).length ? this.selectedCreateDate : undefined,
      'comment.lastUpdated': Object.keys(this.selectedLatestCommentDate).length
        ? this.selectedLatestCommentDate
        : undefined,
      'lastHashtagCheck.by': [...Array.from(this.selectedHashtagCompilers)],
      'lastHashtagCheck.at': Object.keys(this.selectedHashtagCompiledDate).length
        ? this.selectedHashtagCompiledDate
        : undefined,
      hashtagCompiled: this.selectedHashtagCompilationStatus,
    };
    this.filterPopover.close();
    this.onFilter.emit(this.filter);
  }

  doClear(): void {
    this.selectedTypes.clear();
    this.selectedLocalities.clear();
    this.selectedStatuses.clear();
    this.selectedRegionList.clear();
    this.selectedLocations.clear();
    this.selectedCategories.clear();
    this.selectedAreas.clear();
    this.selectedProducts.clear();
    this.selectedScopes.clear();
    this.selectedTherapeuticAreas.clear();
    this.selectedProjects.clear();
    this.selectedCompilers.clear();
    delete this.selectedMaintenance;
    delete this.selectedVerified;
    this.selectedIdVerified = {};
    this.selectedStartDate = {};
    this.selectedCreateDate = {};
    this.selectedLatestCommentDate = {};
    this.selectedMaintainerStatus.clear();
    this.selectedMaintainers.clear();
    this.selectedCreators.clear();
    this.maintainersSearchCtrl.reset();
    this.selectedHashtagCompilers.clear();
    this.selectedHashtagCompiledDate = {};
    delete this.selectedHashtagCompilationStatus;

    delete this.filter;
    this.onFilter.emit();
    this.filterPopover.close();
  }

  doCancel(): void {
    this.filterPopover.close();
    this.setPanel();
  }

  getSelectedLocations(): Array<string> {
    const locations: Set<string> = new Set(this.selectedLocations);
    this.selectedRegionList.forEach((region) => {
      this.regionLocationMap.get(region).forEach((l) => locations.add(l));
    });
    return Array.from(locations);
  }

  // TYPE
  typeList: Value[] = [];
  private selectedTypes: Set<string> = new Set();

  toggleType(type: string): void {
    if (this.selectedTypes.has(type)) {
      this.selectedTypes.delete(type);
    } else {
      this.selectedTypes.add(type);
    }
  }

  isTypeSelected(type: string): boolean {
    return this.selectedTypes.has(type);
  }

  // CATEGORY
  categoryList: Value[] = [];
  private selectedCategories: Set<string> = new Set();

  toggleCategory(category: string): void {
    if (this.selectedCategories.has(category)) {
      this.selectedCategories.delete(category);
    } else {
      this.selectedCategories.add(category);
    }
  }

  isCategorySelected(category: string): boolean {
    return this.selectedCategories.has(category);
  }

  // THERAPEUTIC AREA
  therapeuticAreaList: Value[] = [];
  private selectedTherapeuticAreas: Set<string> = new Set();

  toggleTherapeuticAreas(status: string): void {
    if (this.selectedTherapeuticAreas.has(status)) {
      this.selectedTherapeuticAreas.delete(status);
    } else {
      this.selectedTherapeuticAreas.add(status);
    }
  }

  isTherapeuticAreasSelected(status: string): boolean {
    return this.selectedTherapeuticAreas.has(status);
  }

  // AREA
  areaList: Value[] = [];
  private selectedAreas: Set<string> = new Set();

  toggleAreas(status: string): void {
    if (this.selectedAreas.has(status)) {
      this.selectedAreas.delete(status);
    } else {
      this.selectedAreas.add(status);
    }
  }

  isAreasSelected(status: string): boolean {
    return this.selectedAreas.has(status);
  }

  // PRODUCT
  productList: Value[] = [];
  selectedProducts: Set<any> = new Set();

  toggleProducts(status: string): void {
    if (this.selectedProducts.has(status)) {
      this.selectedProducts.delete(status);
    } else {
      this.selectedProducts.add(status);
    }
  }

  isProductSelected(status: string): boolean {
    return this.selectedProducts.has(status);
  }

  // LOCALITY
  localityList: string[] = ['NATIONAL', 'INTERNATIONAL', 'REGIONAL', 'LOCAL'];
  private selectedLocalities: Set<string> = new Set();

  toggleLocality(locality: string): void {
    if (this.selectedLocalities.has(locality)) {
      this.selectedLocalities.delete(locality);
    } else {
      this.selectedLocalities.add(locality);
    }
  }

  isLocalitySelected(locality: string): boolean {
    return this.selectedLocalities.has(locality);
  }

  // STATUS
  statusList = EventStatus;
  private selectedStatuses: Set<string> = new Set();

  toggleStatus(status: string): void {
    if (this.selectedStatuses.has(status)) {
      this.selectedStatuses.delete(status);
    } else {
      this.selectedStatuses.add(status);
    }
  }

  isStatusSelected(status: string): boolean {
    return this.selectedStatuses.has(status);
  }

  // MAINTENANCE
  selectedMaintenance: boolean;

  toggleMaintenance(): void {
    if (this.selectedMaintenance) {
      delete this.selectedMaintenance;
    } else {
      this.selectedMaintenance = true;
    }
  }

  // SCOPE
  scopeList: string[] = ['ONCOLOGY', 'NON_ONCOLOGY'];
  private selectedScopes: Set<any> = new Set();

  toggleScope(scope: string): void {
    const val = scope === 'ONCOLOGY' ? 'ONCOLOGY' : null;
    if (this.selectedScopes.has(val)) {
      this.selectedScopes.delete(val);
    } else {
      this.selectedScopes.add(val);
    }
  }

  isScopeSelected(scope: string): boolean {
    const val = scope === 'ONCOLOGY' ? 'ONCOLOGY' : null;
    return this.selectedScopes.has(val);
  }

  // LOCATION
  locationList: string[];
  isLoadingLocations = false;
  private selectedLocations: Set<string> = new Set();

  initLocation(): void {
    if (this.locationList) {
      return;
    }

    this.isLoadingLocations = true;
    Promise.all([
      this.svcEvent.distinct('address.countryCode').toPromise(),
      this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').toPromise(),
    ])
      .then(([countryCodes, countries]) => {
        this.locationList = Utils.sortByCountry(
          countryCodes,
          countries.reduce((prev, curr) => ({ ...prev, [curr.code]: curr.title }), {})
        );
        this.isLoadingLocations = false;
      })
      .catch(() => {
        this.isLoadingLocations = false;
      });
  }

  toggleLocation(loc: string): void {
    if (this.selectedLocations.has(loc)) {
      this.selectedLocations.delete(loc);
    } else {
      this.selectedLocations.add(loc);
    }
  }

  isLocationSelected(loc: string): boolean {
    return this.selectedLocations.has(loc);
  }

  // REGION
  regionList: string[];
  regionLocationMap: Map<string, string[]> = new Map();
  isLoadingRegions = false;
  private selectedRegionList: Set<string> = new Set();

  initRegion(): void {
    if (this.regionList) {
      return;
    }

    this.isLoadingRegions = true;

    this.svcUserGroup.find(Number.MAX_SAFE_INTEGER).subscribe(
      (resp) => {
        this.regionList = resp.map((u) => u.name).sort();
        resp.forEach((u) => this.regionLocationMap.set(u.name, u.countries));
      },
      null,
      () => (this.isLoadingRegions = false)
    );
  }

  toggleRegion(region: string): void {
    if (this.selectedRegionList.has(region)) {
      this.selectedRegionList.delete(region);
    } else {
      this.selectedRegionList.add(region);
    }
  }

  isRegionSelected(region: string): boolean {
    return this.selectedRegionList.has(region);
  }

  // PROJECTS
  projectsList: Value[] = [];
  private selectedProjects: Set<string> = new Set();

  toggleProjects(status: string): void {
    if (this.selectedProjects.has(status)) {
      this.selectedProjects.delete(status);
    } else {
      this.selectedProjects.add(status);
    }
  }

  isProjectSelected(value: string): boolean {
    return this.selectedProjects.has(value);
  }

  // VERIFIED
  private selectedVerified: boolean;

  toggleVerified(verified: boolean): void {
    if (typeof this.selectedVerified === 'undefined') {
      this.selectedVerified = verified;
    } else if (this.selectedVerified !== verified) {
      this.selectedVerified = verified;
    }
  }

  // ID VERIFIED
  idVerifiedOptions = [
    { key: 'ID Verified', value: true },
    { key: 'ID UN-Verified', value: false },
  ];
  idVerifiedSubOptions = [
    { key: 'Correct', value: true },
    { key: 'Incorrect', value: false },
  ];

  private selectedIdVerified: { idVerified?: boolean; correct?: boolean } = {};

  setSubPanel(subPanel?: string): void {
    this.visibleSubPanel = subPanel;
  }

  isIdVerifiedValueSelected(idVerified: boolean): boolean {
    return this.selectedIdVerified?.idVerified === idVerified;
  }

  isIdVerifiedSubValueSelected(subValue: boolean): boolean {
    return this.selectedIdVerified?.correct === subValue;
  }

  toggleIdVerifiedValue(idVerified: boolean, key: string) {
    if (this.selectedIdVerified.idVerified === idVerified) {
      this.selectedIdVerified = {};
      return;
    }

    this.selectedIdVerified = {};
    this.selectedIdVerified.idVerified = idVerified;

    if (idVerified) {
      this.setSubPanel('idVerified');
    } else {
      delete this.selectedIdVerified.correct;
    }
  }

  toggleIdVerifiedSubValue(correct?: boolean) {
    if (this.selectedIdVerified.correct === correct) {
      delete this.selectedIdVerified.correct;
      return;
    }
    this.selectedIdVerified.correct = correct;
  }

  // START DATE
  selectedStartDate: { start?: Date; end?: Date } = {};

  setStartDate(start: Date, end: Date) {
    if ((start && start.getFullYear() < 1900) || (end && end.getFullYear() < 1900)) {
      return; // incomplete input
    }
    if (start && !end) {
      end = start;
    }

    this.selectedStartDate = { start, end };
  }

  // CREATED DATE
  selectedCreateDate: { start?: Date; end?: Date } = {};

  setCreateDate(start: Date, end: Date) {
    if ((start && start.getFullYear() < 1900) || (end && end.getFullYear() < 1900)) {
      return; // incomplete input
    }
    if (start && !end) {
      end = start;
    }

    this.selectedCreateDate = { start, end };
  }

  // LATEST COMMENTED  DATE
  selectedLatestCommentDate: { start?: Date; end?: Date } = {};

  setLatestCommentDate(start: Date, end: Date) {
    if ((start && start.getFullYear() < 1900) || (end && end.getFullYear() < 1900)) {
      return; // incomplete input
    }
    if (start && !end) {
      end = start;
    }

    this.selectedLatestCommentDate = { start, end };
  }

  isVerifiedSelected(verified: boolean): boolean {
    return this.selectedVerified === verified;
  }

  // REVIEWERS
  compilersList: User[];
  isLoadingCompilers = false;
  private selectedCompilers: Set<string> = new Set();

  initCompilers(): void {
    if (this.compilersList) {
      return;
    }

    this.isLoadingCompilers = true;
    this.svcEvent
      .reviewers()
      .pipe(tap(() => (this.isLoadingCompilers = false)))
      .subscribe((resp) => (this.compilersList = resp));
  }

  toggleReviewedBy(reviewedBy: string, $event: MouseEvent): void {
    $event.preventDefault();

    if (this.selectedCompilers.has(reviewedBy)) {
      this.selectedCompilers.delete(reviewedBy);
    } else {
      this.selectedCompilers.add(reviewedBy);
    }
  }

  isReviewedBySelected(reviewedBy: string): boolean {
    return this.selectedCompilers.has(reviewedBy);
  }

  // CREATED BY
  creatorsList: User[];
  isLoadingCreators = false;
  private selectedCreators: Set<string> = new Set();

  initEventCreators(): void {
    if (this.creatorsList) {
      return;
    }

    this.isLoadingCreators = true;
    this.svcEvent
      .creators()
      .pipe(tap(() => (this.isLoadingCreators = false)))
      .subscribe((resp) => (this.creatorsList = resp));
  }

  toggleCreatedBy(createdBy: string, $event: MouseEvent): void {
    $event.preventDefault();

    if (this.selectedCreators.has(createdBy)) {
      this.selectedCreators.delete(createdBy);
    } else {
      this.selectedCreators.add(createdBy);
    }
  }

  isCreatedBySelected(createdBy: string): boolean {
    return this.selectedCreators.has(createdBy);
  }

  // MAINTAINER
  maintainersList: User[];
  isLoadingMaintainers = false;
  maintainersSearchCtrl: FormControl = new FormControl('');
  private fullMaintainersList: User[];
  private selectedMaintainerStatus: Set<string> = new Set();
  private selectedMaintainers: Set<string> = new Set();

  initMaintainers(): void {
    if (this.maintainersList) {
      return;
    }

    this.isLoadingMaintainers = true;
    this.svcUser
      .find(null, Number.MAX_SAFE_INTEGER, null, null, { roles: [Roles.EventCreator] }, true)
      .pipe(tap(() => (this.isLoadingMaintainers = false)))
      .subscribe((resp) => {
        resp = resp.filter(
          (val) => val.app_metadata?.additionalInfo?.utcMaintainer || val.app_metadata?.additionalInfo?.draftMaintainer
        );

        this.maintainersList = resp;
        this.fullMaintainersList = resp;
      });

    this.maintainersSearchCtrl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe((val) => this.onMaintainersSearch(val));
  }

  toggleMaintainerStatus(status: string): void {
    if (this.selectedMaintainerStatus.has(status)) {
      this.selectedMaintainerStatus.delete(status);
    } else {
      this.selectedMaintainerStatus.add(status);
    }

    this.onMaintainersSearch(this.maintainersSearchCtrl.value);
  }

  isMaintainerStatusSelected(status: string): boolean {
    return this.selectedMaintainerStatus.has(status);
  }

  toggleMaintainer(maintainer: string, $event: MouseEvent): void {
    $event.preventDefault();

    if (this.selectedMaintainers.has(maintainer)) {
      this.selectedMaintainers.delete(maintainer);
    } else {
      this.selectedMaintainers.add(maintainer);
    }
  }

  isMaintainerSelected(maintainer: string): boolean {
    return this.selectedMaintainers.has(maintainer);
  }

  private onMaintainersSearch(maintainer: string): void {
    if (!maintainer) {
      this.maintainersList = this.fullMaintainersList;
      return;
    }

    this.maintainersList = this.fullMaintainersList.filter((val) => {
      return (
        val.user_metadata &&
        (val.user_metadata?.firstName?.toLowerCase()?.includes(maintainer.toLowerCase()) ||
          val.user_metadata?.lastName?.toLowerCase()?.includes(maintainer.toLowerCase()) ||
          val.name?.toLowerCase()?.includes(maintainer.toLowerCase()))
      );
    });
  }

  // HASHTAG COMPILERS
  hashtagCompilersList: User[];
  isLoadingHashtagCompilers = false;
  private selectedHashtagCompilers: Set<string> = new Set();

  initHashtagCompilers(): void {
    if (this.compilersList) {
      return;
    }

    this.isLoadingCompilers = true;
    this.svcEvent
      .hashtagCompilers()
      .pipe(tap(() => (this.isLoadingCompilers = false)))
      .subscribe((resp) => (this.hashtagCompilersList = resp));
  }

  toggleHashtagCompiledBy(compiledBy: string, $event: MouseEvent): void {
    $event.preventDefault();

    if (this.selectedHashtagCompilers.has(compiledBy)) {
      this.selectedHashtagCompilers.delete(compiledBy);
    } else {
      this.selectedHashtagCompilers.add(compiledBy);
    }
  }

  isHashtagCompiledSelected(reviewedBy: string): boolean {
    return this.selectedHashtagCompilers.has(reviewedBy);
  }

  // HASHTAG COMPILED DATE
  selectedHashtagCompiledDate: { start?: Date; end?: Date } = {};

  setSelectedHashtagCompiledDate(start: Date, end: Date) {
    if ((start && start.getFullYear() < 1900) || (end && end.getFullYear() < 1900)) {
      return; // incomplete input
    }
    if (start && !end) {
      end = start;
    }

    this.selectedHashtagCompiledDate = { start, end };
  }

  // HASHTAG COMPILATION
  private selectedHashtagCompilationStatus: string;

  isHashtagCompilationStatusSelected(compilation: string): boolean {
    return this.selectedHashtagCompilationStatus === compilation;
  }

  toggleHashtagCompilationStatus(compilation: string): void {
    if (this.selectedHashtagCompilationStatus === compilation) {
      delete this.selectedHashtagCompilationStatus; // allow unselect
    } else {
      this.selectedHashtagCompilationStatus = compilation;
    }
  }
}
