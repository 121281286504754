<section class="dirt-section-detail dirt-user-detail" *ngIf="sponsor">
  <div class="row">
    <div class="col-md-8">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <h1 class="bounceInRight">Sponsor Detail</h1>

      <br />
      <dirt-sponsor-form [sponsor]="sponsor" #frmSponsor="frmSponsor" [mode]="'Mapping'"></dirt-sponsor-form>

      <div class="pull-right">
        <!-- Save -->
        <button
          type="submit"
          class="btn btn-primary"
          [ngClass]="{ 'btn-loading': isSubmitting }"
          [disabled]="!frmSponsor.isValid() || isSubmitting || !canUpdate()"
          (click)="onSave(frmSponsor.getValue())"
        >
          <!-- The requirments is to save the changes and look for next mapping with empty Normalized name and Company Id -->
          <span>Save and Next</span>
        </button>
      </div>
    </div>
  </div>
</section>
