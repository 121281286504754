import { PersonAPI } from '../api.service';
import { Person } from '../person';
import { FieldType } from '../../../common/bulk-update/field.config';
import { filterBarConfig } from './filter-bar-config';
import { Roles } from '../../../shared/acl/roles';
import { FieldValue } from '../../../common/bulk-update/field-value';
import { BulkUpdateOptions } from '../../../common/bulk-update/bulk-update-options';
import { enumToDataObject, rangeOfNumbers } from '../utils';
import { PersonStatus } from '../constant/status.enum';

export const weekRange = rangeOfNumbers(4, 1);
export const monthRange = rangeOfNumbers(12, 1);
export const priorityRange = rangeOfNumbers(5, 0);
export const personStatusValues = enumToDataObject(PersonStatus);

export function titleFormatter(entity: Person) {
  return (
    (entity.firstName ? `${entity.firstName} ` : '') +
    (entity.middleName ? `${entity.middleName} ` : '') +
    (entity.lastName || '')
  );
}

export function handleIdentAssigneeCheck(fieldsValues: FieldValue[]) {
  const status = fieldsValues.find((field) => field.config.field === '_meta.status');
  return status && status.value === PersonStatus.VERIFICATION_IN_PROGRESS;
}
export function handleAssigneeCheck(fieldsValues: FieldValue[]) {
  const status = fieldsValues.find((field) => field.config.field === '_meta.status');
  return status && status.value === PersonStatus.COMPILATION_IN_PROGRESS;
}

export function getPriorityRangeValues() {
  return priorityRange;
}

export function getPersonStatusValues() {
  return personStatusValues;
}

export function buildBulkUpdateConfig(srvApi: PersonAPI): BulkUpdateOptions<PersonAPI, Person> {
  return {
    srvApi: srvApi,
    titleFormatter: titleFormatter,
    fields: [
      {
        field: 'priority',
        label: 'Priority',
        type: FieldType.select,
        values: getPriorityRangeValues,
      },
      {
        field: '_meta.status',
        label: 'Status',
        required: true,
        type: FieldType.select,
        values: getPersonStatusValues,
      },
      {
        field: 'identAssignees',
        label: 'Assignee (identify)',
        required: true,
        type: FieldType.multiUser,
        roles: [Roles.PersonCreator, Roles.PersonCompilerIdent],
        hideCheck: true,
        handleCheck: handleIdentAssigneeCheck,
      },
      {
        field: 'assignees',
        label: 'Assignee (compile)',
        required: true,
        type: FieldType.multiUser,
        roles: [Roles.PersonCompiler, Roles.PersonCompilerIdent],
        hideCheck: true,
        handleCheck: handleAssigneeCheck,
      },
    ],
    filterBarConfig,
  };
}
