import { catchError, map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { Account } from './account';
import { APIService } from '../../shared/services/api/api.service';
import {
  GET,
  MediaType,
  Produces,
  Query,
  POST,
  Body,
  Path,
  PATCH,
  DELETE,
} from '../../shared/services/rest/rest.service';
import { AccountAffiliation } from './account-affiliation';

@Injectable()
export class AccountAPI extends APIService {
  @GET('accounts/')
  @Produces(MediaType.JSON)
  public find(
    @Query('name') name?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any,
    @Query('grouped') grouped?: boolean
  ): Observable<Account[]> {
    return;
  }

  @GET('accounts/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Account> {
    return;
  }

  @GET('accounts/count')
  @Produces(MediaType.JSON)
  public count(@Query('name') name?: string, @Query('filter') filter?: any): Observable<any> {
    return;
  }

  @GET('accounts/wait-es/{id}')
  @Produces(MediaType.JSON)
  public waitEs(@Path('id') id: string, @Query('deletion') deletion?: boolean): Observable<{ esUpToDate: boolean }> {
    return;
  }

  @GET('accounts/search')
  @Produces(MediaType.JSON)
  public search(@Query('term') term: string): Observable<Account[]> {
    return;
  }

  @POST('accounts')
  @Produces(MediaType.JSON)
  public create(@Body account: Account): Observable<any> {
    return;
  }

  @PATCH('accounts/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body account: Partial<Account>): Observable<any> {
    return;
  }

  @DELETE('accounts/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }

  @GET('accounts/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  @GET('accounts/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }

  @POST('accounts/submit-job')
  @Produces(MediaType.JSON)
  public submitJob(@Body account: Account): Observable<Account> {
    return;
  }

  /**
   * Upload the file and get a bulk upload ID
   *
   * @param file
   * @param secondEyes
   */
  public startBulkListUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'accounts/bulk-list', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  @PATCH('accounts/{accountId}/affiliations/{affiliationId}')
  @Produces(MediaType.JSON)
  public updateAccountAffiliation(
    @Path('accountId') accountId: string,
    @Path('affiliationId') affiliationId: string,
    @Body update: Partial<AccountAffiliation>
  ): Observable<any> {
    return;
  }

  @PATCH('accounts/{accountId}/affiliations/{affiliationId}/add')
  @Produces(MediaType.JSON)
  public addAffiliation(
    @Path('accountId') accountId: string,
    @Path('affiliationId') affiliationId: string
  ): Observable<any> {
    return;
  }

  @DELETE('accounts/{accountId}/affiliations/{affiliationId}/remove')
  @Produces(MediaType.JSON)
  public removeAffiliation(
    @Path('accountId') accountId: string,
    @Path('affiliationId') affiliationId: string
  ): Observable<any> {
    return;
  }

  @PATCH('accounts/{accountId}/reparent')
  @Produces(MediaType.JSON)
  public reparent(@Path('accountId') accountId: string, @Body params: { newParent?: string }): Observable<any> {
    return;
  }

  public downloadAccountsFile(filter: { [key: string]: any }, name: string): Observable<any> {
    const params = new URLSearchParams();
    filter && Object.keys(filter).length > 0 && params.set('filter', JSON.stringify(filter));
    name && params.set('name', name);

    const paramString = params.toString();
    const url = `${this.getBaseUrl()}accounts/export${paramString ? `?${paramString}` : ''}`;

    return this.http.get(url, { responseType: 'blob' }).pipe(catchError((err) => this.handleError(err)));
  }
}
