import { Component } from '@angular/core';
import { firstValueFrom, tap } from 'rxjs';
import { Router } from '@angular/router';

import { ACL } from '../../shared/acl/acl.service';
import { EventSeries } from '../shared/event-series';
import { EventSeriesAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-event-series-create',
  templateUrl: './create.component.html',
})
export class EventSeriesCreateComponent {
  eventSeries: EventSeries = new EventSeries();

  isSubmitting = false;

  isFormValid: boolean;

  constructor(private router: Router, private svcEventSeries: EventSeriesAPI, private svcACL: ACL) {}

  goBack(): void {
    const link = ['/event-series'];
    this.router.navigate(link);
  }

  async onSubmit(eventSeries: EventSeries): Promise<void> {
    if (!this.svcACL.hasCredential('eventSeries.create') || !eventSeries || !this.isFormValid) {
      return;
    }

    await this.saveEventSeries(eventSeries);
    this.goBack();
  }

  onFormValidityChange(status: string): void {
    this.isFormValid = status === 'VALID';
  }

  private async saveEventSeries(eventSeries: EventSeries): Promise<any> {
    eventSeries._meta = {} as any;

    this.isSubmitting = true;

    return await firstValueFrom(this.svcEventSeries.create(eventSeries).pipe(tap(() => (this.isSubmitting = false))));
  }
}
