import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subscription } from 'rxjs';
import { Store, Select, Actions, ofActionSuccessful } from '@ngxs/store';
import { Step } from '../../step.enum';
import { Mode } from '../../mode.enum';
import { StepOptions } from '../bulk-update-stepper/step-options';
import { BulkUpdateState } from '../../bulk-update.state';
import {
  InitAction,
  NextStepAction,
  PreviousStepAction,
  PrevalidateUpdateAction,
  CloseAction,
  RefreshListAction,
} from '../../bulk-update.actions';
import { BulkUpdateOptionsProvider } from '../../bulk-update-options-provider';
import { Auth } from '../../../../shared/services/auth/auth.service';
import { BulkUpdateOptions } from '../../bulk-update-options';
import { BulkUpdateApi } from '../../bulk-update-api';
import { BulkUpdateModel } from '../../bulk-update.model';
import { BulkUpdateApiSimple } from '../../bulk-update-api-simple';

@Component({
  selector: 'dirt-bulk-update-modal',
  templateUrl: './bulk-update-modal.component.html',
  styleUrls: ['./bulk-update-modal.component.scss'],
})
export class BulkUpdateModalComponent implements OnDestroy, OnInit, AfterViewInit {
  Step = Step;
  Mode = Mode;

  @Input()
  mode: Mode;

  @Input()
  search: string;

  @Input()
  filter: any;

  @Input()
  options: BulkUpdateOptions<BulkUpdateApi<BulkUpdateModel> | BulkUpdateApiSimple, BulkUpdateModel>;

  @Output()
  refresh = new EventEmitter();

  stepsOptions: StepOptions[] = [
    {
      step: Step.first,
      label: 'Selection',
    },
    {
      step: Step.second,
      label: 'Fields & Values',
    },
    {
      step: Step.third,
      label: 'Processing',
    },
  ];

  @Select(BulkUpdateState.currentStep) currentStep$: Observable<Step>;
  @Select(BulkUpdateState.mode) mode$: Observable<Mode>;
  @Select(BulkUpdateState.loading) loading$: Observable<boolean>;
  @Select(BulkUpdateState.updating) updating$: Observable<boolean>;
  @Select(BulkUpdateState.validCount) validCount$: Observable<number>;
  @Select(BulkUpdateState.count) count$: Observable<number>;
  @Select(BulkUpdateState.isValuesSet) isValuesSet$: Observable<boolean>;

  private refreshListSubject: Subscription;

  @ViewChild('stepsContainer') stepsContainer: ElementRef;

  constructor(
    private store: Store,
    private actions$: Actions,
    private activeModal: NgbActiveModal,
    public auth: Auth,
    public bulkUpdateOptionsProvider: BulkUpdateOptionsProvider
  ) {}

  ngOnInit() {
    this.refreshListSubject = this.actions$.pipe(ofActionSuccessful(RefreshListAction)).subscribe(() => {
      this.refresh.emit();
    });
  }

  ngAfterViewInit(): void {
    // Reset scroll position.
    setTimeout(() => {
      this.stepsContainer.nativeElement.scrollLeft = 0;
    }, 0);
  }

  init() {
    this.bulkUpdateOptionsProvider.setOptions(this.options);
    this.store.dispatch(new InitAction(this.mode, this.search, this.filter));
  }

  onNextStepClick() {
    this.store.dispatch(new NextStepAction());
  }

  onPreviousStepClick() {
    this.store.dispatch(new PreviousStepAction());
  }

  onPrevalidateClick() {
    this.store.dispatch(new PrevalidateUpdateAction());
  }

  onCloseClick() {
    this.store.dispatch(new CloseAction());
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.refreshListSubject.unsubscribe();
  }
}
