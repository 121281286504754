import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { Association } from '../shared/association';
import { AssociationAPI } from '../shared/association-api.service';
import { AssociationStatus } from '../shared/constant/status.enum';
import { AssociationFormComponent } from '../shared/form/form.component';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';

type SubmitAction = 'SAVE' | 'SAVE_AND_BACK' | 'SAVE_AND_NEW';

@Component({
  selector: 'dirt-association-create',
  templateUrl: 'create.component.html',
  styleUrls: ['create.component.scss'],
})
export class AssociationCreateComponent implements OnInit, OnDestroy {
  association: Association = new Association();
  sub: Subscription;
  isSubmitting = false;
  saveAndNew = false;

  @ViewChild('frmAssociation', { static: false })
  associationFormComponent: AssociationFormComponent;

  private inScopeCountries: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcAssociation: AssociationAPI,
    private svcValue: ValueAPI
  ) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      this.association.parent = params['parentId'];
    });

    this.svcValue.find(ValueType.InScopeCountry, Number.MAX_SAFE_INTEGER).subscribe((values) => {
      this.inScopeCountries = values.map((v) => v.code as string);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  onSubmit(association: Association, action: SubmitAction): void {
    let problems: string[] = [];
    if (
      !association.parent &&
      association.address?.countryCode &&
      !this.inScopeCountries.includes(association.address.countryCode)
    ) {
      problems.push('Selected country is out of scope.');
    }

    let message: string;
    if (problems.length > 0) {
      message = `The following issues were found: \n - ${problems.join('\n - ')}\n\n`;
      message += 'Do you really want to submit this entry?';

      // confirm the submit action
      if (!window.confirm(message)) {
        return;
      }
    }

    this.setAssociationStatus(association);

    this.createAssociation(association, action);
  }

  setAssociationStatus(association: Association) {
    if (!association.parent) {
      const status = AssociationStatus.READY_FOR_COMPILATION;
      association._meta = { status: AssociationStatus[status] };
      association['identified'] = true;
    }
    return association;
  }

  goBack(): void {
    const link = this.association.parent ? ['/association/detail', this.association.parent] : ['/association'];

    this.router.navigate(link);
  }

  private createAssociation(association: Association, action: SubmitAction): void {
    this.isSubmitting = true;

    this.svcAssociation.create(association).subscribe({
      next: (res) => {
        this.association.id = res._id; // we'll fetch the rest on the detail page
        switch (action) {
          case 'SAVE': // save and show details
            this.goToDetailView();
            break;
          case 'SAVE_AND_NEW': // save and reset form
            this.associationFormComponent.resetForm();
            break;
          default: // save and go back to previous main view
            this.goBack();
            break;
        }
      },
      error: () => (this.isSubmitting = false),
      complete: () => (this.isSubmitting = false),
    });
  }

  private goToDetailView(): void {
    this.router.navigate(['/association/detail', this.association.id]);
  }
}
