import { Directive, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subject, takeUntil } from 'rxjs';

import { Utils } from '../../../common/utils';

@Directive({
  selector: '[longDash]',
})
export class LongDashDirective implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject();

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    fromEvent(this.elementRef.nativeElement, 'blur')
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: any) => this.onValueChange(event.target.value));
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  private onValueChange(value: string) {
    this.elementRef.nativeElement.value = Utils.longDash(value);
    this.elementRef.nativeElement.dispatchEvent(new Event('input'));
  }
}
