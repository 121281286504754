<section class="dirt-comment-create clearfix">
  <form (ngSubmit)="doCreate()" #frmComment="ngForm" novalidate>
    <div class="form-group mb-2">
      <label for="content" class="sr-only">Comment</label>
      <textarea
        id="content"
        class="form-control no-validate"
        name="content"
        rows="1"
        placeholder="Write a comment..."
        minlength="3"
        required
        [(ngModel)]="content"
        [disabled]="isSaving"
        (keydown)="onKeyDown($event)"
      >
      </textarea>
      <small class="form-text text-muted" [hidden]="showBtnSubmit">
        Press <kbd>Shift + Enter</kbd> to add a new line.
      </small>

      <div class="mt-3" [hidden]="!showBtnSubmit && !showBtnCancel">
        <!-- Submit -->
        <button
          type="submit"
          class="btn btn-primary btn-sm pull-right"
          *ngIf="showBtnSubmit"
          [disabled]="frmComment.invalid || isSaving"
        >
          {{ btnSubmitCaption || 'Submit' }}
        </button>

        <!-- Cancel -->
        <button type="button" class="btn btn-secondary btn-sm" *ngIf="showBtnCancel" (click)="onCancel()">
          Cancel
        </button>
      </div>
    </div>
  </form>
</section>
