import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Auth } from '../auth/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private readonly svcAuth: Auth) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.svcAuth.isAuthenticated()) {
      return true;
    }

    Auth.redirectUrl = state.url;
    this.router.navigate(['/login']);
    return false;
  }
}
