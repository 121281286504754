<section class="dirt-event-audit" *ngIf="audit && !isLoading" diScroller>
  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-audit-log-pagination'"
    [pagingSize]="audit?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="getEffectiveTotal()"
    [couldHaveMore]="hasFullPage()"
    (pageChange)="getPaginatedAuditLogs($event)"
  >
  </dirt-pagination>

  <article
    *ngFor="
      let log of audit
        | paginate
          : {
              id: 'dirt-audit-log-pagination',
              itemsPerPage: pagingLimit,
              currentPage: pagingPage,
              totalItems: getEffectiveTotal()
            };
      let idx = index;
      let last = last
    "
    [class]="log.document._meta?.status"
  >
    <div class="di-audit-date" [title]="log.timestamp | date : 'medium'">
      <span>{{ log.timestamp | date : 'dd' }}</span>
      <span>{{ log.timestamp | date : 'MMM' }}</span>
    </div>

    <div class="di-audit-content">
      <div class="di-audit-header d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-between">
          <dirt-username [user]="log.user" [mailto]="true" fontWeight="600" [displayUserBadge]="true"></dirt-username>
          <!-- Operation / Action -->
          <div class="d-flex flex-row">
            <div *ngIf="log.action">
              <span class="badge badge-info">{{ log.action | capitalize }}</span>
            </div>
            <div class="di-audit-operation" (click)="showJson(log.document)">
              <span class="badge badge-secondary" *ngIf="isManuallyAssigned(log)">Manually </span>
              <span
                class="badge"
                [ngClass]="{
                  'badge-secondary': log.operation !== 'delete',
                  'badge-danger': log.operation === 'delete'
                }"
                >{{ log.operation | capitalize }}</span
              >
            </div>
          </div>
        </div>
        <!--Status-->
        <div class="d-flex flex-row justify-content-between">
          <div class="di-audit-status" *ngIf="log.document._meta">
            <span *ngIf="!last && log.document._meta.status !== audit[idx + 1].document._meta?.status">
              {{ log.document._meta.status | capitalize }}
            </span>
            <span *ngIf="last">{{ log.document._meta.status | capitalize }}</span>
          </div>
        </div>
      </div>
      <div class="di-changes-container" *ngIf="log.changes.length">
        <ul class="fa-ul di-change-list">
          <li *ngFor="let change of log.changes">
            <span [ngSwitch]="change.change" class="fa-li" [ngbTooltip]="change.change | capitalize">
              <i *ngSwitchCase="'added'" class="fa fa-plus-circle text-success" aria-hidden="true"></i>
              <i *ngSwitchCase="'updated'" class="fa fa-pencil text-secondary" aria-hidden="true"></i>
              <i *ngSwitchCase="'deleted'" class="fa fa-minus-circle text-danger" aria-hidden="true"></i>
            </span>
            <span class="font-weight-bolder">{{ change.path }}</span>
            <span>:</span>
            <ng-container [ngSwitch]="change.type">
              <span *ngSwitchCase="'date'">{{ change.value | date : 'medium' }}</span>
              <dirt-username *ngSwitchCase="'user'" [user]="change.value.join()"></dirt-username>
              <ng-container *ngSwitchCase="'link'">
                <span *ngFor="let cvLink of change.value; last as isLast">
                  <a [href]="cvLink" rel="noopener" target="_blank">{{ cvLink | domain }}</a>
                  <span [hidden]="isLast">|</span>
                </span>
              </ng-container>
              <span *ngSwitchDefault>{{ change.value?.join(', ') }}</span>
            </ng-container>
          </li>
        </ul>
      </div>
    </div>
  </article>

  <!-- Empty Message -->
  <p class="text-center" *ngIf="!audit.length">No Logs</p>
</section>

<!-- Loading Message -->
<p class="dirt-loading" *ngIf="isLoading">
  <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
  <span class="sr-only">Loading...</span>
</p>
