import { Component, Input, OnInit } from '@angular/core';
import { Color } from '../../enum/color';

@Component({
  selector: 'dirt-verified-icon',
  templateUrl: './verified-icon.component.html',
  styleUrls: ['./verified-icon.component.scss'],
})
export class VerifiedIconComponent implements OnInit {
  @Input()
  color: Color;

  constructor() {}

  ngOnInit() {}
}
