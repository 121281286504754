import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs';

import { Account } from '../account';
import { AccountAPI } from '../account-api.service';

@Component({
  selector: 'dirt-account-move-modal',
  templateUrl: 'move-modal.component.html',
})
export class AccountMoveModalComponent {
  @Input()
  accountId: string;

  newParent?: Account;

  isLoading = false;

  constructor(public activeModal: NgbActiveModal, private readonly svcAccount: AccountAPI) {}

  onSubmit(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    this.svcAccount
      .reparent(this.accountId, { newParent: this.newParent?.id })
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((account) => {
        this.activeModal.close(account);
      });
  }
}
