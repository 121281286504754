import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

import { ProjectTagValue } from '../project-tag-value';
import { ACL } from '../../../shared/acl/acl.service';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { Value } from '../../../shared/services/value/value';

@Component({
  selector: 'dirt-project-tags-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
})
export class ProjectTagsFormComponent implements OnInit {
  @Input('tag')
  model: ProjectTagValue = new ProjectTagValue();

  @Input('isSubmitting')
  isSubmitting = false;

  @Output()
  onSubmitted = new EventEmitter<ProjectTagValue>();

  @Output()
  onCancel = new EventEmitter();

  products: Value[];

  get isEditMode() {
    return this.model && !!this.model._id;
  }

  constructor(private svcAcl: ACL, private readonly svcValue: ValueAPI) {}

  ngOnInit(): void {
    this.loadProducts();
  }

  onTitleChange(newTitle: string) {
    if (!this.isEditMode) {
      this.model.code = this.brushCode(newTitle);
    }
  }

  onCodeBlur() {
    if (!this.isEditMode) {
      this.model.code = this.brushCode(this.model.code);
    }
  }

  private brushCode(newTitle: string): string {
    return (newTitle || '')
      .trim()
      .toUpperCase()
      .substring(0, 20)
      .trim()
      .replace(/[^A-Z0-9]/g, '_');
  }

  onDeliveryChange(newVal: boolean) {
    if (newVal) {
      this.model.active = true;
    }
  }

  onSubmit(): void {
    this.onSubmitted.emit(this.model);
  }

  onCancelClick() {
    this.onCancel.emit();
  }

  canEditTech(): boolean {
    return this.svcAcl.hasCredential('projectTags.update.technical');
  }

  loadProducts() {
    this.svcValue.find(ValueType.Product, Number.MAX_SAFE_INTEGER, 0).subscribe((data) => {
      console.log(data);
      this.products = data;
    });
  }
}
