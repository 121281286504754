import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, PRIMARY_OUTLET } from '@angular/router';
import { first, tap, distinctUntilChanged } from 'rxjs/operators';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';

import { PersonAPI } from '../api.service';
import { PersonStatus } from '../constant/status.enum';
import { UserGroupAPI } from '../../../user-group/shared/api.service';
import { User } from '../../../user/shared/user';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { Value } from '../../../shared/services/value/value';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { Utils } from '../../../common/utils';
import { Completeness, CompletenessValues } from '../constant/completeness.enum';
import { DateRange } from '../../../shared/components/date-range/date-range.component';

@Component({
  selector: 'dirt-person-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class PersonFilterComponent implements OnInit {
  @Output()
  onFilter = new EventEmitter<any>();

  @ViewChild('filterPopover', { static: true })
  filterPopover: NgbPopover;

  visiblePanel: any;

  filter: any;

  countryValues: Value[] = [];
  priorityList = [0, 1, 2, 3, 4, 5];

  searchCtrl: FormControl = new FormControl('');
  visibleSubPanel: any;
  hasSubPanel: Boolean;
  panelVal: string;
  errorMessage: string;

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private svcPerson: PersonAPI,
    private svcUserGroup: UserGroupAPI,
    private svcValue: ValueAPI
  ) {}

  ngOnInit() {
    this.restoreFromQueryParams();
    this.svcValue
      .find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER, 0, '+title', { technical: false })
      .subscribe((data) => {
        this.projectsDeliveryList = data;
      });
    this.svcValue
      .find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER, 0, '+title', { technical: true })
      .subscribe((data) => {
        this.projectsTechnicalList = data;
      });

    this.svcValue.find(ValueType.Product, Number.MAX_SAFE_INTEGER, 0).subscribe((data) => {
      this.productList = data;
    });

    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.countryValues = data;
    });

    this.searchCtrl.valueChanges.pipe(distinctUntilChanged()).subscribe((val) => this.onSearch(val));
    this.searchCtrl.reset(this.completeCompilerList);
  }

  getCountryTitle(code: string): string {
    return (this.countryValues.find((o) => o.code === code) || { title: '' }).title;
  }

  /**
   * Show the popup panel
   */
  onShow(): void {
    // reset panel visibility status
    this.visiblePanel = false;
  }

  /**
   * Set the active filter panel
   *
   * @param panel
   */
  setPanel(panel?: string, hasSubPanel?: boolean): void {
    this.visiblePanel = panel;
    this.errorMessage = undefined; // reset error message
    this.hasSubPanel = hasSubPanel ? true : false;
    this.visibleSubPanel = '';
    if (panel) {
      // uppercase first letter
      const name = panel.charAt(0).toUpperCase() + panel.slice(1).toLowerCase();
      const initMethod = `init${name}`;
      if (this[initMethod]) {
        this[initMethod]();
      }
    }
  }

  /**
   * Apply the filter
   */
  doApply(): void {
    if (this.errorMessage) {
      return;
    }
    this.filter = {
      '_meta.status': Array.from(this.selectedStatuses),
      'affiliations.countryCode': this.getSelectedLocations(),
      projectNames: Array.from(this.selectedProjects),
      products: Array.from(this.selectedProducts),
      compiler: Array.from(this.selectedCompilers),
      tags: Array.from(this.selectedTags),
      verified: this.selectedVerified,
      priority: Array.from(this.selectedPriorities),
      completeness: this.selectedCompleteness.find((elm) => typeof elm !== 'string')
        ? this.selectedCompleteness.map((elm) => JSON.stringify(elm))
        : this.selectedCompleteness,
      utcBy: this.selectedUtcBy,
      createdAt: Object.keys(this.selectedCreatedAt).filter((k) => !!this.selectedCreatedAt[k]).length
        ? this.selectedCreatedAt
        : undefined,
      'review.at': Object.keys(this.selectedSubmittedAt).filter((k) => !!this.selectedSubmittedAt[k]).length
        ? this.selectedSubmittedAt
        : undefined,
      'discard.at': Object.keys(this.selectedDiscardedAt).filter((k) => !!this.selectedDiscardedAt[k]).length
        ? this.selectedDiscardedAt
        : undefined,
    };

    // remove empty values
    Object.keys(this.filter).forEach((key) => {
      const value = this.filter[key];

      if (typeof value === 'undefined' || (Array.isArray(value) && value.length === 0)) {
        delete this.filter[key];
      }
    });

    this.filterPopover.close();
    this.storeAsQueryParams();
    this.onFilter.emit(this.filter);
  }

  /**
   * Clear the active filter
   */
  doClear(): void {
    this.selectedStatuses.clear();
    this.selectedRegionList.clear();
    this.selectedLocations.clear();
    this.selectedProjects.clear();
    this.selectedProducts.clear();
    this.selectedCompilers.clear();
    this.selectedTags.clear();
    this.selectedPriorities.clear();
    delete this.selectedVerified;
    this.selectedCompleteness.length = 0;
    this.selectedUtcBy = undefined;
    this.selectedCreatedAt = {};
    this.selectedSubmittedAt = {};
    this.selectedDiscardedAt = {};

    delete this.filter;
    this.onFilter.emit();
    this.filterPopover.close();

    // Clear query params
    this.router.navigate([]);
  }

  /**
   * Hide the filter popup without applying the changes
   */
  doCancel(): void {
    this.errorMessage = undefined;
    this.filterPopover.close();
    this.setPanel();
  }

  /**
   * Checks whether any filter is set
   */
  isFilterSet(): boolean {
    return !!Object.keys(this.filter || {}).length;
  }

  /**
   * Restore filters saved as query params
   */
  restoreFromQueryParams(): void {
    const tree = this.router.parseUrl(this.router.url);
    const segmentGroup = tree.root.children[PRIMARY_OUTLET];
    if (segmentGroup?.segments?.map((it) => it.path).join('/') !== 'person/list') {
      // do not needlessly restore filters in pages that don't need it
      return;
    }

    const filterMapping = {
      '_meta.status': this.selectedStatuses,
      'affiliations.countryCode': this.selectedLocations,
      projectNames: this.selectedProjects,
      products: this.selectedProducts,
      compiler: this.selectedCompilers,
      tags: this.selectedTags,
      verified: this.selectedVerified,
      priority: this.selectedPriorities,
      completeness: this.selectedCompleteness,
      utcBy: this.selectedUtcBy,
      createdAt: this.selectedCreatedAt,
      'review.at': this.selectedSubmittedAt,
      'discard.at': this.selectedDiscardedAt,
    };

    this.activatedRoute.queryParams.pipe(first()).subscribe((params) => {
      Object.keys(filterMapping).forEach((item) => {
        if (item in params) {
          if (item === 'verified') {
            this.selectedVerified = true;
          } else if (item === 'utcBy') {
            this.selectedUtcBy = params[item];
          } else if (Array.isArray(params[item])) {
            params[item].forEach((p) => {
              filterMapping[item].add ? filterMapping[item].add(p) : filterMapping[item].push(p);
            });
          } else {
            filterMapping[item].add ? filterMapping[item].add(params[item]) : filterMapping[item].push(params[item]);
          }
        } else if (['createdAt', 'review.at', 'discard.at'].includes(item)) {
          const dateRange = this.getDateRangeValueFromQueryParam(params, item);
          if (item === 'createdAt') {
            this.selectedCreatedAt = dateRange;
          } else if (item === 'review.at') {
            this.selectedSubmittedAt = dateRange;
          } else if (item === 'discard.at') {
            this.selectedDiscardedAt = dateRange;
          }
        }
      });
      this.doApply();
    });
  }

  /**
   * Store applied filters as route query params
   */
  storeAsQueryParams(): void {
    if (this.isFilterSet()) {
      const filters = Object.keys(this.filter).reduce((filter, key) => {
        const value = this.filter[key];
        if (Utils.isLiteralObject(value)) {
          Object.keys(value).forEach((oKey) => {
            const oValue = value[oKey];
            if (oValue) {
              if (oValue instanceof Date) {
                filter[`${key}_${oKey}`] = oValue.toJSON();
              } else {
                filter[oKey] = oValue;
              }
            }
          });
        } else {
          filter[key] = value;
        }
        return filter;
      }, {});
      this.router.navigate([], { queryParams: filters });
    }
  }

  /**
   * Get list of selected locations (countries)
   */
  getSelectedLocations(): Array<string> {
    const locations: Set<string> = new Set(this.selectedLocations);
    this.selectedRegionList.forEach((region) => {
      this.regionLocationMap.get(region).forEach((l) => locations.add(l));
    });
    return Array.from(locations);
  }

  // STATUS
  statusList = PersonStatus;
  private selectedStatuses: Set<string> = new Set();

  toggleStatus(status: string): void {
    if (this.selectedStatuses.has(status)) {
      this.selectedStatuses.delete(status);
    } else {
      this.selectedStatuses.add(status);
    }
  }

  isStatusSelected(status: string): boolean {
    return this.selectedStatuses.has(status);
  }

  // REGION
  regionList: string[];
  regionLocationMap: Map<string, string[]> = new Map();
  isLoadingRegions = false;
  private selectedRegionList: Set<string> = new Set();

  initRegion(): void {
    if (this.regionList) {
      return;
    }

    this.isLoadingRegions = true;

    this.svcUserGroup.find(Number.MAX_SAFE_INTEGER).subscribe(
      (resp) => {
        this.regionList = resp.map((u) => u.name).sort();
        resp.forEach((u) => this.regionLocationMap.set(u.name, u.countries));
      },
      null,
      () => (this.isLoadingRegions = false)
    );
  }

  toggleRegion(region: string): void {
    if (this.selectedRegionList.has(region)) {
      this.selectedRegionList.delete(region);
    } else {
      this.selectedRegionList.add(region);
    }
  }

  isRegionSelected(region: string): boolean {
    return this.selectedRegionList.has(region);
  }

  // LOCATION
  locationList: string[];
  isLoadingLocations = false;
  private selectedLocations: Set<string> = new Set();

  initLocation(): void {
    if (this.locationList) {
      return;
    }

    this.isLoadingLocations = true;

    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe({
      next: (countries) => {
        this.locationList = countries.map((c) => c.code as string);
        this.isLoadingLocations = false;
      },
      error: () => (this.isLoadingLocations = false),
    });
  }

  toggleLocation(loc: string): void {
    if (this.selectedLocations.has(loc)) {
      this.selectedLocations.delete(loc);
    } else {
      this.selectedLocations.add(loc);
    }
  }

  isLocationSelected(loc: string): boolean {
    return this.selectedLocations.has(loc);
  }

  // PROJECTS
  projectsTechnicalList: Value[] = [];
  projectsDeliveryList: Value[] = [];
  projectTagList = ['delivery', 'technical'];

  filterOnProjectType(): any[] {
    if (this.panelVal === 'technical') {
      return this.projectsTechnicalList;
    } else if (this.panelVal === 'delivery') {
      return this.projectsDeliveryList;
    }
  }

  private selectedProjects: Set<string> = new Set();

  toggleProjects(status: string): void {
    if (['NON_AUTOMATED', 'AUTOMATED_PLUS_DELIVERY', 'AUTOMATED_NO_DELIVERY', 'LFKA_ONLY'].includes(status)) {
      const hasStatus = this.selectedProjects.has(status);

      this.selectedProjects.clear(); // We only want one of NON_AUTOMATED, AUTOMATED_PLUS_DELIVERY, AUTOMATED_NO_DELIVERY or LFKA_ONLY when either is selected
      if (!hasStatus) {
        // allow deselection using clear
        this.selectedProjects.add(status);
      }
    } else if (
      !this.selectedProjects.has('NON_AUTOMATED') &&
      !this.selectedProjects.has('AUTOMATED_PLUS_DELIVERY') &&
      !this.selectedProjects.has('AUTOMATED_NO_DELIVERY') &&
      !this.selectedProjects.has('LFKA_ONLY')
    ) {
      if (this.selectedProjects.has(status)) {
        this.selectedProjects.delete(status);
      } else {
        this.selectedProjects.add(status);
      }
    }
  }

  isProjectSelected(value: string): boolean {
    return this.selectedProjects.has(value);
  }

  // PRODUCT
  productList: Value[] = [];
  selectedProducts: Set<any> = new Set();

  toggleProducts(status: string): void {
    if (this.selectedProducts.has(status)) {
      this.selectedProducts.delete(status);
    } else {
      this.selectedProducts.add(status);
    }
  }

  isProductSelected(status: string): boolean {
    return this.selectedProducts.has(status);
  }

  // REVIEWERS
  compilersList: User[];
  completeCompilerList: User[];
  isLoadingCompilers = false;
  private selectedCompilers: Set<string> = new Set();

  initCompilers(): void {
    if (this.compilersList) {
      return;
    }

    this.isLoadingCompilers = true;
    this.svcPerson
      .reviewers()
      .pipe(tap(() => (this.isLoadingCompilers = false)))
      .subscribe((resp) => {
        this.compilersList = resp;
        this.completeCompilerList = [...this.compilersList];
      });
  }

  toggleReviewedBy(reviewedBy: string, $event: MouseEvent): void {
    $event.preventDefault();

    if (this.selectedCompilers.has(reviewedBy)) {
      this.selectedCompilers.delete(reviewedBy);
    } else {
      this.selectedCompilers.add(reviewedBy);
    }
  }

  isReviewedBySelected(reviewedBy: string): boolean {
    return this.selectedCompilers.has(reviewedBy);
  }

  // TAG
  tagList: string[];
  isLoadingTags = false;
  private selectedTags: Set<string> = new Set();

  initTag(): void {
    if (this.tagList) {
      return;
    }

    this.isLoadingTags = true;
    this.svcPerson.findTags().subscribe(
      (resp) => (this.tagList = resp.sort()),
      null,
      () => (this.isLoadingTags = false)
    );
  }

  toggleTag(loc: string): void {
    if (this.selectedTags.has(loc)) {
      this.selectedTags.delete(loc);
    } else {
      this.selectedTags.add(loc);
    }
  }

  isTagSelected(loc: string): boolean {
    return this.selectedTags.has(loc);
  }

  // QC
  private selectedVerified: boolean;

  toggleVerified(verified: boolean): void {
    if (this.selectedVerified === verified) {
      delete this.selectedVerified;
    } else if (this.selectedVerified !== verified) {
      this.selectedVerified = verified;
    }
  }

  isVerifiedSelected(verified: boolean): boolean {
    return this.selectedVerified === verified;
  }

  //PriorityList
  private selectedPriorities: Set<string> = new Set();

  isPrioritySelected(priority: string): boolean {
    return this.selectedPriorities.has(priority);
  }
  togglePriority(priority: string): void {
    if (this.selectedPriorities.has(priority)) {
      this.selectedPriorities.delete(priority);
    } else {
      this.selectedPriorities.add(priority);
    }
  }

  // Completeness
  completenessList = Completeness;
  completenessSubList = CompletenessValues;

  private selectedCompleteness: { criteria: String; value: String }[] = [];

  setSubPanel(subPanel?: string, panelVal?: string): void {
    this.visibleSubPanel = subPanel;
    this.panelVal = panelVal;
    this.hasSubPanel = false;
  }

  isCompleteValueSelected(completeVal: String): boolean {
    return this.selectedCompleteness ? this.selectedCompleteness.some((elm) => elm.criteria === completeVal) : false;
  }

  isCompleteSubValueSelected(subValComplete: string): boolean {
    return this.selectedCompleteness
      ? this.selectedCompleteness.some((elm) => elm.criteria === this.panelVal && elm.value === subValComplete)
      : false;
  }

  toggleCompleteSubValue(completeVal: string) {
    if (
      this.selectedCompleteness &&
      this.selectedCompleteness.some((elm) => elm.criteria === this.panelVal && elm.value === completeVal)
    )
      this.selectedCompleteness.splice(
        this.selectedCompleteness.findIndex((elm) => elm.criteria === this.panelVal),
        1
      );
    else if (this.selectedCompleteness.some((elm) => elm.criteria === this.panelVal && elm.value !== completeVal)) {
      this.selectedCompleteness.find((elm) => elm.criteria === this.panelVal).value = completeVal;
    } else this.selectedCompleteness.push({ criteria: this.panelVal, value: completeVal });
  }

  selectedUtcBy: 'compiler' | 'reviewer';

  onSearch(compiler: string): void {
    if (compiler)
      this.compilersList = this.completeCompilerList.filter((val) => {
        return (
          val.user_metadata &&
          (val.user_metadata.firstName.toLowerCase().includes(compiler.toLowerCase()) ||
            val.user_metadata.lastName.toLowerCase().includes(compiler.toLowerCase()) ||
            val.name.toLowerCase().includes(compiler.toLowerCase()))
        );
      });
    else this.compilersList = this.completeCompilerList;
  }

  // CreatedAt
  selectedCreatedAt: DateRange = {};

  setCreatedAt(value: DateRange): void {
    this.selectedCreatedAt = value;
  }

  // submittedAt
  selectedSubmittedAt: DateRange = {};

  setSubmittedAt(value: DateRange): void {
    this.selectedSubmittedAt = value;
  }

  // discardedAt
  selectedDiscardedAt: DateRange = {};

  setDiscardedAt(value: DateRange): void {
    this.selectedDiscardedAt = value;
  }

  onFilterError(message: string): void {
    this.errorMessage = message;
  }

  getDateRangeValueFromQueryParam(params: any, item: string): DateRange {
    if (params[`${item}_start`]) {
      return {
        start: new Date(params[`${item}_start`]),
        end: new Date(params[`${item}_end`]),
        isNotSet: false,
      };
    } else if (params.isNotSet) {
      // params.start have priority
      return {
        start: undefined,
        end: undefined,
        isNotSet: true,
      };
    } else {
      return {};
    }
  }
}
