import { catchError, map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { APIService } from '../../shared/services/api/api.service';
import {
  Body,
  DELETE,
  GET,
  MediaType,
  PATCH,
  Path,
  POST,
  Produces,
  Query,
} from '../../shared/services/rest/rest.service';
import { Domain } from './domain';

@Injectable()
export class DomainAPI extends APIService {
  @GET('domains/')
  @Produces(MediaType.JSON)
  public find(
    @Query('hostname') hostname?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<Domain[]> {
    return;
  }

  @GET('domains/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Domain> {
    return;
  }

  @GET('domains/count')
  @Produces(MediaType.JSON)
  public count(@Query('hostname') domain?: string, @Query('filter') filter?: any): Observable<any> {
    return;
  }

  @POST('domains')
  @Produces(MediaType.JSON)
  public create(@Body domain: Domain): Observable<any> {
    return;
  }

  @PATCH('domains/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body domain: Partial<Domain>): Observable<Domain> {
    return;
  }

  @DELETE('domains/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }

  @GET('domains/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  @GET('domains/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }

  @POST('domains/submit-job')
  @Produces(MediaType.JSON)
  public submitJob(@Body domain: Domain): Observable<Domain> {
    return;
  }

  @GET('domains/validate')
  @Produces(MediaType.JSON)
  public validate(@Query('url') url: string): Observable<{ valid: boolean }> {
    return;
  }

  /**
   * Upload the file and get a bulk upload ID
   *
   * @param file
   * @param secondEyes
   */
  public startBulkListUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'domains/bulk-list', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  public startBulkListCompliantCountriesUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'domains/bulk-list-compliant-countries', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  public uploadScreenshot(domainId: string, file: File): Observable<{ ok: boolean }> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('screenshot', file); // multipart name must match server
    return this.http
      .post(this.getBaseUrl() + `domains/${domainId}/screenshot`, formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).ok));
  }

  @DELETE('domains/{domainId}/screenshot')
  @Produces(MediaType.JSON)
  public deleteScreenshot(@Path('domainId') domainId: string): Observable<{ ok: boolean }> {
    return;
  }
}
