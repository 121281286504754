import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ColGroupDef, RowNode } from 'ag-grid';
import { ColDef } from 'ag-grid/main';
import { ValueFormatterParams } from 'ag-grid/dist/lib/entities/colDef';

import { ACL } from '../../../../shared/acl/acl.service';
import { Profile } from '../../../shared/profile';
import { ProfileGridComponent } from '../../../shared/grid/grid.component';
import { ProfileDirectLinkService } from '../../../shared/modal/direct-link/direct-link.service';
import { ProfileDirectLinkOptions } from '../../../shared/modal/direct-link/direct-link-options.interface';
import { Membership } from '../../../../membership/shared/membership';
import { MembershipAPI } from '../../../../membership/shared/api.service';
import { ProfileMembershipAPI } from '../shared/api.service';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { QCCommentService } from '../../../shared/grid/qc-comment/qc-comment.service';
import { ProfileMembership } from '../../../shared/profile-membership';

@Component({
  selector: 'dirt-profile-membership-list',
  templateUrl: '../../../shared/grid/grid.component.html',
  styleUrls: ['../../../shared/grid/grid.component.scss'],
})
export class ProfileMembershipListComponent extends ProfileGridComponent implements OnInit {
  FIELD_KEY_FA = '_profile.focusAreas';
  FIELD_LINKS = 'association.webSource';
  FIELD_FIRST_NAME = 'firstName';
  FIELD_LAST_NAME = 'lastName';
  FIELD_MIDDLE_NAME = 'middleName';
  FIELD_FULL_NAME = 'fullName';
  FIELD_WORKPLACE = 'workplace';
  FIELD_ASSOCIATION_NAME = 'association.associationName';
  FIELD_SUBASSOCIATION_NAME = 'association.subAssociationName';
  FIELD_POSITION = 'position';
  FIELD_PAST_POSITION = 'isPastPosition';
  FIELD_LOCALITY = 'association.locality';
  FIELD_CITY = 'association.address.city';
  FIELD_COUNTRY = 'association.address.country';

  FIELD_FILTER_CITY = 'city';
  FIELD_FILTER_COUNTRY = 'country';
  FIELD_FILTER_LOCALITY = 'locality';

  constructor(
    protected svcProfileMembership: ProfileMembershipAPI,
    private svcMembership: MembershipAPI,
    private svcDirectLink: ProfileDirectLinkService,
    protected readonly svcACL: ACL,
    protected svcQCComment: QCCommentService
  ) {
    super('id', svcACL, svcQCComment);
  }

  ngOnInit() {
    this.addFilter(this.FIELD_POSITION, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_POSITION, params)
    );
    this.addFilter(this.FIELD_FIRST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_FIRST_NAME, params)
    );
    this.addFilter(this.FIELD_MIDDLE_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_MIDDLE_NAME, params)
    );
    this.addFilter(this.FIELD_LAST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_LAST_NAME, params)
    );
    this.addFilter(this.FIELD_FULL_NAME, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FULL_NAME, params)
    );
    this.addFilter(this.FIELD_FILTER_LOCALITY, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_LOCALITY, params)
    );
    this.addFilter(this.FIELD_FILTER_CITY, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_CITY, params)
    );
    this.addFilter(this.FIELD_FILTER_COUNTRY, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_COUNTRY, params)
    );
    this.addFilter(this.FIELD_PAST_POSITION, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_PAST_POSITION, params)
    );
    this.addFilter(this.FIELD_KEY_FA, true, 'keep', false, (params) =>
      this.defineGetFocusArea().subscribe((result) =>
        result && result[0] && result[0].focusArea
          ? params.success(result[0].focusArea?.map((val) => val.name))
          : params.success('')
      )
    );
    super.ngOnInit();
  }

  protected defineGetDataRows(...params): Observable<any> {
    return this.svcProfileMembership.find.apply(this.svcProfileMembership, [this.profile, ...params.slice(1)]); // migrating profileId to profile
  }

  protected defineDistinct(profileId: string, field: string, filter: any): Observable<string[]> {
    return this.svcProfileMembership.distinct(this.profile, field, filter);
  }

  protected defineUpdate(
    profileId: string,
    ids: string[],
    value: ProfileMembership[]
  ): Observable<{ id; compiledAt? }[]> {
    return this.svcProfileMembership.update(this.profile, value);
  }
  protected defineGetFocusArea(): Observable<any> {
    return this.svcProfileMembership.distinctFocusArea(this.profile);
  }

  protected defineGrid(): (ColDef | ColGroupDef)[] {
    const columnDefs: (ColDef | ColGroupDef)[] = [
      {
        headerName: 'Member',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'First Name',
            field: this.FIELD_FIRST_NAME,
            filterParams: this.filters.get(this.FIELD_FIRST_NAME),
            width: 100,
          },
          {
            headerName: 'Middle Name',
            field: this.FIELD_MIDDLE_NAME,
            filterParams: this.filters.get(this.FIELD_MIDDLE_NAME),
            width: 70,
          },
          {
            headerName: 'Last Name',
            field: this.FIELD_LAST_NAME,
            filterParams: this.filters.get(this.FIELD_LAST_NAME),
            width: 100,
          },
          {
            headerName: 'Full Name',
            field: this.FIELD_FULL_NAME,
            filterParams: this.filters.get(this.FIELD_FULL_NAME),
            width: 90,
          },
          {
            headerName: 'Workplace',
            field: this.FIELD_WORKPLACE,
            filter: 'text',
            width: 100,
          },
          {
            headerName: 'Position',
            field: this.FIELD_POSITION,
            filterParams: this.filters.get(this.FIELD_POSITION),
            valueFormatter: this.formatterPosition,
            columnGroupShow: 'open',
            width: 80,
          },
          {
            headerName: 'Past Position',
            field: this.FIELD_PAST_POSITION,
            filterParams: this.filters.get(this.FIELD_PAST_POSITION),
            valueFormatter: this.formatterPastPosition,
            columnGroupShow: 'open',
            width: 70,
          },
        ],
      },
      {
        headerName: 'Association',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'Association Name',
            field: this.FIELD_ASSOCIATION_NAME,
            filter: 'text',
            width: 400,
          },
          {
            headerName: 'Sub-association Name',
            field: this.FIELD_SUBASSOCIATION_NAME,
            filter: 'text',
            width: 200,
          },
          {
            headerName: 'Locality',
            field: this.FIELD_LOCALITY,
            filterParams: this.filters.get(this.FIELD_FILTER_LOCALITY),
            valueFormatter: this.formatterPosition,
            columnGroupShow: 'open',
            width: 70,
          },
          {
            headerName: 'City',
            field: this.FIELD_CITY,
            filterParams: this.filters.get(this.FIELD_FILTER_CITY),
            columnGroupShow: 'open',
            width: 70,
          },
          {
            headerName: 'Country',
            field: this.FIELD_COUNTRY,
            filterParams: this.filters.get(this.FIELD_FILTER_COUNTRY),
            columnGroupShow: 'open',
            width: 70,
          },
        ],
      },
      {
        headerName: 'Link',
        cellRenderer: this.rendererLinks,
        field: this.FIELD_LINKS,
        suppressMenu: true,
        width: 40,
      },
      {
        headerName: 'Focus Areas',
        field: this.FIELD_KEY_FA,
        filterParams: this.filters.get(this.FIELD_KEY_FA),
        width: 600,
      },
    ];
    return columnDefs;
  }

  protected defineAutoSizableColumns(): string[] {
    return [];
  }

  protected onDirectLink(): void {
    const options = <ProfileDirectLinkOptions>{
      title: 'Membership',
      getEntity: (id) => this.svcMembership.findById(id),
      getData: (m: Membership) => ({
        name: [m.member.lastName, m.member.firstName].join(', '),
        position: m.position.toString(),
        link: ['/membership/detail', m.id],
      }),
    };

    this.svcDirectLink
      .open(options)
      .then((entity) => this.svcProfileMembership.link(this.profile, entity.id).toPromise())
      .then(() => this.reloadData());
  }

  /**
   * Formatter for manual match cell.
   *
   * @param {ValueFormatterParams} param
   * @return {string}
   */
  private formatterPastPosition(param: ValueFormatterParams): string {
    return param.value === true ? 'Yes' : '';
  }

  private formatterPosition(param: ValueFormatterParams): string {
    if (!param.value) {
      return;
    }
    return param.value.charAt(0) + param.value.slice(1).toLowerCase().replace(new RegExp('_', 'g'), ' ');
  }

  /**
   * Renderer function for Links columns.
   *
   * @param {ValueFormatterParams} param
   * @return {string}
   */
  private rendererLinks(param: ValueFormatterParams): string {
    if (!param.data) {
      return '';
    }
    const webSource = param.data.association.webSource;
    const linkWS = webSource ? `<a href='${webSource}' rel=noopener target='_blank'>W</a>` : '';
    return `${linkWS}`;
  }

  /** @override */
  updateCommentAndProfileMatch(comment: string, matched: boolean, errorType: string): void {
    const nodes = this.getSelection();
    const match = matched ? ProfileMatchManual.MATCH : ProfileMatchManual.MISMATCH;

    nodes.forEach((node: RowNode) => {
      const data = Object.assign({}, node.data); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables

      data._profile.match.manual = match;

      // even if comment and errorType are null we should set qc so that thebug icon disappears
      data._profile.qc = { comment, errorType };

      node.setData(data);
    });

    this.update(
      nodes,
      nodes.map(
        (n) =>
          ({
            membershipId: n.data.membershipId,
            match: n.data._profile.match,
            person: { id: this.profile.person.id, kolId: this.profile.person.kolId },
            profileId: this.profileId,
            qc: n.data._profile.qc,
          } as Partial<ProfileMembership>)
      )
    );
  }
}
