<section class="dirt-section-detail medical-insights-profile-detail" *ngIf="note">
  <div class="row">
    <div class="col-md-8">
      <header>
        <h2 style="margin-right: 5px; color: {{ note.training ? 'orange' : 'black' }}">
          {{ note.training ? 'Medical Insight Training Note:' : 'Medical Insight Note:' }}
        </h2>

        <h5 style="display: inline-block">{{ note?.veeva_object_id }}</h5>

        <span class="copy-note-button" *diAcl="'medical-insights-profile.training'">
          <dirt-mi-profile-copy [noteId]="note._id"></dirt-mi-profile-copy>
        </span>
        <h5 *ngIf="diseaseFocusAreaName" style="color: orange; margin-left: auto">{{ diseaseFocusAreaName }}</h5>
      </header>

      <div class="section">
        <textarea readonly class="note-text" id="noteTextArea">{{ noteText }}</textarea>

        <details open>
          <summary>Insights</summary>

          <div class="insights-section">
            <div
              class="insights-line"
              *ngFor="let insight of note.selectedTaxonomyInsights; let idx = index; trackBy: trackByIndex"
              [diMarkerWrapper]="insight"
            >
              <div
                *diMarker="{
                  name: 'concept',
                  area: 'MI',
                  entity:
                    currentJob?.type === 'MEDICAL_INSIGHTS_PROFILE_CURATION_QC' ? 'medical-insights-profile' : 'noop'
                }"
              >
                <select name="taxonomy-concept-{{ idx }}" [(ngModel)]="note.selectedTaxonomyInsights[idx].concept">
                  <option [ngValue]="null" [selected]="!insight.concept" disabled>Concept</option>
                  <option *ngFor="let concept of taxonomyConcepts" [ngValue]="concept.code">
                    {{ concept.title }}
                  </option>
                </select>

                <div class="actions-wrapper">
                  <span class="action-btn action-delete red" title="Delete Row" (click)="onRemoveRow(idx)">
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </span>

                  <button
                    class="plus"
                    title="Add Row"
                    (click)="onAddRow()"
                    [disabled]="!insight.concept"
                    *ngIf="idx === note.selectedTaxonomyInsights.length - 1"
                  >
                    +
                  </button>

                  <!-- Only for stage training nodes - auto-correction results -->
                  <ng-container *diAcl="'medical-insights-profile.training'">
                    <span
                      *ngIf="note.training && note._meta?.status === 'DONE'"
                      [title]="correctedTrainingTaxonomies[idx]?.text"
                      [ngClass]="{
                        'check-mark': correctedTrainingTaxonomies[idx]?.title === 'correct',
                        'red-mark': correctedTrainingTaxonomies[idx]?.title === 'incorrect',
                        'orange-mark': correctedTrainingTaxonomies[idx]?.title === 'missing'
                      }"
                    >
                    </span>
                  </ng-container>
                </div>
              </div>
            </div>

            <!-- No insights -->
            <div class="pull-right">
              No proper Insights:
              <ui-switch
                size="small"
                name="hasNoProperInsights"
                [(ngModel)]="note.hasNoProperInsights"
                [disabled]="hasSelectedTaxonomies"
              ></ui-switch>
            </div>

            <ng-container *diAcl="'medical-insights-profile.training'">
              <div
                class="missingTaxonomiesList"
                *ngIf="
                  missingTaxonomiesOnTrainingCuration?.length > 0 && note.training && note._meta?.status === 'DONE'
                "
              >
                Training: Following Taxonomies inserted at original curation are missing:
                <ul>
                  <li *ngFor="let item of missingTaxonomiesOnTrainingCuration">{{ item }}</li>
                </ul>
              </div>
            </ng-container>
          </div>
        </details>

        <details open>
          <summary>Focus Areas</summary>
          <div class="focus-areas-section">
            <div
              class="focus-area-line"
              *ngFor="let focusArea of viewFocusAreas; let i = index; trackBy: trackById"
              [diMarkerWrapper]="focusArea"
            >
              <div
                *diMarker="{
                  name: 'focus-area',
                  area: 'MI',
                  entity:
                    currentJob?.type === 'MEDICAL_INSIGHTS_PROFILE_CURATION_QC' ? 'medical-insights-profile' : 'noop',
                  leftAlign: true
                }"
              >
                <input
                  type="checkbox"
                  [(ngModel)]="viewFocusAreas[i].highlighted"
                  *ngIf="!viewFocusAreas[i].manuallyAdded"
                />

                <span
                  *ngIf="viewFocusAreas[i].manuallyAdded"
                  class="action-btn action-delete"
                  title="Delete Tag"
                  (click)="onDeleteFocusArea(viewFocusAreas[i].id)"
                >
                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                </span>

                <label
                  [ngClass]="{
                    'red-fa': viewFocusAreas[i].deleted,
                    'dark-blue-fa': !viewFocusAreas[i].manuallyAdded && viewFocusAreas[i].highlighted,
                    'light-blue-fa': !viewFocusAreas[i].manuallyAdded && !viewFocusAreas[i].highlighted,
                    'orange-fa': viewFocusAreas[i].manuallyAdded
                  }"
                >
                  <span class="fa-name">
                    {{ viewFocusAreas[i].name }}
                  </span>
                </label>
                <span class="fa-category"> {{ viewFocusAreas[i].category }}</span>

                <span *ngIf="viewFocusAreas[i].visible" title="Visible focus area">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </span>
              </div>

              <!-- Only for stage training nodes - auto-correction results -->
              <ng-container *diAcl="'medical-insights-profile.training'">
                <span
                  *ngIf="note.training && note._meta?.status === 'DONE'"
                  [title]="correctedTrainingFAs[i]?.text"
                  [ngClass]="{
                    'check-mark': correctedTrainingFAs[i]?.title === 'correct',
                    'red-mark': correctedTrainingFAs[i]?.title === 'incorrect',
                    'orange-mark': correctedTrainingFAs[i]?.title === 'missing'
                  }"
                >
                </span>
              </ng-container>
            </div>

            <ng-container
              *ngIf="missingFAsOnTrainingCuration?.length > 0 && note.training && note._meta?.status === 'DONE'"
            >
              <div class="missingFAsList" *diAcl="'medical-insights-profile.training'">
                Training: Following Focus Areas of original curation are missing:
                <ul>
                  <li *ngFor="let item of missingFAsOnTrainingCuration">{{ item | capitalize }}</li>
                </ul>
              </div>
            </ng-container>

            <button class="btn btn-secondary" (click)="onOpenModal()">Add New Focus Area</button>
          </div>
        </details>

        <!-- Job controls -->
        <div *ngIf="hasJobForCurrentUser; else updateControls">
          <div class="pull-left">
            <!-- Unable to Compile -->
            <button class="btn btn-outline-danger" (click)="onJobUtc()">Unable to Compile!</button>
          </div>

          <div class="pull-right">
            <!-- Save as Draft -->
            <button
              class="btn btn-secondary"
              [ngClass]="{ 'btn-loading': isSubmitting }"
              [disabled]="isSubmitting"
              (click)="onJobDraft()"
            >
              Save as Draft
            </button>

            <!-- Submit -->
            <button
              class="btn btn-primary"
              [ngClass]="{ 'btn-loading': isSubmitting }"
              [disabled]="isSubmitting"
              (click)="onSubmitJob()"
            >
              Submit Job
            </button>
          </div>
        </div>

        <!-- Standard controls -->
        <ng-template #updateControls>
          <div class="pull-right">
            <!-- Save -->
            <button
              class="btn btn-primary"
              [ngClass]="{ 'btn-loading': isSubmitting }"
              [disabled]="isSubmitting"
              (click)="onSaveNote()"
            >
              Save Note
            </button>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="col-md-4">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Information -->
        <li ngbNavItem *diAcl="'medical-insights-profile.info.list'">
          <a ngbNavLink>Information</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <dt class="col-sm-6">Last Curation:</dt>
              <dd class="col-sm-6">{{ note.curated?.at | date : 'medium' }}</dd>

              <dt class="col-sm-6 text-truncate">Status:</dt>
              <dd class="col-sm-6">{{ note._meta?.status | capitalize }}</dd>

              <dt class="col-sm-6">Version:</dt>
              <dd class="col-sm-6">{{ note._version || 0 }}</dd>
            </dl>
          </ng-template>
        </li>

        <!-- Job History -->
        <li ngbNavItem *diAcl="'medical-insights-profile.job-history.list'">
          <a ngbNavLink>Job History</a>
          <ng-template ngbNavContent>
            <dirt-job-history [entityId]="note._id"></dirt-job-history>
          </ng-template>
        </li>

        <!-- Audit Log -->
        <li ngbNavItem *diAcl="'medical-insights-profile.audit.list'">
          <a ngbNavLink>Audit Log</a>
          <ng-template ngbNavContent>
            <dirt-audit-log
              [id]="note._id"
              [entityAPI]="service"
              [explodeComparisonKeys]="['selectedTaxonomyInsights', 'selectedFocusAreas']"
            ></dirt-audit-log>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-medical-insight-meta"></div>
    </div>
  </div>
</section>

<ng-template #focusAreaSelectionModal let-close="close">
  <div class="fa-modal">
    <div class="modal-header">
      <h4 class="modal-title pull-left">Search Focus Areas</h4>
      <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
      <dirt-multiselect-dropdown
        name="search-fas"
        [options]="focusAreasFiltered"
        [settings]="focusAreasSettings"
        [(ngModel)]="focusAreasSelected"
        (lazyLoad)="onFilterFocusAreas($event)"
        (added)="onSelectFocusArea($event)"
        (removed)="onDeleteFocusArea($event)"
      >
      </dirt-multiselect-dropdown>
    </div>

    <div class="modal-footer">
      <div class="pull-right">
        <button type="button" class="btn btn-secondary btn-sm" (click)="close()">Close</button>
      </div>
    </div>
  </div>
</ng-template>
