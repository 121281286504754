import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewContainerRef,
  ElementRef,
} from '@angular/core';
import { TrainingMarkerComponent } from '../../components/training-marker/training-marker.component';

@Directive({
  selector: '[dirtTrainingEvaluationMarker]',
})
export class TrainingEvaluationMarkerDirective implements OnInit {
  @Input()
  set dirtTrainingEvaluationMarker(name: string) {
    if (!name) {
      throw new Error('Invalid fieldName for marker directive!');
    }

    this.fieldName = name;
  }

  @Output()
  onMarked = new EventEmitter<any>();

  private fieldName: string;

  private containerComponent: ComponentRef<TrainingMarkerComponent>;

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private el: ElementRef
  ) {}

  ngOnInit() {
    const containerFactory = this.componentFactoryResolver.resolveComponentFactory(TrainingMarkerComponent);
    this.containerComponent = this.viewContainerRef.createComponent(
      containerFactory,
      0,
      this.viewContainerRef.injector
    );

    this.containerComponent.instance.template = this.templateRef;
    this.containerComponent.instance.fieldName = this.fieldName;
  }
}
