import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, PRIMARY_OUTLET, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { StickyFilterService } from './sticky-filter.service';

@Injectable()
export class StickyFilterGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly svcStickyFilter: StickyFilterService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const hasQueryParams = !!Object.keys(next.queryParams).length;
    const urlWithoutParams = this.svcStickyFilter.getPathFromUrl(state.url);
    const routeFilters = this.svcStickyFilter.getState(urlWithoutParams);

    // Restore
    if (routeFilters && !hasQueryParams) {
      return this.router.navigate([urlWithoutParams], {
        queryParams: routeFilters.queryParams,
      });
    }

    return true;
  }
}
