import { Directive, HostListener, forwardRef, Renderer2, ElementRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNil } from 'lodash';

const TRIM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TrimValueAccessorDirective),
  multi: true,
};

const EXTENDED_TRIM_CLASS = 'extended-trim'; // assign to inputs that shall get the extended treatment

/**
 * The trim accessor for writing trimmed value and listening to changes that is
 * used by the {@link NgModel}, {@link FormControlDirective}, and
 * {@link FormControlName} directives.
 */
@Directive({
  selector: `
    input:not([ngbDatepicker]):not([ngbTypeahead]):not([type=checkbox]):not([type=radio]):not([type=password]):not([type=number]):not(.ng-trim-ignore)[formControlName],
    input:not([ngbDatepicker]):not([ngbTypeahead]):not([type=checkbox]):not([type=radio]):not([type=password]):not([type=number]):not(.ng-trim-ignore)[formControl],
    input:not([ngbDatepicker]):not([ngbTypeahead]):not([type=checkbox]):not([type=radio]):not([type=password]):not([type=number]):not(.ng-trim-ignore)[ngModel],
    textarea:not(.ng-trim-ignore)[formControlName],
    textarea:not(.ng-trim-ignore)[formControl],
    textarea:not(.ng-trim-ignore)[ngModel],
    :not([ngbDatepicker]):not([ngbTypeahead]):not(.ng-trim-ignore)[ngDefaultControl]
  `,
  providers: [TRIM_VALUE_ACCESSOR],
})
export class TrimValueAccessorDirective implements ControlValueAccessor {
  // veeva heavily adjusted that - could even give it back to the community!

  onChange = (_: any) => {};

  onTouched = () => {};

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @HostListener('input', ['$event.target.value'])
  ngOnChange = (val: string) => {
    this.onChange(
      this.extendedTrim(
        val,
        this.elementRef.nativeElement?.classList.contains(EXTENDED_TRIM_CLASS),
        this.elementRef.nativeElement.nodeName === 'TEXTAREA'
      )
    );
  };

  @HostListener('drop', ['$event'])
  ngOnDrop = (evnt) => {
    setTimeout(() => {
      const val = evnt.target.value;
      this.writeValue(val);
      this.onTouched();
    });
  };

  @HostListener('blur', ['$event.target.value'])
  ngOnBlur = (val: string) => {
    this.writeValue(val);
    this.onTouched();
  };

  extendedTrim(value: any, extended?: boolean, isTextArea?: boolean): string {
    if (typeof value !== 'string') {
      return value;
    }

    // Get rid of all control characters: https://en.wikipedia.org/wiki/Control_character#In_Unicode
    // and all zero-width-space \\u200b characters
    // Those introduce some subtle differences in strings (rendering unique constraint useless) and are invisible for the users.
    // Some like \u0000 are not supported by Postgres.
    if (isTextArea) {
      // same but don't touch new lines (\u000A)
      value = value.replace(/[\u0000-\u0009\u000B-\u001F\u007F-\u009F\u200B]/g, '');
    } else {
      value = value.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
    }

    if (extended) {
      // Replace specific characters like quotes, non-breaking spaces, and long dashes with regular spaces
      value = value.replace(/["“”]/g, ' ');
      value = value.replace(/ /g, ' '); // (nbsp)
      value = value.replace(/–/g, '-'); // (longdash)
      value = value.replace(/( {2,})/g, ' ');
    }

    // TODO: could also use normalize('NFD') to make sure different version of the same character (visually) don't bypass unique constraints

    return value.trim();
  }

  writeValue(value: any): void {
    value = isNil(value) ? '' : value;
    if (!this.elementRef.nativeElement.disabled && !this.elementRef.nativeElement.readonly) {
      value = this.extendedTrim(
        value,
        this.elementRef.nativeElement.classList.contains(EXTENDED_TRIM_CLASS),
        this.elementRef.nativeElement.nodeName === 'TEXTAREA'
      );
    }
    this.renderer.setProperty(this.elementRef.nativeElement, 'value', value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }
}
