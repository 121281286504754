import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GET,
  PUT,
  Query,
  Path,
  Body,
  MediaType,
  Produces,
  PATCH,
  POST,
} from '../../../../shared/services/rest/rest.service';

import { APIService } from '../../../../shared/services/api/api.service';
import { Page } from '../../../../shared/values/Page';
import { Profile } from '../../../shared/profile';
import { Video, CompilerComment, Source } from '../../../../video/shared/video';
import { Person } from '../../../../person/shared/person';

export interface ProfileVideoUpdateParam {
  video: Video;
  compilerComment: CompilerComment;
}
@Injectable()
export class ProfileVideoAPI extends APIService {
  @GET('profiles/{id}/videos')
  @Produces(MediaType.JSON)
  public find(
    @Path('id') id: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sortedOn') sortedOn?: string,
    @Query('sortedDirection') sortedDirection?: string,
    @Body filter?: any
  ): Observable<Page<any>> {
    return;
  }

  @GET('profiles/{id}/videos/count')
  @Produces(MediaType.JSON)
  public count(@Path('id') id: string, @Query('filter') filter?: any): Observable<{ count: number }> {
    return;
  }

  @GET('profiles/{id}/videos/distinct')
  @Produces(MediaType.JSON)
  public distinct(
    @Path('id') id: string,
    @Query('field') field: string,
    @Query('filter') filter?: string
  ): Observable<string[]> {
    return;
  }

  @GET('profiles/{id}/videos/ids')
  @Produces(MediaType.JSON)
  public fetchProfileVideoIds(
    @Path('id') id: string,
    @Query('source') source?: Source
  ): Observable<Page<{ source: Source; vendorId: string }>> {
    return;
  }

  @PATCH('profiles/{id}/videos/{profileVideoId}')
  @Produces(MediaType.JSON)
  @Produces(MediaType.JSON)
  public update(
    @Path('id') id: string,
    @Path('profileVideoId') profileVideoId: string,
    @Body video: ProfileVideoUpdateParam
  ): Observable<string> {
    return;
  }

  @PUT('profiles/{id}/videos/link')
  @Produces(MediaType.JSON)
  public link(@Path('id') id: string, @Body data: any): Observable<Profile> {
    return;
  }

  @PUT('profiles/{id}/videos/unlink')
  @Produces(MediaType.JSON)
  public unlink(@Path('id') id: string, @Body profileVideoIds: any): Observable<Profile> {
    return;
  }

  @PUT('people/{id}/videos/link')
  @Produces(MediaType.JSON)
  public linkPersonVideo(@Path('id') id: string, @Body data: any): Observable<Person> {
    return;
  }

  @PUT('people/{id}/videos/unlink')
  @Produces(MediaType.JSON)
  public unlinkPersonVideo(@Path('id') id: string, @Body data: any): Observable<Person> {
    return;
  }
}
