import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PersonWithAffiliationModalComponent } from './person-with-affiliation.component';
import { Person } from '../../person';

@Injectable()
export class PersonWithAffiliationModalService {
  constructor(private svcModal: NgbModal) {}

  open(
    cvLinks?: string[],
    createAsOnHold: boolean = false,
    multiCvLink: number = 1,
    defaultProjects?: string[],
    defaultSource?: string,
    disableDisabledCountries?: boolean
  ): Promise<Person> {
    const ref = this.svcModal.open(PersonWithAffiliationModalComponent);
    ref.componentInstance.newPerson.cvLinks = cvLinks || [];
    if (defaultProjects) {
      ref.componentInstance.newPerson.projectNames = defaultProjects;
    } else {
      ref.componentInstance.newPerson.projectNames = [];
    }
    if (defaultSource) {
      ref.componentInstance.newPerson.source = defaultSource;
    }
    ref.componentInstance.newPerson.affiliations = [];
    ref.componentInstance.createAsOnHold = createAsOnHold;
    ref.componentInstance.multiCvLink = multiCvLink;
    ref.componentInstance.disableDisabledCountries = !!disableDisabledCountries;
    return ref.result;
  }
}
