import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { MembershipComponent } from './component';
import { MembershipListComponent } from './list/list.component';
import { MembershipDetailComponent } from './detail/detail.component';
import { MembershipCreateComponent } from './create/create.component';
import { MembershipFormComponent } from './shared/form/form.component';

import { membershipRouting } from './routes';
import { MembershipAPI } from './shared/api.service';

@NgModule({
  imports: [SharedModule, membershipRouting],
  declarations: [
    MembershipComponent,
    MembershipListComponent,
    MembershipDetailComponent,
    MembershipCreateComponent,
    MembershipFormComponent,
  ],
  exports: [MembershipListComponent],
  providers: [MembershipAPI],
})
export class MembershipModule {}
