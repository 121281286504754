export class Job {
  id: string;
  _id: string;
  entityType: string;
  entityId: string;
  extEntityId: string;
  type: string;
  poolId: string;
  priority: number;
  hint?: string;
  findingId?: string;
  qcSessionId?: string;
  _meta: {
    assignee?: string;
    status: string;
  };
  createdAt: Date;
  updatedAt: Date;
  completedAt: Date;
  completedBy: string;
  verifiedAt?: Date;
  verifiedBy?: Date;
  createdBy?: string;
  additionalData: any;
  naturalEntityId?: string;

  constructor() {}
}
