<section class="dirt-section-list dirt-membership-list clearfix">
  <header class="row">
    <div class="col-sm-4">
      <h2>
        <span *ngIf="total">{{ total.count }}</span> Memberships
      </h2>
    </div>
    <div class="col-sm-4">
      <div class="input-group row" *ngIf="isSearchType && !showDuplicates; else simpleSearch">
        <div ngbDropdown class="input-group-btn">
          <button type="button" class="btn btn-secondary" id="searchTypeDropDown" ngbDropdownToggle>
            {{ selectedSearchType | capitalize }}
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button
              type="button"
              class="dropdown-item"
              *ngFor="let searchType of searchTypes | iterable"
              (click)="onSearchTypeChange(searchType.value)"
            >
              {{ searchType.value | capitalize }}
            </button>
          </div>
        </div>
        <input
          type="search"
          class="form-control"
          [formControl]="searchCtrl"
          [(ngModel)]="searchTerm"
          placeholder="Search..."
        />
      </div>
    </div>

    <ng-template #simpleSearch>
      <input
        type="search"
        class="form-control"
        [formControl]="searchCtrl"
        [(ngModel)]="searchTerm"
        placeholder="Search..."
      />
    </ng-template>

    <div class="col-sm-4">
      <!-- Create Button -->
      <ng-template [diAcl]="'membership.create'" [diAclAnd]="!obsolete">
        <button class="btn btn-success btn-sm pull-right" (click)="onCreateClick()" [disabled]="membershipFormOpened">
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Create Membership
        </button>
      </ng-template>
    </div>
  </header>

  <div *ngIf="membershipFormOpened" class="dirt-membership-form">
    <dirt-membership-form
      [membership]="membership"
      [isSubmitting]="isSubmitting"
      [isSaving]="isSaving"
      (onSubmitted)="onSubmit($event)"
      (onSubmittedAndNew)="onSave($event)"
      (onCancel)="onCancelClick()"
    >
    </dirt-membership-form>
  </div>

  <div class="d-flex justify-content-between align-items-baseline">
    <!-- Pagination -->
    <dirt-pagination
      class="dirt-pagination"
      [id]="'dirt-membership-list'"
      [pagingSize]="memberships?.length || 0"
      [pagingSkip]="pagingSkip"
      [pagingLimit]="pagingLimit"
      [pagingTotal]="total?.count || 0"
      [hidden]="pagingLimit >= total?.count"
      (pageChange)="getPage($event)"
    >
    </dirt-pagination>

    <!-- Just need an empty spacer when the pagination is hidden -->
    <span></span>

    <button
      class="btn btn-sm btn-outline-primary"
      [ngClass]="{ active: showDuplicates }"
      (click)="onToggleShowDuplicate()"
    >
      Show Duplicates
    </button>
  </div>

  <div *ngIf="memberships" [hidden]="isLoading">
    <table class="table table-sm table-hover" [hidden]="!memberships.length" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-link"></th>
          <th class="col-state">&nbsp;</th>
          <th class="col-title" [diColSorter]="'association.name'" *ngIf="isSearchType">Title</th>
          <th class="col-first" [diColSorter]="'member.firstName'">First</th>
          <th class="col-middle">Middle</th>
          <th class="col-last" [diColSorter]="'member.lastName'">Last</th>
          <th class="col-nick">Nickname</th>
          <th class="col-suffix">Suffix</th>
          <th class="col-full" [diColSorter]="'member.fullName'">Full</th>
          <th class="col-position" [diColSorter]="'position'">Position</th>
          <th class="col-workplace">Workplace</th>
          <th class="col-ended">
            <input
              (change)="toggleAllEnded($event)"
              type="checkbox"
              [checked]="allEnded"
              [disabled]="!canEditMembership() || isLoading"
            />
            Ended
          </th>
          <th class="col-reviewed">
            <input
              (change)="toggleAllReviewed($event)"
              type="checkbox"
              [checked]="allReviewed"
              [disabled]="!canEditMembership() || isLoading"
            />
            Reviewed
          </th>
          <th class="col-verified" *ngIf="shouldDisplayVerifiedControls">
            <input
              (change)="toggleAllVerified($event)"
              type="checkbox"
              [checked]="allVerified"
              [disabled]="!canEditMembership() || isLoading"
            />
            Verified
          </th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="dirt-membership-row"
          *ngFor="
            let mb of memberships
              | paginate
                : {
                    id: 'dirt-membership-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  }
          "
          [ngClass]="{
            'dirt-selected-item': mb.id === membershipEditId,
            'dirt-flashing-item': flashingId === mb.id,
            'dirt-flashing-edit-item': flashingEditId === mb.id,
            'col-title-expanded': expandedIds.includes(mb.id)
          }"
          (dblclick)="onShowAllText(mb.id)"
        >
          <ng-container *ngIf="membershipEditId !== mb.id">
            <td class="col-link">
              <a [routerLink]="['/membership/detail', mb.id]" rel="noopener" target="_blank"
                ><i class="fa fa-external-link" aria-hidden="true"></i
              ></a>
            </td>
            <td class="col-state">
              <span
                class="state state-stacked state-danger"
                ngbTooltip="Ended / Obsolete"
                placement="right"
                *ngIf="mb.ended || isObsolete(mb.association)"
              ></span>
            </td>
            <td class="col-title" [title]="mb.association.name" *ngIf="isSearchType">
              {{ mb.association.name }}<i class="fa fa-bug fa-inverse" aria-hidden="true" *ngIf="hasQc(mb)"></i>
            </td>
            <td class="col-first" [title]="mb.member.firstName">{{ mb.member.firstName }}</td>
            <td class="col-middle" [title]="mb.member.middleName">{{ mb.member.middleName }}</td>
            <td class="col-last" [title]="mb.member.lastName">{{ mb.member.lastName }}</td>
            <td class="col-nick" [title]="mb.member.nickname">{{ mb.member.nickname }}</td>
            <td class="col-suffix" [title]="mb.member.suffix">{{ mb.member.suffix }}</td>
            <td class="col-full" [title]="mb.member.fullName">{{ mb.member.fullName }}</td>
            <td class="col-position" [title]="mb.position | capitalize">{{ mb.position | capitalize }}</td>
            <td class="col-workplace" [title]="mb.member.workplace">{{ mb.member.workplace }}</td>
            <td class="col-ended" [title]="mb.ended">
              <input
                type="checkbox"
                [disabled]="!canEditMembership() || isObsolete(mb.association)"
                [checked]="mb.ended"
                (change)="onMembershipEndChange(mb, $event)"
              />
            </td>
            <td class="col-ended" [title]="mb.reviewed">
              <input
                type="checkbox"
                [disabled]="!canEditMembership() || isObsolete(mb.association)"
                [checked]="mb.reviewed"
                (change)="onMembershipReviewedChange(mb, $event)"
              />
            </td>
            <td class="col-ended" [title]="mb.verified" *ngIf="shouldDisplayVerifiedControls">
              <input
                type="checkbox"
                [disabled]="!canEditMembership() || isObsolete(mb.association)"
                [checked]="mb.verified"
                (change)="onMembershipVerifiedChange(mb, $event)"
              />
            </td>
            <td class="col-actions">
              <!-- Edit -->
              <span class="action-btn action-edit" (click)="onEditClicked(mb.id, $event)">
                <i class="fa fa-pencil" aria-hidden="true"></i>
              </span>
              <ng-template [diAcl]="'membership.delete'" [diAclAnd]="!isObsolete(mb.association)">
                <!-- Delete -->
                <span class="action-btn action-delete" (click)="onDelete(mb.id, $event)">
                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                </span>
              </ng-template>
            </td>
          </ng-container>
          <ng-container *ngIf="membershipEditId === mb.id">
            <td colspan="12" class="col-edit">
              <dirt-membership-form
                [membership]="mb"
                [isSubmitting]="isSubmitting"
                (onSubmitted)="onEdit($event)"
                (onCancel)="onCancelEditClick()"
              >
              </dirt-membership-form>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <p class="dirt-no-results" *ngIf="!memberships.length">No Memberships</p>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
