import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';

import { ContributionComponent } from './contribution.component';
import { ContributionDetailComponent } from './detail/detail.component';
import { ContributionCreateComponent } from './create/create.component';
import { ContributionModule } from './contribution.module';

export const contributionRoutes: Routes = [
  {
    path: 'contribution',
    component: ContributionComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: 'create/:eventId',
        component: ContributionCreateComponent,
      },
      {
        path: 'detail/:id',
        component: ContributionDetailComponent,
      },
    ],
  },
];

export const contributionRouting: ModuleWithProviders<ContributionModule> = RouterModule.forChild(contributionRoutes);
