export function splitStream(splitOn) {
  let buffer = '';

  return new TransformStream({
    transform(chunk, controller) {
      buffer += chunk;
      const parts = buffer.split(splitOn);
      parts.slice(0, -1).forEach((part) => controller.enqueue(part));
      buffer = parts[parts.length - 1];
    },
    flush(controller) {
      if (buffer) controller.enqueue(buffer);
    },
  });
}

export function parseJSONStream() {
  return new TransformStream({
    transform(chunk, controller) {
      controller.enqueue(JSON.parse(chunk));
    },
  });
}

export function filterStream(handler: (row: unknown) => unknown) {
  return new TransformStream({
    transform(chunk, controller) {
      if (handler(chunk)) {
        controller.enqueue(chunk);
      }
    },
  });
}
