import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ColGroupDef, RowNode } from 'ag-grid';
import { ColDef } from 'ag-grid/main';
import { ValueFormatterParams } from 'ag-grid/dist/lib/entities/colDef';

import { ProfileGridComponent } from '../../../shared/grid/grid.component';
import { ProfileDirectLinkService } from '../../../shared/modal/direct-link/direct-link.service';
import { ProfileDirectLinkOptions } from '../../../shared/modal/direct-link/direct-link-options.interface';
import { Publication } from '../../../../publication/shared/publication';
import { PublicationAPI } from '../../../../publication/shared/publication-api.service';
import { ProfilePublicationAPI } from '../shared/api.service';
import { ACL } from '../../../../shared/acl/acl.service';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { QCCommentService } from '../../../shared/grid/qc-comment/qc-comment.service';
import { ProfilePublication } from '../../../shared/profile-publication';

const AUTHOR_POSITION = ['First Author', 'Last Author', 'Coauthor'];

@Component({
  selector: 'dirt-profile-publication-list',
  templateUrl: '../../../shared/grid/grid.component.html',
  styleUrls: ['../../../shared/grid/grid.component.scss'],
})
export class ProfilePublicationListComponent extends ProfileGridComponent implements OnInit {
  FIELD_AUTHOR_FIRST_NAME = '_profile.author.firstName';
  FIELD_AUTHOR_LAST_NAME = '_profile.author.lastName';
  FIELD_AUTHOR_POSITION = '_profile.author.position';
  FIELD_AUTHOR_AFFILIATION = '_profile.author.affiliation';
  FIELD_FIRST_AUTHOR_AFFILIATION = '_profile.firstAuthor.affiliations';
  FIELD_TITLE = 'title';
  FIELD_JOURNAL_TITLE = 'journal.title';
  FIELD_PUBLICATION_DATE = 'publicationDate';
  FIELD_ABSTRACT = 'abstract.text';
  FIELD_EXTERNAL_PUBMED = '_profile.externalIds.pubmed';

  constructor(
    protected svcProfilePublication: ProfilePublicationAPI,
    private svcPublication: PublicationAPI,
    private svcDirectLink: ProfileDirectLinkService,
    protected readonly svcAcl: ACL,
    protected svcQCComment: QCCommentService
  ) {
    super('id', svcAcl, svcQCComment);
  }

  ngOnInit() {
    this.addFilter(this.FIELD_AUTHOR_POSITION, true, 'keep', true, AUTHOR_POSITION);
    this.addFilter(this.FIELD_AUTHOR_FIRST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_AUTHOR_FIRST_NAME, params)
    );
    this.addFilter(this.FIELD_AUTHOR_LAST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_AUTHOR_LAST_NAME, params)
    );
    this.addFilter(this.FIELD_PUBLICATION_DATE, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_PUBLICATION_DATE, params)
    );
    this.addFilter(this.FIELD_KEY_FA, true, 'keep', false, (params) =>
      this.defineGetFocusArea().subscribe((result) =>
        result && result[0] && result[0].focusArea
          ? params.success(result[0].focusArea?.map((val) => val.name))
          : params.success('')
      )
    );
    super.ngOnInit();
  }

  protected defineDistinct(profileId: string, field: string, filter: any): Observable<string[]> {
    return this.svcProfilePublication.distinct(this.profile, field, filter);
  }

  protected defineUpdate(
    profileId: string,
    ids: string[],
    value: ProfilePublication[]
  ): Observable<{ id; compiledAt? }[]> {
    return this.svcProfilePublication.update(this.profile, value);
  }

  protected defineGetDataRows(...params): Observable<any> {
    return this.svcProfilePublication.find.apply(this.svcProfilePublication, [this.profile, ...params.slice(1)]); // migrating profileId to profile
  }

  protected defineGetFocusArea(): Observable<any> {
    return this.svcProfilePublication.distinctFocusArea(this.profile);
  }

  protected defineGrid(): (ColDef | ColGroupDef)[] {
    const columnDefs: (ColDef | ColGroupDef)[] = [
      {
        headerName: 'Author',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'First Name',
            field: this.FIELD_AUTHOR_FIRST_NAME,
            filterParams: this.filters.get(this.FIELD_AUTHOR_FIRST_NAME),
          },
          {
            headerName: 'Last Name',
            field: this.FIELD_AUTHOR_LAST_NAME,
            filterParams: this.filters.get(this.FIELD_AUTHOR_LAST_NAME),
          },
          {
            headerName: 'Position',
            field: this.FIELD_AUTHOR_POSITION,
            columnGroupShow: 'open',
            filterParams: this.filters.get(this.FIELD_AUTHOR_POSITION),
            width: 80,
          },
          {
            headerName: 'Affiliation',
            field: this.FIELD_AUTHOR_AFFILIATION,
            filter: 'text',
            width: 300,
          },
        ],
      },
      {
        headerName: '1st Author Affiliation',
        field: this.FIELD_FIRST_AUTHOR_AFFILIATION,
        filter: 'text',
        width: 450,
        cellRenderer: this.cellRendererTooltip,
      },
      {
        headerName: 'Link',
        cellRenderer: this.rendererLinks,
        field: this.FIELD_EXTERNAL_PUBMED,
        suppressMenu: true,
        width: 50,
      },
      {
        headerName: 'Publication',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'Title',
            field: this.FIELD_TITLE,
            filter: 'text',
            width: 550,
            cellRenderer: this.cellRendererTooltip,
          },
          {
            headerName: 'Journal Title',
            field: this.FIELD_JOURNAL_TITLE,
            filter: 'text',
            width: 250,
          },
          {
            headerName: 'Date',
            field: this.FIELD_PUBLICATION_DATE,
            filterParams: this.filters.get(this.FIELD_PUBLICATION_DATE),
            width: 50,
            columnGroupShow: 'open',
          },
          {
            headerName: 'Abstract',
            field: this.FIELD_ABSTRACT,
            filter: 'text',
            columnGroupShow: 'open',
          },
        ],
      },
      {
        headerName: 'Focus Areas',
        field: this.FIELD_KEY_FA,
        filterParams: this.filters.get(this.FIELD_KEY_FA),
        width: 550,
      },
    ];

    return columnDefs;
  }

  protected defineAutoSizableColumns(): string[] {
    return [
      this.FIELD_AUTHOR_FIRST_NAME, // First Name
      this.FIELD_AUTHOR_LAST_NAME, // Last Name
      this.FIELD_EXTERNAL_PUBMED, // Links,
    ];
  }

  protected onDirectLink(): void {
    let selectedPosition;

    const options = <ProfileDirectLinkOptions>{
      title: 'Publication',
      positionSet: ['First Author', 'Last Author', 'Coauthor'],
      setPosition: (position) => (selectedPosition = position),
      getEntity: (id) => this.svcPublication.findById(id),
      getData: (p: Publication) => ({ name: p.title }),
    };

    this.svcDirectLink
      .open(options)
      .then((entity: Publication) =>
        this.svcProfilePublication
          .link(this.profile, entity.externalIds.pubmed, { position: selectedPosition })
          .toPromise()
      )
      .then(() => this.reloadData());
  }

  /**
   * Renderer function for Links columns.
   *
   * @param {ValueFormatterParams} param
   * @return {string}
   */
  rendererLinks(param: ValueFormatterParams): string {
    if (!param.data) {
      return '';
    }

    const pmid = param.data.externalIds.pubmed;
    const doi = param.data.externalIds.doi;
    const jamas = param.data.externalIds.jamas;

    const links = [];

    if (pmid && !jamas) {
      // When we have a JAMAS ID, PubMed ID is a fake one
      links.push(
        `<a href='https://www.ncbi.nlm.nih.gov/pubmed/${pmid}' target='_blank' rel='noopener noreferrer'>P</a>`
      );
    }

    if (doi) {
      links.push(`<a href='https://doi.org//${doi}' target='_blank'  rel='noopener noreferrer'>F</a>`);
    }

    if (jamas) {
      links.push(
        `<a href='https://search.jamas.or.jp/link/ui/${jamas}' target='_blank' rel='noopener noreferrer'>J</a>`
      );
    }

    return links.join(' | ');
  }

  /** @override */
  updateCommentAndProfileMatch(comment: string, matched: boolean, errorType: string): void {
    const nodes = this.getSelection();
    const match = matched ? ProfileMatchManual.MATCH : ProfileMatchManual.MISMATCH;

    nodes.forEach((node: RowNode) => {
      const data = Object.assign({}, node.data); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables

      data._profile.match.manual = match;

      // even if comment and errorType are null we should set qc so that thebug icon disappears
      data._profile.qc = { comment, errorType };

      node.setData(data);
    });

    this.update(
      nodes,
      nodes.map(
        (n) =>
          ({
            author: n.data._profile.author,
            pmid: n.data._profile.pmid,
            match: n.data._profile.match,
            person: { id: this.profile.person.id, kolId: this.profile.person.kolId },
            profileId: this.profileId,
            qc: n.data._profile.qc,
          } as Partial<ProfilePublication>)
      )
    );
  }
}
