<section class="dirt-section-create dirt-user-create">
  <a class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
    <span class="fa fa-arrow-left" aria-hidden="true"></span>
  </a>

  <h1 class="bounceInRight">Create User</h1>

  <br />

  <dirt-user-form [user]="user" #frmUser="frmUser"></dirt-user-form>

  <div class="pull-right">
    <button
      type="submit"
      class="btn btn-primary"
      [ngClass]="{ 'btn-loading': isSubmitting }"
      [disabled]="!frmUser.isValid()"
      (click)="onSubmit(frmUser.getValue())"
      *diAcl="'user.create'"
    >
      <span>Submit</span>
    </button>
  </div>
</section>
