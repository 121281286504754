import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { VideoProfileModalComponent } from './shared/modal/profile/profile.component';
import { VideoProfileModalService } from './shared/modal/profile/profile.service';

import { VideoAPI } from './shared/video-api.service';
import { VideoJobComponent } from './video-job/video-job.component';
import { VideoComponent } from './video.component';
import { videoRouting } from './video.route';

@NgModule({
  imports: [SharedModule, videoRouting],
  declarations: [VideoComponent, VideoJobComponent, VideoProfileModalComponent],
  providers: [VideoAPI, VideoProfileModalService],
  exports: [VideoJobComponent],
  entryComponents: [VideoProfileModalComponent],
})
export class VideoModule {}
