import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Contribution } from '../shared/contribution';
import { ContributionAPI } from '../shared/contribution-api.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'dirt-contribution-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.scss'],
})
export class ContributionDetailComponent implements OnInit, OnDestroy {
  id: string;
  contribution: Contribution;
  sub: any;
  isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: ContributionAPI,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.getContribution(this.id);
    });
  }

  getContribution(id: string) {
    this.service.findById(this.id).subscribe((response) => {
      this.contribution = response;
      this.titleService.setTitle(`cLean | Contribution | ${this.contribution.title}`);
    });
  }

  onSubmit(contribution: Contribution): void {
    this.isSubmitting = true;
    this.service.upsert(contribution).subscribe(
      () => {
        this.isSubmitting = false;
        this.navigateTo(['/event/detail', this.contribution.event['id']]);
      },
      () => (this.isSubmitting = false)
    );
  }

  navigateTo(link): void {
    this.router.navigate(link);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
