import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  Observable,
  of,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';

import { DocumentConnection } from '../connection';

@Component({
  selector: 'dirt-document-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss'],
  exportAs: 'documentConnectionsComponent',
})
export class DocumentConnectionsComponent implements AfterViewInit, OnDestroy {
  @Input()
  connections: DocumentConnection[] = [];

  @Input()
  disabled: boolean;

  @Input()
  searchFunction: (term: string) => Observable<DocumentConnection[]>;

  @Input()
  connectionRequestHandler?: (connection?: DocumentConnection) => Observable<DocumentConnection>;

  @Output()
  validityChange: EventEmitter<'VALID' | 'INVALID'> = new EventEmitter();

  isSearching = false;

  searchTerm: string;

  isCollapsed: Map<string, boolean> = new Map();

  @ViewChild(NgbTypeahead)
  private searchAutocomplete: NgbTypeahead;

  @ViewChild('ngForm')
  private ngForm: NgForm;

  private destroy$: Subject<boolean> = new Subject();

  private wndw: Window = window; // allow for testing

  constructor() {
    this.onSearchConnection = this.onSearchConnection.bind(this);
  }

  ngAfterViewInit(): void {
    this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID'); // check validity on init

    this.ngForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
      this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  trackById(_: number, connection: DocumentConnection): string {
    return connection.connectionId;
  }

  onSearchConnection(term$: Observable<string>): Observable<DocumentConnection[]> {
    return term$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }

        return this.searchFunction(term).pipe(catchError(() => of([])));
      }),
      tap(() => (this.isSearching = false))
    );
  }

  onSelectConnection(event: any): void {
    event.preventDefault(); // prevent setting model to [object Object]

    this.searchTerm = '';
    this.searchAutocomplete.dismissPopup();

    if (this.connections.find((c) => c.connectionId === event.item.connectionId)) {
      // already added
      return;
    }

    this.connections.push(event.item);
    this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
  }

  onRemoveConnection(id: string): void {
    if (!this.wndw.confirm('Sure to remove the connection?')) {
      return;
    }

    const idx = this.connections.findIndex((c) => c.connectionId === id);
    if (idx < 0) {
      return;
    }

    this.connections.splice(idx, 1);
    this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
  }

  onToggleCollapse(id: string): void {
    this.isCollapsed.set(id, !this.isCollapsed.get(id));
  }

  onRequestConnection(connection?: DocumentConnection): void {
    if (this.disabled || !this.connectionRequestHandler || (connection && !connection.readyForDelivery)) {
      return;
    }

    this.connectionRequestHandler(connection)
      .pipe(take(1))
      .subscribe((connection) => {
        if (this.connections.find((c) => c.connectionId === connection.connectionId)) {
          // already added
          return;
        }

        this.connections.push(connection);
        this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
      });
  }

  private isValid(): boolean {
    return this.ngForm.disabled || this.ngForm.valid;
  }
}
