import { Component, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { get as getByPath, cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';

import { GuidelineAuthor } from '../shared/guideline-author';
import { ACL } from '../../shared/acl/acl.service';
import { GuidelineAPI } from '../../guideline/shared/guideline-api.service';
import { Guideline } from '../../guideline/shared/guideline';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { GuidelineAuthorFormComponent } from '../shared/form/form.component';

@Component({
  selector: 'dirt-guideline-author-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class GuidelineAuthorListComponent implements OnInit, OnDestroy {
  @Input()
  guidelineID: string;

  @Input()
  authors: GuidelineAuthor[];

  @Input()
  readonly: boolean = false;

  displayedAuthors: GuidelineAuthor[];

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 30;
  pagingSkip = 0;

  searchTerm: string;
  searchCtrl: FormControl = new FormControl('');

  isLoading: boolean;
  isFormOpened: boolean;
  isSubmitting: boolean;
  isFormValid: boolean;

  selectedAuthorship: GuidelineAuthor;

  showDuplicates = false;

  @ViewChild('frmAuthorship', { static: false })
  private frmAuthorshipComponent: GuidelineAuthorFormComponent;

  private sort: string;

  private isSearchHandlerAttached = false;

  private destroy$ = new Subject<boolean>();

  private countries: Map<string, string> = new Map();

  constructor(private svcAcl: ACL, private svcGuideline: GuidelineAPI, private svcValue: ValueAPI) {}

  ngOnInit(): void {
    this.getPage(1);
    this.attachSearchHandler();

    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((values) => {
      this.countries = new Map(values.map((value) => [value.code as string, value.title]));
      this.authors = this.getAuthorsWithCountry(this.authors);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.onSort(this.sort);
  }

  onSort(field: string): void {
    this.sort = field;
    const offset = (this.pagingPage - 1) * this.pagingLimit;
    const order = field?.substring(0, 1) === '-' ? -1 : 1;
    const key = field?.substring(1);

    let authors = this.authors;
    if (this.searchTerm) {
      // get what's matching our search term
      authors = authors.filter(
        (author) =>
          author.firstName?.toLowerCase().includes(this.searchTerm) ||
          author.middleName?.toLowerCase().includes(this.searchTerm) ||
          author.lastName?.toLowerCase().includes(this.searchTerm)
      );
    }

    if (this.sort) {
      // only sort when we got a sort key, otherwise returns as received
      authors = authors.sort((a, b) => {
        const valueA = getByPath(a, key);
        const valueB = getByPath(b, key);

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return order === 1 ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        }

        return valueA > valueB ? order : -order;
      });
    }

    this.displayedAuthors = authors.slice(offset, offset + this.pagingLimit);
  }

  onCreateClick(): void {
    if (this.isFormOpened || !this.svcAcl.hasCredential('guideline.create.author')) {
      return;
    }
    this.selectedAuthorship = new GuidelineAuthor();
    this.isFormOpened = true;
  }

  onEditClicked(author: GuidelineAuthor): void {
    if (this.isFormOpened || !this.svcAcl.hasCredential('guideline.update.author')) {
      return;
    }
    this.selectedAuthorship = cloneDeep(author); // break ref to avoid mutating original data
    this.isFormOpened = true;
  }

  onDeleteClicked(id: string): void {
    if (!this.svcAcl.hasCredential('guideline.delete.author')) {
      return;
    }

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.isLoading = true;

    this.authors = this.authors.filter((author) => author._id !== id);
    this.svcGuideline.update(this.guidelineID, { authors: this.authors } as Guideline).subscribe(() => {
      this.isLoading = false;
      this.resetPagination();
      this.getPage(1);
    });
  }

  onCancel(): void {
    this.isFormOpened = false;
    this.selectedAuthorship = null;
  }

  onSave(authorship: GuidelineAuthor, nonFormPartialUpdate?: boolean): void {
    if (authorship._id && !this.svcAcl.hasCredential('guideline.update.author')) {
      return;
    }

    if (!authorship._id && !this.svcAcl.hasCredential('guideline.create.author')) {
      return;
    }

    if (!this.isFormValid && !nonFormPartialUpdate) {
      return;
    }

    this.isSubmitting = true;

    delete (authorship as any).displayedCountry;

    if (authorship._id) {
      // try to update the resource
      const updatedAuthorIndex = this.authors.findIndex((author) => author._id === authorship._id);
      if (updatedAuthorIndex >= 0) {
        this.authors[updatedAuthorIndex] = authorship;
      }
    } else {
      // A newly created resource shouldn't have an ID
      this.authors.push(authorship);
    }

    this.svcGuideline.update(this.guidelineID, { authors: this.authors } as Guideline).subscribe(({ authors }) => {
      this.authors = this.getAuthorsWithCountry(authors);

      this.isSubmitting = false;
      this.isFormOpened = false;
      this.resetPagination();
      this.getPage(1);

      this.selectedAuthorship = null;
    });
  }

  async onSaveAndNew(authorship: GuidelineAuthor): Promise<void> {
    if (!this.isFormValid || !this.svcAcl.hasCredential('guideline.create.author')) {
      return;
    }

    this.isSubmitting = true;
    this.authors.push(authorship);

    this.svcGuideline.update(this.guidelineID, { authors: this.authors } as Guideline).subscribe(({ authors }) => {
      this.authors = this.getAuthorsWithCountry(authors);

      this.isSubmitting = false;
      this.resetPagination();
      this.getPage(1);

      this.selectedAuthorship = new GuidelineAuthor();
      this.selectedAuthorship.position = authorship.position;

      this.frmAuthorshipComponent.enableOriginalNamesSection = false;
    });
  }

  onFormValidityChange(valid: boolean): void {
    setTimeout(() => {
      this.isFormValid = valid;
    }); // crapy change detection...
  }

  hasFullName(): boolean {
    return !!this.displayedAuthors.find((author) => !!author.originalFullName);
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private attachSearchHandler(): void {
    if (this.isSearchHandlerAttached) {
      // don't attach twice - mistake happens
      return;
    }

    this.isSearchHandlerAttached = true;

    this.searchCtrl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(400), takeUntil(this.destroy$))
      .subscribe((value) => {
        if (typeof value === 'undefined') return;

        this.searchTerm = value.trim().toLowerCase();
        this.resetPagination();
        this.getPage(1);
      });
  }

  private getAuthorsWithCountry(authors: GuidelineAuthor[]): GuidelineAuthor[] {
    return authors.map((author) => {
      (author as any).displayedCountry = this.countries.get(author.country) || author.country;
      return author;
    });
  }

  canVerifyAuthor() {
    return this.svcAcl.hasCredential('guideline.qc');
  }

  onAuthorVerifiedChange(author: GuidelineAuthor, $event: any) {
    $event.stopPropagation();
    author.verified = $event.target.checked;
    this.onSave(author, true);
  }

  onToggleShowDuplicate(): void {
    this.showDuplicates = !this.showDuplicates;

    if (!this.showDuplicates) {
      // Reset list
      this.getPage(1);
      return;
    }

    const offset = (this.pagingPage - 1) * this.pagingLimit;
    const order = this.sort?.substring(0, 1) === '-' ? -1 : 1;
    const key = this.sort?.substring(1);

    let authors = this.authors;
    if (this.searchTerm) {
      // get what's matching our search term
      authors = authors.filter(
        (author) =>
          author.firstName?.toLowerCase().includes(this.searchTerm) ||
          author.middleName?.toLowerCase().includes(this.searchTerm) ||
          author.lastName?.toLowerCase().includes(this.searchTerm)
      );
    }

    authors = this.getDuplicates(authors);

    if (this.sort) {
      // only sort when we got a sort key, otherwise returns as received
      authors = authors.sort((a, b) => {
        const valueA = getByPath(a, key);
        const valueB = getByPath(b, key);

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return order === 1 ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        }

        return valueA > valueB ? order : -order;
      });
    }

    this.displayedAuthors = authors.slice(offset, offset + this.pagingLimit);
  }

  hasDuplicates(): boolean {
    return this.getDuplicates(this.authors).length > 0;
  }

  private getDuplicates(authors: GuidelineAuthor[]): GuidelineAuthor[] {
    const seen = new Set();

    return authors.reduce((acc, a, i, originalAuthors) => {
      const key =
        a.firstName?.toLowerCase()?.trim() +
        '-' +
        a.middleName?.toLowerCase()?.trim() +
        '-' +
        a.lastName?.toLowerCase()?.trim() +
        '-' +
        a.position;
      if (seen.has(key)) {
        return acc;
      }

      seen.add(key);

      const matches = originalAuthors
        .slice(i + 1)
        .filter(
          (b) =>
            b.firstName?.toLowerCase()?.trim() +
              '-' +
              b.middleName?.toLowerCase()?.trim() +
              '-' +
              b.lastName?.toLowerCase()?.trim() +
              '-' +
              b.position ===
            key
        );
      if (matches.length > 0) {
        acc.push(a, ...matches);
      }

      return acc;
    }, []);
  }
}
