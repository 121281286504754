import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { User } from '../shared/user';
import { Roles } from '../../shared/acl/roles';
import { UserAPI } from '../shared/api.service';
import { UserFormComponent } from '../shared/form/form.component';

@Component({
  selector: 'dirt-user-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.scss'],
})
export class UserDetailComponent implements OnInit, OnDestroy {
  id: string;
  user: User;
  sub: any;
  isSubmitting = false;
  Roles = Roles;

  @ViewChild(UserFormComponent)
  userForm: UserFormComponent;

  constructor(private route: ActivatedRoute, private router: Router, private svcUser: UserAPI) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.getUser();
    });
  }

  getUser() {
    this.svcUser.findById(this.id).subscribe((resp) => (this.user = resp));
  }

  onSave(user: User): void {
    if (!this.userForm.checkCubeUs()) {
      return;
    }
    this.isSubmitting = true;
    this.svcUser.upsert(this.id, user).subscribe(() => {
      this.isSubmitting = false;
      this.goBack();
    });
  }

  goBack(): void {
    this.router.navigate(['/user']);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
