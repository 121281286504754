import { Component, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Job } from '../job';
import { JobFormComponent } from '../form/form.component';
import { Roles } from '../../../shared/acl/roles';

export interface JobType {
  key: string;
  value: string;
  roles: Roles[];
}

@Component({
  selector: 'dirt-job-modal',
  templateUrl: './modal.component.html',
})
export class JobsModalComponent {
  @Input()
  jobTypes: JobType[];

  @ViewChild(JobFormComponent, { static: false })
  jobForm: JobFormComponent;

  model: Job = { _meta: {}, priority: 1 } as any;

  constructor(public activeModal: NgbActiveModal) {}

  onSave(): void {
    if (!this.jobForm.isValid()) {
      return;
    }
    this.model.poolId = typeof this.model.poolId !== 'string' ? (this.model.poolId as any).id : this.model.poolId;
    this.activeModal.close(this.model);
  }
}
