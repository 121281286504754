import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { QCCommentComponent } from './qc-comment.component';

@Injectable()
export class QCCommentService {
  constructor(private svcModal: NgbModal) {}

  open(comment?: string, selectedError?: string): Promise<any> {
    const ref = this.svcModal.open(QCCommentComponent, {
      backdrop: 'static',
      keyboard: false,
    });

    ref.componentInstance.comment = comment ? comment : '';
    ref.componentInstance.selectedError = selectedError ? selectedError : '';

    return ref.result;
  }
}
