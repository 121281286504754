import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AffiliationAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-affiliation-next',
  templateUrl: 'next.component.html',
  styleUrls: ['next.component.scss'],
})
export class AffiliationNextComponent {
  queue: any;
  isLoadingNext = false;

  constructor(private router: Router, private svcAffiliation: AffiliationAPI) {}

  getNext(): void {
    this.isLoadingNext = true;

    this.svcAffiliation.findNext().subscribe(
      (affiliation) => {
        if (!affiliation || !affiliation.id) {
          return alert('No Affiliation to be processed at the moment! Go and get some rest ;)');
        }

        this.router.navigate(['/affiliation/detail', affiliation.id]);
      },
      () => alert('Error!'),
      () => (this.isLoadingNext = false)
    );
  }
}
