import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AssociationAPI } from '../shared/association-api.service';
import { ACL } from '../../shared/acl/acl.service';

interface Stat {
  user_id: string;
  name: string;
  completed: number;
  total: number;
  needed: number;
  percent: number;
  remaining: number;
  completedReadyForCompilation?: number;
  completedDone?: number;
}

@Component({
  selector: 'dirt-association-qc-next',
  templateUrl: './qc-next.component.html',
  styleUrls: ['./qc-next.component.scss'],
})
export class AssociationQcNextComponent implements OnInit {
  @Input('isMultipleActivityReviewer')
  isMultipleActivityReviewer = false;

  queue: any;
  regionPoolStats: any;
  isLoadingNext: boolean = false;
  exceedCap: boolean = false;
  expanded: boolean = true;
  all: Stat;

  isIdQC: boolean;
  associationPools = [
    {
      id: 'apacPool',
      name: 'APAC I&II&V',
    },
    {
      id: 'row',
      name: 'ROW',
    },
  ];

  constructor(private router: Router, private svcAcl: ACL, private svcAssociation: AssociationAPI) {}

  ngOnInit() {
    this.isIdQC = this.svcAcl.hasCredential('association.idqc');
    this.getQueue();
    if (!this.isIdQC) {
      this.getQCPoolStats();
    }
  }

  getQueue() {
    this.svcAssociation[this.isIdQC ? 'idQcQueue' : 'qcQueue']().subscribe((q) => {
      this.queue = q.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
      if (this.queue.length >= 0) {
        this.setAggregatedStats();
      }
    });
  }

  getNext(): void {
    this.isLoadingNext = true;

    this.svcAssociation[this.isIdQC ? 'findIDQCNext' : 'findQcNext'](this.exceedCap).subscribe(
      (entityId) => {
        if (!entityId) {
          return alert('No Association to be reviewed at the moment! Go and get some rest ;)');
        }

        this.router.navigate(['/association/detail', entityId]);
      },
      (err) => alert('Error!'),
      () => (this.isLoadingNext = false)
    );
  }

  toggleExpanded(): void {
    this.expanded = !this.expanded;
  }

  isExpanded(): boolean {
    return this.expanded;
  }

  setAggregatedStats() {
    if (!this.queue.length) {
      return;
    }
    const aggregation = this.queue
      .map(({ completed = 0, total = 0, needed = 0, remaining = 0 }) => ({ completed, total, needed, remaining }))
      .reduce((prev, current) => {
        return {
          completed: prev.completed + current.completed,
          total: prev.total + current.total,
          needed: prev.needed + current.needed,
          remaining: prev.remaining + current.remaining,
        };
      });
    aggregation.percent = aggregation.total !== 0 ? Math.ceil((aggregation.completed * 100) / aggregation.total) : 0;
    this.all = aggregation;
  }

  getQCPoolStats() {
    this.svcAssociation.qcPoolStats().subscribe((q) => {
      this.regionPoolStats = q;
    });
  }

  getRegionDisplayName(id: string) {
    return this.associationPools.find((item) => item.id === id)?.name;
  }
}
