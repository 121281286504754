<div class="modal-header">
  <h5 class="modal-title">Merge Direction</h5>
</div>

<div class="modal-body">
  <div>
    <input
      type="radio"
      name="mergeDirection"
      id="left-to-right"
      value="leftToRight"
      [disabled]="isWorking"
      [(ngModel)]="mergeDirection"
    />
    <label for="left-to-right">Merge left into right</label>
  </div>

  <div>
    <input
      type="radio"
      name="mergeDirection"
      id="right-to-left"
      value="rightToLeft"
      [disabled]="isWorking"
      [(ngModel)]="mergeDirection"
    />
    <label for="right-to-left">Merge right into left</label>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-outline-dark" [disabled]="isWorking" (click)="onClose()">Close</button>
  <button
    class="btn btn-sm btn-secondary btn-warning"
    [ngClass]="{ 'btn-loading': isWorking }"
    [disabled]="isWorking"
    (click)="onSubmit()"
  >
    Merge &amp; Delete
  </button>
</div>
