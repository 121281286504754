<section class="dirt-profile-summary" *ngIf="!isLoading">
  <dl class="row" *ngIf="profile">
    <dt class="col-sm-4">Status</dt>
    <dd class="col-sm-8">
      <span>{{ profile._meta.status | capitalize }}</span>
      <a [routerLink]="['/profile/detail', profile.id]" rel="noopener" target="_blank">
        <i class="fa fa-external-link" aria-hidden="true"></i>
      </a>
    </dd>

    <dt class="col-sm-4">Last polished</dt>
    <dd class="col-sm-8">
      <span [hidden]="!profile.polishedAt">{{ profile.polishedAt | date : 'dd.MM.y' }}</span>
      <span [hidden]="profile.polishedAt">&mdash;</span>
    </dd>

    <dt class="col-sm-4">Mode</dt>
    <dd class="col-sm-8"><dirt-profile-view-mode [profile]="profile"></dirt-profile-view-mode></dd>

    <dt class="col-sm-4">Priority</dt>
    <dd class="col-sm-8"><dirt-profile-priority [profile]="profile"></dirt-profile-priority></dd>
  </dl>

  <p class="text-center mt-3" *ngIf="!profile">No Profile</p>
</section>

<!-- Loading Mask -->
<div class="di-loading-mask" *ngIf="isLoading">
  <div class="text-center w-100">
    <i class="fa fa-circle-o-notch fa-spin fa-fw"></i>
    <span>Loading...</span>
  </div>
</div>
