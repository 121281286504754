import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dirt-time-input',
  templateUrl: 'input-time.component.html',
})
export class InputTimeComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input()
  value: { hour: number; minute: number };

  @Input()
  name: string;

  @Input()
  disabled?: boolean;

  @Input()
  required?: boolean;

  @Input()
  form: NgForm;

  @Output()
  valueChange: EventEmitter<{ hour: number; minute: number }> = new EventEmitter();

  displayValue: NgbTimeStruct;

  @ViewChild(NgModel, { static: false })
  private ngControl: NgModel;

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['value'] || !this.value) {
      this.displayValue = null;
      return;
    }

    this.displayValue = {
      hour: this.value.hour,
      minute: this.value.minute,
      second: 0,
    };
  }

  /**
   * Register control in parent form so that the input validity is checked when
   * checking form validity.
   */
  ngAfterViewInit(): void {
    this.form.addControl(this.ngControl);
  }

  /**
   * Form could outlive control so unregister control on destroy.
   */
  ngOnDestroy(): void {
    this.form.removeControl(this.ngControl);
  }

  onValueChange(value: NgbTimeStruct): void {
    if (this.disabled) {
      return;
    }

    // if hour or minute is not set, the value will be null
    if (!value) {
      this.valueChange.emit(null);
      return;
    }

    // Send it back as a Time object
    this.valueChange.emit({ hour: value.hour, minute: value.minute });
  }
}
