import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AccountPersonListComponent } from './list/list.component';
import { AccountPersonAPI } from './shared/account-person-api.service';
import { AccountPersonModalComponent } from './modal/person-modal.component';
import { AccountPersonModalService } from './modal/person-modal.service';

@NgModule({
  imports: [SharedModule],
  declarations: [AccountPersonListComponent, AccountPersonModalComponent],
  exports: [AccountPersonListComponent],
  providers: [AccountPersonModalService, AccountPersonAPI],
})
export class AccountPersonModule {}
