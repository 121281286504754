<ng-template [diAcl]="'profile.ml'" [diAclAnd]="!!params.data?._profile?.match?.mlExclude">
  <div>
    <i class="fa fa-minus-circle di-ml-indicator" aria-hidden="true"></i>
  </div>
</ng-template>

<ng-template [diAcl]="'profile.training'" [diAclOr]="params && params.showProfileDPTrainingReport()">
  <ng-template #trainerPopoverTitle>
    <button
      *ngIf="report && !report.isCorrect && !report.markAsCorrect"
      type="submit"
      class="btn btn-sm"
      (click)="updateReport(true)"
    >
      Set as correct
    </button>
    <button
      *ngIf="report && !report.isCorrect && report.markAsCorrect"
      type="submit"
      class="btn btn-sm"
      (click)="updateReport(false)"
    >
      Set as error
    </button>
  </ng-template>

  <div
    [ngbPopover]="trainerPopOverContent"
    triggers="manual"
    [popoverTitle]="trainerPopoverTitle"
    [autoClose]="'outside'"
    #trainingPopover="ngbPopover"
    placement="bottom-left"
    container="body"
  >
    {{ formatValue(params) }}
    <i
      *ngIf="report && !report.isCorrect"
      (click)="openPopOver($event)"
      [ngClass]="{ 'di-incorrect': report && !report.markAsCorrect, 'di-correct': report && report.markAsCorrect }"
      class="fa fa-bug di-qc-indicator"
      aria-hidden="true"
    ></i>
    <i *ngIf="report && report.isCorrect" class="fa di-correct fa-check-circle di-qc-indicator" aria-hidden="true"></i>
  </div>
</ng-template>

<ng-template [diAcl]="'!profile.qc'" [diAclOr]="!params.data?._profile?.qc?.comment">
  <div>{{ formatValue(params) }}</div>
</ng-template>

<ng-template [diAcl]="'profile.qc'" [diAclAnd]="params.data?._profile?.qc?.comment">
  <ng-template #popoverTitle>
    <i class="fa fa-bug text-danger" aria-hidden="true"></i>
    <span>QC Comment</span>
  </ng-template>

  <div
    [ngbPopover]="popOverContent"
    triggers="click"
    [popoverTitle]="popoverTitle"
    [autoClose]="'outside'"
    placement="bottom-left"
    container="body"
  >
    {{ formatValue(params) }}
    <i class="fa fa-bug di-qc-indicator" aria-hidden="true"></i>
  </div>
</ng-template>

<ng-template #popOverContent>
  <div [innerHTML]="params.data?._profile?.qc?.comment | linky"></div>
  <div [innerHTML]="params.data?._profile?.qc?.errorType | linky"></div>
  <i (click)="openEditAlertBox(QCCommentEditPopOver)" class="fa fa-edit" aria-hidden="true"></i>
  <i (click)="deleteQCComment()" class="fa fa-trash-o" aria-hidden="true"></i>
</ng-template>

<ng-template #trainerPopOverContent></ng-template>

<ng-template #QCCommentEditPopOver let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Edit QC comment</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group row">
        <div class="col-sm-8 col-md-10">
          <input type="text" id="qcComment" class="form-control" name="qcComment" [(ngModel)]="comment" />
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="saveEditedComments(modal)">Save</button>
    <button type="button" class="btn btn-secondary btn-sm" (click)="modal.dismiss()">Cancel</button>
  </div>
</ng-template>
