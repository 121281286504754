import { Component, OnInit, Input } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { tap, take } from 'rxjs/operators';

import { Account } from '../shared/account';
import { AccountAPI } from '../shared/account-api.service';
import { ACL } from '../../shared/acl/acl.service';

@Component({
  selector: 'dirt-account-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class AccountCreateComponent implements OnInit {
  account: Account = new Account();

  isSubmitting = false;

  isFormValid = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcAccount: AccountAPI,
    private svcACL: ACL
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      this.account.parent = params['parentId'];
    });
  }

  goBack(): void {
    const link = this.account.parent ? ['/account/detail', this.account.parent] : ['/account'];
    this.router.navigate(link);
  }

  async onSubmit(account: Account): Promise<void> {
    if (!this.svcACL.hasCredential('account.create') || !account || !this.isFormValid) {
      return;
    }

    const newAcc = await this.saveAccount(account);

    // wait for ES to include the result, avoid confusion
    this.isSubmitting = true;
    try {
      await firstValueFrom(this.svcAccount.waitEs(newAcc.id));
    } catch (_e) {} // (just best effort)
    this.isSubmitting = false;

    this.goBack();
  }

  onFormValidityChange(status: string): void {
    this.isFormValid = status === 'VALID';
  }

  private async saveAccount(account: Account): Promise<any> {
    account._meta = {} as any;

    this.isSubmitting = true;

    return await firstValueFrom(this.svcAccount.create(account).pipe(tap(() => (this.isSubmitting = false))));
  }
}
