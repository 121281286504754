import { GET, Query, Path, Body, MediaType, Produces, PATCH, POST } from '../../shared/services/rest/rest.service';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIService } from '../../shared/services/api/api.service';
import { ProfileViewMode } from './constant/view-mode.enum';
import { User } from '../../user/shared/user';
import { Profile } from './profile';

export interface QCStat {
  completed: number;
  total: number;
  percent: number;
}

@Injectable()
export class ProfileAPI extends APIService {
  @GET('profiles/')
  @Produces(MediaType.JSON)
  public find(
    @Query('name') name?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<Profile[]> {
    return;
  }

  @GET('profiles/')
  @Produces(MediaType.JSON)
  public findExt(@Query('name') name?: string, @Query('extSearch') extSearch?: boolean): Observable<Profile[]> {
    return;
  }

  @GET('profiles/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Profile> {
    return;
  }

  @GET('profiles/count')
  @Produces(MediaType.JSON)
  public count(
    @Query('name') name?: string,
    @Query('filter') filter?: string,
    @Query('optional') optional?: boolean
  ): Observable<{ count: number }> {
    return;
  }

  @GET('profiles/')
  @Produces(MediaType.JSON)
  public findByPersonId(@Query('personId') id: string): Observable<Profile[]> {
    return;
  }

  @PATCH('profiles/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body profile: Profile): Observable<Profile> {
    return;
  }

  @POST('profiles/exist')
  @Produces(MediaType.JSON)
  public exist(@Body ids: string[]): Observable<{ invalid: boolean }[]> {
    return;
  }

  @POST('profiles/bulk-update')
  @Produces(MediaType.JSON)
  public bulkUpdate(@Body data: { ids: string[]; values: { [key: string]: any } }): Observable<{ bulkTrackingId }> {
    return;
  }

  @GET('profiles/{id}/status')
  @Produces(MediaType.JSON)
  public status(@Path('id') id: string, @Query('viewMode') viewMode: ProfileViewMode): Observable<any> {
    return;
  }

  @GET('profiles/next')
  @Produces(MediaType.JSON)
  public findNext(): Observable<Profile> {
    return;
  }

  @GET('profiles/queue')
  @Produces(MediaType.JSON)
  public queue(@Query('userId') userId?: string): Observable<any> {
    return;
  }

  /**
   * Find all the audit logs of a given model id
   * @param id
   */
  @GET('profiles/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  /**
   * Find count of audit logs
   * @param id
   */
  @GET('profiles/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }

  /**
   * Find all the distinct values of the `polishedBy` field
   */
  @GET('profiles/polishers')
  @Produces(MediaType.JSON)
  public polishers(): Observable<User[]> {
    return;
  }

  @GET('profiles/qc-next')
  @Produces(MediaType.JSON)
  public findQCNext(): Observable<string | undefined> {
    return;
  }

  @POST('profiles/{id}/replicate')
  @Produces(MediaType.JSON)
  public replicate(@Path('id') id: string, @Body payload: any): Observable<string[]> {
    return;
  }

  @GET('profiles/{id}/training-report')
  @Produces(MediaType.JSON)
  public trainingReport(@Path('id') id: string): Observable<any> {
    return;
  }

  @GET('profiles/{id}/no-videos')
  @Produces(MediaType.JSON)
  public getNoVideos(@Path('id') id: string): Observable<boolean> {
    return;
  }

  @POST('profiles/{id}/no-videos')
  @Produces(MediaType.JSON)
  public toggleNoVideos(@Path('id') id: string, @Body payload: any): Observable<boolean> {
    return;
  }

  /**
   * Upload the file and get a bulk upload ID
   *
   * @param file
   * @param secondEyes
   */
  public startClinicalTrialsBulkListUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'profiles/clinical-trial/bulk-list', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  /**
   * Upload the file and get a bulk upload ID
   *
   * @param file
   * @param secondEyes
   */
  public startProfileVideoJobsBulkListUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'profiles/bulk-video-jobs', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  @GET('profiles/video-next')
  @Produces(MediaType.JSON)
  public findNextVideoCuration(): Observable<Profile> {
    return;
  }

  @POST('profiles/{id}/video-submit')
  @Produces(MediaType.JSON)
  public submitVideos(@Path('id') id: string): Observable<Profile> {
    return;
  }

  @GET('profiles/qc-stats')
  @Produces(MediaType.JSON)
  public QCStats(): Observable<{ region: QCStat; personal: QCStat }> {
    return;
  }

  /**
   * Upload the file and get a bulk upload ID - profile bulk list
   *
   * @param file
   * @param secondEyes
   */
  public startProfileBulkListUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'profiles/bulk-list', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  @GET('profiles/queue-inspection/{userId}')
  @Produces(MediaType.JSON)
  public userProfileQueue(@Path('userId') userId?: string): Observable<Profile[]> {
    return;
  }

  @GET('profiles/queue-size/{userId}')
  @Produces(MediaType.JSON)
  public userProfileQueueSize(@Path('userId') userId?: string): Observable<{ count: number }> {
    return;
  }

  public startBulkListJobUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'profiles/bulk-list-job', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  @POST('profiles/submit-job')
  @Produces(MediaType.JSON)
  public submitJob(@Body person: Profile & { reviewCounts?; reviewCountsMatch? }): Observable<Profile> {
    return;
  }

  @GET('profiles/{id}/grants-status')
  @Produces(MediaType.JSON)
  public grantsStatus(@Path('id') id: string, @Query('viewMode') viewMode: ProfileViewMode): Observable<any> {
    return;
  }

  /**
   * It downloads a profiles data file, with optional filters and search term applied on.
   * @param [filter] - An optional object that contains filter criteria for the profiles to be
   * downloaded. It is encoded as a JSON string and then URL encoded before being added to the download
   * URL.
   * @param {string} [name] - search for specific profiles in the file being downloaded.
   * @returns a profiles file as binary.
   */
  public downloadProfilesFile(filter?: any, name?: string): Observable<any> {
    const params = new URLSearchParams();
    filter && Object.keys(filter).length > 0 && params.set('filter', JSON.stringify(filter));
    name && params.set('name', name);

    const paramString = params.toString();
    const url = `${this.getBaseUrl()}profiles/export${paramString ? `?${paramString}` : ''}`;

    return this.http.get(url, { responseType: 'blob' }).pipe(catchError((err) => this.handleError(err)));
  }
}
