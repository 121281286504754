import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { User } from '../../../user/shared/user';
import { UserAPI } from '../../../user/shared/api.service';
import { Observable, of, take } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'dirt-single-user-select',
  templateUrl: 'single-user-select.component.html',
  styleUrls: ['single-user-select.component.scss'],
})
export class SingleUserSelectComponent implements OnChanges {
  @Input()
  userId: string;

  @Input()
  requiredRole: string;

  @Input()
  disabled: boolean;

  @Output()
  userIdChange: EventEmitter<string> = new EventEmitter();

  isLoading: boolean = false;
  selectedUser: User = null;

  constructor(private svcUser: UserAPI) {
    this.search = this.search.bind(this);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes['userId'] &&
      changes['userId'].currentValue &&
      this.selectedUser?.user_id !== changes['userId'].currentValue
    ) {
      this.svcUser
        .findById(changes['userId'].currentValue)
        .pipe(take(1))
        .subscribe((u) => (this.selectedUser = u));
    }
  }

  search(text$: Observable<any>): Observable<any[]> {
    return text$.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      tap(() => (this.isLoading = true)),
      switchMap((term) => {
        if (term.length > 2) {
          return this.svcUser.find(term, 10, 0, '', { blocked: false, roles: [this.requiredRole] }).pipe(
            take(1),
            catchError(() => {
              return of([]);
            })
          );
        } else {
          return of([]);
        }
      }),
      tap(() => (this.isLoading = false))
    );
  }

  formatName(user: User): string {
    let firstName;
    let lastName;

    if (user.user_metadata) {
      firstName = user.user_metadata.firstName;
      lastName = user.user_metadata.lastName;
    }

    const name = `${firstName} ${lastName} <${user.email}>`;
    return name.trim();
  }

  onSelectUser(evnt: { item: User }) {
    this.userIdChange.emit(evnt.item.user_id);
  }
}
