import { NgModule } from '@angular/core';

import { PodcastAPI } from './shared/podcast-api.service';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [SharedModule],
  declarations: [],
  providers: [PodcastAPI],
})
export class PodcastModule {}
