import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { EventAPI } from '../../../event/shared/api.service';
import { Event } from '../../../event/shared/event';
import { Contribution } from '../contribution';
import { ContributionAPI } from '../contribution-api.service';

@Component({
  selector: 'move-contribution-component',
  templateUrl: 'move-contribution.component.html',
  styleUrls: ['move-contribution.component.scss'],
})
export class MoveContributionComponent implements OnInit {
  searchTerm: string;
  searchCtrl: FormControl = new FormControl('');
  parentID: string;
  contributionToBeMoved: Contribution[];
  pagingLimit = 20;
  pagingSkip = 0;
  sort = '-_id';
  filter: any;
  isSearching = false;
  sessionSelected: string;
  moveToDirect: boolean;
  makeACopy: boolean;
  directContribution: boolean;
  isLoading: boolean;
  eventID: string;
  allContributionOnMove: boolean;
  contributionsContainsChairperson: boolean = false;
  contributionsContainsOrganiser: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private svcEvent: EventAPI,
    private svcConrtibution: ContributionAPI
  ) {}

  ngOnInit(): void {
    this.contributionsContainsOrganiser = this.contributionToBeMoved.some(
      (item) => item.person?.position == 'ORGANIZER'
    );
    this.contributionsContainsChairperson = this.contributionToBeMoved.some(
      (item) => item.person?.position == 'CHAIRPERSON'
    );
  }

  searchAutoComplete = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) =>
        this.svcEvent
          .find(term, this.pagingLimit, this.pagingSkip, this.sort, this.filter, this.parentID)
          .pipe(catchError(() => of([])))
      ),
      map((results: []) => results.filter((o) => !!o))
    );

  onSelectSession($event: any) {
    $event.preventDefault();
    this.sessionSelected = $event.item.id;
    this.searchTerm = $event.item.name;
  }

  updateIdToMove($event: any) {
    $event.stopPropagation();
    if (this.moveToDirect) {
      this.sessionSelected = this.parentID;
      this.searchTerm = '';
    } else {
      this.sessionSelected = '';
    }
  }

  moveContribution() {
    if (!this.sessionSelected) {
      return;
    }
    this.isLoading = true;
    if (!this.allContributionOnMove) {
      this.contributionToBeMoved.forEach((contribution) => (contribution.event = this.sessionSelected));
    } else {
      this.contributionToBeMoved = [];
    }

    this.svcConrtibution
      .moveContribution({
        contributions: this.contributionToBeMoved,
        makeACopy: this.makeACopy,
        moveAllContributions: this.allContributionOnMove,
        sessionToBeMovedTo: this.sessionSelected,
        sessionToBeMovedFrom: this.eventID,
      })
      .subscribe(
        () => {
          this.activeModal.close();
        },
        () => {},
        () => (this.isLoading = false)
      );
  }
}
