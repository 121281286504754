import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIService } from '../../shared/services/api/api.service';
import { Publication } from './publication';
import { GET, MediaType, Produces, Path, Query } from '../../shared/services/rest/rest.service';

@Injectable()
export class PublicationAPI extends APIService {
  @GET('publications/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Publication> {
    return;
  }

  @GET('publications/')
  @Produces(MediaType.JSON)
  public find(
    @Query('name') name?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<Publication[]> {
    return;
  }

  @GET('publications/count')
  @Produces(MediaType.JSON)
  public count(@Query('name') name?: string, @Query('filter') filter?: any): Observable<any> {
    return;
  }

  @GET('publications/search')
  @Produces(MediaType.JSON)
  public search(@Query('term') term: string): Observable<Publication[]> {
    return;
  }
}
