import { Person } from '../../person/shared/person';
import { PrimaryResearch } from './primaryResearch';

export class CommitteePerson {
  id?: string;
  committeeId: string;
  kolId: string;
  position: string;
  originalPosition?: string;
  probability: string;
  sourceUrls: string[];
  person?: Person;

  hasPrimaryResearch?: boolean = false;
  primaryResearchInfo?: PrimaryResearch;
}
