<div class="dirt-contribution-capture-panel">
  <dirt-capture-bar
    #captureBarComponent
    [event]="event"
    [eventCaption]="event.parent ? 'session' : null"
    (hasContribution)="onContributionFromCapture($event)"
  ></dirt-capture-bar>
</div>
<section class="dirt-section-list dirt-contribution-list clearfix">
  <header class="row">
    <div class="col-sm-4">
      <h2>
        <span *ngIf="total">{{ total.count | number }}</span> Contributions
      </h2>
    </div>
    <div class="col-sm-4">
      <div class="input-group row" *ngIf="isSearchType && !showDuplicates; else simpleSearch">
        <div ngbDropdown class="input-group-btn">
          <button type="button" class="btn btn-secondary" id="searchTypeDropDown" ngbDropdownToggle>
            {{ selectedSearchType | capitalize }}
          </button>
          <div class="dropdown-menu" ngbDropdownMenu>
            <button
              type="button"
              class="dropdown-item"
              *ngFor="let searchType of searchTypes | iterable"
              (click)="onSearchTypeChange(searchType.value)"
            >
              {{ searchType.value | capitalize }}
            </button>
          </div>
        </div>
        <input
          type="search"
          class="form-control"
          [formControl]="searchCtrl"
          [(ngModel)]="searchTerm"
          placeholder="Search..."
        />
      </div>
    </div>
    <ng-template #simpleSearch>
      <input
        type="search"
        class="form-control"
        [formControl]="searchCtrl"
        [(ngModel)]="searchTerm"
        placeholder="Search..."
      />
    </ng-template>
    <div class="col-sm-4">
      <button
        class="btn btn-success btn-sm pull-right"
        (click)="onCreateContributionClick()"
        *diAcl="'contribution.create'"
        [disabled]="contributionFormOpened"
      >
        <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Create Contribution
      </button>
    </div>
  </header>

  <div *ngIf="contributionFormOpened" class="dirt-contribution-form">
    <dirt-contribution-form
      [eventId]="event.id"
      [contribution]="contribution"
      [isSubmitting]="isSubmitting"
      [isSaving]="isSaving"
      [showPlaintext]="isFromCapture"
      (reEvalRequest)="captureBarComponent.triggerReEval($event)"
      (prevTitleRequest)="onPrevTitle($event, true)"
      (prevWpRequest)="onPrevWp($event, true)"
      (onSubmitted)="onSubmit($event)"
      (onSubmittedAndNew)="onSave($event)"
      (onCancel)="onCancelClick()"
    >
    </dirt-contribution-form>
  </div>

  <div class="d-flex justify-content-between align-items-baseline">
    <!-- Pagination -->
    <dirt-pagination
      class="dirt-pagination"
      [id]="'dirt-contribution-list'"
      [pagingSize]="contributions?.length || 0"
      [pagingSkip]="pagingSkip"
      [pagingLimit]="pagingLimit"
      [pagingTotal]="total?.count || 0"
      [hidden]="pagingLimit >= total?.count"
      (pageChange)="getPage($event)"
    >
    </dirt-pagination>

    <!-- Just need an empty spacer when the pagination is hidden -->
    <span></span>

    <div class="list-global-actions d-flex justify-content-between align-items-baseline">
      <button
        class="btn btn-sm btn-outline-primary"
        [ngClass]="{ active: showDuplicates }"
        (click)="onToggleShowDuplicate()"
      >
        Show Duplicates
      </button>

      <button type="button" class="btn btn-sm btn-outline-primary" (click)="onContributionMoveAction()">
        Move Selected
      </button>

      <!-- change style of table so that it's maybe ugly, but shows everything without expanding -->
      <label style="margin-left: 10px">
        <ui-switch [checked]="tableExpandView" (change)="tableExpandView = $event" size="small" color="#6d5cae">
        </ui-switch>
        Table expand view
      </label>
      <!-- only show verified -->
      <label style="margin-left: 10px">
        <ui-switch
          [checked]="onlyNeedsVerify"
          [disabled]="!qcSessionId"
          (change)="onChangeOnlyNeedsVerify($event)"
          size="small"
          color="#6d5cae"
        >
        </ui-switch>
        Only needs verify
      </label>
    </div>
  </div>

  <div *ngIf="contributions" [hidden]="isLoading">
    <table class="table table-sm table-hover" (diSort)="onSort($event)">
      <thead>
        <tr [ngClass]="{ 'col-read-all': tableExpandView }">
          <th class="col-link"></th>
          <th class="col-title" [diColSorter]="'title'">Title</th>
          <th class="col-session" *ngIf="selectedSearchType !== searchTypes.Direct">Session</th>
          <th class="col-first" [diColSorter]="'person.firstName'">First</th>
          <th class="col-middle">Middle</th>
          <th class="col-last" [diColSorter]="'person.lastName'">Last</th>
          <th class="col-full" [diColSorter]="'person.fullName'">Full</th>
          <th class="col-position">Position</th>
          <th class="col-workplace">Workplace</th>
          <th class="col-reviewed">
            <label>
              <span class="col-label">Reviewed</span>
              <div class="reviewed-wrapper">
                <input
                  (change)="toggleAllReviewed($event)"
                  type="checkbox"
                  [checked]="allReviewed"
                  [disabled]="!canEditContribution() || isLoading"
                  title="Mark all as reviewed"
                />
              </div>
            </label>
          </th>
          <th class="col-verified" *ngIf="shouldDisplayVerifiedControls">
            <label>
              <span class="col-label">Verified</span>
              <div class="verified-wrapper">
                <input
                  (change)="toggleAllVerified($event)"
                  type="checkbox"
                  [checked]="allVerified"
                  [disabled]="!canEditContribution() || isLoading"
                  title="Mark all as verified"
                />
              </div>
            </label>
          </th>
          <th class="col-move">
            <span class="col-label">Move</span>
            <div class="move-wrapper">
              <input
                (change)="toggleAllMoveContribution($event)"
                type="checkbox"
                [checked]="allContributionOnMove"
                [disabled]="!canEditContribution() || isLoading"
                title="Select all for move"
              />
            </div>
          </th>
          <th class="col-delete">
            <span class="action-btn action-delete" (click)="bulkDelete()" *diAcl="'contribution.delete'">
              <i class="fa fa-trash-o"></i>
            </span>
          </th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>
      <tbody
        class="di-dragList"
        cdkDropList
        id="contributionsList"
        [cdkDropListData]="contributions"
        cdkDropListConnectedTo="sessionList"
      >
        <tr
          class="dirt-contribution-row"
          *ngFor="
            let cn of contributions
              | paginate
                : {
                    id: 'dirt-contribution-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  }
          "
          [ngClass]="{
            'dirt-selected-item': cn.id === contributionEditId,
            'dirt-flashing-item': flashingId === cn.id,
            'dirt-flashing-edit-item': flashingEditId === cn.id,
            'col-title-expanded': expandedIds.includes(cn.id),
            'col-read-all': tableExpandView
          }"
          (dblclick)="onShowAllText(cn.id)"
          cdkDrag
          [cdkDragDisabled]="contributionEditId === cn.id || disableDragAndDrop"
        >
          <div class="di-custom-placeholder" *cdkDragPreview>{{ contributionsToBeDragged }}</div>
          <ng-container *ngIf="contributionEditId !== cn.id">
            <td class="col-link">
              <a [routerLink]="['/contribution/detail', cn.id]" rel="noopener" target="_blank"
                ><i class="fa fa-external-link" aria-hidden="true"></i
              ></a>
            </td>
            <td class="col-title" [title]="cn.title">
              {{ cn.title | dash
              }}<i
                class="fa fa-copy"
                title="Copy title"
                style="cursor: pointer"
                *ngIf="!!cn.title"
                (click)="onCopyTitle(cn.title)"
              ></i
              ><i class="fa fa-bug fa-inverse" aria-hidden="true" *ngIf="hasQc(cn)"></i>
            </td>
            <td
              class="col-session"
              *ngIf="selectedSearchType !== searchTypes.Direct && cn.event.parent; else no_session"
            >
              <span [title]="cn.event.name">{{ cn.event.name }}</span>
            </td>
            <ng-template #no_session>
              <td class="col-session" *ngIf="selectedSearchType !== searchTypes.Direct">--</td>
            </ng-template>
            <td class="col-first">
              <a>{{ cn.person.firstName }}</a>
            </td>
            <td class="col-middle">
              <a>{{ cn.person.middleName }}</a>
            </td>
            <td class="col-last">
              <a>{{ cn.person.lastName }}</a>
            </td>
            <td class="col-full">
              <a>{{ cn.person.fullName }}</a>
            </td>
            <td class="col-position">{{ cn.person.position | capitalize }}</td>
            <td class="col-workplace">
              <span [title]="cn.person.workplace">{{ cn.person.workplace }}</span>
            </td>
            <td class="col-ended" [title]="cn.reviewed">
              <input
                type="checkbox"
                [disabled]="!canEditContribution()"
                [checked]="cn.reviewed"
                (change)="onContributionReviewedChange(cn, $event)"
              />
            </td>
            <td class="col-ended" [title]="cn.verified" *ngIf="shouldDisplayVerifiedControls">
              <span [ngClass]="{ 'di-red-border': isNeeded(cn) }"
                ><input
                  type="checkbox"
                  [disabled]="!canEditContribution()"
                  [checked]="cn.verified"
                  (change)="onContributionVerifiedChange(cn, $event)"
              /></span>
            </td>
            <td class="col-ended">
              <input
                type="checkbox"
                [disabled]="!canEditContribution()"
                (change)="onContributionMoveSelect(cn, $event)"
                [checked]="cn.markForMove"
              />
            </td>
            <td>
              <input
                type="checkbox"
                [disabled]="!canEditContribution()"
                (change)="onContributionDelete(cn, $event)"
                [checked]="cn.markForDelete"
              />
            </td>
            <td class="col-actions">
              <!-- Edit -->
              <span class="action-btn action-edit" (click)="onEditClicked(cn.id, $event)">
                <i class="fa fa-pencil" aria-hidden="true"></i>
              </span>
              <!-- Delete -->
              <span
                class="action-btn action-delete"
                (click)="onDeleteClick(cn.id, $event)"
                *diAcl="'contribution.delete'"
              >
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </span>
            </td>
          </ng-container>
          <ng-container *ngIf="contributionEditId === cn.id">
            <td colspan="14" class="col-edit">
              <dirt-contribution-form
                [eventId]="event.id"
                [contribution]="cn"
                [isSubmitting]="isSubmitting"
                (onSubmitted)="onEdit($event)"
                (onCancel)="onCancelEditClick()"
              >
              </dirt-contribution-form>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <p class="dirt-no-results" *ngIf="!contributions.length">No Contributions</p>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
