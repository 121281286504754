<ng-template [diAcl]="'person.bulkList'">
  <button
    class="list-group-item list-group-item-action"
    [title]="personJobBulkListDelegate.specialHint || ''"
    (click)="onOpenPersonBulkListJobDialog()"
  >
    <i class="fa fa-th-list" aria-hidden="true"></i>
    Bulk Import Person Job
  </button>
</ng-template>
<ng-template [diAcl]="'clinicalTrialProfile.bulkList'">
  <button
    class="list-group-item list-group-item-action"
    [title]="clinicalTrialProfileJobBulkListDelegate.specialHint || ''"
    (click)="onOpenClinicalTrialProfileBulkListJobDialog()"
  >
    <i class="fa fa-th-list" aria-hidden="true"></i>
    Bulk Import CT Profile Job
  </button>
</ng-template>
<ng-template [diAcl]="'organization.bulk-list'">
  <button
    class="list-group-item list-group-item-action"
    [title]="newOrganizationJobBulkListDelegate.specialHint || ''"
    (click)="onOpenNewOrganizationBulkListJobDialog()"
  >
    <i class="fa fa-th-list" aria-hidden="true"></i>
    Bulk Import New Organization Job
  </button>
</ng-template>
<ng-template [diAcl]="'medical-insights-profile.bulk-list'">
  <button
    class="list-group-item list-group-item-action"
    [title]="medicalInsightsProfileJobBulkListDelegate.specialHint || ''"
    (click)="onOpenMIBulkListJobDialog()"
  >
    <i class="fa fa-th-list" aria-hidden="true"></i>
    Bulk Import Medical Insights Profile Job
  </button>
</ng-template>
