<span *ngIf="!!value">
  <label>
    <input
      type="number"
      size="5"
      class="form-control form-control-sm"
      [ngClass]="{error: error}"
      [value]="isNil(value.value) ? '' : value.value"
      (input)="changeInput($event)"
      [placeholder]="(isNil(value.srcValue) || inside) ? '' : value.srcValue"
      [disabled]="statusEnum.Auto === value.status"
      (focus)="inside=true"
      (blur)="inside=false"
  /></label>
  <span *ngIf="((!isNil(value.value)) || inside) && (!isNil(value.srcValue)) && (value.value !== value.srcValue)"
    >({{value.srcValue}})</span
  >
  <small *ngIf="statusEnum.Auto !== value.status">
    <label><input [name]="name" [value]="statusEnum.Keep" [(ngModel)]="value.status" type="radio" /> keep</label>
    <label><input [name]="name" [value]="statusEnum.Discard" [(ngModel)]="value.status" type="radio" /> discard</label>
    <label
      ><input [name]="name" [value]="statusEnum.Discontinue" [(ngModel)]="value.status" type="radio" />
      discontinue</label
    >
  </small>
</span>
