import { Injectable } from '@angular/core';
import { firstValueFrom, from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JwtHelperService } from '@auth0/angular-jwt';

import {
  GET,
  PUT,
  Query,
  Path,
  Body,
  MediaType,
  Produces,
  PATCH,
  POST,
} from '../../../../shared/services/rest/rest.service';
import { SubentityApiService } from '../../common/subentity-api.service';
import { ProfileClinicalTrial, ProfileClinicalTrialInvestigator } from '../../../shared/profile-clinical-trial';
import { Profile } from '../../../shared/profile';
import { ACL } from '../../../../shared/acl/acl.service';
import { PersonAPI } from '../../../../person/shared/api.service';
import { filterStream, parseJSONStream, splitStream } from '../../stream.utils';
import { Utils } from '../../../../common/utils';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { Roles } from '../../../../shared/acl/roles';

type ProfileClinicalTrialRaw = {
  person_id: string;
  nctid: string;
  match_result: string;
  match_source?: string;
  fas?: string; // split by @
  fas_ids?: string; // split by @
  first_name: string;
  middle_name: string;
  last_name: string;
  position: string;
  facility_name: string;
  city: string;
  postal_code: string;
  country: string;
  state: string;
  ml_exclude?: 't' | 'f' | true | false;
};

type ClinicalTrialBaseInfo = {
  nct: string;
  source: string;
  title: string;
  summary: string;
  condition: string;
  investigator: ProfileClinicalTrialInvestigator;
};

@Injectable()
export class ProfileClinicalTrialAPI extends SubentityApiService<
  ProfileClinicalTrial,
  { id: string; _profile: ProfileClinicalTrial },
  ProfileClinicalTrial[],
  { position: string }
> {
  constructor(
    http: HttpClient,
    svcModal: NgbModal,
    svcAcl: ACL,
    svcJwtHelper: JwtHelperService,
    private svcPerson: PersonAPI
  ) {
    super(http, svcModal, svcAcl, svcJwtHelper);
  }

  @POST('clinical-trials/base-info')
  @Produces(MediaType.JSON)
  public getCtBaseInfo(
    @Body ncts: string[] | { ncts: string[]; investigatorIds?: string[] }
  ): Observable<ClinicalTrialBaseInfo[]> {
    return;
  }

  public findAll(profile: Profile): Observable<any> {
    return from(this.findAllAutomated(profile));
  }

  protected async enrichAll(
    profile: Profile,
    allList: ProfileClinicalTrial[]
  ): Promise<{ id; _profile: ProfileClinicalTrial }[]> {
    const curatedMatches = await this.findAllCurated(profile);
    const curatedMatchesById = new Map(curatedMatches.map((match) => [match.id, match]));
    const mappedCuratedMatches = new Set<string>(); // track curated matches that were mapped to an automated match for later

    // 1 - Augment auto matches with manual info
    allList.forEach((match) => {
      if (!curatedMatchesById.has(match.id)) {
        return;
      }

      const curatedMatch = curatedMatchesById.get(match.id);

      mappedCuratedMatches.add(match.id);

      match.mongoId = curatedMatch.mongoId;

      if (curatedMatch.match) {
        match.match.manual = curatedMatch.match.manual;
        match.match.pendingSubmission = curatedMatch.match.pendingSubmission;
      }

      match.compiledAt = curatedMatch.compiledAt;
      match.qc = curatedMatch.qc;
    });

    // 2 - Append fully manual matches
    const remainingCurated = curatedMatches.filter(
      (item) =>
        !mappedCuratedMatches.has(item.id) &&
        (ProfileMatchManual.MATCH === item.match?.manual || ProfileMatchManual.MISMATCH === item.match?.manual)
    );
    let completeAllList = [...allList, ...remainingCurated];

    // Given we don't have manual info in ensureLoaded before calling enrichAll, we need to filter what's already matched here for compilers.
    // Will need to do exactly the same thing for the other sources when progressively switching them to outbox.

    // for compilers outside training and (usual) view mode delta, we could speed up (ViewModeFilter will _always_ filter out the same ones) - load way less base-info etc around the globe:
    if (
      this.svcAcl.userRoles?.includes(Roles.ProfileCompiler) &&
      !profile.training &&
      profile.viewMode?.endsWith('_DELTA')
    ) {
      completeAllList = completeAllList.filter(
        (item) => item.match?.manual === 'NONE' || item.match?.pendingSubmission
      );
    }

    // 3 - Augment with source info and transform
    const trialIds = [...new Set(completeAllList.map((match) => match.nct))]; // make sure we don't fetch the same thing multiple time
    const trialsById = new Map<string, ClinicalTrialBaseInfo>();

    const PAGE_SIZE = 2500;
    for (let i = 0; i < trialIds.length; i += PAGE_SIZE) {
      const trials = await firstValueFrom(this.getCtBaseInfo(trialIds.slice(i, i + PAGE_SIZE)));
      trials.forEach((trial) => trialsById.set(trial.nct, trial));
    }

    return completeAllList
      .filter((match) => trialsById.has(match.nct)) // Those without data in source are not relevant
      .map((match) => {
        // augment with source and transform
        const trial = trialsById.get(match.nct);

        return {
          id: match.id, // replace w/ mongo ID when curated
          mongoId: match.mongoId,
          nct: match.nct,
          source: match.source,
          _profile: {
            // (the match as such)
            ...match,
            match: {
              ...match.match,
              mlAnyway:
                Math.abs(Utils.hash((match as any).person?.kolId + '' + (match.nct || 'null').toString()) % 100) < 1,
            },
            focusAreas: match.focusAreas?.map((fa) => (typeof fa === 'string' ? fa : (fa as any).name)),
            // focusAreaIds: not needed (no asia filter)
          },
          clinical_trial: {
            title: trial.title,
            purpose: trial.summary,
            condition: trial.condition,
          },
          investigator: match.investigator,
        };
      });
  }

  protected async loadAndEnrichOrigins(
    profileId: string,
    kolId: string
  ): Promise<{ id; _profile: ProfileClinicalTrial }[]> {
    const person = await firstValueFrom(this.svcPerson.findById(kolId));
    const clinicalTrialIds: string[] = (
      (person as any)?.additionalDetails?.automatedProfileIds?.clinical_trialIds || []
    ).map(
      (clinicalTrialId) => clinicalTrialId.split('_')[0] /* We only have investigator ID that is <NCT>_<whatever> */
    );
    if (clinicalTrialIds.length < 1) {
      return [];
    }

    // load clinical-trials
    const clinicalTrialsList = await firstValueFrom(
      this.getCtBaseInfo({
        ncts: clinicalTrialIds,
        investigatorIds: (person as any)?.additionalDetails?.automatedProfileIds?.clinical_trialIds,
      })
    );
    const clinicalTrialsMap = {};
    clinicalTrialsList.forEach((c) => (clinicalTrialsMap[c.nct] = c));

    // do transform into the format we used - from here fw, we should not need any change
    const transformed = clinicalTrialIds.map((clinicalTrialId) => ({
      // (pretty simple transform if you break it down)
      ...clinicalTrialsMap[clinicalTrialId], // (journal, abstract, etc.)
      id: null,
      nct: clinicalTrialId,
      _profile: {
        // (the match as such)
        match: { automate: 'PB' },
        focusAreas: [],
      },
      clinical_trial: {
        // have to pull those info differently for CT, same as above minus investigator
        title: clinicalTrialsMap[clinicalTrialId].title,
        purpose: clinicalTrialsMap[clinicalTrialId].summary,
        facility: clinicalTrialsMap[clinicalTrialId].investigator?.facility,
      },
      investigator: clinicalTrialsMap[clinicalTrialId].investigator || {},
    }));

    return transformed;
  }

  @PATCH('profiles/{id}/clinical-trials')
  @Produces(MediaType.JSON)
  public updateImpl(@Path('id') id: string, @Body matches: ProfileClinicalTrial[]): Observable<ProfileClinicalTrial[]> {
    return null;
  }

  @PUT('profiles/{id}/clinical-trials/rel/{fk}')
  @Produces(MediaType.JSON)
  public linkImpl(
    @Path('id') id: string,
    @Path('fk') clinicalTrialId: string,
    @Body data: { position: string }
  ): Observable<ProfileClinicalTrial> {
    return;
  }

  private async findAllAutomated(profile: Profile): Promise<ProfileClinicalTrial[]> {
    const options: RequestInit = { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } };
    const response: Response = await fetch(
      this.getBaseUrl() + 'profiles/' + profile.id + '/clinical-trials/automated',
      options
    ); // (we want a stream now)
    if (!response.ok) {
      throw new Error('Failed to download automated matches');
    }

    // For automated matches, we need to get the base profile matches, the clone will not have anything under its name.
    const identifier = profile.training ? profile.baseProfileKolId : profile.person.kolId;
    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(splitStream('\n'))
      .pipeThrough(filterStream((row: string) => row.includes(identifier))) // save us some JSON parsing
      .pipeThrough(parseJSONStream())
      .pipeThrough(filterStream((row: ProfileClinicalTrialRaw) => row.person_id === identifier)) // Catch the very few cases that we could leave out
      .pipeThrough(transformToMatch())
      .getReader();

    const matches: ProfileClinicalTrial[] = [];

    while (true) {
      const result = await reader.read(); // Leave timeout to Nginx

      if (!result) {
        // Nothing in the stream
        break;
      }

      if (result.done) {
        break; // Everything was read
      }

      matches.push(result.value);
    }

    // We're done
    reader.cancel();

    return matches;
  }

  private async findAllCurated(profile: Profile): Promise<ProfileClinicalTrial[]> {
    const options: RequestInit = { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } };
    const response: Response = await fetch(
      this.getBaseUrl() + 'profiles/' + profile.id + '/clinical-trials/curated',
      options
    ); // (we want a stream now)
    if (!response.ok) {
      throw new Error('Failed to download curated matches');
    }

    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(splitStream('\n'))
      .pipeThrough(parseJSONStream())
      .getReader();

    const matches: ProfileClinicalTrial[] = [];

    while (true) {
      const result = await reader.read(); // Leave timeout to Nginx

      if (!result) {
        // Nothing in the stream
        break;
      }

      if (result.done) {
        break; // Everything was read
      }

      this.postProcessFromMongo(result.value);
      matches.push(result.value);
    }

    // We're done
    reader.cancel();

    return matches;
  }

  protected postProcessFromMongo(item: ProfileClinicalTrial) {
    // also what is coming back from updates
    item.mongoId = item.id;
    item.id = 't-' + Utils.hash(getUniqueIdent(item));
  }

  // --- for CT profile ---

  @GET('profiles/clinical-trials/fa-by-ct-id/{id}')
  @Produces(MediaType.JSON)
  public findFocusAreaByCTid(@Path('id') id: string): Observable<string[]> {
    return;
  }

  @GET('profiles/clinical-trials/by-nct/{nct}')
  @Produces(MediaType.JSON)
  public findByNct(@Path('nct') nct: string, @Query('manualMatchOnly') manualMatchOnly?: boolean): Observable<any[]> {
    return;
  }
}

function getUniqueIdent(item: ProfileClinicalTrial): any {
  return [
    // item.person?.kolId, // we already work with a single KP, so having KOL ID here is redundant and detrimental when we want to merge base profile auto matches with cloned profile manual matches.
    item.nct,
    item.investigator?.facility,
    item.investigator?.firstName,
    item.investigator?.lastName,
    item.investigator?.middleName,
    item.investigator?.position,
    item.investigator?.address?.city,
    item.investigator?.address?.country,
    item.investigator?.address?.postalCode,
    item.investigator?.address?.state,
  ]
    .map((s) => s || '')
    .join('|'); // had the same previously in backend as buildFilter (same fields)
}

function mapRawToModel(chunk: ProfileClinicalTrialRaw): ProfileClinicalTrial {
  const fas = (chunk.fas || '').split('@').filter((s) => !!s);
  const faIds = (chunk.fas_ids || '').split('@').filter((s) => !!s);

  const match: ProfileClinicalTrial = {
    id: null, // (filled below)
    nct: chunk.nctid,
    person: { kolId: chunk.person_id },
    investigator: {
      firstName: chunk.first_name,
      middleName: chunk.middle_name,
      lastName: chunk.last_name,
      position: chunk.position,
      facility: chunk.facility_name,
      address: {
        city: chunk.city,
        country: chunk.country,
        state: chunk.state,
        postalCode: chunk.postal_code,
      },
    },
    match: {
      automate: chunk.match_result,
      manual: ProfileMatchManual.NONE, // Will enrich via curated matches
      matchSource: chunk.match_source,
      mlExclude: 't' === chunk.ml_exclude || true === chunk.ml_exclude,
    },
    focusAreas: fas.map((fa, i) => ({ name: fa, id: faIds[i] })),

    // filled later
    source: null,
    clinical_trial: null,
  };

  match.id = 't-' + Utils.hash(getUniqueIdent(match));
  return match;
}

function transformToMatch() {
  return new TransformStream({
    transform(chunk: ProfileClinicalTrialRaw, controller) {
      const match = mapRawToModel(chunk);
      controller.enqueue(match);
    },
  });
}
