import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[ngModel][dirtPasteNormalizer]',
})
export class DirtPasteNormalizerDirective {
  value: string;

  @Output()
  ngModelChange: EventEmitter<string> = new EventEmitter();

  @HostListener('paste', ['$event'])
  async onInputChange($event: any) {
    const input = await navigator.clipboard.readText();
    const { value } = $event.target;

    if (!input || !input.trim() || input !== value) {
      return;
    }

    // Change it to TitleCase
    this.value = input.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase());

    this.ngModelChange.emit(this.value);
  }
}
