import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'distinct',
})
export class DistinctPipe implements PipeTransform {
  transform(array: any[]): any[] {
    return array.filter((value, index, self) => self.indexOf(value) === index);
  }
}
