<div class="modal-header">
  <h5 class="modal-title">New Person</h5>
</div>
<div class="modal-body">
  <div>
    <div>
      {{
        affiliation
          ? 'Affiliation: ' + affiliation.name + ' ' + (affiliation.department || '')
          : '(no affiliation selected - select below)'
      }}
      <small
        ><a class="alink" *ngIf="affiliation && !selAffiliation" (click)="selAffiliation = true">Select...</a></small
      >
    </div>
    <dirt-affiliation-single-select
      [hidden]="affiliation && !selAffiliation"
      [wide]="true"
      [curatedOnly]="true"
      [excludeUnpolished]="false"
      (affiliationSelected)="affiliation = $event; selAffiliation = false"
    ></dirt-affiliation-single-select>
  </div>
  <dirt-person-form-inline
    [model]="newPerson"
    [checkDomainCompliance]="true"
    [disabledFields]="['source', 'projectsDelivery']"
    [multiCvLink]="multiCvLink"
    [displayCountry]="affiliation ? 'OPTIONAL' : 'MANDATORY'"
    [disableDisabledCountries]="disableDisabledCountries"
    (validityChange)="isFormValid = 'VALID' === $event"
  ></dirt-person-form-inline>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    [disabled]="isSubmitting"
    (click)="onCancelClick(); $event.stopPropagation()"
  >
    <span>Cancel</span>
  </button>
  <button
    type="submit"
    class="btn btn-primary"
    [ngClass]="{ 'btn-loading': isSubmitting }"
    [disabled]="!isFormValid || isSubmitting"
    (click)="onSubmitClick(); $event.stopPropagation()"
  >
    <span>Save</span>
  </button>
</div>
