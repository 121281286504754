export enum SentimentType {
  TWITTER = 'TWITTER',
}

export enum SentimentCurationStatus {
  READY_FOR_COMPILATION = 'READY_FOR_COMPILATION',
  COMPILATION_IN_PROGRESS = 'COMPILATION_IN_PROGRESS',
  DONE = 'DONE',
}

export enum SentimentClassification {
  POSITIVE = 'POSITIVE',
  NEUTRAL = 'NEUTRAL',
  NEGATIVE = 'NEGATIVE',
}

export class SentimentAutomaticCuration {
  subject: string;
  indication: string;
  setting: string;
  project: string;
  customer: string;
  subjectFaId: number;
  indicationFaId?: number;
}

export class SentimentManualCuration {
  subject: string;
  tag: string;
  sentiment: SentimentClassification;
  project: string;
  customer: string;
  subjectFaId: number;
}

export class CurationMeta {
  status: SentimentCurationStatus;
  assignee: string;
}

export class SentimentCuration {
  id: string;

  type: SentimentType;
  entityId: string;

  text: string; // not in what we save ourselves (transient, don't duplicate)

  autoCuration: SentimentAutomaticCuration[];
  manualCuration: SentimentManualCuration[];

  _meta: CurationMeta;

  priority: number; // >0 to be looked at
  curatedAt: Date;
  curatedBy: string;

  createdAt: Date;
  updatedAt: Date;
}
