import { Person } from '../../person/shared/person';

export class DocumentPerson {
  id?: string;
  documentId: string;
  kolId: string;
  position: string;
  sourceUrls: string[];
  person?: Person;
}
