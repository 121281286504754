<section class="dirt-section-create dirt-person-create">
  <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
    <span class="fa fa-arrow-left" aria-hidden="true"></span>
  </button>

  <h1 class="bounceInRight">Create Person</h1>

  <br />

  <dirt-person-form [person]="person" #frmPerson="frmPerson"></dirt-person-form>

  <div class="pull-right">
    <!-- Person -->
    <button
      type="submit"
      class="btn btn-primary"
      [ngClass]="{ 'btn-loading': isSubmitting }"
      [disabled]="!frmPerson.isValid() || isSubmitting"
      [title]="frmPerson.invalidFields ? frmPerson.invalidFields().join(', ') : ''"
      (click)="onSubmit(frmPerson.getValue())"
      *diAcl="'person.create'"
    >
      <span>{{ frmPerson.hasDuplicates() ? 'Submit Anyway' : 'Submit' }}</span>
    </button>
  </div>
</section>

<ng-template #duplicateAlertDialog let-close="close">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Person Already Exists!</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="duplicatePersonData" class="modal-body">
    <div class="row ml-1">
      Person ID: {{ duplicatePersonData.kolId }} &nbsp;
      <a
        *ngIf="duplicatePersonData.id"
        [routerLink]="['/person/detail', duplicatePersonData.id]"
        rel="noopener"
        target="_blank"
      >
        Open profile
      </a>
    </div>
  </div>

  <div class="modal-footer">
    <div class="pull-right">
      <button type="button" class="btn btn-secondary btn-sm" (click)="close()">Close</button>
    </div>
  </div>
</ng-template>
