import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { AffiliationAPI } from '../../api.service';
import { Affiliation } from '../../affiliation';

enum MergeDirection {
  LEFT_TO_RIGHT = 'leftToRight',
  RIGHT_TO_LEFT = 'rightToLeft',
}

@Component({
  selector: 'dirt-merge-problems',
  templateUrl: './merge-direction.component.html',
  styleUrls: ['./merge-direction.component.scss'],
})
export class AffiliationMergeDirectionComponent {
  left: Affiliation;
  right: Affiliation;

  isWorking: boolean;

  mergeDirection = MergeDirection.LEFT_TO_RIGHT;

  constructor(public activeModal: NgbActiveModal, public readonly svcAffiliation: AffiliationAPI) {}

  onClose(): void {
    this.activeModal.close();
  }

  onSubmit(): void {
    let winner: Affiliation, looser: Affiliation;
    if (this.mergeDirection === MergeDirection.LEFT_TO_RIGHT) {
      winner = this.right;
      looser = this.left;
    } else {
      winner = this.left;
      looser = this.right;
    }

    this.isWorking = true;
    this.svcAffiliation.merge(looser.id, winner.id).subscribe(
      (res) => {
        this.isWorking = false;
        this.activeModal.close(res);
      },
      (err) => console.log(err)
    );
  }
}
