<form novalidate>
  <div class="clearfix">
    <!-- Email -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="email">Email</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="email"
          id="email"
          class="form-control"
          name="email"
          [(ngModel)]="model.email"
          autofocus
          [readonly]="!isNewRecord()"
          required
        />
      </div>
    </div>

    <!-- Password -->
    <div class="form-group row" *ngIf="isNewRecord()">
      <label class="col-form-label col-sm-4 col-md-2" for="password">Password</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="password"
          id="password"
          class="form-control"
          name="password"
          [(ngModel)]="model.password"
          autofocus
          required
        />
      </div>
    </div>

    <!-- First Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="firstName">First Name</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="firstName"
          class="form-control"
          name="firstName"
          [(ngModel)]="model.user_metadata.firstName"
          [required]="!isNewRecord()"
          [disabled]="model.blocked"
          autofocus
        />
      </div>
    </div>

    <!-- Last Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="lastName">Last Name</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="lastName"
          class="form-control"
          name="lastName"
          [(ngModel)]="model.user_metadata.lastName"
          [required]="!isNewRecord()"
          [disabled]="model.blocked"
          autofocus
        />
      </div>
    </div>

    <!-- Blocked -->
    <div class="form-group row" *ngIf="!isNewRecord()">
      <label class="col-form-label col-sm-4 col-md-2" for="blocked">Blocked</label>
      <div class="col-sm-8 col-md-10 pt-1">
        <ui-switch id="blocked" name="blocked" size="small" color="#ff0000" [(ngModel)]="model.blocked"> </ui-switch>
      </div>
    </div>

    <!-- Underperformer -->
    <div class="form-group row" *ngIf="doesNeedUnderperformer()">
      <label class="col-form-label col-sm-4 col-md-2" for="newJoiner">New joiner/Underperformer</label>
      <div class="col-sm-8 col-md-10 pt-1">
        <ui-switch
          id="underperformer"
          name="underperformer"
          size="small"
          [(ngModel)]="model.app_metadata.additionalInfo.underperformer"
          (ngModelChange)="onUnderperformerChange($event)"
        >
        </ui-switch>
      </div>
    </div>

    <!-- UTC Maintainer -->
    <div class="form-group row" *ngIf="hasRoleOf('EVENT_CREATOR')">
      <label class="col-form-label col-sm-4 col-md-2" for="utcMaintainer">UTC Maintainer</label>
      <div class="col-sm-8 col-md-10 pt-1">
        <ui-switch
          id="utcMaintainer"
          name="utcMaintainer"
          size="small"
          [(ngModel)]="model.app_metadata.additionalInfo.utcMaintainer"
        >
        </ui-switch>
      </div>
    </div>

    <!-- Draft Maintainer -->
    <div class="form-group row" *ngIf="hasRoleOf('EVENT_CREATOR')">
      <label class="col-form-label col-sm-4 col-md-2" for="draftMaintainer">Draft Maintainer</label>
      <div class="col-sm-8 col-md-10 pt-1">
        <ui-switch
          id="draftMaintainer"
          name="draftMaintainer"
          size="small"
          [(ngModel)]="model.app_metadata.additionalInfo.draftMaintainer"
        >
        </ui-switch>
      </div>
    </div>

    <!-- Valid till -->
    <div
      class="form-group row"
      *ngIf="
        (hasRoleOf('PERSON_COMPILER') || hasRoleOf('PROFILE_COMPILER')) &&
        model.app_metadata.additionalInfo.underperformer
      "
    >
      <label class="col-form-label col-sm-4 col-md-2">New joiner/Underperformer valid till</label>
      <div class="col-sm-8 col-md-10">
        <div class="input-group">
          <input
            class="form-control"
            name="startDate"
            readonly
            [(ngModel)]="model.app_metadata.additionalInfo.underperfValidTill"
            ngbDatepicker
            #dp="ngbDatepicker"
          />

          <div class="input-group-append">
            <button type="button" class="btn btn-secondary" (click)="dp.toggle()">
              <i class="fa fa-calendar" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <!-- Roles -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Roles</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multiselect-dropdown
          name="roles"
          [ngClass]="{ disabled: model.blocked }"
          [options]="roles"
          [settings]="rolesSettings"
          [(ngModel)]="model.app_metadata.roles"
          (ngModelChange)="onRolesChange($event)"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <!-- person identifiers -->
    <div class="form-group row" *ngIf="hasRoleOf('PERSON_ID_REVIEWER')">
      <label class="col-form-label col-sm-4 col-md-2">Person identifiers</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multi-user-select
          [initialUserIds]="model.app_metadata.additionalInfo?.personIdentifiers"
          [selectedUsers]="personIdentifiers"
          [roles]="searchRoleConfig.personIdentifier"
          [filterUsers]="filterPersonIdentifiers"
          (selectedUsersChange)="onSelectedUsersChange('personIdentifiers', $event)"
        >
        </dirt-multi-user-select>
      </div>
    </div>

    <!-- person compilers -->
    <div class="form-group row" *ngIf="hasRoleOf('PERSON_REVIEWER') && !hasRoleOf('PROFILE_REVIEWER')">
      <label class="col-form-label col-sm-4 col-md-2">Person compilers</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multi-user-select
          [initialUserIds]="model.app_metadata.personCompilers"
          [selectedUsers]="personCompilers"
          [roles]="searchRoleConfig.personCompiler"
          [filterUsers]="filterPersonCompilers"
          (selectedUsersChange)="onSelectedUsersChange('personCompilers', $event)"
        >
        </dirt-multi-user-select>
      </div>
    </div>

    <!-- event compilers -->
    <div class="form-group row" *ngIf="hasRoleOf('EVENT_MODERATOR')">
      <label class="col-form-label col-sm-4 col-md-2">Event compilers</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multi-user-select
          [initialUserIds]="model.app_metadata.eventCompilers"
          [selectedUsers]="eventCompilers"
          [roles]="searchRoleConfig.eventCompiler"
          [filterUsers]="filterEventCompilers"
          (selectedUsersChange)="onSelectedUsersChange('eventCompilers', $event)"
        >
        </dirt-multi-user-select>
      </div>
    </div>

    <!-- association compilers -->
    <div class="form-group row" *ngIf="hasRoleOf('ASSOCIATION_MODERATOR')">
      <label class="col-form-label col-sm-4 col-md-2">Association compilers</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multi-user-select
          [initialUserIds]="model.app_metadata.associationCompilers"
          [selectedUsers]="associationCompilers"
          [roles]="searchRoleConfig.associationCompiler"
          [filterUsers]="filterAssociationCompilers"
          (selectedUsersChange)="onSelectedUsersChange('associationCompilers', $event)"
        >
        </dirt-multi-user-select>
      </div>
    </div>

    <!-- event creators -->
    <div class="form-group row" *ngIf="hasRoleOf('EVENT_ID_REVIEWER')">
      <label class="col-form-label col-sm-4 col-md-2">Event Creators</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multi-user-select
          [initialUserIds]="model.app_metadata.additionalInfo?.eventCreators"
          [selectedUsers]="eventCreators"
          [roles]="searchRoleConfig.eventCreator"
          [filterUsers]="filterEventCreators"
          (selectedUsersChange)="onSelectedUsersChange('eventCreators', $event)"
        >
        </dirt-multi-user-select>
      </div>
    </div>

    <!-- association creators -->
    <div class="form-group row" *ngIf="hasRoleOf('ASSOCIATION_ID_REVIEWER')">
      <label class="col-form-label col-sm-4 col-md-2">Association Creators</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multi-user-select
          [initialUserIds]="model.app_metadata.additionalInfo?.associationCreators"
          [selectedUsers]="associationCreators"
          [roles]="searchRoleConfig.associationCreator"
          [filterUsers]="filterAssociationCreators"
          (selectedUsersChange)="onSelectedUsersChange('associationCreators', $event)"
        >
        </dirt-multi-user-select>
      </div>
    </div>

    <!-- guideline creators -->
    <div class="form-group row" *ngIf="hasRoleOf('GUIDELINE_ID_REVIEWER')">
      <label class="col-form-label col-sm-4 col-md-2">Guideline Creators</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multi-user-select
          [initialUserIds]="model.app_metadata.additionalInfo?.guidelineCreators"
          [selectedUsers]="guidelineCreators"
          [roles]="searchRoleConfig.guidelineCreator"
          [filterUsers]="filterGuidelineCreators"
          (selectedUsersChange)="onSelectedUsersChange('guidelineCreators', $event)"
        >
        </dirt-multi-user-select>
      </div>
    </div>

    <!-- User Groups -->
    <div class="form-group row" *ngIf="doesNeedRegion()">
      <label class="col-form-label col-sm-4 col-md-2">Regions</label>
      <div class="col-sm-8 col-md-10" *ngIf="!model.user_id || !!userGroups.length">
        <dirt-multiselect-dropdown
          name="groups"
          [ngClass]="{ disabled: model.blocked }"
          [options]="userGroups"
          [settings]="rolesSettings"
          [(ngModel)]="model.app_metadata.groups"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Region preferences</label>
      <div class="col-sm-8 col-md-10" *ngIf="!!userGroups.length">
        <div *ngFor="let c of model.app_metadata.additionalInfo?.regionPreferences || []; let idx = index">
          #{{ idx + 1 }} - Region(s):
          <dirt-multiselect-dropdown
            name="region-pref-groups-{{ idx }}"
            [ngClass]="{ disabled: model.blocked }"
            [options]="userGroups"
            [settings]="rolesSettings"
            [(ngModel)]="c.groups"
          >
          </dirt-multiselect-dropdown>
          <button [disabled]="model.blocked" (click)="removePref(idx)">-</button>
        </div>
        <div>
          <button [disabled]="model.blocked" (click)="addPrefRow()">+</button>
        </div>
      </div>
    </div>

    <!-- Languages -->
    <div class="form-group row" *ngIf="showSpokenLanguages()">
      <label class="col-form-label col-sm-4 col-md-2">Spoken Languages</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multiselect-dropdown
          name="spoken-languages"
          [ngClass]="{ disabled: model.blocked }"
          [options]="spokenLanguages"
          [settings]="spokenLanguagesSettings"
          [(ngModel)]="model.app_metadata.spokenLanguages"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <!-- Association Pools -->
    <div class="form-group row" *ngIf="hasRoleOf('ASSOCIATION_REVIEWER')">
      <label class="col-form-label col-sm-4 col-md-2">Association Pool</label>
      <div class="col-sm-8 col-md-10" *ngIf="!model.user_id || !!workflowQcPools.length">
        <dirt-multiselect-dropdown
          name="associationPools"
          [ngClass]="{ disabled: model.blocked }"
          [options]="workflowQcPools"
          [settings]="rolesSettings"
          [(ngModel)]="model.app_metadata.associationPools"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <!-- Event Pools -->
    <div class="form-group row" *ngIf="hasRoleOf('EVENT_REVIEWER')">
      <label class="col-form-label col-sm-4 col-md-2">Event Pool</label>
      <div class="col-sm-8 col-md-10" *ngIf="!model.user_id || !!workflowQcPools.length">
        <dirt-multiselect-dropdown
          name="eventPools"
          [ngClass]="{ disabled: model.blocked }"
          [options]="workflowQcPools"
          [settings]="rolesSettings"
          [(ngModel)]="model.app_metadata.eventPools"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <!-- Event projects -->
    <div class="form-group row" *ngIf="hasRoleOf('EVENT_COMPILER')">
      <label class="col-form-label col-sm-4 col-md-2">Projects (Events)</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multiselect-dropdown
          name="eventProjects"
          [ngClass]="{ disabled: model.blocked }"
          [options]="projects"
          [settings]="projectsSettings"
          [(ngModel)]="model.app_metadata.additionalInfo.eventProjects"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <!-- Association projects -->
    <div class="form-group row" *ngIf="hasRoleOf('ASSOCIATION_COMPILER')">
      <label class="col-form-label col-sm-4 col-md-2">Projects (Association)</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multiselect-dropdown
          name="associationProjects"
          [ngClass]="{ disabled: model.blocked }"
          [options]="projects"
          [settings]="projectsSettings"
          [(ngModel)]="model.app_metadata.additionalInfo.associationProjects"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <div
      class="form-group row"
      *ngIf="hasRoleOf('PERSON_COMPILER') || hasRoleOf('PERSON_COMPILER_IDENT') || hasRoleOf('PERSON_CREATOR')"
    >
      <label class="col-form-label col-sm-4 col-md-2">Projects (Person)</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multiselect-dropdown
          name="projects"
          [ngClass]="{ disabled: model.blocked }"
          [options]="personProjects"
          [settings]="projectsSettings"
          [(ngModel)]="model.app_metadata.projectNames"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <div class="form-group row" *ngIf="hasRoleOf('GUIDELINE_COMPILER')">
      <label class="col-form-label col-sm-4 col-md-2">Projects (Guideline)</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multiselect-dropdown
          name="guidelineProjects"
          [ngClass]="{ disabled: model.blocked }"
          [options]="projects"
          [settings]="projectsSettings"
          [(ngModel)]="model.app_metadata.additionalInfo.guidelineProjects"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <div
      class="form-group row"
      *ngIf="hasRoleOf('PROFILE_COMPILER') && (model.app_metadata.profileProjectNames || []).length"
    >
      <!-- phasing out -->
      <label class="col-form-label col-sm-4 col-md-2">Projects (Profile)</label>
      <div class="col-form-label col-sm-8 col-md-10">
        {{ formatPersonProjects(model.app_metadata.profileProjectNames) }}
      </div>
    </div>
    <div class="form-group row" *ngIf="hasRoleOf('PROFILE_COMPILER')">
      <label class="col-form-label col-sm-4 col-md-2">Categories (Profile)</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multiselect-dropdown
          name="profileCategories"
          [ngClass]="{ disabled: model.blocked }"
          [options]="profileCategories"
          [settings]="profileCategoriesSettings"
          [(ngModel)]="model.app_metadata.profileCategories"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>
  </div>
</form>
