<span>
  <input
    #srch
    type="search"
    class="di-search form-control ng-trim-ignore"
    [ngClass]="{ loading: isSearching }"
    [ngbTypeahead]="searchAutoComplete"
    [focusFirst]="false"
    [resultTemplate]="searchResultTemplate"
    (selectItem)="addressChange.emit($event.item)"
    (keyup.escape)="addressChange.emit(null)"
    [(ngModel)]="searchTerm"
    [disabled]="disabled"
  />
</span>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div class="di-sac-name">
    <span>{{ r.formatted }}</span>
    <span class="pull-right" *ngIf="r.isWithinHealthSystem">
      &nbsp;&nbsp;&nbsp;&nbsp;<i class="fa fa-building" style="color: orange"></i>
    </span>
  </div>
</ng-template>
