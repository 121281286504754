import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { VideoProfileModalComponent, LinkedEntityType } from './profile.component';
import { Profile } from '../../../../profile/shared/profile';
import { Person } from '../../../../person/shared/person';
import { Job } from '../../../../jobs/shared/job';

@Injectable()
export class VideoProfileModalService {
  constructor(private svcModal: NgbModal) {}

  open(job: Job): Promise<{ type: LinkedEntityType; data: Profile | Person }> {
    const ref = this.svcModal.open(VideoProfileModalComponent);
    ref.componentInstance.job = job;
    return ref.result;
  }
}
