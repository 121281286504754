import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { AssociationModule } from '../association/association.module';
import { EventModule } from '../event/event.module';
import { PersonModule } from '../person/person.module';
import { ProfileModule } from '../profile/profile.module';
import { MemoModule } from '../memo/memo.module';
import { AdminComponent } from './admin.component';
import { AdminNextComponent } from './next/next.component';
import { AdminForbiddenComponent } from './forbidden/forbidden.component';
import { adminRouting } from './admin.routes';
import { FindByIdComponent } from './find-by-id/find-by-id.component';
import { FindPersonComponent } from './find-by-id/find-person.component';
import { FindProfileComponent } from './find-by-id/find-profile.component';
import { ProjectTagsModule } from '../project-tags/project-tags.module';
import { GuidelineModule } from '../guideline/guideline.module';
import { SentimentModule } from '../sentiment/sentiment.module';
import { AffiliationModule } from '../affiliation/affiliation.module';
import { UserAPI } from '../user/shared/api.service';
import { AccountModule } from '../account/account.module';
import { JobsModule } from '../jobs/jobs.module';
import { UserPoolModule } from '../user-pool/user-pool.module';
import { ClinicalTrialProfileModule } from '../clinical-trial-profile/clinical-trial-profile.module';
import { DomainModule } from '../domain/domain.module';
import { CommitteeModule } from '../committee/committee.module';
import { DocumentModule } from '../document/document.module';
import { EventSeriesModule } from '../event-series/event-series.module';
import { OrganizationModule } from '../organizations/organization.module';
import { SponsorModule } from '../sponsors/sponsors.module';
import { ClinicalTrialSponsorModule } from '../clinical-trial-sponsor/clinical-trial-sponsor.module';
import { NoopComponent } from './noop/noop.component';
import { MedicalInsightsProfileModule } from 'app/medical-insights-profile/medical-insights-profile.module';
import { OrganizationAffiliationModule } from '../organization-affiliation/organization-affiliation.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    EventModule,
    AssociationModule,
    PersonModule,
    ProfileModule,
    MemoModule,
    ProjectTagsModule,
    UserPoolModule,
    SponsorModule,
    GuidelineModule,
    SentimentModule,
    AffiliationModule,
    AccountModule,
    JobsModule,
    MedicalInsightsProfileModule,
    ClinicalTrialProfileModule,
    DomainModule,
    CommitteeModule,
    DocumentModule,
    EventSeriesModule,
    OrganizationAffiliationModule,
    OrganizationModule,
    ClinicalTrialSponsorModule,
    adminRouting,
  ],
  declarations: [
    AdminComponent,
    AdminNextComponent,
    AdminForbiddenComponent,
    FindByIdComponent,
    FindPersonComponent,
    FindProfileComponent,
    NoopComponent,
  ],
  providers: [UserAPI],
})
export class AdminModule {}
