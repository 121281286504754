<div
  class="di-marker-wrapper"
  [ngbPopover]="popoverContent"
  [popoverTitle]="popoverTitle"
  [autoClose]="false"
  [ngClass]="{ 'is-marked': !!errors[0].comment || !!!!errors[0].type }"
  popoverClass="di-marker-popover"
  popoverTitle="Mark Field"
  triggers="manual"
  [placement]="
    leftAlign || fieldName === 'firstName' || fieldName === 'originalFirstName' ? 'bottom-left' : 'bottom-right'
  "
  (hidden)="onPopoverClose()"
>
  <!-- Original Content Injection -->
  <ng-container *ngTemplateOutlet="template"></ng-container>

  <!-- Annotate Trigger -->
  <div class="di-annotate" *ngIf="isShown && !popover.isOpen()" (click)="popover.open()">
    <span>
      <i class="fa fa-bug fa-inverse" aria-hidden="true"></i>
    </span>
  </div>
</div>

<!-- Header -->
<ng-template #popoverTitle>
  <div class="float-right">
    <!-- Close -->
    <a class="btn btn-sm" (click)="doDiscard()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
    <!-- Submit (normal button, otherwise this cascades for no good; alt would be preventDefault / stopPropagation) -->
    <button
      type="button"
      class="btn btn-sm"
      ngbTooltip="Apply"
      [disabled]="isSubmitDisabled()"
      (click)="doSave()"
      *diAcl="
        this.entity + '.qc || ' + this.entity + '.idqc || ' + this.entity + '.imageqc || ' + this.entity + '.idbdqc'
      "
    >
      <i class="fa fa-check" aria-hidden="true"></i>
    </button>
  </div>

  <span>Mark Field</span>
  <a
    class="btn btn-sm"
    ngbTooltip="Unmark"
    [hidden]="isSubmitDisabled()"
    (click)="doRemove()"
    *diAcl="this.entity + '.qc || ' + this.entity + '.idqc || ' + this.entity + '.idbdqc'"
  >
    <i class="fa fa-trash" aria-hidden="true"></i>
  </a>
</ng-template>

<!-- Content -->
<ng-template #popoverContent>
  <ng-container *ngIf="hasPermission() && canSetChecked()">
    <label for="checked">Checked</label>
    <input id="checked" [(ngModel)]="checked" (change)="onCheckedChange()" type="checkbox" />
  </ng-container>

  <div *ngFor="let error of errors; last as isLast; first as isFirst">
    <div
      *ngIf="errorTypes.length"
      class="d-inline-block di-qc-error-type-wrapper"
      ngbDropdown
      [placement]="fieldName === 'affiliations' || fieldName === 'affiliationsLfka' ? 'left' : 'auto'"
      #myDrop="ngbDropdown"
    >
      <button
        class="btn btn-link mr-2 di-qc-error-type-btn"
        id="dropdownManual"
        [disabled]="!hasPermission()"
        ngbDropdownAnchor
        (click)="onDropClick($event, myDrop)"
      >
        {{ !error.type ? 'Select error type' : error.type.error }}
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownManual" class="di-error-type-container">
        <div class="di-error-type-list">
          <div
            *ngFor="let errorType of errorTypes"
            class="di-error-line-wrapper"
            (click)="onErrorTypeClick(error, errorType); $event.stopPropagation()"
          >
            <div class="di-check-error">
              <i
                class="fa fa-check"
                *ngIf="error?.type?.error === errorType.error"
                [ngClass]="{ 'di-selected': error?.type?.error === errorType.error }"
                aria-hidden="true"
              ></i>
            </div>
            <div class="di-error-type-label">
              {{ errorType.error }}
            </div>
            <div class="di-info-icon-wrapper">
              <i
                *ngIf="errorType.info"
                placement="right"
                [ngbTooltip]="errorType.info"
                class="fa fa-info-circle"
                aria-hidden="true"
              >
              </i>
            </div>
          </div>
        </div>
      </div>
    </div>

    <textarea
      class="form-control"
      rows="2"
      [(ngModel)]="error.comment"
      placeholder="Write your comment..."
      *ngIf="hasPermission()"
    ></textarea>
    <div class="form-control massage-container" *ngIf="!hasPermission()">{{ error.comment }}</div>

    <div>
      <label><input [disabled]="!hasPermission()" [(ngModel)]="error.fixed" type="checkbox" /> Fixed</label>
    </div>

    <button
      class="btn"
      *ngIf="!isFirst || errors.length > 1"
      [disabled]="!hasPermission()"
      (click)="onRemoveErrorClick(error)"
    >
      <i class="fa fa-minus"></i>
    </button>
    <button
      class="btn"
      *ngIf="isLast && errorTypes.length"
      [disabled]="!hasPermission()"
      (click)="onAddNewErrorClick()"
    >
      <i class="fa fa-plus"></i>
    </button>
  </div>
</ng-template>
