<form #frmEventSeries="ngForm" novalidate>
  <div class="container-fluid">
    <!-- Name -->
    <div class="form-group row">
      <label class="col-form-label col-md-3" for="name">Name</label>
      <div class="col-md-9">
        <input
          id="name"
          class="form-control"
          name="name"
          [(ngModel)]="model.name"
          [readonly]="!isFieldEditable('name')"
          required
        />
      </div>
    </div>

    <!-- Original Language -->
    <div class="form-group row" *ngIf="model.id">
      <label class="col-form-label col-md-3" for="originalLanguage">Original Language</label>
      <div class="col-md-9">
        <select
          id="originalLanguage"
          class="form-control custom-select"
          name="originalLanguage"
          [(ngModel)]="model.originalLanguage"
          [disabled]="!isFieldEditable('originalLanguage')"
        >
          <option *ngFor="let language of languages$ | async" [value]="language.code">{{ language.title }}</option>
        </select>
      </div>
    </div>

    <!-- Type -->
    <div class="form-group row">
      <label class="col-form-label col-md-3" for="type">Type</label>
      <div class="col-md-9">
        <select
          id="type"
          class="form-control custom-select"
          name="type"
          [(ngModel)]="model.type"
          (ngModelChange)="onTypeChange($event)"
          [disabled]="!isFieldEditable('type')"
          required
        >
          <option *ngFor="let type of types" [value]="type.code" [disabled]="type.disabled">
            {{ type.title }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-md-3">Projects</label>
      <div class="col-md-2 di-project-names">
        <dirt-multiselect-dropdown
          name="projectNames"
          [ngClass]="{ disabled: !isFieldEditable('projectNames') }"
          [options]="projects"
          [settings]="projectsSettings"
          [(ngModel)]="model.projectNames"
          required
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <!-- Category -->
    <div class="form-group row">
      <label class="col-form-label col-md-3" for="category">Category</label>
      <div class="col-md-9">
        <select
          id="category"
          class="form-control custom-select"
          name="category"
          [(ngModel)]="model.category"
          [disabled]="!isFieldEditable('category')"
          required
        >
          <option *ngFor="let category of categories" [value]="category.code">
            {{ category.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Country -->
    <div class="form-group row">
      <label class="col-form-label col-md-3" for="country">Country</label>
      <div class="col-md-3">
        <select
          class="form-control custom-select"
          name="country"
          [(ngModel)]="model.country"
          [disabled]="!isFieldEditable('country')"
          required
        >
          <option *ngFor="let country of countries" [value]="country.code">
            {{ country.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Websources -->
    <div class="form-group row" *ngFor="let affLink of model.webSources; let idx = index; trackBy: trackByIndex">
      <label class="col-form-label col-md-3">Websource</label>
      <div class="col-md-9">
        <dirt-link
          [(url)]="model.webSources[idx]"
          name="webSources-{{ idx }}"
          [form]="ngForm"
          [required]="isFieldEditable('webSources') && 0 === idx"
          [disabled]="!isFieldEditable('webSources')"
          [nonCompliantDomainType]="nonCompliantDomainType"
        >
          <button
            type="button"
            class="btn btn-secondary"
            (click)="model.webSources.push('')"
            *ngIf="idx === model.webSources.length - 1 && isFieldEditable('webSources')"
          >
            <i class="fa fa-plus-circle"></i>
          </button>

          <button
            type="button"
            class="btn btn-secondary"
            (click)="model.webSources.splice(idx, 1)"
            *ngIf="idx > 0 && isFieldEditable('webSources')"
          >
            <i class="fa fa-minus-circle"></i>
          </button>
        </dirt-link>
      </div>
    </div>

    <!-- Not Happening Anymore -->
    <div class="form-group row">
      <label class="col-form-label col-md-3">Not Happening Anymore</label>
      <div class="col-md-9 pt-1">
        <ui-switch
          size="small"
          color="#f9d975"
          name="notHappeningAnymore"
          [(ngModel)]="model.notHappeningAnymore"
          [disabled]="!isFieldEditable('notHappeningAnymore')"
        ></ui-switch>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-md-3" for="ownerProduct">Owner product</label>

      <div class="col-md-9 input-group">
        <select
          id="ownerProduct"
          class="form-control custom-select"
          name="ownerProduct"
          [(ngModel)]="model.ownerProduct"
          [required]="!model.id"
          [disabled]="model.id && !isFieldEditable('ownerProduct')"
        >
          <option *ngFor="let product of products" [value]="product.value">
            {{ product.value }}
          </option>
        </select>
      </div>
    </div>
  </div>
</form>
