import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { NgForm } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { IMultiSelectSettings } from '../multiselect-dropdown/types';
import { Person } from '../../../person/shared/person';
import { Value } from '../../services/value/value';
import { ValueAPI } from '../../services/value/value-api.service';
import { ValueType } from '../../enum/value-type.enum';

const CLS_MULTISELECT_BTN = 'btn btn-sm btn-secondary';

@Component({
  selector: 'dirt-person-form-inline',
  templateUrl: './person-form-inline.component.html',
  styleUrls: ['./person-form-inline.component.scss'],
})
/**
 * Embeddable person form that contains only mandatory fields.
 */
export class PersonFormInlineComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  model: Person = new Person();

  @Input()
  disabled?: boolean;

  @Input()
  disabledFields?: string[];

  @Input()
  checkDomainCompliance: boolean = true;

  @Input()
  multiCvLink: number = 1;

  @Input()
  displayCountry: 'MANDATORY' | 'OPTIONAL' | 'NONE' = 'NONE';

  @Input()
  disableDisabledCountries: boolean = false; // only if we have country

  @Input()
  entity?: string;

  @Input()
  area?: string;

  @Output()
  validityChange: EventEmitter<'VALID' | 'INVALID'> = new EventEmitter();

  @Output()
  projectNamesChange: EventEmitter<true> = new EventEmitter();

  projectsDelivery: {
    id: string | number;
    name: string;
    disabled: boolean;
  }[] = [];

  projectsTechnical: {
    id: string | number;
    name: string;
    disabled: boolean;
  }[] = [];

  projectNamesDelivery: string[] = [];
  projectNamesTechnical: string[] = [];

  projectsSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    dynamicTitleMaxItems: 5,
    position: 'top',
  };

  sources: Value[] = [];
  countries: Value[] = [];
  suffixes: Value[] = [];

  @ViewChild(NgForm)
  private personForm: NgForm;

  private destroy$: Subject<boolean> = new Subject();

  constructor(private readonly svcValue: ValueAPI, private readonly changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    forkJoin({
      projects: this.svcValue.find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER, 0, '+title'),
      sources: this.svcValue.find(ValueType.PersonSource, Number.MAX_SAFE_INTEGER),
      ...('NONE' === this.displayCountry
        ? {}
        : { countries: this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title') }),
      suffixes: this.svcValue.find(ValueType.PersonSuffix, Number.MAX_SAFE_INTEGER),
    }).subscribe((data) => {
      this.changeDetector.detach(); // we'll do a change detection once and for all

      this.sources = data.sources;
      this.countries = data.countries || [];
      this.suffixes = data.suffixes;

      data.projects.forEach((p: any) => {
        const project = {
          id: p.code,
          name: p.title,
          disabled: false,
        };

        if (p.technical) {
          this.projectsTechnical.push(project);
        } else {
          this.projectsDelivery.push(project);
        }
      });

      // Break ref to rerender
      this.projectsTechnical = [...this.projectsTechnical];
      this.projectsDelivery = [...this.projectsDelivery];

      this.initiateProjectNames();

      // Keep that at the bottom after you've affected all the values!
      this.changeDetector.reattach();
      this.changeDetector.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.personForm.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      switch (this.personForm.status) {
        case 'DISABLED':
        case 'VALID':
          this.validityChange.emit('VALID');
          break;
        case 'INVALID':
          this.validityChange.emit('INVALID');
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  onProjectTagChangeForDelivery(projects: string[]): void {
    this.projectNamesDelivery = projects;
    this.model.projectNames = Array.from(
      new Set([...(this.model?.projectNames || [])].concat(projects, this.projectNamesTechnical))
    );
    this.projectNamesChange.emit(true);
  }

  onProjectTagChangeForTechnical(projects: string[]): void {
    this.projectNamesTechnical = projects;
    this.model.projectNames = Array.from(
      new Set([...(this.model?.projectNames || [])].concat(projects, this.projectNamesDelivery))
    );
    this.projectNamesChange.emit(true);
  }

  trackByIndex(idx: number): number {
    return idx;
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }

  isFieldDisabled(field: string): boolean {
    return this.disabled || (this.disabledFields || []).includes(field);
  }

  initiateProjectNames(): void {
    this.model?.projectNames?.forEach((pName) => {
      if (this.projectsTechnical.find((pT) => pT.id === pName)) {
        this.projectNamesTechnical.push(pName);
      } else if (this.projectsDelivery.find((pT) => pT.id === pName)) {
        this.projectNamesDelivery.push(pName);
      }
    });
  }
}
