import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  GET,
  POST,
  DELETE,
  Path,
  Body,
  Query,
  MediaType,
  Produces,
  PATCH,
} from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { Association } from './association';
import { User } from '../../user/shared/user';
import { catchError, map } from 'rxjs/operators';
import { Affiliation } from '../../affiliation/shared/affiliation';

@Injectable()
export class AssociationAPI extends APIService {
  @GET('associations/')
  @Produces(MediaType.JSON)
  public find(
    @Query('name') name?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any,
    @Query('parentId') parentId?: string
  ): Observable<Association[]> {
    return;
  }

  @GET('associations/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Association> {
    return;
  }

  @GET('associations/count')
  @Produces(MediaType.JSON)
  public count(
    @Query('associationId') associationId?: string,
    @Query('name') name?: string,
    @Query('filter') filter?: string
  ): Observable<any> {
    return;
  }

  @POST('associations')
  @Produces(MediaType.JSON)
  public create(@Body asso: Association): Observable<any> {
    return;
  }

  @PATCH('associations/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body association: Association): Observable<Association> {
    return;
  }

  @POST('associations/exist')
  @Produces(MediaType.JSON)
  public exist(@Body ids: string[]): Observable<{ invalid: boolean }[]> {
    return;
  }

  @POST('associations/bulk-update')
  @Produces(MediaType.JSON)
  public bulkUpdate(@Body data: { ids: string[]; values: { [key: string]: any } }): Observable<{ bulkTrackingId }> {
    return;
  }

  @PATCH('associations/{id}')
  @Produces(MediaType.JSON)
  public upsert(@Path('id') id: string, @Body asso: Association): Observable<any> {
    return;
  }

  @DELETE('associations/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }

  @GET('associations/next')
  @Produces(MediaType.JSON)
  public findNext(): Observable<Association> {
    return;
  }

  @GET('associations/distinct')
  @Produces(MediaType.JSON)
  public distinct(@Query('field') field: string, @Query('filter') filter?: any): Observable<string[]> {
    return;
  }

  /**
   * Find models which are part of the given user's workflow
   *
   * @method queue
   * @param {String} userId
   * @returns {Observable}
   */
  @GET('associations/queue')
  @Produces(MediaType.JSON)
  public queue(@Query('userId') userId?: string): Observable<any> {
    return;
  }

  /**
   * Find all the audit logs of a given model id
   * @param id
   */
  @GET('associations/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  /**
   * Find count of audit logs
   * @param id
   */
  @GET('associations/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }

  @GET('associations/search')
  @Produces(MediaType.JSON)
  public search(
    @Query('name') name?: string,
    @Query('originalName') originalName?: string,
    @Query('webSource') webSource?: string,
    @Query('rootOnly') rootOnly?: boolean,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number
  ): Observable<Association[]> {
    return;
  }

  @GET('associations/search-elastic')
  @Produces(MediaType.JSON)
  public searchElastic(
    @Query('term') term: string,
    @Query('limit') limit?: number,
    @Query('rootOnly') rootOnly?: boolean
  ): Observable<Affiliation> {
    return;
  }

  /**
   * Find all the distinct values of the `_meta.assignee` field
   */
  @GET('associations/reviewers')
  @Produces(MediaType.JSON)
  public reviewers(): Observable<User[]> {
    return;
  }

  public getLogoPreview(source: string): Observable<any> {
    return this.http.get(this.getBaseUrl() + `associations/logo-preview?source=${source}`); // bypass APIService interceptor
  }

  public startBulkListUpload(file: File, secondEyes: string): Observable<string> {
    return this.doBulkUpload(file, secondEyes, 'associations/bulk-list');
  }

  public startBulkSearchUpload(file: File, secondEyes: string): Observable<string> {
    return this.doBulkUpload(file, secondEyes, 'associations/bulk-search');
  }

  /**
   * Upload the file and get a bulk upload ID
   *
   * @param file
   * @param secondEyes
   */
  private doBulkUpload(file: File, secondEyes: string, endpoint: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);

    return this.http
      .post(this.getBaseUrl() + endpoint, formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj: any) => obj.bulkTrackingId));
  }

  @GET('association/id-qc-queue')
  @Produces(MediaType.JSON)
  public idQcQueue(): Observable<any> {
    return;
  }

  @GET('association/id-qc-next')
  @Produces(MediaType.JSON)
  public findIDQCNext(@Query('exceedCap') exceedCap?: boolean): Observable<string | undefined> {
    return;
  }

  @GET('association/qc-queue')
  @Produces(MediaType.JSON)
  public qcQueue(): Observable<any> {
    return;
  }

  @GET('association/qc-next')
  @Produces(MediaType.JSON)
  public findQcNext(@Query('exceedCap') exceedCap?: boolean): Observable<string | undefined> {
    return;
  }

  @GET('associations/creators')
  @Produces(MediaType.JSON)
  public creators(): Observable<User[]> {
    return;
  }

  @GET('association/qc-pool-stats')
  @Produces(MediaType.JSON)
  public qcPoolStats(): Observable<User[]> {
    return;
  }
}
