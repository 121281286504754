import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ColDef, ColGroupDef, RowNode } from 'ag-grid';
import { ValueFormatterParams } from 'ag-grid/dist/lib/entities/colDef';

import { ACL } from '../../../../shared/acl/acl.service';
import { ProfileGridComponent } from '../../../shared/grid/grid.component';
import { ProfileDirectLinkService } from '../../../shared/modal/direct-link/direct-link.service';
import { ProfileDirectLinkOptions } from '../../../shared/modal/direct-link/direct-link-options.interface';
import { Contribution } from '../../../../contribution/shared/contribution';
import { ContributionAPI } from '../../../../contribution/shared/contribution-api.service';
import { ProfileContributionAPI } from '../shared/api.service';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { QCCommentService } from '../../../shared/grid/qc-comment/qc-comment.service';
import { ProfileContribution } from '../../../shared/profile-contribution';

@Component({
  selector: 'dirt-profile-contribution-list',
  templateUrl: '../../../shared/grid/grid.component.html',
  styleUrls: ['../../../shared/grid/grid.component.scss'],
})
export class ProfileContributionListComponent extends ProfileGridComponent implements OnInit {
  FIELD_LINKS = 'event.webSource';
  FIELD_FIRST_NAME = 'firstName';
  FIELD_LAST_NAME = 'lastName';
  FIELD_MIDDLE_NAME = 'middleName';
  FIELD_FULL_NAME = 'fullName';
  FIELD_EVENT_NAME = 'event.name';
  FIELD_POSITION = 'position';
  FIELD_COUNTRY = 'event.address.country';
  FIELD_SESSION_TITLE = 'session.name';
  FIELD_CONTRIBUTION_TITLE = 'title';
  FIELD_WORKPLACE = 'workplace';
  FIELD_EVENT_START_YEAR = 'event.startDate';

  FIELD_FILTER_COUNTRY = 'country';
  FIELD_FILTER_EVENT_START_YEAR = 'startDate';

  constructor(
    protected svcProfileContribution: ProfileContributionAPI,
    private svcContribution: ContributionAPI,
    private svcDirectLink: ProfileDirectLinkService,
    protected readonly svcACL: ACL,
    protected svcQCComment: QCCommentService
  ) {
    super('id', svcACL, svcQCComment);
  }

  ngOnInit() {
    this.addFilter(this.FIELD_POSITION, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_POSITION, params)
    );
    this.addFilter(this.FIELD_FIRST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_FIRST_NAME, params)
    );
    this.addFilter(this.FIELD_MIDDLE_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_MIDDLE_NAME, params)
    );
    this.addFilter(this.FIELD_LAST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_LAST_NAME, params)
    );
    this.addFilter(this.FIELD_FULL_NAME, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FULL_NAME, params)
    );
    this.addFilter(this.FIELD_FILTER_COUNTRY, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_COUNTRY, params)
    );
    this.addFilter(this.FIELD_FILTER_EVENT_START_YEAR, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_EVENT_START_YEAR, params)
    );
    this.addFilter(this.FIELD_KEY_FA, true, 'keep', false, (params) =>
      this.defineGetFocusArea().subscribe((result) =>
        result && result[0] && result[0].focusArea
          ? params.success(result[0].focusArea?.map((val) => val.name))
          : params.success('')
      )
    );
    super.ngOnInit();
  }

  protected defineGetDataRows(...params): Observable<any> {
    return this.svcProfileContribution.find.apply(this.svcProfileContribution, [this.profile, ...params.slice(1)]); // migrating profileId to profile
  }

  protected defineDistinct(profileId: string, field: string, filter: any): Observable<string[]> {
    return this.svcProfileContribution.distinct(this.profile, field, filter);
  }

  protected defineUpdate(
    profileId: string,
    ids: string[],
    value: ProfileContribution[]
  ): Observable<{ id; compiledAt? }[]> {
    return this.svcProfileContribution.update(this.profile, value);
  }

  protected defineGetFocusArea(): Observable<any> {
    return this.svcProfileContribution.distinctFocusArea(this.profile);
  }

  protected defineGrid(): (ColDef | ColGroupDef)[] {
    const columnDefs: (ColDef | ColGroupDef)[] = [
      {
        headerName: 'Contributor',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'First Name',
            field: this.FIELD_FIRST_NAME,
            filterParams: this.filters.get(this.FIELD_FIRST_NAME),
            width: 135,
          },
          {
            headerName: 'Middle Name',
            field: this.FIELD_MIDDLE_NAME,
            filterParams: this.filters.get(this.FIELD_MIDDLE_NAME),
            width: 70,
          },
          {
            headerName: 'Last Name',
            field: this.FIELD_LAST_NAME,
            filterParams: this.filters.get(this.FIELD_LAST_NAME),
            width: 130,
          },
          {
            headerName: 'Full Name',
            field: this.FIELD_FULL_NAME,
            filterParams: this.filters.get(this.FIELD_FULL_NAME),
            width: 90,
          },
          {
            headerName: 'Position',
            valueFormatter: this.formatterPosition,
            field: this.FIELD_POSITION,
            filterParams: this.filters.get(this.FIELD_POSITION),
            columnGroupShow: 'open',
            width: 110,
          },
          {
            headerName: 'Workplace',
            field: this.FIELD_WORKPLACE,
            filter: 'text',
            width: 250,
          },
        ],
      },
      {
        headerName: 'Link',
        cellRenderer: this.rendererLinks,
        field: this.FIELD_LINKS,
        suppressMenu: true,
        width: 50,
      },
      {
        headerName: 'Event',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'Event Name',
            field: this.FIELD_EVENT_NAME,
            filter: 'text',
            width: 400,
          },
          {
            headerName: 'Session Title',
            field: this.FIELD_SESSION_TITLE,
            filter: 'text',
            width: 230,
          },
          {
            headerName: 'Contribution Title',
            field: this.FIELD_CONTRIBUTION_TITLE,
            filter: 'text',
            width: 230,
          },
          {
            headerName: 'Date',
            field: this.FIELD_EVENT_START_YEAR,
            filterParams: this.filters.get(this.FIELD_FILTER_EVENT_START_YEAR),
            columnGroupShow: 'open',
            width: 45,
          },
          {
            headerName: 'Country',
            field: this.FIELD_COUNTRY,
            filterParams: this.filters.get(this.FIELD_FILTER_COUNTRY),
            columnGroupShow: 'open',
            width: 90,
          },
        ],
      },
      {
        headerName: 'Focus Areas',
        field: this.FIELD_KEY_FA,
        filterParams: this.filters.get(this.FIELD_KEY_FA),
        width: 500,
      },
    ];
    return columnDefs;
  }

  protected defineAutoSizableColumns(): string[] {
    return [];
  }

  protected onDirectLink(): void {
    const options = <ProfileDirectLinkOptions>{
      title: 'Contribution',
      getEntity: (id) => this.svcContribution.findById(id),
      getData: (c: Contribution) => ({
        name: [c.person.lastName, c.person.firstName].join(', '),
        position: c.person.position,
        link: ['/contribution/detail', c.id],
      }),
    };

    this.svcDirectLink
      .open(options)
      .then((entity) => this.svcProfileContribution.link(this.profile, entity.id).toPromise())
      .then(() => this.reloadData());
  }

  private formatterPosition(param: ValueFormatterParams): string {
    if (!param.value) {
      return;
    }
    return param.value.charAt(0) + param.value.slice(1).toLowerCase();
  }

  /**
   * Renderer function for Links columns.
   *
   * @param {ValueFormatterParams} param
   * @return {string}
   */
  private rendererLinks(param: ValueFormatterParams): string {
    if (!param.data) {
      return '';
    }
    const webSource = param.data.event.webSource;
    const linkWS = webSource ? `<a href='${webSource}' rel=noopener target='_blank'>W</a>` : '';
    return `${linkWS}`;
  }

  /** @override */
  updateCommentAndProfileMatch(comment: string, matched: boolean, errorType: string): void {
    const nodes = this.getSelection();
    const match = matched ? ProfileMatchManual.MATCH : ProfileMatchManual.MISMATCH;

    nodes.forEach((node: RowNode) => {
      const data = Object.assign({}, node.data); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables

      data._profile.match.manual = match;

      // even if comment and errorType are null we should set qc so that thebug icon disappears
      data._profile.qc = { comment, errorType };

      node.setData(data);
    });

    this.update(
      nodes,
      nodes.map(
        (n) =>
          ({
            contributionId: n.data._profile.contributionId,
            match: n.data._profile.match,
            person: { id: this.profile.person.id, kolId: this.profile.person.kolId },
            profileId: this.profileId,
            qc: n.data._profile.qc,
          } as Partial<ProfileContribution>)
      )
    );
  }
}
