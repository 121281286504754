export const NonLatinCountries = [
  'Algeria',
  'Bahrain',
  'China',
  'Egypt',
  'Hong Kong',
  'India',
  'Iraq',
  'Israel',
  'Kuwait',
  'Lebanon',
  'Japan',
  'Jordan',
  'Macau',
  'Morocco',
  'Oman',
  'Qatar',
  'Saudi Arabia',
  'Singapore',
  'South Korea',
  'Taiwan',
  'Thailand',
  'Tunisia',
  'UAE',
  'United Arab Emirates',
];
