<div class="clearfix">
  <form #ngForm="ngForm" novalidate>
    <!-- Title -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Title</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          class="form-control"
          name="title"
          [(ngModel)]="model.title"
          [readonly]="!isFieldEditable('title')"
          autofocus
          required
          longDash
        />
      </div>
    </div>

    <!-- Type -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Type</label>
      <div class="col-md-3">
        <select
          class="form-control custom-select"
          name="type"
          [(ngModel)]="model.type"
          (ngModelChange)="onTypeChanges($event)"
          [disabled]="!isFieldEditable('type')"
          required
        >
          <option *ngFor="let type of types" [value]="type.code">
            {{ type.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Category -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Category</label>
      <div class="col-md-3">
        <input type="text" class="form-control" name="category" [(ngModel)]="displayCategory" required readonly />
      </div>
    </div>

    <!-- Country -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Country</label>
      <div class="col-md-3">
        <select
          class="form-control custom-select"
          name="country"
          [(ngModel)]="model.country"
          [disabled]="!isFieldEditable('country')"
          required
        >
          <option *ngFor="let country of countries$ | async" [value]="country.code">
            {{ country.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- URL -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">URL</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.url"
          name="url"
          [form]="ngForm"
          [disabled]="!isFieldEditable('url')"
          [required]="true"
          (urlChange)="onDocumentUrlChange($event)"
        ></dirt-link>
      </div>
    </div>

    <!-- Format -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Format</label>
      <div class="col-md-3">
        <div class="input-wrapper">
          <select
            class="form-control custom-select"
            name="format"
            [(ngModel)]="model.format"
            (ngModelChange)="onFormatChange()"
            [disabled]="!isFieldEditable('format') || (isLoadingFormat$ | async)"
            required
          >
            <option *ngFor="let format of formats$ | async" [value]="format.code">
              {{ format.title }}
            </option>
          </select>

          <div class="spinner-wrapper">
            <i class="fa fa-pulse fa-spinner di-va-super" [hidden]="!(isLoadingFormat$ | async)"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- Version -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Version</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          class="form-control"
          name="documentVersion"
          [(ngModel)]="model.documentVersion"
          [readonly]="!isFieldEditable('documentVersion')"
        />
      </div>
    </div>

    <!-- Previous Version -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Previous Version</label>
      <div class="col-sm-8 col-md-10 input-group">
        <input
          type="search"
          class="di-search form-control ng-trim-ignore"
          name="previousVersion"
          [(ngModel)]="model.previousVersion"
          [readonly]="!isFieldEditable('previousVersion')"
          [ngbTypeahead]="onSearchDocument"
          [resultTemplate]="searchResultTemplate"
          [inputFormatter]="formatTitle"
          [editable]="false"
          (ngModelChange)="onPrevDocChanged($event)"
        />

        <div class="input-group-append">
          <a
            class="btn btn-secondary"
            href="/document/detail/{{ model.previousVersion.id }}"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Open document in a new tab"
            *ngIf="model.previousVersion"
          >
            <i class="fa fa-external-link dirt-link-pointer" aria-hidden="true"></i>
          </a>

          <div class="input-group-text">
            <i class="fa fa-search" aria-hidden="true" [hidden]="isSearchingPreviousVersion"></i>
            <i class="fa fa-refresh fa-spin" [hidden]="!isSearchingPreviousVersion"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- Status -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Status</label>
      <div class="col-md-3">
        <select
          class="form-control custom-select"
          name="status"
          [(ngModel)]="model.status"
          (ngModelChange)="onStatusChange()"
          [disabled]="!isFieldEditable('status')"
          required
        >
          <option *ngFor="let status of documentStatuses | keyvalue" [value]="status.key">
            {{ status.value }}
          </option>
        </select>
      </div>
    </div>

    <!-- Source URL -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Websource</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.sourceUrl"
          name="sourceUrl"
          [form]="ngForm"
          [disabled]="!isFieldEditable('sourceUrl')"
        ></dirt-link>
      </div>
    </div>

    <!-- Publisher -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Publisher</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          class="form-control"
          name="publisher"
          [(ngModel)]="model.publisher"
          [readonly]="!isFieldEditable('publisher') || model.publisherNotFound"
          [required]="!model.publisherNotFound"
        />
      </div>
    </div>

    <!-- Publish not found -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Publisher not found</label>
      <div class="col-sm-8 col-md-10 pt-1">
        <ui-switch
          size="small"
          color="#6d5cae"
          [checked]="model.publisherNotFound === true"
          [disabled]="!isFieldEditable('publisher') || !model._id"
          (change)="onPublisherNotFoundChange($event)"
        >
        </ui-switch>
      </div>
    </div>

    <!-- Publication Date -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Publication Date</label>
      <div class="col-sm-8 col-md-10">
        <dirt-date-input
          name="publishedDate"
          [(value)]="model.publishedDate"
          [form]="ngForm"
          [required]="true"
          [disabled]="!isFieldEditable('publishedDate')"
          (valueChange)="publishDateValidator($event)"
        >
        </dirt-date-input>
      </div>
    </div>

    <!-- Effective Start Date -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Effective Start Date</label>
      <div class="col-sm-8 col-md-10">
        <dirt-date-input
          name="effectiveStartDate"
          [(value)]="model.effectiveStartDate"
          [form]="ngForm"
          [disabled]="!isFieldEditable('effectiveStartDate')"
          [required]="!!model.effectiveEndDate"
          (valueChange)="effectiveDateValidator($event, 'effectiveStartDate')"
        >
        </dirt-date-input>
      </div>
    </div>

    <!-- Effective End Date -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Effective End Date</label>
      <div class="col-sm-8 col-md-10">
        <dirt-date-input
          name="effectiveEndDate"
          [(value)]="model.effectiveEndDate"
          [form]="ngForm"
          [disabled]="!isFieldEditable('effectiveEndDate')"
          (valueChange)="effectiveDateValidator($event, 'effectiveEndDate')"
        >
        </dirt-date-input>
      </div>
    </div>

    <!-- Planned Review Date -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Planned Review Date</label>
      <div class="col-sm-8 col-md-10">
        <dirt-date-input
          name="plannedReviewDate"
          [(value)]="model.plannedReviewDate"
          [form]="ngForm"
          [disabled]="!isFieldEditable('plannedReviewDate')"
        >
        </dirt-date-input>
      </div>
    </div>

    <!-- Connection type -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Connection Type</label>
      <div class="col-md-3">
        <select
          class="form-control custom-select"
          name="connectionType"
          [(ngModel)]="model.connectionType"
          [disabled]="!isFieldEditable('connectionType')"
          required
        >
          <option *ngFor="let connectionType of documentConnectionTypes | keyvalue" [value]="connectionType.key">
            {{ connectionType.value }}
          </option>
        </select>
      </div>
    </div>

    <!-- Account Connections -->
    <div class="form-group row" [hidden]="model.connectionType === 'EXTERNAL'">
      <label class="col-form-label col-sm-4 col-md-2">Account Connections</label>
      <div class="col-sm-8 col-md-10">
        <dirt-document-connections
          [connections]="accountConnections"
          [disabled]="!isFieldEditable('connections')"
          [searchFunction]="onSearchAccountConnection"
          #accountConnectionsComponent="documentConnectionsComponent"
        >
        </dirt-document-connections>
      </div>
    </div>

    <!-- Organization Connections -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Organizations Connections</label>
      <div class="col-sm-8 col-md-10">
        <dirt-document-connections
          [connections]="affiliationConnections"
          [disabled]="!isFieldEditable('connections')"
          [searchFunction]="onSearchAffiliationConnection"
          [connectionRequestHandler]="canRequestAffiliation ? onRequestAffiliationConnection : null"
          #affiliationConnectionsComponent="documentConnectionsComponent"
        >
        </dirt-document-connections>
      </div>
    </div>
  </form>
</div>

<!-- Typeahead Pool Item Template -->
<ng-template #searchResultTemplate let-res="result" let-t="term">
  <span class="di-sac-title">{{ res.title }}</span>
  <span class="di-sac-version badge badge-info">{{ res.documentVersion }}</span>
</ng-template>
