<section class="dirt-section-detail dirt-membership-detail" *ngIf="membership">
  <header>
    <button
      type="button"
      class="dirt-btn-back btn btn-primary-outline btn-sm"
      (click)="navigateTo(['/association/detail', membership.association.id])"
    >
      <span class="fa fa-arrow-left" aria-hidden="true"></span>
    </button>

    <h1 class="bounceInRight">Membership Detail</h1>

    <p>
      Belongs to
      <a [routerLink]="['/association/detail', membership.association.id]">{{ membership.association.name }}</a>
    </p>
  </header>

  <br />
  <dirt-membership-form [membership]="membership" [isSubmitting]="isSubmitting" (onSubmitted)="onSubmit($event)">
  </dirt-membership-form>
</section>
