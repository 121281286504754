<section class="dirt-section-create dirt-sponsor-normalized-names">
  <a class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
    <span class="fa fa-arrow-left" aria-hidden="true"></span>
  </a>

  <h1 class="bounceInRight">Edit Normalized Names</h1>

  <br />

  <dirt-sponsor-form #frmSponsor="frmSponsor" [mode]="'Edit Normalized Names'"></dirt-sponsor-form>

  <div class="pull-right">
    <button
      type="submit"
      class="btn btn-primary"
      [ngClass]="{ 'btn-loading': isSubmitting }"
      [disabled]="!frmSponsor.isValid()"
      (click)="onSubmit(frmSponsor.getValue())"
      *diAcl="'sponsor.update'"
    >
      <span>Save</span>
    </button>
  </div>
</section>
