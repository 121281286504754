import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { BulkDelegate } from '../../../../common/bulk/bulk.delegate';
import {
  BulkModalComponent,
  BULK_MODAL_OPTIONS,
} from '../../../../common/bulk/component/bulk-modal/bulk-modal.component';

export const BULK_LIST_MODAL_OPTIONS = {
  centered: true,
  size: 'lg',
  backdrop: false,
  windowClass: 'di-bulk-update-modal',
};

@Component({
  selector: 'dirt-bulk-list-button',
  templateUrl: './bulk-list-button.component.html',
  styleUrls: ['./bulk-list-button.component.scss'],
})
export class BulkListButtonComponent {
  @Input()
  delegate: BulkDelegate;

  wndw: Window = window; // be able to override in tests

  constructor(private svcModal: NgbModal) {}

  onOpenDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.delegate;
  }
}
