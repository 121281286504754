import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';

let clientConfig: Promise<{ [k: string]: any }> = null;

export function obtainClientConfig(svcValue: ValueAPI): Promise<{ [k: string]: any }> {
  if (!clientConfig) {
    clientConfig = svcValue
      .find(ValueType.ClientConfig)
      .toPromise()
      .then((_c) => {
        const cfg = {};
        _c.forEach((_cc) => {
          cfg[_cc.code] = _cc.value;
        });
        return cfg;
      });
  }
  return clientConfig; // always the same first promise
}
