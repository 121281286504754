import { capitalize } from 'lodash';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, forkJoin, Subject, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { Filter, FiltersComponent, FilterType } from '../../shared/components/filters/filters.component';
import { Job } from '../shared/job';
import { JobsAPI } from '../shared/api.service';
import { JobStatus } from '../shared/constant/status.enum';
import { UserPool } from '../../user-pool/shared/user-pool';
import { UserPoolAPI } from '../../user-pool/shared/api.service';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { Value } from '../../shared/services/value/value';
import { BulkUpdateOptions } from '../../common/bulk-update/bulk-update-options';
import { FieldType } from '../../common/bulk-update/field.config';
import { filterBarConfig } from '../../person/shared/config/filter-bar-config';
import { rangeOfNumbers } from '../../person/shared/utils';

@Component({
  selector: 'dirt-job-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class JobListComponent implements OnInit, OnDestroy {
  jobs: Job[];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  // Sorting / Filtering settings
  searchCtrl: FormControl = new FormControl('');
  filter: { [key: string]: any } = {
    '_meta.status': [JobStatus.READY_FOR_COMPILATION, JobStatus.COMPILATION_IN_PROGRESS],
  };
  filters: Filter[] = [
    {
      title: 'Status',
      key: '_meta.status',
      type: FilterType.MULTI_VALUE,
      values: Object.keys(JobStatus)
        .sort()
        .map((k) => ({
          title: JobStatus[k],
          displayValue: capitalize(JobStatus[k]).replace(/_/g, ' '),
          selected: [JobStatus.READY_FOR_COMPILATION, JobStatus.COMPILATION_IN_PROGRESS].includes(JobStatus[k]), // Only what's open by default
        })),
    },
  ];
  filterBarConfig = {
    '_meta.status': {
      label: 'Status',
      format(value: string) {
        return capitalize(value).replace(/_/g, ' ');
      },
    },
    projectNames: {
      label: 'Projects',
    },
  };

  bulkUpdateConfig: BulkUpdateOptions<JobsAPI, Job>;

  private sort = '-_id';

  isSearching = false;

  searchTerm: string;

  selectedJobId: string;

  @ViewChild(FiltersComponent, { static: false })
  private filterRef: FiltersComponent;

  private pools: UserPool[];

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly svcJob: JobsAPI,
    private readonly svcUserPool: UserPoolAPI,
    private readonly router: Router,
    private svcValue: ValueAPI
  ) {}

  ngOnInit(): void {
    forkJoin({
      pools: this.svcUserPool.find(),
      jobTypes: this.svcJob.getTypes(),
      personProjects: this.svcValue.find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER, 0),
      projects: this.svcValue.find(ValueType.Project, Number.MAX_SAFE_INTEGER, 0),
    }).subscribe((data) => {
      this.pools = data.pools;

      const typesFilter = {
        title: 'Types',
        key: 'type',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: data.jobTypes.map((value) => ({ title: value, displayValue: value })),
      };

      // unify all projects to one
      const projectMap: { [code: string]: Value } = {};
      data.projects.forEach((p) => (projectMap[p.code] = p));
      data.personProjects.forEach((p) => (projectMap[p.code] = p));
      const allProjects = Object.values(projectMap).sort((p1, p2) =>
        ((p1.title || p1.code) as string).localeCompare((p2.title || p2.code) as string)
      );
      const projectsFilter = {
        title: 'Projects',
        key: 'projectNames',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: allProjects.map((p) => ({ title: p.code as string, displayValue: (p.title || p.code) as string })),
      };

      this.filters = [...this.filters, typesFilter, projectsFilter]; // DO break reference when creating a filter from an Observable/Promise (otherwise change detection won't run)

      this.doLoad();

      this.searchCtrl.valueChanges
        .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
        .subscribe((term) => {
          this.searchTerm = term;
          this.doLoad();
        });
    });
    this.bulkUpdateConfig = {
      srvApi: this.svcJob,
      fields: [
        {
          field: 'reset',
          label: 'Reset',
          tooltip: 'Reset - i.e. unassign and put back in queue for someone else to pick up',
          required: true,
          type: FieldType.select,
          values: () => ({ YES: 'YES' }),
        },
        {
          field: 'close',
          label: 'Close',
          tooltip: 'Close - i.e. will no longer be worked by anyone',
          required: true,
          type: FieldType.select,
          values: () => ({ YES: 'YES' }),
        },
        {
          field: 'priority',
          label: 'Priority',
          required: true,
          type: FieldType.select,
          values: rangeOfNumbers(5, 0),
        },
        {
          field: 'assignees',
          label: 'Assignee',
          required: true,
          type: FieldType.multiUser,
        },
      ],
      filterBarConfig,
      titleFormatter: null, // take IDs as-is
    };
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /** just avoid useless rendering if we can */
  trackById(_index: number, job: Job): string {
    return job._id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getJobs();
  }

  onSort(field: string): void {
    this.sort = field;

    this.storeFiltersAsQueryParams();
    this.getJobs();
  }

  onFilter(filter: { [key: string]: any }): void {
    this.filter = filter;
    this.doLoad();
  }

  onFilterClear(): void {
    this.filterRef.doClear();
  }

  onFilterValueRemove(item: { key: string; value: any }): void {
    this.filterRef.removeValue(item.key, item.value);
  }

  onExportJobs(): void {
    this.svcJob.downloadJobsFile(this.filter).subscribe((jobsBlob) => {
      const dlDummyLink = document.createElement('a');

      dlDummyLink.setAttribute('download', `jobs_list_${new Date().toLocaleString()}.xlsx`);
      dlDummyLink.setAttribute('href', URL.createObjectURL(jobsBlob));
      dlDummyLink.click();
      dlDummyLink.parentElement.removeChild(dlDummyLink);
    });
  }

  openLinkInNewTab(id: string): void {
    if (!id) {
      return;
    }
    const url = this.router.serializeUrl(this.router.createUrlTree(['/job/detail', id]));
    window.open(url, '_blank');
  }

  doLoad(): void {
    this.storeFiltersAsQueryParams();
    this.resetPagination();
    this.getJobs();
    this.getJobsCount();
  }

  private storeFiltersAsQueryParams(): void {
    if (this.sort) {
      const filters = { ...(this.sort && { sortField: this.sort }) };
      this.router.navigate([], { queryParams: filters });
    }
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getJobs(): void {
    this.isLoading = true;

    // TODO: should only see what matter to you: PERSON_ -> people, PROFILE_ -> profile, etc

    this.svcJob.find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.filter).subscribe({
      next: (jobs) => {
        this.jobs = jobs;
        this.jobs.forEach((j: any) => {
          j.pool = this.pools.find((p) => p.id === j.poolId)?.name || j.poolId; // map poolId to pool name for display
        });

        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  private getJobsCount(): void {
    this.svcJob.count(this.filter, this.searchTerm).subscribe((count) => {
      this.total = count;
    });
  }
}
