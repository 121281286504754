import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { GrantAPI } from './shared/grant-api.service';

@NgModule({
  imports: [SharedModule],
  declarations: [],
  providers: [GrantAPI],
})
export class GrantModule {}
