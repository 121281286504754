import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AccountAffiliationSuspect, AffiliationSuspectStatus } from './account-affiliation-suspect';
import { APIService } from '../../shared/services/api/api.service';
import {
  GET,
  MediaType,
  Produces,
  Query,
  POST,
  Body,
  Path,
  PATCH,
  DELETE,
} from '../../shared/services/rest/rest.service';

@Injectable()
export class AccountAffiliationSuspectAPI extends APIService {
  @GET('account-affiliation-suspect/')
  @Produces(MediaType.JSON)
  public find(
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('accountId') accountId?: string,
    @Query('filter') filter?: any
  ): Observable<AccountAffiliationSuspect[]> {
    return;
  }

  @GET('account-affiliation-suspect/count')
  @Produces(MediaType.JSON)
  public count(@Query('accountId') accountId?: string): Observable<any> {
    return;
  }

  @PATCH('account-affiliation-suspect/{id}')
  @Produces(MediaType.JSON)
  public update(
    @Path('id') id: string,
    @Body accountFinancialInformation: Partial<AccountAffiliationSuspect>
  ): Observable<any> {
    return;
  }

  @PATCH('account-affiliation-suspect/{accountId}/match-status/{affiliationId}')
  @Produces(MediaType.JSON)
  public setAffiliationSuspectMatchStatus(
    @Path('accountId') accountId: string,
    @Path('affiliationId') affiliationId: string,
    @Body match: { status: AffiliationSuspectStatus }
  ): Observable<any> {
    return;
  }
}
