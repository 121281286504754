import { ComponentRef, Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';

import { SideBarComponent } from './side-bar.component';

@Injectable({ providedIn: 'root' })
export class SideBarService {
  /** private, but expose for SideBarComponent to register itself */
  _componentRef: SideBarComponent;

  open<T>(content: ComponentType<T>): ComponentRef<T> {
    return this._componentRef.open(content);
  }

  close(): void {
    this._componentRef.close();
  }
}
