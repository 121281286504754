import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { SharedModule } from '../../shared/shared.module';
import { BulkUpdateComponent } from './components/bulk-update-button/bulk-update.component';
import { BulkUpdateModalComponent } from './components/bulk-update-modal/bulk-update-modal.component';
import { BulkUpdateStepperComponent } from './components/bulk-update-stepper/bulk-update-stepper.component';
import { BulkUpdateSelectStepComponent } from './steps/bulk-update-select-step/bulk-update-select-step.component';
import { BulkUpdateFieldValueStepComponent } from './steps/bulk-update-field-value-step/bulk-update-field-value-step.component';
import { BulkUpdateResultsStepComponent } from './steps/bulk-update-results-step/bulk-update-results-step.component';
import { BulkUpdateStepsContainerComponent } from './components/bulk-update-steps-container/bulk-update-steps-container.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BulkUpdateState } from './bulk-update.state';
import { UserSelectComponent } from './components/user-select/user-select.component';
import { BulkUpdateOptionsProvider } from './bulk-update-options-provider';
import { BulkListModule } from '../bulk-list/bulk-list.module';

@NgModule({
  declarations: [
    BulkUpdateComponent,
    BulkUpdateModalComponent,
    BulkUpdateStepperComponent,
    BulkUpdateSelectStepComponent,
    BulkUpdateFieldValueStepComponent,
    BulkUpdateResultsStepComponent,
    BulkUpdateStepsContainerComponent,
    UserSelectComponent,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    SharedModule,
    BulkListModule, // for update state
    NgxsModule.forFeature([BulkUpdateState]),
  ],
  exports: [BulkUpdateComponent, BulkUpdateModalComponent],
  providers: [BulkUpdateOptionsProvider],
})
export class BulkUpdateModule {}
