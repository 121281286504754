import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs';

import { Auth } from './shared/services/auth/auth.service';
import { environment } from '../environments/environment';
import { setupCloudWatchRUM } from './shared/services/observability/cloudwatch-rum.service';

@Component({
  selector: 'dirt',
  encapsulation: ViewEncapsulation.None,
  template: `
    <router-outlet></router-outlet>
    <dirt-side-bar></dirt-side-bar>
  `,
})
export class DirtComponent implements OnInit {
  constructor(
    private readonly svcAuth: Auth,
    private readonly router: Router,
    private titleService: Title,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.svcAuth.scheduleTokenRefreshing();
    // add Gtag & track router
    const wndw = window as any;
    const gtagId = (environment as any).gtagId;
    if (gtagId && !wndw.cleanGtagInit) {
      // thanks, https://github.com/mzuccaroli/angular-google-tag-manager/blob/master/projects/angular-google-tag-manager/src/lib/angular-google-tag-manager.service.ts
      wndw.cleanGtagInit = true; // don't duplicate
      wndw.dataLayer = wndw.dataLayer || [];
      wndw.gtag = function () {
        wndw.dataLayer.push(arguments);
      }; // can call from anywhere - actually: this is a small memory leak, about 40 bytes per call (so 1M calls = 1M navigations = is 40Megabytes - so it's manageable)
      wndw.gtag('js', new Date());
      wndw.gtag('config', gtagId, { send_page_view: false }); // only send express via router addon
      let lastEvent = 0;
      this.router.events.forEach((item) => {
        if (item instanceof NavigationEnd) {
          const currEvent = Date.now();
          if (currEvent - lastEvent < 200) {
            return; // don't log twice when we append params
          } // (else)
          lastEvent = currEvent;
          let normalizedUrl = location.href; // =gtag default
          normalizedUrl = normalizedUrl.replaceAll(/[0-9a-f]{24}/g, 'id'); // replace all Mongo-IDs
          wndw.gtag('event', 'page_view', { page_location: normalizedUrl });
        }
      });
      // finally add the gtag script
      const scriptTag = window.document.createElement('script');
      scriptTag.setAttribute('async', 'async');
      scriptTag.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=' + gtagId);
      window.document.head.appendChild(scriptTag);
    }
    // prevent easy close (as nav is router-links, we really only show this when leaving cLean)
    if (!window.localStorage.getItem('NO_CLOSE_CONFIRM') && environment.production /*incl. nightly*/) {
      window.addEventListener('beforeunload', (event) => {
        event.preventDefault();
        return (event.returnValue = 'Sure to close?'); // (Chrome ignores the message now and shows sth generic)
      });
    }
    // https://medium.com/@oranaki9910/how-to-create-a-dynamic-page-title-in-angular-28d71aa50861
    this.updateTitle();

    setupCloudWatchRUM(environment);
  }

  private updateTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const child: ActivatedRoute | null = this.route.firstChild;
          let title = child && child.snapshot.data['title'];
          if (title) {
            return title;
          }
        })
      )
      .subscribe((title) => {
        this.titleService.setTitle(title ? `cLean | ${title}` : 'cLean | Veeva');
      });
  }
}
