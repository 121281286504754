<form [diMarkerWrapper]="model" [defaultFixed]="true" (ngSubmit)="onSubmit()" #frmContribution="ngForm" novalidate>
  <fieldset *diMarker="{ name: 'contribution', entity: 'session' }" class="dirt-fieldset form-group">
    <div class="form-group row">
      <div class="col-12">
        <h3 *ngIf="!isEditMode">Create new contribution</h3>
        <h3 *ngIf="isEditMode">Edit contribution</h3>
      </div>
    </div>
    <!-- Plaintext -->
    <div class="form-group row" *ngIf="showPlaintext">
      <label class="col-form-label col-md-1" for="plainText">Text</label>
      <div class="col-md-8">
        <textarea
          id="plainText"
          #plainText
          name="plainText"
          [(ngModel)]="model.plainText"
          rows="6"
          class="form-control"
          style="width: 100%"
        ></textarea>
        <span *ngIf="!wordByWordMode">
          <button type="button" (click)="onReEval()">(Re-)Evaluate</button>
          <button type="button" (click)="onLcase()">Make lowercase</button>
          <button type="button" (click)="onPrevTitle()">Prev title</button>
          <button type="button" (click)="onPrevWp()">Prev workpl.</button>
          <button type="button" (click)="onClearForm()">Clear form</button>
          <button type="button" (click)="onWordByWordStart()">Word by word</button>
        </span>
        <span *ngIf="wordByWordMode">
          Word by word:
          <button type="button" (click)="onWordByWordInsert('title')" title="Ctrl-1 / Meta-1">+Title</button>
          <button type="button" (click)="onWordByWordInsert('firstName')">+First</button>
          <button type="button" (click)="onWordByWordInsert('middleName')">+Middle</button>
          <button type="button" (click)="onWordByWordInsert('lastName')">+Last</button>
          <button type="button" (click)="onWordByWordInsertNameSplit()" title="Auto-split; Ctrl-2 / Meta-2">
            +Name
          </button>
          <button
            type="button"
            (click)="onWordByWordInsert('fullName')"
            title="Ctrl-4 / Meta-4"
            *ngIf="isFullNameVisible()"
          >
            +Full name
          </button>
          <button type="button" (click)="onWordByWordInsert('suffix')">+Suffix</button>
          <button type="button" (click)="onWordByWordInsert('nickname')">+Nick</button>
          <button type="button" (click)="onWordByWordInsert('workplace')" title="Ctrl-3 / Meta-3">+Workpl.</button>
          <button type="button" (click)="onWordByWordCont()" title="continue - sel same again">cont</button>
          <button type="button" (click)="onWordByWordSkip()" title="skip - sel next">skip</button>
          <button type="button" (click)="onWordByWordUndo()" title="undo last action">undo</button>
          <button type="button" (click)="onWordByWordEnd()">end</button><br />
        </span>
        <span *ngIf="wordByWordMode && isExtendedTestEnabled()">
          <label
            ><input type="checkbox" [checked]="wordByWordRememberAll" (change)="onWordByWordRememberAllChg($event)" />
            Remember all for next</label
          >
          <label
            ><input
              type="checkbox"
              [checked]="wordByWordRememberRemainder"
              (change)="onWordByWordRememberRemainderChg($event)"
            />
            Remember remainder for next</label
          ><br />
        </span>
      </div>
    </div>
    <!-- Title -->
    <div class="form-group row">
      <label class="col-form-label col-md-1" for="title">Title</label>
      <div class="col-md-8">
        <input
          id="title"
          type="text"
          class="form-control"
          name="title"
          [ngModel]="model.title"
          (ngModelChange)="model.title = longDash($event)"
          [required]="isTitleRequired()"
          [disabled]="isReadOnly || isTitleDisabled()"
          dirtPasteNormalizer
          *diMarker="{ name: 'title', entity: 'contribution' }"
          autofocus
        />
      </div>
      <label class="col-form-label col-md-1" for="position">Position</label>
      <div class="col-md-2">
        <select
          id="position"
          class="form-control custom-select"
          name="position"
          [(ngModel)]="model.person.position"
          [disabled]="isReadOnly"
          (change)="onPositionChange()"
          *diMarker="{ name: 'position', entity: 'contribution' }"
          required
        >
          <option
            *ngFor="let position of positions"
            [disabled]="isPositionDisable(position.name)"
            [value]="position.name"
          >
            {{ position.name | capitalize }}
          </option>
        </select>
      </div>
    </div>

    <!-- Original Language -->
    <div class="form-group row" *ngIf="model.id">
      <label class="col-form-label col-md-1" for="originalLanguage">Original Language</label>
      <div class="col-md-2">
        <select
          id="originalLanguage"
          class="form-control custom-select"
          name="originalLanguage"
          [(ngModel)]="model.originalLanguage"
          [disabled]="isReadOnly"
        >
          <option *ngFor="let language of languages$ | async" [value]="language.code">{{ language.title }}</option>
        </select>
      </div>
    </div>

    <!-- Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-1" for="nameText">
        <span class="pr-2">Name</span>
        <a href="javascript:void(0)" class="di-timid" ngbTooltip="Paste and split" (click)="onClipboardPaste($event)">
          <i class="fa fa-clipboard" aria-hidden="true"></i>
        </a>
      </label>
      <!-- First Name -->
      <div class="col-md-3">
        <label class="sr-only" for="firstName">First Name</label>
        <input
          id="firstName"
          class="form-control"
          name="firstName"
          placeholder="First Name"
          [ngModel]="model.person.firstName"
          (ngModelChange)="model.person.firstName = longDash($event)"
          [disabled]="isReadOnly"
          *diMarker="{ name: 'firstName', entity: 'contribution', area: 'BD' }"
          dirtPasteNormalizer
        />
      </div>
      <!-- Middle Name -->
      <div class="col-md-2">
        <label class="sr-only" for="middleName">Middle Name</label>
        <input
          id="middleName"
          class="form-control"
          name="middleName"
          placeholder="Middle Name"
          [ngModel]="model.person.middleName"
          (ngModelChange)="model.person.middleName = longDash($event)"
          [disabled]="isReadOnly"
          dirtPasteNormalizer
        />
      </div>
      <!-- Last Name -->
      <div class="col-md-3">
        <label class="sr-only" for="lastName">Last Name</label>
        <input
          id="lastName"
          class="form-control"
          name="lastName"
          placeholder="Last Name"
          #lastName="ngModel"
          [ngModel]="model.person.lastName"
          (ngModelChange)="model.person.lastName = longDash($event)"
          [required]="!model.person.fullName"
          [disabled]="isReadOnly"
          *diMarker="{ name: 'lastName', entity: 'contribution', area: 'BD' }"
          dirtPasteNormalizer
        />
      </div>
      <!-- Suffix -->
      <div class="col-md-1">
        <label class="sr-only" for="suffix">Suffix</label>
        <input
          id="suffix"
          class="form-control"
          name="suffix"
          placeholder="Suffix"
          [ngModel]="model.person.suffix"
          (ngModelChange)="model.person.suffix = longDash($event)"
          [disabled]="isReadOnly"
        />
      </div>
      <!-- Nickname -->
      <div class="col-md-2">
        <label class="sr-only" for="nickname">Nickname</label>
        <input
          id="nickname"
          class="form-control"
          name="nickname"
          placeholder="Nickname"
          [ngModel]="model.person.nickname"
          (ngModelChange)="model.person.nickname = longDash($event)"
          [disabled]="isReadOnly"
          dirtPasteNormalizer
        />
      </div>
    </div>

    <!-- Full Name -->
    <div class="form-group row" *ngIf="isFullNameVisible()">
      <label class="col-form-label col-sm-4 col-md-1" for="fullName">Full Name</label>
      <div class="col-sm-8 col-md-11">
        <input
          id="fullName"
          class="form-control"
          name="fullName"
          [ngModel]="model.person.fullName"
          (ngModelChange)="model.person.fullName = longDash($event)"
          #fullName="ngModel"
          [required]="lastName && lastName.dirty && !model.person.lastName"
          *diMarker="{ name: 'fullName', entity: 'contribution', area: 'BD' }"
          [disabled]="isReadOnly"
        />
        <p class="form-text text-muted">Notice: only for non-latin / non-cyrillic scripts</p>
      </div>
    </div>

    <div class="form-group row">
      <!-- Workplace -->
      <label class="col-form-label col-md-1" for="workplace">Workplace</label>
      <div class="col-md-5">
        <input
          id="workplace"
          class="form-control"
          name="workplace"
          [ngModel]="model.person.workplace"
          (ngModelChange)="model.person.workplace = longDash($event)"
          [disabled]="isReadOnly"
          *diMarker="{ name: 'workplace', entity: 'contribution', area: 'BD' }"
          dirtPasteNormalizer
        />
      </div>
      <!-- Sponsor -->
      <label class="col-form-label col-md-1">Sponsor</label>
      <div class="col-md-3 di-contrib-sponsor">
        <dirt-multiselect-dropdown
          name="sponsors"
          [options]="sponsorsFiltered"
          [settings]="sponsorsSettings"
          [(ngModel)]="model.sponsors"
          (lazyLoad)="filterSponsors($event)"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <div class="dirt-actions col-md-12" *ngIf="!isReadOnly">
      <div class="dirt-btn-ct pull-left">
        <input
          type="checkbox"
          name="reviewed"
          [checked]="model.reviewed"
          (change)="onReviewedChange($event)"
          [disabled]="isReadOnly"
        />
        <span>Reviewed</span>
      </div>
      <div class="dirt-btn-ct pull-left" *ngIf="shouldDisplayVerifiedControls">
        <input
          type="checkbox"
          name="verified"
          [checked]="model.verified"
          (change)="onVerifiedChange($event)"
          [disabled]="isReadOnly"
        />
        <span>Verified</span>
      </div>
      <div class="dirt-btn-ct pull-right">
        <button
          type="button"
          class="btn btn-secondary"
          [disabled]="isSubmitting || isSubmitting || isSaving"
          (click)="onCancelClick(); $event.stopPropagation()"
        >
          <span>Cancel</span>
        </button>
      </div>

      <div *ngIf="!isEditMode" class="dirt-btn-ct pull-right">
        <button
          type="button"
          class="btn btn-primary"
          [ngClass]="{ 'btn-loading': isSaving }"
          [disabled]="!frmContribution.form.valid || isSubmitting || isSaving"
          (click)="onSubmitAndNew(); $event.preventDefault(); $event.stopPropagation()"
        >
          <span>Save & New</span>
        </button>
      </div>

      <div class="dirt-btn-ct pull-right">
        <button
          type="submit"
          class="btn btn-primary"
          [ngClass]="{ 'btn-loading': isSubmitting }"
          [disabled]="!frmContribution.form.valid || isSubmitting || isSaving"
        >
          <span>Save</span>
        </button>
      </div>
    </div>
  </fieldset>
</form>
