import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AclGuard } from '../shared/acl/guard.service';
import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { JobComponent } from './job.component';
import { JobDetailComponent } from './detail/detail.component';
import { JobListComponent } from './list/list.component';
import { JobsModule } from './jobs.module';
import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';

const jobRoutes: Routes = [
  {
    path: 'job',
    component: JobComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'detail/:id',
        component: JobDetailComponent,
      },
      {
        path: 'list',
        component: JobListComponent,
        data: { acl: 'job.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
    data: { title: 'Job' },
  },
];

export const jobsRouting: ModuleWithProviders<JobsModule> = RouterModule.forChild(jobRoutes);
