import { Component } from '@angular/core';
import { Person } from '../../../../person/shared/person';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Affiliation } from '../../../../affiliation/shared/affiliation';
import { PersonAPI } from '../../../../person/shared/api.service';
import { PersonStatus } from '../../../../person/shared/constant/status.enum';
import { uniq } from 'lodash';

@Component({
  selector: 'dirt-ct-profile-person-modal',
  templateUrl: './person-with-affiliation.component.html',
  styleUrls: ['./person-with-affiliation.component.scss'],
})
export class PersonWithAffiliationModalComponent {
  newPerson: Person = new Person();
  affiliation: Affiliation = null;
  selAffiliation: boolean = false;
  isFormValid: boolean = false;
  isSubmitting: boolean = false;
  createAsOnHold: boolean = false;
  multiCvLink: number = 1;
  disableDisabledCountries: boolean = false;

  constructor(private readonly activeModal: NgbActiveModal, private readonly svcPerson: PersonAPI) {}

  onCancelClick() {
    this.activeModal.close(null);
  }

  onSubmitClick() {
    if (this.isSubmitting || !this.isFormValid) {
      return;
    }
    this.isSubmitting = true;
    // build and save the newPerson
    this.newPerson._meta = { status: this.createAsOnHold ? PersonStatus.ON_HOLD : PersonStatus.PENDING_VERIFICATION };
    if (this.affiliation) {
      this.newPerson.affiliations.push({
        id: this.affiliation.id,
        primary: true,
        countryCode: this.affiliation.address?.countryCode || 'US',
      } as any);
    }
    this.newPerson.projectNames = uniq(this.newPerson.projectNames || []);
    this.svcPerson.create(this.newPerson).subscribe(
      (p) => {
        this.isSubmitting = false;
        alert('New person created');
        this.activeModal.close(p);
      },
      () => {
        this.isSubmitting = false;
        alert('Failed to create new person');
      }
    );
  }
}
