import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ErrorMessage } from './error-message';

@Component({
  selector: 'dirt-input-error-msg',
  templateUrl: './input-error-msg.component.html',
  styleUrls: ['./input-error-msg.component.scss'],
})
export class InputErrorMsgComponent implements OnInit {
  @Input()
  errorMsg: ErrorMessage;

  @Input()
  template: TemplateRef<any>;

  constructor() {}

  ngOnInit() {}
}
