<div class="side-bar-wrapper" [ngClass]="{ closed: isClosed }">
  <div class="backdrop" (click)="close()"></div>
  <section class="container">
    <div class="header">
      <button type="button" class="close" title="Close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="body">
      <ng-template cdkPortalOutlet></ng-template>
    </div>
  </section>
</div>
