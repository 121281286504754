<form #fromAssociation="ngForm" novalidate>
  <div [diMarkerWrapper]="model" class="clearfix">
    <!-- Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="name">Name</label>
      <div class="col-sm-8 col-md-10">
        <div class="input-group">
          <!-- Association Name -->
          <ng-container *ngIf="!model.parent"
            ><input
              type="text"
              id="name"
              class="form-control"
              name="name"
              [ngModel]="model.name"
              (ngModelChange)="model.name = longDash($event)"
              [readonly]="!isFieldEditable('name') || isObsolete()"
              autofocus
              required
              dirtPasteNormalizer
              *diMarker="{ name: 'name', entity: model.parent ? 'subassociation' : 'association', area: 'ALL' }"
          /></ng-container>

          <!-- Sub-Association Name -->
          <ng-container *ngIf="model.parent">
            <textarea
              id="name"
              class="form-control"
              name="name"
              [ngModel]="model.name"
              (ngModelChange)="model.name = longDash($event)"
              [readonly]="!isFieldEditable('name') || isObsolete()"
              autofocus
              required
              dirtPasteNormalizer
              *diMarker="{ name: 'name', entity: model.parent ? 'subassociation' : 'association' }"
            >
            </textarea>
          </ng-container>

          <div class="input-group-append">
            <div class="input-group-text" ngbTooltip="Name Translated" placement="left">
              <input
                type="checkbox"
                name="isNameTranslated"
                [(ngModel)]="model.nameTranslated"
                [disabled]="!isFieldEditable('nameTranslated') || isObsolete()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Original Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="originalName">Original Name</label>
      <div class="col-sm-8 col-md-10">
        <!-- Association Original Name -->
        <ng-container *ngIf="!model.parent">
          <!-- Keep name: 'nameSecondary' in diMarker! -->
          <input
            type="text"
            id="originalName"
            class="form-control"
            name="originalName"
            [ngModel]="model.originalName"
            (ngModelChange)="model.originalName = longDash($event)"
            [readonly]="!isFieldEditable('originalName') || isObsolete()"
            dirtPasteNormalizer
            *diMarker="{ name: 'nameSecondary', entity: 'association', area: 'ID' }"
          />
        </ng-container>

        <!-- Sub-Association Original Name -->
        <ng-container *ngIf="model.parent">
          <!-- Keep name: 'nameSecondary' in diMarker! -->
          <textarea
            id="originalName"
            class="form-control"
            name="originalName"
            [ngModel]="model.originalName"
            (ngModelChange)="model.originalName = longDash($event)"
            [readonly]="!isFieldEditable('originalName') || isObsolete()"
            dirtPasteNormalizer
            *diMarker="{ name: 'nameSecondary', entity: 'subassociation', area: 'ID' }"
          >
          </textarea>
        </ng-container>
      </div>
    </div>

    <!-- Original Language -->
    <div class="form-group row" *ngIf="model.id && (model.originalLanguage || model.originalName)">
      <label class="col-form-label col-sm-4 col-md-2" for="originalLanguage">Original Language</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="originalLanguage"
          class="form-control custom-select"
          name="originalLanguage"
          [(ngModel)]="model.originalLanguage"
          [disabled]="!isFieldEditable('originalLanguage')"
        >
          <option *ngFor="let language of languages$ | async" [value]="language.code">{{ language.title }}</option>
        </select>
      </div>
    </div>

    <!-- Description -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="description">Description</label>
      <div class="col-sm-8 col-md-10">
        <textarea
          id="description"
          name="description"
          class="form-control"
          [(ngModel)]="model.description"
          [readonly]="!isFieldEditable('description') || isObsolete()"
        >
        </textarea>
      </div>
    </div>

    <!-- Obsolete -->
    <div class="form-group row" *ngIf="model.parent && model.id">
      <label class="col-sm-4 col-md-2">Obsolete</label>
      <div class="col-sm-8 col-md-10 pt-1">
        <ui-switch
          size="small"
          [checked]="isObsolete()"
          [disabled]="!isFieldEditable('obsolete')"
          (change)="onObsoleteChange($event)"
        >
        </ui-switch>
      </div>
    </div>

    <!-- Type -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="type">Type</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="type"
          class="form-control custom-select"
          name="type"
          [(ngModel)]="model.type"
          [disabled]="!isFieldEditable('type')"
          required
          *diMarker="{ name: 'type', entity: model.parent ? 'subassociation' : 'association', area: 'ALL' }"
        >
          <option *ngFor="let type of types" [value]="type.code">
            {{ type.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Reach -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="reach">Reach</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="reach"
          class="form-control custom-select"
          name="reach"
          [(ngModel)]="model.reach"
          [disabled]="!isFieldEditable('reach')"
          required
          *diMarker="{ name: 'reach', entity: model.parent ? 'subassociation' : 'association', area: 'ID' }"
        >
          <option *ngFor="let reach of reaches | keys" [value]="reach">
            {{ reach | capitalize }}
          </option>
        </select>
      </div>
    </div>

    <!-- Category -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="category">Category</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="category"
          class="form-control custom-select"
          name="category"
          [(ngModel)]="model.category"
          [disabled]="!isFieldEditable('category')"
          required
          *diMarker="{ name: 'category', entity: model.parent ? 'subassociation' : 'association', area: 'ID' }"
        >
          <option *ngFor="let category of categories" [value]="category.code">
            {{ category.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Scope -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Oncology</label>
      <div class="col-sm-8 col-md-10 pt-1">
        <ui-switch
          size="small"
          color="#6d5cae"
          [checked]="isScopeChecked()"
          [disabled]="!isFieldEditable('scope')"
          (change)="onScopeChange($event)"
        >
        </ui-switch>
      </div>
    </div>

    <!-- Therapeutic Areas -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Therapeutic Areas</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multiselect-dropdown
          name="ta-tags"
          [ngClass]="{ disabled: !isFieldEditable('therapeuticAreas') }"
          [options]="therapeuticAreas"
          [settings]="therapeuticAreaSettings"
          [(ngModel)]="model.therapeuticAreas"
          required
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <!-- Area Tags -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Areas</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multiselect-dropdown
          name="area-tags"
          [ngClass]="{ disabled: !isFieldEditable('areas') }"
          [options]="areas"
          [settings]="areaSettings"
          [(ngModel)]="model.areas"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <!-- Base Web Source -->
    <ng-container *ngIf="!model.parent">
      <div class="form-group row" *ngFor="let link of model.webSources; let idx = index; trackBy: trackByIndex">
        <label class="col-form-label col-sm-4 col-md-2">Base Web Source</label>
        <div class="col-sm-8 col-md-10">
          <dirt-link
            [(url)]="model.webSources[idx]"
            name="webSources-{{ idx }}"
            [form]="fromAssociation"
            [nonCompliantDomainType]="nonCompliantDomainType"
            [disabled]="!isFieldEditable('webSource') || isObsolete()"
            [required]="true"
            *diMarker="{ name: 'webSource', entity: model.parent ? 'subassociation' : 'association', area: 'ALL' }"
          >
            <button
              type="button"
              class="btn btn-secondary"
              (click)="pushItemToList(model.webSources)"
              *ngIf="idx == model.webSources.length - 1 && isFieldEditable('webSource')"
            >
              <i class="fa fa-plus-circle"></i>
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              (click)="removeFromByIndex(model.webSources, idx)"
              *ngIf="idx > 0 && idx <= 3 && isFieldEditable('webSource')"
            >
              <i class="fa fa-minus-circle"></i>
            </button>
          </dirt-link>
        </div>
      </div>
    </ng-container>

    <!--Member Web Source-->
    <div class="form-group row" *ngFor="let link of model.memberWebSources; let idx = index; trackBy: trackByIndex">
      <label class="col-form-label col-sm-4 col-md-2">Member Web Source</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.memberWebSources[idx]"
          name="memberWebSources-{{ idx }}"
          [form]="fromAssociation"
          [nonCompliantDomainType]="nonCompliantDomainType"
          [disabled]="!isFieldEditable('memberWebSource') || isObsolete()"
          *diMarker="{ name: 'memberWebSource', entity: model.parent ? 'subassociation' : 'association', area: 'ALL' }"
        >
          <button
            type="button"
            class="btn btn-secondary"
            (click)="pushItemToList(model.memberWebSources)"
            *ngIf="idx == model.memberWebSources.length - 1 && isFieldEditable('memberWebSource')"
          >
            <i class="fa fa-plus-circle"></i>
          </button>

          <button
            type="button"
            class="btn btn-secondary"
            (click)="removeFromByIndex(model.memberWebSources, idx)"
            *ngIf="idx > 0 && idx <= 3 && isFieldEditable('memberWebSource')"
          >
            <i class="fa fa-minus-circle"></i>
          </button>
        </dirt-link>
      </div>
    </div>

    <!--Twitter handle -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="twitterHandle">Twitter handle</label>

      <div
        class="col-sm-8 col-md-10"
        *diMarker="{ name: 'twitterHandle', entity: model.parent ? 'subassociation' : 'association', area: 'ALL' }"
      >
        <input
          type="twitter"
          id="twitterHandle"
          class="form-control"
          name="twitterHandle"
          [(ngModel)]="model.twitterHandle"
          [readonly]="!isFieldEditable('twitterHandle') || isObsolete()"
          (change)="onTwitterHandleChange(model.twitterHandle)"
          twitterHandleValidator
          *diInputErrorMsg="{
            text: 'No Twitter account matching handle.',
            show: !isLogoPreviewLoading && hasTwitterHandleError
          }"
        />
      </div>
    </div>

    <div *ngIf="!model.parent">
      <!--YouTube Channel URL -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="youtubeChannelUrl">YouTube Channel URL</label>

        <div class="col-sm-8 col-md-10">
          <input
            type="url"
            id="youtubeChannelUrl"
            class="form-control"
            name="youtubeChannelUrl"
            placeholder="e.g: https://youtube.com/@veeva"
            [(ngModel)]="model.youtubeChannelUrl"
            [readonly]="!isFieldEditable('youtubeChannelUrl') || isObsolete()"
            validateDomain="youtube.com"
          />
        </div>
      </div>

      <!--LinkedIn Profile URL -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="linkedInProfileUrl">LinkedIn Profile URL</label>

        <div class="col-sm-8 col-md-10">
          <input
            type="url"
            id="linkedInProfileUrl"
            class="form-control"
            name="linkedInProfileUrl"
            placeholder="e.g: https://linkedin.com/in/veeva"
            [(ngModel)]="model.linkedInProfileUrl"
            [readonly]="!isFieldEditable('linkedInProfileUrl') || isObsolete()"
            validateDomain="linkedin.com"
          />
        </div>
      </div>

      <!-- Logo URL -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="remoteLogoUrl">Logo URL</label>

        <div class="col-sm-8 col-md-10">
          <input
            type="url"
            id="remoteLogoUrl"
            class="form-control"
            name="remoteLogoUrl"
            placeholder="e.g: https://veeva.com"
            [(ngModel)]="model.remoteLogoUrl"
            [readonly]="!isFieldEditable('remoteLogoUrl') || isObsolete() || !!model.useTwitterLogo"
            (change)="handleLogoPreviewURLChange(model.remoteLogoUrl)"
            *diInputErrorMsg="{
              text: 'Provided URL does not resolve to a valid image or none of image sizes exceeds 200px',
              show: !isLogoPreviewLoading && hasLogoPreviewError
            }"
          />
        </div>
      </div>

      <!-- Logo Web Source -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="logoWebSource">Logo Web Source</label>

        <div class="col-sm-8 col-md-10">
          <input
            type="url"
            id="logoWebSource"
            class="form-control"
            name="logoWebSource"
            placeholder="e.g: https://veeva.com"
            [(ngModel)]="model.logoWebSource"
            [readonly]="!isFieldEditable('logoWebSource') || isObsolete() || !!model.useTwitterLogo"
          />
        </div>
      </div>

      <!-- Twitter Logo -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">Use Twitter Logo</label>

        <div class="col-sm-8 col-md-10 pt-1">
          <ui-switch
            size="small"
            color="#6d5cae"
            [checked]="!!model.useTwitterLogo"
            [disabled]="!isFieldEditable('useTwitterLogo') || !model.twitterHandle"
            (change)="onUseTwitterLogoChange($event)"
          >
          </ui-switch>
        </div>
      </div>

      <!-- Original Logo Preview -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">Original Logo Preview</label>

        <div class="col-sm-8 col-md-10">
          <div
            class="association-logo-preview di-thumbnail-container"
            [ngClass]="{
              'load-error':
                (model.remoteLogoUrl && !isLogoPreviewLoading && hasLogoPreviewError) ||
                (model.useTwitterLogo && !isLogoPreviewLoading && hasTwitterHandleError)
            }"
          >
            <img
              #logoPreview
              class="img-thumbnail"
              [ngClass]="{ 'is-loading': isLogoPreviewLoading }"
              src="assets/blank-profile.png"
              (loadstart)="isLogoPreviewLoading = true"
              (load)="isLogoPreviewLoading = false"
              (error)="onLogoPreviewLoadFailure()"
            />

            <dirt-spinner [show]="isLogoPreviewLoading"></dirt-spinner>
          </div>
        </div>
      </div>
    </div>

    <!-- Priority -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Priority</label>
      <div class="col-sm-8 col-md-10">
        <ngb-rating name="priority" [(rate)]="model.priority" [readonly]="!isFieldEditable('priority')" max="5">
          <ng-template let-fill="fill" let-index="index">
            <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
          </ng-template>
        </ngb-rating>
      </div>
    </div>

    <!-- Address -->
    <div class="row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="address">Address</label>
      <div class="col-sm-8 col-md-10">
        <span *diMarker="{ name: 'address', entity: model.parent ? 'subassociation' : 'association', area: 'ALL' }">
          <dirt-address
            [address]="model.address"
            [stateSelector]="true"
            [addNoStateOption]="true"
            [disabled]="!isFieldEditable('address')"
            [countrySource]="'event-association-country'"
            *ngIf="!model.parent"
          >
          </dirt-address>
        </span>
      </div>
    </div>

    <!-- Phone -->
    <dirt-input-phone
      *ngIf="!model.parent"
      [model]="model.phone"
      [form]="ngForm"
      [disabled]="!isFieldEditable('phone')"
    >
    </dirt-input-phone>

    <!-- Email -->
    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="email">E-mail Address</label>
      <div class="col-sm-8 col-md-10">
        <div class="input-group">
          <input
            type="email"
            class="form-control"
            id="email"
            name="email"
            [ngModel]="model.email | decapitalize"
            (ngModelChange)="model.email = $event"
            [disabled]="!isFieldEditable('email')"
            validateEmail
          />
        </div>
      </div>
    </div>

    <div class="form-group row" *ngIf="isNewRecord()">
      <label class="col-form-label col-sm-4 col-md-2">Projects</label>
      <div class="col-md-2 di-project-names">
        <span
          *diMarker="{
            name: 'projectNames',
            entity: model.parent ? 'subassociation' : 'association',
            leftAlign: true,
            area: 'ALL'
          }"
        >
          <dirt-multiselect-dropdown
            name="projects"
            [ngClass]="{ disabled: !isFieldEditable('projectNames') }"
            [options]="projects"
            [settings]="projectsSettings"
            [(ngModel)]="model.projectNames"
            [required]="!model.parent"
          >
          </dirt-multiselect-dropdown>
        </span>
      </div>
    </div>

    <!-- Sponsors -->
    <div class="form-group row" *ngIf="model.parent">
      <label class="col-form-label col-sm-4 col-md-2">Sponsors</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multiselect-dropdown
          name="sponsors"
          [ngClass]="{ disabled: !isFieldEditable('sponsors') }"
          [options]="sponsorsFiltered"
          [settings]="sponsorsSettings"
          [(ngModel)]="model.sponsors"
          (lazyLoad)="filterSponsors($event)"
          *diMarker="{
            name: 'sponsor',
            entity: model.parent ? 'subassociation' : 'association',
            leftAlign: true,
            area: 'ALL'
          }"
        >
        </dirt-multiselect-dropdown>
      </div>
    </div>

    <div class="form-group row" *ngIf="!model.parent">
      <label class="col-form-label col-sm-4 col-md-2" for="ownerProduct">Owner product</label>

      <div class="col-sm-8 col-md-10 input-group">
        <select
          id="ownerProduct"
          class="form-control custom-select"
          name="ownerProduct"
          [(ngModel)]="model.ownerProduct"
          [required]="!model.id"
          [disabled]="model.id && !isFieldEditable('ownerProduct')"
        >
          <option *ngFor="let product of products" [value]="product.value">
            {{ product.value }}
          </option>
        </select>
      </div>
    </div>

    <hr [hidden]="model.parent" />
  </div>
</form>
