<section class="dirt-section-list dirt-guideline-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span *ngIf="!isPublicationGuideline">Guidelines</span>
        <span *ngIf="isPublicationGuideline">Publication Guidelines</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        id="guidelines-searchbar"
        class="di-search form-control ng-trim-ignore"
        [ngClass]="{ loading: isSearching }"
        [ngbTypeahead]="onSearchGuideline"
        [focusFirst]="false"
        [resultTemplate]="searchResultTemplate"
        placeholder="Search by Title or PubMed ID"
        [(ngModel)]="searchTerm"
        (keyup.enter)="onSearchGuidelineWithReRender()"
        (selectItem)="navigateTo($event.item._id)"
      />
    </div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Filter -->
        <dirt-filters [filters]="filters" (onFilter)="onFilter($event)"></dirt-filters>

        <!-- TODO: bulk -->

        <!-- Create Button -->
        <ng-template [diAcl]="'guideline.create'" [diAclAnd]="!isPublicationGuideline">
          <a class="btn btn-success btn-sm" [routerLink]="['/guideline/create']">
            <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
            <span>Create Guideline</span>
          </a>
        </ng-template>
      </div>
    </div>
  </header>

  <!-- Filter Bar -->
  <dirt-filters-bar
    [filter]="filter"
    [config]="filterBarConfig"
    (filterClear)="onFilterClear()"
    (filterValueRemove)="onFilterValueRemove($event)"
  ></dirt-filters-bar>

  <div class="pull-right">
    <!-- Dashboards -->
    <dirt-button-dropdown [title]="'Dashboards'" [icon]="'fa-tachometer'" *diAcl="'guideline.dashboards'">
      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/guideline-base/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Guideline Base
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/guideline-workflow-performance/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Guideline Workflow Performance
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/guideline-id-throughput/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Guideline ID Throughput
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/guideline-id-qc-throughput/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Guideline ID QC Throughput
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/guideline-id-qc-error-rate/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Guideline ID QC Error Rate
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/guideline-qc-throughput/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Guideline QC Throughput
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/guideline-qc-error-rate/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Guideline QC Error Rate
      </a>
    </dirt-button-dropdown>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-guideline-list'"
    [pagingSize]="guidelines?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="guidelines?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-state">&nbsp;</th>
          <th class="col-title" [diColSorter]="'title'">Title</th>
          <th class="col-publication-year" [diColSorter]="'publicationDate'">Pub. Year</th>
          <th class="col-country" [diColSorter]="'journal.country'">Country</th>
          <th class="col-category" [diColSorter]="'category'">Cat.</th>
          <th class="col-reach" [diColSorter]="'reach'">Reach</th>
          <th class="col-created" [diColSorter]="'createdAt'">Created</th>
          <th class="col-modified" [diColSorter]="'updatedAt'">Modified</th>
          <th class="col-reviewed" [diColSorter]="'reviewedAt'">Reviewed</th>
          <th class="col-status" [diColSorter]="'_meta.status'">Status</th>
          <th class="col-authors-count">
            <span ngbTooltip="Authors Count">
              <i class="fa fa-list" aria-hidden="true"></i>
            </span>
          </th>
          <th class="col-priority" [diColSorter]="'priority'">Priority</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-guideline-row"
          *ngFor="
            let guideline of guidelines
              | paginate
                : {
                    id: 'dirt-guideline-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: guidelineTrackBy
          "
        >
          <td class="col-state">
            <span
              class="state state-stacked state-success"
              ngbTooltip="PubMed"
              placement="right"
              *ngIf="guideline.externalIds?.pmid"
            ></span>
          </td>
          <td class="col-title">
            <a
              *ngIf="!isPublicationGuideline"
              class="dirt-guideline-name"
              [title]="guideline.title | dash"
              [routerLink]="['/guideline/detail', guideline._id]"
              >{{ guideline.title | dash }}</a
            >
            <a
              *ngIf="isPublicationGuideline"
              class="dirt-guideline-name"
              [title]="guideline.title | dash"
              [routerLink]="['/publication-guideline/detail', guideline._id]"
              >{{ guideline.title | dash }}</a
            >
          </td>
          <td class="col-publication-year">{{ guideline.publicationDate | date : 'y' }}</td>
          <td class="col-country">{{ guideline.journal?.country === null ? '-' : guideline.journal?.country }}</td>
          <td class="col-category">{{ guideline.category }}</td>
          <td class="col-reach">{{ guideline.reach | capitalize }}</td>
          <td class="col-created">{{ guideline.createdAt | date : 'dd.MM.y' }}</td>
          <td class="col-modified">{{ guideline.updatedAt | date : 'dd.MM.y' }}</td>
          <td class="col-reviewed">{{ guideline.reviewedAt | date : 'dd.MM.y' }}</td>
          <td class="col-status" [title]="guideline._meta?.status | capitalize">
            {{ guideline._meta?.status | capitalize }}
          </td>
          <td class="col-authors-count">{{ guideline.authorsCount | number }}</td>
          <td class="col-priority" [ngClass]="{ disabled: !isPriorityEditable }">
            <ngb-rating
              [rate]="guideline.priority"
              (rateChange)="setPriority(guideline, $event)"
              [readonly]="!isPriorityEditable"
              max="5"
            >
              <ng-template let-fill="fill" let-index="index">
                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
              </ng-template>
            </ngb-rating>
          </td>
          <td class="col-actions">
            <ng-template [diAcl]="'guideline.delete'" [diAclAnd]="!isPublicationGuideline">
              <!-- Delete -->
              <span class="action-btn action-delete" title="Delete Guideline" (click)="onDelete(guideline._id)">
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p *ngIf="!isPublicationGuideline" class="dirt-no-results">No Guidelines</p>
      <p *ngIf="isPublicationGuideline" class="dirt-no-results">No Publication Guidelines</p>
    </ng-template>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-guideline-list'"
    [pagingSize]="guidelines?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="isLoading || pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div class="di-sac-id pull-right">{{ r.externalIds.pmid || r.externalIds.pubmed }}</div>
  <div class="di-sac-title">
    <span>{{ r.title }}</span>
    <span *ngIf="r.originalTitle">({{ r.originalTitle }})</span>
  </div>
</ng-template>
