import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';

import { AccountAPI } from './shared/account-api.service';
import { AccountComponent } from './account.component';
import { AccountCreateComponent } from './create/create.component';
import { AccountDetailComponent } from './detail/detail.component';
import { AccountFinancialInformationModule } from '../account-financial-information/account-financial-information.module';
import { AccountFormComponent } from './shared/form/form.component';
import { AccountListComponent } from './list/list.component';
import { AccountMedicalInformationModule } from '../account-medical-information/account-medical-information.module';
import { accountRouting } from './account.routes';
import { CommentModule } from '../comment/comment.module';
import { SharedModule } from '../shared/shared.module';
import { AccountAffiliationModule } from '../account-affiliation/account-affiliation.module';
import { AccountAffiliationSuspectModule } from '../account-affiliation-suspect/account-affiliation-suspect.module';
import { AccountSimpleListComponent } from './simple-list/simple-list.component';
import { AccountSingleSelectComponent } from './shared/components/account-single-select/account-single-select';
import { AccountMoveModalService } from './shared/move-modal/move-modal.service';
import { AccountMoveModalComponent } from './shared/move-modal/move-modal.component';
import { AccountPayerInformationModule } from '../account-payer-information/account-payer-information.module';
import { AccountPersonModule } from '../account-person/account-person.module';

@NgModule({
  imports: [
    SharedModule,
    CommentModule,
    NgbAccordionModule,
    AccountFinancialInformationModule,
    AccountMedicalInformationModule,
    AccountAffiliationModule,
    AccountAffiliationSuspectModule,
    AccountPayerInformationModule,
    AccountPersonModule,
    accountRouting,
  ],
  declarations: [
    AccountComponent,
    AccountListComponent,
    AccountSimpleListComponent,
    AccountCreateComponent,
    AccountDetailComponent,
    AccountFormComponent,
    AccountSingleSelectComponent,
    AccountMoveModalComponent,
  ],
  exports: [AccountSingleSelectComponent],
  providers: [AccountAPI, AccountMoveModalService],
})
export class AccountModule {}
