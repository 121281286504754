<span class="dirt-user-name">
  <span *ngIf="user?.user_id && !id">
    <!-- Email -->
    <a href="mailto:{{ user.email }}" class="dirt-email" title="Email to: {{ user.email }}" *ngIf="mailto">
      <i class="fa fa-envelope-o" aria-hidden="true"></i>
    </a>
    <span [hidden]="hideLFKAUserBadge()">
      <span [hidden]="!isLFKAUser()" title="created by / for LFKA" class="badge badge-lfka">LFKA</span>
      <span [hidden]="isLFKAUser() || isMedTechUser()" title="created by / for LFTA" class="badge badge-lfta">{{
        'LFTA' | productLabel
      }}</span>
      <span [hidden]="!isMedTechUser()" title="created by / for MedTech" class="badge badge-lmt">{{
        'LMT' | productLabel
      }}</span>
    </span>
    <span class="dirt-name">
      <a
        [routerLink]="['/user/detail', user.user_id]"
        rel="noopener"
        target="_blank"
        [ngStyle]="{ 'font-weight': fontWeight }"
        >{{ getName() }}</a
      >
    </span>
  </span>

  <span *ngIf="!user?.user_id && !id">
    <span class="dirt-name">
      <a [routerLink]="['/user/detail', user]" rel="noopener" target="_blank" [ngStyle]="{ 'font-weight': fontWeight }"
        >User {{ getUserAlias(user) }}</a
      >
    </span>
  </span>

  <span *ngIf="id && name">
    <span class="dirt-name">
      <a [routerLink]="['/user/detail', id]" rel="noopener" target="_blank" [ngStyle]="{ 'font-weight': fontWeight }">{{
        name
      }}</a>
    </span>
  </span>

  <span *ngIf="id && !name">
    <span class="dirt-name">
      <a [routerLink]="['/user/detail', id]" rel="noopener" target="_blank" [ngStyle]="{ 'font-weight': fontWeight }"
        >user | {{ getUserAlias(id) }}</a
      >
    </span>
  </span>
</span>
