<section class="dirt-section-create dirt-account-payer-information-create">
  <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
    <span class="fa fa-arrow-left" aria-hidden="true"></span>
  </button>

  <h1 class="bounceInRight">Create Payer Information</h1>

  <br />

  <dirt-account-payer-information-form
    [accountPayerInformation]="accountPayerInformation"
    #frmAccountPayerInformation="frmAccountPayerInformation"
    (validityChange)="onFormValidityChange($event)"
    (isTotalShareValid)="handleSharesValidityChange($event)"
  >
  </dirt-account-payer-information-form>

  <div class="pull-right">
    <ng-template [diAcl]="'account-payer-information.create'">
      <button
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="!isFormValid || isSubmitting"
        (click)="onSubmit(frmAccountPayerInformation.getValue())"
      >
        Submit
      </button>
    </ng-template>
  </div>
</section>
