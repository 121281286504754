<span [ngClass]="{wide: wide}">
  <input
    type="search"
    class="di-search form-control ng-trim-ignore"
    [ngClass]="{ loading: isSearching }"
    [ngbTypeahead]="searchCommittees"
    [focusFirst]="false"
    [resultTemplate]="searchResultTemplate"
    (selectItem)="onChangeCommittee($event.item)"
    [(ngModel)]="searchTerm"
  />
</span>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <ng-container>
    <div class="di-sac-verified pull-left">
      <dirt-verified-icon color="success" *ngIf="r?.verified"></dirt-verified-icon>
    </div>
    <div class="di-sac-committee pull-left">
      <div class="di-sac-name">{{ r?.name }}</div>
      <div class="di-sac-address">{{ r?.address?.formatted || '&mdash;' }}</div>
    </div>
  </ng-container>
</ng-template>
