import { Pipe } from '@angular/core';
import { PipeTransform } from '@angular/core';

const REGEX = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/;

@Pipe({ name: 'domain' })
export class DomainPipe implements PipeTransform {
  transform(value: string) {
    return value && value.match(REGEX)[1];
  }
}
