import { ProfileViewMode } from '../../shared/constant/view-mode.enum';
import { ProfileMatchAutomate } from '../../shared/constant/match-automate.enum';
import { ProfileMatchManual } from '../../shared/constant/match-manual.enum';
import { Roles } from '../../../shared/acl/roles';

const ASIA_FA_IDS = [
  '70',
  '89',
  '120',
  '196',
  '225',
  '491',
  '492',
  '493',
  '507',
  '524',
  '540',
  '554',
  '575',
  '654',
  '873',
  '883',
  '985',
  '1093',
  '1328',
  '1374',
  '1428',
  '1599',
  '1604',
  '1605',
  '1607',
  '1615',
  '1616',
  '1663',
  '1915',
  '1985',
  '2024',
  '2042',
  '2050',
  '2072',
  '2169',
  '2276',
  '2514',
  '2640',
  '2975',
  '3180',
  '3190',
  '3422',
  '3459',
  '3460',
  '3471',
  '3530',
];

export function ViewModeFilter(
  obj: { _profile: { match?: { automate?; direct?; manual?; pendingSubmission? }; focusAreaIds? } },
  viewMode: string,
  userRoles: string[],
  useAsiaFaIds?: boolean /*pubs*/,
  traineeView?: boolean
) {
  let res;
  if (userRoles.includes(Roles.ProfileCompiler)) {
    // COMPILER
    switch (viewMode) {
      case 'DEFAULT':
        res =
          obj._profile.match?.automate !== ProfileMatchAutomate.SUSPECT_1 ||
          obj._profile.match?.direct ||
          obj._profile.match?.manual === ProfileMatchManual.MATCH ||
          obj._profile.match?.manual === ProfileMatchManual.MISMATCH;
        break;

      case 'DEFAULT_DELTA':
        res = obj._profile.match?.automate !== ProfileMatchAutomate.SUSPECT_1;
        if (!traineeView) {
          res =
            res && (obj._profile.match?.manual === ProfileMatchManual.NONE || obj._profile.match?.pendingSubmission);
        }
        break;

      case 'CHINA_KOREA':
        res =
          obj._profile.match?.direct ||
          obj._profile.match?.automate === ProfileMatchAutomate.MATCH ||
          obj._profile.match?.manual === ProfileMatchManual.MATCH ||
          obj._profile.match?.manual === ProfileMatchManual.MISMATCH ||
          (obj._profile.match?.automate === ProfileMatchAutomate.SUSPECT &&
            (!useAsiaFaIds || !!ASIA_FA_IDS.find((faId) => obj._profile.focusAreaIds?.includes(faId))));
        break;

      case 'CHINA_KOREA_DELTA':
        res =
          obj._profile.match?.automate === ProfileMatchAutomate.MATCH ||
          (obj._profile.match?.automate === ProfileMatchAutomate.SUSPECT &&
            (!useAsiaFaIds || !!ASIA_FA_IDS.find((faId) => obj._profile.focusAreaIds?.includes(faId))));
        if (!traineeView) {
          res =
            res && (obj._profile.match?.manual === ProfileMatchManual.NONE || obj._profile.match?.pendingSubmission);
        }
        break;

      case 'DOUBLE_NAMES':
        // no filter!
        res = true;
        break;

      case 'DOUBLE_NAMES_DELTA':
        if (!traineeView) {
          res = obj._profile.match?.manual === ProfileMatchManual.NONE || obj._profile.match?.pendingSubmission;
        }
        break;
    }
  } else {
    // ADMIN, PROFILE_MANAGER, PROFILE_MODERATOR, PROFILE_VIEWER
    switch (viewMode) {
      case 'DEFAULT':
      case 'DEFAULT_DELTA':
        res =
          obj._profile.match?.automate !== ProfileMatchAutomate.SUSPECT_1 ||
          obj._profile.match?.direct ||
          obj._profile.match?.manual === ProfileMatchManual.MATCH ||
          obj._profile.match?.manual === ProfileMatchManual.MISMATCH;
        break;

      case 'CHINA_KOREA':
      case 'CHINA_KOREA_DELTA':
        res =
          obj._profile.match?.direct ||
          obj._profile.match?.automate === ProfileMatchAutomate.MATCH ||
          obj._profile.match?.manual === ProfileMatchManual.MATCH ||
          obj._profile.match?.manual === ProfileMatchManual.MISMATCH ||
          (obj._profile.match?.automate === ProfileMatchAutomate.SUSPECT &&
            (!useAsiaFaIds || !!ASIA_FA_IDS.find((faId) => obj._profile.focusAreaIds?.includes(faId))));
        break;

      case 'DOUBLE_NAMES':
      case 'DOUBLE_NAMES_DELTA':
        // no filter!
        res = true;
        break;
    }
  }
  return !!res;
}
