import { Component, OnInit } from '@angular/core';

import { Association } from '../shared/association';
import { AssociationAPI } from '../shared/association-api.service';

@Component({
  selector: 'dirt-association-search',
  templateUrl: 'search.component.html',
  styleUrls: ['search.component.scss'],
})
export class AssociationSearchComponent implements OnInit {
  isLoading: boolean;
  associations: Array<Association>;

  model: any = {};

  constructor(private svcAssociation: AssociationAPI) {}

  ngOnInit() {
    this.getAssociations();
  }

  getAssociations() {
    this.isLoading = true;

    this.svcAssociation
      .search(this.model.name, this.model.originalName, this.model.webSource, this.model.rootOnly)
      .subscribe(
        (res) => (this.associations = res),
        null,
        () => (this.isLoading = false)
      );
  }

  onSearch(): void {
    this.getAssociations();
  }
}
