export const environment = {
  production: true,
  clientEndpoint: 'https://nightly.veeva.io/',
  apiEndpoint: 'https://api.nightly.veeva.io/',
  auth0Domain: 'veeva-test.auth0.com',
  auth0ClientId: 'wyMDSJZ2ZfqFvbMmNrW0X1ljqej7amHB',
  agGridLicense:
    'Veeva_Systems_GmbH_cLean_(Capture_Lean)_2Devs16_April_2019__MTU1NTM2OTIwMDAwMA==8be0eda0cfb902858e6e38429bd4f733',
  gtagId: 'G-FESQJVX99Q',
  trainingEnabled: true,
  enableRUM: false,
};
