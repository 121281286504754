import { Component, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { ValueAPI } from '../../../../shared/services/value/value-api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { ValueType } from '../../../../shared/enum/value-type.enum';
import { Value } from '../../../../shared/services/value/value';

@Component({
  selector: 'dirt-value-not-in-list-modal',
  templateUrl: './not-in-list.component.html',
  styleUrls: ['./not-in-list.component.scss'],
})
export class ValueNotInListModalComponent {
  type: ValueType;
  normalizeCode: boolean;
  code: string;
  value: string;

  isSubmitting = false;

  constructor(private svcValue: ValueAPI, public activeModal: NgbActiveModal) {}

  @ViewChild(NgForm)
  private valueForm: NgForm;

  deriveCode(v) {
    this.code = !this.normalizeCode
      ? (v || '').trim()
      : (v || '')
          .trim()
          .toUpperCase()
          .substring(0, 20)
          .trim()
          .replace(/[^A-Z0-9]/g, '_');
  }

  onCancelClick() {
    this.activeModal.close(null);
  }

  onSubmit() {
    const vl = (this.value || '').trim();
    const cd = (this.code || '').trim();
    if (!vl || !this.valueForm.form.valid) {
      return;
    }
    this.svcValue
      .checkExists(this.type, cd)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.exists) {
          alert('That code already exists');
          return;
        }
        this.svcValue
          .suggest({
            type: this.type,
            code: cd,
            value: vl,
          } as Value)
          .pipe(take(1))
          .subscribe((res) => this.activeModal.close(res));
      });
  }
}
