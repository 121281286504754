import { NgModule, Type } from '@angular/core';

import { DiSort } from './data-table/sort.directive';
import { DiColumnSort } from './data-table/column-sorter.directive';
import { DirtGooglePlaceDirective } from './google-place/google-place.directive';
import { DirtScrollerDirective } from './scroller/scrollbar.directive';
import { DirtPasteNormalizerDirective } from './form/paste-normalizer.directive';
import { EntityMarkerDirective } from './marker/marker.directive';
import { PersonMarkerWrapperDirective } from './marker-wrapper/wrapper.directive';
import { TrainingEvaluationMarkerDirective } from './training-evaluation-marker/training-evaluation-marker.directive';
import { TrainingEvaluationMarkerWrapperDirective } from './training-evaluation-marker/training-evaluation-marker-wrapper.directive';
import { ViewContainerDirective } from './view-container/view-container.directive';
import { LongDashDirective } from './long-dash/long-dash.directive';
import { InputNumberScrollOverrideDirective } from './input-number-scroll-override/input-number-scroll-override.directive';

export { DiSort } from './data-table/sort.directive';
export { DiColumnSort } from './data-table/column-sorter.directive';
export { DirtGooglePlaceDirective } from './google-place/google-place.directive';
export { DirtScrollerDirective } from './scroller/scrollbar.directive';
export { DirtPasteNormalizerDirective } from './form/paste-normalizer.directive';
export { EntityMarkerDirective } from './marker/marker.directive';
export { PersonMarkerWrapperDirective } from './marker-wrapper/wrapper.directive';
export { TrainingEvaluationMarkerDirective } from './training-evaluation-marker/training-evaluation-marker.directive';
export { TrainingEvaluationMarkerWrapperDirective } from './training-evaluation-marker/training-evaluation-marker-wrapper.directive';
export { ViewContainerDirective } from './view-container/view-container.directive';
export { LongDashDirective } from './long-dash/long-dash.directive';

export const DIRT_DIRECTIVES: Type<any>[] = [
  DiSort,
  DiColumnSort,
  DirtGooglePlaceDirective,
  DirtScrollerDirective,
  DirtPasteNormalizerDirective,
  EntityMarkerDirective,
  PersonMarkerWrapperDirective,
  TrainingEvaluationMarkerDirective,
  TrainingEvaluationMarkerWrapperDirective,
  ViewContainerDirective,
  LongDashDirective,
  InputNumberScrollOverrideDirective,
];

/**
 * Internal module used for sharing directives
 */
@NgModule({
  declarations: DIRT_DIRECTIVES,
  exports: DIRT_DIRECTIVES,
})
export class DirtDirectivesModule {}
