<section class="dirt-section-list dirt-person-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span *ngIf="total">{{ total.count | number }}</span> People
      </h1>
    </div>
    <div class="col-sm-4" *ngIf="!advancedSearchSpec">
      <!-- Search -->
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        [ngClass]="{ loading: isSearching }"
        [ngbTypeahead]="searchAutoComplete"
        [focusFirst]="false"
        [resultTemplate]="searchResultTemplate"
        (selectItem)="navigateTo(['/person/detail', $event.item.id])"
        [(ngModel)]="searchTerm"
        (keyup.enter)="doLoad()"
        placeholder="Search by Name, Person or Opendata ID"
      />
      <small><a class="alink" (click)="openAdvancedSearch()">Advanced search</a></small>
    </div>
    <div class="col-sm-4" *ngIf="advancedSearchSpec">
      <small
        ><a class="alink" (click)="openAdvancedSearch()">Change search</a>
        <a class="alink" (click)="clearAdvancedSearch()">Clear search</a></small
      >
    </div>
    <dialog class="advanced-search-dialog" #advancedSearchDialog (keyup.enter)="runAdvancedSearch()">
      <dirt-person-advanced-search
        *ngIf="advancedSearchSpec"
        [model]="advancedSearchSpec"
      ></dirt-person-advanced-search>
      <hr />
      <div class="pull-right">
        <button class="btn" (click)="advancedSearchDialogElement.nativeElement.close()">Cancel</button>
        <button class="btn" (click)="clearAdvancedSearch()">Clear</button>
        <button class="btn btn-primary" (click)="runAdvancedSearch()">GO</button>
      </div>
    </dialog>
    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Filter -->
        <!-- TODO: replace that by the generic filter component when there is a change to make here -->
        <dirt-person-filter *ngIf="!advancedSearchSpec" (onFilter)="setFilter($event)"></dirt-person-filter>

        <dirt-button-dropdown
          [title]="'Bulk'"
          [icon]="'fa-pencil'"
          *diAcl="'person.list.bulkUpdate || person.bulkList'"
        >
          <dirt-bulk-update
            *diAcl="'person.list.bulkUpdate'"
            [filter]="!advancedSearchSpec ? filter : null"
            [search]="!advancedSearchSpec ? searchTerm : null"
            (refresh)="onBulkUpdateRefresh()"
            [options]="bulkUpdateConfig"
          ></dirt-bulk-update>

          <ng-template [diAcl]="'person.bulkList'">
            <button
              class="list-group-item list-group-item-action"
              [title]="bulkListDelegate.specialHint || ''"
              (click)="onOpenBulkListDialog()"
            >
              <i class="fa fa-th-list" aria-hidden="true"></i>
              Bulk import
            </button>

            <button
              class="list-group-item list-group-item-action"
              [title]="twitterBulkListDelegate.specialHint || ''"
              (click)="onOpenBulkListTwitterDialog()"
            >
              <i class="fa fa-th-list" aria-hidden="true"></i>
              Bulk Import Twitter
            </button>

            <button
              class="list-group-item list-group-item-action"
              [title]="complianceBulkListDelegate.specialHint || ''"
              (click)="onOpenBulkListComplianceDialog()"
            >
              <i class="fa fa-th-list" aria-hidden="true"></i>
              Bulk Import Compliance
            </button>

            <button
              class="list-group-item list-group-item-action"
              [title]="jobBulkListDelegate.specialHint || ''"
              (click)="onOpenBulkListJobDialog()"
            >
              <i class="fa fa-th-list" aria-hidden="true"></i>
              Bulk Import Job
            </button>
          </ng-template>
        </dirt-button-dropdown>

        <!-- Create Button -->
        <button class="btn btn-success btn-sm" (click)="navigateTo(['/person/create'])" *diAcl="'person.create'">
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Create Person
        </button>
      </div>
    </div>
  </header>

  <div class="pull-right">
    <!-- Dashboards -->
    <dirt-button-dropdown [title]="'Dashboards'" [icon]="'fa-tachometer'" *diAcl="'person.dashboards'">
      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/profiling-qc/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Profiling QC
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/qc-feedback/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        QC Feedback
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/people-idbd-throughput/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        ID BD Throughput
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/people-idbd-qc/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        ID BD QC
      </a>
    </dirt-button-dropdown>

    <!-- Export -->
    <button class="btn btn-secondary btn-sm" (click)="exportPersons()" *diAcl="'person.export'">
      <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Export List
    </button>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-person-list'"
    [pagingSize]="people?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Table -->
  <div *ngIf="people" [hidden]="isLoading">
    <table class="table table-sm table-hover" [hidden]="!people.length" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-state">&nbsp;</th>
          <th class="col-firstname" [diColSorter]="'firstName.sc'">First Name</th>
          <th class="col-middlename" [diColSorter]="'middleName.sc'">Middle Name</th>
          <th class="col-lastname" [diColSorter]="'lastName.sc'">Last Name</th>
          <th class="col-country" [diColSorter]="'opCountry.keyword'">Country</th>
          <th class="col-ta">Delivery projects</th>
          <th class="col-ta">Technical projects</th>
          <th class="col-automated">&nbsp;</th>
          <th class="col-verified">&nbsp;</th>
          <th class="col-status" [diColSorter]="'opMetaStatus.keyword'">Status</th>
          <th class="col-modified" [diColSorter]="'opUpdatedAt'" *ngIf="!advancedSearchSpec">Modified</th>
          <th class="col-modified" *ngIf="advancedSearchSpec">Modified</th>
          <th class="col-reviewedAt" [diColSorter]="'review.at'" *ngIf="!advancedSearchSpec">Compiled</th>
          <th class="col-reviewedAt" *ngIf="advancedSearchSpec">Compiled</th>
          <th class="col-priority" [diColSorter]="'priority'" *ngIf="!advancedSearchSpec">Priority</th>
          <th class="col-priority" *ngIf="advancedSearchSpec">Priority</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="dirt-person-row"
          *ngFor="
            let person of people
              | paginate
                : {
                    id: 'dirt-person-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  }
          "
          (mouseover)="onRowMouseover(person)"
          (click)="navigateTo(['/person/detail', person.id], $event)"
        >
          <td class="dirt-person-status">
            <span [ngSwitch]="person.status">
              <span *ngSwitchCase="'DECEASED'" ngbTooltip="Deceased" placement="right" aria-hidden="true">&#9401;</span>
              <span *ngSwitchCase="'RETIRED'" ngbTooltip="Retired" placement="right" aria-hidden="true">&#9415;</span>
            </span>
            <span
              *ngIf="person.optoutStatus && person.optoutStatus.includes('OPTOUT_FOR_TA')"
              ngbTooltip="Opt-Out"
              placement="right"
              aria-hidden="true"
              >&#9412;</span
            >
            <span
              *ngIf="
                (person._meta?.status === 'PENDING_VERIFICATION' ||
                  person._meta?.status === 'VERIFICATION_IN_PROGRESS') &&
                !person.projectNames?.includes('DEDUPLICATION') &&
                person._meta?.assignee
              "
              class="dirt-person-reserve dirt-person-reserved"
              title="This person is reserved for identification"
              >res</span
            >
          </td>
          <td class="col-firstname" (mouseover)="onNameMouseover(person)">
            <a class="dirt-person-name" [routerLink]="['/person/detail', person.id]">{{ person.firstName }}</a>
          </td>
          <td class="col-middlename" (mouseover)="onNameMouseover(person)">{{ person.middleName }}</td>
          <td class="col-lastname" (mouseover)="onNameMouseover(person)">
            <a class="dirt-person-name" [routerLink]="['/person/detail', person.id]">{{ person.lastName }}</a>
            <div
              class="dirt-person-overlay"
              *ngIf="currentOverlayPerson && currentOverlayPerson.kolId === person.kolId"
            >
              <span title="first name">{{ person.firstName }}</span>
              <span title="middle name">{{ person.middleName }}</span>
              <span title="nick name(s)">{{
                person.nicknames?.join(', ').length > 0 ? ' (' + person.nicknames.join(', ') + ')' : ''
              }}</span>
              <span title="last name">{{ person.lastName }}</span>
              <span title="alternative name(s)">{{
                person.alternativeNames?.join(', ').length > 0 ? ' (' + person.alternativeNames.join(', ') + ')' : ''
              }}</span
              ><br />
              <em>{{ calcAffiliationCaption(person) }}</em
              ><br />
              <small>{{ calcDegreesCaption(person) }} {{ calcSpecialtiesCaption(person) }}</small>
            </div>
          </td>
          <td class="col-country">{{ getCountryTitle(getPrimaryCountryCode(person)) }}</td>
          <td class="col-ta">
            <span [ngbTooltip]="getDelList(person)">{{ getDelList(person) }}</span>
          </td>
          <td class="col-ta">
            <span [ngbTooltip]="getTechList(person)">{{ getTechList(person) }}</span>
          </td>
          <td class="col-automated">
            <span *ngIf="isAutoProject(person)" ngbTooltip="Automated" placement="right" aria-hidden="true"
              >&#9398;</span
            >
          </td>
          <td class="col-verified" [ngStyle]="{ color: !person.verified ? 'darkgrey' : null }">
            <dirt-verified-icon
              *ngIf="person.verified || person.idverified"
              [ngbTooltip]="(person.idverified ? 'ID-verified ' : '') + (person.verified ? 'Verified' : '')"
              placement="top"
              aria-hidden="true"
            ></dirt-verified-icon>
          </td>
          <td class="col-status">{{ person._meta?.status | capitalize }}</td>
          <td class="col-modified">{{ person.updatedAt | date : 'dd.MM.y' }}</td>
          <td class="col-modified">{{ person.review?.at | date : 'dd.MM.y' }}</td>
          <td class="col-priority">
            <ngb-rating
              [rate]="person.priority"
              (rateChange)="setPriority(person, $event)"
              [readonly]="!isPriorityEditable()"
              (click)="$event.stopPropagation()"
              max="5"
            >
              <ng-template let-fill="fill" let-index="index">
                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
              </ng-template>
            </ngb-rating>
          </td>
          <td class="col-actions">
            <!-- Delete -->
            <span class="action-btn action-delete" (click)="onDelete(person.id, $event)" *diAcl="'person.delete'">
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <p class="dirt-no-results" *ngIf="!people.length">No People</p>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-person-list'"
    [pagingSize]="people?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="isLoading || pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div (click)="onTypeaheadResClick($event, r)">
    <div class="di-sac-kol-id pull-right">{{ r.kolId }}</div>
    <div class="di-sac-name">
      <span>{{ r.firstName }} {{ r.middleName }} {{ r.lastName }}</span>
      <span *ngIf="r.originalFullName">({{ r.originalFullName }})</span>
      <span class="di-sac-status">{{ r._meta?.status || '-' | capitalize }}</span>
    </div>
  </div>
</ng-template>
