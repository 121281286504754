<section class="dirt-section-create dirt-association-create">
  <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
    <span class="fa fa-arrow-left" aria-hidden="true"></span>
  </button>

  <h1 class="bounceInRight">
    <span [hidden]="association.parent">Create Association</span>
    <span [hidden]="!association.parent">Create Sub Association</span>
  </h1>

  <br />

  <dirt-association-form [association]="association" #frmAssociation="frmAssociation"></dirt-association-form>

  <div>
    <dirt-ocr-bar [webSource]="null"></dirt-ocr-bar>
  </div>

  <div class="pull-right">
    <!-- Association -->
    <ng-template [diAcl]="'association.create'" [diAclAnd]="!association.parent">
      <!-- Submit -->
      <button
        type="submit"
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="!frmAssociation.isValid() || isSubmitting"
        (click)="onSubmit(frmAssociation.getValue(), 'SAVE_AND_BACK')"
      >
        <span>Submit</span>
      </button>

      <button
        type="submit"
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="!frmAssociation.isValid() || isSubmitting"
        (click)="onSubmit(frmAssociation.getValue(), 'SAVE_AND_NEW')"
      >
        <span>Save & New</span>
      </button>
    </ng-template>

    <!-- Sub Association -->
    <ng-template [diAcl]="'subassociation.create'" [diAclAnd]="!!association.parent">
      <button
        type="submit"
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="!frmAssociation.isValid() || isSubmitting"
        (click)="onSubmit(frmAssociation.getValue(), 'SAVE')"
      >
        <span>Submit</span>
      </button>

      <button
        type="submit"
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="!frmAssociation.isValid() || isSubmitting"
        (click)="onSubmit(frmAssociation.getValue(), 'SAVE_AND_NEW')"
      >
        <span>Save & New</span>
      </button>
    </ng-template>
  </div>
</section>
