import { Component, Input } from '@angular/core';

import { ACL } from '../../../shared/acl/acl.service';
import { Profile } from '../profile';
import { ProfileAPI } from '../api.service';

@Component({
  selector: 'dirt-profile-priority',
  templateUrl: 'priority.component.html',
  styleUrls: ['priority.component.scss'],
})
export class ProfilePriorityComponent {
  @Input()
  profile: Profile;

  constructor(private svcAcl: ACL, private svcProfile: ProfileAPI) {}

  setPriority(profile: Profile, priority: number): void {
    if (!priority) {
      return;
    }

    this.svcProfile.update(profile.id, <Profile>{ priority }).subscribe(() => {});
  }

  isEditable(): boolean {
    return this.svcAcl.hasCredential('profile.update');
  }
}
