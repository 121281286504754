import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * Angular 2 RESTClient class.
 *
 * @class RESTClient
 * @constructor
 */
@Injectable()
export class RESTClient {
  constructor(protected http: HttpClient) {}

  protected getBaseUrl(): string {
    return;
  }

  protected getDefaultHeaders(): Object {
    return;
  }

  /**
   * Response Interceptor
   *
   * @method responseInterceptor
   * @param {HttpResponse} res - response object
   * @returns {HttpResponse} res - transformed response object
   */
  protected responseInterceptor(res: Observable<any>): Observable<any> {
    return res;
  }
}

/**
 * Set the base URL of REST resource
 * @param {String} url - base URL
 */
export function BaseUrl(url: string) {
  return function <TFunction extends Function>(Target: TFunction): TFunction {
    Target.prototype.getBaseUrl = function () {
      return url;
    };
    return Target;
  };
}

/**
 * Set default headers for every method of the RESTClient
 * @param {Object} headers - deafult headers in a key-value pair
 */
export function DefaultHeaders(headers: any) {
  return function <TFunction extends Function>(Target: TFunction): TFunction {
    Target.prototype.getDefaultHeaders = function () {
      return headers;
    };
    return Target;
  };
}

function paramBuilder(paramName: string) {
  return function (key: string) {
    return function (target: RESTClient, propertyKey: string | symbol, parameterIndex: number) {
      const metadataKey = `${propertyKey.toString()}_${paramName}_parameters`;
      const paramObj = {
        key: key,
        parameterIndex: parameterIndex,
      };

      if (Array.isArray(target[metadataKey])) {
        target[metadataKey].push(paramObj);
      } else {
        target[metadataKey] = [paramObj];
      }
    };
  };
}

/**
 * Path variable of a method's url, type: string
 * @param {string} key - path key to bind value
 */
export const Path = paramBuilder('Path');
/**
 * Query value of a method's url, type: string
 * @param {string} key - query key to bind value
 */
export const Query = paramBuilder('Query');
/**
 * Body of a REST method, type: key-value pair object
 * Only one body per method!
 */
export const Body = paramBuilder('Body')('Body');
/**
 * Custom header of a REST method, type: string
 * @param {string} key - header key to bind value
 */
export const Header = paramBuilder('Header');

/**
 * Set custom headers for a REST method
 * @param {Object} headersDef - custom headers in a key-value pair
 */
export function Headers(headersDef: any) {
  return function (target: RESTClient, propertyKey: string, descriptor: any) {
    descriptor.headers = headersDef;
    return descriptor;
  };
}

/**
 * Defines the media type(s) that the methods can produce
 * @param producesDef MediaType - mediaType to be parsed
 */
export function Produces(producesDef: MediaType) {
  return function (target: RESTClient, propertyKey: string, descriptor: any) {
    descriptor.isJSON = producesDef === MediaType.JSON;
    return descriptor;
  };
}

/**
 * Supported @Produces media types
 */
export enum MediaType {
  JSON,
}

function methodBuilder(method: string) {
  return function (url: string) {
    return function (target: RESTClient, propertyKey: string, descriptor: any) {
      const pPath = target[`${propertyKey}_Path_parameters`];
      const pQuery = target[`${propertyKey}_Query_parameters`];
      const pBody = target[`${propertyKey}_Body_parameters`];
      const pHeader = target[`${propertyKey}_Header_parameters`];

      descriptor.value = function (...args: any[]) {
        // Body
        let body = null;

        if (pBody) {
          body = JSON.stringify(args[pBody[0].parameterIndex]);
        }

        // Path
        let resUrl: string = url;

        if (pPath) {
          for (const k in pPath) {
            if (pPath.hasOwnProperty(k)) {
              resUrl = resUrl.replace('{' + pPath[k].key + '}', args[pPath[k].parameterIndex]);
            }
          }
        }

        // Query
        let queryParams = new HttpParams();

        if (pQuery) {
          pQuery
            .filter((p) => args[p.parameterIndex]) // filter out optional parameters
            .forEach((p) => {
              const key = p.key;
              let value = args[p.parameterIndex];
              // if the value is a instance of Object, we stringify it
              if (value instanceof Object) {
                value = JSON.stringify(value);
              }
              queryParams = queryParams.set(encodeURIComponent(key), encodeURIComponent(value));
            });
        }

        // Headers
        // set class default headers
        let headers = new HttpHeaders(this.getDefaultHeaders());

        // set method specific headers
        for (const k in descriptor.headers) {
          if (descriptor.headers.hasOwnProperty(k)) {
            headers = headers.append(k, descriptor.headers[k]);
          }
        }

        // set parameter specific headers
        if (pHeader) {
          for (const k in pHeader) {
            if (pHeader.hasOwnProperty(k)) {
              headers = headers.append(pHeader[k].key, args[pHeader[k].parameterIndex]);
            }
          }
        }

        const reqUrl = this.getBaseUrl() + resUrl;

        // make the request and store the observable for later transformation
        let observable: Observable<HttpResponse<any>> = this.http.request(method, reqUrl, {
          body,
          headers,
          params: queryParams,
          observe: 'body',
        });

        // intercept the response
        observable = this.responseInterceptor(observable);

        return observable;
      };

      return descriptor;
    };
  };
}

/**
 * GET method
 * @param {string} url - resource url of the method
 */
export const GET = methodBuilder('GET');
/**
 * POST method
 * @param {string} url - resource url of the method
 */
export const POST = methodBuilder('POST');
/**
 * PUT method
 * @param {string} url - resource url of the method
 */
export const PUT = methodBuilder('PUT');
/**
 * PATCH method
 * @param {string} url - resource url of the method
 */
export const PATCH = methodBuilder('PATCH');
/**
 * DELETE method
 * @param {string} url - resource url of the method
 */
export const DELETE = methodBuilder('DELETE');
/**
 * HEAD method
 * @param {string} url - resource url of the method
 */
export const HEAD = methodBuilder('HEAD');
