import { Component, HostListener, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { concatMap, map, switchMap, take, tap } from 'rxjs/operators';
import { get } from 'lodash';
import { firstValueFrom, from, lastValueFrom, Observable, of } from 'rxjs';

import { ACL } from '../../shared/acl/acl.service';
import { Roles } from '../../shared/acl/roles';
import { Person } from '../shared/person';
import { PersonAPI } from '../shared/api.service';
import { PersonStatus } from '../shared/constant/status.enum';
import { PersonMergeService } from '../shared/modal/merge/merge.service';
import { UserGroupAPI } from '../../user-group/shared/api.service';
import { ProjectSetChange } from '../../shared/components/project-name/set-change';
import { ValueType } from '../../shared/enum/value-type.enum';
import { PersonFormComponent } from '../shared/form/form.component';
import {
  deleteEmptyKeyFields,
  setOnSubmitValues,
  setOnSubmitIdentValues,
  setOnSaveValues,
} from '../shared/person-util';
import { sortAffiliations } from '../shared/person-affiliation-util';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { Auth } from '../../shared/services/auth/auth.service';
import { CommentAPI } from '../../comment/shared/api.service';
import { Comment } from '../../comment/shared/comment';
import { UserAPI } from '../../user/shared/api.service';
import { Utils } from '../../common/utils';
import { PERSON_RECENT_MERGE_ID_KEY } from '../shared/person.constants';
import { JobsAPI } from '../../jobs/shared/api.service';
import { Job } from '../../jobs/shared/job';
import { JobsModalService } from '../../jobs/shared/modal/modal.service';
import { Value } from '../../shared/services/value/value';
import { PersonDuplicateTakeoverService } from '../shared/modal/duplicate/duplicate.service';
import { PersonDuplicateTakeover } from '../shared/person-dup-takeover';
import { AffiliationMaintenanceJobModalService } from '../../affiliation/shared/modal/affiliation-maintenance-job/affiliation-maintenance-job.service';
import { PersonAffiliationModalService } from '../../shared/services/modal.service';
import { PersonAffiliation } from '../shared/person-affiliation';
import { PersonAffiliationLfka } from '../shared/person-affiliation-lfka';
import { PersonJob } from '../shared/constant/job.enum';
import { isOnlyLFKAProject } from '../shared/utils';
import { ProductsBadgeName } from '../shared/constant/product-badge.enum';
import { sortComplianceDataCategories } from '../shared/person-compliance-util';
import { Title } from '@angular/platform-browser';
import { MarkerArea } from '../../shared/directives/marker/marker.directive';
import { getReviewMarkerArea } from '../../shared/utils/job-review-marker';

@Component({
  selector: 'dirt-person-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.scss'],
})
export class PersonDetailComponent implements OnInit, OnChanges {
  id: string;
  person: Person;
  personJob: Job;
  originalPrimaryAddress: any;
  originalStatus: string = null;

  checkFindings: { [field: string]: string[] } = {};
  get checkKeys() {
    return Object.keys(this.checkFindings);
  }

  statuses = PersonStatus;

  identificationStatuses = [PersonStatus.PENDING_VERIFICATION, PersonStatus.VERIFICATION_IN_PROGRESS];
  limitedCommentStatuses = [PersonStatus.DUPLICATE, PersonStatus.ID_OUT];
  managerOnlyStatuses = [PersonStatus.ON_HOLD];
  compilationStatuses = [PersonStatus.READY_FOR_COMPILATION, PersonStatus.COMPILATION_IN_PROGRESS];
  limitedDisabledStatuses = [
    PersonStatus.READY_FOR_COMPILATION,
    PersonStatus.COMPILATION_IN_PROGRESS,
    PersonStatus.UNABLE_TO_COMPILE,
    PersonStatus.DONE,
    PersonStatus.DUPLICATE_SUSPECT,
    ...this.managerOnlyStatuses,
  ];
  limitedDisabledStatusesEx = [
    ...this.limitedDisabledStatuses,
    PersonStatus.NO_INFO,
    PersonStatus.PENDING_VERIFICATION,
  ];
  limitedDisabledStatusesByJob = {
    [PersonJob.DEDUPLICATION]: [...this.limitedDisabledStatuses, PersonStatus.NO_INFO],
    [PersonJob.WORK]: Object.values(PersonStatus).filter((s) => s !== PersonStatus.ID_OUT),
    [PersonJob.WORK_QC]: Object.values(PersonStatus).filter((s) => s !== PersonStatus.ID_OUT),
    [PersonJob.WORK_MAINTENANCE]: Object.values(PersonStatus).filter((s) => s !== PersonStatus.ID_OUT),
    [PersonJob.WORK_MAINTENANCE_QC]: Object.values(PersonStatus).filter((s) => s !== PersonStatus.ID_OUT),
  };
  assigneeStatuses = [PersonStatus.VERIFICATION_IN_PROGRESS, PersonStatus.COMPILATION_IN_PROGRESS];

  identifyUser = null;
  compileUser = null;

  isObjectionCommentShown = false;
  objectionStatus: string;
  regionLocationList = [];

  isLoadingRegions: boolean;
  isSavingStatus: boolean;
  isSavingVerified: boolean;
  isSubmitting: boolean;
  isUpdating: boolean;

  auditLogShallowCompKeys = ['degrees', 'therapeuticAreas', 'specialties', 'segments', 'prefixes', 'projectNames'];

  ValueType = ValueType;

  @ViewChild(PersonFormComponent, { static: false })
  personForm: PersonFormComponent;

  closeOnSubmit = false;
  @ViewChild('commentList') commentList;
  @ViewChild('findingsList') findingsList;

  private autoTags: string[] = [];

  myUserId: string;

  @ViewChild('duplicateAlertDialog') modalContent: TemplateRef<any>;

  modalOption: NgbModalOptions = { backdrop: 'static' };
  modalRef: NgbModalRef;
  duplicatePersonData: { kolId: string; id: string } = null;

  shouldDisplayCommentModal: boolean;

  basePerson: Person = new Person();

  disableForm: boolean;

  products: string[];

  canEditDeliveryProjects: boolean;

  canEditTechnicalProjects: boolean;

  jobTypes: (Value & { requiredRoles?: string[] })[];

  reviewMarkerArea: MarkerArea | null = null;

  protected wndw: Window = window; // allow for testing

  private projectNames: Value[];

  private countries: Value[];

  private euCountries: string[];

  getProductColor = Utils.getProductColor;

  hasUnpolishedAffiliations = false;
  hasUnpolishedLFKAAffiliations = false;

  isCollapsed: Map<string, boolean> = new Map();

  private LFKA_TAGS: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcAcl: ACL,
    private svcPerson: PersonAPI,
    private svcMergeModal: PersonMergeService,
    private svcDupModal: PersonDuplicateTakeoverService,
    private svcAuth: Auth,
    private svcUser: UserAPI,
    private svcUserGroup: UserGroupAPI,
    private svcModal: NgbModal,
    private svcComment: CommentAPI,
    private svcJob: JobsAPI,
    private svcValue: ValueAPI,
    private svcJobModal: JobsModalService,
    private svcAffiliationMaintenanceModal: AffiliationMaintenanceJobModalService,
    private svcAffiliationCreateModal: PersonAffiliationModalService,
    private titleService: Title
  ) {
    this.onRequestAffiliationCreation = this.onRequestAffiliationCreation.bind(this);
    this.onRequestAffiliationMaintenance = this.onRequestAffiliationMaintenance.bind(this);
  }

  ngOnInit() {
    this.isCollapsed.set('committees', true);

    this.initPerson();
    this.initRegion();
    this.svcValue
      .find(ValueType.PersonJobType, Number.MAX_SAFE_INTEGER)
      .pipe(take(1))
      .subscribe((_jts) => (this.jobTypes = _jts.sort((j1, j2) => j1.value.localeCompare(j2.value))));
    this.svcValue
      .find(ValueType.EUCountries, 1)
      .pipe(take(1))
      .subscribe((_euc) => (this.euCountries = (_euc[0] || {}).value || []));
    this.reviewMarkerArea = getReviewMarkerArea(this.personJob?.type);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentJobType']) {
      this.reviewMarkerArea = getReviewMarkerArea(this.personJob?.type);
    }
  }

  private initPerson() {
    this.canEditDeliveryProjects =
      this.svcAcl.hasCredential('person.create.prop.deliveryProjects') ||
      this.svcAcl.hasCredential('person.update.prop.deliveryProjects');
    this.canEditTechnicalProjects =
      this.svcAcl.hasCredential('person.create.prop.technicalProjects') ||
      this.svcAcl.hasCredential('person.update.prop.technicalProjects');

    this.route.params
      .pipe(
        map((params) => (this.id = params['id'])),
        switchMap((id) =>
          this.svcPerson.findById(id, undefined, this.route.snapshot.queryParamMap.get('qcSession') || undefined)
        ),
        switchMap((person) => {
          if (!this.svcAcl.hasCredential('person.comment.list')) {
            return of({ person, count: 0 });
          }
          return this.svcComment.count(person.id).pipe(map(({ count }) => ({ person, count })));
        })
      )
      .subscribe(async ({ person, count }) => {
        if (person.basePersonId && person._meta.status === PersonStatus.DONE) {
          await this.loadBasePerson(person.basePersonId);
        }
        this.person = person;
        this.titleService.setTitle(`cLean | Person | ${this.person.firstName + ' ' + this.person.lastName}`);
        sortAffiliations(this.person);
        sortComplianceDataCategories(this.person);
        this.originalPrimaryAddress = this.getPrimaryAddress();
        this.originalStatus = this.person._meta?.status || null;

        this.identifyUser = null;
        this.compileUser = null;

        this.hasUnpolishedAffiliations = !!person.affiliations?.find((a) => !a.readyForDelivery);
        this.hasUnpolishedLFKAAffiliations = !!person.affiliationsLfka?.find((a) => !a.readyForDelivery);

        // try to make user display better - have new & efficient endpoint showing ONLY names
        if (person.identify && person.identify.by && typeof person.identify.by === 'string') {
          this.svcUser.findNameById(person.identify.by).subscribe((p) => {
            if (p.user_id && p.name) {
              this.identifyUser = p;
            }
          });
        }

        if (person.review && person.review.by && typeof person.review.by === 'string') {
          this.svcUser.findNameById(person.review.by).subscribe((p) => {
            if (p.user_id && p.name) {
              this.compileUser = p;
            }
          });
        }

        const user = await firstValueFrom(this.svcAuth.getProfile());
        this.myUserId = user.user_id;

        this.recheckFormDisabled();

        // Only get job details if user is the one working it
        if (person._meta?.jobId && this.person?._meta?.assignee === this.myUserId) {
          this.svcJob
            .findById(person._meta.jobId)
            .pipe(take(1))
            .subscribe((j) => {
              this.personJob = j;

              if (j?._meta?.assignee === this.myUserId) {
                // only limit if current user is the one that is working the job
                this.canEditDeliveryProjects =
                  this.svcAcl.hasCredential('person.create.prop.deliveryProjects', j.type) ||
                  this.svcAcl.hasCredential('person.update.prop.deliveryProjects', j.type);
                this.canEditTechnicalProjects =
                  this.svcAcl.hasCredential('person.create.prop.technicalProjects', j.type) ||
                  this.svcAcl.hasCredential('person.update.prop.technicalProjects', j.type);
                this.reviewMarkerArea = getReviewMarkerArea(this.personJob.type);
              }
            });
        }

        this.svcValue.find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER).subscribe((data) => {
          this.projectNames = data;
          this.autoTags = data.reduce((acc, item) => {
            if (item.autoTag) {
              acc.push(item.code as string);
            }
            return acc;
          }, []);

          this.products = this.getKOLProducts();
          this.LFKA_TAGS = data.filter((v) => v.product === 'LFKA').map((v) => v.code as string);
        });

        if (this.svcAcl.hasCredential('person.checkFindings.list') && !this.svcAcl.hasRole(Roles.Admin)) {
          this.svcPerson
            .checkFindings(person.kolId, 'ncd', true)
            .pipe(take(1))
            .subscribe((fnd) => {
              let cnt = 0;
              (fnd || []).forEach((f) => {
                if (!person.review?.at || person.review.at < f.checkDate) {
                  // ignore removal before last curation
                  if (!this.checkFindings[f.problemField]) {
                    this.checkFindings[f.problemField] = [];
                  }
                  this.checkFindings[f.problemField].push(f.problemValue);
                  cnt++;
                }
              });
              if (cnt > 0) {
                this.svcModal.open(this.findingsList);
              }
            });
        }

        this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER).subscribe((data) => {
          this.countries = data;
        });

        if (count === 0) {
          // do not show the modal if there is no comment
          return;
        }

        if (
          this.svcAcl.hasRole(Roles.ProfileCompiler) ||
          this.svcAcl.hasRole(Roles.ProfileReviewer) ||
          this.svcAcl.hasRole(Roles.PersonCompiler) ||
          this.svcAcl.hasRole(Roles.PersonCompilerIdent) ||
          this.svcAcl.hasRole(Roles.PersonReviewer)
        ) {
          this.svcModal.open(this.commentList);
        }
      });
  }

  private recheckFormDisabled() {
    if (
      ((this.svcAcl.hasRole(Roles.PersonViewer) && this.svcAcl.hasRole(Roles.PersonCompiler)) ||
        this.svcAcl.hasRole(Roles.PersonCompilerIdent)) &&
      !(this.person._meta?.assignee === this.myUserId || this.person.discard?.by === this.myUserId)
    ) {
      this.disableForm = true;
    } else if (this.person.optoutStatus?.length && !this.svcAcl.hasCredential('person.updateOptOut')) {
      this.disableForm = true;
    } else {
      this.disableForm = false;
    }
  }

  initRegion(): void {
    this.isLoadingRegions = true;

    this.svcUserGroup.find(Number.MAX_SAFE_INTEGER).subscribe(
      (resp) => (this.regionLocationList = resp.map((u) => [u.name, u.countries])),
      null,
      () => (this.isLoadingRegions = false)
    );
  }

  updateDraft(person: Person): void {
    this.isSubmitting = true;
    deleteEmptyKeyFields(person);
    sortAffiliations(person);

    const observable = this.svcPerson.upsertDraft(person);

    observable.subscribe(
      () => {
        this.isSubmitting = false;
        if (this.closeOnSubmit) {
          window.close();
        } else {
          this.goBack();
        }
      },
      () => (this.isSubmitting = false)
    );
  }

  update(person: Person, partial?: boolean): void {
    if (!this.validateDegreeSpecialityExpertTypeRelation()) {
      return;
    }
    this.isSubmitting = true;
    deleteEmptyKeyFields(person);
    sortAffiliations(person);
    const observable = partial ? this.svcPerson.update(this.id, person) : this.svcPerson.upsert(person);

    observable.subscribe(
      () => {
        this.isSubmitting = false;
        if (this.closeOnSubmit) {
          window.close();
        } else {
          this.goBack();
        }
      },
      (error) => {
        this.handleDuplicateExternalIdError(error);
        this.isSubmitting = false;
      },
      () => (this.isSubmitting = false)
    );
  }

  isUserManager(): boolean {
    return (
      this.svcAcl.hasRole(Roles.Admin) ||
      this.svcAcl.hasRole(Roles.PersonManager) ||
      this.svcAcl.hasRole(Roles.ProfileManager)
    );
  }

  isUserModerator(): boolean {
    return (
      this.svcAcl.hasRole(Roles.Admin) ||
      this.svcAcl.hasRole(Roles.PersonModerator) ||
      this.svcAcl.hasRole(Roles.ProfileModerator)
    );
  }

  isUserModeratorModifier(): boolean {
    return (
      this.svcAcl.hasRole(Roles.Admin) ||
      this.svcAcl.hasRole(Roles.PersonModerator) ||
      this.svcAcl.hasRole(Roles.PersonModifier)
    );
  }

  onSubmitJob(person: Person): void {
    // When submitting our WORK job, make sure we don't have an automated primary affiliation.
    if (
      this.hasJobForCurrentUser() &&
      this.personJob?.type === PersonJob.WORK &&
      this.hasAutomatedPrimaryAffiliation()
    ) {
      window.alert('Primary affiliation cannot be an automated affiliation');
      return;
    }

    if (!this.personForm.isValid()) {
      if (!this.canSubmitJobAnyway()) {
        return;
      }
      if (!this.wndw.confirm('There are validation errors left. Sure you want to submit?')) {
        return;
      }
    }

    this.isSubmitting = true;
    deleteEmptyKeyFields(person);
    sortAffiliations(person);

    this.svcPerson.submitJob(person).subscribe(
      () => {
        this.isSubmitting = false;
        if (this.closeOnSubmit) {
          window.close();
        } else {
          this.goBack(true);
        }
      },
      (error) => {
        this.handleDuplicateExternalIdError(error);
        this.isSubmitting = false;
      },
      () => (this.isSubmitting = false)
    );
  }

  async onJobDraft(person: Person): Promise<void> {
    this.isSubmitting = true;

    this.svcPerson
      .upsertDraft(person)
      .pipe(
        concatMap(() => this.svcJob.setDraft(this.personJob._id)),
        tap(() => (this.isSubmitting = false))
      )
      .subscribe({
        next: () => {
          if (this.closeOnSubmit) {
            window.close();
          } else {
            this.goBack(true);
          }
        },
        error: () => {
          this.isSubmitting = false;
        },
      });
  }

  onJobUtc(): void {
    let comment = '';
    while (comment.length < 3) {
      comment = this.wndw.prompt('Enter a comment for Unable to Compile...');
      if (null === comment) {
        return;
      }
    }

    this.isSubmitting = true;

    this.svcJob.setUtc(this.personJob._id, comment).subscribe(
      () => {
        this.isSubmitting = false;
        if (this.closeOnSubmit) {
          window.close();
        } else {
          this.goBack(true);
        }
      },
      () => (this.isSubmitting = false)
    );
  }

  onSave(person: Person): void {
    if (!this.personForm.isValid()) {
      if (!this.canSaveAnyway()) {
        return;
      }
      if (!this.wndw.confirm('There are validation errors left. Sure you want to save?')) {
        return;
      }
    }
    setOnSaveValues(person);
    this.update(person);
  }

  @HostListener('document:keydown.control.b')
  @HostListener('document:keydown.meta.b')
  onSubmitAndClose() {
    // No accident shall happen - jobs has nothing to do with old submit, but PersonCompiler and PersonCompilerIdent still have the 'person.update.submit' permission
    if (this.hasJobForCurrentUser()) {
      return;
    }
    if (!this.svcAcl.hasCredential('person.update.submit')) {
      return;
    }
    if (!this.personForm.isValid()) {
      alert('Unable to submit! The form is not valid.');
      return;
    }
    this.closeOnSubmit = true;
    this.onSubmit(this.personForm.getValue());
  }

  async onSaveDraft(person: Person): Promise<void> {
    if (person._meta && person._meta.consecutiveDraftCount >= 2 && person._meta.assignee === this.myUserId) {
      alert('Cannot save as draft. Only two drafts at the same time allowed.');
      return;
    }
    const drafts = await this.svcPerson.drafts().toPromise();
    if (
      !(
        (drafts.current || []).filter((_d) => _d.id !== person.id /* we ourselves are always OK */).length <
        drafts.limit
      )
    ) {
      alert('Already have the maximum of ' + drafts.limit + ' drafts');
      return;
    } // (else we're clear)
    person._meta.claimedUntil = null;
    this.updateDraft(person);
  }

  onSubmit(person: Person): void {
    if (this.svcAcl.hasRole(Roles.PersonCompilerIdent) && this.hasAutomatedPrimaryAffiliation()) {
      window.alert('Primary affiliation cannot be an automated affiliation');
      return;
    }

    if (!this.doConfirm()) {
      return;
    }

    if (this.svcAcl.hasRole(Roles.PersonCompilerIdent)) {
      person.projectNames = person.projectNames?.filter(
        (project) => !this.autoTags.includes(project) && project !== 'OTHER'
      );
    }

    setOnSubmitValues(
      person,
      this.svcAcl.hasRole(Roles.PersonCompiler) ||
        this.svcAcl.hasRole(Roles.PersonCompilerIdent) ||
        this.svcAcl.hasRole(Roles.ProfileCompiler)
    );
    this.update(person);
  }

  onSubmitIdent(person: Person): void {
    if (!this.doConfirmIdent()) {
      return;
    }

    setOnSubmitIdentValues(person, this.svcAcl.hasRole(Roles.PersonCreator));
    this.update(person);
  }

  onProjectSelectionChanged(event: ProjectSetChange) {
    this.recheckFormDisabled();
    this.svcPerson.update(this.person.id, { projectNames: event.activeSet } as Person).subscribe(
      (resp: Person) => this.getMergedResponse(this.person, resp),
      null,
      () => event.callback()
    );
  }

  async onMerge(): Promise<void> {
    const options = {
      kolId: this.person.kolId,
      title: `${this.person.firstName} ${this.person.lastName}`,
    };

    let dialogRes;
    try {
      dialogRes = await this.svcMergeModal.open(options);
    } catch (_e) {
      // cancel
      return;
    }

    await this.svcPerson.merge(this.id, dialogRes.id).toPromise(); // so far fails promise or was OK

    // remember the ID of the winner - so when we have the winner open in parallel, we can get back to that tab and update
    // (if we open a window with res, we might even have several tabs where we return to - so we need to keep an array per day)
    let existingIds: { date: string; ids: string[] } = JSON.parse(
      this.wndw.localStorage.getItem(PERSON_RECENT_MERGE_ID_KEY) || '{}'
    );
    const todayStr = new Date().toISOString().substring(0, 10);
    if (!existingIds.date || existingIds.date < todayStr) {
      // reset (cound new)
      existingIds = { date: todayStr, ids: [] };
    }
    existingIds.ids.push(dialogRes.id);
    this.wndw.localStorage.setItem(PERSON_RECENT_MERGE_ID_KEY, JSON.stringify(existingIds));
    // (now we're good)
    this.goBack();
  }

  onObjection(status: string): void {
    this.objectionStatus = status;
    this.isObjectionCommentShown = true;
  }

  onObjectionSubmit(): void {
    const val = <Person>{
      published: true,
      _meta: { status: this.objectionStatus },
    };

    this.update(val, true);
  }

  onObjectionCancel(): void {
    this.objectionStatus = null;
    this.isObjectionCommentShown = false;
  }

  onChangeStatus(_meta: any): void {
    // decide whether we need to kick off takeover
    if (this.originalStatus !== _meta.status && PersonStatus.DUPLICATE === _meta.status) {
      this.svcDupModal
        .open({
          person: this.person,
        })
        .then((duplicateRes: PersonDuplicateTakeover | null) => {
          if (duplicateRes) {
            this.isSavingStatus = true;
            this.svcPerson
              .reportDuplicate(duplicateRes)
              .pipe(take(1))
              .subscribe(
                (resp: Person) => {
                  // take over ext id and a LOT else
                  this.getMergedResponse(this.person, resp);
                  this.person.externalIds = resp.externalIds; // (might have removed those)
                  this.originalStatus = _meta.status; // now we're safely established
                },
                null,
                () => (this.isSavingStatus = false)
              );
          }
        })
        .catch(() => {
          // cancel
          if (this.originalStatus) {
            this.person._meta.status = this.originalStatus;
          }
        });
      return;
    } else if (this.originalStatus !== _meta.status && PersonStatus.DUPLICATE === this.originalStatus) {
      // we have to unreport also - not nice but nec
      this.svcPerson
        .unreportDuplicate({ discardKolId: this.person.kolId, newMeta: _meta })
        .pipe(take(1))
        .subscribe(
          (resp: Person) => {
            // take over ext id and a LOT else
            this.getMergedResponse(this.person, resp);
            this.person.externalIds = resp.externalIds; // (might have removed those)
            this.originalStatus = _meta.status; // now we're safely established
          },
          null,
          () => (this.isSavingStatus = false)
        );
      return;
    } // (else do what we always did)

    this.isSavingStatus = true;

    const person = <Person>{ _meta };

    this.svcPerson
      .update(this.person.id, person)
      .pipe(take(1))
      .subscribe(
        (resp: Person) => {
          this.getMergedResponse(this.person, resp);
          this.originalStatus = _meta.status; // next change needs to fit again
        },
        null,
        () => (this.isSavingStatus = false)
      );
  }

  async onCreateComment(comment: string) {
    await lastValueFrom(
      this.svcComment.create(<Comment>{
        content: comment,
        owner: this.person.id,
      })
    );
  }

  onDeleteComplianceRecord(complianceId: string) {
    if (this.doConfirmComplianceRecordDeletion()) {
      this.svcPerson.deleteComplianceRecord(this.person.id, complianceId).subscribe((updatedPerson) => {
        this.person = this.getMergedResponse(this.person, updatedPerson);
      });
    }
  }

  getMergedResponse(localData: Person, remoteData: Person): Person {
    localData.updatedAt = remoteData.updatedAt;
    localData._meta = remoteData._meta;
    localData._version = remoteData._version;
    localData.readyForDelivery = remoteData.readyForDelivery;
    localData.readyForDeliveryLFTA = remoteData.readyForDeliveryLFTA;
    localData.readyForDeliveryLFKA = remoteData.readyForDeliveryLFKA;
    localData.kppCurated = remoteData.kppCurated;
    localData.projectNames = remoteData.projectNames;
    localData.discard = remoteData.discard;
    localData.compliance = remoteData.compliance;
    this.recheckFormDisabled();

    if (remoteData._meta?.jobId) {
      this.svcJob
        .findById(remoteData._meta.jobId)
        .pipe(take(1))
        .subscribe((j) => {
          this.personJob = j;

          if (j?._meta?.assignee === this.myUserId) {
            // only limit if current user is the one that is working the job
            this.canEditDeliveryProjects =
              this.svcAcl.hasCredential('person.create.prop.deliveryProjects', j.type) ||
              this.svcAcl.hasCredential('person.update.prop.deliveryProjects', j.type);
            this.canEditTechnicalProjects =
              this.svcAcl.hasCredential('person.create.prop.technicalProjects', j.type) ||
              this.svcAcl.hasCredential('person.update.prop.technicalProjects', j.type);
          }
        });
    }
    return localData;
  }

  goBack(toStdQueue?: boolean): void {
    if (this.svcAcl.hasCredential('identify')) {
      this.router.navigate(['/id-next']);
      return;
    } else if (toStdQueue) {
      // (esp. jobs)
      this.router.navigate(['/next']);
      return;
    } else if (this.svcAcl.hasCredential('identifyqc')) {
      this.router.navigate(['/idqc-next']);
      return;
    } else {
      // (all else)
      this.router.navigate(['/person']);
    }
  }

  doConfirm(): boolean {
    let message = 'Do you really want to submit this person?';
    message += '\nNote: By submitting, you will not be having any access to it anymore!';

    return window.confirm(message);
  }

  doConfirmIdent(): boolean {
    let message = 'Do you really want to submit this person?';
    message += '\nNote: By submitting, it will be passed on to the next step!';

    return window.confirm(message);
  }

  doConfirmComplianceRecordDeletion(): boolean {
    return window.confirm('Do you really want to delete this compliance record?');
  }

  canCreateComment(): boolean {
    return this.svcAcl.hasCredential('person.comment.create');
  }

  canChangeStatus(): boolean {
    return (
      this.svcAcl.hasCredential('person.update.prop.meta.status', this.getJobTypeForCurrentUser()) && !this.disableForm
    );
  }

  canChangeManagerOnlyStatus(): boolean {
    return this.canChangeStatus() && (this.isUserManager() || this.isUserModeratorModifier());
  }

  canChangeLimitedStatus(): boolean {
    return (
      this.svcAcl.hasCredential('person.update.prop.meta.statusLimited', this.getJobTypeForCurrentUser()) &&
      !this.canChangeStatus() &&
      (!this.person.optoutStatus?.length || this.svcAcl.hasCredential('person.updateOptOut'))
    );
  }

  hasRestrictedProjectOptions() {
    return this.svcAcl.hasRole(Roles.PersonCompilerIdent);
  }

  hasRestrictedStatusOptions() {
    return this.svcAcl.hasRole(Roles.PersonCompilerIdent);
  }

  hasJobForCurrentUser(): boolean {
    return this.personJob && this.myUserId && this.person?._meta?.assignee === this.myUserId;
  }
  getJobTypeForCurrentUser(): string | null {
    return this.hasJobForCurrentUser() ? this.personJob.type : null;
  }

  canSaveDraft(): boolean {
    return (
      this.svcAcl.hasCredential('person.update.draft') || // (what we had before)
      (this.svcAcl.hasCredential('person.update.draftIdent') && this.canSubmitIdent())
    ); // (otherwise it's a normal save)
  }
  canSubmitJob() {
    return this.svcAcl.hasCredential('job.submit');
  }
  canSubmitJobAnyway() {
    return this.canSubmitJob() && this.canSaveAnyway(); // (same permission)
  }
  canSubmitIdent(): boolean {
    return (
      this.svcAcl.hasCredential('person.update.submitIdent') &&
      this.person._meta &&
      this.identificationStatuses.indexOf(this.person._meta.status as any) >= 0
    );
  }
  canSubmitForReal(): boolean {
    // per se when one has permission - yet for id-out and friends, it should be just 'save'
    return (
      this.svcAcl.hasCredential('person.update.submit') &&
      this.person._meta &&
      (this.identificationStatuses.indexOf(this.person._meta.status as any) >= 0 ||
        this.compilationStatuses.indexOf(this.person._meta.status as any) >= 0 ||
        PersonStatus.DONE === this.person._meta.status)
    );
  }
  canSave(): boolean {
    // need to go beyond ACL here, too
    if (this.svcAcl.hasCredential('person.update')) {
      return true;
    } else if (this.svcAcl.hasCredential('person.update.saveLimited')) {
      return !(this.canSubmitIdent() || this.canSubmitForReal()); // when not leaving the workflow, we have to submit
    } else {
      return false;
    }
  }
  canSaveAnyway(): boolean {
    return this.svcAcl.hasCredential('person.updateAnyway'); // allow saving despite remaining form validation issues
  }

  onIdVerifiedChange(idverified: boolean): void {
    this.isSavingVerified = true;

    this.svcPerson.update(this.person.id, { idverified } as Person).subscribe(
      (resp: Person) => {
        this.person.updatedAt = resp.updatedAt;
        this.person.idverified = resp.idverified;
        this.person._version = resp._version;
      },
      () => (this.isSavingVerified = false),
      () => (this.isSavingVerified = false)
    );
  }

  onVerifiedChange(verified: boolean): void {
    this.isSavingVerified = true;

    this.svcPerson.update(this.person.id, { verified } as Person).subscribe(
      (resp: Person) => {
        this.person.updatedAt = resp.updatedAt;
        this.person.verified = resp.verified;
        this.person._version = resp._version;
      },
      () => (this.isSavingVerified = false),
      () => (this.isSavingVerified = false)
    );
  }

  private getPrimaryAddress() {
    const primaryAffiliation = this.person.affiliations.find((a) => a.primary);

    if (primaryAffiliation) {
      // return a copy not a reference
      return Object.assign({}, primaryAffiliation.address); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables
    }
  }

  onModalKeyPress(e: KeyboardEvent) {
    e.stopPropagation();
  }

  isAutoProject(person: Person) {
    if (!person.projectNames) {
      return;
    }
    return person.projectNames.some((item) => this.autoTags.indexOf(item) > -1);
  }

  skipVerification(): void {
    if (!window.confirm('Are you sure?')) {
      return;
    }

    const val = this.svcAcl.hasCredential('person.update.skipid')
      ? <Person>{
          idverificationSkipped: true,
        }
      : <Person>{
          // skip & skipidbd
          verificationSkipped: true,
        };

    this.svcPerson.update(this.person.id, val).subscribe(() => this.router.navigate(['/']));
  }
  isClaimedByOtherUser(): boolean {
    return (
      this.person._meta &&
      this.person._meta.claimedUntil &&
      new Date(this.person._meta.claimedUntil) > new Date() &&
      this.person._meta.assignee &&
      this.person._meta.assignee !== this.myUserId
    );
  }
  isDraft(): boolean {
    return this.person._meta && this.person._meta.draftUntil && new Date(this.person._meta.draftUntil) > new Date();
  }

  handleDuplicateExternalIdError(e) {
    if (e.error && e.error.code && e.error.code === 'DUPLICATE_EXTERNAL_ID') {
      if (e.error.data && e.error.data.kolId && e.error.data.id) {
        this.duplicatePersonData = {
          kolId: e.error.data.kolId,
          id: e.error.data.id,
        };
        this.svcModal.open(this.modalContent, this.modalOption).result.then(
          () => {
            this.duplicatePersonData = null;
          },
          () => {
            this.duplicatePersonData = null;
          }
        );
      }
    }
  }

  validateDegreeSpecialityExpertTypeRelation() {
    if (!this.personForm.isExpertTypeSpecialityDegreeRelationValid()) {
      return this.doConfirmSubmissionWithDegreeErrors();
    } else {
      return true;
    }
  }

  doConfirmSubmissionWithDegreeErrors(): boolean {
    let message = 'You have a mistake in some of the following fields (Degrees/Specialities/Expert type)';
    message += '\nAre you sure you want to submit the profile?';

    return window.confirm(message);
  }
  //training evaluation methods
  async loadBasePerson(basePersonId) {
    this.basePerson = await this.svcPerson.findById(basePersonId, true).toPromise();
  }

  savePersonReport(comment: string) {
    const reviewBy = this.person.review && this.person.review.by;
    const compilerId = typeof reviewBy === 'string' ? reviewBy : (reviewBy || {}).user_id;
    const report = {
      comment,
      compilerId,
      reportDetails: this.person.trainingEvaluationReport.reportDetails,
    };
    const person = <Person>({ trainingEvaluationReport: report } as unknown);
    this.person.trainingEvaluationReport.comment = comment;
    this.svcPerson.update(this.id, person).subscribe(() => this.goBack());
  }

  hasBDErrorReport() {
    return !!((this.person && this.person.trainingEvaluationReport) || {}).reportDetails;
  }
  getPersonTrainingErrorRate() {
    const report = ((this.person && this.person.trainingEvaluationReport) || {}).reportDetails;
    if (!report) return 'n/a';
    return Utils.getPersonOverallErrorRate(report);
  }

  getBreakdownErrorRate(path) {
    const report = ((this.person && this.person.trainingEvaluationReport) || {}).reportDetails;
    return Utils.getFieldLevelErrorRate(path, report);
  }

  getNameErrorRate() {
    const report = ((this.person && this.person.trainingEvaluationReport) || {}).reportDetails;
    if (!report) return 'n/a';
    return Utils.getNameErrorRates(report);
  }

  hasTrainingPermission(): boolean {
    return this.svcAcl.hasCredential('profile.training');
  }
  isTrainingProfile() {
    return this.person && this.person.training;
  }
  hasTrainingFeaturesEnabled() {
    return this.hasTrainingPermission() && this.isTrainingProfile();
  }

  showBDTrainingReport() {
    const bdCompilerId = get(this.person, 'trainingEvaluationReport.compilerId');
    const comment = get(this.person, 'trainingEvaluationReport.comment');
    return this.myUserId === bdCompilerId && !!comment;
  }

  onCopyID(copyValID: any): void {
    navigator.clipboard.writeText(copyValID);
  }

  onRequestAffiliationCreation(
    sourceItem?: PersonAffiliation | PersonAffiliationLfka,
    disableNameField?: boolean,
    requestOwnerProduct?: string
  ): Observable<PersonAffiliation | PersonAffiliationLfka> {
    return from(
      this.svcAffiliationCreateModal.open(
        sourceItem,
        disableNameField,
        this.getJobTypeForCurrentUser(),
        this.isRequestTopPriority(),
        requestOwnerProduct
      )
    );
  }

  onRequestAffiliationMaintenance(id: string, requestOwnerProduct?: string): void {
    this.svcAffiliationMaintenanceModal.open(id, this.isRequestTopPriority(), requestOwnerProduct);
  }

  private isRequestTopPriority(): boolean {
    return (
      this.person.projectNames?.includes('DCR_SERVICES_TEAM') ||
      this.person.projectNames?.includes('FOLLOWED_AUTO_LINK')
    );
  }

  @HostListener('document:visibilitychange')
  async onGlobalVisibility() {
    if (!this.wndw.document.hidden) {
      // coming back to that tab
      const recentMergeIds: { date: string; ids: string[] } = JSON.parse(
        this.wndw.localStorage.getItem(PERSON_RECENT_MERGE_ID_KEY) || '{}'
      );

      if (recentMergeIds.ids && this.person?.id && recentMergeIds.ids.indexOf(this.person.id) >= 0) {
        // we did merge sth into this - so we should reload the mergedIds (and version for good measure)
        this.svcPerson.findById(this.person.id).subscribe(
          (personUpdate) => {
            this.person.mergedKolIds = personUpdate.mergedKolIds;
            this.person.updatedAt = personUpdate.updatedAt;
            this.person._version = personUpdate._version;
            recentMergeIds.ids = recentMergeIds.ids.filter((id) => id !== this.person.id);
            this.wndw.localStorage.setItem(PERSON_RECENT_MERGE_ID_KEY, JSON.stringify(recentMergeIds)); // don't have to do that twice
          },
          (err) => (this.isUpdating = false)
        );
      }
    }
  }

  onJobCreate(): void {
    if (!this.svcAcl.hasCredential('job.create') || !this.person.id) {
      return;
    }

    this.svcJobModal
      .open(
        this.jobTypes.map((jt) => ({
          key: jt.code as string,
          value: jt.value,
          roles: (jt.requiredRoles as Roles[]) || [Roles.PersonCompiler],
        }))
      )
      .then((res) => {
        if (!res) {
          return;
        }

        const job = {
          ...res,
          entityType: 'people',
          entityId: this.person.id,
        };

        this.svcJob.create(job).subscribe(() => {});
      })
      .catch(() => {});
  }

  private getKOLProducts(): string[] {
    return this.person.projectNames?.reduce((acc, p) => {
      const project = this.projectNames?.find((v) => v?.code === p);

      const product = ProductsBadgeName[project?.code as keyof typeof ProductsBadgeName] ?? project?.product;
      if (!product) {
        return acc;
      }
      if (!acc.includes(product)) {
        acc.push(product);
      }
      return acc;
    }, []);
  }

  getCountry(): string {
    const primaryAffiliation = this.person?.affiliations?.find((a) => a.primary);
    if (primaryAffiliation) {
      return (
        this.countries?.find((c) => c.code === primaryAffiliation.address?.countryCode)?.title ||
        primaryAffiliation.address?.countryCode
      );
    }

    return (
      this.countries?.find((c) => c.code === this.person?.countryWorkflow)?.title ||
      this.person?.countryWorkflow ||
      'N/A'
    );
  }

  requiresEu() {
    const primAff = this.person?.affiliations?.find((a) => a.primary);
    if (primAff) {
      return (this.euCountries || []).includes(primAff.address?.countryCode || primAff.countryCode || null);
    } else {
      return (this.euCountries || []).includes(this.person?.countryWorkflow || null);
    }
  }

  toggleCollapseSettings(section: string): void {
    this.isCollapsed.set(section, !this.isCollapsed.get(section));
  }

  isOnlyKAMProject(): boolean {
    return isOnlyLFKAProject(this.person?.projectNames, this.LFKA_TAGS);
  }

  private hasAutomatedPrimaryAffiliation(): boolean {
    const primaryIsAuto = this.person.affiliations?.find((aff) => aff.primary)?.source === 'OPEN_DATA';
    const hasPrimaryCandidate = !!this.person.affiliations?.find((aff) => aff.primaryCandidate);

    return primaryIsAuto && !hasPrimaryCandidate;
  }
}
