import { Injectable } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { ContributorTitles } from '../../../contribution/shared/constant/titles';
import { ContributorPrefixes } from '../../../contribution/shared/constant/prefixes';
import { ContributorSuffixes } from '../../../contribution/shared/constant/sufixes';
import { ContributorNameParts } from '../../../contribution/shared/constant/name-parts';
import { Name } from './name';

const XRegExp = require('xregexp');

const WORD_REGEX = new XRegExp('\\p{L}\\S*.?', 'g');

@Injectable({
  providedIn: 'root',
})
export class NameSplitService {
  constructor(private readonly titleCasePipe: TitleCasePipe) {}

  splitFullName(value: string) {
    const name: Name = {
      first: '',
      middle: '',
      last: '',
    };

    if (!value) {
      return name;
    }

    const forbiddenParts = [...ContributorTitles, ...ContributorPrefixes, ...ContributorSuffixes];

    const parts = value
      .split(',')[0]
      .match(WORD_REGEX)
      // Remove forbidden parts
      .filter((part) => !forbiddenParts.includes(part.trim()))
      // Remove [*.]
      .map((part) => part.replace(/\./g, ' ').replace(/(\*)/g, ' ').trim())
      // Convert to title case
      .map((part) =>
        this.titleCasePipe
          .transform(part)
          .split('-')
          .map((txt) => this.titleCasePipe.transform(txt))
          .join('-')
      );

    name.first = parts[0];

    if (parts.length === 2) {
      name.middle = '';
      name.last = parts[1];
    } else {
      const namePartIndex = parts.findIndex((part) => ContributorNameParts.includes(part.toLowerCase()));
      if (namePartIndex === 1) {
        name.middle = '';
        name.last = parts.slice(1).join(' ');
      } else {
        name.middle = parts[1];
        name.last = parts.slice(2).join(' ');
      }
    }

    return name;
  }
}
