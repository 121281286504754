import { ActivatedRoute, Router } from '@angular/router';
import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Observable, Subscription, of, take } from 'rxjs';
import { map, catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isNil } from 'lodash';

import { ACL } from '../../shared/acl/acl.service';
import { Person } from '../shared/person';
import { PersonAPI } from '../shared/api.service';
import { Value } from '../../shared/services/value/value';
import { ValueType } from '../../shared/enum/value-type.enum';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { BulkUpdateOptions } from '../../common/bulk-update/bulk-update-options';
import { buildBulkUpdateConfig } from '../shared/config/bulk-update-config';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { BulkModalComponent, BULK_MODAL_OPTIONS } from '../../common/bulk/component/bulk-modal/bulk-modal.component';
import { JobsAPI } from '../../jobs/shared/api.service';
import { PersonAdvancedSearchDto } from '../shared/person-advanced-search-dto';
import { AffiliationAPI } from '../../affiliation/shared/api.service';
import { Affiliation } from '../../affiliation/shared/affiliation';

const LS_ADVANCED_SEARCH_KEY = 'clean-people-advanced-search';

@Component({
  selector: 'dirt-person-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class PersonListComponent implements OnInit, OnDestroy {
  people: Person[];
  isLoading = true;
  isSearching = false;

  total: { count: number };
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  sort = '-_id';
  filter: any;

  searchTerm: string;

  countryValues: Value[] = [];

  private autoTags: string[] = [];

  private deliveryProjectTags: Value[] = [];

  private technicalProjectTags: Value[] = [];

  private queryParamsSubscription$: Subscription;

  affiliationInfos: { [id: string]: string } = {};
  degreeTitles: { [id: string]: string } = {};
  specialtyTitles: { [id: string]: string } = {};
  currentOverlayToHandle = null; // timeout handle, when we scroll on
  currentOverlayPerson: Person = null;

  bulkUpdateConfig: BulkUpdateOptions<PersonAPI, Person>;

  bulkListDelegate: BulkDelegate = {
    title: 'Bulk Import',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListUpload.bind(this),
    specificNotifyReloadFct: this.getPeople.bind(this),
  };

  twitterBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import Twitter',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListTwitterUpload.bind(this),
    specificNotifyReloadFct: this.getPeople.bind(this),
  };

  complianceBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import Compliance',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListComplianceUpload.bind(this),
    specificNotifyReloadFct: this.getPeople.bind(this),
  };

  jobBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import Job',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListJobUpload.bind(this),
    specificNotifyReloadFct: this.getPeople.bind(this),
  };

  advancedSearchSpec: PersonAdvancedSearchDto = null;
  @ViewChild('advancedSearchDialog', { read: ElementRef }) advancedSearchDialogElement: ElementRef;

  wndw: Window = window;

  searchAutoComplete = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => this.svcPerson.search(term).pipe(catchError(() => of([])))),
      map((results: []) => results.filter((o) => !!o)),
      tap(() => (this.isSearching = false))
    );

  constructor(
    private router: Router,
    private svcPerson: PersonAPI,
    private svcAffiliation: AffiliationAPI,
    private svcJob: JobsAPI,
    private svcAcl: ACL,
    private svcModal: NgbModal,
    private svcValue: ValueAPI,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    // TODO: load advanced search, when currently in localStorage
    this.bulkUpdateConfig = buildBulkUpdateConfig(this.svcPerson);

    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.countryValues = data;
    });

    this.svcValue.find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER).subscribe((projects) => {
      this.autoTags = projects.filter((project) => !!project.autoTag).map((project) => project.code as string);
      this.deliveryProjectTags = projects.filter((project) => !!project.delivery);
      this.technicalProjectTags = projects.filter((project) => !!project.technical);
    });

    this.advancedSearchSpec = JSON.parse(this.wndw.localStorage.getItem(LS_ADVANCED_SEARCH_KEY) || 'null');
    if (this.advancedSearchSpec) {
      // restore sort key & page as well
      if (this.advancedSearchSpec.sort) {
        const sortPrefix = this.advancedSearchSpec.sortDesc ? '-' : '+';
        this.sort = sortPrefix + this.advancedSearchSpec.sort;
      }
      if (this.advancedSearchSpec.page > 0) {
        this.pagingPage = this.advancedSearchSpec.page + 1;
        this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
      }
      // and execute the search
      this.runAdvancedSearch();
    } // else getPeople is called from the template

    this.queryParamsSubscription$ = this.activatedRoute.queryParams.pipe(take(1)).subscribe((params) => {
      this.searchTerm = params['name'];
      // trigger the search bar to load the person list
      this.doLoad();
    });
  }

  ngOnDestroy() {
    this.queryParamsSubscription$.unsubscribe();
  }

  getCountryTitle(code: string): string {
    return (this.countryValues.find((o) => o.code === code) || { title: '' }).title;
  }

  getPeople(): void {
    // TODO: when we have advanced search, go there
    if (this.advancedSearchSpec) {
      this.runAdvancedSearch();
      return;
    } // (else do the default)

    this.isLoading = true;

    if (this.sort === '-_id' && this.searchTerm) {
      // Remove sorting by id when term is not empty (we want list ordered by closest match)
      this.sort = '';
    } else if (!this.sort && !this.searchTerm) {
      // Revert sorting by id when term is empty
      this.sort = '-_id';
    }

    this.svcPerson.find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.filter).subscribe(
      (resp) => (this.people = resp),
      null,
      () => (this.isLoading = false)
    );
  }

  getCount() {
    delete this.total;

    this.svcPerson
      .count(this.searchTerm, this.filter, true) // (optional=true: server excludes heavy count when restricted)
      .subscribe((res) => (this.total = res));
  }

  getPage(page: number) {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getPeople();
  }

  getPrimaryCountryCode(person: Person): string {
    const primaryAffiliation = person.affiliations && person.affiliations.find((af) => af.primary);

    return primaryAffiliation && 'countryCode' in primaryAffiliation
      ? primaryAffiliation['countryCode']
      : person.countryWorkflow;
  }

  resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  onSort(field: string): void {
    this.sort = field;
    this.resetPagination();
    this.getPeople();
  }

  doLoad(): void {
    this.resetPagination();
    this.getCount();
    this.getPeople();
  }

  onDelete(id: string, $event) {
    $event.stopPropagation();

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.svcPerson.deleteById(id).subscribe(() => {
      this.getPeople();
      this.getCount();
    });
  }

  onOpenBulkListDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.bulkListDelegate;
  }

  private handleBulkListUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcPerson.startBulkListUpload(file, secondEyes);
  }

  setFilter(filter: any): void {
    this.filter = filter;

    this.resetPagination();
    this.getPeople();
    this.getCount();
  }

  setPriority(person: Person, priority: number): void {
    if (!priority) {
      return;
    }

    if (person.priority === priority) {
      return;
    }

    this.svcPerson.update(person.id, <Person>{ priority: priority }).subscribe(() => {});
  }

  isPriorityEditable(): boolean {
    return this.svcAcl.hasCredential('person.update.prop.priority');
  }

  navigateTo(route, e?) {
    if (e && /dirt-person-name|star-icon/.test(e.target.className)) {
      return;
    }
    this.router.navigate(route);
  }

  isAutoProject(person: Person) {
    if (!person.projectNames) {
      return;
    }
    return person.projectNames.some((item) => this.autoTags.indexOf(item) > -1);
  }
  onBulkUpdateRefresh() {
    this.getPeople();
  }

  async exportPersons() {
    this.svcPerson
      .downloadPersonsFile(this.filter, this.searchTerm)
      .pipe(take(1))
      .subscribe((personsBlob) => {
        const dlDummyLink = document.createElement('a');

        dlDummyLink.setAttribute('download', `kol_list_${new Date().toLocaleString()}.xlsx`);
        dlDummyLink.setAttribute('href', URL.createObjectURL(personsBlob));
        dlDummyLink.click();

        dlDummyLink.parentElement.removeChild(dlDummyLink);
      });
  }

  getDelList(person: Person) {
    return person.projectNames.reduce((projects, project) => {
      const title = this.deliveryProjectTags.find((t) => t.code === project)?.title;
      if (title) {
        projects.push(title);
      }
      return projects;
    }, []);
  }

  getTechList(person: Person) {
    return person.projectNames.reduce((projects, project) => {
      const title = this.technicalProjectTags.find((t) => t.code === project)?.title;
      if (title) {
        projects.push(title);
      }
      return projects;
    }, []);
  }

  onOpenBulkListTwitterDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.twitterBulkListDelegate;
  }

  onOpenBulkListComplianceDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.complianceBulkListDelegate;
  }

  private handleBulkListTwitterUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcPerson.startBulkListTwitterUpload(file, secondEyes);
  }

  private handleBulkListComplianceUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcPerson.startBulkListComplianceUpload(file, secondEyes);
  }

  onOpenBulkListJobDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.jobBulkListDelegate;
  }

  private handleBulkListJobUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcJob.startBulkListPersonJobUpload(file, secondEyes);
  }

  openAdvancedSearch() {
    this.advancedSearchSpec = JSON.parse(this.wndw.localStorage.getItem(LS_ADVANCED_SEARCH_KEY) || '{"filter": {}}');
    this.advancedSearchDialogElement.nativeElement.show();
  }
  runAdvancedSearch() {
    if (!this.advancedSearchSpec) {
      return;
    }
    if (Object.values(this.advancedSearchSpec.filter || {}).filter((f) => !isNil(f)).length < 1) {
      return; // we don't have a single filter
    }
    this.advancedSearchDialogElement?.nativeElement?.close();
    // take over search & page & sort
    this.advancedSearchSpec.size = 100;
    this.advancedSearchSpec.page = this.pagingPage - 1;

    const sortMatch = /^([\+|-]?)(.+)$/.exec(this.sort || '');
    if (sortMatch) {
      this.advancedSearchSpec.sortDesc = '+' !== sortMatch[1];
      this.advancedSearchSpec.sort = sortMatch[2];
    }

    // persist to localstorage
    localStorage.setItem(LS_ADVANCED_SEARCH_KEY, JSON.stringify(this.advancedSearchSpec));
    // actually pull results
    this.isLoading = true;
    this.svcPerson
      .advancedSearch(this.advancedSearchSpec)
      .pipe(take(1))
      .subscribe(
        (res) => {
          this.isLoading = false;
          this.people = res.items;
          this.total = { count: res.total };
        },
        () => (this.isLoading = false)
      );
  }
  clearAdvancedSearch() {
    this.advancedSearchDialogElement.nativeElement.close();
    this.advancedSearchSpec = null;
    this.wndw.localStorage.removeItem(LS_ADVANCED_SEARCH_KEY);
    this.sort = '-_id'; // clear sort when clearing search
  }

  onNameMouseover(p: Person) {
    if (this.currentOverlayToHandle) {
      this.wndw.clearTimeout(this.currentOverlayToHandle);
    }
    if (this.currentOverlayPerson && this.currentOverlayPerson.kolId === p.kolId) {
      return; // we got all we need
    }
    this.currentOverlayToHandle = null;
    this.currentOverlayPerson = null;
    this.currentOverlayToHandle = this.wndw.setTimeout(() => {
      this.currentOverlayPerson = p; // now we can display the popover
      const primaryAff = p.affiliations?.find((p) => p.primary);
      if (primaryAff?.id && this.affiliationInfos[primaryAff.id] === undefined) {
        // load the affiliation
        if (Object.keys(this.affiliationInfos).length > 200) {
          // avoid memory leak if we open the overlay _OFTEN_
          this.affiliationInfos = {};
        }
        this.affiliationInfos[primaryAff.id] = null; // only kick off loading once
        this.svcAffiliation
          .findById(primaryAff.id)
          .pipe(map((aff: Affiliation) => aff.name + (aff.department ? ' - ' : '') + (aff.department || '')))
          .pipe(take(1))
          .subscribe((aff) => (this.affiliationInfos[primaryAff.id] = aff)); // now we can display in overlay
      }
      (p.degrees || [])
        .filter((d) => this.degreeTitles[d] === undefined)
        .forEach((d) => {
          this.degreeTitles[d] = null; // only kick off loading once
          this.svcValue
            .findByCode(ValueType.Degree, d)
            .pipe(take(1))
            .subscribe((val) => (this.degreeTitles[d] = val.title));
        });
      (p.specialties || [])
        .filter((d) => this.specialtyTitles[d] === undefined)
        .forEach((d) => {
          this.specialtyTitles[d] = null; // only kick off loading once
          this.svcValue
            .findByCode(ValueType.Specialty, d)
            .pipe(take(1))
            .subscribe((val) => (this.specialtyTitles[d] = val.title));
        });
    }, 500);
  }
  onRowMouseover(p: Person) {
    if (this.currentOverlayPerson && this.currentOverlayPerson.kolId !== p.kolId) {
      this.currentOverlayPerson = null; // (just clear if we hover another row)
    }
  }
  calcAffiliationCaption(p: Person) {
    const primaryAff = p.affiliations?.find((p) => p.primary);
    if (!primaryAff) {
      return '(no primary affiliation)';
    }
    const aff = this.affiliationInfos[primaryAff.id];
    return aff || '(loading affiliation)';
  }
  calcDegreesCaption(p: Person) {
    let degreeString = (p.degrees || []).map((d) => this.degreeTitles[d] || d).join(', ');
    if (degreeString.length > 0) {
      return 'Degrees: ' + degreeString;
    } else {
      return '(no degrees)';
    }
  }
  calcSpecialtiesCaption(p: Person) {
    let specString = (p.specialties || []).map((d) => this.specialtyTitles[d] || d).join(', ');
    if (specString.length > 0) {
      return 'Specialties: ' + specString;
    } else {
      return '(no specialties)';
    }
  }

  onTypeaheadResClick(evnt: MouseEvent, p: { id }) {
    if (evnt.ctrlKey || evnt.metaKey) {
      evnt.preventDefault();
      evnt.stopPropagation();
      window.open('/person/detail/' + p.id, '_blank', 'noopener');
    }
  }
}
