import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AclGuard } from '../shared/acl/guard.service';
import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { EventComponent } from '../event/event.component';
import { EventSeriesCreateComponent } from './create/create.component';
import { EventSeriesDetailComponent } from './detail/detail.component';
import { EventSeriesListComponent } from './list/list.component';
import { EventSeriesModule } from './event-series.module';
import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';

const eventSeriesRoutes: Routes = [
  {
    path: 'event-series',
    component: EventComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'create',
        component: EventSeriesCreateComponent,
      },
      {
        path: 'detail/:id',
        component: EventSeriesDetailComponent,
      },
      {
        path: 'list',
        component: EventSeriesListComponent,
        data: { acl: 'eventSeries.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
    data: { title: 'Event Series' },
  },
];

export const eventSeriesRouting: ModuleWithProviders<EventSeriesModule> = RouterModule.forChild(eventSeriesRoutes);
