import { Affiliation } from '../../affiliation/shared/affiliation';
import { Association } from '../../association/shared/association';
import { PersonBaseinfo } from '../../person/shared/person-baseinfo';

export type ClinicalTrialSponsorConnection = Affiliation | PersonBaseinfo | Association;

export class ClinicalTrialSponsor {
  id: string;
  name: string;
  type?: string;
  connectionId?: string;
  connection?: ClinicalTrialSponsorConnection;
  prooflinks?: string[];
  _meta: {
    assignee?: string;
    jobId?: string;
    claimedUntil?: Date;
  };
  curated?: { at: Date; by: string };
  createdAt: Date;
  updatedAt: Date;
  _version: number;
}
