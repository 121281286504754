<section class="dirt-section-detail dirt-user-detail" *ngIf="pool">
  <div class="row">
    <div class="col-md-8">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <h1 class="bounceInRight">User Pool Detail</h1>

      <br />
      <dirt-user-pool-form [pool]="pool" #frmPool="frmPool"></dirt-user-pool-form>

      <div class="pull-right">
        <!-- Save -->
        <button
          type="submit"
          class="btn btn-primary"
          [ngClass]="{ 'btn-loading': isSubmitting }"
          [disabled]="!frmPool.isValid() || isSubmitting"
          (click)="onSave(frmPool.getValue())"
        >
          <span>Save</span>
        </button>
      </div>
    </div>

    <div class="col-md-4">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Information -->
        <li ngbNavItem>
          <a ngbNavLink>Information</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <dt class="col-sm-4">Created</dt>
              <dd class="col-sm-8">
                <b>{{ pool.createdAt | date : 'medium' }}</b>
              </dd>

              <dt class="col-sm-4">Modified</dt>
              <dd class="col-sm-8">
                <b>{{ pool.updatedAt | date : 'medium' }}</b>
              </dd>
            </dl>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-user-meta"></div>
    </div>
  </div>
</section>
