import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TimeReportComponent } from './components/time-report-component/time-report.component';
import { TimeTrackingAPI } from './shared/api.service';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [TimeReportComponent],
  exports: [TimeReportComponent],
  providers: [TimeTrackingAPI],
})
export class TimeTrackModule {}
