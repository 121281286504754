import { NgModule } from '@angular/core';
import { Router, NavigationEnd, RouterStateSnapshot } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { StickyFilterService } from './sticky-filter.service';
import { StickyFilterGuard } from './sticky-filter.guard';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [StickyFilterService, StickyFilterGuard],
})
export class StickyFilterModule {
  constructor(private readonly router: Router, private readonly svcStickyFilter: StickyFilterService) {
    const { events } = this.router;

    // Monitor `NavigationEnd` events
    events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router.routerState.snapshot)
      )
      .subscribe((value: RouterStateSnapshot) => {
        const { queryParams } = value.root;
        const hasQueryParams = !!Object.keys(value.root.queryParams).length;
        const urlWithoutParams = this.svcStickyFilter.getPathFromUrl(value.url);

        this.svcStickyFilter.toggleState(urlWithoutParams, hasQueryParams ? { queryParams } : undefined);
      });
  }
}
