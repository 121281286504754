import { Person } from '../../person/shared/person';

export enum AccountPersonStatus {
  ADDED_NEW = 'ADDED - NEW',
  ADDED_EXISTING = 'ADDED - EXISTING',
  MATCHED = 'MATCHED',
}

export enum AccountPersonRequestMaintenance {
  REQUESTED = 'requested',
  DONE = 'done',
}

export class JobDetail {
  _id?: string;
  matrix: string;
  title: string;
  departmentName: string;
  qc?: any;
}

export class AccountPerson {
  id: string;
  _id: string;
  accountId: string;
  kolId: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  affiliationId?: string;
  person?: Person;
  qc?: any;
  jobDetails?: JobDetail[];
  status?: AccountPersonStatus;
  requestMaintenance?: AccountPersonRequestMaintenance;
  inactive?: boolean;
  sourceUrls?: string[];
  isPersonAffiliationConnected?: boolean;
  isPersonAccountConnected?: boolean;
  reviewed?: boolean;
  reviewedAt?: Date;
  markForDelete: boolean;
  createdAt: Date;
  updatedAt: Date;
  _version: number;
}
