<span class="di-status">
  <a href="javascript:void(0)" (click)="onOpenModal(dialog)">
    {{ _meta?.status || 'No status' | capitalize }}
  </a>

  <!-- Assignee -->
  <span class="di-assignee" *ngIf="user">
    <i class="fa fa-user-circle" aria-hidden="true"></i>
    <dirt-username [user]="user"></dirt-username>
  </span>
</span>

<ng-template #dialog let-close="close">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Status Change</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="status">Status</label>
      <div class="col-sm-8 col-md-10">
        <select id="status" class="form-control custom-select" name="status" [(ngModel)]="selectedStatus" required>
          <option
            *ngFor="let status of statuses | iterable"
            [value]="status.key"
            [disabled]="
              disabledStatuses.includes(status.key) || (keepAssignee && assigneeStatuses?.includes(status.key))
            "
          >
            {{ status.value | capitalize }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group row" *ngIf="hasAssignee()">
      <label class="col-form-label col-sm-4 col-md-2" for="selected-users">Assignee</label>
      <div class="col-sm-8 col-md-10">
        <input
          class="form-control"
          id="selected-users"
          [(ngModel)]="selectedUser"
          [ngbTypeahead]="search"
          [resultTemplate]="customItemTemplate"
          [inputFormatter]="formatName"
          (selectItem)="onSelectUser($event)"
        />
        <span class="fa fa-refresh fa-spin" aria-hidden="true" [hidden]="!isLoading"></span>
      </div>
    </div>

    <div class="form-group row" *ngIf="needsStatusIdOutReason()">
      <label class="col-form-label col-sm-4 col-md-2" for="selected-users">Id Out Reason</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="statusIdOutReason"
          class="form-control custom-select"
          name="statusIdOutReason"
          [(ngModel)]="selectedIdOutReason"
          required
        >
          <option *ngFor="let reason of statusIdOutReasons | keyvalue" [value]="reason.key">
            {{ reason.value }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group row" *ngIf="needsComment()">
      <label class="col-form-label col-sm-4 col-md-2" for="selected-users">Comment</label>
      <div class="col-sm-8 col-md-10">
        <textarea class="form-control" id="status-comment" [(ngModel)]="statusComment"></textarea>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="pull-right">
      <button
        type="button"
        class="btn btn-primary btn-sm"
        (click)="onSave()"
        [disabled]="hasAssignee() && isUserRequired"
      >
        Save
      </button>
      <button type="button" class="btn btn-secondary btn-sm" (click)="close()">Cancel</button>
    </div>
  </div>
</ng-template>

<!-- Typeahead Item Template -->
<ng-template #customItemTemplate let-model="result" let-t="term">
  <span>{{ model.user_metadata?.firstName }}</span>
  <span>{{ model.user_metadata?.lastName }}</span>
  <span><{{ model.email }}></span>
</ng-template>
