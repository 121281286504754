export const ContributorPrefixes = [
  'Dr',
  'Dr.',
  'MD',
  'Dra.',
  'Dra',
  'Dres.',
  'Dres',
  'Dras',
  'Dras.',
  'Prof.',
  'Lic.',
  'Dott',
  'Dott.',
  'Dott.ssa',
  'Prim',
  'Prim.',
  'Pr',
  'Pr.',
  'Drs',
  'Drs.',
  'Professor',
  'Prof.',
];
