import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, firstValueFrom, takeUntil, tap } from 'rxjs';

import { AccountPersonAPI } from '../shared/account-person-api.service';
import { AccountPersonModalService } from '../modal/person-modal.service';
import { Account } from '../../account/shared/account';
import { AccountPerson } from '../shared/account-person';
import { SideBarService } from '../../shared/components/side-bar/side-bar.service';
import { AuditLogComponent } from '../../shared/components';
import { AccountAffiliation } from '../../account-affiliation/shared/account-affiliation';
import { Person } from '../../person/shared/person';
import { ACL } from '../../shared/acl/acl.service';

@Component({
  selector: 'dirt-account-person-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class AccountPersonListComponent implements OnInit {
  @Input()
  account: Account;

  accountPeople: AccountPerson[] = [];

  accountPeopleToAttach: AccountPerson[] = [];

  accountAffiliations: AccountAffiliation[];

  people: Person[] = [];

  isLoading: boolean;
  isSubmitting: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 50;
  pagingSkip = 0;

  private sort = '-createdAt';

  searchCtrl: FormControl = new FormControl('');

  private searchTerm: string;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private svcAccountPerson: AccountPersonAPI,
    private svcAccountPersonModal: AccountPersonModalService,
    private readonly svcSideBar: SideBarService,
    private svcAcl: ACL
  ) {}

  ngOnInit() {
    this.doLoad();
    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        if (typeof value === 'undefined') {
          return;
        }
        this.searchTerm = value;
        this.doLoad();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  onSort(field: string): void {
    this.sort = field;

    this.getAttachedPeople();
  }

  onAddPersonRequested(): void {
    this.svcAccountPersonModal
      .open(this.account, 'ADD')
      .then(() => this.doLoad())
      .catch(() => {});
  }

  onEditPersonRequested(accountPerson: AccountPerson): void {
    this.svcAccountPersonModal
      .open(this.account, 'EDIT', accountPerson)
      .then(() => this.doLoad())
      .catch(() => {});
  }

  onDeletePersonRequested(accountPerson: AccountPerson): void {
    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.svcAccountPerson.deleteById(accountPerson._id).subscribe(() => this.doLoad());
  }

  onViewPersonRequested(accountPerson: AccountPerson): void {
    this.svcAccountPersonModal.open(this.account, 'VIEW', accountPerson).catch(() => {});
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getAttachedPeople();
  }

  private async doLoad(): Promise<void> {
    this.resetPagination();
    this.getAttachedPeople();
    this.getAttachedPeopleCount();
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getAttachedPeople(): void {
    this.isLoading = true;
    this.svcAccountPerson
      .find(this.account.id, this.pagingLimit, this.pagingSkip, this.sort, null, this.searchTerm)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((connections) => {
        this.accountPeople = connections;
      });
  }

  private getAttachedPeopleCount(): void {
    this.svcAccountPerson.count(this.account.id).subscribe((count) => {
      this.total = count;
    });
  }

  trackById(index: number, account: Account): string {
    return account.id;
  }

  onRequestAuditLogs(ids: string[]): void {
    const componentRef = this.svcSideBar.open<AuditLogComponent>(AuditLogComponent);
    componentRef.instance.id = ids;
    componentRef.instance.entityAPI = this.svcAccountPerson;
    componentRef.instance.ngOnChanges({
      id: { previousValue: null, currentValue: ids, isFirstChange: () => true, firstChange: true },
    }); // Need to be invoked manually
  }

  canReviewKP() {
    return this.svcAcl.hasCredential('account-person.update') && this.svcAcl.hasCredential('account-person.qc');
  }

  async toggleAllReviewed($event: any) {
    if (!Array.isArray(this.accountPeople) || this.accountPeople.length === 0) {
      return;
    }

    $event.stopPropagation();
    this.isLoading = true;
    await firstValueFrom(
      this.svcAccountPerson.bulkUpdate({
        ids: this.accountPeople.map((kp) => kp._id),
        reviewed: !!$event.target.checked,
      })
    );
    this.getAttachedPeople();
    return;
  }

  async onKPReviewedChange(accountPerson: AccountPerson, $event: any) {
    $event.stopPropagation();
    accountPerson.reviewed = !!$event.target.checked;
    accountPerson.reviewedAt = new Date();
    this.isLoading = true;
    await firstValueFrom(this.svcAccountPerson.update(accountPerson._id, accountPerson));
    this.getAttachedPeople();
    return;
  }

  async onAccountPersonMarkForDelete(accountPerson: AccountPerson, $event: any) {
    $event.stopPropagation();
    accountPerson.markForDelete = $event.target.checked;
    this.isLoading = true;
    await firstValueFrom(this.svcAccountPerson.update(accountPerson._id, accountPerson));
    this.getAttachedPeople();
    return;
  }

  getTooltipContent(accountPerson: any, type: 'matrix' | 'title'): string {
    return accountPerson?.jobDetails?.map((jobDetail) => jobDetail[type]).join(', ') || '';
  }
}
