import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { UserPool } from '../shared/user-pool';
import { UserPoolAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-user-pool-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class UserPoolCreateComponent {
  pool: UserPool = new UserPool();
  isSubmitting = false;

  constructor(private router: Router, private svcUserPool: UserPoolAPI) {}

  onSubmit(pool: UserPool): void {
    this.isSubmitting = true;
    this.svcUserPool.create(pool).subscribe(
      (resp) => {
        this.isSubmitting = false;
        this.goBack();
      },
      () => (this.isSubmitting = false)
    );
  }

  goBack(): void {
    this.router.navigate(['/user-pool']);
  }
}
