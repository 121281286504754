import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { concatMap, of, take, tap } from 'rxjs';

import { ACL } from '../../shared/acl/acl.service';
import { Auth } from '../../shared/services/auth/auth.service';
import { ClinicalTrialSponsor } from '../shared/clinical-trial-sponsor';
import { ClinicalTrialSponsorAPI } from '../shared/clinical-trial-sponsor-api.service';
import { Job } from '../../jobs/shared/job';
import { JobsAPI } from '../../jobs/shared/api.service';
import { User } from '../../user/shared/user';

@Component({
  selector: 'dirt-ct-sponsor-detail',
  templateUrl: './detail.component.html',
})
export class ClinicalTrialSponsorDetailComponent implements OnInit {
  id: string;

  sponsor: ClinicalTrialSponsor;

  isLoading = true;

  isSubmitting = false;

  isFormValid: boolean;

  canSubmitNonJob: boolean;

  canSubmitJob: boolean;

  hasJobForCurrentUser: boolean;

  currentJob: Job;

  canCreateComments: boolean;

  private currentUser: User;

  private wndw: Window = window; // allow for testing

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcAcl: ACL,
    private svcAuth: Auth,
    private svcJob: JobsAPI,
    public svcClinicalTrialSponsor: ClinicalTrialSponsorAPI // used in template too
  ) {}

  ngOnInit(): void {
    this.canSubmitNonJob = this.svcAcl.hasCredential('clinicalTrialSponsor.update');
    this.canSubmitJob = this.svcAcl.hasCredential('job.submit');
    this.canCreateComments = this.svcAcl.hasCredential('clinicalTrialSponsor.comment.create');

    this.route.params
      .pipe(
        take(1),
        tap(() => {
          this.isLoading = true;

          // Make sure we cannot submit something we shouldn't under the pretense that we are working a job
          this.currentJob = null;
          this.hasJobForCurrentUser = false;
        }),
        concatMap((params) => {
          window.scrollTo(0, 0);

          this.id = params['id'];

          return this.svcClinicalTrialSponsor.findById(this.id).pipe(tap((sponsor) => (this.sponsor = sponsor)));
        }),
        concatMap(() => {
          if (this.currentUser) {
            // We already know current user, don't waste time fetching again
            return of(this.currentUser);
          }

          return this.svcAuth.getProfile().pipe(tap((user) => (this.currentUser = user)));
        }),
        concatMap(() => {
          // Only get job details if user is the one working it
          if (!(this.sponsor._meta?.jobId && this.sponsor._meta?.assignee === this.currentUser.user_id)) {
            return of(null);
          }

          return this.svcJob.findById(this.sponsor._meta.jobId).pipe(
            tap((job) => {
              if (job) {
                this.currentJob = job;
                this.hasJobForCurrentUser =
                  this.svcAcl.hasCredential('job.submit') &&
                  this.sponsor._meta.jobId &&
                  this.currentUser.user_id === this.sponsor._meta.assignee;
              }
            })
          );
        }),
        tap(() => (this.isLoading = false))
      )
      .subscribe();
  }

  goBack(): void {
    const link = this.hasJobForCurrentUser ? ['/next'] : ['/ct-sponsor'];
    this.router.navigate(link);
  }

  onSave(sponsor: ClinicalTrialSponsor): void {
    this.updateClinicalTrialSponsor(sponsor);
  }

  onFormValidityChange(status: string): void {
    setTimeout(() => (this.isFormValid = status === 'VALID'));
  }

  onJobUtc(): void {
    if (!this.hasJobForCurrentUser) {
      return;
    }

    let comment = '';
    while (comment.length < 3) {
      comment = this.wndw.prompt('Enter a comment for Unable to Compile...');
      if (null === comment) {
        return;
      }
    }

    this.isSubmitting = true;
    this.svcJob
      .setUtc(this.currentJob._id, comment)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  onJobDraft(sponsor: ClinicalTrialSponsor): void {
    if (!this.hasJobForCurrentUser) {
      return;
    }

    this.isSubmitting = true;
    this.svcClinicalTrialSponsor
      .update(this.sponsor.id, sponsor)
      .pipe(
        concatMap(() => this.svcJob.setDraft(this.currentJob._id)),
        tap(() => (this.isSubmitting = false))
      )
      .subscribe(() => {
        this.goBack();
      });
  }

  onSubmitJob(sponsor: ClinicalTrialSponsor): void {
    if (!this.hasJobForCurrentUser || !this.isFormValid) {
      return;
    }

    this.isSubmitting = true;
    this.svcClinicalTrialSponsor
      .submitJob(sponsor)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  onCopyID(id: string): void {
    navigator.clipboard.writeText(id);
  }

  isClaimedByOtherUser(): boolean {
    // actual valid use of call from template to handle expiration
    const hasValidClaim = this.sponsor._meta?.claimedUntil && new Date(this.sponsor._meta.claimedUntil) > new Date();
    const isClaimedByUser = this.sponsor._meta?.assignee && this.sponsor._meta.assignee !== this.currentUser.user_id;

    return hasValidClaim && isClaimedByUser;
  }

  private updateClinicalTrialSponsor(sponsor: ClinicalTrialSponsor): void {
    if (!this.canSubmitNonJob || !this.isFormValid) {
      return;
    }

    this.isSubmitting = true;
    this.svcClinicalTrialSponsor
      .update(this.id, sponsor)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }
}
