<section class="dirt-section-create dirt-account-medical-information-create">
  <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
    <span class="fa fa-arrow-left" aria-hidden="true"></span>
  </button>

  <h1 class="bounceInRight">Create Medical Information</h1>

  <br />

  <dirt-account-medical-information-form
    [accountMedicalInformation]="accountMedicalInformation"
    #frmAccountMedicalInformation="frmAccountMedicalInformation"
    (validityChange)="onFormValidityChange($event)"
  >
  </dirt-account-medical-information-form>

  <div class="pull-right">
    <ng-template [diAcl]="'account-medical-information.create'">
      <button
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="!isFormValid || isSubmitting"
        (click)="onSubmit(frmAccountMedicalInformation.getValue())"
      >
        Submit
      </button>
    </ng-template>
  </div>
</section>
