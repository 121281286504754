<span class="dirt-address-tile" [ngClass]="{'draft-color': draftColor}">
  <span *diMarker="{name: 'address', entity: (review ? 'organization' : 'noop')}">
    <div class="alink" (click)="onDetail()">{{address?.formatted}}</div>
    <span *ngIf="address?.formatted">
      <a href="https://google.com/search?q={{ encodeURIComponent(address.formatted) }}" target="_blank"
        ><i class="fa fa-external-link"></i
      ></a>
      <i class="fa fa-copy" style="cursor: pointer" (click)="onCopyAddress()" ngbTooltip="Copy Name"></i>
    </span>
    <div style="display: flex; flex-direction: column" *ngIf="organizationAddress">
      <div style="display: flex; justify-content: space-between">
        <label
          >Floor <input type="text" name="floor" size="5" [(ngModel)]="organizationAddress.floor" [disabled]="disabled"
        /></label>
        <label
          >Suite <input type="text" name="suite" size="5" [(ngModel)]="organizationAddress.suite" [disabled]="disabled"
        /></label>
      </div>
      <div>
        <dirt-link
          [(url)]="organizationAddress.websource"
          name="websource"
          [disabled]="disabled"
          [placeholder]="'Websource'"
        ></dirt-link>
      </div>
    </div>
  </span>
  <span
    *ngIf="removeButton && (!disabled)"
    class="fa fa-trash-o alink removebutton"
    (click)="removeClicked.emit(true)"
  ></span>
  <span *ngIf="addButton" class="fa fa-plus alink addbutton" (click)="addClicked.emit(true)"></span>
</span>

<!-- Modal: details -->
<ng-template #addressDetailsModal let-d="dismiss">
  <div class="dirt-content-wrapper" tabindex="0" (keydown.escape)="d()">
    <div class="modal-header">
      <h5 class="modal-title">Address Details</h5>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <a href="https://google.com/search?q={{ encodeURIComponent(address.formatted) }}" target="_blank">
          {{address.formatted}}
        </a>
        &nbsp;
        <i class="fa fa-copy" style="cursor: pointer" (click)="onCopyAddress()" ngbTooltip="Copy Name"></i>
      </div>

      <hr />

      <form #websourceForm="ngForm">
        <!-- Websource -->
        <div class="form-group row">
          <label class="col-form-label col-sm-6 col-md-4">Websource</label>
          <div class="col-sm-6 col-md-8">
            <dirt-link
              [(url)]="address.websource"
              [form]="websourceForm"
              name="websource"
              [disabled]="!isAddressEditable() || !isEditing"
            ></dirt-link>
          </div>
        </div>

        <!-- Original Street -->
        <div class="form-group row">
          <label class="col-form-label col-sm-6 col-md-4">Original Street</label>
          <div class="col-sm-6 col-md-8">
            <input
              type="text"
              class="form-control"
              name="originalStreet"
              [(ngModel)]="address.originalName"
              [disabled]="!isEditing || !isAddressEditable()"
            />
          </div>
        </div>

        <!-- Original Street 2 -->
        <div class="form-group row">
          <label class="col-form-label col-sm-6 col-md-4">Street Additional</label>
          <div class="col-sm-6 col-md-8">
            <input
              type="text"
              class="form-control"
              name="originalStreet2"
              [(ngModel)]="address.originalAdditionalInfo"
              [disabled]="!isEditing || !isAddressEditable()"
            />
          </div>
        </div>

        <!-- Original City -->
        <div class="form-group row">
          <label class="col-form-label col-sm-6 col-md-4">Original City</label>
          <div class="col-sm-6 col-md-8">
            <input
              type="text"
              class="form-control"
              name="originalCity"
              [(ngModel)]="address.originalCity"
              [disabled]="!isEditing || !isAddressEditable()"
            />
          </div>
        </div>

        <!-- Original Language -->
        <div class="form-group row">
          <label class="col-form-label col-sm-6 col-md-4">Original Language</label>
          <div class="col-sm-6 col-md-8">
            <input
              type="text"
              class="form-control"
              name="originalLanguage"
              [(ngModel)]="address.originalLanguage"
              [disabled]="!isEditing || !isAddressEditable()"
            />
          </div>
        </div>

        <!-- Edit & Save Controls-->
        <div *ngIf="!isEditing">
          <button *ngIf="isAddressEditable()" class="btn btn-sm btn-secondary" (click)="onEdit()">Edit</button>
        </div>
        <div *ngIf="isEditing">
          <button class="btn btn-sm btn-primary" [disabled]="!websourceForm.valid" (click)="onSave()">Save</button>
          <button class="btn btn-sm btn-secondary" (click)="isEditing=false">Cancel</button>
        </div>
      </form>
    </div>
  </div>
</ng-template>
