import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Contribution } from '../contribution';

import { MoveContributionComponent } from './move-contribution.component';
import { Event } from '../../../event/shared/event';

@Injectable()
export class MoveContributionService {
  constructor(private svcModal: NgbModal) {}

  open(
    contributionToBeMoved: Contribution[],
    parentID: string,
    directContribution: boolean,
    eventID: string,
    allContributionOnMove: boolean,
    sessionSelected?: Event
  ): Promise<any> {
    const ref = this.svcModal.open(MoveContributionComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });

    ref.componentInstance.contributionToBeMoved = contributionToBeMoved;
    ref.componentInstance.parentID = parentID;
    ref.componentInstance.directContribution = directContribution;
    sessionSelected?.id ? (ref.componentInstance.sessionSelected = sessionSelected.id) : null;
    sessionSelected?.name ? (ref.componentInstance.searchTerm = sessionSelected.name) : null;
    ref.componentInstance.eventID = eventID;
    ref.componentInstance.allContributionOnMove = allContributionOnMove;

    return ref.result;
  }
}
