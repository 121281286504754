export class GuidelineAuthor {
  _id: string;
  authorId: string;
  affiliations: string[];
  city: string;
  country: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  nickname: string;
  originalFullName: string;
  institution: string;
  position: string;
  qc: string;
  verified: boolean;
}
