export class PersonDuplicateTakeover {
  discardKolId: string;

  stayKolId: string; // likely = person.kolId (except after merge)

  detectDate: Date; // (important b/c we need to

  discardedVid: string; // (discarded from stay)
  addedVid: string; // (added to stay - was NOT there before)

  discardedNpi: string; // (discarded from stay)
  addedNpi: string; // (added to stay - was NOT there before)

  addedProjectNames: string[]; // (added to stay - was NOT there before)
}
