import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { of, Subject, Observable, from, firstValueFrom } from 'rxjs';
import { tap, concatMap, takeUntil, map } from 'rxjs/operators';

import { Account } from '../shared/account';
import { AccountAPI } from '../shared/account-api.service';
import { AccountStatus } from '../shared/constant/status.enum';
import { ACL } from '../../shared/acl/acl.service';
import { Auth } from '../../shared/services/auth/auth.service';
import { Job } from '../../jobs/shared/job';
import { JobsAPI } from '../../jobs/shared/api.service';
import { User } from '../../user/shared/user';
import { Roles } from '../../shared/acl/roles';
import { AccountAffiliationListComponent } from '../../account-affiliation/list/list.component';
import { AccountAffiliationSuspectListComponent } from '../../account-affiliation-suspect/list/list.component';
import { AccountAffiliationSuspectAPI } from '../../account-affiliation-suspect/shared/account-affiliation-suspect-api.service';
import { AffiliationSuspectStatus } from '../../account-affiliation-suspect/shared/account-affiliation-suspect';
import { AccountAffiliationAPI } from '../../account-affiliation/shared/account-affiliation-api.service';
import { AccountMoveModalService } from '../shared/move-modal/move-modal.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'dirt-account-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class AccountDetailComponent implements OnInit, OnDestroy {
  id: string;

  account: Account;

  isLoading = true;

  isSubmitting = false;

  isSavingStatus = false;

  isSavingPriority = false;

  isFormValid: boolean;

  statuses = AccountStatus;

  assigneeStatuses = []; // none - only using jobs

  disabledStatuses = [
    AccountStatus.READY_FOR_COMPILATION, // Going back does nothing
  ];

  canCreateComments: boolean;

  canSaveAnyway: boolean;

  canSubmitNonJob: boolean;

  canSubmitJob: boolean;

  hasJobForCurrentUser: boolean;

  currentJob: Job;

  private currentUser: User;

  private destroy$: Subject<boolean> = new Subject();

  private wndw: Window = window; // allow for testing

  isCollapsed = new Map();

  @ViewChild(AccountAffiliationListComponent, { static: false })
  private accountAffiliationListRef: AccountAffiliationListComponent;

  @ViewChild(AccountAffiliationSuspectListComponent, { static: false })
  private accountAffiliationSuspectListRef: AccountAffiliationSuspectListComponent;

  isPriorityEditable: boolean;

  hasUnpolishedAffiliations: boolean;

  accountAffiliationIds?: string[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcAcl: ACL,
    private svcAuth: Auth,
    private svcJob: JobsAPI,
    public svcAccount: AccountAPI, // used in template too
    private svcAccountAffiliationSuspect: AccountAffiliationSuspectAPI,
    private svcAccountAffiliation: AccountAffiliationAPI,
    private svcAccountMoveModal: AccountMoveModalService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.destroy$), // we want to react to url change to fetch child details
        tap(() => {
          this.isLoading = true;

          // Make sure we cannot submit something we shouldn't under the pretense that we are working a job
          this.currentJob = null;
          this.hasJobForCurrentUser = false;
        }),
        concatMap((params) => {
          window.scrollTo(0, 0);

          this.id = params['id'];

          return this.svcAccount.findById(this.id).pipe(
            tap((account) => {
              this.account = account;
              this.titleService.setTitle(`cLean | Account| ${this.account.name}`);
            })
          );
        }),
        concatMap(() => {
          return this.svcAccountAffiliation
            .find(null, 1, 0, null, this.account.id, undefined, { 'affiliation.readyForDelivery': [null, false] }, true)
            .pipe(tap((affiliations) => (this.hasUnpolishedAffiliations = affiliations?.length > 0)));
        }),
        tap(
          () =>
            (this.canSubmitNonJob = this.svcAcl.hasCredential(
              this.account.parent ? 'division.update' : 'account.update'
            ))
        ),
        concatMap(() => {
          if (this.currentUser) {
            // We already know current user, don't waste time fetching again
            return of(this.currentUser);
          }

          return this.svcAuth.getProfile().pipe(tap((user) => (this.currentUser = user)));
        }),
        concatMap(() => {
          // Only get job details if user is the one working it
          if (!(this.account._meta?.jobId && this.currentUser.user_id === this.account._meta?.assignee)) {
            return of(null);
          }

          return this.svcJob.findById(this.account._meta.jobId).pipe(
            tap((job) => {
              if (job) {
                this.currentJob = job;
                this.hasJobForCurrentUser =
                  this.svcAcl.hasCredential('job.submit') &&
                  this.account._meta.jobId &&
                  this.currentUser.user_id === this.account._meta.assignee;
              }
            })
          );
        }),
        tap(() => (this.isLoading = false))
      )
      .subscribe();

    this.canCreateComments = this.svcAcl.hasCredential('account.comment.create');
    this.canSaveAnyway = this.svcAcl.hasCredential('account.saveAnyway'); // allow saving despite remaining form validation issues
    this.canSubmitJob = this.svcAcl.hasCredential('job.submit');
    this.setCollapseSettings();

    this.isPriorityEditable = !this.account?.parent && this.svcAcl.hasCredential('account.update.prop.priority');
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  goBack(): void {
    const link = this.account.parent ? ['/account/detail', this.account.parent.id] : ['/account'];
    this.router.navigate(link);
  }

  onJobUtc(): void {
    if (!this.hasJobForCurrentUser) {
      return;
    }

    let comment = '';
    while (comment.length < 3) {
      comment = this.wndw.prompt('Enter a comment for Unable to Compile...');
      if (null === comment) {
        return;
      }
    }

    this.isSubmitting = true;

    this.svcJob
      .setUtc(this.currentJob._id, comment)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(() => {
        this.goBack();
      });
  }

  async onJobDraft(account: Account): Promise<void> {
    if (!this.hasJobForCurrentUser) {
      return;
    }

    this.isSubmitting = true;

    this.svcAccount
      .update(account._id, account)
      .pipe(
        concatMap(() => this.svcJob.setDraft(this.currentJob._id)),
        tap(() => (this.isSubmitting = false))
      )
      .subscribe(() => {
        this.goBack();
      });
  }

  onSubmitJob(account: Account): void {
    if (!this.hasJobForCurrentUser || (!this.isFormValid && !this.canSaveAnyway)) {
      return;
    }
    if (
      !this.isFormValid &&
      this.canSaveAnyway &&
      !this.wndw.confirm('There are validation errors left. Sure you want to submit?')
    ) {
      return;
    }

    if (this.currentJob.type === 'ACCOUNT_MAPPING' && !this.accountAffiliationListRef.hasHQAffiliation()) {
      // block submission without HQ
      this.wndw.alert('Please select an HQ organization.');
      return;
    }

    this.validateJobSubmission().subscribe(({ valid, message }) => {
      if (!valid) {
        return alert(message);
      }
      this.isSubmitting = true;

      this.svcAccount
        .submitJob(account)
        .pipe(tap(() => (this.isSubmitting = false)))
        .subscribe(() => {
          this.goBack();
        });
    });
  }

  onSave(account: Account, goBack?: boolean): void {
    if (!this.canSubmitNonJob || (!this.isFormValid && !this.canSaveAnyway)) {
      return;
    }
    if (!this.isFormValid && this.canSaveAnyway) {
      if (!this.wndw.confirm('There are validation errors left. Sure you want to submit?')) {
        return;
      }
    }

    this.isSubmitting = true;

    this.svcAccount
      .update(this.id, account)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe((account) => {
        this.account = this.getMergedAccount(this.account, account);
        if (goBack) {
          this.goBack();
        }
      });
  }

  onStatusChange(_meta: any): void {
    if (!this.svcAcl.hasCredential('account.update.prop.meta.status')) {
      return;
    }

    this.isSavingStatus = true;

    this.svcAccount
      .update(this.account._id, { _meta })
      .pipe(tap(() => (this.isSavingStatus = false)))
      .subscribe((account) => {
        this.account._meta.status = _meta.status;
        this.account = this.getMergedAccount(this.account, account);
      });
  }

  onFormValidityChange(status: string): void {
    setTimeout(() => (this.isFormValid = status === 'VALID'));
  }

  isClaimedByOtherUser(): boolean {
    // actual valid use of call from template to handle expiration
    const hasValidClaim = this.account._meta.claimedUntil && new Date(this.account._meta.claimedUntil) > new Date();
    const isClaimedByUser = this.account._meta.assignee && this.account._meta.assignee !== this.currentUser.user_id;

    return hasValidClaim && isClaimedByUser;
  }

  private getMergedAccount(localData: Account, remoteData: Account): Account {
    localData.updatedAt = remoteData.updatedAt;
    localData._version = remoteData._version;
    localData.readyForDelivery = remoteData.readyForDelivery;
    localData.removedAffiliationIds = remoteData.removedAffiliationIds;
    return localData;
  }

  setCollapseSettings(): void {
    const isAccountAffiliationCompiler = this.svcAcl.hasRole(Roles.AccountAffiliationCompiler);
    const isAccountKPCompiler = this.svcAcl.hasRole(Roles.AccountJTKPCompiler);
    this.isCollapsed.set('affiliation', !isAccountAffiliationCompiler);
    this.isCollapsed.set('affiliationSuspects', !isAccountAffiliationCompiler);
    this.isCollapsed.set('person', !isAccountKPCompiler);
    this.isCollapsed.set('division', true);
    this.isCollapsed.set('financial', true);
    this.isCollapsed.set('medical', true);
    this.isCollapsed.set('payer', true);
  }

  toggleCollapseSettings(section: string) {
    this.isCollapsed.set(section, !this.isCollapsed.get(section));
  }

  onMatchChange() {
    this.accountAffiliationListRef.doLoad();
  }

  onAffiliationRemove(): void {
    // Refresh the suspect list
    this.accountAffiliationSuspectListRef.doLoad();

    // Refresh account data
    this.isLoading = true;
    this.svcAccount
      .findById(this.account._id)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((account) => {
        this.account = this.getMergedAccount(this.account, account);
      });
  }

  onSetHQ(): void {
    this.svcAccount.findById(this.id).subscribe((account) => {
      this.account = this.getMergedAccount(this.account, account);
    });
  }

  onMoveAccount(): void {
    from(this.svcAccountMoveModal.open(this.account.id)).subscribe(async (account) => {
      this.account = this.getMergedAccount(this.account, account);

      this.account.level = account.level;
      this.account.rootAccount = account.rootAccount;

      if (account.parent) {
        // we need more than just ID
        this.account.parent = await firstValueFrom(this.svcAccount.findById(account.parent as any));
      }
    });
  }

  onActiveIdChange(activeId: string): void {
    if (activeId !== 'account-affiliation-audits') {
      return;
    }

    // Get the IDs of all connected account-affiliations
    this.accountAffiliationIds = null; // We need to clear it to fetch the updated version
    this.svcAccountAffiliation.findIds(this.account._id).subscribe((ids: string[]) => {
      this.accountAffiliationIds = ids.concat(this.account.removedAffiliationIds);
    });
  }

  validateJobSubmission() {
    let obs: Observable<{ valid: boolean; message?: string }>;
    if (this.currentJob.type === 'ACCOUNT_MAPPING') {
      obs = this.svcAccountAffiliationSuspect
        .find(1, 0, undefined, this.account.id, { status: AffiliationSuspectStatus.SUSPECT })
        .pipe(
          map((found) => ({
            valid: found.length === 0,
            ...(found.length > 0 && { message: `There are still undecided suspects. Please work on them.` }),
          }))
        );
    } else {
      obs = of({ valid: true });
    }
    return obs;
  }

  setPriority(account: Account, priority: number): void {
    if (!priority || (account.priority && account.priority === priority)) {
      return;
    }
    this.isSavingPriority = true;
    this.svcAccount
      .update(account._id, { priority } as Account)
      .pipe(tap(() => (this.isSavingPriority = false)))
      .subscribe((updatedAccount) => {
        this.account.priority = priority;
        this.account = this.getMergedAccount(this.account, updatedAccount);
      });
  }

  onInactiveChange(checked: boolean): void {
    this.account.inactive = checked;
  }
}
