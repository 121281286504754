import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';

import { AssociationAPI } from '../../association-api.service';
import { Association } from '../../association';

@Component({
  selector: 'dirt-association-single-select',
  templateUrl: 'association-single-select.html',
  styleUrls: ['association-single-select.scss'],
})
export class AssociationSingleSelectComponent {
  @Input()
  association: Association | string;

  @Output() // (can [(association)]
  associationSelected: EventEmitter<Association> = new EventEmitter();

  @Input()
  wide: boolean = false;

  @Input()
  inline = false;

  @Input()
  rootOnly: boolean = false;

  @Input()
  disabled = false;

  @Input()
  required = false;

  @Input()
  creationRequestHandler?: (association?: Association) => Observable<Association>;

  isSearching = false;

  searchAssociations = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }

        return this.svcAssociation.searchElastic(term as any, 100, this.rootOnly).pipe(
          take(1),
          catchError(() => {
            return of([]);
          })
        );
      }),
      tap(() => (this.isSearching = false))
    );

  constructor(private readonly svcAssociation: AssociationAPI) {}

  onChangeAssociation(association) {
    this.associationSelected.emit(association);

    if (!this.inline) {
      setTimeout(() => (this.association = null)); // (next apply)
    } else {
      this.association = association;
    }
  }

  onChangeAssociationInline(value: Association | string): void {
    if (!value || !value?.toString()?.trim()) {
      this.associationSelected.emit(null); // value cleared
    }
  }

  onRequestAssociation(association: Association): void {
    if (!this.creationRequestHandler) {
      return;
    }

    this.creationRequestHandler(association)
      .pipe(take(1))
      .subscribe((association) => {
        this.onChangeAssociation(association);
      });
  }

  formatTitle(association: Association): string {
    return association.name;
  }
}
