import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { Affiliation } from '../shared/affiliation';
import { Person } from '../../person/shared/person';
import { PersonAPI } from '../../person/shared/api.service';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { Value } from '../../shared/services/value/value';

@Component({
  selector: 'dirt-affiliation-person-list',
  templateUrl: 'person-list.component.html',
  styleUrls: ['person-list.component.scss'],
})
export class AffiliationPersonListComponent implements OnInit {
  @Input()
  affiliation: Affiliation;

  people: Person[] = [];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  searchCtrl: FormControl = new FormControl('');

  private searchTerm: string;

  private sort = '+affiliations.primary';

  private destroy$: Subject<boolean> = new Subject();

  private projects: Value[];

  constructor(private readonly svcPerson: PersonAPI, private readonly svcValue: ValueAPI) {}

  ngOnInit() {
    this.svcValue.find(ValueType.PersonProject).subscribe((projects) => {
      this.projects = projects;

      this.doLoad();
    });

    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
      .subscribe((value) => {
        if (typeof value === 'undefined') {
          return;
        }

        this.searchTerm = value;
        this.doLoad();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  onSort(field: string): void {
    this.sort = field;

    this.getAttachedPeople();
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getAttachedPeople();
  }

  private doLoad(): void {
    this.resetPagination();
    this.getAttachedPeople();
    this.getAttachedPeopleCount();
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getAttachedPeople(): void {
    this.isLoading = true;

    this.svcPerson
      .find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, {
        affiliationsCombined: [this.affiliation.id],
      })
      .subscribe((people) => {
        this.people = people.map((p) => {
          (p as any).products = p.projectNames.reduce((acc, pn) => {
            const product = this.projects.find((pp) => pp.code === pn)?.product;
            if (!acc.includes(product) && !!product?.trim()) {
              acc.push(product);
            }
            return acc;
          }, []);

          const lftaAffiliation = p.affiliations?.find((a) => a.id === this.affiliation.id);
          if (!!lftaAffiliation) {
            (p as any).isPrimaryAffiliation = lftaAffiliation.primary;
            (p as any).isPrimaryCandidateAffiliation = lftaAffiliation.primaryCandidate;
          }

          return p;
        });
      });

    this.isLoading = false;
  }

  private getAttachedPeopleCount(): void {
    this.svcPerson.count(this.searchTerm, { affiliationsCombined: [this.affiliation.id] }).subscribe((count) => {
      this.total = count;
    });
  }
}
