<section class="dirt-sentiment-next">
  <div class="next-content"></div>

  <div class="next-action">
    <button type="button" class="btn btn-success" (click)="getNext()" [disabled]="isLoadingNext">
      <i class="fa fa-refresh fa-spin" [hidden]="!isLoadingNext"></i>
      Next sentiment
    </button>
  </div>
</section>
