<section class="dirt-section-list dirt-event-series-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>Event Series</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        placeholder="Search..."
        [formControl]="searchCtrl"
      />
    </div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Filter -->
        <dirt-filters [filters]="filters" (onFilter)="onFilter($event)"></dirt-filters>

        <dirt-button-dropdown [title]="'Bulk'" [icon]="'fa-pencil'" *diAcl="'eventSeries.bulkList'">
          <button
            class="list-group-item list-group-item-action"
            [title]="jobBulkListDelegate.specialHint || ''"
            (click)="onOpenBulkListJobDialog()"
          >
            <i class="fa fa-th-list" aria-hidden="true"></i>
            Bulk Import Job
          </button>
        </dirt-button-dropdown>

        <!-- Create Button -->
        <a class="btn btn-success btn-sm" [routerLink]="['/event-series/create']" *diAcl="'eventSeries.create'">
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
          <span>Create Series</span>
        </a>
      </div>
    </div>
  </header>

  <br />

  <!-- Filter Bar -->
  <dirt-filters-bar
    [filter]="filter"
    [config]="filterBarConfig"
    (filterClear)="onFilterClear()"
    (filterValueRemove)="onFilterValueRemove($event)"
  ></dirt-filters-bar>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-event-series-list'"
    [pagingSize]="eventSeries?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Table -->
  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="eventSeries?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-state">&nbsp;</th>
          <th class="col-name" [diColSorter]="'name'">Name</th>
          <th class="col-event-start-date">Date</th>
          <th class="col-event-status">Status</th>

          <th class="col-event-count" [diColSorter]="'eventsCount'">
            <span ngbTooltip="Events Count"><i class="fa fa-list" aria-hidden="true"></i></span>
          </th>
          <th class="col-contributions-count">
            <span ngbTooltip="Contributions Count"><i class="fa fa-th-list" aria-hidden="true"></i></span>
          </th>
          <th class="col-modified" [diColSorter]="'updatedAt'">Modified</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <ng-container
          *ngFor="
            let series of eventSeries
              | paginate
                : {
                    id: 'dirt-event-series-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <tr class="dirt-event-series-row">
            <td class="col-state"></td>
            <td class="col-name">
              <a
                class="dirt-event-series-name"
                [title]="series.name"
                [routerLink]="['/event-series/detail', series.id]"
                >{{ series.name }}</a
              >
            </td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td class="col-event-count">{{ series.eventsCount }}</td>
            <td class="col-contributions-count">&nbsp;</td>
            <td class="col-modified">{{ series.updatedAt | date : 'dd.MM.y' }}</td>
            <td class="col-actions">
              <!-- Delete -->
              <span
                class="action-btn action-delete"
                title="Delete Series"
                (click)="onDelete(series.id)"
                *diAcl="'eventSeries.delete'"
              >
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </span>
            </td>
          </tr>

          <tr class="dirt-event-row" *ngFor="let event of series.events">
            <td class="col-state pl-4">
              <span
                class="state state-stacked state-success"
                ngbTooltip="Verified"
                placement="right"
                *ngIf="event.verified"
              ></span>
              <span
                class="state state-stacked state-primary"
                ngbTooltip="Oncology"
                placement="right"
                *ngIf="event.scope"
              ></span>
            </td>
            <td class="col-event-name">
              <a [title]="event.name" [routerLink]="['/event/detail', event._id]" rel="noopener" target="_blank">{{
                event.name
              }}</a>
            </td>
            <td class="col-event-started">
              {{ event.startDate | date : 'dd.MM.y' }}
            </td>
            <td class="col-event-status">
              {{ event._meta.status | capitalize }}
            </td>
            <td>&nbsp;</td>
            <td>{{ event.contributionsCount }}</td>
            <td>&nbsp;</td>
            <td class="col-event-actions">
              <!-- Clone -->
              <a title="Clone event" [routerLink]="['/event/create']" [queryParams]="{ from: event._id }">
                <i class="fa fa-plus-circle" aria-hidden="true"></i>
              </a>

              &nbsp;

              <!-- Delete -->
              <span
                class="action-btn action-remove"
                (click)="onRemoveEvent(series, event)"
                *diAcl="'eventSeries.update.prop.event'"
              >
                <i class="fa fa-remove" aria-hidden="true"></i>
              </span>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No Event Series</p>
    </ng-template>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-event-list'"
    [pagingSize]="eventSeries?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="isLoading || pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
