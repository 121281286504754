export enum Completeness {
  emails = 'Email',
  degrees = 'Degrees',
  ['affiliations.position'] = 'Job Title',
  ['social.linkedinUrl'] = 'Linkdin URL',
  ['externalIds.openData'] = 'VID',
  ['externalIds.npi'] = 'NPI',
}

export enum CompletenessValues {
  EMPTY = 'EMPTY',
  NOT_EMPTY = 'NOT EMPTY',
}
