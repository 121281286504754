export enum OrganizationJob {
  STRUCTURE_COMPILATION = 'ORGANIZATION_STRUCTURE_COMPILATION',
  TYPE_COMPILATION = 'ORGANIZATION_TYPE_COMPILATION',
  TRANSFORMATION_COMPILATION = 'ORGANIZATION_TRANSFORMATION_COMPILATION',
  TRANSFORMATION_COMPILATION_QC = 'ORGANIZATION_TRANSFORMATION_QC',
  MAPPING_COMPILATION = 'ORGANIZATION_MAPPING_COMPILATION',
  MAPPING_QC = 'ORGANIZATION_MAPPING_QC',
  CORRECTNESS_QC = 'ORGANIZATION_CORRECTNESS_QC',
  COMPLETENESS_QC = 'ORGANIZATION_COMPLETENESS_QC',
  TYPES_QC = 'ORGANIZATION_TYPES_QC',
  MAINTENANCE = 'ORGANIZATION_MAINTENANCE',
}
