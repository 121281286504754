<div class="input-group">
  <ngb-timepicker
    [(ngModel)]="displayValue"
    [meridian]="false"
    [name]="name"
    (ngModelChange)="onValueChange($event)"
    [spinners]="false"
    [required]="required"
    [disabled]="disabled"
  >
  </ngb-timepicker>
</div>
