import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BulkModalComponent } from './component/bulk-modal/bulk-modal.component';
import { BulkTrackingAPI } from './shared/api.service';

@NgModule({
  declarations: [BulkModalComponent],
  imports: [CommonModule],
  exports: [BulkModalComponent],
  providers: [BulkTrackingAPI],
})
export class BulkModule {}
