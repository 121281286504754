<section class="dirt-section-create dirt-event-create">
  <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
    <span class="fa fa-arrow-left" aria-hidden="true"></span>
  </button>

  <h1 class="bounceInRight">
    <span [hidden]="event.parent">Create Event</span>
    <span [hidden]="!event.parent">Create Session / Satellite Symposiums</span>
  </h1>

  <br />

  <dirt-event-form [event]="event" [seriesName]="seriesName" #frmEvent="frmEvent"></dirt-event-form>

  <div>
    <dirt-ocr-bar [webSource]="null"></dirt-ocr-bar>
  </div>

  <div class="pull-right">
    <!-- Event -->
    <ng-template [diAcl]="'event.create'" [diAclAnd]="!event.parent">
      <!-- Submit -->
      <ng-template [diAcl]="'!event.create.unable'" [diAclOr]="frmEvent.getValue().webSource">
        <button
          type="submit"
          class="btn btn-primary"
          [ngClass]="{ 'btn-loading': isSubmitting }"
          [disabled]="!frmEvent.isValid() || isSubmitting"
          (click)="onSubmit(frmEvent.getValue(), 'SAVE_AND_BACK')"
        >
          <span>Submit</span>
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          [ngClass]="{ 'btn-loading': isSubmitting }"
          [disabled]="!frmEvent.isValid() || isSubmitting"
          (click)="onSubmit(frmEvent.getValue(), 'SAVE_AND_NEW')"
        >
          <span>Save & New</span>
        </button>
      </ng-template>

      <!-- No Info -->
      <ng-template [diAcl]="'event.create.unable'" [diAclAnd]="!frmEvent.getValue().webSource">
        <button
          type="submit"
          class="btn btn-warning"
          [ngClass]="{ 'btn-loading': isSubmitting }"
          [disabled]="!frmEvent.isValid() || isSubmitting"
          (click)="onNoInfo(frmEvent.getValue())"
        >
          <span>Mark as No Info</span>
        </button>
      </ng-template>
    </ng-template>

    <!-- Session -->
    <ng-template [diAcl]="'session.create'" [diAclAnd]="!!event.parent">
      <button
        type="submit"
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="!frmEvent.isValid() || isSubmitting"
        (click)="onSubmit(frmEvent.getValue(), 'SAVE')"
      >
        <span>Submit</span>
      </button>
      <button
        type="submit"
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="!frmEvent.isValid() || isSubmitting"
        (click)="onSubmit(frmEvent.getValue(), 'SAVE_AND_NEW')"
      >
        <span>Save & New</span>
      </button>
    </ng-template>
  </div>
</section>
