import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[mongoIdValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MongoIdValidator, multi: true }],
})
export class MongoIdValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    if (control.value) {
      return control.value.match(/^[a-f0-9]{24}$/) ? null : { mongoId: true };
    }
  }
}
