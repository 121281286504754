import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GET,
  POST,
  DELETE,
  Path,
  Body,
  Query,
  Produces,
  MediaType,
  BaseUrl,
  PATCH,
} from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { Comment } from './comment';
import { environment } from '../../../environments/environment';

@Injectable()
export class CommentAPI extends APIService {
  @GET('comments/')
  @Produces(MediaType.JSON)
  public find(
    @Query('ownerId') ownerId?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('peopleId') peopleId?: string
  ): Observable<Comment[]> {
    return null;
  }

  @GET('comments/count')
  @Produces(MediaType.JSON)
  public count(@Query('ownerId') ownerId?: string): Observable<any> {
    return null;
  }

  @POST('comments')
  @Produces(MediaType.JSON)
  public create(@Body comment: Comment): Observable<Comment> {
    return null;
  }

  @DELETE('comments/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return null;
  }

  @PATCH('comments/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body comment: Comment): Observable<Comment> {
    return null;
  }
}
