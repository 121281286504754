import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Event } from '../../../event/shared/event';
import { Contribution } from '../../../contribution/shared/contribution';
import { ContributionParseAPI } from '../contribution-parse-api.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { NameSplitService } from '../../../shared/services/name-split/name-split.service';
import { ContributionFormComponent } from '../../../contribution/shared/form/form.component';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { Value } from '../../../shared/services/value/value';

export const CAPTURE_EVENT_ID = 'capture_event_id';
export const CAPTURE_EVENT_NAME = 'capture_event_name';
export const CAPTURE_TEXTS = 'capture_texts';
export const CAPTURE_RUNNING = 'capture_running';

@Component({
  selector: 'dirt-capture-bar',
  templateUrl: './capture-bar.component.html',
  styleUrls: ['./capture-bar.component.scss'],
})
export class CaptureBarComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  event: Event;

  @Input()
  eventCaption: string;

  @Output()
  hasContribution: EventEmitter<Contribution> = new EventEmitter();

  captureStarted = false; // (ever)
  captureRunning = false; // (right now)
  capturedCount = 0;
  currentIndex = -1; // <0 for none (or paste)
  private captureTrackInterval: number;

  showPdfInput = false;
  pdfCustomUrl = '';
  showScreenshotCapture = false;
  showPdfWait = false;
  showPdfWorkbench = false;
  pdfWorkbenchCollapsedMode = false;
  pdfWorkbenchExpandAnyway = false;
  pdfUuidInfo: { uuid: string; url?: string; pages: number; text?: string } | null = null; // PDFs have a URL, screenshots don't; text can be part of screenshots (when directly OCRed)
  pdfWorkbenchPage: number = null; // null = all in one
  pdfWorkbenchImg: string | SafeUrl = null; // data URL
  pdfWorkbenchShowFrames = false;
  pdfWorkbenchFrames: { rect: any; text: string }[] = null;
  pdfWorkbenchFrame: number = null; // null = no frame

  coords: number[] = null;
  selecting = false;
  startCoords: number[] = null;
  endCoords: number[] = null;
  currentSelRect: number[] = null; // x,y,w,h - by requestAnimationFrame below
  addToPrev = false;
  prevText = null;
  lang = ''; // one or more tesseract lang codes (sep by +)
  zoom: '0.25' | '0.5' | '1' | '2' = '1';
  private specialLangs: { key: string; title: string }[] = null;

  @ViewChild('captureBar') captureBarElement: ElementRef;
  @ViewChild('screenshotCapture') screenshotCaptureElement: ElementRef;
  @ViewChild('customUrl') customUrlElement: ElementRef;
  @ViewChild('scrollArea') scrollAreaElement: ElementRef;
  @ViewChild('captureSelection') captureSelectionElement: ElementRef;

  wndw: Window = window; // be able to override in tests
  countries: Value[] = [];

  constructor(
    private readonly svcContributionParse: ContributionParseAPI,
    private svcNameSplit: NameSplitService,
    private readonly sanitizer: DomSanitizer,
    private svcValue: ValueAPI
  ) {}

  ngOnInit(): void {
    this.loadCountries();
    this.lang = this.wndw.localStorage.getItem('capture_pref_lang') || '';
    this.onAnotherEvent();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['event'] &&
      changes['event'].previousValue &&
      changes['event'].currentValue &&
      changes['event'].previousValue.id !== changes['event'].currentValue.id
    ) {
      this.onAnotherEvent();
    }
  }

  private onAnotherEvent(): void {
    const currentCaptureRunning = this.wndw.localStorage.getItem(CAPTURE_RUNNING);
    const currentCaptureEvent = this.wndw.localStorage.getItem(CAPTURE_EVENT_ID);
    if (currentCaptureRunning && this.event.id === currentCaptureEvent) {
      this.captureStarted = true;
      this.captureRunning = true;
      this.monitorCapture();
    } else {
      this.captureStarted = false;
      this.captureRunning = false;
      this.endMonitorCapture();
    }
  }

  ngOnDestroy() {
    this.endMonitorCapture();
  }

  isEnabled() {
    return !!this.wndw.localStorage.getItem('BETATEST2');
  }

  private getCaptureTexts(): (string | { title; name; fullName; wp })[] {
    try {
      return JSON.parse(this.wndw.localStorage.getItem(CAPTURE_TEXTS) || '[]').filter(
        (c) => typeof c === 'object' || (c || '').trim().length > 0
      );
    } catch (e) {
      console.error(e);
      return [];
    }
  }
  private setCaptureTexts(texts: (string | {})[]): void {
    this.wndw.localStorage.setItem(CAPTURE_TEXTS, JSON.stringify(texts));
  }

  onStartCapture() {
    const currentCaptureEvent = this.wndw.localStorage.getItem(CAPTURE_EVENT_ID);
    if (!(!currentCaptureEvent || this.event.id === currentCaptureEvent)) {
      if (this.getCaptureTexts().length > 0) {
        if (
          !this.wndw.confirm(
            'You still have ' +
              this.getCaptureTexts().length +
              ' contributions for ' +
              (this.wndw.localStorage.getItem(CAPTURE_EVENT_NAME) || currentCaptureEvent) +
              '. Are you sure you want to capture here and discard those?'
          )
        ) {
          return;
        } // (else continue)
        this.wndw.localStorage.setItem(CAPTURE_TEXTS, '[]');
      }
    } // (else nfa - shouldn't be here anyway)
    this.wndw.localStorage.setItem(CAPTURE_EVENT_ID, this.event.id);
    this.wndw.localStorage.setItem(CAPTURE_EVENT_NAME, this.event.name);
    this.wndw.localStorage.setItem(CAPTURE_RUNNING, 'true');
    this.captureStarted = true;
    this.captureRunning = true;
    this.currentIndex = -1; // start w/ first, regardless
    this.monitorCapture();
  }

  private monitorCapture() {
    this.endMonitorCapture(); // (prev)
    this.onTrackCapture(); // (first)
    this.captureTrackInterval = this.wndw.setInterval(this.onTrackCapture.bind(this), 1000);
  }
  private endMonitorCapture() {
    if (this.captureTrackInterval) {
      clearInterval(this.captureTrackInterval);
    }
  }

  onEndCapture() {
    this.wndw.localStorage.removeItem(CAPTURE_RUNNING);
    this.captureRunning = false;
    this.onTrackCapture(); // (latest)
    this.endMonitorCapture();
    this.checkAnother(); // emit first immediately
  }

  onTrackCapture() {
    this.capturedCount = this.getCaptureTexts().length;
  }

  hasPrevContrib() {
    return this.currentIndex > 0;
  }
  async onPrevContrib() {
    if (this.hasPrevContrib()) {
      this.currentIndex--;
      const contrib = this.getCaptureTexts()[this.currentIndex];
      if (typeof contrib === 'string') {
        this.hasContribution.emit(await this.lenientContribParse(contrib as string));
      } else {
        this.hasContribution.emit(await this.structContribParse(contrib as any));
      }
    }
  }
  hasNextContrib() {
    return this.getCaptureTexts().length - 1 > this.currentIndex;
  }
  async onNextContrib(force?: boolean) {
    let contrib = null;
    if (this.hasNextContrib()) {
      this.currentIndex++;
      contrib = this.getCaptureTexts()[this.currentIndex];
    } else if (force && this.getCaptureTexts().length > 0) {
      // add the last
      contrib = this.getCaptureTexts()[this.getCaptureTexts().length - 1];
    }
    if (contrib && typeof contrib === 'string') {
      this.hasContribution.emit(await this.lenientContribParse(contrib as string));
    } else if (contrib) {
      this.hasContribution.emit(await this.structContribParse(contrib as any));
    }
  }
  onClear() {
    if (
      !this.wndw.confirm(
        'Sure to clear all ' + this.capturedCount + ' contributions captured (& not yet added) so far?'
      )
    ) {
      return;
    }
    this.currentIndex = -1;
    this.setCaptureTexts([]);
    this.capturedCount = 0;
  }

  onPasteContrib() {
    this.currentIndex = -1;
    this.hasContribution.emit(new Contribution(/* Empty */));
  }

  public currentContribComplete(): boolean {
    if (this.currentIndex >= 0) {
      // currentIndex likely zero - only skip would increase it
      let texts = this.getCaptureTexts();
      texts = [...texts.slice(0, this.currentIndex), ...texts.slice(this.currentIndex + 1)];
      this.setCaptureTexts(texts);
      this.capturedCount--;
      return true;
    } else {
      return false;
    }
  }

  public async triggerReEval(contribution: Contribution) {
    if ((contribution.plainText || '').trim().length < 1) {
      return; // don't call the service w/ empty
    }
    this.hasContribution.emit(
      await this.svcContributionParse.parseContributionFromText(contribution.plainText, contribution).toPromise()
    ); // fail hard
  }
  public async checkAnother() {
    if (this.getCaptureTexts().length > 0) {
      if (this.currentIndex > this.getCaptureTexts().length - 1) {
        this.currentIndex--;
      }
      this.currentIndex = Math.max(this.currentIndex, 0);
      const contrib = this.getCaptureTexts()[this.currentIndex];
      if (typeof contrib === 'string') {
        this.hasContribution.emit(await this.lenientContribParse(contrib as string));
      } else {
        this.hasContribution.emit(await this.structContribParse(contrib as any));
      }
    }
  }

  private async lenientContribParse(text: string, existingContribution?: Contribution): Promise<Contribution> {
    // return an empty contrib w/ just text so one can at least proceed
    try {
      const res = await this.svcContributionParse.parseContributionFromText(text, existingContribution).toPromise();
      return res;
    } catch (_e) {
      console.error(_e);
      const res = existingContribution || new Contribution();
      res.plainText = text;
      res.originalPlainText = res.originalPlainText || text;
      return res;
    }
  }

  private async structContribParse(contrib: { title; name; fullName; wp }): Promise<Contribution> {
    const res = new Contribution();
    if (contrib.title) {
      res.title = contrib.title;
    }
    if (contrib.name) {
      const name = this.svcNameSplit.splitFullName(contrib.name);
      res.person.firstName = name.first;
      res.person.middleName = name.middle;
      res.person.lastName = name.last;
      res.person.sourceName = {
        firstName: name.first,
        middleName: name.middle,
        lastName: name.last,
        sourceStr: contrib.name,
      };
    }
    if (contrib.fullName && ContributionFormComponent.isFullNameVisibleFor(this.event, this.countries)) {
      res.person.fullName = contrib.fullName;
    }
    if (contrib.wp) {
      res.person.workplace = contrib.wp;
    }
    const resLc = (await this.svcContributionParse.smartLcase([res]).toPromise())[0] as Contribution; // (not just partial - we get back all fields we give in)
    return resLc;
  }

  async onPdfFromWebSrc() {
    return this.onPdfGo(this.event.webSource);
  }
  onPdfCustom() {
    this.showScreenshotCapture = false;
    this.showPdfWorkbench = false;
    this.showPdfInput = true;
    this.pdfCustomUrl = '';
    this.wndw.setTimeout(() => this.customUrlElement.nativeElement.focus()); // next render
  }
  async onPdfGo(url) {
    if (!(url || '').startsWith('http')) {
      return;
    }
    this.showPdfInput = false;
    this.showScreenshotCapture = false;
    this.showPdfWorkbench = false;
    this.showPdfWait = true;
    this.pdfUuidInfo = null;
    this.pdfWorkbenchPage = null; // null = all in one
    this.pdfWorkbenchImg = null;
    this.resetSelect();
    try {
      this.pdfUuidInfo = await this.svcContributionParse.pdfOcrUrlToUuid(url).toPromise();
      this.pdfWorkbenchPage = this.pdfUuidInfo.pages > 1 ? 1 : null; // no need for pages when there's only one
      await this.showPage();
      this.showPdfWorkbench = true;
    } finally {
      this.showPdfWait = false;
    }
    this.pdfWorkbenchCollapsedMode = false;
    this.wndw.setTimeout(() => this.captureBarElement.nativeElement.scrollIntoView({ behavior: 'smooth' })); // next render
  }

  private async showPage() {
    this.pdfWorkbenchFrames = null;
    this.pdfWorkbenchFrame = null;
    const prefShowPdfWait = this.showPdfWait;
    this.pdfWorkbenchImg = this.sanitizer.bypassSecurityTrustUrl(
      await this.svcContributionParse
        .pdfOcrGetByUuid(
          this.pdfUuidInfo.uuid,
          this.pdfWorkbenchShowFrames ? 'framespng' : 'png',
          this.pdfWorkbenchPage
        )
        .toPromise()
    );
    this.showPdfWait = prefShowPdfWait;
    setTimeout(() => this.scrollAreaElement.nativeElement.scrollTo(0, 0)); // (next render)
  }

  async onShowFramesChange() {
    this.pdfWorkbenchCollapsedMode = false;
    await this.showPage();
  }
  async onPageChange(delta?: number) {
    if (delta) {
      this.pdfWorkbenchPage = parseInt(this.pdfWorkbenchPage as any, 10) + delta;
    }
    this.pdfWorkbenchCollapsedMode = false;
    this.resetSelect();
    await this.showPage();
  }

  onUpdateCoords(evnt) {
    this.coords = [Math.round(evnt.offsetX), Math.round(evnt.offsetY)];
    if (this.selecting) {
      this.pdfWorkbenchCollapsedMode = false;
      if (evnt.ctrlKey || evnt.metaKey) {
        this.addToPrev = true;
      }
      this.endCoords = this.coords;
      requestAnimationFrame(() => this.showSelect());
    }
  }
  private resetSelect() {
    this.selecting = false;
    this.startCoords = null;
    this.endCoords = null;
    this.currentSelRect = null;
  }
  private showSelect() {
    // do this expicitly (via requestAnimationFrame) for a smooth experience
    this.currentSelRect = [
      Math.min(this.endCoords[0], this.startCoords[0]),
      Math.min(this.endCoords[1], this.startCoords[1]),
      Math.max(this.endCoords[0], this.startCoords[0]) - Math.min(this.endCoords[0], this.startCoords[0]),
      Math.max(this.endCoords[1], this.startCoords[1]) - Math.min(this.endCoords[1], this.startCoords[1]),
    ];
  }
  onStartSelect(evnt) {
    this.selecting = true;
    this.startCoords = this.coords;
    this.endCoords = this.coords;
  }
  @HostListener('document:keydown.escape')
  onCancelSelect() {
    if (this.selecting) {
      this.selecting = false;
      this.resetSelect();
    }
  }
  async onEndSelect(regular = false) {
    this.selecting = false;
    const substSelect =
      this.startCoords &&
      this.endCoords &&
      Math.abs(this.startCoords[0] - this.endCoords[0]) >= 10 &&
      Math.abs(this.startCoords[1] - this.endCoords[1]) >= 10;
    if (regular && substSelect) {
      const add = this.addToPrev;
      this.addToPrev = false;
      let page = null;
      if (this.pdfWorkbenchPage) {
        page = this.pdfWorkbenchPage;
      }
      const zoomFactor = this.getZoomFactor();
      const r = this.currentSelRect.map((c) => c * zoomFactor);
      this.showPdfWait = true;
      let text = null;
      try {
        text = (
          await this.svcContributionParse
            .pdfOcrCoords(this.pdfUuidInfo.uuid, r[0], r[1], r[2], r[3], page, this.lang)
            .toPromise()
        ).text;
      } finally {
        this.showPdfWait = false;
      }
      if (text && text.trim()) {
        if (add && this.prevText) {
          text = this.prevText + text;
        }
        this.prevText = text;
        const contrib = await this.lenientContribParse(text);
        if (this.pdfUuidInfo.url) {
          contrib.pdfUrl = this.pdfUuidInfo.url;
        }
        this.hasContribution.emit(contrib);
        this.wndw.setTimeout(() => {
          this.pdfWorkbenchCollapsedMode = true;
          this.pdfWorkbenchExpandAnyway = false;
        }, 250);
      }
    } else if (!substSelect) {
      this.pdfWorkbenchCollapsedMode = false; // click = stay here
    }
  }

  private getZoomFactor() {
    if ('0.25' === this.zoom) {
      return 8;
    } else if ('0.5' === this.zoom) {
      return 4;
    } else if ('1' === this.zoom) {
      return 2; // once more factor 2 (retina)
    } else if ('2' === this.zoom) {
      return 1;
    } else {
      console.warn('Wrong zoom ' + this.zoom);
      return 1;
    }
  }

  getSpecialLangs(): { key: string; title: string }[] {
    if (!this.specialLangs) {
      try {
        const storedPlain = this.wndw.localStorage.getItem('capture_special_langs');
        const stored = JSON.parse(storedPlain);
        if (Array.isArray(stored)) {
          this.specialLangs = stored.filter((_s) => _s.key && _s.title);
        } else {
          console.error('Invalid special langs: ' + storedPlain);
          this.specialLangs = [];
        }
      } catch (_e) {
        console.error(_e); // but make sure we stay in business
        this.specialLangs = [];
      }
    }
    return this.specialLangs || [];
  }

  onLangChange(newLang: string): void {
    this.pdfWorkbenchCollapsedMode = false;
    this.wndw.localStorage.setItem('capture_pref_lang', newLang);
    this.resetSelect();
  }
  onZoomChange(): void {
    this.pdfWorkbenchCollapsedMode = false;
    this.resetSelect();
  }

  async onWalkFrames() {
    this.showPdfWait = true;
    let page = null;
    if (this.pdfWorkbenchPage) {
      page = this.pdfWorkbenchPage;
    }
    this.pdfWorkbenchCollapsedMode = false;
    this.currentSelRect = null;
    this.pdfWorkbenchFrames = null;
    this.pdfWorkbenchFrame = null;
    try {
      this.pdfWorkbenchFrames = await this.svcContributionParse
        .pdfOcrFrames(this.pdfUuidInfo.uuid, page, this.lang)
        .toPromise();
    } finally {
      this.showPdfWait = false;
    }
    if (this.pdfWorkbenchFrames && this.pdfWorkbenchFrames.length > 0) {
      this.pdfWorkbenchFrames.sort((f1, f2) => {
        const baseX1 = f1.rect[0] - (f1.rect[0] % 100);
        const baseX2 = f2.rect[0] - (f2.rect[0] % 100);
        const y1 = f1.rect[1];
        const y2 = f2.rect[1];
        return 10 * Math.sign(baseX1 - baseX2) + Math.sign(y1 - y2);
      });
      this.pdfWorkbenchFrame = 0;
      this.showFrame();
    }
  }
  async onFrameChange(delta?: number) {
    if (delta) {
      this.pdfWorkbenchFrame = parseInt((this.pdfWorkbenchFrame || 0) as any, 10) + delta;
    }
    this.showFrame();
  }
  private showFrame() {
    if (this.pdfWorkbenchFrames && this.pdfWorkbenchFrames[this.pdfWorkbenchFrame]) {
      this.pdfWorkbenchCollapsedMode = false; // keep it open
      const zoomFactor = this.getZoomFactor();
      this.currentSelRect = this.pdfWorkbenchFrames[this.pdfWorkbenchFrame].rect.map((n) => n / zoomFactor); // we shrink the image, too
      this.wndw.setTimeout(() =>
        this.captureSelectionElement.nativeElement['scrollIntoViewIfNeeded' || 'scrollIntoView']()
      ); // (next render)
      const text = this.pdfWorkbenchFrames[this.pdfWorkbenchFrame].text;
      if (text) {
        (async () => {
          const contrib = await this.lenientContribParse(text);
          if (this.pdfUuidInfo.url) {
            contrib.pdfUrl = this.pdfUuidInfo.url;
          }
          this.hasContribution.emit(contrib);
        })();
      }
    } else {
      this.currentSelRect = null;
    }
  }
  onCloseFrames() {
    this.pdfWorkbenchFrames = null;
    this.pdfWorkbenchFrame = null;
  }

  onScreenshotCapture() {
    this.showPdfInput = false;
    this.showPdfWorkbench = false;
    this.showScreenshotCapture = true;
    this.wndw.setTimeout(() => this.screenshotCaptureElement.nativeElement.focus()); // next render
  }
  @HostListener('window:paste', ['$event'])
  async onScreenshotGo(evnt: any /*PasteEvent*/) {
    if (!this.showScreenshotCapture) {
      return; // don't get it mixed up with normal editing
    }
    const fle: any = Array.from(evnt.clipboardData.files || []).filter((f) =>
      (f as any).type.startsWith('image/png')
    )[0];
    if (fle) {
      this.showScreenshotCapture = false;
      this.showPdfWait = true;
      this.pdfUuidInfo = null;
      this.pdfWorkbenchPage = null; // null = all in one (always for screenshots)
      this.pdfWorkbenchImg = null;
      this.resetSelect();
      try {
        this.pdfUuidInfo = await this.svcContributionParse.pdfOcrPostScreen(fle, this.lang).toPromise();
        await this.showPage();
        this.showPdfWorkbench = true;
      } finally {
        this.showPdfWait = false;
      }
      this.pdfWorkbenchCollapsedMode = false;
      this.wndw.setTimeout(() => this.captureBarElement.nativeElement.scrollIntoView({ behavior: 'smooth' })); // next render
      if (this.pdfUuidInfo.text) {
        // small screnshots are OCRed directly
        this.hasContribution.emit(await this.lenientContribParse(this.pdfUuidInfo.text));
        this.pdfWorkbenchCollapsedMode = true;
        this.pdfWorkbenchExpandAnyway = false;
      }
    }
  }

  arrayRange(to, from = 1) {
    const res = [];
    for (let i = from; i <= to; i++) {
      res.push(i);
    }
    return res;
  }

  loadCountries() {
    this.svcValue.find(ValueType.EventAssociationCountry, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.countries = data;
    });
  }
}
