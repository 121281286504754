import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { ContributorListComponent } from './list/list.component';

import { contributorRouting } from './contributor.routes';
import { ContributorAPI } from './shared/contributor-api.service';
import { BulkListModule } from '../common/bulk-list/bulk-list.module';
import { BulkModule } from '../common/bulk/bulk.module';
import { RegionRestrictGuard } from './shared/guards/region-restrict-guard.service';

@NgModule({
  imports: [SharedModule, BulkListModule, contributorRouting, BulkModule],
  declarations: [ContributorListComponent],
  exports: [ContributorListComponent],
  providers: [ContributorAPI, RegionRestrictGuard],
})
export class ContributorModule {}
