import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ValueNotInListModalComponent } from './shared/modal/not-in-list/not-in-list.component';
import { ValueNotInListModalService } from './shared/modal/not-in-list/not-in-list.service';

@NgModule({
  imports: [SharedModule],
  declarations: [ValueNotInListModalComponent],
  providers: [ValueNotInListModalService],
})
export class ValueModule {}
