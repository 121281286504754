import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { POST, MediaType, Produces, Body } from '../../../shared/services/rest/rest.service';
import { APIService } from '../../../shared/services/api/api.service';
import { TimeTracking } from './time-tracking';

@Injectable()
export class TimeTrackingAPI extends APIService {
  @POST('time-tracking')
  @Produces(MediaType.JSON)
  public postTrackings(@Body data: { trackings: TimeTracking[]; lastTs: Date }): Observable<{ ok: boolean }> {
    return;
  }
}
