import { Affiliation } from '../../affiliation/shared/affiliation';
import { Association } from '../../association/shared/association';
import { DomainSourceType } from './constant/source-type.enum';
import { DomainStatus } from './constant/status.enum';
import { DomainTerms } from './constant/terms.enum';

export class Domain {
  id: string;
  hostname: string;
  example?: string;
  sourceType?: DomainSourceType;
  terms?: DomainTerms;
  prooflink?: string;
  screenshotUrl?: string;
  screenshotPreview?: string;
  status: DomainStatus;
  checkDate?: Date; // When was it last checked by a curator
  frequency?: number; // How many occurrences we have in cLean. nullish = not calculated
  usedForImages?: boolean;
  _meta: { jobId?: string; assignee?: string; claimedUntil?: Date };
  createdAt: Date;
  updatedAt: Date;
  checked?: { at: Date; by: string };
  reviewed?: { at: Date; by: string };
  _version: number;
  address: any = {};
  email?: string;
  requiresChangeApproval?: boolean;
  optOut?: boolean;
  associationIds?: (string | Association)[] = [];
  associationNames?: string[] = [];
  organizationIds?: (string | Affiliation)[] = [];
  organizationNames?: string[] = [];
  pendingUpdates?: Pick<Domain, 'terms'>;
}
