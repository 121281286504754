import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';

import { AssociationAPI } from '../association-api.service';
import { AssociationReach } from '../constant/reach.enum';
import { AssociationStatus } from '../constant/status.enum';
import { UserGroupAPI } from '../../../user-group/shared/api.service';
import { Value } from '../../../shared/services/value/value';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { Utils } from '../../../common/utils';
import { User } from '../../../user/shared/user';

@Component({
  selector: 'dirt-association-filter',
  templateUrl: 'filter.component.html',
  styleUrls: ['filter.component.scss'],
})
export class AssociationFilterComponent implements OnInit {
  visiblePanel: any;
  visibleSubPanel: any;
  filter: any;

  // TYPE
  typeList: Value[] = [];
  selectedTypes: Set<string> = new Set();

  // LOCALITY
  localityList = AssociationReach;
  selectedLocalities: Set<string> = new Set();

  // Category
  categoryList: Value[];
  selectedCategories: Set<string> = new Set();

  // STATUS
  statusList = AssociationStatus;
  selectedStatuses: Set<string> = new Set();

  // THERAPEUTIC AREAS
  therapeuticAreaList: Value[] = [];
  selectedTherapeuticAreas: Set<string> = new Set();

  // AREAS
  areaList: Value[] = [];
  selectedAreas: Set<string> = new Set();

  // PRODUCT
  productList: Value[] = [];
  selectedProducts: Set<any> = new Set();

  // SCOPE
  scopeList: string[] = ['ONCOLOGY', 'NON_ONCOLOGY'];
  selectedScopes: Set<any> = new Set();

  // MAINTENANCE
  selectedMaintenance: boolean;

  // LOCATION
  locationList: string[];
  isLoadingLocations = false;
  selectedLocations: Set<string> = new Set();

  // REGION
  regionList: string[];
  regionLocationMap: Map<string, string[]> = new Map();
  isLoadingRegions = false;
  private selectedRegionList: Set<string> = new Set();

  // VERIFIED
  private selectedVerified: boolean;

  @Output()
  onFilter = new EventEmitter<any>();

  @ViewChild('filterPopover', { static: true })
  filterPopover: NgbPopover;

  countryValues: Value[] = [];

  constructor(private svcAssociation: AssociationAPI, private svcUserGroup: UserGroupAPI, private svcValue: ValueAPI) {}

  ngOnInit() {
    this.svcValue.find(ValueType.Project, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.projectsList = data;
    });

    this.svcValue.find(ValueType.Area, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.areaList = data;
    });

    this.svcValue.find(ValueType.Product, Number.MAX_SAFE_INTEGER, 0).subscribe((data) => {
      this.productList = data;
    });

    this.svcValue.find(ValueType.TherapeuticArea, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.therapeuticAreaList = data;
    });

    this.svcValue.find(ValueType.Category, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.categoryList = data;
    });

    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.countryValues = data;
    });

    this.svcValue.find(ValueType.AssociationType, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.typeList = data;
    });

    this.initAssociationCreators();
  }

  getCountryTitle(code: string): string {
    return (this.countryValues.find((o) => o.code === code) || { title: '' }).title;
  }

  onShow(): void {
    // reset panel visibility status
    this.visiblePanel = false;
  }

  setPanel(panel?: string): void {
    this.visiblePanel = panel;

    if (panel) {
      // uppercase first letter
      const name = panel.charAt(0).toUpperCase() + panel.slice(1).toLowerCase();
      const initMethod = `init${name}`;
      if (this[initMethod]) {
        this[initMethod]();
      }
    }
  }

  doApply(): void {
    this.filter = {
      type: Array.from(this.selectedTypes),
      reach: Array.from(this.selectedLocalities),
      category: Array.from(this.selectedCategories),
      therapeuticAreas: Array.from(this.selectedTherapeuticAreas),
      areas: Array.from(this.selectedAreas),
      products: Array.from(this.selectedProducts),
      scope: Array.from(this.selectedScopes),
      projectNames: Array.from(this.selectedProjects),
      '_meta.status': Array.from(this.selectedStatuses),
      '_meta.maintenance': this.selectedMaintenance ? [true] : [],
      '_meta.assignee': Array.from(this.selectedCompilers),
      'address.countryCode': this.getSelectedLocations(),
      verified: this.selectedVerified,
      idVerified: Object.keys(this.selectedIdVerified).length ? JSON.stringify(this.selectedIdVerified) : undefined,
      reviewedAt: Object.keys(this.selectedReviewDate).filter((k) => !!this.selectedReviewDate[k]).length
        ? this.selectedReviewDate
        : undefined,
      createdAt: Object.keys(this.selectedCreatedDate).filter((k) => !!this.selectedCreatedDate[k]).length
        ? this.selectedCreatedDate
        : undefined,
      'identify.by': Array.from(this.selectedCreators),
    };

    this.filterPopover.close();
    this.onFilter.emit(this.filter);
  }

  doClear(): void {
    this.selectedTypes.clear();
    this.selectedLocalities.clear();
    this.selectedCategories.clear();
    this.selectedStatuses.clear();
    this.selectedTherapeuticAreas.clear();
    this.selectedAreas.clear();
    this.selectedProducts.clear();
    this.selectedScopes.clear();
    this.selectedRegionList.clear();
    this.selectedLocations.clear();
    this.selectedProjects.clear();
    this.selectedCreators.clear();
    this.selectedCompilers.clear();
    this.selectedReviewDate = {};
    this.selectedCreatedDate = {};
    this.selectedIdVerified = {};
    delete this.selectedMaintenance;
    delete this.selectedVerified;

    delete this.filter;
    this.onFilter.emit();
    this.filterPopover.close();
  }

  doCancel(): void {
    this.filterPopover.close();
    this.setPanel();
  }

  getSelectedLocations(): Array<string> {
    const locations: Set<string> = new Set(this.selectedLocations);
    this.selectedRegionList.forEach((region) => {
      this.regionLocationMap.get(region).forEach((l) => locations.add(l));
    });
    return Array.from(locations);
  }

  getSelectedRegionCountries(): string[] {
    const countries = [];
    this.selectedRegionList.forEach((region) => {
      this.regionLocationMap.get(region).forEach((o) => countries.push(o));
    });
    return countries;
  }

  toggleType(type: string): void {
    if (this.selectedTypes.has(type)) {
      this.selectedTypes.delete(type);
    } else {
      this.selectedTypes.add(type);
    }
  }

  isTypeSelected(type: string): boolean {
    return this.selectedTypes.has(type);
  }

  toggleLocality(locality: string): void {
    if (this.selectedLocalities.has(locality)) {
      this.selectedLocalities.delete(locality);
    } else {
      this.selectedLocalities.add(locality);
    }
  }

  isLocalitySelected(locality: string): boolean {
    return this.selectedLocalities.has(locality);
  }

  toggleCategory(category: string): void {
    if (this.selectedCategories.has(category)) {
      this.selectedCategories.delete(category);
    } else {
      this.selectedCategories.add(category);
    }
  }

  isCategorySelected(category: string): boolean {
    return this.selectedCategories.has(category);
  }

  toggleStatus(status: string): void {
    if (this.selectedStatuses.has(status)) {
      this.selectedStatuses.delete(status);
    } else {
      this.selectedStatuses.add(status);
    }
  }

  isStatusSelected(status: string): boolean {
    return this.selectedStatuses.has(status);
  }

  toggleTherapeuticAreas(status: string): void {
    if (this.selectedTherapeuticAreas.has(status)) {
      this.selectedTherapeuticAreas.delete(status);
    } else {
      this.selectedTherapeuticAreas.add(status);
    }
  }

  isTherapeuticAreasSelected(status: string): boolean {
    return this.selectedTherapeuticAreas.has(status);
  }

  toggleAreas(status: string): void {
    if (this.selectedAreas.has(status)) {
      this.selectedAreas.delete(status);
    } else {
      this.selectedAreas.add(status);
    }
  }

  isAreasSelected(status: string): boolean {
    return this.selectedAreas.has(status);
  }

  toggleProducts(status: string): void {
    if (this.selectedProducts.has(status)) {
      this.selectedProducts.delete(status);
    } else {
      this.selectedProducts.add(status);
    }
  }

  isProductSelected(status: string): boolean {
    return this.selectedProducts.has(status);
  }

  toggleScope(scope: string): void {
    const val = scope === 'ONCOLOGY' ? 'ONCOLOGY' : null;
    if (this.selectedScopes.has(val)) {
      this.selectedScopes.delete(val);
    } else {
      this.selectedScopes.add(val);
    }
  }

  isScopeSelected(scope: string): boolean {
    const val = scope === 'ONCOLOGY' ? 'ONCOLOGY' : null;
    return this.selectedScopes.has(val);
  }

  toggleMaintenance(): void {
    if (this.selectedMaintenance) {
      delete this.selectedMaintenance;
    } else {
      this.selectedMaintenance = true;
    }
  }

  initLocation(): void {
    if (this.locationList) {
      return;
    }

    this.isLoadingLocations = true;
    Promise.all([
      this.svcAssociation
        .distinct('address.countryCode', { 'address.countryCode': this.getSelectedRegionCountries() })
        .toPromise(),
      this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').toPromise(),
    ])
      .then(([countryCodes, countries]) => {
        this.locationList = Utils.sortByCountry(
          countryCodes,
          countries.reduce((prev, curr) => ({ ...prev, [curr.code]: curr.title }), {})
        );
        this.isLoadingLocations = false;
      })
      .catch(() => {
        this.isLoadingLocations = false;
      });
  }

  toggleLocation(loc: string): void {
    if (this.selectedLocations.has(loc)) {
      this.selectedLocations.delete(loc);
    } else {
      this.selectedLocations.add(loc);
    }
  }

  isLocationSelected(loc: string): boolean {
    return this.selectedLocations.has(loc);
  }

  initRegion(): void {
    if (this.regionList) {
      return;
    }

    this.isLoadingRegions = true;

    this.svcUserGroup.find(Number.MAX_SAFE_INTEGER).subscribe(
      (resp) => {
        this.regionList = resp.map((u) => u.name).sort();
        resp.forEach((u) => this.regionLocationMap.set(u.name, u.countries));
      },
      null,
      () => (this.isLoadingRegions = false)
    );
  }

  toggleRegion(region: string): void {
    if (this.selectedRegionList.has(region)) {
      this.selectedRegionList.delete(region);
    } else {
      this.selectedRegionList.add(region);
    }

    this.locationList = null;
  }

  isRegionSelected(region: string): boolean {
    return this.selectedRegionList.has(region);
  }

  // VERIFIED
  toggleVerified(verified: boolean): void {
    if (typeof this.selectedVerified === 'undefined') {
      this.selectedVerified = verified;
    } else if (this.selectedVerified !== verified) {
      this.selectedVerified = verified;
    }
  }

  // PROJECTS
  projectsList: Value[] = [];
  private selectedProjects: Set<string> = new Set();

  toggleProjects(status: string): void {
    if (this.selectedProjects.has(status)) {
      this.selectedProjects.delete(status);
    } else {
      this.selectedProjects.add(status);
    }
  }

  isProjectSelected(value: string): boolean {
    return this.selectedProjects.has(value);
  }

  isVerifiedSelected(verified: boolean): boolean {
    return this.selectedVerified === verified;
  }

  // REVIEWERS
  compilersList: User[];
  isLoadingCompilers = false;
  private selectedCompilers: Set<string> = new Set();

  initCompilers(): void {
    if (this.compilersList) {
      return;
    }

    this.isLoadingCompilers = true;
    this.svcAssociation
      .reviewers()
      .pipe(tap(() => (this.isLoadingCompilers = false)))
      .subscribe((resp) => (this.compilersList = resp));
  }

  toggleReviewedBy(reviewedBy: string, $event: MouseEvent): void {
    $event.preventDefault();

    if (this.selectedCompilers.has(reviewedBy)) {
      this.selectedCompilers.delete(reviewedBy);
    } else {
      this.selectedCompilers.add(reviewedBy);
    }
  }

  isReviewedBySelected(reviewedBy: string): boolean {
    return this.selectedCompilers.has(reviewedBy);
  }

  // ReviewDate
  selectedReviewDate: { start?: Date; end?: Date } = {};

  setReviewDate(start: Date, end: Date) {
    if ((start && start.getFullYear() < 1900) || (end && end.getFullYear() < 1900)) {
      return; // incomplete input
    }
    if (start && !end) {
      end = start;
    }

    this.selectedReviewDate = { start, end };
  }

  // CreatedDate
  selectedCreatedDate: { start?: Date; end?: Date } = {};

  setCreatedDate(start: Date, end: Date) {
    if ((start && start.getFullYear() < 1900) || (end && end.getFullYear() < 1900)) {
      return; // incomplete input
    }
    if (start && !end) {
      end = start;
    }

    this.selectedCreatedDate = { start, end };
  }

  // ID VERIFIED
  idVerifiedOptions = [
    { key: 'ID Verified', value: true },
    { key: 'ID UN-Verified', value: false },
  ];
  idVerifiedSubOptions = [
    { key: 'Correct', value: true },
    { key: 'Incorrect', value: false },
  ];

  private selectedIdVerified: { idVerified?: boolean; correct?: boolean } = {};

  setSubPanel(subPanel?: string): void {
    this.visibleSubPanel = subPanel;
  }

  isIdVerifiedValueSelected(idVerified: boolean): boolean {
    return this.selectedIdVerified?.idVerified === idVerified;
  }

  isIdVerifiedSubValueSelected(subValue: boolean): boolean {
    return this.selectedIdVerified?.correct === subValue;
  }

  toggleIdVerifiedValue(idVerified: boolean, key: string) {
    if (this.selectedIdVerified.idVerified === idVerified) {
      this.selectedIdVerified = {};
      return;
    }

    this.selectedIdVerified = {};
    this.selectedIdVerified.idVerified = idVerified;

    if (idVerified) {
      this.setSubPanel('idVerified');
    } else {
      delete this.selectedIdVerified.correct;
    }
  }

  toggleIdVerifiedSubValue(correct?: boolean) {
    if (this.selectedIdVerified.correct === correct) {
      delete this.selectedIdVerified.correct;
      return;
    }
    this.selectedIdVerified.correct = correct;
  }

  // CREATED BY
  creatorsList: User[];
  isLoadingCreators = false;
  private selectedCreators: Set<string> = new Set();

  initAssociationCreators(): void {
    if (this.creatorsList) {
      return;
    }

    this.isLoadingCreators = true;
    this.svcAssociation
      .creators()
      .pipe(tap(() => (this.isLoadingCreators = false)))
      .subscribe((resp) => (this.creatorsList = resp));
  }

  toggleCreatedBy(createdBy: string, $event: MouseEvent): void {
    $event.preventDefault();

    if (this.selectedCreators.has(createdBy)) {
      this.selectedCreators.delete(createdBy);
    } else {
      this.selectedCreators.add(createdBy);
    }
  }

  isCreatedBySelected(createdBy: string): boolean {
    return this.selectedCreators.has(createdBy);
  }
}
