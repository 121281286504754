import { Injectable } from '@angular/core';
import { firstValueFrom, from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PUT, Path, Body, MediaType, Produces, PATCH, POST } from '../../../../shared/services/rest/rest.service';
import { SubentityApiService } from '../../common/subentity-api.service';
import { ProfileMembership } from '../../../shared/profile-membership';
import { Profile } from '../../../shared/profile';
import { ACL } from '../../../../shared/acl/acl.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { PersonAPI } from '../../../../person/shared/api.service';
import { filterStream, parseJSONStream, splitStream } from '../../stream.utils';
import { Utils } from '../../../../common/utils';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { Roles } from '../../../../shared/acl/roles';

type ProfileMembershipRaw = {
  person_id: string;
  association_id: string;
  match_result: string;
  match_source?: string;
  fas?: string; // split by @
  fas_ids?: string; // split by @
  ml_exclude?: 't' | 'f' | true | false;
};

@Injectable()
export class ProfileMembershipAPI extends SubentityApiService<
  ProfileMembership,
  { id; _profile: ProfileMembership },
  ProfileMembership[],
  {} // nothing beyond our IDs
> {
  constructor(
    http: HttpClient,
    svcModal: NgbModal,
    svcAcl: ACL,
    svcJwtHelper: JwtHelperService,
    private svcPerson: PersonAPI
  ) {
    super(http, svcModal, svcAcl, svcJwtHelper);
  }

  public findAll(profile: Profile): Observable<ProfileMembership[]> {
    return from(this.findAllAutomated(profile));
  }

  @POST('memberships/base-info')
  @Produces(MediaType.JSON)
  public getMembershipBaseInfo(@Body membershipIds: string[]): Observable<
    {
      membershipId;
      firstName;
      middleName;
      lastName;
      fullName;
      workplace;
      isPastPosition;
      position;
      association: { associationName; subAssociationName; webSource; locality; address: { city; country } };
    }[]
  > {
    return;
  }

  protected async enrichAll(
    profile: Profile,
    allList: ProfileMembership[]
  ): Promise<{ id; _profile: ProfileMembership }[]> {
    // also need curated - which may or may not match (so we get a longer list)
    // getUniqueIdent works there as well
    const allCurated: ProfileMembership[] = await this.findAllCurated(profile);
    const allCuratedMap = {};
    const allCuratedMatchedMap = {};
    allCurated.forEach((item) => {
      allCuratedMap[item.id] = item;
    });
    allList.forEach((item) => {
      const curated = allCuratedMap[item.id];
      if (curated) {
        allCuratedMatchedMap[item.id] = true;
        item.mongoId = curated.mongoId;
        if (curated.match) {
          item.match.manual = curated.match.manual;
          item.match.pendingSubmission = curated.match.pendingSubmission;
        }
        item.compiledAt = curated.compiledAt;
        item.qc = curated.qc;
      }
    });
    // everything that is curated as MATCH or MISMATCH is shown, no matter whether we still have it in KIP
    const remainingCurated = allCurated.filter(
      (item) =>
        !allCuratedMatchedMap[item.id] &&
        (ProfileMatchManual.MATCH === item.match?.manual || ProfileMatchManual.MISMATCH === item.match?.manual)
    );
    let completeAllList = [...allList, ...remainingCurated];

    // Given we don't have manual info in ensureLoaded before calling enrichAll, we need to filter what's already matched here for compilers.
    // Will need to do exactly the same thing for the other sources when progressively switching them to outbox.

    // for compilers outside training and (usual) view mode delta, we could speed up (ViewModeFilter will _always_ filter out the same ones) - load way less base-info etc around the globe:
    if (
      this.svcAcl.userRoles?.includes(Roles.ProfileCompiler) &&
      !profile.training &&
      profile.viewMode?.endsWith('_DELTA')
    ) {
      completeAllList = completeAllList.filter(
        (item) => item.match?.manual === 'NONE' || item.match?.pendingSubmission
      );
    }

    // load members
    const memsMap = {};
    const PAGE_SIZE = 2500;
    for (let i = 0; i < completeAllList.length; i += PAGE_SIZE) {
      const memsList = await firstValueFrom(
        this.getMembershipBaseInfo(completeAllList.slice(i, i + PAGE_SIZE).map((p) => p.membershipId))
      );
      memsList.forEach((c) => (memsMap[c.membershipId] = c));
    }
    // do transform into the format we used - from here fw, we should not need any change
    const transformed = completeAllList
      .filter((c) => !!memsMap[c.membershipId])
      .map((c) => ({
        // (pretty simple transform if you break it down)
        ...memsMap[c.membershipId], // (first/last/full name, assoc, etc.)
        id: c.id, // replace w/ mongo ID when curated
        mongoId: c.mongoId,
        membershipId: c.membershipId,
        _profile: {
          // (the match as such + first author from pub)
          ...c,
          match: {
            ...c.match,
            mlAnyway:
              Math.abs(Utils.hash((c as any).person?.kolId + '' + (c.membershipId || 'null').toString()) % 100) < 1,
          },
          focusAreas: c.focusAreas?.map((fa) => (typeof fa === 'string' ? fa : (fa as any).name)),
          //focusAreaIds: not needed (no asia filter)
        },
      }));
    return transformed;
  }

  protected async loadAndEnrichOrigins(
    profileId: string,
    kolId: string
  ): Promise<{ id; _profile: ProfileMembership }[]> {
    const person = await firstValueFrom(this.svcPerson.findById(kolId));
    const membershipIds: string[] = (person as any)?.additionalDetails?.automatedProfileIds?.membershipIds || [];
    if (membershipIds.length < 1) {
      return [];
    }

    // load memberships
    const membershipsList = await firstValueFrom(this.getMembershipBaseInfo(membershipIds));
    const membershipsMap = {};
    membershipsList.forEach((c) => (membershipsMap[c.membershipId] = c));

    // do transform into the format we used - from here fw, we should not need any change
    const transformed = membershipIds.map((membershipId) => ({
      // (pretty simple transform if you break it down)
      ...membershipsMap[membershipId], // (journal, abstract, etc.)
      id: null,
      membershipId: membershipId,
      _profile: {
        // (the match as such)
        match: { automate: 'PB' },
        focusAreas: [],
      },
    }));

    return transformed;
  }

  @PATCH('profiles/{id}/memberships')
  @Produces(MediaType.JSON)
  public updateImpl(@Path('id') id: string, @Body matches: ProfileMembership[]): Observable<ProfileMembership[]> {
    return null;
  } // match API

  @PUT('profiles/{id}/memberships/rel/{fk}')
  @Produces(MediaType.JSON)
  public linkImpl(@Path('id') id: string, @Path('fk') membershipId: string): Observable<ProfileMembership> {
    return null;
  }

  private async findAllAutomated(profile: Profile): Promise<ProfileMembership[]> {
    const options: RequestInit = { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } };
    const response: Response = await fetch(
      this.getBaseUrl() + 'profiles/' + profile.id + '/memberships/automated',
      options
    ); // (we want a stream now)
    if (!response.ok) {
      throw new Error('Failed to download automated matches');
    }

    // For automated matches, we need to get the base profile matches, the clone will not have anything under its name.
    const identifier = profile.training ? profile.baseProfileKolId : profile.person.kolId;
    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(splitStream('\n'))
      .pipeThrough(filterStream((row: string) => row.includes(identifier))) // save us some JSON parsing
      .pipeThrough(parseJSONStream())
      .pipeThrough(filterStream((row: ProfileMembershipRaw) => row.person_id === identifier)) // Catch the very few cases that we could leave out
      .pipeThrough(transformToMatch())
      .getReader();

    const matches: ProfileMembership[] = [];

    while (true) {
      const result = await reader.read(); // Leave timeout to Nginx

      if (!result) {
        // Nothing in the stream
        break;
      }

      if (result.done) {
        break; // Everything was read
      }

      matches.push(result.value);
    }

    // We're done
    reader.cancel();

    return matches;
  }

  private async findAllCurated(profile: Profile): Promise<ProfileMembership[]> {
    const options: RequestInit = { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } };
    const response: Response = await fetch(
      this.getBaseUrl() + 'profiles/' + profile.id + '/memberships/curated',
      options
    ); // (we want a stream now)
    if (!response.ok) {
      throw new Error('Failed to download curated matches');
    }

    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(splitStream('\n'))
      .pipeThrough(parseJSONStream())
      .getReader();

    const matches: ProfileMembership[] = [];

    while (true) {
      const result = await reader.read(); // Leave timeout to Nginx

      if (!result) {
        // Nothing in the stream
        break;
      }

      if (result.done) {
        break; // Everything was read
      }

      this.postProcessFromMongo(result.value);
      matches.push(result.value);
    }

    // We're done
    reader.cancel();

    return matches;
  }

  protected postProcessFromMongo(item: ProfileMembership) {
    // also what is coming back from updates
    item.mongoId = item.id;
    item.id = 't-' + Utils.hash(getUniqueIdent(item));
  }
}

function getUniqueIdent(item: ProfileMembership): any {
  return [
    // item.person?.kolId, // we already work with a single KP, so having KOL ID here is redundant and detrimental when we want to merge base profile auto matches with cloned profile manual matches.
    item.membershipId,
  ]
    .map((s) => s || '')
    .join('|'); // had the same previously in backend as buildFilter (same fields)
}

function mapRawToModel(chunk: ProfileMembershipRaw): ProfileMembership {
  const fas = (chunk.fas || '').split('@').filter((s) => !!s);
  const faIds = (chunk.fas_ids || '').split('@').filter((s) => !!s);

  const match: ProfileMembership = {
    id: null, // (filled below)
    membershipId: chunk.association_id,
    person: { kolId: chunk.person_id },
    match: {
      automate: chunk.match_result,
      manual: ProfileMatchManual.NONE, // Will enrich via curated matches
      matchSource: chunk.match_source,
      mlExclude: 't' === chunk.ml_exclude || true === chunk.ml_exclude,
    },
    focusAreas: fas.map((fa, i) => ({ name: fa, id: faIds[i] })),

    // filled later
    firstName: null,
    middleName: null,
    lastName: null,
    fullName: null,
    workplace: null,
    position: null,
    isPastPosition: null,
    association: {
      associationName: null,
      subAssociationName: null,
      webSource: null,
      locality: null,
      address: {
        city: null,
        country: null,
      },
    },
  };

  match.id = 't-' + Utils.hash(getUniqueIdent(match));
  return match;
}

function transformToMatch() {
  return new TransformStream({
    transform(chunk: ProfileMembershipRaw, controller) {
      const match = mapRawToModel(chunk);
      controller.enqueue(match);
    },
  });
}
