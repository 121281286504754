import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl } from '@angular/forms';

const REQUIRED_OR_NULL_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => RequiredOrNullValidator),
  multi: true,
};

@Directive({
  selector: '[ngModel][requiredOrNull]',
  providers: [REQUIRED_OR_NULL_VALIDATOR],
})
export class RequiredOrNullValidator implements Validator {
  validate(control: AbstractControl): { [key: string]: any } | null {
    if (null === control.value) {
      return null;
    }
    return Validators.required(control);
  }
}
