import { Directive, ElementRef, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[diScroller]',
})
export class DirtScrollerDirective implements OnInit {
  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    const el: HTMLElement = this.elRef.nativeElement;

    this.renderer.listen(el, 'wheel', (e: WheelEvent) => {
      const delta = e['wheelDelta'];
      const height = el.scrollHeight - el.offsetHeight;
      const stop = (delta > 0 && el.scrollTop === 0) || (delta < 0 && el.scrollTop === height);

      if (stop) {
        e.preventDefault();
      }
    });
  }
}
