import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'dirt-capture-bookmarklet',
  templateUrl: './capture-bookmarklet.component.html',
  styleUrls: ['./capture-bookmarklet.component.scss'],
})
export class CaptureBookmarkletComponent implements OnInit {
  bookmarkletUrl: SafeUrl;
  bookmarkletUrlInlinebar: SafeUrl;
  mth: string;

  wndw: Window = window; // be able to override in tests

  constructor(sanitizer: DomSanitizer) {
    this.bookmarkletUrl = sanitizer.bypassSecurityTrustUrl(
      "javascript:window.open('" +
        location.href.substring(0, location.href.indexOf('/', 9)) +
        "/assets/capture.html#' + encodeURIComponent(document.getSelection().toString()), null, 'toobar=no,width=450,height=380,left=500,top=200,alwaysRaised=yes')"
    );
    this.bookmarkletUrlInlinebar = sanitizer.bypassSecurityTrustUrl(
      'javascript:document.body.insertBefore(document.createElement(\'div\'), document.body.firstChild).innerHTML=\'<div style="position: relative; "><div style="position: fixed; right: 0; top: 0; width: 300px; height: 100px; z-index: 1000000; background: white; "><iframe style="width: 100%; height: 100%; " src="' +
        location.href.substring(0, location.href.indexOf('/', 9)) +
        "/assets/capture-inlinebar.html\" id=\"__clean_capture_inlinebar\"></iframe></div></div>'; window.addEventListener('keydown', function(evnt) {if(evnt.metaKey ||evnt.ctrlKey){if(evnt.key==='1'){evnt.preventDefault(); document.getElementById('__clean_capture_inlinebar').contentWindow.postMessage(JSON.stringify({to: 'title', text: document.getSelection().toString()}), '*'); } else if(evnt.key==='2'){evnt.preventDefault(); document.getElementById('__clean_capture_inlinebar').contentWindow.postMessage(JSON.stringify({to: 'name', text: document.getSelection().toString()}), '*'); } else if(evnt.key==='3'){evnt.preventDefault(); document.getElementById('__clean_capture_inlinebar').contentWindow.postMessage(JSON.stringify({to: 'wp', text: document.getSelection().toString()}), '*'); } else if(evnt.key==='4'){evnt.preventDefault(); document.getElementById('__clean_capture_inlinebar').contentWindow.postMessage(JSON.stringify({to: 'fullName', text: document.getSelection().toString()}), '*'); } else if(evnt.key==='5'){evnt.preventDefault(); document.getElementById('__clean_capture_inlinebar').contentWindow.postMessage(JSON.stringify({submit: true}), '*'); }}}); "
    );
  }

  ngOnInit(): void {
    this.mth = this.wndw.localStorage.getItem('capture_pref_mth') || '';
  }

  onMthChange(newMth: string): void {
    this.wndw.localStorage.setItem('capture_pref_mth', newMth);
  }

  isExtendedTestEnabled() {
    return !!this.wndw.localStorage.getItem('BETATEST2b');
  }
}
