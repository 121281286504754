import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { MaintenanceMode } from '../../enum/maintenance-mode.enum';

@Component({
  selector: 'dirt-maintenance-mode',
  templateUrl: './maintenance-mode.component.html',
  styleUrls: ['./maintenance-mode.component.scss'],
})
export class MaintenanceModeComponent implements OnInit {
  @Input()
  mode: MaintenanceMode = null;

  @Input()
  period = 0;

  @Output()
  maintenanceChange = new EventEmitter<{ mode: MaintenanceMode; period: number }>();

  maintenanceModes = MaintenanceMode;

  selectedMode: string;
  selectedPeriod: number;

  maxPeriod = 0;

  modalRef: NgbModalRef;

  constructor(private svcModal: NgbModal) {}

  updatePeriod() {
    switch (MaintenanceMode[this.selectedMode]) {
      case MaintenanceMode.WEEK:
        this.maxPeriod = 4;
        break;
      case MaintenanceMode.MONTH:
        this.maxPeriod = 12;
        break;
      default:
        this.maxPeriod = 0;
    }

    if (this.selectedPeriod > this.maxPeriod) {
      this.selectedPeriod = 0;
    }
  }

  ngOnInit() {}

  doOpenModal(content) {
    this.selectedMode = MaintenanceMode[this.mode] || null;
    this.selectedPeriod = this.period || 0;
    this.updatePeriod();
    this.modalRef = this.svcModal.open(content);
  }

  onModeChange() {
    this.updatePeriod();
  }

  onSave() {
    this.maintenanceChange.emit({
      mode: MaintenanceMode[this.selectedMode] || null,
      period: this.selectedPeriod || null,
    });

    this.modalRef.close();
  }
}
