import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { ContributionComponent } from './contribution.component';
import { ContributionListComponent } from './list/list.component';
import { ContributionDetailComponent } from './detail/detail.component';
import { ContributionCreateComponent } from './create/create.component';
import { ContributionFormComponent } from './shared/form/form.component';

import { ContributionCaptureModule } from '../contribution-capture/contribution-capture.module';
import { contributionRouting } from './contribution.routes';
import { ContributionAPI } from './shared/contribution-api.service';
import { MoveContributionComponent } from './shared/move-contribution-modal/move-contribution.component';
import { MoveContributionService } from './shared/move-contribution-modal/move-contribution.service';

@NgModule({
  imports: [SharedModule, ContributionCaptureModule, contributionRouting],
  declarations: [
    ContributionComponent,
    ContributionListComponent,
    ContributionDetailComponent,
    ContributionCreateComponent,
    ContributionFormComponent,
    MoveContributionComponent,
  ],
  exports: [ContributionListComponent],
  providers: [ContributionAPI, MoveContributionService],
})
export class ContributionModule {}
