import {
  Component,
  Input,
  OnInit,
  ViewChild,
  AfterViewChecked,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

import { ACL } from '../../../shared/acl/acl.service';
import { Membership } from '../membership';
import { MembershipPosition } from '../constant/position.enum';
import { AssociationObsolete } from '../../../association/shared/constant/obsolete.enum';
import { YearpickerComponent } from '../../../shared/components';
import { NameSplitService } from '../../../shared/services/name-split/name-split.service';
import { Auth } from '../../../shared/services/auth/auth.service';
import { Utils } from '../../../common/utils';
import { Roles } from '../../../shared/acl/roles';
import { Value } from '../../../shared/services/value/value';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';

@Component({
  selector: 'dirt-membership-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
  exportAs: 'frmMembership',
})
export class MembershipFormComponent implements OnInit, AfterViewChecked {
  @Input()
  isSubmitting: boolean;

  @Input('isSaving')
  isSaving = false;

  positions = MembershipPosition;
  suffixes: Value[] = [];
  valid: boolean;
  isReadOnly: boolean;

  @Input('membership')
  model: Membership = new Membership();

  @Output()
  onSubmitted = new EventEmitter<Membership>();

  @Output()
  onSubmittedAndNew = new EventEmitter<Membership>();

  @Output()
  onCancel = new EventEmitter();

  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  @ViewChild('startYear', { static: false })
  startYear: YearpickerComponent;

  @ViewChild('endYear', { static: false })
  endYear: YearpickerComponent;

  @ViewChild('lastName', { static: false }) // allows setting full name required as soon as poss
  lastName: NgModel;

  longDash = Utils.longDash;

  shouldDisplayVerifiedControls: boolean;

  get isEditMode() {
    return this.model && !!this.model.id;
  }

  constructor(
    private svcAcl: ACL,
    private svcNameSplit: NameSplitService,
    public auth: Auth,
    private svcValue: ValueAPI
  ) {}

  ngOnInit(): void {
    this.isReadOnly = (this.model.id && !this.svcAcl.hasCredential('membership.update')) || this.isObsolete();

    this.shouldDisplayVerifiedControls =
      !this.svcAcl.hasRole(Roles.EventCompiler) && !this.svcAcl.hasRole(Roles.AssociationCompiler);
    if (!this.model.position) {
      this.model.position = this.positions.MEMBER;
    }

    this.svcValue.find(ValueType.PersonSuffix, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.suffixes = data;
    });
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      this.valid = this.ngForm.form.valid;
    }, 1000);
  }

  onStartYearSelected(): void {
    const startYear = this.startYear.getYear();
    const endYear = this.endYear.getYear();
    if (startYear && endYear && startYear > endYear) {
      this.startYear.setYear(endYear);
    }
  }

  onEndedChange(value) {
    this.model.ended = value;
  }

  onReviewedChange(value) {
    this.model.reviewed = value;
  }

  onVerifiedChange(value) {
    this.model.verified = value;
  }

  isObsolete(): boolean {
    if (!this.model.id) {
      return false;
    }

    return AssociationObsolete.YES === this.model.association.obsolete;
  }

  @HostListener('document:keydown.control.x')
  @HostListener('document:keydown.meta.x')
  onClipboardPaste() {
    (navigator as any).clipboard
      .readText()
      .then((text) => {
        const name = this.svcNameSplit.splitFullName(text);
        const { member } = this.model;
        member.firstName = name.first;
        member.middleName = name.middle;
        member.lastName = name.last;
      })
      .catch(() => window.alert('You have to grant access to read from your clipboard!'));
  }

  onSubmitAndNew() {
    this.completeModel();
    this.onSubmittedAndNew.emit(this.model);
  }

  onSubmit(val): void {
    this.completeModel();
    this.onSubmitted.emit(this.model);
  }

  private completeModel() {
    this.model.startYear = this.startYear.getYear();
    this.model.endYear = this.endYear.getYear();
  }

  onCancelClick() {
    this.onCancel.emit();
  }
}
