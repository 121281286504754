<section class="di-linked-people" *ngIf="!showLastNamesOnly && (!isLoading || !!profiles.length || !!people.length)">
  <div class="card">
    <div class="card-body">
      <h6 class="card-title" *ngIf="!!profiles.length">Linked People</h6>

      <ol *ngIf="!!profiles.length">
        <li *ngFor="let profile of profiles">
          <!-- Name -->
          <a
            *ngIf="profile?.profileId"
            [routerLink]="['/profile/detail', profile.profileId.id]"
            rel="noopener"
            target="_blank"
            >{{ profile?.profileId?.person?.firstName }} {{ profile?.profileId?.person?.lastName }}</a
          >

          <!-- Full Name -->
          <span *ngIf="profile?.profileId?.person?.originalFullName"
            >({{ profile?.profileId?.person?.originalFullName }})</span
          >

          <a class="removeLink" *ngIf="showRemove" (click)="onRemove.emit({ type: 'PROFILE', personProfile: profile })"
            >remove</a
          >
        </li>
      </ol>

      <h6 class="card-title" *ngIf="!!people.length">Identified People</h6>

      <ol *ngIf="!!people.length">
        <li *ngFor="let person of people">
          <!-- Name -->
          <a [routerLink]="['/person/detail', person.id]" rel="noopener" target="_blank"
            >{{ person.firstName }} {{ person.lastName }}</a
          >

          <!-- Full Name -->
          <span *ngIf="person.originalFullName">({{ person.originalFullName }})</span>

          <a class="removeLink" *ngIf="showRemove" (click)="onRemove.emit({ type: 'PERSON', personProfile: person })"
            >remove</a
          >
        </li>
      </ol>
      <div *ngIf="!isLoading && !people.length && !profiles.length">No Linked People</div>
    </div>
  </div>
</section>

<!-- Loading Message -->
<p class="dirt-loading" *ngIf="!showLastNamesOnly && isLoading">
  <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
  <span class="sr-only">Loading...</span>
</p>

<span *ngIf="showLastNamesOnly && !isLoading">
  {{ getLastNames() }}
</span>
