import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProfileAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-profile-copy',
  templateUrl: './profile-copy.component.html',
  styleUrls: ['./profile-copy.component.scss'],
})
export class ProfileCopyComponent implements OnInit {
  modalOption: NgbModalOptions = {};
  modalRef: NgbModalRef;
  inputValue: string = '';
  isReplicating: boolean = false;
  @Input()
  profileId: string = '';

  constructor(protected modalService: NgbModal, private svcProfile: ProfileAPI) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
  }

  ngOnInit(): void {}

  onOpenModal(dialog) {
    this.modalRef = this.modalService.open(dialog, this.modalOption);
  }

  onSubmit() {
    if (!this.inputValue) {
      return;
    }
    if (parseInt(this.inputValue) > 20) {
      alert('maximum 20 copies allowed');
      return;
    }
    this.isReplicating = true;
    this.modalRef.close();
    this.svcProfile.replicate(this.profileId, { copies: this.inputValue }).subscribe(
      (response) => {
        window.prompt('Profiles with following preson ID has been created:', response.join(','));
      },
      (err) => alert('Error!'),
      () => {
        this.isReplicating = false;
      }
    );
  }
}
