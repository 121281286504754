import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AclGuard } from '../shared/acl/guard.service';
import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { GuidelineListComponent } from './list/list.component';
import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';
import { GuidelineComponent } from './guideline.component';
import { GuidelineCreateComponent } from './create/create.component';
import { GuidelineDetailComponent } from './detail/detail.component';
import { GuidelineModule } from './guideline.module';

const guidelineRoutes: Routes = [
  {
    path: 'guideline',
    component: GuidelineComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'create',
        component: GuidelineCreateComponent,
      },
      {
        path: 'detail/:id',
        component: GuidelineDetailComponent,
      },
      {
        path: 'list',
        component: GuidelineListComponent,
        data: { acl: 'guideline.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
    data: { title: 'Guideline' },
  },
  {
    path: 'publication-guideline',
    component: GuidelineComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'detail/:id',
        component: GuidelineDetailComponent,
      },
      {
        path: 'list',
        component: GuidelineListComponent,
        data: { acl: 'guideline.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
    data: { title: 'Publication Guideline' },
  },
];

export const guidelineRouting: ModuleWithProviders<GuidelineModule> = RouterModule.forChild(guidelineRoutes);
