<section class="dirt-section-list dirt-people-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>Attached People</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        placeholder="Search..."
        [formControl]="searchCtrl"
      />
    </div>
  </header>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-people-list'"
    [pagingSize]="people?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="people?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-state">&nbsp;</th>
          <th class="col-firstname" [diColSorter]="'firstName.sc'">First Name</th>
          <th class="col-middlename" [diColSorter]="'middleName.sc'">Middle Name</th>
          <th class="col-lastname" [diColSorter]="'lastName.sc'">Last Name</th>
          <th class="col-originalFullName">Original Full name</th>
          <th class="col-status" [diColSorter]="'opMetaStatus.keyword'">Status</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-person-row"
          *ngFor="
            let person of people
              | paginate
                : {
                    id: 'dirt-people-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <td class="col-state">
            <span *ngFor="let product of person.products" class="badge badge-{{ product | lowercase }}">
              {{ product | uppercase }}
            </span>
            <span class="badge badge-primary" *ngIf="person.isPrimaryAffiliation">Primary</span>
            <span class="badge badge-primary" *ngIf="person.isPrimaryCandidateAffiliation">Primary Candidate</span>
            <span class="badge badge-info" *ngIf="person.autoProfile">Auto</span>
          </td>
          <td class="col-firstname">
            <a [routerLink]="['/person/detail', person.id]" rel="noopener noreferrer" target="_blank">{{
              person.firstName
            }}</a>
          </td>
          <td class="col-middlename">{{ person.middleName }}</td>
          <td class="col-lastname">{{ person.lastName }}</td>
          <td class="col-originalFullName">{{ person.originalFullName }}</td>
          <td class="col-status">{{ person._meta.status }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">Not used in People so far</p>
    </ng-template>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-people-list'"
    [pagingSize]="people?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
