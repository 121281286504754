export enum GuidelineStatus {
  DRAFT = 'DRAFT',

  READY_FOR_COMPILATION = 'READY_FOR_COMPILATION',
  COMPILATION_IN_PROGRESS = 'COMPILATION_IN_PROGRESS',
  UNABLE_TO_COMPILE = 'UNABLE_TO_COMPILE',

  NO_INFO = 'NO_INFO',
  DONE = 'DONE',
}
