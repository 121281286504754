import { NgModule } from '@angular/core';

import { JobsNextComponent } from './next/next.component';
import { JobsNextCreateComponent } from './next-create/next-create.component';
import { JobsAPI } from './shared/api.service';
import { SharedModule } from '../shared/shared.module';
import { JobsModalComponent } from './shared/modal/modal.component';
import { JobsModalService } from './shared/modal/modal.service';
import { JobsNextListComponent } from './next/list/list.component';
import { BulkListModule } from '../common/bulk-list/bulk-list.module';
import { BulkModule } from '../common/bulk/bulk.module';
import { jobsRouting } from './jobs.routes';
import { JobListComponent } from './list/list.component';
import { JobComponent } from './job.component';
import { JobFormComponent } from './shared/form/form.component';
import { JobDetailComponent } from './detail/detail.component';
import { BulkUpdateModule } from '../common/bulk-update/bulk-update.module';
import { JobEntityURLComponent } from './shared/jobEntityUrl/jobEntityUrl.component';

@NgModule({
  imports: [SharedModule, BulkListModule, BulkUpdateModule, BulkModule, jobsRouting],
  declarations: [
    JobEntityURLComponent,
    JobComponent,
    JobListComponent,
    JobsNextComponent,
    JobsNextCreateComponent,
    JobFormComponent,
    JobsModalComponent,
    JobsNextListComponent,
    JobDetailComponent,
  ],
  providers: [JobsAPI, JobsModalService],
  exports: [
    JobEntityURLComponent,
    JobsNextComponent,
    JobsNextCreateComponent,
    JobsModalComponent,
    JobsNextListComponent,
  ],
})
export class JobsModule {}
