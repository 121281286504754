import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { intersection } from 'lodash';

import { FormList } from '../../../shared/interfaces/form-list.interface';
import { ACL } from '../../../shared/acl/acl.service';
import { Person } from '../person';
import { PersonAPI } from '../api.service';
import { PersonAffiliationsComponent } from '../../../shared/components/affiliations/affiliations.component';
import { Roles } from '../../../shared/acl/roles';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { Value } from '../../../shared/services/value/value';
import { Utils } from '../../../common/utils';
import { PersonAffiliationsLfkaComponent } from '../../../shared/components/affiliations-lfka/affiliations-lfka.component';
import { PersonStatus } from '../constant/status.enum';
import { IMultiSelectOption, IMultiSelectSettings } from '../../../shared/components/multiselect-dropdown/types';
import {
  ImageFormComponent,
  ImageFormModel,
  ImageFormValidationConfig,
} from '../../../shared/components/image-form/image-form.component';
import { PersonJob } from '../constant/job.enum';
import { PersonAffiliation } from '../person-affiliation';
import { PersonAffiliationLfka } from '../person-affiliation-lfka';
import { PersonAffiliationEducation } from '../person-affiliation-education';
import { PersonAffiliationsEducationComponent } from '../../../shared/components/affiliations-education/affiliations-education.component';
import { isOnlyLFKAProject } from '../utils';
import { MarkerArea } from '../../../shared/directives/marker/marker.directive';
import { getReviewMarkerArea } from '../../../shared/utils/job-review-marker';

const CLS_MULTISELECT_BTN = 'btn btn-sm btn-secondary';
const SPECIALTIES_LIMIT = 10;

@Component({
  selector: 'dirt-person-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
  exportAs: 'frmPerson',
})
export class PersonFormComponent implements OnInit, OnChanges {
  @Input('person')
  model: Person = new Person();

  @Input()
  currentJobType: string | null = null;

  @Input()
  detail: boolean;

  @Input('basePerson')
  basePerson: Person = new Person();

  @Input()
  readonly: boolean;

  @Input()
  checkFindings: { [field: string]: string[] } = {};

  @Input()
  affiliationCreateRequestHandler: (
    sourceItem?: PersonAffiliation | PersonAffiliationLfka | PersonAffiliationEducation,
    disableNameField?: boolean,
    requestOwnerProduct?: string
  ) => Observable<PersonAffiliation | PersonAffiliationLfka | PersonAffiliationEducation> = null;

  @Input()
  affiliationMaintenanceRequestHandler: (id, requestOwnerProduct?: string) => any = null;

  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  @ViewChild(PersonAffiliationsComponent)
  affiliationsComponent: FormList;

  @ViewChild(PersonAffiliationsLfkaComponent)
  affiliationsLfkaComponent: FormList;

  @ViewChild(PersonAffiliationsEducationComponent)
  affiliationsEducationComponent: FormList;

  @ViewChild(ImageFormComponent)
  imageFormComponent: ImageFormComponent;

  @ViewChild('linkedin')
  linkedinElement: ElementRef;

  initialOpenDataId: string;
  isTogglingPhotoApproval: boolean;
  readonly openDataLength = 18;
  readonly npiLength = 10;
  readonly maxNameLength = 50;
  readonly maxFullNameLength = 152;
  readonly namePattern: string = '^[^\\d]+$';

  suffixes: Value[] = [];
  sources: Value[] = [];
  states: Value[] = [];
  professions: Value[] = [];
  professionsCodes: string[] = [];
  degreesValue: Value[] = [];
  phoneTypes: Value[] = [];
  optoutStates: IMultiSelectOption[] = [];
  products: Value[];

  // Degrees
  degrees: IMultiSelectOption[] = [];
  degreesSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    sortSelectedFirst: true,
    dynamicTitleMaxItems: Number.POSITIVE_INFINITY,
  };

  // Specialties
  specialties: IMultiSelectOption[] = [];
  specialtiesSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    sortSelectedFirst: true,
    dynamicTitleMaxItems: 5,
    selectionLimit: SPECIALTIES_LIMIT,
  };

  // OriginalSpecialties
  originalSpecialties: any[];

  // Segments
  segments: IMultiSelectOption[] = [];
  segmentsSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: false,
    sortSelectedFirst: true,
    dynamicTitleMaxItems: 5,
  };

  projectsSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    sortSelectedFirst: true,
    dynamicTitleMaxItems: 5,
  };

  projectsDelivery: {
    id: string | number;
    name: string;
    disabled: boolean;
  }[] = [];
  projectsTechnical: {
    id: string | number;
    name: string;
    disabled: boolean;
  }[] = [];
  projectNamesDelivery: string[];
  projectNamesTechnical: string[];

  duplicatesByEmail: Person[] = [];

  duplicatesByCVLink: Person[] = [];

  duplicatesByAffiliationAndName: Person[] = [];

  isLoadingDuplicates: boolean;

  personLogoValue: ImageFormModel = {};

  imageValidationConfig: ImageFormValidationConfig = {
    minSize: 150,
    maxAspectRatio: 2,
  };

  // Just make it available in the template
  SPECIALTIES_LIMIT = SPECIALTIES_LIMIT;

  private isFirstDegreeChangeCall = true;

  private isFormImageValid = true;

  private canWorkImage: boolean;

  /**
   * Based on the current job type that a user is working, we want to determine the
   * area type that in turn determines the `field-error-type`s that are visible during
   * the review.
   */
  reviewMarkerArea: MarkerArea | null = null;

  LFKA_TAGS: string[] = [];

  languages$: Observable<Value[]>;

  countries$: Observable<Value[]>;

  constructor(private svcAcl: ACL, private svcPerson: PersonAPI, private svcValue: ValueAPI, private router: Router) {}

  ngOnInit() {
    this.initialOpenDataId = this.model.externalIds.openData;

    this.loadDeliveryProjects();
    this.loadTechnicalProjects();
    this.loadSpecialties();
    this.loadOriginalSpecialties();
    this.loadProfessions();
    this.loadSources();
    this.loadStates();
    this.loadSegments();
    this.loadDegrees();
    this.loadProducts();
    this.initializeLists();
    this.onEmailsBlur();
    this.onCVLinksBlur();
    this.onAnyAffiliationChanges();
    this.initImageFormValue();
    this.loadPhoneTypes();
    this.loadSuffixes();
    this.setReviewMarkerArea();

    this.languages$ = this.svcValue.find(ValueType.Language, Number.MAX_SAFE_INTEGER, 0, '+title');
    this.countries$ = this.svcValue.find(ValueType.InScopeCountry, Number.MAX_SAFE_INTEGER, 0, '+title');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && changes.model.currentValue) {
      // ensure collections are there
      const curr = changes.model.currentValue as Person;
      if (!curr.affiliations) {
        curr.affiliations = [];
      }
      if (!curr.affiliationsLfka) {
        curr.affiliationsLfka = [];
      }
      if (!curr.affiliationsEducation) {
        curr.affiliationsEducation = [];
      }

      this.model.phone = Object.keys(this.model.phone || {}).length > 0 ? this.model.phone : ({} as any);
    }

    if (changes['currentJobType']) {
      this.setReviewMarkerArea();
    }

    if (
      (changes['currentJobType'].previousValue !== PersonJob.PERSON_LINKEDIN_CURATION &&
        changes['currentJobType'].currentValue === PersonJob.PERSON_LINKEDIN_CURATION) ||
      (changes['currentJobType'].previousValue !== PersonJob.PERSON_LINKEDIN_QC &&
        changes['currentJobType'].currentValue === PersonJob.PERSON_LINKEDIN_QC)
    ) {
      setTimeout(() => this.linkedinElement.nativeElement.scrollIntoView({ behavior: 'smooth' }));
    }

    this.canWorkImage = this.isFieldEditable('image') || this.isFieldEditable('social.twitter');
  }

  isNewRecord(): boolean {
    return !this.model.id;
  }

  isValid(): boolean {
    if (
      this.currentJobType === PersonJob.PERSON_IMAGE_CURATION ||
      this.currentJobType === PersonJob.PERSON_IMAGE_MAINTENANCE
    ) {
      return this.isFormImageValid;
    }

    let res = this.ngForm.form.valid;
    if (this.affiliationsComponent) {
      res = res && this.affiliationsComponent.isValid();
    }
    if (this.affiliationsLfkaComponent) {
      res = res && this.affiliationsLfkaComponent.isValid();
    }
    if (this.affiliationsEducationComponent) {
      res = res && this.affiliationsEducationComponent.isValid();
    }
    if (this.canWorkImage) {
      res = res && this.isFormImageValid;
    }
    return res && !this.isLoadingDuplicates;
  }

  invalidFields(): string[] {
    return Utils.prettyErrorFields(this.ngForm.form);
  }

  isFieldValid(fieldName) {
    const field = this.ngForm.controls[fieldName];
    if (field) {
      return field.valid;
    }
  }

  onKeydown(e: KeyboardEvent, value: string, maxLength: number): void {
    if (/\d/.test(e.key) && value && value.length >= maxLength) {
      e.preventDefault();
    }
  }

  searchTags() {
    const fn = (term: string) => {
      if (!this.isFieldEditable('tags')) {
        return from([]);
      }
      return this.svcPerson.findTags(term);
    };

    return fn.bind(this);
  }

  getValue(): Person {
    const model = Object.assign({}, this.model); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables

    if (model.tags) {
      // flatten tags
      model.tags = model.tags.map((tag) => tag['display'] || tag);
    }

    if (model.review && typeof model.review.by === 'object') {
      model.review.by = model.review.by.user_id;
    }

    if (this.affiliationsComponent) {
      const affiliationsList = this.affiliationsComponent.getListItems();
      const hasNewlyCreated = affiliationsList.find((aff) => aff.newlyCreated);
      if (hasNewlyCreated) {
        model.newAffiliationCreated = true;
      }
      model.affiliations = affiliationsList;
    }
    if (this.affiliationsLfkaComponent) {
      model.affiliationsLfka = this.affiliationsLfkaComponent.getListItems();
    }
    if (this.affiliationsEducationComponent) {
      model.affiliationsEducation = this.affiliationsEducationComponent.getListItems();
    }

    if (this.canWorkImage) {
      model.social = model.social || { twitter: {} };
      model.social.twitter = model.social.twitter || {};
      model.social.twitter.notFound = this.personLogoValue.isTwitterHandleNotFound;
      model.social.twitter.username = model.social.twitter.notFound ? null : this.personLogoValue.twitterHandle;
      model.social.twitter.approved = model.social.twitter.notFound
        ? false
        : this.personLogoValue.isTwitterHandleApproved;
      model.social.twitter.photoApproved = model.social.twitter.notFound
        ? false
        : this.personLogoValue.useTwitterHandle;

      model.image = model.image || {};
      model.image.notFound = this.personLogoValue.isImageNotFound;
      model.image.url = model.image.notFound ? null : this.personLogoValue.url;
      model.image.source = model.image.notFound ? null : this.personLogoValue.websource;

      // Reset local URL
      if (
        (model.image.notFound || !model.image.url) &&
        (model.social.twitter.notFound || !model.social.twitter.username || !model.social.twitter.photoApproved)
      ) {
        model.image.local_url = null;
        model.image.local_url_nocache = null;
      }
    }
    if (this.personLogoValue.qc) {
      model.qc = Object.assign(model.qc || {}, this.personLogoValue.qc);
    }

    Utils.removeEmptyAndDuplicatedElementsFromArray(model, [
      'degreeLinks',
      'nameLinks',
      'alternativeNames',
      'nicknames',
      'positionLinks',
      'statusLinks',
      'affiliationsLinks',
      'specialtyLinks',
      'talkingPoints',
      'emailLinks',
      'cvLinks',
    ]);

    return model;
  }

  isFieldEditable(field: string, explicit?: boolean): boolean {
    if (this.readonly) {
      return false;
    }

    const prefix = this.model.id ? 'update' : 'create';
    return this.svcAcl.hasCredential(`person.${prefix}.prop.${field}`, this.currentJobType, undefined, explicit);
  }

  isFieldMandatory(field: string): boolean {
    const prefix = this.model.id ? 'update' : 'create';
    return (
      this.svcAcl.hasCredential(`person.${prefix}.mandatory.prop.${field}`, this.currentJobType, true) &&
      !this.svcAcl.hasCredential(`person.${prefix}.optional.prop.${field}`, this.currentJobType, true)
    ); // can combine two roles, and become optional again, esp. merger; job makes it mandatory or it's optional
  }

  isSpecialtiesMandatory(): boolean {
    // only mandatory if also 'MD-like' degree
    const perSe = this.isFieldMandatory('specialties'); // don't circumvent 'normal' - keep app manage-able
    const MD_LIKE = (this.degreesValue || []).filter((v) => v.needsSpecialty).map((v) => v.code); // codes of degree - now MD, could also imagine: DO, MChD, DMED_DACH, DDS, DMD, ND - see clean-api/scripts/degree_needs_specialty.js
    return (
      perSe &&
      (this.model.degrees || []).filter((d) => MD_LIKE.indexOf(d) >= 0).length > 0 &&
      this.isMandatoryBecauseOfStatus()
    );
  }

  isCVLinkMandatory(): boolean {
    // only mandatory if person not in ID OUT or NO INFO status
    const perSe = this.isFieldMandatory('cvLinks'); // don't circumvent 'normal' - keep app manage-able
    return perSe && this.isMandatoryBecauseOfStatus();
  }

  isCountryMandatory(): boolean {
    // not mandatory when kol is in ID OUT status
    return this.isMandatoryBecauseOfStatus();
  }

  isMandatoryBecauseOfStatus(): boolean {
    // not mandatory when kol is in id out status; again small method that can be evaluated often w/out problem
    return (
      !this.model._meta || // no status yet = submit = we want to know
      (this.model._meta.status !== PersonStatus.ID_OUT &&
        this.model._meta.status !== PersonStatus.DUPLICATE &&
        this.model._meta.status !== PersonStatus.NO_INFO &&
        this.model._meta.status !== PersonStatus.ON_HOLD)
    ); // for all but these we want to know
  }

  isCountryWorkflowEnabled() {
    return !this.model.affiliations?.find((a) => a.primary) && this.isFieldEditable('countryWorkflow');
  }

  isIdentifying() {
    // in some way, shape, form working on identification until the first 'DONE'
    return (
      PersonJob.DEDUPLICATION === this.currentJobType ||
      PersonStatus.PENDING_VERIFICATION === this.model._meta?.status ||
      PersonStatus.VERIFICATION_IN_PROGRESS === this.model._meta?.status
    );
  }

  highlightFields(additionalClass: string) {
    additionalClass = additionalClass ? `form-control ${additionalClass || ''}` : `form-control`;

    return this.svcAcl.hasRole(Roles.ProfileModerator) ||
      this.svcAcl.hasRole(Roles.ProfileModifier) ||
      this.svcAcl.hasRole(Roles.ProfileCompiler)
      ? `form-focus ${additionalClass}`
      : additionalClass;
  }

  get isCompiler() {
    return this.svcAcl.hasRole(Roles.PersonCompiler) || this.svcAcl.hasRole(Roles.ProfileCompiler);
  }

  hasRestrictedStateOptions() {
    return this.svcAcl.hasRole(Roles.PersonCompilerIdent);
  }

  isStateDisabled(state: string): boolean {
    if (!state) {
      return false;
    }

    if (!['DECEASED', 'RETIRED'].includes(state.toString()) && this.hasRestrictedStateOptions()) {
      return true;
    }

    return (
      (['RISING_EXPERT', 'NOT_RISING'].includes(state.toString()) ||
        this.model.optoutStatus.includes('OPTOUT_FOR_TA')) &&
      this.isCompiler
    );
  }

  trackByIndex(idx: number): number {
    return idx;
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }

  gotoCvLink(cvLink: string): void {
    window.open(cvLink, '_blank', 'noopener');
  }

  gotoEmailLink(emailLink: string): void {
    window.open(emailLink, '_blank', 'noopener');
  }

  onTwitterChange(username: string): void {
    this.model.social = this.model.social || { twitter: {} };
    if (username.trim()) {
      this.model.social.twitter = {
        username,
      };
    } else {
      this.model.social.twitter = {};
    }
  }

  onLinkedInUrlChange(linkedInUrl: string): void {
    this.model.social = this.model.social || { linkedinUrl: '' };
    if (linkedInUrl.trim()) {
      this.model.social.linkedinUrl = linkedInUrl;
    } else {
      this.model.social.linkedinUrl = '';
    }
  }

  onLinkedInNotFoundChange(checked: boolean): void {
    this.model.linkedInNotFound = checked;
  }

  isLinkedInNotFoundDisabled(): boolean {
    if (this.model.social && this.model.social.linkedinUrl) {
      if (this.model.social.linkedinUrl === '') {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  onImageUrlChange(value: string) {
    this.model.image = this.model.image || {};
    this.model.image.url = value;
  }

  onImageSourceChange(value: string) {
    this.model.image = this.model.image || {};
    this.model.image.source = value;
  }

  private updatePhotoApproval() {
    this.isTogglingPhotoApproval = true;

    this.svcPerson
      .upsert(this.model)
      .pipe(tap(() => (this.isTogglingPhotoApproval = false)))
      .subscribe((resp: Person) => (this.model._version = resp._version));
  }

  setPhotoApproval(value: boolean) {
    this.model.social.twitter.photoApproved = value;
    this.updatePhotoApproval();
  }

  togglePhotoApproval(): void {
    if (!('photoApproved' in this.model.social.twitter)) {
      return;
    }

    this.model.social.twitter.photoApproved = !this.model.social.twitter.photoApproved;
    this.updatePhotoApproval();
  }

  onSearchPerson(): void {
    const fullName = `${this.model.firstName ?? ''} ${this.model.lastName ?? ''}`;

    // Navigate to the person list with the filtered person name in a new tab
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/person'], { queryParams: { name: `"${fullName}"` } })
    );
    window.open(url, '_blank');
  }

  private initializeLists(): void {
    const lists = {
      alternativeNames: this.model.alternativeNames,
      cvLinks: this.model.cvLinks,
      nameLinks: this.model.nameLinks,
      positionLinks: this.model.positionLinks,
      statusLinks: this.model.statusLinks,
      emailLinks: this.model.emailLinks,
      degreeLinks: this.model.degreeLinks,
      specialtyLinks: this.model.specialtyLinks,
      affiliationsLinks: this.model.affiliationsLinks,
      emails: this.model.emails,
      faxes: this.model.faxes,
      talkingPoints: this.model.talkingPoints,
      nicknames: this.model.nicknames,
    };

    Object.entries(lists).forEach(([key, list]) => {
      if (!Array.isArray(list)) {
        this.model[key] = []; // null is a primitive hence it's passed by copy, we need to mutate the original object
        list = this.model[key];
      }

      if (!list.length) {
        list.push('');
      }
    });
  }

  // Added two separate function in order to maintain the user experience
  loadDeliveryProjects() {
    this.svcValue
      .find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER, 0, '+title', { technical: false })
      .toPromise()
      .then((data) => {
        this.projectsDelivery = data.map((o) => ({
          id: o.code,
          name: o.title,
          disabled: false,
        }));
        const lfkaTags = data.filter((o) => o.product === 'LFKA').map((o) => o.code as string);
        this.LFKA_TAGS = this.LFKA_TAGS.concat(lfkaTags);
      });
  }

  loadTechnicalProjects() {
    this.svcValue
      .find(ValueType.PersonProject, Number.MAX_SAFE_INTEGER, 0, '+title', { technical: true })
      .toPromise()
      .then((data) => {
        this.projectsTechnical = data.map((o) => ({
          id: o.code,
          name: o.title,
          disabled: false,
        }));
        const lfkaTags = data.filter((o) => o.product === 'LFKA').map((o) => o.code as string);
        this.LFKA_TAGS = this.LFKA_TAGS.concat(lfkaTags);
      });
  }

  loadSources() {
    this.svcValue
      .find(ValueType.PersonSource, Number.MAX_SAFE_INTEGER)
      .toPromise()
      .then((data) => {
        this.sources = data;
      });
  }

  loadStates() {
    this.svcValue
      .find(ValueType.PersonState, Number.MAX_SAFE_INTEGER)
      .toPromise()
      .then((data) => {
        this.states = data;
      });
  }

  loadSpecialties() {
    this.svcValue
      .find(ValueType.Specialty, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.specialties = data.map((o) => ({
          id: o.code,
          name: o.title,
          disabled: o.disabled,
        }));
      });
  }

  loadOriginalSpecialties() {
    this.svcValue.find(ValueType.OriginalSpeciality, Number.MAX_SAFE_INTEGER, 0, undefined).subscribe((data) => {
      this.originalSpecialties = data.map((item) => ({
        id: item.value.code,
        originalSpecialty: item.value.originalSpecialty,
        specialty: item.value.specialty,
        label: `${item.value.originalSpecialty} (${item.value.country})`,
        disabled: item.disabled,
      }));
    });
  }

  loadSegments() {
    this.svcValue
      .find(ValueType.Segment, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.segments = data.map((o) => ({
          id: o.code,
          name: o.title,
          disabled: false,
        }));
      });
  }

  loadDegrees() {
    this.svcValue
      .find(ValueType.Degree, Number.MAX_SAFE_INTEGER, 0, '+order')
      .toPromise()
      .then((data) => {
        this.degreesValue = [...data];
        this.setDegree(this.isKAMProject());
      });
  }

  loadProducts() {
    this.svcValue.find(ValueType.Product, Number.MAX_SAFE_INTEGER, 0).subscribe((data) => {
      this.products = [
        ...data.filter((d) => 'LFTA' === d.value), // make sure it's first
        ...data.filter((d) => 'LFTA' !== d.value),
      ];
    });
  }

  isKAMProject(): boolean {
    return !!this.model?.projectNames?.find((p) => this.LFKA_TAGS.includes(p));
  }

  isMoreThanKAMProject(): boolean {
    // has any other project than KAM ones (no project = assume standard profile)
    return !!this.model?.projectNames?.find((p) => !this.LFKA_TAGS.includes(p));
  }

  isOnlyKAMProject(): boolean {
    return isOnlyLFKAProject(this.model?.projectNames, this.LFKA_TAGS);
  }

  isMainlyUS() {
    return (
      this.model.countryWorkflow === 'US' ||
      (this.model.affiliations || []).filter((a) => a.primary && a.address?.countryCode === 'US').length > 0
    );
  }

  isOpenDataRejectedChecked(): boolean {
    return this.model.openDataStatus === 'Rejected';
  }

  onOpenDataRejectedCheckedChange(checked: boolean): void {
    this.model.openDataStatus = checked ? 'Rejected' : null;
    if (checked) {
      this.model.openDataNotFound = false;
    }
  }

  onProjectTagChangeForDelivery(projects: string[]): void {
    this.projectNamesDelivery = projects;
    this.model.projectNames = [].concat(projects, this.projectNamesTechnical);
    this.setDegree(this.isKAMProject());
  }

  onProjectTagChangeForTechnical(projects: string[]): void {
    this.projectNamesTechnical = projects;
    this.model.projectNames = [].concat(projects, this.projectNamesDelivery);
    this.setDegree(this.isKAMProject());
  }

  private setDegree(KAM: boolean) {
    let disabled: boolean;
    this.degrees = this.degreesValue.map((o) => {
      if (o.area && o.area.includes('KAM') && !KAM) disabled = true;
      else disabled = false;
      return {
        id: o.code,
        name: o.title,
        disabled,
        renderFirst: o.category === 'MD',
        classes: o.category === 'MD' ? 'highlight-md' : o.category === 'Doctorate' ? 'highlight-phd' : '',
      };
    });
    return;
  }

  loadProfessions() {
    this.svcValue.find(ValueType.PersonProfession, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.professions = data;
      this.professionsCodes = data.map((v) => v.code as string);
    });
  }

  onOptoutToggle(optout: boolean): void {
    this.model.optoutStatus = optout ? ['OPTOUT_FOR_TA'] : [];
  }

  onDegreeChange(values: string[]): void {
    // prevent first call from incorrectly resetting the specialties/profession (first call is form init/restore)
    if (this.isFirstDegreeChangeCall) {
      this.isFirstDegreeChangeCall = false;
      return;
    }

    if (!values.length) {
      this.model.specialties = [];
      this.model.profession = null;
      return;
    }

    const country = this.getCountry();
    const degreesProfessionsCodes = values
      .map((code) => this.degreesValue?.find((v) => v.code === code)?.expertType)
      // just to be extra sure, filter out nullish values
      .filter((code) => code);

    const professions = degreesProfessionsCodes
      .map((code) => this.professions.find((v) => v.code === code))
      // Exclude professions whose country restrictions don't match our current country
      .filter((p) => !Array.isArray(p.showForCountryCodes) || p.showForCountryCodes.includes(country))
      // Sort by priority so that the profession with the highest priority is the one we end up using
      .sort((p1, p2) => p1.order - p2.order);

    // Use the highest priority profession or reset it in case nothing matches
    this.model.profession = (professions?.[0]?.code as string) || null;

    const degree = (this.degreesValue || []).find((v) => v.code === values[values.length - 1]);
    if ((this.model.specialties || []).length < 1 && degree && degree.specialties && degree.specialties.length === 1) {
      // set specialties if degree have some and we don't already have some selected (don't override)
      this.model.specialties = [...degree.specialties];
    }
  }

  isExpertTypeSpecialityDegreeRelationValid() {
    const degrees = (this.degreesValue || []).filter((v) => this.model.degrees.indexOf(v.code.toString()) > -1);
    return !degrees.find(
      (degree) =>
        (degree.needsEmptyExpertType && !!this.model.profession) ||
        (degree.needsEmptySpecialty && (this.model.specialties || []).length > 0) ||
        (degree.needsSpecialty && (this.model.specialties || []).length <= 0) ||
        (degree.specialties && intersection(degree.specialties, this.model.specialties || []).length === 0) ||
        (degree.expertType && this.model.profession !== degree.expertType)
    );
  }

  onNameBlur(): void {
    this.onEmailsBlur();
    this.onCVLinksBlur();
    this.onAnyAffiliationChanges();
  }

  onEmailsBlur(): void {
    const [email] = this.model.emails || [];
    if (!email || !this.model.firstName || !this.model.lastName || this.model.training) {
      this.duplicatesByEmail = [];
      return;
    }

    this.isLoadingDuplicates = true;
    this.svcPerson
      .getDuplicates({
        kolId: this.model.kolId,
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        emails: this.model.emails,
      })
      .pipe(tap(() => (this.isLoadingDuplicates = false)))
      .subscribe((duplicates) => {
        this.duplicatesByEmail = duplicates;
      });
  }

  onCVLinksBlur(): void {
    if (
      this.model.cvLinks?.filter((l) => !!l).length < 1 ||
      !this.model.firstName ||
      !this.model.lastName ||
      this.model.training
    ) {
      this.duplicatesByCVLink = [];
      return;
    }

    this.isLoadingDuplicates = true;
    this.svcPerson
      .getDuplicates({
        kolId: this.model.kolId,
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        cvLinks: this.model.cvLinks,
      })
      .pipe(tap(() => (this.isLoadingDuplicates = false)))
      .subscribe((duplicates) => {
        this.duplicatesByCVLink = duplicates;
      });
  }

  onAnyAffiliationChanges(): void {
    const primary = this.model.affiliations?.find((af) => af.primary);
    if (!primary || !this.model.firstName || !this.model.lastName || this.model.training) {
      this.duplicatesByAffiliationAndName = [];
      return;
    }

    this.isLoadingDuplicates = true;
    this.svcPerson
      .getDuplicates({
        kolId: this.model.kolId,
        firstName: this.model.firstName,
        lastName: this.model.lastName,
        affiliations: [
          {
            id: primary.id,
            primary: true,
            address: {
              countryCode: primary.address?.countryCode,
            },
          },
        ] as any,
      })
      .pipe(tap(() => (this.isLoadingDuplicates = false)))
      .subscribe((duplicates) => {
        this.duplicatesByAffiliationAndName = duplicates;
      });
  }

  hasDuplicates(): boolean {
    return (
      this.duplicatesByEmail?.length > 0 ||
      this.duplicatesByCVLink?.length > 0 ||
      this.duplicatesByAffiliationAndName?.length > 0
    );
  }

  degreeSearchFunc = (degree: string) => {
    // Function was picked up from angular-2-dropdown-multiselect hence trying to keep it as similar as possible while adding extra regex for space
    const regExpStr = degree
      .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&')
      .replace(/\s+/g, '. ')
      .replace(/\.\s*/g, '.*\\s*');
    return new RegExp(regExpStr, 'i');
  };

  hasUSAddress() {
    const primary = this.model.affiliations?.find((af) => af.primary);
    if (primary?.address) {
      return primary?.address?.countryCode === 'US';
    }
    return this.isMainlyUS();
  }

  isNpiMandatory(): boolean {
    return this.hasUSAddress() && this.isFieldMandatory('externalIds.npi') && !this.model.npiNotFound;
  }

  isOpenDataMandatory(): boolean {
    return this.isFieldMandatory('externalIds.openData') && !this.model.openDataNotFound;
  }

  onNpiNotFoundCheckedChange(checked: boolean) {
    this.model.npiNotFound = checked;
  }

  onNpiModelChange(npi: string) {
    if (npi) {
      this.model.npiNotFound = false;
    }
  }

  onOpenDataNotFoundCheckedChange(checked: boolean) {
    this.model.openDataNotFound = checked;
  }

  onOpenDataModelChange(openData: string) {
    if (openData) {
      this.model.openDataNotFound = false;
      this.model.openDataStatus = null; // Reset what was there
    }
  }

  shouldAddMoreLink(links: string[]) {
    if (links.length < 3) {
      return true;
    }

    if ([PersonJob.LFKA_KPP_CURATION, PersonJob.LFKA_KPP_MAINTENANCE].includes(this.currentJobType as any)) {
      return true;
    }

    if (
      this.model.projectNames?.length > 0 &&
      this.model.projectNames.every((project) =>
        ['KAM_ALL', 'KAM_VIP', 'LFKA_PT_CONFIRMED', 'LFKA_PT_SUGGESTED', 'LFKA_COMMITTEE'].includes(project)
      )
    ) {
      return !this.model.projectNames.every((project) => ['KAM_ALL', 'KAM_VIP'].includes(project));
    }

    if (this.hasUSAddress()) {
      return true;
    }
  }

  onFormImageValidityChange(isValid: boolean): void {
    setTimeout(() => {
      this.isFormImageValid = isValid;
    });
  }

  private initImageFormValue(): void {
    this.personLogoValue = {
      isImageNotFound: this.model.image?.notFound,
      url: this.model.image?.url,
      websource: this.model.image?.source,
      isTwitterHandleNotFound: this.model.social?.twitter?.notFound,
      twitterHandle: this.model.social?.twitter?.username,
      isTwitterHandleApproved: this.model.social?.twitter?.approved,
      useTwitterHandle: this.model.social?.twitter?.photoApproved,
    };
  }

  private loadPhoneTypes(): void {
    this.svcValue.find(ValueType.PersonPhoneType, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.phoneTypes = data;
    });
  }

  onCopyName(): void {
    const { firstName = '', middleName = '', lastName = '', suffix = '' } = this.model;
    navigator.clipboard.writeText([firstName, middleName, lastName, suffix].filter((s) => !!s).join(' '));
  }

  onCopyOriginalFullName(): void {
    if (this.model.originalFullName) {
      navigator.clipboard.writeText(this.model.originalFullName);
    }
  }

  isCVLinkEditable(idx: number): boolean {
    let canEdit = this.isFieldEditable('cvLinks');
    if (idx === 0 && !!this.model?.cvLinks?.[0]) {
      // profile compilers cannot touch LFKA master CV link (if it's there)
      canEdit = canEdit && !(this.isOnlyKAMProject() && this.svcAcl.hasRole(Roles.ProfileCompiler));
    }
    return canEdit;
  }

  private loadSuffixes(): void {
    this.svcValue.find(ValueType.PersonSuffix, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.suffixes = data;
    });
  }

  onOriginalSpecialtyAdded(selected: any): void {
    const mappedSpecialty = selected.specialty;
    const specialtyCode = this.specialties.find((sp) => sp.name === mappedSpecialty)?.id;
    if (!specialtyCode) {
      return;
    }
    if (specialtyCode && !this.model.specialties.includes(specialtyCode)) {
      this.model.specialties.push(specialtyCode);
    }
    this.ngForm.controls.specialties.updateValueAndValidity();
  }

  onOriginalSpecialtyRemoved(removed: Value): void {
    const mappedSpecialty = removed?.value?.specialty;
    const specialtyCode = this.specialties.find((sp) => sp.name === mappedSpecialty)?.id;

    if (specialtyCode && this.model.specialties.includes(specialtyCode)) {
      this.removeFromByIndex(this.model.specialties, this.model.specialties.indexOf(specialtyCode));
    }
    this.ngForm.controls.specialties.updateValueAndValidity();
  }

  onSpecialtyRemoved(code: string): void {
    const specialty = this.specialties.find((sp) => sp.id === code)?.name;
    const originalSpecialtyCodes = this.originalSpecialties
      .filter((osp) => osp.specialty === specialty)
      .map((item) => item.id);
    this.model.originalSpecialties = this.model.originalSpecialties.filter(
      (ospCode) => !originalSpecialtyCodes.includes(ospCode)
    );
    this.ngForm.controls.specialties.updateValueAndValidity();
  }

  obtainSocial(type: 'instagram' | 'tiktok') {
    if (!this.model.social) {
      this.model.social = {};
    }
    if (!this.model.social[type]) {
      this.model.social[type] = {};
    }
    return this.model.social[type];
  }
  onSocialNotFoundChange(type: 'instagram' | 'tiktok', evnt) {
    this.obtainSocial(type);
    this.model.social[type].notFound = evnt;
    if (this.model.social[type].notFound) {
      this.model.social[type].username = '';
    }
  }

  setReviewMarkerArea() {
    this.reviewMarkerArea = getReviewMarkerArea(this.currentJobType);
  }

  private getCountry(): string {
    const primaryAffiliation = this.model?.affiliations?.find((a) => a.primary);
    if (primaryAffiliation) {
      return primaryAffiliation.address?.countryCode;
    }
    return this.model?.countryWorkflow;
  }
}
