import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { tap } from 'rxjs';

import { AffiliationAPI } from '../../api.service';
import { ACL } from '../../../../shared/acl/acl.service';

@Component({
  selector: 'dirt-affiliation-maintenance-modal',
  templateUrl: './affiliation-maintenance-job.component.html',
  styleUrls: ['./affiliation-maintenance-job.component.scss'],
})
export class AffiliationMaintenanceJobComponent implements OnInit {
  @Input()
  affiliationId: string;

  @Input()
  isCustomerRequest: boolean; // have a way to force it based on external conditions

  @Input()
  requestOwnerProduct?: string;

  newJobDesc: any = {};

  isSubmitting: boolean = false;

  canUpdateAffiliation: boolean;

  @ViewChild(NgForm)
  private jobForm: NgForm;

  constructor(
    private readonly activeModal: NgbActiveModal,
    private readonly svcAffiliation: AffiliationAPI,
    private svcAcl: ACL
  ) {}

  ngOnInit(): void {
    this.canUpdateAffiliation = this.svcAcl.hasCredential('affiliation.update');
    if (this.isCustomerRequest) {
      this.newJobDesc.customerRequest = true;
    }
  }

  onCancelClick() {
    this.activeModal.close(null);
  }

  onSubmitClick() {
    if (this.isSubmitting || !this.jobForm.valid) {
      return;
    }

    this.newJobDesc.requestOwnerProduct = this.requestOwnerProduct;

    this.isSubmitting = true;
    this.svcAffiliation
      .maintenanceRequest(this.affiliationId, this.newJobDesc)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe({
        next: (p) => this.activeModal.close(p),
        error: () => alert('Failed to create request'),
      });
  }
}
