import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AffiliationAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-affiliation-next-merge',
  templateUrl: 'next-merge.component.html',
  styleUrls: ['next-merge.component.scss'],
})
export class AffiliationNextMergeComponent {
  queue: any;
  isLoadingNext = false;

  constructor(private router: Router, private svcAffiliation: AffiliationAPI) {}

  getNext(): void {
    this.isLoadingNext = true;

    this.svcAffiliation.findNextMerge().subscribe(
      (finding) => {
        if (!finding || !finding._id) {
          return alert('No Affiliation Merge to be processed at the moment! Go and get some rest ;)');
        }

        this.router.navigate(['/affiliation/detail-merge', finding._id]);
      },
      () => alert('Error!'),
      () => (this.isLoadingNext = false)
    );
  }
}
