<section class="dirt-section-list dirt-account-medical-information-list">
  <header class="row justify-content-between">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>Medical Information</span>
      </h1>
    </div>

    <div class="col-sm-4 create-button-wrapper">
      <!-- Create Button -->
      <a
        class="btn btn-success btn-sm"
        [routerLink]="['/account-medical-information/create']"
        [queryParams]="{ accountId: accountId }"
        *diAcl="'account-medical-information.create'"
      >
        <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
        <span>Create Medical Information</span>
      </a>
    </div>
  </header>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-account-medical-information'"
    [pagingSize]="accountMedicalInformation?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table
      class="table table-sm table-hover"
      *ngIf="accountMedicalInformation?.length; else empty"
      (diSort)="onSort($event)"
    >
      <thead>
        <tr>
          <th class="col-year" [diColSorter]="'year'">Year</th>
          <th class="col-created" [diColSorter]="'createdAt'">Created</th>
          <th class="col-modified" [diColSorter]="'updatedAt'">Modified</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-account-medical-information-row"
          *ngFor="
            let row of accountMedicalInformation
              | paginate
                : {
                    id: 'dirt-account-medical-information',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <td class="col-year">
            <a [routerLink]="['/account-medical-information/detail', row.id]">{{ row.year }}</a>
          </td>
          <td class="col-created">{{ row.createdAt | date : 'dd.MM.y' }}</td>
          <td class="col-modified">{{ row.updatedAt | date : 'dd.MM.y' }}</td>
          <td class="col-actions">
            <!-- Delete -->
            <span
              class="action-btn action-delete"
              title="Delete Medical Information"
              (click)="onDelete(row._id)"
              *diAcl="'account-medical-information.delete'"
            >
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No Medical Information</p>
    </ng-template>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
