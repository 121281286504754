import { GuidelineAuthor } from '../../guideline-author/shared/guideline-author';
import { Association } from '../../association/shared/association';

export class Guideline {
  _id: string;
  id: string;
  title: string;
  originalTitle: string;
  abstract: { text?: string } = {};
  associations: string[] = [];
  associationRefs: Association[] = [];
  authors: GuidelineAuthor[];
  externalIds: {
    doi?: string;
    guidelineid?: string;
    pmid?: string;
    /** Only for guidelines from publications collection, same as pmid - just different name */
    pubmed?: string;
  } = {};
  externalLinks: { link: string; type: string }[];
  keywords: { keyword: string; majorTopic: string }[];
  /** Stored as string, but always manipulated as date in the UI */
  publicationDate: string | Date;
  types: string[];
  sources: string[];
  _meta: {
    assignee?: string;
    lastAssignee?: string;
    maintenance?: boolean;
    status: string;
    claimedUntil?: Date;
  };
  journal: {
    title?: string;
    country?: string;
    issue?: string;
    volume?: string;
  } = {};
  priority: number;
  projectNames: string[];
  category: number;
  reach: string;
  webSource: string;
  validityDate: string;
  published: boolean;
  _version: number;
  readyForDelivery: boolean;
  createdAt: Date;
  updatedAt: Date;
  reviewedAt: Date;
  fullContent: boolean;
  comment?: string;
  verified?: boolean;
  verifiedBy?: string;
  verificationSkipped?: boolean;
  verificationSkippedBy?: string;
  verificationSkippedAt?: Date;

  qc: any;
  originalLanguage?: string;
  ownerProduct: string;
}
