import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET, MediaType, Produces, Path, Query, PATCH, Body, POST } from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { ClinicalTrialProfile } from './clinical-trial-profile';
import { ClinicalTrialSite } from './site';
import { ClinicalTrialSiteMapping } from './site-mapping';
import { Job } from '../../jobs/shared/job';

@Injectable()
export class ClinicalTrialProfileAPI extends APIService {
  @GET('clinical-trial-profiles/ext-list')
  @Produces(MediaType.JSON)
  public findExtList(
    @Query('jobType') jobType?: string,
    @Query('nct') nct?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<
    { id; nct; category; priority; status; createdAt; updatedAt; curatedBy; curatedAt; qcBy; qcAt; ctrial }[]
  > {
    return;
  }

  @GET('clinical-trial-profiles/ext-list/count')
  @Produces(MediaType.JSON)
  public countExtList(
    @Query('jobType') jobType?: string,
    @Query('nct') nct?: string,
    @Query('filter') filter?: any
  ): Observable<{ count }> {
    return;
  }

  @GET('clinical-trial-profiles/categories')
  @Produces(MediaType.JSON)
  public categories(): Observable<string[]> {
    return;
  }

  @GET('clinical-trial-profiles/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<ClinicalTrialProfile> {
    return;
  }

  @GET('clinical-trial-profiles/obtain/{nct}')
  @Produces(MediaType.JSON)
  public obtain(@Path('nct') nct: string): Observable<ClinicalTrialProfile> {
    return;
  }

  @POST('clinical-trial-profiles/adhoc-qc/{id}')
  @Produces(MediaType.JSON)
  public adhocQc(@Path('id') id: string, @Query('curationType') curationType: string): Observable<Job | null> {
    return;
  }

  @PATCH('clinical-trial-profiles/{id}')
  @Produces(MediaType.JSON)
  public update(
    @Path('id') id: string,
    @Body event: Partial<ClinicalTrialProfile>,
    @Query('submitJob') submitJob?: boolean
  ): Observable<any> {
    return;
  }

  @POST('clinical-trial-sites/details')
  @Produces(MediaType.JSON)
  public findSitesByIds(@Body ids: string[]): Observable<ClinicalTrialSite[]> {
    return;
  }

  @GET('clinical-trial-site-mappings/by-nct')
  @Produces(MediaType.JSON)
  public findCtSiteMappings(@Query('ncts') ncts: string /*comma-sep*/): Observable<ClinicalTrialSiteMapping[]> {
    return;
  }

  @GET('clinical-trial-sites/obtain/{affiliationId}')
  @Produces(MediaType.JSON)
  public obtainSite(@Path('affiliationId') affiliationId: string): Observable<ClinicalTrialSite> {
    return;
  }

  /**
   * Find all the audit logs of a given model id
   * @param id
   */
  @GET('clinical-trial-profiles/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  /**
   * Find count of audit logs
   * @param id
   */
  @GET('clinical-trial-profiles/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }

  /**
   * Find affiliationId
   * @param kolId
   * @param facility
   */
  @GET('clinical-trial-profiles/affiliation/{kolId}/{facility}')
  @Produces(MediaType.JSON)
  public findAffiliation(@Path('kolId') kolId: string, @Path('facility') facility: string): Observable<any> {
    return;
  }
}
