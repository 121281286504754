import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[diMarkerWrapper]',
})
export class PersonMarkerWrapperDirective {
  @Input()
  set diMarkerWrapper(model: any) {
    if (typeof model === 'undefined') {
      throw new Error('Invalid model for marker wrapper!');
    }

    this.model = model;
  }

  @Input()
  suppressMarker: boolean = false;

  @Input()
  defaultFixed: boolean = false;

  private model: { [key: string]: any };

  getValue(fieldName: string): { comment: string; type: any[]; fixed?: boolean; checked?: boolean } {
    const qcVal = this.model?.qc && this.model.qc[fieldName];
    return qcVal ? JSON.parse(JSON.stringify(qcVal)) : qcVal; // make sure we work on a copy until we apply
  }

  apply(fieldName: string, comment: string, type?: any[], checked?: boolean): void {
    const qc = this.model?.qc || {};

    qc[fieldName] = {};

    if (comment) {
      qc[fieldName].comment = comment;
    }

    if (type) {
      qc[fieldName].type = type;
    }

    if (checked) {
      qc[fieldName].checked = true;
    } else {
      delete qc[fieldName].checked;
    }

    if (!qc[fieldName].comment && !qc[fieldName].type && !qc[fieldName].checked) {
      delete qc[fieldName];
    }

    Object.assign(this.model, { qc });
  }
}
