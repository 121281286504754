import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { SentimentComponent } from './sentiment.component';
import { SentimentNextComponent } from './next/next.component';
import { SentimentAPI } from './shared/api.service';
import { BulkListModule } from '../common/bulk-list/bulk-list.module';
import { sentimentRouting } from './sentiment.routes';
import { SentimentDetailComponent } from './detail/detail.component';
import { SentimentFormComponent } from './shared/form/form.component';
import { SentimentListComponent } from './list/list.component';

@NgModule({
  imports: [SharedModule, BulkListModule, sentimentRouting],
  declarations: [
    SentimentComponent,
    SentimentListComponent,
    SentimentDetailComponent,
    SentimentFormComponent,
    SentimentNextComponent,
  ],
  exports: [SentimentNextComponent],
  providers: [SentimentAPI],
})
export class SentimentModule {}
