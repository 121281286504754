export class Comment {
  public content: string;
  public owner: string;
  public user: string;
  public createdAt: string;
  public _id: string;
  public updatedBy: string;

  constructor() {}
}
