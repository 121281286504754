import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AffiliationAPI } from '../../api.service';
import { Affiliation } from '../../affiliation';

@Component({
  selector: 'dirt-merge',
  templateUrl: './merge.component.html',
  styleUrls: ['./merge.component.scss'],
})
export class AffiliationMergeComponent implements OnInit {
  options: { id: string; title: string };

  winnerId: string;
  winner: Affiliation;

  isInvalid = false;
  isLoading = false;

  constructor(public activeModal: NgbActiveModal, public readonly svcAffiliation: AffiliationAPI) {}

  onSearch(id: string): void {
    if (this.options.id === this.winnerId) {
      this.isInvalid = true;
      return;
    }

    this.isInvalid = false;
    this.isLoading = true;

    this.svcAffiliation.findById(id).subscribe(
      (affiliation: Affiliation) => (this.winner = affiliation),
      () => {
        this.isInvalid = true;
        this.isLoading = false;
      },
      () => (this.isLoading = false)
    );
  }

  ngOnInit() {}
}
