import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';

import { VideoJobComponent } from './video-job/video-job.component';
import { VideoComponent } from './video.component';
import { VideoModule } from './video.module';

const videoRoutes: Routes = [
  {
    path: 'video-job',
    component: VideoComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: 'detail/:id',
        component: VideoJobComponent,
      },
    ],
  },
];

export const videoRouting: ModuleWithProviders<VideoModule> = RouterModule.forChild(videoRoutes);
