export class Memo {
  public id: string;
  public user: string;
  public date: any;
  public hours: number;
  public task: string;
  public createdBy: any;

  constructor() {}
}
