import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GET,
  PUT,
  Query,
  Path,
  Body,
  MediaType,
  Produces,
  PATCH,
  POST,
} from '../../../../shared/services/rest/rest.service';
import { APIService } from '../../../../shared/services/api/api.service';

@Injectable()
export class ProfilePaymentNonusAPI extends APIService {
  @POST('profiles/{id}/payments-nonus')
  @Produces(MediaType.JSON)
  public find(
    @Path('id') id: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sortedOn') sortedOn?: string,
    @Query('sortedDirection') sortedDirection?: string,
    @Body filter?: any
  ): Observable<any> {
    return;
  }

  @GET('profiles/{id}/payments-nonus/count')
  @Produces(MediaType.JSON)
  public count(@Path('id') id: string, @Query('filter') filter?: any): Observable<{ count: number }> {
    return;
  }

  @GET('profiles/{id}/payments-nonus/distinct')
  @Produces(MediaType.JSON)
  public distinct(
    @Path('id') id: string,
    @Query('field') field: string,
    @Query('filter') filter?: string
  ): Observable<string[]> {
    return;
  }

  @PATCH('profiles/{id}/payments-nonus')
  @Produces(MediaType.JSON)
  public update(
    @Path('id') id: string,
    @Body paymentChg: { paymentIds; manual; comment?; errorType? }
  ): Observable<{ id; compiledAt? }[]> {
    return;
  } // match API

  @PUT('profiles/{id}/payments-nonus/rel/{fk}')
  @Produces(MediaType.JSON)
  public link(@Path('id') id: string, @Path('fk') paymentId: string | number): Observable<any> {
    return;
  }
}
