import { Component, Input, OnInit } from '@angular/core';
import { ValueAPI } from '../../../../shared/services/value/value-api.service';
import { Value } from '../../../../shared/services/value/value';
import { ValueType } from '../../../../shared/enum/value-type.enum';
import { take } from 'rxjs';
import { IMultiSelectOption, IMultiSelectSettings } from '../../../../shared/components/multiselect-dropdown/types';
import { AffiliationAdvancedSearchDto } from '../../affiliation-advanced-search-dto';

const CLS_MULTISELECT_BTN = 'btn btn-sm btn-secondary';

@Component({
  selector: 'dirt-affiliation-advanced-search',
  templateUrl: 'advanced-search.html',
  styleUrls: ['advanced-search.scss'],
})
export class AffiliationAdvancedSearchComponent implements OnInit {
  @Input()
  model: AffiliationAdvancedSearchDto;

  countries: Value[] = [];
  states: IMultiSelectOption[] = [];
  statesSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    selectionLimit: 1,
  };
  types: IMultiSelectOption[] = [];
  typesSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    selectionLimit: 1,
  };

  constructor(private readonly svcValue: ValueAPI) {}

  ngOnInit() {
    this.svcValue
      .find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title')
      .pipe(take(1))
      .subscribe((_c) => (this.countries = _c));
    this.svcValue
      .find(ValueType.AffiliationType, Number.MAX_SAFE_INTEGER, 0, '+title')
      .pipe(take(1))
      .subscribe((_c) => (this.types = _c.map((v) => ({ id: v.code, name: v.title }))));
    if (this.model.filter.countryCode) {
      this.loadCountryStates(this.model.filter.countryCode);
    }
  }

  loadCountryStates(countryCode: string) {
    this.svcValue
      .find(ValueType.CountryState, Number.MAX_SAFE_INTEGER, 0, '+title', { code: countryCode })
      .pipe(take(1))
      .subscribe((vs) => {
        if (vs.length > 0) {
          this.states = vs[0].value.map((v) => ({ id: v, name: v }));
        }
      });
  }

  onCountryChange(event: Event) {
    this.model.filter.state = null;
    this.loadCountryStates(this.model.filter.countryCode);
  }
}
