import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { GuidelineAuthorListComponent } from './list/list.component';
import { GuidelineAuthorFormComponent } from './shared/form/form.component';

@NgModule({
  imports: [SharedModule],
  declarations: [GuidelineAuthorListComponent, GuidelineAuthorFormComponent],
  exports: [GuidelineAuthorListComponent],
})
export class GuidelineAuthorModule {}
