<div class="modal-header">
  <h5 class="modal-title">{{ isEdit ? 'Edit Person' : 'Add Person' }}</h5>
</div>

<div class="modal-body">
  <ng-container *ngIf="!isEdit && !disablePersonForm">
    <input
      type="search"
      id="people-searchbar"
      class="di-search form-control ng-trim-ignore"
      [ngClass]="{ loading: isSearching }"
      [ngbTypeahead]="onSearchPeople"
      [focusFirst]="false"
      [resultTemplate]="searchResultTemplate"
      placeholder="Search..."
      [(ngModel)]="searchTerm"
      (selectItem)="onSelectPerson($event)"
    />

    <a href="javascript:void(0)" class="small pull-right pt-1 pr-2" (click)="onTogglePersonForm()"> Create Person </a>

    <br />
  </ng-container>

  <h6 *ngIf="!showPersonForm && selectedCommitteePerson">
    Person ID:
    <a
      [routerLink]="['/person/detail', selectedCommitteePerson.person._id]"
      rel="noopener noreferrer"
      target="_blank"
      >{{ selectedCommitteePerson.person.kolId }}</a
    >
  </h6>

  <ng-container *ngIf="selectedCommitteePerson">
    <dirt-person-form-inline
      [model]="selectedCommitteePerson.person"
      [disabledFields]="showPersonForm ? createDisabledFields : editDisabledFields"
      [displayCountry]="'MANDATORY'"
      [disabled]="disablePersonForm"
      (projectNamesChange)="projectNamesDidChange = true"
      (validityChange)="onFormValidityChange($event)"
    >
    </dirt-person-form-inline>
  </ng-container>

  <form #frmAdditional="ngForm">
    <ng-container *ngIf="selectedCommitteePerson">
      <!-- Position -->
      <div class="form-group">
        <label class="col-form-label">Standardized Position</label>
        <select
          class="form-control custom-select"
          name="position"
          [(ngModel)]="selectedCommitteePerson.position"
          [disabled]="disablePersonForm"
          required
        >
          <option *ngFor="let position of getPositions()" [value]="position.code">
            {{ position.title }}
          </option>
        </select>
      </div>

      <!-- Original Position -->
      <div class="form-group">
        <label class="col-form-label">Original Position</label>
        <input
          type="text"
          class="form-control"
          name="originalPosition"
          [(ngModel)]="selectedCommitteePerson.originalPosition"
          [disabled]="disablePersonForm"
        />
      </div>

      <!-- Probability -->
      <div class="form-group">
        <label class="col-form-label">Probability</label>
        <select
          class="form-control custom-select"
          name="probability"
          [(ngModel)]="selectedCommitteePerson.probability"
          [disabled]="disablePersonForm"
          required
        >
          <option *ngFor="let probability of committeePersonProbabilities" [value]="probability.code">
            {{ probability.title }}
          </option>
        </select>
      </div>

      <!-- CV Links -->
      <div
        class="form-group"
        *ngFor="let _ of selectedCommitteePerson.sourceUrls; let idx = index; trackBy: trackByIndex"
      >
        <label class="col-form-label" for="sourceUrls-{{ idx }}">{{
          idx === 0 ? 'Master Source Link' : 'Source Link'
        }}</label>
        <dirt-link
          [(url)]="selectedCommitteePerson.sourceUrls[idx]"
          name="sourceUrls-{{ idx }}"
          [form]="frmAdditional"
          [disabled]="disablePersonForm"
          [required]="idx === 0 && !isFieldEditable('primaryResearch')"
        >
          <button
            type="button"
            class="btn btn-secondary"
            (click)="pushItemToList(selectedCommitteePerson.sourceUrls)"
            *ngIf="idx === selectedCommitteePerson.sourceUrls.length - 1 && !disablePersonForm"
          >
            <i class="fa fa-plus-circle"></i>
          </button>

          <button
            type="button"
            class="btn btn-secondary"
            (click)="removeFromByIndex(selectedCommitteePerson.sourceUrls, idx)"
            *ngIf="idx > 0 && !disablePersonForm"
          >
            <i class="fa fa-minus-circle"></i>
          </button>
        </dirt-link>
      </div>

      <!-- Primary Research -->
      <div class="form-group primaryResearch-wrapper">
        <label class="col-form-label title">Primary Research</label>
        <ui-switch
          size="small"
          color="#6d5cae"
          id="toggle"
          class="ui-switch"
          [checked]="selectedCommitteePerson.hasPrimaryResearch === true"
          (change)="onPrimaryResearchSwitchChange($event)"
          [disabled]="!isFieldEditable('primaryResearch')"
          required
        >
        </ui-switch>

        <!-- All Primary Research fields -->
        <ng-container *ngIf="selectedCommitteePerson.hasPrimaryResearch">
          <!-- Result dropdown -->
          <div class="form-group">
            <label class="col-form-label">Result:</label>
            <select
              class="form-control custom-select"
              name="primaryResearch-result"
              [(ngModel)]="selectedCommitteePerson.primaryResearchInfo.result"
              [disabled]="!isFieldEditable('primaryResearch')"
              required
            >
              <option [ngValue]="null" disabled>Select the result</option>
              <option *ngFor="let result of primaryResearchResults | keyvalue" [value]="result.value">
                {{ result.value }}
              </option>
            </select>
          </div>

          <!-- Method dropdown -->
          <div class="form-group">
            <label class="col-form-label">Method to reach:</label>
            <select
              class="form-control custom-select"
              name="primaryResearch-method"
              [(ngModel)]="selectedCommitteePerson.primaryResearchInfo.method"
              (change)="onPrimaryResearchMethodChange()"
              [disabled]="!isFieldEditable('primaryResearch')"
              required
            >
              <option [ngValue]="null" disabled>Select the method</option>
              <option *ngFor="let method of primaryResearchMethods | keyvalue" [value]="method.value">
                {{ method.value }}
              </option>
            </select>
          </div>

          <!-- For Phone Method -->
          <ng-container *ngIf="selectedCommitteePerson.primaryResearchInfo.method === 'Phone'">
            <div class="form-group">
              <dirt-input-phone
                [model]="selectedCommitteePerson.primaryResearchInfo.details.phone"
                [form]="frmAdditional"
                [disabled]="!isFieldEditable('primaryResearch')"
                [required]="true"
              >
              </dirt-input-phone>
            </div>

            <div class="form-group">
              <label class="col-form-label">Phone-call Date</label>
              <input
                type="date"
                id="phone-date"
                class="form-control"
                [(ngModel)]="selectedCommitteePerson.primaryResearchInfo.details.phoneCallDate"
                [disabled]="!isFieldEditable('primaryResearch')"
                name="phone-date"
                required
              />
            </div>

            <div class="form-group">
              <label class="col-form-label">Call Report</label>
              <textarea
                maxlength="1000"
                class="form-control call-report"
                name="call-report"
                [(ngModel)]="selectedCommitteePerson.primaryResearchInfo.details.phoneCallReport"
                placeholder="Please, provide your report from the call..."
                [disabled]="!isFieldEditable('primaryResearch')"
                required
              ></textarea>
            </div>
          </ng-container>

          <!-- For Email Method -->
          <ng-container *ngIf="selectedCommitteePerson.primaryResearchInfo.method === 'Email'">
            <div class="form-group">
              <label class="col-form-label" for="email">Email Address</label>
              <input
                type="email"
                class="form-control"
                id="email"
                name="email"
                [(ngModel)]="selectedCommitteePerson.primaryResearchInfo.details.emailAddress"
                [disabled]="!isFieldEditable('primaryResearch')"
                placeholder="Please, enter the email address of the contact."
                validateEmail
                required
              />
            </div>

            <div class="form-group">
              <label class="col-form-label">Date information received</label>
              <input
                type="date"
                id="email-date"
                class="form-control"
                [(ngModel)]="selectedCommitteePerson.primaryResearchInfo.details.emailDate"
                [disabled]="!isFieldEditable('primaryResearch')"
                name="email-date"
                required
              />
            </div>

            <div class="form-group">
              <label class="col-form-label">Email Printout</label>
              <dirt-link
                [(url)]="selectedCommitteePerson.primaryResearchInfo.details.emailPrintout"
                name="email-printout"
                [form]="frmAdditional"
                [disabled]="!isFieldEditable('primaryResearch')"
                [required]="true"
                [placeholder]="'Please, provide a GDrive or Egnyte URL of the Email Printout pdf file'"
                [validateFormatOnly]="true"
              >
              </dirt-link>
            </div>
          </ng-container>

          <!-- For Document Method -->
          <ng-container *ngIf="selectedCommitteePerson.primaryResearchInfo.method === 'Document'">
            <div class="form-group">
              <label class="col-form-label">Document Title</label>
              <input
                type="text"
                class="form-control"
                name="doc-title"
                [ngModel]="selectedCommitteePerson.primaryResearchInfo.details.documentTitle"
                (ngModelChange)="selectedCommitteePerson.primaryResearchInfo.details.documentTitle = longDash($event)"
                [disabled]="!isFieldEditable('primaryResearch')"
                placeholder="Please, enter the title of the document."
                required
              />
            </div>

            <div class="form-group">
              <label class="col-form-label">Document Publish Date</label>
              <input
                type="date"
                id="doc-date"
                class="form-control"
                [(ngModel)]="selectedCommitteePerson.primaryResearchInfo.details.documentPublishDate"
                [disabled]="!isFieldEditable('primaryResearch')"
                name="doc-date"
                required
              />
            </div>

            <div class="form-group">
              <label class="col-form-label">Document host URL</label>
              <dirt-link
                [(url)]="selectedCommitteePerson.primaryResearchInfo.details.documentURL"
                name="doc-url"
                [form]="frmAdditional"
                [disabled]="!isFieldEditable('primaryResearch')"
                [required]="true"
                [placeholder]="'Please, provide an account website or GDrive/Egnyte URL for the pdf file.'"
                [validateFormatOnly]="true"
              >
              </dirt-link>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-outline-dark" [disabled]="isSearching" (click)="activeModal.dismiss()">Close</button>

  <ng-container *ngIf="!disablePersonForm">
    <ng-container *ngIf="!isEdit">
      <button
        class="btn btn-sm btn-success"
        [ngClass]="{ 'btn-loading': isSearching || isSaving }"
        [hidden]="!selectedCommitteePerson"
        [disabled]="isSearching || !isFormValid || isSaving"
        (click)="onAddPerson()"
        *ngIf="!showPersonForm"
      >
        Add
      </button>
      <button
        class="btn btn-sm btn-success"
        [ngClass]="{ 'btn-loading': isSearching || isSaving }"
        [disabled]="isSearching || !isFormValid || isSaving"
        (click)="onRequestPerson()"
        *ngIf="showPersonForm"
      >
        Request & Add
      </button>
    </ng-container>

    <ng-container *ngIf="isEdit">
      <button
        class="btn btn-sm btn-success"
        [ngClass]="{ 'btn-loading': isSearching || isSaving }"
        [disabled]="isSearching || !isFormValid || isSaving"
        (click)="onEditPerson()"
      >
        Save
      </button>
    </ng-container>
  </ng-container>
</div>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div class="di-sac-kol-id pull-right">{{ r.kolId }}</div>
  <div class="di-sac-name">
    <span>{{ r.firstName }} {{ r.middleName }} {{ r.lastName }}</span>
    <span *ngIf="r.originalFullName">({{ r.originalFullName }})</span>
    <span class="di-sac-status">{{ r._meta?.status || '-' | capitalize }}</span>
  </div>

  <div class="di-sac-affiliation-name" *ngIf="r.affiliationName">
    {{ r.affiliationName }}
  </div>
</ng-template>
