import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AccountAffiliationModalComponent } from './affiliation.component';
import { Affiliation } from '../../../../affiliation/shared/affiliation';
import { AccountAffiliationStatus } from '../../account-affiliation';

export interface AccountAffiliationModalOptions {
  topOnly: boolean;
  allMappedAffiliationIds: string[];
}

@Injectable()
export class AccountAffiliationModalService {
  constructor(private svcModal: NgbModal) {}

  open(options: AccountAffiliationModalOptions): Promise<{ type: AccountAffiliationStatus; data: Affiliation }> {
    const ref = this.svcModal.open(AccountAffiliationModalComponent);
    ref.componentInstance.options = options;
    return ref.result;
  }
}
