import { NgModule } from '@angular/core';

import { AffiliationModule } from '../affiliation/affiliation.module';
import { AssociationModule } from '../association/association.module';
import { CommentModule } from '../comment/comment.module';
import { DomainAPI } from './shared/domain-api.service';
import { DomainComponent } from './domain.component';
import { DomainCreateComponent } from './create/create.component';
import { DomainDetailComponent } from './detail/detail.component';
import { DomainFormComponent } from './shared/form/form.component';
import { DomainListComponent } from './list/list.component';
import { domainRouting } from './domain.routes';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [SharedModule, CommentModule, domainRouting, AssociationModule, AffiliationModule],
  declarations: [
    DomainComponent,
    DomainCreateComponent,
    DomainDetailComponent,
    DomainListComponent,
    DomainFormComponent,
  ],
  exports: [],
  providers: [DomainAPI],
})
export class DomainModule {}
