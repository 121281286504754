<section class="dirt-section-list dirt-user-pool-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>User Pools</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        placeholder="Search..."
        [formControl]="searchCtrl"
      />
    </div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Filter -->
        <dirt-filters [filters]="filters" (onFilter)="onFilter($event)"></dirt-filters>

        <!-- Create Button -->
        <button class="btn btn-success btn-sm" [routerLink]="['/user-pool/create']" *diAcl="'userPool.create'">
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Create User Pool
        </button>
      </div>
    </div>
  </header>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-pool-list'"
    [pagingSize]="pools?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Table -->
  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="pools?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-state">&nbsp;</th>
          <th class="col-name">Name</th>
          <th class="col-user-count">
            <span ngbTooltip="User Count">
              <i class="fa fa-list" aria-hidden="true"></i>
            </span>
          </th>
          <th class="col-created">Created</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-pool-row"
          *ngFor="
            let pool of pools
              | paginate
                : {
                    id: 'dirt-pool-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <td class="col-state">
            <span class="badge badge-info" *ngIf="pool.system" title="Managed by System">S</span>
            <span class="badge badge-danger" *ngIf="pool.disabled" title="Disabled">D</span>
          </td>
          <td class="col-name">
            <a [title]="pool.name" [routerLink]="['/user-pool/detail', pool.id]">{{ pool.name }}</a>
          </td>
          <td class="col-user-count">{{ pool.userCount | number }}</td>
          <td class="col-created">{{ pool.createdAt | date : 'dd.MM.y' }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No User Pools</p>
    </ng-template>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-pool-list'"
    [pagingSize]="pools?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
