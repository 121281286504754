<form (ngSubmit)="onSubmit()" #frmValue="ngForm" novalidate>
  <div class="modal-header">
    <h5 class="modal-title">Add Profile</h5>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label class="col-form-label" for="type">Type</label>
      <input type="text" id="type" name="type" class="form-control" [ngModel]="type" readonly />
    </div>
    <div class="form-group">
      <label class="col-form-label" for="code">Code</label>
      <input type="text" id="code" name="code" class="form-control" [ngModel]="code" readonly />
    </div>
    <div class="form-group">
      <label class="col-form-label" for="value">Value</label>
      <input
        type="text"
        id="value"
        name="value"
        class="form-control"
        [(ngModel)]="value"
        (ngModelChange)="deriveCode($event)"
        required
      />
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-secondary"
      [disabled]="isSubmitting"
      (click)="onCancelClick(); $event.stopPropagation()"
    >
      <span>Cancel</span>
    </button>
    <button
      type="submit"
      class="btn btn-primary"
      [ngClass]="{ 'btn-loading': isSubmitting }"
      [disabled]="!frmValue.form.valid || isSubmitting"
    >
      <span>Save</span>
    </button>
  </div>
</form>
