import { EventEmitter, HostListener, Input, OnInit, Output, Directive } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ColDef,
  ColGroupDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  RowNode,
} from 'ag-grid/main';
import { Key } from 'ts-keycode-enum';
import { ValueFormatterParams } from 'ag-grid/dist/lib/entities/colDef';
import { ACL } from '../../../shared/acl/acl.service';
import { ProfileMatchManual } from '../constant/match-manual.enum';
import { ProfileMatchAutomate } from '../constant/match-automate.enum';
import { ProfileGridMatchManualRendererComponent } from './renderer/match-manual-renderer.component';
import { CheckerRendererComponent } from './renderer/checker-renderer/checker-renderer.component';
import { TrainingReportDelegate } from '../../shared/training-report-delegate';
import { QCCommentService } from './qc-comment/qc-comment.service';
import { Profile } from '../profile';
import { ProfileViewMode } from '../constant/view-mode.enum';
import { ProfileDirectSource } from '../constant/direct-source.enum';

const MANUAL_MATCH = Object.keys(ProfileMatchManual);
const AUTOMATE_MATCH = Object.keys(ProfileMatchAutomate);

@Directive()
export abstract class ProfileGridComponent implements OnInit {
  FIELD_MATCH_MANUAL = '_profile.match.manual';
  FIELD_MATCH_AUTO = '_profile.match.automate';
  FIELD_MATCH_SOURCE = '_profile.match.matchSource';
  FIELD_UPDATED_AT = '_profile.compiledAt';
  FIELD_KEY_FA = '_profile.focusAreas';
  FILTER_KEY_MANUAL_MATCH = 'MANUAL_MATCH';
  FILTER_KEY_AUTOMATE_MATCH = 'AUTOMATE_MATCH';

  @Input('id')
  protected profileId: string;

  @Input('profile')
  protected profile: Profile;

  @Input('viewMode')
  protected profileViewMode: ProfileViewMode;

  @Input('modeQA')
  protected isQA: boolean;

  @Input('modeML')
  protected isML: boolean;

  @Input('sectionId')
  sectionId: string;

  @Input('trainingReportDelegates')
  trainingReportDelegates: TrainingReportDelegate;

  @Output()
  onProfileChanged = new EventEmitter<{ id; compiledAt? }[]>();

  @Output()
  onQAChanged = new EventEmitter<boolean>();

  @Output()
  onMLChanged = new EventEmitter<boolean>();

  @Output()
  onIsSavingChanged = new EventEmitter<boolean>();

  @Output()
  newTotalCount = new EventEmitter<{ total: number; matches: number }>();

  protected isOrigin: boolean = false; // we manage this ourselves

  filters = new Map<string, any>();
  autoSizeFields = new Set<string>();

  total = 0;
  pagingSize = 50;
  hasMore: boolean;
  isSaving = false;
  lastFilterModel = {};

  agGridApi: GridApi;
  agColApi: ColumnApi;

  agEnterpriseDataSource: IServerSideDatasource = {
    getRows: (params) => this.getRowsData(params),
  };

  defaultColDef: ColDef = {
    filterParams: {
      apply: true,
      filterOptions: ['contains'],
      newRowsAction: 'keep',
      suppressMiniFilter: true,
      suppressAndOrCondition: true,
    },
    menuTabs: ['filterMenuTab'],
    suppressMovable: true,
  };

  frameworkComponents = {
    matchManualRenderer: ProfileGridMatchManualRendererComponent,
    checkerRenderer: CheckerRendererComponent,
  };

  protected constructor(protected rowKeyId: string, protected svcACL: ACL, protected svcQCComment?: QCCommentService) {}

  ngOnInit() {
    this.addFilter(this.FILTER_KEY_MANUAL_MATCH, true, 'keep', true, MANUAL_MATCH);
    this.addFilter(this.FILTER_KEY_AUTOMATE_MATCH, true, 'keep', true, AUTOMATE_MATCH);
    this.addFilter(this.FIELD_UPDATED_AT, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_UPDATED_AT, params)
    );
  }

  getRowNodeId = (rowNode) => {
    const pathComponents = this.rowKeyId.split('.');
    let rowNodeId = rowNode;

    for (let i = 0; i < pathComponents.length; i++) {
      rowNodeId = rowNodeId[pathComponents[i]];
    }

    return rowNodeId;
  };

  getRowClass = (row) => {
    if ('PB' === row.data?._profile?.match?.automate) {
      return 'di-row-pb';
    } // (else stay undefined)
  };

  getContextMenuItems = () => {
    return ['copy'];
  };

  /**
   * Callback of Grid ready event.
   *
   * @param {GridReadyEvent} params
   */
  onGridReady(params: GridReadyEvent): void {
    this.agGridApi = params.api;
    this.agColApi = params.columnApi;
    this.agGridApi.setColumnDefs(this.getBaseColumns().concat(this.defineGrid()));
    this.agGridApi.sizeColumnsToFit();
  }

  /**
   * Reload the cached data in Grid.
   */
  reloadData(): void {
    this.agGridApi.setEnterpriseDatasource(this.agEnterpriseDataSource);
  }

  /**
   * Callback when a filter is changed and applied.
   */
  onFilterChange(): void {
    this.agGridApi.deselectAll();

    // NB! monkey-patch to hide the mini-filter menu once clicking on apply button!
    document.body.click();
  }

  /**
   * Get current grid's selection.
   *
   * @return {RowNode[]}
   */
  getSelection(): RowNode[] {
    if (!this.agGridApi) {
      return [];
    }
    const nodes = this.agGridApi.getSelectedNodes();
    return nodes.filter(
      (node) =>
        node.data?._profile?.match?.automate !== 'PB' &&
        node.data?._profile?.match?.directSource !== ProfileDirectSource.CT_CURATION
    );
  }

  /**
   * Checks whether any filter is present (excluding 'Manual Match')
   *
   * @return {boolean}
   */
  isFilterSet(): boolean {
    if (!this.agGridApi) {
      return false;
    }
    const model = this.agGridApi.getFilterModel();

    const len = Object.keys(model).length;
    return len > 1 || (len > 0 && !model[this.FIELD_MATCH_MANUAL]);
  }

  sortAlphabetically() {
    this.agGridApi.getSortModel();
    this.reloadData();
  }
  /**
   * Reset current filter of the grid.
   */
  resetFilter(): void {
    this.agColApi.getAllColumns().forEach((col) => {
      if (col.getColId() !== this.FIELD_MATCH_MANUAL) {
        this.agGridApi.destroyFilter(col);
      }
    });
  }

  /**
   * Checks whether filter is set on 'Manual Match' column.
   *
   * @return {boolean}
   */
  isUnpolishedFilterActive(): boolean {
    return (
      this.agGridApi &&
      this.agGridApi.getFilterInstance(this.FIELD_MATCH_MANUAL) &&
      this.agGridApi.getFilterInstance(this.FIELD_MATCH_MANUAL).isFilterActive()
    );
  }

  /**
   * Load only records with manual match field empty.
   *
   * @param force
   */
  toggleUnpolished(force?: boolean): void {
    if (force || !this.isUnpolishedFilterActive()) {
      this.agGridApi.getFilterInstance(this.FIELD_MATCH_MANUAL).setModel([ProfileMatchManual.NONE]);
    } else {
      this.agGridApi.destroyFilter(this.FIELD_MATCH_MANUAL);
    }

    this.agGridApi.onFilterChanged();
  }

  /**
   * Toggle manual match field's value.
   *
   * @param {boolean} matched
   */
  async toggleMatch(matched: boolean) {
    const hasQcPermission = this.svcACL.hasCredential('profile.qc');
    let comment: string;
    let errorType: string;

    if (hasQcPermission) {
      ({ comment, errorType } = await this.svcQCComment
        .open()
        .then((comment) => comment)
        .catch(() => {
          return { comment: undefined, errorType: undefined };
        }));
      if (!comment) {
        return;
      }
    }
    this.updateCommentAndProfileMatch(comment, matched, errorType);
  }

  /**
   * Set the isQA filter.
   */
  setQAEnabled(enabled: boolean): void {
    this.onQAChanged.emit(enabled);
    this.agGridApi.onFilterChanged();
  }

  setMLEnabled(enabled: boolean): void {
    this.onMLChanged.emit(enabled);
    this.agGridApi.onFilterChanged();
  }

  setOriginsEnabled(enabled: boolean): void {
    this.isOrigin = enabled; // (no need for events here)
    this.agGridApi.onFilterChanged();
  }

  protected abstract defineGetDataRows(
    profileId: string,
    limit: number,
    skip: number,
    sortedOn: string,
    sortedDirection: number,
    filterModel: any,
    includeOrigins?: boolean
  ): Observable<any>;

  protected abstract defineDistinct(profileId: string, field: string, filter: any): Observable<string[]>;

  protected abstract defineUpdate(
    profileId: string,
    ids: string[],
    value: { match: ProfileMatchManual; comment?: string; errorType?: string } | { match? }[] /*new outbox style*/
  ): Observable<{ id; compiledAt? }[]>;

  protected abstract defineGetFocusArea(profileId: string): Observable<any>;

  protected abstract defineGrid(): (ColDef | ColGroupDef)[];

  protected abstract defineAutoSizableColumns(): string[];

  protected abstract onDirectLink(): void;

  protected addFilter(
    name: string,
    apply: boolean,
    newRowsAction: string,
    suppressMiniFilter: boolean,
    values: string[] | Function
  ): void {
    this.filters.set(name, { apply, newRowsAction, suppressMiniFilter, values });
  }

  protected addAutoResizableColumn(columnName: string): void {
    this.autoSizeFields.add(columnName);
  }

  protected getDistinctValues(field: string, params: { success: Function }): void {
    const filter = this.agGridApi.getFilterModel();
    this.defineDistinct(this.profileId, field, filter).subscribe((result) => params.success(result));
  }

  /**
   * Detects the last filtered column
   *
   * @return {String|Null} Last filtered column or null in case of reset filter
   */
  protected detectLastFilteredColumn() {
    let lastFiltered;

    const model = this.agGridApi.getFilterModel();
    const fields = Object.keys(model || {});
    const lastFields = Object.keys(this.lastFilterModel);

    if (!this.lastFilterModel) {
      this.lastFilterModel = model;
      lastFiltered = fields[0];
    } else {
      if (fields.length < lastFields.length) {
        lastFiltered = null;
      } else {
        for (let i = 0; i < fields.length; ++i) {
          if (!lastFields.includes(fields[i])) {
            lastFiltered = fields[i];
          }
        }
      }
    }
    this.lastFilterModel = model;
    return lastFiltered;
  }

  /**
   * Automatically resize the columns based on their content
   *
   * @param {string[]} childIds
   */
  protected autoSizeColumns(childIds: string[]): void {
    let ids = [
      'checker', // Checkbox
      this.FIELD_MATCH_MANUAL, // Manual Match
      this.FIELD_MATCH_AUTO, // Automate Match
      // this.FIELD_KEY_FA
    ];

    ids = ids.concat(childIds);
    this.autoSizeFields.forEach((val) => ids.push(val));
    this.agColApi.autoSizeColumns(ids);
  }

  /**
   * Formatter to capitalize the content of a cell.
   *
   * @param {ValueFormatterParams} param
   * @return {string}
   */
  protected formatterMatchAutomate(param: ValueFormatterParams): string {
    const coreFmt = (param: { value }) => {
      switch (param.value) {
        case ProfileMatchAutomate.MATCH:
          return 'Match';

        case ProfileMatchAutomate.SUSPECT:
          return 'Suspect';

        case ProfileMatchAutomate.SUSPECT_1:
          return 'Double Name Suspect';

        default:
          return '';
      }
    };
    if ('PB' === param.data?._profile?.match?.automate) {
      if ((param.data?._profile?.match?.pbReal || 0) > 0) {
        return 'PB+' + (coreFmt({ value: param.data?._profile?.match?.pbRealMatch }) || 'real');
      } else {
        return 'PB';
      }
    }
    return coreFmt(param);
  }

  /**
   * Formatter to convert ISO date to DD.MM.YY.
   *
   * @param {ValueFormatterParams} param
   * @return {string}
   */
  protected formatterISODate(param: ValueFormatterParams): string {
    if (!param.value) {
      return '';
    }

    if (isNaN(Date.parse(param.value))) {
      return 'Invalid Date';
    }

    const dt = new Date(param.value);
    return dt.toLocaleDateString();
  }

  /**
   * Wrap the cell content into a <span> with title attribute to show as browser's native tooltip
   *
   * @param {ICellRendererParams} params
   * @return {string}
   */
  protected cellRendererTooltip(params: ICellRendererParams): string {
    return `<span title="${params.value}">${params.value}</span>`;
  }

  /**
   * Define base columns used by all sub-classes
   *
   * @return {Object}
   */
  getBaseColumns(): (ColDef | ColGroupDef)[] {
    return [
      {
        colId: 'checker',
        pinned: 'left',
        checkboxSelection: true,
        suppressMenu: true,
        suppressResize: true,
        maxWidth: 40,
        headerComponent: 'checkerRenderer',
        headerComponentParams: {
          onCheckChanged: (value: boolean) => {
            if (value) {
              this.agGridApi.getModel().forEachNode((node) => {
                node.setSelected(true);
              });
            } else {
              this.agGridApi.deselectAll();
            }
          },
        },
      },
      {
        headerName: 'Matching Status',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'Match',
            filter: 'agSetColumnFilter',
            pinned: 'left',
            field: this.FIELD_MATCH_MANUAL,
            filterParams: this.filters.get(this.FILTER_KEY_MANUAL_MATCH),
            cellClassRules: this.styleRuleMatch('mm'),
            cellRenderer: 'matchManualRenderer',
            maxWidth: 80,
            ...(this.trainingReportDelegates && {
              cellRendererParams: {
                getReport: this.trainingReportDelegates.getReportItem,
                updateReport: this.trainingReportDelegates.updateReportItem,
                showProfileDPTrainingReport: this.trainingReportDelegates.showProfileDPTrainingReport,
                hasTrainingFeaturesEnabled: this.trainingReportDelegates.hasTrainingFeaturesEnabled,
                updateQCComments: this.updateCommentAndProfileMatch.bind(this),
              },
            }),
          },
          {
            headerName: 'KIP',
            filter: 'agSetColumnFilter',
            pinned: 'left',
            field: this.FIELD_MATCH_AUTO,
            filterParams: this.filters.get(this.FILTER_KEY_AUTOMATE_MATCH),
            valueFormatter: this.formatterMatchAutomate,
            cellClassRules: this.styleRuleMatch('ma'),
            maxWidth: 80,
          },
          {
            headerName: 'Source',
            pinned: 'left',
            field: this.FIELD_MATCH_SOURCE,
            suppressMenu: true,
            columnGroupShow: 'open',
          },
        ],
      },
      {
        headerName: 'Updated At',
        pinned: 'left',
        field: this.FIELD_UPDATED_AT,
        filterParams: this.filters.get(this.FIELD_UPDATED_AT),
        valueFormatter: this.formatterISODate,
        maxWidth: 170,
      },
    ];
  }

  /**
   * Style the content of match cell.
   *
   * @param {string} matcher
   * @return {Object}
   */
  private styleRuleMatch(matcher: string): { [cssClassName: string]: string | Function } {
    const prefix = 'di-cell';
    const rules = {};

    rules[`${prefix}-${matcher}-match`] = (p) => p.value === ProfileMatchManual.MATCH;
    rules[`${prefix}-${matcher}-mismatch`] = (p) => p.value === ProfileMatchManual.MISMATCH;
    rules[`${prefix}-${matcher}-suspect`] = (p) =>
      [ProfileMatchAutomate.SUSPECT, ProfileMatchAutomate.SUSPECT_1].includes(p.value);

    return rules;
  }

  /**
   * Load the paginated data
   *
   * @param {IServerSideGetRowsParams} params
   */
  private getRowsData(params: IServerSideGetRowsParams): void {
    // bail-out early if there's a save in progress
    if (this.isSaving) {
      setTimeout(() => this.getRowsData(params), 100);
      return;
    }

    const { request } = params;
    const skip = request.startRow;
    const limit = request.endRow - skip;
    const filter = request.filterModel;
    let sortedOn;
    let sortedDirection;
    if (request.sortModel.length) {
      sortedOn = request.sortModel[0].colId ? request.sortModel[0].colId : undefined;
      sortedDirection = request.sortModel[0].sort ? (request.sortModel[0].sort === 'asc' ? 1 : -1) : undefined;
    }

    if (this.isQA) {
      filter.lastPolished = true;
    }

    if (!this.isML) {
      filter.mlExclude = false;
    }

    this.defineGetDataRows(
      this.profileId,
      250,
      skip,
      sortedOn,
      sortedDirection,
      request.filterModel,
      this.isOrigin
    ).subscribe(
      (result) => {
        this.total = result.total;
        this.hasMore = result.hasMore;
        this.newTotalCount.emit({ total: result.total, matches: result.matches });
        params.successCallback(result.content, this.total);
        this.autoSizeColumns(this.defineAutoSizableColumns());
      },
      () => params.failCallback()
    );
  }

  /**
   * Update the match status of a row.
   *
   * @param {RowNode[]} rowNodes
   * @param {string} value
   */
  protected update(
    rowNodes: RowNode[],
    value: { match: ProfileMatchManual; comment?: string; errorType?: string } | { match? }[] /*new outbox style*/
  ): void {
    this.isSaving = true;
    this.onIsSavingChanged.next(this.isSaving);

    const ids = rowNodes.map((n) => n.id);

    this.defineUpdate(this.profileId, ids, value).subscribe((resp) => {
      this.isSaving = false;
      this.onIsSavingChanged.next(this.isSaving);

      rowNodes.forEach((n) => {
        const idxRec = resp.findIndex((item) => item.id === n.id);

        if (idxRec > -1 && resp[idxRec].compiledAt) {
          n.setDataValue(this.FIELD_UPDATED_AT, resp[idxRec].compiledAt);
        }
      });

      this.onProfileChanged.emit(resp); // changed entries
    });
  }

  prevSelecteIndex = null;

  changeRowSelection() {
    const focusedCell = this.agGridApi.getFocusedCell();

    if (!focusedCell) {
      return;
    }

    const rowIndex = focusedCell.rowIndex;

    if (this.prevSelecteIndex != null) {
      this.agGridApi.deselectIndex(this.prevSelecteIndex);
    } else {
      const selectedRows = this.agGridApi.getSelectedNodes();
      if (selectedRows.length === 1) {
        this.agGridApi.deselectNode(selectedRows[0]);
      }
    }

    const node = this.agGridApi.getModel().getRow(rowIndex);
    if (this.agGridApi.isNodeSelected(node)) {
      this.prevSelecteIndex = null;
    } else {
      this.prevSelecteIndex = rowIndex;
    }
    this.agGridApi.selectIndex(rowIndex, true, false);
  }

  @HostListener('document:keydown.arrowdown', ['$event'])
  onArrowDown() {
    this.changeRowSelection();
  }

  @HostListener('document:keydown.arrowup', ['$event'])
  onArrowUp() {
    this.changeRowSelection();
  }

  @HostListener('document:keydown', ['$event'])
  private onKeyPress($event: KeyboardEvent) {
    // Don't mess around when user is typing in a filter input
    const inputs = ['input', 'textarea'];
    if (inputs.includes($event.target['nodeName'].toLowerCase())) {
      return;
    }

    if ($event.keyCode === Key.Escape) {
      this.agGridApi.deselectAll();
    }

    if ($event.keyCode === Key.Y) {
      this.toggleMatch(true);
    } else if ($event.keyCode === Key.N) {
      this.toggleMatch(false);
    }
  }

  deselectAll() {
    this.agGridApi.deselectAll();
  }

  /**
   * Updates the edited comment by QC
   *
   * @param {boolean} matched
   */
  updateCommentAndProfileMatch(comment: string, matched: boolean, errorType?: string): void {
    const nodes = this.getSelection();
    const match = matched ? ProfileMatchManual.MATCH : ProfileMatchManual.MISMATCH;

    nodes.forEach((node: RowNode) => {
      const data = Object.assign({}, node.data); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables

      data._profile.match.manual = match;

      // Check if _profile.qc exists, if not, create it
      if (!data._profile.qc) {
        data._profile.qc = {};
      }
      // even if comment and errorType are null we should set qc so that thebug icon disappears
      data._profile.qc = { comment, errorType };
      node.setData(data);
    });
    this.update(nodes, { match, comment, errorType });
  }

  matchedByCt() {
    const node = this.getSelection()[0];
    const data = Object.assign({}, node.data);
    return data._profile.match?.directSource === ProfileDirectSource.CT_CURATION;
  }
}
