import { Component, OnInit } from '@angular/core';

import { Auth } from '../../services/auth/auth.service';

@Component({
  selector: 'dirt-admin-header',
  templateUrl: 'header.component.html',
  styleUrls: ['header.component.scss'],
})
export class AdminHeaderComponent implements OnInit {
  isAffiliationModuleUser: boolean;

  constructor(private svcAuth: Auth) {}

  ngOnInit(): void {
    this.svcAuth.getProfile().subscribe((profile) => {
      this.isAffiliationModuleUser =
        profile.app_metadata.roles?.findIndex((role) => role.startsWith('AFFILIATION_')) >= 0;
    });
  }
}
