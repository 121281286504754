import { Component, Inject, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { BulkUpdateState } from '../../bulk-update.state';
import { Observable } from 'rxjs';
import { BulkUpdateModel } from '../../bulk-update.model';
import { Mode } from '../../mode.enum';
import { BulkUpdateOptionsProvider } from '../../bulk-update-options-provider';

@Component({
  selector: 'dirt-bulk-update-results-step',
  templateUrl: './bulk-update-results-step.component.html',
  styleUrls: ['./bulk-update-results-step.component.scss'],
})
export class BulkUpdateResultsStepComponent implements OnInit {
  Mode = Mode;

  @Select(BulkUpdateState.mode) mode$: Observable<Mode>;
  @Select(BulkUpdateState.updating) updating$: Observable<boolean>;
  @Select(BulkUpdateState.numberRecords) numberRecords$: Observable<number>;
  @Select(BulkUpdateState.numberDone) numberDone$: Observable<number>;
  @Select(BulkUpdateState.updatedCount) updatedCount$: Observable<number>;
  @Select(BulkUpdateState.errorCount) errorCount$: Observable<number>;
  @Select(BulkUpdateState.errors) errors$: Observable<
    Array<{
      entity: BulkUpdateModel;
      error: string;
    }>
  >;

  public titleFormatter: (entity: BulkUpdateModel) => string;

  constructor(public bulkUpdateOptionsProvider: BulkUpdateOptionsProvider) {}

  ngOnInit() {
    this.titleFormatter = this.bulkUpdateOptionsProvider.getOptions().titleFormatter;
  }
}
