import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { EventAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-event-next-hashtag',
  templateUrl: 'next-hashtag.component.html',
  styleUrls: ['next-hashtag.component.scss'],
})
export class EventNextHashtagComponent {
  isLoadingNext = false;

  constructor(private router: Router, private svcEvent: EventAPI) {}

  getNext(): void {
    this.isLoadingNext = true;

    this.svcEvent.findNextHashtag().subscribe(
      (event) => {
        if (!event || !event.id) {
          return alert('No events to be processed at the moment! Go and get some rest ;)');
        }

        this.router.navigate(['/event/detail', event.id]);
      },
      () => alert('Error!'),
      () => (this.isLoadingNext = false)
    );
  }
}
