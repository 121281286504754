import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ColDef } from 'ag-grid/main';
import { ColGroupDef } from 'ag-grid';
import { ValueFormatterParams } from 'ag-grid/dist/lib/entities/colDef';

import { ACL } from '../../../../shared/acl/acl.service';
import { Profile } from '../../../shared/profile';
import { ProfileGridComponent } from '../../../shared/grid/grid.component';
import { ProfileDirectLinkService } from '../../../shared/modal/direct-link/direct-link.service';
import { ProfileDirectLinkOptions } from '../../../shared/modal/direct-link/direct-link-options.interface';
import { Payment } from '../../../../payment/shared/payment';
import { PaymentAPI } from '../../../../payment/shared/payment-api.service';
import { ProfilePaymentAPI } from '../shared/api.service';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { QCCommentService } from '../../../shared/grid/qc-comment/qc-comment.service';

@Component({
  selector: 'dirt-profile-payment-us-list',
  templateUrl: '../../../shared/grid/grid.component.html',
  styleUrls: ['../../../shared/grid/grid.component.scss'],
})
export class ProfilePaymentListComponent extends ProfileGridComponent implements OnInit {
  FIELD_KEY_FA = '_profile.keyFocusAreas';
  FIELD_FIRST_NAME = 'person.firstName';
  FIELD_MIDDLE_NAME = 'person.middleName';
  FIELD_LAST_NAME = 'person.lastName';
  FIELD_SUFFIX = 'person.suffix';
  FIELD_PRIMARY_SPECIALTY = 'person.primarySpecialty';
  FIELD_STREET = 'address.street';
  FIELD_CITY = 'address.city';
  FIELD_STATE = 'address.state';
  FIELD_COUNTRY = 'address.country';

  FIELD_FILTER_FIRST_NAME = 'firstName';
  FIELD_FILTER_MIDDLE_NAME = 'middleName';
  FIELD_FILTER_LAST_NAME = 'lastName';
  FIELD_FILTER_SUFFIX = 'suffix';
  FIELD_FILTER_CITY = 'city';
  FIELD_FILTER_STATE = 'state';
  FIELD_FILTER_COUNTRY = 'country';

  constructor(
    protected svcProfilePayment: ProfilePaymentAPI,
    private svcPayment: PaymentAPI,
    private svcDirectLink: ProfileDirectLinkService,
    protected readonly svcACL: ACL,
    protected svcQCComment: QCCommentService
  ) {
    super('id', svcACL, svcQCComment);
  }

  ngOnInit() {
    this.addFilter(this.FIELD_FIRST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_FIRST_NAME, params)
    );
    this.addFilter(this.FIELD_MIDDLE_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_MIDDLE_NAME, params)
    );
    this.addFilter(this.FIELD_LAST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_LAST_NAME, params)
    );
    this.addFilter(this.FIELD_SUFFIX, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_SUFFIX, params)
    );
    this.addFilter(this.FIELD_CITY, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_CITY, params)
    );
    this.addFilter(this.FIELD_STATE, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_STATE, params)
    );
    this.addFilter(this.FIELD_COUNTRY, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_COUNTRY, params)
    );
    this.addFilter(this.FIELD_KEY_FA, true, 'keep', false, (params) =>
      this.defineGetFocusArea(this.profileId).subscribe((result) =>
        result && result[0] && result[0].focusArea
          ? params.success(result[0].focusArea?.map((val) => val.name))
          : params.success('')
      )
    );
    super.ngOnInit();
  }

  protected defineGetDataRows(...params): Observable<any> {
    return this.svcProfilePayment.find.apply(this.svcProfilePayment, params);
  }

  protected defineDistinct(profileId: string, field: string, filter: any): Observable<string[]> {
    return this.svcProfilePayment.distinct(profileId, field, filter);
  }

  protected defineUpdate(
    profileId: string,
    ids: string[],
    value: { match: ProfileMatchManual; comment?: string; errorType?: string }
  ): Observable<{ id; compiledAt? }[]> {
    return this.svcProfilePayment.update(profileId, {
      paymentIds: ids,
      manual: value.match,
      comment: value.comment,
      errorType: value.errorType,
    });
  }

  protected defineGetFocusArea(profileId: string): Observable<any> {
    return this.svcProfilePayment.distinctFocusArea(profileId);
  }

  protected defineGrid(): (ColDef | ColGroupDef)[] {
    const columnDefs: (ColDef | ColGroupDef)[] = [
      {
        headerName: 'Profile',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'First Name',
            field: this.FIELD_FIRST_NAME,
            filterParams: this.filters.get(this.FIELD_FIRST_NAME),
            width: 100,
          },
          {
            headerName: 'Middle Name',
            field: this.FIELD_MIDDLE_NAME,
            filterParams: this.filters.get(this.FIELD_MIDDLE_NAME),
            width: 90,
          },
          {
            headerName: 'Last Name',
            field: this.FIELD_LAST_NAME,
            filterParams: this.filters.get(this.FIELD_LAST_NAME),
            width: 100,
          },
          {
            headerName: 'Suffix',
            field: this.FIELD_SUFFIX,
            filterParams: this.filters.get(this.FIELD_SUFFIX),
            columnGroupShow: 'open',
            width: 70,
          },
          {
            headerName: 'Primary Specialty',
            field: this.FIELD_PRIMARY_SPECIALTY,
            // filterParams: this.filters.get(this.FIELD_PRIMARY_SPECIALTY),
            filter: 'text',
            width: 250,
          },
        ],
      },
      {
        headerName: 'Address',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'Street',
            field: this.FIELD_STREET,
            valueFormatter: this.formatterCase,
            filter: 'text',
            width: 150,
          },
          {
            headerName: 'City',
            field: this.FIELD_CITY,
            valueFormatter: this.formatterCase,
            filterParams: this.filters.get(this.FIELD_CITY),
            width: 70,
          },
          {
            headerName: 'State',
            field: this.FIELD_STATE,
            filterParams: this.filters.get(this.FIELD_STATE),
            width: 70,
          },
          {
            headerName: 'Country',
            field: this.FIELD_COUNTRY,
            valueFormatter: this.formatterCase,
            filterParams: this.filters.get(this.FIELD_COUNTRY),
            width: 100,
          },
        ],
      },
      {
        headerName: 'Focus Area',
        field: this.FIELD_KEY_FA,
        filterParams: this.filters.get(this.FIELD_KEY_FA),
        width: 500,
      },
    ];
    return columnDefs;
  }

  protected defineAutoSizableColumns(): string[] {
    return [];
  }

  protected onDirectLink(): void {
    const options = <ProfileDirectLinkOptions>{
      title: 'Payment',
      getEntity: (id) => this.svcPayment.findById(id),
      getData: (p: Payment) => ({ name: [p.lastName, p.firstName].join(', ') }),
    };

    this.svcDirectLink
      .open(options)
      .then((entity: Payment) => this.svcProfilePayment.link(this.profileId, entity.profileId).toPromise())
      .then(() => this.reloadData());
  }

  private formatterCase(param: ValueFormatterParams): string {
    if (!param.value) {
      return;
    }
    const array = param.value.split(' ').filter((el) => el.length !== 0);
    let str = '';
    for (let i = 0; i < array.length; i++) {
      str += array[i].length > 1 ? array[i].charAt(0) + array[i].slice(1).toLowerCase() : array[i].toUpperCase();
      if (i < array.length - 1) {
        str += ' ';
      }
    }
    return str;
  }
}
