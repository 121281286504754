import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  concatMap,
  distinctUntilChanged,
  map,
  startWith,
  Subject,
  takeUntil,
} from 'rxjs';
import { get, isEmpty, set } from 'lodash';
import { NgForm } from '@angular/forms';

import { ACL } from '../../../shared/acl/acl.service';
import { AddressComponent } from '../../../shared/components';
import { Affiliation } from '../../../affiliation/shared/affiliation';
import { Association } from '../../../association/shared/association';
import { Domain } from '../domain';
import { DomainAPI } from '../domain-api.service';
import { DomainJob } from '../constant/job.enum';
import { DomainSourceType } from '../constant/source-type.enum';
import { DomainTerms } from '../constant/terms.enum';
import { Roles } from '../../../shared/acl/roles';
import { Utils } from '../../../common/utils';

@Component({
  selector: 'dirt-domain-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  exportAs: 'frmDomain',
})
export class DomainFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('domain')
  model: Domain = new Domain();

  @Input()
  currentJobType?: DomainJob;

  @Output()
  validityChange: EventEmitter<'VALID' | 'INVALID'> = new EventEmitter();

  terms = DomainTerms;

  sourceTypes = DomainSourceType;

  isWorkingScreenshot$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  hasScreenshotError$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isDomainAdmin: boolean;

  @ViewChild(AddressComponent)
  addressComponent: AddressComponent;

  @ViewChild('form')
  private ngForm: NgForm;

  @ViewChild('screenshotInput')
  private screenshotInput: ElementRef;

  private wndw: Window = window; // allow for testing

  private destroy$: Subject<boolean> = new Subject();

  private protocolRegexp = new RegExp(/(^\w+:|^)\/\//);

  private associationsIdsValidityChange$ = new Subject<boolean>();

  private organizationIdsValidityChange$ = new Subject<boolean>();

  constructor(private svcAcl: ACL, private svcDomain: DomainAPI) {}

  ngOnInit(): void {
    this.isDomainAdmin = this.svcAcl.hasRole(Roles.Admin) || this.svcAcl.hasRole(Roles.DomainAdmin);

    this.initObjectFields();
    this.initMultiFields();
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.isWorkingScreenshot$,
      this.hasScreenshotError$,
      this.ngForm.statusChanges.pipe(startWith(null)),
      this.associationsIdsValidityChange$.pipe(startWith(null)),
      this.organizationIdsValidityChange$.pipe(startWith(null)),
    ])
      .pipe(
        takeUntil(this.destroy$),
        map(() => this.isValid()),
        distinctUntilChanged()
      )
      .subscribe((isValid) => {
        this.validityChange.emit(isValid ? 'VALID' : 'INVALID');
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
    this.isWorkingScreenshot$.complete();
    this.hasScreenshotError$.complete();
  }

  getValue(): Domain {
    const model = Object.assign({}, this.model);

    if (this.addressComponent) {
      model.address = this.addressComponent.getAddress();
    }

    if (this.model.sourceType === DomainSourceType.Association) {
      // If we display associations, get rid of organizations data
      this.model.organizationIds = [];
      this.model.organizationNames = [];
    } else if (model.sourceType && model.sourceType !== DomainSourceType.Other) {
      // We display organizations, clear association data
      this.model.associationIds = [];
      this.model.associationNames = [];
    } else {
      // clear it all
      this.model.organizationIds = [];
      this.model.organizationNames = [];
      this.model.associationIds = [];
      this.model.associationNames = [];
    }

    Utils.removeEmptyAndDuplicatedElementsFromArray(model, [
      'associationIds',
      'associationNames',
      'organizationIds',
      'organizationNames',
    ]);

    // We should not sent this to the server - it's too big for Nginx and we don't need it for updates
    delete model.screenshotPreview;

    return model;
  }

  isFieldEditable(field: string): boolean {
    const prefix = this.model.id ? 'update' : 'create';
    return this.svcAcl.hasCredential(`domain.${prefix}.prop.${field}`, this.currentJobType);
  }

  onScreenshotAdd(): void {
    if (!this.isFieldEditable('screenshot')) {
      return;
    }

    this.hasScreenshotError$.next(false);

    const file: File = this.screenshotInput.nativeElement.files?.[0];
    if (!file || this.isWorkingScreenshot$.value) {
      return;
    }

    const allowedFormats = ['image/png', 'image/jpeg', 'image/webp'];
    if (!allowedFormats.includes(file.type)) {
      this.hasScreenshotError$.next(true);
      return;
    }

    this.isWorkingScreenshot$.next(true);
    this.svcDomain
      .uploadScreenshot(this.model.id, file)
      .pipe(concatMap(() => this.svcDomain.findById(this.model.id)))
      .subscribe((domain) => {
        this.model = this.getMergedResponse(domain, this.model);
        this.isWorkingScreenshot$.next(false);
      });
  }

  onScreenshotRemoveRequest(): void {
    if (
      !this.isFieldEditable('screenshot') ||
      this.isWorkingScreenshot$.value ||
      !this.wndw.confirm('Delete the screenshot?')
    ) {
      return;
    }

    this.screenshotInput.nativeElement.value = ''; // reset input

    this.isWorkingScreenshot$.next(true);
    this.svcDomain
      .deleteScreenshot(this.model.id)
      .pipe(concatMap(() => this.svcDomain.findById(this.model.id)))
      .subscribe((domain) => {
        this.model = this.getMergedResponse(domain, this.model);
        this.isWorkingScreenshot$.next(false);
      });
  }

  onSourceAssociationSelected(association: Association): void {
    if (!this.model.sourceType || ![DomainSourceType.Association].includes(this.model.sourceType)) {
      return;
    }

    if (!this.model.associationIds.find((id) => id !== association.id)) {
      this.model.associationIds = [...this.model.associationIds.filter((a) => !!a), association];
      this.associationsIdsValidityChange$.next(true); // We are valid
    }

    if (Object.keys(this.model.address || {}).length > 0 && Object.keys(association.address || {}).length > 0) {
      let message = `Do you want to automatically fill contact address with selected association address? This will override the current address.`;
      message += `\nCurrent address: ${[
        this.model.address?.street,
        this.model.address?.city,
        this.model.address?.zip,
        this.model.address?.state,
        this.model.address?.countryCode,
      ]
        .filter((v) => !!v)
        .join(' ')}`;
      message += `\nAssociation address: ${[
        association.address?.street,
        association.address?.city,
        association.address?.zip,
        association.address?.state,
        association.address?.countryCode,
      ]
        .filter((v) => !!v)
        .join(' ')}`;

      if (!this.wndw.confirm(message)) {
        return;
      }
    }

    this.model.address = { ...(association.address || {}) };
  }

  onSourceOrganizationSelected(organization: Affiliation): void {
    if (
      !this.model.sourceType ||
      [DomainSourceType.Association, DomainSourceType.Other].includes(this.model.sourceType)
    ) {
      return;
    }

    if (!this.model.organizationIds.find((id) => id !== organization.id)) {
      this.model.organizationIds = [...this.model.organizationIds.filter((a) => !!a), organization];
      this.organizationIdsValidityChange$.next(true); // We are valid
    }

    if (Object.keys(this.model.address || {}).length > 0 && Object.keys(organization.address || {}).length > 0) {
      let message = `Do you want to automatically fill contact address with selected organization address? This will override the current address.`;
      message += `\nCurrent address: ${[
        this.model.address?.street,
        this.model.address?.city,
        this.model.address?.zip,
        this.model.address?.state,
        this.model.address?.countryCode,
      ]
        .filter((v) => !!v)
        .join(' ')}`;
      message += `\nOrganization address: ${[
        organization.address?.street,
        organization.address?.city,
        organization.address?.zip,
        organization.address?.state,
        organization.address?.countryCode,
      ]
        .filter((v) => !!v)
        .join(' ')}`;

      if (!this.wndw.confirm(message)) {
        return;
      }
    }

    this.model.address = { ...(organization.address || {}) };
  }

  onAcceptPendingUpdate(key: string): void {
    set(this.model, key, this.model.pendingUpdates[key]); // apply the change
    delete this.model.pendingUpdates[key];
  }

  onRejectPendingUpdate(key: string): void {
    delete this.model.pendingUpdates[key];
  }

  onOpenURL(url: string): void {
    if (!url.match(/^https?:\/\//i)) {
      // Don't touch protocol if we have it (http or https), otherwise, try to fix it ourselves
      url = 'http://' + url.replace(this.protocolRegexp, ''); // remove protocols if any
    }
    window.open(url, '_blank', 'noopener noreferrer'); // force link to be opened externally
  }

  onSourceTypeChange(): void {
    if (this.model.sourceType === DomainSourceType.Association) {
      this.associationsIdsValidityChange$.next(true);
    } else if (this.model.sourceType && this.model.sourceType !== DomainSourceType.Other) {
      this.organizationIdsValidityChange$.next(true);
    } else {
      this.associationsIdsValidityChange$.next(true);
      this.organizationIdsValidityChange$.next(true);
    }
  }

  onAddFreeTextAssociationName(): void {
    this.associationsIdsValidityChange$.next(true);
  }

  onAddFreeTextOrganizationName(): void {
    this.organizationIdsValidityChange$.next(true);
  }

  async onOpenScreenshotPreview(): Promise<void> {
    if (!this.model.screenshotPreview) {
      return;
    }

    // Chrome does not support loading data URLs directly in a new tabs
    const res = await fetch(this.model.screenshotPreview);
    const blob = await res.blob();
    const url = URL.createObjectURL(blob);

    this.wndw.open(url);
  }

  needSourceType(): boolean {
    return ![DomainTerms.NoRestrictions].includes(this.model.terms); // for copyright symbol (and restrictive), source type really makes a diff, so capture it; keep capturing it for sure exclusions (no change to before)
  }
  needProoflink(): boolean {
    return ![DomainTerms.CopyrightSymbol, DomainTerms.NoRestrictions].includes(this.model.terms);
  }

  trackByIndex(index: number): number {
    return index;
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }

  private isValid(): boolean {
    let screenshotValid = true;
    if (this.model.id) {
      screenshotValid =
        !this.hasScreenshotError$.value &&
        !this.isWorkingScreenshot$.value &&
        (this.model.terms === DomainTerms.RestrictiveTerms || !!this.model.screenshotUrl);
    }
    const contactValid =
      this.model.sourceType === DomainSourceType.Other ||
      Object.keys(this.model.address || {}).length > 0 ||
      !!this.model.email?.trim();

    let isValid = this.ngForm.form.valid && screenshotValid && contactValid;

    if (this.addressComponent) {
      isValid = isValid && this.addressComponent.isValid();
    }

    return isValid && this.getAssociationValidity() && this.getOrganizationValidity();
  }

  private getMergedResponse(params: Partial<Domain>, original: Domain): Domain {
    original.screenshotUrl = params.screenshotUrl;
    original.updatedAt = params.updatedAt;
    original._version = params._version;

    return original;
  }

  private initObjectFields(): void {
    const fields = ['address'];

    fields.forEach((field) => {
      if (isEmpty(get(this.model, field))) {
        set(this.model, field, {});
      }
    });
  }

  private initMultiFields(): void {
    const fields = ['associationIds', 'organizationIds'];

    fields.forEach((field) => {
      if (!Array.isArray(this.model[field]) || this.model[field].length === 0 || this.model[field].every((v) => !v)) {
        this.model[field] = [''];
      }
    });

    const fieldsJustArray = ['associationNames', 'organizationNames'];
    fieldsJustArray.forEach((field) => {
      if (!Array.isArray(this.model[field])) {
        this.model[field] = [];
      }
    });
  }

  /** Valid when type is set to something else than ASSOCIATION or when we have details already */
  private getAssociationValidity(): boolean {
    return (
      !this.model.sourceType ||
      this.model.sourceType !== DomainSourceType.Association ||
      this.model.associationIds?.filter((a) => !!a)?.length > 0 ||
      this.model.associationNames?.filter((a) => !!a)?.length > 0
    );
  }

  /** Valid when type is set to something else than ASSOCIATION and OTHER or when we have details already */
  private getOrganizationValidity(): boolean {
    return (
      !this.model.sourceType ||
      this.model.sourceType === DomainSourceType.Association ||
      this.model.sourceType === DomainSourceType.Other ||
      this.model.organizationIds?.filter((a) => !!a)?.length > 0 ||
      this.model.organizationNames?.filter((a) => !!a)?.length > 0
    );
  }
}
