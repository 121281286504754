<section class="dirt-section-list dirt-comment-list" diScroller>
  <div *ngIf="ownerId && enableCreate">
    <dirt-comment-create [ownerId]="ownerId" (onSubmitted)="updateList($event)" [hidden]="isLoading">
    </dirt-comment-create>
  </div>

  <div *ngIf="comments" [hidden]="isLoading">
    <div class="media" *ngFor="let comment of comments; trackBy: comment?.id">
      <img
        class="d-flex rounded-circle mr-3"
        [src]="comment.user.picture"
        [alt]="comment.user.email"
        *ngIf="comment.user.user_id"
      />
      <img class="d-flex rounded-circle mr-3" src="assets/blank-profile.png" alt="User" *ngIf="!comment.user.user_id" />
      <div class="media-body">
        <h5 class="mt-0">
          <span class="text-muted mt-1 pull-right">{{ comment.createdAt | date }}</span>
          <dirt-username [user]="comment.user" [displayUserBadge]="true"></dirt-username>
        </h5>
        <p [innerHTML]="comment.content | linky" *ngIf="selectedCommentId !== comment.id"></p>
        <div *ngIf="showNavToItem"><a class="alink" (click)="navToItem.emit({ id: comment.owner })">Go to Item</a></div>
        <div *ngIf="ownerId && enableCreate && selectedCommentId === comment.id">
          <dirt-comment-create
            [ownerId]="ownerId"
            (onUpdated)="endUpdateComment()"
            [existingContent]="comment.content"
            [hidden]="isLoading"
            [commentId]="comment.id"
          >
          </dirt-comment-create>
        </div>
        <div *ngIf="!editCommentIsActive && selectedCommentId !== comment.id">
          <span *ngIf="comment.updatedBy" class="comment-author">
            <i>
              <small class="form-text text-muted">
                Last edited by {{ comment.updatedBy.user_metadata.firstName }}
                {{ comment.updatedBy.user_metadata.lastName }}
              </small>
            </i>
          </span>
          <div class="actions">
            <!-- Edit -->
            <span *ngIf="enableUpdate" class="action-btn action-edit" (click)="onEditClicked(comment.id)">
              <i class="fa fa-pencil" aria-hidden="true"></i>
            </span>
            <!-- Delete -->
            <span *ngIf="enableDelete" class="action-btn action-delete" (click)="onDeleteClicked(comment.id)">
              <i class="fa fa-trash-o" aria-hidden="true"></i>
            </span>
          </div>
        </div>
        <div *ngIf="editCommentIsActive && selectedCommentId === comment.id">
          <button class="btn btn-sm btn-outline-primary" type="button" (click)="onCancelEdit()">cancel</button>
        </div>
      </div>
    </div>

    <!-- Empty Message -->
    <p class="dirt-no-results" *ngIf="!comments.length">No Comments</p>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
