import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import { get } from 'lodash';

import { ACL } from '../../../shared/acl/acl.service';
import { Event } from '../../../event/shared/event';

@Component({
  selector: 'dirt-event-series-event-list',
  templateUrl: 'event-list.component.html',
  styleUrls: ['./event-list.component.scss'],
})
export class EventSeriesEventList implements OnInit {
  @Input()
  seriesId: string;

  @Input()
  events: Event[];

  @Input()
  currentJobType?: string;

  @Output()
  eventsChange: EventEmitter<Event[]> = new EventEmitter();

  displayedEvents: Event[];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 30;
  pagingSkip = 0;

  // Sorting / Filtering settings
  searchCtrl: FormControl = new FormControl('');

  private searchTerm: string;

  private sort = '-startDate';

  private destroy$: Subject<boolean> = new Subject();

  constructor(private svcACL: ACL) {}

  ngOnInit(): void {
    this.getPage(1);

    this.searchCtrl.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(400), takeUntil(this.destroy$))
      .subscribe((value) => {
        if (typeof value === 'undefined') return;

        this.searchTerm = value.trim().toLowerCase();
        this.resetPagination();
        this.getPage(1);
      });
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.onSort(this.sort);
  }

  onSort(field: string): void {
    this.sort = field;

    const offset = (this.pagingPage - 1) * this.pagingLimit;
    const order = field?.substring(0, 1) === '-' ? -1 : 1;
    const key = field?.substring(1);

    let events = this.events;
    if (this.searchTerm) {
      // get what's matching our search term
      events = events.filter((author) => author.name?.toLowerCase().includes(this.searchTerm));
    }

    if (this.sort) {
      // only sort when we got a sort key, otherwise returns as received
      events = events.sort((a, b) => {
        const valueA = get(a, key);
        const valueB = get(b, key);

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          return order === 1 ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
        }

        return valueA > valueB ? order : -order;
      });
    }

    this.displayedEvents = events.slice(offset, offset + this.pagingLimit);
  }

  onDetachEvent(eventId: string): void {
    if (!this.svcACL.hasCredential('eventSeries.update.prop.event')) {
      return;
    }

    if (!window.confirm('Do you want to detach this event from the series?')) {
      return;
    }

    this.events = this.events.filter((event: any) => event._id !== eventId);
    this.getPage(1);

    this.eventsChange.emit(this.events);
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }
}
