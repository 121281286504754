<div class="modal-header">
  <h4 class="modal-title">New Association</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('dismiss')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form #form="ngForm" novalidate>
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Parent Association Name</label>
      <div class="col-sm-8 col-md-10">
        <div *ngIf="parentId && parentAssociation">
          <span
            >Association:
            <a href="/association/detail/{{ parentAssociation.id }}" target="_blank">{{
              parentAssociation.name
            }}</a></span
          >
          <span class="action-btn action-delete" title="Remove Parent" (click)="onParentAssociationDisconnected()">
            <i class="fa fa-trash-o" aria-hidden="true"></i>
          </span>
        </div>

        <dirt-association-single-select
          [wide]="true"
          [rootOnly]="true"
          (associationSelected)="onParentAssociationSelected($event)"
        ></dirt-association-single-select>
      </div>
    </div>

    <!-- Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Name</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          class="form-control"
          name="name"
          [(ngModel)]="model.name"
          autofocus
          required
          longDash
          dirtPasteNormalizer
        />
      </div>
    </div>

    <!-- Original Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Original Name</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          class="form-control"
          name="originalName"
          [(ngModel)]="model.originalName"
          longDash
          dirtPasteNormalizer
        />
      </div>
    </div>

    <!-- Base Web Sources -->
    <ng-container *ngIf="!parentId">
      <div class="form-group row" *ngFor="let link of model.webSources; let idx = index; trackBy: trackByIndex">
        <label class="col-form-label col-sm-4 col-md-2">Base Web Source</label>
        <div class="col-sm-8 col-md-10">
          <dirt-link
            [(url)]="model.webSources[idx]"
            name="webSources-{{ idx }}"
            [form]="fromAssociation"
            [nonCompliantDomainType]="nonCompliantDomainType"
            [required]="true"
          >
            <button
              type="button"
              class="btn btn-secondary"
              (click)="pushItemToList(model.webSources)"
              *ngIf="idx == model.webSources.length - 1"
            >
              <i class="fa fa-plus-circle"></i>
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              (click)="removeFromByIndex(model.webSources, idx)"
              *ngIf="idx > 0 && idx <= 3"
            >
              <i class="fa fa-minus-circle"></i>
            </button>
          </dirt-link>
        </div>
      </div>
    </ng-container>

    <!-- Member Web Sources -->
    <div class="form-group row" *ngFor="let link of model.memberWebSources; let idx = index; trackBy: trackByIndex">
      <label class="col-form-label col-sm-4 col-md-2">Member Web Source</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.memberWebSources[idx]"
          name="memberWebSources-{{ idx }}"
          [form]="ngForm"
          [nonCompliantDomainType]="nonCompliantDomainType"
        >
          <button
            type="button"
            class="btn btn-secondary"
            (click)="pushItemToList(model.memberWebSources)"
            *ngIf="idx == model.memberWebSources.length - 1"
          >
            <i class="fa fa-plus-circle"></i>
          </button>

          <button
            type="button"
            class="btn btn-secondary"
            (click)="removeFromByIndex(model.memberWebSources, idx)"
            *ngIf="idx > 0 && idx <= 3"
          >
            <i class="fa fa-minus-circle"></i>
          </button>
        </dirt-link>
      </div>
    </div>

    <!-- Country -->
    <div class="form-group row" *ngIf="!parentId">
      <label class="col-form-label col-sm-4 col-md-2">Country</label>
      <div class="col-md-3">
        <select class="form-control custom-select" name="country" [(ngModel)]="model.address.countryCode">
          <option *ngFor="let country of countries$ | async" [value]="country.code">
            {{ country.title }}
          </option>
        </select>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="pull-right">
    <button type="button" class="btn btn-secondary btn-sm" (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="onSave()" [disabled]="isSaving || !form.valid">
      Create & Attach
    </button>
  </div>
</div>
