import { Component, EventEmitter, OnDestroy, Output, Input, OnInit } from '@angular/core';

import { CustomFilterComponent, FilterValue } from '../../../../shared/components/filters/filters.component';

interface SelectedFilterValue {
  [name: string]: any;
}
export interface SubMenuFilterValue extends FilterValue {
  key: string;
  value: boolean;
  subOptions?: { key: string; title: string; value: boolean }[];
}

@Component({
  selector: 'dirt-sub-menu-filter',
  templateUrl: './sub-menu-filter.component.html',
  styleUrls: ['./sub-menu-filter.component.scss'],
})
export class SubMenuFilterComponent implements OnInit, OnDestroy, CustomFilterComponent {
  @Input()
  values: SubMenuFilterValue[];

  @Input()
  value: string;

  @Output()
  valueChange = new EventEmitter<string>();

  rawValue: SelectedFilterValue = {};
  visibleSubPanel: string;

  ngOnInit(): void {
    if (typeof this.value === 'string') {
      try {
        this.rawValue = JSON.parse(this.value);
      } catch (error) {
        console.error(`Initial value parsing failed, ${JSON.stringify(error)}`);
      }
    } else {
      this.rawValue = {};
    }
    this.visibleSubPanel = '';
  }

  ngOnDestroy(): void {
    this.valueChange.complete();
  }

  doCancel(previousValue: SelectedFilterValue): void {
    this.rawValue = previousValue;
  }

  doClear(): void {
    this.rawValue = {};
  }

  onRawValueChange(): void {
    this.valueChange.next(JSON.stringify(this.rawValue));
  }

  setSubPanel(subPanel: string): void {
    this.visibleSubPanel = subPanel;
  }

  isValueSelected(option: SubMenuFilterValue): boolean {
    return this.rawValue?.[option.key] === option.value;
  }

  toggleMenuSelection(option: SubMenuFilterValue) {
    if (this.rawValue?.[option.key] === option.value) {
      delete this.rawValue?.[option.key];
      return;
    }

    this.rawValue[option.key] = option.value;

    if (option.subOptions?.length) {
      this.setSubPanel(option.key);
    }
    this.onRawValueChange();
  }

  toggleSubMenuSelection(option: SubMenuFilterValue) {
    if (this.rawValue?.[option.key] === option.value) {
      delete this.rawValue?.[option.key];
      return;
    }
    this.rawValue[option.key] = option.value;
    this.onRawValueChange();
  }

  getSubpanelOptions() {
    return (this.values.find((item) => item.key === this.visibleSubPanel) || {}).subOptions || [];
  }
}
