import { ProfileAPI } from '../api.service';
import { Profile } from '../profile';
import { FieldType } from '../../../common/bulk-update/field.config';
import { ProfileTag } from '../constant/profile-tag.enum';
import { ProfileViewMode } from '../constant/view-mode.enum';
import { filterBarConfig } from './filter-bar-config';
import { Roles } from '../../../shared/acl/roles';
import { FieldValue } from '../../../common/bulk-update/field-value';
import { BulkUpdateOptions } from '../../../common/bulk-update/bulk-update-options';
import { enumToDataObject, rangeOfNumbers } from '../utils';
import { ProfileStatus } from '../constant/status.enum';

export const weekRange = rangeOfNumbers(4, 1);
export const monthRange = rangeOfNumbers(12, 1);
export const priorityRange = rangeOfNumbers(5, 0);
export const profileStatusValues = enumToDataObject(ProfileStatus);
export const profileTagValues = enumToDataObject(ProfileTag);
export const profileViewModeValues = enumToDataObject(ProfileViewMode, true);

export function titleFormatter(entity: Profile) {
  return (
    (entity.person.firstName ? `${entity.person.firstName} ` : '') +
    (entity.person.middleName ? `${entity.person.middleName} ` : '') +
    (entity.person.lastName || '')
  );
}

export function handleAssigneeCheck(fieldsValues: FieldValue[]) {
  const status = fieldsValues.find((field) => field.config.field === '_meta.status');
  return status && status.value === ProfileStatus.COMPILATION_IN_PROGRESS;
}

export function getPriorityRangeValues() {
  return priorityRange;
}

export function getProfileStatusValues() {
  return profileStatusValues;
}

export function getProfileTagValues() {
  return profileTagValues;
}

export function getProfileViewModeValues() {
  return profileViewModeValues;
}

export function buildBulkUpdateConfig(srvApi: ProfileAPI): BulkUpdateOptions<ProfileAPI, Profile> {
  return {
    srvApi: srvApi,
    titleFormatter: titleFormatter,
    fields: [
      {
        field: 'priority',
        label: 'Priority',
        required: true,
        type: FieldType.select,
        values: getPriorityRangeValues,
      },
      {
        field: '_meta.status',
        label: 'Status',
        required: true,
        type: FieldType.select,
        values: getProfileStatusValues,
      },
      {
        field: 'assignees',
        label: 'Assignee',
        required: true,
        type: FieldType.multiUser,
        roles: [Roles.ProfileCompiler],
        hideCheck: true,
        handleCheck: handleAssigneeCheck,
      },
      {
        field: 'tags',
        label: 'Followed Tag',
        type: FieldType.multiSelect,
        values: getProfileTagValues,
      },
      {
        field: 'viewMode',
        label: 'View Mode',
        required: true,
        type: FieldType.select,
        values: getProfileViewModeValues,
      },
    ],
    filterBarConfig,
  };
}
