import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { MemoComponent } from './memo.component';
import { MemoListComponent } from './list/list.component';
import { MemoFormComponent } from './shared/form/form.component';

import { memoRouting } from './memo.routes';
import { MemoAPI } from './shared/memo-api.service';

@NgModule({
  imports: [SharedModule, memoRouting],
  declarations: [MemoComponent, MemoListComponent, MemoFormComponent],
  exports: [MemoListComponent],
  providers: [MemoAPI],
})
export class MemoModule {}
