<form novalidate>
  <div class="clearfix">
    <div *ngIf="mode == 'Mapping'; else editNormalizedNames">
      <!-- Original Name -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="originalName">Original Name</label>
        <div class="col-sm-4 col-md-6">
          <input
            type="text"
            id="originalName"
            class="form-control"
            name="originalName"
            [(ngModel)]="model.originalName"
            autofocus
            required
          />
        </div>
        <div class="col-sm-2 col-md-4">
          <button type="button" class="btn btn-sm btn-outline-primary" (click)="searchSimilarNames()">
            Search Similar Names
          </button>
        </div>
      </div>

      <!-- Normalized Name -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="normalizedName">Normalized Name</label>
        <div class="col-sm-4 col-md-6">
          <ng-select
            name="normalizedNames"
            [items]="existingSponsors"
            [virtualScroll]="true"
            bindLabel="normalizedName"
            bindValue="normalizedName"
            placeholder="Select Sponsor"
            [(ngModel)]="model.normalizedName"
            [multiple]="false"
            [hideSelected]="true"
            (change)="updateCompanyId($event)"
            [hidden]="showNewNormalizedNameInput"
          >
          </ng-select>
          <input
            type="text"
            id="normalizedName"
            class="form-control"
            name="normalizedName"
            [(ngModel)]="model.normalizedName"
            [hidden]="!showNewNormalizedNameInput"
            (blur)="handleNewNormalizedName($event)"
            autofocus
          />
        </div>
      </div>

      <!-- Company Id -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="companyId">Company Id</label>
        <div class="col-sm-4 col-md-6">
          <ng-select
            name="companyIds"
            [items]="existingSponsors"
            [virtualScroll]="true"
            bindLabel="companyId"
            [disabled]="showNewNormalizedNameInput"
            bindValue="companyId"
            placeholder="Select Company Id"
            [(ngModel)]="model.companyId"
            [multiple]="false"
            [hideSelected]="true"
            (change)="updateCompanyId($event)"
            [required]="model.normalizedName && !model.companyId"
          >
          </ng-select>
        </div>
      </div>

      <!-- Add New Company Switch -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="new_sponsor">{{ 'Add new company' }}</label>
        <div class="col-sm-8 col-md-10">
          <ui-switch
            size="small"
            name="newSponsor"
            [ngModel]="showNewNormalizedNameInput"
            (change)="handleNewCompanySwitch($event)"
          ></ui-switch>
        </div>
      </div>
    </div>

    <!-- Edit Normalized Names Form -->
    <ng-template #editNormalizedNames>
      <!-- Normalized Name -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="normalizedName">Normalized Name</label>
        <div class="col-sm-4 col-md-6">
          <ng-select
            name="normalizedNames"
            [items]="existingSponsors"
            [virtualScroll]="true"
            bindLabel="normalizedName"
            bindValue="normalizedName"
            placeholder="Select Sponsor"
            [(ngModel)]="model.normalizedName"
            [multiple]="false"
            [hideSelected]="true"
            (change)="handleNormalizedNameChanged($event)"
          >
          </ng-select>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="normalizedName">New Name</label>
        <div class="col-sm-4 col-md-6">
          <input
            type="text"
            id="editedNormalizedName"
            class="form-control"
            name="editedNormalizedName"
            [(ngModel)]="editedNormalizedName"
            autofocus
          />
        </div>
      </div>
    </ng-template>
  </div>
</form>

<!-- Suggestions Table -->
<div [hidden]="!showSuggestions">
  <table class="table table-sm table-hover">
    <thead>
      <tr>
        <th>Original Name</th>
        <th>Normalized Name</th>
        <th>Company Id</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let suggestion of suggestions">
        <td>{{ suggestion.originalName }}</td>
        <td>{{ suggestion.normalizedName }}</td>
        <td>{{ suggestion.companyId }}</td>
      </tr>
    </tbody>
  </table>
</div>
