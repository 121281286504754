import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Auth } from '../shared/services/auth/auth.service';
import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';

import { UserComponent } from './user.component';
import { UserListComponent } from './list/list.component';
import { UserDetailComponent } from './detail/detail.component';
import { UserCreateComponent } from './create/create.component';
import { UserLoginComponent } from './login/login.component';
import { UserLoginGuard } from './login/login-guard.service';
import { UserModule } from './user.module';

const userRoutes: Routes = [
  {
    path: '',
    component: UserComponent,
    children: [
      {
        path: 'user',
        canActivate: [AuthGuard],
        canActivateChild: [AclGuard],
        children: [
          {
            path: 'create',
            component: UserCreateComponent,
            data: { acl: 'user.create' },
          },
          {
            path: 'detail/:id',
            component: UserDetailComponent,
            data: { acl: 'user.detail' },
          },
          {
            path: '',
            component: UserListComponent,
            data: { acl: 'user.list' },
          },
        ],
      },
    ],
  },
  {
    path: 'login',
    component: UserLoginComponent,
    canActivate: [UserLoginGuard],
  },
];

export const authProviders = [AuthGuard, AclGuard, UserLoginGuard, Auth];

export const userRouting: ModuleWithProviders<UserModule> = RouterModule.forChild(userRoutes);
