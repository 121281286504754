<div class="clearfix">
  <form #ngForm="ngForm" novalidate>
    <!-- Year -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="year">Year</label>
      <div class="col-md-3">
        <input
          type="number"
          id="year"
          class="form-control"
          name="year"
          [(ngModel)]="model.year"
          [readonly]="!isFieldEditable('year')"
          min="1900"
          autofocus
          required
          integerValidator
        />
      </div>
    </div>

    <!-- Quarter -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="quarter">Quarter</label>
      <div class="col-md-3">
        <input
          type="number"
          id="quarter"
          class="form-control"
          name="quarter"
          min="0"
          max="4"
          [(ngModel)]="model.quarter"
          [readonly]="!isFieldEditable('quarter')"
          required
          integerValidator
        />
      </div>
    </div>

    <!-- Annual Revenue -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="annualRevenue">Annual Revenue</label>
      <div class="col-md-3">
        <input
          type="number"
          id="annualRevenue"
          class="form-control"
          name="annualRevenue"
          [(ngModel)]="model.annualRevenue"
          [readonly]="!isFieldEditable('annualRevenue')"
        />
      </div>
    </div>

    <!-- Quarterly Revenue -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="annualRevenue">Quarterly Revenue</label>
      <div class="col-md-3">
        <input
          type="number"
          id="quarterlyRevenue"
          class="form-control"
          name="quarterlyRevenue"
          [(ngModel)]="model.quarterlyRevenue"
          [readonly]="!isFieldEditable('quarterlyRevenue')"
        />
      </div>
    </div>

    <!-- Annual Net Result Profit -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="annualNetResultProfit">Annual Net Result Profit</label>
      <div class="col-md-3">
        <input
          type="number"
          id="annualNetResultProfit"
          class="form-control"
          name="annualNetResultProfit"
          [(ngModel)]="model.annualNetResultProfit"
          [readonly]="!isFieldEditable('annualNetResultProfit')"
        />
      </div>
    </div>

    <!-- Annual Net Result Loss -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="annualNetResultLoss">Annual Net Result Loss</label>
      <div class="col-md-3">
        <input
          type="number"
          id="annualNetResultLoss"
          class="form-control"
          name="annualNetResultLoss"
          [(ngModel)]="model.annualNetResultLoss"
          [readonly]="!isFieldEditable('annualNetResultLoss')"
        />
      </div>
    </div>

    <!-- Quarterly Net Result Profit -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="quarterlyNetResultProfit">Quarterly Net Result Profit</label>
      <div class="col-md-3">
        <input
          type="number"
          id="quarterlyNetResultProfit"
          class="form-control"
          name="quarterlyNetResultProfit"
          [(ngModel)]="model.quarterlyNetResultProfit"
          [readonly]="!isFieldEditable('quarterlyNetResultProfit')"
        />
      </div>
    </div>

    <!-- Quarterly Net Result Loss -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="quarterlyNetResultLoss">Quarterly Net Result Loss</label>
      <div class="col-md-3">
        <input
          type="number"
          id="quarterlyNetResultLoss"
          class="form-control"
          name="quarterlyNetResultLoss"
          [(ngModel)]="model.quarterlyNetResultLoss"
          [readonly]="!isFieldEditable('quarterlyNetResultLoss')"
        />
      </div>
    </div>

    <!-- Reporting Currency -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="reportingCurrency">Reporting Currency</label>
      <div class="col-md-3">
        <select
          id="reportingCurrency"
          class="form-control custom-select"
          name="reportingCurrency"
          [(ngModel)]="model.reportingCurrency"
          [disabled]="!isFieldEditable('reportingCurrency')"
        >
          <option *ngFor="let currency of currencies" [value]="currency.code">
            {{ currency.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Employees Count -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="employeeCount">Employees Count</label>
      <div class="col-md-3">
        <input
          type="number"
          id="employeeCount"
          class="form-control"
          name="employeeCount"
          [(ngModel)]="model.employeeCount"
          [readonly]="!isFieldEditable('employeeCount')"
          integerValidator
        />
      </div>
    </div>

    <!-- Websources -->
    <div class="form-group row" *ngFor="let affLink of model.websources; let idx = index; trackBy: trackByIndex">
      <label class="col-form-label col-sm-4 col-md-2" for="websources-{{ idx }}">Websource</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.websources[idx]"
          name="websources-{{ idx }}"
          [form]="ngForm"
          [disabled]="!isFieldEditable('websources')"
        >
          <button
            type="button"
            class="btn btn-secondary"
            (click)="pushItemToList(model.websources)"
            *ngIf="idx == model.websources.length - 1 && isFieldEditable('websources')"
          >
            <i class="fa fa-plus-circle"></i>
          </button>

          <button
            type="button"
            class="btn btn-secondary"
            (click)="removeFromByIndex(model.websources, idx)"
            *ngIf="idx > 0 && isFieldEditable('websources')"
          >
            <i class="fa fa-minus-circle"></i>
          </button>
        </dirt-link>
      </div>
    </div>
  </form>
</div>
