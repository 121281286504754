import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Value } from '../../../../shared/services/value/value';
import { ValueNotInListModalComponent } from './not-in-list.component';
import { ValueType } from '../../../../shared/enum/value-type.enum';

@Injectable()
export class ValueNotInListModalService {
  constructor(private svcModal: NgbModal) {}

  open(type: ValueType, normalizeCode: boolean): Promise<Value> {
    const ref = this.svcModal.open(ValueNotInListModalComponent);
    ref.componentInstance.type = type;
    ref.componentInstance.normalizeCode = normalizeCode;
    return ref.result;
  }
}
