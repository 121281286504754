export enum Source {
  YOUTUBE = 'YOUTUBE',
  ECANCER = 'ECANCER',
}

export enum CompilerComment {
  NOT_RELEVANT = 'not_relevant',
  TO_REMOVE = 'to_remove',
  EMPTY = '',
}

export class Video {
  public id: string;
  public vendorId: string;
  public source: Source;
  public publicationDate?: Date;
  public title?: string;
  public thumbnailUrl?: string;
  public description?: string;
  public compilerComment?: CompilerComment;
  public originalLanguage?: string;

  public createdAt: Date;
  public updatedAt: Date;
  public _version: number;

  constructor() {}
}
