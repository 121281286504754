import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIService } from '../api/api.service';
import { GET, MediaType, Produces, Query } from '../rest/rest.service';

@Injectable()
export class TranslateAPI extends APIService {
  @GET('translation/')
  @Produces(MediaType.JSON)
  translate(@Query('value') value: string): Observable<{ value: string }> {
    return;
  }
}
