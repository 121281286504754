import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dirt-modal-json-viewer',
  templateUrl: 'json-viewer.component.html',
  styleUrls: ['json-viewer.component.scss'],
})
export class JsonViewerComponent {
  options: { title: string; content: any };

  constructor(public activeModal: NgbActiveModal) {}
}
