export class OrganizationAdvancedSearchDto {
  filter: {
    address: {
      state?: string;
      city?: string;
      name?: string;
      houseNo?: string;
      zip?: string;
      countryCode?: string;
    };
    organizationName?: string;
    organizationId?: string;
    websource?: string;
  };
  sort?: string;
  sortDesc?: boolean;
  page?: number;
  size?: number;
  searchWithinHealthsystem: string;
}
