import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject } from 'rxjs';
import { format } from 'date-fns';

import { ACL } from '../../shared/acl/acl.service';
import { Event } from '../../event/shared/event';
import { EventSeries } from '../shared/event-series';
import { EventSeriesAPI } from '../shared/api.service';
import { Filter, FiltersComponent, FilterType } from '../../shared/components/filters/filters.component';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { JobsAPI } from '../../jobs/shared/api.service';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkModalComponent, BULK_MODAL_OPTIONS } from '../../common/bulk/component/bulk-modal/bulk-modal.component';

@Component({
  selector: 'dirt-event-series-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class EventSeriesListComponent implements OnInit {
  eventSeries: Array<EventSeries>;

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 25;
  pagingSkip = 0;

  // Sorting / Filtering settings
  searchCtrl: FormControl = new FormControl('');
  filter: { [key: string]: any };
  filters: Filter[] = [];
  filterBarConfig = {
    noInfo: {
      label: 'No Info',
      format: (value: string) => {
        return value === 'true' ? 'Yes' : 'No';
      },
    },
    'maintained.at': {
      label: 'Maintained at',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
  };

  jobBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import Job',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListJobUpload.bind(this),
    specificNotifyReloadFct: this.getEventSeries.bind(this),
  };

  private searchTerm: string;

  private sort = '-_id';

  @ViewChild(FiltersComponent, { static: false })
  private filterRef: FiltersComponent;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private svcEventSeries: EventSeriesAPI,
    private router: Router,
    private route: ActivatedRoute,
    private svcACL: ACL,
    private svcModal: NgbModal,
    private svcJob: JobsAPI
  ) {}

  ngOnInit(): void {
    this.filters.push(
      {
        title: 'No Info',
        key: 'noInfo',
        type: FilterType.SINGLE_VALUE,
        values: [
          { title: 'true', displayValue: 'Yes' },
          { title: 'false', displayValue: 'No' },
        ],
      },
      {
        title: 'Maintained at',
        key: 'maintained.at',
        type: FilterType.DATE_RANGE,
      }
    );

    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params.searchTerm) {
        this.searchTerm = params.searchTerm;
        this.searchCtrl.setValue(this.searchTerm, { emitEvent: false }); // restore search term
      }
      if (params.sortField) {
        this.sort = params.sortField;
      }

      this.doLoad();
    });

    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.searchTerm = term;
        this.doLoad();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /** just avoid useless rendering if we can */
  trackById(_: number, eventSeries: EventSeries): string {
    return eventSeries.id;
  }

  navigateTo(id: string): void {
    if (!id) {
      return;
    }

    this.router.navigate(['/event-series/detail', id]);
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getEventSeries();
  }

  onSort(field: string): void {
    this.sort = field;

    this.storeFiltersAsQueryParams();
    this.getEventSeries();
  }

  onDelete(eventSeriesId: string): void {
    if (!this.svcACL.hasCredential('eventSeries.delete')) {
      return;
    }

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.svcEventSeries.deleteById(eventSeriesId).subscribe(() => {
      this.getEventSeries();
      this.getEventSeriesCount();
    });
  }

  onRemoveEvent(eventSeries: EventSeries, event: Event): void {
    if (!this.svcACL.hasCredential('eventSeries.update.prop.event')) {
      return;
    }

    if (!window.confirm('Do you want to detach this event from the series?')) {
      return;
    }

    const index = eventSeries.events.indexOf(event);
    eventSeries.events.splice(index, 1);

    this.svcEventSeries.update(eventSeries.id, eventSeries).subscribe(() => {
      this.getEventSeries();
      this.getEventSeriesCount();
    });
  }

  onFilter(filter: { [key: string]: any }): void {
    this.filter = filter;
    this.doLoad();
  }

  onFilterClear(): void {
    this.filterRef.doClear();
  }

  onFilterValueRemove(item: { key: string; value: any }): void {
    this.filterRef.removeValue(item.key, item.value);
  }

  private doLoad(): void {
    this.storeFiltersAsQueryParams();
    this.resetPagination();
    this.getEventSeries();
    this.getEventSeriesCount();
  }

  private storeFiltersAsQueryParams(): void {
    if (this.searchTerm || this.sort) {
      const filters = {
        ...(this.searchTerm && { searchTerm: this.searchTerm }),
        ...(this.sort && { sortField: this.sort }),
      };
      this.router.navigate([], { queryParams: filters });
    }
  }

  private getEventSeries(): void {
    this.isLoading = true;

    this.svcEventSeries
      .find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.filter)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe({
        next: (res) => (this.eventSeries = res),
        error: () => (this.isLoading = false),
      });
  }

  private getEventSeriesCount(): void {
    this.svcEventSeries.count(this.searchTerm, this.filter).subscribe((res) => (this.total = res));
  }

  resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private handleBulkListJobUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcJob.startBulkListEventSeriesJobUpload(file, secondEyes);
  }

  onOpenBulkListJobDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.jobBulkListDelegate;
  }
}
