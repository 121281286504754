import { APIService } from '../../../shared/services/api/api.service';
import { Injectable } from '@angular/core';
import { firstValueFrom, from, Observable } from 'rxjs';
import { Page } from '../../../shared/values/Page';
import { Profile } from '../../shared/profile';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ACL } from '../../../shared/acl/acl.service';
import { ViewModeFilter } from './view-mode-filter';
import { TOKEN_NAME } from '../../../shared/services/auth/auth.constants';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Roles } from '../../../shared/acl/roles';

@Injectable()
export abstract class SubentityApiService<
  T extends {
    id;
    mongoId?;
    match?: { mlExclude?; mlAnyway?; pendingSubmission?; direct?; manual; automate };
    qc?: { comment?; errorType? };
    focusAreas?;
    compiledAt?;
  },
  TP extends { id; _profile: T & { mongoId?; focusAreaIds? } },
  TC extends { manual; comment?; errorType? } | { match? }[] /*new outbox style*/,
  LNK
> extends APIService {
  constructor(http: HttpClient, svcModal: NgbModal, protected svcAcl: ACL, private svcJwtHelper: JwtHelperService) {
    super(http, svcModal, svcJwtHelper);
  }

  private currentProfile = null;
  private currentRawList: TP[] = [];
  private loadingPromise = null;
  private currentRawOriginsList: TP[] = null;
  private loadingOriginsPromise = null;

  protected abstract findAll(profile: Profile): Observable<T[]>; // we can re-load from like link when we have this sep
  protected abstract enrichAll(profile: Profile, allList: T[]): Promise<TP[]>;
  private async ensureLoaded(profile: Profile) {
    if (this.currentProfile?.id === profile.id) {
      return; // we're loaded already
    }

    if (this.loadingPromise) {
      await this.loadingPromise; // (we only have one open at a time)
      return; // (we'll have loaded now)
    }

    let _resolve;
    this.loadingPromise = new Promise((resolve) => (_resolve = resolve));

    let allList: T[];
    try {
      allList = await firstValueFrom(this.findAll(profile));

      // for compilers outside training and (usual) view mode delta, we could speed up (ViewModeFilter will _always_ filter out the same ones) - load way less base-info etc around the globe:
      if (
        this.svcAcl.userRoles?.includes(Roles.ProfileCompiler) &&
        !profile.training &&
        profile.viewMode?.endsWith('_DELTA')
      ) {
        allList = allList.filter((item) => item.match?.manual === 'NONE' || item.match?.pendingSubmission);
      }
      // TODO: might do a similar optimization for QA as well

      // Can exclude those if user cannot see them - load way less base-info etc around the globe:
      if (!this.svcAcl.hasCredential('profile.ml')) {
        allList = allList.filter(
          (item) => !item.match?.mlExclude || item.match?.mlAnyway || item.match?.pendingSubmission
        );
      }

      // Else take the full list
    } catch (error) {
      this.handleError({
        status: 500,
        error: { verbatim: true, message: 'Failed to load matches. Please refresh the page.' },
      } as any);
      throw new Error('Failed to load matches');
    }

    try {
      this.currentRawList = await this.enrichAll(profile, allList);
      this.currentProfile = profile;
      this.currentRawOriginsList = null;
      this.loadingPromise = null;
      _resolve(); // (however many are waiting)
    } catch (error) {
      this.handleError({
        status: 500,
        error: { verbatim: true, message: 'Failed to enrich matches. Please refresh the page.' },
      } as any);
      throw new Error('Failed to enrich matches');
    }
  }

  protected async loadAndEnrichOrigins(profileId: string, kolId: string): Promise<TP[]> {
    return []; // per se: nothing - but can overwrite
  }
  private async ensureOriginsLoaded(profile: Profile) {
    if (this.currentProfile?.id !== profile.id) {
      throw new Error('Need normal profile first');
    }
    if (this.currentRawOriginsList !== null) {
      return; // already have it
    }
    if (this.loadingOriginsPromise) {
      await this.loadingOriginsPromise; // (we only have one open at a time)
      return; // (we'll have loaded now)
    }
    let _resolve;

    try {
      this.loadingOriginsPromise = new Promise((resolve) => (_resolve = resolve));
      this.currentRawOriginsList = await this.loadAndEnrichOrigins(profile.id, profile.person.kolId);
      this.loadingOriginsPromise = null;
      _resolve(); // (however many are waiting)
    } catch (error) {
      this.handleError({
        status: 500,
        error: { verbatim: true, message: 'Failed to load origin matches. Please refresh the page.' },
      } as any);
      throw new Error('Failed to load origin matches');
    }
  }

  // mimick existing functions

  // mimick most of the "old" methods by loading all and applying client-side filter or sort
  // (if we cache filtered, we need to make filter a cononical json ({a:1, b:2} is equal to ({b:2, a:1})
  // AND we need to re-compute when updates happen; as updates happen: not sure there's lots of value

  protected useAsiaFas(): boolean {
    return false; // override for publications
  }
  protected async baseFilterList(profile: Profile, list: TP[]): Promise<TP[]> {
    // allow override for test only;
    const useAsiaFas = this.useAsiaFas();
    // determine whether we show training feedback
    let user = 'unknown';
    try {
      const token = this.svcJwtHelper.decodeToken(localStorage.getItem(TOKEN_NAME));
      user = token['https://veeva.io/user_id'] || token.user_id; // still support the old way
    } catch (_e) {}
    const traineeView =
      profile.training &&
      profile.trainingEvaluationReport &&
      profile._meta.status === 'DONE' &&
      profile.polishedBy === user;
    // ok, we can invoke
    return list.filter((item) =>
      ViewModeFilter(item, profile.viewMode, this.svcAcl.userRoles, useAsiaFas, traineeView)
    );
  }

  private async filterList(profile: Profile, list: TP[], filter): Promise<TP[]> {
    if (false === filter.mlExclude) {
      list = list.filter((l) => !l._profile?.match?.mlExclude || l._profile?.match?.mlAnyway);
    }
    if (true === filter.lastPolished) {
      list = list.filter((l) => l._profile.compiledAt >= profile.lastAssignmentDate);
    }
    if (filter['match.manual']?.values) {
      list = list.filter((l) => filter['match.manual'].values.includes(l._profile?.match?.manual));
    }
    // filter by value (column match value)
    Object.entries(filter)
      .filter(([key]) => !['match', 'mlExclude', 'lastPolished'].includes(key.split('.')[0]))
      .filter(([key, spec]) => (spec as any)?.values)
      .forEach(([key, spec]) => {
        const steps = key.split('.');
        list = list.filter((l) => {
          let val: any = l;
          steps.forEach((prop) => {
            val = val?.[prop];
          });
          if (val && Array.isArray(val)) {
            return !!val.find((valItem) => (spec as any).values.includes(valItem));
          } else {
            if (!val) {
              // (Blanks) filter
              return (
                (spec as any).values.includes('') ||
                (spec as any).values.includes(undefined) ||
                (spec as any).values.includes(null)
              );
            }
            return (spec as any).values.includes(val);
          }
        });
      });
    // filter by text match (column contains text)
    Object.entries(filter)
      .filter(([key]) => !['match', 'mlExclude', 'lastPolished'].includes(key.split('.')[0]))
      .filter(([key, spec]) => (spec as any)?.filterType === 'text' && (spec as any)?.filter)
      .forEach(([key, spec]) => {
        const steps = key.split('.');
        const filterLower = (spec as any).filter.toLowerCase();

        list = list.filter((l) => {
          let val: any = l;
          steps.forEach((prop) => {
            val = val?.[prop];
          });
          if (Array.isArray(val)) {
            val = val.join('#');
          }
          return val && val.toLowerCase().includes(filterLower);
        });
      });
    // others likewise, poss. lookup pubs from hash by pmid
    return list;
  }

  public find(
    profile: Profile,
    limit?: number,
    skip?: number,
    sortedOn?: string,
    sortedDirection?: string,
    filter?: any,
    includeOrigins?: boolean
  ): Observable<Page<TP> & { matches: number }> {
    return from(
      (async () => {
        await this.ensureLoaded(profile);
        if (includeOrigins) {
          await this.ensureOriginsLoaded(profile);
        }
        let list = this.currentRawList;
        if (includeOrigins) {
          list = [...this.currentRawOriginsList, ...list];
        }
        list = await this.baseFilterList(profile, list);
        if (filter) {
          list = await this.filterList(profile, list, filter);
        }
        if (sortedOn) {
          const dir = '-1' == sortedDirection ? -1 : 1;
          const steps = sortedOn.split('.');
          list.sort((l1, l2) => {
            let l1Val: any = l1;
            let l2Val: any = l2;
            steps.forEach((prop) => {
              l1Val = l1Val?.[prop];
              l2Val = l2Val?.[prop];
            });
            if (Array.isArray(l1Val)) {
              l1Val = l1Val.join('#');
            }
            if (Array.isArray(l2Val)) {
              l2Val = l2Val.join('#');
            }
            return dir * (l1Val || '').toString().localeCompare((l2Val || '').toString(), { numeric: true }); // We want to handle everything, not just strings
          });
        }
        const fromIncl = skip || 0;
        const toExcl = limit ? (skip || 0) + limit : list.length;
        const totalCount = list.length;
        const matchesCount = list.filter(
          (l) =>
            l._profile.match?.manual === 'MATCH' ||
            (l._profile.match?.manual === 'NONE' && l._profile.match?.automate === 'MATCH')
        ).length;
        const res = { total: totalCount, matches: matchesCount, content: list.slice(fromIncl, toExcl) };
        return res;
      })()
    );
  }

  public count(profile: Profile, filter?: any): Observable<{ count: number }> {
    return from(
      (async () => {
        await this.ensureLoaded(profile);
        let list = this.currentRawList;
        list = await this.baseFilterList(profile, list);
        if (filter) {
          list = await this.filterList(profile, list, filter);
        }
        return { count: list.length };
      })()
    );
  }

  public distinct(profile: Profile, field: string, filter?: string): Observable<string[]> {
    return from(
      (async () => {
        await this.ensureLoaded(profile);
        let list = this.currentRawList;
        list = await this.baseFilterList(profile, list);
        if (filter) {
          list = await this.filterList(profile, list, filter);
        }
        const dist = {};
        let steps = field.split('.');
        list.forEach((l) => {
          let val: any = l;
          steps.forEach((prop) => {
            val = val?.[prop];
          });
          if (Array.isArray(val)) {
            val = val.join('#');
          }
          if (val) {
            dist[val] = true;
          }
        });
        return [...Object.keys(dist), null /* (Blanks) */].sort();
      })()
    );
  }

  public distinctFocusArea(profile: Profile): Observable<{ focusArea: { name: string }[] }[]> {
    return from(
      (async () => {
        await this.ensureLoaded(profile);
        let list = this.currentRawList;
        const names = {};
        list.forEach((l) => {
          l._profile.focusAreas?.forEach((fa) => {
            const faName = typeof fa === 'string' ? fa : fa.name;
            if (faName) {
              names[faName] = true;
            }
          });
        });
        return [{ focusArea: Object.keys(names).map((name) => ({ name })) }];
      })()
    );
  }

  protected postProcessFromMongo(item: { id }) {
    // when we have a generated ID and a mongo ID, swap them when updates come back
    // per se, do nothing
  }

  protected abstract updateImpl(profileId: string, chg: TC): Observable<T[]>; // call backend
  public update(profile: Profile, chg: TC): Observable<T[]> {
    return from(
      (async () => {
        const res = await firstValueFrom(this.updateImpl(profile.id, chg));
        // we need to update our data with match, comment, compiledAt as we page to it again
        const resDataMap: { [id: string]: T } = {};
        res.forEach((r) => this.postProcessFromMongo(r)); // switch IDs et al if needed (likely only outbox)
        res.forEach((r) => (resDataMap[r.id] = r));
        const resIds: string[] = res.map((r) => r.id);
        this.currentRawList
          .filter((item) => resIds.includes(item.id))
          .forEach((item) => {
            const resItem: T = resDataMap[item.id];
            item._profile.match = { ...resItem.match, mlAnyway: item._profile.match?.mlAnyway /*make sure we keep*/ };
            if ((chg as any).comment) {
              // for "traditional" way, se set here; we've already set the value when we're working outbox-style
              item._profile.qc = {
                comment: (chg as any).comment?.trim() || null,
                errorType: (chg as any).errorType || null,
              };
            }
            if (resItem.mongoId) {
              // keep the mongoId - in case we need it as well
              item._profile.mongoId = resItem.mongoId;
            }
            item._profile.compiledAt = resItem.compiledAt;
          });
        return res;
      })()
    );
  }

  protected abstract linkImpl(profileId: string, lnkId: string, data: LNK): Observable<T>;

  public link(profile: Profile, lnkId: string, data?: LNK): Observable<T> {
    return from(
      (async () => {
        const res = await firstValueFrom(this.linkImpl(profile.id, lnkId, data));
        const resEnriched = (await this.enrichAll(profile, [res]))[0];
        this.currentRawList.push(resEnriched);
        return res;
      })()
    );
  }
}
