import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { GET, Path, MediaType, Produces } from '../../../shared/services/rest/rest.service';
import { APIService } from '../../../shared/services/api/api.service';
import { BulkTracking } from './bulk-tracking';
import { BulkType } from './bulk-types';

@Injectable()
export class BulkTrackingAPI extends APIService {
  /**
   * Find an instance of the model matched by filter
   *
   * @method findById
   * @param {String} id
   * @returns {Observable} observable model
   */
  @GET('bulk-list-status/current/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<BulkTracking> {
    return;
  }

  /**
   * Get the file as binary based on ID (need XHR to pass Authentication)
   *
   * @param id
   */
  public downloadFeedbackFile(id: string, type: BulkType): Observable<any> {
    return this.http
      .get(`${this.getBaseUrl()}${type.toString()}/download-res/${id}`, { responseType: 'blob' })
      .pipe(catchError((err) => this.handleError(err)));
  }
}
