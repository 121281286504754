import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl } from '@angular/forms';

const REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9-]+\.([a-z0-9-]*[a-z0-9])+(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

const EMAIL_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => EmailValidator),
  multi: true,
};

@Directive({
  selector: '[ngModel][validateEmail]',
  providers: [EMAIL_VALIDATOR],
})
export class EmailValidator implements Validator {
  validate(control: AbstractControl): { [key: string]: any } {
    if (this.isPresent(Validators.required(control))) {
      return null;
    }

    const v: string = control.value;
    return this.validateEmail(v);
  }

  public validateEmail(v: string) {
    return REGEXP.test(v) ? null : { email: true };
  }

  private isPresent(obj: any) {
    return obj !== undefined && obj !== null;
  }
}
