export enum EventStatus {
  DRAFT = 'DRAFT',
  // convert to draft DRAFT_FOR_CHECK           = 'DRAFT_FOR_CHECK',

  READY_FOR_COMPILATION = 'READY_FOR_COMPILATION',
  COMPILATION_IN_PROGRESS = 'COMPILATION_IN_PROGRESS',
  UNABLE_TO_COMPILE = 'UNABLE_TO_COMPILE',

  POSTPONED = 'POSTPONED',
  CANCELLED = 'CANCELLED',

  NO_INFO = 'NO_INFO',
  DONE = 'DONE',
}
