<span *ngIf="!!value">
  <label>
    <input
      class="form-control form-control-sm"
      [type]="inside ? 'date' : 'text'"
      [ngClass]="{error: error || value.value && validateInput(value.value)!=='Valid'}"
      [value]="asString(value.value, inside)"
      [placeholder]="asString(value.srcValue)"
      [disabled]="statusEnum.Auto === value.status || disabled"
      (focus)="inside=true"
      (blur)="changeInput($event); inside=false"
    />
  </label>
  <span
    *ngIf="((!isNil(value.value)) || inside) && (!isNil(value.srcValue)) && (asString(value.value) !== asString(value.srcValue))"
    >({{asString(value.srcValue)}})</span
  >
  <small *ngIf="statusEnum.Auto !== value.status">
    <label class="checkbox-discard"
      ><input
        type="checkbox"
        [checked]="statusEnum.Discard === value.status"
        (input)="value.status = ($event.target.checked ? statusEnum.Discard : statusEnum.Keep)"
        [disabled]="disabled"
      />
      Discard</label
    >
  </small>
  <span *ngIf="value.value && validateInput(value.value)!=='Valid'" class="error-message"
    >Date {{ validateInput(value.value) | lowercase }}!</span
  >
</span>
