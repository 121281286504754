import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { User } from '../shared/user';
import { UserAPI } from '../shared/api.service';
import { UserFormComponent } from '../shared/form/form.component';

@Component({
  selector: 'dirt-user-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class UserCreateComponent {
  user: User = new User();
  isSubmitting = false;

  @ViewChild(UserFormComponent)
  userForm: UserFormComponent;

  constructor(private router: Router, private svcUser: UserAPI) {}

  onSubmit(user: User): void {
    if (!this.userForm.checkCubeUs()) {
      return;
    }
    this.isSubmitting = true;
    this.svcUser.create(user).subscribe(
      (resp) => {
        this.isSubmitting = false;
        this.goBack();
      },
      () => (this.isSubmitting = false)
    );
  }

  goBack(): void {
    this.router.navigate(['/user']);
  }
}
