<span [ngClass]="{ wide: wide }">
  <input
    type="search"
    class="di-search form-control ng-trim-ignore"
    [ngClass]="{ loading: isSearching }"
    [ngbTypeahead]="searchAutoComplete"
    [focusFirst]="false"
    [resultTemplate]="searchResultTemplate"
    (selectItem)="onChangePerson($event.item)"
    [(ngModel)]="searchTerm"
    [disabled]="disabled"
    [placeholder]="determinePersonCaption(kolId)"
  />
  <div>
    <a
      *ngIf="!!kolId"
      target="_blank"
      (click)="personOpened.emit(kolId)"
      [routerLink]="['/person/detail', kolId]"
      [queryParams]="qcSessionId ? { qcSession: qcSessionId } : null"
      title="open in new tab"
      style="float: right"
      ><i class="fa fa-external-link"></i
    ></a>
    <a
      *ngIf="!!kolId && !disabled"
      class="alink"
      style="float: right; margin-right: 10px"
      title="clear selected"
      (click)="onClearPerson()"
      ><i class="fa fa-eraser"></i
    ></a>
    <a *ngIf="!!bestGuessInput" class="alink" [title]="bestGuessInput" (click)="doBestGuess()"><em>Best guess</em></a>
    <a *ngIf="!autoMappingId" class="alink" (click)="showPersonModal()">New Person...</a>
  </div>
</span>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div class="di-sac-kol-id pull-right">{{ r.kolId }}</div>
  <div class="di-sac-name">
    <span
      >{{ r.firstName }} {{ r.middleName }} {{ r.lastName }}
      <small>{{ r.affiliationName }} {{ r.affiliationDepartment }}</small></span
    >
    <span class="di-sac-status">{{ r._meta?.status || '-' | capitalize }}</span>
  </div>
</ng-template>
