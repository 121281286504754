import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';

import { AccountFinancialInformation } from '../shared/account-financial-information';
import { AccountFinancialInformationAPI } from '../shared/account-financial-information-api.service';
import { ACL } from '../../shared/acl/acl.service';

@Component({
  selector: 'dirt-account-financial-information-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class AccountFinancialInformationListComponent implements OnInit {
  @Input()
  accountId: string;

  accountFinancialInformation: AccountFinancialInformation[];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 30;
  pagingSkip = 0;

  private sort = '-year';

  constructor(
    private readonly router: Router,
    private readonly svcAccountFinancialInformation: AccountFinancialInformationAPI,
    private readonly svcACL: ACL
  ) {}

  ngOnInit(): void {
    this.doLoad();
  }

  /** just avoid useless rendering if we can */
  trackById(index: number, account: AccountFinancialInformation): string {
    return account.id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getAccountsFinancialInformation();
  }

  onSort(field: string): void {
    this.sort = field;

    this.getAccountsFinancialInformation();
  }

  onDelete(id: string): void {
    if (!this.svcACL.hasCredential('account-financial-information.delete')) {
      return;
    }

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.svcAccountFinancialInformation.deleteById(id).subscribe(() => {
      this.getAccountsFinancialInformation();
      this.getAccountsFinancialInformationCount();
    });
  }

  navigateTo(id: string): void {
    if (!id) {
      return;
    }

    this.router.navigate(['/account-financial-information/detail', id]);
  }

  private doLoad(): void {
    this.resetPagination();
    this.getAccountsFinancialInformation();
    this.getAccountsFinancialInformationCount();
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getAccountsFinancialInformation(): void {
    this.isLoading = true;

    this.svcAccountFinancialInformation
      .find(this.pagingLimit, this.pagingSkip, this.sort, this.accountId)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((accountFinancialInformation) => {
        this.accountFinancialInformation = accountFinancialInformation;
      });
  }

  private getAccountsFinancialInformationCount(): void {
    this.svcAccountFinancialInformation.count(this.accountId).subscribe((count) => {
      this.total = count;
    });
  }
}
