<section class="dirt-section-create dirt-guideline-create">
  <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
    <span class="fa fa-arrow-left" aria-hidden="true"></span>
  </button>

  <h1 class="bounceInRight">Create Guideline</h1>

  <br />

  <dirt-guideline-form [guideline]="guideline" #frmGuideline="frmGuideline"></dirt-guideline-form>

  <div>
    <dirt-ocr-bar [webSource]="null"></dirt-ocr-bar>
  </div>

  <div class="pull-right">
    <ng-template [diAcl]="'guideline.create'">
      <button
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="!frmGuideline.isValid() || isSubmitting"
        (click)="onSubmit(frmGuideline.getValue())"
      >
        Submit
      </button>

      <button
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="!frmGuideline.isValid() || isSubmitting"
        (click)="onSaveAndNew(frmGuideline.getValue()); $event.stopPropagation()"
      >
        Save & New
      </button>
    </ng-template>
  </div>
</section>
