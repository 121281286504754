<div
  class="di-thumbnail-container d-flex align-items-center justify-content-center rounded-circle"
  [ngClass]="{
    'is-loading': loading,
    readonly: isReadonly(),
    approved: isPhotoApproved() === true,
    disapproved: isPhotoApproved() === false,
    'di-squared': displayMode === 'square',
    'di-rounded': displayMode === 'round'
  }"
  (dblclick)="onTogglePhotoApprovalClick()"
>
  <img [ngClass]="{ 'img-thumbnail': displayMode === 'square' }" [src]="getUrl()" [alt]="twitter?.username" />

  <div class="di-thumbs-up-down-approved-container" *ngIf="isPhotoApproved() === undefined">
    <div class="di-thumbs-up" (dblclick)="onSetPhotoApprovalClick($event, true)">
      <i class="fa fa-thumbs-up" aria-hidden="true"></i>
    </div>
    <div class="di-thumbs-down" (dblclick)="onSetPhotoApprovalClick($event, false)">
      <i class="fa fa-thumbs-down" aria-hidden="true"></i>
    </div>
  </div>
</div>
