import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PersonMergeComponent } from './merge.component';

@Injectable()
export class PersonMergeService {
  constructor(private svcModal: NgbModal) {}

  open(options: any): Promise<any> {
    const ref = this.svcModal.open(PersonMergeComponent);

    ref.componentInstance.options = options;

    return ref.result;
  }
}
