import {
  Component,
  Input,
  Output,
  OnInit,
  EventEmitter,
  OnDestroy,
  HostListener,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgForm, NgModel } from '@angular/forms';

import { ACL } from '../../../shared/acl/acl.service';
import { EventAPI } from '../../../event/shared/api.service';
import { ContributionAPI } from '../contribution-api.service';
import { Contribution } from '../contribution';
import { ContributionSessionPositions } from '../constant/session-positions';
import { ContributionEventPositions } from '../constant/event-positions';
import { NonLatinCountries } from '../constant/countries-non-latin';
import { NameSplitService } from '../../../shared/services/name-split/name-split.service';
import { Auth } from '../../../shared/services/auth/auth.service';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { Value } from '../../../shared/services/value/value';
import { Utils } from '../../../common/utils';
import { Roles } from '../../../shared/acl/roles';
import { IMultiSelectOption, IMultiSelectSettings } from '../../../shared/components/multiselect-dropdown/types';
import { Observable } from 'rxjs';
import { SponsorAPI } from '../../../sponsors/shared/api.service';

const CLS_MULTISELECT_BTN = 'btn btn-sm btn-secondary';
const UNTITLED_POSITIONS = ['CHAIRPERSON', 'ORGANIZER', 'PARTICIPANT'];
const WORD_BY_WORD_C_FIELDS = ['title'];
const WORD_BY_WORD_P_FIELDS = ['firstName', 'middleName', 'lastName', 'nickname', 'suffix', 'workplace'];
const WORD_BY_WORD_CLEAR = ['title'];

const WHITESPACE_RE = /([\r\n\t\.,:;\(\)\?\!_\- ]+)/m;

@Component({
  selector: 'dirt-contribution-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
})
export class ContributionFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  eventId: string;

  @Input()
  isSubmitting = false;

  @Input()
  isSaving = false;

  @Input()
  showPlaintext = false;

  @Output()
  reEvalRequest: EventEmitter<Contribution> = new EventEmitter();
  @Output()
  prevTitleRequest: EventEmitter<Contribution> = new EventEmitter();
  @Output()
  prevWpRequest: EventEmitter<Contribution> = new EventEmitter();

  @ViewChild('lastName', { static: false }) // allows setting full name required as soon as poss
  lastName: NgModel;

  @ViewChild('plainText') plainTextArea;
  wordByWordMode = false;
  wordByWordModeAtLeastOnce = false;
  private wordByWordMatch: string[] = null;
  private wordByWordPos = -1;
  private wordByWordPrevWhat = null;
  private wordByWordDoneSoFar = [];
  wordByWordRememberAll = false;
  wordByWordRememberRemainder = false;
  private wordByWordRemembered: string = null;

  submitted = false;
  isReadOnly = false;

  eventPositions = ContributionEventPositions;

  positions: IMultiSelectOption[] = [];
  sub: any;

  // Sponsors
  sponsors: (IMultiSelectOption & { nameLower: string })[] = [];
  sponsorsFiltered: (IMultiSelectOption & { nameLower: string })[] = [];
  sponsorsSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    enableSearch: true,
    searchRenderLimit: 100,
    isLazyLoad: true,
    isLazyLoadWithSelected: true,
    dynamicTitleMaxItems: 5,
  };

  countries: Value[] = [];

  @Input('contribution')
  model: Contribution = new Contribution();

  @Output()
  onSubmitted = new EventEmitter<Contribution>();

  @Output()
  onSubmittedAndNew = new EventEmitter<Contribution>();

  @Output()
  onCancel = new EventEmitter();

  longDash = Utils.longDash;

  wndw: Window = window; // be able to override in tests

  shouldDisplayVerifiedControls: boolean;

  languages$: Observable<Value[]>;

  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  get isEditMode() {
    return this.model && !!this.model.id;
  }

  constructor(
    private route: ActivatedRoute,
    private eventSvc: EventAPI,
    private contribSvc: ContributionAPI,
    private svcAcl: ACL,
    private svcNameSplit: NameSplitService,
    private svcValue: ValueAPI,
    private svcSponsor: SponsorAPI,
    public auth: Auth
  ) {}

  ngOnInit() {
    this.loadSponsors();
    this.loadCountries();

    this.isReadOnly = this.model.id && !this.svcAcl.hasCredential('contribution.update');

    if (this.model.event) {
      this.normalizePositions(this.model.event);
    } else {
      this.sub = this.route.params.subscribe((params) => {
        this.eventId = params['eventId'] || this.eventId;
        this.getParent();
      });
    }

    this.shouldDisplayVerifiedControls =
      !this.svcAcl.hasRole(Roles.EventCompiler) && !this.svcAcl.hasRole(Roles.AssociationCompiler);
    if (!this.model.person.position) {
      this.model.person.position = ContributionEventPositions.SPEAKER;
    }

    this.languages$ = this.svcValue.find(ValueType.Language, Number.MAX_SAFE_INTEGER, 0, '+title');
  }

  isFullNameVisible(): boolean {
    return true; // until we have a better way, just don't get in the way
    /*let event = this.model && this.model.event;
    return ContributionFormComponent.isFullNameVisibleFor(event, this.countries);*/
  }
  static isFullNameVisibleFor(event: any, countries?: Value[]): boolean {
    if (event) {
      // if it's a session, go for its parent event
      event = event.parent || event;
      let country = event.address && event.address.country;
      if (!country && event.address && event.address.countryCode && (countries || []).length > 0) {
        const countryValue = countries.find((e) => e.code === event.address.countryCode);
        country = countryValue && countryValue.title;
      }
      return country && NonLatinCountries.indexOf(country) !== -1;
    }
    return false;
  }

  isTitleRequired(): boolean {
    const position = (this.model.person || {})['position'];
    return UNTITLED_POSITIONS.indexOf(position) === -1;
  }

  onPositionChange(): void {
    if (this.isTitleDisabled()) {
      if (this.model.title) {
        (this.model as any).title_removed = this.model.title;
      }
      delete this.model.title;
    } else if (!this.model.title && (this.model as any).title_removed) {
      this.model.title = (this.model as any).title_removed;
      delete (this.model as any).title_removed;
    }
  }

  onReEval(): void {
    this.reEvalRequest.emit(this.model);
  }
  async onLcase(): Promise<void> {
    if (this.model.plainText) {
      try {
        const smartRes = await this.contribSvc.smartLcase([{ plainText: this.model.plainText }]).toPromise();
        this.model.plainText = smartRes[0].plainText;
      } catch (_e) {
        // log but do fallback
        console.error(_e);
        this.model.plainText = this.model.plainText.replace(/[A-Z]{2,}/g, (s) =>
          ['USA'].indexOf(s) < 0 ? s.substring(0, 1) + s.substring(1).toLowerCase() : s
        );
      }
      this.reEvalRequest.emit(this.model);
    }
  }
  onPrevTitle(): void {
    this.prevTitleRequest.emit(this.model);
  }
  onPrevWp(): void {
    this.prevWpRequest.emit(this.model);
  }
  onClearForm(): void {
    // just be able to start like new
    delete this.model.person.firstName;
    delete this.model.person.middleName;
    delete this.model.person.lastName;
    delete this.model.person.suffix;
    delete this.model.person.nickname;
    delete this.model.person.fullName;
    delete this.model.person.workplace;
    delete this.model.person.contributionDate;
    delete this.model.person.contributionTime;
  }
  // simplify by inserting word by word
  onWordByWordStart(keepTitle?: boolean): void {
    if ((this.model.plainText || '').trim().length < 1) {
      return;
    }
    WORD_BY_WORD_C_FIELDS.filter((f) => !(keepTitle && 'title' === f)).forEach((f) => (this.model[f] = ''));
    WORD_BY_WORD_P_FIELDS.forEach((f) => (this.model.person[f] = ''));
    let t = this.model.plainText;
    t = t.replace(/–/g, '-');
    t = t.replace(/ /g, ' ');
    this.wordByWordMatch = [];
    let localMtch = WHITESPACE_RE.exec(t);
    while (localMtch) {
      if (localMtch.index > 0) {
        this.wordByWordMatch.push(t.substring(0, localMtch.index));
        this.wordByWordMatch.push(localMtch[1]);
      }
      t = t.substring(localMtch.index + localMtch[1].length);
      localMtch = WHITESPACE_RE.exec(t);
    }
    if (t.length > 0) {
      this.wordByWordMatch.push(t);
    }
    if (this.wordByWordMatch.length > 0) {
      this.wordByWordPos = 0;
      this.wordByWordPrevWhat = null;
      this.wordByWordDoneSoFar = [];
      this.wordByWordMode = true;
      this.wordByWordModeAtLeastOnce = true;
      this.plainTextArea.nativeElement.scrollTop = 0;
      this.wordByWordSel();
    } else {
      this.wordByWordMatch = null;
    }
  }
  private wordByWordSel() {
    let intPos = 0;
    let lenBefore = 0;
    while (intPos < this.wordByWordPos) {
      lenBefore += this.wordByWordMatch[intPos].length;
      intPos++;
    }
    this.plainTextArea.nativeElement.setSelectionRange(
      lenBefore,
      lenBefore + this.wordByWordMatch[this.wordByWordPos].length
    );
    this.plainTextArea.nativeElement.focus();
  }
  private wordByWordNext() {
    if (WHITESPACE_RE.exec(this.wordByWordMatch[this.wordByWordPos])) {
      this.wordByWordPos += 1; // (we have whitespace already - e.g. Windows sel)
    } else {
      this.wordByWordPos += 2; // jump over whitespace
    }
    if (this.wordByWordMatch && this.wordByWordPos < this.wordByWordMatch.length) {
      this.wordByWordSel();
    } else if (this.wordByWordMatch) {
      this.onWordByWordEnd(); // (same as click)
    }
  }
  onWordByWordInsert(what: string) {
    if ('fullName' === what && !this.isFullNameVisible()) {
      return;
    }
    let white = '';
    if (this.wordByWordPos > 0 && what === this.wordByWordPrevWhat) {
      let prevWhite = this.wordByWordMatch[this.wordByWordPos - 2 + 1]; // prev whitespace
      prevWhite = prevWhite.replace(/[\r\n\t]+/gm, ' ');
      prevWhite = prevWhite.replace(/ {2,}/, ' ');
      white = prevWhite;
    }
    this.wordByWordPrevWhat = what;
    let clear = false;
    if (WORD_BY_WORD_CLEAR.indexOf(what) >= 0 && this.wordByWordDoneSoFar.indexOf(what) < 0) {
      clear = true;
    }
    this.wordByWordDoneSoFar.push(what);
    let tgt = null; // same logic whereever we go
    if (WORD_BY_WORD_C_FIELDS.indexOf(what) >= 0) {
      tgt = this.model;
    } else if (WORD_BY_WORD_P_FIELDS.indexOf(what) >= 0) {
      tgt = this.model.person;
    }
    if (tgt) {
      if (clear) {
        tgt[what] = '';
      }
      if (tgt[what] !== undefined && tgt[what].length > 0 && !(tgt[what].endsWith(' ') || white.endsWith(' '))) {
        white += ' ';
      }
      let { selEnd, actualSel } = this.getWordByWordActualSel();
      if (actualSel) {
        tgt[what] = (tgt[what] || '') + white + actualSel;
        this.adjustWordByWordPosForActualSel(selEnd);
      } else {
        tgt[what] = (tgt[what] || '') + white + this.wordByWordMatch[this.wordByWordPos];
      }
    }
    this.wordByWordNext();
  }
  onWordByWordInsertNameSplit() {
    let { selEnd, actualSel } = this.getWordByWordActualSel();
    let text;
    if (actualSel) {
      text = actualSel;
    } else {
      text = this.wordByWordMatch[this.wordByWordPos];
    }
    const name = this.svcNameSplit.splitFullName(text);
    const { person } = this.model;
    person.firstName = name.first;
    person.middleName = name.middle;
    person.lastName = name.last;
    person.sourceName = {
      firstName: name.first,
      middleName: name.middle,
      lastName: name.last,
      sourceStr: text,
    };
    if (actualSel) {
      this.adjustWordByWordPosForActualSel(selEnd);
    }
    this.wordByWordNext();
  }
  private getWordByWordActualSel() {
    // NEW: take actual sel and continue w/ next word AFTER (normalize whitespace as before & trim)
    let selStart = this.plainTextArea.nativeElement.selectionStart || 0;
    let selEnd = this.plainTextArea.nativeElement.selectionEnd || 0;
    let actualSel = (this.plainTextArea.nativeElement.value || '').substring(selStart, selEnd);
    actualSel = actualSel.replace(/[\r\n\t]+/gm, ' ');
    actualSel = actualSel.replace(/ {2,}/, ' ');
    actualSel = actualSel.trim();
    return { selEnd, actualSel };
  }
  private adjustWordByWordPosForActualSel(selEnd) {
    // need to adjust pos until we're covering the end of sel
    let intPos = 0;
    let lenBefore = 0;
    while (lenBefore < selEnd) {
      lenBefore += this.wordByWordMatch[intPos].length;
      intPos++;
      if (intPos >= this.wordByWordMatch.length) {
        break;
      }
    }
    this.wordByWordPos = intPos - 1 /* next = next word*/;
  }
  @HostListener('document:keydown.control.1', ['$event'])
  @HostListener('document:keydown.meta.1', ['$event'])
  onWordByWordKeystrokeTitle(evnt: Event) {
    if (this.showPlaintext && this.wordByWordMode) {
      evnt.preventDefault();
      this.onWordByWordInsert('title');
    }
  }
  @HostListener('document:keydown.control.2', ['$event'])
  @HostListener('document:keydown.meta.2', ['$event'])
  onWordByWordKeystrokeName(evnt: Event) {
    if (this.showPlaintext && this.wordByWordMode) {
      evnt.preventDefault();
      this.onWordByWordInsertNameSplit();
    }
  }
  @HostListener('document:keydown.control.3', ['$event'])
  @HostListener('document:keydown.meta.3', ['$event'])
  onWordByWordKeystrokeFullName(evnt: Event) {
    if (this.showPlaintext && this.wordByWordMode) {
      evnt.preventDefault();
      this.onWordByWordInsert('workplace');
    }
  }
  @HostListener('document:keydown.control.4', ['$event'])
  @HostListener('document:keydown.meta.4', ['$event'])
  onWordByWordKeystrokeWorkplace(evnt: Event) {
    if (this.showPlaintext && this.wordByWordMode) {
      evnt.preventDefault();
      this.onWordByWordInsert('fullName');
    }
  }
  onWordByWordCont() {
    this.wordByWordSel();
  }
  onWordByWordSkip() {
    this.wordByWordNext();
  }
  onWordByWordUndo() {
    if (this.wordByWordPrevWhat && this.wordByWordPos > 0) {
      if (WORD_BY_WORD_C_FIELDS.indexOf(this.wordByWordPrevWhat) >= 0) {
        this.model[this.wordByWordPrevWhat] = this.model[this.wordByWordPrevWhat].substring(
          0,
          this.model[this.wordByWordPrevWhat].length -
            this.wordByWordMatch[this.wordByWordPos - 2].length -
            this.wordByWordMatch[this.wordByWordPos - 2 + 1].length
        );
      } else if (WORD_BY_WORD_P_FIELDS.indexOf(this.wordByWordPrevWhat) >= 0) {
        this.model.person[this.wordByWordPrevWhat] = this.model.person[this.wordByWordPrevWhat].substring(
          0,
          this.model.person[this.wordByWordPrevWhat].length -
            this.wordByWordMatch[this.wordByWordPos - 2].length -
            this.wordByWordMatch[this.wordByWordPos - 2 + 1].length
        );
      }
      this.wordByWordPos -= 2;
      this.wordByWordSel();
    }
  }
  onWordByWordEnd() {
    this.wordByWordMode = false;
  }
  onWordByWordRememberAllChg(evnt) {
    const newVal = evnt.target.checked;
    this.wordByWordRememberAll = newVal;
    if (newVal) {
      this.wordByWordRememberRemainder = false;
    } else {
      this.wordByWordRemembered = null;
    }
  }
  onWordByWordRememberRemainderChg(evnt) {
    const newVal = evnt.target.checked;
    this.wordByWordRememberRemainder = newVal;
    if (newVal) {
      this.wordByWordRememberAll = false;
    } else {
      this.wordByWordRemembered = null;
    }
  }

  isTitleDisabled(): boolean {
    const disablePositions = [
      ContributionEventPositions.PARTICIPANT,
      ContributionEventPositions.CHAIRPERSON,
      ContributionEventPositions.ORGANIZER,
    ];

    return disablePositions.includes(this.model.person.position);
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.model.person.position === this.eventPositions.PARTICIPANT) {
      delete this.model.title;
    }
    this.rememberWordByWordOnSubmit();
    this.onSubmitted.emit(this.model);
  }

  onSubmitAndNew() {
    this.rememberWordByWordOnSubmit();
    this.onSubmittedAndNew.emit(this.model);
  }

  private rememberWordByWordOnSubmit() {
    try {
      if (this.wordByWordModeAtLeastOnce) {
        if (this.wordByWordRememberAll) {
          this.wordByWordRemembered = this.model.plainText;
        } else if (this.wordByWordRememberRemainder) {
          let intPos = 0;
          let lenBefore = 0;
          while (intPos < this.wordByWordPos) {
            lenBefore += this.wordByWordMatch[intPos].length;
            intPos++;
            if (intPos >= this.wordByWordMatch.length) {
              break;
            }
          }
          this.wordByWordRemembered = this.model.plainText.substring(lenBefore).trim();
          if (lenBefore > 0) {
            this.model.plainText = this.model.plainText.substring(0, lenBefore).trim();
          }
        } else {
          this.wordByWordRemembered = null;
        }
        delete this.model.originalPlainText; // make sure we don't carry around giant irrelevant (training still can use plainText)
      } // (else leave all as-is)
    } catch (_e) {
      console.error(_e); // just don't interrupt saving EVER
    }
  }

  @HostListener('document:keydown.control.x')
  @HostListener('document:keydown.meta.x')
  onClipboardPaste(evnt: Event) {
    if (this.showPlaintext && !(evnt instanceof PointerEvent || evnt instanceof MouseEvent)) {
      return; // don't mix legacy and ML
    }
    (navigator as any).clipboard
      .readText()
      .then((text) => {
        const name = this.svcNameSplit.splitFullName(text);
        const { person } = this.model;
        person.firstName = name.first;
        person.middleName = name.middle;
        person.lastName = name.last;
        person.sourceName = {
          firstName: name.first,
          middleName: name.middle,
          lastName: name.last,
          sourceStr: text,
        };
      })
      .catch(() => window.alert('You have to grant access to read from your clipboard!'));
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model && changes.model.currentValue) {
      const contrib = changes.model.currentValue as Contribution;
      if ((contrib as any)._fromCapture) {
        this.wordByWordRemembered = null;
      }
      delete (contrib as any)._fromCapture;
      try {
        if (this.showPlaintext && this.wordByWordRemembered && !contrib.plainText) {
          contrib.plainText = this.wordByWordRemembered; // (no interpretation!)
          this.onWordByWordStart(true);
        }
      } catch (_e) {
        console.error(_e); // just don't interrupt loading EVER
      }
    }
  }

  private loadSponsors(): void {
    this.svcSponsor
      .getSponsors()
      .toPromise()
      .then((data) => {
        this.sponsors = data.map((o) => ({
          id: o.companyId,
          name: o.normalizedName,
          disabled: !!o.disabled,
          nameLower: o.normalizedName?.toLowerCase(),
        }));
        this.filterSponsors({ filter: null }); // init display
      });
  }

  filterSponsors({ filter }) {
    let selectedSponsors = [];
    if (this.model.sponsors?.length) {
      selectedSponsors = this.model.sponsors.map(
        (s) => this.sponsors.find((sp) => sp.id === s) || { id: s, name: s, nameLower: s, disabled: true }
      );
    }
    if (!(filter?.length >= 3)) {
      this.sponsorsFiltered = selectedSponsors;
    } else {
      const filterLower = filter.toLowerCase();
      this.sponsorsFiltered = [
        ...selectedSponsors,
        ...this.sponsors.filter((s) => s.nameLower.indexOf(filterLower) >= 0).slice(0, 100),
      ];
    }
  }

  private normalizePositions(event) {
    const positionConstants = event.parent ? ContributionSessionPositions : ContributionEventPositions;

    Object.keys(positionConstants).forEach((c) => this.positions.push({ id: c, name: positionConstants[c] }));
  }

  private getParent() {
    this.eventSvc.findById(this.eventId).subscribe((resp) => {
      this.normalizePositions(resp);
    });
  }

  onCancelClick() {
    this.onCancel.emit();
  }

  onReviewedChange($event: any) {
    $event.stopPropagation();
    this.model.reviewed = $event.target.checked;
  }
  onVerifiedChange($event: any) {
    $event.stopPropagation();
    this.model.verified = $event.target.checked;
  }

  isExtendedTestEnabled() {
    return !!this.wndw.localStorage.getItem('BETATEST2b');
  }

  loadCountries() {
    this.svcValue.find(ValueType.EventAssociationCountry, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.countries = data;
    });
  }

  isPositionDisable(position: string) {
    return (
      (position === ContributionEventPositions.ORGANIZER && this.model.event && this.model.event.parent) ||
      (position === ContributionSessionPositions.CHAIRPERSON && this.model.event && !this.model.event.parent)
    );
  }
}
