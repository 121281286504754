import { capitalize } from 'lodash';
import { Component, Input, OnChanges } from '@angular/core';

import { Job } from './../job';

@Component({
  selector: 'dirt-job-entity-url',
  templateUrl: './jobEntityUrl.component.html',
  styleUrls: ['./jobEntityUrl.component.scss'],
})
export class JobEntityURLComponent implements OnChanges {
  @Input()
  model: Job;

  @Input()
  showLabel: boolean = false;

  entityTitle: string = '';
  entityDomain: string = '';
  entityId: string = '';

  constructor() {}

  ngOnChanges() {
    if (this.model) {
      const { title, domain, id } = this.getEntity();
      this.entityTitle = title;
      this.entityDomain = domain;
      this.entityId = id;
    }
  }

  /**
   * Logic to determine the entity URL based on the model type.
   *
   * Output is used on the template, so we generate the
   * `title` of this job entity, its `domain` (used on the url link)
   * and its entity `id`.
   */
  getEntity(): { title: string; domain: string; id: string } {
    const entityId = this.model.naturalEntityId || this.model.entityId || this.model.extEntityId;

    switch (this.model.entityType) {
      case 'clinical-trial-profiles': {
        return { title: 'NCT Code', domain: 'ct-profile', id: entityId };
      }
      case 'people': {
        return { title: 'Person Id', domain: 'person', id: entityId };
      }
      case 'clinical-trial-sponsors': {
        return { title: 'NCT Sponsor Code', domain: 'ct-sponsor', id: entityId };
      }
      case 'events-series':
        return { title: 'Event Series Id', domain: 'event-series', id: entityId };
      case 'medical-insights-profiles-uat':
        return {
          title: `Medical Insights UAT Profile Id`,
          domain: 'medical-insights-profile-uat',
          id: this.model.entityId,
        };
      case 'medical-insights-profiles':
        const singularTypeTitle = this.model.entityType.slice(0, this.model.entityType.length - 1); // e.g. affiliation
        return { title: `Medical Insights Profile Id`, domain: singularTypeTitle, id: this.model.entityId };
      case 'affiliations':
      case 'accounts':
      case 'committees':
      case 'documents':
      case 'domains':
      case 'events':
      case 'organizations':
      case 'profiles': {
        const singularTypeTitle = this.model.entityType.slice(0, this.model.entityType.length - 1); // e.g. affiliation
        const entityTypeTitle = capitalize(singularTypeTitle).replace(/_/g, ' '); // e.g. Affiliation
        return { title: `${entityTypeTitle} Id`, domain: singularTypeTitle, id: entityId };
      }
      case 'videos': {
        return { title: 'Video Id', domain: 'video-job', id: this.model._id };
      }

      // For rest unidentified...
      default:
        return { title: '', domain: null, id: entityId };
    }
  }

  getRouterLink() {
    return `/${this.entityDomain}/detail/${this.entityId}`;
  }

  openLinkInNewTab(): void {
    window.open(this.getRouterLink(), '_blank');
  }
}
