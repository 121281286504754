<button
  class="btn btn-secondary btn-sm"
  (click)="onShow()"
  [ngbPopover]="filterPopoverContent"
  [autoClose]="false"
  placement="bottom-right"
  #filterPopover="ngbPopover"
>
  Filter
  <i class="fa fa-sort-down" aria-hidden="true"></i>
</button>

<ng-template #filterPopoverContent>
  <div class="dirt-filter-content">
    <div class="card dirt-filter-list" [hidden]="visiblePanel">
      <div class="card-header text-center">Filter</div>

      <div class="card-body" diScroller>
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let filter of filters; trackBy: trackByKey"
            (click)="setPanel(filter.title)"
          >
            {{ filter.title }} <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <div class="card-footer" *ngIf="activeFilters.size > 0">
        <button class="btn btn-outline-primary btn-sm pull-right" (click)="doClear()">Clear Filters</button>
      </div>
    </div>

    <div class="card dirt-filter-panel" [hidden]="!visiblePanel">
      <div class="card-header text-center">
        <i class="fa fa-chevron-left dirt-back" aria-hidden="true" (click)="setPanel()"></i>
        <span>Filter by {{ visiblePanel | capitalize }}</span>
      </div>

      <div class="card-body">
        <!-- STANDARDS FILTERS -->
        <div *ngFor="let filter of filters; trackBy: trackByKey" [hidden]="visiblePanel !== filter.title">
          <ng-container [ngSwitch]="filter.type">
            <!-- SINGLE_VALUE -->
            <ul class="list-group" *ngSwitchCase="'SINGLE_VALUE'">
              <li
                class="list-group-item justify-content-between"
                *ngFor="let value of filter.values"
                [class.selected]="value.selected"
                (click)="toggleSingleSelectSelection(filter, value)"
              >
                {{ value.displayValue || value.title }} <i class="fa fa-minus" aria-hidden="true"></i>
              </li>
            </ul>

            <!-- MULTI_VALUE -->
            <ul class="list-group" *ngSwitchCase="'MULTI_VALUE'">
              <li
                class="list-group-item justify-content-between"
                *ngFor="let value of filter.values"
                [class.selected]="value.selected"
                (click)="toggleMultiSelectSelection(filter, value)"
              >
                {{ value.displayValue || value.title }} <i class="fa fa-minus" aria-hidden="true"></i>
              </li>
            </ul>

            <!-- MULTI_VALUE_FILTERABLE -->
            <ng-container *ngSwitchCase="'MULTI_VALUE_FILTERABLE'">
              <div>
                <input
                  ngModel
                  #searchInput="ngModel"
                  type="search"
                  name="test"
                  class="form-control"
                  placeholder="Search..."
                />
              </div>

              <li
                class="list-group-item justify-content-between"
                *ngFor="let value of filter.values | filter : 'displayValue' : searchInput.value"
                [class.selected]="value.selected"
                (click)="toggleMultiSelectSelection(filter, value)"
              >
                {{ value.displayValue || value.title }} <i class="fa fa-minus" aria-hidden="true"></i>
              </li>
            </ng-container>

            <!-- DATE_RANGE -->
            <ng-container *ngSwitchCase="'DATE_RANGE'">
              <div class="p-1 overflow-hidden">
                <div class="form-group row justify-content-center">
                  <label class="col-sm-3 col-form-label">Start</label>
                  <div class="col-sm-9">
                    <input
                      type="date"
                      class="form-control"
                      placeholder="From"
                      [max]="activeFilters.get(filter.key)?.$lte | date : 'yyyy-MM-dd'"
                      [value]="activeFilters.get(filter.key)?.$gte | date : 'yyyy-MM-dd'"
                      (change)="updateDateRange(filter, '$gte', reviewDateFrom.valueAsDate)"
                      #reviewDateFrom
                    />
                  </div>
                </div>
                <div class="form-group row justify-content-center">
                  <label class="col-sm-3 col-form-label">End</label>
                  <div class="col-sm-9">
                    <input
                      type="date"
                      class="form-control"
                      placeholder="To"
                      [min]="activeFilters.get(filter.key)?.$gte | date : 'yyyy-MM-dd'"
                      [value]="activeFilters.get(filter.key)?.$lte | date : 'yyyy-MM-dd'"
                      (change)="updateDateRange(filter, '$lte', reviewDateTo.valueAsDate)"
                      #reviewDateTo
                    />
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- CUSTOM -->
            <ng-container *ngSwitchCase="'CUSTOM'" viewContainer #customComponentWrapper="viewContainer">
              {{ renderCustomComponent(customComponentWrapper.container, filter) }}
            </ng-container>

            <!-- NOT SUPPORTED -->
            <p *ngSwitchDefault>Filter type {{ filter.type }} is not supported</p>
          </ng-container>
        </div>
      </div>

      <div class="card-footer">
        <button class="btn btn-outline-primary btn-sm pull-left" (click)="doApply()">Apply</button>
        <button class="btn btn-outline-secondary btn-sm pull-right" (click)="doCancel()">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>
