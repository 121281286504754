import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { ACL } from '../../acl/acl.service';
import { ProjectSetChange } from './set-change';
import { Value } from '../../services/value/value';
import { ValueType } from '../../enum/value-type.enum';
import { ValueAPI } from '../../services/value/value-api.service';

@Component({
  selector: 'dirt-project-name',
  templateUrl: 'project-name.component.html',
  styleUrls: ['./project-name.component.scss'],
})
export class ProjectNameComponent implements OnInit, OnChanges {
  @Input()
  activeSet: string[] = [];

  @Input()
  limitToSet: string[] = null; // (empty array is limit already)

  @Input()
  projectTag: string = 'none';

  @Input()
  disabled: boolean = false;

  @Input()
  type: ValueType;

  @Output()
  onProjectSelectionChanged = new EventEmitter<ProjectSetChange>();

  isSaving = false;
  isLoading = false;
  selected: any[] = [];
  items$: Observable<Value[]>;
  projectTags: Value[] = [];

  constructor(private svcAcl: ACL, private svcValue: ValueAPI) {}

  ngOnInit(): void {
    this.initValues();
  }

  ngOnChanges(): void {
    this.initValues();
  }

  initValues() {
    this.isLoading = true;
    const condition =
      this.projectTag === 'delivery'
        ? { technical: false }
        : this.projectTag === 'technical'
        ? { technical: true }
        : {};
    this.items$ = this.svcValue.find(this.type, Number.MAX_SAFE_INTEGER, 0, '+title', condition);
    this.items$.subscribe((data) => {
      this.projectTags = data.map((item) => ({
        ...item,
        disabled: this.isDisabled(item.code as string),
      }));
      this.combineValues();
      this.isLoading = false;
    });
  }

  onClick(value: string) {
    const index = this.activeSet.indexOf(value);
    let changed = true;

    if (index > -1) {
      if (this.activeSet.length > 1) {
        this.activeSet.splice(index, 1);
      } else {
        changed = false;
        // TODO fire an event informing the user and restricting the deletion
      }
    } else {
      this.activeSet.push(value);
    }

    if (changed) {
      this.isSaving = true;
      this.onProjectSelectionChanged.emit({ activeSet: this.activeSet, callback: () => this.onChangeConsumed() });
    }
  }

  onChangeConsumed() {
    this.isSaving = false;
    this.combineValues();
  }

  combineValues(): void {
    if (this.activeSet) {
      for (let i = 0; i < this.activeSet.length; i++) {
        const project = this.projectTags.find((o) => o.code === this.activeSet[i]);
        if (project) {
          this.selected = [...this.selected, project.code];
        }
      }
    }
  }

  isDisabled(value: string) {
    if (!Array.isArray(this.limitToSet)) {
      return false;
    } else {
      return this.limitToSet.indexOf(value) < 0;
    }
  }
}
