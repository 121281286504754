import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
  GET,
  MediaType,
  Produces,
  Path,
  Query,
  POST,
  Body,
  PATCH,
  DELETE,
} from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { Guideline } from './guideline';
import { User } from '../../user/shared/user';

@Injectable()
export class GuidelineAPI extends APIService {
  @GET('guidelines/')
  @Produces(MediaType.JSON)
  public find(
    @Query('name') name?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<Guideline[]> {
    return;
  }

  @GET('guidelines/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Guideline> {
    return;
  }

  @GET('guidelines/count')
  @Produces(MediaType.JSON)
  public count(@Query('name') name?: string, @Query('filter') filter?: any): Observable<any> {
    return;
  }

  @POST('guidelines')
  @Produces(MediaType.JSON)
  public create(@Body guideline: Guideline): Observable<any> {
    return;
  }

  @PATCH('guidelines/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body guideline: Guideline): Observable<any> {
    return;
  }

  @DELETE('guidelines/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }

  /**
   * Find all the audit logs of a given model id
   * @param id
   */
  @GET('guidelines/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  /**
   * Find count of audit logs
   * @param id
   */
  @GET('guidelines/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }

  @GET('guidelines/next')
  @Produces(MediaType.JSON)
  public findNext(): Observable<Guideline> {
    return;
  }

  /**
   * Find models which are part of the given user's workflow
   */
  @GET('guidelines/queue')
  @Produces(MediaType.JSON)
  public queue(@Query('userId') userId?: string): Observable<any> {
    return;
  }

  /**
   * Find all the distinct values of the `_meta.assignee` and `_meta.lastAssignee` field
   */
  @GET('guidelines/reviewers')
  @Produces(MediaType.JSON)
  public reviewers(): Observable<User[]> {
    return;
  }

  @GET('guidelines/search')
  @Produces(MediaType.JSON)
  public search(@Query('term') term: string): Observable<Guideline[]> {
    return;
  }

  @GET('guidelines/id-qc-queue')
  @Produces(MediaType.JSON)
  public idQcQueue(): Observable<any> {
    return;
  }

  @GET('guidelines/id-qc-next')
  @Produces(MediaType.JSON)
  public findIDQCNext(@Query('exceedCap') exceedCap?: boolean): Observable<string | undefined> {
    return;
  }

  @GET('guidelines/creators')
  @Produces(MediaType.JSON)
  public creators(): Observable<User[]> {
    return;
  }

  @GET('guidelines/qc-queue')
  @Produces(MediaType.JSON)
  public qcQueue(): Observable<any> {
    return;
  }

  @GET('guidelines/qc-next')
  @Produces(MediaType.JSON)
  public findQcNext(@Query('exceedCap') exceedCap?: boolean): Observable<string | undefined> {
    return;
  }
}
