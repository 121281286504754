import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { OrganizationAffiliation, OrganizationAffiliationMappingCurationStatus } from './organization-affiliation';
import { APIService } from '../../shared/services/api/api.service';
import { MediaType, Produces, POST, Body, Path, PATCH } from '../../shared/services/rest/rest.service';

@Injectable()
export class OrganizationAffiliationAPI extends APIService {
  @POST('organization-affiliation-mappings/list')
  @Produces(MediaType.JSON)
  public find(
    @Body
    payload: {
      organizationIds: string[];
      status?: OrganizationAffiliationMappingCurationStatus;
      sort?: string;
      limit?: number;
      skip?: number;
    }
  ): Observable<OrganizationAffiliation[]> {
    return;
  }

  @PATCH('organization-affiliation-mappings/{id}')
  @Produces(MediaType.JSON)
  public update(
    @Path('id') id: string,
    @Body payload: { affiliationId?: string; statusCurated?: OrganizationAffiliationMappingCurationStatus }
  ): Observable<OrganizationAffiliation> {
    return;
  }

  @POST('organization-affiliation-mappings')
  @Produces(MediaType.JSON)
  create(@Body payload: any): Observable<OrganizationAffiliation> {
    return;
  }
}
