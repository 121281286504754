import { NgModule } from '@angular/core';

import { AccountPayerInformationAPI } from './shared/account-payer-information-api.service';
import { AccountPayerInformationComponent } from './account-payer-information.component';
import { AccountPayerInformationCreateComponent } from './create/create.component';
import { AccountPayerInformationDetailComponent } from './detail/detail.component';
import { AccountPayerInformationListComponent } from './list/list.component';
import { accountPayerInformationRouting } from './account-payer-information.routes';
import { SharedModule } from '../shared/shared.module';
import { AccountPayerInformationFormComponent } from './shared/form/form.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  imports: [SharedModule, accountPayerInformationRouting, NgSelectModule],
  declarations: [
    AccountPayerInformationComponent,
    AccountPayerInformationListComponent,
    AccountPayerInformationCreateComponent,
    AccountPayerInformationDetailComponent,
    AccountPayerInformationFormComponent,
  ],
  exports: [AccountPayerInformationListComponent],
  providers: [AccountPayerInformationAPI],
})
export class AccountPayerInformationModule {}
