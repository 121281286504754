<details>
  <summary>Get bookmarklet, change settings (& some tips)</summary>
  <div>
    Drag the following link into your bookmarks bar:
    <a [href]="bookmarkletUrl" style="text-decoration: underline">extract contrib</a><br />
    <small>(Use <em>View > Always Show Bookmarks Bar</em> to get the bookmarks bar)</small><br />
    In the end, it should look like the red area: <br />
    <img alt="Bookmarklet in the bookmarks bar" src="/assets/bookmarklet.png" /><br />
    <span *ngIf="isExtendedTestEnabled()">
      <strong>Inline bar: </strong><br />
      Also drag the following link into your bookmarks bar:
      <a [href]="bookmarkletUrlInlinebar" style="text-decoration: underline">inline bar</a><br />
      Clicking it will add the inline bar to the current page. After navigating, you might have to repeat this step.
      Re-install to get swapped keys -3/-4.<br />
    </span>
    <strong>Settings: </strong><br />
    <label title="For some contributions, the old(er) method might still work better - you can change any time"
      >AI version
      <select [(ngModel)]="mth" (ngModelChange)="onMthChange($event)">
        <option value="">default (=v2)</option>
        <option value="v1">v1</option>
      </select></label
    ><br />
    <strong>Tips: </strong><br />
    <ul style="list-style-type: square">
      <li>You can use <em>Paste contrib</em> with text from anywhere (even PDF that allows for it)</li>
      <li>
        You can edit the text area (white multiline field labeled 'Text')<br />
        e.g. you can cut/copy text, paste text copied before, ...<br />
        then click <em>'(Re-)Evaluate'</em>
      </li>
      <li>
        When there are e.g. several SE's contributing to one talk,<br />
        it's recommended to capture the title only with the first person
      </li>
      <li>You can get the title last saved via <em>Prev title</em></li>
      <li>You can make ALL CAPS text to normal with button <em>'Make lowercase'</em></li>
      <li>You can also <em>skip</em> a captured item and not add any contribution</li>
      <li>There's <em>'Clear form'</em> to clear title, name, workplace</li>
      <li>Press <em>ESC</em> to cancel selecting (before releasing the mouse)</li>
      <li>
        Press <em>Ctrl</em> (Windows) / <em>option</em> aka <em>Alt</em> (Mac) while dragging to copy between fields by
        drag&drop
      </li>
      <li>
        Press <em>Ctrl</em> (Windows) / <em>Meta</em> aka <em>command</em> (Mac) while selecting in PDF/screenshot to
        <strong>add</strong> to the previous selection
      </li>
      <li>Choosing a language will <strong>improve OCR</strong></li>
      <li>
        When the selection is not picked up (you see '-no text selected-'), it might be in an iframe<br />
        here's another bookmarklet to open contents separately:
        <a
          href="javascript:window.open(null, '_blank', '').document.write('<strong>iframes:</strong> (click each line to open)<br/>' + Array.from(document.getElementsByTagName('iframe')).map((n, i) => '<a href=&quot;' + n.src + '&quot; target=&quot;_blank&quot;>' + n.src + '</a>').join('<br/>'))"
          style="text-decoration: underline"
          >show iframes</a
        ><br />
        (can be used independently from cLean)
      </li>
      <li>no need for <em>Start capturing</em> when working with <em>Paste contrib</em> or <em>more capture</em></li>
      <li>collapse this while you work ;-)</li>
    </ul>
  </div>
</details>
