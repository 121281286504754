import { NgModule, Type } from '@angular/core';

import { CapitalizePipe } from './capitalize/capitalize.pipe';
import { DeCapitalizePipe } from './decapitalize/decapitalize.pipe';
import { DistinctPipe } from './distinct/distinct.pipe';
import { DomainPipe } from './domain/domain.pipe';
import { EllipsisPipe } from './ellipsis/ellipsis.pipe';
import { IterablePipe } from './iterable/iterable.pipe';
import { HighlightPipe } from './highlight/highlight.pipe';
import { KeysPipe } from './keys/keys.pipe';
import { SplitPipe } from './split/split.pipe';
import { RangePipe } from './range/range.pipe';
import { Dashipe } from './dash/dash.pipe';
import { FilterPipe } from './filter/filter.pipe';
import { GetByPathPipe } from './get-by-path.pipe';
import { ProductLabelPipe } from './product-label/product-lable.pipe';

export { CapitalizePipe } from './capitalize/capitalize.pipe';
export { DeCapitalizePipe } from './decapitalize/decapitalize.pipe';
export { DistinctPipe } from './distinct/distinct.pipe';
export { DomainPipe } from './domain/domain.pipe';
export { EllipsisPipe } from './ellipsis/ellipsis.pipe';
export { HighlightPipe } from './highlight/highlight.pipe';
export { IterablePipe } from './iterable/iterable.pipe';
export { KeysPipe } from './keys/keys.pipe';
export { SplitPipe } from './split/split.pipe';
export { RangePipe } from './range/range.pipe';
export { Dashipe } from './dash/dash.pipe';
export { FilterPipe } from './filter/filter.pipe';
export { ProductLabelPipe } from './product-label/product-lable.pipe';

export const DIRT_PIPES: Type<any>[] = [
  CapitalizePipe,
  DeCapitalizePipe,
  DistinctPipe,
  DomainPipe,
  EllipsisPipe,
  HighlightPipe,
  IterablePipe,
  KeysPipe,
  SplitPipe,
  RangePipe,
  Dashipe,
  FilterPipe,
  GetByPathPipe,
  ProductLabelPipe,
];

/**
 * Internal module used for sharing pipes
 */
@NgModule({
  declarations: DIRT_PIPES,
  exports: DIRT_PIPES,
})
export class DirtPipesModule {}
