import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { NgForm } from '@angular/forms';

import { AccountPayerInformation, AccountPayerShareInformation } from '../account-payer-infomation';
import { ACL } from '../../../shared/acl/acl.service';
import { Value } from '../../../shared/services/value/value';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { Utils } from '../../../common/utils';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'dirt-account-payer-information-form',
  templateUrl: './form.component.html',
  exportAs: 'frmAccountPayerInformation',
})
export class AccountPayerInformationFormComponent {
  @Input('accountPayerInformation')
  model: AccountPayerInformation = new AccountPayerInformation();

  @Output()
  validityChange: EventEmitter<'VALID' | 'INVALID'> = new EventEmitter();

  @Output()
  isTotalShareValid = new EventEmitter<boolean>();

  @ViewChild('ngForm')
  ngForm: NgForm;

  currencies: Value[] = [];
  healthPayerTypes: Value[] = [];

  customHealthPayerNamesEnabled: boolean;

  private destroy$: Subject<boolean> = new Subject();

  private createEmptyPayerShare(): AccountPayerShareInformation {
    return {
      payerName: '',
      standardizedPayerName: '',
      share: null,
      websources: [''],
    };
  }

  constructor(private svcAcl: ACL, private svcValue: ValueAPI) {}

  ngOnChanges(): void {
    this.initMultiFields();
  }

  ngOnInit(): void {
    this.svcValue
      .find(ValueType.AccountHeatherPayerTypes, Number.MAX_SAFE_INTEGER, 0, '+title')
      .subscribe((healthPayerTypes) => {
        this.healthPayerTypes = healthPayerTypes || [];
      });
  }

  ngAfterViewInit(): void {
    this.ngForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
      this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
      this.onPayerSharesChanged();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  getValue(): AccountPayerInformation {
    const model = cloneDeep(this.model);
    if (model.payerShares) {
      model.payerShares = model.payerShares.map((share) => {
        Utils.removeEmptyAndDuplicatedElementsFromArray(share, ['websources']);
        return share;
      });
    }
    return model;
  }

  isFieldEditable(field: string): boolean {
    const module = 'account-payer-information';
    const prefix = this.model.id ? 'update' : 'create';
    return this.svcAcl.hasCredential(`${module}.${prefix}.prop.${field}`);
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[], yearIdx?: number): void {
    list.push('');
  }

  trackByIndex(index: number): number {
    return index;
  }

  private isValid(): boolean {
    return this.ngForm.form.valid;
  }

  private initMultiFields(): void {
    // If payerShares is not initialized or empty, add an empty AccountPayerShareInformation
    if (!this.model.payerShares || this.model.payerShares.length === 0) {
      this.model.payerShares = [new AccountPayerShareInformation()];
    }

    this.model.payerShares.forEach((payerShare) => {
      if (!payerShare) {
        payerShare = new AccountPayerShareInformation();
      }

      if (
        !Array.isArray(payerShare.websources) ||
        payerShare.websources.length === 0 ||
        payerShare.websources.every((v) => !v)
      ) {
        payerShare.websources = [''];
      }
    });
  }

  isCustomPayerNameEnabled(standardName: string): boolean {
    const healthType = this.healthPayerTypes.find((type) => type.code === standardName);
    return healthType?.customHealthPayerNameEnabled ?? false;
  }

  clearCustomPayerNameIfDisabled(payerShare: AccountPayerShareInformation) {
    if (!this.isCustomPayerNameEnabled(payerShare.standardizedPayerName)) {
      payerShare.payerName = '';
    }
  }

  validateTotalShare() {
    const allSharesAdded = this.model.payerShares.every((payerShare) => payerShare.share != null);
    const total = this.model.payerShares.reduce((total, payerShare) => total + payerShare.share, 0);

    if ((total !== 100 && allSharesAdded) || !allSharesAdded) {
      return false;
    }
    return true;
  }

  addNewPayerShare() {
    this.model.payerShares.push(this.createEmptyPayerShare());
  }

  deleteLastShare() {
    this.model.payerShares.splice(-1);
    this.onPayerSharesChanged();
  }

  onPayerSharesChanged() {
    const valid = this.validateTotalShare();
    this.isTotalShareValid.emit(valid);
  }
}
