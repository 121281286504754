import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIService } from '../../shared/services/api/api.service';
import { Audit } from '../../shared/interfaces/audit.interface';
import { Body, GET, MediaType, PATCH, Path, POST, Produces, Query } from '../../shared/services/rest/rest.service';
import { ClinicalTrialSponsor, ClinicalTrialSponsorConnection } from './clinical-trial-sponsor';

@Injectable()
export class ClinicalTrialSponsorAPI extends APIService {
  @GET('clinical-trial-sponsors/')
  @Produces(MediaType.JSON)
  public find(
    @Query('name') name?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: Record<string, unknown>
  ): Observable<ClinicalTrialSponsor[]> {
    return;
  }

  @GET('clinical-trial-sponsors/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<ClinicalTrialSponsor> {
    return;
  }

  @GET('clinical-trial-sponsors/count')
  @Produces(MediaType.JSON)
  public count(
    @Query('name') name?: string,
    @Query('filter') filter?: Record<string, unknown>
  ): Observable<{ count: number }> {
    return;
  }

  @PATCH('clinical-trial-sponsors/{id}')
  @Produces(MediaType.JSON)
  public update(
    @Path('id') id: string,
    @Body sponsor: Partial<ClinicalTrialSponsor>
  ): Observable<ClinicalTrialSponsor> {
    return;
  }

  @GET('clinical-trial-sponsors/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(
    @Path('id') id: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number
  ): Observable<Audit<ClinicalTrialSponsor>[]> {
    return;
  }

  @GET('clinical-trial-sponsors/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<{ count: number }> {
    return;
  }

  @POST('clinical-trial-sponsors/submit-job')
  @Produces(MediaType.JSON)
  public submitJob(@Body sponsor: ClinicalTrialSponsor): Observable<ClinicalTrialSponsor> {
    return;
  }

  @GET('clinical-trial-sponsors/{id}/connection-suggestions')
  @Produces(MediaType.JSON)
  public getConnectionSuggestions(
    @Path('id') id: string
  ): Observable<{ type: string; connection: ClinicalTrialSponsorConnection }[]> {
    return;
  }
}
