import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { ProjectTagsComponent } from './project-tags.component';
import { ProjectTagsFormComponent } from './shared/form/form.component';
import { ProjectTagsListComponent } from './list/list.component';
import { ProjectTagsAPI } from './shared/api.service';
import { projectTagsRouting } from './project-tags.routes';

@NgModule({
  imports: [SharedModule, projectTagsRouting],
  declarations: [ProjectTagsComponent, ProjectTagsFormComponent, ProjectTagsListComponent],
  exports: [ProjectTagsFormComponent],
  providers: [ProjectTagsAPI],
})
export class ProjectTagsModule {}
