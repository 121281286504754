import { Component, OnInit } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { tap, take, concatMap, catchError } from 'rxjs/operators';

import { AccountAPI } from '../../account/shared/account-api.service';
import { AccountMedicalInformation } from '../shared/account-medical-information';
import { AccountMedicalInformationAPI } from '../shared/account-medical-information-api.service';
import { ACL } from '../../shared/acl/acl.service';

@Component({
  selector: 'dirt-account-medical-information-create',
  templateUrl: './create.component.html',
})
export class AccountMedicalInformationCreateComponent implements OnInit {
  accountMedicalInformation: AccountMedicalInformation = new AccountMedicalInformation();

  isSubmitting = false;

  isFormValid: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcAccountMedicalInformation: AccountMedicalInformationAPI,
    private svcAccount: AccountAPI,
    private svcACL: ACL
  ) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        take(1),
        concatMap((params) => this.svcAccount.findById(params['accountId']))
      )
      .subscribe((account) => {
        this.accountMedicalInformation.account = account;
      });
  }

  goBack(): void {
    this.router.navigate(['/account/detail', this.accountMedicalInformation.account.id]);
  }

  async onSubmit(accountMedicalInformation: AccountMedicalInformation): Promise<void> {
    if (
      !this.svcACL.hasCredential('account-medical-information.create') ||
      !accountMedicalInformation ||
      !this.isFormValid
    ) {
      return;
    }

    await this.saveAccountMedicalInformation(accountMedicalInformation);
    this.goBack();
  }

  onFormValidityChange(status: string): void {
    this.isFormValid = status === 'VALID';
  }

  private async saveAccountMedicalInformation(accountMedicalInformation: AccountMedicalInformation): Promise<any> {
    this.isSubmitting = true;

    return await firstValueFrom(
      this.svcAccountMedicalInformation.create(accountMedicalInformation).pipe(
        tap(() => (this.isSubmitting = false)),
        catchError((err) => {
          this.isSubmitting = false;
          throw err;
        })
      )
    );
  }
}
