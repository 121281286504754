<section class="dirt-section-list dirt-guideline-author-list clearfix">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ authors?.length }}&nbsp;</span>
        <span>Authors</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        id="guidelines-searchbar"
        class="form-control"
        placeholder="Search..."
        [formControl]="searchCtrl"
      />
    </div>

    <div class="col-sm-4">
      <!-- Create Button -->
      <ng-template [diAcl]="'guideline.create.author'" [diAclAnd]="!readonly">
        <button class="btn btn-success btn-sm pull-right" (click)="onCreateClick()" [disabled]="isFormOpened">
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
          <span>Create Authorship</span>
        </button>
      </ng-template>
    </div>
  </header>

  <!-- Form -->
  <div class="dirt-fieldset" *ngIf="isFormOpened">
    <dirt-authorship-form
      #frmAuthorship="frmAuthorship"
      [author]="selectedAuthorship"
      (validityChange)="onFormValidityChange($event)"
    ></dirt-authorship-form>

    <div class="pull-right">
      <button class="btn btn-secondary" [disabled]="isSubmitting" (click)="onCancel()">Cancel</button>

      <button
        *ngIf="!selectedAuthorship._id"
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="!isFormValid || isSubmitting"
        (click)="onSaveAndNew(frmAuthorship.getValue())"
      >
        Save & New
      </button>

      <button
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="!isFormValid || isSubmitting"
        (click)="onSave(frmAuthorship.getValue())"
      >
        Save
      </button>
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-baseline">
    <!-- Pagination -->
    <dirt-pagination
      class="dirt-pagination"
      [id]="'dirt-guideline-author-list'"
      [pagingSize]="displayedAuthors?.length || 0"
      [pagingSkip]="pagingSkip"
      [pagingLimit]="pagingLimit"
      [pagingTotal]="authors?.length || 0"
      [hidden]="pagingLimit >= authors?.length"
      (pageChange)="getPage($event)"
    >
    </dirt-pagination>

    <!-- Just need an empty spacer when the pagination is hidden -->
    <span></span>

    <button
      class="btn btn-sm btn-outline-primary"
      [ngClass]="{ active: showDuplicates }"
      (click)="onToggleShowDuplicate()"
    >
      Show Duplicates
    </button>
  </div>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="authors?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-firstname" [diColSorter]="'firstName'">First Name</th>
          <th class="col-middlename" [diColSorter]="'middleName'">Middle Name</th>
          <th class="col-lastname" [diColSorter]="'lastName'">Last Name</th>
          <th class="col-originalFullName" [diColSorter]="'originalFullName'" *ngIf="hasFullName()">
            Original Full Name
          </th>
          <th class="col-position" [diColSorter]="'position'">Position</th>
          <th class="col-institution" [diColSorter]="'institution'">Institution</th>
          <th class="col-city" [diColSorter]="'city'">City</th>
          <th class="col-country" [diColSorter]="'country'">Country</th>
          <th class="col-verified" [diColSorter]="'verified'">Verified</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-guideline-author-row"
          *ngFor="
            let author of displayedAuthors
              | paginate
                : {
                    id: 'dirt-guideline-author-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: authors?.length
                  }
          "
        >
          <td class="col-firstname">{{ author.firstName }}</td>
          <td class="col-middlename">{{ author.middleName }}</td>
          <td class="col-lastname">{{ author.lastName }}</td>
          <td class="col-originalFullName" *ngIf="hasFullName()">{{ author.originalFullName }}</td>
          <td class="col-position">{{ author.position | capitalize }}</td>
          <td class="col-institution">{{ author.institution }}</td>
          <td class="col-city">{{ author.city }}</td>
          <td class="col-country">{{ author.displayedCountry }}</td>
          <td class="col-verified">
            <input
              type="checkbox"
              [disabled]="!canVerifyAuthor()"
              [checked]="author.verified"
              (change)="onAuthorVerifiedChange(author, $event)"
            />
          </td>
          <td class="col-actions">
            <!-- Edit -->
            <ng-template [diAcl]="'guideline.update.author'" [diAclAnd]="!readonly && !isFormOpened">
              <span class="action-btn action-edit" (click)="onEditClicked(author)">
                <i class="fa fa-pencil" aria-hidden="true"></i>
              </span>
            </ng-template>

            <!-- Delete -->
            <ng-template [diAcl]="'guideline.delete.author'" [diAclAnd]="!readonly && !isFormOpened">
              <span class="action-btn action-delete" (click)="onDeleteClicked(author._id)">
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No Authorship</p>
    </ng-template>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
