import { catchError, map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { APIService } from '../../shared/services/api/api.service';
import { Committee } from './committee';
import {
  GET,
  MediaType,
  Produces,
  Query,
  POST,
  Body,
  Path,
  PATCH,
  DELETE,
} from '../../shared/services/rest/rest.service';

@Injectable()
export class CommitteeAPI extends APIService {
  @GET('committees/')
  @Produces(MediaType.JSON)
  public find(
    @Query('name') name?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<Committee[]> {
    return;
  }

  @GET('committees/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Committee> {
    return;
  }

  @GET('committees/count')
  @Produces(MediaType.JSON)
  public count(@Query('name') name?: string, @Query('filter') filter?: any): Observable<any> {
    return;
  }

  @POST('committees')
  @Produces(MediaType.JSON)
  public create(@Body committee: Committee): Observable<any> {
    return;
  }

  @PATCH('committees/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body committee: Partial<Committee>): Observable<any> {
    return;
  }

  @DELETE('committees/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }

  @GET('committees/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  @GET('committees/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }

  @POST('committees/submit-job')
  @Produces(MediaType.JSON)
  public submitJob(@Body committee: Committee): Observable<Committee> {
    return;
  }

  /**
   * Upload the file and get a bulk upload ID
   *
   * @param file
   * @param secondEyes
   */
  public startBulkListUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'committees/bulk-list', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  /**
   * Get the file as binary
   */
  public downloadCommitteesFile(filter?: any, name?: string): Observable<any> {
    let filterUrl = filter ? 'filter=' + encodeURIComponent(JSON.stringify(filter)) : null;
    if (name) {
      filterUrl = (filterUrl ? filterUrl + '&' : '') + 'name=' + encodeURIComponent(name);
    }
    return this.http
      .get(this.getBaseUrl() + 'committees/export' + (filterUrl ? '?' + filterUrl : ''), { responseType: 'blob' })
      .pipe(
        catchError((err) => {
          console.log(err);
          return this.handleError(err);
        })
      );
  }
}
