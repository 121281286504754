import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'dirt-inline-edit',
  templateUrl: 'inline-edit.component.html',
  styleUrls: ['inline-edit.component.scss'],
})
export class InlineEditComponent {
  @Input()
  value: string;
  editValue: string;
  @Output()
  valueChange: EventEmitter<string> = new EventEmitter();

  @Input()
  name: string;
  @Input()
  size: number = 20;
  @Input()
  suggestions: string[] = null; // (so far, do a dropdown)

  editMode = false;

  @ViewChild('ctrl')
  editControl: ElementRef;

  constructor() {}

  onStartEdit() {
    this.editValue = this.value;
    this.editMode = true;
    setTimeout(() => {
      this.editControl.nativeElement.focus();
    }, 50);
  }
  onBlurEdit() {
    setTimeout(() => {
      if (this.editMode) {
        // if still in edit mode (no cancel clicked): confirm
        this.onConfirmEdit();
      }
    }, 50);
  }
  onConfirmEdit() {
    const newValue = this.editValue;
    this.editMode = false;
    if (newValue !== this.value) {
      this.value = newValue;
      this.valueChange.emit(newValue);
    }
  }
  onCancelEdit() {
    this.editMode = false;
  }

  hasSuggestions() {
    return this.suggestions && this.suggestions.length > 0;
  }
}
