import { ResultDialogComponent } from './result-dialog.component';
import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class ResultDialogService {
  modalOptions: NgbModalOptions = {
    container: 'body',
    size: 'lg',
  };

  constructor(private svcModal: NgbModal) {}

  async open(options: { title: string; results: any[] }): Promise<any> {
    const ref = this.svcModal.open(ResultDialogComponent, this.modalOptions);

    ref.componentInstance.title = options.title;
    ref.componentInstance.results = options.results;

    return ref.result;
  }
}
