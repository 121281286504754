import { Routes, RouterModule } from '@angular/router';

import { AccountComponent } from './account.component';
import { AccountCreateComponent } from './create/create.component';
import { AccountListComponent } from './list/list.component';
import { AccountModule } from './account.module';
import { AclGuard } from '../shared/acl/guard.service';
import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { ModuleWithProviders } from '@angular/core';
import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';
import { AccountDetailComponent } from './detail/detail.component';

const accountRoutes: Routes = [
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'create',
        component: AccountCreateComponent,
      },
      {
        path: 'detail/:id',
        component: AccountDetailComponent,
      },
      {
        path: 'list',
        component: AccountListComponent,
        data: { acl: 'account.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
    data: { title: 'Account' },
  },
];

export const accountRouting: ModuleWithProviders<AccountModule> = RouterModule.forChild(accountRoutes);
