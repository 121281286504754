<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Bulk update</h4>
</div>
<div class="modal-stepper">
  <dirt-bulk-update-stepper [steps]="stepsOptions" [activeStep]="currentStep$ | async"> </dirt-bulk-update-stepper>
</div>
<div class="modal-body modal-steps-wrapper">
  <div #stepsContainer class="di-steps-container-wrapper">
    <dirt-bulk-update-steps-container [activeStep]="currentStep$ | async">
      <dirt-bulk-update-select-step firstStep></dirt-bulk-update-select-step>
      <dirt-bulk-update-field-value-step secondStep></dirt-bulk-update-field-value-step>
      <dirt-bulk-update-results-step thirdStep></dirt-bulk-update-results-step>
    </dirt-bulk-update-steps-container>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark di-prev-btn"
    *ngIf="(currentStep$ | async) !== Step.first"
    [disabled]="
      (updating$ | async) ||
      (options.srvApi && (mode$ | async) === Mode.filter && (currentStep$ | async) === Step.second)
    "
    (click)="onPreviousStepClick()"
  >
    Previous
  </button>
  <button
    type="button"
    class="btn btn-outline-dark"
    *ngIf="(currentStep$ | async) === Step.first && (mode$ | async) === Mode.manual && !options.srvApi.simple"
    [disabled]="loading$ | async"
    (click)="onPrevalidateClick()"
  >
    Prevalidate
  </button>
  <button
    type="button"
    class="btn btn-outline-dark"
    *ngIf="(currentStep$ | async) === Step.first"
    [disabled]="
      (loading$ | async) ||
      (count$ | async) === 0 ||
      ((mode$ | async) === Mode.manual && !options.srvApi.simple && (validCount$ | async) !== (count$ | async))
    "
    (click)="onNextStepClick()"
  >
    Next
  </button>
  <button
    type="button"
    class="btn btn-outline-dark"
    *ngIf="(currentStep$ | async) === Step.second"
    [disabled]="!(isValuesSet$ | async)"
    (click)="onNextStepClick()"
  >
    Start
  </button>
  <button class="btn btn-outline-dark" [disabled]="updating$ | async" (click)="onCloseClick()">
    {{ (currentStep$ | async) === Step.third ? 'Done' : 'Cancel' }}
  </button>
</div>
