import { Account } from '../../account/shared/account';

export class AccountFinancialInformation {
  id: string;
  account: Account;
  year: number;
  quarter: number;
  annualRevenue: number;
  quarterlyRevenue: number;
  annualNetResultProfit: number;
  annualNetResultLoss: number;
  quarterlyNetResultProfit: number;
  quarterlyNetResultLoss: number;
  reportingCurrency: number;
  employeeCount: number;
  websources: string[];
  createdAt: Date;
  updatedAt: Date;
}
