import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Mode } from '../../mode.enum';
import { BulkUpdateModalComponent } from '../bulk-update-modal/bulk-update-modal.component';
import { Subscription } from 'rxjs';
import { Utils } from '../../../utils';
import { Auth } from '../../../../shared/services/auth/auth.service';
import { BulkUpdateApi } from '../../bulk-update-api';
import { BulkUpdateModel } from '../../bulk-update.model';
import { BulkUpdateOptions } from '../../bulk-update-options';
import { BulkUpdateApiSimple } from '../../bulk-update-api-simple';

@Component({
  selector: 'dirt-bulk-update',
  templateUrl: './bulk-update.component.html',
})
export class BulkUpdateComponent implements OnInit, OnDestroy {
  @Input()
  search: string;

  @Input()
  filter: any;

  @Input()
  filterCount: number = 0;

  get isFilterNotEmpty() {
    if (!this.filter) {
      return false;
    }
    return !Utils.isEmptyObject(this.filter);
  }

  @Input()
  options: BulkUpdateOptions<BulkUpdateApi<BulkUpdateModel> | BulkUpdateApiSimple, BulkUpdateModel>;

  @Output()
  refresh = new EventEmitter();

  refreshSub: Subscription;

  constructor(private svcModal: NgbModal, public auth: Auth) {}

  ngOnInit() {}

  openModel() {
    return this.svcModal.open(BulkUpdateModalComponent, {
      centered: true,
      size: 'lg',
      backdrop: false,
      windowClass: 'di-bulk-update-modal',
    });
  }

  onBulkUpdateManualClick() {
    const modalRef = this.openModel();
    modalRef.componentInstance.mode = Mode.manual;
    modalRef.componentInstance.options = this.options;
    modalRef.componentInstance.init();
    this.refreshSub = modalRef.componentInstance.refresh.subscribe(() => {
      this.refresh.emit();
    });
  }

  onBulkUpdateFilterClick() {
    if ((this.filterCount || 0) > 500) {
      if (!confirm('You are about to change ' + this.filterCount + ' items - VERY sure?')) {
        return;
      }
    }
    const modalRef = this.openModel();
    modalRef.componentInstance.mode = Mode.filter;
    modalRef.componentInstance.search = this.search;
    modalRef.componentInstance.filter = this.filter;
    modalRef.componentInstance.options = this.options;
    this.refreshSub = modalRef.componentInstance.refresh.subscribe(() => {
      this.refresh.emit();
    });
    modalRef.componentInstance.init();
  }

  ngOnDestroy() {
    if (this.refreshSub) {
      this.refreshSub.unsubscribe();
    }
  }
}
