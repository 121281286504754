export const ContributorTitles = [
  'ACNS-BC',
  'ANP-C',
  'APRN',
  'ATSF',
  'BA(Hons)',
  'MB BCh',
  'BC',
  'BS(Hons)',
  'BSN',
  'BSc(Hons)',
  'BSc',
  'BS',
  'BHS',
  'CCP',
  'CCRN',
  'CE',
  'CHDA',
  'CM',
  'CN',
  'CR',
  'DACVP',
  'DC',
  'DM',
  'DO',
  'D.Phil',
  'DPhil',
  'DSc',
  'DrPH',
  'FACMG',
  'FACP',
  'FACS',
  'FACR',
  'FASCO',
  'FASTRO',
  'FRACP',
  'FRCPC',
  'FRCP',
  'FS',
  'JD',
  'MA',
  'MACP',
  'MBA',
  'MBBS',
  'MBh',
  'MBE',
  'MD',
  'MEd',
  'MhB',
  'MHS',
  'MMed',
  'MPH',
  'MSci',
  'MSc',
  'MSN',
  'MS',
  'MRCP(UK)',
  'PA',
  'PharmD',
  'PH',
  'PT',
  'Ph D',
  'Ph.D',
  'PhD',
  'RN',
  'RPFT',
  'RPFT',
  'RRT',
  'ScD',
  'LGC',
  'LSW',
  'MRCP',
  'MRCP',
  'MPP',
  'LCGC',
  'JD',
  'FSCT',
  'MRCP',
  'FR',
  'ChPM',
  'CHES',
  'CC',
  'BA',
  'AP',
  'AOCNS',
  'AN',
  'FA',
  'FAAP',
  'FAAHPM',
  'MGC',
  'CS',
  'MB',
  'APRN-BC',
  'ABFT',
  'ANP-BC',
  'AOCN',
  'APRN',
  'APRN-BC',
  'B.Sc.',
  'BA',
  'BCOP',
  'BN',
  'BPharm',
  'BS',
  'BSN',
  'BSc',
  'BSc (c)',
  'BScPharm',
  'CCFP',
  'CCRP',
  'CGC',
  'CHES',
  'CM',
  'CPC',
  'CPNP',
  'CRNP',
  'D.O.',
  'D.Phil',
  'DDS',
  'DNB',
  'DO',
  'DPhil',
  'DSc',
  'Dipl.-Biol.',
  'Dr.',
  'DrPH',
  'FAAHPM',
  'FAAN',
  'FAAP',
  'FACC',
  'FACMG',
  'FACP',
  'FACR',
  'FACS',
  'FAChPM',
  'FASCO',
  'FASTRO',
  'FCCP',
  'FCRP',
  'FMedSci',
  'FRACP',
  'FRCP',
  'FRCPC',
  'FRCPath',
  'FRCR',
  'FRCS',
  'FSCT',
  'JD',
  'LCGC',
  'LGC',
  'LL.M.',
  'LSW',
  'M.D',
  'M.D.',
  'M.D.',
  'M.S.',
  'M.D.',
  'Ph.D.',
  'M.Hlth.Sc',
  'MA',
  'MACP',
  'MB',
  'MB BCh BAO',
  'MBA',
  'MBA.',
  'MBBCh',
  'MBBChir',
  'MBBS',
  'MBBS BSc',
  'MBBS FRANZCR',
  'MBBS MPhil',
  'MBChB',
  'MBOE',
  'MCh',
  'MD',
  'MD MS',
  'MD PHD',
  'MD PhD',
  'MD,PhD',
  'MD.',
  'MD.',
  'Ph.D',
  'MD/PhD',
  'MD/PhD student',
  'MDCM',
  'MEd',
  'MGC',
  'MHS',
  'MHSA',
  'MHSc',
  'MMed',
  'MMedSc',
  'MPA',
  'MPH',
  'MPP',
  'MPhil',
  'MRCP',
  'MRCPATH',
  'MRCPI',
  'MRCPUK',
  'MS',
  'MSCE',
  'MSN',
  'MSPH',
  'MSW',
  'MSc',
  'MSc (Advanced Oncology)',
  'Master',
  'Md Phd',
  'Medical Oncology',
  'None',
  'PT',
  'Ph MD',
  'Ph.D',
  'Ph.D.',
  'PhD',
  'PhD (c)',
  'PhD MPH',
  'PhD student',
  'PhD.',
  'PhDc',
  'PharmD',
  'Prof. Dr. med.',
  'Psychologist',
  'RD',
  'RN',
  'RPh',
  'ScD',
  'ScM',
  'AGPCNP-BCS',
  'D.H.Sc',
  'FASCO',
  'Gail J.',
  'Roboz',
];
