import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';

import { ClinicalTrialProfileDetailComponent } from './detail/detail.component';
import { ClinicalTrialProfileModule } from './clinical-trial-profile.module';
import { ClinicalTrialProfileComponent } from './clinical-trial-profile.component';
import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';
import { ClinicalTrialProfileExtListComponent } from './list/list.component';

const ctProfileRoutes: Routes = [
  {
    path: 'ct-profile',
    component: ClinicalTrialProfileComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'detail/:id',
        component: ClinicalTrialProfileDetailComponent,
      },
      {
        path: 'list',
        component: ClinicalTrialProfileExtListComponent,
        data: { acl: 'clinicalTrialProfile.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
    data: { title: 'CT' },
  },
];

export const ctProfileRouting: ModuleWithProviders<ClinicalTrialProfileModule> = RouterModule.forChild(ctProfileRoutes);
