import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';

import { ProfileComponent } from './profile.component';
import { ProfileListComponent } from './list/list.component';
import { ProfileDetailComponent } from './detail/detail.component';
import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';
import { ProfileModule } from './profile.module';

const profileRoutes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'detail/:id',
        component: ProfileDetailComponent,
      },
      {
        path: 'list',
        component: ProfileListComponent,
        data: { acl: 'profile.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
    data: { title: 'Profile' },
  },
];

export const profileRouting: ModuleWithProviders<ProfileModule> = RouterModule.forChild(profileRoutes);
