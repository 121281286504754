export class Contribution {
  public id: string;
  public title: string;
  public plainText?: string;
  public originalPlainText?: string;
  public pdfUrl?: string;
  public event: any;
  public reviewed?: boolean;
  public verified?: boolean;
  public person: {
    firstName?: string;
    lastName?: string;
    middleName?: string;
    nickname?: string;
    fullName?: string;
    position?: string;
    suffix?: string;
    workplace?: string;
    sourceName?: {
      sourceStr: string;
      firstName: string;
      middleName: string;
      lastName: string;
    };
    contributionDate?: Date;
    contributionTime?: {
      hour: number;
      minute: number;
    };
  } = {};
  public sponsors: string[] = [];
  public markForDelete: boolean;
  public markForMove: boolean;
  qc: any;
  public originalLanguage?: string;

  constructor() {}
}
