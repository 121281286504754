import { NgModule, Type } from '@angular/core';

import { EmailValidator } from './email.validator';
import { UrlValidator } from './url.validator';
import { NameValidator } from './name.validator';
import { NameWithoutDotValidator } from './nameWithoutDot.validator';
import { ListValidator } from './list.validator';
import { AllowEmptyValidator } from './allowEmpty.validator';
import { DomainValidator } from './domain.validator';
import { TwitterHandleValidator } from './twitterHandle.validator';
import { TwitterHashtagValidator } from './twitterHashtag.validator';
import { DomainComplianceValidator } from './domainCompliance.validator';
import { MongoIdValidator } from './mongoId.validator';
import { RequiredOrNullValidator } from './requiredOrNull.validator';
import { IntegerValidator } from './integer.validator';

export { UrlValidator } from './url.validator';
export { EmailValidator } from './email.validator';
export { NameValidator } from './name.validator';
export { ListValidator } from './list.validator';
export { AllowEmptyValidator } from './allowEmpty.validator';
export { DomainValidator } from './domain.validator';
export { TwitterHandleValidator } from './twitterHandle.validator';
export { IntegerValidator } from './integer.validator';

export const DIRT_VALIDATORS: Type<any>[] = [
  EmailValidator,
  RequiredOrNullValidator,
  UrlValidator,
  NameValidator,
  NameWithoutDotValidator,
  ListValidator,
  AllowEmptyValidator,
  DomainValidator,
  TwitterHandleValidator,
  TwitterHashtagValidator,
  DomainComplianceValidator,
  MongoIdValidator,
  IntegerValidator,
];

/**
 * Internal module used for sharing form validators
 */
@NgModule({
  declarations: DIRT_VALIDATORS,
  exports: DIRT_VALIDATORS,
})
export class DirtValidatorsModule {}
