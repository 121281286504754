<div class="modal-header">
  <h5 class="modal-title">Move Account</h5>
</div>

<div class="modal-body">
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="name">New Parent (Optional)</label>
    <div class="col-sm-8 col-md-10">
      <dirt-account-single-select [wide]="true" (accountSelected)="newParent = $event"></dirt-account-single-select>

      <p *ngIf="newParent">
        New Parent:
        <a [routerLink]="['/account/detail', newParent.id]" rel="noopener" target="_blank">{{ newParent.name }}</a>
      </p>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-outline-dark" [disabled]="this.isLoading" (click)="activeModal.dismiss()">
    Cancel
  </button>
  <button class="btn btn-sm btn-success" [disabled]="this.isLoading" (click)="onSubmit()">Move Account</button>
</div>
