<section class="dirt-section-detail dirt-ct-sponsor-detail">
  <div class="row" *ngIf="!isLoading; else loader">
    <div class="col-md-8">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <div class="dirt-title-ct">
        <h1 class="bounceInRight">Sponsor Detail</h1>

        <div>
          <button type="button" class="btn btn-outline-primary" (click)="onCopyID(sponsor.id)">Copy ID</button>
        </div>
      </div>

      <dirt-ct-sponsor-form
        #frmSponsor
        [model]="sponsor"
        [currentJobType]="currentJob?.type"
        (validityChange)="onFormValidityChange($event)"
      ></dirt-ct-sponsor-form>

      <!-- Job controls -->
      <div *ngIf="hasJobForCurrentUser; else updateControls">
        <div class="pull-left">
          <!-- Unable to Compile -->
          <button class="btn btn-outline-danger" (click)="onJobUtc()">Unable to Compile!</button>
        </div>

        <div class="pull-right">
          <!-- Save as Draft -->
          <button
            class="btn btn-secondary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="isSubmitting"
            (click)="onJobDraft(frmSponsor.getValue())"
          >
            Save as Draft
          </button>

          <!-- Submit -->
          <button
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!isFormValid || isSubmitting"
            (click)="onSubmitJob(frmSponsor.getValue())"
          >
            Submit Job
          </button>
        </div>
      </div>

      <!-- Standard controls -->
      <ng-template #updateControls>
        <div class="pull-right" *ngIf="canSubmitNonJob">
          <button
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!isFormValid || isSubmitting"
            (click)="onSave(frmSponsor.getValue())"
          >
            Save
          </button>
        </div>
      </ng-template>
    </div>

    <div class="col-md-4">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Workflow -->
        <li ngbNavItem>
          <a ngbNavLink>Workflow</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <dt class="col-sm-4" [hidden]="!isClaimedByOtherUser()"></dt>
              <dd class="col-sm-8" [hidden]="!isClaimedByOtherUser()">
                <span title="s/o else started working recently" class="badge badge-info">Claimed</span>
              </dd>

              <dt class="col-sm-4" *ngIf="hasJobForCurrentUser">Job</dt>
              <dd class="col-sm-8" *ngIf="hasJobForCurrentUser">
                {{ currentJob?.type + (currentJob?.hint ? ' - ' + currentJob.hint : '') }}
              </dd>

              <dt class="col-sm-4" [ngClass]="{ 'di-separator': hasJobForCurrentUser }">Created</dt>
              <dd class="col-sm-8" [ngClass]="{ 'di-separator': hasJobForCurrentUser }">
                {{ sponsor.createdAt | date : 'medium' }}
              </dd>

              <dt class="col-sm-4 text-truncate">Modified</dt>
              <dd class="col-sm-8">{{ sponsor.updatedAt | date : 'medium' }}</dd>

              <dt class="col-sm-4">Version</dt>
              <dd class="col-sm-8">{{ sponsor._version || 0 }}</dd>

              <ng-container *ngIf="sponsor.curated?.at">
                <dt class="col-sm-4 text-truncate di-separator">Curated at</dt>
                <dd class="col-sm-8 di-separator">{{ sponsor.curated.at | date : 'medium' }}</dd>

                <dt class="col-sm-4 text-truncate">Curated by</dt>
                <dd class="col-sm-8 font-italic">
                  <dirt-username [userId]="sponsor.curated.by"></dirt-username>
                </dd>
              </ng-container>
            </dl>
          </ng-template>
        </li>

        <!-- Comments -->
        <ng-template [diAcl]="'clinicalTrialSponsor.comment.list'">
          <li ngbNavItem>
            <a ngbNavLink>Comments</a>
            <ng-template ngbNavContent>
              <dirt-comment-list [ownerId]="id" [enableCreate]="canCreateComments"></dirt-comment-list>
            </ng-template>
          </li>
        </ng-template>

        <!-- Audit Log -->
        <ng-template [diAcl]="'clinicalTrialSponsor.audit.list'">
          <li ngbNavItem>
            <a ngbNavLink>Audit Log</a>
            <ng-template ngbNavContent>
              <dirt-audit-log [id]="id" [entityAPI]="svcClinicalTrialSponsor"></dirt-audit-log>
            </ng-template>
          </li>
        </ng-template>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-ct-sponsor-meta"></div>
    </div>
  </div>

  <!-- Loading Message -->
  <ng-template #loader>
    <p class="dirt-loading">
      <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
      <span class="sr-only">Loading...</span>
    </p>
  </ng-template>
</section>
