import { Component, Output, EventEmitter, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Observable, firstValueFrom, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { OrganizationAPI } from '../../api.service';
import { Organization } from '../../organization';

@Component({
  selector: 'dirt-organization-lookup',
  templateUrl: 'organization-lookup.component.html',
  styleUrls: ['organization-lookup.component.scss'],
})
export class OrganizationLookupComponent implements OnInit {
  @Input()
  rootOrganizationId: string;
  @Input()
  disabled: boolean = false;
  @Input()
  // Enables the included component checkbox for the user to switch between global and healthsystem-focussed search
  allowOutsideRoot: boolean = false;
  @Input()
  // Whether health systems outside of `rootOrganizationId` should be searched
  outsideRoot: boolean = false;
  @Input()
  // Whether to only search for root organizations or to include non-root child organizations in the search results
  onlyRoot: boolean = false;
  @Input()
  minimumCharactersBeforeSearch: number = 0;
  @Input()
  transformed?: boolean;
  @Input()
  ignoreOrgIds: string[] = [];
  @Output()
  organizationChange: EventEmitter<Organization> = new EventEmitter();

  @ViewChild('srch')
  searchBox: ElementRef;

  isSearching = false;
  searchTerm: string;

  searchAutoComplete = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => {
        if (!term || term.length < this.minimumCharactersBeforeSearch) {
          return of([]);
        }
        const filter = this.transformed ? { transformed: true } : undefined;
        const search = this.onlyRoot
          ? this.svcOrganization.find(true, term, 25, undefined, undefined, filter)
          : this.outsideRoot
          ? this.svcOrganization.find(undefined, term, 25, undefined, undefined, filter)
          : this.svcOrganization.findForRoot(this.rootOrganizationId, term, 25, undefined, undefined, filter);
        return search.pipe(
          take(1),
          switchMap(async (orgs) => {
            // Also fetch all roots associated with the found matches
            const uniqueRoots = new Set(orgs.map((o) => o.root));
            orgs.forEach((o) => uniqueRoots.delete(o.root));
            const rootOrgs = await Promise.all(
              [...uniqueRoots].map((id) => firstValueFrom(this.svcOrganization.findById(id)))
            );

            return [...rootOrgs, ...orgs].filter((o) => !this.ignoreOrgIds.includes(o._id.toString()));
          }),
          catchError(() => of([]))
        );
      }),
      tap(() => (this.isSearching = false))
    );

  constructor(private svcOrganization: OrganizationAPI) {}

  ngOnInit() {
    setTimeout(() => this.searchBox?.nativeElement?.focus());
  }

  changeOutsideRoot() {
    this.outsideRoot = !this.outsideRoot;
    this.searchBox?.nativeElement?.focus();
  }

  onSelectItem(evnt) {
    this.organizationChange.emit(evnt.item);
    // When propagated, causes the selected item to be evaluated as text and replace the search term, ie. showing "[Object object]"
    evnt.preventDefault();
    // but that's not enough, some selections need to know what we have
    if (this.searchBox?.nativeElement && evnt.item?._id) {
      this.searchBox.nativeElement.value = evnt.item.fullName || evnt.item.name;
    }
  }
}
