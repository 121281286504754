<form #formImage="ngForm" [diMarkerWrapper]="model">
  <ng-container *ngIf="!hideTwitterFields">
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">No Twitter</label>
      <div class="col-sm-8 col-md-10 pt-1">
        <ui-switch
          size="small"
          color="#6d5cae"
          name="isTwitterHandleNotFound"
          [(ngModel)]="!!model.isTwitterHandleNotFound"
          (ngModelChange)="onTwitterNotFoundChange()"
          [disabled]="disableTwitterHandleControls"
        >
        </ui-switch>
      </div>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="twitterHandle">Twitter handle</label>

      <div class="col-sm-8 col-md-10 input-group">
        <input
          type="twitter"
          id="twitterHandle"
          class="form-control"
          name="twitterHandle"
          [formControl]="twitterHandleCtrl"
          [readonly]="disableTwitterHandleControls"
          twitterHandleValidator
        />

        <div
          class="input-group-append btn-group-toggle di-twitter-approved-toggle"
          [ngClass]="{ disabled: disableTwitterHandleControls || model.isTwitterHandleNotFound }"
          name="twitter-approved"
          ngbRadioGroup
          [(ngModel)]="model.isTwitterHandleApproved"
          (change)="onIsTwitterHandleApprovedChange()"
        >
          <label class="btn-secondary" ngbButtonLabel>
            <input
              type="radio"
              [value]="true"
              ngbButton
              [disabled]="disableTwitterHandleControls || model.isTwitterHandleNotFound || hasTwitterHandleError"
            />
            <i class="fa fa-thumbs-up" [ngClass]="{ approved: !!model.isTwitterHandleApproved }" aria-hidden="true"></i>
          </label>

          <label class="btn-secondary" ngbButtonLabel>
            <input
              type="radio"
              [value]="false"
              ngbButton
              [disabled]="disableTwitterHandleControls || model.isTwitterHandleNotFound || hasTwitterHandleError"
            />
            <i
              class="fa fa-thumbs-down"
              [ngClass]="{ disapproved: model.isTwitterHandleApproved === false }"
              aria-hidden="true"
            ></i>
          </label>
        </div>
      </div>

      <!-- Errors -->
      <ng-container *ngIf="twitterHandleCtrl.dirty || twitterHandleCtrl.touched">
        <div class="col-sm-8 col-md-10 offset-sm-4 offset-md-2 error" *ngIf="twitterHandleCtrl.errors?.['invalid']">
          Handle may only contains alphanumeric characters and cannot be longer than 15 characters.
        </div>

        <div class="col-sm-8 col-md-10 offset-sm-4 offset-md-2 error" *ngIf="twitterHandleCtrl.errors?.['unknown']">
          No Twitter account matching handle.
        </div>
      </ng-container>
    </div>

    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Use Twitter Image</label>

      <div class="col-sm-8 col-md-10 pt-1">
        <ui-switch
          size="small"
          color="#6d5cae"
          name="useTwitterHandle"
          [(ngModel)]="model.useTwitterHandle"
          (ngModelChange)="onUseTwitterHandleChange($event)"
          [disabled]="
            disableImageControls ||
            model.isTwitterHandleNotFound ||
            !twitterHandleCtrl.value ||
            !model.isTwitterHandleApproved ||
            !!twitterHandleCtrl.errors
          "
          [loading]="twitterHandleCtrl.pending"
        >
          <i class="fa fa-spinner fa-pulse" *ngIf="twitterHandleCtrl.pending"></i>
        </ui-switch>
      </div>
    </div>
  </ng-container>

  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Image not Found</label>
    <div *diMarker="{ name: 'imageNotFound', area: reviewArea, leftAlign: true }" class="col-sm-8 col-md-10 pt-1">
      <ui-switch
        size="small"
        color="#6d5cae"
        name="isImageNotFound"
        [(ngModel)]="model.isImageNotFound"
        (ngModelChange)="onImageNotFoundChange()"
        [disabled]="disableImageControls"
      >
      </ui-switch>
    </div>
  </div>

  <div class="form-group row link-opener">
    <a rel="noopener" (click)="onVisitAllLinksClick()" target="_blank">
      Visit all links <i class="fa fa-external-link" aria-hidden="true"></i>
    </a>
  </div>

  <div class="form-group row" *diMarker="{ name: 'imageURL', area: reviewArea }">
    <label class="col-form-label col-sm-4 col-md-2" for="url">Image URL</label>

    <div class="col-sm-8 col-md-10">
      <!-- nonCompliantDomainValidator only validate URL format for this case -->
      <input
        type="url"
        id="url"
        class="form-control"
        name="url"
        placeholder="e.g: https://veeva.com"
        [formControl]="imageURLCtrl"
        [readonly]="disableImageControls"
        [nonCompliantDomainValidator]="{ validateFormatOnly: true }"
        *diInputErrorMsg="{
          text: 'Provided URL cannot be loaded or image size is invalid',
          show: !isImagePreviewLoading && (hasURLError || hasImagePreviewError)
        }"
      />
    </div>
  </div>

  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Image Web Source</label>

    <div class="col-sm-8 col-md-10">
      <input
        type="url"
        class="form-control"
        placeholder="e.g: https://veeva.com"
        [formControl]="imageWebsourceCtrl"
        [readonly]="disableImageControls"
        [nonCompliantDomainValidator]="null"
        [sameValueValidator]="imageURLCtrl"
        *diMarker="{ name: 'imageWebsource', area: reviewArea }"
      />
    </div>

    <!-- Errors -->
    <ng-container *ngIf="imageWebsourceCtrl?.dirty || imageWebsourceCtrl?.touched">
      <div
        class="col-sm-8 col-md-10 offset-sm-4 offset-md-2 error"
        *ngIf="imageWebsourceCtrl?.errors?.['non-compliant']"
      >
        This link is non-compliant!
      </div>

      <div class="col-sm-8 col-md-10 offset-sm-4 offset-md-2 error" *ngIf="imageWebsourceCtrl?.errors?.['same-value']">
        Websource has the same value as image URL
      </div>
    </ng-container>
  </div>

  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Original Image Preview (might look different in Link)</label>

    <div class="col-sm-8 col-md-10">
      <div
        class="image-preview di-thumbnail-container"
        [ngClass]="{
          'load-error': hasImagePreviewError,
          'di-squared': displayMode === 'square',
          'di-rounded': displayMode === 'round'
        }"
      >
        <img
          #imagePreview
          [ngClass]="{ 'is-loading': isImagePreviewLoading, 'img-thumbnail': displayMode === 'square' }"
          [src]="defaultImage"
        />

        <dirt-spinner [show]="isImagePreviewLoading"></dirt-spinner>
      </div>
    </div>

    <ng-container
      *ngIf="
        (imageURLCtrl?.dirty || imageURLCtrl?.touched) &&
        imageURLCtrl.value &&
        !isImagePreviewLoading &&
        hasImagePreviewError
      "
    >
      <div class="col-sm-8 col-md-10 offset-sm-4 offset-md-2 error">
        Provided URL cannot be loaded or image size is invalid. Please check the following:
        <ul>
          <li>Image format is PNG, JPEG, WEBP, or SVG</li>
          <li>Image can be opened (not behind login, confirmation, not found, etc)</li>
          <li>Image width or height are more than {{ imageValidationConfig.minSize }}</li>
          <li *ngIf="imageValidationConfig.maxAspectRatio > 0">
            Image aspect ratio is less than {{ imageValidationConfig.maxAspectRatio }}
          </li>
        </ul>
      </div>
    </ng-container>
  </div>
</form>
