import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { take } from 'rxjs/operators';
import { Organization, OrganizationAddress } from '../organization';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { Value } from '../../../shared/services/value/value';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { NgForm } from '@angular/forms';
import { AddressEntity } from '../address-entity';
import { AddressComponent } from '../../../shared/components';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap/modal/modal-ref';
import { OrganizationAPI } from '../api.service';
import { ACL } from '../../../shared/acl/acl.service';
import { Roles } from '../../../shared/acl/roles';
import { JobsModalService } from '../../../jobs/shared/modal/modal.service';
import { JobsAPI } from '../../../jobs/shared/api.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'dirt-organization-detail-form',
  templateUrl: 'organization-detail-form.component.html',
  styleUrls: ['organization-detail-form.component.scss'],
  exportAs: 'frmOrgDetail',
})
export class OrganizationDetailFormComponent implements OnInit, OnChanges {
  @Input('organization')
  model: Organization = new Organization();

  @Input()
  formReadonly: boolean;

  @Input()
  displayExternalOrganization: boolean;
  @Input()
  expandInternalParentName: boolean;

  @Input()
  showCheckedBadge: boolean;

  @Input()
  organizationLineage: { id: string; name: string }[] = [];

  @Input()
  organizationInfo: { [id: string]: Partial<Organization> & { name; addresses? } } = {};
  @Input()
  addressInfo: { [id: string]: Partial<AddressEntity> & { formatted } } = {};

  @Output()
  organizationLinkClicked: EventEmitter<{ id: string }> = new EventEmitter();

  @ViewChild('internalForm')
  internalForm: NgForm;
  @ViewChild('addAddressModal')
  addAddressModal;
  @ViewChild('addAddressForm')
  addAddressForm: AddressComponent;

  types: Value[] = [];
  jobTypes: (Value & { requiredRoles?: string[] })[];
  addParent: boolean = false;
  addAddress: boolean = false;
  addressModalInstance: NgbModalRef = null;
  addressToCreate: AddressEntity = null;
  languages$: Observable<Value[]>; // same as person
  isManager: boolean = false;
  wndw: Window = window; // test-able

  constructor(
    public readonly svcOrganization: OrganizationAPI,
    public readonly svcValue: ValueAPI,
    public readonly svcAcl: ACL,
    private svcModal: NgbModal,
    private svcJobModal: JobsModalService,
    private svcJob: JobsAPI
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['model']) {
      // we need to add one empty `alternativeNames` entry to show at least one input field for curating
      this.initializeLists();
    }
  }

  ngOnInit() {
    this.initializeLists();
    this.svcValue
      .find(ValueType.OrganizationType, Number.MAX_SAFE_INTEGER)
      .pipe(take(1))
      .subscribe((values) => (this.types = values));
    this.languages$ = this.svcValue.find(ValueType.Language, Number.MAX_SAFE_INTEGER, 0, '+title');
    this.isManager = this.svcAcl.hasRole(Roles.Admin) || this.svcAcl.hasRole(Roles.OrganizationManager);
    if (this.isManager) {
      this.svcValue
        .find(ValueType.OrganizationJobType, Number.MAX_SAFE_INTEGER, 0, '+value')
        .pipe(take(1))
        .subscribe((_jts) => (this.jobTypes = _jts));
    }
  }

  isAlien(organization: Organization) {
    let res =
      !!organization &&
      !organization.isRoot &&
      !!organization._id &&
      organization.root?.toString() !== this.model.root?.toString();
    if (this.displayExternalOrganization) {
      res = !res; // it's the other way around
    }
    return res;
  }

  isFieldEditable(field) {
    return (
      !this.formReadonly &&
      this.svcAcl.hasCredential('organization.update.prop.' + field) &&
      !this.svcAcl.hasCredential('organization.update.readonly.prop.' + field)
    );
  }

  private initializeLists(): void {
    const lists = {
      alternativeNames: this.model.alternativeNames,
    };

    Object.entries(lists).forEach(([key, list]) => {
      if (!Array.isArray(list)) {
        this.model[key] = []; // null is a primitive hence it's passed by copy, we need to mutate the original object
        list = this.model[key];
      }

      if (!list.length) {
        list.push('');
      }
    });
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }

  isValid(): boolean {
    // so far: not full form, only field name
    return !!this.internalForm?.controls['name']?.valid;
  }

  onAddSecondaryParent(parent: Organization) {
    this.addParent = false;
    if (!parent) {
      return;
    }
    if (this.model.parents.find((p) => p === parent._id)) {
      return this.wndw.alert('Parent is already assigned');
    }
    if (!this.organizationInfo[parent._id]) {
      this.organizationInfo[parent._id] = parent;
    }
    this.model.parents.push(parent._id);
  }
  onRemoveSecondaryParent(id: string, idx: number) {
    if (0 === idx) {
      throw 'Cannot remove primary parent';
    }
    if (!this.wndw.confirm('Remove secondary parent ' + (this.organizationInfo[id]?.name || id || '-') + '?')) {
      return;
    }
    this.model.parents = this.model.parents.filter((pId) => pId !== id);
  }

  onAddAddress(address: AddressEntity) {
    this.addAddress = false;
    if (!address) {
      return;
    }
    this.model.addresses = this.model.addresses || []; // btw: null or undefined
    if (this.model.addresses.find((a) => a.addressId === address._id)) {
      return this.wndw.alert('Address is already assigned');
    }
    if (!this.addressInfo[address._id]) {
      this.addressInfo[address._id] = address;
    }
    this.model.addresses?.push({ addressId: address._id } as OrganizationAddress);
  }
  onRemoveAddress(id: string, idx: number) {
    if (!this.wndw.confirm('Remove address ' + (this.addressInfo[id]?.formatted || id || '-') + '?')) {
      return;
    }
    this.model.addresses.splice(idx, 1);
  }

  listOtherParentAddresses(): OrganizationAddress[] {
    // all we didn't add yet
    if (this.model.parents && this.model.parents.length) {
      const addr = this.organizationInfo[this.model.parents[0]]?.addresses || [];
      return addr.filter((a) => !this.model.addresses?.find((_a) => _a.addressId === a.id));
    } else {
      return [];
    }
  }
  onAddParentAddress(parentAddr: OrganizationAddress) {
    const copy: OrganizationAddress = JSON.parse(JSON.stringify(parentAddr));
    this.model.addresses = this.model.addresses || [];
    this.model.addresses.push(copy);
  }

  onOpenNewAddressDialog() {
    this.addressToCreate = {} as AddressEntity;
    this.addressModalInstance = this.svcModal.open(this.addAddressModal, { size: 'xl' });
  }
  onCreateAddress() {
    this.addAddress = false;
    this.addressModalInstance.close();
    this.svcOrganization
      .createAddress(this.addressToCreate)
      .pipe(take(1))
      .subscribe((createRes) => {
        this.addressInfo[createRes.res._id] = createRes.res;
        this.model.addresses = this.model.addresses || [];
        this.model.addresses.push({ addressId: createRes.res._id } as OrganizationAddress);
      });
  }

  onJobCreate(): void {
    if (!(this.isManager && this.svcAcl.hasCredential('job.create'))) {
      return;
    }

    this.svcJobModal
      .open(
        this.jobTypes
          .filter(
            (jt) =>
              this.model.isRoot ||
              ['ORGANIZATION_CORRECTNESS_QC', 'ORGANIZATION_TYPE_COMPILATION'].includes(jt.code as string)
          )
          .map((jt) => ({
            key: jt.code as string,
            value: jt.value,
            roles: (jt.requiredRoles as Roles[]) || [Roles.PersonCompiler],
          }))
      )
      .then((res) => {
        if (!res) {
          return;
        }

        const job = {
          ...res,
          entityType: 'organizations',
          entityId: this.model._id,
        };

        this.svcJob.create(job).subscribe(() => alert('Job created'));
      })
      .catch(() => {});
  }

  trackByIndex(idx: number): number {
    return idx;
  }
}
