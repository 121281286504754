import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { JsonViewerComponent } from './json-viewer.component';

@Injectable()
export class JsonViewerService {
  modalOptions: NgbModalOptions = {
    container: 'body',
    size: 'lg',
  };

  constructor(private svcModal: NgbModal) {}

  open(options: any): Promise<any> {
    const ref = this.svcModal.open(JsonViewerComponent, this.modalOptions);

    ref.componentInstance.options = options;

    return ref.result;
  }
}
