<div class="di-affiliation-suggestions">
  <div class="di-suggestion-row" *ngFor="let af of affiliations">
    <div class="di-suggestion-details">
      <div class="di-suggestion-name">
        <a [routerLink]="['/affiliation/detail', af.id]" rel="noopener" target="_blank"
          >{{ af.name }}&nbsp;<i class="fa fa-external-link" aria-hidden="true"></i
        ></a>
        <span *ngIf="af.department">&nbsp;-&nbsp;{{ af.department }}</span>
      </div>
      <div class="di-suggestion-address" *ngIf="af.address?.countryCode">
        <span *ngIf="af.address?.name || af.address?.street">
          <span>{{ af.address.name || af.address.street }}</span>
          <span>{{ af.address.additionalInfo }}</span>
        </span>
        <span *ngIf="af.address?.city">{{ af.address.city }},</span>
        <span *ngIf="af.address?.state">{{ af.address.state }},</span>
        <span>{{ getCountryTitle(af.address?.countryCode) }}</span>
        <span *ngIf="af.address?.zip">{{ af.address.zip }}</span>
      </div>
      <div [ngStyle]="{ color: getTypeColor(af.type) }" *ngIf="af.type">
        <span>{{ affiliationType[af.type] }}</span>
      </div>
    </div>
    <div class="di-suggestion-actions">
      <button *ngIf="onSelectedItem.observed" class="btn btn-light" (click)="onSelectItem(af)">
        {{ selectLabel }}
      </button>
    </div>
  </div>
</div>
