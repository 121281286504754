import { Component } from '@angular/core';

@Component({
  selector: 'dirt-contribution',
  template: `
    <dirt-admin-header></dirt-admin-header>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  `,
})
export class ContributionComponent {}
