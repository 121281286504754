import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';
import { EventComponent } from '../event/event.component';
import { ContributorListComponent } from './list/list.component';
import { ContributorModule } from './contributor.module';
import { RegionRestrictGuard } from './shared/guards/region-restrict-guard.service';

const contributorRoutes: Routes = [
  {
    path: 'contributor',
    component: EventComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: 'list',
        component: ContributorListComponent,
        canActivate: [RegionRestrictGuard],
        data: { acl: 'contributor.list' },
      },
    ],
    data: { title: 'Contribution' },
  },
];

export const contributorRouting: ModuleWithProviders<ContributorModule> = RouterModule.forChild(contributorRoutes);
