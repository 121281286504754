import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';

import { UserPoolComponent } from './user-pool.component';
import { UserPoolListComponent } from './list/list.component';
import { UserPoolDetailComponent } from './detail/detail.component';
import { UserPoolCreateComponent } from './create/create.component';
import { UserPoolModule } from './user-pool.module';

const userPoolRoutes: Routes = [
  {
    path: 'user-pool',
    component: UserPoolComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'create',
        component: UserPoolCreateComponent,
        data: { acl: 'userPool.create' },
      },
      {
        path: 'detail/:id',
        component: UserPoolDetailComponent,
        data: { acl: 'userPool.detail' },
      },
      {
        path: 'list',
        component: UserPoolListComponent,
        data: { acl: 'userPool.list' },
      },
    ],
  },
];

export const userPoolRouting: ModuleWithProviders<UserPoolModule> = RouterModule.forChild(userPoolRoutes);
