import { Component, Input, ViewChild } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { NgForm } from '@angular/forms';

import { Sponsor, UpdateNormalizedNamesData } from '../sponsor';
import { SponsorAPI } from '../api.service';
import { Observable, lastValueFrom } from 'rxjs';

@Component({
  selector: 'dirt-sponsor-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
  exportAs: 'frmSponsor',
  providers: [TitleCasePipe],
})
export class SponsorFormComponent {
  @Input('sponsor')
  model: Sponsor = new Sponsor();

  @Input('mode')
  mode = 'Mapping' || 'Edit Normalized Names';

  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  showSuggestions = false;

  suggestions: Array<Sponsor>;

  existingSponsors = [];

  sponsorsFiltered = [];

  showNewNormalizedNameInput = false;

  editedNormalizedName: string;

  constructor(private svcSponsor: SponsorAPI) {}

  ngOnInit(): void {
    this.loadSponsors();
  }

  getValue(): Sponsor | UpdateNormalizedNamesData {
    if (this.mode === 'Mapping') {
      return this.model;
    } else {
      return {
        oldName: this.model.normalizedName,
        newName: this.editedNormalizedName,
      };
    }
  }

  isValid(): boolean {
    return this.ngForm.form.valid;
  }

  originalNameExists(): Observable<boolean> {
    return this.svcSponsor.exist(this.model.originalName);
  }

  generateNewId() {
    this.svcSponsor.getNextCompanyId().subscribe((resp) => {
      if (resp) {
        this.model.companyId = resp;
        // normalized name must be mapped only to one company id
        if (!this.showNewNormalizedNameInput) {
          this.model.normalizedName = null;
        }
      }
    });
  }

  searchSimilarNames() {
    this.svcSponsor.find(this.model.originalName, 10).subscribe((sponsors) => {
      this.showSuggestions = true;
      this.suggestions = sponsors;
    });
  }

  async loadSponsors(): Promise<void> {
    await lastValueFrom(this.svcSponsor.getSponsors()).then((data) => {
      this.existingSponsors = data;
    });
  }

  public updateCompanyId(filter: Sponsor) {
    if (!filter) {
      this.model.normalizedName = null;
      this.model.companyId = null;
      return;
    }
    if (!this.showNewNormalizedNameInput) {
      this.model.normalizedName = filter.normalizedName;
      this.model.companyId = filter.companyId;
    }
  }

  handleNewCompanySwitch(enabled: boolean) {
    this.model.companyId = null;
    this.model.normalizedName = null;
    this.showNewNormalizedNameInput = enabled;
    if (enabled) {
      this.generateNewId();
    }
  }

  handleNormalizedNameChanged(sponsor: Sponsor) {
    this.editedNormalizedName = sponsor.normalizedName;
  }
}
