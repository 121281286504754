import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

import { Guideline } from '../shared/guideline';
import { GuidelineFormComponent } from '../shared/form/form.component';
import { GuidelineAPI } from '../shared/guideline-api.service';
import { GuidelineStatus } from '../shared/constant/status.enum';
import { ACL } from '../../shared/acl/acl.service';
import { Comment } from '../../comment/shared/comment';
import { CommentAPI } from '../../comment/shared/api.service';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';

@Component({
  selector: 'dirt-guideline-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss'],
})
export class GuidelineCreateComponent implements OnInit {
  @ViewChild('frmGuideline', { static: false })
  guidelineFormComponent: GuidelineFormComponent;

  guideline: Guideline = new Guideline();

  isSubmitting = false;

  private inScopeCountries: string[] = [];

  constructor(
    private router: Router,
    private svcGuideline: GuidelineAPI,
    private svcACL: ACL,
    private svcComment: CommentAPI,
    private svcValue: ValueAPI
  ) {}

  ngOnInit(): void {
    this.svcValue.find(ValueType.InScopeCountry, Number.MAX_SAFE_INTEGER).subscribe((values) => {
      this.inScopeCountries = values.map((v) => v.code as string);
    });
  }

  goBack(): void {
    this.router.navigate(['/guideline']);
  }

  async onSubmit(guideline: Guideline): Promise<void> {
    let problems: string[] = [];
    if (guideline.journal?.country && !this.inScopeCountries.includes(guideline.journal.country)) {
      problems.push('Selected country is out of scope.');
    }

    let message: string;
    if (problems.length > 0) {
      message = `The following issues were found: \n - ${problems.join('\n - ')}\n\n`;
      message += 'Do you really want to submit this entry?';

      // confirm the submit action
      if (!window.confirm(message)) {
        return;
      }
    }

    await this.saveGuideline(guideline);
    this.goBack();
  }

  async onSaveAndNew(guideline: Guideline): Promise<void> {
    let problems: string[] = [];
    if (guideline.journal?.country && !this.inScopeCountries.includes(guideline.journal.country)) {
      problems.push('Selected country is out of scope.');
    }

    let message: string;
    if (problems.length > 0) {
      message = `The following issues were found: \n - ${problems.join('\n - ')}\n\n`;
      message += 'Do you really want to submit this entry?';

      // confirm the submit action
      if (!window.confirm(message)) {
        return;
      }
    }

    const savedGuideline = await this.saveGuideline(guideline);

    this.guideline = new Guideline();
    this.guideline.reach = savedGuideline.reach;
    this.guideline.category = guideline.category;
    this.guideline.projectNames = savedGuideline.projectNames;
  }

  private async saveGuideline(guideline: Guideline): Promise<any> {
    if (!this.svcACL.hasCredential('guideline.create')) {
      return;
    }
    let comment = (guideline as Guideline & { comment: string }).comment;
    delete (guideline as Guideline & { comment: string }).comment;

    guideline._meta = { ...(guideline._meta || {}), status: GuidelineStatus.READY_FOR_COMPILATION };

    this.isSubmitting = true;

    const savedGuideline = await this.svcGuideline
      .create(guideline)
      .pipe(tap(() => (this.isSubmitting = false)))
      .toPromise();
    try {
      const commentObj = <Comment>{
        content: comment,
        owner: savedGuideline.id,
      };
      await this.svcComment.create(commentObj).toPromise();
    } catch (e) {
      console.error('Comment creation failed');
    }
    return savedGuideline;
  }
}
