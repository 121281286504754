import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Input,
  ViewChild,
  OnInit,
  OnDestroy,
  Component,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Subject, forkJoin } from 'rxjs';
import { takeUntil, distinctUntilChanged } from 'rxjs/operators';

import { Account } from '../account';
import { ACL } from '../../../shared/acl/acl.service';
import { AddressComponent } from '../../../shared/components';
import { IMultiSelectOption, IMultiSelectSettings } from '../../../shared/components/multiselect-dropdown/types';
import { Utils } from '../../../common/utils';
import { Value } from '../../../shared/services/value/value';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import {
  ImageFormModel,
  ImageFormComponent,
  ImageFormValidationConfig,
} from '../../../shared/components/image-form/image-form.component';

const CLS_MULTISELECT_BTN = 'btn btn-sm btn-secondary';

@Component({
  selector: 'dirt-account-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  exportAs: 'frmAccount',
})
export class AccountFormComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input('account')
  model: Account = new Account();

  @Input()
  currentJobType?: string;

  @Output()
  validityChange: EventEmitter<'VALID' | 'INVALID'> = new EventEmitter();

  @ViewChild('baseDataForm')
  baseDataForm: NgForm;

  @ViewChild('financialForm')
  financialForm: NgForm;

  @ViewChild('medicalForm')
  medicalForm: NgForm;

  @ViewChild('metricsDataForm')
  metricsDataForm: NgForm;

  longDash = Utils.longDash;

  availableAccountSubtypes: { code: string; title: string }[] = [];

  availableAccountTeachingStatus: { code: string; title: string }[] = [];

  accountTypes: Value[] = [];
  accountSubtypes: Value[] = [];
  economicStatuses: Value[] = [];
  ownershipStatuses: Value[] = [];
  accountTeachingStatus: Value[] = [];

  medicalServices: { id: string; name: string; disabled: boolean }[] = [];

  medicalServicesSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    dynamicTitleMaxItems: 5,
  };

  academicPartners: { id: string; name: string; disabled: boolean }[] = [];

  academicPartnersSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    dynamicTitleMaxItems: 5,
  };

  isPublicCompany: boolean;

  showMetricsDataSection = false;

  isMetricsDataFormValid: boolean;

  isSearching: boolean;

  healthPlanNetworkTypes: IMultiSelectOption[] = [];
  healthPlanNetworkTypeSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    enableSearch: false,
    dynamicTitleMaxItems: 5,
  };

  private isFormImageValid = true;

  private canWorkImage: boolean;

  accountLogoValue: ImageFormModel = {};

  @ViewChild(AddressComponent, { static: false })
  private addressComponent: AddressComponent;

  @ViewChild(ImageFormComponent)
  imageFormComponent: ImageFormComponent;

  imageValidationConfig: ImageFormValidationConfig = {
    minSize: 200,
    maxAspectRatio: 2,
  };

  private destroy$: Subject<boolean> = new Subject();

  constructor(private svcAcl: ACL, private svcValue: ValueAPI) {}

  ngOnChanges(): void {
    this.initMultiFields();
    this.initObjectFields();
    this.onAddressChange();
    this.initImageFormValue();
    this.canWorkImage = this.isFieldEditable('logo') || this.isFieldEditable('social.twitter');
  }

  ngOnInit(): void {
    forkJoin({
      accountTypes: this.svcValue.find(ValueType.AccountTypes, Number.MAX_SAFE_INTEGER, 0, '+title'),
      accountSubtypes: this.svcValue.find(ValueType.AccountSubTypes, Number.MAX_SAFE_INTEGER, 0, '+title'),
      economicStatuses: this.svcValue.find(ValueType.AccountEconomicStatus, Number.MAX_SAFE_INTEGER, 0, '+title'),
      ownershipStatuses: this.svcValue.find(ValueType.AccountOwnershipStatus, Number.MAX_SAFE_INTEGER, 0, '+title'),
      healthPlanNetworkTypes: this.svcValue.find(
        ValueType.AccountHealthPlanNetworkTypes,
        Number.MAX_SAFE_INTEGER,
        0,
        '+title'
      ),
      accountTeachingStatus: this.svcValue.find(ValueType.AccountTeachingStatus, Number.MAX_SAFE_INTEGER, 0, '+title'),
    }).subscribe((data) => {
      this.accountTypes = data.accountTypes || [];
      this.accountSubtypes = data.accountSubtypes || [];
      this.economicStatuses = data.economicStatuses || [];
      this.ownershipStatuses = data.ownershipStatuses || [];
      this.healthPlanNetworkTypes = (data.healthPlanNetworkTypes || []).map((v) => ({ id: v.code, name: v.title }));
      this.accountTeachingStatus = data.accountTeachingStatus || [];

      this.onAddressChange(); // filter the available sub-types
    });
  }

  ngAfterViewInit(): void {
    this.baseDataForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
      this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
    });

    this.addressComponent.form.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
      this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
    });

    if (this.metricsDataForm) {
      this.metricsDataForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
        if (!this.model.id) {
          return;
        }
        setTimeout(() => (this.isMetricsDataFormValid = this.metricsDataForm.valid));
        this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  getValue(): Account {
    const model = Object.assign({}, this.model); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables

    if (this.addressComponent) {
      model.address = this.addressComponent.getAddress();
    }

    Utils.removeEmptyAndDuplicatedElementsFromArray(model, [
      'phone',
      'logo',
      'alternativeNames',
      'websources',
      'payerMix',
      'metricsDataLinks',
      'healthPlanProductCategories',
      'healthPlanNetworkType',
      'social',
      'departmentNames',
      'departmentNamesLinks',
      'summaryLinks',
      'developmentNotesLinks',
    ]);

    if (this.canWorkImage) {
      model.social = model.social || { twitter: {} };
      model.social.twitter = model.social.twitter || {};
      model.social.twitter.notFound = this.accountLogoValue.isTwitterHandleNotFound;
      model.social.twitter.username = model.social.twitter.notFound ? null : this.accountLogoValue.twitterHandle;
      model.social.twitter.approved = model.social.twitter.notFound
        ? false
        : this.accountLogoValue.isTwitterHandleApproved;
      model.social.twitter.photoApproved = model.social.twitter.notFound
        ? false
        : this.accountLogoValue.useTwitterHandle;

      model.logo = model.logo || {};
      model.logo.notFound = this.accountLogoValue.isImageNotFound;
      model.logo.url = model.logo.notFound ? null : this.accountLogoValue.url;
      model.logo.source = model.logo.notFound ? null : this.accountLogoValue.websource;

      // Reset local URL
      if (
        (model.logo.notFound || !model.logo.url) &&
        (model.social.twitter.notFound || !model.social.twitter.username || !model.social.twitter.photoApproved)
      ) {
        model.logo.local_url = null;
        model.logo.local_url_nocache = null;
      }
    }
    return model;
  }

  isFieldEditable(field: string): boolean {
    const module = this.model.parent ? 'division' : 'account';
    const prefix = this.model.id ? 'update' : 'create';
    return this.svcAcl.hasCredential(`${module}.${prefix}.prop.${field}`, this.currentJobType);
  }

  onLogoURLChange(value: string) {
    this.model.logo = this.model.logo || {};
    this.model.logo.url = value;
  }

  onToggleMetricsDataSection(): void {
    this.showMetricsDataSection = !this.showMetricsDataSection;
  }

  onAccountTypeChange(): void {
    this.onAddressChange();
    setTimeout(() => this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID')); // next rerender - if we hide invalid sections, they won't count anymore
  }

  onAddressChange(): void {
    if (this.accountSubtypes.length === 0) {
      // values not loaded yet
      return;
    }

    let values = this.accountSubtypes.filter((subtype) => subtype.showForType === this.model.type);
    let forCountries = values.reduce((acc, v) => acc.concat(v.showForCountryCodes), []); // not in that = no restriction

    if (forCountries.includes(this.model.address?.countryCode)) {
      // show what's available to these countries
      values = values.filter((subtype) => subtype.showForCountryCodes?.includes(this.model.address.countryCode));
    }

    this.availableAccountSubtypes = values.map((v) => ({ code: v.code as string, title: v.title }));

    if (!this.availableAccountSubtypes.find((t) => t.code === this.model.subtype)) {
      // reset subtype
      this.model.subtype = null;
    }
    if (this.accountTeachingStatus.length === 0) {
      return;
    }
    let teachingStatusValues = this.accountTeachingStatus;
    let forCountriesTS = teachingStatusValues.reduce((acc, v) => acc.concat(v.showForCountryCodes), []); // not in that = no restriction

    if (forCountriesTS.includes(this.model.address?.countryCode) || forCountriesTS.includes(undefined)) {
      // show what's available to these countries
      teachingStatusValues = teachingStatusValues.filter(
        (ts) => !ts.showForCountryCodes || ts.showForCountryCodes?.includes(this.model.address.countryCode)
      );
    }

    this.availableAccountTeachingStatus = teachingStatusValues.map((v) => ({ code: v.code as string, title: v.title }));
    if (!this.availableAccountTeachingStatus.find((t) => t.code === this.model.teachingStatus)) {
      // reset teaching status
      this.model.teachingStatus = null;
    }
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }

  pushObjectToList(list: any[]): void {
    list.push({ name: '' });
  }

  trackByIndex(index: number): number {
    return index;
  }

  private isValid(): boolean {
    let isValid = this.baseDataForm.form.valid;

    if (this.model.id) {
      // metrics data section isn't displayed when creating
      isValid = isValid && (!this.metricsDataForm || this.metricsDataForm.form.valid);
    }

    if (this.addressComponent) {
      isValid = isValid && this.addressComponent.isValid();
    }

    if (this.canWorkImage) {
      isValid = isValid && this.isFormImageValid;
    }

    return isValid;
  }

  private initMultiFields(): void {
    const fields = [
      'payerMix',
      'metricsDataLinks',
      'healthPlanProductCategories',
      'alternativeNames',
      'websources',
      'summaryLinks',
      'developmentNotesLinks',
      'departmentNames',
      'departmentNamesLinks',
    ];

    fields.forEach((field) => {
      if (!Array.isArray(this.model[field]) || this.model[field].length === 0 || this.model[field].every((v) => !v)) {
        this.model[field] = [''];
      }
    });

    if (
      !Array.isArray(this.model.healthPlanNetworkType) ||
      this.model.healthPlanNetworkType.length === 0 ||
      this.model.healthPlanNetworkType.every((v) => !v)
    ) {
      this.model.healthPlanNetworkType = [];
    }
  }

  private initObjectFields(): void {
    const fields = ['logo', 'phone'];

    fields.forEach((field) => {
      if (!this.model[field]) {
        this.model[field] = {};
      }
    });
  }

  onFormImageValidityChange(isValid: boolean): void {
    setTimeout(() => {
      this.isFormImageValid = isValid;
      this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
    });
  }

  private initImageFormValue(): void {
    this.accountLogoValue = {
      isImageNotFound: this.model.logo?.notFound,
      url: this.model.logo?.url,
      websource: this.model.logo?.source,
      isTwitterHandleNotFound: this.model.social?.twitter?.notFound,
      twitterHandle: this.model.social?.twitter?.username,
      isTwitterHandleApproved: this.model.social?.twitter?.approved,
      useTwitterHandle: this.model.social?.twitter?.photoApproved,
    };
  }

  onImageUrlChange(value: string) {
    this.model.logo = this.model.logo || {};
    this.model.logo.url = value;
  }

  onImageSourceChange(value: string) {
    this.model.logo = this.model.logo || {};
    this.model.logo.source = value;
  }

  onReorderLinks(event: CdkDragDrop<string[]>, list: string[]): void {
    moveItemInArray(list, event.previousIndex, event.currentIndex);
  }

  onTwitterChange(username: string): void {
    // I saw this function in src/app/person/shared/form/form.component.ts but not sure if it's used at all?
    this.model.social = this.model.social || { twitter: {} };
    if (username.trim()) {
      this.model.social.twitter = {
        username,
      };
    } else {
      this.model.social.twitter = {};
    }
  }

  onPriorityChange(priority: number) {
    if (priority != null) {
      this.model.priority = priority;
    }
  }

  isListOfDepartmentsProofLinkRequired(): boolean {
    const nonEmptyDepartmentNames = this.model.departmentNames.filter((s) => !!s.trim());
    const nonEmptyDepartmentNamesLinks = this.model.departmentNamesLinks.filter((s) => !!s.trim());
    return nonEmptyDepartmentNames.length > 0 && nonEmptyDepartmentNamesLinks.length === 0;
  }
}
