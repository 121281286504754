import { Component, OnInit, OnChanges, Input, SimpleChange, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { firstValueFrom, Observable } from 'rxjs';

import { Membership } from '../shared/membership';
import { MembershipAPI } from '../shared/api.service';
import { Association } from '../../association/shared/association';
import { AssociationObsolete } from '../../association/shared/constant/obsolete.enum';
import { SearchType } from '../../contribution/shared/constant/contribution-search.enum';
import { AssociationAPI } from '../../association/shared/association-api.service';
import { ACL } from '../../shared/acl/acl.service';
import { Roles } from '../../shared/acl/roles';

@Component({
  selector: 'dirt-membership-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class MembershipListComponent implements OnInit, OnChanges {
  @Input()
  association: Association;

  @Input()
  obsolete: boolean;

  @Input()
  isSearchType: boolean;

  isLoading: boolean;

  searchTypes = SearchType;
  selectedSearchType = SearchType.Direct;

  // pagination settings
  total: any;
  pagingPage = 1;
  pagingLimit = 30;
  pagingSkip = 0;

  sort = '+ended';
  memberships: Array<Membership>;

  searchTerm: string;
  searchCtrl: FormControl = new FormControl('');

  membershipFormOpened = false;
  membershipEditId: string;
  flashingId: string;
  flashingEditId: string;

  membership: Membership = new Membership();
  isSubmitting = false;
  isSaving = false;

  expandedIds = [];
  allReviewed = false;
  allVerified = false;
  allEnded = false;

  @Output() onVerifiedUpdate = new EventEmitter<any>();

  shouldDisplayVerifiedControls: boolean;

  showDuplicates = false;

  constructor(
    private router: Router,
    private svcMembership: MembershipAPI,
    private svcAssociation: AssociationAPI,
    private svcAcl: ACL
  ) {}

  ngOnInit() {
    this.attachSearchHandler();

    this.shouldDisplayVerifiedControls =
      !this.svcAcl.hasRole(Roles.EventCompiler) && !this.svcAcl.hasRole(Roles.AssociationCompiler);
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    const associationId = changes['association'];
    if (associationId) {
      this.association = associationId.currentValue;
      this.doLoad();
    }
  }

  private doLoad() {
    this.resetPagination();
    this.getCount();
    this.getMemberships();
  }

  getMemberships(): void {
    this.isLoading = true;

    let observable = this.svcMembership.find(
      this.association.id,
      this.pagingLimit,
      this.pagingSkip,
      this.sort,
      this.searchTerm,
      this.selectedSearchType
    );
    if (this.showDuplicates) {
      observable = this.svcMembership.getDuplicates(this.association.id, this.pagingLimit, this.pagingSkip);
    }

    observable.pipe(tap(() => (this.isLoading = false))).subscribe((memberships) => {
      this.memberships = memberships;

      if (!this.showDuplicates) {
        this.setAllReviewedFlag();
        this.setAllVerifiedFlag();
        this.setAllEndedFlag();
      }
    });
  }

  getCount() {
    let observable = this.svcMembership.count(this.association.id, this.searchTerm, this.selectedSearchType);
    if (this.showDuplicates) {
      observable = this.svcMembership.getDuplicatesCount(this.association.id);
    }

    observable.subscribe((res) => (this.total = res));
  }

  getPage(page: number) {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getMemberships();
  }

  navigateTo(route, e) {
    if (e && e.target.nodeName === 'A') {
      return;
    }

    this.router.navigate(route);
  }

  resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  onSort(field: string): void {
    if (this.showDuplicates) {
      return;
    }

    this.sort = field;
    this.resetPagination();
    this.getMemberships();
  }

  onDelete(membershipId: string, $event) {
    $event.stopPropagation();

    if (window.confirm('Do you want to remove this entry?')) {
      this.svcMembership.deleteById(membershipId).subscribe(() => {
        this.getMemberships();
        this.getCount();
      });
    }
  }

  isObsolete(association: Association): boolean {
    return AssociationObsolete.YES === association.obsolete;
  }

  onSearchTypeChange(val: SearchType) {
    if (this.showDuplicates) {
      return;
    }

    this.selectedSearchType = val;
    this.doLoad();
  }

  attachSearchHandler(): void {
    this.searchCtrl.valueChanges.pipe(distinctUntilChanged(), debounceTime(400)).subscribe((val) => {
      if (typeof val !== 'undefined') {
        this.doLoad();
      }
    });
  }

  getParent(): void {
    this.svcAssociation.findById(this.association.id).subscribe((resp) => (this.membership.association = resp));
  }

  onCreateClick() {
    this.membership = new Membership();
    this.getParent();
    this.membershipFormOpened = true;
  }

  onEditClicked(membershipEditId, $event) {
    $event.stopPropagation();
    this.membershipEditId = membershipEditId;
  }

  onSubmit(membership: Membership): void {
    console.log('onSubmit');
    const newMembership = { ...membership };
    newMembership.association = this.association.id;

    this.isSubmitting = true;
    this.svcMembership.create(newMembership).subscribe(
      (resp) => {
        this.isSubmitting = false;
        this.membershipFormOpened = false;
        this.memberships = [{ ...resp, association: this.association }, ...(this.memberships || [])];
        this.flashingId = resp.id;
        setTimeout(() => {
          this.flashingId = null;
        }, 3000);
        this.getCount();
      },
      () => (this.isSubmitting = false)
    );
  }

  onSave(membership: Membership) {
    console.log('onSave');
    const newMembership = { ...membership };
    newMembership.association = this.association.id;

    this.isSaving = true;
    this.svcMembership.create(newMembership).subscribe(
      (resp) => {
        this.isSaving = false;
        this.memberships = [{ ...resp, association: this.association }, ...(this.memberships || [])];
        this.flashingId = resp.id;
        this.membership = new Membership();
        this.membership.association = this.association;
        // this.membership.webSources = resp.webSources;
        this.membership.position = resp.position;
        this.getCount();
        setTimeout(() => {
          this.flashingId = null;
        }, 3000);
      },
      () => (this.isSaving = false)
    );
  }

  onEdit(membership: Membership, emitVerifiedChange?: boolean) {
    console.log('onEdit');
    this.isSubmitting = true;
    this.svcMembership.upsert(membership).subscribe(
      (resp) => {
        this.isSubmitting = false;
        this.flashingEditId = resp.id;
        const index = this.memberships.findIndex((c) => c.id === membership.id);
        this.memberships = [
          ...this.memberships.slice(0, index),
          { ...resp, association: this.memberships[index].association },
          ...this.memberships.slice(index + 1),
        ];
        this.membershipEditId = null;
        this.setAllReviewedFlag();
        this.setAllEndedFlag();
        if (emitVerifiedChange) {
          this.setAllVerifiedFlag();
          this.onVerifiedUpdate.emit();
        }
        setTimeout(() => {
          this.flashingEditId = null;
        }, 3000);
      },
      () => (this.isSubmitting = false)
    );
  }

  onCancelEditClick() {
    this.membershipEditId = null;
    this.doLoad();
  }

  onCancelClick() {
    this.membershipFormOpened = false;
    this.membership = new Membership();
  }

  onShowAllText(id: string) {
    if (this.expandedIds.includes(id)) {
      this.expandedIds = this.expandedIds.filter((eId) => eId !== id);
    } else {
      this.expandedIds.push(id);
    }
  }

  onMembershipEndChange(membership: Membership, $event: any) {
    $event.stopPropagation();
    membership.ended = $event.target.checked;
    this.onEdit(membership);
  }

  onMembershipReviewedChange(membership: Membership, $event: any) {
    $event.stopPropagation();
    membership.reviewed = $event.target.checked;
    this.onEdit(membership);
  }

  onMembershipVerifiedChange(membership: Membership, $event: any) {
    $event.stopPropagation();
    membership.verified = $event.target.checked;
    this.onEdit(membership, true);
  }

  canEditMembership() {
    return this.svcAcl.hasCredential('membership.update');
  }

  toggleAllReviewed($event: any) {
    if (this.showDuplicates) {
      return;
    }

    $event.stopPropagation();
    this.isLoading = true;
    this.svcMembership
      .bulkUpdate({
        ids: this.memberships.map((mb) => mb.id),
        values: {
          reviewed: $event.target.checked,
        },
      })
      .toPromise()
      .then((resp) => {
        this.getMemberships();
      });
  }

  toggleAllVerified($event: any) {
    if (this.showDuplicates) {
      return;
    }

    $event.stopPropagation();
    this.isLoading = true;
    this.svcMembership
      .bulkUpdate({
        ids: this.memberships.map((mb) => mb.id),
        values: {
          verified: $event.target.checked,
        },
      })
      .toPromise()
      .then((resp) => {
        this.getMemberships();
        this.onVerifiedUpdate.emit();
      });
  }

  private setAllReviewedFlag() {
    this.allReviewed = this.memberships.every((mb) => mb.reviewed === true);
  }
  private setAllVerifiedFlag() {
    this.allVerified = this.memberships.every((mb) => mb.verified === true);
  }
  private setAllEndedFlag() {
    this.allEnded = this.memberships.every((mb) => mb.ended === true);
  }

  toggleAllEnded($event: any) {
    if (this.showDuplicates) {
      return;
    }

    $event.stopPropagation();
    this.isLoading = true;

    this.svcMembership
      .bulkUpdate({
        ids: this.memberships.map((mb) => mb.id),
        values: {
          ended: $event.target.checked,
        },
      })
      .subscribe({
        complete: () => this.getMemberships(),
      });
  }

  hasQc(membership: Membership): boolean {
    return Object.keys(membership.qc || {}).length > 0;
  }

  onToggleShowDuplicate(): void {
    this.showDuplicates = !this.showDuplicates;
    this.doLoad();
  }

  async hasDuplicates(): Promise<boolean> {
    const res = await firstValueFrom(this.svcMembership.getDuplicatesCount(this.association.id));
    return res.count > 0;
  }
}
