import { Component, Input, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';

import { Committee } from '../shared/committee';
import { CommitteePerson } from '../shared/person';
import { CommitteePersonAPI } from '../shared/committee-person-api.service';
import { CommitteePersonModalService } from '../person-modal/person-modal.service';

@Component({
  selector: 'dirt-committee-person-list',
  templateUrl: 'person-list.component.html',
  styleUrls: ['person-list.component.scss'],
})
export class CommitteePersonListComponent implements OnInit {
  @Input()
  committee: Committee;

  committeePeople: CommitteePerson[] = [];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 30;
  pagingSkip = 0;

  private sort = '+_id';

  constructor(
    private svcCommitteePerson: CommitteePersonAPI,
    private svcCommitteePersonModal: CommitteePersonModalService
  ) {}

  ngOnInit() {
    this.doLoad();
  }

  onSort(field: string): void {
    this.sort = field;

    this.getAttachedPeople();
  }

  onAddPersonRequested(): void {
    this.svcCommitteePersonModal
      .open(this.committee, 'ADD')
      .then(() => this.doLoad())
      .catch(() => {});
  }

  onEditPersonRequested(committeePerson: CommitteePerson): void {
    this.svcCommitteePersonModal
      .open(this.committee, 'EDIT', committeePerson)
      .then(() => this.doLoad())
      .catch(() => {});
  }

  onDeletePersonRequested(committeePerson: CommitteePerson): void {
    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.svcCommitteePerson.deleteById(committeePerson.id).subscribe(() => this.doLoad());
  }

  onViewPersonRequested(committeePerson: CommitteePerson): void {
    this.svcCommitteePersonModal.open(this.committee, 'VIEW', committeePerson).catch(() => {});
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getAttachedPeople();
  }

  private doLoad(): void {
    this.resetPagination();
    this.getAttachedPeople();
    this.getAttachedPeopleCount();
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getAttachedPeople(): void {
    this.isLoading = true;

    this.svcCommitteePerson
      .find(this.committee.id, this.pagingLimit, this.pagingSkip, this.sort)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((connections) => {
        this.committeePeople = connections;
      });
  }

  private getAttachedPeopleCount(): void {
    this.svcCommitteePerson.count(this.committee.id).subscribe((count) => {
      this.total = count;
    });
  }
}
