<div
  class="di-training-marker-wrapper"
  #trainingPopover="ngbPopover"
  [ngbPopover]="popoverContent"
  [popoverTitle]="popoverTitle"
  [autoClose]="false"
  popoverClass="di-training-marker-popover"
  popoverTitle="Info"
  triggers="manual"
  [placement]="fieldName === 'firstName' || fieldName === 'originalFirstName' ? 'auto' : 'bottom-right'"
  *ngIf="report"
>
  <!-- Original Content Injection -->
  <ng-container *ngTemplateOutlet="template"></ng-container>
  <!-- Annotate Trigger -->
  <div
    [ngClass]="{ 'di-annotate': !markedAsCorrect(), 'di-annotate-match': markedAsCorrect() }"
    *ngIf="!isCorrect()"
    (click)="openPopOver()"
  >
    <span>
      <i class="fa fa-bug fa-inverse" aria-hidden="true"></i>
    </span>
  </div>

  <div class="di-annotate-match" *ngIf="isCorrect()">
    <span>
      <i class="fa fa-check-circle fa-inverse" aria-hidden="true"></i>
    </span>
  </div>
</div>

<!-- Header -->
<ng-template #popoverTitle>
  <div *ngIf="fieldName === 'affiliations'" class="float-right">
    <div ngbDropdown class="d-inline-block">
      <button class="btn btn-sm" id="dropdownForm1" ngbDropdownToggle>Set as correct</button>
      <div ngbDropdownMenu aria-labelledby="dropdownForm1">
        <form class="px-2">
          <div class="form-check">
            <input
              type="checkbox"
              [disabled]="report?.isCorrect"
              [checked]="report?.markAsCorrect"
              (change)="toggleMarkAsCorrect($event)"
              class="form-check-input"
              id="dropdownCheck"
            />
            <label class="form-check-label" for="dropdownCheck"> Set Affiliations Correct </label>
          </div>
          <div class="dropdown-divider"></div>
          <div class="form-check">
            <input
              type="checkbox"
              [disabled]="report?.positions?.isCorrect"
              [checked]="report?.positions?.markAsCorrect"
              (change)="toggleMarkAsCorrect($event, fieldName + '.positions')"
              class="form-check-input"
              id="dropdownCheck"
            />
            <label class="form-check-label" for="dropdownCheck"> Set Job Titles Correct </label>
          </div>
          <div class="dropdown-divider"></div>
        </form>
      </div>
    </div>

    <!-- Close -->
    <a class="btn btn-sm" (click)="popover.close()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <div *ngIf="fieldName !== 'affiliations'" class="float-right">
    <button
      *ngIf="!report.isCorrect && !report.markAsCorrect"
      type="submit"
      class="btn btn-sm"
      (click)="markAsCorrect()"
    >
      Set as correct
    </button>
    <button *ngIf="!report.isCorrect && report.markAsCorrect" type="submit" class="btn btn-sm" (click)="markAsError()">
      Set as error
    </button>
    <!-- Close -->
    <a class="btn btn-sm" (click)="popover.close()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </a>
  </div>

  <span>{{ fieldName }}</span>
</ng-template>

<!-- Content -->
<ng-template #popoverContent>
  <div class="row">
    <div class="col-md-6">
      <div class="di-value-header"><b>Base value</b></div>
      <div class="di-value-content">
        <div *ngIf="fieldName !== 'affiliations'">
          <div *ngIf="isArray(baseValue)">
            <span *ngFor="let val of baseValue">
              {{ getDisplayValue(val) }}
              <hr />
            </span>
          </div>
          <span *ngIf="!isArray(baseValue)">{{ getDisplayValue(baseValue) }}</span>
        </div>
        <div *ngIf="fieldName === 'affiliations'">
          <div *ngFor="let affiliation of baseValue; let idx = index; trackBy: trackByIndex">
            <dl>
              <dt>Name</dt>
              <dd>{{ affiliation.name }}</dd>
              <dt>Department</dt>
              <dd>{{ affiliation.department }}</dd>
              <dt>Position</dt>
              <dd>{{ affiliation.position }}</dd>
              <dt>Original Position</dt>
              <dd>{{ affiliation.originalPosition }}</dd>
              <dt>Primary</dt>
              <dd>{{ affiliation.primary === true ? 'True' : 'False' }}</dd>
              <dt>Active</dt>
              <dd>{{ affiliation.inactive === true ? 'False' : 'True' }}</dd>
              <hr />
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="di-value-header"><b>Current Value</b></div>
      <div class="di-value-content">
        <div *ngIf="fieldName !== 'affiliations'">
          <div *ngIf="isArray(value)">
            <span *ngFor="let val of value">
              {{ getDisplayValue(val) }}
              <hr />
            </span>
          </div>
          <span *ngIf="!isArray(value)">{{ getDisplayValue(value) }}</span>
        </div>
        <div *ngIf="fieldName === 'affiliations'">
          <div *ngFor="let affiliation of value; let idx = index; trackBy: trackByIndex">
            <dl>
              <dt>Name</dt>
              <dd>{{ affiliation.name }}</dd>
              <dt>Department</dt>
              <dd>{{ affiliation.department }}</dd>
              <dt>Position</dt>
              <dd>{{ affiliation.position }}</dd>
              <dt>Original Position</dt>
              <dd>{{ affiliation.originalPosition }}</dd>
              <dt>Primary</dt>
              <dd>{{ affiliation.primary === true ? 'True' : 'False' }}</dd>
              <dt>Active</dt>
              <dd>{{ affiliation.inactive === true ? 'False' : 'True' }}</dd>
              <hr />
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
