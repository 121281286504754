import { EnumValues } from './../../shared/enum/generic-type.enum';
import { PrimaryResearchResults, PrimaryResearchMethods } from './constant/primaryResearch.enum';

export type PhoneDetails = {
  phone: {
    number?: string;
    countryCallingCode?: string;
  };
  phoneCallDate: Date;
  phoneCallReport: string;
};

export type EmailDetails = {
  emailAddress: string;
  emailDate: Date;
  emailPrintout: string;
};

export type DocumentDetails = {
  documentTitle: string;
  documentPublishDate: Date;
  documentURL: string;
};

type ResultType = EnumValues<typeof PrimaryResearchResults>; // 'Confirmed' | 'InCorrect'
type MethodType = EnumValues<typeof PrimaryResearchMethods>; // 'Phone' | 'Email' | 'Document'

export type PrimaryResearch = {
  result?: ResultType;
  method?: MethodType;
  details?: PhoneDetails | EmailDetails | DocumentDetails;
};

export function initPrimaryResearchDetails(methodEnumValue: string) {
  if (!!methodEnumValue) {
    switch (methodEnumValue) {
      case PrimaryResearchMethods.DOCUMENT:
        return {
          documentTitle: '',
          documentPublishDate: null,
          documentURL: '',
        } as DocumentDetails;
      case PrimaryResearchMethods.EMAIL:
        return {
          emailAddress: '',
          emailDate: null,
          emailPrintout: '',
        } as EmailDetails;
      case PrimaryResearchMethods.PHONE:
        return {
          phone: { number: '', countryCallingCode: '' },
          phoneCallDate: null,
          phoneCallReport: '',
        } as PhoneDetails;
      default:
        throw new Error(`Non-existent method as: ${methodEnumValue}`);
    }
  }
}
