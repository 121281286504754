<a
  *ngIf="
    !params.data.IS_SUM &&
    (params.data.ERROR_THROUGHPUT_QC_DP ||
      params.data.ERROR_RATE_BD_MAJOR ||
      params.data.ERROR_RATE_BD_MINOR ||
      params.data.ERROR_RATE_ID_MAJOR ||
      params.data.ERROR_RATE_ID_MINOR ||
      params.data.ERROR_RATE_MAJOR ||
      params.data.ERROR_RATE_MINOR)
  "
  rel="noopener"
  target="_blank"
  href="/dashboard/feedback-report"
  [ngClass]="{ 'dirt-is-visited': isVisited }"
  (click)="onClick(); $event.preventDefault()"
  >details</a
>
