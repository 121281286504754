<div class="clearfix">
  <form #form="ngForm" [diMarkerWrapper]="model" novalidate>
    <!-- Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Name</label>
      <div class="col-sm-8 col-md-10">
        <input type="text" class="form-control" [value]="model.name" readonly />
      </div>
    </div>

    <!-- Type -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Type</label>
      <div class="col-md-3">
        <select
          class="form-control custom-select"
          name="type"
          [(ngModel)]="model.type"
          (ngModelChange)="onTypeChanged()"
          [disabled]="!isFieldEditable('type')"
          required
          *diMarker="{
            name: 'type',
            entity: currentJobType === 'CLINICAL_TRIAL_SPONSOR_REVIEW' ? 'clinicalTrialSponsor' : 'noop',
            area: 'ALL'
          }"
        >
          <option *ngFor="let type of clinicalTrialSponsorTypes$ | async" [value]="type.code">
            {{ type.title }}
          </option>
        </select>
      </div>
    </div>

    <!-- Suggestions -->
    <div class="form-group row" *ngIf="displayedSuggestions.length > 0">
      <label class="col-form-label col-sm-4 col-md-2">Suggestions</label>
      <div class="col-sm-8 col-md-10">
        <div *ngFor="let suggestion of suggestions; trackBy: trackById">
          <label class="col-form-label">{{ suggestion.type }}</label>
          <ng-container *ngIf="suggestion.type === 'INDUSTRY' || suggestion.type === 'HCO'">
            <dirt-affiliation-single-select
              [affiliation]="suggestion.connection"
              [wide]="true"
              [inline]="true"
              [disabled]="true"
            >
              <button
                type="button"
                class="btn btn-secondary"
                title="Select suggestion"
                (click)="onSuggestionSelected(suggestion.type, suggestion.connection)"
              >
                <i class="fa fa-check-circle"></i>
              </button>
            </dirt-affiliation-single-select>
          </ng-container>

          <ng-container *ngIf="suggestion.type === 'ASSOCIATION'">
            <dirt-association-single-select
              [association]="suggestion.connection"
              [wide]="true"
              [inline]="true"
              [disabled]="true"
            >
              <button
                type="button"
                class="btn btn-secondary"
                title="Select suggestion"
                (click)="onSuggestionSelected(suggestion.type, suggestion.connection)"
              >
                <i class="fa fa-check-circle"></i>
              </button>
            </dirt-association-single-select>
          </ng-container>

          <ng-container *ngIf="suggestion.type === 'PERSON'">
            <dirt-person-single-select [person]="suggestion.connection" [wide]="true" [inline]="true" [disabled]="true">
              <button
                type="button"
                class="btn btn-secondary"
                title="Select suggestion"
                (click)="onSuggestionSelected(suggestion.type, suggestion.connection)"
              >
                <i class="fa fa-check-circle"></i>
              </button>
            </dirt-person-single-select>
          </ng-container>
        </div>
      </div>
    </div>

    <!-- Connection: Organization -->
    <div class="form-group row" *ngIf="model.type === 'INDUSTRY' || model.type === 'HCO'">
      <label class="col-form-label col-sm-4 col-md-2">Organization</label>
      <div class="col-sm-8 col-md-10">
        <div
          *diMarker="{
            name: 'connection',
            entity: currentJobType === 'CLINICAL_TRIAL_SPONSOR_REVIEW' ? 'clinicalTrialSponsor' : 'noop',
            area: 'ALL'
          }"
        >
          <dirt-affiliation-single-select
            [affiliation]="model.connection"
            [wide]="true"
            [inline]="true"
            [curatedOnly]="true"
            [creationRequestHandler]="isFieldEditable('connection') ? onRequestOrganizationCreation : null"
            (affiliationSelected)="onConnectionSelected($event)"
            [disabled]="!isFieldEditable('connection')"
            [required]="model.type !== 'INDUSTRY'"
          ></dirt-affiliation-single-select>
        </div>
      </div>
    </div>

    <!-- Connection: Association -->
    <div class="form-group row" *ngIf="model.type === 'ASSOCIATION'">
      <label class="col-form-label col-sm-4 col-md-2">Association</label>
      <div class="col-sm-8 col-md-10">
        <div
          *diMarker="{
            name: 'connection',
            entity: currentJobType === 'CLINICAL_TRIAL_SPONSOR_REVIEW' ? 'clinicalTrialSponsor' : 'noop',
            area: 'ALL'
          }"
        >
          <dirt-association-single-select
            [association]="model.connection"
            [wide]="true"
            [inline]="true"
            [rootOnly]="true"
            [creationRequestHandler]="isFieldEditable('connection') ? onRequestAssociationCreation : null"
            (associationSelected)="onConnectionSelected($event)"
            [disabled]="!isFieldEditable('connection')"
            [required]="true"
          ></dirt-association-single-select>
        </div>
      </div>
    </div>

    <!-- Connection: Person -->
    <div class="form-group row" *ngIf="model.type === 'PERSON'">
      <label class="col-form-label col-sm-4 col-md-2">Person</label>
      <div class="col-sm-8 col-md-10">
        <div
          *diMarker="{
            name: 'connection',
            entity: currentJobType === 'CLINICAL_TRIAL_SPONSOR_REVIEW' ? 'clinicalTrialSponsor' : 'noop',
            area: 'ALL'
          }"
        >
          <dirt-person-single-select
            [person]="model.connection"
            [wide]="true"
            [inline]="true"
            [curatedOnly]="true"
            [creationRequestHandler]="isFieldEditable('connection') ? onRequestPersonCreation : null"
            (personSelected)="onConnectionSelected($event)"
            [disabled]="!isFieldEditable('connection')"
            [required]="true"
          ></dirt-person-single-select>
        </div>
      </div>
    </div>

    <!-- Proof Link -->
    <div class="form-group row" *ngFor="let link of model.prooflinks; let idx = index; trackBy: trackByIndex">
      <label class="col-form-label col-sm-4 col-md-2">Proof Link</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.prooflinks[idx]"
          name="prooflinks-{{ idx }}"
          [form]="form"
          [disabled]="!isFieldEditable('prooflink')"
          [required]="idx == 0"
        >
          <button
            type="button"
            class="btn btn-secondary"
            (click)="pushItemToList(model.prooflinks)"
            *ngIf="idx == model.prooflinks.length - 1 && isFieldEditable('prooflinks')"
          >
            <i class="fa fa-plus-circle"></i>
          </button>

          <button
            type="button"
            class="btn btn-secondary"
            (click)="removeFromByIndex(model.prooflinks, idx)"
            *ngIf="idx > 0 && isFieldEditable('prooflinks')"
          >
            <i class="fa fa-minus-circle"></i>
          </button>
        </dirt-link>
      </div>
    </div>
  </form>
</div>
