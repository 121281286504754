<div class="modal-header">
  <h5 class="modal-title">{{ isEdit ? 'Edit Person' : 'Add Person' }}</h5>
</div>

<div class="modal-body">
  <ng-container *ngIf="!isEdit && !disablePersonForm">
    <input
      type="search"
      id="people-searchbar"
      class="di-search form-control ng-trim-ignore"
      [ngClass]="{ loading: isSearching }"
      [ngbTypeahead]="onSearchPeople"
      [focusFirst]="false"
      [resultTemplate]="searchResultTemplate"
      placeholder="Search..."
      [(ngModel)]="searchTerm"
      (selectItem)="onSelectPerson($event)"
    />

    <a href="javascript:void(0)" class="small pull-right pt-1 pr-2" (click)="onTogglePersonForm()"> Create Person </a>

    <br />
  </ng-container>

  <h6 *ngIf="!showPersonForm && selectedDocumentPerson">
    Person ID:
    <a [routerLink]="['/person/detail', selectedDocumentPerson.person._id]" rel="noopener noreferrer" target="_blank">{{
      selectedDocumentPerson.person.kolId
    }}</a>
  </h6>

  <ng-container *ngIf="selectedDocumentPerson">
    <dirt-person-form-inline
      [model]="selectedDocumentPerson.person"
      [disabledFields]="showPersonForm ? createDisabledFields : editDisabledFields"
      [displayCountry]="'MANDATORY'"
      [disabled]="disablePersonForm"
      (validityChange)="onFormValidityChange($event)"
    >
    </dirt-person-form-inline>
  </ng-container>

  <form #frmAdditional="ngForm">
    <ng-container *ngIf="selectedDocumentPerson">
      <!-- Position -->
      <div class="form-group">
        <label class="col-form-label">Position</label>
        <select
          class="form-control custom-select"
          name="position"
          [(ngModel)]="selectedDocumentPerson.position"
          [disabled]="disablePersonForm"
          required
        >
          <option *ngFor="let position of positions" [value]="position.code">
            {{ position.title }}
          </option>
        </select>
      </div>

      <!-- CV Links -->
      <div
        class="form-group"
        *ngFor="let _ of selectedDocumentPerson.sourceUrls; let idx = index; trackBy: trackByIndex"
      >
        <label class="col-form-label" for="sourceUrls-{{ idx }}">{{
          idx === 0 ? 'Master Source Link' : 'Source Link'
        }}</label>
        <dirt-link
          [(url)]="selectedDocumentPerson.sourceUrls[idx]"
          name="sourceUrls-{{ idx }}"
          [form]="frmAdditional"
          [disabled]="disablePersonForm"
          [required]="idx === 0"
        >
          <button
            type="button"
            class="btn btn-secondary"
            (click)="pushItemToList(selectedDocumentPerson.sourceUrls)"
            *ngIf="
              idx == selectedDocumentPerson.sourceUrls.length - 1 &&
              !disablePersonForm &&
              selectedDocumentPerson.sourceUrls.length < 3
            "
          >
            <i class="fa fa-plus-circle"></i>
          </button>

          <button
            type="button"
            class="btn btn-secondary"
            (click)="removeFromByIndex(selectedDocumentPerson.sourceUrls, idx)"
            *ngIf="idx > 0 && !disablePersonForm"
          >
            <i class="fa fa-minus-circle"></i>
          </button>
        </dirt-link>
      </div>
    </ng-container>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-outline-dark" [disabled]="isSearching" (click)="activeModal.dismiss()">Close</button>

  <ng-container *ngIf="!disablePersonForm">
    <ng-container *ngIf="!isEdit">
      <button
        class="btn btn-sm btn-success"
        [ngClass]="{ 'btn-loading': isSearching || isSaving }"
        [hidden]="!selectedDocumentPerson"
        [disabled]="isSearching || !isFormValid || isSaving"
        (click)="onAddPerson()"
        *ngIf="!showPersonForm"
      >
        Add
      </button>
      <button
        class="btn btn-sm btn-success"
        [ngClass]="{ 'btn-loading': isSearching || isSaving }"
        [disabled]="isSearching || !isFormValid || isSaving"
        (click)="onRequestPerson()"
        *ngIf="showPersonForm"
      >
        Request & Add
      </button>
    </ng-container>

    <ng-container *ngIf="isEdit">
      <button
        class="btn btn-sm btn-success"
        [ngClass]="{ 'btn-loading': isSearching || isSaving }"
        [disabled]="isSearching || !isFormValid || isSaving"
        (click)="onEditPerson()"
      >
        Save
      </button>
    </ng-container>
  </ng-container>
</div>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div class="di-sac-kol-id pull-right">{{ r.kolId }}</div>
  <div class="di-sac-name">
    <span>{{ r.firstName }} {{ r.middleName }} {{ r.lastName }}</span>
    <span *ngIf="r.originalFullName">({{ r.originalFullName }})</span>
    <span class="di-sac-status">{{ r._meta?.status || '-' | capitalize }}</span>
  </div>

  <div class="di-sac-affiliation-name" *ngIf="r.affiliationName">
    {{ r.affiliationName }}
  </div>
</ng-template>
