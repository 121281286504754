import { Component, OnInit, Input } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Step } from '../../step.enum';

@Component({
  selector: 'dirt-bulk-update-steps-container',
  templateUrl: './bulk-update-steps-container.component.html',
  styleUrls: ['./bulk-update-steps-container.component.scss'],
  animations: [
    trigger('slide', [
      state('first', style({ transform: 'translateX(0)' })),
      state('second', style({ transform: 'translateX(-33.33%)' })),
      state('third', style({ transform: 'translateX(-66.66%)' })),
      transition('* => *', animate(300)),
    ]),
  ],
})
export class BulkUpdateStepsContainerComponent implements OnInit {
  @Input()
  activeStep: Step;

  constructor() {}

  ngOnInit() {}
}
