<section class="dirt-section-detail dirt-domain-detail">
  <div class="row" *ngIf="!isLoading; else loader">
    <div class="col-md-8">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <h1 class="bounceInRight">Domain Detail</h1>

      <br />

      <dirt-domain-form
        #frmDomain="frmDomain"
        [domain]="domain"
        [currentJobType]="currentJob?.type"
        (validityChange)="onFormValidityChange($event)"
      ></dirt-domain-form>

      <!-- Job controls -->
      <div *ngIf="hasJobForCurrentUser; else updateControls">
        <div class="pull-left">
          <!-- Unable to Compile -->
          <button class="btn btn-outline-danger" (click)="onJobUtc()">Unable to Compile!</button>
        </div>

        <div class="pull-right">
          <!-- Save as Draft -->
          <button
            class="btn btn-secondary"
            [ngClass]="{ 'btn-loading': isSaving }"
            [disabled]="isSaving"
            (click)="onJobDraft(frmDomain.getValue())"
          >
            Save as Draft
          </button>

          <!-- Submit -->
          <button
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSaving }"
            [disabled]="(!isFormValid && !canSaveAnyway) || isSaving"
            (click)="onSubmitJob(frmDomain.getValue())"
          >
            {{ !isFormValid && canSaveAnyway ? 'Submit Job Anyway' : 'Submit Job' }}
          </button>
        </div>
      </div>

      <!-- Standard controls -->
      <ng-template #updateControls>
        <div class="pull-right" *ngIf="canSubmitNonJob">
          <button
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSaving }"
            [disabled]="(!isFormValid && !canSaveAnyway) || isSaving"
            (click)="onSave(frmDomain.getValue())"
          >
            {{ !isFormValid && canSaveAnyway ? 'Save Anyway' : 'Save' }}
          </button>
        </div>
      </ng-template>
    </div>

    <div class="col-md-4" *ngIf="domain">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Workflow -->
        <li ngbNavItem>
          <a ngbNavLink>Workflow</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <dt class="col-sm-4" [hidden]="!isClaimedByOtherUser()"></dt>
              <dd class="col-sm-8" [hidden]="!isClaimedByOtherUser()">
                <span title="s/o else started working recently" class="badge badge-info">Claimed</span>
              </dd>

              <dt class="col-sm-4" *ngIf="hasJobForCurrentUser">Job</dt>
              <dd class="col-sm-8" *ngIf="hasJobForCurrentUser">
                {{ currentJob?.type + (currentJob?.hint ? ' - ' + currentJob.hint : '') }}
              </dd>

              <dt class="col-sm-4" [ngClass]="{ 'di-separator': hasJobForCurrentUser }">Created</dt>
              <dd class="col-sm-8" [ngClass]="{ 'di-separator': hasJobForCurrentUser }">
                {{ domain.createdAt | date : 'medium' }}
              </dd>

              <dt class="col-sm-4 text-truncate">Modified</dt>
              <dd class="col-sm-8">{{ domain.updatedAt | date : 'medium' }}</dd>

              <dt class="col-sm-4 text-truncate">Status</dt>
              <dd class="col-sm-8">
                {{ domain.status }}
              </dd>

              <dt class="col-sm-4 text-truncate">Frequency</dt>
              <dd class="col-sm-8">
                {{ domain.frequency || 'N/A' }}
              </dd>

              <dt class="col-sm-4 text-truncate">Used For Image</dt>
              <dd class="col-sm-8">
                <i
                  class="fa"
                  [ngClass]="{ 'fa-check': domain.usedForImage, 'fa-times': !domain.usedForImage }"
                  aria-hidden="true"
                ></i>
              </dd>

              <dt class="col-sm-4">Version</dt>
              <dd class="col-sm-8">{{ domain._version || 0 }}</dd>

              <dt class="col-sm-4">Requires Change Approval</dt>
              <dd class="col-sm-8">
                <ng-container *ngIf="isDomainAdmin">
                  <ui-switch
                    size="small"
                    name="requiresChangeApproval"
                    [(ngModel)]="domain.requiresChangeApproval"
                    (change)="onRequiresChangeApprovalChange($event)"
                    [disabled]="isSaving"
                  ></ui-switch>
                </ng-container>

                <ng-container *ngIf="!isDomainAdmin">
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-check': domain.requiresChangeApproval,
                      'fa-times': !domain.requiresChangeApproval
                    }"
                    aria-hidden="true"
                  ></i>
                </ng-container>
              </dd>

              <dt class="col-sm-4">Opt-Out</dt>
              <dd class="col-sm-8">
                <ng-container *ngIf="isDomainAdmin">
                  <ui-switch
                    size="small"
                    name="optOut"
                    [(ngModel)]="domain.optOut"
                    (change)="onOptOutChange($event)"
                    [disabled]="isSaving"
                  ></ui-switch>
                </ng-container>

                <ng-container *ngIf="!isDomainAdmin">
                  <i
                    class="fa"
                    [ngClass]="{ 'fa-check': domain.optOut, 'fa-times': !domain.optOut }"
                    aria-hidden="true"
                  ></i>
                </ng-container>
              </dd>

              <ng-container *ngIf="domain.checked?.at">
                <dt class="col-sm-4 text-truncate di-separator">Checked at</dt>
                <dd class="col-sm-8 di-separator">{{ domain.checked.at | date : 'medium' }}</dd>
              </ng-container>

              <ng-container *ngIf="domain.checked?.by">
                <dt class="col-sm-4 text-truncate">Checked by</dt>
                <dd class="col-sm-8 font-italic">
                  <dirt-username [userId]="domain.checked.by"></dirt-username>
                </dd>
              </ng-container>

              <ng-container *ngIf="domain.reviewed?.at">
                <dt class="col-sm-4 text-truncate di-separator">Reviewed at</dt>
                <dd class="col-sm-8 di-separator">{{ domain.reviewed.at | date : 'medium' }}</dd>
              </ng-container>

              <ng-container *ngIf="domain.reviewed?.by">
                <dt class="col-sm-4 text-truncate">Reviewed by</dt>
                <dd class="col-sm-8 font-italic">
                  <dirt-username [userId]="domain.reviewed.by"></dirt-username>
                </dd>
              </ng-container>
            </dl>
          </ng-template>
        </li>

        <!-- Comments -->
        <ng-template [diAcl]="'domain.comment.list'">
          <li ngbNavItem>
            <a ngbNavLink>Comments</a>
            <ng-template ngbNavContent>
              <dirt-comment-list [ownerId]="id" [enableCreate]="canCreateComments"></dirt-comment-list>
            </ng-template>
          </li>
        </ng-template>

        <!-- Audit Log -->
        <ng-template [diAcl]="'domain.audit.list'">
          <li ngbNavItem>
            <a ngbNavLink>Audit Log</a>
            <ng-template ngbNavContent>
              <dirt-audit-log [id]="id" [entityAPI]="svcDomain"></dirt-audit-log>
            </ng-template>
          </li>
        </ng-template>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-domain-meta"></div>
    </div>
  </div>

  <!-- Loading Message -->
  <ng-template #loader>
    <p class="dirt-loading">
      <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
      <span class="sr-only">Loading...</span>
    </p>
  </ng-template>
</section>
