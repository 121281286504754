import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FieldType } from '../../field.config';
import { BulkUpdateState } from '../../bulk-update.state';
import { SetFieldValueAction, ToggleFieldValueCheckAction } from '../../bulk-update.actions';
import { Observable, Subscription } from 'rxjs';
import { FieldValue } from '../../field-value';
import { UserAPI } from '../../../../user/shared/api.service';
import { Auth } from '../../../../shared/services/auth/auth.service';

@Component({
  selector: 'dirt-bulk-update-field-value-step',
  templateUrl: './bulk-update-field-value-step.component.html',
  styleUrls: ['./bulk-update-field-value-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkUpdateFieldValueStepComponent implements OnInit, OnDestroy {
  FieldType = FieldType;

  @Select(BulkUpdateState.values) values$: Observable<FieldValue[]>;
  @Select(BulkUpdateState.hasEu) hasEu$: Observable<boolean>;

  values: FieldValue[] = [];
  hasEu: boolean = false;

  valuesSubscription: Subscription;
  hasEuSubscription: Subscription;

  constructor(private store: Store, private svcUser: UserAPI, private auth: Auth) {
    this.valuesSubscription = this.values$.subscribe((values) => {
      this.values = values;
    });
    this.hasEuSubscription = this.hasEu$.subscribe((hasEu) => {
      this.hasEu = hasEu;
    });
  }

  onCheckChange(field: string) {
    this.store.dispatch(new ToggleFieldValueCheckAction(field));
  }

  onFieldValueChange(field: string, value: any) {
    this.store.dispatch(new SetFieldValueAction(field, value === 'null' ? null : value));
  }

  getValues(currentField: FieldValue) {
    if (typeof currentField.config.values === 'function') {
      return currentField.config.values(this.values);
    } else {
      return currentField.config.values;
    }
  }

  trackByItem(index, item) {
    return `${index}-${item.check}-${item.value}`;
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.valuesSubscription.unsubscribe();
    this.hasEuSubscription.unsubscribe();
  }
}
