<button
  class="list-group-item list-group-item-action"
  [disabled]="!(isFilterNotEmpty || search)"
  (click)="onBulkUpdateFilterClick()"
>
  Apply filter & Search
</button>

<button class="list-group-item list-group-item-action" (click)="onBulkUpdateManualClick()">
  Add custom list of ids
</button>
