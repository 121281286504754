import { CommitteeConnection } from './connection';

export class Committee {
  _id: string;
  id: string;
  name: string;
  originalName?: string;
  format: string;
  country: string;
  type: string;
  topic: string;
  therapeuticAreas: string[];
  status: string;
  statusWebSource: string;
  website: string;
  websources: string[];
  priority: number;

  _meta: {
    status: string;
    assignee?: string;
    jobId?: string;
    claimedUntil?: Date;
  };

  createdAt: Date;
  updatedAt: Date;
  _version: number;

  connections: CommitteeConnection[];

  committeeID?: string; // Only used during migration from spreadsheet to cLean. Should be removed later and will not be filled for committees created via UI

  identify?: { by: string; at: Date };
  mapping?: { by: string; at: Date };

  readyForDelivery?: boolean;

  peopleCount?: number; // only in list view
}
