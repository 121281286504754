import { Component, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';

import { Affiliation } from '../../../affiliation/shared/affiliation';
import { AffiliationAPI } from '../../../affiliation/shared/api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { Value } from '../../../shared/services/value/value';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ACL } from '../../acl/acl.service';

@Component({
  selector: 'dirt-person-affiliation-modal',
  styleUrls: ['modal.component.scss'],
  templateUrl: 'modal.component.html',
})
export class PersonAffiliationModalComponent implements OnInit {
  private _sourceItem = new Affiliation();

  suggestedItems: Affiliation[] = [];

  countryValues: Value[] = [];

  orginalAddress: Affiliation['originalAddr'] = {};

  duplicates: { name; department }[] | null = null;

  @Input()
  disableNameField: boolean = false;

  @Input()
  currentJobType?: string;

  @Input()
  isCustomerRequest?: boolean;

  @Input()
  requestOwnerProduct?: string;

  get sourceItem() {
    return this._sourceItem;
  }

  @Input()
  set sourceItem(value: Affiliation) {
    this.svcValue
      .find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.countryValues = data;
      });

    if (value) {
      this._sourceItem = new Affiliation();
      this._sourceItem.name = value.name;
      if (value.department && (!value.type || !['INDUSTRY', 'ASSOCIATION', 'PRIVATE_PRACTICE'].includes(value.type))) {
        const matchResult = value.department.match(/-/);
        this._sourceItem.department = matchResult
          ? value.department.substring(0, matchResult.index + 1)
          : value.department;
      }
      this._sourceItem.originalName = value.originalName;
      this._sourceItem.type = value.type;
      this._sourceItem.address = Object.assign({}, value.address); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables
      if (value.address) {
        this._sourceItem.originalAddr = Object.assign(this.orginalAddress, {
          city: value.address.originalCity,
          name: value.address.originalStreet,
          additionalInfo: value.address.originalAdditionalInfo,
        });
      }
      if (value.sourceLfka) {
        this._sourceItem.sourceLfka = true;
      }
      this._sourceItem.usageInfo = value.usageInfo;
    }

    this.duplicates = null; // make sure next one starts afresh
  }

  isSubmitting: boolean;

  @Output()
  onSuccess: any;

  canCreateAffiliation: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly svcAffiliation: AffiliationAPI,
    private svcValue: ValueAPI,
    private svcAcl: ACL
  ) {
    this._sourceItem.address = {};
  }

  ngOnInit(): void {
    this.canCreateAffiliation = this.svcAcl.hasCredential('affiliation.create', this.currentJobType);
  }

  onSubmit(affiliation: Affiliation, force?: boolean): void {
    if (!this.canSubmit()) {
      return;
    }

    this.isSubmitting = true;
    this.svcAffiliation
      .create(affiliation, !!force)
      .pipe(tap(() => (this.isSubmitting = false)))
      .subscribe(
        (result: { res: Affiliation; potentialDups?: { name; department }[] }) => {
          if (result.potentialDups && result.potentialDups.length) {
            // show the dups and have the save button be "save anyway" with force
            this.duplicates = result.potentialDups.filter((d) => !!d); // Elastic but not mongo might return null
          } else {
            this.activeModal.close(result.res);
          }
        },
        () => (this.isSubmitting = false)
      );
  }

  onAffiliationChange(aff: Affiliation) {
    if (!aff.name && !aff.department && !aff.originalName && !aff.originalDepartment) {
      this.suggestedItems = [];
      return;
    }

    const filter = {};
    if (aff.address && aff.address.countryCode) {
      filter['address.countryCode'] = aff.address.countryCode;
    }

    const term = {
      name: aff.name,
      department: aff.department,
      originalName: aff.originalName,
      originalDepartment: aff.originalDepartment,
    };

    this.svcAffiliation
      .find(term, 10, 0, null, filter, false, aff.sourceLfka /* top only */)
      .toPromise()
      .then((items) => {
        this.suggestedItems = items;
      });
  }

  onSelectItem(item: Affiliation) {
    this.activeModal.close(item);
  }

  canSubmit(): boolean {
    return (
      this.svcAcl.hasCredential('affiliation.create', this.currentJobType) ||
      this.svcAcl.hasCredential('affiliation.request', this.currentJobType)
    );
  }
}
