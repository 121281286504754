<div class="p-1 overflow-hidden">
  <div class="form-group row justify-content-center">
    <label class="col-sm-5 col-form-label">From</label>
    <div class="col-sm-7">
      <input
        type="number"
        class="form-control"
        placeholder="From"
        [max]="rawValue.$lte"
        [(ngModel)]="rawValue.$gte"
        (ngModelChange)="onRawValueChange()"
      />
    </div>
  </div>

  <div class="form-group row justify-content-center">
    <label class="col-sm-5 col-form-label">To</label>
    <div class="col-sm-7">
      <input
        type="number"
        class="form-control"
        placeholder="To"
        [min]="rawValue.$gte"
        [(ngModel)]="rawValue.$lte"
        (ngModelChange)="onRawValueChange()"
      />
    </div>
  </div>
</div>
