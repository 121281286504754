<section class="dirt-jobs-next">
  <div class="next-content">
    <p *ngIf="stats && stats.length">Your current week's job stats:</p>
    <!--Loading Indicator-->
    <p [hidden]="!!stats">
      <i class="fa fa-refresh fa-spin fa-fw"></i>
      Loading your stats...
    </p>
    <ul class="list-group" *ngIf="stats && stats.length">
      <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let stat of stats">
        <span class="col-md-6"> {{ stat.pool }} </span>
        <span class="col-md-2">
          <h5>
            <span ngbTooltip="Done" class="badge badge-secondary">{{ stat.done }}</span>
          </h5>
        </span>
        <span class="col-md-4">
          <h5>
            <span ngbTooltip="Available" class="badge badge-secondary">{{ stat.available }}</span>
          </h5>
        </span>
      </li>
    </ul>
    <br />
    <p>There might also be additional jobs to work{{ drafts && drafts.length ? ' (including drafts)' : '' }}:</p>
    <!--Loading Indicator-->
    <p [hidden]="!!drafts">
      <i class="fa fa-refresh fa-spin fa-fw"></i>
      Loading your drafts...
    </p>

    <ul class="list-group" *ngIf="drafts && drafts.length">
      <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let draft of drafts">
        <a href="#" (click)="getNext($event, draft)">{{ draft.type || '' }} - {{ draft.abstract || draft._id }}</a>
      </li>
    </ul>
  </div>
  <div class="next-action">
    <button type="button" class="btn btn-success" (click)="getNext()" [disabled]="isLoadingNext">
      <i class="fa fa-refresh fa-spin" [hidden]="!isLoadingNext"></i>
      Next Job
    </button>
  </div>
</section>

<dirt-next-job-list></dirt-next-job-list>
