import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { OcrHelperAPI } from '../ocr-helper-api.service';

export const CAPTURE_EVENT_ID = 'capture_event_id';
export const CAPTURE_EVENT_NAME = 'capture_event_name';
export const CAPTURE_TEXTS = 'capture_texts';
export const CAPTURE_RUNNING = 'capture_running';

@Component({
  selector: 'dirt-ocr-bar',
  templateUrl: './ocr-bar.component.html',
  styleUrls: ['./ocr-bar.component.scss'],
})
export class OcrBarComponent implements OnInit {
  @Input()
  webSource: string;

  // @Output = paste to clipboard

  showPdfInput = false;
  pdfCustomUrl = '';
  showScreenshotCapture = false;
  showPdfWait = false;
  showPdfWorkbench = false;
  pdfWorkbenchCollapsedMode = false;
  pdfWorkbenchExpandAnyway = false;
  pdfUuidInfo: { uuid: string; url?: string; pages: number; text?: string } | null = null; // PDFs have a URL, screenshots don't; text can be part of screenshots (when directly OCRed)
  pdfWorkbenchPage: number = null; // null = all in one
  pdfWorkbenchImg: string | SafeUrl = null; // data URL

  coords: number[] = null;
  selecting = false;
  startCoords: number[] = null;
  endCoords: number[] = null;
  currentSelRect: number[] = null; // x,y,w,h - by requestAnimationFrame below
  lang = ''; // one or more tesseract lang codes (sep by +)
  lastUrl = null; // one or more tesseract lang codes (sep by +)
  zoom: '0.25' | '0.5' | '1' | '2' = '1';
  private specialLangs: { key: string; title: string }[] = null;

  lastCopied: string = null;
  lastCopiedTo: number = null;

  @ViewChild('ocrBar') ocrBarElement: ElementRef;
  @ViewChild('screenshotCapture') screenshotCaptureElement: ElementRef;
  @ViewChild('customUrl') customUrlElement: ElementRef;
  @ViewChild('scrollArea') scrollAreaElement: ElementRef;
  @ViewChild('captureSelection') captureSelectionElement: ElementRef;

  wndw: Window = window; // be able to override in tests

  constructor(private readonly svcOcrHelper: OcrHelperAPI, private readonly sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.lang = this.wndw.localStorage.getItem('capture_pref_lang') || '';
    this.lastUrl = this.wndw.localStorage.getItem('capture_last_url') || '';
  }

  onPdfCustom() {
    this.showScreenshotCapture = false;
    this.showPdfWorkbench = false;
    this.showPdfInput = true;
    this.pdfCustomUrl = '';
    this.wndw.setTimeout(() => this.customUrlElement.nativeElement.focus()); // next render
  }
  async onPdfGo(url) {
    if (!(url || '').startsWith('http')) {
      return;
    }
    this.wndw.localStorage.setItem('capture_last_url', url);
    this.showPdfInput = false;
    this.showScreenshotCapture = false;
    this.showPdfWorkbench = false;
    this.showPdfWait = true;
    this.pdfUuidInfo = null;
    this.pdfWorkbenchPage = null; // null = all in one
    this.pdfWorkbenchImg = null;
    this.resetSelect();
    try {
      this.pdfUuidInfo = await this.svcOcrHelper.pdfOcrUrlToUuid(url).toPromise();
      this.pdfWorkbenchPage = this.pdfUuidInfo.pages > 1 ? 1 : null; // no need for pages when there's only one
      await this.showPage();
      this.showPdfWorkbench = true;
    } finally {
      this.showPdfWait = false;
    }
    this.pdfWorkbenchCollapsedMode = false;
    this.wndw.setTimeout(() => this.ocrBarElement.nativeElement.scrollIntoView({ behavior: 'smooth' })); // next render
  }

  private async showPage() {
    const prefShowPdfWait = this.showPdfWait;
    this.pdfWorkbenchImg = this.sanitizer.bypassSecurityTrustUrl(
      await this.svcOcrHelper.pdfOcrGetByUuid(this.pdfUuidInfo.uuid, 'png', this.pdfWorkbenchPage).toPromise()
    );
    this.showPdfWait = prefShowPdfWait;
    setTimeout(() => this.scrollAreaElement.nativeElement.scrollTo(0, 0)); // (next render)
  }

  async onShowFramesChange() {
    this.pdfWorkbenchCollapsedMode = false;
    await this.showPage();
  }
  async onPageChange(delta?: number) {
    if (delta) {
      this.pdfWorkbenchPage = parseInt(this.pdfWorkbenchPage as any, 10) + delta;
    }
    this.pdfWorkbenchCollapsedMode = false;
    this.resetSelect();
    await this.showPage();
  }

  onUpdateCoords(evnt) {
    this.coords = [Math.round(evnt.offsetX), Math.round(evnt.offsetY)];
    if (this.selecting) {
      this.pdfWorkbenchCollapsedMode = false;
      this.endCoords = this.coords;
      requestAnimationFrame(() => this.showSelect());
    }
  }
  private resetSelect() {
    this.selecting = false;
    this.startCoords = null;
    this.endCoords = null;
    this.currentSelRect = null;
  }
  private showSelect() {
    // do this expicitly (via requestAnimationFrame) for a smooth experience
    this.currentSelRect = [
      Math.min(this.endCoords[0], this.startCoords[0]),
      Math.min(this.endCoords[1], this.startCoords[1]),
      Math.max(this.endCoords[0], this.startCoords[0]) - Math.min(this.endCoords[0], this.startCoords[0]),
      Math.max(this.endCoords[1], this.startCoords[1]) - Math.min(this.endCoords[1], this.startCoords[1]),
    ];
  }
  onStartSelect(evnt) {
    this.selecting = true;
    this.startCoords = this.coords;
    this.endCoords = this.coords;
  }
  @HostListener('document:keydown.escape')
  onCancelSelect() {
    if (this.selecting) {
      this.selecting = false;
      this.resetSelect();
    }
  }
  async onEndSelect(regular = false) {
    this.selecting = false;
    const substSelect =
      this.startCoords &&
      this.endCoords &&
      Math.abs(this.startCoords[0] - this.endCoords[0]) >= 10 &&
      Math.abs(this.startCoords[1] - this.endCoords[1]) >= 10;
    if (regular && substSelect) {
      let page = null;
      if (this.pdfWorkbenchPage) {
        page = this.pdfWorkbenchPage;
      }
      const zoomFactor = this.getZoomFactor();
      const r = this.currentSelRect.map((c) => c * zoomFactor);
      this.showPdfWait = true;
      let text = null;
      try {
        text = (
          await this.svcOcrHelper
            .pdfOcrCoords(this.pdfUuidInfo.uuid, r[0], r[1], r[2], r[3], page, this.lang)
            .toPromise()
        ).text;
      } finally {
        this.showPdfWait = false;
      }
      if (text && text.trim()) {
        this.wndw.navigator.clipboard.writeText(text); // just add to clipboard!
        this.showLastCopied(text);
        this.wndw.setTimeout(() => {
          this.pdfWorkbenchCollapsedMode = true;
          this.pdfWorkbenchExpandAnyway = false;
        }, 150);
      }
    } else if (!substSelect) {
      this.pdfWorkbenchCollapsedMode = false; // click = stay here
    }
  }

  private getZoomFactor() {
    if ('0.25' === this.zoom) {
      return 8;
    } else if ('0.5' === this.zoom) {
      return 4;
    } else if ('1' === this.zoom) {
      return 2; // once more factor 2 (retina)
    } else if ('2' === this.zoom) {
      return 1;
    } else {
      console.warn('Wrong zoom ' + this.zoom);
      return 1;
    }
  }

  getSpecialLangs(): { key: string; title: string }[] {
    if (!this.specialLangs) {
      try {
        const storedPlain = this.wndw.localStorage.getItem('capture_special_langs');
        const stored = JSON.parse(storedPlain);
        if (Array.isArray(stored)) {
          this.specialLangs = stored.filter((_s) => _s.key && _s.title);
        } else {
          console.error('Invalid special langs: ' + storedPlain);
          this.specialLangs = [];
        }
      } catch (_e) {
        console.error(_e); // but make sure we stay in business
        this.specialLangs = [];
      }
    }
    return this.specialLangs || [];
  }

  onLangChange(newLang: string): void {
    this.pdfWorkbenchCollapsedMode = false;
    this.wndw.localStorage.setItem('capture_pref_lang', newLang);
    this.resetSelect();
  }
  onZoomChange(): void {
    this.pdfWorkbenchCollapsedMode = false;
    this.resetSelect();
  }

  onScreenshotCapture() {
    this.showPdfInput = false;
    this.showPdfWorkbench = false;
    this.showScreenshotCapture = true;
    this.wndw.setTimeout(() => this.screenshotCaptureElement.nativeElement.focus()); // next render
  }
  @HostListener('window:paste', ['$event'])
  async onScreenshotGo(evnt: any /*PasteEvent*/) {
    if (!this.showScreenshotCapture) {
      return; // don't get it mixed up with normal editing
    }
    const fle: any = Array.from(evnt.clipboardData.files || []).filter((f) =>
      (f as any).type.startsWith('image/png')
    )[0];
    if (fle) {
      this.showScreenshotCapture = false;
      this.showPdfWait = true;
      this.pdfUuidInfo = null;
      this.pdfWorkbenchPage = null; // null = all in one (always for screenshots)
      this.pdfWorkbenchImg = null;
      this.resetSelect();
      try {
        this.pdfUuidInfo = await this.svcOcrHelper.pdfOcrPostScreen(fle, this.lang).toPromise();
        await this.showPage();
        this.showPdfWorkbench = true;
      } finally {
        this.showPdfWait = false;
      }
      this.pdfWorkbenchCollapsedMode = false;
      this.wndw.setTimeout(() => this.ocrBarElement.nativeElement.scrollIntoView({ behavior: 'smooth' })); // next render
      if (this.pdfUuidInfo.text) {
        // small screnshots are OCRed directly
        this.wndw.navigator.clipboard.writeText(this.pdfUuidInfo.text); // just add to clipboard!
        this.showLastCopied(this.pdfUuidInfo.text);
        this.pdfWorkbenchCollapsedMode = true;
        this.pdfWorkbenchExpandAnyway = false;
      }
    }
  }

  private showLastCopied(text: string) {
    this.lastCopied = text;
    if (this.lastCopiedTo) {
      this.wndw.clearTimeout(this.lastCopiedTo);
      this.lastCopiedTo = null;
    }
    this.lastCopiedTo = this.wndw.setTimeout(() => {
      this.lastCopied = null;
      this.lastCopiedTo = null;
    }, 5000);
  }

  arrayRange(to, from = 1) {
    const res = [];
    for (let i = from; i <= to; i++) {
      res.push(i);
    }
    return res;
  }
}
