<section class="dirt-association-qc-next">
  <div *ngIf="isMultipleActivityReviewer" class="card-header" (click)="toggleExpanded()">
    <div class="d-inline-block">
      <i
        class="fa fa-lg"
        [ngClass]="{ 'fa-angle-right': !isExpanded(), 'fa-angle-down': isExpanded() }"
        aria-hidden="true"
      ></i>
      <span *ngIf="isExpanded()" class="font-weight-bold pl-1 pr-2">Association Queue</span>
      <span *ngIf="!isExpanded()" class="font-weight-bold pl-1 pr-2"
        >Association Queue
        <span
          *ngIf="all"
          class="badge"
          [ngClass]="{
            'badge-secondary': all.percent === 0 && all.needed >= 1,
            'badge-success': all.needed < 1,
            'badge-warning': all.needed >= 1 && all.percent > 0
          }"
          >{{ stat.completed }}/{{ stat.total }} {{ stat.percent }}%</span
        >
      </span>
    </div>
  </div>

  <div [ngbCollapse]="!isExpanded()">
    <div class="next-content">
      <!--Loading Indicator-->
      <p [hidden]="!!queue">
        <i class="fa fa-refresh fa-spin fa-fw"></i>
        Loading your statistics...
      </p>
      <div>
        <div class="card" *ngIf="regionPoolStats && regionPoolStats.length">
          <div class="card-header">Here are the statistics by your region pool:</div>

          <div class="card-body">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th></th>
                  <th>Associations</th>
                  <th>QCed memberships%</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let stat of regionPoolStats">
                  <td>
                    <span>{{ getRegionDisplayName(stat.region) | capitalize }}</span>
                  </td>

                  <!-- Remaining -->
                  <td>
                    <h5>
                      <span class="badge badge-secondary">{{ stat.total }}</span>
                    </h5>
                  </td>

                  <!-- QCed % -->
                  <td>
                    <h5>
                      <span class="badge">{{ stat.qcedPercent }}%</span>
                    </h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card" *ngIf="queue && queue.length">
          <div class="card-header">Here are the associations waiting for your {{ isIdQC ? 'ID' : '' }} review:</div>

          <div class="card-body">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th></th>
                  <th>Remaining</th>
                  <th>Total</th>

                  <ng-container *ngIf="isIdQC">
                    <th>Ready for Compilation</th>
                    <th>Done</th>
                  </ng-container>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let stat of queue">
                  <td>
                    <span>{{ stat.name.split('_').join(' ') | capitalize }}</span>
                  </td>

                  <!-- Remaining -->
                  <td>
                    <h5>
                      <span class="badge badge-secondary">{{ stat.remaining }}</span>
                    </h5>
                  </td>

                  <!-- Total -->
                  <td>
                    <h5>
                      <span
                        class="badge"
                        [ngClass]="{
                          'badge-secondary': stat.percent === 0 && stat.needed >= 1,
                          'badge-success': stat.needed < 1,
                          'badge-warning': stat.needed >= 1 && stat.percent > 0
                        }"
                        >{{ stat.completed }}/{{ stat.total }} {{ stat.percent }}%</span
                      >
                    </h5>
                  </td>

                  <ng-container *ngIf="isIdQC">
                    <!-- Ready for Compilation -->
                    <td>
                      <h5>
                        <span class="badge badge-secondary"
                          >{{ (stat.completedReadyForCompilation / (stat.total || 1)) * 100 | number : '1.0-0' }}%</span
                        >
                      </h5>
                    </td>

                    <!-- Done -->
                    <td>
                      <h5>
                        <span class="badge badge-secondary"
                          >{{ (stat.completedDone / (stat.total || 1)) * 100 | number : '1.0-0' }}%</span
                        >
                      </h5>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <p *ngIf="queue && !queue.length">
        <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i>&nbsp; You have no pending item in your queue. Simply
        click the button to start working on a new one!
      </p>
    </div>

    <div class="exceed-cap">
      <label><input type="checkbox" [(ngModel)]="exceedCap" /> Exceed review cap</label>
    </div>

    <div class="next-action">
      <button type="button" class="btn btn-success" (click)="getNext()" [disabled]="isLoadingNext">
        <i class="fa fa-refresh fa-spin" [hidden]="!isLoadingNext"></i>
        Next Association
      </button>
    </div>
  </div>
</section>
