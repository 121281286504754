export enum GoogleAddress {
  CITY = <any>'city',
  COUNTY = <any>'county',
  COUNTRY = <any>'country',
  COUNTRY_CODE = <any>'countryCode',
  FORMATTED = <any>'formatted',
  LATITUDE = <any>'latitude',
  LONGITUDE = <any>'longitude',
  NAME = <any>'name',
  NEIGHBORHOOD = <any>'neighborhood',
  OFFSET_UTC = <any>'offsetUTC',
  STREET = <any>'street',
  STREET_NO = <any>'streetNumber',
  STATE = <any>'state',
  VICINITY = <any>'vicinity',
  ZIP = <any>'zip',
  ZIP_SUFFIX = <any>'zipSuffix',
}
