<form class="no-validate" novalidate>
  <!-- Address Type -->
  <div class="form-group row" *ngIf="!disabled && (!disableGoogle || internalSearchConfig)">
    <div class="btn-group col-md-12" name="radioAddressType" [(ngModel)]="addressEntryMode" ngbRadioGroup>
      <label class="btn btn-secondary btn-sm" ngbButtonLabel (click)="onAddressGoogleEntry()" *ngIf="!disableGoogle">
        <input ngbButton type="radio" value="googleSearch" /> Google Search
      </label>
      <label class="btn btn-secondary btn-sm" ngbButtonLabel (click)="onAddressManualEntry()">
        <input ngbButton type="radio" value="manual" /> Manual Entry
      </label>
      <label
        *ngIf="internalSearchConfig"
        class="btn btn-secondary btn-sm"
        ngbButtonLabel
        (click)="onAddressInternalSearchEntry()"
      >
        <input ngbButton type="radio" value="internalSearch" />
        {{ internalSearchConfig.label }}
      </label>
    </div>
  </div>

  <!-- Address: Google -->
  <div class="form-group row" *ngIf="addressEntryMode === 'googleSearch'">
    <div class="col-md-12">
      <input
        class="form-control"
        id="address"
        name="address"
        [ngModel]="address"
        (ngModelChange)="onAddressChange($event)"
        dirtGooglePlace
        [forceAddress]="force"
        [disabled]="disabled"
        [required]="mandatory"
        [withHouseNo]="withHouseNo"
      />
    </div>
  </div>

  <!-- Address: Internal Search -->
  <div class="form-group row" *ngIf="addressEntryMode === 'internalSearch' && internalSearchConfig">
    <div class="input-group col-md-12">
      <div class="input-group-prepend">
        <div class="input-group-text">
          <i class="fa fa-search" aria-hidden="true" [hidden]="isSearching"></i>
          <i class="fa fa-refresh fa-spin" [hidden]="!isSearching"></i>
        </div>
      </div>
      <input
        type="text"
        class="form-control ng-trim-ignore"
        name="series"
        [placeholder]="internalSearchConfig.searchPlaceHolder"
        [ngbTypeahead]="search"
        [inputFormatter]="formatInput"
        [resultFormatter]="formatOutput"
        (selectItem)="onItemSelect($event)"
      />
    </div>
  </div>

  <!-- Address: Manual  -->
  <fieldset [disabled]="addressEntryMode !== 'manual' || disabled">
    <!-- Street -->
    <div class="form-group row">
      <div [ngClass]="{ 'col-sm-12': !withHouseNo, 'col-sm-10': withHouseNo }">
        <label class="sr-only" for="street">Street</label>
        <input
          type="text"
          id="street"
          class="form-control"
          name="address-street"
          [ngModel]="address && address.name"
          (ngModelChange)="address.name = longDash($event)"
          placeholder="Street"
          [required]="mandatory"
        />
      </div>
      <div class="col-sm-2" *ngIf="withHouseNo">
        <label class="sr-only" for="houseNo">HouseNo</label>
        <input
          type="text"
          id="houseNo"
          class="form-control"
          name="address-houseNo"
          [ngModel]="address && address.houseNo"
          (ngModelChange)="address.houseNo = longDash($event)"
          placeholder="House#"
        />
      </div>
    </div>

    <!-- Additional -->
    <div class="form-group" *ngIf="address && additional">
      <label class="sr-only" for="addressAddInfo">Street Additional</label>
      <input
        type="text"
        id="addressAddInfo"
        class="form-control"
        name="addressAddInfo"
        placeholder="Street Additional"
        [ngModel]="address.additionalInfo"
        (ngModelChange)="address.additionalInfo = longDash($event)"
        [disabled]="disabled"
      />
    </div>

    <div class="form-group row">
      <div class="col-md-12">
        <div class="row">
          <!-- City -->
          <div class="col-md-4">
            <label class="sr-only" for="city">City</label>
            <input
              type="text"
              id="city"
              class="form-control"
              name="address-city"
              [ngModel]="address && address.city"
              (ngModelChange)="address.city = longDash($event)"
              placeholder="City"
              [required]="mandatory || mandatoryCity"
            />
          </div>

          <!-- State -->
          <div class="col-md-3" *ngIf="!original">
            <label class="sr-only" for="address-state">State</label>
            <select
              id="address-state"
              class="form-control custom-select"
              name="address-state"
              [(ngModel)]="address && address.state"
              [validateList]="getStateCountries(address.countryCode)"
              [disabled]="!isStateSelectorEnabled()"
              allowEmpty
            >
              <option [ngValue]="undefined">&mdash; State &mdash;</option>
              <option *ngIf="addNoStateOption" value="">--</option>
              <option *ngFor="let state of getStateCountries(address.countryCode)" [value]="state">
                {{ state }}
              </option>
            </select>
          </div>

          <!-- Zip -->
          <div class="col-md-2" *ngIf="!original">
            <label class="sr-only" for="zip">Zip</label>
            <input
              type="text"
              id="zip"
              class="form-control"
              name="address-zip"
              [ngModel]="address && address.zip"
              (ngModelChange)="address.zip = longDash($event)"
              placeholder="Zip"
              [required]="mandatory"
            />
          </div>

          <!-- Country -->
          <div class="col-md-3" *ngIf="!original">
            <label class="sr-only" for="countryCode">Country</label>
            <select
              id="countryCode"
              class="form-control custom-select"
              name="address-countryCode"
              [(ngModel)]="address && address.countryCode"
              (change)="onCountryCodeChange($event.target.value)"
              [required]="mandatory || mandatoryCountry"
            >
              <option *ngFor="let country of countries" [value]="country.id">
                {{ country.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</form>
