<section class="dirt-section-list dirt-domain-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>Domains</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        placeholder="Search by Hostname"
        [formControl]="searchCtrl"
      />
    </div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Filter -->
        <dirt-filters [filters]="filters" (onFilter)="onFilter($event)"></dirt-filters>

        <!-- Bulk -->
        <ng-container>
          <dirt-button-dropdown
            [title]="'Bulk'"
            [icon]="'fa-pencil'"
            *diAcl="'domain.bulkList || domain.bulkListCompliantCountries'"
          >
            <button
              class="list-group-item list-group-item-action"
              [title]="bulkListDelegate.specialHint || ''"
              (click)="onOpenBulkListDialog()"
              *diAcl="'domain.bulkList'"
            >
              <i class="fa fa-th-list" aria-hidden="true"></i>
              Bulk import
            </button>

            <button
              class="list-group-item list-group-item-action"
              [title]="bulkListCompliantCountriesDelegate.specialHint || ''"
              (click)="onOpenBulkListCompliantCountriesDialog()"
              *diAcl="'domain.bulkListCompliantCountries'"
            >
              <i class="fa fa-th-list" aria-hidden="true"></i>
              Bulk import compliant countries
            </button>
          </dirt-button-dropdown>
        </ng-container>

        <!-- Create Button -->
        <a class="btn btn-success btn-sm" [routerLink]="['/domain/create']" *diAcl="'domain.create'">
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
          <span>Create Domain</span>
        </a>
      </div>
    </div>
  </header>

  <!-- Filter Bar -->
  <dirt-filters-bar
    [filter]="filter"
    [config]="filterBarConfig"
    (filterClear)="onFilterClear()"
    (filterValueRemove)="onFilterValueRemove($event)"
  ></dirt-filters-bar>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-domain-list'"
    [pagingSize]="domains?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="domains?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-hostname" [diColSorter]="'hostname.keyword'">Hostname</th>
          <th class="col-status" [diColSorter]="'status'">Status</th>
          <th class="col-checked" [diColSorter]="'checked.at'">Checked</th>
          <th class="col-frequency" [diColSorter]="'frequency'">Frequency</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-domain-row"
          *ngFor="
            let domain of domains
              | paginate
                : {
                    id: 'dirt-domain-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <td class="col-hosname">
            <a class="dirt-domain-hosname" [title]="domain.hostname" [routerLink]="['/domain/detail', domain._id]">{{
              domain.hostname
            }}</a>
          </td>
          <td class="col-status" [title]="domain.status | capitalize">{{ domain.status | capitalize }}</td>
          <td class="col-checked">{{ domain.checked?.at | date : 'dd.MM.y' }}</td>
          <td class="col-frequency">{{ domain.frequency || 'N/A' }}</td>
          <td class="col-actions">
            <ng-template [diAcl]="'domain.delete'">
              <!-- Delete -->
              <span class="action-btn action-delete" title="Delete Domain" (click)="onDelete(domain._id)">
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No Domains</p>
    </ng-template>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-domain-list'"
    [pagingSize]="domains?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
