import { Pipe, PipeTransform } from '@angular/core';

const MAPPING = {
  LFTA: 'LFKP',
};

@Pipe({ name: 'productLabel' })
export class ProductLabelPipe implements PipeTransform {
  transform(value: string): number {
    return MAPPING[value] || value;
  }
}
