import { Twitter } from '../../shared/values/twitter';

export class Account {
  _id: string;
  id: string;
  name: string;
  originalName?: string;
  alternativeNames: string;
  acronym?: string;
  address: any = {};
  parent?: { id: string; name: string };
  rootAccount?: string;
  level: number;
  type: string;
  subtype: string;
  phone: {
    number?: string;
    countryCallingCode?: string;
    extension?: string;
  } = {};
  website: string;
  logo: {
    url?: string;
    source?: string;
    local_url?: string;
    local_url_nocache?: string;
    approved?: boolean;
    notFound?: boolean;
  };
  social: {
    twitter?: Twitter;
  };
  websources: string[];

  // METRICS DATA INFORMATION
  summary: string;
  summaryLinks: string[] = [];
  developmentNotes: string;
  developmentNotesLinks: string[] = [];
  economicStatus: string;
  ownershipStatus: string;
  internationalOperations: boolean;
  marketShare: number;
  isGPOMember: boolean;
  GPOName: string;
  GPOLink: string;
  isNCCNMember: boolean;
  payerMix: string[] = [];
  ownHealthInsurance: boolean;
  healthPlanName: string;
  healthPlanNetworkType: string[] = [];
  healthPlanProductCategories: string;
  healthPlanMembersCount: number;
  pharmacyBenefitManager: string;
  metricsDataLinks: string[] = [];
  departmentNames?: string[] = [];
  departmentNamesLinks?: string[] = [];

  divisions: Account[];

  _meta: {
    status: string;
    assignee?: string;
    jobId?: string;
    claimedUntil?: Date;
  };

  qc?: any;

  readyForDelivery: boolean; // (only top-level)

  createdAt: Date;
  updatedAt: Date;
  _version: number;

  identify?: { by: string; at: Date };
  mapping?: { by: string; at: Date };
  profiling?: { by: string; at: Date };
  review?: { by: string; at: Date };

  priority: number;

  accountID?: string; // Only used during migration from spreadsheet to cLean. Should be removed later and will not be filled for accounts created via UI

  removedAffiliationIds?: string[];

  teachingStatus?: string;

  inactive?: boolean;
}
