import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { CaptureBookmarkletComponent } from './shared/capture-bookmarklet/capture-bookmarklet.component';
import { CaptureBarComponent } from './shared/capture-bar/capture-bar.component';
import { ContributionParseAPI } from './shared/contribution-parse-api.service';

@NgModule({
  imports: [SharedModule],
  declarations: [CaptureBookmarkletComponent, CaptureBarComponent],
  exports: [CaptureBookmarkletComponent, CaptureBarComponent],
  providers: [ContributionParseAPI],
})
export class ContributionCaptureModule {}
