<div class="input-group">
  <input
    #dateInput="ngModel"
    type="text"
    class="form-control"
    [name]="name"
    [(ngModel)]="displayValue"
    (ngModelChange)="onValueChange($event)"
    ngbDatepicker
    #dp="ngbDatepicker"
    readonly
    [required]="required"
  />

  <div class="input-group-append">
    <button type="button" class="btn btn-secondary" (click)="dp.toggle()" [hidden]="disabled">
      <i class="fa fa-calendar" aria-hidden="true"></i>
    </button>

    <button type="button" class="btn btn-secondary" (click)="onValueClear()" [hidden]="disabled || !displayValue">
      <i class="fa fa-trash-o" aria-hidden="true"></i>
    </button>
  </div>
</div>
