import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { tap, concatMap, take, catchError } from 'rxjs/operators';

import { AccountMedicalInformation } from '../shared/account-medical-information';
import { AccountMedicalInformationAPI } from '../shared/account-medical-information-api.service';

@Component({
  selector: 'dirt-account-medical-information-detail',
  templateUrl: './detail.component.html',
})
export class AccountMedicalInformationDetailComponent implements OnInit {
  id: string;

  accountMedicalInformation: AccountMedicalInformation;

  isLoading = true;

  isSubmitting = false;

  isFormValid: boolean;

  private wndw: Window = window; // allow for testing

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public svcAccountMedicalInformation: AccountMedicalInformationAPI // used in template too
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        take(1),
        tap(() => (this.isLoading = true)),
        concatMap((params) => {
          this.wndw.scrollTo(0, 0);

          this.id = params['id'];

          return this.svcAccountMedicalInformation
            .findById(this.id)
            .pipe(tap((accountMedicalInformation) => (this.accountMedicalInformation = accountMedicalInformation)));
        }),
        tap(() => (this.isLoading = false))
      )
      .subscribe();
  }

  goBack(): void {
    this.router.navigate(['/account/detail', this.accountMedicalInformation.account.id]);
  }

  onSave(accountMedicalInformation: AccountMedicalInformation): void {
    this.updateAccountMedicalInformation(accountMedicalInformation);
  }

  onFormValidityChange(status: string): void {
    setTimeout(() => (this.isFormValid = status === 'VALID'));
  }

  private updateAccountMedicalInformation(accountMedicalInformation: AccountMedicalInformation): void {
    if (!this.isFormValid) {
      return;
    }

    this.isSubmitting = true;

    this.svcAccountMedicalInformation
      .update(this.id, accountMedicalInformation)
      .pipe(
        tap(() => (this.isSubmitting = false)),
        catchError((err) => {
          this.isSubmitting = false;
          throw err;
        })
      )
      .subscribe(() => {
        this.goBack();
      });
  }
}
