<section class="dirt-section-detail dirt-job detail">
  <div class="row" *ngIf="!isLoading; else loader">
    <div class="col-md-8">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <h1 class="bounceInRight">Job Detail</h1>

      <br />

      <dirt-job-form #form [model]="job" [jobTypes]="jobTypes"></dirt-job-form>

      <div class="pull-left" *ngIf="canReset">
        <button class="btn btn-primary" (click)="onReset()">Reset</button>
      </div>

      <div class="pull-right" *diAcl="'job.update'">
        <button
          class="btn btn-primary"
          [ngClass]="{ 'btn-loading': isSubmitting }"
          [disabled]="!form.isValid() || isSubmitting"
          (click)="onSave()"
        >
          Save
        </button>
      </div>
    </div>

    <div class="col-md-4">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Workflow -->
        <li ngbNavItem>
          <a ngbNavLink>Workflow</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <dt class="col-sm-4">Created</dt>
              <dd class="col-sm-8">{{ job.createdAt | date : 'medium' }}</dd>

              <dt class="col-sm-4 text-truncate">Modified</dt>
              <dd class="col-sm-8">{{ job.updatedAt | date : 'medium' }}</dd>

              <ng-container *ngIf="job.completedAt">
                <dt class="col-sm-4 text-truncate">Completed</dt>
                <dd class="col-sm-8">{{ job.completedAt | date : 'medium' }}</dd>

                <dt class="col-sm-4 text-truncate">Completed By</dt>
                <dd class="col-sm-8">
                  <dirt-username [userId]="job.completedBy"></dirt-username>
                </dd>
              </ng-container>

              <!-- TODO: with KPP: discardedAt -->

              <dt class="col-sm-4 text-truncate">Status</dt>
              <dd class="col-sm-8">
                <span>{{ job._meta?.status | capitalize }}</span>
              </dd>

              <dt class="col-sm-4">Version</dt>
              <dd class="col-sm-8">{{ job._version || 0 }}</dd>
            </dl>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-job-meta"></div>
    </div>
  </div>

  <!-- Loading Message -->
  <ng-template #loader>
    <p class="dirt-loading">
      <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
      <span class="sr-only">Loading...</span>
    </p>
  </ng-template>
</section>
