import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { Directive, forwardRef } from '@angular/core';

@Directive({
  selector: '[integerValidator]',
  providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => IntegerValidator), multi: true }],
})
export class IntegerValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors {
    if (control.value === undefined || control.value === null) {
      return null;
    }

    return Number.isInteger(control.value) ? null : { notInteger: true };
  }
}
