import { ErrorHandler } from '@angular/core';
import { recordErrorIntoRUM } from './cloudwatch-rum.service';
import { environment } from '../../../../environments/environment';

/**
 * Hooks into Angular's error handling and
 *  - records them to RUM for the environments stage & prod
 *  - records them to the local error console for the environments dev & nightly
 *
 * See https://github.com/aws-observability/aws-rum-web/blob/main/docs/cdn_angular.md
 */
export class CleanErrorHandler implements ErrorHandler {
  handleError(error: any) {
    if (environment.enableRUM) {
      recordErrorIntoRUM(error);
      return;
    }

    // As a fallback error handler
    console.error(error);
  }
}
