import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep } from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

export enum FilterType {
  SINGLE_VALUE = 'SINGLE_VALUE',
  MULTI_VALUE = 'MULTI_VALUE',
  MULTI_VALUE_FILTERABLE = 'MULTI_VALUE_FILTERABLE',
  DATE_RANGE = 'DATE_RANGE',
  CUSTOM = 'CUSTOM', // allow passing a custom component to be rendered
}

export interface Filter {
  title: string;
  key: string;
  type: FilterType;
  value?: unknown;
  values?: FilterValue[];
  component?: new (...args: unknown[]) => CustomFilterComponent;
}

export interface FilterValue {
  title: string;
  displayValue: string;
  selected?: boolean;
}

export interface CustomFilterComponent {
  value: unknown;
  valueChange: EventEmitter<unknown>;
  doCancel: (previousValue: any) => void;
  doClear: () => void;
  values?: FilterValue[];
}

export interface SubMenuFilterOptions {
  key: string;
  value: boolean;
  subOptions?: { key: string; value: boolean }[];
}

@Component({
  selector: 'dirt-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnChanges, OnDestroy {
  /**
   * IMPORTANT: when adding filters asynchronously, remember to create a new
   * reference (e.g. spread old filter array in a new one) so that ngOnChanges
   * is triggered.
   */
  @Input() filters: Filter[];

  @Output() onFilter = new EventEmitter<{ [key: string]: any }>();

  visiblePanel: string;

  activeFilters = new Map<string, any>();

  changedFilters = new Map<string, any>(); // could be private, but kinda important to test so keep public

  @ViewChild('filterPopover', { static: false })
  private filterPopover: NgbPopover;

  private customFiltersRefs: Map<Filter, ComponentRef<CustomFilterComponent>> = new Map();

  private destroy$: Subject<boolean> = new Subject();

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.filters) {
      return;
    }

    this.activeFilters = new Map<string, any>(); // reset active filters
    changes.filters.currentValue.forEach((filter: Filter) => {
      switch (filter.type) {
        case FilterType.DATE_RANGE:
          this.activeFilters.set(filter.key, filter.value);
          if (Object.keys(this.activeFilters.get(filter.key) || {}).length === 0) {
            // clear empty filters
            this.activeFilters.delete(filter.key);
          }
          break;
        case FilterType.SINGLE_VALUE:
        case FilterType.MULTI_VALUE:
        case FilterType.MULTI_VALUE_FILTERABLE:
          this.activeFilters.set(
            filter.key,
            filter.values.filter((value: FilterValue) => value.selected)
          );
          if (this.activeFilters.get(filter.key)?.length === 0) {
            // clear empty filters
            this.activeFilters.delete(filter.key);
          }
          break;
        case FilterType.CUSTOM:
          this.activeFilters.set(filter.key, filter.value);
          const value = this.activeFilters.get(filter.key);
          if (value === undefined || value === null) {
            this.activeFilters.delete(filter.key);
          }
          if (
            value === undefined ||
            value === null ||
            (typeof value === 'object' && Object.keys(value || {}).length === 0)
          ) {
            // clear empty filters
            this.activeFilters.delete(filter.key);
          }
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  onShow(): void {
    this.visiblePanel = null; // reset panel visibility
    this.applyCancel();
  }

  doApply(): void {
    this.activeFilters = new Map([
      ...cloneDeep(Array.from(this.activeFilters.entries())),
      ...cloneDeep(Array.from(this.changedFilters.entries())),
    ]); // "commit" changes (destroy ref to not mutate the data later on, it's used for cancel logic)
    this.changedFilters.clear(); // "discard" changes since they've been committed

    const filters = Array.from(this.activeFilters.entries()).reduce((acc, [filterKey, filterValue]) => {
      const filter = this.filters.find((f) => f.key === filterKey);

      switch (filter.type) {
        case FilterType.DATE_RANGE:
          acc[filter.key] = filterValue as { $gte: string; $lte: string };
          if (Object.keys(acc[filter.key] || {}).length === 0) {
            // clear empty filters
            this.activeFilters.delete(filter.key);
            delete acc[filter.key];
          }
          break;
        case FilterType.SINGLE_VALUE:
        case FilterType.MULTI_VALUE:
        case FilterType.MULTI_VALUE_FILTERABLE:
          acc[filter.key] = filterValue
            .filter((value: FilterValue) => value.selected)
            .map((value: FilterValue) => value.title);
          if (acc[filter.key]?.length === 0) {
            // clear empty filters
            this.activeFilters.delete(filter.key);
            delete acc[filter.key];
          }
          break;
        case FilterType.CUSTOM:
          acc[filter.key] = filterValue;
          if (
            acc[filter.key] === undefined ||
            acc[filter.key] === null ||
            (typeof acc[filter.key] === 'object' && Object.keys(acc[filter.key] || {}).length === 0)
          ) {
            // clear empty filters
            this.activeFilters.delete(filter.key);
            delete acc[filter.key];
          }
          break;
      }

      return acc;
    }, {});

    this.closeFilterPopover();
    this.onFilter.next(filters);
  }

  doCancel(): void {
    this.applyCancel();
    this.closeFilterPopover();
  }

  doClear(): void {
    this.resetFilters(this.activeFilters);
    this.activeFilters.clear();
    this.applyCancel();
    this.filterPopover.close();
    this.onFilter.next({});

    this.filters.forEach((filter) => {
      // reset custom filters
      if (filter.type !== FilterType.CUSTOM) {
        return;
      }
      this.customFiltersRefs.get(filter).instance.doClear();
    });
  }

  setPanel(panel: string): void {
    this.visiblePanel = panel;
  }

  toggleSingleSelectSelection(filter: Filter, value: FilterValue): void {
    const previousSelectionState = value.selected;
    filter.values.forEach((value) => (value.selected = false)); // reset all selections

    value.selected = !previousSelectionState;
    this.changedFilters.set(filter.key, [value]);
  }

  toggleMultiSelectSelection(filter: Filter, value: FilterValue): void {
    value.selected = !value.selected;

    const newValues = filter.values.filter((value) => value.selected);
    this.changedFilters.set(filter.key, newValues);
  }

  updateDateRange(filter: Filter, key: string, value: Date): void {
    const change = {
      ...(this.activeFilters.get(filter.key) || {}),
      ...(this.changedFilters.get(filter.key) || {}),
    };

    if (change['$gte']?.getFullYear() < 1900 || change['$gte']?.getFullYear() < 1900) {
      return; // incomplete input
    }

    if (value) {
      change[key] = value;
    } else {
      delete change[key];
    }

    if ((change['$gte'] && !change['$lte']) || change['$gte'] > change['$lte']) {
      change['$lte'] = new Date(change['$gte'].toString()); // break the ref
    }

    if (change['$lte'] && !change['$gte']) {
      change['$gte'] = new Date(change['$lte'].toString()); // break the ref
    }

    change['$gte']?.setUTCHours(0, 0, 0, 0); // first hour of day
    change['$lte']?.setUTCHours(23, 59, 59, 999); // last hour of day

    if (Object.keys(change || {}).length === 0) {
      this.changedFilters.delete(filter.key);
    } else {
      this.changedFilters.set(filter.key, change);
    }
  }

  removeValue(key: string, value: unknown): void {
    if (!this.activeFilters.has(key)) {
      return;
    }

    const matchingFilter = this.filters.find((filter) => filter.key === key);
    if (!matchingFilter) {
      return;
    }

    this.doCancel(); // prevent accidentally committing changes

    switch (matchingFilter.type) {
      case FilterType.DATE_RANGE:
        matchingFilter.value = {};
        this.activeFilters.delete(key);
        break;
      case FilterType.SINGLE_VALUE:
      case FilterType.MULTI_VALUE:
      case FilterType.MULTI_VALUE_FILTERABLE:
        const matchingValue = matchingFilter.values.find((v) => v.title === value);
        if (!matchingValue) {
          return;
        }

        matchingValue.selected = false;
        this.activeFilters.set(
          key,
          matchingFilter.values.filter((v: FilterValue) => v.selected)
        );
        if (this.activeFilters.get(key)?.length === 0) {
          // clear empty filters
          this.activeFilters.delete(key);
        }
        break;
      case FilterType.CUSTOM:
        delete matchingFilter.value;
        this.activeFilters.delete(key);
        break;
    }

    this.doApply();
  }

  trackByKey(index: number, filter: Filter): string {
    return filter.key;
  }

  renderCustomComponent(customComponentWrapper: ViewContainerRef, filter: Filter): void {
    if (filter.type !== FilterType.CUSTOM || !filter.component) {
      return;
    }

    // if the component should be here and isn't, insert if back
    if (this.customFiltersRefs.has(filter)) {
      if (customComponentWrapper.indexOf(this.customFiltersRefs.get(filter)?.hostView) === -1) {
        this.attachCustomFilter(customComponentWrapper, filter);
      }
      return;
    }

    this.attachCustomFilter(customComponentWrapper, filter);
  }

  private closeFilterPopover(): void {
    this.filterPopover.close();
    this.setPanel(undefined);
  }

  /** Reset filters contained in filters map */
  private resetFilters(filters: Map<string, any>): void {
    for (const key of filters.keys()) {
      const matchingFilter = this.filters.find((f) => f.key === key);
      switch (matchingFilter.type) {
        case FilterType.DATE_RANGE:
          matchingFilter.value = {};
          break;
        case FilterType.SINGLE_VALUE:
        case FilterType.MULTI_VALUE:
        case FilterType.MULTI_VALUE_FILTERABLE:
          matchingFilter.values.forEach((value) => (value.selected = false));
          break;
        case FilterType.CUSTOM:
          delete matchingFilter.value;
          break;
      }
    }
  }

  private applyCancel(): void {
    for (const key of this.changedFilters.keys()) {
      // Go back to previous state
      const matchingFilter = this.filters.find((f) => f.key === key);
      switch (matchingFilter.type) {
        case FilterType.DATE_RANGE:
          matchingFilter.value = this.activeFilters.get(key) || {};
          break;
        case FilterType.SINGLE_VALUE:
        case FilterType.MULTI_VALUE:
        case FilterType.MULTI_VALUE_FILTERABLE:
          if (!this.activeFilters.has(key)) {
            // no selected since the beginning? unselect all
            matchingFilter.values.forEach((value: FilterValue) => (value.selected = false));
          } else {
            // go back to the last active state we got
            this.changedFilters.get(key).forEach((value: FilterValue) => {
              const matchingValue = this.activeFilters
                .get(key)
                .find((originalValue: FilterValue) => originalValue.title === value.title);
              if (!matchingValue) {
                value.selected = false;
              } else {
                value.selected = matchingValue.selected;
              }
            });
          }
          break;
        case FilterType.CUSTOM:
          matchingFilter.value = this.activeFilters.get(key);
          this.customFiltersRefs.get(matchingFilter).instance.doCancel(matchingFilter.value);
          break;
      }
    }

    this.changedFilters.clear(); // "discard" changes
  }

  /**
   * Dynamically insert a filter component from its class name, and ensure
   * that its values are properly set.
   */
  private attachCustomFilter(customComponentWrapper: ViewContainerRef, filter: Filter): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(filter.component);
    const componentRef = componentFactory.create(customComponentWrapper.injector);

    componentRef.changeDetectorRef.detach();

    componentRef.instance.value = this.activeFilters.get(filter.key);
    componentRef.instance.values = filter.values;
    componentRef.instance.valueChange.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.changedFilters.set(filter.key, value);
    }); // Sub will complete when custom filter is destroyed and the subject will be unsubscribed

    customComponentWrapper.insert(componentRef.hostView);

    setTimeout(() => {
      componentRef.changeDetectorRef.reattach();
    }); // Just the usual Angular change detection crap

    this.customFiltersRefs.set(filter, componentRef);
  }
}
