import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, take, map, tap, switchMap, catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { capitalize } from 'lodash';
import { format } from 'date-fns';

import { GuidelineAPI } from '../shared/guideline-api.service';
import { Guideline } from '../shared/guideline';
import { ACL } from '../../shared/acl/acl.service';
import { Filter, FiltersComponent, FilterType } from '../../shared/components/filters/filters.component';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { GuidelineStatus } from '../shared/constant/status.enum';
import { YearFilterComponent } from '../shared/filters/year-filter/year-filter.component';
import {
  SubMenuFilterComponent,
  SubMenuFilterValue,
} from '../shared/filters/sub-menu-filter/sub-menu-filter.component';
import { PublicationAPI } from '../../publication/shared/publication-api.service';

@Component({
  selector: 'dirt-guideline-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class GuidelineListComponent implements OnInit {
  guidelines: Guideline[];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  // Sorting / Filtering settings
  filter: { [key: string]: any };
  searchTerm: string;
  filterBarConfig = {
    'journal.country': {
      label: 'Country',
      format: (value: string) => {
        return this.countries.get(value) || value;
      },
    },
    '_meta.status': {
      label: 'Status',
      format(value: string) {
        return capitalize(value).replace(/_/g, ' ');
      },
    },
    publicationDate: {
      label: 'Publication Year',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy')} - ${format(value.$lte, 'yyyy')}`;
      },
    },
    createdAt: {
      label: 'Created at',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
    updatedAt: {
      label: 'Modified at',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
    compiler: {
      label: 'Compiler',
      format: (value: string) => {
        return this.compilers.get(value) || value;
      },
    },
    'identify.by': {
      label: 'Created By',
      format: (value: string) => {
        return this.creators.get(value) || value;
      },
    },
    category: {
      label: 'Category',
      format: (value: string) => {
        return this.categories.get(value) || value;
      },
    },
    projectNames: {
      label: 'Projects',
      format: (value: string) => {
        return this.projects.get(value) || value;
      },
    },
    products: {
      label: 'Products',
    },
    idVerified: {
      label: 'ID Verified',
      format: (value: string) => {
        try {
          const parsedValue = JSON.parse(value);
          const getTitle = (options, key) =>
            (options.find((item) => item.key === key && item.value === parsedValue[key]) || {}).title || '';
          let formattedValues = [];
          Object.keys(parsedValue || {}).forEach((key) => {
            const title =
              key === 'idVerified'
                ? getTitle(this.idVerificationOptions, key)
                : getTitle(this.idVerificationSubOptions, key);
            formattedValues.push(title);
          });
          return formattedValues.join(',');
        } catch (error) {
          console.error(`Initial value parsing failed, ${JSON.stringify(error)}`);
        }
      },
    },
  };

  isPriorityEditable: boolean;

  filters: Filter[] = [];

  isPublicationGuideline = false;

  isSearching = false;

  private sort = '-_id';

  @ViewChild(FiltersComponent, { static: false })
  private filterRef: FiltersComponent;

  private countries: Map<string, string> = new Map();

  private compilers: Map<string, string> = new Map();

  private categories: Map<string, string> = new Map();

  private projects: Map<string, string> = new Map();

  private creators: Map<string, string> = new Map();

  private idVerificationSubOptions = [
    { key: 'correct', title: 'Correct', value: true },
    { key: 'correct', title: 'Incorrect', value: false },
  ];
  private idVerificationOptions: SubMenuFilterValue[] = [
    {
      key: 'idVerified',
      title: 'ID Verified',
      displayValue: 'ID Verified',
      value: true,
      subOptions: this.idVerificationSubOptions,
    },
    { key: 'idVerified', title: 'ID UN-Verified', displayValue: 'ID Verified', value: false },
  ];

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly svcGuideline: GuidelineAPI,
    private readonly svcPublication: PublicationAPI,
    private readonly svcACL: ACL,
    private readonly svcValue: ValueAPI
  ) {
    this.onSearchGuideline = this.onSearchGuideline.bind(this);
  }

  ngOnInit(): void {
    this.isPublicationGuideline = this.router.routerState.snapshot.url.split('/')[1] === 'publication-guideline';

    this.filters.push(
      {
        title: 'Status',
        key: '_meta.status',
        type: FilterType.MULTI_VALUE,
        values: Object.keys(GuidelineStatus)
          .sort()
          .map((statusKey) => ({ title: GuidelineStatus[statusKey], displayValue: GuidelineStatus[statusKey] })),
      },
      {
        title: 'Publication Year',
        key: 'publicationDate',
        type: FilterType.CUSTOM,
        component: YearFilterComponent,
      },
      {
        title: 'ID Verified',
        key: 'idVerified',
        type: FilterType.CUSTOM,
        component: SubMenuFilterComponent,
        values: this.idVerificationOptions,
      },
      {
        title: 'Created',
        key: 'createdAt',
        type: FilterType.DATE_RANGE,
      },
      {
        title: 'Modified',
        key: 'updatedAt',
        type: FilterType.DATE_RANGE,
      },
      {
        title: 'Abstract Provided',
        key: 'abstract',
        type: FilterType.SINGLE_VALUE,
        values: [
          { title: 'true', displayValue: 'YES' },
          { title: 'false', displayValue: 'NO' },
        ],
      }
    );

    this.route.queryParams.pipe(take(1)).subscribe((params: { searchTerm?: string; sortField?: string }) => {
      if (params.searchTerm) {
        this.searchTerm = params.searchTerm;
      }
      if (params.sortField) {
        this.sort = params.sortField;
      }

      this.doLoad();
    });

    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((values) => {
      const filter = {
        title: 'Countries',
        key: 'journal.country',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: values.map((value) => ({ title: value.code as string, displayValue: value.title })),
      };

      this.filters = [...this.filters, filter]; // DO break reference when creating a filter from an Observable/Promise (otherwise change detection won't run)
      this.countries = new Map(values.map((value) => [value.code as string, value.title]));
    });

    this.svcGuideline.reviewers().subscribe((reviewers) => {
      const filter = {
        title: 'Compilers',
        key: 'compiler',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: reviewers.map((value) => {
          return {
            title: value.user_id,
            displayValue:
              value.user_metadata?.firstName && value.user_metadata?.lastName
                ? `${value.user_metadata?.firstName} ${value.user_metadata?.lastName}`
                : value.name,
          };
        }),
      };

      this.filters = [...this.filters, filter]; // DO break reference when creating a filter from an Observable/Promise (otherwise change detection won't run)
      this.compilers = new Map(
        reviewers.map((value) => {
          return [
            value.user_id,
            value.user_metadata?.firstName && value.user_metadata?.lastName
              ? `${value.user_metadata?.firstName} ${value.user_metadata?.lastName}`
              : value.name,
          ];
        })
      );
    });

    this.svcValue.find(ValueType.Category, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((values) => {
      const filter = {
        title: 'Category',
        key: 'category',
        type: FilterType.MULTI_VALUE,
        values: values.map((value) => ({ title: value.code as string, displayValue: value.title })),
      };

      this.filters = [...this.filters, filter]; // DO break reference when creating a filter from an Observable/Promise (otherwise change detection won't run)
      this.categories = new Map(values.map((value) => [value.code as string, value.title]));
    });

    this.svcValue.find(ValueType.Project, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((values) => {
      const filter = {
        title: 'Project',
        key: 'projectNames',
        type: FilterType.MULTI_VALUE,
        values: values.map((value) => ({ title: value.code as string, displayValue: value.title })),
      };

      this.filters = [...this.filters, filter]; // DO break reference when creating a filter from an Observable/Promise (otherwise change detection won't run)
      this.projects = new Map(values.map((value) => [value.code as string, value.title]));
    });

    this.svcValue.find(ValueType.Product, Number.MAX_SAFE_INTEGER, 0).subscribe((values) => {
      const filter = {
        title: 'Product',
        key: 'products',
        type: FilterType.MULTI_VALUE,
        values: values.map((value) => ({ title: value.value as string, displayValue: value.value })),
      };

      this.filters = [...this.filters, filter]; // DO break reference when creating a filter from an Observable/Promise (otherwise change detection won't run)
    });

    this.svcGuideline.creators().subscribe((creators) => {
      const filter = {
        title: 'Created By',
        key: 'identify.by',
        type: FilterType.MULTI_VALUE_FILTERABLE,
        values: creators.map((value) => {
          return {
            title: value.user_id,
            displayValue:
              value.user_metadata?.firstName && value.user_metadata?.lastName
                ? `${value.user_metadata?.firstName} ${value.user_metadata?.lastName}`
                : value.name,
          };
        }),
      };

      this.filters = [...this.filters, filter]; // DO break reference when creating a filter from an Observable/Promise (otherwise change detection won't run)
      this.creators = new Map(
        creators.map((value) => {
          return [
            value.user_id,
            value.user_metadata?.firstName && value.user_metadata?.lastName
              ? `${value.user_metadata?.firstName} ${value.user_metadata?.lastName}`
              : value.name,
          ];
        })
      );
    });

    this.isPriorityEditable =
      !this.isPublicationGuideline && this.svcACL.hasCredential('guideline.update.prop.priority');
  }

  onSort(field: string): void {
    this.sort = field;

    this.storeFiltersAsQueryParams();
    this.getGuidelines();
  }

  onFilter(filter: { [key: string]: any }): void {
    this.filter = filter;
    this.doLoad();
  }

  /** just avoid useless rendering if we can */
  guidelineTrackBy(index: number, guideline: Guideline): string {
    return guideline.id;
  }

  setPriority(guideline: Guideline, priority: number): void {
    if (
      this.isPublicationGuideline ||
      !this.isPriorityEditable ||
      !priority ||
      (guideline.priority && guideline.priority === priority)
    ) {
      return;
    }

    this.svcGuideline.update(guideline._id, { priority } as Guideline).subscribe(() => {});
  }

  onDelete(guidelineID: string): void {
    if (this.isPublicationGuideline || !this.svcACL.hasCredential('guideline.delete')) {
      return;
    }

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.svcGuideline.deleteById(guidelineID).subscribe(() => {
      this.getGuidelines();
      this.getGuidelinesCount();
    });
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getGuidelines();
  }

  onFilterClear(): void {
    this.filterRef.doClear();
  }

  onFilterValueRemove(item: { key: string; value: any }): void {
    this.filterRef.removeValue(item.key, item.value);
  }

  onSearchGuideline(term$: Observable<string>): Observable<Guideline[]> {
    return term$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }

        if (this.isPublicationGuideline) {
          return this.svcPublication.search(term).pipe(
            map((publications) =>
              publications.map((publication) => {
                return Object.assign(new Guideline(), publication);
              })
            ),
            catchError(() => of([]))
          );
        }

        return this.svcGuideline.search(term).pipe(catchError(() => of([])));
      }),
      tap(() => (this.isSearching = false))
    );
  }

  onSearchGuidelineWithReRender(): void {
    this.sort = undefined; // don't sort what ElasticSearch gave us by default
    this.doLoad();
  }

  navigateTo(id: string): void {
    if (!id) {
      return;
    }

    if (this.isPublicationGuideline) {
      this.router.navigate(['/publication-guideline/detail', id]);
    } else {
      this.router.navigate(['/guideline/detail', id]);
    }
  }

  private doLoad(): void {
    this.storeFiltersAsQueryParams();
    this.resetPagination();
    this.getGuidelines();
    this.getGuidelinesCount();
  }

  private storeFiltersAsQueryParams(): void {
    if (this.searchTerm || this.sort) {
      const filters = {
        ...(this.searchTerm && { searchTerm: this.searchTerm }),
        ...(this.sort && { sortField: this.sort }),
      };
      this.router.navigate([], { queryParams: filters });
    }
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getGuidelines(): void {
    this.isLoading = true;

    let observable: Observable<Guideline[]>;
    if (!this.isPublicationGuideline) {
      observable = this.svcGuideline.find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.filter);
    } else {
      observable = this.svcPublication
        .find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, {
          ...this.filter,
          types: ['GUIDELINE', 'PRACTICE GUIDELINE'],
        })
        .pipe(
          map((publications) =>
            publications.map((publication) => {
              return Object.assign(new Guideline(), publication);
            })
          )
        );
    }

    observable.subscribe(
      (guidelines) => {
        this.guidelines = guidelines.map((guideline) => {
          const publicationDate = new Date(guideline.publicationDate);
          guideline.publicationDate = !isNaN(publicationDate.getTime()) ? guideline.publicationDate : undefined;
          (guideline as any).authorsCount = guideline.authors?.length || 0;

          if (guideline.journal?.country) {
            guideline.journal.country = this.countries.get(guideline.journal.country) || guideline.journal.country;
          }

          return guideline;
        });

        this.isLoading = false;
      },
      () => {
        this.isLoading = false;
      }
    );
  }

  private getGuidelinesCount(): void {
    let observable: Observable<{ count: number }>;
    if (this.isPublicationGuideline) {
      observable = this.svcPublication.count(this.searchTerm, {
        ...this.filter,
        types: ['GUIDELINE', 'PRACTICE GUIDELINE'],
      });
    } else {
      observable = this.svcGuideline.count(this.searchTerm, this.filter);
    }

    observable.subscribe((count) => {
      this.total = count;
    });
  }
}
