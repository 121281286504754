import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { CommentListComponent } from './list/list.component';
import { CommentAPI } from './shared/api.service';
import { CommentCreateComponent } from './create/create.component';

@NgModule({
  imports: [SharedModule],
  declarations: [CommentListComponent, CommentCreateComponent],
  exports: [CommentListComponent, CommentCreateComponent],
  providers: [CommentAPI],
})
export class CommentModule {}
