<div class="di-count-down-container">
  <div class="di-time-part" *ngFor="let component of dateParts; let isLast = last">
    <div class="di-time-part-item">
      <span class="di-time-part-value">
        <span *ngFor="let char of (component.value < 10 ? '0' : '') + component.value | split">
          {{ char }}
        </span>
      </span>
      <span class="di-time-part-label" *ngIf="labels">{{ component.label }}</span>
    </div>
    <div *ngIf="!isLast" class="di-time-part-divider">
      <span>:</span>
    </div>
  </div>
</div>
