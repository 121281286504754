import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AffiliationMaintenanceJobComponent } from './affiliation-maintenance-job.component';

@Injectable()
export class AffiliationMaintenanceJobModalService {
  constructor(private svcModal: NgbModal) {}

  open(affiliationId: string, customerRequest?: boolean, requestOwnerProduct?: string): Promise<{ ok }> {
    const ref = this.svcModal.open(AffiliationMaintenanceJobComponent);
    ref.componentInstance.affiliationId = affiliationId;
    ref.componentInstance.isCustomerRequest = !!customerRequest;
    ref.componentInstance.requestOwnerProduct = requestOwnerProduct;
    return ref.result;
  }
}
