import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Sponsor, UpdateNormalizedNamesData } from '../shared/sponsor';
import { SponsorAPI } from '../shared/api.service';
import { SponsorFormComponent } from '../shared/form/form.component';
import { ACL } from '../../shared/acl/acl.service';

@Component({
  selector: 'dirt-sponsor-normalized-names',
  templateUrl: 'normalized-names.component.html',
})
export class SponsorNormalizedNamesComponent {
  sponsor: Sponsor = new Sponsor();
  isSubmitting = false;

  @ViewChild(SponsorFormComponent)
  sponsorForm: SponsorFormComponent;

  constructor(private router: Router, private svcSponsor: SponsorAPI, private svcAcl: ACL) {}

  onSubmit(data: UpdateNormalizedNamesData): void {
    if (!this.svcAcl.hasCredential('sponsor.update')) {
      return;
    }
    this.isSubmitting = true;
    this.svcSponsor.updateNormalizedNames(data).subscribe(() => {
      this.isSubmitting = false;
      alert('Normalized Name updated successfully');
      this.reloadPage();
    });
  }

  goBack(): void {
    this.router.navigate(['/sponsor']);
  }

  reloadPage(): void {
    window.location.reload();
  }
}
