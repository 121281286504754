import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PersonAPI } from '../shared/api.service';
import { Person } from '../shared/person';

@Component({
  selector: 'dirt-person-next',
  templateUrl: 'next.component.html',
  styleUrls: ['next.component.scss'],
})
export class PersonNextComponent implements OnInit {
  queue: any;
  drafts: Partial<Person>[];
  isLoadingNext: boolean = false;

  constructor(private router: Router, private svcPerson: PersonAPI) {}

  ngOnInit() {
    this.getQueue();
    this.getDrafts();
  }

  private getQueue() {
    this.svcPerson.queue().subscribe((q) => (this.queue = q));
  }

  private getDrafts() {
    this.svcPerson.drafts().subscribe((q) => (this.drafts = q.current));
  }

  getNext(evnt?, draft?: Partial<Person>): void {
    if (evnt) {
      evnt.preventDefault();
    }

    this.isLoadingNext = true;

    this.svcPerson.findNext(draft ? draft.id : undefined).subscribe(
      (person) => {
        if (!person || !person.id) {
          return alert('No people to be processed at the moment! Go and get some rest ;)');
        }

        this.router.navigate(['/person/detail', person.id]);
      },
      (err) => alert('Error!'),
      () => (this.isLoadingNext = false)
    );
  }
}
