<section class="dirt-section-create dirt-membership-create">
  <a class="dirt-btn-back btn btn-primary-outline btn-sm" [routerLink]="['/association/detail', associationId]">
    <span class="fa fa-arrow-left" aria-hidden="true"></span>
  </a>
  <h1 class="bounceInRight">Create Membership</h1>

  <br />
  <dirt-membership-form [membership]="membership" [isSubmitting]="isSubmitting" (onSubmitted)="onSubmit($event)">
  </dirt-membership-form>
</section>
