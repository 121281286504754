import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { PersonDuplicateTakeoverComponent } from './duplicate.component';

@Injectable()
export class PersonDuplicateTakeoverService {
  constructor(private svcModal: NgbModal) {}

  open(options: any): Promise<any> {
    const ref = this.svcModal.open(PersonDuplicateTakeoverComponent);

    ref.componentInstance.options = options;

    return ref.result;
  }
}
