<div class="modal-header">
  <h5 class="modal-title">{{ isEdit ? 'Edit Person' : 'Add Person' }}</h5>
</div>

<div class="modal-body">
  <ng-container *ngIf="!isEdit && !disablePersonForm">
    <input
      type="search"
      id="people-searchbar"
      class="di-search form-control ng-trim-ignore"
      [ngClass]="{ loading: isSearching }"
      [ngbTypeahead]="onSearchPeople"
      [focusFirst]="false"
      [resultTemplate]="searchResultTemplate"
      placeholder="Search..."
      [(ngModel)]="searchTerm"
      (selectItem)="onSelectPerson($event)"
    />

    <a href="javascript:void(0)" class="small pull-right pt-1 pr-2" (click)="onTogglePersonForm()"> Create Person </a>

    <br />
  </ng-container>
  <h6 *ngIf="!showPersonForm && selectedAccountPerson">
    <div [diMarkerWrapper]="selectedAccountPerson" class="clearfix">
      Person ID:
      <a
        [routerLink]="['/person/detail', selectedAccountPerson.person._id]"
        rel="noopener noreferrer"
        target="_blank"
        *diMarker="{ name: 'kolId', entity: 'account-person', area: 'ALL' }"
      >
        {{ selectedAccountPerson.person.kolId }}
      </a>
    </div>
  </h6>

  <ng-container *ngIf="selectedAccountPerson">
    <dirt-person-form-inline
      [model]="selectedAccountPerson.person"
      [disabledFields]="showPersonForm ? createDisabledFields : editDisabledFields"
      [displayCountry]="'MANDATORY'"
      [disabled]="disablePersonForm"
      [area]="'ALL'"
      [entity]="'account-person'"
      [needsQC]="selectedAccountPerson.status === 'ADDED - NEW'"
      (projectNamesChange)="projectNamesDidChange = true"
      (validityChange)="onFormValidityChange($event)"
    >
    </dirt-person-form-inline>
  </ng-container>

  <form #frmAdditional="ngForm">
    <div [diMarkerWrapper]="selectedAccountPerson" class="clearfix" *ngIf="selectedAccountPerson">
      <ng-container *ngIf="selectedAccountPerson">
        <!-- Request Maintenance-->
        <ng-template [diAcl]="'job.create'" *ngIf="mode === 'EDIT'">
          <ng-container *ngIf="!selectedAccountPerson.requestMaintenance; else maintenanceRequested">
            <div class="form-group">
              <button
                type="button"
                class="btn btn-sm btn-warning"
                [disabled]="selectedAccountPerson.requestMaintenance"
                (click)="onMaintenanceRequest()"
                *diMarker="{ name: 'requestMaintenance', entity: 'account-person', area: 'ALL' }"
              >
                Request Maintenance
              </button>
            </div>
          </ng-container>

          <ng-template #maintenanceRequested>
            <div class="form-group">
              <label class="col-form-label">Request Maintenance:</label>
              <select
                class="form-control custom-select"
                name="primaryResearch-method"
                [(ngModel)]="selectedAccountPerson.requestMaintenance"
                [disabled]="disablePersonForm"
                *diMarker="{ name: 'requestMaintenance', entity: 'account-person', area: 'ALL' }"
              >
                <option [ngValue]="null" disabled>Select option</option>
                <option
                  *ngFor="let option of accountPersonRequestMaintenance | keyvalue"
                  [value]="option.value"
                  [disabled]="option.value === 'requested'"
                >
                  {{ option.value }}
                </option>
              </select>
            </div>
          </ng-template>
        </ng-template>

        <!-- Inactive -->
        <div class="form-group">
          <label class="col-form-label">Inactive</label>
          <ui-switch
            size="small"
            class="ui-switch"
            color="#6d5cae"
            [checked]="selectedAccountPerson.inactive === true"
            (change)="onInactiveSwitchChange($event)"
            [disabled]="disablePersonForm"
            *diMarker="{ name: 'inactive', entity: 'account-person', area: 'ALL' }"
          >
          </ui-switch>
        </div>

        <!-- Job Details -->
        <div
          class="form-group"
          *ngFor="let _ of selectedAccountPerson.jobDetails; let idx = index; trackBy: trackByIndex"
        >
          <!-- Job title matrix cluster -->
          <div [diMarkerWrapper]="selectedAccountPerson.jobDetails[idx]">
            <label class="col-form-label" for="jobTitleMatrix-{{ idx }}">Job Title Matrix Cluster</label>
            <select
              class="form-control custom-select"
              name="jobTitleMatrix-{{ idx }}"
              [(ngModel)]="selectedAccountPerson.jobDetails[idx].matrix"
              [disabled]="disablePersonForm"
              *diMarker="{ name: 'jobDetails_matrix', entity: 'account-person', area: 'ALL' }"
              [required]="!selectedAccountPerson.inactive"
            >
              <option *ngFor="let matrix of jobTitleMatrix" [value]="matrix.code">
                {{ matrix.title }}
              </option>
            </select>

            <!-- Title -->
            <label class="col-form-label" for="jobTitle-{{ idx }}">Job Title</label>
            <input
              type="text"
              id="jobTitle-{{ idx }}"
              class="form-control"
              name="jobTitle-{{ idx }}"
              [disabled]="disablePersonForm"
              [(ngModel)]="selectedAccountPerson.jobDetails[idx].title"
              *diMarker="{ name: 'jobDetails_title', entity: 'account-person', area: 'ALL' }"
              [required]="!selectedAccountPerson.inactive"
            />

            <!-- Department name -->
            <label class="col-form-label" for="departmentName-{{ idx }}">Department Name</label>
            <input
              type="text"
              id="departmentName-{{ idx }}"
              class="form-control"
              name="departmentName-{{ idx }}"
              [disabled]="disablePersonForm"
              [(ngModel)]="selectedAccountPerson.jobDetails[idx].departmentName"
              *diMarker="{ name: 'jobDetails_departmentName', entity: 'account-person', area: 'ALL' }"
            />

            <!-- Button: Add -->
            <button
              type="button"
              class="btn btn-secondary"
              (click)="pushItemToList(selectedAccountPerson.jobDetails, true)"
              *ngIf="idx === selectedAccountPerson.jobDetails.length - 1"
            >
              <i class="fa fa-plus-circle"></i>
            </button>

            <!-- Button: Remove -->
            <button
              type="button"
              class="btn btn-secondary"
              (click)="removeFromByIndex(selectedAccountPerson.jobDetails, idx)"
              *ngIf="idx > 0"
            >
              <i class="fa fa-minus-circle"></i>
            </button>
          </div>
        </div>

        <!-- CV Links -->
        <div *diMarker="{ name: 'sourceUrls', entity: 'account-person', area: 'ALL' }">
          <div
            class="form-group"
            *ngFor="let _ of selectedAccountPerson.sourceUrls; let idx = index; trackBy: trackByIndex"
          >
            <label class="col-form-label" for="sourceUrls-{{ idx }}">{{
              idx === 0 ? 'Master Source Link' : 'Source Link'
            }}</label>
            <dirt-link
              [(url)]="selectedAccountPerson.sourceUrls[idx]"
              name="sourceUrls-{{ idx }}"
              [form]="frmAdditional"
              [disabled]="disablePersonForm"
              [required]="idx === 0"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(selectedAccountPerson.sourceUrls)"
                *ngIf="idx === selectedAccountPerson.sourceUrls.length - 1 && !disablePersonForm"
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(selectedAccountPerson.sourceUrls, idx)"
                *ngIf="idx > 0 && !disablePersonForm"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-outline-dark" [disabled]="isSearching" (click)="activeModal.dismiss()">Close</button>

  <ng-container *ngIf="!disablePersonForm">
    <ng-container *ngIf="!isEdit">
      <button
        class="btn btn-sm btn-success"
        [ngClass]="{ 'btn-loading': isSearching || isSaving }"
        [hidden]="!selectedAccountPerson"
        [disabled]="isSearching || !isFormValid || isSaving"
        (click)="onAddPerson()"
        *ngIf="!showPersonForm"
      >
        Add
      </button>
      <button
        class="btn btn-sm btn-success"
        [ngClass]="{ 'btn-loading': isSearching || isSaving }"
        [disabled]="isSearching || !isFormValid || isSaving"
        (click)="onRequestPerson()"
        *ngIf="showPersonForm"
      >
        Request & Add
      </button>
    </ng-container>

    <ng-container *ngIf="isEdit">
      <button
        class="btn btn-sm btn-success"
        [ngClass]="{ 'btn-loading': isSearching || isSaving }"
        [disabled]="isSearching || !isFormValid || isSaving"
        (click)="onEditPerson()"
      >
        Save
      </button>
    </ng-container>
  </ng-container>
</div>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div class="di-sac-kol-id pull-right">{{ r.kolId }}</div>
  <div class="di-sac-name">
    <span>{{ r.firstName }} {{ r.middleName }} {{ r.lastName }}</span>
    <span *ngIf="r.originalFullName">({{ r.originalFullName }})</span>
    <span class="di-sac-status">{{ r._meta?.status || '-' | capitalize }}</span>
  </div>

  <div class="di-sac-affiliation-name" *ngIf="r.affiliationName">
    {{ r.affiliationName }}
  </div>
</ng-template>
