import { Directive, Input } from '@angular/core';
import { set, get } from 'lodash';

@Directive({
  selector: '[dirtTrainingEvaluationMarkerWrapper]',
})
export class TrainingEvaluationMarkerWrapperDirective {
  @Input()
  set dirtTrainingEvaluationMarkerWrapper(baseModel: any) {
    if (typeof baseModel === 'undefined') {
      throw new Error('Invalid model for marker wrapper!');
    }

    this.baseModel = baseModel;
  }

  private baseModel: { [key: string]: any };

  @Input()
  set diMarkerWrapper(model: any) {
    if (typeof model === 'undefined') {
      throw new Error('Invalid model for marker wrapper!');
    }

    this.model = model;
  }

  private model: { [key: string]: any };
  constructor() {}

  isTrainingPerson() {
    return this.model && this.model.training;
  }

  isPersonInDoneState() {
    return this.model && this.model._meta.status === 'DONE';
  }

  hasValidBaseValue() {
    return !!(this.baseModel && this.baseModel.id);
  }

  getBaseValue(fieldName: string) {
    return this.baseModel && get(this.baseModel, fieldName);
  }

  getValue(fieldName: string) {
    return this.model && get(this.model, fieldName);
  }

  getReport(fieldName: string) {
    return (
      this.model &&
      this.model.trainingEvaluationReport &&
      this.model.trainingEvaluationReport.reportDetails &&
      get(this.model.trainingEvaluationReport.reportDetails, fieldName)
    );
  }

  getReportCompilerId() {
    return this.model && this.model.trainingEvaluationReport && this.model.trainingEvaluationReport.compilerId;
  }

  updateReport(
    fieldName: string,
    match?: boolean,
    markAsCorrect?: boolean,
    fieldErrorRate?: { [key: string]: number }
  ): void {
    const reportDetails =
      (this.model.trainingEvaluationReport && this.model.trainingEvaluationReport.reportDetails) || {};
    set(reportDetails, fieldName, get(reportDetails, fieldName, {}));

    if (match !== undefined) {
      set(reportDetails, `${fieldName}.isCorrect`, match);
    }

    if (markAsCorrect !== undefined) {
      set(reportDetails, `${fieldName}.markAsCorrect`, markAsCorrect);
    }

    if (fieldErrorRate !== undefined) {
      set(reportDetails, `${fieldName}.fieldErrorRate`, { ...fieldErrorRate });
    }

    Object.assign(this.model, {
      trainingEvaluationReport: {
        ...this.model.trainingEvaluationReport,
        reportDetails: reportDetails,
      },
    });
  }
}
