import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxPaginationModule } from 'ngx-pagination';
import { TagInputModule } from 'ngx-chips';
import { LinkyModule } from 'angular-linky';
import { UiSwitchModule } from 'ngx-ui-switch';

import { AclModule } from './acl/acl.module';
import { DirtComponentsModule } from './components';
import { DirtModalsModule } from './modals/';
import { DirtDirectivesModule } from './directives';
import { DirtValidatorsModule } from './validators';
import { DirtPipesModule, CapitalizePipe, ProductLabelPipe } from './pipes';
import { NameSplitService } from './services/name-split/name-split.service';
import { InputErrorMsgModule } from './input-error-msg/input-error-msg.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ValueAPI } from './services/value/value-api.service';
import { PersonAffiliationModalService } from './services/modal.service';
import { TranslateAPI } from './services/translate/translate.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TrimValueAccessorDirectiveModule } from './vendor/ng-trim-value-accessor/module';
import { DomainAPI } from '../domain/shared/domain-api.service';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot([]),
    NgbModule,
    NgxPaginationModule,
    TagInputModule,
    LinkyModule,
    UiSwitchModule,
    TrimValueAccessorDirectiveModule,
    /*** DiRT Modules ***/
    AclModule,
    DirtPipesModule,
    DirtComponentsModule,
    DirtModalsModule,
    DirtDirectivesModule,
    DirtValidatorsModule,
    InputErrorMsgModule,
    DragDropModule,
  ],
  providers: [
    TitleCasePipe,
    NameSplitService,
    ValueAPI,
    PersonAffiliationModalService,
    TranslateAPI,
    CapitalizePipe,
    ProductLabelPipe,
    DomainAPI,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgxPaginationModule,
    TagInputModule,
    LinkyModule,
    UiSwitchModule,
    TrimValueAccessorDirectiveModule,
    /*** DiRT Modules & Directives ***/
    AclModule,
    DirtPipesModule,
    DirtComponentsModule,
    DirtDirectivesModule,
    DirtValidatorsModule,
    InputErrorMsgModule,
    BrowserAnimationsModule,
    DragDropModule,
  ],
})
export class SharedModule {}
