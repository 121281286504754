import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ProfileAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-profile-next',
  templateUrl: 'next.component.html',
  styleUrls: ['next.component.scss'],
})
export class ProfileNextComponent implements OnInit {
  queue: any;
  isLoadingNext = false;

  constructor(private router: Router, private svcProfile: ProfileAPI) {}

  ngOnInit() {
    this.getQueue();
  }

  getQueue() {
    this.svcProfile.queue().subscribe((q) => (this.queue = q));
  }

  getNext(): void {
    this.isLoadingNext = true;

    this.svcProfile.findNext().subscribe(
      (profile) => {
        if (!profile || !profile.id) {
          return alert('No Profiles to be processed at the moment! Go and get some rest ;)');
        }

        this.router.navigate(['/profile/detail', profile.id]);
      },
      () => alert('Error!'),
      () => (this.isLoadingNext = false)
    );
  }
}
