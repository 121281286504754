<section class="dirt-section-detail">
  <header>
    <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
      <span class="fa fa-arrow-left" aria-hidden="true"></span>
    </button>

    <h1 class="bounceInRight" style="display: inline-block">CT Profile {{ mainId }}</h1>
    <span style="margin-left: 30px">
      <a [href]="ctLink" target="_blank" rel="noopener">Open source CT</a>
    </span>

    <span style="float: right" *ngIf="canAdhocQc()">
      <button class="btn btn-secondary btn-sm" (click)="startAdhocQc()">Ad-hoc QC</button>
    </span>
  </header>

  <!-- Middle -->
  <div *ngIf="baseTrial" class="row di-info-footer trialInfo">
    <div class="di-info-footer-sidebyside">
      <div *ngIf="baseTrial.title">
        <span class="di-label di-bold text-left">Title:</span>
        <span>{{ baseTrial.title }}</span>
      </div>
      <br />
      <div *ngIf="baseTrial.conditions.length > 0">
        <span class="di-label di-bold text-left">Conditions:</span>
        <span *ngFor="let condition of visibleConditions; last as isLast">
          <span>{{ condition }}</span>
          <span [hidden]="isLast">|</span>
        </span>
        <span *ngIf="baseTrial.conditions.length > 10">
          <button (click)="toggleUnhideConditions()">{{ hideConditions ? 'Show More' : 'Hide' }}</button>
        </span>
      </div>
      <br />
      <div *ngIf="baseTrial.locations">
        <span class="di-label di-bold text-left">Countries:</span>
        <span *ngFor="let country of visibleCountries | distinct; last as isLast">
          <span>{{ country }}</span>
          <span [hidden]="isLast">|</span>
        </span>
        <span *ngIf="baseTrial.locations.length > 10">
          <button (click)="toggleUnhideCountries()">{{ hideCountries ? 'Show More' : 'Hide' }}</button>
        </span>
      </div>
      <br />
      <div *ngIf="clinicalTrialFocusAreas.length > 0">
        <span class="di-label di-bold text-left">Focus Areas:</span>
        <span *ngFor="let focusArea of visibleClinicalTrialFocusAreas; last as isLast">
          <span>{{ focusArea }}</span>
          <span [hidden]="isLast">|</span>
        </span>
        <span *ngIf="clinicalTrialFocusAreas.length > 10">
          <button (click)="toggleUnhideFAs()">{{ hideClinicalTrialFocusAreas ? 'Show More' : 'Hide' }}</button>
        </span>
      </div>
    </div>
    <br />
  </div>

  <p class="dirt-loading" [hidden]="!isLoadingAll">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>

  <p *ngIf="!isLoadingAll && hasJobForCurrentUser()">
    Job: {{ profileJob?.type + (profileJob?.hint ? ' - ' + profileJob.hint : '') }}
  </p>

  <div class="custom-tab-panel" *ngIf="!isLoadingAll">
    <div class="custom-tab-header" [ngClass]="{ 'tab-active': 'MAIN' === tab }" (click)="tab = 'MAIN'">Main</div>
    <div
      *ngIf="canSeePeople()"
      class="custom-tab-header"
      [ngClass]="{ 'tab-active': 'PPL' === tab }"
      (click)="onClickInvestigatorsTab()"
    >
      People
    </div>
    <div
      *ngIf="canSeeSites()"
      class="custom-tab-header"
      [ngClass]="{ 'tab-active': 'SITES' === tab }"
      (click)="onClickSitesTab()"
    >
      Sites
    </div>
    <div
      *ngIf="canSeeAudit()"
      class="custom-tab-header"
      [ngClass]="{ 'tab-active': 'AUDIT' === tab }"
      (click)="tab = 'AUDIT'"
    >
      Audit
    </div>
    <div
      *ngIf="canSeeComment()"
      class="custom-tab-header"
      [ngClass]="{ 'tab-active': 'COMMENT' === tab }"
      (click)="tab = 'COMMENT'"
    >
      Comments
    </div>
  </div>

  <div class="ordered-form" *ngIf="!isLoadingAll && 'MAIN' === tab">
    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Summary title</div>
      <div class="ordered-form-main ordered-form-fyi">{{ baseTrial.title }}</div>
    </div>

    <div class="ordered-form-row" *ngIf="baseTrial.originalTitle">
      <div class="ordered-form-left-label">Original Summary title</div>
      <div class="ordered-form-main ordered-form-fyi">{{ baseTrial.originalTitle }}</div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Official title</div>
      <div class="ordered-form-main ordered-form-fyi">{{ baseTrial.officialTitle }}</div>
    </div>

    <div class="ordered-form-row" *ngIf="baseTrial.originalOfficialTitle">
      <div class="ordered-form-left-label">Original Official title</div>
      <div class="ordered-form-main ordered-form-fyi">{{ baseTrial.originalOfficialTitle }}</div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Summary</div>
      <div class="ordered-form-main ordered-form-fyi">{{ baseTrial.summary }}</div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Phase</div>
      <div class="ordered-form-main ordered-form-fyi">{{ baseTrial.phase || '-' }}</div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Overall status</div>
      <div class="ordered-form-main ordered-form-fyi">{{ baseTrial.overallStatus || '-' }}</div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Study type</div>
      <div class="ordered-form-main ordered-form-fyi">{{ baseTrial.studyType || '-' }}</div>
    </div>

    <!-- responsible party is not yet captured -->
    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Sponsors</div>
      <div class="ordered-form-main ordered-form-fyi" *ngIf="!baseTrial.sponsors?.length">-</div>
      <div class="ordered-form-main ordered-form-fyi" *ngIf="baseTrial.sponsors?.length">
        <div *ngFor="let sp of baseTrial.sponsors">
          {{ sp.name || sp.originalName }} ({{ sp.type }}{{ sp['class'] ? ', ' + sp['class'] : '' }})
        </div>
      </div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">External IDs</div>
      <div class="ordered-form-main">
        <div *ngFor="let eid of profile.externalIds; let i = index">
          <span>{{ eid.value }}</span>
        </div>
        <div *ngIf="!eidAdd && canUpdateCT()">
          <a class="alink" (click)="eidAdd = true">Add another</a>
        </div>
        <div *ngIf="eidAdd">
          <label
            ><input
              type="text"
              class="form-control form-control-sm"
              [(ngModel)]="eidNew"
              (keydown.enter)="addExternalId()"
              (keydown.escape)="eidAdd = false"
          /></label>
          <a class="alink" (click)="addExternalId()">Add</a>
          <small><a class="alink" (click)="eidAdd = false">Cancel</a></small>
        </div>
      </div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Allocation</div>
      <div class="ordered-form-main ordered-form-fyi">{{ baseTrial.studyDesign?.allocation || '-' }}</div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Intervention model</div>
      <div class="ordered-form-main ordered-form-fyi">{{ baseTrial.studyDesign?.interventionModel || '-' }}</div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Purpose</div>
      <div class="ordered-form-main ordered-form-fyi">{{ baseTrial.studyDesign?.primaryPurpose || '-' }}</div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Masking</div>
      <div class="ordered-form-main ordered-form-fyi">{{ baseTrial.studyDesign?.masking || '-' }}</div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Healthy voluntieers</div>
      <div class="ordered-form-main ordered-form-fyi">
        {{
          true === baseTrial.eligibility?.healthyVolunteers
            ? 'Yes'
            : false === baseTrial.eligibility?.healthyVolunteers
            ? 'No'
            : '-'
        }}
      </div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Gender</div>
      <div class="ordered-form-main ordered-form-fyi">{{ baseTrial.eligibility?.gender || '-' }}</div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Minimum age</div>
      <div class="ordered-form-main ordered-form-fyi">
        {{ isNil(baseTrial.eligibility?.minimumAge?.value) ? '-' : baseTrial.eligibility.minimumAge.value }}
        {{ baseTrial.eligibility?.minimumAge?.unit || '' }}
      </div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Maximum age</div>
      <div class="ordered-form-main ordered-form-fyi">
        {{ isNil(baseTrial.eligibility?.maximumAge?.value) ? '-' : baseTrial.eligibility.maximumAge.value }}
        {{ baseTrial.eligibility?.maximumAge?.unit || '' }}
      </div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Conditions or disease</div>
      <div class="ordered-form-main ordered-form-fyi" *ngIf="!baseTrial.conditions?.length">-</div>
      <div class="ordered-form-main ordered-form-fyi" *ngIf="baseTrial.conditions?.length">
        <div *ngFor="let c of baseTrial.conditions">{{ c }}</div>
      </div>
    </div>

    <div class="ordered-form-row" *ngFor="let trialDate of trialDates">
      <div class="ordered-form-left-label">{{ trialDate.caption }} <small>(date)</small></div>
      <div class="ordered-form-main">
        <input type="date" name="trialDate.field" value="{{ trialDate.value }}" readonly />
      </div>
    </div>

    <div class="ordered-form-row" *ngFor="let dt of allDatesAbove">
      <div class="ordered-form-left-label">{{ dt.caption }} <small>(date)</small></div>
      <div class="ordered-form-main" [diMarkerWrapper]="profile">
        <dirt-input-value-date
          *diMarker="{
            name: dt.field,
            area: 'ALL',
            entity: isQcJob() ? 'clinicalTrialProfile' : 'noop',
            leftAlign: true
          }"
          [name]="dt.field"
          [value]="profile[dt.field]"
          (valueEdited)="$event ? (profile[dt.field].status = statusEnum.Keep) : null"
          [disabled]="true"
          [validateDate]="getValidateFunction(dt.field)"
        >
        </dirt-input-value-date>
      </div>
      <div class="ordered-form-right-label">
        <label [ngClass]="{ 'todo-open': profile[dt.field]?.todo }"
          ><input
            type="checkbox"
            [disabled]="true"
            [checked]="!profile[dt.field]?.todo"
            (input)="onSetFieldDone(profile, dt.field, $event.target.checked)"
          />
          done</label
        >
      </div>
    </div>

    <div class="ordered-form-row">
      <div class="ordered-form-left-label">Links</div>
      <div class="ordered-form-main" [diMarkerWrapper]="profile">
        <div
          *diMarker="{
            name: 'links',
            area: 'ALL',
            entity: isQcJob() ? 'clinicalTrialProfile' : 'noop',
            leftAlign: true
          }"
        >
          <!-- Default Link -->
          <dirt-link
            [(url)]="ctLink"
            name="baseLinks-default"
            [form]="siteLinkForm"
            [disabled]="true"
            *ngIf="!!ctLink"
            [disabled]="!canUpdateCT()"
          >
            <button
              type="button"
              class="btn btn-secondary"
              (click)="pushItemToList(profile.baseLinks)"
              *ngIf="!profile.baseLinks?.length"
              [disabled]="!canUpdateCT()"
            >
              <i class="fa fa-plus-circle"></i>
            </button>
          </dirt-link>

          <!-- Manual Links -->
          <ng-container *ngFor="let baseLink of profile.baseLinks; let idx = index; trackBy: trackByIndex">
            <dirt-link
              [(url)]="profile.baseLinks[idx]"
              name="baseLinks-{{ idx }}"
              [form]="siteLinkForm"
              [readonly]="0 === idx && !!ctLink"
              [disabled]="!canUpdateCT()"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(profile.baseLinks)"
                *ngIf="idx == profile.baseLinks.length - 1"
                [disabled]="!canUpdateCT()"
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(profile.baseLinks, idx)"
                [disabled]="!canUpdateCT()"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="ordered-form-row" *ngFor="let dt of allDatesBelow">
      <div class="ordered-form-left-label">{{ dt.caption }} <small>(date)</small></div>
      <div class="ordered-form-main" [diMarkerWrapper]="profile">
        <dirt-input-value-date
          *diMarker="{
            name: dt.field,
            area: 'ALL',
            entity: isQcJob() ? 'clinicalTrialProfile' : 'noop',
            leftAlign: true
          }"
          [name]="dt.field"
          [value]="profile[dt.field]"
          (valueEdited)="$event ? (profile[dt.field].status = statusEnum.Keep) : null"
          [validateDate]="getValidateFunction(dt.field)"
        >
        </dirt-input-value-date>
      </div>
      <div class="ordered-form-right-label">
        <label [ngClass]="{ 'todo-open': profile[dt.field]?.todo }"
          ><input
            type="checkbox"
            [checked]="!profile[dt.field]?.todo"
            (input)="onSetFieldDone(profile, dt.field, $event.target.checked)"
          />
          done</label
        >
      </div>
    </div>
  </div>

  <div class="ordered-form" [hidden]="!(!isLoading && 'PPL' === tab)">
    <p class="dirt-loading" [hidden]="!isLoadingInvestigators">
      <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
      <span class="sr-only">Loading...</span>
    </p>

    <ng-container #investigatorsContainer></ng-container>

    <ng-template #investigatorRow let-mapping="mapping" let-i="i" let-total="total">
      <div class="ordered-form-row" [ngClass]="{ 'border-top-row': i > 0, 'is-greyed': isGreyedStatus(mapping) }">
        <div class="ordered-form-left-label">{{ 0 === i ? 'Investigators' : '' }}</div>

        <div class="ordered-form-main" [diMarkerWrapper]="mapping">
          <div
            class="ordered-form-fyi"
            [title]="mapping.noLongerInSource ? 'No longer in source' : ''"
            [ngClass]="{ strikethrough: mapping.noLongerInSource }"
          >
            {{ i + 1 + '/' + total }}:
            {{
              baseTrialInvestigators[mapping.srcText]?.caption ||
                mapping.srcName ||
                (mapping.manualAddNo > 0 ? 'Manually added #' + mapping.manualAddNo : null) ||
                '(no name from source)'
            }}
            {{ mapping.notInSource ? '(no name from source)' : '' }}
            {{ mapping.autoMappingId ? ' (copied from Person-CT-Mapping) (verified match)' : '' }}
          </div>

          <label
            class="widelabel"
            *diMarker="{
              name: 'person',
              area: 'ALL',
              entity: isQcJob() ? 'clinicalTrialProfile' : 'noop',
              leftAlign: true
            }"
            >Person
            <dirt-investigator-single-select
              [wide]="true"
              [doLoadAdditional]="!isLoadingAll"
              [existPersonInfos]="persons"
              [possibleCvLinks]="[ctLink]"
              [(kolId)]="mapping.kolId"
              [bestGuessInput]="baseTrialInvestigators[mapping.srcText]?.inv.name || null"
              [createAsOnHold]="true"
              [newPersonDefaultSource]="'TRIALS'"
              [newPersonDefaultProjects]="['CT_CURATION']"
              [disableDisabledCountries]="true"
              [qcSessionId]="profileJob?.qcSessionId || null"
              (personOpened)="personsOpened.push(mapping.kolId)"
              [autoMappingId]="mapping.autoMappingId"
              (kolIdChange)="
                populatePersonSite($event, baseTrialInvestigators[mapping.srcText]?.caption || mapping.srcName)
              "
              [disabled]="!canUpdatePeople() || !!mapping.autoMappingId"
            ></dirt-investigator-single-select
          ></label>
          <br />

          <div *ngFor="let role of mapping.roles; let ii = index" style="margin-bottom: -8px">
            <div
              *diMarker="{
                name: 'role',
                area: 'ALL',
                entity: isQcJob() ? 'clinicalTrialProfile' : 'noop',
                leftAlign: true
              }"
            >
              <label
                ><span style="display: inline-block; width: 55px">{{ 0 === ii ? 'Role(s)' : '' }}&#160;</span
                ><select [disabled]="!canUpdatePeople()" class="form-control form-control-sm" [(ngModel)]="role.role">
                  <option [ngValue]="null"></option>
                  <option *ngFor="let ro of roles" [value]="ro.code" [disabled]="roleIsAlreadySelected(ro, mapping)">
                    {{ ro.value || ro.code }}
                  </option>
                </select></label
              >
              <label *ngIf="'OTHERS' === role.role"
                ><input
                  type="text"
                  class="form-control form-control-sm"
                  [(ngModel)]="role.roleOther"
                  placeholder="spec. other"
              /></label>
              <small *ngIf="ii > 0 && canUpdatePeople()"
                ><a class="alink" (click)="removeRole(mapping, ii)">Remove</a></small
              >
            </div>
          </div>

          <div *ngIf="canUpdatePeople()" style="margin-bottom: 8px">
            <span style="display: inline-block; width: 55px">&#160;</span
            ><a class="alink" (click)="mapping.roles.push({ role: null })">Another role</a>
          </div>

          <small *ngIf="statusEnum.Auto !== mapping.status">
            <label class="checkbox-discard"
              ><input
                type="checkbox"
                [checked]="statusEnum.Discard === mapping.status"
                (input)="onSetDiscard(mapping, $event.target.checked)"
                [disabled]="!canUpdatePeople()"
              />
              Discard</label
            >
          </small>
        </div>

        <div class="ordered-form-right-label">
          <label [ngClass]="{ 'todo-open': mapping.todo }"
            ><input
              type="checkbox"
              [checked]="!mapping.todo"
              (input)="onSetDone(mapping, $event.target.checked)"
              [disabled]="!canUpdatePeople()"
            />
            done</label
          >
          <label
            class="checked-open"
            [ngClass]="{ needed: getQcForProp(mapping, 'lineVerified').needed }"
            *ngIf="isQcJob()"
            ><input
              type="checkbox"
              [disabled]="!getQcForProp(mapping, 'lineVerified').needed || !canUpdatePeople()"
              [checked]="getQcForProp(mapping, 'lineVerified').checked"
              (input)="setQcVerified(mapping, 'lineVerified', $event)"
            />
            verified</label
          >
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="!isLoading">
      <div class="ordered-form-row">
        <div class="ordered-form-left-label">{{ !profile.personMappings?.length ? 'Investigators' : '' }}</div>
        <div *ngIf="canUpdatePeople()" class="ordered-form-main" style="text-align: right">
          <a class="alink" (click)="onAddInvestigator()">Add another investigator</a>
        </div>
        <div class="ordered-form-right-label"><!--empty--></div>
      </div>

      <div class="ordered-form-row">
        <div class="ordered-form-left-label">Additional sources</div>
        <div class="ordered-form-main">
          <!-- Default Link -->
          <dirt-link [(url)]="ctLink" name="baseLinks-default" [form]="siteLinkForm" [disabled]="true" *ngIf="!!ctLink">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="pushItemToList(profile.baseLinks)"
              *ngIf="!profile.baseLinks?.length"
              disabled="!canUpdatePeople()"
            >
              <i class="fa fa-plus-circle"></i>
            </button>
          </dirt-link>

          <!-- Manual Links -->
          <ng-container *ngFor="let baseLink of profile.baseLinks; let idx = index; trackBy: trackByIndex">
            <dirt-link
              [(url)]="profile.baseLinks[idx]"
              name="baseLinks-{{ idx }}"
              [form]="siteLinkForm"
              [readonly]="0 === idx && !!ctLink"
              [disabled]="!canUpdatePeople()"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(profile.baseLinks)"
                *ngIf="idx == profile.baseLinks.length - 1"
                disabled="!canUpdatePeople()"
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(profile.baseLinks, idx)"
                disabled="!canUpdatePeople()"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="ordered-form" [hidden]="!(!isLoading && 'SITES' === tab)">
    <div class="tab-header">
      <button class="btn btn-secondary" (click)="onOpenInvestigatorsSitesList()">Open investigators sites list</button>
    </div>

    <form #ngFormMatch="ngForm" novalidate>
      <p class="dirt-loading" [hidden]="!isLoadingSites">
        <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
        <span class="sr-only">Loading...</span>
      </p>

      <ng-container #sitesContainer></ng-container>

      <ng-template #siteRow let-mapping="mapping" let-i="i" let-total="total">
        <div class="ordered-form-row" [ngClass]="{ 'border-top-row': i > 0, 'is-greyed': isGreyedStatus(mapping) }">
          <div class="ordered-form-left-label">{{ 0 === i ? 'Sites' : '' }}</div>

          <div class="ordered-form-main" [diMarkerWrapper]="mapping">
            <div
              class="ordered-form-fyi"
              [title]="mapping.noLongerInSource ? 'No longer in source' : ''"
              [ngClass]="{ strikethrough: mapping.noLongerInSource }"
            >
              {{ i + 1 + '/' + total }}:
              {{
                baseTrialFacilities[mapping.srcText]?.caption ||
                  mapping.srcText ||
                  (mapping.manualAddNo > 0 ? 'Manually added #' + mapping.manualAddNo : null) ||
                  '(no info from source)'
              }}
            </div>

            <label
              class="widelabel"
              *diMarker="{
                name: 'site',
                area: 'ALL',
                entity: isQcJob() ? 'clinicalTrialProfile' : 'noop',
                leftAlign: true
              }"
              >Site
              <dirt-site-single-select
                [wide]="true"
                [doLoadAdditional]="!isLoadingAll"
                [existSites]="sites"
                [(siteId)]="mapping.site"
                [disabled]="!canUpdateSites()"
              ></dirt-site-single-select
            ></label>

            <span *ngIf="mapping.foundMatches?.length">
              <strong>Suggestions: </strong><br />
              <table
                class="matches-table"
                *diMarker="{
                  name: 'suggestion',
                  area: 'ALL',
                  entity: isQcJob() ? 'clinicalTrialProfile' : 'noop',
                  leftAlign: true
                }"
              >
                <tr *ngFor="let match of mapping.foundMatches; let ii = index">
                  <td>
                    {{ determineMatchCaption(match) }}
                    <a target="_blank" [routerLink]="['/affiliation/detail', match.affiliation]" title="open in new tab"
                      ><i class="fa fa-external-link"></i
                    ></a>

                    <ng-container *ngIf="match.fromInvestigators"> (From Investigator matching) </ng-container>

                    <ng-container *ngIf="!match.fromInvestigators">
                      (Auto-{{ match.match.automate | capitalize
                      }}{{ isRecentlyAddedMatch(match) ? ', last 24h' : '' }})
                      {{ isFromBaseDataAffiliation(match) }}
                    </ng-container>
                  </td>
                  <td>
                    <label style="color: darkgreen"
                      ><input
                        type="radio"
                        [name]="'siteFoundMatch-' + i + '-' + ii"
                        [value]="'MATCH'"
                        [(ngModel)]="match.match.manual"
                        (ngModelChange)="onMatchConfirm(mapping, match)"
                      />
                      Match</label
                    >
                    <label style="color: darkred"
                      ><input
                        type="radio"
                        [disabled]="!canUpdateSites()"
                        [name]="'siteFoundMatch-' + i + '-' + ii"
                        [value]="'MISMATCH'"
                        [(ngModel)]="match.match.manual"
                        (ngModelChange)="onMatchConfirm(mapping, match)"
                      />
                      No match</label
                    >
                  </td>
                </tr>
              </table>
            </span>

            <div
              *diMarker="{
                name: 'status',
                area: 'ALL',
                entity: isQcJob() ? 'clinicalTrialProfile' : 'noop',
                leftAlign: true
              }"
            >
              <label
                ><input
                  type="checkbox"
                  name="notFound-{{ i }}"
                  [disabled]="!canUpdateSites()"
                  [(ngModel)]="mapping.notFound"
                  (ngModelChange)="clearSite(mapping, $event, true)"
                />
                Ins/No Info</label
              >
              <label
                ><input
                  type="checkbox"
                  name="almostMatch-{{ i }}"
                  [disabled]="!canUpdateSites()"
                  [(ngModel)]="mapping.almostMatch"
                  (ngModelChange)="clearMatchReasons(mapping)"
                />
                Almost-match</label
              >
              <label *ngIf="mapping.almostMatch">
                - reason
                <select
                  class="form-control form-control-sm"
                  name="almostMatchReason-{{ i }}"
                  [(ngModel)]="mapping.almostMatchReason"
                  [required]="true"
                >
                  <option [ngValue]="null">-- Reason --</option>
                  <option *ngFor="let aar of siteAlmostReasons" [value]="aar.code" [disabled]="aar.disabled">
                    {{ aar.value || aar.code }}
                  </option>
                </select>
              </label>
              <label
                ><input
                  type="checkbox"
                  name="notInScope-{{ i }}"
                  [disabled]="!canUpdateSites()"
                  [(ngModel)]="mapping.notInScope"
                  (ngModelChange)="clearSite(mapping, $event)"
                />
                Not in scope</label
              >
              <label
                ><input
                  type="checkbox"
                  name="unableToMatch-{{ i }}"
                  [disabled]="!canUpdateSites()"
                  [(ngModel)]="mapping.unableToMatch"
                  (ngModelChange)="clearSite(mapping, $event)"
                />
                Unable to match</label
              >
              <label *ngIf="mapping.unableToMatch">
                - reason
                <select
                  class="form-control form-control-sm"
                  name="unableToMatchReason-{{ i }}"
                  [(ngModel)]="mapping.unableToMatchReason"
                  [required]="true"
                >
                  <option [ngValue]="null">-- Reason --</option>
                  <option *ngFor="let uar of siteUnableReasons" [value]="uar.code" [disabled]="uar.disabled">
                    {{ uar.value || uar.code }}
                  </option>
                </select>
              </label>
              <label
                ><input
                  type="checkbox"
                  name="updatedLocationName-{{ i }}"
                  [disabled]="!canUpdateSites()"
                  [(ngModel)]="mapping.updatedLocationName"
                  (ngModelChange)="addProoflink(mapping, $event)"
                />
                Updated location name</label
              >
            </div>

            <div *ngIf="!(mapping.siteLinks?.length > 0) && canUpdateSites()" style="margin-bottom: 10px">
              <a class="alink" (click)="addProoflink(mapping, true)">Add proof link for site</a>
            </div>
            <div *ngIf="mapping.siteLinks?.length > 0" style="margin-bottom: 10px">
              Links
              <br />

              <!-- Don't pull the full non compliant domain list in a loop at least -->
              <ng-container *ngFor="let siteLink of mapping.siteLinks; let idx = index; trackBy: trackByIndex">
                <dirt-link
                  [(url)]="mapping.siteLinks[idx]"
                  name="mapping-{{ i }}-siteLinks-{{ idx }}"
                  [form]="siteLinkForm"
                  [disabled]="!canUpdateSites()"
                >
                  <button
                    type="button"
                    class="btn btn-secondary"
                    [disabled]="!canUpdateSites()"
                    (click)="pushItemToList(mapping.siteLinks)"
                    *ngIf="idx == mapping.siteLinks.length - 1"
                  >
                    <i class="fa fa-plus-circle"></i>
                  </button>

                  <button
                    type="button"
                    class="btn btn-secondary"
                    [disabled]="!canUpdateSites()"
                    (click)="removeFromByIndex(mapping.siteLinks, idx)"
                  >
                    <i class="fa fa-minus-circle"></i>
                  </button>
                </dirt-link>
              </ng-container>
            </div>

            <small *ngIf="statusEnum.Auto !== mapping.status">
              <label class="checkbox-discard"
                ><input
                  type="checkbox"
                  [disabled]="!canUpdateSites()"
                  [checked]="statusEnum.Discard === mapping.status"
                  (input)="onSetDiscard(mapping, $event.target.checked)"
                />
                Discard</label
              >
            </small>
          </div>

          <div class="ordered-form-right-label">
            <label [ngClass]="{ 'todo-open': mapping.todo }"
              ><input
                type="checkbox"
                [disabled]="!canUpdateSites()"
                [checked]="!mapping.todo"
                (input)="onSetDone(mapping, $event.target.checked)"
              />
              done</label
            >
            <label
              class="checked-open"
              [ngClass]="{ needed: getQcForProp(mapping, 'lineVerified').needed }"
              *ngIf="isQcJob()"
              ><input
                type="checkbox"
                [disabled]="!getQcForProp(mapping, 'lineVerified').needed"
                [checked]="getQcForProp(mapping, 'lineVerified').checked"
                (input)="setQcVerified(mapping, 'lineVerified', $event)"
              />
              verified</label
            >
          </div>
        </div>
      </ng-template>

      <ng-container *ngIf="!isLoading">
        <div class="ordered-form-row">
          <div class="ordered-form-left-label">{{ !profile.siteMappings?.length ? 'Sites' : '' }}</div>
          <div *ngIf="canUpdateSites()" class="ordered-form-main" style="text-align: right">
            <a class="alink" (click)="onAddSite()">Add another site</a>
          </div>
          <div class="ordered-form-right-label"><!--empty--></div>
        </div>

        <div class="ordered-form-row">
          <div class="ordered-form-left-label">Links</div>
          <div class="ordered-form-main">
            <!-- Default Link -->
            <dirt-link
              [(url)]="ctLink"
              name="baseLinks-default"
              [form]="siteLinkForm"
              [disabled]="true"
              *ngIf="!!ctLink"
              [disabled]="!canUpdateSites()"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(profile.baseLinks)"
                *ngIf="!profile.baseLinks?.length"
                disabled="!canUpdateSites()"
              >
                <i class="fa fa-plus-circle"></i>
              </button>
            </dirt-link>

            <!-- Manual Links -->
            <ng-container *ngFor="let baseLink of profile.baseLinks; let idx = index; trackBy: trackByIndex">
              <dirt-link
                [(url)]="profile.baseLinks[idx]"
                name="baseLinks-{{ idx }}"
                [form]="siteLinkForm"
                [readonly]="0 === idx && !!ctLink"
                [disabled]="!canUpdateSites()"
              >
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="pushItemToList(profile.baseLinks)"
                  *ngIf="idx == profile.baseLinks.length - 1"
                  disabled="!canUpdateSites()"
                >
                  <i class="fa fa-plus-circle"></i>
                </button>

                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="removeFromByIndex(profile.baseLinks, idx)"
                  disabled="!canUpdateSites()"
                >
                  <i class="fa fa-minus-circle"></i>
                </button>
              </dirt-link>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </form>
  </div>

  <div *ngIf="!isLoading && 'AUDIT' === tab">
    <dirt-audit-log
      [id]="profile?._id"
      [entityAPI]="service"
      [explodeComparisonKeys]="['personMappings', 'siteMappings']"
    ></dirt-audit-log>
  </div>

  <div *ngIf="!isLoading && 'COMMENT' === tab">
    <dirt-comment-list
      [ownerId]="profile?._id"
      [enableCreate]="svcAcl.hasCredential('clinicalTrialProfile.comment.create')"
    ></dirt-comment-list>
  </div>

  <hr />

  <div class="dirt-btn-ct" *ngIf="'AUDIT' !== tab && 'COMMENT' !== tab">
    <div class="pull-left">
      <!-- Unable to Process for job -->
      <button type="button" class="btn btn-outline-danger" (click)="onJobUtc()" *ngIf="hasJobForCurrentUser()">
        Unable to Compile!
      </button>
      <!-- Skip job for this user -->
      <button
        type="button"
        class="btn btn-outline-warning"
        (click)="onJobSkip()"
        *ngIf="hasJobForCurrentUser() && canSkip()"
      >
        Skip job
      </button>
    </div>
    <div class="pull-right">
      <span *ngIf="isQcJob()"
        >Verified {{ getPeopleVerifiedCount() }} people and {{ getSiteVerifiedCount() }} sites
      </span>
      <button
        type="button"
        class="btn btn-secondary"
        (click)="onJobDraft()"
        [disabled]="isSubmitting"
        *ngIf="hasJobForCurrentUser()"
      >
        Save as draft
      </button>
      <button
        type="button"
        class="btn"
        [ngClass]="{
          'btn-primary': !hasJobForCurrentUser(),
          'btn-secondary': hasJobForCurrentUser(),
          'btn-loading': isSubmitting
        }"
        [disabled]="(!isValid() && !canSaveAnyway()) || isSubmitting"
        (click)="onSave()"
        *diAcl="'clinicalTrialProfile.update'"
      >
        <span>{{ !isValid() && canSaveAnyway() ? 'Save Anyway' : 'Save' }}</span>
      </button>
      <button
        type="button"
        class="btn btn-primary"
        [ngClass]="{ 'btn-loading': isSubmitting }"
        [disabled]="(!isValid() && !canSaveAnyway()) || isSubmitting"
        (click)="onSubmitJob()"
        *ngIf="canSubmitJob()"
      >
        <span>{{ !isValid() && canSaveAnyway() ? 'Submit Anyway' : 'Submit' }}</span>
      </button>
    </div>
  </div>
</section>
