import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { Observable, tap } from 'rxjs';

import { Association } from '../../association';
import { AssociationAPI } from '../../association-api.service';
import { AssociationStatus } from '../../constant/status.enum';
import { Value } from '../../../../shared/services/value/value';
import { ValueAPI } from '../../../../shared/services/value/value-api.service';
import { ValueType } from '../../../../shared/enum/value-type.enum';
import { Utils } from '../../../../common/utils';

@Component({
  selector: 'dirt-association-modal',
  templateUrl: 'association-modal.component.html',
})
export class AssociationModalComponent implements OnInit {
  isSaving = false;

  model = new Association();

  parentId?: string;

  parentAssociation?: Association;

  countries$: Observable<Value[]>;

  nonCompliantDomainType: string = ValueType.NonCompliantDomainsActivities;

  @ViewChild(NgForm, { static: false })
  private form: NgForm;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly svcAssociation: AssociationAPI,
    private readonly svcValue: ValueAPI
  ) {}

  ngOnInit(): void {
    this.countries$ = this.svcValue.find(ValueType.EventAssociationCountry, Number.MAX_SAFE_INTEGER, 0, '+title');

    this.model.webSources = [''];
    this.model.memberWebSources = [''];
  }

  onSave(): void {
    if (this.isSaving || !this.form.valid) {
      return;
    }

    const association = this.model;

    association.parent = this.parentId as any;

    if (!association.parent) {
      association.type = 'TO_BE_FILLED';
      association.reach = 'NATIONAL';
      association._meta = { status: AssociationStatus.READY_FOR_COMPILATION };
      (association as any).identified = true;
    }

    if (association.parent) {
      // We don't need it
      association.address = {};
      association.webSources = [];
    }

    Utils.removeEmptyAndDuplicatedElementsFromArray(association, ['webSources', 'memberWebSources']);

    this.isSaving = true;
    this.svcAssociation
      .create(association)
      .pipe(tap(() => (this.isSaving = false)))
      .subscribe((association) => {
        this.activeModal.close(association);
      });
  }

  onParentAssociationSelected(association: Association): void {
    this.parentId = association.id;
    this.parentAssociation = association;
  }

  onParentAssociationDisconnected(): void {
    this.parentId = null;
    this.parentAssociation = null;
  }

  trackByIndex(index: number): number {
    return index;
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }
}
