import { Component, EventEmitter, OnDestroy, Output, Input, OnInit } from '@angular/core';

import { CustomFilterComponent } from '../../../../shared/components/filters/filters.component';

@Component({
  selector: 'dirt-textbox-filter',
  templateUrl: './textbox-filter.component.html',
  styleUrls: ['./textbox-filter.component.scss'],
})
export class TextboxFilterComponent implements OnInit, OnDestroy, CustomFilterComponent {
  @Input()
  value: string;

  @Output()
  valueChange = new EventEmitter<{ $regex?: string; $options?: string }>();

  rawValue: string;

  ngOnInit(): void {
    this.rawValue = this.value;
  }

  ngOnDestroy(): void {
    this.valueChange.complete();
  }

  doCancel(previousValue: string): void {
    this.rawValue = previousValue;
  }

  doClear(): void {
    this.rawValue = '';
  }

  onChange(): void {
    const value: { $regex?: string; $options?: string } = {};
    value.$regex = this.rawValue;
    value.$options = 'i';
    this.valueChange.next(value);
  }
}
