import { Pipe, PipeTransform } from '@angular/core';
import { get } from 'lodash';

@Pipe({
  name: 'getByPath',
})
export class GetByPathPipe implements PipeTransform {
  transform(value: unknown, path: string): unknown {
    return get(value, path);
  }
}
