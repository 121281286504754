import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { PaymentAPI } from './shared/payment-api.service';

@NgModule({
  imports: [SharedModule],
  declarations: [],
  providers: [PaymentAPI],
})
export class PaymentModule {}
