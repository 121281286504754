import { Component } from '@angular/core';

@Component({
  selector: 'dirt-admin-forbidden',
  templateUrl: 'forbidden.component.html',
  styleUrls: ['forbidden.component.scss'],
})
export class AdminForbiddenComponent {
  constructor() {}
}
