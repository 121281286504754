import { Component } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'dirt-find-by-id',
  templateUrl: './find-by-id.component.html',
  styleUrls: ['./find-by-id.component.scss'],
})
export class FindByIdComponent {
  modalOption: NgbModalOptions = {};
  modalRef: NgbModalRef;
  inputValue: string = '';

  constructor(protected modalService: NgbModal) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
  }

  onOpenModal(dialog) {
    this.modalRef = this.modalService.open(dialog, this.modalOption);
  }
}
