import { Component } from '@angular/core';
import { firstValueFrom, tap } from 'rxjs';
import { Router } from '@angular/router';

import { ACL } from '../../shared/acl/acl.service';
import { Domain } from '../shared/domain';
import { DomainAPI } from '../shared/domain-api.service';

@Component({
  selector: 'dirt-domain-create',
  templateUrl: './create.component.html',
})
export class DomainCreateComponent {
  domain: Domain = new Domain();

  isSubmitting = false;

  isFormValid: boolean;

  constructor(private router: Router, private svcDomain: DomainAPI, private svcACL: ACL) {}

  goBack(): void {
    const link = ['/domain'];
    this.router.navigate(link);
  }

  async onSubmit(domain: Domain): Promise<void> {
    if (!this.svcACL.hasCredential('domain.create') || !domain || !this.isFormValid) {
      return;
    }

    await this.saveAccount(domain);
    this.goBack();
  }

  onFormValidityChange(status: string): void {
    setTimeout(() => (this.isFormValid = status === 'VALID'));
  }

  private async saveAccount(domain: Domain): Promise<any> {
    this.isSubmitting = true;

    return await firstValueFrom(this.svcDomain.create(domain).pipe(tap(() => (this.isSubmitting = false))));
  }
}
