import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { User } from '../../user/shared/user';
import { FindByIdComponent } from './find-by-id.component';
import { PersonAPI } from '../../person/shared/api.service';

@Component({
  selector: 'dirt-find-person-for-compilers',
  templateUrl: './find-by-id.component.html',
  styleUrls: ['./find-by-id.component.scss'],
})
export class FindPersonComponent extends FindByIdComponent {
  constructor(protected modalService: NgbModal, private router: Router, private svcPerson: PersonAPI) {
    super(modalService);
  }

  onSubmit() {
    if (!this.inputValue) {
      return;
    }

    this.svcPerson.find(undefined, undefined, undefined, undefined, { kolId: this.inputValue }).subscribe(
      (response) => {
        if (!response || !response.length) {
          return alert('No people found');
        }
        const person = response[0];
        this.router.navigate(['/person/detail', person.id]);
      },
      (err) => alert('Error!'),
      () => this.modalRef.close()
    );
  }
}
