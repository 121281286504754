<div class="p-1 overflow-hidden date-range">
  <div class="form-check">
    <input
      type="radio"
      id="range"
      class="form-check-input"
      name="dateRangeOption"
      [checked]="!value.isNotSet"
      (click)="onSelectedRangeOptionChanged('range')"
    />

    <label class="form-check-label" for="range"> Date Range </label>
  </div>

  <div class="py-2 date-range__range-wrapper">
    <div class="form-group row justify-content-center">
      <label class="col-sm-3 col-form-label">Start</label>
      <div class="col-sm-9">
        <input
          type="date"
          class="form-control"
          name="polishedAtFrom"
          placeholder="From"
          [max]="value.end | date : 'yyyy-MM-dd'"
          [disabled]="value.isNotSet"
          [ngModel]="value.start | date : 'yyyy-MM-dd'"
          (change)="onDateRangeChanged(from.valueAsDate, to.valueAsDate)"
          #from
        />
      </div>
    </div>

    <div class="form-group row justify-content-center">
      <label class="col-sm-3 col-form-label">End</label>
      <div class="col-sm-9">
        <input
          type="date"
          class="form-control"
          name="polishedAtTo"
          placeholder="To"
          [min]="value.start | date : 'yyyy-MM-dd'"
          [disabled]="value.isNotSet"
          [ngModel]="value.end | date : 'yyyy-MM-dd'"
          (change)="onDateRangeChanged(from.valueAsDate, to.valueAsDate)"
          #to
        />
      </div>
    </div>
  </div>

  <div class="form-check" *ngIf="!hideNotSet">
    <input
      type="radio"
      id="notSet"
      class="form-check-input"
      name="dateRangeOption"
      [checked]="value.isNotSet"
      (click)="onSelectedRangeOptionChanged('date-not-set')"
    />

    <label class="form-check-label" for="notSet">
      {{ notSetLabel }}
    </label>
  </div>
</div>
