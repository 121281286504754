<div class="modal-header">
  <h5 class="modal-title">Create new Job</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <dirt-job-form #form [model]="model" [jobTypes]="jobTypes"></dirt-job-form>
</div>

<div class="modal-footer">
  <div class="pull-right">
    <button type="button" class="btn btn-secondary btn-sm" (click)="activeModal.dismiss()">Cancel</button>
    <button type="button" class="btn btn-primary btn-sm" (click)="onSave()" [disabled]="!form.isValid()">Save</button>
  </div>
</div>
