import { Component, Input, OnChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { CommentAPI } from '../shared/api.service';
import { Comment } from '../shared/comment';

@Component({
  selector: 'dirt-comment-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class CommentListComponent implements OnChanges {
  @Input()
  ownerId: string;

  @Input()
  peopleId: string;

  @Input()
  enableCreate: boolean;

  @Input()
  enableUpdate: boolean;

  @Input()
  enableDelete: boolean;

  @Input()
  showNavToItem: boolean;

  @Input()
  alternativeList: Comment[] = null;

  @Output()
  onAutoCloseFunc = new EventEmitter<any>();

  @Output()
  newCommentAdded: EventEmitter<Comment> = new EventEmitter();

  @Output()
  navToItem: EventEmitter<{ id }> = new EventEmitter();

  isLoading = false;
  comments: any[];

  pagingLimit = 100;
  pagingSkip = 0;

  sort = '-_id';

  selectedCommentId: string;

  editCommentIsActive = false;

  constructor(private svcComment: CommentAPI) {}

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    if (changes['ownerId'] && changes['ownerId'].currentValue) {
      this.getComments();
    } else if (changes['alternativeList']) {
      this.getComments(); // refresh if new ext list
    }
  }

  getComments(): void {
    if (this.alternativeList) {
      this.comments = this.alternativeList;
      return;
    }
    if (!this.ownerId) {
      this.comments = [];
      return;
    }

    this.isLoading = true;

    this.svcComment.find(this.ownerId, this.pagingLimit, this.pagingSkip, this.sort, this.peopleId).subscribe(
      (resp) => {
        this.comments = resp;
        if (this.comments.length === 0) this.onAutoCloseFunc.emit();
      },
      null,
      () => (this.isLoading = false)
    );
  }

  updateList(comment: Comment): void {
    this.comments.unshift(comment);
    this.newCommentAdded.emit(comment);
  }

  onCancelEdit() {
    this.editCommentIsActive = false;
    this.selectedCommentId = null;
  }

  endUpdateComment(): void {
    this.onCancelEdit();
    this.getComments();
  }

  onEditClicked(commentId: string) {
    this.selectedCommentId = commentId;
    this.editCommentIsActive = true;
  }

  onDeleteClicked(commentId: string) {
    if (!window.confirm('Are you sure you want to delete the comment?')) {
      return;
    }
    this.isLoading = true;
    const comment = <Comment>{
      owner: this.ownerId,
    };
    this.svcComment.deleteById(commentId).subscribe(() => {
      this.isLoading = false;
      this.getComments();
    });
  }
}
