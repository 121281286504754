import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GET,
  PUT,
  POST,
  DELETE,
  Path,
  Body,
  Query,
  MediaType,
  Produces,
  BaseUrl,
} from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { Contribution } from './contribution';
import { environment } from '../../../environments/environment';

@Injectable()
export class ContributionAPI extends APIService {
  @GET('contributions/')
  @Produces(MediaType.JSON)
  public find(
    @Query('eventId') eventId?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('title') title?: string,
    @Query('searchType') searchType?: string,
    @Query('filter') filter?: any,
    @Query('qcSessionId') qcSessionId?: any
  ): Observable<Contribution[]> {
    return;
  }

  @GET('contributions/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Contribution> {
    return;
  }

  @GET('contributions/count')
  @Produces(MediaType.JSON)
  public count(
    @Query('eventId') eventId?: string,
    @Query('title') title?: string,
    @Query('searchType') searchType?: string
  ): Observable<any> {
    return;
  }

  @POST('contributions')
  @Produces(MediaType.JSON)
  public create(@Body contribution: Contribution): Observable<any> {
    return;
  }

  @PUT('contributions')
  @Produces(MediaType.JSON)
  public upsert(@Body contribution: Contribution): Observable<any> {
    return;
  }

  @DELETE('contributions/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }

  @POST('contributions/smart-lcase')
  @Produces(MediaType.JSON)
  public smartLcase(@Body contribs: Partial<Contribution>[]): Observable<Partial<Contribution>[]> {
    return;
  }

  @GET('contributions/statistic')
  @Produces(MediaType.JSON)
  public statistic(): Observable<any> {
    return;
  }

  @POST('contributions/bulk-update')
  @Produces(MediaType.JSON)
  public bulkUpdate(@Body data: { ids: string[]; values: { [key: string]: any } }): Observable<any> {
    return null;
  }

  @PUT('contributions/bulk-delete')
  @Produces(MediaType.JSON)
  public bulkDelete(@Body contributionDeleteIds: string[]): Observable<any> {
    return null;
  }

  @PUT('contributions/move')
  @Produces(MediaType.JSON)
  public moveContribution(
    @Body
    data: {
      contributions: Contribution[];
      makeACopy: boolean;
      moveAllContributions: boolean;
      sessionToBeMovedTo: string;
      sessionToBeMovedFrom: string;
    }
  ): Observable<any> {
    return;
  }

  @GET('contributions/duplicates')
  @Produces(MediaType.JSON)
  public getDuplicates(
    @Query('eventId') eventId?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number
  ): Observable<Contribution[]> {
    return null;
  }

  @GET('contributions/duplicates/count')
  @Produces(MediaType.JSON)
  public getDuplicatesCount(@Query('eventId') eventId?: string): Observable<{ count: number }> {
    return null;
  }
}
