<span>
  <input
    #ctrl
    *ngIf="editMode && !hasSuggestions()"
    type="text"
    [size]="size"
    name="name"
    [(ngModel)]="editValue"
    (keydown.escape)="onCancelEdit()"
    (keydown.enter)="onConfirmEdit()"
    (blur)="onBlurEdit()"
  />
  <select #ctrl *ngIf="editMode && hasSuggestions()" [(ngModel)]="editValue" (blur)="onBlurEdit()">
    <option *ngFor="let s of suggestions">{{ s }}</option>
    <option *ngIf="suggestions.indexOf(editValue) < 0">{{ editValue }}</option>
  </select>
  <span *ngIf="!editMode && value" title="click to edit" (click)="onStartEdit()">{{ value }}</span>
  <span *ngIf="!editMode && !value" title="click to edit" (click)="onStartEdit()" class="light-empty-placeholder"
    >(empty)</span
  >
</span>
