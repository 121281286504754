import { ClinicalTrialSponsorJob } from '../../clinical-trial-sponsor/shared/constant/job.enum';
import { DomainJob } from '../../domain/shared/constant/job.enum';
import { MedicalInsightsProfileJob } from '../../medical-insights-profile/shared/constant/job.enum';
import { PersonJob } from '../../person/shared/constant/job.enum';

const DEF = {};

DEF[PersonJob.VID] = ['person.update.prop.externalIds.openData', 'person.update.mandatory.prop.externalIds.openData'];

DEF[PersonJob.VID_QC] = ['person.update.prop.externalIds.openData'];

DEF[PersonJob.DEDUPLICATION] = [
  'person.update.prop.firstName',
  'person.update.prop.middleName',
  'person.update.prop.lastName',
  'person.update.prop.suffix',
  'person.update.prop.nicknames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.externalIds.npi',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalFullName',
  'person.update.prop.alternativeNames',
  'person.update.prop.technicalProjects',
  'person.update.prop.meta.statusLimited', // can't set to done by themselves but can set id-out et al
  'person.update.mandatory.prop.externalIds.npi',
  'person.comment.list',
  'person.audit.list',
  'person.profile.summary',
  'person.committee.list',
];

DEF[PersonJob.PERSON_IMAGE_CURATION] = ['person.update.prop.image'];

DEF[PersonJob.PERSON_IMAGE_MAINTENANCE] = ['person.update.prop.image'];

DEF[PersonJob.LFKA_KPP_CURATION] = [
  'affiliation.lfka',
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.affiliations.remove',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.affiliationsLfka',
  'person.update.prop.positionLinks',
  'person.update.prop.degrees',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialties',
  'person.update.prop.specialtyLinks',
  'person.update.prop.technicalProjects',
  'person.update.prop.phone',
  'person.update.prop.phoneLink',
  'person.update.mandatory.prop.affiliationsLfka',
  // not enforced 'person.update.mandatory.prop.positionLinks',
  'affiliation.request',
  'organization.request',
];

DEF[PersonJob.LFKA_KPP_MAINTENANCE] = [
  'affiliation.lfka',
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.affiliations.remove',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.affiliationsLfka',
  'person.update.prop.positionLinks',
  'person.update.prop.degrees',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialties',
  'person.update.prop.specialtyLinks',
  'person.update.prop.technicalProjects',
  'person.update.prop.phone',
  'person.update.prop.phoneLink',
  'person.update.mandatory.prop.affiliationsLfka',
  // not enforced 'person.update.mandatory.prop.positionLinks',
  'affiliation.request',
  'organization.request',
];

DEF[PersonJob.EDUCATIONAL_BACKGROUND] = [
  'person.update.prop.affiliationsEducation',
  'affiliation.request',
  'organization.request',
];

DEF[PersonJob.ORGANIZATION_DISCONNECTION] = [
  'affiliation.lfka',
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.remove',
  'person.update.prop.affiliationsLfka',
  'person.update.prop.affiliationsLfka.remove',
  'person.update.prop.affiliationsEducation',
  'person.update.prop.affiliationsEducation.remove',
];

DEF[PersonJob.PERSON_LINKEDIN_CURATION] = ['person.update.prop.linkedinUrl'];

DEF[PersonJob.PERSON_LINKEDIN_QC] = ['person.update.prop.linkedinUrl'];

DEF[DomainJob.DOMAIN_COMPILATION] = ['domain.update', 'domain.update.prop.*'];

DEF[DomainJob.DOMAIN_MAINTENANCE] = ['domain.update', 'domain.update.prop.*'];

DEF[PersonJob.PERSON_SO_QC] = ['person.update.prop.*', 'affiliation.request', 'organization.request'];

DEF[ClinicalTrialSponsorJob.COMPILATION] = [
  // No work outside of jobs
  'clinicalTrialSponsor.update',
  'clinicalTrialSponsor.update.prop.*',
  'affiliation.request',
  'organization.request',
];

DEF[ClinicalTrialSponsorJob.MAINTENANCE] = [
  // No work outside of jobs
  'clinicalTrialSponsor.update',
  'clinicalTrialSponsor.update.prop.*',
  'affiliation.request',
  'organization.request',
];

DEF[ClinicalTrialSponsorJob.REVIEW] = [
  // No work outside of jobs
  'clinicalTrialSponsor.update',
  'clinicalTrialSponsor.update.prop.*',
  'affiliation.request',
  'organization.request',
];

DEF[PersonJob.EDUCATION] = [
  'person.update.prop.degrees',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialties',
  'person.update.prop.specialtyLinks',
  'person.update.prop.originalSpecialties',
  'person.update.prop.profession',
  'person.audit.list',
  'person.profile.summary',
  'person.comment.list',
];

DEF[PersonJob.EDUCATION_QC] = [
  'person.update.prop.degrees',
  'person.update.prop.degreeLinks',
  'person.update.prop.specialties',
  'person.update.prop.originalSpecialties',
  'person.update.prop.specialtyLinks',
  'person.update.prop.profession',
  'person.audit.list',
  'person.profile.summary',
  'person.comment.list',
];

DEF[PersonJob.WORK] = [
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.removeRequest',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.mandatory.prop.affiliations',
  'person.update.prop.positionLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.emails',
  'person.update.prop.status',
  'person.update.prop.statusLinks',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.meta.statusLimited',
  'person.audit.list',
  'person.profile.summary',
  'person.comment.list',
  'affiliation.update.prop.originalPosition',
  'affiliation.request',
  'organization.request',
];

DEF[PersonJob.WORK_QC] = [
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.removeRequest',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.positionLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.emails',
  'person.update.prop.status',
  'person.update.prop.statusLinks',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.meta.statusLimited',
  'person.audit.list',
  'person.profile.summary',
  'person.comment.list',
  'affiliation.update.prop.originalPosition',
  'affiliation.request',
  'organization.request',
];

DEF[PersonJob.WORK_MAINTENANCE] = [
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.removeRequest',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.mandatory.prop.affiliations',
  'person.update.prop.positionLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.emails',
  'person.update.prop.status',
  'person.update.prop.statusLinks',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.meta.statusLimited',
  'person.audit.list',
  'person.profile.summary',
  'person.comment.list',
  'affiliation.update.prop.originalPosition',
  'affiliation.request',
  'organization.request',
];

DEF[PersonJob.WORK_MAINTENANCE_QC] = [
  'person.update.prop.affiliations',
  'person.update.prop.affiliations.removeRequest',
  'person.update.prop.affiliations.inactive',
  'person.update.prop.affiliations.position',
  'person.update.prop.positionLinks',
  'person.update.prop.emailLinks',
  'person.update.prop.emails',
  'person.update.prop.status',
  'person.update.prop.statusLinks',
  'person.update.prop.affiliationsLinks',
  'person.update.prop.meta.statusLimited',
  'person.audit.list',
  'person.profile.summary',
  'person.comment.list',
  'affiliation.update.prop.originalPosition',
  'affiliation.request',
  'organization.request',
];

DEF[PersonJob.BASE_DATA] = [
  // excl. work above
  'person.update.prop.alternativeNames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.statusLinks',
  'person.update.prop.externalIds.npi', // as creator has it, too
  'person.update.prop.externalIds.sln',
  'person.update.prop.faxes',
  'person.update.prop.firstName',
  'person.update.prop.lastName',
  'person.update.prop.meta.status',
  'person.update.prop.middleName',
  'person.update.prop.nicknames',
  'person.update.prop.optOutInquiry',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalFullName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.phone',
  'person.update.prop.phoneLink',
  'person.update.prop.prefixes',
  'person.update.prop.priority',
  'person.update.prop.professionalFocus',
  'person.update.prop.segments',
  'person.update.prop.source',
  'person.update.prop.suffix',
  'person.update.prop.talkingPoints',
  'person.update.prop.verified',
  'person.update.prop.externalIds.openData',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.update.prop.tags',
  'person.audit.list',
  'person.profile.summary',
  'person.comment.list',
  'affiliation.update.prop.originalPosition',
  'affiliation.request',
  'organization.request',
];

DEF[PersonJob.BASE_DATA_QC] = [
  // excl. work QC above
  'person.update.prop.alternativeNames',
  'person.update.prop.countryWorkflow',
  'person.update.prop.cvLinks',
  'person.update.prop.nameLinks',
  'person.update.prop.statusLinks',
  'person.update.prop.externalIds.npi', // as creator has it, too
  'person.update.prop.externalIds.sln',
  'person.update.prop.faxes',
  'person.update.prop.firstName',
  'person.update.prop.lastName',
  'person.update.prop.meta.status',
  'person.update.prop.middleName',
  'person.update.prop.nicknames',
  'person.update.prop.optOutInquiry',
  'person.update.prop.originalFirstName',
  'person.update.prop.originalFullName',
  'person.update.prop.originalLastName',
  'person.update.prop.originalMiddleName',
  'person.update.prop.phone',
  'person.update.prop.phoneLink',
  'person.update.prop.prefixes',
  'person.update.prop.priority',
  'person.update.prop.professionalFocus',
  'person.update.prop.segments',
  'person.update.prop.source',
  'person.update.prop.suffix',
  'person.update.prop.talkingPoints',
  'person.update.prop.verified',
  'person.update.prop.externalIds.openData',
  'person.update.prop.deliveryProjects',
  'person.update.prop.technicalProjects',
  'person.update.prop.tags',
  'person.audit.list',
  'person.profile.summary',
  'person.comment.list',
  'affiliation.update.prop.originalPosition',
  'affiliation.request',
  'organization.request',
];

DEF[MedicalInsightsProfileJob.MEDICAL_INSIGHTS_PROFILE_CURATION] = ['medical-insights-profile.update'];

// no restrictions for AGENDA_IDENTIFICATION/EVENT_CURATION so far

export const PERMISSIONS_JOBS = DEF;
