import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'iterable' })
export class IterablePipe implements PipeTransform {
  transform(input: object, sort?: boolean): any[] {
    const data = [];

    for (const key in input) {
      data.push({ key: key, value: input[key] });
    }

    if (sort) {
      data.sort((a, b): number => (a.value > b.value ? 1 : -1));
    }

    return data;
  }
}
