import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  forkJoin,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgModel } from '@angular/forms';

import { ACL } from '../../shared/acl/acl.service';
import { AccountPerson, AccountPersonRequestMaintenance, AccountPersonStatus } from '../shared/account-person';
import { AccountPersonAPI } from '../shared/account-person-api.service';
import { Person } from '../../person/shared/person';
import { PersonAPI } from '../../person/shared/api.service';
import { PersonStatus } from '../../person/shared/constant/status.enum';
import { Utils } from '../../common/utils';
import { Value } from '../../shared/services/value/value';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { Account } from '../../account/shared/account';
import { JobsAPI } from '../../jobs/shared/api.service';
import { Job } from '../../jobs/shared/job';
import { PersonJob } from '../../person/shared/constant/job.enum';
import { UserPoolAPI } from '../../user-pool/shared/api.service';
import { UserPool } from '../../user-pool/shared/user-pool';

@Component({
  selector: 'dirt-account-person-modal',
  templateUrl: 'person-modal.component.html',
  styleUrls: ['person-modal.component.scss'],
})
export class AccountPersonModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  account: Account;

  @Input('account-person')
  selectedAccountPerson: AccountPerson;

  @Input()
  mode: 'ADD' | 'VIEW' | 'EDIT';

  isSaving: boolean;

  isSearching: boolean;

  isFormValid: boolean;

  showPersonForm: boolean;

  jobTitleMatrix: Value[];

  searchTerm: string;

  isEdit: boolean;

  projectNamesDidChange: boolean = false;

  createDisabledFields = ['cvLinks'];

  editDisabledFields = [
    'firstName',
    'middleName',
    'lastName',
    'source',
    'suffix',
    'countryWorkflow',
    'source',
    'cvLinks',
  ];

  disablePersonForm = false;

  accountPersonRequestMaintenance = AccountPersonRequestMaintenance;

  @ViewChild('frmAdditional', { static: true })
  private frmAdditional: NgModel;

  private baseFormStatus: string;

  private destroy$: Subject<boolean> = new Subject();

  public longDash = Utils.longDash;

  constructor(
    public activeModal: NgbActiveModal,
    private svcAcl: ACL,
    private svcPerson: PersonAPI,
    private svcAccountPerson: AccountPersonAPI,
    private svcValue: ValueAPI,
    private svcJob: JobsAPI,
    private readonly svcUserPool: UserPoolAPI
  ) {
    this.onSearchPeople = this.onSearchPeople.bind(this);
  }

  ngOnInit() {
    this.isEdit = this.mode === 'EDIT';
    this.disablePersonForm = this.mode === 'VIEW';

    this.svcValue.find(ValueType.AccountPersonJobTitle, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((values) => {
      this.jobTitleMatrix = values;
    });

    // If no selectedAccountPerson, return early
    if (!this.selectedAccountPerson) {
      return;
    }

    // Initialize jobDetails if not present
    if (!this.selectedAccountPerson?.jobDetails?.length) {
      this.selectedAccountPerson.jobDetails = [{ matrix: '', title: '', departmentName: '' }];
    }

    if (!this.selectedAccountPerson?.sourceUrls.length) {
      this.selectedAccountPerson.sourceUrls = [''];
    }

    // If neither account.qc nor selectedAccountPerson.qc is present, return early. Rest of the code is QC logic.
    if (!this.account.qc && !this.selectedAccountPerson.qc) {
      return;
    }

    if (this.selectedAccountPerson.qc && !this.account.qc) {
      this.account.qc = {};
    }

    if (!Object.keys(this.account.qc).includes(this.selectedAccountPerson._id)) {
      this.account.qc[this.selectedAccountPerson._id] = this.selectedAccountPerson.qc;
    }

    if (this.account.qc) {
      this.selectedAccountPerson.qc = this.account.qc[this.selectedAccountPerson._id];
      if (!this.selectedAccountPerson.person.qc) {
        this.selectedAccountPerson.person.qc = {};
      }
      this.selectedAccountPerson.person.qc.firstName = this.selectedAccountPerson.qc?.firstName;
      this.selectedAccountPerson.person.qc.middleName = this.selectedAccountPerson.qc?.middleName;
      this.selectedAccountPerson.person.qc.lastName = this.selectedAccountPerson.qc?.lastName;
      if (this.selectedAccountPerson?.jobDetails.length > 0 && this.selectedAccountPerson.qc?.jobDetails) {
        this.selectedAccountPerson.jobDetails.forEach((jobDetail) => {
          const matchingJob =
            this.selectedAccountPerson.qc.jobDetails.find((accountJob: any) => {
              return Object.keys(accountJob)[0] === jobDetail._id;
            }) || {};
          jobDetail.qc = matchingJob[Object.keys(matchingJob)[0]];
        });
      }
    }
  }

  ngAfterViewInit(): void {
    this.frmAdditional.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.onFormValidityChange());
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  onSearchPeople(term$: Observable<string>): Observable<Person[]> {
    return term$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term: string) => {
        if (!term) {
          return of([]);
        }

        return this.svcPerson
          .find(term, 10, 0, null, { 'affiliations.countryCode': [this.account.address.countryCode] })
          .pipe(catchError(() => of([])));
      }),
      switchMap((people) => {
        if (people.length === 0) {
          return of([]);
        }

        return of(people);
      }),
      tap(() => (this.isSearching = false))
    );
  }

  onTogglePersonForm(): void {
    this.showPersonForm = true;

    this.selectedAccountPerson = {
      accountId: this.account.id,
      sourceUrls: [''],
      person: new Person(),
      jobDetails: [{ matrix: '', title: '', departmentName: '' }],
    } as any;

    this.selectedAccountPerson.person.countryWorkflow = this.account.address.countryCode;
    this.selectedAccountPerson.person.source = 'WEB_RESEARCH';

    this.selectedAccountPerson.person.cvLinks = [''];
    this.selectedAccountPerson.person.projectNames = ['KAM_ALL', 'LFKA_RF_BD'];
    this.projectNamesDidChange = true;
  }

  onSelectPerson(event: any): void {
    event.preventDefault(); // prevent setting model to [object Object]

    this.selectedAccountPerson = {
      person: event.item,
      sourceUrls: [''],
      jobDetails: [{ matrix: '', title: '', departmentName: '' }],
    } as any;

    this.searchTerm = '';
    this.showPersonForm = false;
    this.projectNamesDidChange = false;
    this.selectedAccountPerson.person.source = 'WEB_RESEARCH';

    if (!this.selectedAccountPerson.person.projectNames.includes('KAM_ALL')) {
      this.selectedAccountPerson.person.projectNames = [...this.selectedAccountPerson.person.projectNames, 'KAM_ALL'];
      this.projectNamesDidChange = true;
    }

    if (!this.selectedAccountPerson.person.projectNames.includes('LFKA_RF_BD')) {
      this.selectedAccountPerson.person.projectNames = [
        ...this.selectedAccountPerson.person.projectNames,
        'LFKA_RF_BD',
      ];
      this.projectNamesDidChange = true;
    }
  }

  async onAddPerson(): Promise<void> {
    if (!this.selectedAccountPerson || this.showPersonForm || this.isSaving || this.isSearching) {
      return;
    }
    const person = (
      await firstValueFrom(
        this.svcAccountPerson.find(this.account.id, 1, 0, null, {
          kolId: this.selectedAccountPerson.person.kolId,
          affiliationId: this.selectedAccountPerson.affiliationId,
        })
      )
    )[0];
    if (person) {
      return alert('person is already attached to account');
    }

    this.isSaving = true;

    if (!this.selectedAccountPerson.person.projectNames.includes('KAM_ALL')) {
      this.selectedAccountPerson.person.projectNames = [...this.selectedAccountPerson.person.projectNames, 'KAM_ALL'];
      this.projectNamesDidChange = true;
    }

    if (!this.selectedAccountPerson.person.projectNames.includes('LFKA_RF_BD')) {
      this.selectedAccountPerson.person.projectNames = [
        ...this.selectedAccountPerson.person.projectNames,
        'LFKA_RF_BD',
      ];
      this.projectNamesDidChange = true;
    }

    if (this.projectNamesDidChange) {
      await firstValueFrom(
        this.svcPerson.update(this.selectedAccountPerson.person.id, {
          projectNames: this.selectedAccountPerson.person.projectNames,
        })
      );
    }

    const affiliations = this.selectedAccountPerson.person.affiliations;
    if (affiliations.length === 0 || !affiliations) {
      const person = {
        accountId: this.account.id,
        kolId: this.selectedAccountPerson.person.kolId,
        person: this.selectedAccountPerson.person,
        status: AccountPersonStatus.ADDED_EXISTING,
        jobDetails: this.selectedAccountPerson.jobDetails,
        inactive: this.selectedAccountPerson.inactive,
        isPersonAffiliationConnected: true,
        isPersonAccountConnected: true,
        sourceUrls: this.selectedAccountPerson.sourceUrls,
      } as AccountPerson;
      this.svcAccountPerson.create(person).subscribe(() => {
        this.activeModal.close();
      });
    } else {
      const createObservables: Observable<any>[] = [];
      for (const affiliation of affiliations) {
        const person = {
          accountId: this.account.id,
          kolId: this.selectedAccountPerson.person.kolId,
          affiliationId: affiliation.id,
          person: this.selectedAccountPerson.person,
          status: AccountPersonStatus.ADDED_EXISTING,
          jobDetails: this.selectedAccountPerson.jobDetails,
          inactive: this.selectedAccountPerson.inactive,
          sourceUrls: this.selectedAccountPerson.sourceUrls,
        } as AccountPerson;
        const create$ = this.svcAccountPerson.create(person);
        createObservables.push(create$);
      }
      forkJoin(createObservables).subscribe(() => {
        this.activeModal.close();
      });
    }
  }

  async onEditPerson(): Promise<void> {
    if (!this.selectedAccountPerson || this.showPersonForm || this.isSaving || this.isSearching) {
      return;
    }

    this.isSaving = true;

    if (this.svcAcl.hasCredential('account-person.qc')) {
      // for later when save draft/submit the job to save qc session
      const qc = this.selectedAccountPerson.qc || {};
      qc.jobDetails = this.selectedAccountPerson.jobDetails
        .filter((jd) => jd.qc && Object.keys(jd.qc).length > 0)
        .map((jt) => ({ [jt._id]: jt.qc }));
      if (this.selectedAccountPerson.person?.qc) {
        Object.assign(qc, this.selectedAccountPerson.person.qc);
        delete this.selectedAccountPerson.person.qc;
      }
      if (!this.account.qc) {
        this.account.qc = {};
      }
      this.account.qc[this.selectedAccountPerson._id] = qc;
    }
    await firstValueFrom(
      this.svcPerson.update(this.selectedAccountPerson.person.id, this.selectedAccountPerson.person)
    );
    this.svcAccountPerson.update(this.selectedAccountPerson._id, this.selectedAccountPerson).subscribe(() => {
      this.activeModal.close();
    });
  }

  onRequestPerson(): void {
    if (!this.isFormValid || !this.showPersonForm || this.isSaving || this.isSearching) {
      return;
    }

    this.selectedAccountPerson.person._meta = { status: PersonStatus.ON_HOLD };
    this.selectedAccountPerson.person.projectNames = [
      ...this.selectedAccountPerson.person.projectNames,
      'KAM_ALL',
      'KAM_CREATED',
      'LFKA_RF_BD',
    ];
    this.selectedAccountPerson.status = AccountPersonStatus.ADDED_NEW;
    this.selectedAccountPerson.person.ownerProduct = 'LFKA';

    this.isSaving = true;
    this.svcPerson
      .create(this.selectedAccountPerson.person)
      .pipe(
        concatMap((person) => {
          this.selectedAccountPerson.kolId = person.kolId;
          return this.svcAccountPerson.create(this.selectedAccountPerson);
        })
      )
      .subscribe(() => {
        this.activeModal.close();
      });
  }

  onFormValidityChange(status?: string): void {
    if (status) {
      this.baseFormStatus = status;
    }

    this.isFormValid = this.baseFormStatus === 'VALID' && this.frmAdditional.valid;
  }

  onInactiveSwitchChange(checked: boolean) {
    this.selectedAccountPerson.inactive = checked;
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[], isJobDetail?: boolean): void {
    if (isJobDetail) {
      list.push({ matrix: '', title: '', departmentName: '' });
    } else {
      list.push('');
    }
  }

  trackByIndex(index: number): number {
    return index;
  }

  isFieldEditable(field: string): boolean {
    return this.svcAcl.hasCredential(`account-person.update.prop.${field}`);
  }

  onMaintenanceRequest() {
    if (!this.svcAcl.hasCredential('job.create') || !this.selectedAccountPerson.person.id) {
      alert("You don't have permissions!");
      this.activeModal.close();
      return;
    }

    this.svcUserPool
      .find('Work maintenance')
      .pipe(
        switchMap((pool: UserPool[]) => {
          const poolId = pool.find((p) => p.name === 'Work maintenance').id;
          if (!poolId) {
            return;
          }

          const job = {
            entityId: this.selectedAccountPerson.person.id,
            entityType: 'people',
            poolId: poolId,
            type: PersonJob.WORK_MAINTENANCE,
            priority: 5,
          } as Job;

          return this.svcJob.create(job).pipe(
            concatMap((res) => {
              return this.svcAccountPerson.update(this.selectedAccountPerson._id, {
                requestMaintenance: this.accountPersonRequestMaintenance.REQUESTED,
              });
            })
          );
        })
      )
      .subscribe(() => {
        alert('Job is created!');
        this.activeModal.close();
      });
  }
}
