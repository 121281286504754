import { Component, Inject, OnInit } from '@angular/core';
import { Store, Select, NgxsOnInit } from '@ngxs/store';
import { BulkUpdateState } from '../../bulk-update.state';
import { Observable } from 'rxjs';
import { Mode } from '../../mode.enum';
import { BulkUpdateModel } from '../../bulk-update.model';
import { AddEntityAction, RemoveEntityAction } from '../../bulk-update.actions';
import { FiltersBarConfig } from '../../../../shared/components/filters-bar/filters-bar-config';
import { BulkUpdateOptionsProvider } from '../../bulk-update-options-provider';

@Component({
  selector: 'dirt-bulk-update-select-step',
  templateUrl: './bulk-update-select-step.component.html',
  styleUrls: ['./bulk-update-select-step.component.scss'],
})
export class BulkUpdateSelectStepComponent implements OnInit {
  Mode = Mode;

  @Select(BulkUpdateState.mode) mode$: Observable<Mode>;
  @Select(BulkUpdateState.loading) loading$: Observable<boolean>;
  @Select(BulkUpdateState.validating) validating$: Observable<boolean>;
  @Select(BulkUpdateState.validCount) validCount$: Observable<number>;
  @Select(BulkUpdateState.invalidCount) invalidCount$: Observable<number>;
  @Select(BulkUpdateState.count) count$: Observable<number>;
  @Select(BulkUpdateState.entities) entities$: Observable<BulkUpdateModel[]>;
  @Select(BulkUpdateState.search) search$: Observable<string>;
  @Select(BulkUpdateState.filter) filter$: Observable<any>;

  enteredId: string;

  public titleFormatter: (entity: BulkUpdateModel) => string;
  public filterBarConfig: FiltersBarConfig;

  constructor(private store: Store, public bulkUpdateOptionsProvider: BulkUpdateOptionsProvider) {}

  ngOnInit(): void {
    this.titleFormatter = this.bulkUpdateOptionsProvider.getOptions().titleFormatter;
    this.filterBarConfig = this.bulkUpdateOptionsProvider.getOptions().filterBarConfig;
  }

  onEnterEntityId() {
    if (!this.enteredId) {
      return;
    }

    this.store.dispatch(new AddEntityAction(this.enteredId));
    this.enteredId = '';
  }

  onDelete(id: string) {
    this.store.dispatch(new RemoveEntityAction(id));
  }
}
