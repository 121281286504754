import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, take, tap } from 'rxjs/operators';

import { ACL } from '../../shared/acl/acl.service';
import { Organization } from '../shared/organization';
import { OrganizationAPI } from '../shared/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateRootDialogComponent, MODAL_OPTIONS } from '../shared/create-root-dialog/create-root-dialog.component';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { firstValueFrom, Observable } from 'rxjs';
import { BULK_MODAL_OPTIONS, BulkModalComponent } from '../../common/bulk/component/bulk-modal/bulk-modal.component';
import { Filter, FilterType } from '../../shared/components/filters/filters.component';
import { JobsAPI } from '../../jobs/shared/api.service';
import { AddressEntity } from '../shared/address-entity';
import { OrganizationAdvancedSearchDto } from '../shared/organization-advanced-search-dto';

const LOCAL_STORAGE_ADVANCED_SEARCH_FILTER_KEY = 'clean-organizations-advanced-search';

@Component({
  selector: 'dirt-organization-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class OrganizationListComponent implements OnInit {
  wndw: Window = window;

  organizations: (Organization & { addressesMapped: AddressEntity[] })[];
  orgIdToCountryListMap: Map<string, string>; // maps org id to a country list string

  isLoading = false;

  total: { count: number };
  pagingPage = 1;
  pagingLimit = 25;
  pagingSkip = 0;

  sort = '-_id';

  searchTerm: string;
  searchCtrl: FormControl = new FormControl('');
  filter: { [key: string]: any } = { transformed: ['false'] };
  filters: Filter[] = [
    {
      title: 'Transformed',
      key: 'transformed',
      type: FilterType.SINGLE_VALUE,
      values: [
        { title: 'true', displayValue: 'YES' },
        { title: 'false', displayValue: 'NO', selected: true },
      ],
    },
    {
      title: 'Status',
      key: '_meta.status',
      type: FilterType.MULTI_VALUE,
      values: [
        { displayValue: 'Compilation Ready', title: 'READY_FOR_COMPILATION' },
        { displayValue: 'In Progress', title: 'IN_PROGRESS' },
        { displayValue: 'In Type Compilation', title: 'IN_TYPE_COMPILATION' },
        { displayValue: 'Done', title: 'DONE' },
        { displayValue: 'Pending Removal', title: 'PENDING_REMOVAL' },
      ],
    },
  ];

  bulkListDelegate: BulkDelegate = {
    title: 'Bulk Import',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListUpload.bind(this),
    specificNotifyReloadFct: this.doLoad.bind(this),
  };

  jobBulkListDelegate: BulkDelegate = {
    title: 'Bulk Import Job',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListJobUpload.bind(this),
    specificNotifyReloadFct: this.doLoad.bind(this),
  };

  @ViewChild('moveToNewParentModal')
  moveToNewParentModal;
  orgToMove?: Organization;
  newParentOrg?: Organization;
  moveParentModal: any;

  @ViewChild('healthSystemMergeModal')
  healthSystemMergeModal;
  healthSystemMergeLoser?: Organization;
  healthSystemMergeWinner?: Organization;
  healthSystemMergeState: 'default' | 'pending' | 'done' = 'default';

  @ViewChild('advancedSearchDialog', { read: ElementRef }) advancedSearchDialogElement: ElementRef;
  advancedSearchSpec: OrganizationAdvancedSearchDto;

  constructor(
    private readonly router: Router,
    private readonly svcOrganization: OrganizationAPI,
    private readonly svcModal: NgbModal,
    private readonly svcAcl: ACL,
    private readonly svcJob: JobsAPI
  ) {}

  ngOnInit() {
    this.attachSearchHandler();
    this.doLoad();
  }

  attachSearchHandler(): void {
    this.searchCtrl.valueChanges.pipe(distinctUntilChanged(), debounceTime(400)).subscribe((val) => {
      // search field gets fired with `undefined` value after init
      if (typeof val !== 'undefined') {
        this.doLoad();
      }
    });
  }

  getOrganizations(): void {
    this.isLoading = true;

    if (this.sort === '-_id' && this.searchTerm) {
      // Remove sorting by id when term is not empty
      this.sort = '';
    } else if (!this.sort && !this.searchTerm) {
      // Revert sorting by id when term is empty
      this.sort = '-_id';
    }

    this.svcOrganization
      .find(true, this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.filter)
      .subscribe(
        (resp) => {
          this.organizations = resp;
          this.orgIdToCountryListMap = new Map(
            this.organizations.map((o) => [
              o._id,
              [...new Set((o.addressesMapped || []).map((a) => a.countryCode))].sort().join(', '),
            ])
          );
        },
        null,
        () => (this.isLoading = false)
      );
  }

  getCount() {
    delete this.total;

    this.svcOrganization.count(true, undefined, this.searchTerm, this.filter).subscribe((res) => (this.total = res));
  }

  getPage(page: number) {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    if (this.advancedSearchSpec) {
      this.runAdvancedSearch();
    } else {
      this.getOrganizations();
    }
  }

  onFilter(filter: { [key: string]: any }): void {
    this.filter = filter;
    this.doLoad();
  }

  doLoad(): void {
    this.storeFiltersAsQueryParams();
    this.resetPagination();
    this.getOrganizations();
    this.getCount();
  }

  private storeFiltersAsQueryParams(): void {
    if (this.searchTerm || this.sort) {
      const filters = {
        ...(this.searchTerm && { searchTerm: this.searchTerm }),
        ...(this.sort && { sortField: this.sort }),
      };
      this.router.navigate([], { queryParams: filters });
    }
  }

  resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  navigateTo(route) {
    this.router.navigate(route);
  }

  async onOpenCreateOrganizationDialog(): Promise<void> {
    const modal = this.svcModal.open(CreateRootDialogComponent, MODAL_OPTIONS);
    // Reload once done
    await modal.result;
    this.doLoad();
  }

  async onOpenCreateOrganizationDuringMoveToParentDialog(): Promise<void> {
    const modal = this.svcModal.open(CreateRootDialogComponent, MODAL_OPTIONS);
    const createdOrganization = await modal.result;

    if (createdOrganization) {
      this.newParentOrg = createdOrganization;
    }

    this.doLoad();
  }

  async onOpenBulkListDialog(): Promise<void> {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.bulkListDelegate;
    // Reload once done
    await modal.result;
    this.doLoad();
  }

  onOpenBulkListJobDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.jobBulkListDelegate;
  }

  async onOpenMoveChildToParentDialog(org: Organization): Promise<void> {
    if (!org) {
      return;
    }

    this.orgToMove = org;
    this.newParentOrg = null;
    this.moveParentModal = this.svcModal.open(this.moveToNewParentModal, MODAL_OPTIONS);
    await this.moveParentModal.result;
    this.organizations.splice(
      this.organizations.findIndex((o) => o._id === org._id),
      1
    );
  }

  async onMoveChildToParent(): Promise<void> {
    if (!this.newParentOrg || !this.orgToMove) {
      return;
    }
    if (!window.confirm(`Are you sure to move ${this.orgToMove.name} into ${this.newParentOrg.name}?`)) {
      return;
    }
    await firstValueFrom(
      this.svcOrganization
        .parentToChild({ orgId: this.orgToMove._id, newParentOrgId: this.newParentOrg._id })
        .pipe(take(1))
    );
    this.moveParentModal.close();
  }

  private handleBulkListUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcOrganization.startBulkListUpload(file, secondEyes);
  }

  private handleBulkListJobUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcJob.startBulkListOrganizationJobUpload(file, secondEyes);
  }

  setPriority(organization: Organization, priority: number): void {
    if (!priority) {
      return;
    }

    this.svcOrganization.update(organization._id, { priority: priority } as Partial<Organization>).subscribe(() => {});
  }

  isPriorityEditable(): boolean {
    return this.svcAcl.hasCredential('organization.update.prop.priority');
  }

  openAdvancedSearch() {
    this.advancedSearchSpec = JSON.parse(
      this.wndw.localStorage.getItem(LOCAL_STORAGE_ADVANCED_SEARCH_FILTER_KEY) || '{"filter": { "address": {} }}'
    );
    this.advancedSearchDialogElement.nativeElement.show();
  }

  async runAdvancedSearch() {
    if (this.isAdvancedSearchDisabled()) {
      return;
    }

    this.advancedSearchDialogElement?.nativeElement?.close();
    // take over search & page & sort
    this.advancedSearchSpec.size = this.pagingLimit;
    this.advancedSearchSpec.page = this.pagingPage - 1;

    const sortMatch = /^([\+|-]?)(.+)$/.exec(this.sort || '');
    if (sortMatch) {
      this.advancedSearchSpec.sortDesc = '+' !== sortMatch[1];
      this.advancedSearchSpec.sort = sortMatch[2];
    }

    // persist to localstorage
    localStorage.setItem(LOCAL_STORAGE_ADVANCED_SEARCH_FILTER_KEY, JSON.stringify(this.advancedSearchSpec));
    // actually pull results
    this.isLoading = true;

    try {
      const res = await firstValueFrom(this.svcOrganization.advancedSearch(this.advancedSearchSpec));
      this.organizations = res.items;
      this.total = { count: res.total };
    } finally {
      this.isLoading = false;
    }
  }

  clearAdvancedSearch() {
    this.advancedSearchDialogElement.nativeElement.close();
    this.advancedSearchSpec = null;
    this.wndw.localStorage.removeItem(LOCAL_STORAGE_ADVANCED_SEARCH_FILTER_KEY);
    this.sort = '-_id'; // clear sort when clearing search
    this.doLoad();
  }

  isAdvancedSearchDisabled(): boolean {
    const isEmptyObject = (o: any) => Object.values(o || {}).filter((f) => !!f).length < 1;
    return (
      !this.advancedSearchSpec ||
      isEmptyObject(this.advancedSearchSpec.filter) ||
      (isEmptyObject(this.advancedSearchSpec.filter.address) &&
        !this.advancedSearchSpec.filter.websource &&
        !this.advancedSearchSpec.filter.organizationId &&
        !this.advancedSearchSpec.filter.organizationName)
    );
  }

  async onOpenHealthSystemMergeDialog(org: Organization): Promise<void> {
    if (!org) {
      return;
    }

    this.healthSystemMergeLoser = org;
    this.healthSystemMergeWinner = null;
    this.svcModal.open(this.healthSystemMergeModal, MODAL_OPTIONS);
  }

  async onMergeHealthSystem(): Promise<void> {
    if (!this.healthSystemMergeWinner || !this.healthSystemMergeLoser) {
      return;
    }
    if (
      !window.confirm(
        `Are you sure to merge ${this.healthSystemMergeLoser.name} into ${this.healthSystemMergeWinner.name}?`
      )
    ) {
      return;
    }

    this.healthSystemMergeState = 'pending';
    await firstValueFrom(
      this.svcOrganization
        .mergeOrganizations({
          loserId: this.healthSystemMergeLoser._id,
          winnerId: this.healthSystemMergeWinner._id,
          mergeChildren: true,
        })
        .pipe(tap(() => (this.healthSystemMergeState = 'done')))
    );
    this.organizations.splice(
      this.organizations.findIndex((o) => o._id === this.healthSystemMergeLoser._id),
      1
    );
  }

  onHealthSystemMergeReset() {
    this.healthSystemMergeLoser = null;
    this.healthSystemMergeWinner = null;
    this.healthSystemMergeState = 'default';
  }

  onHealthSystemMergeCancel() {
    this.onHealthSystemMergeReset();
    this.svcModal.dismissAll();
  }
}
