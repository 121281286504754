import { NgModule } from '@angular/core';

import { AccountMedicalInformationAPI } from './shared/account-medical-information-api.service';
import { AccountMedicalInformationComponent } from './account-medical-information.component';
import { AccountMedicalInformationCreateComponent } from './create/create.component';
import { AccountMedicalInformationDetailComponent } from './detail/detail.component';
import { AccountMedicalInformationFormComponent } from './shared/form/form.component';
import { AccountMedicalInformationListComponent } from './list/list.component';
import { accountMedicalInformationRouting } from './account-medical-information.routes';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [SharedModule, accountMedicalInformationRouting],
  declarations: [
    AccountMedicalInformationComponent,
    AccountMedicalInformationListComponent,
    AccountMedicalInformationCreateComponent,
    AccountMedicalInformationDetailComponent,
    AccountMedicalInformationFormComponent,
  ],
  exports: [AccountMedicalInformationListComponent],
  providers: [AccountMedicalInformationAPI],
})
export class AccountMedicalInformationModule {}
