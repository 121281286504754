import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProfileTag } from '../constant/profile-tag.enum';
import { Utils } from '../../../common/utils';

@Component({
  selector: 'dirt-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class ProfileTagsComponent implements OnInit {
  @Input()
  tags: ProfileTag[] = [];

  @Input()
  readonly = false;

  @Input()
  disabled = false;

  @Input()
  alignLeft = false;

  @Output()
  onTagsChange = new EventEmitter<ProfileTag[]>();

  ProfileTag = ProfileTag;

  tagClassMap = {
    [ProfileTag.Followed]: 'badge-danger',
  };

  constructor() {}

  ngOnInit() {}

  getTagClass(tag) {
    if (this.tagClassMap.hasOwnProperty(tag)) {
      return this.tagClassMap[tag];
    } else {
      return 'badge-default';
    }
  }

  onClick(tag) {
    if (!this.tags) {
      this.tags = [];
    }
    if (!this.tags.includes(tag)) {
      this.tags.push(tag);
    }
    this.onTagsChange.emit(this.tags);
  }

  onRemoveClick(tag) {
    Utils.removeItem(this.tags, tag);
    this.onTagsChange.emit(this.tags);
  }
}
