import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ProfileAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-profile-video-next',
  templateUrl: 'video-next.component.html',
  styleUrls: ['video-next.component.scss'],
})
export class ProfileVideoNextComponent implements OnInit {
  isLoadingNext = false;

  constructor(private router: Router, private svcProfile: ProfileAPI) {}

  ngOnInit() {}

  getNext(): void {
    this.isLoadingNext = true;

    this.svcProfile.findNextVideoCuration().subscribe(
      (profile) => {
        if (!profile || !profile.id) {
          return alert('No Profiles to be processed at the moment! Go and get some rest ;)');
        }

        this.router.navigate(['/profile/detail', profile.id]);
      },
      () => alert('Error!'),
      () => (this.isLoadingNext = false)
    );
  }
}
