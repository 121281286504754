import { Routes, RouterModule } from '@angular/router';

import { AclGuard } from '../shared/acl/guard.service';
import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { DocumentComponent } from './document.component';
import { DocumentCreateComponent } from './create/create.component';
import { DocumentDetailComponent } from './detail/detail.component';
import { DocumentListComponent } from './list/list.component';
import { DocumentModule } from './document.module';
import { ModuleWithProviders } from '@angular/core';
import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';

const documentRoutes: Routes = [
  {
    path: 'document',
    component: DocumentComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'create',
        component: DocumentCreateComponent,
      },
      {
        path: 'detail/:id',
        component: DocumentDetailComponent,
      },
      {
        path: 'list',
        component: DocumentListComponent,
        data: { acl: 'document.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
    data: { title: 'Document' },
  },
];

export const documentRouting: ModuleWithProviders<DocumentModule> = RouterModule.forChild(documentRoutes);
