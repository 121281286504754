<div class="di-container">
  <ng-select
    [items]="items$ | async"
    bindLabel="displayLabel"
    [ngModel]="selectedUsers"
    [multiple]="true"
    [disabled]="disabled || loadingInitialUsers"
    [required]="required"
    [loading]="isLoading$ | async"
    [hideSelected]="true"
    (ngModelChange)="onSelectedUsersChange($event)"
    [typeahead]="input$"
  >
  </ng-select>
</div>
