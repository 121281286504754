import * as auth0 from 'auth0-js';
import { Injectable } from '@angular/core';
import { Observable, Subscriber, of, firstValueFrom } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { ACL } from '../../acl/acl.service';
import { User } from '../../../user/shared/user';
import { UserAPI } from '../../../user/shared/api.service';
import { TOKEN_NAME } from './auth.constants';

const TOKEN_REFRESH_START = 'lastTokenRefreshStart';
const REDIRECT_NAME = 'redirectUrl';

@Injectable()
export class Auth {
  private profile: any;
  private profileObservable: Observable<any>;

  private webAuth = new auth0.WebAuth({
    clientID: environment.auth0ClientId,
    domain: environment.auth0Domain,
    redirectUri: `${environment.clientEndpoint}login`,
    responseType: 'token',
    scope: 'openid profile',
  });

  private tokenRefreshingSet: boolean = false;

  public static getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }

  public static get redirectUrl(): string {
    return localStorage.getItem(REDIRECT_NAME);
  }

  public static set redirectUrl(url: string) {
    if (url && url.length > 0) {
      localStorage.setItem(REDIRECT_NAME, url);
    } else {
      localStorage.removeItem(REDIRECT_NAME);
    }
  }

  /**
   * Logout and clean current token from the local storage
   *
   * @method logout
   */
  public static logout(): void {
    localStorage.removeItem(TOKEN_NAME);
  }

  constructor(
    private readonly svcJwtHelper: JwtHelperService,
    private acl: ACL,
    private svcUser: UserAPI,
    private readonly http: HttpClient
  ) {}

  /**
   * Indicates whether or not this user is authenticated.
   *
   * @method isAuthenticated
   * @returns {Boolean} true, if this user is authenticated, otherwise false.
   */
  public isAuthenticated(): boolean {
    return !this.svcJwtHelper.isTokenExpired(Auth.getToken());
  }

  /**
   * Authorize the user by handing the auth process to auth0 universal login page
   *
   * @method authorize
   */
  public authorize(): void {
    this.webAuth.authorize();
  }

  /**
   * Login a user using email and password
   *
   * @method login
   * @param {String} email - user's email
   * @param {String} password - user's password
   * @param {Function} callback
   */
  public login(email: string, password: string, callback?): void {
    this.webAuth.login({ email, password }, callback);
  }

  /**
   * Query current user's full profile
   *
   * @method getProfile
   * @returns {Observable<any>} Observable containing the user's profile
   */
  public getProfile(): Observable<User> {
    if (this.profile) {
      return of(this.profile);
    }
    if (this.profileObservable) {
      return this.profileObservable; // answer will come - but do not call a hundred times until first result
    }

    this.profileObservable = this.svcUser.findMe().pipe(tap((profile: User) => this.setProfile(profile)));
    return this.profileObservable;
  }

  /**
   * Extract the token from the url and store it in local storage
   *
   * @method resolveToken
   * @returns {Observable<void>} Observable
   */
  public resolveToken(): Observable<void> {
    return new Observable((o: Subscriber<void>) => {
      this.webAuth.parseHash(async (err, result) => {
        if (err) {
          alert(`Auth Error: ${err.error}\n${err.errorDescription}`);
          return o.error(err);
        }

        if (result) {
          const storedToken = localStorage.getItem(TOKEN_NAME);
          const token = result.idToken || localStorage.getItem(TOKEN_NAME);

          if (result.error) {
            const desc = result.error_description || '';
            return alert(`Auth Error: ${result.error}\n${desc}`);
          }

          if (token) {
            localStorage.setItem(TOKEN_NAME, token);
            this.scheduleTokenRefreshing();
          }

          try {
            if (storedToken !== token) {
              // We need to save the current token
              await firstValueFrom(this.http.post(environment.apiEndpoint + 'auth/save-token', JSON.stringify({})));
            }
          } catch (err) {
            // cleanup
            Auth.logout();
            this.webAuth.logout({ returnTo: `${environment.clientEndpoint}login` });
          }
        }

        o.next();
        o.complete();
      });
    });
  }

  /**
   * Set the user profile and make sure roles are applied to ACL
   *
   * @method setProfile
   * @param {Object} profile - user's profile
   */
  private setProfile(profile: User): void {
    this.profile = profile;
    this.acl.grantRoles(profile.app_metadata.roles, true);
  }

  private renewToken() {
    localStorage.setItem(TOKEN_REFRESH_START, Date.now().toString());
    this.webAuth.checkSession({}, async (err, result) => {
      const storedToken = localStorage.getItem(TOKEN_NAME);
      const token = result.idToken || localStorage.getItem(TOKEN_NAME);

      if (err) {
        const desc = err.error_description || '';
        console.error(`Auth Error: ${err.error}\n${desc}`);
        return;
      }

      if (token) {
        localStorage.setItem(TOKEN_NAME, token);
      }

      try {
        if (storedToken !== token) {
          // We need to save the current token
          await firstValueFrom(this.http.post(environment.apiEndpoint + 'auth/save-token', JSON.stringify({})));
        }
      } catch (err) {
        // cleanup
        Auth.logout();
        this.webAuth.logout({ returnTo: `${environment.clientEndpoint}login` });
      }
    });
  }

  private getTokenExpiresInMS() {
    const token = this.svcJwtHelper.decodeToken(Auth.getToken());
    return ((token || {}).exp || 0) * 1000; //converting to MS
  }

  public scheduleTokenRefreshing() {
    if (!this.tokenRefreshingSet) {
      this.tokenRefreshingSet = true;
      const refreshInterval = 20 * 1000; //20 sec
      setInterval(() => {
        const lastTokenRefreshTime = localStorage.getItem(TOKEN_REFRESH_START);
        const shouldAbortRefresh = lastTokenRefreshTime && Date.now() - parseInt(lastTokenRefreshTime) <= 10 * 1000;
        const expiresIn = this.getTokenExpiresInMS();
        if (expiresIn - Date.now() <= 60 * 1000 && !shouldAbortRefresh) {
          this.renewToken();
        }
      }, refreshInterval);
    }
  }

  public async logout(): Promise<void> {
    try {
      await firstValueFrom(this.http.post(environment.apiEndpoint + 'auth/revoke-token', JSON.stringify({}))); // Explicitly revoke token
    } catch (error) {
      console.warn('Failed to revoke token. Will delete it anyway');
    } finally {
      // Always cleanup the token
      Auth.logout();
      this.webAuth.logout({ returnTo: `${environment.clientEndpoint}login` });
    }
  }
}
