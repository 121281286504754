import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FiltersBarConfig } from './filters-bar-config';
import { Utils } from '../../../common/utils';

@Component({
  selector: 'dirt-filters-bar',
  templateUrl: './filters-bar.component.html',
  styleUrls: ['./filters-bar.component.scss'],
})
export class FiltersBarComponent implements OnInit {
  @Input()
  filter: any;

  @Output()
  filterClear = new EventEmitter();

  @Output()
  filterValueRemove = new EventEmitter<{ key: string; value: any }>();

  @Input()
  config: FiltersBarConfig;

  @Input()
  readonly: boolean;

  constructor() {}

  getLabel(key: string) {
    const conf = this.config[key];
    if (!conf) {
      return key;
    }

    return conf.label;
  }

  getValueFromMap(key, value) {
    const conf = this.config[key];
    if (!conf) {
      return value;
    }

    const val = !conf.values ? value : conf.values[value];

    if (conf.format) {
      return conf.format(val, this.filter);
    }

    return val;
  }

  isArray(value: any) {
    return Array.isArray(value);
  }

  isFiltersEmpty() {
    return !Utils.isEmptyObject(this.filter);
  }

  onFilterClearClicked() {
    this.filterClear.emit();
  }

  onFilterValueRemove(key, value) {
    this.filterValueRemove.emit({ key, value });
  }

  ngOnInit() {}
}
