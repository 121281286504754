import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationMaintenanceJobComponent } from './organization-maintenance-job.component';

@Injectable()
export class OrganizationMaintenanceJobModalService {
  constructor(private svcModal: NgbModal) {}

  open(organizationId: string, isCustomerRequest?: boolean, requestOwnerProduct?: string): Promise<{ ok: boolean }> {
    const ref = this.svcModal.open(OrganizationMaintenanceJobComponent);
    ref.componentInstance.organizationId = organizationId;
    ref.componentInstance.isCustomerRequest = !!isCustomerRequest;
    ref.componentInstance.requestOwnerProduct = requestOwnerProduct;
    return ref.result;
  }
}
