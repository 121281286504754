import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ColDef, ColGroupDef, RowNode } from 'ag-grid';
import { ValueFormatterParams } from 'ag-grid/dist/lib/entities/colDef';

import { ACL } from '../../../../shared/acl/acl.service';
import { ProfileGridComponent } from '../../../shared/grid/grid.component';
import { ProfileDirectLinkService } from '../../../shared/modal/direct-link/direct-link.service';
import { ProfileDirectLinkOptions } from '../../../shared/modal/direct-link/direct-link-options.interface';
import { ClinicalTrial } from '../../../../clinical-trial/shared/clinical-trial';
import { ClinicalTrialAPI } from '../../../../clinical-trial/shared/clinical-trial-api.service';
import { ProfileClinicalTrialAPI } from '../shared/api.service';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { QCCommentService } from '../../../shared/grid/qc-comment/qc-comment.service';
import { ProfileClinicalTrial } from '../../../shared/profile-clinical-trial';

@Component({
  selector: 'dirt-profile-trial-list',
  templateUrl: '../../../shared/grid/grid.component.html',
  styleUrls: ['../../../shared/grid/grid.component.scss'],
})
export class ProfileClinicalTrialListComponent extends ProfileGridComponent implements OnInit {
  FIELD_NCTID = 'nct';
  FIELD_INVESTIGATOR_FIRST_NAME = 'investigator.firstName';
  FIELD_INVESTIGATOR_LAST_NAME = 'investigator.lastName';
  FIELD_INVESTIGATOR_MIDDLE_NAME = 'investigator.middleName';
  FIELD_INVESTIGATOR_POSITION = 'investigator.position';
  FIELD_INVESTIGATOR_CITY = 'investigator.address.city';
  FIELD_INVESTIGATOR_COUNTRY = 'investigator.address.country';
  FIELD_INVESTIGATOR_FACILITY = 'investigator.facility';
  FIELD_CONDITION = 'clinical_trial.condition';
  FIELD_TITLE = 'clinical_trial.title';
  FIELD_PURPOSE = 'clinical_trial.purpose';

  FIELD_FILTER_CITY = 'city';
  FIELD_FILTER_COUNTRY = 'country';

  constructor(
    protected svcProfileTrial: ProfileClinicalTrialAPI,
    private svcClinicalTrial: ClinicalTrialAPI,
    private svcDirectLink: ProfileDirectLinkService,
    protected readonly svcACL: ACL,
    protected svcQCComment: QCCommentService
  ) {
    super('id', svcACL, svcQCComment);
  }

  ngOnInit() {
    this.addFilter(this.FIELD_INVESTIGATOR_POSITION, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_INVESTIGATOR_POSITION, params)
    );
    this.addFilter(this.FIELD_INVESTIGATOR_FIRST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_INVESTIGATOR_FIRST_NAME, params)
    );
    this.addFilter(this.FIELD_INVESTIGATOR_MIDDLE_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_INVESTIGATOR_MIDDLE_NAME, params)
    );
    this.addFilter(this.FIELD_INVESTIGATOR_LAST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_INVESTIGATOR_LAST_NAME, params)
    );
    this.addFilter(this.FIELD_FILTER_CITY, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_CITY, params)
    );
    this.addFilter(this.FIELD_FILTER_COUNTRY, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_COUNTRY, params)
    );
    this.addFilter(this.FIELD_KEY_FA, true, 'keep', false, (params) =>
      this.defineGetFocusArea().subscribe((result) =>
        result && result[0] && result[0].focusArea
          ? params.success(result[0].focusArea?.map((val) => val.name))
          : params.success('')
      )
    );

    super.ngOnInit();
  }

  protected defineDistinct(profileId: string, field: string, filter: any): Observable<string[]> {
    return this.svcProfileTrial.distinct(this.profile, field, filter);
  }

  protected defineUpdate(
    profileId: string,
    ids: string[],
    value: ProfileClinicalTrial[]
  ): Observable<{ id; compiledAt? }[]> {
    return this.svcProfileTrial.update(this.profile, value);
  }

  protected defineGetDataRows(...params): Observable<any> {
    return this.svcProfileTrial.find.apply(this.svcProfileTrial, [this.profile, ...params.slice(1)]); // migrating profileId to profile
  }

  protected defineGetFocusArea(): Observable<any> {
    return this.svcProfileTrial.distinctFocusArea(this.profile);
  }

  protected defineGrid(): (ColDef | ColGroupDef)[] {
    const columnDefs: (ColDef | ColGroupDef)[] = [
      {
        headerName: 'Investigator',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'First Name',
            field: this.FIELD_INVESTIGATOR_FIRST_NAME,
            filterParams: this.filters.get(this.FIELD_INVESTIGATOR_FIRST_NAME),
            width: 90,
          },
          {
            headerName: 'Middle Name',
            field: this.FIELD_INVESTIGATOR_MIDDLE_NAME,
            filterParams: this.filters.get(this.FIELD_INVESTIGATOR_MIDDLE_NAME),
            width: 70,
          },
          {
            headerName: 'Last Name',
            field: this.FIELD_INVESTIGATOR_LAST_NAME,
            filterParams: this.filters.get(this.FIELD_INVESTIGATOR_LAST_NAME),
            width: 90,
          },
          {
            headerName: 'Position',
            field: this.FIELD_INVESTIGATOR_POSITION,
            filterParams: this.filters.get(this.FIELD_INVESTIGATOR_POSITION),
            columnGroupShow: 'open',
            width: 80,
          },
          {
            headerName: 'Facility',
            field: this.FIELD_INVESTIGATOR_FACILITY,
            filter: 'text',
            width: 180,
          },
        ],
      },
      {
        colId: 'link',
        headerName: 'Link',
        field: this.FIELD_NCTID,
        cellRenderer: this.rendererLinks,
        suppressMenu: true,
        width: 35,
      },
      {
        headerName: 'ID',
        field: this.FIELD_NCTID,
        filter: 'text',
        width: 70,
      },
      {
        headerName: 'Clinical Trial',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'Title',
            field: this.FIELD_TITLE,
            filter: 'text',
            width: 300,
          },
          {
            headerName: 'Purpose',
            field: this.FIELD_PURPOSE,
            filter: 'text',
          },
          {
            headerName: 'Condition',
            field: this.FIELD_CONDITION,
            filter: 'text',
            width: 120,
          },
          {
            headerName: 'City',
            field: this.FIELD_INVESTIGATOR_CITY,
            filterParams: this.filters.get(this.FIELD_FILTER_CITY),
            columnGroupShow: 'open',
            width: 70,
          },
          {
            headerName: 'Country',
            field: this.FIELD_INVESTIGATOR_COUNTRY,
            filterParams: this.filters.get(this.FIELD_FILTER_COUNTRY),
            width: 70,
          },
        ],
      },
      {
        headerName: 'Focus Area',
        field: this.FIELD_KEY_FA,
        filterParams: this.filters.get(this.FIELD_KEY_FA),
        width: 300,
      },
    ];

    return columnDefs;
  }

  protected defineAutoSizableColumns(): string[] {
    return [
      // this.FIELD_FIRST_NAME, // First Name
      // this.FIELD_LAST_NAME  // Last Name
    ];
  }

  protected onDirectLink(): void {
    let selectedPosition;

    const options = <ProfileDirectLinkOptions>{
      title: 'Clinical Trial',
      positionSet: ['Investigator', 'Principal Investigator', 'Sub Investigator'],
      setPosition: (position) => (selectedPosition = position),
      getEntity: (id) => this.svcClinicalTrial.findById(id),
      getData: (p: ClinicalTrial) => ({ name: p.title }),
    };

    this.svcDirectLink
      .open(options)
      .then((entity: ClinicalTrial) =>
        this.svcProfileTrial.link(this.profile, entity.externalIds.nct, { position: selectedPosition }).toPromise()
      )
      .then(() => this.reloadData());
  }

  /**
   * Renderer function for Links columns.
   *
   * @param {ValueFormatterParams} param
   * @return {string}
   */
  rendererLinks(param: ValueFormatterParams): string {
    if (!param.data) {
      return '';
    }
    const trialId = param.data.nct;
    const isJrct =
      (param.data.source || '').toLowerCase() === 'jrct' || (trialId || '').substring(0, 4).toLowerCase() === 'jrct';
    const url = isJrct
      ? 'https://jrct.niph.go.jp/en-latest-detail/' + trialId
      : 'https://clinicaltrials.gov/ct2/show/' + trialId;
    const linkWS = trialId ? `<a href="${url}" rel=noopener target="_blank">CT</a>` : '';
    return `${linkWS}`;
  }

  /** @override */
  updateCommentAndProfileMatch(comment: string, matched: boolean, errorType: string): void {
    const nodes = this.getSelection();
    const match = matched ? ProfileMatchManual.MATCH : ProfileMatchManual.MISMATCH;

    nodes.forEach((node: RowNode) => {
      const data = Object.assign({}, node.data);

      data._profile.match.manual = match;

      // even if comment and errorType are null we should set qc so that the bug icon disappears
      data._profile.qc = { comment, errorType };

      node.setData(data);
    });

    this.update(
      nodes,
      nodes.map(
        (n) =>
          ({
            investigator: n.data._profile.investigator,
            nct: n.data._profile.nct,
            match: n.data._profile.match,
            person: { id: this.profile.person.id, kolId: this.profile.person.kolId },
            profileId: this.profileId,
            qc: n.data._profile.qc,
          } as Partial<ProfileClinicalTrial>)
      )
    );
  }
}
