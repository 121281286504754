import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';
import { AdminComponent } from './admin.component';
import { AdminNextComponent } from './next/next.component';
import { AdminForbiddenComponent } from './forbidden/forbidden.component';
import { AdminModule } from './admin.module';
import { NoopComponent } from './noop/noop.component';

const adminRoutes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      // just get guard.service.ts to redirect as appropriate
      {
        path: '',
        redirectTo: 'noop/noop',
        pathMatch: 'full',
      },
      {
        path: 'noop',
        component: AdminComponent,
        data: { acl: 'noop.noop' },
        children: [
          {
            path: 'noop',
            component: NoopComponent,
          },
        ],
      },
      {
        path: 'id-next', // Alias for routing precedence
        component: AdminNextComponent,
        data: { acl: 'identify' },
      },
      {
        path: 'idqc-next', // Alias for routing precedence
        component: AdminNextComponent,
        data: { acl: 'identifyqc' },
      },
      {
        path: 'next',
        component: AdminNextComponent,
      },
    ],
  },
  {
    path: 'forbidden',
    component: AdminForbiddenComponent,
  },
];

export const adminRouting: ModuleWithProviders<AdminModule> = RouterModule.forChild(adminRoutes);
