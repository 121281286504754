import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ProfileVideoLinkComponent } from './video-link.component';
import { ProfileVideoLinkOptions } from './video-link.interface';

@Injectable()
export class ProfileVideoLinkService {
  constructor(private svcModal: NgbModal) {}

  open(options: ProfileVideoLinkOptions): Promise<any> {
    const ref = this.svcModal.open(ProfileVideoLinkComponent, {
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
    });

    ref.componentInstance.options = options;

    return ref.result;
  }
}
