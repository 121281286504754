import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIService } from '../../shared/services/api/api.service';
import { Body, GET, POST, MediaType, Produces, Path, PATCH, Query } from '../../shared/services/rest/rest.service';
import {
  CuratedFocusArea,
  MedicalInsightsNote,
  MedicalInsightsProfile,
  TaxonomyCategory,
  ViewFocusArea,
} from './medical-insights-profile';
import { splitStream, parseJSONStream, filterStream } from '../../profile/subsection/stream.utils';

@Injectable()
export class MedicalInsightsProfileAPI extends APIService {
  get modulePath(): string {
    return 'medical-insight-profile';
  }

  @GET('medical-insight-profile/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<any> {
    return null;
  }

  @GET('medical-insight-profile/full-fa-list')
  @Produces(MediaType.JSON)
  public getOurFocusAreas(): Observable<Array<ViewFocusArea>> {
    return;
  }

  public async getNote(@Path('id') noteId: string): Promise<any> {
    const options: RequestInit = {
      headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
    };
    const response: Response = await fetch(`${this.getBaseUrl()}${this.modulePath}/note/${noteId}`, options); // (we want a stream now)

    if (!response.ok) {
      throw new Error('Failed to download note.');
    }

    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(splitStream('\n'))
      .pipeThrough(filterStream((row: string) => row.includes(noteId))) // save us some JSON parsing
      .pipeThrough(parseJSONStream())
      .pipeThrough(filterStream((row: MedicalInsightsNote) => row.veeva_object_id === noteId)) // Catch the very few cases that we could leave out
      .getReader();

    while (true) {
      const result = await reader.read(); // Leave timeout to Nginx

      if (!result || result.done) {
        break; // Everything was read
      }

      // We are done
      reader.cancel();

      return result.value;
    }
  }

  @PATCH('medical-insight-profile/{id}')
  @Produces(MediaType.JSON)
  public save(@Path('id') id: string, @Body params: Partial<MedicalInsightsProfile>): Observable<any> {
    return;
  }

  @POST('medical-insights-profile/submit-job')
  submitJob(@Body params: Partial<MedicalInsightsProfile>): Observable<any> {
    return;
  }

  /**
   * Find all the audit logs of a given model id
   * @param id
   */
  @GET('medical-insights-profile/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  /**
   * Find count of audit logs
   * @param id
   */
  @GET('medical-insights-profile/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }

  @POST('medical-insights-profile/{id}/replicate')
  @Produces(MediaType.JSON)
  public replicate(@Path('id') id: string, @Body payload: any): Observable<string[]> {
    return;
  }

  /**
   * Find the parent node in training of this veeva_object_id
   * and return the correct FAs and taxonomies as submitted there.
   * Used in auto-correction when training node is completed.
   * @param id
   */
  @GET('medical-insight-profile/trained-note/{id}')
  @Produces(MediaType.JSON)
  public getTrainingParentNodeInput(
    @Path('id') id: string
  ): Observable<{ correctSelectedFAs: CuratedFocusArea[]; correctSelectedTaxonomies: TaxonomyCategory[] }> {
    return;
  }
}
