import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GET,
  PUT,
  POST,
  DELETE,
  Path,
  Body,
  Query,
  MediaType,
  Produces,
  PATCH,
  BaseUrl,
} from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { Person } from './person';
import { User } from '../../user/shared/user';
import { catchError, map } from 'rxjs/operators';
import { PersonBaseinfo } from './person-baseinfo';
import { PersonDuplicateTakeover } from './person-dup-takeover';
import { PersonAdvancedSearchDto } from './person-advanced-search-dto';

@Injectable()
export class PersonAPI extends APIService {
  conflictInterceptor(error): string {
    if (error && error.code === 'DUPLICATE_EXTERNAL_ID') {
      return ''; // let caller handle
    }
    return super.conflictInterceptor(error);
  }

  @GET('people/')
  @Produces(MediaType.JSON)
  public find(
    @Query('name') name?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<(Person & { eu: boolean })[]> {
    return;
  }

  @GET('people/drafts')
  @Produces(MediaType.JSON)
  public drafts(): Observable<{ limit: number; current: Partial<Person>[] }> {
    return;
  }

  @GET('people/{id}')
  @Produces(MediaType.JSON)
  public findById(
    @Path('id') id: string,
    @Query('trainingMode') trainingMode?: boolean,
    @Query('withQcFromSession') withQcFromSession?: string
  ): Observable<Person> {
    return;
  }

  @GET('people/count')
  @Produces(MediaType.JSON)
  public count(
    @Query('name') name?: string,
    @Query('filter') filter?: any,
    @Query('optional') optional?: boolean
  ): Observable<any> {
    return;
  }

  @GET('people/search')
  @Produces(MediaType.JSON)
  public search(
    @Query('term') term: string,
    @Query('long') long?: boolean,
    @Query('curatedOnly') curatedOnly?: boolean
  ): Observable<Person[]> {
    return;
  }

  @POST('people/advanced-search')
  @Produces(MediaType.JSON)
  public advancedSearch(@Body searchDto: PersonAdvancedSearchDto): Observable<{ items: Person[]; total: number }> {
    return;
  }

  @POST('people')
  @Produces(MediaType.JSON)
  public create(@Body person: Person): Observable<Person> {
    return;
  }

  @PUT('people?makeDraft=true')
  @Produces(MediaType.JSON)
  public upsertDraft(@Body person: Person): Observable<Person> {
    return;
  }

  @PUT('people')
  @Produces(MediaType.JSON)
  public upsert(@Body person: Person): Observable<Person> {
    return;
  }

  @POST('people/submit-job')
  @Produces(MediaType.JSON)
  public submitJob(@Body person: Person): Observable<Person> {
    return;
  }

  @PATCH('people/{id}')
  @Produces(MediaType.JSON)
  public update(
    @Path('id') id: string,
    @Body person: Partial<Person>,
    @Query('forceNotClaimed') forceNotClaimed?: boolean
  ): Observable<Person> {
    return;
  }

  @POST('people/report-duplicate')
  @Produces(MediaType.JSON)
  public reportDuplicate(@Body person: PersonDuplicateTakeover): Observable<Person> {
    return;
  }

  @POST('people/unreport-duplicate')
  @Produces(MediaType.JSON)
  public unreportDuplicate(@Body person: { discardKolId; newMeta }): Observable<Person> {
    return;
  }

  @DELETE('people/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<void> {
    return;
  }

  @GET('people/next')
  @Produces(MediaType.JSON)
  public findNext(@Query('draftId') draftId?: string): Observable<Person> {
    return;
  }

  @GET('people/id-next')
  @Produces(MediaType.JSON)
  public findIdentNext(@Query('draftId') draftId?: string): Observable<Person> {
    return;
  }

  @POST('people/baseinfo')
  @Produces(MediaType.JSON)
  public getBaseinfo(@Body kolIds: string[]): Observable<PersonBaseinfo[]> {
    return;
  }

  /**
   * Find all the distinct values of the `review.by` field
   */
  @GET('people/reviewers')
  @Produces(MediaType.JSON)
  public reviewers(): Observable<User[]> {
    return;
  }

  /**
   * Find all the distinct values of the `tag` field
   *
   * @param name
   */
  @GET('people/tags')
  @Produces(MediaType.JSON)
  public findTags(@Query('name') name?: string): Observable<string[]> {
    return null;
  }

  /**
   * Find models which are part of the given user's workflow
   *
   * @method queue
   * @param {String} userId
   * @returns {Observable}
   */
  @GET('people/queue')
  @Produces(MediaType.JSON)
  public queue(@Query('userId') userId?: string): Observable<any> {
    return;
  }

  /**
   * Find models which are part of the given user's ident workflow
   *
   * @method identQueue
   * @param {String} userId
   * @returns {Observable}
   */
  @GET('people/id-queue')
  @Produces(MediaType.JSON)
  public identQueue(@Query('userId') userId?: string): Observable<any> {
    return;
  }

  /**
   * Find all the audit logs of a given model id
   * @param id
   */
  @GET('people/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  /**
   * Find all the check findings of a given kolId
   * @param id
   */
  @GET('people/{kolId}/check-findings/{type}')
  @Produces(MediaType.JSON)
  public checkFindings(
    @Path('kolId') kolId: string,
    @Path('type') type: string,
    @Query('onlyDone') onlyDone?: boolean
  ): Observable<{ type; checkDate; entityId; autoDone; problemField; problemValue }[]> {
    return;
  }

  /**
   * Find count of audit logs
   * @param id
   */
  @GET('people/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }

  /**
   * Delete the person and mark it as merged with another person
   *
   * @method merge
   * @param {String} id
   * @param {String} winnerId
   * @returns {Observable}
   */
  @POST('people/{id}/merge/{winnerId}')
  @Produces(MediaType.JSON)
  public merge(@Path('id') id: string, @Path('winnerId') winnerId: string): Observable<any> {
    return;
  }

  /**
   * Upload the file and get a bulk upload ID
   *
   * @param file
   * @param secondEyes
   */
  public startBulkListUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'people/bulk-list', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  public startBulkListTwitterUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'people/bulk-list-twitter', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  public startBulkListComplianceUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'people/bulk-list-compliance', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }

  @GET('people/qc-queue')
  @Produces(MediaType.JSON)
  public qcQueue(): Observable<any> {
    return;
  }

  @GET('people/id-qc-queue')
  @Produces(MediaType.JSON)
  public idQcQueue(): Observable<any> {
    return;
  }

  @GET('people/idbd-qc-queue')
  @Produces(MediaType.JSON)
  public idBdQcQueue(): Observable<{ personalDone; regionDone; regionAll }> {
    return;
  }

  @GET('people/qc-next')
  @Produces(MediaType.JSON)
  public findQCNext(): Observable<string | undefined> {
    return;
  }

  @GET('people/id-qc-next')
  @Produces(MediaType.JSON)
  public findIDQCNext(@Query('exceedCap') exceedCap?: boolean): Observable<string | undefined> {
    return;
  }

  @GET('people/idbd-qc-next')
  @Produces(MediaType.JSON)
  public findIDBDQCNext(): Observable<string | undefined> {
    return;
  }

  @POST('people/exist')
  @Produces(MediaType.JSON)
  public exist(@Body ids: string[]): Observable<{ invalid: boolean; eu: boolean }[]> {
    return;
  }

  @POST('people/bulk-update')
  @Produces(MediaType.JSON)
  public bulkUpdate(@Body data: { ids: string[]; values: { [key: string]: any } }): Observable<{ bulkTrackingId }> {
    return;
  }

  @POST('people/{id}/replicate')
  @Produces(MediaType.JSON)
  public replicate(@Path('id') id: string, @Body payload: any): Observable<string[]> {
    return;
  }

  @GET('people/duplicates')
  @Produces(MediaType.JSON)
  public getDuplicates(@Query('params') params: Partial<Person>): Observable<Person[]> {
    return;
  }

  @GET('people/video-identified/{videoId}')
  @Produces(MediaType.JSON)
  public getVideoIdentified(@Path('videoId') videoId: string): Observable<Person[]> {
    return;
  }

  /**
   * Get the file as binary
   */
  public downloadPersonsFile(filter?: any, name?: string): Observable<any> {
    const filterUrl = filter ? 'filter=' + encodeURIComponent(JSON.stringify(filter)) : null;
    const nameUrl = name ? 'name=' + encodeURIComponent(name) : null;
    return this.http
      .get(
        this.getBaseUrl() +
          'people/export' +
          (filterUrl ? '?' + filterUrl : '') +
          (nameUrl ? (filterUrl ? '&' : '?') + nameUrl : ''),
        { responseType: 'blob' }
      )
      .pipe(catchError((err) => this.handleError(err)));
  }

  @GET('people/queue-inspection/{userId}')
  @Produces(MediaType.JSON)
  public userPersonQueue(@Path('userId') userId?: string): Observable<Person[]> {
    return;
  }

  @GET('people/queue-size/{userId}')
  @Produces(MediaType.JSON)
  public userPersonQueueSize(@Path('userId') userId?: string): Observable<{ count: number }> {
    return;
  }

  @GET('people/id-queue-inspection/{userId}')
  @Produces(MediaType.JSON)
  public userPersonIdQueue(@Path('userId') userId?: string): Observable<Person[]> {
    return;
  }

  @GET('people/id-queue-size/{userId}')
  @Produces(MediaType.JSON)
  public userPersonIdQueueSize(@Path('userId') userId?: string): Observable<{ count: number }> {
    return;
  }

  @POST('people/image-maintenance')
  @Produces(MediaType.JSON)
  public requestImageMaintenance(@Query('personId') personId: string): Observable<void> {
    return;
  }

  @PATCH('people/{id}/reserve-id')
  @Produces(MediaType.JSON)
  public reserveProfile(@Path('id') id: string): Observable<Person> {
    return;
  }

  @DELETE('people/{id}/compliance/{complianceId}')
  @Produces(MediaType.JSON)
  public deleteComplianceRecord(
    @Path('id') id: string,
    @Path('complianceId') complianceId: string
  ): Observable<Person> {
    return;
  }
}
