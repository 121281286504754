export class TimeTracking {
  // User by server
  major: string;
  refId: string;
  minor: string;
  status: string;
  timeSpentMs: number;
  changesSubmitted: number;
  pointsChanged: number;
}
