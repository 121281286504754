<section class="dirt-section-list dirt-ct-sponsor-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>Sponsors</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        placeholder="Search by Name"
        [formControl]="searchCtrl"
      />
    </div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Filter -->
        <dirt-filters [filters]="filters" (onFilter)="onFilter($event)"></dirt-filters>
      </div>
    </div>
  </header>

  <!-- Filter Bar -->
  <dirt-filters-bar
    [filter]="filter"
    [config]="filterBarConfig"
    (filterClear)="onFilterClear()"
    (filterValueRemove)="onFilterValueRemove($event)"
  ></dirt-filters-bar>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-ct-sponsor-list'"
    [pagingSize]="sponsors?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="sponsors?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-name" [diColSorter]="'name'">Name</th>
          <th class="col-type" [diColSorter]="'type'">Type</th>
          <th class="col-curated" [diColSorter]="'curated.at'">Curated</th>
          <th class="col-created" [diColSorter]="'createdAt'">Created</th>
          <th class="col-modified" [diColSorter]="'updatedAt'">Modified</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-ct-sponsor-row"
          *ngFor="
            let sponsor of sponsors
              | paginate
                : {
                    id: 'dirt-ct-sponsor-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <td class="col-name">
            <a
              class="dirt-ct-sponsor-name"
              [title]="sponsor.name | dash"
              [routerLink]="['/ct-sponsor/detail', sponsor.id]"
              >{{ sponsor.name }}</a
            >
          </td>
          <td class="col-type">{{ sponsor.type }}</td>
          <td class="col-curated">{{ sponsor.curated?.at | date : 'dd.MM.y' }}</td>
          <td class="col-created">{{ sponsor.createdAt | date : 'dd.MM.y' }}</td>
          <td class="col-modified">{{ sponsor.updatedAt | date : 'dd.MM.y' }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No Sponsors</p>
    </ng-template>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-ct-sponsor-list'"
    [pagingSize]="sponsors?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
