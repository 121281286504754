<div class="dirt-filter-wrapper" *ngIf="isFiltersEmpty()">
  <div class="dirt-filter-container">
    <ng-container *ngFor="let item of filter | iterable : true : true">
      <div class="dirt-filter-group" *ngIf="!config[item.key]?.hide">
        <div class="dirt-filter-group-label">{{ getLabel(item.key) }}:</div>
        <ng-container *ngIf="isArray(item.value)">
          <div class="dirt-filter-group-values" *ngFor="let value of item.value">
            {{ getValueFromMap(item.key, value) }}
            <i
              class="fa fa-times"
              aria-hidden="true"
              *ngIf="!readonly"
              (click)="onFilterValueRemove(item.key, value)"
            ></i>
          </div>
        </ng-container>
        <div class="dirt-filter-group-values" *ngIf="!isArray(item.value)">
          {{ getValueFromMap(item.key, item.value) }}
          <i
            class="fa fa-times"
            aria-hidden="true"
            *ngIf="!readonly"
            (click)="onFilterValueRemove(item.key, item.value)"
          ></i>
        </div>
      </div>
    </ng-container>
  </div>
  <div *ngIf="!readonly">
    <button class="btn btn-outline-primary btn-sm pull-right" (click)="onFilterClearClicked()">Clear</button>
  </div>
</div>
