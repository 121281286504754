<section class="dirt-section-list dirt-association-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span *ngIf="total">{{ total.count | number }}&nbsp;</span>
        <span [hidden]="parentId">Associations</span>
        <span [hidden]="!parentId">Sub Associations</span>
      </h1>
    </div>
    <div class="col-sm-4">
      <input
        type="search"
        class="form-control"
        [formControl]="searchCtrl"
        [(ngModel)]="searchTerm"
        placeholder="Search..."
      />

      <a class="float-right small pt-1 pr-1" [routerLink]="['/association/search']" *diAcl="'association.search'">
        Advanced Search
      </a>
    </div>
    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Filter -->
        <!-- TODO: replace that by the generic filter component when there is a change to make here -->
        <dirt-association-filter (onFilter)="setFilter($event)" *ngIf="!parentId"></dirt-association-filter>

        <dirt-button-dropdown
          [title]="'Bulk'"
          [icon]="'fa-pencil'"
          *diAcl="'association.list.bulkUpdate || association.bulkList || association.bulkSearch'"
        >
          <dirt-bulk-update
            *diAcl="'association.list.bulkUpdate'"
            [filter]="filter"
            [search]="searchTerm"
            (refresh)="onBulkUpdateRefresh()"
            [options]="bulkUpdateConfig"
          ></dirt-bulk-update>

          <button
            class="list-group-item list-group-item-action"
            [title]="bulkListDelegate.specialHint || ''"
            (click)="onOpenBulkListDialog()"
            *diAcl="'association.bulkList'"
          >
            <i class="fa fa-th-list" aria-hidden="true"></i>
            Bulk import
          </button>

          <button
            class="list-group-item list-group-item-action"
            [title]="bulkSearchDelegate.specialHint || ''"
            (click)="onOpenBulkSearchDialog()"
            *diAcl="'association.bulkSearch'"
          >
            <i class="fa fa-th-list" aria-hidden="true"></i>
            Bulk Search
          </button>
        </dirt-button-dropdown>

        <!-- Create Button -->
        <button
          class="btn btn-success btn-sm"
          (click)="navigateTo(['/association/create'])"
          *diAcl="'association.create || subassociation.create'"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
          <span [hidden]="parentId">Create Association</span>
          <span [hidden]="!parentId">Create Sub Association</span>
        </button>
      </div>
    </div>
  </header>

  <div class="pull-right">
    <!-- Dashboards -->
    <dirt-button-dropdown [title]="'Dashboards'" [icon]="'fa-tachometer'" *diAcl="'association.dashboards'">
      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/association-id-throughput/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Association ID Throughput
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/association-id-qc-throughput/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Association ID QC Throughput
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/association-id-qc-error-rate/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Association ID QC Error Rate
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/association-throughput/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Association Throughput
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/association-qc-throughput/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Association QC Throughput
      </a>

      <a
        class="list-group-item list-group-item-action"
        href="https://ods.veeva.io/superset/dashboard/association-qc-error-rate/?standalone=true"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-external-link" aria-hidden="true"></i>
        Association QC Error Rate
      </a>
    </dirt-button-dropdown>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-association-list'"
    [pagingSize]="associations?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Table -->
  <div *ngIf="associations" [hidden]="isLoading">
    <table class="table table-sm table-hover" [hidden]="!associations.length" (diSort)="onSort($event)">
      <thead>
        <tr>
          <!-- Associations + Sub Associations -->
          <th class="col-state">&nbsp;</th>

          <th class="col-name" [diColSorter]="'name'" [ngClass]="{ 'col-name': !parentId, 'col-name-sub': parentId }">
            Name
          </th>

          <!-- Associations -->
          <th class="col-country" [diColSorter]="'address.countryCode'" *ngIf="!parentId">Location</th>
          <th class="col-reach" [diColSorter]="'reach'" *ngIf="!parentId">Reach</th>
          <th class="col-category" [diColSorter]="'category'" *ngIf="!parentId">Cat.</th>
          <th class="col-created" [diColSorter]="'createdAt'" *ngIf="!parentId">Created</th>
          <th class="col-modified" [diColSorter]="'updatedAt'" *ngIf="!parentId">Modified</th>
          <th class="col-reviewed" [diColSorter]="'reviewedAt'" *ngIf="!parentId">Reviewed</th>
          <th class="col-status" [diColSorter]="'_meta.status'" *ngIf="!parentId">Status</th>

          <!-- Sub Associations -->
          <th class="col-maintenance" *ngIf="parentId">Maintenance</th>

          <!-- Associations -->
          <th class="col-association-count" *ngIf="!parentId">
            <span ngbTooltip="Sub-Associations Count"><i class="fa fa-list" aria-hidden="true"></i></span>
          </th>

          <!-- Associations + Sub Associations -->
          <th [ngClass]="{ 'col-membership-count': !parentId, 'col-membership-count-sub': parentId }">
            <span ngbTooltip="Memberships Count"><i class="fa fa-th-list" aria-hidden="true"></i></span>
          </th>

          <!-- Sub Associations -->
          <th *ngIf="parentId" [ngClass]="{ 'col-sponsors-count': parentId, 'col-sponsors-count-sub': parentId }">
            <span ngbTooltip="Sponsors Count"><i class="fa fa-th-list" aria-hidden="true"></i></span>
          </th>

          <!-- Associations -->
          <th class="col-priority" [diColSorter]="'priority'" *ngIf="!parentId">Priority</th>

          <!-- Associations + Sub Associations -->
          <th class="col-reviewed" *ngIf="parentId">
            <input
              (change)="toggleAllReviewed($event)"
              type="checkbox"
              [checked]="allReviewed"
              [disabled]="!canEditSubAssociation() || isLoading"
            />
            Reviewed
          </th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="dirt-association-row"
          *ngFor="
            let association of associations
              | paginate
                : {
                    id: 'dirt-association-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  }
          "
        >
          <td class="col-state" *ngIf="!parentId">
            <span
              class="state state-stacked state-success"
              ngbTooltip="Verified"
              placement="right"
              *ngIf="association.verified"
            ></span>
            <span
              class="state state-stacked state-primary"
              ngbTooltip="Oncology"
              placement="right"
              *ngIf="association.scope"
            ></span>
            <span
              class="state state-stacked state-warning"
              ngbTooltip="Maintenance"
              placement="right"
              *ngIf="association._meta.maintenance"
            ></span>
          </td>
          <td class="col-state" *ngIf="parentId">
            <span
              class="state state-stacked state-danger"
              ngbTooltip="Obsolete"
              placement="right"
              *ngIf="isObsolete(association)"
            ></span>
          </td>
          <td class="col-name">
            <a
              class="dirt-association-name"
              [title]="association.name | dash"
              [routerLink]="['/association/detail', association.id]"
              >{{ association.name | dash }}</a
            >
          </td>
          <td class="col-country" [title]="getCountryTitle(association.address?.countryCode)" *ngIf="!parentId">
            {{ getCountryTitle(association.address?.countryCode) }}
          </td>
          <td class="col-reach" [title]="association.reach | capitalize" *ngIf="!parentId">
            {{ association.reach | capitalize }}
          </td>
          <td class="col-category" [title]="association.category" *ngIf="!parentId">{{ association.category }}</td>
          <td class="col-created" *ngIf="!parentId">{{ association.createdAt | date : 'dd.MM.y' }}</td>
          <td class="col-modified" *ngIf="!parentId">{{ association.updatedAt | date : 'dd.MM.y' }}</td>
          <td class="col-reviewed" *ngIf="!parentId">{{ association.reviewedAt | date : 'dd.MM.y' }}</td>
          <td class="col-status" [title]="association._meta?.status | capitalize" *ngIf="!parentId">
            {{ association._meta?.status | capitalize }}
          </td>
          <td class="col-maintenance" *ngIf="parentId">
            <!-- Maintenance View Only -->
            <ng-template
              [diAcl]="'!subassociation.update.prop.maintenance'"
              [diAclAnd]="!!association._meta?.maintenanceDisabled"
            >
              <i class="fa fa-ban" title="Disabled" aria-hidden="true"></i>
            </ng-template>

            <!-- Maintenance Action -->
            <ng-template
              [diAcl]="'subassociation.update.prop.maintenance'"
              [diAclAnd]="isSavingMaintenance !== association.id"
            >
              <span
                class="di-maintenance-toggle"
                [ngClass]="{ 'no-maintenance': association._meta?.maintenanceDisabled }"
                (click)="toggleMaintenance(association)"
              >
                <i class="fa fa-ban" aria-hidden="true"></i>&nbsp;
                <!-- Action Hint -->
                <span class="di-maintenance-hint">{{
                  association._meta?.maintenanceDisabled ? 'Enable' : 'Disable'
                }}</span>
              </span>
            </ng-template>

            <!-- Saving Indicator-->
            <span *ngIf="isSavingMaintenance === association.id">
              <i class="fa fa-refresh fa-spin"></i>
            </span>
          </td>
          <td class="col-association-count" *ngIf="!parentId">{{ association.associationsCount | number }}</td>

          <td class="col-membership-count">{{ association.membershipsCount | number }}</td>
          <td class="col-sponsors-count" *ngIf="parentId">{{ association.sponsors?.length || 0 | number }}</td>

          <td class="col-priority" *ngIf="!parentId">
            <ngb-rating
              [rate]="association.priority"
              (rateChange)="setPriority(association, $event)"
              [readonly]="!isPriorityEditable()"
              (click)="$event.stopPropagation()"
              max="5"
            >
              <ng-template let-fill="fill" let-index="index">
                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
              </ng-template>
            </ngb-rating>
          </td>
          <td class="col-ended" *ngIf="parentId">
            <input
              type="checkbox"
              [disabled]="!canEditSubAssociation()"
              [checked]="association.reviewed"
              (change)="onSubAssociationReviewedChange(association, $event)"
            />
          </td>
          <td class="col-actions">
            <ng-template [diAcl]="'association.delete || subassociation.delete'" [diAclAnd]="!isObsolete(association)">
              <!-- Delete -->
              <span class="action-btn action-delete" (click)="onDelete(association.id, $event)">
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <p class="dirt-no-results" *ngIf="!associations.length">
      {{ parentId ? 'No Sub Associations' : 'No Associations' }}
    </p>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-association-list'"
    [pagingSize]="associations?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="isLoading || pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
