<section class="dirt-section-detail dirt-guideline-detail">
  <div class="row" *ngIf="!isLoading; else loader">
    <div class="col-md-8">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <h1 class="bounceInRight">Guideline Detail</h1>

      <br />

      <dirt-guideline-form
        [guideline]="guideline"
        [disabled]="isPublicationGuideline"
        #frmGuideline="frmGuideline"
      ></dirt-guideline-form>

      <ng-template [diAcl]="'guideline.update'" [diAclAnd]="!isPublicationGuideline">
        <!-- Unable to Compile -->
        <button
          type="button"
          class="btn btn-outline-danger"
          *diAcl="'guideline.update.unable'"
          (click)="onObjection('UNABLE_TO_COMPILE')"
        >
          Unable to Compile!
        </button>

        <!-- Skip verification -->
        <button
          type="button"
          class="btn btn-outline-danger"
          (click)="skipVerification()"
          *diAcl="'guideline.update.skip'"
        >
          Skip Guideline
        </button>

        <div class="pull-right">
          <button
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!frmGuideline.isValid() || isSubmitting || !authorList?.authors?.length"
            (click)="onSubmit(frmGuideline.getValue())"
            *diAcl="'guideline.update.submit'"
          >
            Submit
          </button>

          <button
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!frmGuideline.isValid() || isSubmitting"
            (click)="onSave(frmGuideline.getValue())"
            *diAcl="'!guideline.update.submit'"
          >
            Save
          </button>
        </div>
      </ng-template>

      <!-- Comment Form -->
      <div class="comment-form">
        <dirt-comment-create
          [ownerId]="id"
          [showBtnSubmit]="true"
          [showBtnCancel]="true"
          [btnSubmitCaption]="objectionStatus | capitalize"
          (onSubmitted)="onUnableToCompile()"
          (onCancelled)="onObjectionCancel()"
          *ngIf="isObjectionCommentShown"
        >
        </dirt-comment-create>
      </div>
    </div>

    <div class="col-md-4">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Workflow -->
        <li ngbNavItem>
          <a ngbNavLink>Workflow</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <ng-container *ngIf="!isPublicationGuideline">
                <dt class="col-sm-5" [hidden]="!isClaimedByOtherUser"></dt>
                <dd class="col-sm-7" [hidden]="!isClaimedByOtherUser">
                  <span title="s/o else started working recently" class="badge badge-info">Claimed</span>
                </dd>

                <dt class="col-sm-4" style="white-space: nowrap">Req by <small>/ Owner prd</small></dt>
                <dd class="col-sm-8">{{ guideline.ownerProduct || '-' }}</dd>

                <dt class="col-sm-4">Verified</dt>
                <dd class="col-sm-8">
                  <span *diAcl="'guideline.update.prop.verified'">
                    <ui-switch
                      [checked]="!!guideline.verified"
                      (change)="onVerifiedChange($event)"
                      size="small"
                    ></ui-switch>
                    <i class="fa fa-pulse fa-spinner di-va-super" [hidden]="!isSavingVerified"></i>
                  </span>

                  <span *diAcl="'!guideline.update.prop.verified'">
                    <i
                      class="fa"
                      [ngClass]="{ 'fa-check': guideline.verified, 'fa-times': !guideline.verified }"
                      aria-hidden="true"
                    ></i>
                  </span>
                </dd>

                <dt class="col-sm-4">ID Verified</dt>
                <dd class="col-sm-8">
                  <span *diAcl="'guideline.update.prop.idVerified'">
                    <ui-switch
                      [checked]="!!guideline.idVerified"
                      (change)="onIdVerifiedChange($event)"
                      size="small"
                    ></ui-switch>
                    <i class="fa fa-pulse fa-spinner di-va-super" [hidden]="!isSavingIdVerified"></i>
                  </span>

                  <span *diAcl="'!guideline.update.prop.idVerified'">
                    <i
                      class="fa"
                      [ngClass]="{ 'fa-check': guideline.idVerified, 'fa-times': !guideline.idVerified }"
                      aria-hidden="true"
                    ></i>
                  </span>
                </dd>
              </ng-container>

              <dt
                class="col-sm-4"
                [ngClass]="{ 'di-separator': !isPublicationGuideline, 'text-truncate': isPublicationGuideline }"
              >
                Created
              </dt>
              <dd class="col-sm-8" [ngClass]="{ 'di-separator': !isPublicationGuideline }">
                {{ guideline.createdAt | date : 'medium' }}
              </dd>

              <dt class="col-sm-4 text-truncate">Modified</dt>
              <dd class="col-sm-8">{{ guideline.updatedAt | date : 'medium' }}</dd>

              <dt class="col-sm-4 text-truncate" *ngIf="guideline.reviewedAt">Reviewed</dt>
              <dd class="col-sm-8" *ngIf="guideline.reviewedAt">{{ guideline.reviewedAt | date : 'medium' }}</dd>

              <ng-container [diMarkerWrapper]="guideline" *ngIf="!isPublicationGuideline">
                <dt class="col-sm-4 text-truncate">Status</dt>
                <dd class="col-sm-8">
                  <span *diAcl="'!guideline.update.prop.meta.status'">{{ guideline._meta?.status | capitalize }}</span>
                  <ng-container *diAcl="'guideline.update.prop.meta.status'">
                    <dirt-status
                      [statuses]="statuses"
                      [assigneeStatuses]="assigneeStatuses"
                      [disabledStatuses]="disabledStatuses"
                      [_meta]="guideline._meta || {}"
                      (onStatusSelected)="onStatusChange($event)"
                      *diMarker="{ name: 'status', entity: 'guideline', area: 'ID' }"
                    >
                    </dirt-status>
                  </ng-container>
                  <i class="fa fa-circle-o-notch fa-spin" [hidden]="!isSavingStatus"></i>
                </dd>

                <dt class="col-sm-4">Project</dt>
                <dd class="col-sm-8">
                  <dirt-project-name
                    [activeSet]="guideline.projectNames || []"
                    [type]="ValueType.Project"
                    [disabled]="!svcAcl.hasCredential('guideline.update.prop.projectNames')"
                    (onProjectSelectionChanged)="onProjectSelectionChanged($event)"
                    *diMarker="{ name: 'projectNames', entity: 'guideline', area: 'ID' }"
                  >
                  </dirt-project-name>
                </dd>
              </ng-container>

              <dt class="col-sm-4">Version</dt>
              <dd class="col-sm-8">{{ guideline._version || 0 }}</dd>
            </dl>
          </ng-template>
        </li>

        <!-- Comments -->
        <ng-template [diAcl]="'guideline.comment.list'" [diAclAnd]="!isPublicationGuideline">
          <li ngbNavItem>
            <a ngbNavLink>Comments</a>
            <ng-template ngbNavContent>
              <dirt-comment-list [ownerId]="id" [enableCreate]="canCreateComments"></dirt-comment-list>
            </ng-template>
          </li>
        </ng-template>

        <!-- Audit Log -->
        <ng-template [diAcl]="'guideline.audit.list'" [diAclAnd]="!isPublicationGuideline">
          <li ngbNavItem>
            <a ngbNavLink>Audit Log</a>
            <ng-template ngbNavContent>
              <dirt-audit-log [id]="id" [entityAPI]="svcGuideline"></dirt-audit-log>
            </ng-template>
          </li>
        </ng-template>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-guideline-meta"></div>
    </div>

    <div class="col-md-12">
      <dirt-ocr-bar [webSource]="guideline.webSource"></dirt-ocr-bar>
    </div>

    <div class="col-md-12" [diMarkerWrapper]="guideline" *ngIf="isRegionCompliant()">
      <dirt-guideline-author-list
        *diMarker="{ name: 'authors', entity: 'guideline', area: 'BD' }"
        [guidelineID]="guideline._id"
        [authors]="guideline.authors"
        [readonly]="isPublicationGuideline"
      ></dirt-guideline-author-list>
    </div>
  </div>

  <!-- Loading Message -->
  <ng-template #loader>
    <p class="dirt-loading">
      <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
      <span class="sr-only">Loading...</span>
    </p>
  </ng-template>
</section>
