<section class="dirt-job-history">
  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-job-history-pagination'"
    [pagingSize]="jobs?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <ng-container *ngIf="jobs?.length; else empty">
      <article
        *ngFor="
          let job of jobs
            | paginate
              : {
                  id: 'dirt-job-history-pagination',
                  itemsPerPage: pagingLimit,
                  currentPage: pagingPage,
                  totalItems: total?.count
                };
          trackBy: trackById
        "
      >
        <div class="di-job-date" [title]="job.createdAt | date : 'medium'">
          <span>{{ job.createdAt | date : 'dd' }}</span>
          <span>{{ job.createdAt | date : 'MMM' }}</span>
        </div>

        <div class="job-content">
          <div class="job-header d-flex justify-content-between">
            <span style="font-weight: 600">{{ job.type | capitalize }}</span>

            <div class="d-flex flex-row align-items-baseline">
              <span class="badge badge-info" [title]="job.completedAt | date : 'medium'" *ngIf="job.completedBy"
                >Completed</span
              >
              <span
                class="badge badge-warning"
                [title]="job.updatedAt | date : 'medium'"
                *ngIf="job._meta.status === 'UNABLE_TO_COMPILE'"
                >UTC</span
              >
            </div>
          </div>

          <ul class="job-details fa-ul">
            <li *ngIf="job.createdBy">
              <span class="font-weight-bolder">Created By:</span>
              <dirt-username [userId]="job.createdBy"></dirt-username>
            </li>
            <li *ngIf="job.hint"><span class="font-weight-bolder">Hint:</span> {{ job.hint }}</li>
            <li><span class="font-weight-bolder">Status:</span> {{ job._meta.status | capitalize }}</li>
            <li *ngIf="job._meta.status === 'UNABLE_TO_COMPILE' && job.unableComment">
              <span class="font-weight-bolder">UTC Comment:</span> {{ job.unableComment }}
            </li>
            <li *ngIf="job.completedBy">
              <span class="font-weight-bolder">Completed By:</span>
              <dirt-username [userId]="job.completedBy"></dirt-username>
            </li>
          </ul>
        </div>
      </article>
    </ng-container>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="text-center">No Jobs</p>
    </ng-template>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
