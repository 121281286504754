import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, take, takeUntil } from 'rxjs';
import { format } from 'date-fns';
import { FormControl } from '@angular/forms';

import { ClinicalTrialSponsor } from '../shared/clinical-trial-sponsor';
import { ClinicalTrialSponsorAPI } from '../shared/clinical-trial-sponsor-api.service';
import { Filter, FiltersComponent, FilterType } from '../../shared/components/filters/filters.component';

@Component({
  selector: 'dirt-ct-sponsor-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ClinicalTrialSponsorListComponent implements OnInit, OnDestroy {
  sponsors: ClinicalTrialSponsor[];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  // Sorting / Filtering settings
  searchCtrl: FormControl = new FormControl('');
  filter: Record<string, unknown>;
  filters: Filter[] = [];
  filterBarConfig = {
    'curated.at': {
      label: 'Curated At',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
    createdAt: {
      label: 'Created at',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
    updatedAt: {
      label: 'Modified at',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
  };

  private searchTerm: string;

  private sort = '-_id';

  @ViewChild(FiltersComponent, { static: false })
  private filterRef: FiltersComponent;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly svcClinicalTrialSponsor: ClinicalTrialSponsorAPI
  ) {}

  ngOnInit(): void {
    this.filters.push(
      {
        title: 'Curated At',
        key: 'curated.at',
        type: FilterType.DATE_RANGE,
      },
      {
        title: 'Created',
        key: 'createdAt',
        type: FilterType.DATE_RANGE,
      },
      {
        title: 'Modified',
        key: 'updatedAt',
        type: FilterType.DATE_RANGE,
      }
    );

    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params.searchTerm) {
        this.searchTerm = params.searchTerm;
        this.searchCtrl.setValue(this.searchTerm, { emitEvent: false }); // restore search term
      }
      if (params.sortField) {
        this.sort = params.sortField;
      }

      this.doLoad();
    });

    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.searchTerm = term;
        this.doLoad();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /** just avoid useless rendering if we can */
  trackById(_: number, sponsor: ClinicalTrialSponsor): string {
    return sponsor.id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getClinicalTrialSponsors();
  }

  onSort(field: string): void {
    this.sort = field;

    this.storeFiltersAsQueryParams();
    this.getClinicalTrialSponsors();
  }

  onFilter(filter: Record<string, unknown>): void {
    this.filter = filter;
    this.doLoad();
  }

  onFilterClear(): void {
    this.filterRef.doClear();
  }

  onFilterValueRemove(item: { key: string; value: unknown }): void {
    this.filterRef.removeValue(item.key, item.value);
  }

  navigateTo(id: string): void {
    if (!id) {
      return;
    }

    this.router.navigate(['/ct-sponsor/detail', id]);
  }

  private doLoad(): void {
    this.storeFiltersAsQueryParams();
    this.resetPagination();
    this.getClinicalTrialSponsors();
    this.getClinicalTrialSponsorsCount();
  }

  private storeFiltersAsQueryParams(): void {
    if (this.searchTerm || this.sort) {
      const filters = {
        ...(this.searchTerm && { searchTerm: this.searchTerm }),
        ...(this.sort && { sortField: this.sort }),
      };
      this.router.navigate([], { queryParams: filters });
    }
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getClinicalTrialSponsors(): void {
    this.svcClinicalTrialSponsor
      .find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.filter)
      .subscribe((sponsors) => {
        this.sponsors = sponsors;
      });
  }

  private getClinicalTrialSponsorsCount(): void {
    this.svcClinicalTrialSponsor.count(this.searchTerm, this.filter).subscribe((count) => {
      this.total = count;
    });
  }
}
