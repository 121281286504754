<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Edit QC comment</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form>
    <div class="form-group row">
      <div class="col-sm-8 col-md-10" name="radioAddressType" [(ngModel)]="qcStatusMode" ngbRadioGroup>
        <label ngbButtonLabel><input type="radio" value="match->mismatch" ngbButton /> Match -> Mismatch</label>
        <label ngbButtonLabel><input type="radio" value="mismatch->match" ngbButton /> Mismatch -> Match</label>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-8 col-md-10">
        <input type="text" id="qcComment" class="form-control" name="qcComment" [(ngModel)]="comment" />
      </div>
    </div>

    <div class="form-group row" *diAcl="'profile.qc.extended'">
      <div class="row ml-3">
        <h5>Error Types</h5>
      </div>

      <div class="col-sm-8 col-md-10" name="radioErrorType" [(ngModel)]="selectedError" ngbRadioGroup>
        <select class="form-control" name="errors-list" [(ngModel)]="selectedError">
          <option *ngFor="let errorType of profileErrorTypes" [value]="errorType.value.error">
            {{ errorType.value.error }}
          </option>
        </select>
      </div>
    </div>

    <div class="row ml-1">
      <label>Complete comment: {{ qcStatusMode }} {{ comment }}</label>
    </div>

    <div class="row ml-1" *ngIf="selectedError">
      <label>Error type: {{ selectedError }}</label>
    </div>
  </form>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="saveEditedComments()">Save</button>
  <button type="button" class="btn btn-secondary btn-sm" (click)="activeModal.dismiss()">Cancel</button>
</div>
