import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIService } from '../../shared/services/api/api.service';
import { CommitteePerson } from './person';
import {
  GET,
  MediaType,
  Produces,
  Query,
  POST,
  Body,
  Path,
  PATCH,
  DELETE,
} from '../../shared/services/rest/rest.service';

@Injectable()
export class CommitteePersonAPI extends APIService {
  @GET('committee-people/')
  @Produces(MediaType.JSON)
  public find(
    @Query('committeeId') committeeId?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<CommitteePerson[]> {
    return;
  }

  @GET('committee-people/count')
  @Produces(MediaType.JSON)
  public count(@Query('committeeId') committeeId?: string): Observable<any> {
    return;
  }

  @GET('committee-people/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<CommitteePerson> {
    return;
  }

  @POST('committee-people')
  @Produces(MediaType.JSON)
  public create(@Body person: CommitteePerson): Observable<any> {
    return;
  }

  @PATCH('committee-people/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body person: Partial<CommitteePerson>): Observable<any> {
    return;
  }

  @DELETE('committee-people/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }
}
