import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FindByIdComponent } from './find-by-id.component';
import { ProfileAPI } from '../../profile/shared/api.service';

@Component({
  selector: 'dirt-find-profile-for-compilers',
  templateUrl: './find-by-id.component.html',
  styleUrls: ['./find-by-id.component.scss'],
})
export class FindProfileComponent extends FindByIdComponent {
  constructor(protected modalService: NgbModal, private router: Router, private svcProfile: ProfileAPI) {
    super(modalService);
  }

  onSubmit() {
    if (!this.inputValue) {
      return;
    }

    this.svcProfile.find(undefined, undefined, undefined, undefined, { 'person.kolId': this.inputValue }).subscribe(
      (response) => {
        if (!response || !response.length) {
          return alert('No Profile found');
        }
        const profile = response[0];
        this.router.navigate(['/profile/detail', profile.id]);
      },
      (err) => alert('Error!'),
      () => this.modalRef.close()
    );
  }
}
