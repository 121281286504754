<section class="di-document-connections">
  <div class="card">
    <div class="card-header">Connection</div>
    <div class="card-body">
      <!-- Searchbar -->
      <div class="di-search">
        <input
          type="search"
          class="form-control ng-trim-ignore"
          [ngClass]="{ loading: isSearching }"
          placeholder="Search..."
          aria-label="Search"
          [ngbTypeahead]="onSearchConnection"
          [resultTemplate]="searchResultTemplate"
          [(ngModel)]="searchTerm"
          (selectItem)="onSelectConnection($event)"
          *ngIf="!disabled"
        />

        <i class="fa fa-pulse fa-spinner di-va-super" [hidden]="!isSearching"></i>
      </div>

      <ng-container *ngIf="!disabled && !!connectionRequestHandler">
        <a href="javascript:void(0)" class="small pull-right pt-1 pr-2" (click)="onRequestConnection()">
          Request Connection
        </a>

        <br />
      </ng-container>

      <!-- Form -->
      <form #ngForm="ngForm" class="no-validate" novalidate>
        <div *ngFor="let connection of connections; let idx = index; trackBy: trackById">
          <div class="card mt-3 connection-wrapper">
            <div class="card-header" (click)="onToggleCollapse(connection.connectionId)">
              <div class="d-inline-block">
                <i
                  class="fa fa-lg"
                  [ngClass]="{
                    'fa-angle-right': isCollapsed.get(connection.connectionId),
                    'fa-angle-down': !isCollapsed.get(connection.connectionId)
                  }"
                  aria-hidden="true"
                >
                </i>

                <span class="font-weight-bold pl-1 pr-2">{{ connection.title }}</span>

                <!-- Unpolished Badge -->
                <div class="d-inline-block ml-2" *ngIf="!connection.readyForDelivery">
                  <div class="badge badge-warning" title="Not yet worked by team responsible of connection">
                    Unpolished
                  </div>
                </div>
              </div>
            </div>

            <div class="card-body" [ngbCollapse]="isCollapsed.get(connection.connectionId)">
              <h5>
                <a
                  [routerLink]="'/' + connection.type.toLowerCase() + '/detail/' + connection.connectionId"
                  target="_blank"
                  rel="noopener"
                  >{{ connection.title }}</a
                >
              </h5>

              <ng-container *ngIf="!disabled && !!connectionRequestHandler && connection.readyForDelivery">
                <a
                  href="javascript:void(0)"
                  class="small pull-right pt-1 pr-2"
                  (click)="onRequestConnection(connection)"
                >
                  Request Maintenance
                </a>

                <br />
              </ng-container>

              <div class="row" *ngIf="connection.linkedAccounts?.length">
                <div class="col-sm-12">
                  Connected accounts:
                  <span *ngFor="let ca of connection.linkedAccounts; let idx = index"
                    >{{ idx > 0 ? ' - ' : ''
                    }}<a [routerLink]="'/account/detail/' + ca._id" target="_blank" rel="noopener">{{
                      ca.name
                    }}</a></span
                  >
                </div>
              </div>

              <div class="row">
                <div class="col-md-10"></div>

                <!-- Actions -->
                <div class="col-md-2 connection-actions">
                  <!-- Remove -->
                  <span
                    class="action-btn action-remove"
                    title="Remove Connection"
                    (click)="onRemoveConnection(connection.connectionId)"
                    *ngIf="!disabled"
                  >
                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <p class="text-center mt-1" *ngIf="!connections.length">No Connections</p>
    </div>
  </div>
</section>

<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div class="di-sac-title">
    <span>{{ r.title }}</span>
  </div>
</ng-template>
