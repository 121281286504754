import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ProfileDirectLinkOptions } from './direct-link-options.interface';

@Component({
  selector: 'dirt-profile-direct-link',
  templateUrl: 'direct-link.component.html',
  styleUrls: ['direct-link.component.scss'],
})
export class ProfileDirectLinkComponent {
  options: ProfileDirectLinkOptions;

  entity: any;
  entityId: string;
  position: string;

  dataItems: any;

  isInvalid = false;
  isLoading = false;

  constructor(public activeModal: NgbActiveModal) {}

  onSearch(id: string): void {
    this.isInvalid = false;
    this.isLoading = true;

    if (this.options.positionSet && this.options.setPosition) {
      this.options.setPosition(this.position);
    }

    this.options.getEntity(id).subscribe(
      (entity) => {
        this.entity = entity;
        this.dataItems = this.options.getData(entity);
      },
      () => {
        this.isInvalid = true;
        this.isLoading = false;
      },
      () => (this.isLoading = false)
    );
  }

  onClose() {
    if (this.options.getOnCloseData) {
      this.activeModal.close(this.options.getOnCloseData(this.entity, this.entityId));
    } else {
      this.activeModal.close(this.entity);
    }
  }
}
