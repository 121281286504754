import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgModel } from '@angular/forms';

import { ACL } from './../../shared/acl/acl.service';
import { AffiliationAPI } from '../../affiliation/shared/api.service';
import { Committee } from '../shared/committee';
import { CommitteePerson } from '../shared/person';
import { CommitteePersonAPI } from '../shared/committee-person-api.service';
import { initPrimaryResearchDetails } from '../shared/primaryResearch';
import { Person } from '../../person/shared/person';
import { PersonAPI } from '../../person/shared/api.service';
import { PersonStatus } from '../../person/shared/constant/status.enum';
import { PrimaryResearchResults, PrimaryResearchMethods } from '../shared/constant/primaryResearch.enum';
import { Utils } from './../../common/utils';
import { Value } from '../../shared/services/value/value';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';

@Component({
  selector: 'dirt-committee-person-modal',
  templateUrl: 'person-modal.component.html',
  styleUrls: ['person-modal.component.scss'],
})
export class CommitteePersonModalComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  committee: Committee;

  @Input()
  selectedCommitteePerson: CommitteePerson;

  @Input()
  mode: 'ADD' | 'VIEW' | 'EDIT';

  isSaving: boolean;

  isSearching: boolean;

  isFormValid: boolean;

  showPersonForm: boolean;

  committeePersonProbabilities: Value[] = [];

  primaryResearchResults = PrimaryResearchResults;
  primaryResearchMethods = PrimaryResearchMethods;

  positions: Value[];

  searchTerm: string;

  isEdit: boolean;

  projectNamesDidChange: boolean = false;

  createDisabledFields = ['cvLinks'];

  editDisabledFields = [
    'firstName',
    'middleName',
    'lastName',
    'source',
    'suffix',
    'countryWorkflow',
    'source',
    'cvLinks',
  ];

  disablePersonForm = false;

  @ViewChild('frmAdditional', { static: true })
  private frmAdditional: NgModel;

  private baseFormStatus: string;

  private destroy$: Subject<boolean> = new Subject();

  public longDash = Utils.longDash;

  constructor(
    public activeModal: NgbActiveModal,
    private svcAcl: ACL,
    private svcPerson: PersonAPI,
    private svcCommitteePerson: CommitteePersonAPI,
    private svcValue: ValueAPI,
    private svcAffiliation: AffiliationAPI
  ) {
    this.onSearchPeople = this.onSearchPeople.bind(this);
  }

  ngOnInit() {
    this.isEdit = this.mode === 'EDIT';
    this.disablePersonForm = this.mode === 'VIEW';

    this.svcValue.find(ValueType.CommitteePersonPosition, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((values) => {
      this.positions = values;
    });

    this.svcValue.find(ValueType.CommitteePersonProbability, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.committeePersonProbabilities = data;
    });
  }

  ngAfterViewInit(): void {
    this.frmAdditional.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.onFormValidityChange());
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  onSearchPeople(term$: Observable<string>): Observable<Person[]> {
    return term$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term: string) => {
        if (!term) {
          return of([]);
        }

        return this.svcPerson.find(term, 10).pipe(catchError(() => of([])));
      }),
      switchMap((people) => {
        if (people.length === 0) {
          return of([]);
        }

        const personToAffiliation = new Map();
        people.forEach((p) => {
          const primaryAffiliationLfka = p.affiliationsLfka?.find((a) => !!a.positions.find((p) => p.primary));
          if (primaryAffiliationLfka) {
            personToAffiliation.set(p, primaryAffiliationLfka.id);
          } else {
            const primaryAffiliation = p.affiliations?.find((a) => a.primary);
            if (primaryAffiliation) {
              personToAffiliation.set(p, primaryAffiliation.id);
            }
          }
        });

        const ids = Array.from(personToAffiliation.values());
        return this.svcAffiliation.find(null, ids.length, 0, null, { _id: ids }).pipe(
          map((affiliations) => {
            personToAffiliation.forEach((affId, p) => {
              const affiliation = affiliations.find((a) => a.id === affId);
              p.affiliationName = [affiliation?.name, affiliation?.department].filter((v) => !!v).join(' - ');
            });

            return people;
          })
        );
      }),
      tap(() => (this.isSearching = false))
    );
  }

  onTogglePersonForm(): void {
    this.showPersonForm = true;

    this.selectedCommitteePerson = {
      committeeId: this.committee.id,
      sourceUrls: [''],
      person: new Person(),
    } as any;

    this.selectedCommitteePerson.person.cvLinks = [''];
    this.projectNamesDidChange = false;
  }

  onSelectPerson(event: any): void {
    event.preventDefault(); // prevent setting model to [object Object]

    this.selectedCommitteePerson = {
      committeeId: this.committee.id,
      kolId: event.item.kolId,
      sourceUrls: [''],
      person: event.item,
    } as any;

    this.searchTerm = '';
    this.showPersonForm = false;
    this.projectNamesDidChange = false;
  }

  async onAddPerson(): Promise<void> {
    if (!this.selectedCommitteePerson || this.showPersonForm || this.isSaving || this.isSearching) {
      return;
    }

    const person = (
      await firstValueFrom(
        this.svcCommitteePerson.find(this.committee.id, 1, 0, null, {
          kolId: this.selectedCommitteePerson.person.kolId,
        })
      )
    )[0];
    if (person) {
      return alert('person is already attached to committee');
    }

    this.isSaving = true;

    // Send for KPP curation
    const lackingBaseTag =
      !(this.selectedCommitteePerson?.person.affiliationsLfka?.length > 0) &&
      !this.selectedCommitteePerson?.person.projectNames?.includes('LFKA_RF_BD');
    if (lackingBaseTag) {
      this.selectedCommitteePerson.person.projectNames = [
        ...(this.selectedCommitteePerson.person.projectNames || []),
        'LFKA_RF_BD',
      ];
    }
    if (lackingBaseTag || this.projectNamesDidChange) {
      await firstValueFrom(
        this.svcPerson.update(this.selectedCommitteePerson.person.id, {
          projectNames: this.selectedCommitteePerson.person.projectNames,
        })
      );
    }

    this.svcCommitteePerson.create(this.selectedCommitteePerson).subscribe(() => {
      this.activeModal.close();
    });
  }

  async onEditPerson(): Promise<void> {
    if (!this.selectedCommitteePerson || this.showPersonForm || this.isSaving || this.isSearching) {
      return;
    }

    this.isSaving = true;

    await firstValueFrom(
      this.svcPerson.update(this.selectedCommitteePerson.person.id, this.selectedCommitteePerson.person)
    );

    Utils.removeEmptyAndDuplicatedElementsFromArray(this.selectedCommitteePerson, ['sourceUrls']);

    this.svcCommitteePerson.update(this.selectedCommitteePerson.id, this.selectedCommitteePerson).subscribe(() => {
      this.activeModal.close();
    });
  }

  onRequestPerson(): void {
    if (!this.isFormValid || !this.showPersonForm || this.isSaving || this.isSearching) {
      return;
    }

    this.selectedCommitteePerson.person._meta = { status: PersonStatus.ON_HOLD };

    // Send for KPP curation
    if (!this.selectedCommitteePerson?.person.projectNames?.includes('LFKA_RF_BD')) {
      this.selectedCommitteePerson.person.projectNames = [
        ...(this.selectedCommitteePerson.person.projectNames || []),
        'LFKA_RF_BD',
      ];
    }

    this.selectedCommitteePerson.person.cvLinks = [this.selectedCommitteePerson.sourceUrls[0]]; // Master CV link in committee is used as master cv link in KP

    this.isSaving = true;
    this.svcPerson
      .create(this.selectedCommitteePerson.person)
      .pipe(
        concatMap((person) => {
          this.selectedCommitteePerson.kolId = person.kolId;
          this.selectedCommitteePerson.person = person;

          Utils.removeEmptyAndDuplicatedElementsFromArray(this.selectedCommitteePerson, ['sourceUrls']);

          return this.svcCommitteePerson.create(this.selectedCommitteePerson);
        })
      )
      .subscribe(() => {
        this.activeModal.close();
      });
  }

  onFormValidityChange(status?: string): void {
    if (status) {
      this.baseFormStatus = status;
    }

    this.isFormValid = this.baseFormStatus === 'VALID' && this.frmAdditional.valid;
  }

  getPositions(): Value[] {
    return this.positions?.filter((p) => p.committeeTypes.includes(this.committee.type));
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }

  trackByIndex(index: number): number {
    return index;
  }

  isFieldEditable(field: string): boolean {
    return this.svcAcl.hasCredential(`committee.update.prop.${field}`);
  }

  onPrimaryResearchSwitchChange(checked: boolean): void {
    this.selectedCommitteePerson.hasPrimaryResearch = checked;
    this.selectedCommitteePerson.primaryResearchInfo = {
      result: null,
      method: null,
      details: null,
    };
  }

  onPrimaryResearchMethodChange(): void {
    const methodValue: string = this.selectedCommitteePerson.primaryResearchInfo.method;

    this.selectedCommitteePerson.primaryResearchInfo.details = initPrimaryResearchDetails(methodValue);
  }
}
