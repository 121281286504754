<section class="dirt-section-list dirt-contributor-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span *ngIf="total">{{ total.count | number }}</span>
        Contributors
      </h1>
    </div>
    <div class="col-sm-4">
      <form (ngSubmit)="doLoad()">
        <div class="input-group row">
          <div ngbDropdown class="input-group-prepend">
            <button type="button" class="btn btn-secondary" ngbDropdownToggle>
              {{ searchName.name | capitalize }}
            </button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button type="button" class="dropdown-item" *ngFor="let name of names" (click)="onNameChange(name)">
                {{ name.name | capitalize }}
              </button>
            </div>
          </div>
          <input
            type="search"
            class="form-control"
            [formControl]="searchCtrl"
            [(ngModel)]="searchTerm"
            placeholder="Search..."
          />
          <span class="input-group-append">
            <button type="submit" class="btn btn-success" [disabled]="isLoading || isCounting">Go!</button>
          </span>
        </div>
      </form>
    </div>
    <div class="col-sm-4">
      <div class="pull-right">
        <dirt-bulk-list-button *diAcl="'contributor.bulkList'" [delegate]="bulkListDelegate"></dirt-bulk-list-button>
      </div>
    </div>
  </header>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-contributor-list'"
    [pagingSize]="people?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div *ngIf="people" [hidden]="isLoading">
    <table class="table table-sm table-hover" [hidden]="!people.length" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-name" [diColSorter]="'person.lastName'">Last Name</th>
          <th class="col-name" [diColSorter]="'person.firstName'">First Name</th>
          <th class="col-name" [diColSorter]="'person.middleName'">Middle Name</th>
          <th class="col-name">Suffix</th>
          <th class="col-name" [diColSorter]="'person.fullName'">Full Name</th>
          <th class="col-event">Event</th>
          <th class="col-event-date">Event Date</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="dirt-contributor-row"
          *ngFor="
            let item of people
              | paginate
                : {
                    id: 'dirt-contributor-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  }
          "
        >
          <td class="col-name">
            <a
              [routerLink]="['/contribution/detail', item._id]"
              class="di-link"
              [title]="item.person.lastName"
              rel="noopener"
              target="_blank"
              >{{ item.person.lastName }}</a
            >
          </td>
          <td class="col-name">
            <a
              [routerLink]="['/contribution/detail', item._id]"
              class="di-link"
              [title]="item.person.firstName"
              rel="noopener"
              target="_blank"
              >{{ item.person.firstName }}</a
            >
          </td>
          <td class="col-name">{{ item.person.middleName }}</td>
          <td class="col-name">{{ item.person.suffix }}</td>
          <td class="col-name">
            <a
              [routerLink]="['/contribution/detail', item._id]"
              class="di-link"
              [title]="item.person.fullName"
              rel="noopener"
              target="_blank"
              >{{ item.person.fullName }}</a
            >
          </td>
          <td class="col-event">
            <a
              [routerLink]="['/event/detail', item.event._id]"
              class="di-link"
              [title]="item.event.name"
              rel="noopener"
              target="_blank"
              >{{ item.event.name }}</a
            >
          </td>
          <td class="col-event-date">{{ item.event.startDate | date : 'dd.MM.y' }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <p class="dirt-no-results" *ngIf="!people.length">No People</p>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-contributor-list'"
    [pagingSize]="people?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="isLoading || pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
