import { NgModule } from '@angular/core';

import { AccountFinancialInformationAPI } from './shared/account-financial-information-api.service';
import { AccountFinancialInformationComponent } from './account-financial-information.component';
import { AccountFinancialInformationCreateComponent } from './create/create.component';
import { AccountFinancialInformationDetailComponent } from './detail/detail.component';
import { AccountFinancialInformationFormComponent } from './shared/form/form.component';
import { AccountFinancialInformationListComponent } from './list/list.component';
import { accountFinancialInformationRouting } from './account-financial-information.routes';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [SharedModule, accountFinancialInformationRouting],
  declarations: [
    AccountFinancialInformationComponent,
    AccountFinancialInformationListComponent,
    AccountFinancialInformationCreateComponent,
    AccountFinancialInformationDetailComponent,
    AccountFinancialInformationFormComponent,
  ],
  exports: [AccountFinancialInformationListComponent],
  providers: [AccountFinancialInformationAPI],
})
export class AccountFinancialInformationModule {}
