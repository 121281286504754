import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class ImageAPI extends APIService {
  public getPreview(source: string): Observable<Blob> {
    return this.http.get(this.getBaseUrl() + `images/preview?source=${source}`, { responseType: 'blob' }); // bypass APIService interceptor
  }
}
