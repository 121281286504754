import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { ContributorAPI } from '../shared/contributor-api.service';
import { NAME } from '../shared/constant/name';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { IMultiSelectOption } from '../../shared/components/multiselect-dropdown/types';

@Component({
  selector: 'dirt-contributor-list',
  templateUrl: 'list.component.html',
  styleUrls: ['list.component.scss'],
})
export class ContributorListComponent implements OnInit {
  people: any[];
  isLoading = false;
  isCounting = false;

  total: any;
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  sort: string;

  searchTerm: string;
  names: IMultiSelectOption[] = [];
  searchName: IMultiSelectOption;
  searchCtrl: FormControl = new FormControl('');

  bulkListDelegate: BulkDelegate = {
    title: 'Bulk Import',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListUpload.bind(this),
    specificNotifyReloadFct: this.getPeople.bind(this),
  };

  constructor(private svcContrib: ContributorAPI) {}

  ngOnInit() {
    this.normalizeNames();
    this.doLoad();
  }

  private normalizeNames() {
    Object.keys(NAME).forEach((t) => this.names.push({ id: t, name: NAME[t] }));

    this.searchName = this.names[2];
  }

  doLoad(): void {
    this.resetPagination();
    this.getCount();
    this.getPeople();
  }

  getPeople(): void {
    this.isLoading = true;

    this.svcContrib.find(this.searchTerm, this.searchName.id, this.pagingLimit, this.pagingSkip, this.sort).subscribe(
      (resp) => (this.people = resp),
      null,
      () => (this.isLoading = false)
    );
  }

  getCount() {
    this.isCounting = true;

    this.svcContrib.count(this.searchTerm, this.searchName.id).subscribe(
      (res) => (this.total = res),
      null,
      () => (this.isCounting = false)
    );
  }

  getPage(page: number) {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getPeople();
  }

  resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  onSort(field: string): void {
    this.sort = field;
    this.resetPagination();
    this.getPeople();
  }

  onNameChange(name: IMultiSelectOption): void {
    this.searchName = name;
  }

  private handleBulkListUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcContrib.startBulkListUpload(file, secondEyes);
  }
}
