<section class="dirt-section-list dirt-account-affiliation-suspects-list">
  <header class="row justify-content-between">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>Organization Suspects</span>
      </h1>
    </div>
  </header>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-account-affiliation-suspects'"
    [pagingSize]="accountAffiliationSuspects?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table
      class="table table-sm table-hover"
      *ngIf="accountAffiliationSuspects?.length; else empty"
      (diSort)="onSort($event)"
    >
      <thead>
        <tr>
          <th class="col-status">Match Status</th>
          <th class="col-name">Name</th>
          <th class="col-type">Type</th>
          <th class="col-state">State</th>
          <th class="col-country">Country</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-account-affiliation-suspects-row"
          [ngClass]="{ 'row-match': row.status === 'MATCH', 'row-mismatch': row.status === 'MISMATCH' }"
          *ngFor="
            let row of accountAffiliationSuspects
              | paginate
                : {
                    id: 'dirt-account-affiliation-suspects',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <td class="col-status">
            <select
              id="status"
              class="form-control"
              name="status"
              [(ngModel)]="row.status"
              (change)="onAffiliationSuspectStatusChange(row, $event)"
              [disabled]="!canEditAffiliation"
            >
              <option [value]="'MATCH'">YES</option>
              <option [value]="'MISMATCH'">NO</option>
              <option [value]="'SUSPECT'">--</option>
            </select>
          </td>
          <td class="col-name">
            <a
              class="dirt-affiliation-name"
              [title]="row.affiliation?.name | dash"
              [routerLink]="['/affiliation/detail', row.affiliation?.id]"
              target="_blank"
              >{{ row.affiliation?.name | dash }}</a
            >
          </td>
          <td class="col-type">
            {{ row.affiliation?.type | capitalize }}
          </td>
          <td class="col-state">{{ row.affiliation?.address?.state }}</td>
          <td class="col-country">{{ row.affiliation?.address?.country }}</td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No Organization Suspects</p>
    </ng-template>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
