<section class="dirt-section-create dirt-contribution-create">
  <a class="dirt-btn-back btn btn-primary-outline btn-sm" [routerLink]="['/event/detail', eventId]">
    <span class="fa fa-arrow-left" aria-hidden="true"></span>
  </a>
  <h1 class="bounceInRight">Create Contribution</h1>

  <br />

  <dirt-contribution-form [contribution]="contribution" [isSubmitting]="isSubmitting" (onSubmitted)="onSubmit($event)">
  </dirt-contribution-form>
</section>
