import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { PersonAffiliationModalComponent } from '../components/affiliation-modal/modal.component';
import { Affiliation } from '../../affiliation/shared/affiliation';

@Injectable()
export class PersonAffiliationModalService {
  ref: NgbModalRef;

  constructor(private readonly svcModal: NgbModal) {}

  open(
    sourceItem?: Affiliation,
    disableNameField?: boolean,
    currentJobType?: string,
    customerRequest?: boolean,
    requestOwnerProduct?: string
  ): Promise<any> {
    this.ref = this.svcModal.open(PersonAffiliationModalComponent, { size: 'xl', backdrop: 'static' });

    this.ref.componentInstance.sourceItem = sourceItem;
    if (disableNameField) {
      this.ref.componentInstance.disableNameField = disableNameField;
    } else {
      this.ref.componentInstance.disableNameField = false;
    }

    this.ref.componentInstance.currentJobType = currentJobType;
    this.ref.componentInstance.isCustomerRequest = customerRequest;
    this.ref.componentInstance.requestOwnerProduct = requestOwnerProduct;

    return this.ref.result;
  }
}
