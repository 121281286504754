import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET, POST, Path, Body, MediaType, Produces } from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { SentimentCuration, SentimentManualCuration, SentimentType } from './sentiment';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SentimentAPI extends APIService {
  @GET('sentiment/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<SentimentCuration> {
    return;
  }

  @POST('sentiment/submit')
  @Produces(MediaType.JSON)
  public submit(
    @Body
    curations: {
      type: SentimentType;
      entityId: string;
      manualCuration: SentimentManualCuration[] /* replace existing, needs completeness */;
    }[]
  ): Observable<{ ok: boolean; incomplete?: boolean }> {
    return;
  }

  @GET('sentiment/next')
  @Produces(MediaType.JSON)
  public findNext(): Observable<SentimentCuration | null> {
    return;
  }

  /**
   * Upload the file and get a bulk upload ID
   *
   * @param file
   * @param secondEyes
   */
  public startBulkListUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'sentiment/bulk-list', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }
}
