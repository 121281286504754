<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Move Contribution</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="form-group row">
    <div class="col-sm-10">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        [ngbTypeahead]="searchAutoComplete"
        [focusFirst]="false"
        [resultTemplate]="searchResultTemplate"
        (selectItem)="onSelectSession($event)"
        [(ngModel)]="searchTerm"
        placeholder="Search..."
        [disabled]="moveToDirect"
      />
    </div>
  </div>
  <div class="form-group row" *ngIf="!directContribution">
    <div class="col-sm-1"><input type="checkbox" [(ngModel)]="moveToDirect" (change)="updateIdToMove($event)" /></div>
    <div>
      <label><h6>Move to Direct</h6></label>
    </div>
  </div>
  <div class="form-group row">
    <div class="col-sm-10" *ngIf="contributionsContainsChairperson && moveToDirect">
      <div class="alert alert-warning">
        <strong>Warning!</strong> Position Chairperson can only be created on session level
      </div>
    </div>
    <div class="col-sm-10" *ngIf="contributionsContainsOrganiser">
      <div class="alert alert-warning">
        <strong>Warning!</strong> Position Organizers can only be created on the event level
      </div>
    </div>
  </div>

  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</div>
<div class="modal-footer">
  <input type="checkbox" [(ngModel)]="makeACopy" *ngIf="!allContributionOnMove" />
  <strong *ngIf="!allContributionOnMove">Make a copy</strong>
  <button type="button" class="btn btn-primary" [disabled]="!sessionSelected" (click)="moveContribution()">Save</button>
  <button type="button" class="btn btn-secondary btn-sm" (click)="activeModal.dismiss()">Cancel</button>
</div>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div class="form-group row" *ngIf="r.id !== eventID">
    <div class="col-sm-9">
      <span>{{ r.name }}</span>
    </div>
  </div>
</ng-template>
