import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { InputErrorMsgComponent } from './input-error-msg.component';
import { InputErrorMsgDirective } from './input-error-msg.directive';

@NgModule({
  declarations: [InputErrorMsgComponent, InputErrorMsgDirective],
  imports: [CommonModule, FormsModule, BrowserAnimationsModule, NgbTooltipModule],
  exports: [InputErrorMsgComponent, InputErrorMsgDirective],
})
export class InputErrorMsgModule {}
