import { Component, Input, OnInit } from '@angular/core';
import { EventAdvancedSearchDto } from '../../event-advanced-search-dto';

@Component({
  selector: 'dirt-event-advanced-search',
  templateUrl: 'advanced-search.html',
  styleUrls: ['advanced-search.scss'],
})
export class EventAdvancedSearchComponent implements OnInit {
  @Input()
  model: EventAdvancedSearchDto = {};

  @Input()
  hideSessionFilters?: boolean;

  ngOnInit(): void {
    // Initialize our search model
    this.model.filter = {
      ...(this.model?.filter || {}),
      startDate: {},
    };
  }
}
