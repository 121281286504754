<section class="dirt-section-create dirt-user-pool-create">
  <a class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
    <span class="fa fa-arrow-left" aria-hidden="true"></span>
  </a>

  <h1 class="bounceInRight">Create User Pool</h1>

  <br />

  <dirt-user-pool-form [pool]="pool" #frmPool="frmPool"></dirt-user-pool-form>

  <div class="pull-right">
    <button
      type="submit"
      class="btn btn-primary"
      [ngClass]="{ 'btn-loading': isSubmitting }"
      [disabled]="!frmPool.isValid()"
      (click)="onSubmit(frmPool.getValue())"
      *diAcl="'userPool.create'"
    >
      <span>Submit</span>
    </button>
  </div>
</section>
