import { Component } from '@angular/core';

@Component({
  selector: 'dirt-clinical-trial-sponsor',
  template: `
    <dirt-admin-header></dirt-admin-header>
    <div class="container-fluid">
      <section class="dirt-navbar-sub">
        <nav class="navbar navbar-expand-lg navbar-light">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item" *diAcl="'clinicalTrialProfile.list'">
              <a class="nav-link" routerLink="/ct-profile" routerLinkActive="is-active">CT</a>
            </li>
            <li class="nav-item" *diAcl="'clinicalTrialSponsor.list'">
              <a class="nav-link" routerLink="/ct-sponsor" routerLinkActive="is-active">Sponsor</a>
            </li>
          </ul>
        </nav>
        &nbsp;

        <router-outlet></router-outlet>
      </section>
    </div>
  `,
})
export class ClinicalTrialSponsorComponent {}
