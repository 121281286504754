import { Component, Input, OnInit } from '@angular/core';

import { FontWeight } from '../../values/font-weight';
import { UserAPI } from '../../../user/shared/api.service';
import { Roles } from '../../acl/roles';

@Component({
  selector: 'dirt-username',
  templateUrl: 'username.component.html',
  styleUrls: ['username.component.scss'],
})
export class UsernameComponent implements OnInit {
  @Input() user: any;
  @Input() mailto: boolean;
  @Input() reverse = false;
  @Input() fontWeight: FontWeight;
  @Input() id: string;
  @Input() name: string;
  @Input() displayUserBadge: boolean;

  /** Lookup the user by its ID */
  @Input() userId: string;

  constructor(private svcUser: UserAPI) {}

  ngOnInit(): void {
    if (!this.userId || this.user) {
      return;
    }

    this.svcUser.findById(this.userId).subscribe((user) => {
      this.user = user || this.userId;
    });
  }

  getName(): string {
    const firstName = (this.user.user_metadata || this.user).firstName;
    const lastName = (this.user.user_metadata || this.user).lastName;

    if (firstName && lastName) {
      return this.reverse ? `${lastName}, ${firstName}` : `${firstName} ${lastName}`;
    } else {
      return this.user.name;
    }
  }

  getUserAlias(userId: string = ''): string {
    return userId.slice(-3);
  }

  isLFKAUser(): boolean {
    if (!Array.isArray(this.user?.app_metadata?.roles)) {
      return false;
    }

    return this.user.app_metadata.roles.some((r: string) => r === Roles.ViewLfka || r.startsWith('ACCOUNT_'));
  }

  hideLFKAUserBadge(): boolean {
    return !this.displayUserBadge;
  }

  isMedTechUser(): boolean {
    if (!Array.isArray(this.user?.app_metadata?.roles)) {
      return false;
    }
    return this.user.app_metadata.roles.some(
      (r: string) => r === Roles.MedInsCompiler || r === Roles.MedInsManager || r === Roles.IsMedTech
    );
  }
}
