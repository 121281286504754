import { Component, OnInit } from '@angular/core';

import { ACL } from '../shared/acl/acl.service';
import { Roles } from '../shared/acl/roles';
import { ValueAPI } from '../shared/services/value/value-api.service';
import { ValueType } from '../shared/enum/value-type.enum';
import { checkRegionRestrictSimple } from '../shared/values/region-restrict';

@Component({
  selector: 'dirt-event',
  template: `
    <dirt-admin-header></dirt-admin-header>
    <div class="container-fluid">
      <section class="dirt-navbar-sub">
        <nav class="navbar navbar-expand-lg navbar-light">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item" *diAcl="'event.list'">
              <a class="nav-link" [routerLink]="['/event/list']" [routerLinkActive]="['is-active']">Events</a>
            </li>
            <li class="nav-item" *diAcl="'eventSeries.list'">
              <a class="nav-link" [routerLink]="['/event-series']" [routerLinkActive]="['is-active']">Series</a>
            </li>
            <ng-template [diAcl]="'contributor.list'" [diAclAnd]="isRegionCompliant">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/contributor/list']" [routerLinkActive]="['is-active']"
                  >Contributors</a
                >
              </li>
            </ng-template>
          </ul>
        </nav>
        &nbsp;
        <router-outlet></router-outlet>
      </section>
    </div>
  `,
})
export class EventComponent implements OnInit {
  isRegionCompliant = false;

  constructor(private readonly svcAcl: ACL, private readonly svcValue: ValueAPI) {}

  ngOnInit(): void {
    if (
      this.svcAcl.hasRole(Roles.Admin) ||
      this.svcAcl.hasRole(Roles.EventManager) ||
      this.svcAcl.hasRole(Roles.EventModerator)
    ) {
      this.isRegionCompliant = true;
    } else {
      this.svcValue.find(ValueType.RegionalRestrict, Number.MAX_SAFE_INTEGER).subscribe((values) => {
        this.isRegionCompliant = checkRegionRestrictSimple(this.svcAcl, values);
      });
    }
  }
}
