<form novalidate>
  <div class="clearfix">
    <!-- Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="name">Name</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          id="name"
          class="form-control"
          name="name"
          [(ngModel)]="model.name"
          [required]="true"
          [readonly]="!canEditField()"
          autofocus
        />
      </div>
    </div>

    <!-- users -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Users</label>
      <div class="col-sm-8 col-md-10">
        <dirt-multi-user-select
          [initialUserIds]="model.users"
          [selectedUsers]="users"
          [roles]="searchRoleConfig"
          (selectedUsersChange)="onSelectedUsersChange($event)"
        >
        </dirt-multi-user-select>
      </div>
    </div>

    <!-- Disabled -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Disabled</label>
      <div class="col-sm-8 col-md-10">
        <ui-switch size="small" name="disabled" [(ngModel)]="model.disabled" [disabled]="!canEditField()"></ui-switch>
      </div>
    </div>
  </div>

  <!-- Regional -->
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Limit to region of curators</label>
    <div class="col-sm-8 col-md-10">
      <ui-switch size="small" name="regional" [(ngModel)]="model.regional" [disabled]="!canEditField()"></ui-switch>
    </div>
  </div>

  <!-- Limit projects assignment -->
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Limit to projects of curators</label>
    <div class="col-sm-8 col-md-10 pt-1">
      <ui-switch
        size="small"
        [checked]="!!model.projectWise"
        [disabled]="model.capacities?.length > 0"
        (change)="onHasProjectWiseChange($event)"
      >
      </ui-switch>
      <div><small>So far only works for KOLs</small></div>
    </div>
  </div>
  <!-- capacity -->
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Reserved capacity</label>
    <div class="col-sm-8 col-md-10 pt-1">
      <div *ngFor="let c of model.capacities || []; let idx = index">
        <label
          >Product
          <select [ngModelOptions]="{ standalone: true }" [(ngModel)]="c.product" [disabled]="c.projectName">
            <option [value]="null"></option>
            <option *ngFor="let p of products" [value]="p.value">{{ p.value }}</option>
          </select></label
        >
        <label
          >Project
          <select
            style="max-width: 300px"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="c.projectName"
            [disabled]="c.product"
          >
            <option [value]="null"></option>
            <option *ngFor="let p of projectNames" [value]="p.code">{{ p.title || p.code }}</option>
          </select></label
        >
        <label
          ><input
            type="number"
            min="0"
            style="text-align: right; width: 50px"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="c.percent"
            [disabled]="!(c.projectName || c.product)"
          />%</label
        >
        <button (click)="removeReserved(idx)">-</button>
      </div>
      <div>
        <button [disabled]="model.projectWise" (click)="addReserved()">+</button>
        <span class="sumreserved" [ngClass]="{ invalid: sumReserved() > 100 }">Reserved: {{ sumReserved() }}%</span>
      </div>
      <div><small>So far only works for KOLs</small></div>
    </div>
  </div>
</form>
