export class ProjectTagValue {
  // effectively the Value class from shared/services, but w/ a few special attributes
  _id: string;

  code: string;
  title: string;
  type: 'project' | 'person-project';
  active: boolean;
  delivery: boolean;
  technical: boolean;
  product: string;

  constructor(type?) {
    if (type) {
      this.type = type;
    }
  }
}
