import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { JobsAPI } from '../../shared/api.service';
import { Job } from '../../shared/job';
import { Utils } from '../../../common/utils';

@Component({
  selector: 'dirt-next-job-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class JobsNextListComponent implements OnInit {
  isLoading = false;
  jobs = [];
  completedJobs = [];
  isLoadingCompletedJobs = false;

  constructor(private svcJob: JobsAPI, private router: Router) {}

  ngOnInit(): void {
    this.getNextJobs();
    this.getCompletedJobs();
  }

  getNextJobs() {
    this.isLoading = true;
    this.svcJob.findNextList().subscribe(
      (jobs) => {
        this.jobs = jobs;
      },
      (err) => alert('Error!'),
      () => (this.isLoading = false)
    );
  }

  getCompletedJobs() {
    this.isLoadingCompletedJobs = true;
    this.svcJob.findCompletedList().subscribe(
      (jobs) => {
        this.completedJobs = jobs;
      },
      (err) => alert('Error!'),
      () => (this.isLoadingCompletedJobs = false)
    );
  }

  onWorkClick(job: Job) {
    const route = this.determineRoute(job);
    if (route) {
      this.router.navigate(route);
    }
  }

  hasRoute(job: Job) {
    return !!this.determineRoute(job);
  }

  private determineRoute(job: Job): string[] | null {
    if ('videos' === job.entityType) {
      return ['/video-job/detail', job._id];
    } else {
      return null;
    }
  }

  hasVideoJob() {
    return this.jobs.find((item) => item.type === 'video');
  }

  hasVideoJobInCompletedJobs() {
    return this.completedJobs.find((item) => item.type === 'video');
  }

  getVideoId(job: Job) {
    if (!job.extEntityId) {
      return;
    }
    return Utils.getYouTubeId(job.extEntityId);
  }
}
