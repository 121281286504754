import { CanActivate } from '@angular/router';
import { Injectable, OnInit } from '@angular/core';

import { ACL } from '../../../shared/acl/acl.service';
import { Roles } from '../../../shared/acl/roles';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { checkRegionRestrictSimple, RegionRestrict } from '../../../shared/values/region-restrict';
import { ValueType } from '../../../shared/enum/value-type.enum';

@Injectable()
export class RegionRestrictGuard implements CanActivate, OnInit {
  // we don't check the actual situation
  constructor(private readonly svcAcl: ACL, private readonly svcValue: ValueAPI) {}

  regionRestrict: RegionRestrict[] = [];

  ngOnInit() {
    this.svcValue.find(ValueType.RegionalRestrict, Number.MAX_SAFE_INTEGER).subscribe((values) => {
      this.regionRestrict = values;
    });
  }

  canActivate(): boolean {
    if (
      this.svcAcl.hasRole(Roles.Admin) ||
      this.svcAcl.hasRole(Roles.EventManager) ||
      this.svcAcl.hasRole(Roles.EventModerator)
    ) {
      return true;
    }

    return checkRegionRestrictSimple(this.svcAcl, this.regionRestrict);
  }
}
