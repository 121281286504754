<ngb-rating
  [max]="5"
  [(rate)]="profile.priority"
  (rateChange)="setPriority(profile, $event)"
  [readonly]="!isEditable()"
>
  <ng-template let-fill="fill" let-index="index">
    <span class="star" [class.filled]="fill === 100">&#9733;</span>
  </ng-template>
</ngb-rating>
