import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { cloneDeep } from 'lodash';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { NgForm } from '@angular/forms';

import { AccountMedicalInformation } from '../account-medical-information';
import { ACL } from '../../../shared/acl/acl.service';
import { Utils } from '../../../common/utils';
import { Value } from '../../../shared/services/value/value';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';

@Component({
  selector: 'dirt-account-medical-information-form',
  templateUrl: './form.component.html',
  exportAs: 'frmAccountMedicalInformation',
})
export class AccountMedicalInformationFormComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input('accountMedicalInformation')
  model: AccountMedicalInformation = new AccountMedicalInformation();

  @Output()
  validityChange: EventEmitter<'VALID' | 'INVALID'> = new EventEmitter();

  @ViewChild('ngForm')
  ngForm: NgForm;

  currencies: Value[] = [];

  private destroy$: Subject<boolean> = new Subject();

  constructor(private svcAcl: ACL, private svcValue: ValueAPI) {}

  ngOnChanges(): void {
    this.initMultiFields();
  }

  ngOnInit(): void {
    this.svcValue.find(ValueType.Currencies, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((currencies) => {
      this.currencies = currencies || [];
    });
  }

  ngAfterViewInit(): void {
    this.ngForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
      this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  getValue(): AccountMedicalInformation {
    const model = cloneDeep(this.model);

    Utils.removeEmptyAndDuplicatedElementsFromArray(model, ['websources']);

    return model;
  }

  isFieldEditable(field: string): boolean {
    const module = 'account-medical-information';
    const prefix = this.model.id ? 'update' : 'create';
    return this.svcAcl.hasCredential(`${module}.${prefix}.prop.${field}`);
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }

  pushObjectToList(list: any[]): void {
    list.push({ name: '' });
  }

  trackByIndex(index: number): number {
    return index;
  }

  private isValid(): boolean {
    return this.ngForm.form.valid;
  }

  private initMultiFields(): void {
    const fields = ['websources'];

    fields.forEach((field) => {
      if (!Array.isArray(this.model[field]) || this.model[field].length === 0 || this.model[field].every((v) => !v)) {
        this.model[field] = [''];
      }
    });
  }
}
