import { Injectable } from '@angular/core';
import { Router, PRIMARY_OUTLET } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class StickyFilterService {
  private keyPrefix = 'route-state';
  private filterState: Map<string, { [key: string]: any }> = new Map();

  constructor(private readonly router: Router) {}

  /**
   * Gets the state, if any, for a given key.
   */
  getState(key: string): { [key: string]: any } {
    const storageKey = `${this.keyPrefix}-${key}`;
    if (this.filterState.has(storageKey)) {
      return this.filterState.get(storageKey);
    }
  }

  /**
   * Persists a states if there's any value passed, otherwise removes from state.
   */
  toggleState(key: string, value?: any) {
    const storageKey = `${this.keyPrefix}-${key}`;

    if (value) {
      this.filterState.set(storageKey, value);
    } else {
      this.filterState.delete(storageKey);
    }
  }

  /**
   * Removes all query params from a given url.
   */
  getPathFromUrl(url: string): string {
    const tree = this.router.parseUrl(url);
    const segmentGroup = tree.root.children[PRIMARY_OUTLET];

    if (!segmentGroup) {
      return '';
    }

    return segmentGroup.segments.map((it) => it.path).join('/');
  }
}
