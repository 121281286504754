import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PersonAPI } from '../shared/api.service';

interface Stat {
  user_id: string;
  name: string;
  completed: number;
  total: number;
  percent: number;
  remaining: number;
  reviewCap: number;
  underperformer: boolean;
}

@Component({
  selector: 'dirt-person-qc-next',
  templateUrl: './qc-next.component.html',
  styleUrls: ['./qc-next.component.scss'],
})
export class QcNextComponent implements OnInit {
  queue: any;
  isLoadingNext: boolean = false;

  constructor(private router: Router, private svcPerson: PersonAPI) {}

  ngOnInit() {
    this.getQueue();
  }

  getQueue() {
    this.svcPerson.qcQueue().subscribe((q) => {
      this.queue = q.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });
    });
  }

  getNext(): void {
    this.isLoadingNext = true;

    this.svcPerson.findQCNext().subscribe(
      (personId) => {
        if (!personId) {
          return alert('No people to be reviewed at the moment! Go and get some rest ;)');
        }

        this.router.navigate(['/person/detail', personId]);
      },
      (err) => alert('Error!'),
      () => (this.isLoadingNext = false)
    );
  }

  getPercentComplete(stat: Stat) {
    return (stat.completed * 100) / stat.total;
  }

  getstatusText(stat: Stat) {
    return `${stat.completed}/${stat.total} ${stat.percent}%`;
  }

  isComplete(stat: Stat) {
    return this.getPercentComplete(stat) >= stat.reviewCap;
  }
}
