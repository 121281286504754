import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { distinctUntilChanged, Observable, Subject, takeUntil } from 'rxjs';
import { NgForm } from '@angular/forms';

import { ACL } from '../../../shared/acl/acl.service';
import { EventSeries } from '../event-series';
import { Value } from '../../../shared/services/value/value';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { IMultiSelectSettings } from '../../../shared/components/multiselect-dropdown/types';

const CLS_MULTISELECT_BTN = 'btn btn-sm btn-secondary';

@Component({
  selector: 'dirt-event-series-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
  exportAs: 'frmEventSeries',
})
export class EventSeriesFormComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input()
  model: EventSeries = new EventSeries();

  @Output()
  validityChange: EventEmitter<'VALID' | 'INVALID'> = new EventEmitter();

  languages$: Observable<Value[]>;

  @ViewChild(NgForm)
  private ngForm: NgForm;

  projects = [];
  projectsSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    dynamicTitleMaxItems: 5,
  };
  types: Value[] = [];
  categories: Value[] = [];
  products: Value[];

  countries: Value[];

  nonCompliantDomainType: string = ValueType.NonCompliantDomainsActivities;

  private destroy$: Subject<boolean> = new Subject();

  constructor(private svcAcl: ACL, private svcValue: ValueAPI) {}

  ngOnInit(): void {
    this.languages$ = this.svcValue.find(ValueType.Language, Number.MAX_SAFE_INTEGER, 0, '+title');
    this.loadProjects();
    this.loadCategories();
    this.loadCountries();
    this.loadProducts();
    this.loadTypes();
  }

  loadProjects() {
    this.svcValue.find(ValueType.Project, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.projects = data.map((o) => ({
        id: o.code,
        name: o.title,
        disabled: o.disabled,
      }));
    });
  }

  loadCategories() {
    this.svcValue.find(ValueType.Category, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.categories = data;
    });
  }

  loadCountries() {
    this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      data.push({
        code: 'INT',
        title: 'International',
        id: null,
        area: [],
        type: ValueType.Country,
        disabled: false,
        value: null,
        order: null,
      });
      this.countries = data;
    });
  }

  loadProducts() {
    this.svcValue.find(ValueType.Product, Number.MAX_SAFE_INTEGER, 0).subscribe((data) => {
      this.products = [
        ...data.filter((d) => 'LFTA' === d.value), // make sure it's first
        ...data.filter((d) => 'LFTA' !== d.value),
      ];
    });
  }

  loadTypes() {
    this.svcValue.find(ValueType.EventType, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.types = data;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && changes.model.currentValue) {
      this.model.webSources = this.model.webSources || [];
      if (this.model.webSources.length < 1) {
        this.model.webSources.push('');
      }
    }
  }

  ngAfterViewInit(): void {
    this.ngForm.statusChanges.pipe(takeUntil(this.destroy$), distinctUntilChanged()).subscribe(() => {
      this.validityChange.emit(this.isValid() ? 'VALID' : 'INVALID');
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  isFieldEditable(field: string): boolean {
    const prefix = this.model.id ? 'update' : 'create';
    return this.svcAcl.hasCredential(`eventSeries.${prefix}.prop.${field}`);
  }

  getValue(): EventSeries {
    return { ...this.model };
  }

  onTypeChange(newValue) {
    if (!this.model.category) {
      if ('WORKSHOP' === newValue) {
        this.model.category = 4;
      } else if (
        'BOARD_MEETING' === newValue ||
        'COMMITTEE_MEETING' === newValue ||
        'INVESTOR_PRESENTATION' === newValue
      ) {
        this.model.category = 5;
      }
    }
  }

  trackByIndex(index: number): number {
    return index;
  }

  private isValid(): boolean {
    return this.ngForm.form.valid;
  }
}
