import { PersonAffiliation } from './person-affiliation';
import { PersonAffiliationLfka } from './person-affiliation-lfka';
import { User } from '../../user/shared/user';
import { Twitter } from '../../shared/values/twitter';
import { CreatedByTeam } from './constant/team.enum';
import { PersonDuplicateTakeover } from './person-dup-takeover';
import { PersonAffiliationEducation } from './person-affiliation-education';
import { PersonCompliance } from './person-compliance';

export class Person {
  id: string;
  alternativeNames: string[] = [];
  affiliations: PersonAffiliation[] = [];
  affiliationsLfka: PersonAffiliationLfka[] = [];
  affiliationsEducation: PersonAffiliationEducation[] = [];
  affiliationsLinks: string[] = [];
  countryWorkflow: string;
  countryOverride?: string;
  createdAt: string;
  cvLinks: string[] = [];
  originalMasterCVLink?: string;
  nameLinks: string[] = [];
  positionLinks: string[] = [];
  statusLinks: string[] = [];
  degrees: string[] = [];
  degreeLinks: string[] = [];
  emails: string[] = [];
  externalIds: {
    npi?: string;
    sln?: string;
    authorId?: string;
    openData?: string;
  } = {};
  faxes: string[] = [];
  firstName: string;
  kolId: string;
  lastName: string;
  middleName: string;
  nicknames: string[] = [];
  optOutInquiry: string;
  originalFirstName: string;
  originalMiddleName: string;
  originalLastName: string;
  originalFullName: string;
  priority: number;
  profession: string;
  projectNames: string[];
  manualProjectNames?: string[];
  published: boolean;
  qc: any;
  readyForDelivery: boolean;
  readyForDeliveryLFTA: boolean;
  readyForDeliveryLFKA: boolean;
  review?: { at: Date; by?: string | User };
  identify?: { at: Date; by?: string | User };
  discard?: { at: Date; by?: string | User };
  kppCurated: boolean;
  segments: string[] = [];
  social?: {
    twitter?: Twitter;
    instagram?: {
      username?: string;
      notFound?: boolean;
    };
    tiktok?: {
      username?: string;
      notFound?: boolean;
    };
    linkedinUrl?: string;
  };
  image: {
    url?: string;
    source?: string;
    local_url?: string;
    local_url_nocache?: string;
    approved?: boolean;
    notFound?: boolean;
  };
  source: string;
  specialties: string[] = [];
  originalSpecialties: string[] = [];
  status: string;
  optoutStatus: string[] = [];
  specialtyLinks: string[] = [];
  suffix: string;
  tags: string[] = [];
  talkingPoints: string[] = [];
  dupTakeovers: PersonDuplicateTakeover[];
  twitter: string;
  updatedAt: string;
  verified: boolean;
  idverified: boolean;
  verificationSkipped: boolean;
  idverificationSkipped: boolean;
  _meta: {
    assignee?: string;
    status: string;
    statusIdOutReason?: string;
    statusIdOutComment?: string;
    claimedUntil?: Date;
    draftUntil?: Date;
    consecutiveDraftCount?: number;
    jobId?: string;
  };
  _version: number;
  openDataStatus?: 'Rejected' | 'Candidate';
  training?: boolean;
  baseProfileId?: string;
  trainingEvaluationReport?: {
    compilerId?: string;
    comment?: string;
    reportDetails?: any;
  };
  basePersonId?: string;
  bdTrainingEvaluationReport?: {
    compilerId?: string;
    comment?: string;
    reportDetails?: any;
  };
  emailLinks: string[] = [];
  newAffiliationCreated?: boolean;
  linkedInNotFound: boolean;
  createdByTeam: CreatedByTeam;
  mergedKolIds?: string[];
  npiNotFound?: boolean;
  openDataNotFound?: boolean;
  futureSubentities: any;
  autoProfile?: boolean;
  phone: {
    countryCallingCode?: string;
    number?: string;
    extension?: string;
    type?: string;
  } = {};
  phoneLink: string;
  originalLanguage?: string;
  compliance?: PersonCompliance[];
  additionalDetails?: any;
  ownerProduct: string;

  constructor() {}
}
