import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { SentimentClassification, SentimentCuration, SentimentManualCuration } from '../sentiment';
import { take } from 'rxjs/operators';

@Component({
  selector: 'dirt-sentiment-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
  exportAs: 'frmSentiment',
})
export class SentimentFormComponent implements OnInit, OnChanges {
  @Input('sentiment')
  model: SentimentCuration = new SentimentCuration();
  indicationMap: { [subject: string]: string[] } = {};
  settingMap: { [subject: string]: string[] } = {};

  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  sentimentTagsTitles: string[] = [];
  sentimentsTitles: string[] = Object.values(SentimentClassification);

  constructor(private svcValue: ValueAPI) {}

  ngOnInit() {
    this.svcValue
      .find(ValueType.SentimentTag, Number.MAX_SAFE_INTEGER, 0, '+title')
      .pipe(take(1))
      .subscribe((data) => {
        this.sentimentTagsTitles = data.map((t) => t.title || '' + t.code);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.model) {
      // automatic-curation: ready to be pulled in
      if (!this.model.autoCuration) {
        this.model.autoCuration = [];
      }
      this.indicationMap = {};
      this.settingMap = {};
      this.model.autoCuration.forEach((ac) => {
        if (ac.indication) {
          if (!this.indicationMap[ac.subjectFaId]) {
            this.indicationMap[ac.subjectFaId] = [];
          }
          if (this.indicationMap[ac.subjectFaId].indexOf(ac.indication) < 0) {
            this.indicationMap[ac.subjectFaId].push(ac.indication);
          }
        }
        if (ac.setting) {
          if (!this.settingMap[ac.subjectFaId]) {
            this.settingMap[ac.subjectFaId] = [];
          }
          if (this.settingMap[ac.subjectFaId].indexOf(ac.setting) < 0) {
            this.settingMap[ac.subjectFaId].push(ac.setting);
          }
        }
      });
      // manual curation - sorted
      if (!this.model.manualCuration) {
        this.model.manualCuration = [];
      }
      this.model.manualCuration.forEach((mc) => {
        if ('n/a' === mc.tag) {
          mc.sentiment = SentimentClassification.NEUTRAL; // fix what comes in wrong
        }
      });
      this.reSortManualCurations(changes.model.currentValue);
    }
  }

  onTagChange(mc: SentimentManualCuration, newTag: string) {
    if ('n/a' === newTag) {
      mc.sentiment = SentimentClassification.NEUTRAL; // make sure we have sth for the disabled entry
    }
  }

  onAddAnother(idx: number) {
    this.model.manualCuration = [
      ...this.model.manualCuration.slice(0, idx + 1),
      {
        subject: this.model.manualCuration[idx].subject,
        subjectFaId: this.model.manualCuration[idx].subjectFaId,
      } as any, // (new one)
      ...this.model.manualCuration.slice(idx + 1),
    ];
  }
  canRemove(idx: number) {
    const currSubjectFaId = this.model.manualCuration[idx].subjectFaId;
    return this.model.manualCuration.filter((mc) => mc.subjectFaId === currSubjectFaId).length >= 2;
  }
  onRemove(idx: number) {
    // make sure we retain one on the same subject
    if (!this.canRemove(idx)) {
      return;
    }
    this.model.manualCuration = [
      ...this.model.manualCuration.slice(0, idx),
      // one will be left out here
      ...this.model.manualCuration.slice(idx + 1),
    ];
  }

  private reSortManualCurations(curation: SentimentCuration) {
    curation.manualCuration.sort((mc1, mc2) =>
      ((mc1.subjectFaId || '') + '#' + (mc1.tag || '')).localeCompare((mc2.subjectFaId || '') + '#' + (mc2.tag || ''))
    );
  }

  isNewRecord(): boolean {
    return !this.model.id;
  }

  isValid(): boolean {
    return this.ngForm.form.valid;
  }
}
