import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';
import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';

import { AffiliationComponent } from './affiliation.component';
import { AffiliationListComponent } from './list/list.component';
import { AffiliationDetailComponent } from './detail/detail.component';
import { AffiliationDetailMergeComponent } from './detail-merge/detail-merge.component';
import { AffiliationCreateComponent } from './create/create.component';
import { AffiliationModule } from './affiliation.module';

const affiliationRoutes: Routes = [
  {
    path: 'affiliation',
    component: AffiliationComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'create',
        component: AffiliationCreateComponent,
      },
      {
        path: 'detail/:id',
        component: AffiliationDetailComponent,
      },
      {
        path: 'detail-merge/:id',
        component: AffiliationDetailMergeComponent,
      },
      {
        path: 'list',
        component: AffiliationListComponent,
        data: { acl: 'affiliation.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
    data: { title: 'Organization' },
  },
];

export const affiliationRouting: ModuleWithProviders<AffiliationModule> = RouterModule.forChild(affiliationRoutes);
