import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';
import { AssociationComponent } from './association.component';
import { associationRouting } from './association.routes';
import { AssociationAPI } from './shared/association-api.service';
import { AssociationListComponent } from './list/list.component';
import { AssociationCreateComponent } from './create/create.component';
import { AssociationDetailComponent } from './detail/detail.component';
import { AssociationFormComponent } from './shared/form/form.component';
import { AssociationNextComponent } from './next/next.component';
import { MembershipModule } from '../membership/module';
import { CommentModule } from '../comment/comment.module';
import { AssociationFilterComponent } from './shared/filter/filter.component';
import { AssociationSearchComponent } from './search/search.component';
import { BulkUpdateModule } from '../common/bulk-update/bulk-update.module';
import { BulkListModule } from '../common/bulk-list/bulk-list.module';
import { BulkModule } from '../common/bulk/bulk.module';
import { OcrHelperModule } from '../ocr-helper/ocr-helper.module';
import { AssociationQcNextComponent } from './qc-next/qc-next.component';
import { AssociationSingleSelectComponent } from './shared/components/association-single-select/association-single-select';
import { AssociationModalService } from './shared/components/association-modal/association-modal.service';
import { AssociationModalComponent } from './shared/components/association-modal/association-modal.component';

@NgModule({
  imports: [
    SharedModule,
    CommentModule,
    MembershipModule,
    associationRouting,
    BulkUpdateModule,
    BulkListModule,
    BulkModule,
    OcrHelperModule,
  ],
  declarations: [
    AssociationComponent,
    AssociationListComponent,
    AssociationSearchComponent,
    AssociationDetailComponent,
    AssociationCreateComponent,
    AssociationNextComponent,
    AssociationFormComponent,
    AssociationFilterComponent,
    AssociationQcNextComponent,
    AssociationSingleSelectComponent,
    AssociationModalComponent,
  ],
  exports: [AssociationNextComponent, AssociationQcNextComponent, AssociationSingleSelectComponent],
  providers: [AssociationAPI, AssociationModalService],
})
export class AssociationModule {}
