import { Account } from '../../account/shared/account';

export class AccountPayerShareInformation {
  standardizedPayerName: string;
  payerName?: string;
  share: number;
  websources?: string[];
}

export class AccountPayerInformation {
  id: string;
  account: Account;
  year: number;
  payerShares: AccountPayerShareInformation[];
  createdAt: Date;
  updatedAt: Date;

  constructor() {}
}
