import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ColDef } from 'ag-grid/main';
import { ColGroupDef } from 'ag-grid';
import { ValueFormatterParams } from 'ag-grid/dist/lib/entities/colDef';

import { ACL } from '../../../../shared/acl/acl.service';
import { ProfileGridComponent } from '../../../shared/grid/grid.component';
import { ProfileDirectLinkService } from '../../../shared/modal/direct-link/direct-link.service';
import { ProfileDirectLinkOptions } from '../../../shared/modal/direct-link/direct-link-options.interface';
import { Grant } from '../../../../grant/shared/grant';
import { GrantAPI } from '../../../../grant/shared/grant-api.service';
import { ProfileGrantAPI } from '../shared/api.service';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { QCCommentService } from '../../../shared/grid/qc-comment/qc-comment.service';

@Component({
  selector: 'dirt-profile-grant-list',
  templateUrl: '../../../shared/grid/grid.component.html',
  styleUrls: ['../../../shared/grid/grid.component.scss'],
})
export class ProfileGrantListComponent extends ProfileGridComponent implements OnInit {
  FIELD_KEY_FA = '_profile.keyFocusAreas';
  FIELD_FIRST_NAME = 'person.firstName';
  FIELD_MIDDLE_NAME = 'person.middleName';
  FIELD_LAST_NAME = 'person.lastName';
  FIELD_AWARD_AMOUNT = 'grant.awardAmount';
  FIELD_PROJECT_TITLE = 'grant.projectTitle';
  FIELD_PROJECT_URL = 'grant.projectDetailURL';
  FIELD_ORG_NAME = 'organization.orgName';
  FIELD_ORG_CITY = 'organization.orgCity';
  FIELD_ORG_COUNTRY = 'organization.orgCountry';
  FIELD_ORG_STATE = 'organization.orgState';
  FIELD_ORG_DPT = 'organization.deptType';

  FIELD_FILTER_FIRST_NAME = 'firstName';
  FIELD_FILTER_MIDDLE_NAME = 'middleName';
  FIELD_FILTER_LAST_NAME = 'lastName';
  FIELD_FILTER_PROJECT_TITLE = 'projectTitle';

  FIELD_FILTER_ORG_NAME = 'orgName';
  FIELD_FILTER_ORG_CITY = 'orgCity';
  FIELD_FILTER_ORG_COUNTRY = 'orgCountry';
  FIELD_FILTER_ORG_STATE = 'orgState';
  FIELD_FILTER_ORG_DPT = 'deptType';

  constructor(
    protected svcProfileGrant: ProfileGrantAPI,
    private svcGrant: GrantAPI,
    private svcDirectLink: ProfileDirectLinkService,
    protected readonly svcACL: ACL,
    protected svcQCComment: QCCommentService
  ) {
    super('id', svcACL, svcQCComment);
  }

  ngOnInit() {
    this.addFilter(this.FIELD_FIRST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_FIRST_NAME, params)
    );
    this.addFilter(this.FIELD_MIDDLE_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_MIDDLE_NAME, params)
    );
    this.addFilter(this.FIELD_LAST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_LAST_NAME, params)
    );
    this.addFilter(this.FIELD_ORG_NAME, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_ORG_NAME, params)
    );
    this.addFilter(this.FIELD_ORG_CITY, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_ORG_CITY, params)
    );
    this.addFilter(this.FIELD_FILTER_PROJECT_TITLE, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_PROJECT_TITLE, params)
    );
    this.addFilter(this.FIELD_KEY_FA, true, 'keep', false, (params) =>
      this.defineGetFocusArea(this.profileId).subscribe((result) =>
        result && result[0] && result[0].focusArea
          ? params.success(result[0].focusArea?.map((val) => val.name))
          : params.success('')
      )
    );
    super.ngOnInit();
  }

  protected defineGetDataRows(...params): Observable<any> {
    return this.svcProfileGrant.find.apply(this.svcProfileGrant, params);
  }

  protected defineDistinct(profileId: string, field: string, filter: any): Observable<string[]> {
    return this.svcProfileGrant.distinct(profileId, field, filter);
  }

  protected defineUpdate(
    profileId: string,
    ids: string[],
    value: { match: ProfileMatchManual; comment?: string; errorType?: string }
  ): Observable<{ id; compiledAt? }[]> {
    return this.svcProfileGrant.update(profileId, {
      paymentIds: ids,
      manual: value.match,
      comment: value.comment,
      errorType: value.errorType,
    });
  }

  protected defineGetFocusArea(profileId: string): Observable<any> {
    return this.svcProfileGrant.distinctFocusArea(profileId);
  }

  protected defineGrid(): (ColDef | ColGroupDef)[] {
    const columnDefs: (ColDef | ColGroupDef)[] = [
      {
        headerName: 'Profile',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'First Name',
            field: this.FIELD_FIRST_NAME,
            filterParams: this.filters.get(this.FIELD_FIRST_NAME),
            width: 100,
          },
          {
            headerName: 'Middle Name',
            field: this.FIELD_MIDDLE_NAME,
            filterParams: this.filters.get(this.FIELD_MIDDLE_NAME),
            width: 90,
          },
          {
            headerName: 'Last Name',
            field: this.FIELD_LAST_NAME,
            filterParams: this.filters.get(this.FIELD_LAST_NAME),
            width: 100,
          },
        ],
      },
      {
        headerName: 'First Grant', // as project can have multiple grants and we are showing only one
        headerClass: 'text-center',
        children: [
          {
            headerName: 'Project Title',
            field: this.FIELD_PROJECT_TITLE,
            filterParams: this.filters.get(this.FIELD_PROJECT_TITLE),
          },
          {
            headerName: 'Project Detail URL',
            cellRenderer: this.rendererLinks,
            field: this.FIELD_PROJECT_URL,
          },
        ],
      },
      {
        headerName: 'Organization',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'Name',
            field: this.FIELD_ORG_NAME,
            filterParams: this.filters.get(this.FIELD_FILTER_ORG_NAME),
          },
          {
            headerName: 'City',
            field: this.FIELD_ORG_CITY,
            filterParams: this.filters.get(this.FIELD_FILTER_ORG_CITY),
          },
          {
            headerName: 'Country',
            field: this.FIELD_ORG_COUNTRY,
          },
          {
            headerName: 'State',
            field: this.FIELD_ORG_STATE,
          },
          ,
          {
            headerName: 'Department',
            field: this.FIELD_ORG_DPT,
          },
        ],
      },
      {
        headerName: 'Focus Area',
        field: this.FIELD_KEY_FA,
        filterParams: this.filters.get(this.FIELD_KEY_FA),
        width: 500,
      },
    ];
    return columnDefs;
  }

  protected defineAutoSizableColumns(): string[] {
    return [];
  }

  protected onDirectLink(): void {
    const options = <ProfileDirectLinkOptions>{
      title: 'Grant',
      getEntity: (id) => this.svcGrant.findById(id),
      getData: (grant: Grant) => ({ name: grant.document.projectTitle }),
      getOnCloseData: (entity: Grant, principalInvestigatorId: string) => ({ entity, principalInvestigatorId }),
    };

    this.svcDirectLink
      .open(options)
      .then((data: { entity: Grant; principalInvestigatorId: string }) =>
        this.svcProfileGrant.link(this.profileId, data.principalInvestigatorId).toPromise()
      )
      .then(() => this.reloadData());
  }

  /**
   * Renderer function for Links columns.
   *
   * @param {ValueFormatterParams} param
   * @return {string}
   */
  rendererLinks(param: ValueFormatterParams): string {
    if (!param.data) {
      return '';
    }
    const link = `<a href='${param.data.grant.projectDetailURL}' rel=noopener target='_blank'>Link</a>`;
    return link;
  }
}
