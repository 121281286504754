import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AssociationAPI } from '../../association/shared/association-api.service';
import { Membership } from '../shared/membership';
import { MembershipAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-membership-create',
  templateUrl: 'create.component.html',
  styleUrls: ['create.component.scss'],
})
export class MembershipCreateComponent implements OnInit, OnDestroy {
  associationId: string;
  membership: Membership = new Membership();
  sub: any;
  initializedAt: Date;
  isSubmitting = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private associationSvc: AssociationAPI,
    private membershipSvc: MembershipAPI
  ) {}

  ngOnInit(): void {
    // record the time, when user starts filling the form
    this.initializedAt = new Date();

    this.sub = this.route.params.subscribe((params) => {
      this.associationId = params['associationId'];
      this.getParent();
    });
  }

  getParent(): void {
    this.associationSvc.findById(this.associationId).subscribe((resp) => (this.membership.association = resp));
  }

  onSubmit(membership: Membership): void {
    membership.association = this.associationId;
    membership['initializedAt'] = this.initializedAt;

    this.isSubmitting = true;
    this.membershipSvc.create(membership).subscribe((response) => {
      this.isSubmitting = false;
      this.router.navigate(['/association/detail', this.associationId]);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
