import { ProfilePublicationAPI } from '../../subsection/publication/shared/api.service';
import { ProfileContributionAPI } from '../../subsection/contribution/shared/api.service';
import { ProfileClinicalTrialAPI } from '../../subsection/clinical-trial/shared/api.service';
import { ProfilePaymentAPI } from '../../subsection/payment/shared/api.service';
import { ProfilePaymentNonusAPI } from '../../subsection/payment-nonus/shared/api.service';
import { ProfileMembershipAPI } from '../../subsection/membership/shared/api.service';
import { ProfileGuidelineAPI } from '../../subsection/guideline/shared/api.service';
import { ProfileVideoAPI } from '../../subsection/video/shared/api.service';
import { Roles } from '../../../shared/acl/roles';
import { ProfileGrantAPI } from '../../subsection/grant/shared/api.service';
import { ProfilePodcastAPI } from '../../subsection/podcast/shared/api.service';

export enum ProfileSection {
  ASSOCIATIONS = 'ASSOCIATIONS',
  EVENTS = 'EVENTS',
  CLINICAL_TRIALS = 'CLINICAL_TRIALS',
  GUIDELINES = 'GUIDELINES',
  PAYMENTS_US = 'PAYMENTS_US',
  PAYMENTS_NONUS = 'PAYMENTS_NONUS',
  PUBLICATIONS = 'PUBLICATIONS',
  GRANTS = 'GRANTS',
  VIDEOS = 'VIDEOS',
  PODCASTS = 'PODCASTS',
}

const allowedRoles = [
  Roles.Admin,
  Roles.Viewer,
  Roles.Trainer,
  Roles.ProfileManager,
  Roles.ProfileModerator,
  Roles.ProfileModifier,
  Roles.ProfileReviewer,
  Roles.ProfileCompiler,
  Roles.ProfileViewer,
  Roles.PersonCreator,
  Roles.PersonCompilerIdent,
  Roles.ProfileSOReviewer,
];
export const ProfileSectionServices = {
  [ProfileSection.PUBLICATIONS]: {
    id: 'publications',
    caption: 'Publications',
    service: ProfilePublicationAPI,
    newCount: true,
    roles: allowedRoles,
  },
  [ProfileSection.EVENTS]: {
    id: 'events',
    caption: 'Events',
    service: ProfileContributionAPI,
    newCount: true,
    roles: allowedRoles,
  },
  [ProfileSection.CLINICAL_TRIALS]: {
    id: 'clinical-trials',
    caption: 'Clinical Trials',
    service: ProfileClinicalTrialAPI,
    newCount: true,
    roles: allowedRoles,
  },
  [ProfileSection.PAYMENTS_US]: {
    id: 'payments',
    caption: 'Payments',
    service: ProfilePaymentAPI,
    roles: allowedRoles,
  },
  [ProfileSection.PAYMENTS_NONUS]: {
    id: 'payments-nonus',
    caption: 'Payments Manual',
    service: ProfilePaymentNonusAPI,
    roles: allowedRoles,
  },
  [ProfileSection.ASSOCIATIONS]: {
    id: 'associations',
    caption: 'Associations',
    service: ProfileMembershipAPI,
    newCount: true,
    roles: allowedRoles,
  },
  [ProfileSection.GUIDELINES]: {
    id: 'guidelines',
    caption: 'Guidelines',
    service: ProfileGuidelineAPI,
    newCount: true,
    roles: allowedRoles,
  },
  [ProfileSection.GRANTS]: {
    id: 'grants',
    caption: 'NIH Grants',
    service: ProfileGrantAPI,
    roles: [
      Roles.Admin,
      Roles.ProfileManager,
      Roles.ProfileModerator,
      Roles.ProfileModifier,
      Roles.ProfileCompilerGrant,
      Roles.ProfileReviewer,
      Roles.PersonReviewer,
    ],
  },
  [ProfileSection.VIDEOS]: {
    id: 'videos',
    caption: 'Videos',
    service: ProfileVideoAPI,
    roles: [
      Roles.VideoCompiler,
      Roles.VideoChannelCompiler,
      Roles.VideoReviewer,
      Roles.ProfileManager,
      Roles.Admin,
      Roles.Viewer,
      Roles.Trainer,
      Roles.ProfileModerator,
      Roles.ProfileModifier,
      Roles.ProfileReviewer,
      Roles.ProfileViewer,
      Roles.VideoManager,
    ],
  },
  [ProfileSection.PODCASTS]: {
    id: 'podcasts',
    caption: 'Podcasts',
    service: ProfilePodcastAPI,
    newCount: true,
    roles: [
      Roles.Admin,
      Roles.ProfileManager,
      Roles.ProfileModerator,
      Roles.ProfileCompilerPodcast,
      Roles.ProfileReviewerPodcast,
    ],
  },
};
