<div #ocrBar class="dirt-ocr-bar">
  <div>
    <button class="btn btn-secondary" [ngbPopover]="ocrPopoverContent" [autoClose]="true" placement="bottom-left">
      <span>OCR</span>
    </button>
  </div>
  <div *ngIf="showPdfInput" class="form-group row">
    <label class="col-form-label col-md-1" for="pdfCustomUrl">URL of PDF</label>
    <div class="col-md-8">
      <input
        id="pdfCustomUrl"
        #customUrl
        type="text"
        class="form-control"
        name="title"
        [(ngModel)]="pdfCustomUrl"
        [required]="true"
        (keyup.enter)="onPdfGo(pdfCustomUrl)"
        placeholder="e.g: https://someconf.com/program/program.pdf"
        autofocus
      />
    </div>
    <div class="col-md-2">
      <button
        class="btn btn-primary"
        [disabled]="!(pdfCustomUrl || '').startsWith('http')"
        (click)="onPdfGo(pdfCustomUrl)"
      >
        GO
      </button>
      <button class="btn btn-secondary" (click)="showPdfInput = false">cancel</button>
    </div>
  </div>
  <div
    *ngIf="showScreenshotCapture"
    #screenshotCapture
    tabindex="0"
    class="dirt-screenshot-capture"
    (blur)="showScreenshotCapture = false"
  >
    Ctrl+V / Meta+V to paste screen
  </div>
  <div [ngStyle]="{ visibility: showPdfWait ? 'visible' : 'hidden' }">Processing - pls. wait...</div>
  <div *ngIf="showPdfWorkbench" class="dirt-capture-workbench">
    <div
      #scrollArea
      [ngClass]="{
        'dirt-capture-workbench-scroll': true,
        collapsed: pdfWorkbenchCollapsedMode && !pdfWorkbenchExpandAnyway
      }"
      (mouseover)="pdfWorkbenchExpandAnyway = true"
      (mouseout)="pdfWorkbenchExpandAnyway = false"
    >
      <div
        [ngClass]="{
          'dirt-capture-workbench-content': true,
          'zoom-025': '0.25' === zoom,
          'zoom-05': '0.5' === zoom,
          'zoom-1': '1' === zoom,
          'zoom-2': '2' === zoom
        }"
      >
        <img [src]="pdfWorkbenchImg" draggable="false" />
        <div
          #captureSelection
          class="dirt-capture-selection"
          [ngStyle]="
            currentSelRect
              ? {
                  left: currentSelRect[0] + 'px',
                  top: currentSelRect[1] + 'px',
                  width: currentSelRect[2] + 'px',
                  height: currentSelRect[3] + 'px'
                }
              : { display: 'none' }
          "
        ></div>
        <div
          class="dirt-capture-overlay"
          (mousedown)="onStartSelect($event)"
          (mouseup)="onEndSelect(true)"
          (mousemove)="onUpdateCoords($event)"
        ></div>
      </div>
    </div>
    <div class="dirt-capture-workbench-tools">
      <span *ngIf="pdfUuidInfo && pdfUuidInfo.pages > 1">
        -
        <button [disabled]="!(pdfWorkbenchPage > 1)" (click)="onPageChange(-1)">prev page</button>
        <select [(ngModel)]="pdfWorkbenchPage" (ngModelChange)="onPageChange()">
          <option *ngFor="let p of arrayRange(pdfUuidInfo.pages)" [value]="p">{{ p }}</option>
        </select>
        <button [disabled]="!(pdfWorkbenchPage < pdfUuidInfo.pages)" (click)="onPageChange(1)">next page</button>
      </span>
      &#160;&#160;
      <label title="Need to make a new selection after choosing here (will remember until sth else is selected)"
        >Language
        <select [(ngModel)]="lang" (ngModelChange)="onLangChange($event)">
          <option value="">(auto-detect)</option>
          <option value="eng">EN (English)</option>
          <option value="spa">ES (Spanish)</option>
          <option value="por">PT (Portugese)</option>
          <option value="fra">FR (French)</option>
          <option value="deu">DE (German)</option>
          <option value="ell">EL (Greek)</option>
          <option value="hun">HU (Hungarian)</option>
          <option value="kor">KR (Korean)</option>
          <option value="jpn">JP (Japanese)</option>
          <option value="chi_sim">ZH (Chinese simpl.)</option>
          <option value="deu+eng+fra+spa+por">Europe (EN,ES,PT,FR,DE)</option>
          <option value="jpn+chi_sim">Asian (JP,ZH)</option>
          <option *ngFor="let s of getSpecialLangs()" [value]="s.key">{{ s.title }}</option>
        </select></label
      >
      &#160;&#160;
      <label
        >Zoom
        <select [(ngModel)]="zoom" (ngModelChange)="onZoomChange()">
          <option value="0.25">25%</option>
          <option value="0.5">50%</option>
          <option value="1">100%</option>
          <option value="2">200%</option>
        </select></label
      >
      &#160;&#160;
      <span class="dirt-last-copied">{{ lastCopied ? 'Copied "' + lastCopied + '"' : '-' }}</span>
      &#160;&#160;
      <button style="float: right" (click)="showPdfWorkbench = false">close</button>
    </div>
  </div>
</div>

<ng-template #ocrPopoverContent>
  <div class="list-group list-group-flush">
    <button class="list-group-item list-group-item-action" [disabled]="!webSource" (click)="this.onPdfGo(webSource)">
      PDF from Web Source
    </button>
    <button
      class="list-group-item list-group-item-action"
      [disabled]="!lastUrl"
      [title]="lastUrl"
      (click)="onPdfGo(lastUrl)"
    >
      re-open last PDF
    </button>
    <button class="list-group-item list-group-item-action" (click)="onPdfCustom()">PDF from other URL</button>
    <button class="list-group-item list-group-item-action" (click)="onScreenshotCapture()">Paste screenshot</button>
  </div>
</ng-template>
