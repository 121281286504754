import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import { MedicalInsightsProfileComponent } from './medical-insights-profile.component';
import { MedicalInsightsProfileDetailComponent } from './detail/detail.component';
import { MedicalInsightsProfileAPI } from './shared/medical-insights-profile-api.service';
import { medicalInsightsProfileRouting } from './medical-insights-profile.routes';
import { SharedModule } from '../shared/shared.module';
import { ValueModule } from './../value/value.module';
import { MedicalInsightsProfileCopyComponent } from './mi-profile-copy/mi-profile-copy.component';
import { MedicalInsightsUATProfileAPI } from './shared/medical-insights-profile-uat-api.service';

@NgModule({
  imports: [SharedModule, medicalInsightsProfileRouting, FormsModule, ValueModule],
  declarations: [
    MedicalInsightsProfileComponent,
    MedicalInsightsProfileDetailComponent,
    MedicalInsightsProfileCopyComponent,
  ],
  providers: [MedicalInsightsProfileAPI, MedicalInsightsUATProfileAPI],
  exports: [],
})
export class MedicalInsightsProfileModule {}
