import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

import { Affiliation } from '../shared/affiliation';
import { AffiliationAPI } from '../shared/api.service';
import { ACL } from '../../shared/acl/acl.service';
import { AffiliationMergeService } from '../shared/modal/merge/merge.service';
import { forkJoin } from 'rxjs';
import { Auth } from '../../shared/services/auth/auth.service';
import { AffiliationDetailComponent } from '../detail/detail.component';

@Component({
  selector: 'dirt-affiliation-detail-merge',
  templateUrl: './detail-merge.component.html',
  styleUrls: ['./detail-merge.component.scss'],
})
export class AffiliationDetailMergeComponent implements OnInit {
  id: string; // finding ID
  finding: { _id; type; manualDecision; manualDecisionDetail; minCreatedAtA; minCreatedAtB; idA; idB };
  newerIsB: boolean | null = null; // know when making a decision
  duplicateSuspect: Affiliation; // filled from idA/idB, dep. on which one is newer
  duplicateMatch: Affiliation; // filled from idA/idB, dep. on which one is newer

  isSubmitting: boolean;

  isSuspectClaimedByOtherUser: boolean = false;
  isMatchClaimedByOtherUser: boolean = false;

  duplicateId: { [key: string]: string } = {};

  private myUserId: string;

  mergeDecision = null; // (one of the following)
  readonly LEFT_WINS = 'LEFT_WINS';
  readonly WHITELIST = 'WHITELIST';
  readonly RIGHT_WINS = 'RIGHT_WINS';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly svcAffiliation: AffiliationAPI,
    private readonly svcAcl: ACL,
    private svcAuth: Auth,
    private svcMergeModal: AffiliationMergeService
  ) {}

  ngOnInit(): void {
    // get by ID, do prep steps for both sides similar to what "normal" details do
    this.route.params
      .pipe(
        map((params) => (this.id = params['id'])),
        switchMap((id) => this.svcAffiliation.findMergeById(id))
      )
      .pipe(
        switchMap((finding) =>
          forkJoin([of(finding), this.svcAffiliation.findById(finding.idA), this.svcAffiliation.findById(finding.idB)])
        )
      )
      .subscribe(([finding, affiliationA, affiliationB]) => {
        this.finding = finding;
        if (finding.minCreatedAtA >= finding.minCreatedAtB) {
          this.newerIsB = false;
          this.duplicateSuspect = affiliationA;
          this.duplicateMatch = affiliationB;
        } else {
          this.newerIsB = true;
          this.duplicateSuspect = affiliationB;
          this.duplicateMatch = affiliationA;
        }
        this.getOriginalAddress(this.duplicateSuspect);
        this.getOriginalAddress(this.duplicateMatch);
        // finally check other claims (should be very rare)
        this.svcAuth.getProfile().subscribe((p) => {
          this.myUserId = p.user_id;

          this.isSuspectClaimedByOtherUser =
            this.duplicateSuspect._meta &&
            this.duplicateSuspect._meta.claimedUntil &&
            new Date(this.duplicateSuspect._meta.claimedUntil) > new Date() &&
            this.duplicateSuspect._meta.assignee &&
            this.duplicateSuspect._meta.assignee !== this.myUserId;
          this.isMatchClaimedByOtherUser =
            this.duplicateMatch._meta &&
            this.duplicateMatch._meta.claimedUntil &&
            new Date(this.duplicateMatch._meta.claimedUntil) > new Date() &&
            this.duplicateMatch._meta.assignee &&
            this.duplicateMatch._meta.assignee !== this.myUserId;
        });
      });
  }

  onSave(affiliation: Affiliation, inputEntity: Affiliation): void {
    if (this.isSubmitting) {
      return;
    }

    this.isSubmitting = true;

    this.svcAffiliation.checkExactDuplicate(affiliation).subscribe(
      (res) => {
        if (res.duplicateId) {
          this.isSubmitting = false;
          this.duplicateId[affiliation.id] = res.duplicateId;
          alert('We got an exact duplicate for ' + affiliation.name + ': ' + res.duplicateId);
        } else {
          this.duplicateId[affiliation.id] = null;
          this.svcAffiliation.update(affiliation.id, affiliation).subscribe(
            (aff) => {
              this.getMergedResponse(inputEntity, aff); // this one stays, the affiliation param is generated one-time
              this.isSubmitting = false;
            },
            () => (this.isSubmitting = false)
          );
        }
      },
      () => (this.isSubmitting = false)
    );
  }

  onSubmit(suspectAffiliation: Affiliation, matchAffiliation: Affiliation): void {
    // TODO: move into decision & trigger from there - along w/ sending decision & decision details
    if (this.isSubmitting || !this.mergeDecision) {
      return;
    }

    let message = 'Do you really want to submit this entry?';
    message += '\nNote: By submitting, you will not be having any access to it anymore!';

    // confirm the submit action
    if (!window.confirm(message)) {
      return;
    }

    this.isSubmitting = true;

    // save both first - check we do not conflict with either
    forkJoin([
      this.svcAffiliation.checkExactDuplicate(suspectAffiliation),
      this.svcAffiliation.checkExactDuplicate(matchAffiliation),
    ]).subscribe(
      ([resSuspect, resMatch]) => {
        let hasDup = false;
        if (resSuspect.duplicateId) {
          this.isSubmitting = false;
          hasDup = true;
          this.duplicateId[this.duplicateSuspect.id] = resSuspect.duplicateId;
          alert('We got an exact duplicate for ' + suspectAffiliation.name + ': ' + resSuspect.duplicateId);
        } else {
          this.duplicateId[this.duplicateSuspect.id] = null;
        }
        if (resMatch.duplicateId) {
          this.isSubmitting = false;
          hasDup = true;
          this.duplicateId[this.duplicateMatch.id] = resMatch.duplicateId;
          alert('We got an exact duplicate for ' + matchAffiliation.name + ': ' + resMatch.duplicateId);
        } else {
          this.duplicateId[this.duplicateMatch.id] = null;
        }
        if (hasDup) {
          return;
        } // (else we can save both as such)
        let updateErr = null;
        forkJoin([
          this.svcAffiliation.update(suspectAffiliation.id, suspectAffiliation).pipe(
            tap((res: Affiliation) => (this.duplicateSuspect._version = res._version)),
            catchError((err) => {
              updateErr = err;
              return of(null);
            })
          ), // (ensure _version of successful)
          this.svcAffiliation.update(matchAffiliation.id, matchAffiliation).pipe(
            tap((res: Affiliation) => (this.duplicateMatch._version = res._version)),
            catchError((err) => {
              updateErr = err;
              return of(null);
            })
          ), // (ensure _version of successful)
        ]).subscribe(
          () => {
            // both are saved as-is - document decision and pot. carry out merge (all on server)
            if (updateErr) {
              this.isSubmitting = false;
              return;
            }
            const decision =
              this.WHITELIST === this.mergeDecision
                ? 'WHITELIST'
                : this.LEFT_WINS === this.mergeDecision
                ? this.newerIsB
                  ? 'WINNER_B'
                  : 'WINNER_A'
                : this.newerIsB
                ? 'WINNER_A'
                : 'WINNER_B'; // left is the newer - so transform
            const loserId = this.LEFT_WINS === this.mergeDecision ? this.duplicateMatch.id : this.duplicateSuspect.id;
            const winnerId = this.LEFT_WINS === this.mergeDecision ? this.duplicateSuspect.id : this.duplicateMatch.id;
            this.svcAffiliation.submitMerge(this.id /*finding-ID*/, decision, loserId, winnerId).subscribe(
              async (res) => {
                // we might have departments to take care of
                const resPairs = (res.pairs || []).map((item) => ({
                  existId: item.exist.id,
                  existCaption: [item.exist.name, item.exist.department].filter((s) => !!s).join(', '),
                  mergeId: item.merge.id,
                  mergeCaption: [item.merge.name, item.merge.department].filter((s) => !!s).join(', '),
                  merged: item.merged,
                }));
                if (!res.ok || res.msg || (res.pairs && res.pairs.length > 0)) {
                  try {
                    await this.svcMergeModal.openInfo({ success: res.ok, msg: res.msg, pairs: resPairs });
                  } catch (_e) {} // cancel (we don't care which one it is)
                }
                this.isSubmitting = false;
                if (res.ok) {
                  if (this.WHITELIST !== this.mergeDecision) {
                    // remember the ID of the winner (when actually merging)
                    AffiliationDetailComponent.rememberWinnerForOtherTabs(winnerId);
                  }
                  // (now we're good)
                  this.goBack();
                } // (& stay here)
              },
              () => (this.isSubmitting = false)
            );
          },
          () => (this.isSubmitting = false)
        );
      },
      () => (this.isSubmitting = false)
    );
  }

  onCopyID(id: string): void {
    navigator.clipboard.writeText(id || '');
  }

  goBack(): void {
    this.router.navigate(['/next']);
  }

  hasPermissionInclLfka(perm: string): boolean {
    // will be ditched soon b/c LFKA affiliation will be merged with LFTA
    if (
      (this.duplicateSuspect.sourceLfka || this.duplicateMatch?.sourceLfka) &&
      !this.svcAcl.hasCredential('affiliation.lfka')
    ) {
      return false;
    }

    return this.svcAcl.hasCredential(perm);
  }

  private getMergedResponse(localData: Affiliation, remoteData: Affiliation): Affiliation {
    localData.updatedAt = remoteData.updatedAt;
    localData._version = remoteData._version;
    return localData;
  }

  private getOriginalAddress(affiliation: Affiliation): Affiliation {
    affiliation.originalAddr = {};
    affiliation.originalAddr.name = affiliation.address.originalStreet;
    affiliation.originalAddr.additionalInfo = affiliation.address.originalAdditionalInfo;
    affiliation.originalAddr.city = affiliation.address.originalCity;

    return affiliation;
  }
}
