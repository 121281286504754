import { ChangeDetectionStrategy, Component, NgZone } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'dirt-feedback-report-link',
  templateUrl: './feedback-report-link.component.html',
  styleUrls: ['./feedback-report-link.component.scss'],
})
export class FeedbackReportLinkComponent implements ICellRendererAngularComp {
  public params: any;

  isVisited = false;

  constructor() {}

  agInit(params: any): void {
    this.params = params;
  }

  refresh(): boolean {
    return false;
  }

  onClick() {
    this.isVisited = true;
    this.params.onOpenLink(this.params.data.USER_ID);
  }
}
