import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { DirtComponent } from './dirt.component';
import { authProviders } from './user/user.routes';
import { AdminModule } from './admin/admin.module';
import { AssociationModule } from './association/association.module';
import { UserModule } from './user/user.module';
import { SharedModule } from './shared/shared.module';
import { Auth } from './shared/services/auth/auth.service';
import { AclModule } from './shared/acl/acl.module';
import { environment } from '../environments/environment';
import { CleanErrorHandler } from './shared/services/observability/error-handler';

const jwtOptions: JwtModuleOptions = {
  config: {
    tokenGetter: Auth.getToken,
    allowedDomains: [
      /*
       * TODO: Enable the following line when Angular bug is fixed:
       * https://github.com/angular/angular/issues/22829
       */
      // new URL(environment.apiEndpoint).host
      'localhost:3000',
      'api.nightly.veeva.io',
      'api.stage.veeva.io',
      'rest.veeva.io',
    ],
  },
};

@NgModule({
  declarations: [DirtComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    NoopAnimationsModule,
    JwtModule.forRoot(jwtOptions),
    NgxsModule.forRoot([], { developmentMode: !environment.production }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
    AdminModule,
    AssociationModule,
    UserModule,
    AclModule.forRoot(),
    SharedModule,
  ],
  providers: [...authProviders, { provide: ErrorHandler, useClass: CleanErrorHandler }],
  bootstrap: [DirtComponent],
})
export class DirtModule {}
