import { Component } from '@angular/core';

@Component({
  selector: 'dirt-document',
  template: `
    <dirt-admin-header></dirt-admin-header>
    <div class="container-fluid">
      <section class="dirt-navbar-sub">
        <nav class="navbar navbar-expand-lg navbar-light">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item" *diAcl="'account.list'">
              <a class="nav-link" routerLink="/account" routerLinkActive="is-active">Accounts</a>
            </li>
            <li class="nav-item" *diAcl="'committee.list'">
              <a class="nav-link" routerLink="/committee" routerLinkActive="is-active">Committees</a>
            </li>
            <li class="nav-item" *diAcl="'document.list'">
              <a class="nav-link" routerLink="/document" routerLinkActive="is-active">Documents</a>
            </li>
          </ul>
        </nav>
        &nbsp;

        <router-outlet></router-outlet>
      </section>
    </div>
  `,
})
export class DocumentComponent {}
