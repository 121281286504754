import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';

import { AccountMedicalInformation } from '../shared/account-medical-information';
import { AccountMedicalInformationAPI } from '../shared/account-medical-information-api.service';
import { ACL } from '../../shared/acl/acl.service';

@Component({
  selector: 'dirt-account-medical-information-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class AccountMedicalInformationListComponent implements OnInit {
  @Input()
  accountId: string;

  accountMedicalInformation: AccountMedicalInformation[];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 30;
  pagingSkip = 0;

  private sort = '-year';

  constructor(
    private readonly router: Router,
    private readonly svcAccountMedicalInformation: AccountMedicalInformationAPI,
    private readonly svcACL: ACL
  ) {}

  ngOnInit(): void {
    this.doLoad();
  }

  /** just avoid useless rendering if we can */
  trackById(index: number, account: AccountMedicalInformation): string {
    return account.id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getAccountsMedicalInformation();
  }

  onSort(field: string): void {
    this.sort = field;

    this.getAccountsMedicalInformation();
  }

  onDelete(id: string): void {
    if (!this.svcACL.hasCredential('account-medical-information.delete')) {
      return;
    }

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.svcAccountMedicalInformation.deleteById(id).subscribe(() => {
      this.getAccountsMedicalInformation();
      this.getAccountsMedicalInformationCount();
    });
  }

  navigateTo(id: string): void {
    if (!id) {
      return;
    }

    this.router.navigate(['/account-medical-information/detail', id]);
  }

  private doLoad(): void {
    this.resetPagination();
    this.getAccountsMedicalInformation();
    this.getAccountsMedicalInformationCount();
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getAccountsMedicalInformation(): void {
    this.isLoading = true;

    this.svcAccountMedicalInformation
      .find(this.pagingLimit, this.pagingSkip, this.sort, this.accountId)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((accountMedicalInformation) => {
        this.accountMedicalInformation = accountMedicalInformation;
      });
  }

  private getAccountsMedicalInformationCount(): void {
    this.svcAccountMedicalInformation.count(this.accountId).subscribe((count) => {
      this.total = count;
    });
  }
}
