import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AccountAffiliation, AccountAffiliationStatus } from './account-affiliation';
import { APIService } from '../../shared/services/api/api.service';
import {
  GET,
  MediaType,
  Produces,
  Query,
  POST,
  Body,
  Path,
  PATCH,
  DELETE,
} from '../../shared/services/rest/rest.service';

@Injectable()
export class AccountAffiliationAPI extends APIService {
  @GET('account-affiliation/')
  @Produces(MediaType.JSON)
  public find(
    @Query('term') term?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('accountId') accountId?: string,
    @Query('affiliationId') affiliationId?: string,
    @Query('filter') filter?: any,
    @Query('grouped') grouped?: boolean
  ): Observable<AccountAffiliation[]> {
    return;
  }

  @GET('account-affiliation/count')
  @Produces(MediaType.JSON)
  public count(
    @Query('accountId') accountId?: string,
    @Query('term') term?: string,
    @Query('grouped') grouped?: boolean
  ): Observable<any> {
    return;
  }

  @POST('account-affiliation')
  @Produces(MediaType.JSON)
  public create(
    @Body accountAffiliation: { accountId: string; affiliationId: string; status: AccountAffiliationStatus }
  ): Observable<any> {
    return;
  }

  @PATCH('account-affiliation/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body AccountAffiliationList: Partial<AccountAffiliation>): Observable<any> {
    return;
  }

  @DELETE('account-affiliation/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }

  @GET('account-affiliation/ids')
  @Produces(MediaType.JSON)
  public findIds(@Query('accountId') accountId?: string): Observable<string[]> {
    return;
  }

  @GET('account-affiliation/affiliation-ids')
  @Produces(MediaType.JSON)
  public findAffiliationIds(@Query('accountId') accountId?: string): Observable<string[]> {
    return;
  }

  @POST('account-affiliation/is-hq')
  @Produces(MediaType.JSON)
  public isHQ(@Body affiliationIds?: string[]): Observable<{ affiliation: string; isHQ: boolean }[]> {
    return;
  }

  @GET('account-affiliation/can-be-hq')
  @Produces(MediaType.JSON)
  public canBeHQ(@Query('affiliationId') affiliationId?: string): Observable<boolean> {
    return;
  }

  @POST('account-affiliation/audit')
  @Produces(MediaType.JSON)
  public audit(@Body ids: string[], @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  @POST('account-affiliation/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Body ids: string[]): Observable<number> {
    return;
  }
}
