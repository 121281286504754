<form #frmPerson="ngForm" novalidate>
  <div
    class="clearfix"
    [diMarkerWrapper]="model"
    [suppressMarker]="suppressMarker"
    [dirtTrainingEvaluationMarkerWrapper]="basePerson"
  >
    <!-- Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Name</label>
      <div class="col-sm-8 col-md-10">
        <div class="row">
          <!-- First Name -->
          <div class="col-md-3">
            <label class="sr-only" for="firstName">First Name</label>
            <span *dirtTrainingEvaluationMarker="'firstName'"></span>
            <input
              type="text"
              id="firstName"
              name="firstName"
              [(ngModel)]="model.firstName"
              placeholder="First Name"
              [disabled]="!isFieldEditable('firstName')"
              [class]="highlightFields()"
              autofocus
              required
              *diMarker="{ name: 'firstName', lfkaOnlyProject: isOnlyKAMProject() }"
              validateNameWithoutDot
              dirtPasteNormalizer
            />
          </div>
          <!-- Middle Name -->
          <div class="col-md-3">
            <label class="sr-only" for="middleName">Middle Name</label>
            <span *dirtTrainingEvaluationMarker="'middleName'"></span>
            <input
              type="text"
              id="middleName"
              [class]="highlightFields()"
              name="middleName"
              [(ngModel)]="model.middleName"
              placeholder="Middle Name"
              [disabled]="!isFieldEditable('middleName')"
              *diMarker="{ name: 'middleName', lfkaOnlyProject: isOnlyKAMProject() }"
              validateNameWithoutDot
              dirtPasteNormalizer
            />
          </div>
          <!-- Last Name -->
          <div class="col-md-3">
            <label class="sr-only" for="lastName">Last Name</label>
            <span *dirtTrainingEvaluationMarker="'lastName'"></span>
            <input
              type="text"
              id="lastName"
              [class]="highlightFields()"
              name="lastName"
              [(ngModel)]="model.lastName"
              placeholder="Last Name"
              [disabled]="!isFieldEditable('lastName')"
              required
              *diMarker="{ name: 'lastName', lfkaOnlyProject: isOnlyKAMProject() }"
              validateName
              dirtPasteNormalizer
            />
          </div>

          <!-- Copy Name Parts -->
          <div class="col-md-1">
            <i class="fa fa-copy" style="cursor: pointer" (click)="onCopyName()" ngbTooltip="Copy Name"></i>
          </div>

          <!-- Suffix -->
          <div class="col-md-2">
            <label class="sr-only" for="suffix">Suffix</label>
            <span *dirtTrainingEvaluationMarker="'suffix'"></span>
            <select
              id="suffix"
              name="suffix"
              [class]="highlightFields('custom-select')"
              [(ngModel)]="model.suffix"
              [disabled]="!isFieldEditable('suffix')"
              *diMarker="{ name: 'suffix', lfkaOnlyProject: isOnlyKAMProject() }"
            >
              <option [ngValue]="undefined">&mdash; Suffix &mdash;</option>
              <option *ngFor="let suffix of suffixes" [value]="suffix.code">
                {{ suffix.title }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!-- Nicknames -->
    <div *diMarker="{ name: 'nicknames', lfkaOnlyProject: isOnlyKAMProject() }">
      <div class="form-group row" *ngFor="let nickname of model.nicknames; let idx = index; trackBy: trackByIndex">
        <label class="col-form-label col-sm-4 col-md-2" for="nickname-{{ idx }}">Nickname</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <input
              type="text"
              id="nickname-{{ idx }}"
              [class]="highlightFields()"
              name="nickname-{{ idx }}"
              [(ngModel)]="model.nicknames[idx]"
              [disabled]="!isFieldEditable('nicknames')"
              dirtPasteNormalizer
            />
            <div *ngIf="idx == model.nicknames.length - 1">
              <span *dirtTrainingEvaluationMarker="'nicknames'"></span>
            </div>
            <div class="input-group-append">
              <!-- Button: Add -->
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.nicknames)"
                *ngIf="idx == model.nicknames.length - 1 && isFieldEditable('nicknames')"
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <!-- Button: Remove -->
              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.nicknames, idx) && isFieldEditable('nicknames')"
                *ngIf="idx > 0 && isFieldEditable('nicknames')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <!-- Original Names -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Original Name</label>
      <div class="col-sm-8 col-md-10">
        <div class="row">
          <!-- First Name -->
          <div class="col-sm-4">
            <label class="sr-only" for="orgFirstName">Original First Name</label>
            <span *dirtTrainingEvaluationMarker="'originalFirstName'"></span>
            <input
              type="text"
              id="orgFirstName"
              [class]="highlightFields()"
              name="orgFirstName"
              [(ngModel)]="model.originalFirstName"
              placeholder="Original First Name"
              [disabled]="!isFieldEditable('originalFirstName')"
              *diMarker="{ name: 'originalFirstName', lfkaOnlyProject: isOnlyKAMProject() }"
              validateName
              dirtPasteNormalizer
            />
          </div>
          <!-- Middle Name -->
          <div class="col-sm-4">
            <label class="sr-only" for="orgMiddleName">Original Middle Name</label>
            <span *dirtTrainingEvaluationMarker="'originalMiddleName'"></span>
            <input
              type="text"
              id="orgMiddleName"
              [class]="highlightFields()"
              name="orgMiddleName"
              [(ngModel)]="model.originalMiddleName"
              placeholder="Original Middle Name"
              [disabled]="!isFieldEditable('originalMiddleName')"
              *diMarker="{ name: 'originalMiddleName', lfkaOnlyProject: isOnlyKAMProject() }"
              validateName
              dirtPasteNormalizer
            />
          </div>
          <!-- Last Name -->
          <div class="col-sm-4">
            <label class="sr-only" for="orgLastName">Original Last Name</label>
            <span *dirtTrainingEvaluationMarker="'originalLastName'"></span>
            <input
              type="text"
              id="orgLastName"
              [class]="highlightFields()"
              name="orgLastName"
              [(ngModel)]="model.originalLastName"
              placeholder="Original Last Name"
              [disabled]="!isFieldEditable('originalLastName')"
              *diMarker="{ name: 'originalLastName', lfkaOnlyProject: isOnlyKAMProject() }"
              validateName
              dirtPasteNormalizer
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Full Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="orgFullName">Original Full Name</label>
      <div class="col-sm-8 col-md-10">
        <span *dirtTrainingEvaluationMarker="'originalFullName'"></span>
        <input
          type="text"
          id="orgFullName"
          [class]="highlightFields()"
          name="originalFullName"
          [(ngModel)]="model.originalFullName"
          [disabled]="!isFieldEditable('originalFullName')"
          *diMarker="{ name: 'originalFullName', lfkaOnlyProject: isOnlyKAMProject() }"
          validateName
          dirtPasteNormalizer
        />
      </div>
    </div>

    <!-- Alternative Names -->
    <div *diMarker="{ name: 'alternativeNames', lfkaOnlyProject: isOnlyKAMProject() }">
      <div
        class="form-group row"
        *ngFor="let altName of model.alternativeNames; let idx = index; trackBy: trackByIndex"
      >
        <label class="col-form-label col-sm-4 col-md-2" for="altName-{{ idx }}">Alt. Name</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <input
              type="text"
              id="altName-{{ idx }}"
              [class]="highlightFields()"
              name="altName-{{ idx }}"
              [(ngModel)]="model.alternativeNames[idx]"
              [disabled]="!isFieldEditable('alternativeNames')"
              validateName
              dirtPasteNormalizer
            />
            <div *ngIf="idx == model.alternativeNames.length - 1">
              <span *dirtTrainingEvaluationMarker="'alternativeNames'"></span>
            </div>
            <div class="input-group-append">
              <!-- Button: Add -->
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.alternativeNames)"
                *ngIf="idx == model.alternativeNames.length - 1 && isFieldEditable('alternativeNames')"
              >
                <i class="fa fa-plus-circle"></i>
              </button>
              <!-- Button: Remove -->
              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.alternativeNames, idx) && isFieldEditable('alternativeNames')"
                *ngIf="idx > 0"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr />

    <!-- Name Link -->
    <div *diMarker="{ name: 'nameLinks', lfkaOnlyProject: isOnlyKAMProject() }">
      <div class="form-group row" *ngFor="let nameLink of model.nameLinks; let idx = index; trackBy: trackByIndex">
        <label class="col-form-label col-sm-4 col-md-2 text-primary">Name Link</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <!-- FIXME: Highlight fields: [class]="highlightFields('dirt-name-links')" -->
            <dirt-link
              [(url)]="model.nameLinks[idx]"
              name="nameLinks-{{ idx }}"
              [form]="ngForm"
              [disabled]="!isFieldEditable('nameLinks')"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.nameLinks)"
                *ngIf="idx == model.nameLinks.length - 1 && isFieldEditable('nameLinks') && model.nameLinks.length < 3"
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.nameLinks, idx)"
                *ngIf="idx > 0 && isFieldEditable('nameLinks')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>

            <div *ngIf="idx == model.nameLinks.length - 1">
              <span *dirtTrainingEvaluationMarker="'nameLinks'"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="checkFindings['nameLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['nameLinks'].join(', ') }}
    </div>

    <hr />

    <!-- CV Link -->
    <div *diMarker="{ name: 'cvLinks', lfkaOnlyProject: isOnlyKAMProject() }">
      <div class="form-group row" *ngFor="let cvLink of model.cvLinks; let idx = index; trackBy: trackByIndex">
        <label class="col-form-label col-sm-4 col-md-2 text-primary"
          ><span *ngIf="idx === 0">Master </span>CV Link</label
        >
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <!-- FIXME: Highlight fields: [class]="highlightFields('dirt-cv-links')" -->
            <dirt-link
              [(url)]="model.cvLinks[idx]"
              name="cvLinks-{{ idx }}"
              [form]="ngForm"
              [disabled]="!isCVLinkEditable(idx)"
              [required]="!initialOpenDataId && isFieldMandatory('cvLinks')"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.cvLinks)"
                *ngIf="idx == model.cvLinks.length - 1 && isFieldEditable('cvLinks') && model.cvLinks.length < 3"
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.cvLinks, idx)"
                *ngIf="idx > 0 && isFieldEditable('cvLinks')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>

            <div *ngIf="idx == model.cvLinks.length - 1"><span *dirtTrainingEvaluationMarker="'cvLinks'"></span></div>
          </div>

          <div
            class="info"
            *ngIf="idx === 0 && model.originalMasterCVLink && model.cvLinks[idx] !== model.originalMasterCVLink"
          >
            Current master CV link different from original master CV link: {{ model.originalMasterCVLink }}
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="checkFindings['cvLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['cvLinks'].join(', ') }}
    </div>

    <hr />

    <!-- Affiliations List -->
    <div class="form-group">
      <span *dirtTrainingEvaluationMarker="'affiliations'"></span>

      <dirt-affiliations
        [affiliations]="model.affiliations"
        [disabled]="!isFieldEditable('affiliations')"
        [lfkaOnlyProject]="isOnlyKAMProject()"
        *ngIf="isMoreThanKAMProject() || model.affiliations?.length > 0"
        [createRequestHandler]="onRequestAffiliationCreation"
        [maintenanceRequestHandler]="onRequestAffiliationMaintenance"
      >
      </dirt-affiliations>

      <br />

      <dirt-affiliations-lfka
        [affiliations]="model.affiliationsLfka"
        [disabled]="!isFieldEditable('affiliationsLfka')"
        [reviewDisabled]="!isFieldEditable('affiliationsLfka')"
        [lfkaOnlyProject]="isOnlyKAMProject()"
        *ngIf="isKAMProject() || model.affiliationsLfka?.length > 0"
        [createRequestHandler]="onRequestAffiliationCreation"
        [maintenanceRequestHandler]="onRequestAffiliationMaintenance"
      >
      </dirt-affiliations-lfka>
    </div>

    <!-- Affiliations Link -->
    <div *diMarker="{ name: 'affiliationsLinks', lfkaOnlyProject: isOnlyKAMProject() }">
      <div
        class="form-group row"
        *ngFor="let affLink of model.affiliationsLinks; let idx = index; trackBy: trackByIndex"
      >
        <label class="col-form-label col-sm-4 col-md-2 text-primary">Affiliation Link</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <!-- FIXME: Highlight fields: [class]="highlightFields('dirt-cv-links')" -->
            <dirt-link
              [(url)]="model.affiliationsLinks[idx]"
              name="affiliationsLinks-{{ idx }}"
              [form]="ngForm"
              [disabled]="!isFieldEditable('affiliationsLinks')"
              [required]="!initialOpenDataId && isFieldMandatory('affiliationsLinks')"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.affiliationsLinks)"
                *ngIf="
                  idx == model.affiliationsLinks.length - 1 &&
                  isFieldEditable('affiliationsLinks') &&
                  shouldAddMoreLink(model.affiliationsLinks)
                "
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.affiliationsLinks, idx)"
                *ngIf="idx > 0 && isFieldEditable('affiliationsLinks')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>

            <div *ngIf="idx == model.affiliationsLinks.length - 1">
              <span *dirtTrainingEvaluationMarker="'affiliationsLinks'"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="checkFindings['affiliationsLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['affiliationsLinks'].join(', ') }}
    </div>

    <!-- Position Link -->

    <hr />

    <div>
      <div
        class="form-group row"
        *ngFor="let positionLink of model.positionLinks; let idx = index; trackBy: trackByIndex"
      >
        <label class="col-form-label col-sm-4 col-md-2 text-primary">Position Link</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <!-- FIXME: Highlight fields: [class]="highlightFields('dirt-position-links')" -->
            <dirt-link
              [(url)]="model.positionLinks[idx]"
              name="positionLinks-{{ idx }}"
              [form]="ngForm"
              [disabled]="!isFieldEditable('positionLinks')"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.positionLinks)"
                *ngIf="
                  idx == model.positionLinks.length - 1 &&
                  isFieldEditable('positionLinks') &&
                  shouldAddMoreLink(model.positionLinks)
                "
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.positionLinks, idx)"
                *ngIf="idx > 0 && isFieldEditable('positionLinks')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>

            <div *ngIf="idx == model.positionLinks.length - 1">
              <span *dirtTrainingEvaluationMarker="'positionLinks'"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="checkFindings['positionLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['positionLinks'].join(', ') }}
    </div>

    <hr />

    <!-- States -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="state">Status</label>
      <div class="col-sm-8 col-md-10">
        <select
          class="form-control custom-select"
          [class]="highlightFields()"
          id="state"
          name="state"
          [(ngModel)]="model.status"
          [disabled]="!isFieldEditable('status') || isStateDisabled(model.status)"
          *diMarker="{ name: 'status', lfkaOnlyProject: isOnlyKAMProject() }"
        >
          <option [ngValue]="undefined">&mdash; Status &mdash;</option>
          <option *ngFor="let state of states" [value]="state.value" [disabled]="isStateDisabled(state.value)">
            {{ state.value | capitalize }}
          </option>
        </select>
      </div>
    </div>

    <hr />
    <!-- Status Link -->
    <div>
      <div class="form-group row" *ngFor="let statusLink of model.statusLinks; let idx = index; trackBy: trackByIndex">
        <label class="col-form-label col-sm-4 col-md-2 text-primary">Status Link</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <!-- FIXME: Highlight fields: [class]="highlightFields('dirt-status-links')" -->
            <dirt-link
              [(url)]="model.statusLinks[idx]"
              name="statusLinks-{{ idx }}"
              [form]="ngForm"
              [disabled]="!isFieldEditable('statusLinks')"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.statusLinks)"
                *ngIf="
                  idx == model.statusLinks.length - 1 && isFieldEditable('statusLinks') && model.statusLinks.length < 3
                "
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.statusLinks, idx)"
                *ngIf="idx > 0 && isFieldEditable('statusLinks')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-link>

            <div *ngIf="idx == model.statusLinks.length - 1">
              <span *dirtTrainingEvaluationMarker="'statusLinks'"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="checkFindings['statusLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['statusLinks'].join(', ') }}
    </div>

    <hr />

    <!-- Emails -->
    <div *diMarker="{ name: 'emails', lfkaOnlyProject: isOnlyKAMProject() }">
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="email">Email</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <input
              type="email"
              id="email"
              [class]="highlightFields()"
              name="email"
              [ngModel]="model.emails[0] | decapitalize"
              (ngModelChange)="model.emails[0] = $event"
              [disabled]="!isFieldEditable('emails')"
              validateEmail
            />
            <span *dirtTrainingEvaluationMarker="'emails'"></span>
          </div>
        </div>
      </div>
    </div>

    <!-- Email Link -->
    <div *diMarker="{ name: 'emailLinks', lfkaOnlyProject: isOnlyKAMProject() }">
      <div class="form-group row" *ngFor="let emailLink of model.emailLinks; let idx = index; trackBy: trackByIndex">
        <label class="col-form-label col-sm-4 col-md-2 text-primary">Email Link</label>
        <div class="col-sm-8 col-md-10">
          <!-- FIXME: Highlight fields: [class]="highlightFields('dirt-cv-links')" -->
          <dirt-link
            [(url)]="model.emailLinks[idx]"
            name="emailLinks-{{ idx }}"
            [form]="ngForm"
            [disabled]="!isFieldEditable('emailLinks')"
          >
            <button
              type="button"
              class="btn btn-secondary"
              (click)="pushItemToList(model.emailLinks)"
              *ngIf="idx == model.emailLinks.length - 1 && isFieldEditable('emailLinks') && model.emailLinks.length < 3"
            >
              <i class="fa fa-plus-circle"></i>
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              (click)="removeFromByIndex(model.emailLinks, idx)"
              *ngIf="idx > 0 && isFieldEditable('emailLinks')"
            >
              <i class="fa fa-minus-circle"></i>
            </button>
          </dirt-link>
        </div>
      </div>
    </div>
    <div *ngIf="checkFindings['emailLinks']?.length > 0" class="info">
      Non-compliant links were removed automatically: {{ checkFindings['emailLinks'].join(', ') }}
    </div>
  </div>
</form>
