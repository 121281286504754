import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SentimentAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-sentiment-next',
  templateUrl: 'next.component.html',
  styleUrls: ['next.component.scss'],
})
export class SentimentNextComponent implements OnInit {
  isLoadingNext: boolean = false;

  constructor(private router: Router, private svcSentiment: SentimentAPI) {}

  ngOnInit() {}

  getNext(evnt?): void {
    if (evnt) {
      evnt.preventDefault();
    }

    this.isLoadingNext = true;

    this.svcSentiment.findNext().subscribe(
      (sentiment) => {
        if (!sentiment || !sentiment.id) {
          return alert('No Sentiments to be processed at the moment! Go and get some rest ;)');
        }

        this.router.navigate(['/sentiment/detail', sentiment.id]);
      },
      (err) => alert('Error!'),
      () => (this.isLoadingNext = false)
    );
  }
}
