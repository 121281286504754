<div
  *ngIf="options"
  class="dropdown"
  [ngClass]="settings.containerClasses"
  [class.open]="isVisible"
  (offClick)="clickedOutside()"
>
  <button
    type="button"
    class="dropdown-toggle"
    [ngClass]="settings.buttonClasses"
    (click)="toggleDropdown($event)"
    [disabled]="disabled"
    [ssAutofocus]="!focusBack"
    [innerHTML]="title"
  >
    <span class="caret"></span>
  </button>

  <div
    #scroller
    *ngIf="isVisible"
    class="dropdown-menu"
    [ngClass]="{
      'chunkydropdown-menu': settings.checkedStyle === 'visual',
      'dropdown-menu-top': settings.position === 'top'
    }"
    (scroll)="settings.isLazyLoad ? checkScrollPosition($event) : null"
    (wheel)="settings.stopScrollPropagation ? checkScrollPropagation($event, scroller) : null"
    [class.pull-right]="settings.pullRight"
    [class.dropdown-menu-right]="settings.pullRight"
    [style.max-height]="settings.maxHeight"
    style="display: block; height: auto; overflow-y: auto"
    (keydown.tab)="focusItem(1, $event)"
    (keydown.shift.tab)="focusItem(-1, $event)"
  >
    <div
      class="input-group search-container"
      *ngIf="
        settings.enableSearch &&
        (renderFilteredOptions.length > 1 || filterControl.value.length > 0 || settings.searchRenderLimit > 0)
      "
    >
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1">
          <i class="fa fa-search" aria-hidden="true"></i>
        </span>
      </div>

      <input
        type="text"
        class="form-control ng-trim-ignore"
        ssAutofocus
        [formControl]="filterControl"
        [placeholder]="texts.searchPlaceholder"
        class="form-control"
      />

      <div class="input-group-append" *ngIf="filterControl.value.length > 0">
        <button class="btn btn-default btn-secondary" type="button" (click)="clearSearch($event)">
          <i class="fa fa-times"></i>
        </button>
      </div>
    </div>

    <a
      role="menuitem"
      href="javascript:;"
      tabindex="-1"
      class="dropdown-item check-control check-control-check"
      *ngIf="settings.showCheckAll && !disabledSelection && renderFilteredOptions.length > 1"
      (click)="checkAll()"
    >
      <span style="width: 16px"
        ><span
          [ngClass]="{
            'glyphicon glyphicon-ok': settings.checkedStyle !== 'fontawesome',
            'fa fa-check': settings.checkedStyle === 'fontawesome'
          }"
        ></span
      ></span>
      {{ texts.checkAll }}
    </a>

    <a
      role="menuitem"
      href="javascript:;"
      tabindex="-1"
      class="dropdown-item check-control check-control-uncheck"
      *ngIf="settings.showUncheckAll && !disabledSelection && renderFilteredOptions.length > 1"
      (click)="uncheckAll()"
    >
      <span style="width: 16px"
        ><span
          [ngClass]="{
            'glyphicon glyphicon-remove': settings.checkedStyle !== 'fontawesome',
            'fa fa-times': settings.checkedStyle === 'fontawesome'
          }"
        ></span
      ></span>
      {{ texts.uncheckAll }}
    </a>

    <a
      *ngIf="settings.showCheckAll || settings.showUncheckAll"
      href="javascript:;"
      class="dropdown-divider divider"
    ></a>
    <a *ngIf="!renderItems" href="javascript:;" class="dropdown-item empty">{{ texts.searchNoRenderText }}</a>
    <a *ngIf="renderItems && !renderFilteredOptions.length" href="javascript:;" class="dropdown-item empty">{{
      texts.searchEmptyResult
    }}</a>

    <a
      class="dropdown-item"
      href="javascript:;"
      *ngFor="let option of renderFilteredOptions; trackBy: trackById"
      [class.active]="isSelected(option)"
      [ngStyle]="getItemStyle(option)"
      [ngClass]="option.classes"
      [class.dropdown-header]="option.isLabel"
      [ssAutofocus]="option !== focusedItem"
      tabindex="-1"
      (click)="setSelected($event, option)"
      (keydown.space)="setSelected($event, option)"
      (keydown.enter)="setSelected($event, option)"
    >
      <span
        *ngIf="!option.isLabel; else label"
        role="menuitem"
        tabindex="-1"
        [style.padding-left]="this.parents.length > 0 && this.parents.indexOf(option.id) < 0 && '30px'"
        [ngStyle]="getItemStyleSelectionDisabled()"
      >
        <ng-container [ngSwitch]="settings.checkedStyle">
          <input
            *ngSwitchCase="'checkboxes'"
            type="checkbox"
            [checked]="isSelected(option)"
            (click)="preventCheckboxCheck($event, option)"
            [disabled]="isCheckboxDisabled(option)"
            [ngStyle]="getItemStyleSelectionDisabled()"
          />

          <span
            *ngSwitchCase="'glyphicon'"
            style="width: 16px"
            class="glyphicon"
            [class.glyphicon-ok]="isSelected(option)"
            [class.glyphicon-lock]="isCheckboxDisabled(option)"
          ></span>
          <span *ngSwitchCase="'fontawesome'" style="width: 16px; display: inline-block">
            <span *ngIf="isSelected(option)"><i class="fa fa-check" aria-hidden="true"></i></span>
            <span *ngIf="isCheckboxDisabled(option)"><i class="fa fa-lock" aria-hidden="true"></i></span>
          </span>

          <span
            *ngSwitchCase="'visual'"
            style="
              display: block;
              float: left;
              border-radius: 0.2em;
              border: 0.1em solid rgba(44, 44, 44, 0.63);
              background: rgba(0, 0, 0, 0.1);
              width: 5.5em;
            "
          >
            <div class="slider" [ngClass]="{ slideron: isSelected(option) }">
              <img
                *ngIf="option.image !== null"
                [src]="option.image"
                style="height: 100%; width: 100%; object-fit: contain"
              />
              <div
                *ngIf="option.image === null"
                style="
                  height: 100%;
                  width: 100%;
                  text-align: center;
                  display: table;
                  background-color: rgba(0, 0, 0, 0.74);
                "
              >
                <div class="content_wrapper">
                  <span style="font-size: 3em; color: white" class="glyphicon glyphicon-eye-close"></span>
                </div>
              </div>
            </div>
          </span>
        </ng-container>

        <span
          [ngClass]="{ chunkyrow: settings.checkedStyle === 'visual' }"
          [class.disabled]="isCheckboxDisabled(option)"
          [ngClass]="settings.itemClasses"
          [style.font-weight]="this.parents.indexOf(option.id) >= 0 ? 'bold' : 'normal'"
        >
          {{ option.name }}
        </span>

        <span *ngIf="settings.showVisibleFA && option.visible" title="Visible focus area">
          <i class="fa fa-eye" aria-hidden="true" style="color: black; margin-right: 1px"></i>
        </span>

        <span
          *ngIf="settings.showCategoryFA"
          class="fa-category"
          style="color: gray; font-style: italic; margin-left: 8px"
        >
          {{ option.category }}</span
        >
      </span>

      <ng-template #label>
        <span [class.disabled]="isCheckboxDisabled(option)">{{ option.name }}</span>
      </ng-template>
    </a>
  </div>
</div>
