import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';

import { Filter, FiltersComponent, FilterType } from '../../shared/components/filters/filters.component';
import { ClinicalTrialProfileAPI } from '../shared/clinical-trial-profile-api.service';
import { UserAPI } from '../../user/shared/api.service';
import { Roles } from '../../shared/acl/roles';
import { format } from 'date-fns';
import { TextboxFilterComponent } from '../shared/filters/textbox-filter/textbox-filter.component';

@Component({
  selector: 'dirt-ct-profile-ext-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ClinicalTrialProfileExtListComponent implements OnInit, OnDestroy {
  rows: { nct; category; priority; status; createdAt; updatedAt; curatedBy; curatedAt; qcBy; qcAt; ctrial }[];

  userNames = {};

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  // Sorting / Filtering settings
  searchCtrl: FormControl = new FormControl('');
  filter: { [key: string]: any };
  filters: Filter[] = [];
  filterBarConfig = {
    conditions: {
      label: 'Disease',
      format(value: { $regex: string; $options: string }) {
        return value.$regex;
      },
    },
    curatedBy: {
      label: 'Curated by',
    },
    qcBy: {
      label: 'QC by',
    },
    curatedAt: {
      label: 'Curated at',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
    qcAt: {
      label: 'QC at',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
  };

  private searchTerm: string;

  private sort = '-_id';
  extListType = 'CT-Timing';

  @ViewChild(FiltersComponent, { static: false })
  private filterRef: FiltersComponent;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly service: ClinicalTrialProfileAPI,
    private readonly svcUser: UserAPI
  ) {}

  ngOnInit(): void {
    forkJoin({
      curators: this.svcUser
        .find(
          undefined,
          1000,
          0,
          undefined,
          { roles: [Roles.CtCompiler, Roles.CtTimingCompiler, Roles.CtPeopleCompiler, Roles.CtSiteCompiler] },
          false,
          false
        )
        .pipe(take(1)), // TODO: find others when changing type
      reviewers: this.svcUser
        .find(undefined, 1000, 0, undefined, { roles: [Roles.CtReviewer] }, false, false)
        .pipe(take(1)),
      params: this.route.queryParams.pipe(take(1)),
    }).subscribe(({ curators, reviewers, params }) => {
      // resolve user names
      curators.forEach(
        (c) => (this.userNames[c.user_id] = c.user_metadata?.firstName + ' ' + c.user_metadata?.lastName)
      );
      (this.filterBarConfig.curatedBy as any).format = (value) => this.userNames[value] || value;
      reviewers.forEach(
        (r) => (this.userNames[r.user_id] = r.user_metadata?.firstName + ' ' + r.user_metadata?.lastName)
      );
      (this.filterBarConfig.qcBy as any).format = (value) => this.userNames[value] || value;
      // define filters
      this.filters = [
        {
          title: 'Disease',
          key: 'conditions',
          type: FilterType.CUSTOM,
          component: TextboxFilterComponent,
        },
        {
          title: 'Curated by',
          key: 'curatedBy',
          type: FilterType.SINGLE_VALUE,
          values: curators
            .map((c) => ({
              title: c.user_id,
              displayValue: c.user_metadata?.firstName + ' ' + c.user_metadata?.lastName,
            }))
            .sort((v1, v2) => v1.displayValue.localeCompare(v2.displayValue)),
        },
        {
          title: 'QC by',
          key: 'qcBy',
          type: FilterType.SINGLE_VALUE,
          values: reviewers
            .map((c) => ({
              title: c.user_id,
              displayValue: c.user_metadata?.firstName + ' ' + c.user_metadata?.lastName,
            }))
            .sort((v1, v2) => v1.displayValue.localeCompare(v2.displayValue)),
        },
        {
          title: 'Curated at',
          key: 'curatedAt',
          type: FilterType.DATE_RANGE,
        },
        {
          title: 'QC at',
          key: 'qcAt',
          type: FilterType.DATE_RANGE,
        },
      ];

      if (params.searchTerm) {
        this.searchTerm = params.searchTerm;
        this.searchCtrl.setValue(this.searchTerm, { emitEvent: false }); // restore search term
      }
      if (params.sortField) {
        this.sort = params.sortField;
      }

      this.doLoad(); // We rely on countries to map code to country name
    });

    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.searchTerm = term;
        this.doLoad();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /** just avoid useless rendering if we can */
  trackById(index: number, row: { id }): string {
    return row.id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getExtList();
  }

  onSort(field: string): void {
    this.sort = field;

    this.storeFiltersAsQueryParams();
    this.getExtList();
  }

  onFilter(filter: { [key: string]: any }): void {
    this.filter = filter;
    this.doLoad();
  }

  onFilterClear(): void {
    this.filterRef.doClear();
  }

  onFilterValueRemove(item: { key: string; value: any }): void {
    this.filterRef.removeValue(item.key, item.value);
  }

  navigateTo(id: string): void {
    if (!id) {
      return;
    }

    this.router.navigate(['/ct-profile/detail', id]);
  }

  private doLoad(): void {
    this.storeFiltersAsQueryParams();
    this.resetPagination();
    this.getExtList();
    this.getExtListCount();
  }

  private storeFiltersAsQueryParams(): void {
    if (this.searchTerm || this.sort) {
      const filters = {
        ...(this.searchTerm && { searchTerm: this.searchTerm }),
        ...(this.sort && { sortField: this.sort }),
      };
      this.router.navigate([], { queryParams: filters });
    }
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  onListTypeChange(evnt) {
    if (evnt.target.value !== this.extListType) {
      this.extListType = evnt.target.value;
      this.doLoad();
    }
  }

  private getExtList(): void {
    this.isLoading = true;

    this.service
      .findExtList(this.extListType, this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.filter)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((rows) => {
        this.rows = rows;
      });
  }

  private getExtListCount(): void {
    this.service.countExtList(this.extListType, this.searchTerm, this.filter).subscribe((count) => {
      this.total = count;
    });
  }
}
