export class UserPool {
  id: string;
  name: string;
  list: boolean;
  users: string[];
  capacities?: {
    product?: string;
    projectNames?: string;
    percent: number;
  }[];
  createdAt: string;
  updatedAt: string;
  disabled?: boolean; // have a mean to indicate that a pool is no longer in use
  regional?: boolean; // if true, user region setting will be used for job routing
  projectWise?: boolean; // if true, user project setting will be used for job routing (KOLs only)
  system?: boolean; // updated via DB only - indicate that the pool was created for a specific task
}
