import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { GoogleAddress } from '../../directives/google-place/address';
import { ValueType } from '../../enum/value-type.enum';
import { ValueAPI } from '../../services/value/value-api.service';
import { Value } from '../../services/value/value';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { AddressInternalSearchConfig, SearchResponse } from './internal-search-config';
import { Utils } from '../../../common/utils';
import { IMultiSelectOption } from '../multiselect-dropdown/types';

const ADDRESS_MANUAL_FIELDS = [
  GoogleAddress.NAME,
  GoogleAddress.CITY,
  GoogleAddress.STATE,
  GoogleAddress.ZIP,
  GoogleAddress.COUNTRY_CODE,
  'primary',
  'additionalInfo',
  'houseNo',
];

export enum ADDRESS_ENTRY_MODE {
  GOOGLE_SEARCH = 'googleSearch',
  MANUAL = 'manual',
  INTERNAL_SEARCH = 'internalSearch',
}

@Component({
  selector: 'dirt-address',
  templateUrl: 'address.component.html',
  styleUrls: ['address.component.scss'],
})
export class AddressComponent implements OnInit, OnChanges {
  @Input()
  address: any = {};

  @Input()
  disabled: boolean;

  @Input()
  disableGoogle: boolean;

  @Input()
  internalSearchConfig: AddressInternalSearchConfig;

  @Input()
  mandatory: boolean;

  @Input()
  mandatoryCountry: boolean;

  @Input()
  mandatoryCity: boolean;

  @Input()
  stateSelector = true;

  @Input()
  additional: boolean;

  @Input()
  force: boolean;

  @Input()
  original: boolean;

  @Input()
  addNoStateOption = false; // add a No State option to the state selector

  @Input()
  countrySource: ValueType.Country | ValueType.EventAssociationCountry | ValueType.InScopeCountry = ValueType.Country;

  @Input()
  addNoCountryOption = false; // add a "No Country" option to the country selector

  @Input()
  withHouseNo = false;

  @Input()
  lenientState = false;

  @Output()
  onChange = new EventEmitter<any>();

  manual = false;
  addressEntryMode: ADDRESS_ENTRY_MODE;

  countries: IMultiSelectOption[] = [];
  states: Value[] = [];

  @ViewChild(NgForm, { static: true })
  form: NgForm;

  search: any;
  isSearching = false;

  longDash = Utils.longDash;

  constructor(private svcValue: ValueAPI) {}

  ngOnInit() {
    if (!this.address) {
      this.address = {};
    }

    this.loadCountries();
    this.loadStates();
    this.setAddressEntryMode();

    this.search = (text$: Observable<string>) =>
      text$.pipe(
        debounceTime(600),
        distinctUntilChanged(),
        tap(() => (this.isSearching = true)),
        switchMap((term) => this.internalSearchConfig.onSearch(term)),
        map((result: SearchResponse[]) => result.filter((item) => item.address || item.parent?.address)),
        tap(() => (this.isSearching = false))
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    // reset address type when address get reset
    if (changes?.address?.currentValue && Object.keys(changes.address.currentValue).length === 0) {
      this.setInitialAddressEntryMode();
    }
  }

  getAddress(): any {
    if (this.isEmptyAddress(this.address)) {
      return;
    }

    if (
      this.addressEntryMode !== ADDRESS_ENTRY_MODE.MANUAL &&
      !this.isStateSelectorEnabled() // really only when it was not there already
    ) {
      delete this.address.state;
    }
    this.address.addressEntryMode = this.addressEntryMode;
    return this.address;
  }

  setAddressEntryMode(): void {
    if (!Object.keys(this.address).length) {
      this.setInitialAddressEntryMode();
      return;
    }

    if (this.address.addressEntryMode) {
      this.addressEntryMode = this.address.addressEntryMode;
      return;
    }

    // if `offset` is set then the address was set using Google
    if (this.address[GoogleAddress.OFFSET_UTC]) {
      this.addressEntryMode = ADDRESS_ENTRY_MODE.GOOGLE_SEARCH;
    } else {
      this.addressEntryMode = this.address.addressEntryMode || ADDRESS_ENTRY_MODE.MANUAL;
    }
  }

  setInitialAddressEntryMode() {
    if (this.disableGoogle) {
      this.addressEntryMode = ADDRESS_ENTRY_MODE.MANUAL;
    } else {
      this.addressEntryMode = ADDRESS_ENTRY_MODE.GOOGLE_SEARCH;
    }
  }

  onAddressGoogleEntry(): void {
    this.address.addressEntryMode = ADDRESS_ENTRY_MODE.GOOGLE_SEARCH;
  }

  // By manual entry, delete fields coming from google, if any
  onAddressManualEntry(): void {
    this.deleteGoogleFieldsFromAddress();
  }

  onAddressInternalSearchEntry(): void {
    this.deleteGoogleFieldsFromAddress();
  }

  deleteGoogleFieldsFromAddress() {
    Object.keys(this.address).forEach((key) => {
      if (ADDRESS_MANUAL_FIELDS.indexOf(key) === -1) {
        delete this.address[key];
      }
    });
  }

  onCountryCodeChange(code: string): void {
    // If country is changed, then update the country also depending on selected `CountryCode`
    this.address.country = this.countries.filter((e) => e.id === code)[0].name;

    const allowedStates = this.getStateCountries(code);
    if (!allowedStates.includes(this.address.state)) {
      // If country is changed, and current state isn't part of the new country states, then reset the existing state selection
      this.address.state = undefined;
    }

    this.onChange.emit(this.address);
  }

  isEmptyAddress(address: any): boolean {
    return address && Object.keys(address).length === 0;
  }

  isValid(): boolean {
    return this.form.valid;
  }

  isStateSelectorEnabled(): boolean {
    // if we're lenient and google-searching, we can always go without state
    if (this.addressEntryMode === ADDRESS_ENTRY_MODE.GOOGLE_SEARCH && this.lenientState) {
      return false;
    }

    if (!this.stateSelector) {
      return this.address && this.address.countryCode === 'US';
    }

    return this.address && !!this.getStateCountries(this.address.countryCode).length;
  }

  getStateCountries(code): any[] {
    return (this.states.find((o) => o.code === code) || { value: [] }).value;
  }

  loadCountries() {
    this.svcValue
      .find(this.countrySource, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.countries = data.map((o) => ({
          id: o.code,
          name: o.title,
          disabled: false,
        }));

        if (this.addNoCountryOption) {
          this.countries.unshift({ id: null, name: 'No Country', disabled: false });
        }
      });
  }

  loadStates() {
    this.svcValue
      .find(ValueType.CountryState, Number.MAX_SAFE_INTEGER)
      .toPromise()
      .then((data) => {
        this.states = data;
      });
  }

  onItemSelect(selected: NgbTypeaheadSelectItemEvent): void {
    const address = selected.item.address || selected.item.parent.address;
    this.onAddressChange(address);
  }

  onAddressChange(data) {
    for (const variableKey in this.address) {
      if (this.address.hasOwnProperty(variableKey)) {
        delete this.address[variableKey];
      }
    }
    Object.assign(this.address, data);
    // when we don't save the state, don't ever display it
    if (
      this.addressEntryMode !== ADDRESS_ENTRY_MODE.MANUAL &&
      !this.lenientState &&
      !this.isStateSelectorEnabled() // really only when it was not there already
    ) {
      delete this.address.state;
    }
    // try to find in states list - otherwise try match w/out umlauts et al, e.g. Zürich
    const longState = this.address.state;
    const statesList = this.getStateCountries(this.address.countryCode);
    if (longState && statesList?.length > 0 && statesList.indexOf(longState) < 0) {
      const longStateNorm = longState.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      const normFound = statesList.filter(
        (s) => s.normalize('NFD').replace(/[\u0300-\u036f]/g, '') === longStateNorm
      )[0];
      if (normFound) {
        console.info('Normalizing ' + longState + ' to ' + normFound);
        this.address.state = normFound;
      } else if (this.lenientState) {
        delete this.address.state; // do NOT save the state when we can't match our normalized list of states
      }
    } else if (longState && statesList?.length < 1 && this.lenientState) {
      delete this.address.state; // do NOT save the state as well when we don't have our normalized list of states
    }

    if (this.address.countryCode) {
      this.onCountryCodeChange(this.address.countryCode);
    }
  }

  formatInput(val: any): string {
    return '';
  }

  formatOutput(val: any): string {
    return val.name;
  }
}
