<ul *ngIf="!visibleSubPanel" class="list-group">
  <li
    class="list-group-item justify-content-between"
    *ngFor="let option of values"
    [class.selected]="isValueSelected(option)"
    (click)="toggleMenuSelection(option)"
  >
    {{ option.title }}
    <i
      class="fa"
      [ngClass]="{
        'fa-chevron-right': (option.subOptions || []).length > 0,
        'fa-minus': (option.subOptions || []).length === 0
      }"
      aria-hidden="true"
    ></i>
  </li>
</ul>

<div *ngIf="visibleSubPanel">
  <div class="text-center">
    <i class="fa fa-chevron-left dirt-back" aria-hidden="true" (click)="setSubPanel('')"> </i>
    <span>Choose sub options or press apply to filter</span>
  </div>
  <ul *ngIf="visibleSubPanel" class="list-group">
    <li
      class="list-group-item justify-content-between"
      *ngFor="let subOption of getSubpanelOptions()"
      [class.selected]="isValueSelected(subOption)"
      (click)="toggleSubMenuSelection(subOption)"
    >
      {{ subOption.title }}
      <i class="fa fa-minus" aria-hidden="true"></i>
    </li>
  </ul>
</div>
