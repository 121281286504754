<div class="clearfix">
  <form #form="ngForm" [diMarkerWrapper]="model" novalidate>
    <!-- Hostname -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Hostname</label>
      <div class="col-sm-8 col-md-10">
        <div class="input-group">
          <ng-container *ngIf="!model.id; else hostname">
            <input
              type="text"
              class="form-control"
              name="hostname"
              [(ngModel)]="model.hostname"
              [readonly]="!isFieldEditable('hostname')"
              autofocus
              required
            />
          </ng-container>

          <ng-template #hostname>
            <input type="text" class="form-control" [value]="model.hostname" readonly />
          </ng-template>

          <div class="input-group-append">
            <button
              class="btn btn-secondary"
              aria-label="Open link in a new tab"
              (click)="onOpenURL(model.hostname)"
              *ngIf="model.hostname"
            >
              <i class="fa fa-external-link dirt-link-pointer" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Example -->
    <ng-container *ngIf="model.example">
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">Example</label>
        <div class="col-sm-8 col-md-10">
          <div class="input-group">
            <input type="text" class="form-control" [value]="model.example" readonly />

            <div class="input-group-append">
              <button class="btn btn-secondary" aria-label="Open link in a new tab" (click)="onOpenURL(model.example)">
                <i class="fa fa-external-link dirt-link-pointer" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <!-- Terms -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Terms</label>
      <div class="col-sm-8 col-md-10">
        <select
          class="form-control custom-select"
          name="terms"
          [(ngModel)]="model.terms"
          [disabled]="!isFieldEditable('terms')"
          required
          *diMarker="{ name: 'terms', entity: currentJobType === 'DOMAIN_REVIEW' ? 'domain' : 'noop', area: 'ALL' }"
        >
          <option *ngFor="let term of terms | keyvalue" [value]="term.value">
            {{ term.value | capitalize }}
          </option>
        </select>

        <div class="pending-value" *ngIf="model.pendingUpdates?.terms">
          <span>Pending value: {{ model.pendingUpdates.terms }}</span>
          <div class="pending-value-controls" *ngIf="isDomainAdmin">
            <i class="fa fa-check" aria-hidden="true" title="Accept" (click)="onAcceptPendingUpdate('terms')"></i>
            <i class="fa fa-trash-o" aria-hidden="true" title="Reject" (click)="onRejectPendingUpdate('terms')"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- Source Type -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Source Type</label>
      <div class="col-sm-8 col-md-10">
        <select
          class="form-control custom-select"
          name="sourceType"
          [(ngModel)]="model.sourceType"
          [disabled]="!isFieldEditable('sourceType')"
          [required]="needSourceType()"
          (ngModelChange)="onSourceTypeChange()"
          *diMarker="{
            name: 'sourceType',
            entity: currentJobType === 'DOMAIN_REVIEW' ? 'domain' : 'noop',
            area: 'ALL'
          }"
        >
          <option *ngFor="let source of sourceTypes | keyvalue" [value]="source.value">
            {{ source.value | capitalize }}
          </option>
        </select>
      </div>
    </div>

    <!-- Connected Association -->
    <div class="form-group row" *ngIf="model.sourceType && model.sourceType === 'ASSOCIATION'">
      <label class="col-form-label col-sm-4 col-md-2">Source Association Name</label>
      <div class="col-sm-8 col-md-10">
        <div
          *diMarker="{
            name: 'associations',
            entity: currentJobType === 'DOMAIN_REVIEW' ? 'domain' : 'noop',
            area: 'ALL'
          }"
        >
          <div
            class="form-group"
            *ngFor="let association of model.associationIds; let idx = index; trackBy: trackByIndex"
            [diMarkerWrapper]="association"
          >
            <dirt-association-single-select
              [association]="association"
              [wide]="true"
              [inline]="true"
              (associationSelected)="onSourceAssociationSelected($event)"
              [disabled]="!isFieldEditable('associationIds')"
              [required]="idx === 0 && !association && !model.associationNames?.[0]"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.associationIds)"
                *ngIf="idx == model.associationIds.length - 1 && isFieldEditable('associationIds')"
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.associationIds, idx)"
                *ngIf="idx > 0 && isFieldEditable('associationIds')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-association-single-select>

            <ng-container
              *ngIf="
                !model.associationNames?.length &&
                idx == model.associationIds.length - 1 &&
                isFieldEditable('associationIds')
              "
            >
              <a
                href="javascript:void(0)"
                class="small pull-right pt-1 pr-2"
                (click)="pushItemToList(model.associationNames)"
              >
                Add free-text Association
              </a>

              <br />
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Connected Association: free-text name -->
    <div
      class="form-group row"
      *ngIf="model.sourceType && model.sourceType === 'ASSOCIATION' && model.associationNames?.length > 0"
    >
      <label class="col-form-label col-sm-4 col-md-2">Source Association Name (free-text)</label>
      <div class="col-sm-8 col-md-10">
        <div
          *diMarker="{
            name: 'associationNames',
            entity: currentJobType === 'DOMAIN_REVIEW' ? 'domain' : 'noop',
            area: 'ALL'
          }"
        >
          <div
            class="form-group"
            *ngFor="let association of model.associationNames; let idx = index; trackBy: trackByIndex"
          >
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                name="associationNames-{{ idx }}"
                [(ngModel)]="model.associationNames[idx]"
                (ngModelChange)="onAddFreeTextAssociationName()"
                [readonly]="!isFieldEditable('associationIds')"
                [required]="!model.associationIds?.[0] && idx === 0"
              />

              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="pushItemToList(model.associationNames)"
                  *ngIf="idx == model.associationNames.length - 1 && isFieldEditable('associationIds')"
                >
                  <i class="fa fa-plus-circle"></i>
                </button>

                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="removeFromByIndex(model.associationNames, idx)"
                  *ngIf="isFieldEditable('associationIds')"
                >
                  <i class="fa fa-minus-circle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Connected Organization -->
    <div
      class="form-group row"
      *ngIf="model.sourceType && model.sourceType !== 'ASSOCIATION' && model.sourceType !== 'OTHER'"
    >
      <label class="col-form-label col-sm-4 col-md-2">Source Organization Name</label>
      <div class="col-sm-8 col-md-10">
        <div
          *diMarker="{
            name: 'organizations',
            entity: currentJobType === 'DOMAIN_REVIEW' ? 'domain' : 'noop',
            area: 'ALL'
          }"
        >
          <div
            class="form-group"
            *ngFor="let organization of model.organizationIds; let idx = index; trackBy: trackByIndex"
            [diMarkerWrapper]="organization"
          >
            <dirt-affiliation-single-select
              [affiliation]="organization"
              [wide]="true"
              [inline]="true"
              (affiliationSelected)="onSourceOrganizationSelected($event)"
              [disabled]="!isFieldEditable('organizationIds')"
              [required]="idx === 0 && !organization && !model.organizationNames?.[0]"
            >
              <button
                type="button"
                class="btn btn-secondary"
                (click)="pushItemToList(model.organizationIds)"
                *ngIf="idx == model.organizationIds.length - 1 && isFieldEditable('organizationIds')"
              >
                <i class="fa fa-plus-circle"></i>
              </button>

              <button
                type="button"
                class="btn btn-secondary"
                (click)="removeFromByIndex(model.organizationIds, idx)"
                *ngIf="idx > 0 && isFieldEditable('organizationIds')"
              >
                <i class="fa fa-minus-circle"></i>
              </button>
            </dirt-affiliation-single-select>

            <ng-container
              *ngIf="
                !model.organizationNames?.length &&
                idx == model.organizationIds.length - 1 &&
                isFieldEditable('organizationIds')
              "
            >
              <a
                href="javascript:void(0)"
                class="small pull-right pt-1 pr-2"
                (click)="pushItemToList(model.organizationNames)"
              >
                Add free-text Organization
              </a>

              <br />
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Connected Organization: free-text name -->
    <div
      class="form-group row"
      *ngIf="
        model.sourceType &&
        model.sourceType !== 'ASSOCIATION' &&
        model.sourceType !== 'OTHER' &&
        model.organizationNames?.length > 0
      "
    >
      <label class="col-form-label col-sm-4 col-md-2">Source Organization Name (free-text)</label>
      <div class="col-sm-8 col-md-10">
        <div
          *diMarker="{
            name: 'associationNames',
            entity: currentJobType === 'DOMAIN_REVIEW' ? 'domain' : 'noop',
            area: 'ALL'
          }"
        >
          <div
            class="form-group"
            *ngFor="let organization of model.organizationNames; let idx = index; trackBy: trackByIndex"
          >
            <div class="input-group">
              <input
                type="text"
                class="form-control"
                name="organizationNames-{{ idx }}"
                [(ngModel)]="model.organizationNames[idx]"
                (ngModelChange)="onAddFreeTextOrganizationName()"
                [readonly]="!isFieldEditable('organizationIds')"
                [required]="!model.organizationIds[0] && idx === 0"
              />

              <div class="input-group-append">
                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="pushItemToList(model.organizationNames)"
                  *ngIf="idx == model.organizationNames.length - 1 && isFieldEditable('organizationIds')"
                >
                  <i class="fa fa-plus-circle"></i>
                </button>

                <button
                  type="button"
                  class="btn btn-secondary"
                  (click)="removeFromByIndex(model.organizationNames, idx)"
                  *ngIf="isFieldEditable('organizationIds')"
                >
                  <i class="fa fa-minus-circle"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Screenshot -->
    <div class="form-group row" *ngIf="model.id">
      <label class="col-form-label col-sm-4 col-md-2">Screenshot</label>
      <div class="col-sm-8 col-md-10">
        <div
          class="input-group"
          *diMarker="{
            name: 'screenshot',
            entity: currentJobType === 'DOMAIN_REVIEW' ? 'domain' : 'noop',
            area: 'ALL'
          }"
        >
          <div class="input-wrapper">
            <input
              type="file"
              class="form-control"
              [ngClass]="{
                'ng-valid': !(hasScreenshotError$ | async) && !!model.screenshotUrl,
                'ng-invalid':
                  (hasScreenshotError$ | async) || (model.terms !== 'RESTRICTIVE_TERMS' && !model.screenshotUrl)
              }"
              [disabled]="!isFieldEditable('screenshot') || (isWorkingScreenshot$ | async)"
              [hidden]="!(hasScreenshotError$ | async) && !!model.screenshotUrl"
              (change)="onScreenshotAdd()"
              #screenshotInput
            />

            <!-- File input isn't super intuitive, show URL as readonly when we have it -->
            <input
              type="text"
              class="form-control ng-valid"
              [value]="model.screenshotUrl"
              [hidden]="(hasScreenshotError$ | async) || !model.screenshotUrl"
              readonly
            />

            <div class="spinner-wrapper">
              <i class="fa fa-pulse fa-spinner di-va-super" [hidden]="!(isWorkingScreenshot$ | async)"></i>
            </div>
          </div>

          <div class="input-group-append" *ngIf="model.screenshotUrl">
            <span class="btn btn-secondary" (click)="onScreenshotRemoveRequest()" *ngIf="isFieldEditable('screenshot')">
              <i class="fa fa-trash-o dirt-link-pointer" aria-hidden="true"></i>
            </span>

            <a
              class="btn btn-secondary"
              href="javascript:void(0)"
              aria-label="Open screenshot in a new tab"
              (click)="onOpenScreenshotPreview()"
            >
              <i class="fa fa-external-link dirt-link-pointer" aria-hidden="true"></i>
            </a>
          </div>
        </div>

        <small
          class="form-text"
          [ngClass]="{ 'text-muted': !(hasScreenshotError$ | async), 'text-danger': (hasScreenshotError$ | async) }"
          >Accepted formats: PNG, JPEG or WEBP</small
        >
      </div>
    </div>

    <!-- Proof Link -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Proof Link</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="model.prooflink"
          name="prooflink"
          [form]="form"
          [disabled]="!isFieldEditable('prooflink')"
          [required]="needProoflink()"
          [validateFormatOnly]="true"
          *diMarker="{ name: 'prooflink', entity: currentJobType === 'DOMAIN_REVIEW' ? 'domain' : 'noop', area: 'ALL' }"
        ></dirt-link>
      </div>
    </div>

    <hr />

    <!-- Address -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Address</label>
      <div class="col-sm-8 col-md-10">
        <dirt-address
          [address]="model.address"
          [stateSelector]="false"
          [addNoStateOption]="true"
          [disabled]="!isFieldEditable('address')"
          [mandatoryCountry]="!model.email"
          [addNoCountryOption]="true"
          *diMarker="{ name: 'address', entity: currentJobType === 'DOMAIN_REVIEW' ? 'domain' : 'noop', area: 'ALL' }"
        >
        </dirt-address>
      </div>
    </div>

    <!-- Email -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Email</label>
      <div class="col-sm-8 col-md-10">
        <input
          type="text"
          class="form-control"
          name="email"
          [(ngModel)]="model.email"
          [readonly]="!isFieldEditable('email')"
          [required]="!addressComponent?.isValid()"
          *diMarker="{ name: 'email', entity: currentJobType === 'DOMAIN_REVIEW' ? 'domain' : 'noop', area: 'ALL' }"
        />
      </div>
    </div>
  </form>
</div>
