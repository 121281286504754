import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { ClinicalTrialAPI } from './shared/clinical-trial-api.service';

@NgModule({
  imports: [SharedModule],
  declarations: [],
  providers: [ClinicalTrialAPI],
})
export class ClinicalTrialModule {}
