import { Mode } from './mode.enum';

export class InitAction {
  static readonly type = '[BulkUpdate] Init';
  constructor(public mode: Mode, public search?: string, public filter?: any) {}
}

export class LoadEntitiesAction {
  static readonly type = '[BulkUpdate] Load entities';
}

export class NextStepAction {
  static readonly type = '[BulkUpdate] Next step';
}

export class PreviousStepAction {
  static readonly type = '[BulkUpdate] Previous step';
}

export class SetFieldValueAction {
  static readonly type = '[BulkUpdate] Set field`s value';
  constructor(public field: string, public value: any) {}
}

export class UpdateDependentChecksAction {
  static readonly type = '[BulkUpdate] Update dependent checks';
}

export class ToggleFieldValueCheckAction {
  static readonly type = '[BulkUpdate] Toggle field`s value handleCheck';
  constructor(public field: string) {}
}

export class RunBulkUpdateAction {
  static readonly type = '[BulkUpdate] Run';
}

export class AddEntityAction {
  static readonly type = '[BulkUpdate] add entity';
  constructor(public id: string) {}
}

export class RemoveEntityAction {
  static readonly type = '[BulkUpdate] remove entity';
  constructor(public id: string) {}
}

export class PrevalidateUpdateAction {
  static readonly type = '[BulkUpdate] prevalidate';
}

export class CloseAction {
  static readonly type = '[BulkUpdate] close';
}

export class RefreshListAction {
  static readonly type = '[BulkUpdate] refresh list';
}
