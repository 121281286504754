import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ACL } from '../../../../shared/acl/acl.service';
import { Value } from '../../../../shared/services/value/value';
import { ValueAPI } from '../../../../shared/services/value/value-api.service';
import { ValueType } from '../../../../shared/enum/value-type.enum';

@Component({
  selector: 'qc-comment-component',
  templateUrl: 'qc-comment.component.html',
})
export class QCCommentComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal, private svcValue: ValueAPI, private svcAcl: ACL) {}

  comment: string;
  qcStatusMode = '';
  profileErrorTypes: Value[];
  selectedError = '';

  ngOnInit(): void {
    if (
      this.comment &&
      this.comment.includes('->') &&
      (this.comment.split('->')[0].toLocaleLowerCase() === 'match' ||
        this.comment.split('->')[0].toLocaleLowerCase() === 'mismatch')
    ) {
      this.qcStatusMode = this.comment.split(' ')[0];
      this.comment = this.comment.split(' ').slice(1).join(' ');
    }

    if (this.svcAcl.hasCredential('profile.qc.extended')) {
      this.svcValue
        .find(ValueType.FieldErrorType, Number.MAX_SAFE_INTEGER, 0, '', { 'value.entity': 'profile' })
        .subscribe((errorTypes) => {
          this.profileErrorTypes = errorTypes;
        });
    }
  }

  saveEditedComments() {
    return this.activeModal.close({ comment: `${this.qcStatusMode} ${this.comment}`, errorType: this.selectedError });
  }
}
