import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { APIService } from '../../services/api/api.service';

@Component({
  selector: 'dirt-error-modal',
  templateUrl: 'error-modal.component.html',
})
export class ErrorModalComponent {
  @Input()
  requestID: string;

  @Input()
  errorMessage: string;

  @Input()
  errorTechnical: string;

  @Input()
  apiService: APIService; // Manually injected because of the circular dependency between APIService and ErrorModalComponent

  isCopying = false;

  private readonly errorTime = new Date().toISOString();

  constructor(private readonly activeModal: NgbActiveModal) {}

  async onCopyDetails(): Promise<void> {
    this.isCopying = true;

    const details = this.apiService.getErrorDetails(
      this.requestID,
      this.errorTime,
      this.errorMessage,
      this.errorTechnical
    );

    navigator.clipboard.writeText(details);

    this.isCopying = false;
  }

  onClose(): void {
    this.activeModal.close();
  }
}
