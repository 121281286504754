<section class="dirt-section-detail dirt-user-detail" *ngIf="user">
  <div class="row">
    <div class="col-md-8">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <h1 class="bounceInRight">User Detail</h1>

      <br />
      <dirt-user-form [user]="user" #frmUser="frmUser"></dirt-user-form>

      <div class="pull-right">
        <!-- Save -->
        <button
          type="submit"
          class="btn btn-primary"
          [ngClass]="{ 'btn-loading': isSubmitting }"
          [disabled]="!frmUser.isValid() || isSubmitting"
          (click)="onSave(frmUser.getValue())"
        >
          <span>Save</span>
        </button>
      </div>
      <ng-container *diAcl="'memo.create'">
        <div
          *ngIf="
            user.app_metadata.roles.includes(Roles.ProfileCompiler) ||
            user.app_metadata.roles.includes(Roles.PersonCompiler)
          "
          class="dirt-memo-link"
        >
          <a [routerLink]="['/memo/list', user.user_id]">Memos <i class="fa fa-clock-o" aria-hidden="true"></i></a>
        </div>
      </ng-container>
    </div>

    <div class="col-md-4">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Information -->
        <li ngbNavItem>
          <a ngbNavLink>Information</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <dt class="col-sm-4">Created</dt>
              <dd class="col-sm-8">
                <b>{{ user.created_at | date : 'medium' }}</b>
              </dd>

              <dt class="col-sm-4">Modified</dt>
              <dd class="col-sm-8">
                <b>{{ user.updated_at | date : 'medium' }}</b>
              </dd>

              <dt class="col-sm-4">Number of Login</dt>
              <dd class="col-sm-8">
                <b>{{ user.logins_count }}</b>
              </dd>

              <dt class="col-sm-4">Last Login</dt>
              <dd class="col-sm-8">
                <b>{{ user.last_login | date : 'medium' }}</b>
              </dd>
            </dl>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-user-meta"></div>
    </div>
  </div>
</section>
