<form (ngSubmit)="onSubmit()" #frmProjectTag="ngForm" novalidate>
  <fieldset class="dirt-fieldset form-group">
    <!-- Title -->
    <div class="form-group row">
      <label class="col-form-label col-md-1" for="title">Title</label>
      <div class="col-md-11">
        <input
          id="title"
          type="text"
          class="form-control"
          name="title"
          [(ngModel)]="model.title"
          (ngModelChange)="onTitleChange($event)"
          [required]="true"
          autofocus
        />
      </div>
    </div>
    <!-- Code -->
    <div class="form-group row">
      <label class="col-form-label col-md-1" for="code">Code</label>
      <div class="col-md-11">
        <input
          id="code"
          type="text"
          class="form-control"
          name="code"
          [(ngModel)]="model.code"
          (blur)="onCodeBlur()"
          [required]="true"
          [disabled]="isEditMode"
        />
      </div>
    </div>
    <!-- Product -->
    <div class="form-group row">
      <label class="col-form-label col-md-1" for="code">Product</label>
      <div class="col-md-11">
        <select
          id="product"
          class="form-control custom-select"
          name="product"
          [(ngModel)]="model.product"
          [required]="true"
        >
          <option *ngFor="let product of products" [value]="product.value">
            {{ product.value }}
          </option>
        </select>
      </div>
    </div>
    <!-- Options -->
    <div class="form-group row" *ngIf="'person-project' === model.type">
      <label class="col-form-label col-md-1"></label>
      <div class="col-md-11 dirt-tag-options">
        <label class="dirt-btn-ct pull-left">
          <input
            type="checkbox"
            name="reviewed"
            [(ngModel)]="model.active"
            [ngModelOptions]="{ standalone: true }"
            [disabled]="model.delivery && model.active"
          />
          <span ngbTooltip="If enabled, profiles with this tag will be ingested and matched by KIP">Active</span>
        </label>
        <label class="dirt-btn-ct pull-left">
          <input
            type="checkbox"
            name="reviewed"
            [(ngModel)]="model.delivery"
            (ngModelChange)="onDeliveryChange($event)"
            [ngModelOptions]="{ standalone: true }"
          />
          <span ngbTooltip="If enabled, profiles with this tag will be delivered to Link">Delivery</span>
        </label>
        <label class="dirt-btn-ct pull-left">
          <input
            type="checkbox"
            name="reviewed"
            [(ngModel)]="model.technical"
            [ngModelOptions]="{ standalone: true }"
            [disabled]="isEditMode || !canEditTech()"
          />
          <span
            ngbTooltip="If enabled, project tag will be used only for routing and process purposes. Set to false for new delivery tags"
            >Technical</span
          >
        </label>
      </div>
    </div>
    <div class="dirt-actions col-md-12">
      <div class="dirt-btn-ct pull-right">
        <button
          type="button"
          class="btn btn-secondary"
          [disabled]="isSubmitting"
          (click)="onCancelClick(); $event.stopPropagation()"
        >
          <span>Cancel</span>
        </button>
      </div>
      <div class="dirt-btn-ct pull-right">
        <button
          type="submit"
          class="btn btn-primary"
          [ngClass]="{ 'btn-loading': isSubmitting }"
          [disabled]="!frmProjectTag.form.valid || isSubmitting"
        >
          <span>Save</span>
        </button>
      </div>
    </div>
  </fieldset>
</form>
