<section class="dirt-section-detail">
  <header>
    <button
      type="button"
      class="dirt-btn-back btn btn-primary-outline btn-sm"
      (click)="navigateTo(['/user/detail', user])"
    >
      <span class="fa fa-arrow-left" aria-hidden="true"></span>
    </button>

    <h1 class="bounceInRight">Memos</h1>

    <p>
      Belongs to <a [routerLink]="['/user/detail', user]">{{ userData?.name }}</a>
    </p>
  </header>

  <br />

  <section class="dirt-section-list dirt-memos-list clearfix">
    <header class="row">
      <div class="col-md-6">
        <h2>
          <span *ngIf="total">{{ total.count | number }}</span> Memos
        </h2>
      </div>

      <div class="col-md-6 d-flex justify-content-end">
        <button
          class="btn btn-secondary btn-sm mx-1"
          *diAcl="'memo.bulkList'"
          [title]="bulkListDelegate.specialHint || ''"
          (click)="onBulkImportClick()"
        >
          <i class="fa fa-th-list" aria-hidden="true"></i>
          Bulk import
        </button>

        <button
          class="btn btn-success btn-sm mx-1"
          (click)="onCreateMemoClick()"
          *diAcl="'memo.create'"
          [disabled]="memoFormOpened"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Create Memo
        </button>
      </div>
    </header>

    <div *ngIf="memoFormOpened" class="dirt-memo-form">
      <dirt-memo-form
        [user]="user"
        [memo]="memo"
        [isSubmitting]="isSubmitting"
        [isSaving]="isSaving"
        (onSubmitted)="onSubmit($event)"
        (onSubmittedAndNew)="onSave($event)"
        (onCancel)="onCancelClick()"
      >
      </dirt-memo-form>
    </div>

    <!-- Pagination -->
    <dirt-pagination
      class="dirt-pagination"
      [id]="'dirt-memo-list'"
      [pagingSize]="memos?.length || 0"
      [pagingSkip]="pagingSkip"
      [pagingLimit]="pagingLimit"
      [pagingTotal]="total?.count || 0"
      [hidden]="pagingLimit >= total?.count"
      (pageChange)="getPage($event)"
    >
    </dirt-pagination>

    <div *ngIf="memos" [hidden]="isLoading">
      <table class="table table-sm table-hover" [hidden]="!memos.length" (diSort)="onSort($event)">
        <thead>
          <tr>
            <th class="col-id">ID</th>
            <th class="col-date" [diColSorter]="'date'">Date</th>
            <th class="col-hours">Hours</th>
            <th class="col-task">Task</th>
            <th class="col-created-by">Created by</th>
            <th class="col-actions">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="dirt-memo-row"
            *ngFor="
              let cn of memos
                | paginate
                  : {
                      id: 'dirt-memos-list',
                      itemsPerPage: pagingLimit,
                      currentPage: pagingPage,
                      totalItems: total?.count
                    }
            "
            [ngClass]="{
              'dirt-selected-item': cn.id === memoEditId,
              'dirt-flashing-item': flashingId === cn.id,
              'dirt-flashing-edit-item': flashingEditId === cn.id
            }"
          >
            <ng-container *ngIf="memoEditId !== cn.id">
              <td class="col-id">{{ cn.id }}</td>
              <td class="col-date">{{ cn.date | date }}</td>
              <td class="col-hours">{{ cn.hours }}</td>
              <td class="col-task">{{ cn.task }}</td>
              <td class="col-created-by"><dirt-username *ngIf="cn.createdBy" [user]="cn.createdBy"></dirt-username></td>
              <td class="col-actions">
                <!-- Edit -->
                <span class="action-btn action-edit" (click)="onEditClicked(cn.id)">
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </span>

                <!-- Delete -->
                <span class="action-btn action-delete" (click)="onDeleteClick(cn.id, $event)" *diAcl="'memo.delete'">
                  <i class="fa fa-trash-o" aria-hidden="true"></i>
                </span>
              </td>
            </ng-container>

            <ng-container *ngIf="memoEditId === cn.id">
              <td colspan="5" class="col-edit">
                <dirt-memo-form
                  [user]="user"
                  [memo]="cn"
                  [isSubmitting]="isSubmitting"
                  (onSubmitted)="onEdit($event)"
                  (onCancel)="onCancelEditClick()"
                >
                </dirt-memo-form>
              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>

      <!-- Empty Message -->
      <p class="dirt-no-results" *ngIf="!memos.length">No Memos</p>
    </div>

    <!-- Loading Message -->
    <p class="dirt-loading" *ngIf="isLoading">
      <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
      <span class="sr-only">Loading...</span>
    </p>
  </section>
</section>
