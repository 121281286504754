<button
  class="btn btn-secondary btn-sm"
  (click)="onShow()"
  [ngbPopover]="filterPopoverContent"
  [autoClose]="false"
  placement="bottom-right"
  #filterPopover="ngbPopover"
>
  <span [ngClass]="{ 'has-filter': isFilterSet() }">
    <i class="fa fa-filter" aria-hidden="true"></i>
    Filter
    <i class="fa fa-sort-down" aria-hidden="true"></i>
  </span>
</button>

<ng-template #filterPopoverContent>
  <div class="dirt-filter-content">
    <!-- Filter List -->
    <div class="card dirt-filter-list" *ngIf="!visiblePanel">
      <div class="card-header text-center">Filters</div>
      <div class="card-body" diScroller>
        <ul class="list-group">
          <li class="list-group-item justify-content-between" (click)="setPanel('mode')">
            Mode
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('status')">
            Status
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('location')">
            Countries
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('region')">
            Regions
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('polishers')">
            Polishers
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('polished_at')">
            Polished at
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('projects')">
            Projects
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('verified')">
            Verified <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('tag')">
            Tags <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('priority')">
            Priority <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
          <li class="list-group-item justify-content-between" (click)="setPanel('videoCompilerComment')">
            Video Compiler Comment <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>
        </ul>
      </div>
      <div class="card-footer" *ngIf="filter">
        <button class="btn btn-outline-primary btn-sm pull-right" (click)="doClear()">Clear Filters</button>
      </div>
    </div>

    <!-- Filter Panel -->
    <div class="card dirt-filter-panel" *ngIf="visiblePanel">
      <div class="card-header text-center">
        <i class="fa fa-chevron-left dirt-back" aria-hidden="true" (click)="setPanel()"></i>
        <span>Filter by {{ visiblePanel | capitalize }}</span>
      </div>

      <!-- Mode -->
      <div class="card-body" [hidden]="visiblePanel !== 'mode'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let mode of modeList | iterable"
            [class.selected]="isModeSelected(mode.key)"
            (click)="toggleMode(mode.key)"
          >
            {{ mode.value }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Status -->
      <div class="card-body" [hidden]="visiblePanel !== 'status'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let status of statusList | iterable"
            [class.selected]="isStatusSelected(status.key)"
            (click)="toggleStatus(status.key)"
          >
            {{ status.value | capitalize }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Locations -->
      <div class="card-body" [hidden]="visiblePanel !== 'location'">
        <div class="text-center" [hidden]="!isLoadingLocations">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <ul class="list-group" [hidden]="isLoadingLocations">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let loc of locationList"
            [class.selected]="isLocationSelected(loc)"
            (click)="toggleLocation(loc)"
          >
            {{ getCountryTitle(loc) }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Regions -->
      <div class="card-body" [hidden]="visiblePanel !== 'region'">
        <div class="text-center" [hidden]="!isLoadingRegions">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let region of regionList"
            [class.selected]="isRegionSelected(region)"
            (click)="toggleRegion(region)"
          >
            {{ region }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Projects -->
      <div class="card-body" [hidden]="visiblePanel !== 'projects'" *ngIf="!visibleSubPanel">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let pr of projectTagList | iterable"
            [class.selected]="isProjectSelected(pr.code)"
            [class.disabled]="
              isProjectSelected('NON_AUTOMATED') ||
              isProjectSelected('AUTOMATED_PLUS_DELIVERY') ||
              isProjectSelected('AUTOMATED_NO_DELIVERY') ||
              isProjectSelected('LFKA_ONLY')
            "
            (click)="setSubPanel('projects', pr.value)"
          >
            {{ pr.value.charAt(0).toUpperCase() + pr.value.slice(1) }}
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </li>

          <li
            class="list-group-item justify-content-between"
            [class.selected]="isProjectSelected('NON_AUTOMATED')"
            (click)="toggleProjects('NON_AUTOMATED')"
          >
            Non Automated
          </li>

          <li
            class="list-group-item justify-content-between"
            [class.selected]="isProjectSelected('AUTOMATED_PLUS_DELIVERY')"
            (click)="toggleProjects('AUTOMATED_PLUS_DELIVERY')"
          >
            Automated + Delivery
          </li>

          <li
            class="list-group-item justify-content-between"
            [class.selected]="isProjectSelected('AUTOMATED_NO_DELIVERY')"
            (click)="toggleProjects('AUTOMATED_NO_DELIVERY')"
          >
            Automated no Delivery
          </li>

          <li
            class="list-group-item justify-content-between"
            [class.selected]="isProjectSelected('LFKA_ONLY')"
            (click)="toggleProjects('LFKA_ONLY')"
          >
            LFKA Only
          </li>
        </ul>
      </div>

      <div class="card-body" [hidden]="visibleSubPanel !== 'projects'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let pr of filterOnProjectType()"
            [class.selected]="isProjectSelected(pr.code)"
            (click)="toggleProjects(pr.code)"
          >
            {{ pr.title }} <i class="fa fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Polishers -->
      <div class="card-body" [hidden]="visiblePanel !== 'polishers'">
        <div class="text-center" [hidden]="!isLoadingPolishers">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <div>
          <input
            type="search"
            class="form-control"
            [formControl]="searchCtrl"
            [(ngModel)]="searchTerm"
            placeholder="Search..."
          />
        </div>
        <ul class="list-group" [hidden]="isLoadingPolishers">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let polisher of polishersList"
            [class.selected]="isPolishedBySelected(polisher.user_id)"
            (click)="togglePolishedBy(polisher.user_id, $event)"
          >
            <dirt-username [user]="polisher"></dirt-username> <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- PolishedAt -->
      <div class="card-body" [hidden]="visiblePanel !== 'polished_at'">
        <dirt-date-range
          notSetLabel="Never Polished"
          [isOpen]="visiblePanel === 'polished_at'"
          [value]="selectedPolishedAt"
          (onChange)="setPolishedAt($event)"
          (onError)="onFilterError($event)"
        >
        </dirt-date-range>
      </div>

      <!-- Tags -->
      <div class="card-body" [hidden]="visiblePanel !== 'tag'">
        <!-- Empty Message -->
        <p class="text-center mb-0" *ngIf="!isLoadingTags && !tagList?.length">No Tags</p>
        <!-- Loading Indicator -->
        <div class="text-center" [hidden]="!isLoadingTags">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
        <ul class="list-group" [hidden]="isLoadingTags">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let tag of tagList"
            [class.selected]="isTagSelected(tag)"
            (click)="toggleTag(tag)"
          >
            {{ tag }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Verified -->
      <div class="card-body" [hidden]="visiblePanel !== 'verified'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let type of [true, null]"
            [class.selected]="isVerifiedSelected(type)"
            (click)="toggleVerified(type)"
          >
            <span *ngIf="type === true">Verified</span>
            <span *ngIf="type === null">Unverified</span>
            <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Priority -->
      <div class="card-body" [hidden]="visiblePanel !== 'priority'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let priority of priorityList | iterable"
            [class.selected]="isPrioritySelected(priority.key)"
            (click)="togglePriority(priority.key)"
          >
            {{ priority.value }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <!-- Video compiler comment -->
      <div class="card-body" [hidden]="visiblePanel !== 'videoCompilerComment'">
        <ul class="list-group">
          <li
            class="list-group-item justify-content-between"
            *ngFor="let comment of videoCompilerComment"
            [class.selected]="isVideosCompilerCommmentSelected(comment.key)"
            (click)="toggleCompilerComment(comment.key)"
          >
            {{ comment.value }} <i class="fa fa-minus" aria-hidden="true"></i>
          </li>
        </ul>
      </div>

      <div class="card-footer">
        <p *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</p>

        <button class="btn btn-outline-primary btn-sm pull-left" [disabled]="!!errorMessage" (click)="doApply()">
          Apply
        </button>
        <button class="btn btn-outline-secondary btn-sm pull-right" (click)="doCancel()">Cancel</button>
      </div>
    </div>
  </div>
</ng-template>
