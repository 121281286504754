import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ellipsis' })
export class EllipsisPipe implements PipeTransform {
  transform(val: string, args: number): string {
    if (!args) {
      return val;
    }

    return val.length > args ? val.substring(0, args) + '...' : val;
  }
}
