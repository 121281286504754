import { NgModule } from '@angular/core';

import { SharedModule } from '../shared/shared.module';

import { PaymentNonusAPI } from './shared/payment-nonus-api.service';

@NgModule({
  imports: [SharedModule],
  declarations: [],
  providers: [PaymentNonusAPI],
})
export class PaymentNonusModule {}
