import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { GET, POST, Body, Query, MediaType, Produces } from '../rest/rest.service';
import { APIService } from '../api/api.service';
import { Value } from './value';
import { ValueType } from '../../enum/value-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ValueAPI extends APIService {
  @GET('values/')
  @Produces(MediaType.JSON)
  public find(
    @Query('type') type?: ValueType,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: object
  ): Observable<Value[]> {
    return;
  }

  @GET('values/')
  @Produces(MediaType.JSON)
  public findByFilter(@Query('type') type?: ValueType, @Query('filter') filter?: object): Observable<Value[]> {
    return;
  }

  public findByCode(type: ValueType, code: string): Observable<Value> {
    return this.findByFilter(type, { code }).pipe(map((vals: Value[]) => (vals || [])[0]));
  }

  @GET('values/search')
  @Produces(MediaType.JSON)
  public search(@Query('type') type: ValueType, @Query('term') term: string): Observable<Value[]> {
    return;
  }

  @GET('values/domain-compliance')
  @Produces(MediaType.JSON)
  public getURLCompliance(
    @Query('domain') domain?: string,
    @Query('type') type?: string
  ): Observable<{ valid: boolean }> {
    return;
  }

  @GET('values/check-exists')
  @Produces(MediaType.JSON)
  public checkExists(@Query('type') type: string, @Query('code') code: string): Observable<{ exists: boolean }> {
    return;
  }

  @POST('values/suggest')
  @Produces(MediaType.JSON)
  public suggest(@Body value: Value): Observable<Value> {
    return;
  }
}
