import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';

import { AccountPayerInformation, AccountPayerShareInformation } from '../shared/account-payer-infomation';
import { AccountPayerInformationAPI } from '../shared/account-payer-information-api.service';
import { ACL } from '../../shared/acl/acl.service';

@Component({
  selector: 'dirt-account-payer-information-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class AccountPayerInformationListComponent implements OnInit {
  @Input()
  accountId: string;

  accountPayerInformation: AccountPayerInformation[];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 30;
  pagingSkip = 0;

  private sort = '-year';

  constructor(
    private readonly router: Router,
    private readonly svcAccountPayerInformation: AccountPayerInformationAPI,
    private readonly svcACL: ACL
  ) {}

  ngOnInit(): void {
    this.doLoad();
  }

  /** just avoid useless rendering if we can */
  trackById(index: number, account: AccountPayerInformation): string {
    return account.id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getAccountsPayerInformation();
  }

  onSort(field: string): void {
    this.sort = field;

    this.getAccountsPayerInformation();
  }

  onDelete(id: string): void {
    if (!this.svcACL.hasCredential('account-payer-information.delete')) {
      return;
    }

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.svcAccountPayerInformation.deleteById(id).subscribe(() => {
      this.getAccountsPayerInformation();
      this.getAccountsPayerInformationCount();
    });
  }

  navigateTo(id: string): void {
    if (!id) {
      return;
    }

    this.router.navigate(['/account-payer-information/detail', id]);
  }

  private doLoad(): void {
    this.resetPagination();
    this.getAccountsPayerInformation();
    this.getAccountsPayerInformationCount();
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getAccountsPayerInformation(): void {
    this.isLoading = true;

    this.svcAccountPayerInformation
      .find(this.pagingLimit, this.pagingSkip, this.sort, this.accountId)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((accountPayerInformation) => {
        this.accountPayerInformation = accountPayerInformation;
      });
  }

  private getAccountsPayerInformationCount(): void {
    this.svcAccountPayerInformation.count(this.accountId).subscribe((count) => {
      this.total = count;
    });
  }

  getStandarizedHealthPayerName(payerShares: AccountPayerShareInformation[]) {
    return payerShares.map((payer) => payer.standardizedPayerName).join(', ');
  }
}
