<section class="dirt-profile-qc-next">
  <div class="next-content">
    <ng-container *ngIf="!isLoadingQCStats; else loader">
      <ul class="list-group" *ngIf="QCStats">
        <p>Overall Statistics</p>

        <!-- Region -->
        <li class="list-group-item d-flex justify-content-between align-items-center row">
          <span class="col-md-8">Your region</span>
          <span class="col-md-4">
            <h5>
              <span class="badge badge-secondary">{{ getStatusText(QCStats.region) }}</span>
            </h5>
          </span>
        </li>

        <!-- QC -->
        <li class="list-group-item d-flex justify-content-between align-items-center row">
          <span class="col-md-8">You individually</span>
          <span class="col-md-4">
            <h5>
              <span class="badge badge-secondary">{{ getStatusText(QCStats.personal) }}</span>
            </h5>
          </span>
        </li>
      </ul>
    </ng-container>

    <!--Loading Indicator-->
    <ng-template #loader>
      <p>
        <i class="fa fa-refresh fa-spin fa-fw"></i>
        Loading your statistics...
      </p>
    </ng-template>
  </div>

  <div class="next-action">
    <button type="button" class="btn btn-success" (click)="getNext()" [disabled]="isLoadingNext">
      <i class="fa fa-refresh fa-spin" [hidden]="!isLoadingNext"></i>
      Next Profile
    </button>
  </div>
</section>
