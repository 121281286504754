import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Component({
  selector: 'dirt-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnDestroy {
  @Input() id: string;

  /** How many entries are displayed */
  @Input() pagingSize: number;

  /** How many entries should be skipped */
  @Input() pagingSkip: number;

  /** Max entries displayed per page */
  @Input() pagingLimit: number;

  /** Max number of entries */
  @Input() pagingTotal: number;

  @Input() isHidden: boolean;

  @Input() couldHaveMore: boolean;

  /** Emitted when user click to change page, will emit the page number */
  @Output() pageChange = new EventEmitter<number>();

  ngOnDestroy(): void {
    this.pageChange.complete();
  }

  onPageChange(page: number): void {
    this.pageChange.next(page);
  }
}
