import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';
import { CommitteeAPI } from '../../committee-api.service';
import { Committee } from '../../committee';

@Component({
  selector: 'dirt-committee-single-select',
  templateUrl: 'committee-single-select.html',
  styleUrls: ['committee-single-select.scss'],
})
export class CommitteeSingleSelectComponent {
  @Output()
  committeeSelected: EventEmitter<Committee> = new EventEmitter();
  @Input()
  wide: boolean = false;

  isSearching = false;
  searchTerm: string;

  searchCommittees = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) =>
        this.svcCommittee.find(term as any).pipe(
          take(1),
          catchError(() => {
            return of([]);
          })
        )
      ),
      tap(() => (this.isSearching = false))
    );

  constructor(private readonly svcCommittee: CommitteeAPI) {}

  onChangeCommittee(committee) {
    this.committeeSelected.emit(committee);
    setTimeout(() => (this.searchTerm = null));
  }
}
