export class EventAdvancedSearchDto {
  filter?: {
    name?: string;
    originalName?: string;
    organizer?: string;
    startDate?: { start?: Date; end?: Date };
  };
  sort?: string;
  sortDesc?: boolean;
  page?: number;
  size?: number;
}
