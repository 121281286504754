<span [ngClass]="{wide: wide}">
  <input
    type="search"
    class="di-search form-control ng-trim-ignore"
    [ngClass]="{ loading: isSearching }"
    [ngbTypeahead]="searchAffiliations"
    [focusFirst]="false"
    [resultTemplate]="searchResultTemplate"
    (selectItem)="onChangeSite($event.item)"
    [(ngModel)]="searchTerm"
    [disabled]="disabled"
    [placeholder]="determineSiteCaption(siteId)"
  />
  <div *ngIf="!disabled">
    <a
      *ngIf="(!!siteId) && (existSites[siteId] || addSites[siteId])"
      target="_blank"
      [routerLink]="['/affiliation/detail', (existSites[siteId] || addSites[siteId])?.affiliation]"
      title="open in new tab"
      style="float: right"
      ><i class="fa fa-external-link"></i
    ></a>
    <a
      *ngIf="!!siteId"
      class="alink"
      style="float: right; margin-right: 10px"
      title="clear selected"
      (click)="onClearSite()"
      ><i class="fa fa-eraser"></i
    ></a>
    <a class="alink" (click)="showAffiliationModal()">Request Site</a>
  </div>
  <ng-container *ngIf="!!siteId">
    <br />
    <span>Affiliation ID: {{ determineAffiliationId(siteId) }}</span>
  </ng-container>
</span>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <ng-container>
    <div class="di-sac-verified pull-left">
      <dirt-verified-icon color="success" *ngIf="r?.verified"></dirt-verified-icon>
    </div>
    <div class="di-sac-affiliation pull-left">
      <div class="di-sac-name">{{ r?.name }}</div>
      <div class="di-sac-department">{{ r?.department || '&mdash;' }}</div>
      <div class="di-sac-address">{{ r?.address?.formatted || '&mdash;' }}</div>
    </div>
    <div class="di-sac-link pull-right">
      <a class="alink" (click)="gotoAffiliation($event, r.id)"><i class="fa fa-external-link"></i></a>
    </div>
  </ng-container>
</ng-template>
