<section class="dirt-section-list dirt-project-tags-list clearfix">
  <header class="row">
    <div class="col-sm-4">
      <h2>
        <span *ngIf="currentList && currentList.length">{{ currentList.length | number }}</span> Tags
      </h2>
      <div>
        <label
          ><input type="radio" [checked]="'person-project' === shownType" (input)="onShowType('person-project')" />
          <span ngbTooltip="Project (for Person/Profile)">Person</span></label
        >
        <label
          ><input type="radio" [checked]="'project' === shownType" (input)="onShowType('project')" />
          <span ngbTooltip="Project (for Association/Event)">Activity</span></label
        >
      </div>
    </div>
    <div class="col-sm-4">
      <div class="input-group row">
        <input
          type="search"
          class="form-control"
          [formControl]="searchCtrl"
          [(ngModel)]="searchTerm"
          placeholder="Search..."
        />
      </div>
    </div>
    <div class="col-sm-4">
      <button
        class="btn btn-success btn-sm pull-right"
        (click)="onCreateClicked($event)"
        *ngIf="canCreate()"
        [disabled]="newTag || editId"
      >
        <i class="fa fa-plus" aria-hidden="true"></i>&nbsp; Create Tag
      </button>
    </div>
  </header>

  <div *ngIf="newTag && canCreate()" class="dirt-project-tags-form">
    <dirt-project-tags-form
      [tag]="newTag"
      [isSubmitting]="isSubmitting"
      (onSubmitted)="onSubmit($event)"
      (onCancel)="onCancelClick()"
    >
    </dirt-project-tags-form>
  </div>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover">
      <thead>
        <tr>
          <th class="col-title">Title</th>
          <th class="col-code">Code</th>
          <th class="col-product">Product</th>
          <th class="col-flag col-active" [ngStyle]="{ visibility: 'person-project' === shownType ? '' : 'hidden' }">
            Active
          </th>
          <th class="col-flag col-delivery" [ngStyle]="{ visibility: 'person-project' === shownType ? '' : 'hidden' }">
            Delivery
          </th>
          <th class="col-flag col-technical" [ngStyle]="{ visibility: 'person-project' === shownType ? '' : 'hidden' }">
            Technical
          </th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="dirt-project-tags-row"
          *ngFor="let tag of currentList"
          [ngClass]="{ 'dirt-selected-item': tag._id === editId }"
        >
          <ng-container *ngIf="editId !== tag._id">
            <td class="col-title">{{ tag.title }}</td>
            <td class="col-code">{{ tag.code }}</td>
            <td class="col-product">{{ tag.product }}</td>
            <th class="col-flag col-active" title="Active">
              <i
                [ngClass]="{ fa: true, 'fa-check': 'person-project' === shownType && tag.active }"
                aria-hidden="true"
              ></i>
            </th>
            <th class="col-flag col-delivery" title="Delivery">
              <i
                [ngClass]="{ fa: true, 'fa-check': 'person-project' === shownType && tag.delivery }"
                aria-hidden="true"
              ></i>
            </th>
            <th class="col-flag col-technical" title="Technical">
              <i
                [ngClass]="{ fa: true, 'fa-check': 'person-project' === shownType && tag.technical }"
                aria-hidden="true"
              ></i>
            </th>
            <td class="col-actions">
              <!-- Edit -->
              <span class="action-btn action-edit" (click)="onEditClicked(tag._id, $event)" *ngIf="canEdit()">
                <i class="fa fa-pencil" aria-hidden="true"></i>
              </span>
              <!-- Delete -->
              <span class="action-btn action-delete" (click)="onDeleteClick(tag._id, $event)" *ngIf="canDelete()">
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </span>
            </td>
          </ng-container>
          <ng-container *ngIf="editId === tag._id">
            <td colspan="11" class="col-edit">
              <dirt-project-tags-form
                [tag]="tag"
                [isSubmitting]="isSubmitting"
                (onSubmitted)="onEdit($event)"
                (onCancel)="onCancelEditClick()"
              >
              </dirt-project-tags-form>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <p class="dirt-no-results" *ngIf="!currentList.length">No Tags</p>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
