import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';
import { forkJoin, Subject, Subscription } from 'rxjs';

import { PersonAPI } from '../../../person/shared/api.service';
import { VideoAPI } from '../../../video/shared/video-api.service';
import { Person } from '../../../person/shared/person';

@Component({
  selector: 'dirt-linked-people',
  templateUrl: 'video-linked-people.component.html',
  styleUrls: ['video-linked-people.component.scss'],
})
export class VideoLinkedPeopleComponent implements OnInit, OnDestroy {
  @Input()
  videoId: string;

  people: Person[] = [];
  profiles: any[] = [];

  isLoading: boolean;
  isLoadingStarted: boolean;

  reloadSubscript: Subscription;
  @Input() reload?: Subject<boolean>;

  @Input()
  showRemove: boolean = false;
  @Output()
  onRemove = new EventEmitter<{ type: 'PERSON' | 'PROFILE'; personProfile }>();

  @Input() showLastNamesOnly?: boolean;

  constructor(private readonly svcPerson: PersonAPI, private readonly svcVideo: VideoAPI) {}

  ngOnInit() {
    this.findAttachedPeople();
    this.subscribeReload();
  }

  subscribeReload() {
    if (!this.reload) {
      return;
    }
    this.reloadSubscript = this.reload.subscribe(() => this.findAttachedPeople());
  }

  private async findAttachedPeople(): Promise<void> {
    if (!this.videoId) {
      return;
    }
    this.isLoading = true;

    forkJoin([
      this.svcVideo.findProfilesByVideoId(this.videoId),
      this.svcPerson.getVideoIdentified(this.videoId),
    ]).subscribe(
      ([linkedProfiles, identifiedPeople]) => {
        this.profiles = linkedProfiles;
        this.people = identifiedPeople;
        this.isLoading = false;
      },
      () => alert('Error!')
    );

    this.isLoadingStarted = true;
  }

  getLastNames() {
    const profiles = this.profiles.map((match) => match?.profileId?.person?.lastName);
    const persons = this.people.map((people) => people?.lastName);
    return profiles.concat(persons).join(',');
  }

  ngOnDestroy() {
    if (this.reloadSubscript) {
      this.reloadSubscript.unsubscribe();
    }
  }
}
