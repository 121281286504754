import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { ColDef, ColGroupDef, RowNode } from 'ag-grid';
import { ValueFormatterParams } from 'ag-grid/dist/lib/entities/colDef';

import { ACL } from '../../../../shared/acl/acl.service';
import { ProfileGridComponent } from '../../../shared/grid/grid.component';
import { ProfileDirectLinkService } from '../../../shared/modal/direct-link/direct-link.service';
import { ProfileDirectLinkOptions } from '../../../shared/modal/direct-link/direct-link-options.interface';
import { GuidelineAPI } from '../../../../guideline/shared/guideline-api.service';
import { Guideline } from '../../../../guideline/shared/guideline';
import { ProfileGuidelineAPI } from '../shared/api.service';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { QCCommentService } from '../../../shared/grid/qc-comment/qc-comment.service';
import { ProfileGuideline } from '../../../shared/profile-guideline';

@Component({
  selector: 'dirt-profile-guideline-list',
  templateUrl: '../../../shared/grid/grid.component.html',
  styleUrls: ['../../../shared/grid/grid.component.scss'],
})
export class ProfileGuidelineListComponent extends ProfileGridComponent implements OnInit {
  FIELD_AUTHOR_ID = 'authorId';
  FIELD_LINK = 'link';
  FIELD_FIRST_NAME = 'author.firstName';
  FIELD_LAST_NAME = 'author.lastName';
  FIELD_POSITION = 'author.position';
  FIELD_CITY = 'guideline.address.city';
  FIELD_COUNTRY = 'guideline.address.country';
  FIELD_INSTITUTION_NAME = 'author.institution';
  FIELD_GUIDELINE_TITLE = 'guideline.title';
  FIELD_JOURNAL_TITLE = 'guideline.journalTitle';
  FIELD_JOURNAL_COUNTRY = 'guideline.journalCountry';
  FIELD_PUBLICATION_YEAR = 'guideline.publicationYear';

  constructor(
    protected svcProfileGuideline: ProfileGuidelineAPI,
    private svcGuideline: GuidelineAPI,
    private svcDirectLink: ProfileDirectLinkService,
    protected readonly svcACL: ACL,
    protected svcQCComment: QCCommentService
  ) {
    super('id', svcACL, svcQCComment);
  }

  ngOnInit() {
    this.addFilter(this.FIELD_FIRST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_FIRST_NAME, params)
    );
    this.addFilter(this.FIELD_LAST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_LAST_NAME, params)
    );
    this.addFilter(this.FIELD_POSITION, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_POSITION, params)
    );
    this.addFilter(this.FIELD_JOURNAL_COUNTRY, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_JOURNAL_COUNTRY, params)
    );
    this.addFilter(this.FIELD_COUNTRY, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_COUNTRY, params)
    );
    this.addFilter(this.FIELD_CITY, true, 'keep', true, (params) => this.getDistinctValues(this.FIELD_CITY, params));
    this.addFilter(this.FIELD_PUBLICATION_YEAR, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_PUBLICATION_YEAR, params)
    );
    this.addFilter(this.FIELD_KEY_FA, true, 'keep', false, (params) =>
      this.defineGetFocusArea(this.profileId).subscribe((result) =>
        result && result[0] && result[0].focusArea
          ? params.success(result[0].focusArea?.map((val) => val.name))
          : params.success('')
      )
    );
    super.ngOnInit();
  }

  protected defineGetDataRows(...params): Observable<any> {
    return this.svcProfileGuideline.find.apply(this.svcProfileGuideline, [this.profile, ...params.slice(1)]); // migrating profileId to profile
  }

  protected defineDistinct(profileId: string, field: string, filter: any): Observable<string[]> {
    return this.svcProfileGuideline.distinct(this.profile, field, filter);
  }

  protected defineUpdate(
    profileId: string,
    ids: string[],
    value: ProfileGuideline[]
  ): Observable<{ id; compiledAt? }[]> {
    return this.svcProfileGuideline.update(this.profile, value);
  }

  protected defineGetFocusArea(profileId: string): Observable<any> {
    return this.svcProfileGuideline.distinctFocusArea(this.profile);
  }

  protected defineGrid(): (ColDef | ColGroupDef)[] {
    const columnDefs: (ColDef | ColGroupDef)[] = [
      {
        headerName: 'Author',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'First Name',
            field: this.FIELD_FIRST_NAME,
            filterParams: this.filters.get(this.FIELD_FIRST_NAME),
            width: 100,
          },
          {
            headerName: 'Last Name',
            field: this.FIELD_LAST_NAME,
            filterParams: this.filters.get(this.FIELD_LAST_NAME),
            width: 100,
          },
          {
            headerName: 'Position',
            field: this.FIELD_POSITION,
            filterParams: this.filters.get(this.FIELD_POSITION),
            columnGroupShow: 'open',
            width: 80,
          },
          {
            headerName: 'City',
            field: this.FIELD_CITY,
            filterParams: this.filters.get(this.FIELD_CITY),
            columnGroupShow: 'open',
            width: 70,
          },
          {
            headerName: 'Country',
            field: this.FIELD_COUNTRY,
            filterParams: this.filters.get(this.FIELD_COUNTRY),
            columnGroupShow: 'open',
            width: 70,
          },
        ],
      },
      {
        headerName: 'Author ID',
        field: this.FIELD_AUTHOR_ID,
        width: 100,
        filter: 'text',
      },
      {
        headerName: 'Institution',
        field: this.FIELD_INSTITUTION_NAME,
        filter: 'text',
        width: 200,
      },
      {
        headerName: 'Link',
        cellRenderer: this.rendererLinks,
        field: this.FIELD_LINK,
        suppressMenu: true,
        width: 40,
      },
      {
        headerName: 'Guideline',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'Title',
            field: this.FIELD_GUIDELINE_TITLE,
            filter: 'text',
            width: 300,
          },
          {
            headerName: 'Journal Title',
            field: this.FIELD_JOURNAL_TITLE,
            filter: 'text',
            width: 230,
          },
          {
            headerName: 'Journal Country',
            field: this.FIELD_JOURNAL_COUNTRY,
            filterParams: this.filters.get(this.FIELD_JOURNAL_COUNTRY),
            width: 80,
          },
          {
            headerName: 'Date',
            field: this.FIELD_PUBLICATION_YEAR,
            filterParams: this.filters.get(this.FIELD_PUBLICATION_YEAR),
            columnGroupShow: 'open',
            width: 50,
          },
        ],
      },
      {
        headerName: 'Focus Areas',
        field: this.FIELD_KEY_FA,
        filterParams: this.filters.get(this.FIELD_KEY_FA),
        width: 500,
      },
    ];
    return columnDefs;
  }

  protected defineAutoSizableColumns(): string[] {
    return [];
  }

  protected onDirectLink(): void {
    let authorId;

    const options = <ProfileDirectLinkOptions>{
      title: 'Guideline',
      getEntity: (id) => {
        authorId = id;

        return this.svcGuideline.find(null, 1, 0, null, { 'authors.authorId': id }).pipe(flatMap((x) => x));
      },
      getData: (g: Guideline) => ({ name: g.title }),
    };

    this.svcDirectLink
      .open(options)
      .then((entity: Guideline) => this.svcProfileGuideline.link(this.profile, authorId).toPromise())
      .then(() => this.reloadData());
  }

  /**
   * Renderer function for Links columns.
   *
   * @param {ValueFormatterParams} param
   * @return {string}
   */
  private rendererLinks(param: ValueFormatterParams): string {
    if (!param.data) {
      return '';
    }
    const webSource = param.data.link;
    const linkWS = webSource ? `<a href='${webSource}' rel=noopener target='_blank'>W</a>` : '';
    return `${linkWS}`;
  }

  /** @override */
  updateCommentAndProfileMatch(comment: string, matched: boolean, errorType: string): void {
    const nodes = this.getSelection();
    const match = matched ? ProfileMatchManual.MATCH : ProfileMatchManual.MISMATCH;

    nodes.forEach((node: RowNode) => {
      const data = Object.assign({}, node.data); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables

      data._profile.match.manual = match;

      // even if comment and errorType are null we should set qc so that thebug icon disappears
      data._profile.qc = { comment, errorType };

      node.setData(data);
    });

    this.update(
      nodes,
      nodes.map(
        (n) =>
          ({
            authorId: n.data.authorId,
            match: n.data._profile.match,
            person: { id: this.profile.person.id, kolId: this.profile.person.kolId },
            profileId: this.profileId,
            qc: n.data._profile.qc,
          } as Partial<ProfileGuideline>)
      )
    );
  }
}
