import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'dirt-count-down-timer',
  templateUrl: './count-down-timer.component.html',
  styleUrls: ['./count-down-timer.component.scss'],
})
export class CountDownTimerComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  date: Date | string;

  @Input()
  labels = false;

  dateParts = [];

  private _updateTimeInterval: any;

  constructor() {}

  /**
   *
   * @param {number} duration
   */
  updateDateParts(duration: number) {
    this.dateParts = [
      {
        label: 'D',
        value: Math.floor(duration / (1000 * 60 * 60 * 24)),
      },
      {
        label: 'H',
        value: Math.floor((duration / (1000 * 60 * 60)) % 24),
      },
      {
        label: 'M',
        value: Math.floor((duration / 1000 / 60) % 60),
      },
      {
        label: 'S',
        value: Math.floor((duration / 1000) % 60),
      },
    ];
  }

  private clearTimer() {
    clearInterval(this._updateTimeInterval);
    this._updateTimeInterval = null;
  }

  updateRemainingTime = () => {
    if (typeof this.date === 'string') {
      this.date = new Date(this.date);
    }

    const now = new Date();
    let duration = this.date.getTime() - now.getTime();
    duration = duration > 0 ? duration : 0;

    this.updateDateParts(duration);

    if (!duration) {
      this.clearTimer();
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    const date = changes.date.currentValue;
    if (!date) {
      this.clearTimer();
      this.updateDateParts(0);
      return;
    }

    if (!this._updateTimeInterval) {
      this._updateTimeInterval = setInterval(this.updateRemainingTime, 1000);
    }
  }

  ngOnInit() {
    this.updateDateParts(0);
  }

  ngOnDestroy() {
    this.clearTimer();
  }
}
