<form #internalForm="ngForm" novalidate>
  <div class="modal-header">
    <h5 class="modal-title">New Organization</h5>
    <button type="button" class="close" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- Name -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Name</label>
      <div class="col-sm-8 col-md-10">
        <input type="text" class="form-control" name="name" [(ngModel)]="name" (keyup)="resetState()" required />
      </div>
    </div>

    <!-- Websource -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Websource</label>
      <div class="col-sm-8 col-md-10">
        <dirt-link
          [(url)]="websource"
          name="websource"
          [form]="internalForm"
          (urlChange)="resetState()"
          required
        ></dirt-link>
      </div>
    </div>

    <!-- Priority (for root) -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" name="priority">Priority</label>
      <div class="col-sm-8 col-md-10">
        <ngb-rating name="priority" [(rate)]="priority" max="5">
          <ng-template let-fill="fill" let-index="index">
            <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
          </ng-template>
        </ngb-rating>
      </div>
    </div>

    <div class="pull-right" style="margin: 12px 0">
      <button class="btn btn-sm btn-secondary" [disabled]="isLoading" (click)="onClose()">Cancel</button>
      <button
        class="btn btn-sm btn-primary"
        *ngIf="state.type !== 'fuzzy-duplicate'"
        [disabled]="isLoading || internalForm.invalid || state.type === 'exact-duplicate'"
        (click)="onCreate(false)"
      >
        Create
      </button>
      <button
        class="btn btn-sm btn-primary"
        *ngIf="state.type === 'fuzzy-duplicate'"
        [disabled]="isLoading || internalForm.invalid"
        (click)="onCreate(true)"
      >
        Create Anyway
      </button>
    </div>

    <div *ngIf="state.type === 'exact-duplicate'">
      <h4>Found duplicates:</h4>
      <ul>
        <li *ngFor="let duplicate of state.duplicates">
          <a href="/organization/detail/{{ duplicate._id }}" target="_blank">{{ duplicate.name }}</a>
          <span *ngIf="duplicate.parents.length > 0">
            - {{ duplicate.parents.length === 1 ? 'Parent' : 'Parents' }}:
            <a
              *ngFor="let duplicateParent of duplicate.parents"
              href="/organization/detail/{{ duplicateParent }}"
              target="_blank"
              >{{ duplicateParent }}</a
            >
          </span>
        </li>
      </ul>
    </div>

    <div *ngIf="state.type === 'fuzzy-duplicate'">
      <h4>Found potential duplicates:</h4>
      <ul>
        <li *ngFor="let duplicate of state.duplicates">
          <a href="/organization/detail/{{ duplicate._id }}" target="_blank">{{ duplicate.name }}</a>
        </li>
      </ul>
    </div>
  </div>
</form>
