import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ColDef } from 'ag-grid/main';
import { ColGroupDef } from 'ag-grid';
import { ValueFormatterParams } from 'ag-grid/dist/lib/entities/colDef';

import { ACL } from '../../../../shared/acl/acl.service';
import { Profile } from '../../../shared/profile';
import { ProfileGridComponent } from '../../../shared/grid/grid.component';
import { ProfileDirectLinkService } from '../../../shared/modal/direct-link/direct-link.service';
import { ProfileDirectLinkOptions } from '../../../shared/modal/direct-link/direct-link-options.interface';
import { PaymentNonus } from '../../../../payment-nonus/shared/payment-nonus';
import { PaymentNonusAPI } from '../../../../payment-nonus/shared/payment-nonus-api.service';
import { ProfilePaymentNonusAPI } from '../shared/api.service';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { QCCommentService } from '../../../shared/grid/qc-comment/qc-comment.service';

@Component({
  selector: 'dirt-profile-payment-nonus-list',
  templateUrl: '../../../shared/grid/grid.component.html',
  styleUrls: ['../../../shared/grid/grid.component.scss'],
})
export class ProfilePaymentNonusListComponent extends ProfileGridComponent implements OnInit {
  FIELD_UNIQUE_IDENTIFIER = 'uniqueIdentifier';
  FIELD_FIRST_NAME = 'person.firstName';
  FIELD_MIDDLE_NAME = 'person.middleName';
  FIELD_LAST_NAME = 'person.lastName';
  FIELD_AFFILIATION = 'affiliation';
  FIELD_STREET = 'address.street';
  FIELD_CITY = 'address.city';
  FIELD_ZIP = 'address.zip';
  FIELD_COUNTRY = 'address.country';

  FIELD_FILTER_FIRST_NAME = 'firstName';
  FIELD_FILTER_MIDDLE_NAME = 'middleName';
  FIELD_FILTER_LAST_NAME = 'lastName';
  FIELD_FILTER_AFFILIATION = 'affiliation';
  FIELD_FILTER_STREET = 'street';
  FIELD_FILTER_CITY = 'city';
  FIELD_FILTER_ZIP = 'zip';
  FIELD_FILTER_COUNTRY = 'country';

  constructor(
    protected svcProfilePaymentNonus: ProfilePaymentNonusAPI,
    private svcPaymentNonus: PaymentNonusAPI,
    private svcDirectLink: ProfileDirectLinkService,
    protected readonly svcACL: ACL,
    protected svcQCComment: QCCommentService
  ) {
    super('id', svcACL, svcQCComment);
  }

  ngOnInit() {
    this.addFilter(this.FIELD_FIRST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_FIRST_NAME, params)
    );
    this.addFilter(this.FIELD_MIDDLE_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_MIDDLE_NAME, params)
    );
    this.addFilter(this.FIELD_LAST_NAME, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_LAST_NAME, params)
    );
    this.addFilter(this.FIELD_AFFILIATION, true, 'keep', false, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_AFFILIATION, params)
    );
    this.addFilter(this.FIELD_STREET, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_STREET, params)
    );
    this.addFilter(this.FIELD_CITY, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_CITY, params)
    );
    this.addFilter(this.FIELD_ZIP, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_ZIP, params)
    );
    this.addFilter(this.FIELD_COUNTRY, true, 'keep', true, (params) =>
      this.getDistinctValues(this.FIELD_FILTER_COUNTRY, params)
    );
    super.ngOnInit();
  }

  protected defineGetDataRows(...params): Observable<any> {
    return this.svcProfilePaymentNonus.find.apply(this.svcProfilePaymentNonus, params);
  }

  protected defineDistinct(profileId: string, field: string, filter: any): Observable<string[]> {
    return this.svcProfilePaymentNonus.distinct(profileId, field, filter);
  }

  protected defineUpdate(
    profileId: string,
    ids: string[],
    value: { match: ProfileMatchManual; comment?: string; errorType: string }
  ): Observable<{ id; compiledAt? }[]> {
    return this.svcProfilePaymentNonus.update(profileId, {
      paymentIds: ids,
      manual: value.match,
      comment: value.comment,
      errorType: value.errorType,
    });
  }

  protected defineGetFocusArea(profileId: string): Observable<any> {
    return /*Observable*/ of([]);
  }

  protected defineGrid(): (ColDef | ColGroupDef)[] {
    const columnDefs: (ColDef | ColGroupDef)[] = [
      {
        headerName: 'Profile',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'UID',
            field: this.FIELD_UNIQUE_IDENTIFIER,
            width: 70,
          },
          {
            headerName: 'First Name',
            field: this.FIELD_FIRST_NAME,
            valueFormatter: this.formatterCase,
            filterParams: this.filters.get(this.FIELD_FIRST_NAME),
            width: 100,
          },
          {
            headerName: 'Middle Name',
            field: this.FIELD_MIDDLE_NAME,
            valueFormatter: this.formatterCase,
            filterParams: this.filters.get(this.FIELD_MIDDLE_NAME),
            width: 90,
          },
          {
            headerName: 'Last Name',
            field: this.FIELD_LAST_NAME,
            valueFormatter: this.formatterCase,
            filterParams: this.filters.get(this.FIELD_LAST_NAME),
            width: 100,
          },
        ],
      },
      {
        headerName: 'Affiliation',
        field: this.FIELD_AFFILIATION,
        valueFormatter: this.formatterCase,
        filterParams: this.filters.get(this.FIELD_AFFILIATION),
        width: 200,
      },
      {
        headerName: 'Address',
        headerClass: 'text-center',
        children: [
          {
            headerName: 'Street',
            field: this.FIELD_STREET,
            valueFormatter: this.formatterCase,
            filterParams: this.filters.get(this.FIELD_STREET),
            width: 150,
          },
          {
            headerName: 'City',
            field: this.FIELD_CITY,
            valueFormatter: this.formatterCase,
            filterParams: this.filters.get(this.FIELD_CITY),
            width: 70,
          },
          {
            headerName: 'Zip',
            field: this.FIELD_ZIP,
            filterParams: this.filters.get(this.FIELD_ZIP),
            width: 70,
          },
          {
            headerName: 'Country',
            field: this.FIELD_COUNTRY,
            valueFormatter: this.formatterCase,
            filterParams: this.filters.get(this.FIELD_COUNTRY),
            width: 100,
          },
        ],
      },
    ];
    return columnDefs;
  }

  protected defineAutoSizableColumns(): string[] {
    return [];
  }

  protected onDirectLink(): void {
    const options = <ProfileDirectLinkOptions>{
      title: 'Payment',
      getEntity: (id) => this.svcPaymentNonus.findById(id),
      getData: (p: PaymentNonus) => ({ name: [p.lastName, p.firstName].join(', ') }),
    };

    this.svcDirectLink
      .open(options)
      .then((entity: PaymentNonus) => this.svcProfilePaymentNonus.link(this.profileId, entity._id).toPromise())
      .then(() => this.reloadData());
  }

  private formatterCase(param: ValueFormatterParams): string {
    if (!param.value) {
      return;
    }
    const array = param.value.split(' ').filter((el) => el.length !== 0);
    let str = '';
    for (let i = 0; i < array.length; i++) {
      str += array[i].length > 1 ? array[i].charAt(0) + array[i].slice(1).toLowerCase() : array[i].toUpperCase();
      if (i < array.length - 1) {
        str += ' ';
      }
    }
    return str;
  }
}
