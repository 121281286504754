import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { concatMap, take, tap } from 'rxjs';

import { Job } from '../shared/job';
import { JobsAPI } from '../shared/api.service';
import { User } from '../../user/shared/user';
import { UserPoolAPI } from '../../user-pool/shared/api.service';
import { JobStatus } from '../shared/constant/status.enum';

@Component({
  selector: 'dirt-job-detail',
  templateUrl: './detail.component.html',
})
export class JobDetailComponent implements OnInit {
  id: string;

  job: Job;

  isLoading = true;
  isSubmitting = false;

  compiler: User;

  canReset = false;

  constructor(
    private readonly svcJob: JobsAPI,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly svcUserPool: UserPoolAPI
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        take(1),
        concatMap((params) => {
          this.id = params['id'];

          return this.svcJob.findById(this.id).pipe(tap(() => (this.isLoading = false)));
        })
      )
      .subscribe((job) => {
        this.job = job;
        this.canReset =
          job._meta.status === JobStatus.READY_FOR_COMPILATION ||
          job._meta.status === JobStatus.COMPILATION_IN_PROGRESS;
        this.svcUserPool.findById(job.poolId).subscribe((pool) => {
          this.job.poolId = pool as any;
          this.job = { ...this.job }; // break ref to force re-render in form
        });
      });
  }

  onSave(): void {
    const job = Object.assign({}, this.job);

    job.poolId = typeof job.poolId !== 'string' ? (job.poolId as any).id : job.poolId;

    this.isSubmitting = true;

    this.svcJob.update(job._id, job).subscribe({
      next: () => this.goBack(),
      complete: () => (this.isSubmitting = false),
    });
  }

  onReset(): void {
    const job = Object.assign({}, this.job);

    job.poolId = typeof job.poolId !== 'string' ? (job.poolId as any).id : job.poolId;

    this.isSubmitting = true;

    this.svcJob.reset(job._id).subscribe({
      next: () => {
        alert('Job is reset successfully!');
        this.reloadPage();
      },
      complete: () => (this.isSubmitting = false),
    });
  }

  goBack(): void {
    this.router.navigate(['/job/list']);
  }

  reloadPage(): void {
    window.location.reload();
  }
}
