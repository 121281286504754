import { Component, OnInit } from '@angular/core';
import { Auth } from '../../shared/services/auth/auth.service';

@Component({
  selector: 'dirt-user-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.scss'],
})
export class UserLoginComponent implements OnInit {
  login: any = {};
  invalidLogin: boolean;
  isSubmitting: boolean;

  constructor(private auth: Auth) {}

  ngOnInit() {
    this.auth.authorize();
  }

  /**
   * @deprecated
   * Since moving to auth0 universal login page, all login functionality
   * is handed over to auth0.
   *
   * @param data - login email and password
   */
  onSubmit(data): void {
    this.invalidLogin = false;
    this.isSubmitting = true;

    this.auth.login(data.email, data.password, (error) => {
      this.isSubmitting = false;

      if (error.status === 401) {
        this.invalidLogin = true;
      } else {
        alert('An error happened! ' + error.message);
      }
    });
  }
}
