import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIService } from '../../shared/services/api/api.service';
import {
  Body,
  DELETE,
  GET,
  MediaType,
  PATCH,
  Path,
  POST,
  Produces,
  Query,
} from '../../shared/services/rest/rest.service';
import { EventSeries } from './event-series';

@Injectable()
export class EventSeriesAPI extends APIService {
  @GET('event-series/')
  @Produces(MediaType.JSON)
  public find(
    @Query('name') name?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<EventSeries[]> {
    return;
  }

  @GET('event-series/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<EventSeries> {
    return;
  }

  @GET('event-series/count')
  @Produces(MediaType.JSON)
  public count(@Query('name') name?: string, @Query('filter') filter?: any): Observable<any> {
    return;
  }

  @POST('event-series')
  @Produces(MediaType.JSON)
  public create(@Body eventSeries: EventSeries): Observable<EventSeries> {
    return;
  }

  @PATCH('event-series/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body eventSeries: Partial<EventSeries>): Observable<EventSeries> {
    return null;
  }

  @DELETE('event-series/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<void> {
    return null;
  }

  @GET('event-series/{id}/audit')
  @Produces(MediaType.JSON)
  public audit(@Path('id') id: string, @Query('limit') limit?: number, @Query('skip') skip?: number): Observable<any> {
    return;
  }

  @GET('event-series/{id}/auditcount')
  @Produces(MediaType.JSON)
  public auditCount(@Path('id') id: string): Observable<any> {
    return;
  }

  @POST('event-series/submit-job')
  @Produces(MediaType.JSON)
  public submitJob(@Body eventSeries: EventSeries): Observable<EventSeries> {
    return;
  }
}
