<a href="javascript:void(0)" class="di-timid" (click)="doOpenModal(maintenance)">
  <i class="fa fa-refresh" aria-hidden="true"></i>
  <span class="di-maintenance-label" *ngIf="mode && period">
    Every{{ period > 1 ? ' ' + period : '' }} {{ mode | lowercase }}{{ period > 1 ? 's' : '' }}
  </span>
</a>

<!-- Modal: Maintenance -->
<ng-template #maintenance let-d="dismiss" let-close="close">
  <div class="modal-header">
    <h5 class="modal-title">Maintenance</h5>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <div class="form-group col-md-6">
        <label for="mode">Mode</label>
        <select
          id="mode"
          class="form-control"
          name="mode"
          [(ngModel)]="selectedMode"
          (change)="onModeChange($event)"
          required
        >
          <option [value]="null">None</option>
          <option *ngFor="let mode of maintenanceModes | iterable" [value]="mode.key">
            {{ mode.value | capitalize }}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6">
        <label for="period">Period</label>
        <select id="period" class="form-control" name="period" [(ngModel)]="selectedPeriod" required>
          <option *ngFor="let period of maxPeriod | range" [value]="period">{{ period }}</option>
        </select>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="pull-right">
      <button type="button" class="btn btn-primary btn-sm" (click)="onSave()">Save</button>
      <button type="button" class="btn btn-secondary btn-sm" (click)="close()">Cancel</button>
    </div>
  </div>
</ng-template>
