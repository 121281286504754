import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PersonAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-bd-copy',
  templateUrl: './bd-copy.component.html',
  styleUrls: ['./bd-copy.component.scss'],
})
export class BdCopyComponent implements OnInit {
  modalOption: NgbModalOptions = {};
  modalRef: NgbModalRef;
  inputValue: string = '';
  isReplicating: boolean = false;
  @Input()
  personId: string = '';

  constructor(protected modalService: NgbModal, private svcPerson: PersonAPI) {
    this.modalOption.backdrop = 'static';
    this.modalOption.keyboard = false;
  }
  ngOnInit(): void {}

  onOpenModal(dialog) {
    this.modalRef = this.modalService.open(dialog, this.modalOption);
  }

  onSubmit() {
    if (!this.inputValue) {
      return;
    }
    if (parseInt(this.inputValue) > 20) {
      alert('maximum 20 copies allowed');
      return;
    }
    this.isReplicating = true;
    this.modalRef.close();
    console.log(this.personId);
    console.log(this.inputValue);
    this.svcPerson.replicate(this.personId, { copies: this.inputValue }).subscribe(
      (response) => {
        window.prompt('BD with following person ID has been created:', response.join(','));
      },
      (err) => alert('Error!'),
      () => {
        this.isReplicating = false;
      }
    );
  }
}
