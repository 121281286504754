import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { catchError, take } from 'rxjs/operators';
import { EMPTY, Observable, lastValueFrom, throwError } from 'rxjs';

import { ACL } from '../../../shared/acl/acl.service';
import { Association } from '../association';
import { AddressComponent } from '../../../shared/components';
import { AssociationObsolete } from '../constant/obsolete.enum';
import { AssociationReach } from '../constant/reach.enum';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { Value } from '../../../shared/services/value/value';
import { Utils } from '../../../common/utils';
import { TwitterAPI } from '../../../shared/services/twitter/twitter.service';
import { IMultiSelectOption, IMultiSelectSettings } from '../../../shared/components/multiselect-dropdown/types';
import { ImageFormModel } from '../../../shared/components/image-form/image-form.component';
import { ImageAPI } from '../../../shared/services/image/image.service';
import { ADDRESS_ENTRY_MODE } from '../../../shared/components/address/address.component';
import { Roles } from '../../../shared/acl/roles';
import { SponsorAPI } from '../../../sponsors/shared/api.service';

const CLS_MULTISELECT_BTN = 'btn btn-sm btn-secondary';

@Component({
  selector: 'dirt-association-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
  exportAs: 'frmAssociation',
})
export class AssociationFormComponent implements OnInit, OnChanges, AfterViewInit {
  types: Value[] = [];
  reaches = AssociationReach;
  categories: Value[] = [];
  products: Value[];

  // Therapeutic Areas
  therapeuticAreas: IMultiSelectOption[] = [];
  therapeuticAreaSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    enableSearch: false,
    dynamicTitleMaxItems: 5,
  };

  // Area Tags
  areas: IMultiSelectOption[] = [];
  areaSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    enableSearch: true,
    dynamicTitleMaxItems: 5,
  };

  projects = [];
  projectsSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    checkedStyle: 'fontawesome',
    enableSearch: true,
    dynamicTitleMaxItems: 5,
  };

  // Sponsors
  sponsors: (IMultiSelectOption & { nameLower: string })[] = [];
  sponsorsFiltered: (IMultiSelectOption & { nameLower: string })[] = [];
  sponsorsSettings: IMultiSelectSettings = {
    buttonClasses: CLS_MULTISELECT_BTN,
    enableSearch: true,
    searchRenderLimit: 100,
    isLazyLoad: true,
    isLazyLoadWithSelected: true,
    dynamicTitleMaxItems: 30,
    showUncheckAll: true,
  };

  @Input('association')
  model: Association = new Association();

  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  @ViewChild(AddressComponent, { static: false })
  addressComponent: AddressComponent;

  @ViewChild('logoPreview', { static: false })
  logoPreview: ElementRef;

  longDash = Utils.longDash;

  twitterFieldName = 'twitterHandle';

  hasLogoPreviewError = false;

  hasTwitterHandleError = false;

  isLogoPreviewLoading = true;

  associationLogoValue: ImageFormModel = {};

  languages$: Observable<Value[]>;

  nonCompliantDomainType: string = ValueType.NonCompliantDomainsActivities;

  constructor(
    private svcAcl: ACL,
    private svcValue: ValueAPI,
    private svcTwitter: TwitterAPI,
    private svcImage: ImageAPI,
    private svcSponsor: SponsorAPI
  ) {}

  ngOnInit(): void {
    this.loadTherapeuticalAreas();
    this.loadAreas();
    this.loadProjects();
    this.loadCategories();
    this.loadProducts();
    this.loadTypes();
    this.setFourStarsForTemplate();
    this.loadSponsors();

    this.languages$ = this.svcValue.find(ValueType.Language, Number.MAX_SAFE_INTEGER, 0, '+title');
  }

  ngAfterViewInit(): void {
    // prevent loading the default logo twice and creating a ExpressionChangedAfterItHasBeenCheckedError
    if (!this.model.useTwitterLogo && !this.model.remoteLogoUrl) {
      return;
    }

    // Restore logo preview
    if (this.model.useTwitterLogo) {
      this.onTwitterHandleChange(this.model.twitterHandle);
    } else {
      this.handleLogoPreviewURLChange(this.model.remoteLogoUrl);
    }
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    const association = changes['model'];

    if (association) {
      this.model = association.currentValue;

      if (!this.model.memberWebSources) {
        this.model.memberWebSources = [];
      }
      if (!this.model.parent && !this.model.webSources) {
        this.model.webSources = [];
      }

      // If (member) web sources is an empty array, then it is not rendered until manual refresh.
      // An Empty element is added to force render the component.
      if (this.model.memberWebSources.length === 0) {
        this.model.memberWebSources.push('');
      }
      if (!this.model.parent && this.model.webSources.length === 0) {
        this.model.webSources.push('');
      }

      this.model.phone = Object.keys(this.model.phone || {}).length > 0 ? this.model.phone : ({} as any);
    }
  }

  isNewRecord(): boolean {
    return !this.model.id;
  }

  isAddressMandatory(): boolean {
    return AssociationReach[AssociationReach.INTERNATIONAL] !== this.model.reach;
  }

  isValid(): boolean {
    let isValid = this.ngForm.form.valid;
    const isTagValid = !this.model.parent ? (this.model.therapeuticAreas || []).length > 0 : true;
    const isLogoWebSourceValid = !this.hasLogoPreviewError || !this.isFieldEditable('logoWebSource'); // don't prevent submit if the field is not editable
    const isTwitterHandleValid = !this.hasTwitterHandleError || !this.isFieldEditable('twitterHandle');
    const isAssociationLogoValid = !this.model.parent
      ? isLogoWebSourceValid && isTwitterHandleValid && !this.isLogoPreviewLoading
      : true;
    if (this.addressComponent) {
      isValid = isValid && this.addressComponent.isValid();
    }
    return isValid && isTagValid && (isAssociationLogoValid || this.allowInValidLogoSubmission());
  }

  getValue(): Association {
    const model = Object.assign({}, this.model); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables

    if (this.addressComponent) {
      model.address = this.addressComponent.getAddress();
    }

    Utils.removeEmptyAndDuplicatedElementsFromArray(model, ['webSources', 'memberWebSources']);

    return model;
  }

  isFieldEditable(field: string): boolean {
    const module = this.model.parent ? 'subassociation' : 'association';
    const prefix = this.model.id ? 'update' : 'create';
    return this.svcAcl.hasCredential(`${module}.${prefix}.prop.${field}`);
  }

  isScopeChecked(): boolean {
    return !!this.model.scope;
  }

  onScopeChange(checked: boolean): void {
    this.model.scope = checked ? 'ONCOLOGY' : null;
  }

  isObsolete(): boolean {
    return AssociationObsolete.YES === this.model.obsolete;
  }

  onObsoleteChange(value: boolean) {
    this.model.obsolete = value ? AssociationObsolete.YES : AssociationObsolete.NO;
  }

  private loadTherapeuticalAreas(): void {
    this.svcValue.find(ValueType.TherapeuticArea, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.therapeuticAreas = data.map((o) => ({
        id: o.code,
        name: o.title,
        disabled: false,
      }));
    });
  }

  private loadAreas(): void {
    this.svcValue.find(ValueType.Area, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.areas = data.map((o) => ({
        id: o.code,
        name: o.title,
        disabled: false,
      }));
    });
  }

  private loadProjects() {
    this.svcValue.find(ValueType.Project, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.projects = data.map((o) => ({
        id: o.code,
        name: o.title,
        disabled: false,
      }));
    });
  }

  private loadCategories() {
    this.svcValue.find(ValueType.Category, Number.MAX_SAFE_INTEGER, 0, '+title').subscribe((data) => {
      this.categories = data;
    });
  }

  private loadProducts() {
    this.svcValue.find(ValueType.Product, Number.MAX_SAFE_INTEGER, 0).subscribe((data) => {
      this.products = [
        ...data.filter((d) => 'LFTA' === d.value), // make sure it's first
        ...data.filter((d) => 'LFTA' !== d.value),
      ];
    });
  }

  private loadTypes() {
    this.svcValue.find(ValueType.AssociationType, Number.MAX_SAFE_INTEGER).subscribe((data) => {
      this.types = data;
    });
  }

  private async loadSponsors(): Promise<void> {
    await lastValueFrom(this.svcSponsor.getSponsors()).then((data) => {
      this.sponsors = data.map((o) => ({
        id: o.companyId,
        name: o.normalizedName,
        disabled: !!o.disabled,
        nameLower: o.normalizedName?.toLowerCase(),
      }));
      return this.filterSponsors({ filter: null }); // init display
    });
  }

  public filterSponsors({ filter }) {
    let selectedSponsors = [];
    if (this.model.sponsors?.length) {
      selectedSponsors = this.model.sponsors.map(
        (s) => this.sponsors.find((sp) => sp.id === s) || { id: s, name: s, nameLower: s, disabled: true }
      );
    }
    if (!(filter?.length >= 3)) {
      this.sponsorsFiltered = selectedSponsors;
    } else {
      const filterLower = filter.toLowerCase();
      this.sponsorsFiltered = [
        ...selectedSponsors,
        ...this.sponsors.filter((s) => s.nameLower.indexOf(filterLower) >= 0).slice(0, 100),
      ];
    }
  }

  setFourStarsForTemplate() {
    this.model.priority = 4;
  }

  resetForm(): void {
    const prevAssociation = JSON.parse(JSON.stringify(this.model));

    this.model = new Association();
    this.model.projectNames = prevAssociation.projectNames;
    this.model.memberWebSources = [''];
    this.model.parent = prevAssociation.parent;

    if (!this.model.parent) {
      this.model.priority = 4;
      this.model.reach = prevAssociation.reach;
      this.model.category = prevAssociation.category;
      this.model.address = { countryCode: '' };
      this.model.therapeuticAreas = prevAssociation.therapeuticAreas;
      this.model.areas = prevAssociation.areas;
      this.model.webSources = [''];
    }

    if (this.addressComponent) {
      this.addressComponent.address = {};
      this.addressComponent.addressEntryMode = ADDRESS_ENTRY_MODE.GOOGLE_SEARCH;
    }
  }

  handleLogoPreviewURLChange(url: string): void {
    if (!url) {
      // reset image preview
      this.isLogoPreviewLoading = false;
      this.hasLogoPreviewError = false;
      this.logoPreview.nativeElement.src = 'assets/blank-profile.png';
      return;
    }

    this.svcImage.getPreview(url).subscribe({
      next: (data) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          // Check image size
          const image = new Image();
          image.onload = () => {
            if (image.width < 200 && image.height < 200) {
              this.onLogoPreviewLoadFailure();
              return;
            }

            this.hasLogoPreviewError = false;
            this.isLogoPreviewLoading = true;
            this.logoPreview.nativeElement.src = reader.result as string;
          };

          image.onerror = () => {
            this.onLogoPreviewLoadFailure();
          };

          image.src = reader.result as string;
        };

        reader.readAsDataURL(data);
      },
      error: () => {
        this.onLogoPreviewLoadFailure();
      },
    });
  }

  onUseTwitterLogoChange(value: boolean): void {
    this.model.useTwitterLogo = value;
    if (!this.model.useTwitterLogo) {
      this.hasTwitterHandleError = false;
      this.handleLogoPreviewURLChange(this.model.remoteLogoUrl);
      return;
    }

    this.hasLogoPreviewError = false;
    this.isLogoPreviewLoading = false;

    if (!this.model.twitterHandle) {
      return;
    }

    this.onTwitterHandleChange(this.model.twitterHandle);
  }

  onTwitterHandleChange(handle: string): void {
    if (!handle) {
      this.hasTwitterHandleError = false;
      this.model.useTwitterLogo = false;
      this.handleLogoPreviewURLChange(this.model.remoteLogoUrl);
      return;
    }

    if (!this.model.useTwitterLogo) {
      return;
    }

    this.hasTwitterHandleError = false;
    this.isLogoPreviewLoading = true;
    this.svcTwitter
      .findProfileByHandle(handle)
      .pipe(
        take(1),
        catchError((error) => {
          if (error.status === 404) {
            this.hasTwitterHandleError = true;
            this.handleLogoPreviewURLChange(undefined);
            return EMPTY;
          }
          return throwError(() => error);
        })
      )
      .subscribe((profile) => {
        this.logoPreview.nativeElement.src = profile['profile_image_url'].replace('normal', '400x400');
        this.handleLogoPreviewURLChange(profile['profile_image_url'].replace('normal', '400x400'));
      });
  }

  onLogoPreviewLoadFailure(): void {
    this.hasLogoPreviewError = true;
    this.isLogoPreviewLoading = false;
    this.logoPreview.nativeElement.src = 'assets/blank-profile.png';
  }

  allowInValidLogoSubmission() {
    return this.svcAcl.hasRole(Roles.AssociationReviewer);
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }

  trackByIndex(index: number): number {
    return index;
  }
}
