import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject, takeUntil, tap } from 'rxjs';
import { FormControl } from '@angular/forms';

import { Committee } from '../committee';
import { CommitteeAPI } from '../committee-api.service';

@Component({
  selector: 'dirt-committee-restricted-list',
  templateUrl: './restricted-list.component.html',
  styleUrls: ['./restricted-list.component.scss'],
})
export class CommitteeRestrictedListComponent implements OnInit, OnDestroy {
  @Input()
  initialFilter: Record<string, unknown>;

  committees: Committee[];

  isLoading = false;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 30;
  pagingSkip = 0;

  // Sorting / Filtering settings
  searchCtrl: FormControl = new FormControl('');

  private searchTerm: string;

  private sort = '-_id';

  private destroy$: Subject<boolean> = new Subject();

  constructor(private readonly svcCommittee: CommitteeAPI) {}

  ngOnInit(): void {
    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.searchTerm = term;
        this.doLoad();
      });

    this.doLoad();
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  trackById(_: number, committee: Committee): string {
    return committee.id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getCommittees();
  }

  onSort(field: string): void {
    this.sort = field;

    this.getCommittees();
  }

  private doLoad(): void {
    this.resetPagination();
    this.getCommittees();
    this.getCommitteesCount();
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getCommittees(): void {
    this.isLoading = true;

    this.svcCommittee
      .find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.initialFilter)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((committees) => {
        this.committees = committees;
      });
  }

  private getCommitteesCount(): void {
    this.svcCommittee.count(this.searchTerm, this.initialFilter).subscribe((count) => {
      this.total = count;
    });
  }
}
