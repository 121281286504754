<section class="dirt-section-list dirt-organization-affiliation-suspects-list">
  <header class="row">
    <div class="col-sm-12" style="padding-bottom: 0px">
      <a class="alink" *diAcl="'organization.affiliation.create'" (click)="addAffiliationMapping.emit()"
        >Add Mapping...</a
      >
    </div>
  </header>

  <div [hidden]="isLoading" *ngIf="allMappings?.length && allAffiliations" class="table">
    <table class="table table-sm table-hover">
      <thead>
        <tr>
          <th class="col-status">Match Status</th>
          <th class="col-name">Name</th>
          <th class="col-address">Address</th>
          <th class="col-websource">Websource</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-organization-affiliation-suspects-row"
          [ngClass]="{ 'row-match': row.statusCurated === 'MATCH', 'row-mismatch': row.statusCurated === 'MISMATCH' }"
          [diMarkerWrapper]="row"
          *ngFor="let row of allMappings; trackBy: trackById"
        >
          <td class="col-status">
            <select
              id="status"
              *diMarker="{ name: 'affiliationMappings', entity: 'organization', area: 'MAPPING' }"
              class="form-control"
              name="status"
              [(ngModel)]="row.statusCurated"
              (change)="matchChanged.emit(row)"
              [disabled]="!canEditAffiliation"
            >
              <option [value]="'MATCH'">YES</option>
              <option [value]="'MISMATCH'">NO</option>
              <option [value]="'UNPOLISHED'">--</option>
            </select>
          </td>
          <td class="col-name">
            <!-- If we have an affiliation name & department, link the name to the affiliation list search mask and the department to the specific id -->
            <span *ngIf="allAffiliations[row.affiliationId]?.name && allAffiliations[row.affiliationId].department">
              <a
                class="dirt-affiliation-name"
                [title]="allAffiliations[row.affiliationId].name | dash"
                href="{{ '/affiliation/list?searchTerm=' + allAffiliations[row.affiliationId].name }}"
                target="_blank"
              >
                {{ allAffiliations[row.affiliationId].name | dash }}
              </a>
              <span *ngIf="allAffiliations[row.affiliationId].department">
                -
                <a
                  class="dirt-affiliation-name"
                  [title]="allAffiliations[row.affiliationId].department | dash"
                  [routerLink]="['/affiliation/detail', row.affiliationId]"
                  target="_blank"
                >
                  {{ allAffiliations[row.affiliationId].department | dash }}
                </a>
              </span>
            </span>

            <!-- If we only have an affiliation name, link to the specific id and display the name -->
            <span *ngIf="allAffiliations[row.affiliationId]?.name && !allAffiliations[row.affiliationId].department">
              <a
                class="dirt-affiliation-name"
                [title]="allAffiliations[row.affiliationId].name | dash"
                [routerLink]="['/affiliation/detail', row.affiliationId]"
                target="_blank"
              >
                {{ allAffiliations[row.affiliationId].name | dash }}
              </a>
            </span>

            <!-- Affilition Type -->
            <span *ngIf="allAffiliations[row.affiliationId]?.type">
              <br />
              ({{ allAffiliations[row.affiliationId].type | capitalize }})
            </span>
          </td>
          <td class="col-address">{{ allAffiliations[row.affiliationId]?.address?.formatted }}</td>
          <td class="col-websource">
            <a
              *ngIf="allAffiliations[row.affiliationId]?.webSource"
              href="{{ allAffiliations[row.affiliationId]?.webSource }}"
              target="_blank"
              ><i class="fa fa-external-link fa-lg"></i>
              <br />
            </a>
            <span *ngIf="row.statusAuto" [ngbTooltip]="'Data Science marked this as a ' + row.statusAuto">
              <i class="fa fa-exclamation-circle">&nbsp;DS {{ row.statusAuto }}</i>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
