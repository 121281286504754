import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GuidelineAPI } from '../shared/guideline-api.service';

@Component({
  selector: 'dirt-guideline-next',
  templateUrl: './next.component.html',
  styleUrls: ['./next.component.scss'],
})
export class GuidelineNextComponent implements OnInit {
  queue: any;

  isLoadingNext = false;

  constructor(private router: Router, private svcGuideline: GuidelineAPI) {}

  ngOnInit(): void {
    this.getQueue();
  }

  getQueue(): void {
    this.svcGuideline.queue().subscribe((q) => (this.queue = q));
  }

  getNext(): void {
    this.isLoadingNext = true;

    this.svcGuideline.findNext().subscribe({
      next: (guideline) => {
        if (!guideline?.id) {
          return alert('No Guideline to be processed at the moment! Go and get some rest ;)');
        }

        this.router.navigate(['/guideline/detail', guideline._id]);
      },
      error: () => alert('Error!'),
      complete: () => (this.isLoadingNext = false),
    });
  }
}
