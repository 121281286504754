import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Person } from '../../person';
import { PersonAPI } from '../../api.service';
import { PersonDuplicateTakeover } from '../../person-dup-takeover';

@Component({
  selector: 'dirt-person-duplicate-takeover',
  templateUrl: 'duplicate.component.html',
  styleUrls: ['duplicate.component.scss'],
})
export class PersonDuplicateTakeoverComponent {
  options: { person: Person /*will be discarded*/ };

  staysId: string;
  stays: Person = null;
  staysProjectDiff: string[] = [];
  vidFrom: 'discard' | 'stay' | 'neither' = null;
  npiFrom: 'discard' | 'stay' = null;
  projectNamesDiff: string[] = [];

  isInvalid = false;
  isLoading = false;

  constructor(public activeModal: NgbActiveModal, private svcPerson: PersonAPI) {}

  onSearch(id: string): void {
    this.isInvalid = false;
    this.isLoading = true;
    this.stays = null;
    this.vidFrom = null;
    this.npiFrom = null;
    this.projectNamesDiff = [];

    this.svcPerson.findById(id).subscribe(
      (person: Person) => {
        this.stays = person;
        this.isInvalid = this.options.person.kolId === this.staysId;
        if (!this.isInvalid) {
          // suggest further handling
          // VID
          if (this.stays.externalIds?.openData && this.options.person.externalIds?.openData) {
            this.vidFrom = 'neither';
          } else if (this.stays.externalIds?.openData) {
            this.vidFrom = 'stay';
          } else if (this.options.person.externalIds?.openData) {
            this.vidFrom = 'discard';
          } // else null
          // NPI
          if (this.stays.externalIds?.npi) {
            this.npiFrom = 'stay';
          } else if (this.options.person.externalIds?.npi) {
            this.npiFrom = 'discard';
          } // else null
          // project names diff
          this.projectNamesDiff = this.options.person.projectNames.filter(
            (pn) => 'AUTOMATED_PROFILE' !== pn && this.stays.projectNames.indexOf(pn) < 0
          );
        }
      },
      () => {
        this.isInvalid = true;
        this.isLoading = false;
      },
      () => (this.isLoading = false)
    );
  }

  onSubmit() {
    if (!this.stays) {
      return;
    }
    const takeover: PersonDuplicateTakeover = new PersonDuplicateTakeover();
    takeover.detectDate = new Date();
    takeover.stayKolId = this.stays.kolId;
    takeover.discardKolId = this.options.person.kolId;
    // VID
    if ('stay' === this.vidFrom) {
      // (we leave stay's VID where it is)
    } else if ('discard' === this.vidFrom) {
      takeover.discardedVid = this.stays.externalIds?.openData;
      takeover.addedVid = this.options.person.externalIds?.openData;
    } else if ('neither' === this.vidFrom) {
      takeover.discardedVid = this.stays.externalIds?.openData;
    }
    // NPI
    if ('stay' === this.npiFrom) {
      // (we leave stay's NPI where it is)
    } else if ('discard' === this.npiFrom) {
      takeover.discardedNpi = this.stays.externalIds?.npi;
      takeover.addedNpi = this.options.person.externalIds?.npi;
    }
    // (projects)
    takeover.addedProjectNames = this.projectNamesDiff;
    this.activeModal.close(takeover);
  }
}
