import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Membership } from '../shared/membership';
import { MembershipAPI } from '../shared/api.service';

@Component({
  selector: 'dirt-membership-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.scss'],
})
export class MembershipDetailComponent implements OnInit, OnDestroy {
  @Input()
  obsolete: boolean;

  id: string;
  membership: Membership;
  sub: any;
  isSubmitting = false;

  constructor(private route: ActivatedRoute, private router: Router, private svcMembership: MembershipAPI) {}

  ngOnInit(): void {
    this.sub = this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.getMembership();
    });
  }

  getMembership() {
    this.svcMembership.findById(this.id).subscribe((response) => (this.membership = response));
  }

  onSubmit(membership: Membership): void {
    this.isSubmitting = true;
    this.svcMembership.upsert(membership).subscribe(
      () => {
        this.isSubmitting = false;
        this.navigateTo(['/association/detail', this.membership['association'].id]);
      },
      () => (this.isSubmitting = false)
    );
  }

  navigateTo(link): void {
    this.router.navigate(link);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
