import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Account } from '../../account/shared/account';
import { AccountPerson } from '../shared/account-person';
import { AccountPersonModalComponent } from './person-modal.component';

@Injectable()
export class AccountPersonModalService {
  constructor(private svcModal: NgbModal) {}

  open(account: Account, mode: 'ADD' | 'VIEW' | 'EDIT', person?: AccountPerson): Promise<void> {
    const ref = this.svcModal.open(AccountPersonModalComponent);
    ref.componentInstance.account = account;
    ref.componentInstance.mode = mode;
    ref.componentInstance.selectedAccountPerson = person;
    return ref.result;
  }
}
