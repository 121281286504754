import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Person } from '../../person';
import { PersonAPI } from '../../api.service';

@Component({
  selector: 'dirt-person-merge',
  templateUrl: 'merge.component.html',
  styleUrls: ['merge.component.scss'],
})
export class PersonMergeComponent {
  options: { kolId: string; title: string };

  winnerId: string;
  winner: Person;

  isInvalid = false;
  isLoading = false;

  constructor(public activeModal: NgbActiveModal, private svcPerson: PersonAPI) {}

  onSearch(id: string): void {
    if (this.options.kolId === this.winnerId) {
      this.isInvalid = true;
      return;
    }

    this.isInvalid = false;
    this.isLoading = true;

    this.svcPerson.findById(id).subscribe(
      (person: Person) => (this.winner = person),
      () => {
        this.isInvalid = true;
        this.isLoading = false;
      },
      () => (this.isLoading = false)
    );
  }
}
