<form #frmSentiment="ngForm" novalidate>
  <div class="clearfix">
    <!-- Text -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Text</label>
      <div class="col-sm-8 col-md-10">
        <textarea
          name="text"
          readonly
          [ngModel]="model.text"
          style="width: 100%; min-height: 80px; background-color: lightgrey"
        ></textarea>
        <div *ngIf="'TWITTER' === model.type">
          <a [href]="'https://twitter.com/i/web/status/' + model.entityId" target="_blank" rel="noopener"
            ><i class="fa fa-twitter"></i> Open in Twitter...</a
          >
        </div>
      </div>
    </div>

    <!-- sentiment curation - with indent -->
    <table>
      <thead>
        <tr>
          <th>Subject</th>
          <th>Indication(s)<br />Setting(s)</th>
          <th>Tag</th>
          <th>Sentiment</th>
          <th></th>
        </tr>
        <tr>
          <td></td>
          <td>(just shown for your support)</td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let mc of model.manualCuration; let idx = index">
          <tr *ngIf="idx < 1 || model.manualCuration[idx - 1].subjectFaId !== mc.subjectFaId">
            <td>{{ mc.subject }}</td>
            <td>
              <span *ngIf="indicationMap[mc.subjectFaId]?.length > 0">{{
                indicationMap[mc.subjectFaId].join(', ')
              }}</span>
              <span *ngIf="!(indicationMap[mc.subjectFaId]?.length > 0)">-</span>
              <span *ngIf="settingMap[mc.subjectFaId]?.length > 0"
                ><br />{{ settingMap[mc.subjectFaId].join(', ') }}</span
              >
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td>
              <select
                [(ngModel)]="mc.tag"
                [ngModelOptions]="{ standalone: true }"
                (ngModelChange)="onTagChange(mc, $event)"
              >
                <option *ngFor="let s of sentimentTagsTitles" [value]="s">{{ s }}</option>
                <option *ngIf="sentimentTagsTitles.indexOf(mc.tag) < 0" [value]="mc.tag">{{ mc.tag }}</option>
              </select>
            </td>
            <td>
              <select [(ngModel)]="mc.sentiment" [ngModelOptions]="{ standalone: true }" [disabled]="'n/a' === mc.tag">
                <option *ngFor="let s of sentimentsTitles" [value]="s">{{ s }}</option>
                <option *ngIf="sentimentsTitles.indexOf(mc.sentiment) < 0" [value]="mc.sentiment">
                  {{ mc.sentiment }}
                </option>
              </select>
            </td>
            <td>
              <i
                class="fa fa-plus icon-button icon-button-small"
                title="Add another row"
                (click)="onAddAnother(idx)"
              ></i>
              <i
                class="fa fa-minus icon-button icon-button-small"
                title="Remove row"
                *ngIf="canRemove(idx)"
                (click)="onRemove(idx)"
              ></i>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</form>
