import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET, Path, Query, MediaType, Produces, POST, Body, PATCH } from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { UserPool } from './user-pool';

@Injectable()
export class UserPoolAPI extends APIService {
  @GET('user-pools/')
  @Produces(MediaType.JSON)
  public find(
    @Query('term') term?: string,
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('filter') filter?: any
  ): Observable<UserPool[]> {
    return null;
  }

  @GET('user-pools/count')
  @Produces(MediaType.JSON)
  public count(@Query('name') name?: string, @Query('filter') filter?: any): Observable<any> {
    return;
  }

  @GET('user-pools/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<UserPool> {
    return null;
  }

  @POST('user-pools')
  @Produces(MediaType.JSON)
  public create(@Body pool: UserPool): Observable<any> {
    return;
  }

  @PATCH('user-pools/{id}')
  @Produces(MediaType.JSON)
  public update(@Path('id') id: string, @Body pool: UserPool): Observable<any> {
    return;
  }
}
