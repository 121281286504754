import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { tap, concatMap, take, catchError } from 'rxjs/operators';

import { AccountFinancialInformation } from '../shared/account-financial-information';
import { AccountFinancialInformationAPI } from '../shared/account-financial-information-api.service';

@Component({
  selector: 'dirt-account-financial-information-detail',
  templateUrl: './detail.component.html',
})
export class AccountFinancialInformationDetailComponent implements OnInit {
  id: string;

  accountFinancialInformation: AccountFinancialInformation;

  isLoading = true;

  isSubmitting = false;

  isFormValid: boolean;

  private wndw: Window = window; // allow for testing

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public svcAccountFinancialInformation: AccountFinancialInformationAPI // used in template too
  ) {}

  ngOnInit(): void {
    this.route.params
      .pipe(
        take(1),
        tap(() => (this.isLoading = true)),
        concatMap((params) => {
          this.wndw.scrollTo(0, 0);

          this.id = params['id'];

          return this.svcAccountFinancialInformation
            .findById(this.id)
            .pipe(
              tap((accountFinancialInformation) => (this.accountFinancialInformation = accountFinancialInformation))
            );
        }),
        tap(() => (this.isLoading = false))
      )
      .subscribe();
  }

  goBack(): void {
    this.router.navigate(['/account/detail', this.accountFinancialInformation.account.id]);
  }

  onSave(accountFinancialInformation: AccountFinancialInformation): void {
    this.updateAccountFinancialInformation(accountFinancialInformation);
  }

  onFormValidityChange(status: string): void {
    setTimeout(() => (this.isFormValid = status === 'VALID'));
  }

  private updateAccountFinancialInformation(accountFinancialInformation: AccountFinancialInformation): void {
    if (!this.isFormValid) {
      return;
    }

    this.isSubmitting = true;

    this.svcAccountFinancialInformation
      .update(this.id, accountFinancialInformation)
      .pipe(
        tap(() => (this.isSubmitting = false)),
        catchError((err) => {
          this.isSubmitting = false;
          throw err;
        })
      )
      .subscribe(() => {
        this.goBack();
      });
  }
}
