import { catchError, debounceTime, distinctUntilChanged, Observable, of, switchMap, take, tap } from 'rxjs';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PersonAPI } from '../../api.service';

import { PersonBaseinfo } from '../../person-baseinfo';

@Component({
  selector: 'dirt-person-single-select',
  templateUrl: './person-single-select.component.html',
  styleUrls: ['./person-single-select.component.scss'],
})
export class PersonSingleSelectComponent {
  @Input()
  person: PersonBaseinfo | string;

  @Output()
  personSelected: EventEmitter<PersonBaseinfo> = new EventEmitter();

  @Input()
  wide: boolean = false;

  @Input()
  inline = false;

  @Input()
  curatedOnly: boolean = false;

  @Input()
  disabled = false;

  @Input()
  required = false;

  @Input()
  creationRequestHandler?: (person?: PersonBaseinfo) => Observable<PersonBaseinfo>;

  isSearching = false;

  searchPeople = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }

        return this.svcPerson.search(term as any, true, this.curatedOnly).pipe(
          take(1),
          catchError(() => of([]))
        );
      }),
      switchMap((results: any[]) => {
        if (results.length === 0) {
          return of([]);
        }

        return this.svcPerson
          .getBaseinfo(results.map((r) => r?.kolId)) // can have null if ES is not in sync with Mongo
          .pipe(
            take(1),
            catchError(() => of([]))
          );
      }),
      tap(() => (this.isSearching = false))
    );

  constructor(private svcPerson: PersonAPI) {}

  onChangePerson(person: PersonBaseinfo): void {
    this.personSelected.emit(person);

    if (!this.inline) {
      setTimeout(() => (this.person = null)); // (next apply)
    } else {
      this.person = person;
    }
  }

  onChangePersonInline(value: PersonBaseinfo | string): void {
    if (!value || !value?.toString()?.trim()) {
      this.personSelected.emit(null); // value cleared
    }
  }

  onRequestPerson(person: PersonBaseinfo): void {
    if (!this.creationRequestHandler) {
      return;
    }

    this.creationRequestHandler(person)
      .pipe(take(1))
      .subscribe((person) => {
        this.onChangePerson(person);
      });
  }

  formatTitle(person: PersonBaseinfo): string {
    return [person.firstName, person.middleName, person.lastName, person.affiliationName, person.affiliationDepartment]
      .filter((p) => !!p)
      .join(' ');
  }
}
