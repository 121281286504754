<div class="modal-header">
  <h5 class="modal-title">
    Merge: <b>{{ options.title }}</b>
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form-group" [ngClass]="{ 'has-danger': isInvalid }">
    <label class="form-control-label mb-3"
      >Search the Person which you would like to merge "<b>{{ options.title }}</b
      >":</label
    >
    <div class="input-group">
      <input
        id="mergeWith"
        class="form-control form-control-danger"
        [ngClass]="{ 'form-control-warning': isInvalid }"
        name="mergeWith"
        [(ngModel)]="winnerId"
        auto="false"
        placeholder="Search a Person ID..."
      />
      <span class="input-group-btn">
        <button class="btn btn-secondary" (click)="onSearch(winnerId)" [disabled]="!winnerId">
          <i class="fa fa-search" aria-hidden="true" [hidden]="isLoading"></i>
          <i class="fa fa-circle-o-notch fa-spin fa-fw" [hidden]="!isLoading"></i>
        </button>
      </span>
    </div>
  </form>

  <p class="mt-3 mb-1" *ngIf="winner">
    Are you sure you want to merge it with "<a
      [routerLink]="['/person/detail', winner.id]"
      rel="noopener"
      target="_blank"
      >{{ winner.firstName }} {{ winner.lastName }}</a
    >"?
  </p>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-sm btn-secondary btn-warning"
    (click)="activeModal.close(winner)"
    [disabled]="!winner"
  >
    Merge &amp; Delete
  </button>
</div>
