<div class="modal-header">
  <h3>Something unexpected happened</h3>
</div>

<div class="modal-body">Error: {{ errorMessage }}</div>

<div class="modal-footer">
  <button class="btn btn-secondary" [ngClass]="{ 'btn-loading': isCopying }" (click)="onCopyDetails()">
    Copy Details
  </button>

  <button class="btn btn-primary" [ngClass]="{ 'btn-loading': isCopying }" (click)="onClose()">OK</button>
</div>
