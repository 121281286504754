<div class="modal-header">
  <h5 class="modal-title">Add Person</h5>
</div>

<div class="modal-body">
  <input
    type="search"
    id="people-searchbar"
    class="di-search form-control ng-trim-ignore"
    [ngClass]="{ loading: isSearching }"
    [ngbTypeahead]="onSearchPeople"
    [focusFirst]="false"
    [resultTemplate]="searchResultTemplate"
    placeholder="Search..."
    [(ngModel)]="searchTerm"
    (selectItem)="onSelect($event)"
  />

  <a href="javascript:void(0)" class="small pull-right pt-1 pr-2" (click)="onTogglePersonForm()"> Create Person </a>

  <br />

  <h6 *ngIf="!enablePersonForm && selectedPerson">Person ID: {{ selectedPerson.kolId }}</h6>

  <ng-container *ngIf="selectedPerson">
    <dirt-person-form-inline
      [model]="selectedPerson"
      [disabled]="!enablePersonForm"
      (validityChange)="onFormValidityChange($event)"
    ></dirt-person-form-inline>

    <!-- Position -->
    <div class="form-group">
      <div class="row">
        <div class="col-md-4">
          <label class="col-form-label">Position</label>
          <input
            type="text"
            class="form-control"
            name="position"
            [(ngModel)]="position"
            disabled
            class="form-control"
          />
        </div>

        <div class="col-md-8">
          <label class="col-form-label">Original Position</label>
          <input
            type="text"
            class="form-control"
            name="originalPosition"
            [(ngModel)]="originalPosition"
            (keyup)="onPositionKeyUp()"
          />
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-outline-dark" [disabled]="isSearching" (click)="activeModal.dismiss()">Close</button>
  <button
    class="btn btn-sm btn-success"
    [ngClass]="{ 'btn-loading': isSearching || isSaving }"
    [hidden]="!selectedPerson"
    [disabled]="isSearching || isSaving"
    (click)="onAdd()"
    *ngIf="!enablePersonForm"
  >
    Add
  </button>
  <button
    class="btn btn-sm btn-success"
    [ngClass]="{ 'btn-loading': isSearching || isSaving }"
    [disabled]="isSearching || !isFormValid || isSaving"
    (click)="onSaveAndAdd()"
    *ngIf="enablePersonForm"
  >
    Save & Add
  </button>
</div>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div class="di-sac-kol-id pull-right">{{ r.kolId }}</div>
  <div class="di-sac-name">
    <span>{{ r.firstName }} {{ r.middleName }} {{ r.lastName }}</span>
    <span *ngIf="r.originalFullName">({{ r.originalFullName }})</span>
    <span class="di-sac-status">{{ r._meta?.status || '-' | capitalize }}</span>
  </div>
</ng-template>
