import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';

import { MembershipComponent } from './component';
import { MembershipDetailComponent } from './detail/detail.component';
import { MembershipCreateComponent } from './create/create.component';
import { MembershipModule } from './module';

export const membershipRoutes: Routes = [
  {
    path: 'membership',
    component: MembershipComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: 'create/:associationId',
        component: MembershipCreateComponent,
      },
      {
        path: 'detail/:id',
        component: MembershipDetailComponent,
      },
    ],
  },
];

export const membershipRouting: ModuleWithProviders<MembershipModule> = RouterModule.forChild(membershipRoutes);
