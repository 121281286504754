import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Twitter } from '../../values/twitter';

@Component({
  selector: 'dirt-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
  @Input()
  twitter?: Twitter;

  @Input()
  url?: string;

  @Input()
  local_url: string;

  @Input()
  loading: boolean;

  @Input()
  readonly = false;

  @Input()
  displayMode: 'round' | 'square' = 'round';

  @Output()
  onTogglePhotoApproval = new EventEmitter();

  @Output()
  onSetPhotoApproval = new EventEmitter<boolean>();

  constructor() {}

  isTweeter = () => this.twitter && this.twitter.username && (this.twitter.photoApproved !== false || !this.url);

  getUrl = () => {
    return this.local_url;
  };

  isReadonly = () => (this.isTweeter() ? this.readonly : true);

  isPhotoApproved = () => {
    if (this.local_url) {
      return true;
    }
    return this.isTweeter() ? this.twitter.photoApproved : true;
  };

  onTogglePhotoApprovalClick() {
    if (this.isReadonly()) {
      return;
    }
    this.onTogglePhotoApproval.emit();
  }

  onSetPhotoApprovalClick(e: MouseEvent, value: boolean) {
    e.stopPropagation();
    if (this.readonly) {
      return;
    }
    this.onSetPhotoApproval.emit(value);
  }
}
