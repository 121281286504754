import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';

import { SentimentComponent } from './sentiment.component';
import { SentimentDetailComponent } from './detail/detail.component';
import { SentimentListComponent } from './list/list.component';
import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';
import { SentimentModule } from './sentiment.module';

const sentimentRoutes: Routes = [
  {
    path: 'sentiment',
    component: SentimentComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'detail/:id',
        component: SentimentDetailComponent,
      },
      {
        path: 'list',
        component: SentimentListComponent,
        data: { acl: 'sentiment.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
  },
];

export const sentimentRouting: ModuleWithProviders<SentimentModule> = RouterModule.forChild(sentimentRoutes);
