import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Committee } from '../shared/committee';
import { CommitteePerson } from '../shared/person';
import { CommitteePersonModalComponent } from './person-modal.component';

@Injectable()
export class CommitteePersonModalService {
  constructor(private svcModal: NgbModal) {}

  open(committee: Committee, mode: 'ADD' | 'VIEW' | 'EDIT', person?: CommitteePerson): Promise<void> {
    const ref = this.svcModal.open(CommitteePersonModalComponent);
    ref.componentInstance.committee = committee;
    ref.componentInstance.mode = mode;
    ref.componentInstance.selectedCommitteePerson = person;
    return ref.result;
  }
}
