import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Account } from '../account';
import { AccountMoveModalComponent } from './move-modal.component';

@Injectable()
export class AccountMoveModalService {
  constructor(private svcModal: NgbModal) {}

  open(accountId: string): Promise<Account> {
    const ref = this.svcModal.open(AccountMoveModalComponent, { size: 'lg' });

    ref.componentInstance.accountId = accountId;

    return ref.result;
  }
}
