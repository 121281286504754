import { Component } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError } from 'rxjs/operators';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { of, Observable } from 'rxjs';

import { Affiliation } from '../../affiliation';
import { AffiliationAPI } from '../../api.service';
import { Person } from '../../../../person/shared/person';
import { PersonAffiliationLfka } from '../../../../person/shared/person-affiliation-lfka';
import { PersonAPI } from '../../../../person/shared/api.service';
import { PersonStatus } from '../../../../person/shared/constant/status.enum';

@Component({
  selector: 'dirt-affiliation-person-modal',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
})
export class AffiliationPersonModalComponent {
  affiliation: Affiliation;

  isSaving: boolean;

  isSearching: boolean;

  searchTerm: string;

  selectedPerson: Person;

  enablePersonForm: boolean;

  position?: string;

  originalPosition?: string;

  isFormValid: boolean;

  private searchTimeOutForPos: any;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly svcPerson: PersonAPI,
    private readonly svcAffiliation: AffiliationAPI
  ) {
    this.onSearchPeople = this.onSearchPeople.bind(this);
  }

  onSearchPeople(term$: Observable<string>): Observable<Person[]> {
    return term$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term: string) => {
        if (!term) {
          return of([]);
        }

        return this.svcPerson.find(term).pipe(catchError(() => of([])));
      }),
      tap(() => (this.isSearching = false))
    );
  }

  onSelect(event: any): void {
    event.preventDefault(); // prevent setting model to [object Object]

    this.selectedPerson = event.item;
    this.searchTerm = '';
    this.enablePersonForm = false;
    this.position = null;
    this.originalPosition = null;
  }

  onFormValidityChange(status: string): void {
    this.isFormValid = status === 'VALID';
  }

  onAdd(): void {
    if (!this.selectedPerson || this.enablePersonForm || this.isSaving || this.isSearching) {
      return;
    }

    const hasAffiliationInLFTA = this.selectedPerson.affiliations?.find((a) => a.id === this.affiliation.id);
    const hasAffiliationInLFKA = this.selectedPerson.affiliationsLfka?.find((a) => a.id === this.affiliation.id);

    if (hasAffiliationInLFTA || hasAffiliationInLFKA) {
      alert('person is already connected to affiliation.');
      return;
    }

    // TODO: Should we add it to LFKA or LFTA affiliations? -> current understanding is LFKA since process is owned by LFKA
    if (!Array.isArray(this.selectedPerson.affiliationsLfka)) {
      this.selectedPerson.affiliationsLfka = [];
    }

    this.selectedPerson.affiliationsLfka.push({
      ...this.affiliation,
      countryCode: this.affiliation.address.countryCode,
      positions: [
        {
          originalPosition: this.originalPosition?.trim(),
          position: this.position,
        },
      ],
    } as PersonAffiliationLfka);

    this.isSaving = true;

    this.svcPerson
      .update(this.selectedPerson.id, this.selectedPerson)
      .pipe(tap(() => (this.isSaving = false)))
      .subscribe((person) => {
        this.activeModal.close(person);
      });
  }

  onSaveAndAdd(): void {
    if (!this.isFormValid || !this.enablePersonForm || this.isSaving || this.isSearching) {
      return;
    }

    this.selectedPerson._meta = { status: PersonStatus.READY_FOR_COMPILATION };
    this.selectedPerson.affiliationsLfka[0].countryCode = this.affiliation.address.countryCode;

    if (this.originalPosition && this.position) {
      this.selectedPerson.affiliationsLfka[0].positions = [
        {
          primary: true,
          originalPosition: this.originalPosition?.trim(),
          position: this.position,
        } as any,
      ];
    }

    this.isSaving = true;

    this.svcPerson
      .create(this.selectedPerson)
      .pipe(tap(() => (this.isSaving = false)))
      .subscribe((person) => {
        this.activeModal.close(person);
      });
  }

  onTogglePersonForm(): void {
    this.enablePersonForm = true;
    this.selectedPerson = new Person();
    this.selectedPerson.cvLinks = [''];
    this.selectedPerson.countryWorkflow = this.affiliation.address?.countryCode;
    this.selectedPerson.affiliationsLfka = [JSON.parse(JSON.stringify(this.affiliation))]; // we'll mutate it later
    this.position = null;
    this.originalPosition = null;
  }

  onPositionKeyUp(): void {
    const delay = 300;

    if (!this.originalPosition || this.originalPosition.trim() === '') {
      this.position = undefined;
      return;
    }

    if (this.searchTimeOutForPos) {
      clearTimeout(this.searchTimeOutForPos);
    }

    this.searchTimeOutForPos = setTimeout(() => {
      this.svcAffiliation.searchPos(this.originalPosition).subscribe((data) => {
        this.position = data.position;
      });
    }, delay);
  }
}
