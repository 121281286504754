import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'dirt-date-input',
  templateUrl: 'input-date.component.html',
})
export class InputDateComponent implements OnChanges, AfterViewInit, OnDestroy {
  @Input()
  value: Date | string;

  @Input()
  name: string;

  @Input()
  disabled?: boolean;

  @Input()
  required?: boolean;

  @Input()
  form: NgForm;

  @Output()
  valueChange: EventEmitter<Date> = new EventEmitter();

  displayValue: NgbDate;

  @ViewChild('dateInput', { static: false })
  private ngControl: NgModel;

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.value) {
      this.displayValue = null;
      return;
    }

    if (typeof this.value === 'string') {
      this.value = new Date(this.value);
    }

    this.displayValue = NgbDate.from({
      year: this.value.getUTCFullYear(),
      month: this.value.getUTCMonth() + 1,
      day: this.value.getUTCDate(),
    });
  }

  /**
   * Register control in parent form so that the input validity is checked when
   * checking form validity.
   */
  ngAfterViewInit(): void {
    this.form.addControl(this.ngControl);
  }

  /**
   * Form could outlive control so unregister control on destroy.
   */
  ngOnDestroy(): void {
    this.form.removeControl(this.ngControl);
  }

  onValueChange(value: NgbDate): void {
    if (this.disabled) {
      return;
    }

    // Send it back as a Date object standardized on UTC
    this.valueChange.emit(new Date(Date.UTC(value.year, value.month - 1, value.day)));
  }

  onValueClear(): void {
    this.displayValue = null;
    this.valueChange.emit(null);
  }
}
