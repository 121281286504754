<form [diMarkerWrapper]="model" (ngSubmit)="onSubmit(frmMembership.value)" #frmMembership="ngForm" novalidate>
  <fieldset *diMarker="{ name: 'membership', entity: 'subassociation' }" class="dirt-fieldset form-group">
    <div class="form-group row">
      <div class="col-12">
        <h3 *ngIf="!isEditMode">Create new membership</h3>
        <h3 *ngIf="isEditMode">Edit membership</h3>
      </div>
    </div>
    <!-- Position -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2" for="position">Position</label>
      <div class="col-sm-8 col-md-10">
        <select
          id="position"
          class="form-control custom-select"
          name="position"
          [(ngModel)]="model.position"
          [disabled]="isReadOnly"
          *diMarker="{ name: 'position', entity: 'membership' }"
          required
        >
          <option *ngFor="let position of positions | iterable" [value]="position.key">
            {{ position.value | capitalize }}
          </option>
        </select>
      </div>
    </div>

    <!-- Ended -->
    <div class="form-group row">
      <label class="col-sm-4 col-md-2">Ended</label>
      <div class="col-sm-8 col-md-10">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            name="ended"
            [checked]="isObsolete() || model.ended"
            (change)="onEndedChange($event.target.checked)"
            [disabled]="isReadOnly"
          />
        </div>
      </div>
    </div>

    <!-- Reviewed -->
    <div class="form-group row">
      <label class="col-sm-4 col-md-2">Reviewed</label>
      <div class="col-sm-8 col-md-10">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            name="reviewed"
            [checked]="model.reviewed"
            (change)="onReviewedChange($event.target.checked)"
            [disabled]="isReadOnly"
          />
        </div>
      </div>
    </div>

    <!-- Verified -->
    <div class="form-group row" *ngIf="shouldDisplayVerifiedControls">
      <label class="col-sm-4 col-md-2">Verified</label>
      <div class="col-sm-8 col-md-10">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            name="ended"
            [checked]="isObsolete() || model.verified"
            (change)="onEndedChange($event.target.checked)"
            [disabled]="isReadOnly"
          />
        </div>
      </div>
    </div>

    <!-- Year -->
    <div class="form-group row">
      <label class="col-form-label col-sm-4 col-md-2">Year</label>
      <div class="col-sm-8 col-md-10">
        <div class="input-group">
          <!-- Start Year -->
          <dirt-yearpicker
            [year]="model.startYear"
            [minOffset]="60"
            [disabled]="isReadOnly"
            [placeholder]="'Start Date'"
            (change)="onStartYearSelected()"
            #startYear
          >
          </dirt-yearpicker>

          <div class="input-group-prepend input-group-append">
            <span class="input-group-text">To</span>
          </div>

          <!-- End Year -->
          <dirt-yearpicker
            [year]="model.endYear"
            [minOffset]="20"
            [maxOffset]="4"
            [disabled]="isReadOnly"
            [placeholder]="'End Date'"
            (change)="onStartYearSelected()"
            #endYear
          >
          </dirt-yearpicker>
        </div>

        <div class="input-group"></div>
      </div>
    </div>

    <fieldset class="dirt-member dirt-fieldset">
      <legend>Member</legend>

      <!-- Full Name -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="fullName">Full Name</label>
        <div class="col-sm-8 col-md-10">
          <input
            id="fullName"
            class="form-control"
            name="fullName"
            [ngModel]="model.member.fullName"
            (ngModelChange)="model.member.fullName = longDash($event)"
            #fullName="ngModel"
            [required]="lastName && lastName.dirty && !model.member.lastName"
            [readonly]="isReadOnly"
            *diMarker="{ name: 'fullName', entity: 'membership' }"
          />
        </div>
      </div>

      <!-- Name -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2">
          <span class="pr-2">Name</span>
          <a href="javascript:void(0)" class="di-timid" ngbTooltip="Paste and split" (click)="onClipboardPaste()">
            <i class="fa fa-clipboard" aria-hidden="true"></i>
          </a>
        </label>
        <div class="col-sm-8 col-md-10">
          <div class="row">
            <!-- First Name -->
            <div class="col-md-3">
              <label class="sr-only" for="firstName">First Name</label>
              <input
                id="firstName"
                class="form-control"
                name="firstName"
                placeholder="First Name"
                [ngModel]="model.member.firstName"
                (ngModelChange)="model.member.firstName = longDash($event)"
                *diMarker="{ name: 'firstName', entity: 'membership' }"
                [readonly]="isReadOnly"
              />
            </div>
            <!-- Middle Name -->
            <div class="col-md-2">
              <label class="sr-only" for="middleName">Middle Name</label>
              <input
                id="middleName"
                class="form-control"
                name="middleName"
                placeholder="Middle Name"
                [ngModel]="model.member.middleName"
                (ngModelChange)="model.member.middleName = longDash($event)"
                [readonly]="isReadOnly"
              />
            </div>
            <!-- Last Name -->
            <div class="col-md-3">
              <label class="sr-only" for="lastName">Last Name</label>
              <input
                id="lastName"
                class="form-control"
                name="lastName"
                placeholder="Last Name"
                [ngModel]="model.member.lastName"
                (ngModelChange)="model.member.lastName = longDash($event)"
                [required]="!model.member.fullName"
                #lastName="ngModel"
                *diMarker="{ name: 'lastName', entity: 'membership' }"
                [readonly]="isReadOnly"
              />
            </div>
            <!-- Suffix -->
            <div class="col-md-2">
              <label class="sr-only" for="suffix">Suffix</label>
              <select
                id="suffix"
                class="form-control custom-select"
                name="suffix"
                [(ngModel)]="model.member.suffix"
                [disabled]="isReadOnly"
              >
                <option [ngValue]="undefined">&mdash; Suffix &mdash;</option>
                <option *ngFor="let suffix of suffixes" [value]="suffix.code">{{ suffix.title }}</option>
              </select>
            </div>
            <!-- Nickname -->
            <div class="col-md-2">
              <label class="sr-only" for="nickname">Nickname</label>
              <input
                id="nickname"
                class="form-control"
                name="nickname"
                placeholder="Nickname"
                [ngModel]="model.member.nickname"
                (ngModelChange)="model.member.nickname = longDash($event)"
                [readonly]="isReadOnly"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Workplace -->
      <div class="form-group row">
        <label class="col-form-label col-sm-4 col-md-2" for="workplace">Workplace</label>
        <div class="col-sm-8 col-md-10">
          <input
            id="workplace"
            class="form-control"
            name="workplace"
            [ngModel]="model.member.workplace"
            (ngModelChange)="model.member.workplace = longDash($event)"
            *diMarker="{ name: 'workplace', entity: 'membership' }"
            [readonly]="isReadOnly"
          />
        </div>
      </div>
    </fieldset>

    <div class="dirt-actions col-md-12" *ngIf="!isReadOnly">
      <div class="dirt-btn-ct pull-right">
        <button
          class="btn btn-secondary"
          [disabled]="isSubmitting || isSubmitting || isSaving"
          (click)="onCancelClick(); $event.stopPropagation()"
        >
          <span>Cancel</span>
        </button>
      </div>

      <div *ngIf="!isEditMode" class="dirt-btn-ct pull-right">
        <button
          class="btn btn-primary"
          [ngClass]="{ 'btn-loading': isSaving }"
          [disabled]="!valid || isSubmitting || isSaving"
          (click)="onSubmitAndNew(); $event.preventDefault(); $event.stopPropagation()"
        >
          <span>Save & New</span>
        </button>
      </div>

      <div class="dirt-btn-ct pull-right">
        <button
          type="submit"
          class="btn btn-primary"
          [ngClass]="{ 'btn-loading': isSubmitting }"
          [disabled]="!valid || isSubmitting || isSaving"
        >
          <span>Save</span>
        </button>
      </div>
    </div>
  </fieldset>
</form>
