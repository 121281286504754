<section class="dirt-section-list dirt-document-list">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>Documents</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <div class="input-group">
        <div ngbDropdown class="input-group-prepend">
          <button class="btn btn-secondary" ngbDropdownToggle>
            {{ searchType | capitalize }}
          </button>

          <div class="dropdown-menu" ngbDropdownMenu>
            <button class="dropdown-item" (click)="onSearchTypeChange('DOCUMENT')" [hidden]="searchType === 'DOCUMENT'">
              Document
            </button>

            <button class="dropdown-item" (click)="onSearchTypeChange('ACCOUNT')" [hidden]="searchType === 'ACCOUNT'">
              Account
            </button>

            <button
              class="dropdown-item"
              (click)="onSearchTypeChange('AFFILIATION')"
              [hidden]="searchType === 'AFFILIATION'"
            >
              Organization
            </button>
          </div>
        </div>

        <input
          type="search"
          class="di-search form-control ng-trim-ignore"
          placeholder="Search..."
          [formControl]="searchCtrl"
        />
      </div>
    </div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Filter -->
        <dirt-filters [filters]="filters" (onFilter)="onFilter($event)"></dirt-filters>

        <!-- Bulk -->
        <ng-container>
          <dirt-button-dropdown [title]="'Bulk'" [icon]="'fa-pencil'" *diAcl="'document.bulkList'">
            <button
              class="list-group-item list-group-item-action"
              [title]="bulkListDelegate.specialHint || ''"
              (click)="onOpenBulkListDialog()"
            >
              <i class="fa fa-th-list" aria-hidden="true"></i>
              Bulk import
            </button>

            <button
              class="list-group-item list-group-item-action"
              [title]="jobBulkListDelegate.specialHint || ''"
              (click)="onOpenBulkListJobDialog()"
            >
              <i class="fa fa-th-list" aria-hidden="true"></i>
              Bulk Import Job
            </button>
          </dirt-button-dropdown>
        </ng-container>

        <!-- Create Button -->
        <a class="btn btn-success btn-sm" [routerLink]="['/document/create']" *diAcl="'document.create'">
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
          <span>Create Document</span>
        </a>
      </div>
    </div>
  </header>

  <div class="pull-right">
    <!-- Export -->
    <button class="btn btn-secondary btn-sm" (click)="onExport()" *diAcl="'document.export'">
      <i class="fa fa-download" aria-hidden="true"></i>&nbsp; Export List
    </button>
  </div>

  <br />

  <!-- Filter Bar -->
  <dirt-filters-bar
    [filter]="filter"
    [config]="filterBarConfig"
    (filterClear)="onFilterClear()"
    (filterValueRemove)="onFilterValueRemove($event)"
  ></dirt-filters-bar>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-document-list'"
    [pagingSize]="documents?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="documents?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-title" [diColSorter]="'title'">Title</th>
          <th class="col-connect" title="Connected accounts">Connected</th>
          <th class="col-numconnect" title="Number of connected accounts (directly or indirectly)"># Acct</th>
          <th class="col-numconnect" title="Number of connected organizations"># Orgs</th>
          <th class="col-country" [diColSorter]="'country'">Country</th>
          <th class="col-category" [diColSorter]="'category'">Category</th>
          <th class="col-type" [diColSorter]="'type'">Type</th>
          <th class="col-people-count">
            <span ngbTooltip="People Count">
              <i class="fa fa-list" aria-hidden="true"></i>
            </span>
          </th>
          <th class="col-status" [diColSorter]="'_meta.status'">Status</th>
          <th class="col-created" [diColSorter]="'createdAt'">Created</th>
          <th class="col-modified" [diColSorter]="'updatedAt'">Modified</th>
          <th class="col-priority" [diColSorter]="'priority'">Priority</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-document-row"
          *ngFor="
            let document of documents
              | paginate
                : {
                    id: 'dirt-document-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <td class="col-title">
            <a
              class="dirt-document-title"
              [title]="document.title | dash"
              [routerLink]="['/document/detail', document._id]"
              >{{ document.title | dash }}</a
            >
          </td>
          <td class="col-connect" [title]="nameString(document.allConnectedAccounts)">
            {{ compactString(document.topConnectedAccount?.name) || '-' }}
          </td>
          <td class="col-numconnect">{{ document.numberAccountConn || 0 }}</td>
          <td class="col-numconnect">{{ document.numberAffiliationConn || 0 }}</td>
          <td class="col-country">{{ document.country }}</td>
          <td class="col-category">{{ document.category | capitalize }}</td>
          <td class="col-type">{{ document.type | capitalize }}</td>
          <td class="col-people-count">{{ document.peopleCount | number }}</td>
          <td class="col-status" [title]="document._meta?.status | capitalize">
            {{ document._meta?.status | capitalize }}
          </td>
          <td class="col-created">{{ document.createdAt | date : 'dd.MM.y' }}</td>
          <td class="col-modified">{{ document.updatedAt | date : 'dd.MM.y' }}</td>
          <td class="col-priority" [ngClass]="{ disabled: !isPriorityEditable }">
            <ngb-rating
              [rate]="document.priority"
              (rateChange)="onSetPriority(document, $event)"
              max="5"
              [readonly]="!isPriorityEditable"
            >
              <ng-template let-fill="fill" let-index="index">
                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
              </ng-template>
            </ngb-rating>
          </td>
          <td class="col-actions">
            <ng-template [diAcl]="'document.delete'">
              <!-- Delete -->
              <span class="action-btn action-delete" title="Delete Document" (click)="onDelete(document._id)">
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No Documents</p>
    </ng-template>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-document-list'"
    [pagingSize]="documents?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
