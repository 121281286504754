<section class="dirt-section-list dirt-account-list" [ngClass]="{ 'expandable-section': parentId }">
  <header class="row">
    <div class="col-sm-4">
      <h1>
        <span>{{ total.count | number }}&nbsp;</span>
        <span>{{ parentId ? 'Children' : 'Accounts' }}</span>
      </h1>
    </div>

    <div class="col-sm-4">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        placeholder="Search by Name, Acronym or ID"
        [formControl]="searchCtrl"
      />
    </div>

    <div class="col-sm-4">
      <div class="pull-right">
        <!-- Create Button -->
        <a
          class="btn btn-success btn-sm"
          [routerLink]="['/account/create']"
          [queryParams]="{ parentId: parentId }"
          *diAcl="'account.create || division.create'"
        >
          <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
          <span>Create {{ parentId ? 'Children' : 'Accounts' }}</span>
        </a>
      </div>
    </div>
  </header>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-account-list'"
    [pagingSize]="accounts?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <div [hidden]="isLoading">
    <table class="table table-sm table-hover" *ngIf="accounts?.length; else empty" (diSort)="onSort($event)">
      <thead>
        <tr>
          <th class="col-name" [diColSorter]="'name.keyword'">Name</th>
          <th class="col-acronym" [diColSorter]="'acronym.keyword'">Acronym</th>
          <th class="col-country" [diColSorter]="'address.countryCode'">Country</th>
          <th class="col-status" [diColSorter]="'_meta.status'">Status</th>
          <th class="col-created" [diColSorter]="'createdAt'">Created</th>
          <th class="col-modified" [diColSorter]="'updatedAt'">Modified</th>
          <th class="col-priority" [diColSorter]="'priority'">Priority</th>
          <th class="col-actions">&nbsp;</th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="dirt-account-row"
          *ngFor="
            let account of accounts
              | paginate
                : {
                    id: 'dirt-account-list',
                    itemsPerPage: pagingLimit,
                    currentPage: pagingPage,
                    totalItems: total?.count
                  };
            trackBy: trackById
          "
        >
          <td class="col-name">
            <a class="dirt-account-name" [title]="account.name | dash" [routerLink]="['/account/detail', account.id]">{{
              account.name | dash
            }}</a>
          </td>
          <td class="col-acronym">{{ account.acronym }}</td>
          <td class="col-country">{{ account.address?.countryCode }}</td>
          <td class="col-status" [title]="account._meta?.status | capitalize">
            {{ account._meta?.status | capitalize }}
          </td>
          <td class="col-created">{{ account.createdAt | date : 'dd.MM.y' }}</td>
          <td class="col-modified">{{ account.updatedAt | date : 'dd.MM.y' }}</td>
          <td class="col-priority" [ngClass]="{ disabled: !isPriorityEditable }">
            <ngb-rating
              [rate]="account.priority"
              (rateChange)="setPriority(account, $event)"
              max="5"
              [readonly]="!isPriorityEditable"
            >
              <ng-template let-fill="fill" let-index="index">
                <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
              </ng-template>
            </ngb-rating>
          </td>
          <td class="col-actions">
            <ng-template [diAcl]="'account.delete'">
              <!-- Delete -->
              <span class="action-btn action-delete" title="Delete Account" (click)="onDelete(account.id)">
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </span>
            </ng-template>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Empty Message -->
    <ng-template #empty>
      <p class="dirt-no-results">No {{ parentId ? 'Children' : 'Accounts' }}</p>
    </ng-template>
  </div>

  <!-- Pagination -->
  <dirt-pagination
    class="dirt-pagination"
    [id]="'dirt-account-list'"
    [pagingSize]="accounts?.length || 0"
    [pagingSkip]="pagingSkip"
    [pagingLimit]="pagingLimit"
    [pagingTotal]="total?.count || 0"
    [hidden]="pagingLimit >= total?.count"
    (pageChange)="getPage($event)"
  >
  </dirt-pagination>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
