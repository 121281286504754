<section class="dirt-section-detail dirt-account-detail">
  <div class="row" *ngIf="!isLoading; else loader">
    <div class="col-md-8">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <h1 class="bounceInRight">Payer Information Detail</h1>
      <p>
        Belongs to
        <a [routerLink]="['/account/detail', accountPayerInformation.account.id]">{{
          accountPayerInformation.account.name | dash
        }}</a>
      </p>

      <br />

      <dirt-account-payer-information-form
        [accountPayerInformation]="accountPayerInformation"
        #frmAccountPayerInformation="frmAccountPayerInformation"
        (validityChange)="onFormValidityChange($event)"
        (isTotalShareValid)="handleSharesValidityChange($event)"
      >
      </dirt-account-payer-information-form>

      <div class="pull-right" *diAcl="'account-payer-information.update'">
        <button
          class="btn btn-primary"
          [ngClass]="{ 'btn-loading': isSubmitting }"
          [disabled]="!isFormValid || isSubmitting"
          (click)="onSave(frmAccountPayerInformation.getValue())"
        >
          Save
        </button>
      </div>
    </div>
  </div>

  <!-- Loading Message -->
  <ng-template #loader>
    <p class="dirt-loading">
      <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
      <span class="sr-only">Loading...</span>
    </p>
  </ng-template>
</section>
