import { BehaviorSubject, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, map } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { User } from '../../../user/shared/user';
import { UserAPI } from '../../../user/shared/api.service';

@Component({
  selector: 'dirt-multi-user-select',
  templateUrl: './multi-user-select.component.html',
  styleUrls: ['./multi-user-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiUserSelectComponent implements OnInit {
  @Input()
  selectedUsers: User[];

  @Input()
  roles: string[] = [];

  @Output()
  selectedUsersChange = new EventEmitter<User[]>();

  @Input()
  disabled: boolean;

  @Input()
  required: boolean;

  @Input()
  multi: boolean;

  @Input()
  initialUserIds: string[];

  @Input('filterUsers')
  filterUsers: (users: User) => boolean;

  @Input()
  requiresEu: boolean = false;

  loadingInitialUsers: boolean = false;

  isLoading$ = new BehaviorSubject(false);
  public items$: Observable<User[]>;
  public input$ = new Subject<string | null>();

  constructor(private svcUser: UserAPI) {}

  ngOnInit(): void {
    if (this.initialUserIds && this.initialUserIds.length) {
      this.loadInitialUsers();
    }
    this.items$ = this.input$.pipe(
      distinctUntilChanged(),
      debounceTime(1000),
      tap(() => this.isLoading$.next(true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }
        if (term.length > 2) {
          return this.svcUser
            .find(
              term,
              10,
              0,
              '',
              { roles: this.roles, blocked: false, ...(this.requiresEu ? { euCompliant: true } : {}) },
              true
            )
            .pipe(
              catchError(() => {
                return of([]);
              })
            );
        } else {
          return of([]);
        }
      }),
      map((users) => {
        if (this.filterUsers) {
          return users.filter(this.filterUsers).map(this.formatUsers);
        }
        return users.map(this.formatUsers);
      }),
      tap(() => this.isLoading$.next(false))
    );
  }

  onSelectedUsersChange(users: User[]) {
    this.selectedUsers = users;
    this.selectedUsersChange.emit(users);
  }

  formatUsers(user: User & { displayLabel: string }) {
    let firstName;
    let lastName;

    if (user.user_metadata) {
      firstName = user.user_metadata.firstName;
      lastName = user.user_metadata.lastName;
    }

    const name = `${firstName} ${lastName} <${user.email}>`;
    user.displayLabel = name.trim();
    return user;
  }

  async loadInitialUsers() {
    this.loadingInitialUsers = true;
    let fullRes = [];
    let start = 0;
    let PAGE = 50;
    while (this.initialUserIds.length > start) {
      const partRes = await firstValueFrom(
        this.svcUser.find(undefined, 5000, undefined, undefined, {
          userIds: this.initialUserIds.slice(start, start + PAGE),
        })
      );
      fullRes = fullRes.concat(partRes);
      start += PAGE;
    }
    this.onSelectedUsersChange(fullRes.map(this.formatUsers));
    this.loadingInitialUsers = false;
  }
}
