import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Affiliation } from '../../../affiliation/shared/affiliation';
import { AddressComponent } from '../address/address.component';
import { ValueType } from '../../enum/value-type.enum';
import { ValueAPI } from '../../services/value/value-api.service';
import { Value } from '../../services/value/value';
import { ACL } from '../../acl/acl.service';
import { Roles } from '../../acl/roles';

@Component({
  selector: 'dirt-affiliation-form',
  templateUrl: 'form.component.html',
  styleUrls: ['form.component.scss'],
  exportAs: 'frmAffiliation',
})
export class AffiliationFormComponent implements OnInit, OnDestroy {
  @Input('affiliation')
  model = new Affiliation();

  @Input()
  disableNameField: boolean;

  @Input()
  disableNameFieldUnconditionally: boolean;

  @Input()
  disableDeptFieldUnconditionally: boolean;

  @Input()
  currentJobType?: string;

  @Input()
  isAffiliationRequest?: boolean;

  @Input()
  isCustomerRequest?: boolean;

  @Input()
  requestOwnerProduct?: string;

  canRequestAffiliation: boolean;

  onChangeSubjectSub: Subscription;
  onChangeSubject = new Subject<Affiliation>();

  @Output()
  onChange = new EventEmitter<Affiliation>();

  @ViewChild(NgForm, { static: true })
  ngForm: NgForm;

  @ViewChild(AddressComponent, { static: true })
  addressComponent: AddressComponent;

  @ViewChild('orgAddr', { static: true })
  orgAddressComponent: AddressComponent;

  types: Value[] = [];

  languages$: Observable<Value[]>;

  constructor(private svcValue: ValueAPI, private svcAcl: ACL) {}

  ngOnInit(): void {
    this.svcValue.find(ValueType.AffiliationType, Number.MAX_SAFE_INTEGER, null, '+title').subscribe((data) => {
      this.types = data;
    });

    this.onChangeSubjectSub = this.onChangeSubject
      .asObservable()
      .pipe(debounceTime(400))
      .subscribe((aff) => this.onChange.emit(aff));

    if (this.isAffiliationRequest) {
      this.model.usageInfo = {};
      if (this.isCustomerRequest) {
        this.model.usageInfo.customerRequest = true;
      }
      if (this.requestOwnerProduct) {
        this.model.usageInfo.requestOwnerProduct = this.requestOwnerProduct;
      }
    }

    this.languages$ = this.svcValue.find(ValueType.Language, Number.MAX_SAFE_INTEGER, 0, '+title');
  }

  onAffiliationChange(affiliation: Affiliation) {
    this.onChangeSubject.next(affiliation);
  }

  isValid(): boolean {
    return (
      this.ngForm.form.valid &&
      this.addressComponent.isValid() &&
      (this.disableDepartmentOnType() ? this.model.department === undefined || this.model.department === '' : true)
    );
  }

  getValue(): Affiliation {
    const model = Object.assign({}, this.model); // TODO: no clone, return domain object (for all such forms); use ngOnChanges+change handlers for helper variables

    model.address = this.addressComponent.getAddress();

    if (this.orgAddressComponent !== undefined) {
      const orgaddress = this.orgAddressComponent.getAddress();

      // Get the value from the component which has id orgAddressComponent and add it to the address model
      for (const variableKey in orgaddress) {
        if (orgaddress.hasOwnProperty(variableKey)) {
          if (variableKey == 'additionalInfo') {
            model.address.originalAdditionalInfo = orgaddress[variableKey];
          } else if (variableKey == 'city') {
            model.address.originalCity = orgaddress[variableKey];
          } else if (variableKey == 'name') {
            model.address.originalStreet = orgaddress[variableKey];
          }
        }
      }
    }

    return model;
  }

  hasSpecialPermForWebSource(field: string): boolean {
    return field === 'webSource' && this.svcAcl.hasRole(Roles.ViewLfka) && this.svcAcl.hasRole(Roles.PersonCompiler);
  }

  isFieldEditable(field: string): boolean {
    if (this.model._meta?.status === 'PENDING_REMOVAL') {
      return false;
    }
    if (this.model.sourceLfka && !this.svcAcl.hasCredential('affiliation.lfka', this.currentJobType)) {
      return false;
    }
    if (this.svcAcl.hasRole(Roles.PersonReviewer) && !this.svcAcl.hasRole(Roles.ViewLfka) && this.model.id) {
      return false;
    }

    const prefix = this.model.id ? 'update' : this.isAffiliationRequest ? 'request' : 'create';
    return this.svcAcl.hasCredential(`affiliation.${prefix}.prop.${field}`, this.currentJobType);
  }

  ngOnDestroy() {
    this.onChangeSubjectSub.unsubscribe();
  }

  disableDepartmentOnType() {
    return ['INDUSTRY', 'ASSOCIATION', 'PRIVATE_PRACTICE'].includes(this.model.type);
  }

  disableNameForCompilerAndReviewer(): boolean {
    return (
      this.disableNameField &&
      (this.svcAcl.hasRole(Roles.PersonCreator) ||
        this.svcAcl.hasRole(Roles.PersonCompilerIdent) ||
        this.svcAcl.hasRole(Roles.PersonCompiler) ||
        this.svcAcl.hasRole(Roles.PersonReviewer) ||
        this.svcAcl.hasRole(Roles.ProfileCompiler))
    );
  }

  trackByIndex(index: number): number {
    return index;
  }

  removeFromByIndex(from: any[], idx: number): void {
    from.splice(idx, 1);
  }

  pushItemToList(list: any[]): void {
    list.push('');
  }
}
