<span [ngClass]="{ wide: wide }">
  <div class="input-group">
    <div class="search-wrapper">
      <input
        type="search"
        class="di-search form-control ng-trim-ignore"
        [ngClass]="{ loading: isSearching }"
        [ngbTypeahead]="searchPeople"
        [focusFirst]="false"
        [resultTemplate]="searchResultTemplate"
        (selectItem)="onChangePerson($event.item)"
        [(ngModel)]="person"
        (ngModelChange)="onChangePersonInline($event)"
        [inputFormatter]="inline ? formatTitle : null"
        [editable]="!inline"
        [disabled]="!!disabled"
        [required]="!!required"
      />

      <i class="fa fa-spinner fa-pulse" *ngIf="isSearching"></i>
    </div>

    <div class="input-group-append" *ngIf="inline">
      <a
        class="btn btn-secondary"
        href="/person/detail/{{ person._id }}"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Open person in a new tab"
        *ngIf="person?._id"
      >
        <i class="fa fa-external-link dirt-link-pointer" aria-hidden="true"></i>
      </a>

      <!-- Can add more buttons to the group if needed -->
      <ng-content></ng-content>
    </div>
  </div>

  <ng-container *ngIf="!!creationRequestHandler">
    <a href="javascript:void(0)" class="small pull-right pt-1 pr-2" (click)="onRequestPerson()"> Request Person </a>

    <br />
  </ng-container>
</span>

<!-- SearchAutoComplete Result Template-->
<ng-template #searchResultTemplate let-r="result" let-t="term">
  <div class="di-sac-person">
    <div class="di-sac-kol-id pull-right">{{ r.kolId }}</div>
    <div class="di-sac-name">
      <span class="di-sac-info"
        >{{ r.firstName }} {{ r.middleName }} {{ r.lastName }}
        <small>{{ r.affiliationName }} {{ r.affiliationDepartment }}</small></span
      >
      <span class="di-sac-status">{{ r._meta?.status || '-' | capitalize }}</span>
    </div>
  </div>
</ng-template>
