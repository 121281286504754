import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';

import { EventComponent } from './event.component';
import { EventListComponent } from './list/list.component';
import { EventDetailComponent } from './detail/detail.component';
import { EventCreateComponent } from './create/create.component';
import { EventSeriesListComponent } from '../event-series/list/list.component';
import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';
import { EventModule } from './event.module';

const eventRoutes: Routes = [
  {
    path: 'event',
    component: EventComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'create',
        component: EventCreateComponent,
      },
      {
        path: 'detail/:id',
        component: EventDetailComponent,
        canActivate: [StickyFilterGuard],
      },
      {
        path: 'list',
        component: EventListComponent,
        data: { acl: 'event.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
    data: { title: 'Event' },
  },
];

export const eventRouting: ModuleWithProviders<EventModule> = RouterModule.forChild(eventRoutes);
