import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { ACL } from '../../../shared/acl/acl.service';
import { PersonAPI } from '../../../person/shared/api.service';
import { Person } from '../../../person/shared/person';
import { Affiliation } from '../affiliation';
import { PersonAffiliationLfka } from '../../../person/shared/person-affiliation-lfka';

@Component({
  selector: 'dirt-affiliation-people',
  templateUrl: 'people.component.html',
  styleUrls: ['people.component.scss'],
})
export class AffiliationPeopleComponent implements OnInit, OnChanges {
  @Input()
  affiliationId: string;

  people: Person[] = [];
  peopleLfka: Person[] = [];

  isLoading: boolean;
  isLoadingStarted: boolean;

  private alreadyLoaded: { [affiliationId: string]: { people: Person[]; peopleLfka: Person[] } } = {};

  constructor(private readonly svcACL: ACL, private readonly svcPerson: PersonAPI) {}

  ngOnInit() {
    this.findAttachedPeople(this.affiliationId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.affiliationId &&
      !changes.affiliationId.firstChange &&
      changes.affiliationId.previousValue !== changes.affiliationId.currentValue
    ) {
      this.findAttachedPeople(changes.affiliationId.currentValue);
    }
  }

  isPrimaryAffiliation(personAffiliations: Affiliation[]): boolean {
    return personAffiliations.some((pa) => pa.primary && pa.id === this.affiliationId);
  }

  isPrimaryAffiliationLfka(personAffiliations: PersonAffiliationLfka[]): boolean {
    return personAffiliations.some(
      (pa) => pa.positions && pa.positions.some((pp) => pp.primary) && pa.id === this.affiliationId
    );
  }

  private async findAttachedPeople(affiliationId): Promise<void> {
    if (this.alreadyLoaded[affiliationId]) {
      this.people = this.alreadyLoaded[affiliationId].people;
      this.peopleLfka = this.alreadyLoaded[affiliationId].peopleLfka;
      return;
    } // else we have no choice

    this.isLoading = true;
    this.isLoadingStarted = true;

    this.people = await this.svcPerson
      .find(null, 50, 0, '+affiliations.primary', { affiliations: [affiliationId] })
      .toPromise();

    this.peopleLfka = await this.svcPerson.find(null, 50, 0, null, { affiliationsLfka: [affiliationId] }).toPromise();

    this.isLoading = false;
    // remember from there
    this.alreadyLoaded[affiliationId] = { people: this.people, peopleLfka: this.peopleLfka };
  }
}
