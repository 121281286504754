import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET, MediaType, Produces, Path } from '../../shared/services/rest/rest.service';

import { APIService } from '../../shared/services/api/api.service';
import { Payment } from './payment';

@Injectable()
export class PaymentAPI extends APIService {
  @GET('payments/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Payment> {
    return;
  }
}
