import { Router } from '@angular/router';
import { firstValueFrom, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import { OnInit, Component, Input, OnDestroy } from '@angular/core';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';

import { Account } from '../shared/account';
import { AccountAPI } from '../shared/account-api.service';
import { ACL } from '../../shared/acl/acl.service';

@Component({
  selector: 'dirt-account-simple-list',
  templateUrl: './simple-list.component.html',
  styleUrls: ['./simple-list.component.scss'],
})
export class AccountSimpleListComponent implements OnInit, OnDestroy {
  @Input()
  parentId: string;

  accounts: Account[];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 30;
  pagingSkip = 0;

  // Sorting / Filtering settings
  searchCtrl: FormControl = new FormControl('');

  private searchTerm: string;

  private sort = '-_id';

  private destroy$: Subject<boolean> = new Subject();

  isPriorityEditable: boolean;

  constructor(private readonly router: Router, private readonly svcAccount: AccountAPI, private readonly svcACL: ACL) {}

  ngOnInit(): void {
    this.isPriorityEditable = this.svcACL.hasCredential('account.update.prop.priority');

    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.searchTerm = term;
        this.doLoad();
      });

    this.doLoad();
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /** just avoid useless rendering if we can */
  trackById(index: number, account: Account): string {
    return account.id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getAccounts();
  }

  onSort(field: string): void {
    this.sort = field;

    this.getAccounts();
  }

  async onDelete(id: string): Promise<void> {
    if (!this.svcACL.hasCredential('account.delete')) {
      return;
    }

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    await firstValueFrom(this.svcAccount.deleteById(id));

    // wait for ES to include the result, avoid confusion
    try {
      await firstValueFrom(this.svcAccount.waitEs(id, true));
    } catch (_e) {} // (just best effort)

    this.getAccounts();
    this.getAccountsCount();
  }

  navigateTo(id: string): void {
    if (!id) {
      return;
    }

    this.router.navigate(['/account/detail', id]);
  }

  private doLoad(): void {
    this.resetPagination();
    this.getAccounts();
    this.getAccountsCount();
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getAccounts(): void {
    this.isLoading = true;

    this.svcAccount
      .find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, { parent: this.parentId })
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((accounts) => {
        this.accounts = accounts;
      });
  }

  private getAccountsCount(): void {
    this.svcAccount.count(this.searchTerm, { parent: this.parentId }).subscribe((count) => {
      this.total = count;
    });
  }

  private getMergedAccount(localData: Account, remoteData: Account): Account {
    localData.updatedAt = remoteData.updatedAt;
    localData._version = remoteData._version;
    return localData;
  }

  setPriority(account: Account, priority: number): void {
    if (!priority || (account.priority && account.priority === priority)) {
      return;
    }

    this.svcAccount.update(account.id, { priority } as Account).subscribe((updatedAccount) => {
      this.accounts = this.accounts.map((acc) => {
        if (acc.id === updatedAccount.id) {
          return this.getMergedAccount(acc, updatedAccount);
        }
        return acc;
      });
    });
  }
}
