import { Component } from '@angular/core';

import { Auth } from '../../services/auth/auth.service';

@Component({
  selector: 'dirt-logout',
  templateUrl: 'logout.component.html',
  styleUrls: ['logout.component.scss'],
  providers: [Auth],
})
export class LogoutComponent {
  constructor(private readonly svcAuth: Auth) {}

  async logout(): Promise<void> {
    await this.svcAuth.logout();
  }
}
