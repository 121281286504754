import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router, PRIMARY_OUTLET } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Subscription, forkJoin, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, first } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ACL } from '../../shared/acl/acl.service';
import { Association } from '../shared/association';
import { AssociationAPI } from '../shared/association-api.service';
import { AssociationObsolete } from '../shared/constant/obsolete.enum';
import { Value } from '../../shared/services/value/value';
import { ValueAPI } from '../../shared/services/value/value-api.service';
import { ValueType } from '../../shared/enum/value-type.enum';
import { BulkUpdateOptions } from '../../common/bulk-update/bulk-update-options';
import { buildBulkUpdateConfig } from '../shared/config/bulk-update-config';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { BulkTrackingAPI } from '../../common/bulk/shared/api.service';
import { BulkModalComponent, BULK_MODAL_OPTIONS } from '../../common/bulk/component/bulk-modal/bulk-modal.component';

@Component({
  selector: 'dirt-association-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class AssociationListComponent implements OnInit, OnDestroy {
  @Input()
  parentId: string;

  @Input()
  obsolete: true;

  associations: Array<Association>;
  sub: Subscription;

  isLoading: boolean;
  isSavingMaintenance: string;
  isQueryParamsRestored = false;

  // pagination settings
  total: any;
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  sort = '-_id';
  filter: any;
  searchTerm: string;
  searchCtrl: FormControl = new FormControl('');

  countryValues: Value[] = [];

  bulkUpdateConfig: BulkUpdateOptions<AssociationAPI, Association>;

  wndw: Window = window; // be able to override in tests

  bulkListDelegate: BulkDelegate = {
    title: 'Bulk Import',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListUpload.bind(this),
    specificNotifyReloadFct: this.getAssociations.bind(this),
  };

  bulkSearchDelegate: BulkDelegate = {
    title: 'Bulk Search',
    type: BulkType.SEARCH,
    specialHint: 'Perform a bulk Google research with spreadsheet data',
    specificStartFct: this.handleBulkSearchUpload.bind(this),
  };

  allReviewed: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private svcAssociation: AssociationAPI,
    private svcAcl: ACL,
    private svcModal: NgbModal,
    private svcValue: ValueAPI,
    private svcBulkUpdate: BulkTrackingAPI
  ) {}

  ngOnInit() {
    this.bulkUpdateConfig = buildBulkUpdateConfig(this.svcAssociation);
    this.restoreFromQueryParams();
    this.sub = this.route.params.subscribe((params) => {
      this.parentId = params['id'];
      this.doLoad();
    });

    this.svcValue
      .find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title')
      .toPromise()
      .then((data) => {
        this.countryValues = data;
      });

    this.attachSearchHandler();
  }

  getCountryTitle(code: string): string {
    return (this.countryValues.find((o) => o.code === code) || { title: '' }).title;
  }

  doLoad(): void {
    if (this.parentId && !this.isQueryParamsRestored) {
      return;
    }
    this.storeAsQueryParams();
    this.resetPagination();
    this.getCount();
    this.getAssociations();
  }

  getAssociations() {
    this.isLoading = true;
    const observables = [
      this.svcAssociation.find(
        this.searchTerm,
        this.pagingLimit,
        this.pagingSkip,
        this.sort,
        this.filter,
        this.parentId
      ),
    ];

    if (this.filter && this.filter['_meta.assignee'] && this.filter['_meta.assignee'].length > 0) {
      const { '_meta.assignee': assignee, ...remainingFilters } = this.filter;
      observables.push(
        this.svcAssociation.find(
          this.searchTerm,
          this.pagingLimit,
          this.pagingSkip,
          this.sort,
          {
            ...remainingFilters,
            '_meta.lastAssignee': assignee,
            '_meta.status': 'DONE',
          },
          this.parentId
        )
      );
    }

    forkJoin(observables).subscribe(
      ([result1, result2 = []]) => {
        this.associations = [...result1, ...result2];
      },
      null,
      () => {
        this.setAllReviewedFlag();
        this.isLoading = false;
      }
    );
  }

  onBulkUpdateRefresh() {
    this.getAssociations();
  }

  getCount() {
    const observables = [this.svcAssociation.count(this.parentId, this.searchTerm, this.filter)];
    if (this.filter && this.filter['_meta.assignee'] && this.filter['_meta.assignee'].length > 0) {
      const { '_meta.assignee': assignee, ...remainingFilters } = this.filter;
      observables.push(
        this.svcAssociation.count(this.parentId, this.searchTerm, {
          ...remainingFilters,
          '_meta.lastAssignee': assignee,
          '_meta.status': 'DONE',
        })
      );
    }

    forkJoin(observables).subscribe(([result1, result2 = { count: 0 }]) => {
      this.total = {
        count: result1.count + result2.count,
      };
    });
  }

  getPage(page: number) {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getAssociations();
  }

  setPriority(association: Association, priority: number): void {
    if (!priority) {
      return;
    }

    if (association.priority === priority) {
      return;
    }

    this.svcAssociation.upsert(association.id, { priority: priority } as Association).subscribe(() => {});
  }

  toggleMaintenance(association: Association): void {
    const maintenanceDisabled = !(association._meta && association._meta.maintenanceDisabled);

    this.isSavingMaintenance = association.id;

    this.svcAssociation
      .upsert(association.id, { _meta: { maintenanceDisabled } } as Association)
      .pipe(tap(() => delete this.isSavingMaintenance))
      .subscribe((resp: Association) => (association._meta = resp._meta));
  }

  isPriorityEditable(): boolean {
    return this.svcAcl.hasCredential('association.update.prop.priority');
  }

  navigateTo(route, e) {
    if (e && /dirt-asso-name|star-icon/.test(e.target.className)) {
      return;
    }

    if (this.parentId) {
      route.push({ parentId: this.parentId });
    }

    this.router.navigate(route);
  }

  resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  onSort(field: string): void {
    this.sort = field;
    this.storeAsQueryParams();
    this.resetPagination();
    this.getAssociations();
  }

  attachSearchHandler(): void {
    this.searchCtrl.valueChanges.pipe(distinctUntilChanged(), debounceTime(400)).subscribe((val) => {
      // search field gets fired with `undefined` value after init
      if (typeof val !== 'undefined') {
        this.doLoad();
      }
    });
  }

  onDelete(eventId: string, $event) {
    $event.stopPropagation();

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.svcAssociation.deleteById(eventId).subscribe({
      next: () => {
        this.getAssociations();
        this.getCount();
      },
      error: (err) => {
        alert(`Cannot delete: ${err.error.message}`);
      },
    });
  }

  setFilter(filter: any): void {
    this.filter = filter;

    this.resetPagination();
    this.getAssociations();
    this.getCount();
  }

  isObsolete(association: Association): boolean {
    return AssociationObsolete.YES === association.obsolete;
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  /**
   * Store applied search text as route query params
   */
  storeAsQueryParams(): void {
    if (this.searchTerm || this.sort) {
      const filters = {
        ...(this.searchTerm && { searchTerm: this.searchTerm }),
        ...(this.sort && { sortField: this.sort }),
      };
      this.router.navigate([], { queryParams: filters });
    }
  }

  /**
   * Restore filters saved as query params
   */
  restoreFromQueryParams(): void {
    const tree = this.router.parseUrl(this.router.url);
    const segmentGroup = tree.root.children[PRIMARY_OUTLET];

    if (
      segmentGroup?.segments?.map((it) => it.path).join('/') !== 'association/list' &&
      segmentGroup?.segments
        ?.slice(0, 2)
        ?.map((it) => it.path)
        .join('/') !== 'association/detail'
    ) {
      // do not needlessly restore filters in pages that don't need it
      return;
    }

    this.route.queryParams.pipe(first()).subscribe((params) => {
      if (params.searchTerm) {
        this.searchTerm = params.searchTerm;
      }
      if (params.sortField) {
        this.sort = params.sortField;
      }
      this.isQueryParamsRestored = true;
      this.doLoad();
    });
  }

  hasAclBulkUpdate() {
    return this.svcAcl.hasCredential('association.list.bulkUpdate');
  }
  hasAclBulkList() {
    return this.svcAcl.hasCredential('association.bulkList');
  }
  hasOnlyAclBulkList() {
    return !this.hasAclBulkUpdate() && this.hasAclBulkList();
  }

  canEditSubAssociation() {
    return this.svcAcl.hasCredential('subassociation.update');
  }

  toggleAllReviewed($event: any) {
    $event.stopPropagation();
    this.isLoading = true;
    this.bulkUpdate({
      reviewed: $event.target.checked,
    });
  }

  bulkUpdate(values: { [key: string]: any }): Promise<void> {
    this.isLoading = true;
    if (this.parentId) {
      return this.svcAssociation
        .bulkUpdate({
          ids: this.associations.map((mb) => mb.id),
          values,
        })
        .toPromise()
        .then((resp) => {
          this.bulkUpdateStatus(resp.bulkTrackingId).then(() => {
            this.getAssociations();
            return;
          });
        });
    }
  }

  onSubAssociationReviewedChange(associations: Association, $event: any) {
    $event.stopPropagation();
    associations.reviewed = $event.target.checked;
    this.onEdit(associations);
  }

  onEdit(associations: Association) {
    this.svcAssociation.update(associations.id, associations).subscribe((resp) => {
      this.getAssociations();
      this.setAllReviewedFlag();
    });
  }
  setAllReviewedFlag() {
    this.allReviewed = this.associations.every((as) => as.parent && as.reviewed === true);
  }
  bulkUpdateStatus(trakingID: string, triesCountLeft = 5): Promise<void> {
    return new Promise((resolve) => {
      this.svcBulkUpdate
        .findById(trakingID)
        .toPromise()
        .then((resp) => {
          if (!resp.complete) {
            if (!triesCountLeft) return resolve();
            setTimeout(() => {
              resolve(this.bulkUpdateStatus(trakingID, triesCountLeft - 1));
            }, 3000);
          } else resolve();
        });
    });
  }

  // BULK MODAL LOGIC

  onOpenBulkListDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.bulkListDelegate;
  }

  onOpenBulkSearchDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.bulkSearchDelegate;
  }

  private handleBulkListUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcAssociation.startBulkListUpload(file, secondEyes);
  }

  private handleBulkSearchUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcAssociation.startBulkSearchUpload(file, secondEyes);
  }
}
