import { ActivatedRoute, Router } from '@angular/router';
import { capitalize } from 'lodash';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { format } from 'date-fns';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ACL } from '../../shared/acl/acl.service';
import { BulkDelegate } from '../../common/bulk/bulk.delegate';
import { BulkModalComponent, BULK_MODAL_OPTIONS } from '../../common/bulk/component/bulk-modal/bulk-modal.component';
import { BulkType } from '../../common/bulk/shared/bulk-types';
import { Domain } from '../shared/domain';
import { DomainAPI } from '../shared/domain-api.service';
import { DomainMetaStatus } from '../shared/constant/meta-status.enum';
import { DomainStatus } from '../shared/constant/status.enum';
import { Filter, FiltersComponent, FilterType } from '../../shared/components/filters/filters.component';

@Component({
  selector: 'dirt-domain-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class DomainListComponent implements OnInit, OnDestroy {
  domains: Domain[];

  isLoading: boolean;

  // Pagination settings
  total = { count: 0 };
  pagingPage = 1;
  pagingLimit = 100;
  pagingSkip = 0;

  // Sorting / Filtering settings
  searchCtrl: FormControl = new FormControl('');
  filter: { [key: string]: any };
  filters: Filter[] = [];
  filterBarConfig = {
    '_meta.status': {
      label: 'Status',
      format(value: string) {
        return capitalize(value).replace(/_/g, ' ');
      },
    },
    status: {
      label: 'Compliance Status',
      format(value: string) {
        return capitalize(value).replace(/_/g, ' ');
      },
    },
    'checked.at': {
      label: 'Checked At',
      format: (value: { $gte: Date; $lte: Date }) => {
        return `${format(value.$gte, 'yyyy/MM/dd')} - ${format(value.$lte, 'yyyy/MM/dd')}`;
      },
    },
  };

  bulkListDelegate: BulkDelegate = {
    title: 'Bulk Import',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListUpload.bind(this),
    specificNotifyReloadFct: this.doLoad.bind(this),
  };

  bulkListCompliantCountriesDelegate: BulkDelegate = {
    title: 'Bulk Import Compliant Countries',
    type: BulkType.LIST,
    specificStartFct: this.handleBulkListCompliantCountriesUpload.bind(this),
    specificNotifyReloadFct: this.doLoad.bind(this),
  };

  private searchTerm: string;

  private sort = '-_id';

  @ViewChild(FiltersComponent)
  private filterRef: FiltersComponent;

  private destroy$: Subject<boolean> = new Subject();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly svcDomain: DomainAPI,
    private readonly svcACL: ACL,
    private readonly svcModal: NgbModal
  ) {}

  ngOnInit(): void {
    this.filters.push(
      {
        title: 'Status',
        key: '_meta.status',
        type: FilterType.MULTI_VALUE,
        values: Object.keys(DomainMetaStatus)
          .sort()
          .map((statusKey) => ({ title: DomainMetaStatus[statusKey], displayValue: DomainMetaStatus[statusKey] })),
      },
      {
        title: 'Compliance Status',
        key: 'status',
        type: FilterType.MULTI_VALUE,
        values: Object.keys(DomainStatus)
          .sort()
          .map((statusKey) => ({ title: DomainStatus[statusKey], displayValue: DomainStatus[statusKey] })),
      },
      {
        title: 'Checked',
        key: 'checked.at',
        type: FilterType.DATE_RANGE,
      }
    );

    this.route.queryParams.pipe(take(1)).subscribe((params) => {
      if (params.searchTerm) {
        this.searchTerm = params.searchTerm;
        this.searchCtrl.setValue(this.searchTerm, { emitEvent: false }); // restore search term
      }
      if (params.sortField) {
        this.sort = params.sortField;
      }

      this.doLoad();
    });

    this.searchCtrl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(400), distinctUntilChanged())
      .subscribe((term) => {
        this.searchTerm = term;
        this.doLoad();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  /** just avoid useless rendering if we can */
  trackById(index: number, domain: Domain): string {
    return domain.id;
  }

  getPage(page: number): void {
    this.pagingPage = page;
    this.pagingSkip = (this.pagingPage - 1) * this.pagingLimit;
    this.getDomains();
  }

  onSort(field: string): void {
    this.sort = field;

    this.storeFiltersAsQueryParams();
    this.getDomains();
  }

  onFilter(filter: { [key: string]: any }): void {
    this.filter = filter;
    this.doLoad();
  }

  onDelete(id: string): void {
    if (!this.svcACL.hasCredential('domain.delete')) {
      return;
    }

    if (!window.confirm('Do you want to remove this entry?')) {
      return;
    }

    this.svcDomain.deleteById(id).subscribe(() => {
      this.getDomains();
      this.getDomainsCount();
    });
  }

  onFilterClear(): void {
    this.filterRef.doClear();
  }

  onFilterValueRemove(item: { key: string; value: any }): void {
    this.filterRef.removeValue(item.key, item.value);
  }

  onOpenBulkListDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.bulkListDelegate;
  }

  onOpenBulkListCompliantCountriesDialog(): void {
    const modal = this.svcModal.open(BulkModalComponent, BULK_MODAL_OPTIONS);
    modal.componentInstance.delegate = this.bulkListCompliantCountriesDelegate;
  }

  navigateTo(id: string): void {
    if (!id) {
      return;
    }

    this.router.navigate(['/domain/detail', id]);
  }

  private doLoad(): void {
    this.storeFiltersAsQueryParams();
    this.resetPagination();
    this.getDomains();
    this.getDomainsCount();
  }

  private storeFiltersAsQueryParams(): void {
    if (this.searchTerm || this.sort) {
      const filters = {
        ...(this.searchTerm && { searchTerm: this.searchTerm }),
        ...(this.sort && { sortField: this.sort }),
      };
      this.router.navigate([], { queryParams: filters });
    }
  }

  private resetPagination(): void {
    this.pagingPage = 1;
    this.pagingSkip = 0;
  }

  private getDomains(): void {
    this.isLoading = true;

    if (this.sort === '-_id' && this.searchTerm) {
      // Remove sorting by id when term is not empty (we want list ordered by closest match)
      this.sort = '';
    } else if (!this.sort && !this.searchTerm) {
      // Revert sorting by id when term is empty
      this.sort = '-_id';
    }

    this.svcDomain
      .find(this.searchTerm, this.pagingLimit, this.pagingSkip, this.sort, this.filter)
      .pipe(tap(() => (this.isLoading = false)))
      .subscribe((domains) => {
        this.domains = domains;
      });
  }

  private getDomainsCount(): void {
    this.svcDomain.count(this.searchTerm, this.filter).subscribe((count) => {
      this.total = count;
    });
  }

  private handleBulkListUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcDomain.startBulkListUpload(file, secondEyes);
  }

  private handleBulkListCompliantCountriesUpload(file: File, secondEyes: string, opts: any): Observable<string> {
    return this.svcDomain.startBulkListCompliantCountriesUpload(file, secondEyes);
  }
}
