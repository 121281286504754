<section class="dirt-user-login" *ngIf="false">
  <div class="alert alert-danger" [hidden]="!invalidLogin">The email and password you entered are not valid.</div>
  <div class="login-box">
    <div class="row">
      <div class="col-md-6 login-brand">
        <h1>CLEAN</h1>
      </div>

      <div class="col-md-6 login-form">
        <form novalidate #frmLogin="ngForm">
          <div class="form-group">
            <label for="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              class="form-control"
              [(ngModel)]="login.email"
              autofocus
              required
            />
          </div>

          <div class="form-group">
            <label for="password">Password</label>
            <input
              type="password"
              id="password"
              name="password"
              class="form-control"
              [(ngModel)]="login.password"
              required
            />
          </div>

          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!frmLogin.form.valid || isSubmitting"
            (click)="onSubmit(frmLogin.value)"
          >
            <span>Login</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</section>
