import { Component, HostListener, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ACL } from '../../shared/acl/acl.service';
import { Roles } from '../../shared/acl/roles';
import { Person } from '../shared/person';
import { PersonAPI } from '../shared/api.service';
import { PersonStatus } from '../shared/constant/status.enum';
import { deleteEmptyKeyFields } from '../shared/person-util';
import { PersonFormComponent } from '../shared/form/form.component';
import { sortAffiliations } from '../shared/person-affiliation-util';

@Component({
  selector: 'dirt-person-create',
  templateUrl: 'create.component.html',
  styleUrls: ['create.component.scss'],
})
export class PersonCreateComponent implements OnInit {
  person: Person = new Person();
  isFormShown = false;
  isSubmitting = false;

  @ViewChild('duplicateAlertDialog') modalContent: TemplateRef<any>;

  @ViewChild(PersonFormComponent, { static: false })
  frmPerson: PersonFormComponent;

  modalOption: NgbModalOptions = { backdrop: 'static' };
  modalRef: NgbModalRef;
  duplicatePersonData: { kolId: string; id: string } = null;

  constructor(
    private router: Router,
    private svcAcl: ACL,
    private svcPerson: PersonAPI,
    protected modalService: NgbModal
  ) {}

  ngOnInit() {}

  onSubmit(person: Person): void {
    if (!this.frmPerson.isExpertTypeSpecialityDegreeRelationValid() && !this.doConfirmSubmissionWithDegreeErrors()) {
      return;
    }

    // NB! default status should be set
    let status = PersonStatus.READY_FOR_COMPILATION;

    if (this.svcAcl.hasRole(Roles.PersonContributor)) {
      status = PersonStatus.PENDING_VERIFICATION;
    }

    person._meta = { status };

    this.isSubmitting = true;
    deleteEmptyKeyFields(person);
    sortAffiliations(person);
    this.svcPerson.create(person).subscribe(
      (resp) => {
        this.isSubmitting = false;
        this.goBack();
      },
      (error) => {
        this.handleDuplicateExternalIdError(error);
        this.isSubmitting = false;
      },
      () => (this.isSubmitting = false)
    );
  }

  goBack(): void {
    this.router.navigate(['/person']);
  }

  handleDuplicateExternalIdError(e) {
    if (e.error && e.error.code && e.error.code === 'DUPLICATE_EXTERNAL_ID') {
      if (e.error.data && e.error.data.kolId && e.error.data.id) {
        this.duplicatePersonData = {
          kolId: e.error.data.kolId,
          id: e.error.data.id,
        };
        this.modalService.open(this.modalContent, this.modalOption).result.then(
          () => {
            this.duplicatePersonData = null;
          },
          () => {
            this.duplicatePersonData = null;
          }
        );
      }
    }
  }

  doConfirmSubmissionWithDegreeErrors(): boolean {
    let message = 'You have a mistake in some of the following fields (Degrees/Specialities/Expert type)';
    message += '\nAre you sure you want to submit the profile?';

    return window.confirm(message);
  }
}
