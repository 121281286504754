import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET, Path, Query, MediaType, Produces, BaseUrl } from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { UserGroup } from './user-group';
import { environment } from '../../../environments/environment';

@Injectable()
export class UserGroupAPI extends APIService {
  @GET('user-groups/')
  @Produces(MediaType.JSON)
  public find(
    @Query('limit') limit?: number,
    @Query('sort') sort?: string,
    @Query('skip') skip?: number,
    @Query('userId') userId?: string
  ): Observable<UserGroup[]> {
    return null;
  }

  @GET('user-groups/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<UserGroup> {
    return null;
  }
}
