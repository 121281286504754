import { NgModule } from '@angular/core';
import { AgGridModule } from 'ag-grid-angular/main';
import { LicenseManager } from 'ag-grid-enterprise/main';

import { environment } from '../';
import { SharedModule } from '../shared/shared.module';
import { CommentModule } from '../comment/comment.module';
import { ClinicalTrialModule } from '../clinical-trial/clinical-trial.module';
import { GuidelineModule } from '../guideline/guideline.module';
import { PaymentModule } from '../payment/payment.module';
import { PaymentNonusModule } from '../payment-nonus/payment-nonus.module';
import { PublicationModule } from '../publication/publication.module';
import { BulkListModule } from '../common/bulk-list/bulk-list.module';
import { BulkUpdateModule } from '../common/bulk-update/bulk-update.module';
import { VideoModule } from '../video/video.module';
import { GrantModule } from '../grant/grant.module';

import { ProfileComponent } from './profile.component';
import { ProfileListComponent } from './list/list.component';
import { ProfileDetailComponent } from './detail/detail.component';
import { ProfileNextComponent } from './next/next.component';
import { ProfileFilterComponent } from './shared/filter/filter.component';
import { ProfileSubmitComponent } from './shared/submit/submit.component';
import { ProfileSummaryComponent } from './shared/summary/summary.component';
import { ProfilePriorityComponent } from './shared/priority/priority.component';
import { ProfileViewModeComponent } from './shared/view-mode/view-mode.component';
import { ProfileDirectLinkComponent } from './shared/modal/direct-link/direct-link.component';
import { ProfileDirectLinkService } from './shared/modal/direct-link/direct-link.service';
import { ProfilePersonFormComponent } from './person-form/form.component';
import { ProfilePublicationListComponent } from './subsection/publication/list/list.component';
import { ProfileContributionListComponent } from './subsection/contribution/list/list.component';
import { ProfileMembershipListComponent } from './subsection/membership/list/list.component';
import { ProfileClinicalTrialListComponent } from './subsection/clinical-trial/list/list.component';
import { ProfileGuidelineListComponent } from './subsection/guideline/list/list.component';
import { ProfilePaymentListComponent } from './subsection/payment/list/list.component';
import { ProfilePaymentNonusListComponent } from './subsection/payment-nonus/list/list.component';
import { ProfileTagsComponent } from './shared/tags/tags.component';
import { ProfileVideoListComponent } from './subsection/video/list/list.component';
import { ProfileGrantListComponent } from './subsection/grant/list/list.component';

import { ProfileGridMatchManualRendererComponent } from './shared/grid/renderer/match-manual-renderer.component';
import { UserCellRendererComponent } from './shared/grid/user-row-renderer/user-cell-renderer.component';

import { profileRouting } from './profile.routes';
import { ProfileAPI } from './shared/api.service';
import { ProfilePublicationAPI } from './subsection/publication/shared/api.service';
import { ProfileVideoAPI } from './subsection/video/shared/api.service';
import { ProfileMembershipAPI } from './subsection/membership/shared/api.service';
import { ProfileClinicalTrialAPI } from './subsection/clinical-trial/shared/api.service';
import { ProfilePaymentAPI } from './subsection/payment/shared/api.service';
import { ProfilePaymentNonusAPI } from './subsection/payment-nonus/shared/api.service';
import { ProfileContributionAPI } from './subsection/contribution/shared/api.service';
import { ProfileGuidelineAPI } from './subsection/guideline/shared/api.service';
import { FeedbackReportLinkComponent } from './shared/grid/feedback-report-link/feedback-report-link.component';
import { CheckerRendererComponent } from './shared/grid/renderer/checker-renderer/checker-renderer.component';
import { ProfileQcNextComponent } from './qc-next/qc-next.component';
import { TimeTrackModule } from '../common/timetrack/time-track.module';
import { ProfileCopyComponent } from './profile-copy/profile-copy.component';
import { CopyButtonRendererComponent } from './shared/grid/copy-button-renderer/copy-button-renderer.component';
import { BulkModule } from '../common/bulk/bulk.module';
import { ProfileVideoLinkComponent } from './shared/modal/video-link/video-link.component';
import { ProfileVideoLinkService } from './shared/modal/video-link/video-link.service';
import { ProfileVideoNextComponent } from './video-next/video-next.component';
import { QCCommentComponent } from './shared/grid/qc-comment/qc-comment.component';
import { QCCommentService } from './shared/grid/qc-comment/qc-comment.service';
import { ProfileGrantAPI } from './subsection/grant/shared/api.service';
import { ProfilePodcastListComponent } from './subsection/podcast/list/list.component';
import { ProfilePodcastAPI } from './subsection/podcast/shared/api.service';
import { PodcastModule } from '../podcast/podcast.module';

@NgModule({
  imports: [
    AgGridModule.withComponents([
      ProfileGridMatchManualRendererComponent,
      UserCellRendererComponent,
      FeedbackReportLinkComponent,
      CopyButtonRendererComponent,
      CheckerRendererComponent,
      QCCommentComponent,
    ]),
    SharedModule,
    CommentModule,
    ClinicalTrialModule,
    GuidelineModule,
    PaymentModule,
    PaymentNonusModule,
    PublicationModule,
    profileRouting,
    BulkUpdateModule,
    BulkListModule,
    TimeTrackModule,
    BulkModule,
    GrantModule,
    VideoModule,
    PodcastModule,
  ],
  declarations: [
    ProfileComponent,
    ProfileListComponent,
    ProfileDetailComponent,
    ProfileNextComponent,
    ProfileFilterComponent,
    ProfileSubmitComponent,
    ProfileSummaryComponent,
    ProfilePriorityComponent,
    ProfileViewModeComponent,
    ProfileDirectLinkComponent,
    ProfileVideoLinkComponent,
    ProfilePublicationListComponent,
    ProfileContributionListComponent,
    ProfileMembershipListComponent,
    ProfileClinicalTrialListComponent,
    ProfilePaymentListComponent,
    ProfilePaymentNonusListComponent,
    ProfileGuidelineListComponent,
    ProfileGridMatchManualRendererComponent,
    UserCellRendererComponent,
    ProfileTagsComponent,
    FeedbackReportLinkComponent,
    CopyButtonRendererComponent,
    CheckerRendererComponent,
    ProfileQcNextComponent,
    ProfilePersonFormComponent,
    ProfileCopyComponent,
    ProfileGrantListComponent,
    ProfileVideoListComponent,
    ProfileVideoNextComponent,
    QCCommentComponent,
    ProfilePodcastListComponent,
  ],
  exports: [
    ProfileNextComponent,
    ProfileSummaryComponent,
    AgGridModule,
    ProfileQcNextComponent,
    ProfileVideoNextComponent,
  ],
  providers: [
    ProfileAPI,
    ProfilePublicationAPI,
    ProfileContributionAPI,
    ProfileClinicalTrialAPI,
    ProfileMembershipAPI,
    ProfilePaymentAPI,
    ProfilePaymentNonusAPI,
    ProfileGuidelineAPI,
    ProfileDirectLinkService,
    ProfileVideoLinkService,
    ProfileVideoAPI,
    QCCommentService,
    ProfileGrantAPI,
    ProfilePodcastAPI,
  ],
})
export class ProfileModule {
  constructor() {
    LicenseManager.setLicenseKey(environment['agGridLicense']);
  }
}
