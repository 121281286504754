import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { PersonAPI } from '../../../../person/shared/api.service';
import { PersonBaseinfo } from '../../../../person/shared/person-baseinfo';
import { PersonWithAffiliationModalService } from '../../../../person/shared/modal/person-with-affiliation/person-with-affiliation.service';
import { PersonStatus } from '../../../../person/shared/constant/status.enum';

@Component({
  selector: 'dirt-investigator-single-select',
  templateUrl: 'investigator-single-select.component.html',
  styleUrls: ['investigator-single-select.component.scss'],
})
export class InvestigatorSingleSelectComponent implements OnChanges {
  @Input()
  kolId: string;
  @Output() // (can [(kolId)]
  kolIdChange: EventEmitter<string> = new EventEmitter();
  @Input()
  wide: boolean = false;
  @Input()
  possibleCvLinks: string[];
  @Input()
  bestGuessInput: string = null;
  @Input()
  createAsOnHold: boolean = false; // create as on hold, not as pending verification - so we can decide which to curate
  @Input()
  newPersonDefaultSource: string = null;
  @Input()
  newPersonDefaultProjects: string[] = null;
  @Input()
  disableDisabledCountries: boolean = false;
  @Input()
  qcSessionId: string = null; // if set, pass as URL param
  @Input()
  disabled: boolean = false;
  @Input()
  autoMappingId: string = null;
  @Output()
  personOpened: EventEmitter<string> = new EventEmitter();

  isSearching = false;
  searchTerm: string;

  @Input()
  existPersonInfos: { [kolId: string]: PersonBaseinfo } = {};
  @Input()
  doLoadAdditional: boolean = false; // so main component can itself load first
  // (plus we load on demand - but avoid 70 requests upfront with above)
  addPersonInfos: { [kolId: string]: PersonBaseinfo } = {};

  searchAutoComplete = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(() => (this.isSearching = true)),
      switchMap((term) =>
        this.svcPerson.search(term as any, true).pipe(
          take(1),
          catchError(() => of([]))
        )
      ),
      map((results: any[]) => this.filterResults(results).slice(0, 30 /*ES orders by match score*/)),
      switchMap((results: any[]) =>
        this.svcPerson.getBaseinfo(results.map((r) => r.kolId)).pipe(
          take(1),
          catchError(() => of([]))
        )
      ),
      tap(() => (this.isSearching = false))
    );

  constructor(
    private svcPerson: PersonAPI,
    private svcChangeDetect: ChangeDetectorRef,
    private svcPersonModal: PersonWithAffiliationModalService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadAnotherPerson(changes.kolId?.currentValue);
  }

  private loadAnotherPerson(kolId: string) {
    if (!this.doLoadAdditional || !kolId || this.existPersonInfos[kolId]) {
      return;
    }
    if (undefined === this.addPersonInfos[kolId]) {
      this.addPersonInfos[kolId] = null; // (we're loading)
      this.svcPerson
        .getBaseinfo([kolId])
        .pipe(take(1))
        .subscribe((pbis) => {
          if (pbis.length > 0) {
            pbis.forEach((pbi) => (this.addPersonInfos[pbi.kolId] = pbi));
            this.svcChangeDetect.markForCheck(); // reset placeholder
          }
        });
    } // else (incl. null: we're loading already or have already)
  }

  onChangePerson(person: { kolId: string }) {
    this.svcPerson
      .getBaseinfo([person.kolId])
      .pipe(take(1))
      .subscribe((pbs) => {
        if (pbs[0]) {
          this.addPersonInfos[pbs[0].kolId] = pbs[0];
        }
        this.kolIdChange.emit(person.kolId);
        this.searchTerm = null;
        this.svcChangeDetect.markForCheck(); // reset placeholder
      });
  }
  onClearPerson() {
    this.kolIdChange.emit(null);
    setTimeout(() => (this.searchTerm = null)); // (next apply)
  }

  determinePersonCaption(kolId: string) {
    if (!kolId) {
      return '';
    }
    const baseInfo = this.existPersonInfos[kolId] || this.addPersonInfos[kolId] || null;
    if (baseInfo) {
      return (
        baseInfo.firstName +
        ' ' +
        (baseInfo.middleName || '') +
        ' ' +
        baseInfo.lastName +
        ', ' +
        (baseInfo.affiliationName || '') +
        ' ' +
        (baseInfo.affiliationDepartment || '') +
        ('ON_HOLD' === baseInfo._meta?.status ? ' (on hold)' : '')
      ); // show on hold to identify potential QC
    } else {
      this.loadAnotherPerson(kolId);
      return kolId;
    }
  }

  doBestGuess() {
    this.isSearching = true;
    this.svcPerson
      .search(this.bestGuessInput)
      .pipe(
        take(1),
        catchError(() => of([])),
        map((results: any[]) => this.filterResults(results).slice(0, 1 /*ES orders by match score*/))
      )
      .subscribe((res) => {
        this.isSearching = false;
        if (res[0]) {
          this.onChangePerson(res[0]);
        }
      });
  }

  private filterResults(results) {
    return results.filter(
      (o) =>
        !!o &&
        (this.createAsOnHold || o._meta.status !== PersonStatus.ON_HOLD) && // when we work on hold, we want our own work
        o._meta.status !== PersonStatus.NO_INFO &&
        o._meta.status !== PersonStatus.ID_OUT &&
        o._meta.status !== 'FROZEN' &&
        o._meta.status !== PersonStatus.DUPLICATE &&
        o._meta.status !== PersonStatus.DUPLICATE_SUSPECT &&
        o._meta.status !== PersonStatus.UNABLE_TO_COMPILE
    );
  }

  showPersonModal() {
    this.svcPersonModal
      .open(
        this.possibleCvLinks,
        this.createAsOnHold,
        3,
        this.newPersonDefaultProjects,
        this.newPersonDefaultSource,
        true
      )
      .then((person) => {
        if (!person) {
          // (cancel)
          return;
        }
        this.loadAnotherPerson(person.kolId);
        this.kolIdChange.emit(person.kolId);
        this.searchTerm = null; // (whatever we did search: no longer)
        this.svcChangeDetect.markForCheck(); // reset placeholder
      })
      .catch(() => {}); // (cancel)
  }
}
