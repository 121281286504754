<div class="modal-header">
  <h5 class="modal-title">
    Duplicate: <b>{{ options.person.kolId }}</b>
  </h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <form class="form-group" [ngClass]="{ 'has-danger': isInvalid }">
    <label class="form-control-label mb-3" for="staysIdIn">Search the Person which you would like to stay:</label>
    <div class="input-group">
      <input
        id="staysIdIn"
        class="form-control form-control-danger"
        [ngClass]="{ 'form-control-warning': isInvalid }"
        name="staysIdIn"
        [(ngModel)]="staysId"
        auto="false"
        placeholder="Search a Person ID..."
      />
      <span class="input-group-btn">
        <button class="btn btn-secondary" (click)="onSearch(staysId)" [disabled]="!staysId">
          <i class="fa fa-search" aria-hidden="true" [hidden]="isLoading"></i>
          <i class="fa fa-circle-o-notch fa-spin fa-fw" [hidden]="!isLoading"></i>
        </button>
      </span>
    </div>
  </form>

  <div *ngIf="stays">
    <h4>Decide how to handle IDs and projects</h4>
    <table border="0" class="dirt-dup-compare">
      <tr>
        <th></th>
        <th>Person to discard</th>
        <th>Person to stay</th>
        <th></th>
      </tr>
      <tr>
        <td></td>
        <td>{{ options.person.kolId }} - {{ options.person.firstName }} {{ options.person.lastName }}</td>
        <td>
          <a [routerLink]="['/person/detail', stays.id]" rel="noopener" target="_blank"
            >{{ stays.kolId }} - {{ stays.firstName }} {{ stays.lastName }}</a
          >
        </td>
        <td></td>
      </tr>
      <tr>
        <td>VID</td>
        <td>
          <label
            ><input type="radio" name="vidFrom" [(ngModel)]="vidFrom" value="discard" [disabled]="true" />
            {{ this.options.person.externalIds?.openData || '-' }}</label
          >
        </td>
        <td>
          <label
            ><input type="radio" name="vidFrom" [(ngModel)]="vidFrom" value="stay" [disabled]="true" />
            {{ this.stays.externalIds?.openData || '-' }}</label
          >
        </td>
        <td>
          <label
            ><input type="radio" name="vidFrom" [(ngModel)]="vidFrom" value="neither" [disabled]="true" />
            (empty)</label
          >
        </td>
      </tr>
      <tr>
        <td>NPI</td>
        <td>
          <label
            ><input
              type="radio"
              name="npiFrom"
              [(ngModel)]="npiFrom"
              value="discard"
              [disabled]="!npiFrom || !this.options.person.externalIds?.npi"
            />
            {{ this.options.person.externalIds?.npi || '-' }}</label
          >
        </td>
        <td>
          <label
            ><input
              type="radio"
              name="npiFrom"
              [(ngModel)]="npiFrom"
              value="stay"
              [disabled]="!npiFrom || !this.stays.externalIds?.npi"
            />
            {{ this.stays.externalIds?.npi || '-' }}</label
          >
        </td>
        <td></td>
      </tr>
      <tr>
        <td>Projects</td>
        <td></td>
        <td>Transfer {{ projectNamesDiff.join(', ') || '(none)' }}</td>
      </tr>
    </table>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-sm btn-secondary btn-warning"
    (click)="onSubmit()"
    [disabled]="!stays || isInvalid"
  >
    Report duplicate
  </button>
</div>
