import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  AfterViewChecked,
  ChangeDetectorRef,
} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  from,
  map,
  Observable,
  of,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { NgForm } from '@angular/forms';

import { AccountAPI } from '../../../account/shared/account-api.service';
import { ACL } from '../../../shared/acl/acl.service';
import { Affiliation } from '../../../affiliation/shared/affiliation';
import { AffiliationAPI } from '../../../affiliation/shared/api.service';
import { AffiliationMaintenanceJobModalService } from '../../../affiliation/shared/modal/affiliation-maintenance-job/affiliation-maintenance-job.service';
import { Document } from '../document';
import { DocumentAPI } from '../document-api.service';
import { DocumentConnection } from '../connection';
import { DocumentConnectionsComponent } from '../connection/connections.component';
import { DocumentConnectionsType } from '../constant/connections-type.enum';
import { PersonAffiliationModalService } from '../../../shared/services/modal.service';
import { Utils } from '../../../common/utils';
import { Value } from '../../../shared/services/value/value';
import { ValueAPI } from '../../../shared/services/value/value-api.service';
import { ValueType } from '../../../shared/enum/value-type.enum';
import { DocumentStatus } from '../constant/status.enum';

@Component({
  selector: 'dirt-document-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  exportAs: 'frmDocument',
})
export class DocumentFormComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  @Input()
  model: Document = new Document();

  @Output()
  validityChange: EventEmitter<'VALID' | 'INVALID'> = new EventEmitter();

  isSearchingPreviousVersion = false;

  countries$: Observable<Value[]>;

  formats$: Observable<Value[]>;

  displayCategory: string;

  types: Value[];

  canRequestAffiliation = false;

  documentConnectionTypes = DocumentConnectionsType;

  accountConnections: DocumentConnection[] = [];

  affiliationConnections: DocumentConnection[] = [];

  isLoadingFormat$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private categories: Value[];

  @ViewChild('ngForm')
  private ngForm: NgForm;

  @ViewChild('accountConnectionsComponent')
  private accountConnectionsComponent: DocumentConnectionsComponent;

  @ViewChild('affiliationConnectionsComponent')
  private affiliationConnectionsComponent: DocumentConnectionsComponent;

  private destroy$: Subject<boolean> = new Subject();

  private statusChange$: Subject<string> = new Subject();

  documentStatuses = DocumentStatus;

  constructor(
    private svcAcl: ACL,
    private svcValue: ValueAPI,
    private svcDocument: DocumentAPI,
    private svcAccount: AccountAPI,
    private svcAffiliation: AffiliationAPI,
    private svcAffiliationModal: PersonAffiliationModalService,
    private svcAffiliationMaintenanceModal: AffiliationMaintenanceJobModalService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    this.onSearchDocument = this.onSearchDocument.bind(this);
    this.onSearchAccountConnection = this.onSearchAccountConnection.bind(this);
    this.onSearchAffiliationConnection = this.onSearchAffiliationConnection.bind(this);
    this.onRequestAffiliationConnection = this.onRequestAffiliationConnection.bind(this);
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.canRequestAffiliation =
      this.svcAcl.hasCredential('affiliation.create') || this.svcAcl.hasCredential('affiliation.request');

    this.countries$ = this.svcValue.find(ValueType.Country, Number.MAX_SAFE_INTEGER, 0, '+title');
    this.formats$ = this.svcValue.find(ValueType.DocumentFormat, Number.MAX_SAFE_INTEGER, 0, '+title');

    forkJoin([
      this.svcValue.find(ValueType.DocumentType, Number.MAX_SAFE_INTEGER, 0, '+title'),
      this.svcValue.find(ValueType.DocumentCategory, Number.MAX_SAFE_INTEGER, 0, '+title'),
    ]).subscribe(([types, categories]) => {
      this.types = types;
      this.categories = categories;
      this.onTypeChanges(this.model.type); // Restore value if any
    });

    if (this.model.previousVersion) {
      // Get previous version info if any
      this.isSearchingPreviousVersion = true;
      this.svcDocument
        .findById(this.model.previousVersion)
        .pipe(tap(() => (this.isSearchingPreviousVersion = false)))
        .subscribe({
          next: (doc) => {
            this.model.previousVersion = doc as any;
          },
          error: () => alert('Failed to fetch document previous version information'),
        });
    }

    if (this.model.connections?.length) {
      this.accountConnections = this.model.connections.filter((c) => c.type === 'ACCOUNT');
      this.affiliationConnections = this.model.connections.filter((c) => c.type === 'AFFILIATION');
    }

    if (this.accountConnections.length) {
      this.svcAccount
        .find(null, this.accountConnections.length, 0, null, {
          _id: this.accountConnections.map((c) => c.connectionId),
        })
        .subscribe((accounts) => {
          this.accountConnections.forEach((c) => {
            const account = accounts.find((a) => a.id === c.connectionId);
            if (account) {
              c.title = account.name;
              c.readyForDelivery = account.readyForDelivery; // for all levels (in sync with other changes)
            } else {
              console.warn('Account ' + c.connectionId + ' not found');
            }
          });
        });
    }

    if (this.affiliationConnections.length) {
      this.svcAffiliation
        .find(null, this.affiliationConnections.length, 0, null, {
          id: this.affiliationConnections.map((c) => c.connectionId),
        })
        .subscribe((accounts) => {
          this.affiliationConnections.forEach((c) => {
            const affiliation = accounts.find((a) => a.id === c.connectionId);
            if (affiliation) {
              c.title = [affiliation.name, affiliation.department].filter((s) => !!s).join(' - ');
              c.readyForDelivery = affiliation.readyForDelivery;
            } else {
              console.warn('Affiliation' + c.connectionId + ' not found');
            }
          });
        });
    }
  }

  ngAfterViewInit(): void {
    combineLatest([
      this.ngForm.statusChanges.pipe(startWith(null)),
      this.accountConnectionsComponent.validityChange.pipe(startWith('VALID')),
      this.affiliationConnectionsComponent.validityChange.pipe(startWith('VALID')),
      this.isLoadingFormat$,
      this.statusChange$.pipe(startWith(null)),
    ])
      .pipe(
        takeUntil(this.destroy$),
        debounceTime(0), // ensure that the model has already been updated
        map(([_, accountConnectionValidity, affiliationConnectionValidity]) => {
          let isValid = this.isValid();

          switch (this.model.connectionType) {
            case 'REGULAR':
              isValid = isValid && this.accountConnections.length + this.affiliationConnections.length === 1; // should have only one of one type, not more
              break;
            case 'JOINT':
              isValid =
                isValid &&
                ((this.accountConnections.length > 0 && this.affiliationConnections.length === 0) ||
                  (this.affiliationConnections.length > 0 && this.accountConnections.length === 0)); // 1-N of one type, not both
              break;
            case 'EXTERNAL':
              isValid = isValid && this.affiliationConnections.length > 0; // at least one affiliation
              break;
            default:
              isValid = false;
              break;
          }

          return (
            (isValid && accountConnectionValidity === 'VALID' && affiliationConnectionValidity === 'VALID') ||
            this.model.status === 'INACTIVE'
          );
        }),
        distinctUntilChanged()
      )
      .subscribe((isValid) => {
        this.validityChange.emit(isValid ? 'VALID' : 'INVALID');
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(false);
    this.destroy$.complete();
  }

  getValue(): Document {
    const connections = [...this.affiliationConnections];
    if (this.model.connectionType !== 'EXTERNAL') {
      connections.push(...this.accountConnections);
    }

    this.model.connections = connections;

    Utils.removeEmptyAndDuplicatedElementsFromArray(this.model, ['connections']);

    return this.model;
  }

  isFieldEditable(field: string): boolean {
    const prefix = this.model._id ? 'update' : 'create';
    return this.svcAcl.hasCredential(`document.${prefix}.prop.${field}`);
  }

  onSearchDocument(term$: Observable<string>): Observable<Document[]> {
    return term$.pipe(
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => (this.isSearchingPreviousVersion = true)),
      switchMap((term) => {
        if (!term) {
          return of([]);
        }

        return this.svcDocument.find(term, 10, 0, '').pipe(
          map((res) => res.filter((r) => r._id !== this.model._id)), // Exclude self
          catchError(() => {
            return of([]);
          })
        ) as Observable<Document[]>;
      }),
      tap(() => (this.isSearchingPreviousVersion = false))
    );
  }

  onSearchAccountConnection(term: string): Observable<DocumentConnection[]> {
    return this.svcAccount.find(term, 5, 0, null, { 'address.countryCode': this.model.country }).pipe(
      map((accounts) => {
        return accounts.map((account) => ({
          probability: null,
          type: 'ACCOUNT',
          connectionId: account.id,
          title: account.name,
        }));
      })
    );
  }

  onSearchAffiliationConnection(term: string): Observable<DocumentConnection[]> {
    return this.svcAffiliation.find(term, 5, 0, null, { 'address.countryCode': this.model.country }).pipe(
      map((affiliations) => {
        return affiliations.map((affiliation) => ({
          probability: null,
          type: 'AFFILIATION',
          connectionId: affiliation.id,
          title: [affiliation.name, affiliation.department].filter((s) => !!s).join(' - '),
          readyForDelivery: affiliation.readyForDelivery,
        }));
      })
    );
  }

  onRequestAffiliationConnection(connection?: DocumentConnection): Observable<DocumentConnection> {
    if (!connection?.connectionId) {
      return from(this.svcAffiliationModal.open()).pipe(
        map((result: Affiliation) => {
          const title = connection?.title
            ? connection.title
            : [result.name, result.department].filter((s) => !!s).join(' - ');
          return {
            probability: null,
            type: 'AFFILIATION',
            connectionId: result.id,
            title,
            readyForDelivery: result?.readyForDelivery || connection?.readyForDelivery,
          };
        })
      );
    }

    return from(this.svcAffiliationMaintenanceModal.open(connection?.connectionId)).pipe(map(() => connection));
  }

  onTypeChanges(code: string): void {
    if (!code) {
      return;
    }

    const type = this.types.find((t) => t.code === code);
    if (!type) {
      console.error(`Did not found type for ${code}`);
      return;
    }

    const category = this.categories.find((c) => c.code === type.showForType);
    this.model.category = category?.code as string;
    this.displayCategory = category?.title;
  }

  onFormatChange(): void {
    // Only a manual change will trigger this
    this.model.autoFormat = false;
  }

  onDocumentUrlChange(url: string): void {
    this.model.format = null; // Whatever was here should go away
    this.model.autoFormat = false;
    if (!url?.trim()) {
      return;
    }

    this.isLoadingFormat$.next(true);
    this.svcDocument.getDocumentFormat(url).subscribe({
      next: ({ format }) => {
        this.model.format = format;
        this.model.autoFormat = true;
      },
      complete: () => {
        this.isLoadingFormat$.next(false);
      },
    });
  }

  onStatusChange(): void {
    this.statusChange$.next(this.model.status);
  }

  formatTitle(doc: Document): string {
    const title = [doc.title];
    if (doc.documentVersion) {
      title.push(`(version: ${doc.documentVersion})`);
    }

    return title.join(' ');
  }

  private isValid(): boolean {
    return this.ngForm.form.valid && !this.isLoadingFormat$.value;
  }

  onPrevDocChanged(input: string) {
    if (!input) {
      this.model.previousVersion = null;
    }
  }

  onPublisherNotFoundChange(checked: boolean): void {
    this.model.publisherNotFound = checked;

    if (checked) {
      this.model.publisher = '';
    }
  }

  publishDateValidator(input: Date): void {
    const today = new Date();
    if (input > today) {
      alert('Publication date cannot be greater than today. Please try again.');
      this.model.publishedDate = null;
      this.ngForm.controls['publishedDate'].setValue(null);
      return;
    }
  }

  effectiveDateValidator(input: Date, field: 'effectiveStartDate' | 'effectiveEndDate'): void {
    const effectiveStartDate = new Date(this.model.effectiveStartDate);
    const effectiveEndDate = new Date(this.model.effectiveEndDate);

    // if effective end date is null then return
    if (effectiveEndDate.getTime() === new Date(null).getTime()) {
      return;
    }
    if (
      input &&
      ((field === 'effectiveEndDate' && input < effectiveStartDate) ||
        (field === 'effectiveStartDate' && input > effectiveEndDate))
    ) {
      alert('Effective End Date should be greater than Effective Start Date');
      this.model[field] = null;
      this.ngForm.controls[field].setValue('');
    }
  }
}
