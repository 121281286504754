import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, take, delay } from 'rxjs/operators';
import { SentimentAPI } from '../shared/api.service';
import { SentimentCuration } from '../shared/sentiment';
import { UserAPI } from '../../user/shared/api.service';

@Component({
  selector: 'dirt-sentiment-detail',
  templateUrl: 'detail.component.html',
  styleUrls: ['detail.component.scss'],
})
export class SentimentDetailComponent implements OnInit {
  id: string;
  sentiment: SentimentCuration;
  curateUser = null;
  isSaving: boolean;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private svcUser: UserAPI,
    private readonly svcSentiment: SentimentAPI
  ) {}

  ngOnInit() {
    this.initSentiment();
  }

  private initSentiment() {
    this.route.params
      .pipe(
        take(1),
        map((params) => (this.id = params['id'])),
        switchMap((id) => this.svcSentiment.findById(id))
      )
      .subscribe((sentiment) => {
        this.sentiment = sentiment;
        this.initUser();
      });
  }

  private initUser() {
    this.curateUser = null;
    // try to make user display better - have new & efficient endpoint showing ONLY names
    if (this.sentiment.curatedBy) {
      this.svcUser
        .findNameById(this.sentiment.curatedBy)
        .toPromise()
        .then((p) => {
          if (p.user_id && p.name) {
            this.curateUser = p;
          }
        });
    }
  }

  async onSubmit() {
    // so far: no stand-alone 'save' (can add onSave later)
    await new Promise((resolve) => setTimeout(resolve, 200)); // wait out form blur
    const existingCombis = [];
    const dups = this.sentiment.manualCuration
      .map((c) => (c.subject || '-') + ' / ' + (c.tag || '-'))
      .filter((combi) => {
        const exists = existingCombis.indexOf(combi) >= 0;
        existingCombis.push(combi);
        return exists;
      });
    if (dups.length > 0) {
      alert('There are duplications - pls. provide an unambigous sentiment. Problems w/ ' + dups.join(', '));
      return;
    }
    const missing = this.sentiment.manualCuration
      .filter((c) => !(c.subject && c.tag && c.sentiment))
      .map((c) => (c.subject || '-') + ' / ' + (c.tag || '-'));
    if (missing.length > 0) {
      alert('There are missing sentiments - pls. provide all sentiments. Problems w/ ' + missing.join(', '));
      return;
    }
    // good 2 go
    this.isSaving = true;
    this.svcSentiment
      .submit([this.sentiment])
      .pipe(take(1))
      .pipe(delay(200)) // show we saved - but don't waste time
      .subscribe(
        (res: { ok: boolean; incomplete?: boolean }) => {
          this.isSaving = false;
          if (res.ok) {
            this.router.navigate(['/next']);
          } else {
            alert('Could not save' + (res.incomplete ? ' - incomplete sentiments' : ''));
          }
        },
        () => (this.isSaving = false)
      );
  }
}
