import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';

import { AccountMedicalInformation } from './account-medical-information';
import { APIService } from '../../shared/services/api/api.service';
import {
  GET,
  MediaType,
  Produces,
  Query,
  POST,
  Body,
  Path,
  PATCH,
  DELETE,
} from '../../shared/services/rest/rest.service';

@Injectable()
export class AccountMedicalInformationAPI extends APIService {
  @GET('account-medical-information/')
  @Produces(MediaType.JSON)
  public find(
    @Query('limit') limit?: number,
    @Query('skip') skip?: number,
    @Query('sort') sort?: string,
    @Query('accountId') accountId?: string
  ): Observable<AccountMedicalInformation[]> {
    return;
  }

  @GET('account-medical-information/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<AccountMedicalInformation> {
    return;
  }

  @GET('account-medical-information/count')
  @Produces(MediaType.JSON)
  public count(@Query('accountId') accountId?: string): Observable<any> {
    return;
  }

  @POST('account-medical-information')
  @Produces(MediaType.JSON)
  public create(@Body accountMedicalInformation: AccountMedicalInformation): Observable<any> {
    return;
  }

  @PATCH('account-medical-information/{id}')
  @Produces(MediaType.JSON)
  public update(
    @Path('id') id: string,
    @Body accountMedicalInformation: Partial<AccountMedicalInformation>
  ): Observable<any> {
    return;
  }

  @DELETE('account-medical-information/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<any> {
    return;
  }

  public startBulkListUpload(file: File, secondEyes: string): Observable<string> {
    // (actually do this ourselves!)
    const formData: FormData = new FormData();
    formData.set('xlsx', file); // multipart name must match server
    formData.set('secondEyes', secondEyes);
    return this.http
      .post(this.getBaseUrl() + 'account-medical-information/bulk-list', formData)
      .pipe(catchError((err) => this.handleError(err)))
      .pipe(map((obj) => (<any>obj).bulkTrackingId));
  }
}
