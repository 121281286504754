import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';
import { StickyFilterGuard } from '../common/sticky-filter/sticky-filter.guard';

import { PersonComponent } from './person.component';
import { PersonListComponent } from './list/list.component';
import { PersonDetailComponent } from './detail/detail.component';
import { PersonCreateComponent } from './create/create.component';
import { PersonModule } from './person.module';

const personRoutes: Routes = [
  {
    path: 'person',
    component: PersonComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: '',
        redirectTo: 'list',
        pathMatch: 'full',
      },
      {
        path: 'create',
        component: PersonCreateComponent,
      },
      {
        path: 'detail/:id',
        component: PersonDetailComponent,
      },
      {
        path: 'list',
        component: PersonListComponent,
        data: { acl: 'person.list' },
        canActivate: [StickyFilterGuard],
      },
    ],
    data: { title: 'Person' },
  },
];

export const personRouting: ModuleWithProviders<PersonModule> = RouterModule.forChild(personRoutes);
