<section class="dirt-section-detail dirt-committee-detail">
  <div class="row" *ngIf="!isLoading; else loader">
    <div class="col-md-8">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <div class="dirt-title-ct">
        <h1 class="bounceInRight">Committee Detail</h1>

        <div>
          <button type="button" class="btn btn-outline-primary" (click)="onCopyID(committee.id)">Copy ID</button>

          <!-- Create Event Button -->
          <div class="display-inline" *ngIf="committee.id">
            <a
              [routerLink]="['/event/create']"
              *diAcl="'event.create || session.create'"
              [queryParams]="{ type: 'committee', id: committee.id }"
              class="btn btn-success btn-sm"
              role="button"
              target="_blank"
            >
              <i class="fa fa-plus" aria-hidden="true"></i>&nbsp;
              <span>Create Event</span>
            </a>
          </div>
        </div>
      </div>

      <dirt-committee-form
        #frmCommittee="frmCommittee"
        [committee]="committee"
        (validityChange)="onFormValidityChange($event)"
      ></dirt-committee-form>

      <!-- Job controls -->
      <div *ngIf="hasJobForCurrentUser; else updateControls">
        <div class="pull-left">
          <!-- Unable to Compile -->
          <button class="btn btn-outline-danger" (click)="onJobUtc()">Unable to Compile!</button>
        </div>

        <div class="pull-right">
          <!-- Save as Draft -->
          <button
            class="btn btn-secondary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="isSubmitting"
            (click)="onJobDraft()"
          >
            Save as Draft
          </button>

          <!-- Submit -->
          <button
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="(!isFormValid && !canSaveAnyway) || isSubmitting"
            (click)="onSubmitJob(frmCommittee.getValue())"
          >
            {{ !isFormValid && canSaveAnyway ? 'Submit Job Anyway' : 'Submit Job' }}
          </button>
        </div>
      </div>

      <!-- Standard controls -->
      <ng-template #updateControls>
        <div class="pull-right" *ngIf="canSubmitNonJob">
          <button
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="(!isFormValid && !canSaveAnyway) || isSubmitting"
            (click)="onSave(frmCommittee.getValue())"
          >
            {{ !isFormValid && canSaveAnyway ? 'Save Anyway' : 'Save' }}
          </button>
        </div>
      </ng-template>
    </div>

    <div class="col-md-4">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Workflow -->
        <li ngbNavItem>
          <a ngbNavLink>Workflow</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <dt class="col-sm-4" [hidden]="!isClaimedByOtherUser()"></dt>
              <dd class="col-sm-8" [hidden]="!isClaimedByOtherUser()">
                <span title="s/o else started working recently" class="badge badge-info">Claimed</span>
              </dd>

              <dt class="col-sm-4" *ngIf="hasJobForCurrentUser">Job</dt>
              <dd class="col-sm-8" *ngIf="hasJobForCurrentUser">
                {{ currentJob?.type + (currentJob?.hint ? ' - ' + currentJob.hint : '') }}
              </dd>

              <dt class="col-sm-4" *ngIf="committee.committeeID">Committee ID (Legacy)</dt>
              <dd class="col-sm-8" *ngIf="committee.committeeID">
                <b>{{ committee.committeeID }}</b>
                <i
                  class="fa fa-copy"
                  style="cursor: pointer"
                  (click)="onCopyID(committee.committeeID)"
                  ngbTooltip="Copied"
                  [openDelay]="1000"
                  [closeDelay]="2000"
                ></i>
              </dd>

              <dt class="col-sm-4" [ngClass]="{ 'di-separator': hasJobForCurrentUser }">Created</dt>
              <dd class="col-sm-8" [ngClass]="{ 'di-separator': hasJobForCurrentUser }">
                {{ committee.createdAt | date : 'medium' }}
              </dd>

              <dt class="col-sm-4 text-truncate">Modified</dt>
              <dd class="col-sm-8">{{ committee.updatedAt | date : 'medium' }}</dd>

              <dt class="col-sm-4 text-truncate">Priority</dt>
              <dd class="col-sm-8">
                <ngb-rating
                  [rate]="committee.priority"
                  (rateChange)="onSetPriority(committee, $event)"
                  max="5"
                  [readonly]="!isPriorityEditable"
                >
                  <ng-template let-fill="fill" let-index="index">
                    <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
                  </ng-template>
                </ngb-rating>
                <i class="fa fa-circle-o-notch fa-spin" [hidden]="!isSavingPriority"></i>
              </dd>

              <dt class="col-sm-4 text-truncate">Status</dt>
              <dd class="col-sm-8">
                <span *diAcl="'!committee.update.prop.meta.status'">{{ committee._meta?.status | capitalize }}</span>
                <ng-container *diAcl="'committee.update.prop.meta.status'">
                  <dirt-status
                    [statuses]="statuses"
                    [assigneeStatuses]="assigneeStatuses"
                    [disabledStatuses]="disabledStatuses"
                    [_meta]="committee._meta || {}"
                    (onStatusSelected)="onStatusChange($event)"
                  >
                  </dirt-status>
                </ng-container>
                <i class="fa fa-circle-o-notch fa-spin" [hidden]="!isSavingStatus"></i>
              </dd>

              <dt class="col-sm-4">Version</dt>
              <dd class="col-sm-8">{{ committee._version || 0 }}</dd>

              <ng-container *ngIf="hasUnpolishedAffiliations">
                <dt class="col-sm-4 di-separator" title="Central organizations did not yet check all organizations">
                  Unpolished organizations
                </dt>
                <dd class="col-sm-8 di-separator">YES</dd>
              </ng-container>

              <dt class="col-sm-4" [ngClass]="{ 'di-separator': !hasUnpolishedAffiliations }">Delivery Ready</dt>
              <dd class="col-sm-8" [ngClass]="{ 'di-separator': !hasUnpolishedAffiliations }">
                <i
                  class="fa"
                  [ngClass]="{ 'fa-check': committee.readyForDelivery, 'fa-times': !committee.readyForDelivery }"
                  aria-hidden="true"
                ></i>
              </dd>

              <ng-container *ngIf="committee.identify?.at">
                <dt class="col-sm-4 text-truncate di-separator">Identified at</dt>
                <dd class="col-sm-8 di-separator">{{ committee.identify.at | date : 'medium' }}</dd>

                <dt class="col-sm-4 text-truncate">Identified by</dt>
                <dd class="col-sm-8 font-italic">
                  <dirt-username [userId]="committee.identify.by"></dirt-username>
                </dd>
              </ng-container>

              <ng-container *ngIf="committee.mapping?.at">
                <dt class="col-sm-4 text-truncate">Mapped at</dt>
                <dd class="col-sm-8">{{ committee.mapping.at | date : 'medium' }}</dd>

                <dt class="col-sm-4 text-truncate">Mapped by</dt>
                <dd class="col-sm-8 font-italic">
                  <dirt-username [userId]="committee.mapping.by"></dirt-username>
                </dd>
              </ng-container>
            </dl>
          </ng-template>
        </li>

        <!-- Comments -->
        <ng-template [diAcl]="'committee.comment.list'">
          <li ngbNavItem>
            <a ngbNavLink>Comments</a>
            <ng-template ngbNavContent>
              <dirt-comment-list [ownerId]="id" [enableCreate]="canCreateComments"></dirt-comment-list>
            </ng-template>
          </li>
        </ng-template>

        <!-- Audit Log -->
        <ng-template [diAcl]="'committee.audit.list'">
          <li ngbNavItem>
            <a ngbNavLink>Audit Log</a>
            <ng-template ngbNavContent>
              <dirt-audit-log [id]="id" [entityAPI]="svcCommittee"></dirt-audit-log>
            </ng-template>
          </li>
        </ng-template>

        <!-- Job History -->
        <li ngbNavItem *diAcl="'committee.job-history.list'">
          <a ngbNavLink>Job History</a>
          <ng-template ngbNavContent>
            <dirt-job-history [entityId]="id"></dirt-job-history>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-committee-meta"></div>
    </div>

    <div class="col-md-12" *diAcl="'committee.person.list'">
      <dirt-committee-person-list [committee]="committee"></dirt-committee-person-list>
    </div>
  </div>

  <!-- Loading Message -->
  <ng-template #loader>
    <p class="dirt-loading">
      <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
      <span class="sr-only">Loading...</span>
    </p>
  </ng-template>
</section>
