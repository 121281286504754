<form novalidate>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchFirstName" title="First name or first initials"
      >First Name</label
    >
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchFirstName"
        name="searchFirstName"
        class="form-control"
        [(ngModel)]="model.filter.firstName"
        placeholder="First Name"
      />
    </div>
    <div>
      <label title="Search in first name and nickname">
        <input type="checkbox" [(ngModel)]="model.filter.includeNickname" [ngModelOptions]="{standalone: true}" /> Incl
        Nickn.
      </label>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchMiddleName" title="Middle name or initials"
      >Middle Name</label
    >
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchMiddleName"
        name="searchMiddleName"
        class="form-control"
        [(ngModel)]="model.filter.middleName"
        placeholder="Middle Name"
      />
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchLastName" title="Last name">Last Name</label>
    <div class="col-sm-7 col-md-9">
      <input
        type="text"
        id="searchLastName"
        name="searchLastName"
        class="form-control"
        [(ngModel)]="model.filter.lastName"
        placeholder="Last Name"
      />
    </div>
    <div>
      <label title="Search in last name and alternative names">
        <input
          type="checkbox"
          [(ngModel)]="model.filter.includeAlternativeName"
          [ngModelOptions]="{standalone: true}"
        />
        Incl Altern.
      </label>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchCountryWorkflow">Country</label>
    <div class="col-sm-7 col-md-9">
      <select
        class="form-control custom-select"
        id="searchCountryWorkflow"
        name="searchCountryWorkflow"
        [(ngModel)]="model.filter.country"
      >
        <option value="">(all)</option>
        <option *ngFor="let c of countries" [value]="c.code">{{ c.title }}</option>
      </select>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchProjectName" title="Delivery project">Project</label>
    <div class="col-sm-7 col-md-9">
      <select
        class="form-control custom-select"
        id="searchProjectName"
        name="searchProjectName"
        [(ngModel)]="model.filter.projectName"
      >
        <option value="">(all)</option>
        <option *ngFor="let p of deliveryProjects" [value]="p.code">{{ p.title }}</option>
      </select>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Specialty</label>
    <div class="col-sm-7 col-md-9">
      <dirt-multiselect-dropdown
        name="searchSpecialty"
        [options]="specialties"
        [settings]="specialtiesSettings"
        [ngModel]="model.filter.specialty"
        (ngModelChange)="model.filter.specialty=$event[0] || null"
      >
      </dirt-multiselect-dropdown>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2">Degree</label>
    <div class="col-sm-7 col-md-9">
      <dirt-multiselect-dropdown
        name="searchDegree"
        [options]="degrees"
        [settings]="degreesSettings"
        [ngModel]="model.filter.degree"
        (ngModelChange)="model.filter.degree=$event[0] || null"
      >
      </dirt-multiselect-dropdown>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-form-label col-sm-4 col-md-2" for="searchMetaStatus">Status</label>
    <div class="col-sm-7 col-md-9">
      <select
        class="form-control custom-select"
        id="searchMetaStatus"
        name="searchMetaStatus"
        [(ngModel)]="model.filter.metaStatus"
      >
        <option value="">(all)</option>
        <option *ngFor="let s of statuses | iterable" [value]="s.key">{{ s.value | capitalize }}</option>
      </select>
    </div>
  </div>
</form>
