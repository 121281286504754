import { Injectable } from '@angular/core';
import { firstValueFrom, from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { JwtHelperService } from '@auth0/angular-jwt';

import { GET, PUT, Path, Body, MediaType, Produces, PATCH, POST } from '../../../../shared/services/rest/rest.service';
import { ProfilePublication } from '../../../shared/profile-publication';
import { SubentityApiService } from '../../common/subentity-api.service';
import { PersonAPI } from '../../../../person/shared/api.service';
import { Utils } from '../../../../common/utils';
import { ACL } from '../../../../shared/acl/acl.service';
import { filterStream, parseJSONStream, splitStream } from '../../stream.utils';
import { Profile } from '../../../shared/profile';
import { ProfileMatchManual } from '../../../shared/constant/match-manual.enum';
import { Roles } from '../../../../shared/acl/roles';

type ProfilePublicationRaw = {
  person_id: string;
  pmid: string;
  match_result: string;
  match_source?: string;
  fas?: string; // split by @
  fas_ids?: string; // split by @
  first_name: string;
  last_name: string;
  position: string;
  affiliation?: string;
  ml_exclude?: 't' | 'f' | true | false;
};

@Injectable()
export class ProfilePublicationAPI extends SubentityApiService<
  ProfilePublication,
  { id; _profile: ProfilePublication & { focusAreaIds } },
  ProfilePublication[],
  { position }
> {
  constructor(
    http: HttpClient,
    svcModal: NgbModal,
    svcAcl: ACL,
    svcJwtHelper: JwtHelperService,
    private svcPerson: PersonAPI
  ) {
    super(http, svcModal, svcAcl, svcJwtHelper);
  }

  protected useAsiaFas(): boolean {
    return true;
  }

  @POST('publications/base-info')
  @Produces(MediaType.JSON)
  public getPublicationBaseInfo(
    @Body pmids: string[] | { pmids: string[]; authorIds?: string[] }
  ): Observable<{ pmid; externalIds: { pubmed }; journal: { title }; publicationDate; abstract: { text }; title }[]> {
    return;
  }

  public findAll(profile: Profile): Observable<ProfilePublication[]> {
    return from(this.findAllAutomated(profile));
  }

  protected async enrichAll(
    profile: Profile,
    allList: ProfilePublication[]
  ): Promise<{ id; _profile: ProfilePublication & { focusAreaIds } }[]> {
    // also need curated - which may or may not match (so we get a longer list)
    // getUniqueIdent works there as well
    const allCurated: ProfilePublication[] = await this.findAllCurated(profile);
    const allCuratedMap = {};
    const allCuratedMatchedMap = {};
    allCurated.forEach((item) => {
      allCuratedMap[item.id] = item;
    });
    allList.forEach((item) => {
      const curated = allCuratedMap[item.id];
      if (curated) {
        allCuratedMatchedMap[item.id] = true;
        item.mongoId = curated.mongoId;
        if (curated.match) {
          item.match.manual = curated.match.manual;
          item.match.pendingSubmission = curated.match.pendingSubmission;
        }
        item.compiledAt = curated.compiledAt;
        item.qc = curated.qc;
      }
    });
    // everything that is curated as MATCH or MISMATCH is shown, no matter whether we still have it in KIP
    const remainingCurated = allCurated.filter(
      (item) =>
        !allCuratedMatchedMap[item.id] &&
        (ProfileMatchManual.MATCH === item.match?.manual || ProfileMatchManual.MISMATCH === item.match?.manual)
    );
    let completeAllList = [...allList, ...remainingCurated];

    // Given we don't have manual info in ensureLoaded before calling enrichAll, we need to filter what's already matched here for compilers.
    // Will need to do exactly the same thing for the other sources when progressively switching them to outbox.

    // for compilers outside training and (usual) view mode delta, we could speed up (ViewModeFilter will _always_ filter out the same ones) - load way less base-info etc around the globe:
    if (
      this.svcAcl.userRoles?.includes(Roles.ProfileCompiler) &&
      !profile.training &&
      profile.viewMode?.endsWith('_DELTA')
    ) {
      completeAllList = completeAllList.filter(
        (item) => item.match?.manual === 'NONE' || item.match?.pendingSubmission
      );
    }

    // load pubs
    const pubsMap = {};
    const PAGE_SIZE = 2500;
    const allIds = [...new Set(completeAllList.map((p) => p.pmid))]; // make unique
    for (let i = 0; i < allIds.length; i += PAGE_SIZE) {
      const pubsList = await firstValueFrom(this.getPublicationBaseInfo(allIds.slice(i, i + PAGE_SIZE)));
      pubsList.forEach((p) => (pubsMap[p.pmid] = p));
    }
    // do transform into the format we used - from here fw, we should not need any change
    const transformed = completeAllList
      .filter((p) => !!pubsMap[p.pmid])
      .map((p) => ({
        // (pretty simple transform if you break it down)
        ...pubsMap[p.pmid], // (journal, abstract, etc.)
        id: p.id, // replace w/ mongo ID when curated
        mongoId: p.mongoId,
        pmid: p.pmid,
        _profile: {
          // (the match as such + first author from pub)
          ...p,
          match: {
            ...p.match,
            mlAnyway: Math.abs(Utils.hash((p as any).person?.kolId + '' + (p.pmid || 'null').toString()) % 100) < 1,
          },
          firstAuthor: pubsMap[p.pmid]?.authors?.find((a) => a.position === 'First Author'),
          focusAreas: p.focusAreas?.map((fa) => (typeof fa === 'string' ? fa : (fa as any).name)),
          focusAreaIds: p.focusAreas?.map((fa) => (typeof fa === 'string' ? fa : (fa as any).id)),
        },
        externalIds: { ...pubsMap[p.pmid]?.externalIds, pubmed: p.pmid }, // (make sure we get pmid whatever)
      }));
    return transformed;
  }

  protected async loadAndEnrichOrigins(
    profileId: string,
    kolId: string
  ): Promise<{ id; _profile: ProfilePublication & { focusAreaIds } }[]> {
    // unchanged with or without outbox
    const person = await firstValueFrom(this.svcPerson.findById(kolId));
    const pmids: string[] = (person as any)?.additionalDetails?.automatedProfileIds?.pubmedIds || [];
    if (pmids.length < 1) {
      return [];
    }

    // load pubs
    const pubsList = await firstValueFrom(
      this.getPublicationBaseInfo({
        pmids,
        authorIds: (person as any)?.additionalDetails?.automatedProfileIds?.publicationAuthorIds,
      })
    );
    const pubsMap = {};
    pubsList.forEach((p) => (pubsMap[p.pmid] = p));

    // do transform into the format we used - from here fw, we should not need any change
    const transformed = pmids.map((pmid) => ({
      // (pretty simple transform if you break it down)
      ...pubsMap[pmid], // (journal, abstract, etc.)
      id: null,
      pmid: pmid,
      _profile: {
        // (the match as such + first author from pub)
        match: { automate: 'PB' },
        author: pubsMap[pmid]?.author,
        firstAuthor: pubsMap[pmid]?.authors?.find((a) => a.position === 'First Author'),
        focusAreas: [],
        focusAreaIds: [],
      },
      externalIds: { ...pubsMap[pmid]?.externalIds, pubmed: pmid }, // (make sure we get pmid whatever)
    }));

    return transformed;
  }

  @PATCH('profiles/{id}/publications')
  @Produces(MediaType.JSON)
  public updateImpl(@Path('id') id: string, @Body matches: ProfilePublication[]): Observable<ProfilePublication[]> {
    return;
  }

  @PUT('profiles/{id}/publications/rel/{fk}')
  @Produces(MediaType.JSON)
  public linkImpl(
    @Path('id') id: string,
    @Path('fk') pubId: string,
    @Body data: { position: string }
  ): Observable<ProfilePublication> {
    return;
  }

  private async findAllAutomated(profile: Profile): Promise<ProfilePublication[]> {
    const options: RequestInit = { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } };
    const response: Response = await fetch(
      this.getBaseUrl() + 'profiles/' + profile.id + '/publications/automated',
      options
    ); // (we want a stream now)
    if (!response.ok) {
      throw new Error('Failed to download automated matches');
    }

    // For automated matches, we need to get the base profile matches, the clone will not have anything under its name.
    const identifier = profile.training ? profile.baseProfileKolId : profile.person.kolId;
    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(splitStream('\n'))
      .pipeThrough(filterStream((row: string) => row.includes(identifier))) // save us some JSON parsing
      .pipeThrough(parseJSONStream())
      .pipeThrough(filterStream((row: ProfilePublicationRaw) => row.person_id === identifier)) // Catch the very few cases that we could leave out
      .pipeThrough(transformToMatch())
      .getReader();

    const matches: ProfilePublication[] = [];

    while (true) {
      const result = await reader.read(); // Leave timeout to Nginx

      if (!result) {
        // Nothing in the stream
        break;
      }

      if (result.done) {
        break; // Everything was read
      }

      matches.push(result.value);
    }

    // We're done
    reader.cancel();

    return matches;
  }

  private async findAllCurated(profile: Profile): Promise<ProfilePublication[]> {
    const options: RequestInit = { headers: { Authorization: 'Bearer ' + localStorage.getItem('token') } };
    const response: Response = await fetch(
      this.getBaseUrl() + 'profiles/' + profile.id + '/publications/curated',
      options
    ); // (we want a stream now)
    if (!response.ok) {
      throw new Error('Failed to download curated matches');
    }

    const reader = response.body
      .pipeThrough(new TextDecoderStream())
      .pipeThrough(splitStream('\n'))
      .pipeThrough(parseJSONStream())
      .getReader();

    const matches: ProfilePublication[] = [];

    while (true) {
      const result = await reader.read(); // Leave timeout to Nginx

      if (!result) {
        // Nothing in the stream
        break;
      }

      if (result.done) {
        break; // Everything was read
      }

      this.postProcessFromMongo(result.value);
      matches.push(result.value);
    }

    // We're done
    reader.cancel();

    return matches;
  }

  protected postProcessFromMongo(item: ProfilePublication) {
    // also what is coming back from updates
    item.mongoId = item.id;
    item.id = 't-' + Utils.hash(getUniqueIdent(item));
  }
}

function getUniqueIdent(item: ProfilePublication): any {
  return [
    // item.person?.kolId, // we already work with a single KP, so having KOL ID here is redundant and detrimental when we want to merge base profile auto matches with cloned profile manual matches.
    item.pmid,
    item.author?.firstName,
    item.author?.lastName,
    item.author?.position,
    item.author?.affiliation,
  ]
    .map((s) => s || '')
    .join('|'); // had the same previously in backend as buildFilter (same fields)
}

function mapRawToModel(chunk: ProfilePublicationRaw): ProfilePublication {
  const fas = (chunk.fas || '').split('@').filter((s) => !!s);
  const faIds = (chunk.fas_ids || '').split('@').filter((s) => !!s);

  const match: ProfilePublication = {
    id: null, // (filled below)
    pmid: chunk.pmid,
    person: { kolId: chunk.person_id },
    author: {
      firstName: chunk.first_name,
      lastName: chunk.last_name,
      position: chunk.position,
      affiliation: chunk.affiliation,
    },
    match: {
      automate: chunk.match_result,
      manual: ProfileMatchManual.NONE, // Will enrich via curated matches
      matchSource: chunk.match_source,
      mlExclude: 't' === chunk.ml_exclude || true === chunk.ml_exclude,
    },
    focusAreas: fas.map((fa, i) => ({ name: fa, id: faIds[i] })),

    // filled later
    abstract: null,
    firstAuthor: null,
    journal: null,
    publicationDate: null,
    title: null,
  };

  match.id = 't-' + Utils.hash(getUniqueIdent(match));
  return match;
}

function transformToMatch() {
  return new TransformStream({
    transform(chunk: ProfilePublicationRaw, controller) {
      const match = mapRawToModel(chunk);
      controller.enqueue(match);
    },
  });
}
