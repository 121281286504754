<div class="input-group">
  <div class="input-wrapper">
    <input
      #linkInput="ngModel"
      type="url"
      class="form-control"
      [ngClass]="{ 'has-controls': !!url, 'form-control-sm': small }"
      [placeholder]="placeholder"
      [name]="name"
      [(ngModel)]="url"
      (ngModelChange)="onValueChange($event)"
      [ngModelOptions]="{ updateOn: 'blur' }"
      [nonCompliantDomainValidator]="
        validateFormatOnly ? { validateFormatOnly: true } : { type: nonCompliantDomainType }
      "
      [readonly]="disabled"
      [required]="required"
      maxlength="1000"
    />

    <div class="error-message-wrapper" *ngIf="!!ngControl && ngControl.hasError('non-compliant')">
      <span [ngbTooltip]="'This link is non-compliant!' + (disabled ? ' (but you can still continue)' : '')">
        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
      </span>
    </div>
  </div>

  <div class="input-group-append">
    <button class="btn btn-secondary" aria-label="Open link in a new tab" (click)="onOpenURL(url)" *ngIf="!!url">
      <i class="fa fa-external-link dirt-link-pointer" aria-hidden="true"></i>
    </button>

    <!-- Can add more buttons to the group if needed -->
    <ng-content></ng-content>
  </div>
</div>
