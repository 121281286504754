import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/guard/auth-guard.service';
import { AclGuard } from '../shared/acl/guard.service';

import { MemoComponent } from './memo.component';
import { MemoListComponent } from './list/list.component';
import { MemoModule } from './memo.module';

export const memoRoutes: Routes = [
  {
    path: 'memo',
    component: MemoComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AclGuard],
    children: [
      {
        path: 'list/:user',
        component: MemoListComponent,
      },
    ],
  },
];

export const memoRouting: ModuleWithProviders<MemoModule> = RouterModule.forChild(memoRoutes);
