export enum PersonStatus {
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  VERIFICATION_IN_PROGRESS = 'VERIFICATION_IN_PROGRESS',

  READY_FOR_COMPILATION = 'READY_FOR_COMPILATION',
  COMPILATION_IN_PROGRESS = 'COMPILATION_IN_PROGRESS',
  UNABLE_TO_COMPILE = 'UNABLE_TO_COMPILE',

  NO_INFO = 'NO_INFO',
  DONE = 'DONE',
  DUPLICATE = 'DUPLICATE',
  ID_OUT = 'ID_OUT',
  ON_HOLD = 'ON_HOLD',
  DUPLICATE_SUSPECT = 'DUPLICATE_SUSPECT',
}
