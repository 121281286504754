import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { InputErrorMsgComponent } from './input-error-msg.component';
import { ErrorMessage } from './error-message';

@Directive({
  selector: '[diInputErrorMsg]',
})
export class InputErrorMsgDirective implements OnInit {
  private component: ComponentRef<InputErrorMsgComponent>;

  @Input()
  set diInputErrorMsg(value: ErrorMessage) {
    if (this.component) {
      this.component.instance.errorMsg = value;
    }
  }

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    const containerFactory = this.componentFactoryResolver.resolveComponentFactory(InputErrorMsgComponent);
    this.component = this.viewContainerRef.createComponent(containerFactory);

    this.component.instance.template = this.templateRef;
  }
}
