import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GET, POST, Body, MediaType, Produces, DELETE, Path, Query } from '../../shared/services/rest/rest.service';
import { APIService } from '../../shared/services/api/api.service';
import { ProjectTagValue } from './project-tag-value';

@Injectable()
export class ProjectTagsAPI extends APIService {
  @GET('values/project-tags/')
  @Produces(MediaType.JSON)
  public listAll(@Query('type') type: 'project' | 'person-project'): Observable<ProjectTagValue[]> {
    return;
  }

  @POST('values/project-tags/')
  @Produces(MediaType.JSON)
  public save(@Body tag: ProjectTagValue): Observable<{ ok: boolean; reason?: string; message?: string; id: string }> {
    return;
  }

  @DELETE('values/project-tags/{id}')
  @Produces(MediaType.JSON)
  public deleteById(@Path('id') id: string): Observable<{ ok: boolean; reason?: string; id: string }> {
    return;
  }
}
