<section class="dirt-section-detail dirt-association-detail" *ngIf="association">
  <div class="row" [hidden]="isLoading">
    <div class="col-md-8">
      <button type="button" class="dirt-btn-back btn btn-primary-outline btn-sm" (click)="goBack()">
        <span class="fa fa-arrow-left" aria-hidden="true"></span>
      </button>

      <div class="dirt-section-details__header-wrapper">
        <h1 class="bounceInRight">
          <span [hidden]="association.parent">Association Detail</span>
          <span [hidden]="!association.parent">Sub Association Detail</span>
        </h1>

        <div class="di-thumbnail-container" *ngIf="!association.parent">
          <img
            class="img-thumbnail"
            [ngClass]="{ 'is-loading': isLogoLoading }"
            [src]="association.localLogoUrl_nocache || association.localLogoUrl || 'assets/blank-profile.png'"
            (loadstart)="isLogoLoading = true"
            (load)="isLogoLoading = false"
          />

          <dirt-spinner [show]="isLogoLoading"></dirt-spinner>
        </div>
      </div>

      <p *ngIf="association.parent">
        Belongs to
        <a [routerLink]="['/association/detail', association.parent.id]">{{ association.parent.name | dash }}</a>
      </p>
      <div
        class="alert alert-warning text-center mt-4"
        *ngIf="!association.parent && association._meta?.maintenance"
        role="alert"
      >
        <i class="fa fa-info-circle" aria-hidden="true"></i>
        <strong>This association is on Maintenance.</strong>
      </div>

      <br />
      <dirt-association-form [association]="association" #frmAssociation="frmAssociation"></dirt-association-form>

      <!-- Association: Buttons -->
      <div class="dirt-btn-ct" *ngIf="!association.parent && !isObjectionCommentShown">
        <!-- Left -->
        <div class="pull-left">
          <!-- Unable to Compile -->
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="onObjection('UNABLE_TO_COMPILE')"
            *diAcl="'association.update.unable'"
          >
            Unable to Compile!
          </button>

          <!-- Skip verification -->
          <button
            type="button"
            class="btn btn-outline-danger"
            (click)="skipVerification()"
            *diAcl="'association.update.skip'"
          >
            Skip Association
          </button>
        </div>

        <!-- Right -->
        <div class="pull-right">
          <!-- Save -->
          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!frmAssociation.isValid() || isSubmitting"
            (click)="onSave(frmAssociation.getValue(), 'SAVE_AND_BACK')"
            *diAcl="'association.update'"
          >
            <span>Save</span>
          </button>

          <!-- Submit -->
          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!frmAssociation.isValid() || isSubmitting"
            (click)="onSubmit(frmAssociation.getValue())"
            *diAcl="'association.update.submit'"
          >
            <span>Submit</span>
          </button>
        </div>
      </div>

      <!-- Comment Form -->
      <dirt-comment-create
        [ownerId]="id"
        [showBtnSubmit]="true"
        [showBtnCancel]="true"
        [btnSubmitCaption]="objectionStatus | capitalize"
        (onSubmitted)="onObjectionSubmit()"
        (onCancelled)="onObjectionCancel()"
        *ngIf="!association.parent && isObjectionCommentShown"
      >
      </dirt-comment-create>

      <!-- Sub-association: Buttons -->
      <div class="dirt-btn-ct" *ngIf="association.parent">
        <!-- Right -->
        <div class="pull-right">
          <!-- Save -->
          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!frmAssociation.isValid() || isSubmitting"
            (click)="onSave(frmAssociation.getValue(), 'SAVE_AND_BACK')"
            *diAcl="'subassociation.update'"
          >
            <span>Save</span>
          </button>

          <button
            type="submit"
            class="btn btn-primary"
            [ngClass]="{ 'btn-loading': isSubmitting }"
            [disabled]="!frmAssociation.isValid() || isSubmitting"
            (click)="onSave(frmAssociation.getValue(), 'SAVE_AND_NEW')"
            *diAcl="'subassociation.update'"
          >
            <span>Save & New</span>
          </button>
        </div>
      </div>
    </div>

    <div [diMarkerWrapper]="association" class="col-md-4" *ngIf="!association.parent">
      <ul ngbNav #nav="ngbNav" class="nav-tabs">
        <!-- Workflow -->
        <li ngbNavItem>
          <a ngbNavLink>Workflow</a>
          <ng-template ngbNavContent>
            <dl class="row">
              <dt class="col-sm-5" [hidden]="!isClaimedByOtherUser()"></dt>
              <dd class="col-sm-7" [hidden]="!isClaimedByOtherUser()">
                <span title="s/o else started working recently" class="badge badge-info">Claimed</span>
              </dd>

              <dt class="col-sm-4" style="white-space: nowrap">Req by <small>/ Owner prd</small></dt>
              <dd class="col-sm-8">{{ association.ownerProduct || '-' }}</dd>

              <dt class="col-sm-4">Verified</dt>
              <dd class="col-sm-8">
                <span *diAcl="'association.update.prop.verified'">
                  <ui-switch
                    [disabled]="!allDirectMembershipVerified || !atleastOneIndirectMembershipVerified"
                    [checked]="!!association.verified"
                    (change)="onVerifiedChange($event)"
                    size="small"
                  ></ui-switch>
                  <i
                    class="fa fa-pulse fa-spinner di-va-super"
                    [hidden]="!isSavingVerified && !isUpdatingVerifiedControl"
                  ></i>
                </span>
                <span *diAcl="'!association.update.prop.verified'">
                  <i
                    class="fa"
                    [ngClass]="{ 'fa-check': association.verified, 'fa-times': !association.verified }"
                    aria-hidden="true"
                  ></i>
                </span>
              </dd>

              <dt class="col-sm-4">ID Verified</dt>
              <dd class="col-sm-8">
                <span *diAcl="'association.update.prop.idVerified'">
                  <ui-switch
                    [checked]="!!association.idVerified"
                    (change)="onIdVerifiedChange($event)"
                    size="small"
                  ></ui-switch>
                  <i class="fa fa-pulse fa-spinner di-va-super" [hidden]="!isSavingIdVerified"></i>
                </span>
                <span *diAcl="'!association.update.prop.idVerified'">
                  <i
                    class="fa"
                    [ngClass]="{ 'fa-check': association.idVerified, 'fa-times': !association.idVerified }"
                    aria-hidden="true"
                  ></i>
                </span>
              </dd>

              <dt class="col-sm-4">No&nbsp;Maintenance</dt>
              <dd class="col-sm-8">
                <span *diAcl="'association.update.prop.meta.maintenanceDisabled'">
                  <ui-switch
                    [checked]="!!association._meta.maintenanceDisabled"
                    (change)="onNoMaintenanceChange($event)"
                    size="small"
                    color="#f8d053"
                  ></ui-switch>
                  <i class="fa fa-pulse fa-spinner di-va-super" [hidden]="!isSavingNoMaintenance"></i>
                </span>
                <span *diAcl="'!association.update.prop.meta.maintenanceDisabled'">
                  <i
                    class="fa"
                    [ngClass]="{
                      'fa-check': association._meta.maintenanceDisabled,
                      'fa-times': !association._meta.maintenanceDisabled
                    }"
                    aria-hidden="true"
                  ></i>
                </span>
              </dd>

              <dt class="col-sm-4">Delivery Ready</dt>
              <dd class="col-sm-8">
                <i
                  class="fa"
                  [ngClass]="{ 'fa-check': association.readyForDelivery, 'fa-times': !association.readyForDelivery }"
                  aria-hidden="true"
                ></i>
              </dd>

              <dt class="col-sm-4 di-separator">Created</dt>
              <dd class="col-sm-8 di-separator">{{ association.createdAt | date : 'medium' }}</dd>

              <dt class="col-sm-4 text-truncate">Modified</dt>
              <dd class="col-sm-8">{{ association.updatedAt | date : 'medium' }}</dd>

              <dt class="col-sm-4 text-truncate" *ngIf="association.reviewedAt">Reviewed</dt>
              <dd class="col-sm-8" *ngIf="association.reviewedAt">{{ association.reviewedAt | date : 'medium' }}</dd>

              <dt class="col-sm-4 text-truncate">Status</dt>
              <dd class="col-sm-8">
                <span *diAcl="'!association.update.prop.meta.status'">{{
                  association._meta?.status | capitalize
                }}</span>
                <ng-container *diAcl="'association.update.prop.meta.status'">
                  <dirt-status
                    [statuses]="statuses"
                    [assigneeStatuses]="assigneeStatuses"
                    [disabledStatuses]="disabledStatuses"
                    [_meta]="association._meta"
                    (onStatusSelected)="onStatusChange($event)"
                    *diMarker="{ name: 'status', entity: 'association', area: 'ID' }"
                  >
                  </dirt-status>
                </ng-container>
                <i class="fa fa-circle-o-notch fa-spin" [hidden]="!isSavingStatus"></i>
              </dd>

              <dt class="col-sm-4">Project</dt>
              <dd class="col-sm-8">
                <dirt-project-name
                  [activeSet]="association.projectNames || []"
                  [type]="ValueType.Project"
                  [disabled]="!svcAcl.hasCredential('association.update.prop.projectNames')"
                  (onProjectSelectionChanged)="onProjectSelectionChanged($event)"
                  *diMarker="{ name: 'projectNames', entity: 'association', area: 'ID' }"
                >
                </dirt-project-name>
              </dd>

              <dt class="col-sm-4">Version</dt>
              <dd class="col-sm-8">{{ association._version || 0 }}</dd>
            </dl>
          </ng-template>
        </li>

        <!-- Comments -->
        <li ngbNavItem *diAcl="'association.comment.list'">
          <a ngbNavLink>Comments</a>
          <ng-template ngbNavContent>
            <dirt-comment-list
              [ownerId]="id"
              [enableCreate]="svcAcl.hasCredential('association.comment.create')"
            ></dirt-comment-list>
          </ng-template>
        </li>

        <!-- Audit Log -->
        <li ngbNavItem *diAcl="'association.audit.list'">
          <a ngbNavLink>Audit Log</a>
          <ng-template ngbNavContent>
            <dirt-audit-log
              [id]="id"
              [entityAPI]="svcAssociation"
              [shallowCompKeys]="auditLogShallowCompKeys"
            ></dirt-audit-log>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="dirt-section-meta dirt-association-meta"></div>
    </div>

    <div class="col-md-12">
      <dirt-ocr-bar [webSource]="(association.webSources || [])[0]"></dirt-ocr-bar>
    </div>

    <ng-template [diAcl]="'membership.list'" [diAclAnd]="isRegionCompliant()">
      <div [diMarkerWrapper]="association" class="col-md-12">
        <dirt-membership-list
          *diMarker="{ name: 'membership', entity: association.parent ? 'subassociation' : '', area: 'BD' }"
          (onVerifiedUpdate)="onVerifiedFlagUpdate($event)"
          [association]="association"
          [obsolete]="isObsolete()"
          [isSearchType]="!association.parent"
        ></dirt-membership-list>
      </div>
    </ng-template>

    <div [diMarkerWrapper]="association" class="col-md-12" *diAcl="'subassociation.list'">
      <ng-container *ngIf="association && !association.parent">
        <dirt-association-list
          *diMarker="{ name: 'subassociation', entity: 'association', area: 'BD' }"
          [parentId]="id"
          [obsolete]="isObsolete()"
        ></dirt-association-list>
      </ng-container>
    </div>
  </div>

  <!-- Loading Message -->
  <p class="dirt-loading" *ngIf="isLoading">
    <i class="fa fa-refresh fa-spin fa-2x" aria-hidden="true"></i>
    <span class="sr-only">Loading...</span>
  </p>
</section>
