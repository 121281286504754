import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { APIService } from '../../shared/services/api/api.service';
import { GET, MediaType, Path, Produces } from '../../shared/services/rest/rest.service';
import { Podcast } from './podcast';

@Injectable()
export class PodcastAPI extends APIService {
  @GET('podcasts/{id}')
  @Produces(MediaType.JSON)
  public findById(@Path('id') id: string): Observable<Podcast> {
    return;
  }
}
