import { Person } from './person';
import { PersonAffiliation } from './person-affiliation';

export function sortAffiliations(person: Person): void {
  // we can in fact change the order - index order plays no role so far (otherwise, deletions would have wreaked havoc already)
  if (person.affiliations) {
    person.affiliations.sort((a1: PersonAffiliation, a2: PersonAffiliation) => {
      if (a1.primary) {
        return -1;
      } else if (a2.primary) {
        return 1;
      } else if (a1.inactive && !a2.inactive) {
        return 1;
      } else if (!a1.inactive && a2.inactive) {
        return -1;
      } else {
        return 0;
      }
    });
  }
}
