import { AssociationObsolete } from './constant/obsolete.enum';

export class Association {
  public id: string;
  public address: any = {};
  public areas: string[] = [];
  public associationsCount: number;
  public category: number;
  public createdAt: Date;
  public membershipsCount: number;
  public name: string;
  public nameTranslated: boolean;
  public originalName: string;
  public obsolete: AssociationObsolete;
  public parent: Association;
  public priority: number;
  public published: boolean;
  public reach: string;
  public readyForDelivery: boolean;
  public reviewedAt: Date;
  public scope: string;
  public therapeuticAreas: string[] = [];
  public type: string;
  public updatedAt: Date;
  public verified: boolean;
  public verificationSkipped?: boolean;
  public verifiedBy?: string;
  public verificationSkippedBy?: string;
  public verificationSkippedAt?: Date;
  public webSources: string[] = [];
  public memberWebSources: string[] = [];
  projectNames: string[];
  public twitterHandle: string;
  public localLogoUrl: string;
  public localLogoUrl_nocache: string;
  public remoteLogoUrl: string;
  public logoWebSource: string;
  public useTwitterLogo: boolean;

  qc: any;
  public _meta: {
    assignee?: string;
    lastAssignee?: string;
    maintenance?: boolean;
    maintenanceDisabled?: boolean;
    status: string;
    claimedUntil?: Date;
  };
  public _version: number;
  public reviewed?: boolean;
  public originalLanguage?: string;

  public description?: string;
  public youtubeChannelUrl?: string;
  public linkedInProfileUrl?: string;
  public phone: {
    countryCallingCode?: string;
    number?: string;
    type?: string;
  } = {};
  public email?: string;

  public sponsors?: Array<string> = [];
  ownerProduct?: string; // only parents

  constructor() {}
}
