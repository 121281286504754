import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';

@Component({
  selector: 'dirt-link',
  templateUrl: 'link.component.html',
  styleUrls: ['link.component.scss'],
})
export class LinkComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  url: string;

  @Input()
  name: string;

  @Input()
  /** if provided will hook into the form validation */
  form?: NgForm;

  @Input()
  small: boolean = false;

  @Input()
  /** If provided, will use domains associated with type in the values collection for validation */
  nonCompliantDomainType?: string;

  @Input()
  disabled?: boolean;

  @Input()
  required?: boolean;

  @Input()
  /** If provided, will only validate the shape and not the compliance of the link */
  validateFormatOnly?: boolean;

  @Input()
  placeholder?: string = 'e.g: https://veeva.com';

  @Output()
  urlChange: EventEmitter<string> = new EventEmitter();

  @ViewChild('linkInput', { static: true })
  ngControl: NgModel;

  private protocolRegexp = new RegExp(/(^\w+:|^)\/\//);

  /**
   * Register control in parent form so that the input validity is checked when
   * checking form validity.
   */
  ngOnInit(): void {
    if (this.form && !this.disabled) {
      this.form.addControl(this.ngControl);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // we always display the status (input is readonly so validators always work) - make sure one can still submit the form when our control is disabled
    if (this.form && this.ngControl && changes['disabled']) {
      if (!changes['disabled'].currentValue) {
        this.form.addControl(this.ngControl); // (form takes care it's only in the list once)
      } else {
        this.form.removeControl(this.ngControl); // (form can stomatch several deletes)
      }
    }
  }

  /**
   * Form could outlive control so unregister control on destroy.
   */
  ngOnDestroy(): void {
    if (this.form) {
      this.form.removeControl(this.ngControl);
    }
  }

  onValueChange(value: string): void {
    if (this.disabled) {
      return;
    }

    this.urlChange.emit(value);
  }

  onOpenURL(url: string): void {
    if (!url.match(/^https?:\/\//i)) {
      // Don't touch protocol if we have it (http or https), otherwise, try to fix it ourselves
      url = 'http://' + url.replace(this.protocolRegexp, ''); // remove protocols if any
    }
    window.open(url, '_blank', 'noopener noreferrer'); // force link to be opened externally
  }
}
