import { Directive, forwardRef } from '@angular/core';
import { NG_VALIDATORS, Validator, Validators, AbstractControl } from '@angular/forms';

const XRegExp = require('xregexp');

const REGEX = new XRegExp("^[\\p{L}\\p{M}-\\s'’]+$");

const NAME_VALIDATOR: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => NameWithoutDotValidator),
  multi: true,
};

@Directive({
  selector:
    '[validateNameWithoutDot][formControlName],[validateNameWithoutDot][formControl],[validateNameWithoutDot][ngModel]',
  providers: [NAME_VALIDATOR],
})
export class NameWithoutDotValidator implements Validator {
  validate(c: AbstractControl): { [key: string]: any } {
    if (this.isPresent(Validators.required(c))) {
      return;
    }

    return this.test(c.value);
  }

  public test(v: string) {
    return REGEX.test(v) ? null : { character: true };
  }

  private isPresent(obj: any) {
    return obj !== undefined && obj !== null;
  }
}
